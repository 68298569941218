import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError } from 'rxjs';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  selector: 'app-promotion-form',
  templateUrl: './promotion-form.component.html',
  styleUrls: ['./promotion-form.component.css'],
})
export class PromotionFormComponent implements OnInit {
  public promotionForm: FormGroup;
  public submitted = false;
  public customerList = ['Best Buy', 'Walmart', 'Target', 'Dollar General'];
  public classList = ['New Release', 'Recent Release', 'Catalog', 'TV'];
  public subClassList = ['DVD', 'BD', '3D', 'UHD'];
  public fundTypeList = [{ fundTypeId: 1, fundTypeName: 'Promo' }, { fundTypeId: 2, fundTypeName: 'Base' }];
  public promotionProductType = ['Special Edition', 'Catalog Drafters', 'Single Disc'];
  public advertisementDescList = ['In-Store', 'Emali Blast', 'Direct Mail'];
  @Input() data: any;
  public skuPromotion = null;
  isEdit = false;
  constructor(public fb: FormBuilder,
    public storage: LocalstorageService, public configurationService: ConfigurationService, public spinner: NgxSpinnerService, public toastr: NgxToasterService) {
    this.initializeForm();
  }

  ngOnInit() {
    console.log('called');
    if (this.data) {
      this.data = JSON.parse(this.data);
      this.isEdit = this.data.isEdit;
      delete this.data.isEdit;
      this.data.customers = (this.data.customers || '').split(',');
      this.data.classes = (this.data.classes || '').split(',');
      this.data.subClasses = (this.data.subClasses || '').split(',');
      this.data.productType = (this.data.productType || '').split(',');
      this.data.consumerPromotion = (this.data.consumerPromotion || '').split(',');
      this.data.advertisementDescription = (this.data.advertisementDescription || '').split(',');
      this.initializeFormWithValues();
      this.getSkuPromotionList(this.data.promotionId);
    }
  }
  public getSkuPromotionList = async (promotionId: any) => {
    const params = {
      active: true,
      promotionId: promotionId
    };
    const skuList = await this.configurationService.SKUPromotionGetAll(params).toPromise().catch(error => throwError(error));
    this.skuPromotion = skuList;
    this.storage.set('SKULIST', this.skuPromotion);
  }

  public initializeFormWithValues = () => {
    Object.keys(this.promotionForm.controls).forEach(key => {
      if (this.data[key] !== undefined && this.data[key] !== null) {
        this.promotionForm.controls[key].setValue(this.data[key]);
      }
    });
  }
  public initializeForm = () => {
    this.promotionForm = this.fb.group({
      promotionId: [0],
      promotionName: [],
      active: [],
      customers: [],
      planningMonth: [],
      startDate: [],
      endDate: [],
      updateRMF: [],
      returnRate: [],
      fundTypeId: [],
      shipmentOffsetWeeks: [],
      comment: [],
      returnOffsetWeeks: [],
      classes: [],
      subClasses: [],
      promotionDescription: [],
      primaryPlacement: [],
      secondaryPlacement: [],
      // productType: [],
      consumerPromotion: [],
      advertisementDescription: [],
      categoryStrategy: []
    });
  }
  public onSubmit = () => {
    this.submitted = true;
    if (this.promotionForm.valid) {
      const model = { ...this.promotionForm.value };
      model.customers = (model.customers || []).toString();
      model.classes = (model.classes || []).toString();
      model.subClasses = (model.subClasses || []).toString();
      model.productType = (model.productType || []).toString();
      model.consumerPromotion = (model.consumerPromotion || []).toString();
      model.advertisementDescription = (model.advertisementDescription || []).toString();
      this.spinner.show();
      if (this.isEdit) {
        this.configurationService.PromotionUpdate(model).subscribe(res => {
          this.spinner.hide();
          this.toastr.success('Promotion', 'Promotion updated successfully.');
          this.configurationService.promotionListSubject.next(res);
          this.isEdit = false;
          this.initializeForm();
        }, error => {
          this.spinner.hide();
        }
        );
      } else {
        this.configurationService.PromotionAdd(model).subscribe(res => {
          this.spinner.hide();
          this.getSkuPromotionList(res);
          this.toastr.success('Promotion', 'Promotion updated successfully.');
          this.configurationService.promotionListSubject.next(res);
          this.isEdit = true;
        }, error => {
          this.spinner.hide();
        });
      }
    }
  }
  public onNoClick = () => {
  }
}
