import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AllCommunityModules, CellClickedEvent } from '@ag-grid-community/all-modules';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ViewRendererComponent } from 'src/common/view-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { FirebaseService } from 'src/services/firebase.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-pdm-listing',
  templateUrl: './pdm-listing.component.html',
  styleUrls: ['./pdm-listing.component.css']
})
export class PdmListingComponent implements OnInit {
  @ViewChild('ExcelImport') excelImport: TemplateRef<any>;
  @ViewChild('dialog') dialogTemplate: TemplateRef<any>;
  public categoryList = [];
  public category;
  public files = [];
  public classList = [{ classCode: 1, classCodeDescription: 'TV' }, { classCode: 2, classCodeDescription: 'Theatrical' }];
  public subClassList = [
    { subClassCode: 1, subClassCodeDescription: 'DVD' },
    { subClassCode: 2, subClassCodeDescription: 'BD' },
    { subClassCode: 3, subClassCodeDescription: '3D' },
    { subClassCode: 4, subClassCodeDescription: 'UHD' }
  ];
  public columnDefs = [
    {
      headerName: 'Image',
      show: true,
      field: 'imagePath',
      resizable: true,
      filter: false,
      pinned: 'left',
      cellRenderer: (params) => {
        return `<img width='50' height='50' src='${params.value}'/>`;
      },
      width: 100
    },
    {
      headerName: 'SKU',
      show: true,
      field: 'sku',
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openModal.bind(this),
      },
      width: 150,
      pinned: 'left',
    },

    {
      headerName: 'Department',
      show: true,
      field: 'department',
      // pinned: 'left',
      width: 150,
    },
    {
      headerName: 'Class',
      show: true,
      field: 'class',
    },
    {
      headerName: 'Subclass',
      show: true,
      field: 'subclass',
    },
    {
      headerName: 'Product Description',
      show: true,
      field: 'productDescription',
    },
    {
      headerName: 'POS Description',
      show: true,
      field: 'posDescription',
    },
    {
      headerName: 'UPC Type',
      show: true,
      field: 'upcType',
    },
    {
      headerName: 'UPC',
      show: true,
      field: 'upc',
    },
    {
      headerName: 'Brand Name',
      show: true,
      field: 'brandName',
    },
    {
      headerName: 'Supplier GLN',
      show: true,
      field: 'supplierGLN',
    },
    {
      headerName: 'Supplier GLN Name',
      show: true,
      field: 'supplierGLNName',
    },
    {
      headerName: 'Category',
      show: true,
      field: 'category',
    },
    {
      headerName: 'Product Copy',
      show: true,
      field: '',
      resizable: true,
      filter: false,

      cellRenderer: 'viewRenderer',
      cellRendererParams: {
        onClick: this.openProductViewRenderer.bind(this),
      },
      width: 150,
    },
    {
      headerName: 'Images',
      show: true,
      field: '',
      resizable: true,
      filter: false,

      cellRenderer: 'viewRenderer',
      cellRendererParams: {
        onClick: this.openImageViewRenderer.bind(this),
      },
      width: 100
    },
    {
      headerName: 'Video',
      show: true,
      field: '',
      resizable: true,
      filter: false,

      cellRenderer: 'viewRenderer',
      cellRendererParams: {
        onClick: this.openVideoViewRenderer.bind(this),
      },
      width: 100
    },
    {
      headerName: 'Document',
      show: true,
      field: '',
      resizable: true,
      filter: false,

      cellRenderer: 'viewRenderer',
      cellRendererParams: {
        onClick: this.openDocumentViewRenderer.bind(this),
      },
      width: 120
    },
    {
      headerName: '',
      show: true,
      field: 'delete',
      resizable: true,
      filter: false,
      pinned: 'right',
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveGoalDialog.bind(this),
      },
      width: 80
    }
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public ItemMasterList = [];
  public modules = AllCommunityModules;
  public user: any;
  public selectAll = false;
  public imagePathList = [
    'https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4tjV5?ver=eab4&q=90&m=6&h=270&w=270&b=%23FFFFFFFF&f=jpg&o=f&aim=true',
    'https://images-na.ssl-images-amazon.com/images/I/51u17Fbw%2B6L._AC_UL160_.jpg',
    'https://images-na.ssl-images-amazon.com/images/I/51PlrE3b1LL._AC_UL160_.jpg',
    'https://compass-ssl.xbox.com/assets/fb/1d/fb1db1be-e38e-4939-a917-45795b7490c3.jpg?n=X1S-MWF-2017_3-4-Col-0_Console_740x417.jpg',
    'https://store-images.s-microsoft.com/image/apps.53461.66922732424733865.effc282c-0a95-45c2-8069-86e7e083b3aa.a6e0c6a7-34c8-410b-871a-b1e6d650871a?mode=scale&q=90&h=300&w=200&background=%23FFFFFF'
  ];
  constructor(public router: Router,
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<any>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public firebase: FirebaseService) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent,
        nameRenderer: NameRendererComponent,
        viewRenderer: ViewRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    this.getItemMasterList();
    this.firebase.getAllCategory().subscribe((res: any) => {
      this.categoryList = res.map(e => {
        return {
          ...e.payload.doc.data(),
          id: e.payload.doc.id
        };
      });
    });
    this.configurationService.itemMasterListSubject$.subscribe(res => {
      if (res) {
        this.getItemMasterList();
      }
    });
  }
  public getItemMasterList = () => {
    // this.configurationService.ItemMasterGetAll({}).subscribe(res => {
    //   this.ItemMasterList = res;
    //   for (let index = 0;  index < this.ItemMasterList.length; index++) {
    //     this.ItemMasterList[index].imagePath = this.imagePathList[index];
    //   }
    // });
    this.firebase.getAllSKU().subscribe((res: any) => {
      this.ItemMasterList = res.map(e => {
        return {
          ...e.payload.doc.data(),
          id: e.payload.doc.id
        };
      });
      for (let index = 0; index < this.ItemMasterList.length; index++) {
        this.ItemMasterList[index].imagePath = this.ItemMasterList[index].images && this.ItemMasterList[index].images.length > 0 ? this.ItemMasterList[index].images[this.ItemMasterList[index].images.length - 1].documentPath : '';
      }
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.sku }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeItemMaster(row.rowData);
      }
    });
  }
  openProductViewRenderer(row: any): void {
    const selector = {
      menuId: '470',
      name: 'Product Copy',
      selector: 'app-product-copy',
      displayName: 'Product Copy',
      data: {}
    };
    this.firebase.category = row.rowData;
    this.configurationService.menuRendererSubject.next(selector);
  }
  openImageViewRenderer(row: any): void {
    const selector = {
      menuId: '471',
      name: 'Image',
      selector: 'app-image',
      displayName: 'Image',
      data: row.rowData
    };
    this.firebase.category = row.rowData;
    this.configurationService.menuRendererSubject.next(selector);
  }
  openVideoViewRenderer(row: any): void {
    const selector = {
      menuId: '472',
      name: 'Video',
      selector: 'app-video',
      displayName: 'Video',
      data: {}
    };
    this.firebase.category = row.rowData;
    this.configurationService.menuRendererSubject.next(selector);
  }
  openDocumentViewRenderer(row: any): void {
    const selector = {
      menuId: '473',
      name: 'Document',
      selector: 'app-document',
      displayName: 'Document',
      data: {}
    };
    this.firebase.category = row.rowData;
    this.configurationService.menuRendererSubject.next(selector);
  }
  public removeItemMaster(selectedItem: any) {
    if (selectedItem) {
      const model = {
        ...selectedItem,
        active: false
      };
      this.spinner.show();
      this.configurationService.ItemMasterActivate(model).subscribe(res => {
        if (res) {
          this.spinner.hide();
          this.toastr.success('Master Items', 'Item removed successfully');
          this.ItemMasterList = this.ItemMasterList.filter(row => row.itemMasterId !== model.itemMasterId);
          this.gridOptions.api.setRowData(this.ItemMasterList);
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('Master Items', error.error.text);
      });
    }
  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  // onCellClicked($event: CellClickedEvent) {
  //   if ($event && $event.colDef.field === 'itemDesciption') {
  //     this.openModal($event.data);
  //   }
  // }
  public openModal(data?: any) {
    const selector = {
      menuId: '461',
      name: 'PDM Creation',
      selector: 'app-pdm-form',
      displayName: 'PDM Creation',
      data: data
    };
    this.configurationService.menuRendererSubject.next(selector);
  }
  openExcelImportDialog(data, templateRef?: TemplateRef<any>): void {
    this.matDialogRef = this.dialog.open(this.excelImport, { width: '500px', data: data || {} });
  }
  public getTemplate() {
    this.gridApi.exportDataAsCsv();
    this.matDialogRef.close();
  }
  onSelect(event: any) {
  }
  onRemove(event: any) {
  }
  openFullScreenMode() {
    const domGrid = document.getElementById('myGrid') as any;
    if (domGrid) {
      domGrid.webkitRequestFullscreen();
    }
  }
  selectAllSelected(flag: boolean): void {
    if (flag) {
      this.columnDefs.forEach(column => column.show = true);
    } else {
      this.columnDefs.forEach(column => column.show = false);
    }
  }
  public onSave = () => {
    const newColumnDefs = this.columnDefs.filter(column => column.show);
    this.gridOptions.api.setColumnDefs(newColumnDefs);
    this.matDialogRef.close();
  }
  openDialog(data, templateRef?: TemplateRef<any>): void {
    this.matDialogRef = this.dialog.open(this.dialogTemplate, { width: '500px', data: data || {} });
  }
}
