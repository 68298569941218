<!-- Main Content area -->
<div class="container-fluid" style="zoom: 70%; min-height: 100vh">
  <div class="row">
    <div class="col-md-3 form-group">
      <label>Timeframe</label>
      <ng-multiselect-dropdown
        [settings]="dropdownSettings"
        *ngIf="monthList && monthList.length > 0"
        style="max-height: 20px !important"
        [placeholder]="'Timeframe'"
        [data]="monthList"
        type="number"
        [(ngModel)]="timeframeSelectedList"
        (onSelectAll)="filterListByMonth($event)"
        (onDeSelect)="filterListByMonth(null)"
        (onSelect)="filterListByMonth(null)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2 form-group">
      <label>Item ID</label>
      <ng-multiselect-dropdown
        [settings]="dropdownSettingsSingle"
        *ngIf="itemIdList && itemIdList.length > 0"
        [placeholder]="'Item ID'"
        [data]="itemIdList"
        type="number"
        [(ngModel)]="selectedItemIds"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2 form-group">
      <label>Item</label>
      <ng-multiselect-dropdown
        [settings]="dropdownSettingsSingle"
        *ngIf="itemList && itemList.length > 0"
        [placeholder]="'Item'"
        [data]="itemList"
        type="number"
        [(ngModel)]="itemSeletedList"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2 form-group">
      <label>Version</label>
      <ng-multiselect-dropdown
        [settings]="dropdownSettingsSingle"
        *ngIf="versionList && versionList.length > 0"
        [placeholder]="'Version'"
        [data]="versionList"
        type="number"
        [(ngModel)]="seletedVersion"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-3 form-group">
      <label>Customer</label>
      <ng-multiselect-dropdown
        [settings]="dropdownSettingsSingle"
        *ngIf="customerList && customerList.length > 0"
        [placeholder]="'Customer'"
        [data]="customerList"
        type="number"
        [(ngModel)]="customerSelectedList"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
  </div>
  <mat-card-title
    [ngStyle]="{
      visibility:
        customerSelectedList.length > 0 &&
        seletedVersion &&
        itemSeletedList.length > 0 &&
        timeframeSelectedList.length > 0 &&
        dataSource
          ? 'visible'
          : 'collapse'
    }"
  >
    Graphic View
  </mat-card-title>
  <mat-card
    [ngStyle]="{
      visibility:
        customerSelectedList.length > 0 &&
        seletedVersion &&
        itemSeletedList.length > 0 &&
        timeframeSelectedList.length > 0 &&
        dataSource
          ? 'visible'
          : 'collapse'
    }"
    class="mat-card-box"
    style="border-top: 2px solid #d81ad1"
  >
    <mat-card-header> </mat-card-header>
    <mat-card-content>
      <fusioncharts
        *ngIf="dataSource"
        [width]="width"
        [height]="height"
        [type]="type"
        [dataFormat]="dataFormat"
        [dataSource]="dataSource"
      >
      </fusioncharts>
    </mat-card-content>
  </mat-card>
  <div
    [ngStyle]="{
      visibility:
        customerSelectedList.length > 0 &&
        seletedVersion &&
        itemSeletedList.length > 0 &&
        timeframeSelectedList.length > 0 &&
        dataSource
          ? 'visible'
          : 'collapse'
    }"
    class="action-panel text-right"
  >
    <span (click)="openDialog({}, dialog)">
      <i
        style="font-size: 42px; cursor: pointer"
        class="material-icons"
        aria-label="Column Show/Hide"
      >
        list_alt
      </i>
    </span>
    <span (click)="openDialog({}, dialogGraphFields)">
      <i
        style="font-size: 42px; cursor: pointer"
        class="material-icons"
        aria-label="Column Show/Hide"
      >
        trending_up
      </i>
    </span>
    <span (click)="openExcelImportDialog({})">
      <i
        style="font-size: 42px; cursor: pointer"
        class="material-icons"
        aria-label="Column Show/Hide"
      >
        import_export
      </i>
    </span>
    <span
      (click)="openFullScreen('forcast-grid')"
      matTooltip="Full Screen"
      matTooltipPosition="above"
    >
      <i
        style="font-size: 42px; cursor: pointer"
        class="material-icons"
        aria-label="Full Screen"
      >
        fullscreen
      </i>
    </span>
  </div>
  <div>
    <table
      *ngIf="
        customerSelectedList.length > 0 &&
        seletedVersion &&
        itemSeletedList.length > 0 &&
        timeframeSelectedList.length > 0
      "
      [ngStyle]="{
        visibility:
          customerSelectedList.length > 0 &&
          seletedVersion &&
          itemSeletedList.length > 0 &&
          timeframeSelectedList.length > 0 &&
          dataSource
            ? 'visible'
            : 'collapse'
      }"
      class="table"
    >
      <!-- Data Label Start -->
      <td>
        <tr>
          <td style="text-align: left" align="left">
            <tr>
              <label>&nbsp;</label>
            </tr>
            <tr>
              <td>
                <tr>
                  <label>&nbsp;</label>
                </tr>
                <tr>
                  <td>
                    <tr>
                      <label>&nbsp;</label>
                    </tr>
                    <tr>
                      <td>
                        <tr>
                          <label>&nbsp;</label>
                        </tr>
                        <tr>
                          <td>
                            <tr>
                              <label style="height: 35px !important"
                                >&nbsp;</label
                              >
                            </tr>
                            <ng-container *ngFor="let label of leftGroupList">
                              <tr
                                [attr.rowspan]="label.rowspan"
                                style="
                                  text-align: center;
                                  align-items: center;
                                  display: flex;
                                  font-size: large;
                                "
                                [ngStyle]="{
                                  background: label.color,
                                  height: label.rowspan * 34 + 'px'
                                }"
                              >
                                <label
                                  style="
                                    min-width: 150px;
                                    max-width: 150px;
                                    height: 34px;
                                    text-align: center;
                                  "
                                  >{{ label.name }}</label
                                >
                              </tr>
                            </ng-container>
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </td>
                </tr>
              </td>
            </tr>
          </td>
        </tr>
      </td>
      <!-- Data Label End -->
      <td>
        <tr>
          <td style="text-align: left" align="left">
            <tr>
              <label>&nbsp;</label>
            </tr>
            <tr>
              <td>
                <tr>
                  <label>&nbsp;</label>
                </tr>
                <tr>
                  <td>
                    <tr>
                      <label>&nbsp;</label>
                    </tr>
                    <tr>
                      <td>
                        <tr>
                          <label>&nbsp;</label>
                        </tr>
                        <tr>
                          <td>
                            <tr>
                              <label style="height: 35px !important"
                                >&nbsp;</label
                              >
                            </tr>
                            <ng-container *ngFor="let label of chooserList">
                              <tr *ngIf="label.checked" style="height: 34px">
                                <label
                                  style="min-width: 300px; max-width: 300px"
                                  >{{ label.name }}</label
                                >
                              </tr>
                            </ng-container>
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </td>
                </tr>
              </td>
            </tr>
          </td>
        </tr>
      </td>
      <!-- Data Table Start-->
      <td
        style="overflow-x: scroll; width: 100%"
        [ngStyle]="{ 'max-width': getWidth() }"
      >
        <tr>
          <td
            *ngFor="let year of yearList"
            style="text-align: center"
            align="center"
          >
            <tr>
              <label
                >{{ year.title
                }}<i
                  (click)="collapseYear(year)"
                  style="font-size: 15px"
                  class="material-icons"
                  >remove_red_eye</i
                ></label
              >
            </tr>
            <tr>
              <td
                [class.d-none]="year.show"
                *ngFor="let half of year.halfList"
                style="text-align: center"
                align="center"
              >
                <tr>
                  <label
                    >{{ half.title
                    }}<i
                      (click)="collapseHalf(half)"
                      style="font-size: 15px"
                      class="material-icons"
                      >remove_red_eye</i
                    ></label
                  >
                </tr>
                <tr>
                  <td
                    [class.d-none]="year.show || half.show"
                    *ngFor="let quarter of half.quarterList"
                    style="text-align: center"
                  >
                    <tr>
                      <label
                        >{{ quarter.title
                        }}<i
                          (click)="collapseQuarter(quarter)"
                          style="font-size: 15px"
                          class="material-icons"
                          >remove_red_eye</i
                        ></label
                      >
                    </tr>
                    <tr>
                      <td
                        [class.d-none]="year.show || half.show || quarter.show"
                        *ngFor="let month of quarter.monthList"
                        style="text-align: center"
                      >
                        <tr>
                          <label
                            >{{ month.title
                            }}<i
                              (click)="collapseMonth(month)"
                              style="font-size: 15px"
                              class="material-icons"
                              >remove_red_eye</i
                            ></label
                          >
                        </tr>
                        <tr>
                          <ng-container
                            [ngTemplateOutlet]="
                              !year.show &&
                              !half.show &&
                              !quarter.show &&
                              !month.show
                                ? monthColumn
                                : otherColumn
                            "
                            [ngTemplateOutletContext]="{
                              week: getObject(year, month, quarter, {})
                            }"
                          >
                          </ng-container>
                          <ng-template #monthColumn>
                            <td
                              *ngFor="let week of month.weekList"
                              style="text-align: center"
                            >
                              <tr>
                                <label style="height: 35px !important">{{
                                  week.title
                                }}</label>
                              </tr>
                              <!-- Algo -->
                              <tr *ngIf="checkIfInList('BaselineSTForecast')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.Algo.BaselineSTForecast"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="BaselineSTForecast"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('AlgoPromoUnits')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.Algo.AlgoPromoUnits"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AlgoPromoUnits"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('AlgoTotalSTFcst')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.Algo.AlgoTotalSTFcst"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AlgoTotalSTFcst"
                                />
                              </tr>
                              <tr
                                *ngIf="checkIfInList('AlgoTotalSTFcstvartoLY')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.Algo.AlgoTotalSTFcstvartoLY"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AlgoTotalSTFcstvartoLY"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList(
                                    'AlgoTotalSTFcstvartoDPTotalSTFcst'
                                  )
                                "
                              >
                                <input
                                  [ngModel]="
                                    week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst
                                      | percent
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AlgoTotalSTFcstvartoDPTotalSTFcst"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList(
                                    'AlgoTotalSTFcstvartoCOTotalSTFcst'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AlgoTotalSTFcstvartoCOTotalSTFcst"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList(
                                    'AlgoTotalSTFcstvartoConsensusSTFcst'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.Algo
                                      .AlgoTotalSTFcstvartoConsensusSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AlgoTotalSTFcstvartoConsensusSTFcst"
                                />
                              </tr>
                              <!-- Algo -->

                              <!-- DemandPlanning -->
                              <tr *ngIf="checkIfInList('DPBaselineSTFcst')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.DemandPlanning.DPBaselineSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="DPBaselineSTFcst"
                                />
                              </tr>
                              <tr
                                *ngIf="checkIfInList('DPManualFcstAdjustment')"
                              >
                                <input
                                  [disabled]="false"
                                  type="number"
                                  [(ngModel)]="
                                    week.DemandPlanning.DPManualFcstAdjustment
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  (change)="DPManualFcstAdjustmentUpdated(week)"
                                  id="DPManualFcstAdjustment"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('DPPromoUnits')">
                                <input
                                  [disabled]="false"
                                  type="number"
                                  (change)="DPPromoUnitsUpdated(week)"
                                  [(ngModel)]="week.DemandPlanning.DPPromoUnits"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="DPPromoUnits"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('DPTotalSTFcst')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.DemandPlanning.DPTotalSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="DPTotalSTFcst"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('DPComments')">
                                <input
                                  [disabled]="false"
                                  type="text"
                                  [(ngModel)]="week.DemandPlanning.DPComments"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="DPComments"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('DPTotalSTFcstvartoLY')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.DemandPlanning.DPTotalSTFcstvartoLY
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="DPTotalSTFcstvartoLY"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList(
                                    'DPTotalSTFcstvartoCOTotalSTFcst'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.DemandPlanning
                                      .DPTotalSTFcstvartoCOTotalSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="DPTotalSTFcstvartoCOTotalSTFcst"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList(
                                    'DPTotalSTFcstvartoConsensusSTFcst'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.DemandPlanning
                                      .DPTotalSTFcstvartoConsensusSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="DPTotalSTFcstvartoConsensusSTFcst"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList(
                                    'DPTotalSTFcstvartoAlgoTotalSTFcst'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.DemandPlanning
                                      .DPTotalSTFcstvartoAlgoTotalSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="DPTotalSTFcstvartoAlgoTotalSTFcst"
                                />
                              </tr>
                              <!-- DemandPlanning -->

                              <!-- ChannelOps -->
                              <tr *ngIf="checkIfInList('COBaselineSTForecast')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ChannelOps.COBaselineSTForecast
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="COBaselineSTForecast"
                                />
                              </tr>
                              <tr
                                *ngIf="checkIfInList('COManualFcstAdjustment')"
                              >
                                <input
                                  [disabled]="false"
                                  type="number"
                                  [(ngModel)]="
                                    week.ChannelOps.COManualFcstAdjustment
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  (change)="COManualFcstAdjustmentUpdated(week)"
                                  id="COManualFcstAdjustment"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('COPromoUnits')">
                                <input
                                  [disabled]="false"
                                  type="number"
                                  (change)="COPromoUnitsUpdated(week)"
                                  [(ngModel)]="week.ChannelOps.COPromoUnits"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="COPromoUnits"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('COTotalSTFcst')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.ChannelOps.COTotalSTFcst"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="COTotalSTFcst"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('COComments')">
                                <input
                                  [disabled]="false"
                                  type="text"
                                  [(ngModel)]="week.ChannelOps.COComments"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="COComments"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('COTotalSTFcstvartoLY')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ChannelOps.COTotalSTFcstvartoLY
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="COTotalSTFcstvartoLY"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList(
                                    'COTotalSTFcstvartoDPTotalSTFcst'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ChannelOps
                                      .COTotalSTFcstvartoDPTotalSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="COTotalSTFcstvartoDPTotalSTFcst"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList(
                                    'COTotalSTFcstvartoConsensusSTFcst'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ChannelOps
                                      .COTotalSTFcstvartoConsensusSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="COTotalSTFcstvartoConsensusSTFcst"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList(
                                    'COTotalSTFcstvartoAlgoTotalSTFcst'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ChannelOps
                                      .COTotalSTFcstvartoAlgoTotalSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="COTotalSTFcstvartoAlgoTotalSTFcst"
                                />
                              </tr>

                              <!-- ChannelOps -->

                              <!-- CurrentSellThrough -->
                              <tr *ngIf="checkIfInList('CustomerSTFcst')">
                                <input
                                  [disabled]="false"
                                  type="number"
                                  (change)="CustomerSTFcstUpdated(week)"
                                  [(ngModel)]="
                                    week.CurrentSellThrough.CustomerSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="CustomerSTFcst"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('ConsensusSTFcst')">
                                <input
                                  [disabled]="false"
                                  type="number"
                                  (change)="ConsensusSTFcstUpdated(week)"
                                  [(ngModel)]="
                                    week.CurrentSellThrough.ConsensusSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ConsensusSTFcst"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList(
                                    'ConsensusSTFcstvartoCustomerSTFcst'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.CurrentSellThrough
                                      .ConsensusSTFcstvartoCustomerSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ConsensusSTFcstvartoCustomerSTFcst"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('ConstrainedSTFcst')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.CurrentSellThrough.ConstrainedSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ConstrainedSTFcst"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList(
                                    'ConstrainedSTFcstvartoConsensusSTFcst'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.CurrentSellThrough
                                      .ConstrainedSTFcstvartoConsensusSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ConstrainedSTFcstvartoConsensusSTFcst"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('ProjectedLostSales')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.CurrentSellThrough.ProjectedLostSales
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ProjectedLostSales"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('ActualST')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.CurrentSellThrough.ActualST"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ActualST"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('WeeklyTrend')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.CurrentSellThrough.WeeklyTrend
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="WeeklyTrend"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('PromoIndicator')">
                                <input
                                  type="text"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.CurrentSellThrough.PromoIndicator
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="PromoIndicator"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('CPFRComments')">
                                <input
                                  [disabled]="false"
                                  type="text"
                                  [(ngModel)]="
                                    week.CurrentSellThrough.CPFRComments
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="CPFRComments"
                                />
                              </tr>
                              <tr
                                *ngIf="checkIfInList('CustomerInStockEstimate')"
                              >
                                <input
                                  [disabled]="false"
                                  type="number"
                                  [(ngModel)]="
                                    week.CurrentSellThrough
                                      .CustomerInStockEstimate
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="CustomerInStockEstimate"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList('ActualVartoConsensusFcstUnits')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.CurrentSellThrough
                                      .ActualVartoConsensusFcstUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ActualVartoConsensusFcstUnits"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList('ActualVartoConsensusFcstPst')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.CurrentSellThrough
                                      .ActualVartoConsensusFcstPst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ActualVartoConsensusFcstPst"
                                />
                              </tr>
                              <!-- CurrentSellThrough -->

                              <!-- LastYearSales -->
                              <tr *ngIf="checkIfInList('LYActualST')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.LastYearSales.LYActualST"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="LYActualST"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('LYPromoIndicator')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.LastYearSales.LYPromoIndicator
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="LYPromoIndicator"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('LYCOComments')">
                                <input
                                  type="text"
                                  [disabled]="true"
                                  [(ngModel)]="week.LastYearSales.LYCOComments"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="LYCOComments"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList('LYCustomerInStockEstimate')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.LastYearSales.LYCustomerInStockEstimate
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="LYCustomerInStockEstimate"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('LYConsensusFcst')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.LastYearSales.LYConsensusFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="LYConsensusFcst"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList('LYActualtoConsensusFcstVarPst')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.LastYearSales
                                      .LYActualtoConsensusFcstVarPst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="LYActualtoConsensusFcstVarPst"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList(
                                    'LYActualtoConsensusFcstVarUnits'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.LastYearSales
                                      .LYActualtoConsensusFcstVarUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="LYActualtoConsensusFcstVarUnits"
                                />
                              </tr>
                              <!-- LastYearSales -->

                              <!-- StAccuracy -->
                              <tr
                                *ngIf="
                                  checkIfInList('CustomerSTFcstvsActualST')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.StAccuracy.CustomerSTFcstvsActualST
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="CustomerSTFcstvsActualST"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList('AlgoTotalSTFcstvsActualST')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.StAccuracy.AlgoTotalSTFcstvsActualST
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AlgoTotalSTFcstvsActualST"
                                />
                              </tr>
                              <tr
                                *ngIf="checkIfInList('COTotalSTFcstvsActualST')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.StAccuracy.COTotalSTFcstvsActualST
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="COTotalSTFcstvsActualST"
                                />
                              </tr>
                              <tr
                                *ngIf="checkIfInList('DPTotalSTFcstvsActualST')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.StAccuracy.DPTotalSTFcstvsActualST
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="DPTotalSTFcstvsActualST"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList('ConstrainedSTFcstvsActualST')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.StAccuracy.ConstrainedSTFcstvsActualST
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ConstrainedSTFcstvsActualST"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList('ConsensusSTFcstvsActualST')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.StAccuracy.ConsensusSTFcstvsActualST
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ConsensusSTFcstvsActualST"
                                />
                              </tr>
                              <!-- StAccuracy -->

                              <!-- SellIn -->
                              <tr
                                *ngIf="
                                  checkIfInList(
                                    'CustomerRequestedSellInReceiveWk'
                                  )
                                "
                              >
                                <input
                                  [disabled]="false"
                                  type="number"
                                  (change)="
                                    CustomerRequestedSellInReceiveWkUpdated(
                                      week
                                    )
                                  "
                                  [(ngModel)]="
                                    week.SellIn.CustomerRequestedSellInReceiveWk
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="CustomerRequestedSellInReceiveWk"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList('FinalSellInCommitReceiveWk')
                                "
                              >
                                <input
                                  [disabled]="false"
                                  type="number"
                                  [(ngModel)]="
                                    week.SellIn.FinalSellInCommitReceiveWk
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="FinalSellInCommitReceiveWk"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList(
                                    'FinalSellInCommitvartoCustomerRequest'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.SellIn
                                      .FinalSellInCommitvartoCustomerRequest
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="FinalSellInCommitvartoCustomerRequest"
                                />
                              </tr>
                              <tr
                                *ngIf="checkIfInList('AlgoSellInFcstReceiveWk')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.SellIn.AlgoSellInFcstReceiveWk
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AlgoSellInFcstReceiveWk"
                                />
                              </tr>
                              <tr
                                *ngIf="checkIfInList('DPSellInFcstReceiveWk')"
                              >
                                <input
                                  [disabled]="false"
                                  type="number"
                                  [(ngModel)]="
                                    week.SellIn.DPSellInFcstReceiveWk
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="DPSellInFcstReceiveWk"
                                />
                              </tr>
                              <tr
                                *ngIf="checkIfInList('COSellInFcstReceiveWk')"
                              >
                                <input
                                  [disabled]="false"
                                  type="number"
                                  [(ngModel)]="
                                    week.SellIn.COSellInFcstReceiveWk
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="COSellInFcstReceiveWk"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList('ConsensusSellInFcstReceiveWk')
                                "
                              >
                                <input
                                  [disabled]="false"
                                  type="number"
                                  [(ngModel)]="
                                    week.SellIn.ConsensusSellInFcstReceiveWk
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ConsensusSellInFcstReceiveWk"
                                />
                              </tr>
                              <tr
                                *ngIf="
                                  checkIfInList('FinalSellInCommitsShipWk')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.SellIn.FinalSellInCommitsShipWk
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="FinalSellInCommitsShipWk"
                                />
                              </tr>
                              <!-- SellIn -->

                              <!-- CustomerInventory -->
                              <tr
                                *ngIf="checkIfInList('CustomerIOHProjection')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.CustomerInventory.CustomerIOHProjection
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="CustomerIOHProjection"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('IOHProjection')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.CustomerInventory.IOHProjection
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="IOHProjection"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('CustomerFWOSCalc')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.CustomerInventory.CustomerFWOSCalc
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="CustomerFWOSCalc"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('FBFWOSCalc')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.CustomerInventory.FBFWOSCalc
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="FBFWOSCalc"
                                />
                              </tr>
                              <tr
                                *ngIf="checkIfInList('FWOSvartoCustomerTarget')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.CustomerInventory
                                      .FWOSvartoCustomerTarget
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="FWOSvartoCustomerTarget"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('FWOSvartoFBTarget')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.CustomerInventory.FWOSvartoFBTarget
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="FWOSvartoFBTarget"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('CustomerStoreOH')">
                                <input
                                  [disabled]="false"
                                  type="number"
                                  [(ngModel)]="
                                    week.CustomerInventory.CustomerStoreOH
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="CustomerStoreOH"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('CustomerDCOH')">
                                <input
                                  [disabled]="false"
                                  type="number"
                                  [(ngModel)]="
                                    week.CustomerInventory.CustomerDCOH
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="CustomerDCOH"
                                />
                              </tr>
                              <!-- CustomerInventory -->

                              <!-- FBInventory -->
                              <tr *ngIf="checkIfInList('FBTotalOnHand')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.FBInventory.FBTotalOnHand"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="FBTotalOnHand"
                                />
                              </tr>
                              <tr
                                *ngIf="checkIfInList('FBAvailableAllocation')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.FBInventory.FBAvailableAllocation
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="FBAvailableAllocation"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('OnOrder')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.FBInventory.OnOrder"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="OnOrder"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('InTransit')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.FBInventory.InTransit"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="InTransit"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('InventoryReceived')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.FBInventory.InventoryReceived
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="InventoryReceived"
                                />
                              </tr>
                              <!-- FBInventory -->

                              <!-- KPI -->
                              <tr
                                *ngIf="
                                  checkIfInList('OnTimeDeliveryPerformance')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.KPI.OnTimeDeliveryPerformance
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="OnTimeDeliveryPerformance"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('OnTimeInFull')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.KPI.OnTimeInFull"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="OnTimeInFull"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('CustomerChargebacks')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.KPI.CustomerChargebacks"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="CustomerChargebacks"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('StandardDeliveryPst')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.KPI.StandardDeliveryPst"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="StandardDeliveryPst"
                                />
                              </tr>
                              <tr *ngIf="checkIfInList('ExpeditedDeliveryPst')">
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.KPI.ExpeditedDeliveryPst"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ExpeditedDeliveryPst"
                                />
                              </tr>
                              <!-- KPI -->
                            </td>
                          </ng-template>
                        </tr>
                      </td>
                      <ng-container
                        [ngTemplateOutlet]="
                          !year.show && !half.show && !quarter.show
                            ? null
                            : quarterColumn
                        "
                        [ngTemplateOutletContext]="{
                          week: getObject(year, {}, quarter, {})
                        }"
                      >
                      </ng-container>
                    </tr>
                  </td>
                  <ng-container
                    [ngTemplateOutlet]="
                      !year.show && !half.show ? null : halfColumn
                    "
                    [ngTemplateOutletContext]="{
                      week: getObject(year, {}, {}, half)
                    }"
                  >
                  </ng-container>
                </tr>
              </td>
              <ng-container
                [ngTemplateOutlet]="!year.show ? null : yearColumn"
                [ngTemplateOutletContext]="{
                  week: getObject(year, {}, {}, {})
                }"
              >
              </ng-container>
            </tr>
          </td>
        </tr>
      </td>
      <!-- Data Table End-->
    </table>
  </div>
</div>
<ng-template #dialog let-data>
  <h1 mat-dialog-title>Show/Hide Measures</h1>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <mat-checkbox
      maxlength="20"
      type="number"
      [(ngModel)]="selectAll"
      (ngModelChange)="selectAllSelected($event)"
    >
      Select/Remove All
    </mat-checkbox>
    <button
      mat-raised-button
      class="btn btn-primary m-left"
      color="primary"
      (click)="onSave()"
    >
      Save & Close
    </button>
  </div>
  <div mat-dialog-content>
    <div style="display: flex; flex-direction: column">
      <ng-container *ngFor="let item of chooserList || []">
        <ng-container>
          <mat-checkbox maxlength="20" type="number" [(ngModel)]="item.checked">
            {{ item.name }}
          </mat-checkbox>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #dialogGraphFields let-data>
  <h1 mat-dialog-title>Show/Hide Measures</h1>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <mat-checkbox
      maxlength="20"
      type="number"
      [(ngModel)]="selectAll"
      (ngModelChange)="selectAllGraphSelected($event)"
    >
      Select/Remove All
    </mat-checkbox>
    <button
      mat-raised-button
      class="btn btn-primary m-left"
      color="primary"
      (click)="onSave()"
    >
      Save & Close
    </button>
  </div>
  <div mat-dialog-content>
    <div style="display: flex; flex-direction: column">
      <ng-container *ngFor="let item of graphChooserList || []">
        <ng-container>
          <mat-checkbox maxlength="20" type="number" [(ngModel)]="item.checked">
            {{ item.name }}
          </mat-checkbox>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #ExcelImport let-data>
  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <h1 mat-dialog-title>Import/Export</h1>
      <button
        mat-raised-button
        class="btn btn-primary"
        color="primary"
        (click)="exportToExcel()"
      >
        Export to Excel
      </button>
    </div>
    <div>
      <ngx-dropzone (change)="onSelect($event)">
        <ngx-dropzone-label>Drop files here!</ngx-dropzone-label>
        <ngx-dropzone-preview
          *ngFor="let f of files"
          [removable]="true"
          (removed)="onRemove(f)"
        >
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>
    <!-- <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview> -->
  </div>
</ng-template>
<!-- Month Cummulated Column Start-->
<ng-template #otherColumn let-week="week">
  <td style="text-align: center">
    <tr>
      <label style="height: 35px !important">&nbsp;</label>
    </tr>
    <!-- Algo -->
    <tr *ngIf="checkIfInList('BaselineSTForecast')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.BaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="BaselineSTForecast"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoPromoUnits')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoPromoUnits"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoPromoUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoLY')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcstvartoLY"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoLY"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoDPTotalSTFcst')">
      <input
        [ngModel]="week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst | percent"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoDPTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoCOTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoCOTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcstvartoConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <!-- Algo -->

    <!-- DemandPlanning -->
    <tr *ngIf="checkIfInList('DPBaselineSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPBaselineSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPManualFcstAdjustment')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.DemandPlanning.DPManualFcstAdjustment"
        [ngModelOptions]="{ standalone: true }"
        (change)="DPManualFcstAdjustmentUpdated(week)"
        id="DPManualFcstAdjustment"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPPromoUnits')">
      <input
        [disabled]="false"
        type="number"
        (change)="DPPromoUnitsUpdated(week)"
        [(ngModel)]="week.DemandPlanning.DPPromoUnits"
        [ngModelOptions]="{ standalone: true }"
        id="DPPromoUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPComments')">
      <input
        [disabled]="false"
        type="text"
        [(ngModel)]="week.DemandPlanning.DPComments"
        [ngModelOptions]="{ standalone: true }"
        id="DPComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoLY')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoLY"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoLY"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoCOTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoCOTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoCOTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoAlgoTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoAlgoTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoAlgoTotalSTFcst"
      />
    </tr>
    <!-- DemandPlanning -->

    <!-- ChannelOps -->
    <tr *ngIf="checkIfInList('COBaselineSTForecast')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COBaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTForecast"
      />
    </tr>
    <tr *ngIf="checkIfInList('COManualFcstAdjustment')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.ChannelOps.COManualFcstAdjustment"
        [ngModelOptions]="{ standalone: true }"
        (change)="COManualFcstAdjustmentUpdated(week)"
        id="COManualFcstAdjustment"
      />
    </tr>
    <tr *ngIf="checkIfInList('COPromoUnits')">
      <input
        [disabled]="false"
        type="number"
        (change)="COPromoUnitsUpdated(week)"
        [(ngModel)]="week.ChannelOps.COPromoUnits"
        [ngModelOptions]="{ standalone: true }"
        id="COPromoUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('COComments')">
      <input
        [disabled]="false"
        type="text"
        [(ngModel)]="week.ChannelOps.COComments"
        [ngModelOptions]="{ standalone: true }"
        id="COComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoLY')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoLY"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoLY"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoDPTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoDPTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoDPTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoAlgoTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoAlgoTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoAlgoTotalSTFcst"
      />
    </tr>

    <!-- ChannelOps -->

    <!-- CurrentSellThrough -->
    <tr *ngIf="checkIfInList('CustomerSTFcst')">
      <input
        [disabled]="false"
        type="number"
        (change)="CustomerSTFcstUpdated(week)"
        [(ngModel)]="week.CurrentSellThrough.CustomerSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSTFcst')">
      <input
        [disabled]="false"
        type="number"
        (change)="ConsensusSTFcstUpdated(week)"
        [(ngModel)]="week.CurrentSellThrough.ConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSTFcstvartoCustomerSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSTFcstvartoCustomerSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConstrainedSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ConstrainedSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="ConstrainedSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConstrainedSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst
        "
        [ngModelOptions]="{ standalone: true }"
        id="ConstrainedSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ProjectedLostSales')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ProjectedLostSales"
        [ngModelOptions]="{ standalone: true }"
        id="ProjectedLostSales"
      />
    </tr>
    <tr *ngIf="checkIfInList('ActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ActualST"
        [ngModelOptions]="{ standalone: true }"
        id="ActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('WeeklyTrend')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.WeeklyTrend"
        [ngModelOptions]="{ standalone: true }"
        id="WeeklyTrend"
      />
    </tr>
    <tr *ngIf="checkIfInList('PromoIndicator')">
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.PromoIndicator"
        [ngModelOptions]="{ standalone: true }"
        id="PromoIndicator"
      />
    </tr>
    <tr *ngIf="checkIfInList('CPFRComments')">
      <input
        [disabled]="false"
        type="text"
        [(ngModel)]="week.CurrentSellThrough.CPFRComments"
        [ngModelOptions]="{ standalone: true }"
        id="CPFRComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerInStockEstimate')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.CurrentSellThrough.CustomerInStockEstimate"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerInStockEstimate"
      />
    </tr>
    <tr *ngIf="checkIfInList('ActualVartoConsensusFcstUnits')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ActualVartoConsensusFcstUnits"
        [ngModelOptions]="{ standalone: true }"
        id="ActualVartoConsensusFcstUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('ActualVartoConsensusFcstPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ActualVartoConsensusFcstPst"
        [ngModelOptions]="{ standalone: true }"
        id="ActualVartoConsensusFcstPst"
      />
    </tr>
    <!-- CurrentSellThrough -->

    <!-- LastYearSales -->
    <tr *ngIf="checkIfInList('LYActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYActualST"
        [ngModelOptions]="{ standalone: true }"
        id="LYActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYPromoIndicator')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYPromoIndicator"
        [ngModelOptions]="{ standalone: true }"
        id="LYPromoIndicator"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYCOComments')">
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYCOComments"
        [ngModelOptions]="{ standalone: true }"
        id="LYCOComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYCustomerInStockEstimate')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYCustomerInStockEstimate"
        [ngModelOptions]="{ standalone: true }"
        id="LYCustomerInStockEstimate"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYConsensusFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYConsensusFcst"
        [ngModelOptions]="{ standalone: true }"
        id="LYConsensusFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYActualtoConsensusFcstVarPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYActualtoConsensusFcstVarPst"
        [ngModelOptions]="{ standalone: true }"
        id="LYActualtoConsensusFcstVarPst"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYActualtoConsensusFcstVarUnits')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYActualtoConsensusFcstVarUnits"
        [ngModelOptions]="{ standalone: true }"
        id="LYActualtoConsensusFcstVarUnits"
      />
    </tr>
    <!-- LastYearSales -->

    <!-- StAccuracy -->
    <tr *ngIf="checkIfInList('CustomerSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.CustomerSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.AlgoTotalSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.COTotalSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.DPTotalSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConstrainedSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.ConstrainedSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="ConstrainedSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.ConsensusSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSTFcstvsActualST"
      />
    </tr>
    <!-- StAccuracy -->

    <!-- SellIn -->
    <tr *ngIf="checkIfInList('CustomerRequestedSellInReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        (change)="CustomerRequestedSellInReceiveWkUpdated(week)"
        [(ngModel)]="week.SellIn.CustomerRequestedSellInReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerRequestedSellInReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('FinalSellInCommitReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.FinalSellInCommitReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="FinalSellInCommitReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('FinalSellInCommitvartoCustomerRequest')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.SellIn.FinalSellInCommitvartoCustomerRequest"
        [ngModelOptions]="{ standalone: true }"
        id="FinalSellInCommitvartoCustomerRequest"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoSellInFcstReceiveWk')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.SellIn.AlgoSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPSellInFcstReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.DPSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="DPSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('COSellInFcstReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.COSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="COSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSellInFcstReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.ConsensusSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('FinalSellInCommitsShipWk')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.SellIn.FinalSellInCommitsShipWk"
        [ngModelOptions]="{ standalone: true }"
        id="FinalSellInCommitsShipWk"
      />
    </tr>
    <!-- SellIn -->

    <!-- CustomerInventory -->
    <tr *ngIf="checkIfInList('CustomerIOHProjection')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.CustomerIOHProjection"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerIOHProjection"
      />
    </tr>
    <tr *ngIf="checkIfInList('IOHProjection')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.IOHProjection"
        [ngModelOptions]="{ standalone: true }"
        id="IOHProjection"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerFWOSCalc')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.CustomerFWOSCalc"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerFWOSCalc"
      />
    </tr>
    <tr *ngIf="checkIfInList('FBFWOSCalc')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.FBFWOSCalc"
        [ngModelOptions]="{ standalone: true }"
        id="FBFWOSCalc"
      />
    </tr>
    <tr *ngIf="checkIfInList('FWOSvartoCustomerTarget')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.FWOSvartoCustomerTarget"
        [ngModelOptions]="{ standalone: true }"
        id="FWOSvartoCustomerTarget"
      />
    </tr>
    <tr *ngIf="checkIfInList('FWOSvartoFBTarget')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.FWOSvartoFBTarget"
        [ngModelOptions]="{ standalone: true }"
        id="FWOSvartoFBTarget"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerStoreOH')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.CustomerInventory.CustomerStoreOH"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerStoreOH"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerDCOH')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.CustomerInventory.CustomerDCOH"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerDCOH"
      />
    </tr>
    <!-- CustomerInventory -->

    <!-- FBInventory -->
    <tr *ngIf="checkIfInList('FBTotalOnHand')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.FBTotalOnHand"
        [ngModelOptions]="{ standalone: true }"
        id="FBTotalOnHand"
      />
    </tr>
    <tr *ngIf="checkIfInList('FBAvailableAllocation')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.FBAvailableAllocation"
        [ngModelOptions]="{ standalone: true }"
        id="FBAvailableAllocation"
      />
    </tr>
    <tr *ngIf="checkIfInList('OnOrder')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.OnOrder"
        [ngModelOptions]="{ standalone: true }"
        id="OnOrder"
      />
    </tr>
    <tr *ngIf="checkIfInList('InTransit')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.InTransit"
        [ngModelOptions]="{ standalone: true }"
        id="InTransit"
      />
    </tr>
    <tr *ngIf="checkIfInList('InventoryReceived')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.InventoryReceived"
        [ngModelOptions]="{ standalone: true }"
        id="InventoryReceived"
      />
    </tr>
    <!-- FBInventory -->

    <!-- KPI -->
    <tr *ngIf="checkIfInList('OnTimeDeliveryPerformance')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.OnTimeDeliveryPerformance"
        [ngModelOptions]="{ standalone: true }"
        id="OnTimeDeliveryPerformance"
      />
    </tr>
    <tr *ngIf="checkIfInList('OnTimeInFull')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.OnTimeInFull"
        [ngModelOptions]="{ standalone: true }"
        id="OnTimeInFull"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerChargebacks')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.CustomerChargebacks"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerChargebacks"
      />
    </tr>
    <tr *ngIf="checkIfInList('StandardDeliveryPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.StandardDeliveryPst"
        [ngModelOptions]="{ standalone: true }"
        id="StandardDeliveryPst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ExpeditedDeliveryPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.ExpeditedDeliveryPst"
        [ngModelOptions]="{ standalone: true }"
        id="ExpeditedDeliveryPst"
      />
    </tr>
    <!-- KPI -->
  </td>
</ng-template>
<!-- Month Cummulated Column End -->

<!-- Quarter Cummulated Column Start -->

<ng-template #quarterColumn let-week="week">
  <td style="text-align: center">
    <tr>
      <label style="height: 35px !important">&nbsp;</label>
    </tr>
    <tr>
      <label style="height: 35px !important">&nbsp;</label>
    </tr>
    <!-- Algo -->
    <tr *ngIf="checkIfInList('BaselineSTForecast')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.BaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="BaselineSTForecast"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoPromoUnits')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoPromoUnits"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoPromoUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoLY')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcstvartoLY"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoLY"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoDPTotalSTFcst')">
      <input
        [ngModel]="week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst | percent"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoDPTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoCOTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoCOTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcstvartoConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <!-- Algo -->

    <!-- DemandPlanning -->
    <tr *ngIf="checkIfInList('DPBaselineSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPBaselineSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPManualFcstAdjustment')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.DemandPlanning.DPManualFcstAdjustment"
        [ngModelOptions]="{ standalone: true }"
        (change)="DPManualFcstAdjustmentUpdated(week)"
        id="DPManualFcstAdjustment"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPPromoUnits')">
      <input
        [disabled]="false"
        type="number"
        (change)="DPPromoUnitsUpdated(week)"
        [(ngModel)]="week.DemandPlanning.DPPromoUnits"
        [ngModelOptions]="{ standalone: true }"
        id="DPPromoUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPComments')">
      <input
        [disabled]="false"
        type="text"
        [(ngModel)]="week.DemandPlanning.DPComments"
        [ngModelOptions]="{ standalone: true }"
        id="DPComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoLY')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoLY"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoLY"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoCOTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoCOTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoCOTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoAlgoTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoAlgoTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoAlgoTotalSTFcst"
      />
    </tr>
    <!-- DemandPlanning -->

    <!-- ChannelOps -->
    <tr *ngIf="checkIfInList('COBaselineSTForecast')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COBaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTForecast"
      />
    </tr>
    <tr *ngIf="checkIfInList('COManualFcstAdjustment')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.ChannelOps.COManualFcstAdjustment"
        [ngModelOptions]="{ standalone: true }"
        (change)="COManualFcstAdjustmentUpdated(week)"
        id="COManualFcstAdjustment"
      />
    </tr>
    <tr *ngIf="checkIfInList('COPromoUnits')">
      <input
        [disabled]="false"
        type="number"
        (change)="COPromoUnitsUpdated(week)"
        [(ngModel)]="week.ChannelOps.COPromoUnits"
        [ngModelOptions]="{ standalone: true }"
        id="COPromoUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('COComments')">
      <input
        [disabled]="false"
        type="text"
        [(ngModel)]="week.ChannelOps.COComments"
        [ngModelOptions]="{ standalone: true }"
        id="COComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoLY')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoLY"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoLY"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoDPTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoDPTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoDPTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoAlgoTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoAlgoTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoAlgoTotalSTFcst"
      />
    </tr>

    <!-- ChannelOps -->

    <!-- CurrentSellThrough -->
    <tr *ngIf="checkIfInList('CustomerSTFcst')">
      <input
        [disabled]="false"
        type="number"
        (change)="CustomerSTFcstUpdated(week)"
        [(ngModel)]="week.CurrentSellThrough.CustomerSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSTFcst')">
      <input
        [disabled]="false"
        type="number"
        (change)="ConsensusSTFcstUpdated(week)"
        [(ngModel)]="week.CurrentSellThrough.ConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSTFcstvartoCustomerSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSTFcstvartoCustomerSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConstrainedSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ConstrainedSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="ConstrainedSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConstrainedSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst
        "
        [ngModelOptions]="{ standalone: true }"
        id="ConstrainedSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ProjectedLostSales')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ProjectedLostSales"
        [ngModelOptions]="{ standalone: true }"
        id="ProjectedLostSales"
      />
    </tr>
    <tr *ngIf="checkIfInList('ActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ActualST"
        [ngModelOptions]="{ standalone: true }"
        id="ActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('WeeklyTrend')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.WeeklyTrend"
        [ngModelOptions]="{ standalone: true }"
        id="WeeklyTrend"
      />
    </tr>
    <tr *ngIf="checkIfInList('PromoIndicator')">
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.PromoIndicator"
        [ngModelOptions]="{ standalone: true }"
        id="PromoIndicator"
      />
    </tr>
    <tr *ngIf="checkIfInList('CPFRComments')">
      <input
        [disabled]="false"
        type="text"
        [(ngModel)]="week.CurrentSellThrough.CPFRComments"
        [ngModelOptions]="{ standalone: true }"
        id="CPFRComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerInStockEstimate')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.CurrentSellThrough.CustomerInStockEstimate"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerInStockEstimate"
      />
    </tr>
    <tr *ngIf="checkIfInList('ActualVartoConsensusFcstUnits')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ActualVartoConsensusFcstUnits"
        [ngModelOptions]="{ standalone: true }"
        id="ActualVartoConsensusFcstUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('ActualVartoConsensusFcstPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ActualVartoConsensusFcstPst"
        [ngModelOptions]="{ standalone: true }"
        id="ActualVartoConsensusFcstPst"
      />
    </tr>
    <!-- CurrentSellThrough -->

    <!-- LastYearSales -->
    <tr *ngIf="checkIfInList('LYActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYActualST"
        [ngModelOptions]="{ standalone: true }"
        id="LYActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYPromoIndicator')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYPromoIndicator"
        [ngModelOptions]="{ standalone: true }"
        id="LYPromoIndicator"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYCOComments')">
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYCOComments"
        [ngModelOptions]="{ standalone: true }"
        id="LYCOComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYCustomerInStockEstimate')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYCustomerInStockEstimate"
        [ngModelOptions]="{ standalone: true }"
        id="LYCustomerInStockEstimate"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYConsensusFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYConsensusFcst"
        [ngModelOptions]="{ standalone: true }"
        id="LYConsensusFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYActualtoConsensusFcstVarPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYActualtoConsensusFcstVarPst"
        [ngModelOptions]="{ standalone: true }"
        id="LYActualtoConsensusFcstVarPst"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYActualtoConsensusFcstVarUnits')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYActualtoConsensusFcstVarUnits"
        [ngModelOptions]="{ standalone: true }"
        id="LYActualtoConsensusFcstVarUnits"
      />
    </tr>
    <!-- LastYearSales -->

    <!-- StAccuracy -->
    <tr *ngIf="checkIfInList('CustomerSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.CustomerSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.AlgoTotalSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.COTotalSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.DPTotalSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConstrainedSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.ConstrainedSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="ConstrainedSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.ConsensusSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSTFcstvsActualST"
      />
    </tr>
    <!-- StAccuracy -->

    <!-- SellIn -->
    <tr *ngIf="checkIfInList('CustomerRequestedSellInReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        (change)="CustomerRequestedSellInReceiveWkUpdated(week)"
        [(ngModel)]="week.SellIn.CustomerRequestedSellInReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerRequestedSellInReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('FinalSellInCommitReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.FinalSellInCommitReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="FinalSellInCommitReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('FinalSellInCommitvartoCustomerRequest')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.SellIn.FinalSellInCommitvartoCustomerRequest"
        [ngModelOptions]="{ standalone: true }"
        id="FinalSellInCommitvartoCustomerRequest"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoSellInFcstReceiveWk')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.SellIn.AlgoSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPSellInFcstReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.DPSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="DPSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('COSellInFcstReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.COSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="COSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSellInFcstReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.ConsensusSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('FinalSellInCommitsShipWk')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.SellIn.FinalSellInCommitsShipWk"
        [ngModelOptions]="{ standalone: true }"
        id="FinalSellInCommitsShipWk"
      />
    </tr>
    <!-- SellIn -->

    <!-- CustomerInventory -->
    <tr *ngIf="checkIfInList('CustomerIOHProjection')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.CustomerIOHProjection"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerIOHProjection"
      />
    </tr>
    <tr *ngIf="checkIfInList('IOHProjection')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.IOHProjection"
        [ngModelOptions]="{ standalone: true }"
        id="IOHProjection"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerFWOSCalc')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.CustomerFWOSCalc"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerFWOSCalc"
      />
    </tr>
    <tr *ngIf="checkIfInList('FBFWOSCalc')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.FBFWOSCalc"
        [ngModelOptions]="{ standalone: true }"
        id="FBFWOSCalc"
      />
    </tr>
    <tr *ngIf="checkIfInList('FWOSvartoCustomerTarget')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.FWOSvartoCustomerTarget"
        [ngModelOptions]="{ standalone: true }"
        id="FWOSvartoCustomerTarget"
      />
    </tr>
    <tr *ngIf="checkIfInList('FWOSvartoFBTarget')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.FWOSvartoFBTarget"
        [ngModelOptions]="{ standalone: true }"
        id="FWOSvartoFBTarget"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerStoreOH')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.CustomerInventory.CustomerStoreOH"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerStoreOH"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerDCOH')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.CustomerInventory.CustomerDCOH"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerDCOH"
      />
    </tr>
    <!-- CustomerInventory -->

    <!-- FBInventory -->
    <tr *ngIf="checkIfInList('FBTotalOnHand')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.FBTotalOnHand"
        [ngModelOptions]="{ standalone: true }"
        id="FBTotalOnHand"
      />
    </tr>
    <tr *ngIf="checkIfInList('FBAvailableAllocation')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.FBAvailableAllocation"
        [ngModelOptions]="{ standalone: true }"
        id="FBAvailableAllocation"
      />
    </tr>
    <tr *ngIf="checkIfInList('OnOrder')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.OnOrder"
        [ngModelOptions]="{ standalone: true }"
        id="OnOrder"
      />
    </tr>
    <tr *ngIf="checkIfInList('InTransit')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.InTransit"
        [ngModelOptions]="{ standalone: true }"
        id="InTransit"
      />
    </tr>
    <tr *ngIf="checkIfInList('InventoryReceived')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.InventoryReceived"
        [ngModelOptions]="{ standalone: true }"
        id="InventoryReceived"
      />
    </tr>
    <!-- FBInventory -->

    <!-- KPI -->
    <tr *ngIf="checkIfInList('OnTimeDeliveryPerformance')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.OnTimeDeliveryPerformance"
        [ngModelOptions]="{ standalone: true }"
        id="OnTimeDeliveryPerformance"
      />
    </tr>
    <tr *ngIf="checkIfInList('OnTimeInFull')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.OnTimeInFull"
        [ngModelOptions]="{ standalone: true }"
        id="OnTimeInFull"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerChargebacks')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.CustomerChargebacks"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerChargebacks"
      />
    </tr>
    <tr *ngIf="checkIfInList('StandardDeliveryPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.StandardDeliveryPst"
        [ngModelOptions]="{ standalone: true }"
        id="StandardDeliveryPst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ExpeditedDeliveryPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.ExpeditedDeliveryPst"
        [ngModelOptions]="{ standalone: true }"
        id="ExpeditedDeliveryPst"
      />
    </tr>
    <!-- KPI -->
  </td>
</ng-template>
<!-- Quarter Cummulated Column End -->

<!-- Half Cummulated Column Start -->
<ng-template #halfColumn let-week="week">
  <td style="text-align: center">
    <tr>
      <label style="height: 35px !important">&nbsp;</label>
    </tr>
    <tr>
      <label style="height: 35px !important">&nbsp;</label>
    </tr>
    <tr>
      <label style="height: 35px !important">&nbsp;</label>
    </tr>
    <!-- Algo -->
    <tr *ngIf="checkIfInList('BaselineSTForecast')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.BaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="BaselineSTForecast"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoPromoUnits')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoPromoUnits"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoPromoUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoLY')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcstvartoLY"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoLY"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoDPTotalSTFcst')">
      <input
        [ngModel]="week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst | percent"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoDPTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoCOTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoCOTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcstvartoConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <!-- Algo -->

    <!-- DemandPlanning -->
    <tr *ngIf="checkIfInList('DPBaselineSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPBaselineSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPManualFcstAdjustment')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.DemandPlanning.DPManualFcstAdjustment"
        [ngModelOptions]="{ standalone: true }"
        (change)="DPManualFcstAdjustmentUpdated(week)"
        id="DPManualFcstAdjustment"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPPromoUnits')">
      <input
        [disabled]="false"
        type="number"
        (change)="DPPromoUnitsUpdated(week)"
        [(ngModel)]="week.DemandPlanning.DPPromoUnits"
        [ngModelOptions]="{ standalone: true }"
        id="DPPromoUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPComments')">
      <input
        [disabled]="false"
        type="text"
        [(ngModel)]="week.DemandPlanning.DPComments"
        [ngModelOptions]="{ standalone: true }"
        id="DPComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoLY')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoLY"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoLY"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoCOTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoCOTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoCOTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoAlgoTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoAlgoTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoAlgoTotalSTFcst"
      />
    </tr>
    <!-- DemandPlanning -->

    <!-- ChannelOps -->
    <tr *ngIf="checkIfInList('COBaselineSTForecast')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COBaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTForecast"
      />
    </tr>
    <tr *ngIf="checkIfInList('COManualFcstAdjustment')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.ChannelOps.COManualFcstAdjustment"
        [ngModelOptions]="{ standalone: true }"
        (change)="COManualFcstAdjustmentUpdated(week)"
        id="COManualFcstAdjustment"
      />
    </tr>
    <tr *ngIf="checkIfInList('COPromoUnits')">
      <input
        [disabled]="false"
        type="number"
        (change)="COPromoUnitsUpdated(week)"
        [(ngModel)]="week.ChannelOps.COPromoUnits"
        [ngModelOptions]="{ standalone: true }"
        id="COPromoUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('COComments')">
      <input
        [disabled]="false"
        type="text"
        [(ngModel)]="week.ChannelOps.COComments"
        [ngModelOptions]="{ standalone: true }"
        id="COComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoLY')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoLY"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoLY"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoDPTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoDPTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoDPTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoAlgoTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoAlgoTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoAlgoTotalSTFcst"
      />
    </tr>

    <!-- ChannelOps -->

    <!-- CurrentSellThrough -->
    <tr *ngIf="checkIfInList('CustomerSTFcst')">
      <input
        [disabled]="false"
        type="number"
        (change)="CustomerSTFcstUpdated(week)"
        [(ngModel)]="week.CurrentSellThrough.CustomerSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSTFcst')">
      <input
        [disabled]="false"
        type="number"
        (change)="ConsensusSTFcstUpdated(week)"
        [(ngModel)]="week.CurrentSellThrough.ConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSTFcstvartoCustomerSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSTFcstvartoCustomerSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConstrainedSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ConstrainedSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="ConstrainedSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConstrainedSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst
        "
        [ngModelOptions]="{ standalone: true }"
        id="ConstrainedSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ProjectedLostSales')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ProjectedLostSales"
        [ngModelOptions]="{ standalone: true }"
        id="ProjectedLostSales"
      />
    </tr>
    <tr *ngIf="checkIfInList('ActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ActualST"
        [ngModelOptions]="{ standalone: true }"
        id="ActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('WeeklyTrend')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.WeeklyTrend"
        [ngModelOptions]="{ standalone: true }"
        id="WeeklyTrend"
      />
    </tr>
    <tr *ngIf="checkIfInList('PromoIndicator')">
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.PromoIndicator"
        [ngModelOptions]="{ standalone: true }"
        id="PromoIndicator"
      />
    </tr>
    <tr *ngIf="checkIfInList('CPFRComments')">
      <input
        [disabled]="false"
        type="text"
        [(ngModel)]="week.CurrentSellThrough.CPFRComments"
        [ngModelOptions]="{ standalone: true }"
        id="CPFRComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerInStockEstimate')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.CurrentSellThrough.CustomerInStockEstimate"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerInStockEstimate"
      />
    </tr>
    <tr *ngIf="checkIfInList('ActualVartoConsensusFcstUnits')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ActualVartoConsensusFcstUnits"
        [ngModelOptions]="{ standalone: true }"
        id="ActualVartoConsensusFcstUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('ActualVartoConsensusFcstPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ActualVartoConsensusFcstPst"
        [ngModelOptions]="{ standalone: true }"
        id="ActualVartoConsensusFcstPst"
      />
    </tr>
    <!-- CurrentSellThrough -->

    <!-- LastYearSales -->
    <tr *ngIf="checkIfInList('LYActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYActualST"
        [ngModelOptions]="{ standalone: true }"
        id="LYActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYPromoIndicator')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYPromoIndicator"
        [ngModelOptions]="{ standalone: true }"
        id="LYPromoIndicator"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYCOComments')">
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYCOComments"
        [ngModelOptions]="{ standalone: true }"
        id="LYCOComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYCustomerInStockEstimate')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYCustomerInStockEstimate"
        [ngModelOptions]="{ standalone: true }"
        id="LYCustomerInStockEstimate"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYConsensusFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYConsensusFcst"
        [ngModelOptions]="{ standalone: true }"
        id="LYConsensusFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYActualtoConsensusFcstVarPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYActualtoConsensusFcstVarPst"
        [ngModelOptions]="{ standalone: true }"
        id="LYActualtoConsensusFcstVarPst"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYActualtoConsensusFcstVarUnits')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYActualtoConsensusFcstVarUnits"
        [ngModelOptions]="{ standalone: true }"
        id="LYActualtoConsensusFcstVarUnits"
      />
    </tr>
    <!-- LastYearSales -->

    <!-- StAccuracy -->
    <tr *ngIf="checkIfInList('CustomerSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.CustomerSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.AlgoTotalSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.COTotalSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.DPTotalSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConstrainedSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.ConstrainedSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="ConstrainedSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.ConsensusSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSTFcstvsActualST"
      />
    </tr>
    <!-- StAccuracy -->

    <!-- SellIn -->
    <tr *ngIf="checkIfInList('CustomerRequestedSellInReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        (change)="CustomerRequestedSellInReceiveWkUpdated(week)"
        [(ngModel)]="week.SellIn.CustomerRequestedSellInReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerRequestedSellInReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('FinalSellInCommitReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.FinalSellInCommitReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="FinalSellInCommitReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('FinalSellInCommitvartoCustomerRequest')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.SellIn.FinalSellInCommitvartoCustomerRequest"
        [ngModelOptions]="{ standalone: true }"
        id="FinalSellInCommitvartoCustomerRequest"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoSellInFcstReceiveWk')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.SellIn.AlgoSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPSellInFcstReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.DPSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="DPSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('COSellInFcstReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.COSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="COSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSellInFcstReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.ConsensusSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('FinalSellInCommitsShipWk')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.SellIn.FinalSellInCommitsShipWk"
        [ngModelOptions]="{ standalone: true }"
        id="FinalSellInCommitsShipWk"
      />
    </tr>
    <!-- SellIn -->

    <!-- CustomerInventory -->
    <tr *ngIf="checkIfInList('CustomerIOHProjection')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.CustomerIOHProjection"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerIOHProjection"
      />
    </tr>
    <tr *ngIf="checkIfInList('IOHProjection')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.IOHProjection"
        [ngModelOptions]="{ standalone: true }"
        id="IOHProjection"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerFWOSCalc')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.CustomerFWOSCalc"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerFWOSCalc"
      />
    </tr>
    <tr *ngIf="checkIfInList('FBFWOSCalc')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.FBFWOSCalc"
        [ngModelOptions]="{ standalone: true }"
        id="FBFWOSCalc"
      />
    </tr>
    <tr *ngIf="checkIfInList('FWOSvartoCustomerTarget')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.FWOSvartoCustomerTarget"
        [ngModelOptions]="{ standalone: true }"
        id="FWOSvartoCustomerTarget"
      />
    </tr>
    <tr *ngIf="checkIfInList('FWOSvartoFBTarget')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.FWOSvartoFBTarget"
        [ngModelOptions]="{ standalone: true }"
        id="FWOSvartoFBTarget"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerStoreOH')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.CustomerInventory.CustomerStoreOH"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerStoreOH"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerDCOH')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.CustomerInventory.CustomerDCOH"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerDCOH"
      />
    </tr>
    <!-- CustomerInventory -->

    <!-- FBInventory -->
    <tr *ngIf="checkIfInList('FBTotalOnHand')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.FBTotalOnHand"
        [ngModelOptions]="{ standalone: true }"
        id="FBTotalOnHand"
      />
    </tr>
    <tr *ngIf="checkIfInList('FBAvailableAllocation')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.FBAvailableAllocation"
        [ngModelOptions]="{ standalone: true }"
        id="FBAvailableAllocation"
      />
    </tr>
    <tr *ngIf="checkIfInList('OnOrder')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.OnOrder"
        [ngModelOptions]="{ standalone: true }"
        id="OnOrder"
      />
    </tr>
    <tr *ngIf="checkIfInList('InTransit')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.InTransit"
        [ngModelOptions]="{ standalone: true }"
        id="InTransit"
      />
    </tr>
    <tr *ngIf="checkIfInList('InventoryReceived')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.InventoryReceived"
        [ngModelOptions]="{ standalone: true }"
        id="InventoryReceived"
      />
    </tr>
    <!-- FBInventory -->

    <!-- KPI -->
    <tr *ngIf="checkIfInList('OnTimeDeliveryPerformance')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.OnTimeDeliveryPerformance"
        [ngModelOptions]="{ standalone: true }"
        id="OnTimeDeliveryPerformance"
      />
    </tr>
    <tr *ngIf="checkIfInList('OnTimeInFull')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.OnTimeInFull"
        [ngModelOptions]="{ standalone: true }"
        id="OnTimeInFull"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerChargebacks')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.CustomerChargebacks"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerChargebacks"
      />
    </tr>
    <tr *ngIf="checkIfInList('StandardDeliveryPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.StandardDeliveryPst"
        [ngModelOptions]="{ standalone: true }"
        id="StandardDeliveryPst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ExpeditedDeliveryPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.ExpeditedDeliveryPst"
        [ngModelOptions]="{ standalone: true }"
        id="ExpeditedDeliveryPst"
      />
    </tr>
    <!-- KPI -->
  </td>
</ng-template>
<!-- Half Cummulated Column End -->

<!-- Year Cummulated Column Start -->
<ng-template #yearColumn let-week="week">
  <td style="text-align: center">
    <tr>
      <label style="height: 35px !important">&nbsp;</label>
    </tr>
    <tr>
      <label style="height: 35px !important">&nbsp;</label>
    </tr>
    <tr>
      <label style="height: 35px !important">&nbsp;</label>
    </tr>
    <tr>
      <label style="height: 35px !important">&nbsp;</label>
    </tr>
    <!-- Algo -->
    <tr *ngIf="checkIfInList('BaselineSTForecast')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.BaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="BaselineSTForecast"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoPromoUnits')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoPromoUnits"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoPromoUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoLY')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcstvartoLY"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoLY"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoDPTotalSTFcst')">
      <input
        [ngModel]="week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst | percent"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoDPTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoCOTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoCOTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.Algo.AlgoTotalSTFcstvartoConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <!-- Algo -->

    <!-- DemandPlanning -->
    <tr *ngIf="checkIfInList('DPBaselineSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPBaselineSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPManualFcstAdjustment')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.DemandPlanning.DPManualFcstAdjustment"
        [ngModelOptions]="{ standalone: true }"
        (change)="DPManualFcstAdjustmentUpdated(week)"
        id="DPManualFcstAdjustment"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPPromoUnits')">
      <input
        [disabled]="false"
        type="number"
        (change)="DPPromoUnitsUpdated(week)"
        [(ngModel)]="week.DemandPlanning.DPPromoUnits"
        [ngModelOptions]="{ standalone: true }"
        id="DPPromoUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPComments')">
      <input
        [disabled]="false"
        type="text"
        [(ngModel)]="week.DemandPlanning.DPComments"
        [ngModelOptions]="{ standalone: true }"
        id="DPComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoLY')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoLY"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoLY"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoCOTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoCOTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoCOTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvartoAlgoTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.DemandPlanning.DPTotalSTFcstvartoAlgoTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvartoAlgoTotalSTFcst"
      />
    </tr>
    <!-- DemandPlanning -->

    <!-- ChannelOps -->
    <tr *ngIf="checkIfInList('COBaselineSTForecast')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COBaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTForecast"
      />
    </tr>
    <tr *ngIf="checkIfInList('COManualFcstAdjustment')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.ChannelOps.COManualFcstAdjustment"
        [ngModelOptions]="{ standalone: true }"
        (change)="COManualFcstAdjustmentUpdated(week)"
        id="COManualFcstAdjustment"
      />
    </tr>
    <tr *ngIf="checkIfInList('COPromoUnits')">
      <input
        [disabled]="false"
        type="number"
        (change)="COPromoUnitsUpdated(week)"
        [(ngModel)]="week.ChannelOps.COPromoUnits"
        [ngModelOptions]="{ standalone: true }"
        id="COPromoUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('COComments')">
      <input
        [disabled]="false"
        type="text"
        [(ngModel)]="week.ChannelOps.COComments"
        [ngModelOptions]="{ standalone: true }"
        id="COComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoLY')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoLY"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoLY"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoDPTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoDPTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoDPTotalSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvartoAlgoTotalSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ChannelOps.COTotalSTFcstvartoAlgoTotalSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvartoAlgoTotalSTFcst"
      />
    </tr>

    <!-- ChannelOps -->

    <!-- CurrentSellThrough -->
    <tr *ngIf="checkIfInList('CustomerSTFcst')">
      <input
        [disabled]="false"
        type="number"
        (change)="CustomerSTFcstUpdated(week)"
        [(ngModel)]="week.CurrentSellThrough.CustomerSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSTFcst')">
      <input
        [disabled]="false"
        type="number"
        (change)="ConsensusSTFcstUpdated(week)"
        [(ngModel)]="week.CurrentSellThrough.ConsensusSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSTFcstvartoCustomerSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSTFcstvartoCustomerSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConstrainedSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ConstrainedSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="ConstrainedSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConstrainedSTFcstvartoConsensusSTFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst
        "
        [ngModelOptions]="{ standalone: true }"
        id="ConstrainedSTFcstvartoConsensusSTFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ProjectedLostSales')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ProjectedLostSales"
        [ngModelOptions]="{ standalone: true }"
        id="ProjectedLostSales"
      />
    </tr>
    <tr *ngIf="checkIfInList('ActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ActualST"
        [ngModelOptions]="{ standalone: true }"
        id="ActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('WeeklyTrend')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.WeeklyTrend"
        [ngModelOptions]="{ standalone: true }"
        id="WeeklyTrend"
      />
    </tr>
    <tr *ngIf="checkIfInList('PromoIndicator')">
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.PromoIndicator"
        [ngModelOptions]="{ standalone: true }"
        id="PromoIndicator"
      />
    </tr>
    <tr *ngIf="checkIfInList('CPFRComments')">
      <input
        [disabled]="false"
        type="text"
        [(ngModel)]="week.CurrentSellThrough.CPFRComments"
        [ngModelOptions]="{ standalone: true }"
        id="CPFRComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerInStockEstimate')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.CurrentSellThrough.CustomerInStockEstimate"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerInStockEstimate"
      />
    </tr>
    <tr *ngIf="checkIfInList('ActualVartoConsensusFcstUnits')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ActualVartoConsensusFcstUnits"
        [ngModelOptions]="{ standalone: true }"
        id="ActualVartoConsensusFcstUnits"
      />
    </tr>
    <tr *ngIf="checkIfInList('ActualVartoConsensusFcstPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CurrentSellThrough.ActualVartoConsensusFcstPst"
        [ngModelOptions]="{ standalone: true }"
        id="ActualVartoConsensusFcstPst"
      />
    </tr>
    <!-- CurrentSellThrough -->

    <!-- LastYearSales -->
    <tr *ngIf="checkIfInList('LYActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYActualST"
        [ngModelOptions]="{ standalone: true }"
        id="LYActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYPromoIndicator')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYPromoIndicator"
        [ngModelOptions]="{ standalone: true }"
        id="LYPromoIndicator"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYCOComments')">
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYCOComments"
        [ngModelOptions]="{ standalone: true }"
        id="LYCOComments"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYCustomerInStockEstimate')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYCustomerInStockEstimate"
        [ngModelOptions]="{ standalone: true }"
        id="LYCustomerInStockEstimate"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYConsensusFcst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYConsensusFcst"
        [ngModelOptions]="{ standalone: true }"
        id="LYConsensusFcst"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYActualtoConsensusFcstVarPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYActualtoConsensusFcstVarPst"
        [ngModelOptions]="{ standalone: true }"
        id="LYActualtoConsensusFcstVarPst"
      />
    </tr>
    <tr *ngIf="checkIfInList('LYActualtoConsensusFcstVarUnits')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.LastYearSales.LYActualtoConsensusFcstVarUnits"
        [ngModelOptions]="{ standalone: true }"
        id="LYActualtoConsensusFcstVarUnits"
      />
    </tr>
    <!-- LastYearSales -->

    <!-- StAccuracy -->
    <tr *ngIf="checkIfInList('CustomerSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.CustomerSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoTotalSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.AlgoTotalSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoTotalSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('COTotalSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.COTotalSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="COTotalSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPTotalSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.DPTotalSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="DPTotalSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConstrainedSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.ConstrainedSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="ConstrainedSTFcstvsActualST"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSTFcstvsActualST')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.StAccuracy.ConsensusSTFcstvsActualST"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSTFcstvsActualST"
      />
    </tr>
    <!-- StAccuracy -->

    <!-- SellIn -->
    <tr *ngIf="checkIfInList('CustomerRequestedSellInReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        (change)="CustomerRequestedSellInReceiveWkUpdated(week)"
        [(ngModel)]="week.SellIn.CustomerRequestedSellInReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerRequestedSellInReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('FinalSellInCommitReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.FinalSellInCommitReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="FinalSellInCommitReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('FinalSellInCommitvartoCustomerRequest')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.SellIn.FinalSellInCommitvartoCustomerRequest"
        [ngModelOptions]="{ standalone: true }"
        id="FinalSellInCommitvartoCustomerRequest"
      />
    </tr>
    <tr *ngIf="checkIfInList('AlgoSellInFcstReceiveWk')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.SellIn.AlgoSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="AlgoSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('DPSellInFcstReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.DPSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="DPSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('COSellInFcstReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.COSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="COSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('ConsensusSellInFcstReceiveWk')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.SellIn.ConsensusSellInFcstReceiveWk"
        [ngModelOptions]="{ standalone: true }"
        id="ConsensusSellInFcstReceiveWk"
      />
    </tr>
    <tr *ngIf="checkIfInList('FinalSellInCommitsShipWk')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.SellIn.FinalSellInCommitsShipWk"
        [ngModelOptions]="{ standalone: true }"
        id="FinalSellInCommitsShipWk"
      />
    </tr>
    <!-- SellIn -->

    <!-- CustomerInventory -->
    <tr *ngIf="checkIfInList('CustomerIOHProjection')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.CustomerIOHProjection"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerIOHProjection"
      />
    </tr>
    <tr *ngIf="checkIfInList('IOHProjection')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.IOHProjection"
        [ngModelOptions]="{ standalone: true }"
        id="IOHProjection"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerFWOSCalc')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.CustomerFWOSCalc"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerFWOSCalc"
      />
    </tr>
    <tr *ngIf="checkIfInList('FBFWOSCalc')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.FBFWOSCalc"
        [ngModelOptions]="{ standalone: true }"
        id="FBFWOSCalc"
      />
    </tr>
    <tr *ngIf="checkIfInList('FWOSvartoCustomerTarget')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.FWOSvartoCustomerTarget"
        [ngModelOptions]="{ standalone: true }"
        id="FWOSvartoCustomerTarget"
      />
    </tr>
    <tr *ngIf="checkIfInList('FWOSvartoFBTarget')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.CustomerInventory.FWOSvartoFBTarget"
        [ngModelOptions]="{ standalone: true }"
        id="FWOSvartoFBTarget"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerStoreOH')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.CustomerInventory.CustomerStoreOH"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerStoreOH"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerDCOH')">
      <input
        [disabled]="false"
        type="number"
        [(ngModel)]="week.CustomerInventory.CustomerDCOH"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerDCOH"
      />
    </tr>
    <!-- CustomerInventory -->

    <!-- FBInventory -->
    <tr *ngIf="checkIfInList('FBTotalOnHand')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.FBTotalOnHand"
        [ngModelOptions]="{ standalone: true }"
        id="FBTotalOnHand"
      />
    </tr>
    <tr *ngIf="checkIfInList('FBAvailableAllocation')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.FBAvailableAllocation"
        [ngModelOptions]="{ standalone: true }"
        id="FBAvailableAllocation"
      />
    </tr>
    <tr *ngIf="checkIfInList('OnOrder')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.OnOrder"
        [ngModelOptions]="{ standalone: true }"
        id="OnOrder"
      />
    </tr>
    <tr *ngIf="checkIfInList('InTransit')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.InTransit"
        [ngModelOptions]="{ standalone: true }"
        id="InTransit"
      />
    </tr>
    <tr *ngIf="checkIfInList('InventoryReceived')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.FBInventory.InventoryReceived"
        [ngModelOptions]="{ standalone: true }"
        id="InventoryReceived"
      />
    </tr>
    <!-- FBInventory -->

    <!-- KPI -->
    <tr *ngIf="checkIfInList('OnTimeDeliveryPerformance')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.OnTimeDeliveryPerformance"
        [ngModelOptions]="{ standalone: true }"
        id="OnTimeDeliveryPerformance"
      />
    </tr>
    <tr *ngIf="checkIfInList('OnTimeInFull')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.OnTimeInFull"
        [ngModelOptions]="{ standalone: true }"
        id="OnTimeInFull"
      />
    </tr>
    <tr *ngIf="checkIfInList('CustomerChargebacks')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.CustomerChargebacks"
        [ngModelOptions]="{ standalone: true }"
        id="CustomerChargebacks"
      />
    </tr>
    <tr *ngIf="checkIfInList('StandardDeliveryPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.StandardDeliveryPst"
        [ngModelOptions]="{ standalone: true }"
        id="StandardDeliveryPst"
      />
    </tr>
    <tr *ngIf="checkIfInList('ExpeditedDeliveryPst')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.KPI.ExpeditedDeliveryPst"
        [ngModelOptions]="{ standalone: true }"
        id="ExpeditedDeliveryPst"
      />
    </tr>
    <!-- KPI -->
  </td>
</ng-template>
<!-- Year Cummulated Column End -->
