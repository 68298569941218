<div class="container">
  <div class="user-page">
    <div class="row">
      <div class="col-md-1 d-block d-sm-block d-md-none mobile-icon">
        <div class="btn-show">
          <button (click)="openAddDialog({})" mat-icon-button color="primary">
            <i class="material-icons">
              add_circle
            </i>
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <form action="" class="form-fields show-search">
          <div class="form-group">
            <mat-form-field>
              <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-md-8 d-none d-sm-none d-md-block tablet-icon">
        <div class="btn-show">
          <button (click)="openAddDialog({})" mat-icon-button color="primary">
            <i class="material-icons">
              add_circle
            </i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material"   [suppressMenuHide]="true"
       groupHeaders suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
      (cellDoubleClicked)="onCellClicked($event)" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
      [rowData]="attributeList" (gridReady)="onGridReady($event)" [modules]="modules" [rowDragManaged]="true"
      [animateRows]="true" #grid></ag-grid-angular>
  </div>
</div>
<ng-template #Adddialog let-data>
  <h1 mat-dialog-title>Create Template</h1>
  <div mat-dialog-content>
    <form>
      <div class="row">
        <div class="col-12 action-btns text-right">
          <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="addField(data)">Add
            Field</button>
            <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit(data)">Save</button>

        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <input matInput [(ngModel)]="data.title" [ngModelOptions]="{standalone: true}" placeholder="Enter Title">
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngFor="let field of data.fieldList">
        <div class="col-md-4">
          <mat-form-field>
            <input matInput placeholder="Enter Name" [(ngModel)]="field.name" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-select placeholder="Select Type" [(ngModel)]="field.type" [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let res of answerTypeList; let i = index" [value]="res.answerTypeId">
                {{res.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="field.type === 4 || field.type === 5" class="col-md-4 text-right">
          <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="addOption(field)" style="display: inline-block;">Add
            Option</button>
        </div>
        <div class="col-md-4" *ngFor="let option of field.options || []">
          <mat-form-field>
            <input matInput placeholder="Enter Option Name" [(ngModel)]="option.name"
              [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
      </div>

    </form>
  </div>
</ng-template>
