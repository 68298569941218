<div mat-dialog-content style="min-height: 470px;">
    <p><strong>Add Drop</strong></p>
    <form [formGroup]="addDropForm">
        <div class="row">
            <div class="col-md-12 d-flex align-items-center justify-content-between">
                <strong class="mr-2">Date</strong>
                <retail-calendar class="w-100" [selectedDate]="addDropForm.get('date').value" (dateChanged)="addDropForm.get('date').setValue($event)"></retail-calendar>
            </div>
            <div class="col-md-12 d-flex align-items-center justify-content-between">
                <strong class="mr-2">Name</strong>
                <mat-form-field appearance="fill">
                    <input matInput formControlName="name">
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions class="justify-content-end">
    <button mat-stroked-button color="accent" (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="accent" class="ml-2" [disabled]="addDropForm.invalid"  (click)="onSaveClick()">Save</button>
</div>
