import { Component, OnInit } from '@angular/core';
import { MfpService } from 'src/services/mfp-services/mfp.service';

@Component({
  selector: 'app-merchandise-financial-planning',
  templateUrl: './merchandise-financial-planning.component.html',
  styleUrls: ['./merchandise-financial-planning.component.scss']
})
export class MerchandiseFinancialPlanningComponent implements OnInit {

  constructor(private mfpService: MfpService) { }

  ngOnInit(): void {
  }

  selectedTabChange() {
    // this.mfpService.updateTotal.next(this.mfpService.yearlyTotal);
  }

}
