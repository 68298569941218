import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { USER_ID } from 'src/common/keys';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ViewRendererComponent } from 'src/common/view-renderer';
import { FirebaseService } from 'src/services/firebase.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  selector: 'app-pdm-category',
  templateUrl: './pdm-category.component.html',
  styleUrls: ['./pdm-category.component.css']
})
export class PdmCategoryComponent implements OnInit {
  public columnDefs = [
    {
      headerName: 'Category Name',
      field: 'categoryName',
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openAddDialog.bind(this),
      },
      pinned: 'left',
      width: 250
    },
    {
      headerName: 'Class',
      field: 'class',
    },
    {
      headerName: 'Owner',
      field: 'owner',
    },
    // {
    //   headerName: '',
    //   field: '',
    //   resizable: true,
    //   filter: false,
    //   cellRenderer: 'viewRenderer',
    //   cellRendererParams: {
    //     onClick: this.openRemoveGoalDialog.bind(this),
    //   },
    //   width: 80
    // },
    {
      headerName: '',
      field: 'delete',
      resizable: true,
      filter: false,
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveGoalDialog.bind(this),
      },
      width: 80
    }
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public categoryList = [];
  public templateList = [];
  public modules = AllCommunityModules;
  public user: any;
  public businessInfoForm: FormGroup;
  public isEdit = false;
  @ViewChild('Adddialog') dialogAddTemplate: TemplateRef<any>;
  constructor(
    public router: Router,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    public storage: LocalstorageService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public firebase: FirebaseService) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent,
        nameRenderer: NameRendererComponent,
        viewRenderer: ViewRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    this.initializeForm();
    this.firebase.getAllTemplates().subscribe((res: any) => {
      this.templateList = res.map(e => {
        return {
          ...e.payload.doc.data(),
          id: e.payload.doc.id
        };
      });
    });
    this.getAttributeList();
  }
  public getAttributeList = () => {
    this.firebase.getAllCategory().subscribe((res: any) => {
      this.categoryList = res.map(e => {
        return {
          ...e.payload.doc.data(),
          id: e.payload.doc.id
        };
      });
    });
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.categoryName }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeItemMaster(row.rowData);
      }
    });
  }
  public removeItemMaster(selectedItem: any) {
    if (selectedItem) {
      const model = {
        ...selectedItem,
        active: false
      };
      this.firebase.DeleteCategory(selectedItem).then(res => { });
      this.confirmationDialogRef.close();
    }
  }
  public initializeForm = () => {
    this.businessInfoForm = this.formBuilder.group({
      title: [],
      bussinessAttributeId: [],
      answerTypeId: [],
      code: [],
      createdById: [this.storage.get(USER_ID)],
      active: true
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  onCellClicked($event) {

    this.router.navigate(['addUser'], {
      queryParams: {
        id: btoa($event.data.userId)
      }
    });
  }
  public navigateToRoles = () => {
    this.router.navigate(['Roles']);
  }
  openAddDialog(data, templateRef?: TemplateRef<any>): void {
    if (data && data.rowData) {
      this.isEdit = true;
      // Object.keys(this.businessInfoForm.controls).forEach(key => {
      //   this.businessInfoForm.controls[key].setValue(data.rowData[key]);
      // });
    }
    this.dialogRef = this.dialog.open(this.dialogAddTemplate, { width: '800px', data: data.rowData || {} });
  }
  public onSubmit = (data) => {
    const model = { ...data };
    if (this.isEdit) {
      this.firebase.UpdateCategory(model).then(res => {
        this.dialogRef.close();
      });
    } else {
      this.firebase.addNewCategory(model).then(res => {
        this.dialogRef.close();
      });
    }
  }
}
