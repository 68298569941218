<div mat-dialog-content>
  <mat-form-field>
    <mat-select placeholder="Select Month" [(ngModel)]="headerName">
      <mat-option *ngFor="let res of monthList; let i = index" [value]="res.value">
        {{res.name}}
      </mat-option>
    </mat-select>
    <!-- <mat-error *ngIf="submitted && !source">
      *Required Field
    </mat-error> -->
    <mat-error *ngIf="showErrorMessage">Column already exists</mat-error>
  </mat-form-field>
</div>
<div class="action-panel text-right">
  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit()">Save</button>
  <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="onNoClick()">Cancel</button>
</div>

