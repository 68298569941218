<div class="container-fluid">
  <div class="wrapper col-md-12">
    <mat-card-header>
      <mat-card-title>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="titleForm" (ngSubmit)="onSubmit()" class="form-fields">
        <div class="row">
          <div class="col-md-3 form-group">
            <mat-form-field>
              <mat-select placeholder="Select Customer" formControlName="customer" multiple>
                <mat-option *ngFor="let res of customerList; let i = index" [value]="res">
                  {{res}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput formControlName="itemNumber" placeholder="Item #">
            </mat-form-field>
          </div>
          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput formControlName="itemDesciption" placeholder="Item Description">
            </mat-form-field>
          </div>
          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput formControlName="titleCode" placeholder="Title Code">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput formControlName="titleName" placeholder="Title Name">
            </mat-form-field>
          </div>
          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput formControlName="upc" placeholder="UPC">
            </mat-form-field>
          </div>
          <div class="col-md-3 form-group">
            <mat-form-field>
              <mat-select placeholder="Select Class" formControlName="classCodeId">
                <mat-option *ngFor="let res of classList; let i = index" [value]="res.classCode">
                  {{res.classCodeDescription}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3 form-group">
            <mat-form-field>
              <mat-select placeholder="Select Subclass" formControlName="subClassCodeId">
                <mat-option *ngFor="let res of subClassList; let i = index" [value]="res.subClassCode">
                  {{res.subClassCodeDescription}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 form-group">
            <mat-form-field class="example-full-width">
              <input (focus)="picker1.open()" and (click)="picker1.open()" formControlName="streetDate" matInput
                [matDatepicker]="picker1" placeholder="Street Date">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
              <mat-error *ngIf="submitted && titleForm.controls['streetDate'].hasError('required')">
                *Required Field
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput formControlName="arp" placeholder="ARP">
            </mat-form-field>
          </div>
          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput formControlName="unitCost" placeholder="Unit Cost">
            </mat-form-field>
          </div>
          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput formControlName="grossBoxOffice" placeholder="Gross Box Office">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 form-group">
            <mat-form-field>
              <mat-select placeholder="Select Genre" formControlName="genre">
                <mat-option *ngFor="let res of genreList; let i = index" [value]="res">
                  {{res}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3 form-group">
            <label></label>
            <mat-slide-toggle formControlName="active">Active
            </mat-slide-toggle>
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <div class="action-btns text-right">
        <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit()">Save</button>
      </div>
    </mat-card-actions>
  </div>
</div>
