import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError } from 'rxjs';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  selector: 'app-promotion-form-v2',
  templateUrl: './promotion-form-v2.component.html',
  styleUrls: ['./promotion-form-v2.component.css'],
})
export class PromotionFormV2Component implements OnInit {
  public promotionForm: FormGroup;
  public submitted = false;
  public customerList = ['Best Buy', 'Walmart', 'Target', 'Dollar General'];
  public classList = ['New Release', 'Recent Release', 'Catalog', 'TV'];
  public subClassList = ['DVD', 'BD', '3D', 'UHD'];
  public fundTypeList = [{ fundTypeId: 1, fundTypeName: 'Promo' }, { fundTypeId: 2, fundTypeName: 'Base' }];
  public promotionProductType = ['Special Edition', 'Catalog Drafters', 'Single Disc'];
  public advertisementDescList = ['In-Store', 'Emali Blast', 'Direct Mail'];
  @Input() data: any;
  public skuPromotion =
    [
      {
        "customers":"Best Buy",
        "country":"US",
        "productType":"Oculus",
        "itemNumber":"FB332931",
        "itemDescription":"Quest 2 64GB",
        "upc":"1112233441",
        "unitCost":"$ 149.50",
        "regularRetail":"$ 299",
        "unitMarginCredit":"$ 30",
        "algoPromoTotalForecast":"1200",
        "cOPromoTotalForecast":"1500",
      },

      {
        "customers":"Best Buy",
        "country":"US",
        "productType":"Oculus",
        "itemNumber":"FB332932",
        "itemDescription":"Quest 2 256GB",
        "upc":"1112233442",
        "unitCost":"$ 199.50",
        "regularRetail":"$ 399",
        "unitMarginCredit":"$ 40",
        "algoPromoTotalForecast":"900",
        "cOPromoTotalForecast":"1000",
      },

      {
        "customers":"Best Buy",
        "country":"US",
        "productType":"Oculus Accessories",
        "itemNumber":"FB332933",
        "itemDescription":"Oculus Quest 2 Carrying Case",
        "upc":"1112233442",
        "unitCost":"$ 24.50",
        "regularRetail":"$ 49",
        "unitMarginCredit":"$ 5",
        "algoPromoTotalForecast":"300",
        "cOPromoTotalForecast":"250",
      },
    {
        "customers":"Best Buy",
        "country":"US",
        "productType":"Oculus Accessories",
        "itemNumber":"FB332934",
        "itemDescription":"Oculus Quest 2 Elite Strap",
        "upc":"1112233442",
        "unitCost":"$ 24.50",
        "regularRetail":"$ 49",
        "unitMarginCredit":"$ 5",
        "algoPromoTotalForecast":"325",
        "cOPromoTotalForecast":"300",
      }

  ];
  public itemList = [
    'Quest 64GB - US/CA/JP/TW',
    'Quest 128GB - US/CA/JP/TW',
    'Portal+ Black',
    'Oculus Go Relaxed Facial Interface',
    'Oculus Go Charging Cable',
    'Oculus Rift S Headband',
    'Oculus Rift Headset (only)',
    'Oculus Rift S',
    'Audio Arms',
    'Oculus Touch - Left Controller',
    'Oculus Quest UK/IE/HK/SG 64GB',
    'Rock Band VR connector',
    'Earphones',
    'Quest 64GB - EU',
    'Portal Black',
    'Quest 64GB - UK/IE/HK/SG',
    'Oculus Go Controller (Grade B)',
    'Portal Black (Gen 2)',
    'Touch',
    'Portal TV (Catalina)',
    'Oculus Rift S Standard Facial Interface',
    'Portal Mini - Dark (CA)',
    'Portal Dark - (CA)',
    'Oculus Go Carry Case',
    'Portal TV (UK/IE)',
    'Oculus Go Standalone VR 32 GB',
    'Portal+ White',
    'Rift - Marvel Bundle',
    'Unknown Oculus Product',
    'Portal Mini - White',
    'Portal Light - (CA)',
    'Oculus Go - 64GB',
    'Quest 64GB - AU/NZ',
    'Oculus Quest 128GB',
    'Sensor',
    'Quest 128GB and Case Bundle (S10252627@AMA+S10260629)',
    'Portal + White - CA',
    'Quest 128GB - AU/NZ',
    'Portal White - CA',
    'Oculus Go Controller',
    'Portal Mini (Omni)',
    'Portal White',
    'Portal White (Gen 2)',
    'Portal Mini - Black',
    'Oculus Quest IEM',
    'Portal Mini - Dark (EU)',
    'Oculus Quest Travel case',
    'Portal (Atlas)',
    'Oculus Quest UK/IE/HK/SG 128GB',
    'Portal+ Dark - (EU)',
    'Portal Mini - White (CA)',
    'Quest 64GB and Case Bundle (S10252626@AMA+S10260629)',
    'Portal TV (CA)',
    'Oculus Go - 32GB',
    'Oculus Go 64GB Headset (Grade B)',
    'Rift S',
    'Rift S Device',
    'Oculus Rift',
    '15W USB-C Power Adapter for the United Kingdom, Ireland, Hong Kong and Singapore',
    'SID (Simple Input Device)',
    '15W USB-C Power Adapter for EU',
    'Quest 128GB -EU',
    'HMD cable',
    'Oculus Go - 32GB ROW',
    'Online Game Code',
    'Oculus Touch - Right Controller',
    'Hardbundle',
    'Oculus Go Facial Interface Eye Rings',
    'Portal Mini - White (EU)',
    'Portal+ Light - (EU)',
    'Oculus Quest 64GB',
    'Oculus Go - 64GB ROW',
    'Oculus Touch Right Hand Controller',
    '15W USB-C Power Adapter for USA, Canada, Japan and Taiwan',
    'Portal TV (EU)',
    'Portal TV',
    'Portal Dark - (UK/IE)',
    'Portal + Black - CA',
    'Quest 128GB - UK/IE/HK/SG',
    'Oculus Go - Carrying Case',
    'Oculus Go Fitted Facial Interface',
    'Portal+ Light - (UK/IE)',
    'Oculus Sensor',
    'Oculus Go Controller Lanyard',
    'Portal+ Dark - (UK/IE)"',
    'Quest In-ear Headphones',
    'Portal Dark - (EU)',
    '15W USB-C Power Adapter for Australia and New Zealand',
    'Oculus Go Glasses Spacer',
    'Oculus Go Power Adapter',
    'Portal Light - (UK/IE)',
    'Oculus Go 32GB Headset (Grade B)',
    'Quest Travel Case',
    'Oculus Quest Standard Facial Interface',
    'Portal Light - (EU)',
    'Rift',
    'Portal Black - CA',
    'Portal Mini - White (UK/IE)',
    'Oculus Go Standalone VR 64 GB',
    'Portal Mini - Dark (UK/IE)',
    'Oculus Touch Left Hand Controller'
  ];
  isEdit = false;
  constructor(public fb: FormBuilder,
    public storage: LocalstorageService, public configurationService: ConfigurationService, public spinner: NgxSpinnerService, public toastr: NgxToasterService) {
    this.initializeForm();
  }

  ngOnInit() {
    console.log('called');
    if (this.data) {
      this.data = JSON.parse(this.data);
      this.isEdit = this.data.isEdit;
      delete this.data.isEdit;
      this.data.customers = (this.data.customers || '').split(',');
      this.data.classes = (this.data.classes || '').split(',');
      this.data.subClasses = (this.data.subClasses || '').split(',');
      this.data.productType = (this.data.productType || '').split(',');
      //this.data.consumerPromotion = (this.data.consumerPromotion || '').split(',');
      //this.data.advertisementDescription = (this.data.advertisementDescription || '').split(',');
      this.initializeFormWithValues();
      //this.getSkuPromotionList(this.data.promotionId);
    }
  }
  public getSkuPromotionList = async (promotionId: any) => {
    const params = {
      active: true,
      promotionId: promotionId
    };
    //const skuList = await this.configurationService.SKUPromotionGetAll(params).toPromise().catch(error => throwError(error));
    //this.skuPromotion = skuList;
    this.storage.set('SKULIST', this.skuPromotion);
  }

  public initializeFormWithValues = () => {
    Object.keys(this.promotionForm.controls).forEach(key => {
      if (this.data[key] !== undefined && this.data[key] !== null) {
        this.promotionForm.controls[key].setValue(this.data[key]);
      }
    });
  }
  public initializeForm = () => {
    this.promotionForm = this.fb.group({
      promotionId: [0],
      promotionName: [],
      promotionalMethod: [],
      item: [],
      methodStartDate: [],
      methodEndDate: [],
      active: [],
      default: [],
      customers: [],
      planningMonth: [],
      startDate: [],
      endDate: [],
      //updateRMF: [],
      //returnRate: [],
      //fundTypeId: [],
      //shipmentOffsetWeeks: [],
      comment: [],
     // returnOffsetWeeks: [],
     // classes: [],
      //subClasses: [],
      //promotionDescription: [],
     // primaryPlacement: [],
     // secondaryPlacement: [],
      // productType: [],
      //consumerPromotion: [],
     // advertisementDescription: [],
      //categoryStrategy: []
    });
  }
  public onSubmit = () => {
    this.submitted = true;
    if (this.promotionForm.valid) {
      const model = { ...this.promotionForm.value };
      model.customers = (model.customers || []).toString();
      model.classes = (model.classes || []).toString();
      model.subClasses = (model.subClasses || []).toString();
      model.productType = (model.productType || []).toString();
      //model.consumerPromotion = (model.consumerPromotion || []).toString();
     // model.advertisementDescription = (model.advertisementDescription || []).toString();
      this.spinner.show();
      if (this.isEdit) {
        this.configurationService.PromotionUpdate(model).subscribe(res => {
          this.spinner.hide();
          this.toastr.success('Promotion', 'Promotion updated successfully.');
          this.configurationService.promotionListSubject.next(res);
          this.isEdit = false;
          this.initializeForm();
        }, error => {
          this.spinner.hide();
        }
        );
      } else {
        this.configurationService.PromotionAdd(model).subscribe(res => {
          this.spinner.hide();
         // this.getSkuPromotionList(res);
          this.toastr.success('Promotion', 'Promotion updated successfully.');
          this.configurationService.promotionListSubject.next(res);
          this.isEdit = true;
        }, error => {
          this.spinner.hide();
        });
      }
    }
  }
  public onNoClick = () => {
  }
}
