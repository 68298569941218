import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { LocalstorageService } from '../../../../../services/localstorage.service';
import { USER_ID } from '../../../../../common/keys';
import { FB_Template } from '../../global.models';
import { ForecastConfirmationModalComponent } from '../forecast-confirmation-modal/forecast-confirmation-modal.component';
@Component({
  selector: 'app-forecast-delete-planner-modal',
  templateUrl: './fb-forecast-delete-planner-modal.component.html',
  styleUrls: ['./fb-forecast-delete-planner-modal.component.scss']
})
export class ForecastDeletePlannerModalComponent implements OnInit {
  versionList = [];


  constructor(
    public dialogRef: MatDialogRef<ForecastDeletePlannerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public config: ConfigurationService, public storage: LocalstorageService,
    public cdr : ChangeDetectorRef) { }

    ngOnInit() {
      this.getVersionList();
    }
    public getVersionList = () => {
      let templateId;
      if (this.data.isGlobal) {
        templateId = FB_Template.Global_Planning;
      } else if (this.data.isRegional) {
        templateId = FB_Template.Regional_Planning;
      } else if (this.data.isDirectInventory) {
        templateId = FB_Template.Retail_Direct;
      } else if (this.data.isInDirectInventory) {
        templateId = FB_Template.Retail_InDirect;
      }
      const params = {
        userId: this.storage.get(USER_ID),
        fbTemplateId: templateId
      }
      if(this.data.isItem){
        this.config.userItemForecastGetList(params).subscribe(res => {
          this.versionList = res;
          this.cdr.detectChanges()
        })
      }else
      this.config.userFBForecastGetList(params).subscribe(res => {
        this.versionList = res;
        this.cdr.detectChanges()
      })
    }
    public close = () => {

      this.dialogRef.close(this.data);
    }
    public deletePlanners(){
      this.data.version = this.versionList.filter(el => el.checked);
      if (this.data.version) {
        this.dialogRef.close(this.data);
      } else {
        return;
      }
    }
}
