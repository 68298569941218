<div class="container-fluid">
  <div class="user-page">
    <div class="row">
      <div class="col-md-1 d-block d-sm-block d-md-none mobile-icon">
        <div class="btn-show">
          <span (click)="openFullScreenMode()">
            <i style="font-size: 36px;" class="material-icons" aria-label="Full Screen">
              launch
            </i>
          </span>
        </div>
      </div>
      <div class="col-md-3">
        <form action="" class="form-fields show-search">
          <div class="form-group">
            <mat-form-field>
              <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-md-3">
        <form action="" class="form-fields show-search">
          <div class="form-group">
            <mat-form-field class="example-full-width">
              <input (dateChange)="filterChange()" (focus)="picker1.open()" and (click)="picker1.open()"
                [(ngModel)]="startDate" [ngModelOptions]="{standalone: true}" matInput [max]="endDate"
                [matDatepicker]="picker1" placeholder="Select Start Date">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-md-3">
        <form action="" class="form-fields show-search">
          <div class="form-group">
            <mat-form-field class="example-full-width">
              <input (dateChange)="filterChange()" (focus)="picker2.open()" and (click)="picker2.open()"
                [(ngModel)]="endDate" [ngModelOptions]="{standalone: true}" matInput [min]="startDate"
                [matDatepicker]="picker2" placeholder="Select Start Date">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-md-3 d-none d-sm-none d-md-block tablet-icon">
        <div class="btn-show">
          <span (click)="openDialog({})">
            <i style="font-size: 36px;" class="material-icons" aria-label="Column Show/Hide">
              list_alt
            </i>
          </span>
          <span (click)="openFullScreenMode()">
            <i style="font-size: 36px;" class="material-icons" aria-label="Full Screen">
              launch
            </i>
          </span>
        </div>
      </div>
    </div>
    <form>
      <div class="row">
        <div class="col-md-3 form-group">
          <mat-form-field>
            <mat-select placeholder="Select In-Stock & Target" [(ngModel)]="selectedPercentage"
              (selectionChange)="openExcelFile($event.value)" [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let res of percentageList; let i = index" [value]="res">
                {{res}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4 form-group">
          <mat-form-field>
            <mat-select (selectionChange)="filterChange()" placeholder="Select Product Group"
              [(ngModel)]="selectedProductGroup" [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let res of productGroupList; let i = index" [value]="res">
                {{res}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4 form-group">
          <mat-form-field>
            <mat-select (selectionChange)="filterChange()" placeholder="Select Business Unit"
              [(ngModel)]="selectedBusinessUnit" [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let res of businessUnitList; let i = index" [value]="res">
                {{res}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4 form-group">
          <mat-form-field>
            <mat-select (selectionChange)="filterChange()" placeholder="Select Retail KeyCode"
              [(ngModel)]="seletedRetailKeyCode" [ngModelOptions]="{standalone: true}" multiple>
              <mat-option *ngFor="let res of retailKeyCodeList; let i = index" [value]="res">
                {{res}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="all-show-grid">
    <ag-grid-angular id="myGrid" class="ag-theme-material"  [suppressMenuHide]="true"
       groupHeaders suppressRowClickSelection  toolPanelSuppressGroups
      toolPanelSuppressValues [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="ItemMasterList"
      (gridReady)="onGridReady($event)" [modules]="modules" #grid></ag-grid-angular>
  </div>
</div>
<div class="container">
  <h5>Initial Inventory Movement</h5>
  <!-- <combo-chart-component id="chart"
  [view]="view"
  [scheme]="comboBarScheme"
  [colorSchemeLine]="lineChartScheme"
  [results]="barChartDatasets"
  [animations]="animations"
  [lineChart]="chartDatasets"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  [showGridLines]="showGridLines"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [showRightYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [yAxisLabelRight]="yAxisLabelRight">
</combo-chart-component> -->
<app-line-chart [chartDatasets]="chartDatasets" [xAxis]="xAxis" [yAxis]="yAxis"></app-line-chart>

</div>
<div class="container">
  <h5>Simulated Inventory Movement</h5>
  <app-line-chart [chartDatasets]="simulatedChartDatasets" [xAxis]="xAxisSimulated" [yAxis]="yAxis"></app-line-chart>
</div>
<ng-template #dialog let-data>
  <h1 mat-dialog-title>Show/Hide Columns</h1>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <mat-checkbox maxlength="20" [(ngModel)]="selectAll" (change)="selectAllSelected($event)">Select/Remove All
    </mat-checkbox>
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSave(data)">Save &
      Close</button>
  </div>
  <div mat-dialog-content>
    <div style="display: flex;flex-direction: column;">
      <mat-checkbox maxlength="20" *ngFor="let item of columnDefs" [(ngModel)]="item.show">{{item.headerName}}
      </mat-checkbox>
    </div>
  </div>
</ng-template>
