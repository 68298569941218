import { AllModules, ColumnApi, GridApi, GridOptions, ServerSideStoreType } from '@ag-grid-enterprise/all-modules';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { downloadFromLink, gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import { ConfigurationService } from 'src/services/configuration.service';
import { ForecastLookupService } from 'src/services/forecast-services/forecast-lookup-service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { defaultColDef, getSeasonalityColDefs } from './ag-grid/grid-options';
import { SeasonalityEditRenderer } from './ag-grid/seasonality-edit-renderer.component';
import { SeasonalitySetupComponent } from './seasonality-setup/seasonality-setup.component';
import { SeasonalityService } from 'src/services/seasonality-services/seasonality.service';
import { EditSeasonalityComponent } from './edit-seasonality/edit-seasonality.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { SeasonalityLineChartCellRenderer } from './ag-grid/seasonality-line-chart-cell-renderer.component';
import { SeasonalityChartDialogComponent } from './dialogs/seasonality-chart-dialog/seasonality-chart-dialog.component';
import { getFilterModel, getSortModel } from 'src/common/util/util';
import { SharedDataService } from 'src/services/shared-data.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PendingSeasonalityComponent } from './dialogs/pending-seasonality/pending-seasonality.component';

@Component({
  selector: 'app-seasonality-manager',
  templateUrl: './seasonality-manager.component.html',
  styleUrls: ['./seasonality-manager.component.css']
})
export class SeasonalityManagerComponent implements OnInit, AfterViewInit, OnDestroy {
  paginationPageSize = 20;
  cacheBlockSize = 20;

  public modules = AllModules;
  rowModelType = 'serverSide';
  serverSideStoreType = ServerSideStoreType.Partial;
  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  rowSelection = 'multiple';
  public defaultColDef = defaultColDef;
  public columnDefs = getSeasonalityColDefs({}, this);
  public formDataReader: FormData
  gridData: any = [];
  frameworkComponents = {
    seasonalityEditRenderer: SeasonalityEditRenderer,
    seasonalityLineChartCellRenderer: SeasonalityLineChartCellRenderer
  };
  selectedRows: any = [];
  totalRows: number = 0;

  public actionSubscription: Subscription;
  public lineChartSubscription: Subscription;

  levelDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'seasonalityLevelId',
    textField: 'seasonalityLevelDescription',
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: 'No Data Available'
  };

  monthsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'planningMonth',
    textField: 'planningMonth',
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: 'No Data Available'
  };

  accountsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'accountName',
    textField: 'accountName',
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: 'No Data Available'
  };

  filterEvent: any = {
    status: null,
    isAnomalous: null
  };
  seasonalityName: any;
  seasonalityLevelIds: any = [];
  startMonths: any = [];
  endMonths: any = [];
  status: any;
  accounts: any = [];
  isAnomalous: boolean = false;

  selectedLevels: any = [];
  seasonalityStartMonth: any = [];
  seasonalityEndMonth: any = [];
  selectedAccounts: any = [];

  seasonalityLevelList: any = [];
  seasonalityMonthList: any = [];
  statusList: any = [
    { statusId: null, statusName: 'All' },
    { statusId: false, statusName: 'Inactive' },
    { statusId: true, statusName: 'Active' }
  ];
  accountList: any = [];
  

  constructor(public configurationService: ConfigurationService, public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public seasonalityService: SeasonalityService,
    public forecastLookupService: ForecastLookupService,
    public confirmationDialogRef: MatDialogRef<SeasonalitySetupComponent>,
    public _dataService: SharedDataService,
    public dialogAddRef: MatDialogRef<any>,
  ) { }

  get isFilteredApplied(): boolean {
    return this.gridApi ? !_.isEmpty(this.gridApi.getFilterModel()) : false;
  }

  ngOnInit() {
    this.listenSubscriptions();
    this.getLookupData();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.createDataSource();
    }, 10);
  }

  getLookupData() {
    this.forecastLookupService.GetAccountslist().subscribe(accountList => {
      this.accountList = accountList;
    });

    this.forecastLookupService.GetPlanningMonthList().subscribe(seasonalityMonthList => {
      this.seasonalityMonthList = seasonalityMonthList;
    });

    this.seasonalityService.GetSeasonalityLookupList().subscribe((seasonalityLevelList: any) => {
      this.seasonalityLevelList = this.whiteSeasonalityLevels(seasonalityLevelList);
    });

  }

  whiteSeasonalityLevels(seasonalityLevelList: any) {
    let whiteSeasonalityLevel = [];

    seasonalityLevelList.forEach(
      (value) => {
        if (value.seasonalityLevelCode === 'AC')
          value.seasonalityLevelDescription = this._dataService.getDataById(1);
        else if (value.seasonalityLevelCode === 'B')
          value.seasonalityLevelDescription = this._dataService.getDataById(2);
        else if (value.seasonalityLevelCode === 'PG')
          value.seasonalityLevelDescription = this._dataService.getDataById(3);
        else if (value.seasonalityLevelCode === 'BAC')
          value.seasonalityLevelDescription = `${this._dataService.getDataById(1)} & ${this._dataService.getDataById(2)}`;
        else if (value.seasonalityLevelCode === 'PGAC')
          value.seasonalityLevelDescription = `${this._dataService.getDataById(1)} & ${this._dataService.getDataById(3)}`;
        else if (value.seasonalityLevelCode === 'SK')
          value.seasonalityLevelDescription = this._dataService.getDataById(5);
        else if (value.seasonalityLevelCode === 'ACSKU')
          value.seasonalityLevelDescription = `${this._dataService.getDataById(1)} & ${this._dataService.getDataById(4)}`;
        whiteSeasonalityLevel.push(value);
      }
    );
    return whiteSeasonalityLevel;
  }

  levelChange() {
    const levels = [];
    this.selectedLevels.forEach(a => {
      levels.push(a.seasonalityLevelId);
    });
    this.seasonalityLevelIds = levels;
    this.applyFilterEvent();
  }

  startMonthsChange() {
    const months = [];
    this.seasonalityStartMonth.forEach(a => {
      months.push(a.planningMonth);
    });
    this.startMonths = months;
    this.applyFilterEvent();
  }

  endMonthsChange() {
    const months = [];
    this.seasonalityEndMonth.forEach(a => {
      months.push(a.planningMonth);
    });
    this.endMonths = months;
    this.applyFilterEvent();
  }

  accountsChange() {
    const accounts = [];
    this.selectedAccounts.forEach(a => {
      accounts.push(a.accountName);
    });
    this.accounts = accounts;
    this.applyFilterEvent();
  }

  clearValues() {
    this.seasonalityName = '';
    this.seasonalityLevelIds = [];
    this.startMonths = [];
    this.endMonths = [];
    this.accounts = [];
    this.status = null;

    this.selectedLevels = [];
    this.seasonalityStartMonth = [];
    this.seasonalityEndMonth = [];
    this.selectedAccounts = [];
    this.applyFilterEvent();
  }

  applyFilterEvent() {
    this.filterEvent = {
      ...this.seasonalityName && { seasonalityName: this.seasonalityName },
      ...this.seasonalityLevelIds.length && { seasonalityLevelIds: this.seasonalityLevelIds.join(',') },
      ...this.startMonths.length && { startMonths: this.startMonths.join(',') },
      ...this.endMonths.length && { endMonths: this.endMonths.join(',') },
      ...{ status: this.status },
      ...this.accounts.length && { accounts: this.accounts.join(',') },
      ...{ isAnomalous: this.isAnomalous ? this.isAnomalous : null }
    };
    this.createDataSource();
  }

  createDataSource() {
    const datasource = {
      getRows: (params) => {
        const sortModel = params.request.sortModel;
        const filterModel = params.request.filterModel;
        const requestBody: any = {
          // 0 base index of page number
          offSet: params.request.startRow || 0,
          pageSize: this.paginationPageSize,
          ...this.filterEvent
        };

        requestBody.filterModel = getFilterModel(filterModel);
        requestBody.sortModel = getSortModel(sortModel);

        this.seasonalityService
          .GetSeasonalityList(requestBody)
          .pipe(
            tap(() => this.gridApi.hideOverlay())
          )
          .subscribe((response: any) => {
            params.successCallback(response.data, response.totalRecord);
            this.totalRows = response.totalRecord;
            this.deselectNode();
            response.data.length == 0 && this.gridApi.showNoRowsOverlay();
            this.getSeasonalityChartData(requestBody);
          });
      },
    };
    this.gridApi.setServerSideDatasource(datasource);
  }

  getSeasonalityChartData(requestBody) {
    this.seasonalityService.SeasonalityGraphDataGetList(requestBody).subscribe((graphData: any) => {
      this.createGridChartData(graphData.data);
    });
  }

  createGridChartData(graphData) {
    const seasonalitiesSalesData: any = [];
    const seasonalities = _.groupBy(graphData, a => a.seasonalityId);
    _.keys(seasonalities).forEach(seasonalityId => {
      const seasonalitySaleObject: any = {
        seasonalityId: Number(seasonalityId),
        saleData: {}
      };
      seasonalities[seasonalityId].forEach(saleObject => {
        seasonalitySaleObject.saleData[`${saleObject.weekDateAndYear}`] = saleObject.seasonalityIndex;
      });
      seasonalitiesSalesData.push(seasonalitySaleObject);
    });
    this.updateGridDataNodes(seasonalitiesSalesData);
  }

  updateGridDataNodes(seasonalitiesSalesData) {
    const gridData = this.getGridData();
    gridData.forEach(row => {
      const saleData = _.find(seasonalitiesSalesData, a => a.seasonalityId === row.seasonalityId);
      if (saleData) {
        row.saleData = saleData.saleData;
      }
    });
    setTimeout(() => {
      this.gridApi.redrawRows();
    }, 50);
  }

  getGridData() {
    return _.map(_.get(this.gridApi, 'rowModel.nodeManager.rowNodes', []), a => a && a.data);
  }

  deselectNode() {
    if (this.gridApi) {
      this.gridApi.deselectAll();
    }
  }

  listenSubscriptions() {
    this.actionSubscription = this.seasonalityService.getSeasonalityGridActionCellSubject().subscribe(data => {
      if (data) {
        const seasonalityData = {
          isEdit: true,
          isCopyOperation: false,
          seasonalityData: data.seasonalityData
        }
        this.openSeasonalityEditView(seasonalityData);
      }
    });

    this.lineChartSubscription = this.seasonalityService.getSeasonalityManagerGridChartActionSubject().subscribe(data => {
      if (data) {
        this.openLineChart(data);
      }
    });
  }

  openLineChart(data) {
    this.dialog.open(SeasonalityChartDialogComponent, {
      disableClose: true,
      data: {
        chartData: data
      }
    });
  }

  openPendingSeasonalityDialog() {
    const dialogRef = this.dialog.open(PendingSeasonalityComponent, {
      disableClose: true,
      width: '950px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.createDataSource();
      }
    });
  }

  openSeasonalityEditView(data) {
    const editSeasonalityComponent = {
      menuId: '454',
      name: 'Seasonality Editor',
      selector: 'app-edit-seasonality',
      displayName: 'Seasonality Editor',
      module: 'ForecastEnginModule',
      data: data,
      component: EditSeasonalityComponent,
    }
    this.configurationService.menuRendererSubject.next(editSeasonalityComponent);
  }

  clearSelectedRows() {
    this.gridApi.deselectAll();
  }

  clearGridFilters() {
    this.gridApi.setFilterModel(null);
  }

  openColumnDialog() {
    const dialogRef = this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select'],
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onExportGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'Seasonality-Manager',
      colDefs: this.columnDefs,
      allColumns: false,
      specialCharactersFields: ['seasonalityItems'],
      columnKeys: _.map(this.columnDefs, a => a.field)
    });
    this.gridApi.exportDataAsCsv(csvExportParams);
  }

  openSeasonalitySetupDetail(data = {}): void {
    // tslint:disable-next-line: no-use-before-declare

    this.confirmationDialogRef = this.dialog.open(SeasonalitySetupComponent, {
      width: '1200px',
      disableClose: true,
      data: data
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.createDataSource();
      }
    });
  }

  rowSelectionChanged(params) {
    this.selectedRows = params.api.getSelectedRows();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
  }

  copySeasonality() {
    if (this.selectedRows.length === 1) {
      const copyData = {
        isCopyOperation: true,
        seasonalityId: this.selectedRows[0].seasonalityId
      }
      this.openSeasonalitySetupDetail(copyData);
    } else {
      this.toastr.warning('Warning', 'Please select only one seasonality to copy.')
    }
  }

  deleteSeasonality() {
    if (this.selectedRows.length) {
      const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {
          headerName: `Delete Seasonalities`,
          confirmationMessage: `Are you sure you want to delete these seasonalities?`
        }
      });
      confirmationDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.spinner.show();
          this.seasonalityService.DeleteSeasonality({ seasonality: this.selectedRows }).subscribe(res => {
            if (res) {
              this.createDataSource();
              this.spinner.hide();
              this.toastr.success('Success', `Seasonalities deleted successfully.`);
            };
          }, err => {
            this.toastr.error('Error', `System failed to delete seasonalities.`);
            this.spinner.hide();
          });
        }
      });
    } else {
      this.toastr.warning('Warning', 'Please select rows first.');
    }
  }

  changeSeasonalityStatus(status) {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        headerName: `Status Change`,
        confirmationMessage: `Are you sure you want to ${status ? 'activate' : 'disable'} these seasonalities?`
      }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        const payload = this.selectedRows.map(a => { return { seasonalityId: a.seasonalityId, status: status } });

        this.seasonalityService.UpdateSeasonalitiesStatus({ seasonality: payload }).subscribe(res => {
          if (res) {
            this.gridApi.refreshServerSideStore({ purge: true });
            this.spinner.hide();
            this.toastr.success('Success', `Mapping status change successfully.`);
          };
        }, err => {
          this.toastr.error('Error', `System failed to update status.`);
          this.spinner.hide();
        });
      }
    });
  }

  importSeasonality() {
    (document.getElementById('upload-custom-seasonality-data') as any).value = '';
    document.getElementById('upload-custom-seasonality-data').click();
  }

  exportSeasonalityTemplate() {
    
    this.spinner.show();
    this.seasonalityService.ExportSeasonalityTemplate().subscribe((res: any) => {
      downloadFromLink(res, 'SeasonalityTemplate');
      this.spinner.hide();
    });
  }
  validateUploadedCSV(fileInput: any) {
    const formDataReader = new FormData();

    if (fileInput.target.files && fileInput.target.files.length) {
      const [files] = fileInput.target.files;

      formDataReader.append('file', files);
     
      formDataReader.append('deactivate', "False");
      this.formDataReader = formDataReader;
      this.uploadImportedSeasonalityCsvFile(formDataReader);
    }
  }

  uploadImportedSeasonalityCsvFile(formData) {
    this.seasonalityService.ImportSeasonality(formData).subscribe(
      (res: any) => {
        if (res && res.length) {
          const successMessages = [];
          const errorMessages = [];
          const warningMessages = [];
          const infoMessages = [];
          res.forEach(element => {

            switch (element.messageType) {
              case 'Success':
                successMessages.push(element.messageText);
                break;
              case 'Warning':
                warningMessages.push(element.messageText);
                break;
              case 'Info':
                infoMessages.push(element.messageText);
                break;
              case 'Error':
                errorMessages.push(element.messageText);
                break;
              default:
                break;
            }
          });

          if (errorMessages.length)
            this.toastr.error('Error', `${errorMessages.map(a => a).join('<br />')}`);
          if (successMessages.length)
            this.toastr.success('Success', `${successMessages.map(a => a).join('<br />')}`);
          if (warningMessages.length) {
            
            this.deactivateDialogue();
           // this.openDialog(this.broadCastDialog);
            // this.toastr.warning('Warning', `${warningMessages.map(a => a).join('<br />')}`);
          }
          if (infoMessages.length)
            this.toastr.info('Info', `${infoMessages.map(a => a).join('<br />')}`);

          this.createDataSource();
        } else {
          this.toastr.warning('Warning', 'Seasonality Data Import Failed.');

        }
      },
      (err: any) => {
        this.toastr.error('Error', 'Seasnality Data Import Failed.');
      });
  }

  ngOnDestroy(): void {
    this.actionSubscription.unsubscribe();
    this.lineChartSubscription.unsubscribe();
  }
  deactivatePrevious() {

    this.formDataReader.delete('deactivate');
    this.formDataReader.append('deactivate', "True");
    this.uploadImportedSeasonalityCsvFile(this.formDataReader);

  }
  public openDialog = (template) => {

    this.dialogAddRef = this.dialog.open(template, { width: '600px' });
    this.dialogAddRef.afterClosed().subscribe((res) => {

    });


  };


  deactivateDialogue() {
   
      const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {
          headerName: `Deactivate Previous`,
          confirmationMessage: `You are trying to create seasonality for active combination? \n Deactivate Previous`
        }
      });
      confirmationDialogRef.afterClosed().subscribe(result => {
        if (result) {
         this.deactivatePrevious();
        }
      });
    
  }

}
