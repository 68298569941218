import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Component, OnInit } from '@angular/core';
import { paginationPageSize, rowSelection, generateGridColumns } from '../../ag-grid/gridOptions';
import { POData } from '../../MockData';

@Component({
  selector: 'app-replenishment-po-summary',
  templateUrl: './po-summary.component.html',
  styleUrls: ['./po-summary.component.scss']
})
export class PoSummaryComponent implements OnInit {

  public gridOptions: any;
  paginationPageSize: number;
  rowModelType: string;
  gridData: any[];
  columnDefs: any[];
  rowSelection: string;

  modules = AllModules;

  constructor() { }

  ngOnInit(): void {
    this.rowModelType = 'clientSide';
    this.paginationPageSize = paginationPageSize;
    this.gridOptions = {
      frameworkComponents: {},
      pagination: true,
      paginationPageSize: this.paginationPageSize,
    };
    this.rowSelection = rowSelection;
    this.gridData = POData;
    const keys = Object.keys(this.gridData[0]);
    this.columnDefs = generateGridColumns(keys);
  }

}
