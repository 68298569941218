import { ColDef } from '@ag-grid-community/all-modules';

export const paginationPageSize = 10;
export const cacheBlockSize = 50;

export const currencyGridColDefs: ColDef[] = [
  {
    headerName: 'Reporting Month',
    field: 'month',
    minWidth: 40,
    resizable: true,
  },
  {
    headerName: 'Monthly Conversion Rate',
    field: 'rate',
    minWidth: 80,
    resizable: true,
    editable: true
  }
];
