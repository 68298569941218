import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReplenishmentService } from 'src/services/replenishment.service';
import { ReplenishmentLineChartComponent } from './components/replenishment-line-chart/replenishment-line-chart.component';

@Component({
  selector: 'app-replenishment-module',
  templateUrl: './replenishment-module.component.html',
  styleUrls: ['./replenishment-module.component.scss']
})
export class ReplenishmentModuleComponent implements OnInit {

  constructor(private replenishmentService: ReplenishmentService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.replenishmentService.replenishmentGraphSubject.subscribe((res: any) => {
      this.openGraph(res);
    });
  }

  openGraph(data) {
    this.dialog.open(ReplenishmentLineChartComponent, {
      data: data,
      width: '600px'
    });
  }

}
