import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { USER_ID } from '../../../../../common/keys';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { LocalstorageService } from '../../../../../services/localstorage.service';
import { FB_Template } from '../../global.models';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as XLSX from 'xlsx';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { InlineWorker } from '../../fb-forecast/inline-worker';
@Component({
  selector: 'app-planner-import',
  templateUrl: './planner-import.component.html',
  styleUrls: ['./planner-import.component.scss'],
})
export class ImportPlannerComponent implements OnInit {
  importFiles = [
    { fileName: 'March_Q4_2020_west_cost', date: '07/15/2021', checked: false },
    { fileName: 'March_Q4_2020_west_cost', date: '07/15/2021', checked: false },
    { fileName: 'March_Q4_2020_west_cost', date: '07/15/2021', checked: false },
  ];
  files = [];
  @ViewChild('importError') template: TemplateRef<any>;
  measureList;
  forecastList;
  accountList;
  itemsList;
  calendarList;
  gridListUI;
  filteredMeasures;
  dropdownObjects;
  selectedMeasures;
  distributorList = [];
  fbTemplateId;
  constructor(
    public dialogRef: MatDialogRef<ImportPlannerComponent>,
    public spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public config: ConfigurationService,
    public storage: LocalstorageService,
    public toaster: NgxToasterService,
    public dialog: MatDialog,
    public dialogRefError: MatDialogRef<any>
  ) {
    this.measureList = data.measureList;
    this.forecastList = data.forecastList;
    this.accountList = this.data.accountList;
    this.itemsList = data.itemsList;
    this.gridListUI = data.gridListUI;
    this.calendarList = data.calendarList;
    this.dropdownObjects = data.dropdownObjects;
    this.distributorList = data.distributorList;
    this.fbTemplateId = data.fbTemplateId;
  }

  ngOnInit() {}
  importFile(event) {
    if (this.fbTemplateId === FB_Template.Retail_InDirect) {
      this.onSelectIndirectPlanner(event)
    } else {
      this.onSelect(event);
    }
  }
  onSelect(event: any) {
    /* wire up file reader */
    // const target: any = <DataTransfer>(event.target);
    if (event.addedFiles.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(event.addedFiles[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, {
        type: 'binary',
        cellDates: false,
      });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      let data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      // console.log(data);
      data.forEach((row) => {
        row = Object.keys(row).map(
          (key) => (row[key.replace(/\s↵/g, '')] = row[key])
        ); // Data will be logged in array format containing objects
      });
      data.forEach((el: any, index) => {
        const foundSKU = this.data.itemsList.find(
          (row) => el.SKU && row.sku === el.SKU.toString()
        );
        const foundAccount = this.data.accountList.find(
          (row) => el.Account && row.salesforceId === el.Account.toString()
        );
        const FoundMeasure = this.data.measureList.find(
          (row) => el.MeasureName && row.measureName === el.MeasureName
        );
        const foundCalendar = this.data.calendarList.find((row) => {
          let isFound = false;
          Object.keys(el).forEach((key, index) => {
            if (index > 4) {
              isFound =
                new Date(key).toLocaleDateString() ===
                new Date(row.weekStartDate).toLocaleDateString();
            }
          });
          return isFound;
        });
        if (foundSKU && foundAccount && foundCalendar && FoundMeasure) {
          el.item = foundSKU;
          el.account = foundAccount;
          el.calendar = foundCalendar;
          el.measure = FoundMeasure;
        }
      });
      let editViaUploadList = [];
      data.forEach((row: any) => {
        Object.keys(row).forEach((key, index) => {
          if (new Date(key).toLocaleDateString() !== 'Invalid Date') {
            const calendar = this.data.calendarList.find((el) => {
              return (
                new Date(key).toLocaleDateString() ===
                new Date(el.weekStartDate).toLocaleDateString()
              );
            });
            const forecastObject = {
              fbForecastId: 0,
              itemId: row.item?.itemManagerId,
              channelId: row.account?.itemAccountId,
              measureId: row.measure?.measureId,
              measureCode: row.measure?.code,
              calendarId: calendar?.calendarId,
              isEdited: true,
              value: row.measure?.code.indexOf('Comment') < 0 ? row[key] : 0,
              textValue:
                row.measure?.code.indexOf('Comment') > -1 ? row[key] : '',
              templateId: row.measure?.templateId,
              userFBForecastId: this.data.userFBForecastId || 0,
              versionTypeId: this.data.versionTypeId,
              userId: this.storage.get(USER_ID),
              fbTemplateId: this.data.fbTemplateId,
            };
            editViaUploadList.push(forecastObject);
          }
        });
      });
      editViaUploadList = editViaUploadList.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.calendarId === thing.calendarId &&
              t.itemId === thing.itemId &&
              t.channelId === thing.channelId &&
              t.measureId === thing.measureId
          )
      );
      const filteredList = editViaUploadList.filter(
        (el) => el.templateId === 3 || el.templateId === 2
      );
      const measures = editViaUploadList
        .map((row) => row.measureId)
        .filter(
          (thing, index, self) => index === self.findIndex((t) => t === thing)
        );
      if (filteredList && filteredList.length) {
        this.dialogRef.close({
          list: filteredList,
          ...this.data,
          selectedMeasures: measures,
        });
      } else {
        this.dialogRef.close();
        this.dialogRefError = this.dialog.open(this.template, {
          width: '600px',
          panelClass: 'fb-dialog-wrapper',
        });
      }
    };
  }
  onSelectIndirectPlanner(event: any) {
    /* wire up file reader */
    // const target: any = <DataTransfer>(event.target);
    if (event.addedFiles.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(event.addedFiles[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, {
        type: 'binary',
        cellDates: false,
      });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      let data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      // console.log(data);
      data.forEach((row) => {
        row = Object.keys(row).map(
          (key) => (row[key.replace(/\s↵/g, '')] = row[key])
        ); // Data will be logged in array format containing objects
      });
      let distiRows = data.filter(row => row['Disti/Account'] === 'Distributor');
      let AccountRows = data.filter(row => row['Disti/Account'] === 'Account');
      distiRows = this.mapDistiRows(distiRows);
      AccountRows = this.mapAccountRows(AccountRows);
      let distiUploadRows = this.createUploadListDisti(distiRows);
      let accountUploadRows = this.createUploadListAccount(AccountRows);
      distiUploadRows = distiUploadRows.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.calendarId === thing.calendarId &&
              t.itemId === thing.itemId &&
              t.measureId === thing.measureId
          )
      );
      accountUploadRows = accountUploadRows.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.calendarId === thing.calendarId &&
              t.itemId === thing.itemId &&
              t.channelId === thing.channelId &&
              t.measureId === thing.measureId
          )
      );
      const filteredListDisti = distiUploadRows.filter(
        (el) => (el.isDisti === 3 || el.isDisti === 2) && (el.value || el.textValue)
      );
      const filteredListAccount = accountUploadRows.filter(
        (el) => el.isAccount === 3 || el.isAccount === 2 && (el.value || el.textValue)
      );
      const mergedList = [...filteredListDisti, ...filteredListAccount];
      const measures = mergedList
        .map((row) => row.measureId)
        .filter(
          (thing, index, self) => index === self.findIndex((t) => t === thing)
        );
      if ((filteredListDisti && filteredListDisti.length > 0) || (filteredListAccount && filteredListAccount.length > 0)) {
        this.dialogRef.close({
          distiListUpload: filteredListDisti,
          accountListUpload: filteredListAccount,
          ...this.data,
          selectedMeasures: measures,
        });
      } else {
        this.dialogRef.close();
        this.dialogRefError = this.dialog.open(this.template, {
          width: '600px',
          panelClass: 'fb-dialog-wrapper',
        });
      }
    };
  }
  public mapDistiRows(rows) {
    rows.forEach((el: any, index) => {
      const foundSKU = this.data.itemsList.find(
        (row) => el.SKU && row.sku === el.SKU.toString()
      );
      const FoundMeasure = this.data.measureList.find(
        (row) => el.MeasureName && row.measureName === el.MeasureName
      );
      const FoundDistributor = this.distributorList.find(
        (row) => el.SFID && row.distributoR_SFID === el.SFID
      );
      const foundCalendar = this.data.calendarList.find((row) => {
        let isFound = false;
        Object.keys(el).forEach((key, index) => {
          if (index > 5) {
            isFound =
              new Date(key).toLocaleDateString() ===
              new Date(row.weekStartDate).toLocaleDateString();
          }
        });
        return isFound;
      });
      if (foundSKU && foundCalendar && FoundMeasure) {
        el.item = foundSKU;
        el.calendar = foundCalendar;
        el.measure = FoundMeasure;
        el.distributor = FoundDistributor;
      }
    });
    return rows;
  }
  public mapAccountRows(rows) {
    rows.forEach((el: any, index) => {
      const foundSKU = this.data.itemsList.find(
        (row) => el.SKU && row.sku === el.SKU.toString()
      );
      const foundAccount = this.data.accountList.find(
        (row) => el.SFID && row.salesforceId === el.SFID.toString()
      );
      const FoundMeasure = this.data.measureList.find(
        (row) => el.MeasureName && row.measureName === el.MeasureName
      );
      const FoundDistributor = this.distributorList.find(
        (row) => el.SFID && row.distributoR_SFID === el.SFID
      );
      const foundCalendar = this.data.calendarList.find((row) => {
        let isFound = false;
        Object.keys(el).forEach((key, index) => {
          if (index > 5) {
            isFound =
              new Date(key).toLocaleDateString() ===
              new Date(row.weekStartDate).toLocaleDateString();
          }
        });
        return isFound;
      });
      if (foundSKU && foundAccount && foundCalendar && FoundMeasure) {
        el.item = foundSKU;
        el.account = foundAccount;
        el.calendar = foundCalendar;
        el.measure = FoundMeasure;
        el.distributor = FoundDistributor;
      }
    });
    return rows;
  }
  public createUploadListDisti(rows) {
    let editViaUploadList = [];
    rows.forEach((row: any) => {
      Object.keys(row).forEach((key, index) => {
        if (new Date(key).toLocaleDateString() !== 'Invalid Date') {
          const calendar = this.data.calendarList.find((el) => {
            return (
              new Date(key).toLocaleDateString() ===
              new Date(el.weekStartDate).toLocaleDateString()
            );
          });
          const forecastObject = {
            fbForecastId: 0,
            itemId: row.item?.itemManagerId,
            measureId: row.measure?.measureId,
            isDisti: row.measure?.isDisti,
            measureCode: row.measure?.code,
            calendarId: calendar?.calendarId,
            distiId: row.distributor?.distributorId || 0,
            isEdited: true,
            value: row.measure?.code.indexOf('Comment') < 0 ? row[key] : 0,
            textValue:
              row.measure?.code.indexOf('Comment') > -1 ? row[key] : '',
            templateId: row.measure?.templateId,
            userFBForecastId: this.data.userFBForecastId || 0,
            versionTypeId: this.data.versionTypeId,
            userId: this.storage.get(USER_ID),
            fbTemplateId: this.data.fbTemplateId,
          };
          editViaUploadList.push(forecastObject);
        }
      });
    });
    return editViaUploadList;
  }
  public createUploadListAccount(rows) {
    let editViaUploadList = [];
    rows.forEach((row: any) => {
      Object.keys(row).forEach((key, index) => {
        if (new Date(key).toLocaleDateString() !== 'Invalid Date') {
          const calendar = this.data.calendarList.find((el) => {
            return (
              new Date(key).toLocaleDateString() ===
              new Date(el.weekStartDate).toLocaleDateString()
            );
          });
          const forecastObject = {
            fbForecastId: 0,
            itemId: row.item?.itemManagerId,
            measureId: row.measure?.measureId,
            channelId: row.account?.itemAccountId,
            measureCode: row.measure?.code,
            isAccount: row.measure?.isAccount,
            calendarId: calendar?.calendarId,
            distiId: row.distributor?.distributorId || 0,
            isEdited: true,
            value: row.measure?.code.indexOf('Comment') < 0 ? row[key] : 0,
            textValue:
              row.measure?.code.indexOf('Comment') > -1 ? row[key] : '',
            templateId: row.measure?.templateId,
            userFBForecastId: this.data.userFBForecastId || 0,
            versionTypeId: this.data.versionTypeId,
            userId: this.storage.get(USER_ID),
            fbTemplateId: this.data.fbTemplateId,
          };
          editViaUploadList.push(forecastObject);
        }
      });
    });
    return editViaUploadList;
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
