import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CustomHeaderComponent } from '../goal-settings/custom-header.component';
import { ButtonRendererComponent } from '../goal-settings/button-renderer.component';
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
@Component({
  selector: 'app-same-store-setting',
  templateUrl: './same-store-setting.component.html',
  styleUrls: ['./same-store-setting.component.css']
})
export class SameStoreSettingComponent implements OnInit, OnDestroy {
  public submitted = false;
  public isEdit = false;
  public columnDefs: any = [{
    headerName: 'Store',
    field: 'SameStoreRow.Name',
    pinned: 'left'
  },
  {
    headerName: '',
    field: 'cancel',
    pinned: 'left',
    cellRenderer: 'buttonRenderer',
    cellRendererParams: {
      onClick: this.openRemoveStoreDialog.bind(this),
    },
    width: 80
  }];
  private gridApi: any;
  public getRowHeight;
  public  headerHeight;
  public storeHeaderTypeList = [];
  public storeHeaderTypeListTemp = [];
  public storeList = [];
  public store: any;
  public gridOptions: any;
  public modules = AllCommunityModules;
  public clientId = 1;
  public editSubscription: Subscription;
  public removeSubscription: Subscription;
  public channelList = [];
  public storeMixFromList = [];
  public channel: any;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public dialogAddRef: MatDialogRef<DialogSameStoreChannelAddComponent>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public dialogRef: MatDialogRef<DialogSameStoreSettingsComponent>) {
    this.gridOptions = {
      frameworkComponents: { agColumnHeader: CustomHeaderComponent, buttonRenderer: ButtonRendererComponent, },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.headerHeight = 40;
    this.gridOptions.getRowHeight = function(params) {
      return 40;
    };
  }
  delete(rowData: any) {
    const row = {
      SameStoreRowId: rowData.SameStoreRow.SameStoreRowId,
      active: false,
    };
    this.spinner.show();
    if (row.SameStoreRowId) {
      this.configurationService.activateSameStoreRow(row).subscribe(res => {
        this.storeList = this.storeList.filter(Store => row.SameStoreRowId !== Store.SameStoreRow.SameStoreRowId);
        this.gridOptions.api.setRowData(this.storeList);
        this.spinner.hide();
        this.toastr.success('Success', 'Store removed successfully');
      }, error => {
        this.spinner.hide();
      });
    } else {
      this.storeList = this.storeList.filter(Store => rowData !== Store);
      this.spinner.hide();
    }

  }
  ngOnInit() {
    this.editSubscription = this.configurationService.getEditColumnSubject().subscribe(res => {
      if (res) {
        this.openColumnDialog(true, res);
      }
    });
    // this.getSameStoreFormList();
    this.removeSubscription = this.configurationService.getRemoveColumnSubject().subscribe(res => {
      if (res) {
        this.openRemoveColumnDialog(res);
      }
    });
    this.populateList();
  }
  public populateList = () => {
    this.configurationService.getAllChannels({ clientId: 1 }).subscribe(res => {
      this.channelList = res.data;
    });
  }
  public getStoreFormList = (event: any) => {

  }
  ngOnDestroy() {
    this.editSubscription.unsubscribe();
    this.removeSubscription.unsubscribe();
  }
  public getSameStoreFormList = (storeId) => {
    this.storeList = [];
    this.configurationService.getStoreMixRowList({ clientId: 1, ChannelId: this.channel.channelId }).subscribe(res => {
      this.storeMixFromList = (res || []);
    });
    this.spinner.show();
    this.configurationService.getSameStoreFormList({ clientId: 1, ChannelId: this.channel.channelId }).subscribe((Stores: any) => {
      this.storeList = Stores.pivotList || [];
      this.storeList.forEach(element => {
        element.SameStoreRow = {
          Name: element.SameStoreRow.name,
          SameStoreRowId: element.SameStoreRow.sameStoreRowId,
          ClientId: element.SameStoreRow.clientId,
          NewFormRowId: element.newFormRowId,
          Active: element.active,
          ChannelId: element.SameStoreRow.channelId
        };
        this.spinner.hide();
      });
    }, error => {
      this.spinner.hide();
    });
    this.configurationService.getSameStoreHeaderList({ clientId: 1, active: true, ChannelId: this.channel.channelId }).subscribe((res: any) => {
      this.storeHeaderTypeList = res;
      this.storeHeaderTypeListTemp = this.storeHeaderTypeList;
      this.storeHeaderTypeListTemp = this.storeHeaderTypeListTemp.map(StoreHeader => ({ id: StoreHeader.sameStoreHeaderId, headerName: StoreHeader.name, field: StoreHeader.code, active: StoreHeader.active }));
      this.columnDefs = [{
        headerName: 'Store',
        field: 'SameStoreRow.Name',
        pinned: 'left'
      },
      {
        headerName: '',
        field: 'cancel',
        pinned: 'left',
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.openRemoveStoreDialog.bind(this),
        },
        width: 80
      }];
      this.storeHeaderTypeListTemp.forEach(StoreHeader => {
        this.columnDefs.push({
          ...StoreHeader, editable: true, valueSetter: function (params) {
            params.data[params.colDef.field] = Number(params.newValue);
            return true;
          }
        });
      });
      if (this.gridOptions.api) {
        this.gridOptions.api.setColumnDefs(this.columnDefs);
      }
      this.spinner.hide();
    }, error => {
      this.columnDefs = [{
        headerName: 'Store',
        field: 'SameStoreRow.Name',
        pinned: 'left'
      },
      {
        headerName: '',
        field: 'cancel',
        pinned: 'left',
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.openRemoveStoreDialog.bind(this),
        },
        width: 80
      }];
      this.spinner.hide();
    });
  }
  populateForm(event): void {
    if (event && event.data) {
      this.isEdit = true;
      const dataToPopulate = event.data;
    }
  }
  public onSubmit = () => {
    this.submitted = true;
    let model = this.gridOptions.rowData;
    const columnList = this.columnDefs.filter(col => col.id).map(res => ({ SameStoreHeaderId: res.id, Name: res.headerName, ClientId: this.clientId, Code: res.field, Active: res.active || true, NewFormRowId: this.store }));
    model = {
      listOfSameStoreHeader: columnList,
      sameStoreRowList: model
    };
    console.log(model);
    this.spinner.show();
    this.configurationService.addSameStoreForm(model).subscribe(res => {
      this.spinner.hide();
      this.toastr.success('Success', 'Store saved successfully');
    }, error => {
      this.spinner.hide();
    });
  }
  Cancel_Click() {
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  openStoreDialog() {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogAddRef = this.dialog.open(DialogSameStoreChannelAddComponent, {
      width: '500px',
      data: { ...this.columnDefs, storeMixFromList: this.storeMixFromList }

    });

    this.dialogAddRef.afterClosed().subscribe(result => {
      if (result) {
        const list = ['SameStoreRow.Name', 'cancel'];
        const defaultFields = {};
        this.columnDefs.forEach(column => {
          if (!list.includes(column.field)) {
            defaultFields[column.field] = 0;
          }
        });
        result = {
          SameStoreRow: { ...result, ClientId: this.clientId, SameStoreRowId: 0, ChannelId: this.channel.channelId },
          ...defaultFields
        };
        this.storeList.push(result);
        this.gridOptions.api.setRowData(this.storeList);
      }
    });
  }
  openColumnDialog(isEdit?: boolean, column?: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogRef = this.dialog.open(DialogSameStoreSettingsComponent, {
      width: '500px',
      data: { ...column, isEdit: isEdit, columnDefs: this.columnDefs },
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.oldColumn) {
          this.updateColumnDef(result);
        } else {
          this.addColumnDef(result.headerName);
        }
        this.gridOptions.api.setColumnDefs(this.columnDefs);
      }
    });
  }
  public addColumnDef = (headerName: string) => {
    const model = {
      name: headerName,
      clientId: this.clientId,
      SameStoreHeaderId: 0,
      NewFormRowId: this.store,
      ChannelId: this.channel.channelId
    };
    this.spinner.show();
    this.configurationService.addSameStoreHeader(model).subscribe(res => {
      if (res) {
        const newModel = { id: res.sameStoreHeaderId, headerName: res.name, field: res.code, active: res.active };
        this.columnDefs.push({ ...newModel, editable: true });
        this.storeList.forEach(Store => {
          Store[res.code] = null;
        });
        this.gridOptions.api.setColumnDefs(this.columnDefs);
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
    });
  }
  public updateColumnDef = (result: any) => {
    const model = {
      name: result.headerName,
      code: result.field,
      active: result.active,
      clientId: this.clientId,
      SameStoreHeaderId: result.id,
      NewFormRowId: this.store,
      ChannelId: this.channel.channelId
    };
    this.spinner.show();
    this.configurationService.updateSameStoreHeader(model).subscribe(res => {
      if (res) {
        this.columnDefs.forEach(col => {
          if (col.headerName === result.oldColumn) {
            col.headerName = result.headerName;
            col.field = result.field;
          }
        });
        this.gridOptions.api.setColumnDefs(this.columnDefs);
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }
  openRemoveColumnDialog(columnToRemove?: string): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: columnToRemove
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.storeHeaderTypeListTemp = this.columnDefs;
        this.removeColumn(result);
      }
    });
  }
  public removeColumn = (column: any) => {
    if (column) {
      const model = {
        SameStoreHeaderId: column.id,
        active: false
      };
      this.spinner.show();
      this.configurationService.activateSameStoreHeader(model).subscribe(res => {
        if (res) {
          this.columnDefs = this.columnDefs.filter(col => col.id !== column.id);
          this.gridOptions.api.setColumnDefs(this.columnDefs);
          this.spinner.hide();
        }
      }, error => {
        this.spinner.hide();
      });
    }
  }
  openRemoveStoreDialog(StoreToRemove: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: StoreToRemove.rowData.SameStoreRow.name || StoreToRemove.rowData.SameStoreRow.Name }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.delete(StoreToRemove.rowData);
      }
    });
  }
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'header-name-dialog',
  templateUrl: 'header-name-dialog.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DialogSameStoreSettingsComponent {
  public headerName = '';
  date = new FormControl(_moment());
  public showErrorMessage = false;
  monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  constructor(
    public dialogRef: MatDialogRef<DialogSameStoreSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data && this.data.isEdit) {
      const headerName = this.data.headerName;
      const date = _moment(`${headerName.split('-')[1]}-${this.monthList.findIndex(res => res === headerName.split('-')[0]) + 1}-01`);
      this.date.setValue(date);
    }
  }
  chosenYearHandler(normalizedYear: any) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit() {
    if (this.date.value) {
      if (!this.data.isEdit) {
        this.data.field = this.headerName.replace(/[^A-Za-z0-9 ]/ig, '');
      }
      this.headerName = this.date.value ? this.getMonthYearValue(this.date.value) : '';
      const newColumn = {
        ...this.data,
        headerName: this.headerName,
        oldColumn: this.data.displayName,
      };
      const columnIfExists = this.data.columnDefs.find(column => column.headerName.toLowerCase() === this.headerName.toLowerCase());
      if (!columnIfExists) {
        this.showErrorMessage = false;
        this.dialogRef.close(newColumn);
      } else {
        this.showErrorMessage = true;
      }
    }
  }
  public getMonthYearValue = (date: any) => {
    return `${this.monthList[date.month()]}-${date.year()}`;
  }
}
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'store-dialog',
  templateUrl: 'same-store-channel.html',
})
export class DialogSameStoreChannelAddComponent {
  public store;
  public storeMixFromList = [];
  constructor(
    public dialogRef: MatDialogRef<DialogSameStoreChannelAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.storeMixFromList = data.storeMixFromList || [];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit() {
    if (this.store) {
      this.dialogRef.close({ Name: this.store.name, NewFormRowId: this.store.newFormRowId });
    }
  }
}
