import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AllModules, GridApi, ColumnApi, ServerSideStoreType } from '@ag-grid-enterprise/all-modules';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import {
  childGridColDefs,
  paginationPageSize,
  cacheBlockSize,
  defaultColDef
} from '../../product-manager/ag-grid/gridOptions';
import { ItemDialogComponent } from '../item-dialog/item-dialog.component';
import { ChildItemEditRenderer } from '../../product-manager/ag-grid/item-edit-renderer.component';
import { ChildItemActionCellRenderer } from '../../product-manager/ag-grid/child-item-action-cell-renderer.component';
import { ChildItemService, ItemManagerService } from 'src/services/Item-Management-Services';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { UsersService } from 'src/services/User-services/user-services';
import { INVALID_TEAM, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { getFilterModel, getSortModel } from '../../util/util';
import { tap } from 'rxjs/operators';


@Component({
  selector: 'app-item-grid-dialog',
  templateUrl: './item-grid-dialog.component.html',
  styleUrls: ['./item-grid-dialog.component.css']
})
export class ItemGridDialogComponent implements OnInit, OnDestroy {

  @Input() isDialoag?: boolean = false;

  rowModelType = 'serverSide';
  serverSideStoreType = ServerSideStoreType.Partial;
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  rowSelection = 'multiple';
  public gridColumnDefs = childGridColDefs(true);
  public defaultColDef = defaultColDef;
  public modules = AllModules;
  private gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: any;
  hardcodedFilters: any = {};
  gridData: any = [];
  frameworkComponents = {
    childItemEditRenderer: ChildItemEditRenderer,
    childItemActionCellRenderer: ChildItemActionCellRenderer
  }
  public actionSubscription: Subscription;

  selectedMasterRow: any;
  selectedItems: any = [];

  userTeam: any = '';
  isRetailerTeam: boolean = false;
  filterEvent: any = {};

  constructor(
    public dialogRef: MatDialogRef<ItemGridDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private childItemService: ChildItemService,
    private itemManagerService: ItemManagerService,
    private usersService: UsersService,
    public toastr: NgxToasterService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>) { }

  ngOnInit() {
    this.setRoleBaseAccess();
    this.actionSubscription = this.childItemService.getChildGridActionSubject().subscribe(res => {
      if (res) {
        this.performGridAction(res);
      }
    });
    if (this.data.selectedMasterRow) {
      this.selectedMasterRow = this.data.selectedMasterRow;
    } else if (this.data.filterEvent) {
      this.hardcodedFilters = this.data.filterEvent;
    }
    this.getFormContorlsData();
  }

  setRoleBaseAccess() {
    this.userTeam = this.usersService.checkLoginUserTeam();
    switch (this.userTeam) {
      case VENDOR_TEAM_CODE: {
        this.isRetailerTeam = false;
        break;
      }
      case RETAILER_TEAM_CODE: {
        this.isRetailerTeam = true;
        break;
      }
      case INVALID_TEAM: {
        console.log('Valid Team Not assigned');
        break;
      }
    }

    this.gridColumnDefs = childGridColDefs(true, this.isRetailerTeam);
    this.gridApi && this.gridApi.setColumnDefs(this.gridColumnDefs);
  }

  performGridAction(actionObject) {
    switch (actionObject.action) {
      case 'edit': {
        this.openItemDialog(true, actionObject.actionData);
        break;
      }
      case 'delete': {
        this.openDeleteDialog(actionObject.actionData);
        break;
      }
    }
  }

  getFormContorlsData() {
    this.itemManagerService.GetAllRatings().toPromise();
    this.itemManagerService.GetAllClasses().toPromise();
    this.itemManagerService.GetAllSubClasses().toPromise();
    this.itemManagerService.GetAllAccounts().toPromise();
  }

  createGridDataSource() {
    const datasource = {
      getRows: (params) => {
        const sortModel = params.request.sortModel;
        const filterModel = params.request.filterModel;
        const requestBody: any = {
          offSet: params.request.startRow ? params.request.startRow : 0,
          pageSize: paginationPageSize,
          ...this.hardcodedFilters,
          ...this.filterEvent,
          ...this.data.selectedMasterRow && { masterItemId: this.selectedMasterRow.masterItemId },
          ...this.data.promotionItemId && { promotionItemId: this.data.promotionItemId },
          ...this.data.promotionItemPlannerId && { promotionItemPlannerId: this.data.promotionItemPlannerId },
        };
        requestBody.filterModel = getFilterModel(filterModel);
        requestBody.sortModel = getSortModel(sortModel);

        this.childItemService
          .GetAllChildItems(requestBody)
          .pipe(
            tap(() => this.gridApi.hideOverlay())
          )
          .subscribe((response: any) => {
            params.successCallback(response.data, response.totalRecord);
            response.data.length == 0 && this.gridApi.showNoRowsOverlay();
          });
      }
    };
    this.gridApi && this.gridApi.setServerSideDatasource(datasource);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.createGridDataSource();
  }

  onExportGridData() {
    this.gridApi.exportDataAsCsv();
  }

  gridRowSelectionChanged(params) {
    this.selectedItems = params.api.getSelectedRows();
  }

  openItemDialog(isEdit = false, data = {}): void {
    if (!this.childItemService.childItemDialogRef) {
      const dialogRef = this.dialog.open(ItemDialogComponent, {
        data: {
          isEdit: isEdit,
          isMasterPage: false,
          formData: {
            ...data
          },
          selectedMasterRow: this.selectedMasterRow
        }
      });
      this.childItemService.childItemDialogRef = dialogRef;
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.gridApi.refreshServerSideStore({ purge: true });
        }
        this.childItemService.childItemDialogRef = null;
      });
    }

  }

  openColumnDialog() {
    this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi
      },
      width: '700px'
    });
  }

  openDeleteDialog(actionData) {
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: actionData.itemDescription }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteChildItem(actionData);
      }
    });
  }

  deleteChildItem(actionData) {
    this.childItemService.DeleteChildItem(actionData.childItemId).subscribe((res: any) => {
      this.toastr.success('Success', 'Child Item Deleted Successfully.');
      this.gridApi.refreshServerSideStore({ purge: true });
    }, err => {
      this.toastr.error('Error', 'Child Item Deletion Failed.');
    });
  }

  applyFilters(event) {
    const masterItemId = _.get(this.selectedMasterRow, 'masterItemId');
    if (masterItemId) {
      event['masterItemId'] = this.selectedMasterRow.masterItemId;
    }
    this.filterEvent = {
      ...event
    };
    this.createGridDataSource();
  }

  clearFilters() {
    this.filterEvent = {};
    this.createGridDataSource();
  }

  onDoneClick() {
    this.dialogRef.close({ selectedItems: this.selectedItems });
  }

  ngOnDestroy() {
    this.actionSubscription.unsubscribe();
  }
}
