<div class="container">
  <div class="user-page">
    <div class="row">
      <div class="col-md-1 d-block d-sm-block d-md-none mobile-icon">
        <div class="btn-show">
          <button (click)="openAddDialog({})" mat-icon-button color="primary">
            <i class="material-icons">
              add_circle
            </i>
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <form action="" class="form-fields show-search">
          <div class="form-group">
            <mat-form-field>
              <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-md-8 d-none d-sm-none d-md-block tablet-icon">
        <div class="btn-show">
          <button (click)="openAddDialog({})" mat-icon-button color="primary">
            <i class="material-icons">
              add_circle
            </i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material"   [suppressMenuHide]="true"
       groupHeaders suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
      (cellDoubleClicked)="onCellClicked($event)" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
      [rowData]="businessInfoList" (gridReady)="onGridReady($event)" [modules]="modules" #grid></ag-grid-angular>
  </div>
</div>
<ng-template #Adddialog let-data>
  <h1 mat-dialog-title>Business Information</h1>
  <div mat-dialog-content>
    <form [formGroup]="businessInfoForm">
      <div class="row">
        <ng-container *ngFor="let control of attributeList">
          <div class="col-md-4">
            <ng-container *ngIf="control.answerTypeId === 1">
              <mat-form-field>
                <input matInput [formControlName]="control.code" [placeholder]="control.title">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="control.answerTypeId === 2">
              <mat-form-field>
                <mat-select [formControlName]="control.code" [placeholder]="control.title">
                  <mat-option *ngFor="let res of control.optionList; let i = index" [value]="res.optionId.toString()">
                    {{res.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="control.answerTypeId === 3">
              <mat-form-field>
                <mat-select [formControlName]="control.code" [placeholder]="control.title" multiple="true">
                  <mat-option *ngFor="let res of control.optionList; let i = index" [value]="res.optionId">
                    {{res.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="action-btns text-right">
        <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit(data)">Save</button>
      </div>
    </form>
  </div>
</ng-template>
