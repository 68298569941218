<div class="d-flex align-items-center w-100 p-2">
    <h6 class="m-0 mr-5">
        Company Level {{yearly?'Fiscal Year':'Quarterly'}} Targets
    </h6>
    <mat-slide-toggle class="ml-4" [(ngModel)]="yearly" color="warn">
        {{yearly ? 'Year' : 'Quarter'}}
    </mat-slide-toggle>
</div>
<app-yearly-planning *ngIf="yearly"></app-yearly-planning>
<app-quarterly-planning *ngIf="!yearly"></app-quarterly-planning>
