import { ForecastSetupComponent } from './../modules/forecast-engin/features/forecast-manager/forecast-setup/forecast-setup.component';
import { ForecastManagerComponent } from '../modules/forecast-engin/features/forecast-manager/forecast-manager.component';
import { InsightCustomerDetailViewComponent } from "src/modules/app-insight-customer/insight-customer-detail-view/insight-customer-detail-view.component";
import { InsightCustomerListComponent } from "src/modules/app-insight-customer/insight-customer-list/insight-customer-list.component";
import { ApparelCountryComponent } from "src/modules/apparel/apparel-country/apparel-country.component";
import { BusinessMetadataComponent } from "src/modules/apparel/business-metadata/business-metadata.component";
import { AdminSettingsComponent } from "src/modules/auth/features/admin-settings/admin-settings.component";
import { FacebookAccountSettingsComponent } from "src/modules/auth/features/admin-settings/facebook-account-settings/facebook-account-settings.component";
import { FacebookProductSettingsComponent } from "src/modules/auth/features/admin-settings/facebook-product-settings/facebook-product-settings.component";
import { ResetPasswordComponent } from "src/modules/auth/features/login/reset-password/reset-password.component";
import { CreateUpdateRoleComponent } from "src/modules/auth/features/roles/create-update-role/create-update-role.component";
import { RolesComponent } from "src/modules/auth/features/roles/roles.component";
import { CreateUpdateTeamComponent } from "src/modules/auth/features/teams/create-update-team/create-update-team.component";
import { CurrencyComponent } from "src/modules/auth/features/teams/currency/currency.component";
import { FinanceComponent } from "src/modules/auth/features/teams/finance/finance.component";
import { TeamsComponent } from "src/modules/auth/features/teams/teams.component";
import { CreateUpdateUserComponent } from "src/modules/auth/features/users/create-update-user/create-update-user.component";
import { UsersComponent } from "src/modules/auth/features/users/users.component";
import { BudgetComponent } from "src/modules/cpm/features/budget/budget.component";
import { BusinessAttributesComponent } from "src/modules/cpm/features/business-attributes/business-attributes.component";
import { BusinessInformationComponent } from "src/modules/cpm/features/business-information/business-information.component";
import { DecayCurveCreationComponent } from "src/modules/cpm/features/decay-curve/decay-curve-creation/decay-curve-creation.component";
import { DecayCurveComponent } from "src/modules/cpm/features/decay-curve/decay-curve.component";
import { DynamicSimulationComponent } from "src/modules/cpm/features/dynamic-simulation/dynamic-simulation.component";
import { ItemPlanComponent } from "src/modules/cpm/features/item-plan/item-plan.component";
import { LevelsComponent } from "src/modules/cpm/features/levels/levels.component";
import { PromotionFormV2Component } from "src/modules/cpm/features/promotion-v2/promotion-form-v2/promotion-form-v2.component";
import { PromotionListingV2Component } from "src/modules/cpm/features/promotion-v2/promotion-listing-v2/promotion-listing-v2.component";
import { PromotionV2Component } from "src/modules/cpm/features/promotion-v2/promotion-v2.component";
import { SkuListLinkV2Component } from "src/modules/cpm/features/promotion-v2/sku-list-link-v2/sku-list-link-v2.component";
import { PromotionFormComponent } from "src/modules/cpm/features/promotion/promotion-form/promotion-form.component";
import { PromotionListingComponent } from "src/modules/cpm/features/promotion/promotion-listing/promotion-listing.component";
import { PromotionComponent } from "src/modules/cpm/features/promotion/promotion.component";
import { SkuListLinkComponent } from "src/modules/cpm/features/promotion/sku-list-link/sku-list-link.component";
import { SkuCreationFormComponent } from "src/modules/cpm/features/sku-listing/sku-creation-form/sku-creation-form.component";
import { SkuListingComponent } from "src/modules/cpm/features/sku-listing/sku-listing.component";
import { TitleListingComponent } from "src/modules/cpm/features/title-listing/title-listing.component";
import { WorkGroupComponent } from "src/modules/cpm/features/work-group/work-group.component";
import { FacebookComponent } from "src/modules/facebook/facebook.component";
import { BestbuyForecastComponent } from "src/modules/facebook/features/bestbuy-forecast/bestbuy-forecast.component";
import { FacebookForecastV2Component } from "src/modules/facebook/features/facebook-forecast-v2/facebook-forecast-v2.component";
import { FacebookMethodForecastComponent } from "src/modules/facebook/features/facebook-forecast-v2/facebook-method-forecast/facebook-method-forecast.component";
import { FacebookForecastComponent } from "src/modules/facebook/features/facebook-forecast/facebook-forecast.component";
import { FbForecastComponent } from "src/modules/facebook/features/fb-forecast/fb-forecast.component";
import { ForcastComponent } from "src/modules/facebook/features/forcast/forcast.component";
import { ForecastV2Component } from "src/modules/facebook/features/forcast/forecast-v2/forecast-v2.component";
import { GlobalDemandPlannerNewComponent } from "src/modules/facebook/features/global-demand-planner-new/global-demand-planner-new.component";
import { InventoryPlannerComponent } from "src/modules/facebook/features/inventory-planner/inventory-planner.component";
import { MethodForecastComponent } from "src/modules/facebook/features/method-forecast/method-forecast.component";
import { NewItemPlannerComponent } from "src/modules/facebook/features/new-item-planner/new-item-planner.component";
import { RegionalDemandPlannerNewComponent } from "src/modules/facebook/features/regional-demand-planner-new/regional-demand-planner-new.component";
import { RetailIndirectPlannerComponent } from "src/modules/facebook/features/retail-indirect-planner/retail-indirect-planner.component";
import { VersionForecastComponent } from "src/modules/facebook/features/version-forecast/version-forecast.component";
import { ClusteringForecastComponent } from "src/modules/clustering-forecast/clustering-forecast.component";
import { PlanogramOptimizerComponent } from "src/modules/item-manager/features/planogram-optimizer/planogram-optimizer.component";
import { ItemManagerComponent } from "src/modules/item-manager/item-manager.component";
import { AccountFormComponent } from "src/modules/items/account/account-form/account-form.component";
import { AccountComponent } from "src/modules/items/account/account.component";
import { ItemFormComponent } from "src/modules/items/item-form/item-form.component";
import { ItemsComponent } from "src/modules/items/items.component";
import { ActivityComponent } from "src/modules/layout/features/activity/activity.component";
import { AlgoMenuComponent } from "src/modules/layout/features/algo-menu/algo-menu.component";
import { AnomolyDetectorFormComponent } from "src/modules/layout/features/anomoly-detector-form/anomoly-detector-form.component";
import { InsightListingV2Component } from "src/modules/layout/features/app-insight-v2/insight-listing-v2/insight-listing-v2.component";
import { InsightListingComponent } from "src/modules/layout/features/app-insight/insight-listing/insight-listing.component";
import { NotificationConfigurationComponent } from "src/modules/layout/features/notification-configuration/notification-configuration.component";
import { SuggestAdminComponent } from "src/modules/layout/features/suggest-admin/suggest-admin.component";
import { UserInterfaceComponent } from "src/modules/layout/features/user-interface/user-interface.component";
import { ChatDetailsComponent } from "src/modules/messaging/chat-details/chat-details.component";
import { CalenderListComponent } from "src/modules/mfp/features/calender-list/calender-list.component";
import { CategorySettingsComponent } from "src/modules/mfp/features/category-settings/category-settings.component";
import { ChannelViewComponent } from "src/modules/mfp/features/channel-view/channel-view.component";
import { ChannelComponent } from "src/modules/mfp/features/channel/channel.component";
import { CustomerDistributorComponent } from "src/modules/mfp/features/customer-distributor/customer-distributor.component";
import { GoalSettingsComponent } from "src/modules/mfp/features/goal-settings/goal-settings.component";
import { NewStoreSettingComponent } from "src/modules/mfp/features/new-store-setting/new-store-setting.component";
import { SameStoreSettingComponent } from "src/modules/mfp/features/same-store-setting/same-store-setting.component";
import { SourceChannelWiseConfigComponent } from "src/modules/mfp/features/source-channel-wise-config/source-channel-wise-config.component";
import { SourceComponent } from "src/modules/mfp/features/source/source.component";
import { MonthWiseSameComponent } from "src/modules/mfp/features/summary-by-year/month-wise-same/month-wise-same.component";
import { SummaryByYearComponent } from "src/modules/mfp/features/summary-by-year/summary-by-year.component";
import { OrderPlannerComponent } from "src/modules/microsoft/features/order-planner/order-planner.component";
import { MicrosoftComponent } from "src/modules/microsoft/microsoft.component";
import { PdmCategoryComponent } from "src/modules/pdm/features/pdm-category/pdm-category.component";
import { PdmFormComponent } from "src/modules/pdm/features/pdm-form/pdm-form.component";
import { DocumentComponent } from "src/modules/pdm/features/pdm-form/pdm-listing/document/document.component";
import { ImageComponent } from "src/modules/pdm/features/pdm-form/pdm-listing/image/image.component";
import { PdmListingComponent } from "src/modules/pdm/features/pdm-form/pdm-listing/pdm-listing.component";
import { ProductCopyComponent } from "src/modules/pdm/features/pdm-form/pdm-listing/product-copy/product-copy.component";
import { VideoComponent } from "src/modules/pdm/features/pdm-form/pdm-listing/video/video.component";
import { TemplateComponent } from "src/modules/pdm/features/template/template.component";
import { PromotionListComponent } from "src/modules/promotion/features/promotion-list/promotion-list.component";
import { DynamicReportComponent } from "src/modules/reporting/features/dynamic-report/dynamic-report.component";
import { QNAReportComponent } from "src/modules/reporting/features/qna-report/qna-report.component";
import { PowerBiEmbeddedComponent } from "src/modules/reporting/features/reports/power-bi-embedded/power-bi-embedded.component";
import { ReportingComponent } from "src/modules/reporting/reporting.component";
import {
  ItemApprovalDetailComponent,
  ItemListComponent,
  ItemPromotionDetailComponent,
  ItemPromotionListComponent,
  MasterItemListComponent,
  BBNewItemPlannerComponent,
  PromotionPlannerComponent,
  PromotionPlannerDetailComponent
} from '../modules/item-manager/features';
import { ApparelSeasonComponent } from "src/modules/apparel/apparel-season/apparel-season.component";
import { FileManagementComponent } from "src/modules/clustering-forecast/file-management/file-management.component";
import {
  AddEditMappingForecastItemComponent,
  EditSeasonalityComponent,
  ForecastDataManagerComponent,
  MappingManagerComponent,
  OutlierManagerComponent,
  PromoManagerComponent,
  PromoSetupDetailComponent,
  SeasonalityManagerComponent,
  SeasonalitySetupComponent
} from 'src/modules/forecast-engin/features';
import {
  DemoBBNewItemPlannerComponent,
  DemoItemApprovalDetailComponent,
  DemoItemPromotionDetailComponent,
  DemoItemPromotionListComponent,
  DemoMasterChildItemListComponent,
  DemoMasterItemListComponent
} from 'src/modules/demo-manager/features';
import { MerchandiseFinancialPlanningComponent } from 'src/modules/merchandise-financial-planning/merchandise-financial-planning.component';
import { ReplenishmentModuleComponent } from 'src/modules/replenishment-module/replenishment-module.component';
import { ClientsComponent } from 'src/modules/auth/features/clients/clients.component';
import { HierarchySetupWhiteLabelingComponent } from 'src/modules/auth/features/hierarchy-setup-white-labeling/hierarchy-setup-white-labeling.component';
import { HierarchySetupComponent } from 'src/modules/auth/features/hierarchy-setup/hierarchy-setup.component';
import { HierarchyManagerComponent } from 'src/modules/auth/features/hierarchy-manager/hierarchy-manager.component';
import { TabConfigurationComponent } from 'src/modules/auth/features/tab-configuration/tab-configuration.component';


export class Page {
  menuId: string | number;
  name: string;
  selector: string;
  module: string;
  [key: string]: any;
}
export const Pages: Page[] = [
  {
    menuId: '1',
    name: 'Channel',
    selector: 'app-channel',
    component: ChannelComponent,
    module: 'MfpModule',
  },
  {
    menuId: '2',
    name: 'source',
    selector: 'app-source',
    module: 'MfpModule',
    component: SourceComponent,
  },
  {
    menuId: '3',
    name: 'distributor',
    selector: 'app-distributor',
    module: 'MfpModule',
    component: CustomerDistributorComponent,
  },
  {
    menuId: '4',
    name: 'Change Password',
    selector: 'app-reset-password',
    module: 'Auth',
    component: ResetPasswordComponent,
  },
  {
    menuId: '5',
    name: 'activity',
    selector: 'app-activity',
    module: 'app',
    component: ActivityComponent,
  },
  {
    menuId: '6',
    name: 'roles',
    selector: 'app-roles',
    module: 'Auth',
    component: RolesComponent,
  },
  {
    menuId: '14',
    name: 'Menus',
    selector: 'app-algo-menu',
    module: 'app',
    component: AlgoMenuComponent,
  },
  {
    menuId: '15',
    name: 'Calendar',
    selector: 'app-calender-list',
    module: 'MfpModule',
    component: CalenderListComponent,
  },
  // {
  //   menuId: '15',
  //   name: 'Calendar Setting',
  //   selector: 'app-calender-setting',
  // },
  {
    menuId: '16',
    name: 'Category Setting',
    selector: 'app-category-settings',
    module: 'MfpModule',
    component: CategorySettingsComponent,
  },
  {
    menuId: '17',
    name: 'Category',
    selector: 'app-channel-view',
    module: 'MfpModule',
    component: ChannelViewComponent,
  },
  {
    menuId: '19',
    name: 'Goal Setting',
    selector: 'app-goal-settings',
    module: 'MfpModule',
    component: GoalSettingsComponent,
  },
  {
    menuId: '22',
    name: 'New Store Setting',
    selector: 'app-new-store-setting',
    module: 'MfpModule',
    component: NewStoreSettingComponent,
  },
  {
    menuId: '36',
    name: 'Same Store Setting',
    selector: 'app-same-store-setting',
    module: 'MfpModule',
    component: SameStoreSettingComponent,
  },
  {
    menuId: '37',
    name: 'Source & Channel Wise Config',
    selector: 'app-source-channel-wise-config',
    module: 'MfpModule',
    component: SourceChannelWiseConfigComponent,
  },
  {
    menuId: '38',
    name: 'Source Settings',
    selector: 'app-source-settings',
    module: 'MfpModule',
    component: SourceChannelWiseConfigComponent,
  },
  {
    menuId: '39',
    name: 'Users',
    selector: 'app-users',
    module: 'Auth',
    component: UsersComponent,
  },
  {
    menuId: '40',
    name: 'Announcement Configuration',
    selector: 'app-notification-configuration',
    module: 'app',
    component: NotificationConfigurationComponent,
  },
  {
    menuId: '41',
    name: 'Summary By Year',
    selector: 'app-summary-by-year',
    displayName: 'Summary By Year',
    module: 'MfpModule',
    component: SummaryByYearComponent,
  },
  {
    menuId: '42',
    name: 'Month Wise Same',
    selector: 'app-month-wise-same',
    displayName: 'Month Wise Same',
    module: 'MfpModule',
    component: MonthWiseSameComponent,
  },
  {
    menuId: '45',
    name: 'User Interface Activity',
    selector: 'app-user-interface',
    displayName: 'User Interface Activity',
    module: 'app',
    component: UserInterfaceComponent,
  },
  {
    menuId: '44',
    name: 'Budget',
    selector: 'app-budget',
    displayName: 'Budget',
    module: 'PdmModule',
    component: BudgetComponent,
  },
  {
    menuId: '45',
    name: 'Promotion',
    selector: 'app-promotion',
    displayName: 'Promotion',
    module: 'PdmModule',
    component: PromotionComponent,
  },
  {
    menuId: '45',
    name: 'Promotion V2',
    selector: 'app-promotion-v2',
    displayName: 'Promotion V2',
    module: 'CpmModule',
    component: PromotionV2Component,
  },
  {
    menuId: '46',
    name: 'Reporting',
    selector: 'app-reporting',
    displayName: 'Reporting',
    module: 'ReportingModule',
    component: ReportingComponent,
  },
  {
    menuId: '48',
    name: 'Title',
    selector: 'app-title-listing',
    displayName: 'Title',
    module: 'PdmModule',
    component: TitleListingComponent,
  },
  {
    menuId: '49',
    name: 'SKU',
    selector: 'app-sku-listing',
    displayName: 'SKU',
    module: 'PdmModule',
    component: SkuListingComponent,
  },
  {
    menuId: '450',
    name: 'All Promotion',
    selector: 'app-promotion-listing',
    displayName: 'All Promotion',
    module: 'PdmModule',
    component: PromotionListingComponent,
  },
  {
    menuId: '450',
    name: 'All Promotion V2',
    selector: 'app-promotion-listing-v2',
    displayName: 'All Promotion V2',
    module: 'CpmModule',
    component: PromotionListingV2Component,
  },

  {
    menuId: '451',
    name: 'Create Promotion',
    selector: 'app-promotion-form',
    displayName: 'Create Promotion',
    module: 'PdmModule',
    component: PromotionFormComponent,
  },

  {
    menuId: '451',
    name: 'Create Promotion V2',
    selector: 'app-promotion-form-v2',
    displayName: 'Create Promotion V2',
    module: 'CpmModule',
    component: PromotionFormV2Component,
  },
  {
    menuId: '451',
    name: 'SKU List',
    selector: 'app-sku-list-link',
    displayName: 'SKU List',
    module: 'CpmModule',
    component: SkuListLinkComponent,
  },
  {
    menuId: '451',
    name: 'SKU List',
    selector: 'app-sku-list-link-v2',
    displayName: 'SKU List',
    module: 'CpmModule',
    component: SkuListLinkV2Component,
  },
  {
    menuId: '56',
    name: 'Create Master Item',
    selector: 'app-sku-creation-form',
    displayName: 'Create Master Item',
    module: 'CpmModule',
    component: SkuCreationFormComponent,
  },
  {
    menuId: '50',
    name: 'Item Plan',
    selector: 'app-item-plan',
    displayName: 'Item Plan',
    module: 'PdmModule',
    component: ItemPlanComponent,
  },
  {
    menuId: '51',
    name: 'All Decay Curve',
    selector: 'app-decay-curve',
    displayName: 'Decay Curve',
    module: 'PdmModule',
    component: DecayCurveComponent,
  },
  {
    menuId: '52',
    name: 'Create/Build Decay Curve',
    selector: 'app-decay-curve-creation-form',
    displayName: 'Create/Build Decay Curve',
    module: 'PdmModule',
    component: DecayCurveCreationComponent,
  },
  {
    menuId: '465',
    name: 'PDM Creation',
    selector: 'app-pdm-form',
    displayName: 'PDM Creation',
    module: 'PdmModule',
    component: PdmFormComponent,
  },
  {
    menuId: '466',
    name: 'Item Manager',
    selector: 'app-pdm-listing',
    displayName: 'Item Manager',
    module: 'PdmModule',
    component: PdmListingComponent,
  },
  {
    menuId: '436',
    name: 'Product Copy',
    selector: 'app-product-copy',
    displayName: 'Product Copy',
    module: 'PdmModule',
    component: ProductCopyComponent,
  },
  {
    menuId: '496',
    name: 'Image',
    selector: 'app-image',
    displayName: 'Image',
    module: 'PdmModule',
    component: ImageComponent,
  },
  {
    menuId: '486',
    name: 'Video',
    selector: 'app-video',
    displayName: 'Video',
    module: 'PdmModule',
    component: VideoComponent,
  },
  {
    menuId: '476',
    name: 'Document',
    selector: 'app-document',
    displayName: 'Document',
    module: 'PdmModule',
    component: DocumentComponent,
  },
  {
    menuId: '43',
    name: 'Business Information',
    selector: 'app-business-information',
    displayName: 'Business Information',
    module: 'CpmModule',
    component: BusinessInformationComponent,
  },
  {
    menuId: '43',
    name: 'Business Attributes',
    selector: 'app-business-attributes',
    displayName: 'Business Attributes',
    module: 'CpmModule',
    component: BusinessAttributesComponent,
  },
  {
    menuId: '43',
    name: 'Business Levels',
    selector: 'app-levels',
    displayName: 'Business Levels',
    module: 'CpmModule',
    component: LevelsComponent,
  },
  {
    menuId: '43',
    name: 'Workgroup',
    selector: 'app-work-group',
    displayName: 'Workgroup',
    module: 'CpmModule',
    component: WorkGroupComponent,
  },
  {
    menuId: '48',
    name: 'Category Template',
    selector: 'app-template',
    displayName: 'Category Template',
    module: 'PdmModule',
    component: TemplateComponent,
  },
  {
    menuId: '49',
    name: 'PDM Category',
    selector: 'app-pdm-category',
    displayName: 'PDM Category',
    module: 'PdmModule',
    component: PdmCategoryComponent,
  },
  {
    menuId: '68',
    name: 'Power Bi',
    selector: 'app-power-bi-embedded',
    displayName: 'Power Bi',
    module: 'ReportingModule',
    component: PowerBiEmbeddedComponent,
  },
  {
    menuId: '74',
    name: 'Anomoly Detector',
    selector: 'app-anomoly-detector-form',
    displayName: 'Anomoly Detector',
    module: 'app',
    component: AnomolyDetectorFormComponent,
  },
  {
    menuId: '74',
    name: 'Anomoly Detector',
    selector: 'app-dynamic-report',
    displayName: 'Anomoly Detector',
    module: 'ReportingModule',
    component: DynamicReportComponent,
  },
  {
    menuId: '75',
    name: 'QnA Report',
    selector: 'app-qna-report',
    displayName: 'QnA Report',
    module: 'ReportingModule',
    component: QNAReportComponent,
  },
  {
    menuId: '86',
    name: 'Simulations',
    selector: 'app-dynamic-simulation',
    displayName: 'Simulations',
    module: 'ReportingModule',
    component: DynamicSimulationComponent,
  },
  {
    menuId: '87',
    name: 'Application Insight',
    selector: 'app-insight-listing',
    displayName: 'Application Insight',
    module: 'app',
    component: InsightListingComponent,
  },
  {
    menuId: '88',
    name: 'Forecast Planner',
    selector: 'app-forcast',
    displayName: 'Forecast Planner',
    module: 'FbModule',
    component: ForcastComponent,
  },
  {
    menuId: '89',
    name: 'Method Forecast Planner',
    selector: 'app-method-forecast-planner',
    displayName: 'Method Forecast Planner',
    module: 'FbModule',
    component: MethodForecastComponent,
  },
  {
    menuId: '90',
    name: 'Version Forecast Planner',
    selector: 'app-version-forecast-planner',
    displayName: 'Version Forecast Planner',
    module: 'FbModule',
    component: VersionForecastComponent,
  },
  {
    menuId: '91',
    name: 'Suggest Admin',
    selector: 'app-suggest-admin',
    displayName: 'Suggest Admin',
    module: 'app',
    component: SuggestAdminComponent,
  },
  {
    menuId: '92',
    name: 'Best Buy Forecast',
    selector: 'app-bestbuy-forecast',
    displayName: 'Best Buy Forecast',
    module: 'FbModule',
    component: BestbuyForecastComponent,
  },
  {
    menuId: '92',
    name: 'Facebook Forecast',
    selector: 'app-facebook-forecast',
    displayName: 'Facebook Forecast (save file to title)',
    module: 'FbModule',
    component: FacebookForecastComponent,
  },
  {
    menuId: '93',
    name: 'Facebook Forecast V2',
    selector: 'app-facebook-forecast-v2',
    displayName: 'Facebook Forecast V2',
    module: 'FbModule',
    component: FacebookForecastV2Component,
  },
  {
    menuId: '89',
    name: 'Application Insight V2',
    selector: 'app-insight-listing-v2',
    displayName: 'Application Insight V2',
    module: 'app',
    component: InsightListingV2Component,
  },
  {
    menuId: '89',
    name: 'Forecast V2',
    selector: 'app-forecast-v2',
    displayName: 'Forecast V2',
    module: 'FbModule',
    component: ForecastV2Component,
  },
  {
    menuId: '90',
    name: 'Method Forecast',
    selector: 'app-facebook-method-forecast',
    displayName: 'Method Forecast',
    module: 'FbModule',
    component: FacebookMethodForecastComponent,
  },
  {
    menuId: '91',
    name: 'Admin Settings',
    selector: 'app-admin-settings',
    displayName: 'Admin Settings',
    module: 'app',
    component: AdminSettingsComponent,
  },
  {
    menuId: '92',
    name: 'Role Setting',
    selector: 'app-create-update-role',
    displayName: 'Role Setting',
    module: 'Auth',
    component: CreateUpdateRoleComponent,
  },
  {
    menuId: '93',
    name: 'User Setting',
    selector: 'app-create-update-user',
    displayName: 'User Setting',
    module: 'Auth',
    component: CreateUpdateUserComponent,
  },
  {
    menuId: '94',
    name: 'Team Setting',
    selector: 'app-create-update-team',
    displayName: 'User Setting',
    module: 'Auth',
    component: CreateUpdateTeamComponent,
  },
  {
    menuId: '95',
    name: 'Teams',
    selector: 'app-teams',
    displayName: 'Teams',
    module: 'Auth',
    component: TeamsComponent,
  },
  {
    menuId: '96',
    name: 'Currency Setting',
    selector: 'app-currency',
    displayName: 'Currency Setting',
    module: 'Auth',
    component: CurrencyComponent,
  },
  {
    menuId: '97',
    name: 'Finance Setting',
    selector: 'app-finance',
    displayName: 'Finance Setting',
    module: 'Auth',
    component: FinanceComponent,
  },
  {
    menuId: '20',
    name: 'Item Manager',
    selector: 'app-item-manager',
    displayName: 'Item Manager',
    module: 'ItemManagerModule',
    component: ItemManagerComponent
  },
  {
    menuId: '21',
    name: 'Master Item List',
    selector: 'app-master-item-list',
    displayName: 'Item Manager',
    module: 'ItemManagerModule',
    component: MasterItemListComponent
  },
  {
    menuId: '22',
    name: 'Item List',
    selector: 'app-item-list',
    displayName: 'Item List',
    module: 'ItemManagerModule',
    component: ItemListComponent
  },
  {
    menuId: '38',
    name: 'New Item Planner',
    selector: 'app-new-item-planner',
    displayName: 'New Item Planner',
    module: 'ItemManagerModule',
    component: BBNewItemPlannerComponent
  },
  {
    menuId: '39',
    name: 'New Item Promotions',
    selector: 'app-item-promotion-list',
    displayName: 'New Item Promotions',
    module: 'ItemManagerModule',
    component: ItemPromotionListComponent
  },
  {
    menuId: '399',
    name: 'New Item Promotion Detail',
    selector: 'app-item-promotion-detail',
    displayName: 'New Item Promotions',
    module: 'ItemManagerModule',
    component: ItemPromotionDetailComponent
  },
  {
    menuId: '40',
    name: 'New Item Approvals',
    selector: 'app-item-approval-detail',
    displayName: 'New Item Approvals',
    module: 'ItemManagerModule',
    component: ItemApprovalDetailComponent
  },
  {
    menuId: '98',
    name: 'Facebook',
    selector: 'app-facebook',
    displayName: 'Facebook',
    module: 'FbModule',
    component: FacebookComponent,
  },
  {
    menuId: '99',
    name: 'Facebook Forecast',
    selector: 'app-fb-forecast',
    displayName: 'Facebook Forecast (save file to title)',
    module: 'FbModule',
    component: FbForecastComponent,
  },
  {
    menuId: '100',
    name: 'Item Manager',
    selector: 'app-items',
    displayName: 'Item Manager',
    module: 'ItemsModule',
    component: ItemsComponent,
  },
  {
    menuId: '101',
    name: 'Item Form',
    selector: 'app-item-form',
    displayName: 'Item Form',
    module: 'ItemsModule',
    component: ItemFormComponent,
  },
  {
    menuId: '102',
    name: 'Account Manager',
    selector: 'app-account',
    displayName: 'Account',
    module: 'ItemsModule',
    component: AccountComponent,
  },
  {
    menuId: '103',
    name: 'Account Form',
    selector: 'app-account-form',
    displayName: 'Account Form',
    module: 'ItemsModule',
    component: AccountFormComponent,
  },
  {
    menuId: '104',
    name: 'Account Settings',
    selector: 'app-facebook-account-settings',
    displayName: 'Account Settings',
    module: 'Auth',
    component: FacebookAccountSettingsComponent,
  },
  {
    menuId: '104',
    name: 'Product Settings',
    selector: 'app-facebook-product-settings',
    displayName: 'Product Settings',
    module: 'Auth',
    component: FacebookProductSettingsComponent,
  },
  {
    menuId: '59',
    name: 'Promotion Planner',
    selector: 'app-promotion-planner',
    displayName: 'Promotion Planner',
    module: 'ItemManagerModule',
    component: PromotionPlannerComponent
  },
  {
    menuId: '399',
    name: 'Promotion Planner',
    selector: 'app-promotion-planner-detail',
    displayName: 'Promotion Planner',
    module: 'ItemManagerModule',
    component: PromotionPlannerDetailComponent
  },
  {
    menuId: '105',
    name: 'Promotion Planner',
    selector: 'app-promotion-list',
    displayName: 'Promotion Planner',
    module: 'Promotion',
    component: PromotionListComponent,
  },
  {
    menuId: '1069',
    name: 'Actionable Insights',
    selector: 'app-insight-customer-list',
    displayName: 'Actionable Insights',
    module: 'AppInsight',
    component: InsightCustomerListComponent,
  },
  {
    menuId: '1069',
    name: 'Insight Details',
    selector: 'app-insight-customer-detail-view',
    displayName: 'Insight Details',
    module: 'AppInsight',
    component: InsightCustomerDetailViewComponent,
  },
  {
    menuId: '69',
    name: 'Planogram Optimizer',
    selector: 'app-planogram-optimizer',
    displayName: 'Planogram Optimizer',
    module: 'ItemManagerModule',
    component: PlanogramOptimizerComponent
  },
  {
    menuId: '70',
    name: 'Self-Service Clustering',
    selector: 'app-clustering-forecast',
    displayName: 'Self-Service Clustering',
    module: 'ClusteringForecastModule',
    component: ClusteringForecastComponent
  },
  {
    menuId: '71',
    name: 'Files & Data Management',
    selector: 'app-file-data-management',
    displayName: 'Files & Data Management',
    module: 'ClusteringForecastModule',
    component: FileManagementComponent
  },
  {
    menuId: '188',
    name: 'Chat Details',
    selector: 'app-chat-details',
    displayName: 'Chat Details',
    module: 'MessagingModule',
    component: ChatDetailsComponent
  },
  {
    menuId: '1070',
    name: 'New Item Planning',
    selector: 'app-new-item-planner',
    displayName: 'New Item Planning',
    module: 'FbModule',
    component: NewItemPlannerComponent,
  },
  {
    menuId: '1071',
    name: 'Global Demand Planning New',
    selector: 'app-global-demand-planner-new',
    displayName: 'Global Demand Planning New (save file to title)',
    module: 'FbModule',
    component: GlobalDemandPlannerNewComponent,
  },
  {
    menuId: '1072',
    name: 'Regiona Demand Planning New',
    selector: 'app-regional-demand-planner-new',
    displayName: 'Regional Demand Planning New (save file to title)',
    module: 'FbModule',
    component: RegionalDemandPlannerNewComponent,
  },
  {
    menuId: '1073',
    name: 'Inventory Planner',
    selector: 'app-inventory-planner',
    displayName: 'Inventory Planner',
    module: 'FbModule',
    component: InventoryPlannerComponent,
  },
  {
    menuId: '1074',
    name: 'Retail Indirect Planner',
    selector: 'app-retail-indirect-planner',
    displayName: 'Retail Indirect Planner',
    module: 'FbModule',
    component: RetailIndirectPlannerComponent,
  },
  {
    menuId: '1075',
    name: 'Microsoft',
    selector: 'app-microsoft',
    module: 'MicrosoftModule',
    component: MicrosoftComponent,
  },
  {
    menuId: '1076',
    name: 'Order Planner',
    selector: 'app-order-planner',
    module: 'MicrosoftModule',
    component: OrderPlannerComponent,
  },
  {
    menuId: '316',
    name: 'Outlier Manager',
    selector: 'app-outlier-manager',
    module: 'ForecastEnginModule',
    component: OutlierManagerComponent,
  },
  {
    menuId: '415',
    name: 'Mapping Manager',
    selector: 'app-mapping-manager',
    module: 'ForecastEnginModule',
    component: MappingManagerComponent,
  },
  {
    menuId: '194',
    name: 'Currency Feature',
    selector: 'app-apparel-country',
    displayName: 'Currency Feature',
    module: 'ApparelModule',
    component: ApparelCountryComponent
  },
  {
    menuId: '386',
    name: 'Seasons Definitions',
    selector: 'app-apparel-season',
    displayName: 'Seasons Definitions',
    module: 'ApparelModule',
    component: ApparelSeasonComponent
  },
  {
    menuId: '195',
    name: 'Business Definitions',
    selector: 'app-business-metadata',
    displayName: 'Business Definitions',
    module: 'ApparelModule',
    component: BusinessMetadataComponent
  },
  {
    menuId: '299',
    name: 'MFP Demo',
    selector: 'app-merchandise-financial-planning',
    displayName: 'MFP Demo',
    module: 'MerchandiseFinancialPlanningModule',
    component: MerchandiseFinancialPlanningComponent
  },
  {
    menuId: '446',
    name: 'Promotion Planner',
    selector: 'app-promo-manager',
    module: 'ForecastEnginModule',
    component: PromoManagerComponent,
  },
  {
    menuId: '449',
    name: 'Promo Details',
    selector: 'app-promo-setup-detail',
    displayName: 'Promo Details',
    module: 'ForecastEnginModule',
    component: PromoSetupDetailComponent
  },
  {
    menuId: '452',
    name: 'Seasonality Manager',
    selector: 'app-seasonality-manager',
    displayName: 'Seasonality Manager',
    module: 'ForecastEnginModule',
    component: SeasonalityManagerComponent
  },
  {
    menuId: '454',
    name: 'Seasonality Editor',
    selector: 'app-edit-seasonality',
    displayName: 'Seasonality Editor',
    module: 'ForecastEnginModule',
    component: EditSeasonalityComponent
  },
  {
    menuId: '455',
    name: 'Forecast Manager',
    selector: 'app-forecast-manager',
    displayName: 'Forecast Manager',
    module: 'ForecastEnginModule',
    component: ForecastManagerComponent
  },
  {
    menuId: '456',
    name: 'Forecast Data Manager',
    selector: 'app-forecast-data-manager',
    displayName: 'Forecast Data Manager',
    module: 'ForecastEnginModule',
    component: ForecastDataManagerComponent
  },
  {
    menuId: '459',
    name: 'Forecast setup ',
    selector: 'app-forecast-setup',
    displayName: 'Forecast setup ',
    module: 'ForecastEnginModule',
    component: ForecastSetupComponent
  },
  {
    menuId: '21',
    name: 'Master Item List',
    selector: 'app-demo-master-item-list',
    displayName: 'Item Manager',
    module: 'DemoManagerModule',
    component: DemoMasterItemListComponent
  },
  {
    menuId: '22',
    name: 'Item List',
    selector: 'app-demo-master-child-item-list',
    displayName: 'Item List',
    module: 'DemoManagerModule',
    component: DemoMasterChildItemListComponent
  },
  {
    menuId: '38',
    name: 'New Item Planner',
    selector: 'app-demo-new-item-planner',
    displayName: 'New Item Planner',
    module: 'DemoManagerModule',
    component: DemoBBNewItemPlannerComponent
  },
  {
    menuId: '39',
    name: 'New Item Promotions',
    selector: 'app-demo-item-promotion-list',
    displayName: 'New Item Promotions',
    module: 'DemoManagerModule',
    component: DemoItemPromotionListComponent
  },
  {
    menuId: '399',
    name: 'New Item Promotion Detail',
    selector: 'app-demo-item-promotion-detail',
    displayName: 'New Item Promotions',
    module: 'DemoManagerModule',
    component: DemoItemPromotionDetailComponent
  },
  {
    menuId: '40',
    name: 'New Item Approvals',
    selector: 'app-demo-item-approval-detail',
    displayName: 'New Item Approvals',
    module: 'DemoManagerModule',
    component: DemoItemApprovalDetailComponent
  },
  {
    menuId: '497',
    name: 'Replenishment Module',
    selector: 'app-replenishment-module',
    displayName: 'Replenishment Module',
    module: 'ReplenishmentModule',
    component: ReplenishmentModuleComponent
  },
  {
    menuId: '462',
    name: 'Item Mapping Setup',
    selector: 'app-add-edit-mapping-forecast-item',
    displayName: 'Item Mapping Setup',
    module: 'ForecastEnginModule',
    component: AddEditMappingForecastItemComponent
  },
  {
    menuId: '463',
    name: 'Client Manager',
    selector: 'app-clients',
    module: 'Auth',
    component: ClientsComponent,
  },
  {
    menuId: '464',
    name: 'Forecast White Labeling',
    selector: 'app-hierarchy-setup-white-labeling',
    module: 'Auth',
    component: HierarchySetupWhiteLabelingComponent,
  },
  {
    menuId: '465',
    name: 'Hierarchy Setup',
    selector: 'app-hierarchy-setup',
    module: 'Auth',
    component: HierarchySetupComponent,
  },
  {
    menuId: '466',
    name: 'Tab Configuration',
    selector: 'app-tab-configuration',
    module: 'Auth',
    component: TabConfigurationComponent,
  },
];
export const MenuList = Pages;
