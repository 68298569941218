<!-- Main Content area -->
<div class="container">

  <div class="row">
    <div class="col-md-3 form-group">
      <mat-form-field>
        <mat-select placeholder="Select Source" (ngModelChange)="populateDefaultYears($event, false)" [(ngModel)]="source">
          <mat-option *ngFor="let res of sources; let i = index" [value]="res">
            {{res.sourcingName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-3 form-group">
      <mat-form-field>
        <mat-select placeholder="Select Channel" [(ngModel)]="channel" (ngModelChange)="populateDefaultYears($event, true)">
          <mat-option *ngFor=" let res of channelList; let i=index" [value]="res">
            {{res.channelName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="source && channel" class="col-md-6 form-group file-uploader">
      <angular-file-uploader [config]="afuConfig">
      </angular-file-uploader>
    </div>

  </div>
  <div *ngIf="source && channel" class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit()">Save</button>
    <button mat-raised-button class="btn btn-primary" color="primary" (click)="openColumnDialog()">
      Add Header Name
      <i class="icon-add"></i>
    </button>
  </div>
  <div *ngIf="source && channel" class="all-show-grid">
    <ag-grid-angular class="ag-theme-material"  groupHeaders suppressRowClickSelection
      toolPanelSuppressGroups toolPanelSuppressValues [pagination]="true" [gridOptions]="gridOptions"
      [columnDefs]="columnDefs" [rowData]="defaultList" (cellClicked)="populateForm($event)"
      (gridReady)="onGridReady($event)" [modules]="modules" #grid>
    </ag-grid-angular>
  </div>
</div>
