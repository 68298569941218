import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-replenishment-data-imports',
  templateUrl: './data-imports.component.html',
  styleUrls: ['./data-imports.component.scss']
})
export class DataImportsComponent implements OnInit {

  importsData = [
    'Items',
    'Locations',
    'Sales',
    'Forecast',
    'Stock',
    'DC Inventory',
    "PO's/On Order",
    'Receivings',
    'Replenishment Orders'
  ];

  showItems = false;
  
  constructor() { }

  ngOnInit(): void {
  }

  navigateToItems(item) {
    if (item != 'Items') return;
    this.showItems = true;
    setTimeout(() => {
      const component = document.getElementById('itemsDivReplenishment');
      if (component) {
        component.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  }

}
