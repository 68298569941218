import { Component, OnInit, ViewEncapsulation, TemplateRef, ViewChild } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BudgetComponent implements OnInit {
  public submitted = false;
  public isEdit = false;
  public columnDefs = [];
  private gridApi: any;
  public tenureTypeList = [];
  public tenureTypeListTemp = [];
  public rowList = [];
  public goalTypeList = [];
  public gridOptions: any;
  public getRowHeight;
  public getRowStyle;
  public headerHeight;
  public modules = AllCommunityModules;
  public clientId = 1;
  public channelList = [{ channelId: 1, channelName: 'Retail' }];
  public channel = null;
  public csvRecords: any;
  public customerSelectedList = [];
  public customerList = [];
  public classSelectedList = [];
  public classList = [];
  public yearList = [];
  public monthList = [];
  public seletedYear = null;
  public selectedMonth = null;
  public defaultRow = {};
  public selectAll = true;
  files: File[] = [];
  @ViewChild('ExcelImport') excelImport: TemplateRef<any>;
  @ViewChild('dialog') dialogTemplate: TemplateRef<any>;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog) {
    this.gridOptions = {
      frameworkComponents: {},
      // pagination: true,
      // paginationAutoPageSize: true,
      // suppressMenuHide: true,
    };
    this.headerHeight = 50;
    this.gridOptions.getRowHeight = function (params) {
      return 40;
    };
    this.gridOptions.getRowStyle = function (params) {
      if (params.data && params.data.apBudgetTenureName && typeof params.data.apBudgetTenureName === 'string') {
        if (params.data && params.data.apBudgetTenureName && params.data.apBudgetTenureName.endsWith('FY21')) {
          return { 'background-color': '#D6EAF8' };
        }
        if (params.data && params.data.apBudgetTenureName && params.data.apBudgetTenureName.startsWith('Q')) {
          return { 'background-color': '#3498DB' };
        }
        if (params.data && params.data.apBudgetTenureName && params.data.apBudgetTenureName.startsWith('WK')) {
          return { 'background-color': '' };
        }
      }
    };
  }
  openDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(this.dialogTemplate, { width: '500px', data: data || {} });
  }
  ngOnInit() {
    this.customerList.push(
      { apCustomerId: 1, apCustomerName: 'Best Buy' },
      { apCustomerId: 2, apCustomerName: 'Walmart' },
      { apCustomerId: 3, apCustomerName: 'Amazon' },
      // { apCustomerId: 4, apCustomerName: 'Target' },
      { apCustomerId: 5, apCustomerName: 'Dollar Tree' },
      { apCustomerId: 6, apCustomerName: 'Sam\s Club' }
    );
    this.classList.push(
      { apClassId: 1, apClassName: 'New Release' },
      { apClassId: 2, apClassName: 'Recent Release' },
      { apClassId: 3, apClassName: 'Theatrical Catalog' },
      // { apClassId: 4, apClassName: 'Target' },
      { apClassId: 5, apClassName: 'TV' }
    );
    for (let index = 0; index < 12; index++) {
      const date = new Date(2009, index, 10);  // 2009-11-10
      const month = date.toLocaleString('default', { month: 'long' });
      this.monthList.push(
        {
          monthId: index + 1, monthName: `${month}`
        });
    }
    console.log(this.monthList);
    for (let index = new Date().getFullYear() + 5; index > 1980; index--) {
      this.yearList.push(index);
    }
    this.columnDefs.push(
      // {
      //   headerName: '',
      //   width: 150,
      //
      //  field: 'grouping',
      //   pinned: 'left',
      //   type: '',
      //   enableRowGroup: true
      // },
      {
        headerName: 'Budget',

        editable: true,
        width: 200,
        show: true,
        field: 'apBudgetTenureName',
        pinned: 'left',
        type: '',
        headerClass: 'shipmentGroup'
      },
      {
        headerName: 'Shipped Unit Budget',
        type: '',

        editable: true,
        width: 150,
        show: true,
        field: 'unitBudget',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
        background: 'black'
      },
      {
        headerName: 'Shipped Unit Fcst',
        type: '',

        editable: true,
        width: 150,
        show: true,
        field: 'unitFcst',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'Shipped Unit Act',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'unitAct',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'Shipped Unit Fcst % to Budget',
        editable: true,
        width: 200,
        show: true,
        field: 'unitFcstToAct',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) * 100 : 0}%</span>`;
        }
      },
      {
        headerName: 'Shipped Unit LY',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'unitLy',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'Shipped Unit Fcst % to LY',
        editable: true,
        width: 200,
        headerClass: 'shipmentGroup',
        show: true,
        field: 'unitFcstToLy',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) * 100 : 0}%</span>`;
        }
      },
      {
        headerName: 'Shipped $ Budget',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'shippedBudget',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>$ ${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'Shipped $ Fcst',
        type: '',
        editable: true,
        width: 150,
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>$ ${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
        show: true,
        field: 'shippedFcst'
      },
      {
        headerName: 'Shipped $ Act',
        editable: true,
        width: 150,
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>$ ${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
        show: true,
        field: 'shippedAct'
      },
      {
        headerName: 'Shipped $ Fcst % to Budget',
        type: '',
        editable: true,
        width: 200,
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) * 100 : 0}%</span>`;
        },
        show: true,
        field: 'shippedFcsttoAct'
      },
      {
        headerName: 'Shipped $ LY',
        editable: true,
        width: 150,
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>$ ${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
        show: true,
        field: 'shippedLY'
      },
      {
        headerName: 'Shipped $ Fcst % to LY',
        editable: true,
        width: 200,
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) * 100 : 0}%</span>`;
        },
        show: true,
        field: 'shippedFcsttoLY'
      },
      {
        headerName: 'POS Unit Budget',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'posUnitBudget',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'POS Unit Fcst',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'posUnitFcst',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'POS Unit Act',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'posUnitAct',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'POS Unit Fcst % to Budget',
        editable: true,
        width: 200,
        show: true,
        field: 'posUnitFcstToAct ',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) * 100 : 0}%</span>`;
        }
      },
      {
        headerName: 'POS Unit LY',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'posUnitLY',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'POS Unit Fcst % to LY',
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) * 100 : 0}%</span>`;
        },
        editable: true,
        width: 200,
        headerClass: 'shipmentGroup',
        show: true,
        field: 'posUnitFcstToLy',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
      },
      {
        headerName: 'POS $ Budget',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'posBudget',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'POS $ Fcst',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'posFcst',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'POS $ Act',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'posAct',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'POS $ Fcst % to Budget',
        editable: true,
        width: 200,
        show: true,
        field: 'posFcstToBudget',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) * 100 : 0}%</span>`;
        }
      },
      {
        headerName: 'POS $ LY',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'posLy',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'POS $ Fcst % to LY',
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) * 100 : 0}%</span>`;
        },
        editable: true,
        width: 200,
        show: true,
        field: 'posFcstToLy',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
      },
      {
        headerName: 'RMF $ Budget',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'rmfBudget',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'RMF $ Fcst',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'rmfFcst',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'RMF $ Act',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'rmfAct',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'RMF $ Fcst % to Budget',
        editable: true,
        width: 200,
        show: true,
        field: 'rmfFcstToAct',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) * 100 : 0}%</span>`;
        }
      },
      {
        headerName: 'RMF $ LY',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'rmfLy',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'RMF $ Fcst % to LY',
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) * 100 : 0}%</span>`;
        },
        editable: true,
        width: 200,
        show: true,
        field: 'rmfFcstToLy',
        headerClass: 'shipmentGroup',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
      },
      {
        headerName: 'ARP Budget',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'arpBudget',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'ARP Fcst',
        type: '',
        editable: true,
        width: 150,
        show: true,
        field: 'arpFcst',
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>$ ${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
      },
      {
        headerName: 'ARP Act',
        type: '',
        editable: true,
        width: 150,
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>$ ${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
        show: true,
        field: 'arpAct'
      },
      {
        headerName: 'ARP Fcst % to Budget',
        editable: true,
        width: 200,
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) * 100 : 0}%</span>`;
        },
        show: true,
        field: 'arpFcstToBudget'
      },
      {
        headerName: 'ARP LY',
        type: '',
        editable: true,
        width: 150,
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>$ ${params.value ? +params.value.toFixed(2) : 0}</span>`;
        },
        show: true,
        field: 'arpLy'
      },
      {
        headerName: 'ARP Fcst % to LY',
        editable: true,
        width: 150,
        valueSetter: function (params) {
          params.data[params.colDef.field] = Number(params.newValue);
          return true;
        },
        cellRenderer: (params) => {
          return `<span>${params.value ? +params.value.toFixed(2) * 100 : 0}%</span>`;
        },
        show: true,
        field: 'arpFcstToLy'
        // }
      }
    );

    this.columnDefs.forEach((column) => this.defaultRow[column.field] = Math.random());
    const manupulatedList = [];
    const obj = JSON.parse(JSON.stringify(this.defaultRow));
    for (let index = 1; index <= 48; index++) {
      this.rowList.push({ ...obj });
    }
    // this.manipulateObject(this.rowList);
  }
  public mapList = async (event) => {
    const budgetList = await this.configurationService.GetBudgetList({ APCustomerId: 1, TenureName: 2021, APClassId: 1 }).toPromise();
    // budgetList = budgetList.map(row => this.defaultRow);
    this.manipulateObject(budgetList);
  }
  openExcelImportDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(this.excelImport, { width: '500px', data: data || {} });
  }
  onCellValueChanged = (value) => {
    this.manipulateObjectOnValueChange(this.rowList);
  }
  public manipulateObject = (rowList: any) => {
    let list = [];
    const defaultObject = JSON.parse(JSON.stringify(this.defaultRow));
    for (let index = 0; index < rowList.length; index = index + 4) {
      let monthElement = JSON.parse(JSON.stringify(this.defaultRow));
      let w1 = rowList[index];
      let w2 = rowList[index + 1];
      let w3 = rowList[index + 2];
      let w4 = rowList[index + 3];
      if (index % 4 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[index / 4].monthName} FY21`;
      }
      this.createList(monthElement, w1, w2, w3, w4);
      list.push(JSON.parse(JSON.stringify(monthElement)));
      list.push(JSON.parse(JSON.stringify(w1)));
      list.push(JSON.parse(JSON.stringify(w2)));
      list.push(JSON.parse(JSON.stringify(w3)));
      list.push(JSON.parse(JSON.stringify(w4)));
      if (index === 8) {
        let q1 = {};
        Object.keys(this.defaultRow).forEach(key => {
          q1[key] = list[0][key] + list[5][key] + list[10][key];
        });
        q1['apBudgetTenureName'] = 'Q1';
        list.push(JSON.parse(JSON.stringify(q1)));
      }
      if (index === 20) {
        let q2 = {};
        Object.keys(this.defaultRow).forEach(key => {
          q2[key] = list[16][key] + list[21][key] + list[26][key];
        });
        q2['apBudgetTenureName'] = 'Q2';
        list.push(JSON.parse(JSON.stringify(q2)));
      }
      if (index === 32) {
        let q3 = {};
        Object.keys(this.defaultRow).forEach(key => {
          q3[key] = list[32][key] + list[37][key] + list[42][key];
        });
        q3['apBudgetTenureName'] = 'Q3';
        list.push(JSON.parse(JSON.stringify(q3)));
      }
      if (index === 44) {
        let q4 = {};
        Object.keys(this.defaultRow).forEach(key => {
          q4[key] = list[48][key] + list[53][key] + list[59][key];
        });
        q4['apBudgetTenureName'] = 'Q4';
        list.push(JSON.parse(JSON.stringify(q4)));
        let total = {};
        Object.keys(this.defaultRow).forEach(key => {
          total[key] = list[15][key] + list[31][key] + list[47][key] + list[63][key];
        });
        total['apBudgetTenureName'] = 'Total Year';
        list.push(JSON.parse(JSON.stringify(total)));
      }
    }
    this.rowList = [];
    this.rowList = list;
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowList);
    }
  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  public manipulateObjectOnValueChange = (rowList: any) => {
    let list = [];
    const defaultObject = JSON.parse(JSON.stringify(this.defaultRow));
    for (let index = 1; index < rowList.length; index = index + 5) {
      let monthElement = JSON.parse(JSON.stringify(this.defaultRow));
      let w1 = rowList[index];
      let w2 = rowList[index + 1];
      let w3 = rowList[index + 2];
      let w4 = rowList[index + 3];
      if (index === 1) {
        monthElement.apBudgetTenureName = `${this.monthList[0].monthName} FY21`;
      }
      if (index % 6 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[1].monthName} FY21`;
      }
      if (index % 11 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[2].monthName} FY21`;
      }
      if (index % 17 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[3].monthName} FY21`;
      }
      if (index % 22 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[4].monthName} FY21`;
      }
      if (index % 27 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[5].monthName} FY21`;
      }
      if (index % 33 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[6].monthName} FY21`;
      }
      if (index % 38 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[7].monthName} FY21`;
      }
      if (index % 43 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[8].monthName} FY21`;
      }
      if (index % 49 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[9].monthName} FY21`;
      }
      if (index % 54 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[10].monthName} FY21`;
      }
      if (index % 59 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[11].monthName} FY21`;
      }
      this.createList(monthElement, w1, w2, w3, w4);
      list.push(JSON.parse(JSON.stringify(monthElement)));
      list.push(JSON.parse(JSON.stringify(w1)));
      list.push(JSON.parse(JSON.stringify(w2)));
      list.push(JSON.parse(JSON.stringify(w3)));
      list.push(JSON.parse(JSON.stringify(w4)));
      if (index === 11) {
        let q1 = {};
        Object.keys(this.defaultRow).forEach(key => {
          if (key !== 'apBudgetTenureName') {
            q1[key] = list[0][key] + list[5][key] + list[10][key];
          }
        });
        q1['apBudgetTenureName'] = 'Q1';
        list.push(JSON.parse(JSON.stringify(q1)));
      }
      if (index === 27) {
        let q2 = {};
        Object.keys(this.defaultRow).forEach(key => {
          if (key !== 'apBudgetTenureName') {
            q2[key] = list[16][key] + list[21][key] + list[26][key];
          }
        });
        q2['apBudgetTenureName'] = 'Q2';
        list.push(JSON.parse(JSON.stringify(q2)));
      }
      if (index === 43) {
        let q3 = {};
        Object.keys(this.defaultRow).forEach(key => {
          if (key !== 'apBudgetTenureName') {
            q3[key] = list[32][key] + list[37][key] + list[42][key];
          }
        });
        q3['apBudgetTenureName'] = 'Q3';
        list.push(JSON.parse(JSON.stringify(q3)));
      }
      if (index === 59) {
        let q4 = {};
        Object.keys(this.defaultRow).forEach(key => {
          if (key !== 'apBudgetTenureName') {
            q4[key] = list[48][key] + list[53][key] + list[58][key];
          }
        });
        q4['apBudgetTenureName'] = 'Q4';
        list.push(JSON.parse(JSON.stringify(q4)));
        let total = {};
        Object.keys(this.defaultRow).forEach(key => {
          if (key !== 'apBudgetTenureName') {
            total[key] = list[15][key] + list[31][key] + list[47][key] + list[63][key];
          }
        });
        total['apBudgetTenureName'] = 'Total Year';
        list.push(JSON.parse(JSON.stringify(total)));
      }
      if (index === 11 || index === 27 || index === 43 || index === 59) {
        index++;
      }
    }
    this.rowList = [];
    this.rowList = list;
    if (this.gridOptions && this.gridOptions.api) {
      setTimeout(() => {
        this.gridOptions.api.setRowData(this.rowList);
      }, 100);
    }
  }
  public createList = (monthElement, w1, w2, w3, w4) => {
    w1.apBudgetTenureName = `WK1`;
    w2.apBudgetTenureName = `WK2`;
    w3.apBudgetTenureName = `WK3`;
    w4.apBudgetTenureName = `WK4`;

    monthElement.unitBudget = w1.unitBudget + w2.unitBudget + w3.unitBudget + w4.unitBudget;
    monthElement.unitFcst = w1.unitFcst + w2.unitFcst + w3.unitFcst + w4.unitFcst;
    monthElement.unitAct = w1.unitAct + w2.unitAct + w3.unitAct + w4.unitAct;
    monthElement.unitFcstToAct = (monthElement.unitFcst / monthElement.unitAct) - 1;
    w1.unitFcstToAct = (w1.unitFcst / w1.unitAct) - 1;
    w2.unitFcstToAct = (w2.unitFcst / w2.unitAct) - 1;
    w3.unitFcstToAct = (w3.unitFcst / w3.unitAct) - 1;
    w4.unitFcstToAct = (w4.unitFcst / w4.unitAct) - 1;
    monthElement.unitLy = w1.unitLy + w2.unitLy + w3.unitLy + w4.unitLy;;
    monthElement.unitFcstToLy = (monthElement.unitFcst / monthElement.unitLy) - 1;
    w1.unitFcstToLy = (w1.unitFcst / w1.unitLy) - 1;
    w2.unitFcstToLy = (w2.unitFcst / w2.unitLy) - 1;
    w3.unitFcstToLy = (w3.unitFcst / w3.unitLy) - 1;
    w4.unitFcstToLy = (w4.unitFcst / w4.unitLy) - 1;

    monthElement.shippedBudget = w1.shippedBudget + w2.shippedBudget + w3.shippedBudget + w4.shippedBudget;
    monthElement.shippedFcst = w1.shippedFcst + w2.shippedFcst + w3.shippedFcst + w4.shippedFcst;
    monthElement.shippedAct = w1.shippedAct + w2.shippedAct + w3.shippedAct + w4.shippedAct;
    monthElement.shippedFcsttoAct = (monthElement.shippedFcst / monthElement.shippedAct) - 1;
    w1.shippedFcsttoAct = (w1.shippedFcst / w1.shippedAct) - 1;
    w2.shippedFcsttoAct = (w2.shippedFcst / w2.shippedAct) - 1;
    w3.shippedFcsttoAct = (w3.shippedFcst / w3.shippedAct) - 1;
    w4.shippedFcsttoAct = (w4.shippedFcst / w4.shippedAct) - 1;
    monthElement.shippedLY = w1.shippedLY + w2.shippedLY + w3.shippedLY + w4.shippedLY;
    monthElement.shippedFcsttoLY = (monthElement.shippedFcst / monthElement.shippedLY) - 1;
    w1.shippedFcsttoLY = (w1.shippedFcst / w1.shippedLY) - 1;
    w2.shippedFcsttoLY = (w2.shippedFcst / w2.shippedLY) - 1;
    w3.shippedFcsttoLY = (w3.shippedFcst / w3.shippedLY) - 1;
    w4.shippedFcsttoLY = (w4.shippedFcst / w4.shippedLY) - 1;

    monthElement.posUnitBudget = w1.posUnitBudget + w2.posUnitBudget + w3.posUnitBudget + w4.posUnitBudget;
    monthElement.posUnitFcst = w1.posUnitFcst + w2.posUnitFcst + w3.posUnitFcst + w4.posUnitFcst;
    monthElement.posUnitAct = w1.posUnitAct + w2.posUnitAct + w3.posUnitAct + w4.posUnitAct;
    monthElement.posUnitFcstToAct = (monthElement.posUnitFcst / monthElement.posUnitAct) - 1;
    w1.posUnitFcstToAct = (w1.posUnitFcst / w1.posUnitAct) - 1;
    w2.posUnitFcstToAct = (w2.posUnitFcst / w2.posUnitAct) - 1;
    w3.posUnitFcstToAct = (w3.posUnitFcst / w3.posUnitAct) - 1;
    w4.posUnitFcstToAct = (w4.posUnitFcst / w4.posUnitAct) - 1;
    monthElement.posUnitLy = w1.posUnitLy + w2.posUnitLy + w3.posUnitLy + w4.posUnitLy;
    monthElement.posUnitFcstToLy = (monthElement.posUnitFcst / monthElement.posUnitLy) - 1;
    w1.posUnitFcstToLy = (w1.posUnitFcst / w1.posUnitLy) - 1;
    w2.posUnitFcstToLy = (w2.posUnitFcst / w2.posUnitLy) - 1;
    w3.posUnitFcstToLy = (w3.posUnitFcst / w3.posUnitLy) - 1;
    w4.posUnitFcstToLy = (w4.posUnitFcst / w4.posUnitLy) - 1;

    monthElement.posBudget = w1.posBudget + w2.posBudget + w3.posBudget + w4.posBudget;
    monthElement.posFcst = w1.posFcst + w2.posFcst + w3.posFcst + w4.posFcst;
    monthElement.posAct = w1.posAct + w2.posAct + w3.posAct + w4.posAct;
    monthElement.posFcstToAct = (monthElement.posFcst / monthElement.posAct) - 1;
    w1.posFcstToAct = (w1.posFcst / w1.posAct) - 1;
    w2.posFcstToAct = (w2.posFcst / w2.posAct) - 1;
    w3.posFcstToAct = (w3.posFcst / w3.posAct) - 1;
    w4.posFcstToAct = (w4.posFcst / w4.posAct) - 1;
    monthElement.posLy = w1.posLy + w2.posLy + w3.posLy + w4.posLy;
    monthElement.posFcstToLy = (monthElement.posFcst / monthElement.posLy) - 1;
    w1.posFcstToLy = (w1.posFcst / w1.posLy) - 1;
    w2.posFcstToLy = (w2.posFcst / w2.posLy) - 1;
    w3.posFcstToLy = (w3.posFcst / w3.posLy) - 1;
    w4.posFcstToLy = (w4.posFcst / w4.posLy) - 1;

    monthElement.rmfBudget = w1.rmfBudget + w2.rmfBudget + w3.rmfBudget + w4.rmfBudget;
    monthElement.rmfFcst = w1.rmfFcst + w2.rmfFcst + w3.rmfFcst + w4.rmfFcst;
    monthElement.rmfAct = w1.rmfAct + w2.rmfAct + w3.rmfAct + w4.rmfAct;
    monthElement.rmfFcstToAct = (monthElement.rmfFcst / monthElement.rmfAct) - 1;
    w1.rmfFcstToAct = (w1.rmfFcst / w1.rmfAct) - 1;
    w2.rmfFcstToAct = (w2.rmfFcst / w2.rmfAct) - 1;
    w3.rmfFcstToAct = (w3.rmfFcst / w3.rmfAct) - 1;
    w4.rmfFcstToAct = (w4.rmfFcst / w4.rmfAct) - 1;
    monthElement.rmfLy = w1.rmfLy + w2.rmfLy + w3.rmfLy + w4.rmfLy;
    monthElement.rmfFcstToLy = (monthElement.rmfFcst / monthElement.rmfLy) - 1;
    w1.rmfFcstToLy = (w1.rmfFcst / w1.rmfLy) - 1;
    w2.rmfFcstToLy = (w2.rmfFcst / w2.rmfLy) - 1;
    w3.rmfFcstToLy = (w3.rmfFcst / w3.rmfLy) - 1;
    w4.rmfFcstToLy = (w4.rmfFcst / w4.rmfLy) - 1;

    monthElement.arpBudget = w1.arpBudget + w2.arpBudget + w3.arpBudget + w4.arpBudget;
    monthElement.arpFcst = w1.arpFcst + w2.arpFcst + w3.arpFcst + w4.arpFcst;
    monthElement.arpAct = w1.arpAct + w2.arpAct + w3.arpAct + w4.arpAct;
    monthElement.arpFcstToAct = (monthElement.arpFcst / monthElement.arpAct) - 1;
    w1.arpFcstToAct = (w1.arpFcst / w1.arpAct) - 1;
    w2.arpFcstToAct = (w2.arpFcst / w2.arpAct) - 1;
    w3.arpFcstToAct = (w3.arpFcst / w3.arpAct) - 1;
    w4.arpFcstToAct = (w4.arpFcst / w4.arpAct) - 1;
    monthElement.arpLy = w1.arpLy + w2.arpLy + w3.arpLy + w4.arpLy;
    monthElement.arpFcstToLy = (monthElement.arpFcst / monthElement.arpLy) - 1;
    w1.arpFcstToLy = (w1.arpFcst / w1.arpLy) - 1;
    w2.arpFcstToLy = (w2.arpFcst / w2.arpLy) - 1;
    w3.arpFcstToLy = (w3.arpFcst / w3.arpLy) - 1;
    w4.arpFcstToLy = (w4.arpFcst / w4.arpLy) - 1;
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  public onSubmit = () => {
  }
  selectAllSelected(flag: boolean): void {
    if (flag) {
      this.columnDefs.forEach(column => column.show = true);
    } else {
      this.columnDefs.forEach(column => column.show = false);
    }
  }
  public onSave = () => {
    const newColumnDefs = this.columnDefs.filter(column => column.show);
    this.gridOptions.api.setColumnDefs(newColumnDefs);
    this.dialogRef.close();
  }
  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }
  exportToExcel = () => {
    this.gridApi.exportDataAsCsv();
  }
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
}
