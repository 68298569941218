<div class="new-item-planner" id="fb-forecast">
  <div class="container-fluid item-wrapper">
    <form class="account-form">
      <div class="row">
        <div class="col-12">
          <h2>New Account Forecast Setting</h2>
        </div>
        <div class="col-md-6 padding-right">
          <div>
            <div class="form-group">
              <label for="launchCurve">Launch Curve</label>
              <mat-select
                [(ngModel)]="dataModel.launchCurveId"
                [disabled]="!editPermission"
                name="launchCurve"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option
                  *ngFor="let item of launchCurvesList"
                  [value]="item.id"
                >
                  {{ item.name }}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label for="launchCurve">Seasonlaity Index</label>
              <mat-select
              [disabled]="!editPermission"
                [(ngModel)]="dataModel.seasonalityIndexId"
                name="seasonlaityIndex"
                [ngModelOptions]="{ standalone: true }"
                (selectionChange)="createItemAccountLists()"
              >
                <mat-option
                  *ngFor="let item of seasonlaityIndexList"
                  [value]="item.commonCodeValueId"
                >
                  {{ item.value }}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label for="launchCurve">Seasonlaity Pattern Product</label>
              <mat-select
              [disabled]="!editPermission"
                [(ngModel)]="dataModel.seasonalityPatternProductId"
                name="seasonlaityPatternProduct"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option
                  *ngFor="let item of seasonlaityPatternProductList"
                  [value]="item.id"
                >
                  {{ item.name }}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label for="launchCurve">Seasonlaity Pattern Account</label>
              <mat-select
              [disabled]="!editPermission"
                [(ngModel)]="dataModel.seasonalityPatternAccountId"
                name="seasonlaityPatternAccount"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option
                  *ngFor="let item of seasonlaityPatternAccountList"
                  [value]="item.id"
                >
                  {{ item.name }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="bottom-div">
            <div class="form-group">
              <label for="">Active</label>
              <mat-slide-toggle
              [disabled]="!editPermission"
                [(ngModel)]="dataModel.nipActive"
              ></mat-slide-toggle>
            </div>
            <div class="form-group">
              <div class="action-panel text-right btn-wrapper">
                <button
                  (click)="apply()"
                  mat-raised-button
                  class="btn btn-primary m-left"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 padding-left">
          <div>
            <div class="form-group">
              <label for="baseLineRunRate">BaseLine Run Rate</label>
              <input [disabled]="!editPermission" [(ngModel)]="dataModel.baselineRunRate" type="number" />
            </div>
            <div class="form-group">
              <label for="">Channel</label>
              <mat-select
              [disabled]="!editPermission"
                [(ngModel)]="dataModel.channelId"
                (selectionChange)="onChannelChange(dataModel.channelId)"
              >
                <mat-option
                  *ngFor="let channel of channelList"
                  [value]="channel.channelId"
                  >{{ channel.channelName }}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label for="">Region</label>
              <mat-select
              [disabled]="!editPermission"
                (selectionChange)="onRegionChange(dataModel.regionId)"
                [(ngModel)]="dataModel.regionId"
                name="continentId"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option
                  *ngFor="let region of regionList"
                  [value]="region.continentId"
                  >{{ region.continentName }}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label for="">Country</label>
              <mat-select
              [disabled]="!editPermission"
                (selectionChange)="onCountryChange(dataModel.countryId)"
                [(ngModel)]="dataModel.countryId"
              >
                <mat-option
                  *ngFor="let country of countryList"
                  [value]="country.countryId"
                  >{{ country.countryName }}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label for="country">Distributor</label>
              <mat-select
              [disabled]="!editPermission"
                [(ngModel)]="dataModel.distributorId"
                (selectionChange)="onDistributorChange()"
                name="distributorId"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option
                  *ngFor="let distributor of distributorList"
                  [value]="distributor.distributorId"
                >
                  {{ distributor.distributorName }}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label for="">Account</label>
              <mat-select [disabled]="!editPermission" [(ngModel)]="dataModel.oldAccountId">
                <mat-option
                  *ngFor="let account of accountList"
                  [value]="account.itemAccountId"
                  >{{ account.accountName }}
                </mat-option>
              </mat-select>
            </div>

            <div class="form-group time-dropdown">
              <label for="timeFrame">Time Frame</label>
              <app-treeview-dropdown
              [disabled]="!editPermission"
                [type]="'Timeframe'"
                [items]="calendarListDropdown"
                (valueChange)="setValue($event)"
              >
              </app-treeview-dropdown>
            </div>
          </div>
          <div class="bottom-div">
            <div class="form-group">
              <div class="action-panel text-right btn-wrapper">
                <button (click)="getCalculation()" mat-raised-button class="btn btn-primary m-left">
                  Claculate and Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="items-wrapper-grid new-item-account-setting-grid">
    <ag-grid-angular
      class="ag-theme-material"
      [gridOptions]="gridOptions"
      [defaultColDef]="defaultColDef"
      [headerHeight]="headerHeight"
      [columnDefs]="columnDefs"
      [rowData]="accountSettingList"
      (gridReady)="onGridReady($event)"
      [modules]="modules"
      [rowSelection]="rowSelection"
      (selectionChanged)="onSelectionChanged($event)"
      #grid2
    >
    </ag-grid-angular>
  </div>
  <div class="col-12 btn-wrapper">
    <button class="btn btn-secondary">Close</button>
    <button class="btn btn-primary" (click)="saveSettings()">Save</button>
  </div>
</div>
