import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-drop',
  templateUrl: './add-drop.component.html',
  styleUrls: ['./add-drop.component.scss']
})
export class AddDropComponent implements OnInit {

  addDropForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddDropComponent>
  ) { }

  ngOnInit(): void {
    this.addDropForm = this.formBuilder.group({
      name: [null, Validators.required],
      date: [null, Validators.required]
    });
  }

  
  onSaveClick() {
    this.dialogRef.close(this.addDropForm.value);
  }
  
  onNoClick() {
    this.dialogRef.close(false);
  }

}
