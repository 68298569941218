import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationService } from '../../../services/configuration.service';
import { Item } from '../item.model';

@Component({
  selector: 'app-item-form',
  templateUrl: './item-form.component.html',
  styleUrls: ['./item-form.component.scss'],
})
export class ItemFormComponent implements OnInit, OnChanges {
  itemModel: Item = new Item();
  public isEdit = false;
  public submitted = false;
  @Output() closePanel = new EventEmitter();
  @Output() updateList = new EventEmitter();
  constructor(
    public config: ConfigurationService,
    public spinner: NgxSpinnerService,
    public cdr: ChangeDetectorRef
  ) {}
  ngOnInit() {
    this.itemModel = this.config.itemModel;
    if (this.itemModel.itemManagerId) {
      this.isEdit = true;
    } else {
      this.itemModel.editPermission = true;
    }
  }
  ngOnChanges(simpleChange: SimpleChanges) {
    if (
      simpleChange &&
      simpleChange.itemModel &&
      simpleChange.itemModel.currentValue
    ) {
      this.itemModel = simpleChange.itemModel.currentValue;
      this.cdr.detectChanges();
    }
  }
  public onSubmit = () => {
    this.submitted = true;
    const model = {
      ...this.itemModel,
    };
    if (
      this.itemModel.description &&
      this.itemModel.brandId &&
      this.itemModel.productGroupId &&
      this.itemModel.productFamilyId &&
      this.itemModel.msrp &&
      this.itemModel.cost &&
      this.itemModel.color &&
      this.itemModel.capacity &&
      this.itemModel.screenSize &&
      this.itemModel.sku &&
      this.itemModel.productCategory &&
      this.itemModel.productStatus &&
      this.itemModel.streetDate
    ) {
      this.spinner.show();
      if (this.isEdit) {
        this.config.ItemManagerUpdate(model).subscribe(
          (res) => {
            this.submitted = false;
            this.spinner.hide();
            this.updateList.emit(true);
            this.closePanel.emit(true);
          },
          (error) => {
            this.spinner.hide();
          }
        );
      } else {
        this.config.ItemManagerAdd(model).subscribe(
          (res) => {
            this.isEdit = true;
            this.itemModel.itemManagerId = res;
            this.submitted = false;
            this.closePanel.emit(true);
            this.updateList.emit(true);
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
      }
    }
  };
}
