import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NodeCheckEventArgs, DragAndDropEventArgs, TreeView } from '@syncfusion/ej2-angular-navigations';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-facebook-account-settings',
  templateUrl: './facebook-account-settings.component.html',
  styleUrls: ['./facebook-account-settings.component.scss']
})
export class FacebookAccountSettingsComponent implements OnInit {
  @ViewChild('tree') public tree: TreeView;
  @ViewChild('channelTemplate') public channelTemplate: TemplateRef<any>;
  @ViewChild('regionTemplate') public regionTemplate: TemplateRef<any>;
  @ViewChild('countryTemplate') public countryTemplate: TemplateRef<any>;
  @ViewChild('distributorTemplate') public distributorTemplate: TemplateRef<any>;
  public treeViewList = [];
  accountList: any;
  constructor(
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public dialogAddRef: MatDialogRef<any>,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
  }

  ngOnInit() {
    this.populateTreeView();
  }
  public populateTreeView = () => {
    const params = {
      offset: 0,
      pageSize: 100000,
      active: true
    }
    let url = `offSet=${params.offset ? params.offset : 0}&pageSize=${params.pageSize ? params.pageSize : 20}&active=true`
    this.configurationService.ItemAccountGetAll(url).subscribe(res => {
      this.accountList = (res.data || []);
      this.GetAccountSettingList();
    });
  }
  public GetAccountSettingList = (forecast?: any) => {
    this.spinner.show();
    this.configurationService.AccountSettingMappingGetList({}).subscribe((res) => {
      const accoutSettingListTemp = res;
      const accoutSettingList = res;
      const channelIds = [];
      accoutSettingList.forEach(account => {
        if (channelIds.includes(account.channelId)) {
        } else {
          channelIds.push(account.channelId);
        }
      });
      let treeList = [];
      channelIds.forEach(channel => {
        let channelObject = {
          channelName: accoutSettingList.find(acc => acc.channelId === channel) ? accoutSettingList.find(acc => acc.channelId === channel).channelName : '',
          name: accoutSettingList.find(acc => acc.channelId === channel) ? accoutSettingList.find(acc => acc.channelId === channel).channelName : '',
          channelId: channel,
          children: [],
          isChannel: true,
          isDelete: true
        }
        const regionIds = accoutSettingListTemp.filter(el => el.channelId === channel).map(el => el.continentId).filter((value, index, self) => self.indexOf(value) === index);
        regionIds.forEach(regionId => {
          let regionObject = {
            continentName: accoutSettingList.find(acc => acc.continentId === regionId) ? accoutSettingList.find(acc => acc.continentId === regionId).continentName : '',
            name: accoutSettingList.find(acc => acc.continentId === regionId) ? accoutSettingList.find(acc => acc.continentId === regionId).continentName : '',
            continentId: regionId,
            channelId: channel,
            children: [],
            isRegion: true,
            isDelete: true
          }
          const countryIds = accoutSettingListTemp.filter(el => el.continentId === regionId && el.channelId === channel).map(el => el.countryId).filter((value, index, self) => self.indexOf(value) === index);
          countryIds.forEach(countryId => {
            let countryObject = {
              countryName: accoutSettingList.find(acc => acc.countryId === countryId) ? accoutSettingList.find(acc => acc.countryId === countryId).countryName : '',
              name: accoutSettingList.find(acc => acc.countryId === countryId) ? accoutSettingList.find(acc => acc.countryId === countryId).countryName : '',
              countryId: countryId,
              continentId: regionId,
              channelId: channel,
              children: [],
              isCountry: true,
              isDelete: true
            }
            const distributorIds = accoutSettingListTemp.filter(el => el.continentId === regionId && el.channelId === channel && el.countryId === countryId).map(el => el.distributorId).filter((value, index, self) => self.indexOf(value) === index);
            distributorIds.forEach(distributor => {
              let accounts = this.accountList.filter(account => account.countryId === countryId
                && account.regionId === regionId
                && account.channelId === channel
                && account.distributorId === distributor);
              accounts = accounts.map(element => ({
                name: element.accountName,
                isAccount: true,
                ...element
              }));
              const distributorObject = {
                distributorName: accoutSettingList.find(acc => acc.distributorId === distributor) ? accoutSettingList.find(acc => acc.distributorId === distributor).distributorName : '',
                name: accoutSettingList.find(acc => acc.distributorId === distributor) ? accoutSettingList.find(acc => acc.distributorId === distributor).distributorName : '',
                countryId: countryId,
                continentId: regionId,
                channelId: channel,
                distributorId: distributor,
                children: accounts,
                isDistributor: true,
                isDelete: true
              }
              if (distributorObject.distributorId) {
                countryObject.children.push(distributorObject)
              }
              if (distributorObject.distributorId && distributorObject.children.length > 0) {
                channelObject.isDelete = false;
                regionObject.isDelete = false;
                countryObject.isDelete = false;
                distributorObject.isDelete = false;
              }
            });
            if (countryObject.countryId) {
              regionObject.children.push(countryObject)
            }
          });
          if (regionObject.continentId) {
            channelObject.children.push(regionObject)
          }
        });
        treeList.push(channelObject);
      });

      this.treeViewList = treeList;
      this.spinner.hide();
    });
  }
  public openDialog = (selectedItem) => {
    selectedItem.isEdit = false;
    if (selectedItem.isChannel) {
      selectedItem.regionName = '';
      this.dialogRef = this.dialog.open(this.regionTemplate, { data: selectedItem });
    }
    else if (selectedItem.isRegion) {
      selectedItem.countryName = '';
      this.dialogRef = this.dialog.open(this.countryTemplate, { data: selectedItem });
    }
    else if (selectedItem.isCountry) {
      selectedItem.distributorName = '';
      this.dialogRef = this.dialog.open(this.distributorTemplate, { data: selectedItem });
    } else {
      selectedItem.channelName = '';
      this.dialogRef = this.dialog.open(this.channelTemplate, { data: selectedItem });
    }
  }
  public openEditDialog = (selectedItem) => {
    selectedItem.isEdit = true;
    if (selectedItem.isChannel) {
      this.dialogRef = this.dialog.open(this.channelTemplate, { data: selectedItem });
    }
    else if (selectedItem.isRegion) {
      this.dialogRef = this.dialog.open(this.regionTemplate, { data: selectedItem });
    }
    else if (selectedItem.isCountry) {
      this.dialogRef = this.dialog.open(this.countryTemplate, { data: selectedItem });
    }
    else if (selectedItem.isDistributor) {
      this.dialogRef = this.dialog.open(this.distributorTemplate, { data: selectedItem });
    }
  }
  public onSubmitChannel = (channelObject) => {
    const model = {
      ...channelObject
    }
    this.spinner.show();
    if (channelObject.isEdit) {
      this.configurationService.AccountSettingMappingUpdateChannel(model).subscribe(res => {
        this.dialogRef.close();
        this.treeViewList.forEach(channel => {
          if (
            channel.channelId === model.channelId
          ) {
            channel.channelName = model.channelName;
            channel.name = model.channelName;
          }
        });
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      })
    } else {
      this.configurationService.AccountSettingMappingAddChannel(model).subscribe(res => {
        this.dialogRef.close();
        model.channelId = res;
        model.name = model.channelName;
        model.isDelete = true;
        model.isChannel = true;
        model.children = [];
        this.treeViewList.push(model);
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      })
    }

  }
  public onSubmitContinent = (ContinentObject) => {
    const model = {
      ...ContinentObject
    }
    this.spinner.show();
    if (ContinentObject.isEdit) {
      this.configurationService.AccountSettingMappingUpdateContinent(model).subscribe(res => {
        this.dialogRef.close();
        this.treeViewList.forEach(channel => {
          channel.children.forEach(continent => {
            if (
              channel.channelId === model.channelId &&
              model.continentId === continent.continentId
            ) {
              continent.continentName = model.continentName;
              continent.name = model.continentName;
            }
          });
        });
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      })
    } else {
      this.configurationService.AccountSettingMappingAddContinent(model).subscribe(res => {
        this.dialogRef.close();
        let regionObject = {
          continentName: model.continentName,
          name: model.continentName,
          continentId: res,
          channelId: model.channelId,
          children: [],
          isRegion: true,
          isDelete: true
        }
        const index = this.treeViewList.findIndex(channel => {
          return channel.channelId === model.channelId
        });
        if (index > -1) {
          this.treeViewList[index].children.push(regionObject);
        }
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      })
    }

  }
  public onSubmitCountry = (CountryObject) => {
    const model = {
      ...CountryObject
    }
    this.spinner.show();
    if (CountryObject.isEdit) {
      this.configurationService.AccountSettingMappingUpdateCountry(model).subscribe(res => {
        this.dialogRef.close();
        this.treeViewList.forEach(channel => {
          channel.children.forEach(continent => {
            continent.children.forEach(country => {
              if (
                channel.channelId === model.channelId &&
                model.continentId === continent.continentId &&
                model.countryId === country.countryId
              ) {
                country.countryName = model.countryName;
                country.name = model.countryName;
              }
            });
          });
        });
        this.spinner.hide();

      }, error => {
        this.spinner.hide();
      })
    } else {
      this.configurationService.AccountSettingMappingAddCountry(model).subscribe(res => {
        this.dialogRef.close();
        model.name = model.countryName;
        let countryObject = {
          countryName: model.countryName,
          name: model.countryName,
          continentId: model.regionId || model.continentId,
          channelId: model.channelId,
          countryId: res,
          children: [],
          isCountry: true,
          isDelete: true
        }
        this.treeViewList.forEach(channel => {
          channel.children.forEach(continent => {
            if (channel.channelId === model.channelId && model.continentId === continent.continentId) {
              continent.children.push(countryObject);
            }
          });
          this.spinner.hide();
        });
      }, error => {
        this.spinner.hide();
      })
    }

  }
  public onSubmitDistributor = (DistributorObject) => {
    const model = {
      ...DistributorObject
    }
    this.spinner.show();
    if (DistributorObject.isEdit) {
      this.configurationService.AccountSettingMappingUpdateDistributor(model).subscribe(res => {
        this.dialogRef.close();
        this.treeViewList.forEach(channel => {
          channel.children.forEach(continent => {
            continent.children.forEach(country => {
              country.children.forEach(distributor => {
                if (
                  channel.channelId === model.channelId &&
                  model.continentId === continent.continentId &&
                  model.countryId === country.countryId &&
                  model.distributorId === distributor.distributorId
                ) {
                  distributor.distributorName = model.distributorName;
                  distributor.name = model.distributorName;
                }
              });
            });
          });
        });
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      })
    } else {
      this.configurationService.AccountSettingMappingAddDistributor(model).subscribe(res => {
        this.dialogRef.close();
        let distributorObject = {
          distributorName: model.distributorName,
          name: model.distributorName,
          continentId: model.regionId || model.continentId,
          channelId: model.channelId,
          countryId: model.countryId,
          distributorId: res,
          children: [],
          isDistributor: true,
          isDelete: true
        }
        this.treeViewList.forEach(channel => {
          channel.children.forEach(continent => {
            continent.children.forEach(country => {
              if (channel.channelId === model.channelId && model.continentId === continent.continentId && model.countryId === country.countryId) {
                country.children.push(distributorObject);
              }
            });
          });
        });
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      })
    }

  }
  openConfirmationDialog(nodeData: any) {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { ...nodeData, headerName: nodeData.name }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeNode(result);
      }
    });
  }
  public removeNode = (nodeData: any) => {
    const model = {
      ...nodeData,
      active: false
    };
    if (nodeData.isDelete || nodeData.isAccount) {
      this.spinner.show();
      if (nodeData.isChannel) {
        this.configurationService.AccountSettingMappingActivateChannel(model).subscribe(flag => {
          this.treeViewList = this.treeViewList.filter(channel => channel.channelId !== model.channelId);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      } else if (nodeData.isRegion) {
        this.configurationService.AccountSettingMappingActivateContinent(model).subscribe(flag => {
          this.treeViewList.forEach(channel => {
            channel.children = channel.children.filter(region => region.continentId !== model.continentId)
          })
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      } else if (nodeData.isCountry) {
        this.configurationService.AccountSettingMappingActivateCountry(model).subscribe(flag => {
          this.treeViewList.forEach(channel => {
            channel.children.forEach(region => {
              region.children = region.children.filter(country => country.countryId !== model.countryId)
            });
            this.spinner.hide();
          })
        }, error => {
          this.spinner.hide();
        });
      } else if (nodeData.isDistributor) {
        this.configurationService.AccountSettingMappingActivateDistributor(model).subscribe(flag => {
          this.treeViewList.forEach(channel => {
            channel.children.forEach(region => {
              region.children.forEach(country => {
                country.children = country.children.filter(distributor => distributor.distributorId !== model.distributorId)
              });
            });
          });
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      } else if (nodeData.isAccount) {
        this.configurationService.ItemAccountActivate(model).subscribe(flag => {
          this.treeViewList.forEach(channel => {
            channel.children.forEach(region => {
              region.children.forEach(country => {
                country.children.forEach(distributor => {
                  distributor.children = distributor.children.filter(itemAccount => itemAccount.itemAccountId !== model.itemAccountId)
                  if (distributor.children.length < 1) {
                    channel.isDelete = true;
                    region.isDelete = true;
                    country.isDelete = true;
                    distributor.isDelete = true;
                  }
                });
              });
            });
          });
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      }
    } else {
      this.toastr.info('', `${nodeData.name} cannot be deleted because it is associated to an account.`)
    }

  }
  public getHeading = (item) => {
    if (item.isChannel) {
      return 'Channel:'
    }
    if (item.isRegion) {
      return 'Region:'
    }
    if (item.isCountry) {
      return 'Country:'
    }
    if (item.isDistributor) {
      return 'Distributor:'
    }
    if (item.isAccount) {
      return 'Account:'
    }
  }
}
