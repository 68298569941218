import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable()
export class MappingService {

  // Subjects
  addEditMappingActionSubject: Subject<boolean> = new Subject();

  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */

  // Observables

  constructor(private http: HttpClient) {
  }

  GetCalendarList(body) {
    return this.http.post(`${environment.ConfigurationLookup}/GetCalendarList`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  GetLookupItemsList() {
    return this.http.get(`${environment.MappingManager}/itemgetlist`)
      .pipe(map(response => {
        return response;
      }));
  }

  CheckOverlapWeek(body = {}) {
    return this.http.post(`${environment.MappingManager}/getoverlapweek`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  MappingItemGetList() {
    return this.http.get(`${environment.MappingManager}/mappingItemGetList`)
      .pipe(map(forecastitemlist => {
        return forecastitemlist;
      }));
  }

  GetMappingHitory(body) {
    return this.http.post(`${environment.MappingManager}/mappinghistorygetlist`, body)
      .pipe(map(forecastitemlist => {
        return forecastitemlist;
      }));
  }

  GetWeeklyMappingSaleUnit(body) {
    return this.http.post(`${environment.MappingManager}/mappingSaleUnitList`, body)
      .pipe(map(forecastsaleunits => {
        return forecastsaleunits;
      }));
  }

  UpdateForecastItemStatus(body) {
    return this.http.put(`${environment.MappingManager}/activate`, body)
      .pipe(map(forecastitem => {
        return forecastitem;
      }));
  }

  AddForecastItem(body = {}) {
    return this.http.post(`${environment.MappingManager}/addforecastitem`, body)
      .pipe(map(forecastitem => {
        return forecastitem;
      }));
  }

  UpdateForecastItem(body = {}) {
    return this.http.put(`${environment.MappingManager}/update`, body)
      .pipe(map(forecastitem => {
        return forecastitem;
      }));
  }

  DeleteForecastItem(body) {
    return this.http.post(`${environment.MappingManager}/delete`, body)
      .pipe(map(forecastitemlist => {
        return forecastitemlist;
      }));
  }

  DeleteMappedItem(mappedItemId, retailerKey, sku) {
    return this.http.delete(`${environment.MappingManager}/deletemappeditem?mappedItemId=${mappedItemId}&retailer=${retailerKey}&sku=${sku}`)
      .pipe(map(response => {
        return response;
      }));
  }

  MappingSourceByMappingManagerId(mappingManagerId) {
    return this.http.get(`${environment.MappingManager}/mappingSourceByMappingManagerId?MappingManagerId=${mappingManagerId}`)
      .pipe(map(mappeditemlist => {
        return mappeditemlist;
      }));
  }

  GetAvgWeeklySaleUnit(body) {
    return this.http.post(`${environment.MappingManager}/getavgweeklysaleunit`, body)
      .pipe(map(mappedItems => {
        return mappedItems;
      }));
  }

  TriggerMappingManagerPipeline(body = {}) {
    return this.http.post(`${environment.MappingManager}/pipeline`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  AzurePipelineTriggerGetList(body = {}) {
    return this.http.get(`${environment.MappingManager}/AzurePipelineTriggerGetList`)
      .pipe(map(azpipeline => {
        return azpipeline;
      }));
  }

  GetUnmappedItemList() {
    return this.http.get(`${environment.MappingManager}/unmappeditemgetlist`)
      .pipe(map(unmappeditemgetlist => {
        return unmappeditemgetlist;
      }));
  }

}
