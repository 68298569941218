import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cacheBlockSize, paginationPageSize, planogramGridColDefs } from './ag-grid/gridOptions';
import { draggableArrayTypes, enforcementDropDownOptions } from './contants/planogram-contants';
import { AddMovieComponent } from './dialogs/add-movie/add-movie.component';
import { PlanogramTableComponent } from './components/planogram-table/planogram-table.component';
import * as FileSaver from "file-saver";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import { PlanogramItemActionCellRenderer } from './ag-grid/planogramItemActionCellRenderer';
import { AGNumberEditorComponent } from 'src/common/ag-number-cell-editor';
import { DropdownRendererComponent } from 'src/common/dropdown-renderer';
import { CheckboxRenderer } from 'src/common/ag-checkbox-renderer';
import { PlanogramService } from 'src/services/Item-Management-Services/planogram.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { USER_INFO } from 'src/common/keys';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-planogram-optimizer',
  templateUrl: './planogram-optimizer.component.html',
  styleUrls: ['./planogram-optimizer.component.scss']
})
export class PlanogramOptimizerComponent implements OnInit, OnDestroy {

  // Grid Variables
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  public gridColumnDefs = planogramGridColDefs;
  public modules = AllCommunityModules;
  public gridOptions: any;
  private gridApi;
  frameworkComponents = {
    numberCellEditor: AGNumberEditorComponent,
    checkboxRenderer: CheckboxRenderer,
    dropdownRendererComponent: DropdownRendererComponent,
    planogramItemActionCellRenderer: PlanogramItemActionCellRenderer
  }

  labels = [
    {
      title: 'New Added',
      color: 'bg-green'
    },
    {
      title: 'Facing Change',
      color: 'bg-purple'
    },
    {
      title: 'New Unused',
      color: 'bg-blue'
    },
    {
      title: 'Old Removed',
      color: 'bg-red'
    },
    {
      title: 'Moved',
      color: 'bg-orangered'
    }
  ];

  private draggingItem = {
    index: 0,       //Array Index
    arrayType: 'NEW_fosnrB'    //Array Type
  };

  draggableArrayTypes = draggableArrayTypes;

  _unsubscribeAll: Subject<any>;

  previousWeekData = [];
  previousWeekGroups = {
    FOSNR_B: [],
    FOSNR_C: [],
    FOSNR_D: []
  };

  thisWeekData = [];

  userObj = {} as any;

  nextWeekRuns = [];
  runningOptimizer = false;

  lockedPlanogram = -1;
  lockingPlanogram = 0;

  namesSubscription: Subscription;
  previousWeekFileName: string;
  currentWeekFileName: string;
  savePlanogramSubscription: Subscription;
  lockedPlanogramName: string;

  uploadingPreviousWeekFile = false;
  uploadingThisWeekFile = false;

  loadingPreviousWeekPlanogram = true;
  nextWeekTabIndex = 0;

  constructor(private planogramService: PlanogramService,
    public storage: LocalstorageService,
    private toastrService: ToastrService,
    private dialog: MatDialog) {
    this.userObj = this.storage.get(USER_INFO);
  }

  ngOnInit() {
    this._unsubscribeAll = new Subject();
    this.planogramService.setAuthToken()
      .then(() => {
        this.fetchLockedPlanogram();
        this.getLatestNames().then(() => {
          this.fetchLastWeeksData();
          this.fetchThisWeeksData();
        })
          .catch(() => {
            this.loadingPreviousWeekPlanogram = false;
          });
      });
    this.planogramService.updatePlanogramItem
      .subscribe((obj: any) => {
        let selectedArray = [];
        switch (obj.planogram_side) {
          case 'FOS B':
            selectedArray = this.previousWeekGroups.FOSNR_B;
            break;
          case 'FOS C':
            selectedArray = this.previousWeekGroups.FOSNR_C;
            break;
          case 'FOS D':
            selectedArray = this.previousWeekGroups.FOSNR_D;
            break;
          default:
            selectedArray = [];
            break;
        }
        selectedArray.forEach((item: any) => {
          if (item.id == obj.id) {
            item.weeks_old = obj.weeks_old;
            item.tw_forecast = obj.tw_forecast;
            item.on_hand = obj.on_hand;
            item.on_order = obj.on_order;
            item.base_ca = obj.base_ca;
            item.calc_ca = obj.calc_ca;
          }
        });
      });

    this.planogramService.planogramItemActionHandler
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        const rowData = res.actionData;
        if (res.action == 'edit') {
          this.addItemDialog(rowData);
        } else {
          this.openDeleteDialog(rowData);
        }
      });
  }

  openDeleteDialog(actionData) {
    let confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: actionData.title }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteCurrentWeekItem(actionData);
      }
    });
  }

  deleteCurrentWeekItem(itemData) {
    this.gridApi.showLoadingOverlay();
    this.planogramService.deleteMovie(itemData.id)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        let itemIndex = -1;
        this.thisWeekData.forEach((element, index) => {
          if (element.id == itemData.id)
            itemIndex = index;
        });
        if (itemIndex > -1) {
          this.thisWeekData.splice(itemIndex, 1);
          this.gridApi.applyTransaction({ remove: [itemData] });
        }
        this.gridApi.hideOverlay();
        this.toastrService.success('Item has been successfully deleted.')
      },
        err => {
          this.gridApi.hideOverlay();
          this.toastrService.error('Something went wrong.');
        });
  }

  updateData() {
    const dialogRef = this.dialog.open(PlanogramTableComponent, {
      data: {
        tableData: JSON.parse(JSON.stringify(this.previousWeekGroups)),
        userId: this.userObj.userId || 1,
        name: this.previousWeekFileName
      },
      disableClose: true,
      maxHeight: '90vh'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.length) {
        for (let i = 0; i < result.length; i++) {
          if (i <= 39) {
            this.previousWeekGroups.FOSNR_B[i].weeks_old = result[i].weeks_old;
            this.previousWeekGroups.FOSNR_B[i].tw_forecast = result[i].tw_forecast;
            this.previousWeekGroups.FOSNR_B[i].on_hand = result[i].on_hand;
            this.previousWeekGroups.FOSNR_B[i].on_order = result[i].on_order;
            this.previousWeekGroups.FOSNR_B[i].base_ca = result[i].base_ca;
            this.previousWeekGroups.FOSNR_B[i].calc_ca = result[i].calc_ca;
          } else if (i > 39 && i <= 79) {
            this.previousWeekGroups.FOSNR_D[i - 40].weeks_old = result[i].weeks_old;
            this.previousWeekGroups.FOSNR_D[i - 40].tw_forecast = result[i].tw_forecast;
            this.previousWeekGroups.FOSNR_D[i - 40].on_hand = result[i].on_hand;
            this.previousWeekGroups.FOSNR_D[i - 40].on_order = result[i].on_order;
            this.previousWeekGroups.FOSNR_D[i - 40].base_ca = result[i].base_ca;
            this.previousWeekGroups.FOSNR_D[i - 40].calc_ca = result[i].calc_ca;
          } else {
            this.previousWeekGroups.FOSNR_C[i - 80].weeks_old = result[i].weeks_old;
            this.previousWeekGroups.FOSNR_C[i - 80].tw_forecast = result[i].tw_forecast;
            this.previousWeekGroups.FOSNR_C[i - 80].on_hand = result[i].on_hand;
            this.previousWeekGroups.FOSNR_C[i - 80].on_order = result[i].on_order;
            this.previousWeekGroups.FOSNR_C[i - 80].base_ca = result[i].base_ca;
            this.previousWeekGroups.FOSNR_C[i - 80].calc_ca = result[i].calc_ca;
          }
        }
      }
    });
  }

  rearrangePreviousWeekData() {
    this.previousWeekGroups.FOSNR_B = [];
    this.previousWeekGroups.FOSNR_C = [];
    this.previousWeekGroups.FOSNR_D = [];
    this.previousWeekData.forEach(element => {
      element.planogram_side == 'FOS B' ? this.previousWeekGroups.FOSNR_B.push(element) :
        (element.planogram_side == 'FOS C' ? this.previousWeekGroups.FOSNR_C.push(element) :
          this.previousWeekGroups.FOSNR_D.push(element));
    });
  }

  rearrangeThisWeekData() {
    this.thisWeekData.forEach(element => {
      switch (element.planogram_side) {
        case 'FOS B':
          element.planogram_side = enforcementDropDownOptions[1];
          break;
        case 'FOS C':
          element.planogram_side = enforcementDropDownOptions[2];
          break;
        case 'FOS D':
          element.planogram_side = enforcementDropDownOptions[3];
          break;
        default:
          element.planogram_side = enforcementDropDownOptions[0];
          break;
      }
    });
  }

  rearrangeNextWeekData(data) {
    const nextWeekGroups = {
      FOSNR_B: [],
      FOSNR_C: [],
      FOSNR_D: [],
      LEFT_OVERS: []
    };
    nextWeekGroups.FOSNR_B = [];
    nextWeekGroups.FOSNR_C = [];
    nextWeekGroups.FOSNR_D = [];
    nextWeekGroups.LEFT_OVERS = [];
    data.updated_planogram.forEach((element: any, index: number) => {
      if (element.color_code && element.color_code == 'lightyellow') element.label = this.labels[0];
      if (element.color_code && element.color_code == 'orangered') element.label = this.labels[4];
      element.originalLabel = element.label;
      element.originalPosition = element.position;
      element.originalColor = element.color_code;
      if (element.planogram_side == 'FOS B') nextWeekGroups.FOSNR_B.push(element);
      if (element.planogram_side == 'FOS C') nextWeekGroups.FOSNR_C.push(element);
      if (element.planogram_side == 'FOS D') nextWeekGroups.FOSNR_D.push(element);
    });
    data.titles_new_unused.forEach((element: any) => {
      element.label = this.labels[2];
      element.originalLabel = element.label;
      element.originalPosition = element.position;
      element.originalColor = element.color_code;
      nextWeekGroups.LEFT_OVERS.push(element);
    });
    data.titles_removed.forEach((element: any) => {
      element.label = this.labels[3];
      element.originalLabel = element.label;
      element.originalPosition = element.position;
      element.originalColor = element.color_code;
      nextWeekGroups.LEFT_OVERS.push(element);
    });
    this.nextWeekRuns.push(nextWeekGroups);
    this.nextWeekTabIndex = this.nextWeekRuns.length - 1;
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  fetchLastWeeksData() {
    this.loadingPreviousWeekPlanogram = true;
    this.planogramService.getPlanogramData(this.previousWeekFileName, this.userObj.userId || 1)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        if (res && res.payload) {
          this.previousWeekData = res.payload;
          this.rearrangePreviousWeekData();
        }
        this.loadingPreviousWeekPlanogram = false;
      },
        err => {
          this.loadingPreviousWeekPlanogram = false;
        });
  }

  fetchThisWeeksData() {
    this.gridApi.showLoadingOverlay();
    this.planogramService.getNewMovies(this.currentWeekFileName, this.userObj.userId || 1)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        if (res && res.payload) {
          this.thisWeekData = res.payload;
          this.rearrangeThisWeekData();
        }
        this.gridApi.hideOverlay();
      },
        err => {
          this.gridApi.hideOverlay();
        });
  }

  updateThisWeeksMovie(movieObj: { id: number, group: number, minimum_facing: number, maximum_facing: number, enforce_top: boolean, planogram_side: string }) {
    this.planogramService.updateMovie(movieObj.id, movieObj)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {

      });
  }

  cellValueChanged(rowData: any) {
    const obj = {
      id: rowData.data.id,
      group: rowData.data.group,
      minimum_facing: rowData.data.minimum_facing,
      maximum_facing: rowData.data.maximum_facing,
      enforce_top: rowData.data.enforce_top,
      planogram_side: rowData.data.planogram_side
    };
    switch (obj.planogram_side) {
      case enforcementDropDownOptions[1]:
        obj.planogram_side = 'FOS B';
        break;
      case enforcementDropDownOptions[2]:
        obj.planogram_side = 'FOS C';
        break;
      case enforcementDropDownOptions[3]:
        obj.planogram_side = 'FOS D';
        break;
      default:
        obj.planogram_side = '';
        break;
    }
    this.updateThisWeeksMovie(obj);
  }

  uploadLastWeeksData(file: File) {
    this.uploadingPreviousWeekFile = true;
    this.planogramService.addPlanogramData(file, file.name, this.userObj.userId || 1)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        this.toastrService.success('File uploaded successfully!');
        this.uploadingPreviousWeekFile = false;
        this.getLatestNames().then(() => {
          this.fetchLastWeeksData();
        });
      },
        err => {
          this.uploadingPreviousWeekFile = false;
          if (err && err.error && err.error.description) {
            const errorMessage = err.error.description.split(':');
            if (errorMessage.length > 1)
              this.toastrService.error(errorMessage[1]);
            else
              this.toastrService.error("Something went wrong.");
          } else
            this.toastrService.error("Something went wrong.");
        });
  }

  uploadCurrentWeeksData(file: File) {
    this.uploadingThisWeekFile = true;
    this.planogramService.addNewMovies(file, file.name, this.userObj.userId || 1)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        this.uploadingThisWeekFile = false;
        this.toastrService.success('File uploaded successfully!');
        this.getLatestNames().then(() => {
          this.fetchThisWeeksData();
        });
      },
        err => {
          this.uploadingThisWeekFile = false;
          if (err && err.error && err.error.description) {
            const errorMessage = err.error.description.split(':');
            if (errorMessage.length > 1)
              this.toastrService.error(errorMessage[1]);
            else
              this.toastrService.error("Something went wrong.");
          } else
            this.toastrService.error("Something went wrong.");
        });
  }

  runOptimizer() {
    if (!this.previousWeekFileName || !this.currentWeekFileName) return;
    this.runningOptimizer = true;
    const formData: FormData = new FormData();
    formData.append('user', this.userObj.userId || "1");
    formData.append('planogram', this.previousWeekFileName);
    formData.append('movies', this.currentWeekFileName);
    this.planogramService.runOptimizer(formData)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        if (res.payload && res.payload.payload) {
          this.rearrangeNextWeekData(res.payload.payload);
        }
        this.runningOptimizer = false;
      },
        err => {
          this.runningOptimizer = false;
        });
  }

  counter(i: number): Array<any> {
    return new Array(i);
  }

  importPrvWeekData() {
    (document.getElementById('upload-prv-week-planogram-data') as any).value = '';
    document.getElementById('upload-prv-week-planogram-data').click();
  }

  importCurrentWeekData() {
    (document.getElementById('upload-current-planogram-data') as any).value = '';
    document.getElementById('upload-current-planogram-data').click();
  }

  onFileSelect(event, type: string) {
    const files = event.target.files as FileList;
    const file = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (type == 'previous') this.uploadLastWeeksData(file);
      else if (type == 'current') this.uploadCurrentWeeksData(file);
      event.target.value = null;
    }
    reader.onerror = e => {
      event.target.value = null;
      this.toastrService.error('File uploading failed!');
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  // WHEN NEXT WEEK/LEFTOVER ITEM IS DROPPED
  drop(runIndex: number, index: number, arrayType: string, locked = false) {

    if (locked) return;

    // SELECTING THE ARRAY ON WHICH ITEM IS DROPPED
    let arrayDropped = [];
    let arrayDroppedType = '';
    switch (arrayType) {
      case draggableArrayTypes.NEW_FOSNER_B:
        arrayDropped = this.nextWeekRuns[runIndex].FOSNR_B;
        arrayDroppedType = 'FOS B';
        break;
      case draggableArrayTypes.NEW_FOSNER_C:
        arrayDropped = this.nextWeekRuns[runIndex].FOSNR_C;
        arrayDroppedType = 'FOS C';
        break;
      case draggableArrayTypes.NEW_FOSNER_D:
        arrayDropped = this.nextWeekRuns[runIndex].FOSNR_D;
        arrayDroppedType = 'FOS D';
        break;
      case draggableArrayTypes.LEFT_OVER:
        arrayDropped = this.nextWeekRuns[runIndex].LEFT_OVERS;
        break;
      default:
        return;
    }

    // SELECTING THE ARRAY FROM WHICH ITEM IS DRAGGED
    let draggedArray = [];
    let draggedArrayType = '';
    switch (this.draggingItem.arrayType) {
      case draggableArrayTypes.NEW_FOSNER_B:
        draggedArray = this.nextWeekRuns[runIndex].FOSNR_B;
        draggedArrayType = 'FOS B';
        break;
      case draggableArrayTypes.NEW_FOSNER_C:
        draggedArray = this.nextWeekRuns[runIndex].FOSNR_C;
        draggedArrayType = 'FOS C';
        break;
      case draggableArrayTypes.NEW_FOSNER_D:
        draggedArray = this.nextWeekRuns[runIndex].FOSNR_D;
        draggedArrayType = 'FOS D';
        break;
      case draggableArrayTypes.LEFT_OVER:
        draggedArray = this.nextWeekRuns[runIndex].LEFT_OVERS;
        break;
      default:
        return;
    }

    if (index == this.draggingItem.index && draggedArrayType == arrayDroppedType) return; // dropped on same position

    // SWAPPING ITEMS
    const firstElement = arrayDropped[index];
    const secondElement = draggedArray[this.draggingItem.index];

    // POSITIONS SWAPING
    const firstElementPostion = firstElement.position;
    const secondElementPostion = secondElement.position;

    firstElement.position = secondElementPostion;
    secondElement.position = firstElementPostion;

    // SWAPPING SIDES
    firstElement.planogram_side = draggedArrayType;
    secondElement.planogram_side = arrayDroppedType;

    // FOR LEFTOVERS LABELS
    if (!arrayDroppedType || !draggedArrayType) {
      if (!(!arrayDroppedType && !draggedArrayType)) {
        let firstLabel = firstElement.label;
        let secondLabel = secondElement.label;
        let firstColor = firstElement.color_code;
        let secondColor = secondElement.color_code;

        arrayDroppedType ? firstLabel = this.labels[0] : firstLabel = this.labels[3];
        draggedArrayType ? secondLabel = this.labels[0] : secondLabel = this.labels[3];
        arrayDroppedType ? firstColor = 'lightyellow' : firstColor = 'lightcoral';
        draggedArrayType ? secondColor = 'lightyellow' : secondColor = 'lightcoral';

        firstElement.label = secondLabel;
        secondElement.label = firstLabel;
        firstElement.color_code = secondColor;
        secondElement.color_code = firstColor;
      }
    } else {
      // SWAPPING LABELS
      if (!firstElement.label) {
        firstElement.label = this.labels[4];
        firstElement.color_code = 'orangered';
      }
      if (!secondElement.label) {
        secondElement.label = this.labels[4];
        secondElement.color_code = 'orangered';
      }
    }

    // If items are placed back to original positions
    if (firstElement.originalPosition == firstElement.position) {
      firstElement.label = firstElement.originalLabel;
      firstElement.color_code = firstElement.originalColor;
    }
    if (secondElement.originalPosition == secondElement.position) {
      secondElement.label = secondElement.originalLabel;
      firstElement.color_code = firstElement.originalColor;
    }

    arrayDropped[index] = secondElement;
    draggedArray[this.draggingItem.index] = firstElement;

    // REMOVE EMPTY ITEM FROM LEFT OVERS ARRAY
    if (!draggedArray[this.draggingItem.index] && this.draggingItem.arrayType == draggableArrayTypes.LEFT_OVER) {
      draggedArray.splice(this.draggingItem.index, 1);
    }

    if (this.lockedPlanogramName) {
      arrayDropped[index].side = arrayDropped[index].planogram_side;
      draggedArray[this.draggingItem.index].side = draggedArray[this.draggingItem.index].planogram_side;
      const items = [
        arrayDropped[index],
        draggedArray[this.draggingItem.index]
      ];
      this.updatePlanogramOnSwap(items);
    }

    return;
  }

  updatePlanogramOnSwap(items) {
    const obj = {
      swappedItems: items,
      updatedBy: this.userObj.userId || 1,
      name: this.lockedPlanogramName
    };
    const formData = new FormData();
    formData.append('update_current_plano', JSON.stringify(obj));
    this.planogramService.updatePlanogramOnSwap(formData)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
      },
        err => {
        });
  }

  // ON DRAG START: Store the related info
  cdkDragStarted(index: number, arrayType: string) {
    this.draggingItem.index = index;
    this.draggingItem.arrayType = arrayType;
  }

  addItemDialog(itemData = null) {
    const dialogRef = this.dialog.open(AddMovieComponent, {
      data: {
        name: this.currentWeekFileName,
        userId: this.userObj.userId || 1,
        itemData: itemData ? JSON.parse(JSON.stringify(itemData)) : null
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (itemData) {
          switch (result.planogram_side) {
            case 'FOS B':
              result.planogram_side = enforcementDropDownOptions[1];
              break;
            case 'FOS C':
              result.planogram_side = enforcementDropDownOptions[2];
              break;
            case 'FOS D':
              result.planogram_side = enforcementDropDownOptions[3];
              break;
            default:
              result.planogram_side = enforcementDropDownOptions[0];
              break;
          }
          this.thisWeekData.forEach((element, index) => {
            if (element.id == result.id)
              this.thisWeekData[index] = JSON.parse(JSON.stringify(result));
          });
          this.gridApi.setRowData(this.thisWeekData);
        } else {
          this.fetchThisWeeksData();
        }
      }
    });
  }

  // DOWNLOAD PLANOGRAM IMPLEMENTATION : EXCEL FILE
  exportAsXLSX(data: { FOSNR_B: [], FOSNR_C: [], FOSNR_D: [] }): void {
    const workbookData = [
      {
        workSheet: 'Tab1',
        rows: [...data.FOSNR_B, ...data.FOSNR_D, ...data.FOSNR_C]
      }
    ];

    workbookData[0].rows.forEach((element: any) => {
      delete element.label;
    });

    this.exportAsExcelFile(workbookData, 'ALGO_Planogram');
  }

  private async exportAsExcelFile(workbookData: any[], excelFileName: string) {
    const workbook = new ExcelJS.Workbook();

    workbookData.forEach(({ workSheet, rows }) => {
      const sheet = workbook.addWorksheet(workSheet);
      const uniqueHeaders = [
        ...new Set(
          rows.reduce((prev, next) => [...prev, ...Object.keys(next)], [])
        )
      ];
      sheet.columns = uniqueHeaders.map(x => ({ header: x, key: x }));

      rows.forEach((jsonRow, i) => {
        let cellValues = { ...jsonRow };

        uniqueHeaders.forEach((header: any, j) => {
          if (Array.isArray(jsonRow[header])) {
            cellValues[header] = "";
          }
        });
        sheet.addRow(cellValues);
        uniqueHeaders.forEach((header: any, j) => {
          if (Array.isArray(jsonRow[header])) {
            const jsonDropdown = jsonRow[header];
            sheet.getCell(
              this.getSpreadSheetCellNumber(i + 1, j)
            ).dataValidation = {
              type: "list",
              formulae: [`"${jsonDropdown.join(",")}"`]
            };
          }
        });
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(buffer, excelFileName);
  }

  private getSpreadSheetCellNumber(row, column) {
    let result = "";

    // Get spreadsheet column letter
    let n = column;
    while (n >= 0) {
      result = String.fromCharCode((n % 26) + 65) + result;
      n = Math.floor(n / 26) - 1;
    }

    // Get spreadsheet row number
    result += `${row + 1}`;

    return result;
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }
  // EXPORT CODE ENDED

  saveNewPlanogram(index: number): void {
    const titlesRemoved = [];
    const titlesAdded = [];
    const updatedPlanogram = [
      ...this.nextWeekRuns[index].FOSNR_B, ...this.nextWeekRuns[index].FOSNR_D, ...this.nextWeekRuns[index].FOSNR_C
    ];
    this.nextWeekRuns[index].LEFT_OVERS.forEach((element: any) => {
      if (element.label && element.label == this.labels[2]) titlesAdded.push(element);
      else if (element.label && element.label == this.labels[3]) titlesRemoved.push(element);
    });
    if (this.savePlanogramSubscription) this.savePlanogramSubscription.unsubscribe();
    this.lockingPlanogram = index + 1;
    const formData: FormData = new FormData();
    formData.append('user', this.userObj.userId || "1");
    formData.append('titles_removed', JSON.stringify(titlesRemoved));
    formData.append('titles_added', JSON.stringify(titlesAdded));
    formData.append('updated_planogram', JSON.stringify(updatedPlanogram));
    // formData.append('titles_new_unused', '');
    this.savePlanogramSubscription = this.planogramService.savePlanogram(formData)
      .subscribe((res: any) => {
        if (res.payload && res.payload.name) {
          this.toastrService.success(`Planogram has been locked successfully!`);
          this.lockedPlanogramName = res.payload.name;
          this.fetchLockedPlanogram();
        }
      },
        err => {
          this.lockingPlanogram = 0;
          this.toastrService.error('Something went wrong.');
        });
  }

  getLatestNames(): Promise<boolean> {
    if (this.namesSubscription) this.namesSubscription.unsubscribe();
    return new Promise((resolve, reject) => {
      this.namesSubscription = this.planogramService.getLatestNames()
        .subscribe((res: any) => {
          if (res.payload) {
            res.payload.old_week_plano ? this.previousWeekFileName = res.payload.old_week_plano : null;
            res.payload.new_week_movies ? this.currentWeekFileName = res.payload.new_week_movies : null;
          }
          resolve(true);
        },
          err => {
            reject();
          });
    });
  }

  getSavingButtonTitle(index: number): string {
    if (this.lockingPlanogram == index + 1) return "SAVING...";
    if (this.lockedPlanogram == index) return "ALREADY SAVED"
    return "SAVE";
  }

  fetchLockedPlanogram() {
    this.planogramService.fetchLockedPlanogram()
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        if (res.payload && res.payload.updated_planogram) {
          this.nextWeekRuns = [];
          this.rearrangeNextWeekData(res.payload);
          this.lockingPlanogram = 0;
          this.lockedPlanogram = 0;
        }
        if (res.payload && res.payload.name) {
          this.lockedPlanogramName = res.payload.name;
        }
      },
        err => {
        });
  }

  deletePlanogram() {
    if (!this.lockedPlanogramName) {
      return;
    }
    this.lockingPlanogram = 1;
    this.planogramService.deleteSavedPlanogram(this.lockedPlanogramName)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        this.getLatestNames().then(() => {
          this.nextWeekRuns = [];
          this.lockedPlanogram = -1;
          this.lockingPlanogram = 0;
          this.toastrService.success(`Planogram has been deleted successfully!`);
          this.lockedPlanogramName = '';
          this.nextWeekTabIndex = 0;
        });
      },
        err => {
          this.lockingPlanogram = 0;
          this.toastrService.error('Something went wrong.');
        });
  }

  discardRun(runIndex: number) {
    this.nextWeekRuns.splice(runIndex, 1);
  }

}
