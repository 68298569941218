import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { MSMeasureProfile } from './model/MSMeasureProfile';

@Component({
  selector: 'app-order-list-measures-new-component',
  templateUrl: './order-list-measures-new-component.component.html',
  styleUrls: ['./order-list-measures-new-component.component.scss']
})
export class OrderListMeasuresNewComponentComponent implements OnInit {

  savedProfiles: MSMeasureProfile[];
  selectedProfile: MSMeasureProfile;
  maxProfileCount = 20;
  public filteredMeasures = [];
  selectAll = false;
  constructor(
    public dialogRef: MatDialogRef<OrderListMeasuresNewComponentComponent>,
    public spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public config: ConfigurationService,
    public storage: LocalstorageService
  ) { }

  ngOnInit() {
    this.getOrderListPrfiles();
    this.data.measureList = JSON.parse(JSON.stringify(this.data.measureList));
    this.data.measureList = JSON.parse(JSON.stringify(this.data.measureList));
    const udpMeasures = this.data.measureList.filter(row => row.parentId === 10)
    const normalMeasures = this.data.measureList.filter(row => row.parentId !== 10).sort((a, b) => a.measureName > b.measureName ? 1 : -1);
    this.data.measureList = [...normalMeasures, ...udpMeasures];
  }
  getOrderListPrfiles() {
    let params = {
      UserId: this.storage.get(USER_ID),
    };
    this.spinner.show();
    this.config
      .MsForecastGetMeasureOrderList(params)
      .subscribe((response) => {
        this.savedProfiles = response.sort((a, b) => a.msMeasureProfileName > b.msMeasureProfileName ? 1 : -1);
        this.spinner.hide();
        if (this.savedProfiles.length > 0) {
          const appliedProfile = this.savedProfiles.find((el) => el.applied);
          this.selectedProfile = appliedProfile
            ? appliedProfile
            : null;
          if (this.selectedProfile) {
            this.selectedProfile.checked = true;
            this.data.measureList.forEach((element) => {
              const measures = this.selectedProfile.measureIds.split(',')
              if (measures.includes(element.measureId.toString()))
                element.checked = true;
              else element.checked = false;
            });
          }
          else {
            this.selectAll = true
            this.data.measureList.forEach((element) => {
              element.checked = true;
            });
          }

        } else {
          this.data.measureList.forEach((element) => {
            element.checked = false;
          });
        }
      });
  }
  createProfile() {
    // if (this.savedProfiles.length >= this.maxProfileCount) {
    //   this.data.maxCountReached = true;
    // } else {
    //   this.data.isCreateProfile = true;
    //   this.data.isFirst = this.savedProfiles.length == 0;
    // }
    this.dialogRef.close(this.data);
  }
  profileChanged(event) {
    this.selectAll = false
    if (event.checked) {
      this.savedProfiles.forEach(res => {
        if (res !== event) {
          res.checked = false;
        } else {
          event.checked = true;
        }
      });
      this.selectedProfile = event;
      this.data.measureList.forEach(element => {
        const measures = event.measureIds.split(',')
        if (measures.includes(element.measureId.toString()))
          element.checked = event
        else
          element.checked = false
      });
    } else {
      this.data.measureList.forEach(element => {

        element.checked = false
      });
    }
  }
  applyMeasure() {
    let model = {
      msMeasureProfileId: this.selectedProfile.msMeasureProfileId,
      active: true,
      applied: true,
      currentUserId: this.storage.get(USER_ID),
    };
    this.spinner.show();
    this.config.MsForecastApplyMeasureOrderList(model).subscribe((resp) => {
      this.data.profileApplied = true;
      this.data.selectedProfile = this.selectedProfile;
      this.dialogRef.close(this.data);
      this.spinner.hide();
    });
  }
  updateMeasure() {
    let selectedMeasures = this.data.measureList.filter((x) => x.checked);
    let measureIds = '';
    selectedMeasures.forEach((element) => {
      measureIds = measureIds + element.measureId + ',';
    });
    measureIds = measureIds.slice(0, -1);
    this.selectedProfile.measureIds = measureIds;
    let model = {
      ...this.selectedProfile,
      userId: this.storage.get(USER_ID),
    };
    this.spinner.show();
    this.config.MsForecastUpdateMeasureOrderList(model).subscribe((resp) => {
      this.spinner.hide();
    });
  }
  selectAllChanged = (value) => {
    this.data.measureList.forEach((element) => {
      element.checked = value.checked;
    });
  };
  searchForMeasure = (value) => {
    if (value) {

    }
    this.data.measureList = this.data.measureList.filter(row => row.measureName.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1);
  }
}
