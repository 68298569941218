import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { FB_Template } from '../../global.models';

@Component({
  selector: 'app-fb-forecast-scrolling',
  templateUrl: './fb-forecast-scrolling.component.html',
  styleUrls: ['./fb-forecast-scrolling.component.scss'],
})
export class FbForecastScrollingComponent implements OnInit, OnChanges {
  @Input() productList;
  @Input() productTree;
  @Input() isChannel;
  @Input() isProduct;
  @Input() fbTemplateId;
  @Input() selectedDistibutor;
  @Input() currentAccount;
  @Input() selectedProduct;
  @Output() selectionChange = new EventEmitter();
  selectedValue;
  loadDropDown;
  leafNodes = [];
  value;
  index = 0;
  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400,
  });
  @Input() scrollText: any;
  data: any;
  jsondata: any;
  public flatList = [];
  constructor() {}

  ngOnInit(): void {
    this.data = this.productList;
    if (this.isChannel) {
      this.markCheckedIfAnyChannelNodeSelected();
      this.filterChannelNodes();
    } else if (this.isProduct) {
      this.markCheckedIfAnyNodeSelected();
      this.filterNodes();
    }
    if (this.fbTemplateId !== FB_Template.Retail_Direct && this.fbTemplateId !== FB_Template.Retail_InDirect) {
      this.productList = [
        new TreeviewItem({
          text: 'All',
          children: this.productList,
          value: 0,
        }),
      ];
    }
    this.productList = this.productList.sort((a,b) => a.text > b.text ? 1 : -1);
    // this.createFlatList();
    const selectedElement = this.productList.find((el) => el.text === 'All');
    if (!this.scrollText) {
      if (selectedElement) {
        this.scrollText = selectedElement.text;
      } else if (this.productList.length > 0) {
        this.scrollText = this.productList[0].text;
      }
    }
    if (this.selectedDistibutor) {
      this.scrollText = this.selectedDistibutor.distributorName;
    }
    if (this.currentAccount) {
      this.scrollText = this.currentAccount.accountName;
    }
    if (this.selectedProduct) {
      const currentProduct = this.productList.find(row => row.value === this.selectedProduct);
      if (currentProduct) {
        this.scrollText = currentProduct.text;
      }
    }
  }
  ngOnChanges(change: SimpleChanges) {
    if (change.selectedProduct && change.selectedProduct.currentValue) {
      if (this.selectedProduct) {
        const currentProduct = this.productList.find(row => row.value === this.selectedProduct);
        if (currentProduct) {
          this.scrollText = currentProduct.text;
        }
      }
    }
  }
  public markCheckedIfAnyNodeSelected = () => {
    this.productList.forEach((channelOrBrand) => {
      if (channelOrBrand.internalChecked) {
        channelOrBrand.internalChildren?.forEach((regionOrProductGroup) => {
          regionOrProductGroup.internalChecked = true;
          channelOrBrand.internalChildren?.forEach((countryOrProductFamily) => {
            countryOrProductFamily.internalChecked = true;
            countryOrProductFamily.internalChildren?.forEach(
              (distiOrParentProduct) => {
                distiOrParentProduct.internalChecked = true;
                distiOrParentProduct.internalChildren?.forEach(
                  (itemOrAccount) => {
                    itemOrAccount.internalChecked = true;
                  }
                );
              }
            );
          });
        });
      } else {
        channelOrBrand.internalChildren?.forEach((regionOrProductGroup1) => {
          if (regionOrProductGroup1.internalChecked) {
            regionOrProductGroup1.internalChildren?.forEach(
              (countryOrProductFamily1) => {
                countryOrProductFamily1.internalChecked = true;
                countryOrProductFamily1.internalChildren?.forEach(
                  (distiOrParentProduct1) => {
                    distiOrParentProduct1.internalChecked = true;
                    distiOrParentProduct1.internalChildren?.forEach(
                      (itemOrAccount1) => {
                        itemOrAccount1.internalChecked = true;
                      }
                    );
                  }
                );
              }
            );
          } else {
            regionOrProductGroup1.internalChildren?.forEach(
              (countryOrProductFamily2) => {
                if (countryOrProductFamily2.internalChecked) {
                  countryOrProductFamily2.internalChildren?.forEach(
                    (distiOrParentProduct2) => {
                      distiOrParentProduct2.internalChecked = true;
                      countryOrProductFamily2.internalChildren?.forEach(
                        (itemOrAccount3) => {
                          itemOrAccount3.internalChecked = true;
                        }
                      );
                    }
                  );
                } else {
                  countryOrProductFamily2.internalChildren?.forEach(
                    (distiOrParentProduct3) => {
                      if (distiOrParentProduct3.internalChecked) {
                        distiOrParentProduct3.internalChildren?.forEach(
                          (itemOrAccount4) => {
                            if (itemOrAccount4.internalChecked) {
                              distiOrParentProduct3.internalChecked = true;
                              countryOrProductFamily2.internalChecked = true;
                              regionOrProductGroup1.internalChecked = true;
                            }
                          }
                        );
                      } else {
                        distiOrParentProduct3.internalChildren?.forEach(
                          (itemOrAccount5) => {
                            if (itemOrAccount5.internalChecked) {
                              distiOrParentProduct3.internalChecked = true;
                              countryOrProductFamily2.internalChecked = true;
                              regionOrProductGroup1.internalChecked = true;
                            }
                          }
                        );
                      }
                    }
                  );
                }
              }
            );
          }
        });
      }
    });
  };
  public markCheckedIfAnyChannelNodeSelected = () => {
    this.productList.forEach((channelOrBrand) => {
      if (channelOrBrand.internalChecked) {
        channelOrBrand.internalChildren?.forEach((regionOrProductGroup) => {
          regionOrProductGroup.internalChecked = true;
          channelOrBrand.internalChildren?.forEach((countryOrProductFamily) => {
            countryOrProductFamily.internalChecked = true;
            countryOrProductFamily.internalChildren?.forEach(
              (distiOrParentProduct) => {
                distiOrParentProduct.internalChecked = true;
              }
            );
          });
        });
      } else {
        channelOrBrand.internalChildren?.forEach((regionOrProductGroup1) => {
          if (regionOrProductGroup1.internalChecked) {
            regionOrProductGroup1.internalChildren?.forEach(
              (countryOrProductFamily1) => {
                countryOrProductFamily1.internalChecked = true;
                countryOrProductFamily1.internalChildren?.forEach(
                  (distiOrParentProduct1) => {
                    distiOrParentProduct1.internalChecked = true;
                  }
                );
              }
            );
          } else {
            regionOrProductGroup1.internalChildren?.forEach(
              (countryOrProductFamily2) => {
                if (countryOrProductFamily2.internalChecked) {
                  countryOrProductFamily2.internalChildren?.forEach(
                    (distiOrParentProduct2) => {
                      distiOrParentProduct2.internalChecked = true;
                    }
                  );
                } else {
                  countryOrProductFamily2.internalChildren?.forEach(
                    (distiOrParentProduct3) => {
                      if (distiOrParentProduct3.internalChecked) {
                        if (distiOrParentProduct3.internalChecked) {
                          distiOrParentProduct3.internalChecked = true;
                          countryOrProductFamily2.internalChecked = true;
                          regionOrProductGroup1.internalChecked = true;
                        }
                      } else {
                        distiOrParentProduct3.internalChildren?.forEach(
                          (itemOrAccount5) => {
                            if (itemOrAccount5.internalChecked) {
                              distiOrParentProduct3.internalChecked = true;
                              countryOrProductFamily2.internalChecked = true;
                              regionOrProductGroup1.internalChecked = true;
                            }
                          }
                        );
                      }
                    }
                  );
                }
              }
            );
          }
        });
      }
    });
  };
  public filterNodes = () => {
    this.productList.forEach((element1) => {
      if (element1.internalChildren && element1.internalChildren.length > 0) {
        if (this.isChannel) {
          element1.isChannel = true;
        } else if (this.isProduct) {
          element1.isBrand = true;
        }
        element1.internalChildren.forEach((element2) => {
          if (
            element2.internalChildren &&
            element2.internalChildren.length > 0
          ) {
            if (this.isChannel) {
              element2.isRegion = true;
            } else if (this.isProduct) {
              element2.isProductGroup = true;
            }
            element2.internalChildren.forEach((element3) => {
              if (
                element3.internalChildren &&
                element3.internalChildren.length > 0
              ) {
                if (this.isChannel) {
                  element3.isCountry = true;
                } else if (this.isProduct) {
                  element3.isProductFamily = true;
                }
                element3.internalChildren.forEach((element4) => {
                  if (
                    element4.internalChildren &&
                    element4.internalChildren.length > 0
                  ) {
                    if (this.isChannel) {
                      element4.isDistributor = true;
                    } else if (this.isProduct) {
                      element4.isParentProduct = true;
                    }
                    element4.internalChildren = element4.internalChildren.map((leaf) => {
                        if (this.isChannel) {
                          leaf.isItemAccount = true;
                        } else if (this.isProduct) {
                          leaf.isItemManager = true;
                        }
                        this.flatList.push(leaf);
                        return leaf;
                      });
                    this.flatList.push(element4);
                  }
                });
                this.flatList.push(element3);
              }
            });
            this.flatList.push(element2);
          }
        });
        this.flatList.push(element1);
      }
    });
  };
  public filterChannelNodes = () => {
    this.productList.forEach((element1) => {
      if (element1.internalChildren && element1.internalChildren.length > 0) {
        if (this.isChannel) {
          element1.isChannel = true;
        } else if (this.isProduct) {
          element1.isBrand = true;
        }
        element1.internalChildren.forEach((element2) => {
          if (
            element2.internalChildren &&
            element2.internalChildren.length > 0
          ) {
            if (this.isChannel) {
              element2.isRegion = true;
            } else if (this.isProduct) {
              element2.isProductGroup = true;
            }
            element2.internalChildren.forEach((element3) => {
              if (
                element3.internalChildren &&
                element3.internalChildren.length > 0
              ) {
                if (this.isChannel) {
                  element3.isCountry = true;
                } else if (this.isProduct) {
                  element3.isProductFamily = true;
                }
                element3.internalChildren.forEach((element4) => {
                  if (
                    element4.internalChildren &&
                    element4.internalChildren.length > 0
                  ) {
                    if (this.isChannel) {
                      element4.isItemAccount = true;
                    }
                    this.flatList.push(element4);
                    return element4;
                  }
                });
                this.flatList.push(element3);
              }
            });
            this.flatList.push(element2);
          }
        });
        this.flatList.push(element1);
      }
    });
  };
  getLeafNodes(nodes, result = []) {
    for (var i = 0, length = nodes.length; i < length; i++) {
      if (
        !nodes[i].internalChildren ||
        nodes[i].internalChildren.length === 0
      ) {
        result.push(nodes[i]);
      } else {
        result = this.getLeafNodes(nodes[i].internalChildren, result);
      }
    }
    return result;
  }
  setValues(event) {
    this.scrollText = event.text;
    event = {
      ...event,
      isProduct: this.isProduct,
      isChannel: this.isChannel,
    };
    const selectedValue = this.searchSelectedValue(event);
    this.selectionChange.emit(event);
    if (this.isChannel) {
      if (selectedValue?.isChannel) {
        this.leafNodes = this.flatList.filter((el) => el.isChannel);
      } else if (selectedValue?.isRegion) {
        this.leafNodes = this.flatList.filter((el) => el.isRegion);
      } else if (selectedValue?.isCountry) {
        this.leafNodes = this.flatList.filter((el) => el.isCountry);
      } else if (selectedValue?.isDistributor) {
        this.leafNodes = this.flatList.filter((el) => el.isDistributor);
      } else if (selectedValue?.isItemAccount) {
        this.leafNodes = this.flatList.filter((el) => el.isItemAccount);
      }
    } else if (this.isProduct) {
      if (selectedValue?.isBrand) {
        this.leafNodes = this.flatList.filter((el) => el.isBrand);
      } else if (selectedValue?.isProductGroup) {
        this.leafNodes = this.flatList.filter((el) => el.isProductGroup);
      } else if (selectedValue?.isProductFamily) {
        this.leafNodes = this.flatList.filter((el) => el.isProductFamily);
      } else if (selectedValue?.isParentProduct) {
        this.leafNodes = this.flatList.filter((el) => el.isParentProduct);
      } else if (selectedValue?.isItemManager) {
        this.leafNodes = this.flatList.filter((el) => el.isItemManager);
      }
    }
    const index = this.leafNodes.findIndex((el) => el.value === event.value);
    if (index > -1) {
      this.index = index;
    } else {
      this.index = 0;
    }
  }
  showDropDown() {
    this.loadDropDown = !this.loadDropDown;
  }
  onFilterChange(event) {}
  scrollRight() {
    if (this.index >= this.leafNodes.length) return;
    this.index++;
    this.scrollText = this.leafNodes[this.index].text;
    const value = {
      ...this.leafNodes[this.index],
      isProduct: this.isProduct,
      isChannel: this.isChannel,
    };
    this.selectionChange.emit(value);
  }
  scrollLeft() {
    if (this.index <= -1) {
      return;
    }
    this.index--;
    const value = {
      ...this.leafNodes[this.index],
      isProduct: this.isProduct,
      isChannel: this.isChannel,
    };
    this.selectionChange.emit(value);
    this.scrollText = this.leafNodes[this.index].text;
  }
  search = (tree, target) => {
    if (tree.value.length) {
      for (var i = 0; i < tree.value.length; i++) {
        if (tree.value[i] === target) {
          return tree;
        }
      }
    }
    if (tree.value === target) {
      return tree;
    }
    if (tree.children) {
      for (const child of tree.children) {
        const res = this.search(child, target);

        if (res) {
          let data = tree.children;
          return { res, data };
        }
      }
    } else {
      return null;
    }
  };
  public searchSelectedValue = (node) => {
    return this.flatList.find((el) => el.value === node.value);
  };
}
