import { Component, OnInit, ViewEncapsulation, TemplateRef, ViewChild, ElementRef, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { Observable, of } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { NgxToasterService } from '../../../../../services/ngx-toaster.service';
import { LocalstorageService } from '../../../../../services/localstorage.service';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { FbForecast } from '../../../../../common/facebook.models';
import { USER_ID } from '../../../../../common/keys';
import { ForecastMeasuresComponent } from '../forecast-measures/forecast-measures.component';
import { ForecastConfirmationModalComponent } from '../forecast-confirmation-modal/forecast-confirmation-modal.component';
import { HalfYear, Month, PhysicalYear, Quarter, Week } from '../../global.models';

@Component({
  selector: 'app-fb-forecast-grid',
  templateUrl: './fb-forecast-grid.component.html',
  styleUrls: ['./fb-forecast-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FbForecastGridComponent implements OnInit, OnChanges {
  @Input() selectedProducts
  @Input() productTree
  @Output() onClickEvent = new EventEmitter()
  methodList = [
    'Algo Baseline ST Forecast',
    'DP Baseline ST Fcst',
    'CO Baseline ST Fcst',
    'Linear',
    'Curve',
    'Exponential',
    'Logarithmic',
    'Power',
    'Parabola',
    'Moving Average',
    'Single Expotential Smoothing',
    'Adaptive Smoothing',
    'Holts Linear Trend',
    'Period Percentage Change',
    'Seasonal Smoothing Method',
    'Seasonal Smoothing Average',
    'Seasonal Percentage Change',
    'Additive Decomposition',
    'Winters Additive'
  ]
  @Input() leftGroupList = [];
  @Input() measureList = [];
  @Input() dropdownObjects;
  @Input() forecastList = [];
  @Input() versionTypeId = 2;
  public selectedValue;
  width = '100%';
  height = '500';
  type = "msline";
  dataFormat = "json";
  dataSource;
  public submitted = false;
  public isEdit = false;
  public columnDefs = [];
  private gridApi: any;
  public tenureTypeList = [];
  public tenureTypeListTemp = [];
  public rowList = [];
  public gridOptions: any;
  public getRowHeight;
  public getRowStyle;
  public headerHeight;
  public modules = AllCommunityModules;
  public clientId = 1;
  public channel = null;
  public csvRecords: any;
  public customerSelectedList = [];
  public selectedItemIds = [];
  public timeframeSelectedList = [];
  public itemSeletedList = [];
  public timeframeList = [
    {
      headerName: 'Year'
    },
    {
      headerName: 'Half Year'
    },
    {
      headerName: 'Quarter'
    },
    {
      headerName: 'Monthly'
    },
    {
      headerName: 'Weekly'
    }
  ];
  public versionList = [];
  public monthList = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredCustomers: Observable<any[]>;
  filteredItems: Observable<any[]>;
  filteredTimeframe: Observable<any[]>;
  public seletedYear = null;
  public seletedVersion = null;
  public selectedMonth = null;
  public defaultRow = {};
  public selectAll = true;
  searchCustomer = new FormControl();
  searchItem = new FormControl();
  searchTimeframe = new FormControl();
  files: File[] = [];
  @Input() public yearListGrid = [];
  @Input() public calendarList = [];
  yearList = [];
  totalCalculatedList = [];
  tempColDefs = [];
  yearListTemp = [];
  yearListForFilter = [];
  graphList = [];
  tempGroupList = [];
  labelList;
  channelSelectedList;
  chooserList = [];
  gridListUI = [];
  selectedProfile: any;
  profileMeasureList: any;
  savedMeasures: any[];
  constructor(
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    public dialogRef: MatDialogRef<any>,
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef) {
  }
  get gridData() {
    return this.gridListUI.filter(row => row.isSelected);
  }
  ngOnInit() {
    this.tempGroupList = JSON.parse(JSON.stringify(this.leftGroupList));
  }
  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges && simpleChanges.measureList && simpleChanges.measureList.currentValue) {
      this.measureList = simpleChanges.measureList.currentValue
      this.savedMeasures = this.measureList
    }
    if (simpleChanges && simpleChanges.leftGroupList && simpleChanges.leftGroupList.currentValue) {
      this.leftGroupList = simpleChanges.leftGroupList.currentValue
    }
    if (simpleChanges && simpleChanges.yearListGrid && simpleChanges.yearListGrid.currentValue) {
      this.yearListGrid = simpleChanges.yearListGrid.currentValue;
      this.createUIList(true);
      this.yearListGrid.forEach(year => this.collapseYear(year));
    }
  }
  openMesuresDialog() {
    this.profileMeasureList = JSON.parse(JSON.stringify(this.savedMeasures.map(row => ({ ...row, checked: false }))))
    this.dialog.open(ForecastMeasuresComponent, {
      width: '1200px',
      panelClass: 'fb-dialog-wrapper',

      data: { measureList: this.profileMeasureList },
    }).afterClosed().subscribe(command => {
      if (command) {
        if (command.isCreateProfile) {
          this.selectedProfile = command
          this.dialog.open(ForecastConfirmationModalComponent, {
            width: '700px',
            data: {
              label: 'What would you like to name your profile?', isOnLoad: false, changeNameConfirmation: true,
              isInputRequired: true, inputLabel: 'Enter name'
            },
            disableClose: true
          }).afterClosed().subscribe(command => {
            if (command.isNameSave) {
              let selectedMeasures = this.selectedProfile.measureList.filter(x => x.checked)
              this.savedMeasures = selectedMeasures
              let measureIds = ''
              selectedMeasures.forEach(element => {
                measureIds = measureIds + element.measureId + ','

              });
              measureIds = measureIds.slice(0, -1)
              let model = {
                "fbMeasureProfileId": 0,
                "measureIds": measureIds,
                "fbMeasureProfileName": command.name,
                "userId": this.storage.get(USER_ID),
                "applied": this.selectedProfile.isFirst
              }
              this.configurationService.FbForecastSaveMeasureProfile(model).subscribe(resp => {
                this.selectedProfile = command
                let selectedMeasures = this.selectedProfile.measureList.filter(x => x.checked)
                this.measureList = selectedMeasures
                this.leftGroupList = this.leftGroupList.map(row => ({ ...row, rowspan: this.measureList.filter(measure => measure.parentId === row.measureId && measure.checked).length }))
                this.measureList = this.measureList.filter(row => row.parentId).map(row => ({ ...row, checked: true })).sort((a, b) => a.parentId - b.parentId);
                this.cdr.detectChanges();
              })
            }
          })
        }
        if (command.profileApplied) {
          this.selectedProfile = command
          let selectedMeasures = this.selectedProfile.measureList.filter(x => x.checked)
          if (selectedMeasures.length)
            this.measureList = selectedMeasures
          else
            this.measureList = this.savedMeasures
          this.leftGroupList = this.leftGroupList.map(row => ({ ...row, rowspan: this.measureList.filter(measure => measure.parentId === row.measureId && measure.checked).length }))
          this.measureList = this.measureList.filter(row => row.parentId).map(row => ({ ...row, checked: true })).sort((a, b) => a.parentId - b.parentId);
          this.cdr.detectChanges();
        }
        if(command.maxCountReached){
          this.dialog.open(ForecastConfirmationModalComponent, {
            width: '700px',
            panelClass: 'fb-dialog-wrapper',
            data: {
              label: 'Max profile creation limit has been reached. You can create only 20 profiles', isOnLoad: false, confirmation: true
            },
            disableClose: true
          })
        }

      }

    });
  }
  public createUIList = (flag: boolean, selected?: string, currentYear?) => {
    this.yearListGrid.forEach(year => {
      if (flag) {
        year.yearValue = year.title;
        year.isYear = true;
        this.collapseYear(year);
      } else {
        if (year.title === currentYear.title) {
          year.halfListUI = [];
          year.quarterListUI = [];
          year.monthListUI = [];
          year.weekListUI = [];
          year.isYear = true;
          year.halfList.forEach(half => {
            half.yearValue = year.title;
            half.isHalf = true;
            let h = this.collapseHalf(half);
            year.halfListUI.push(h)
            half.quarterList.forEach(quarter => {
              quarter.isQuarter = true;
              quarter.yearValue = year.title;
              let q = this.collapseQuarter(quarter);
              q.half = half.title;
              year.quarterListUI.push(q);
              quarter.monthList.forEach(month => {
                month.isMonth = true;
                month.yearValue = year.title;
                let m = this.collapseMonth(month);
                m.half = half.title;
                m.quarter = quarter.title;
                year.monthListUI.push(m);
                month.weekList.forEach(week => {
                  week.isWeek = true;
                  week.month = m.title;
                  week.yearValue = year.title;
                  year.weekListUI.push(week);
                });
              });

            });
          });
        }
      }
    });
    if (flag) {
      this.gridListUI = [...this.yearListGrid];
    }
    const list = [];
    this.yearListGrid.forEach(year => {
      year.isSelected = true;
      list.push(year);
      year.halfList.forEach(half => {
        half.isSelected = false;
        half.isHalf = true;
        half.yearValue = year.title;
        list.push(half);
        half.quarterList.forEach(quarter => {
          quarter.half = half.title;
          quarter.yearValue = year.title;
          quarter.isQuarter = true;
          quarter.isSelected = false;
          list.push(quarter);
          quarter.monthList.forEach(month => {
            month.quarter = quarter.title;
            month.yearValue = year.title;
            month.isSelected = false;
            month.isMonth = true;
            list.push(month);
            month.weekList.forEach(week => {
              week.month = month.title;
              week.yearValue = year.title;
              week.isWeek = true;
              week.isSelected = false;
              list.push(week);
            });
          })
        })
      })
    });
    this.gridListUI = list;
  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  selectAllSelected(flag: boolean): void {
    if (flag) {
      this.measureList = this.measureList.map(row => ({ ...row, checked: true }))
    } else {
      this.measureList = this.measureList.map(row => ({ ...row, checked: false }))
    }
  }
  public onSave = () => {
    this.dialogRef.close();
    let groups = JSON.parse(JSON.stringify(this.tempGroupList));
    const list = this.measureList.filter(row => row.checked);
    groups.forEach(group => {
      group.rowspan = list.filter(el => el.tag === group.name).length;
      if (group.name === 'ST Accuracy' && group.rowspan > 0) {
        // group.rowspan = group.rowspan + 1;
      }
    });
    groups = groups.filter(group => group.rowspan);
    this.leftGroupList = groups;
  }
  openFullScreen = (id: string) => {
    const domGrid = document.getElementById(id) as any;
    if (domGrid) {
      domGrid.webkitRequestFullscreen();
    }
  }
  getWidth = () => {
    return window.screen.width - 550 + 'px';
  }
  filterListByMonth = (list) => {
    if (list) {
      this.timeframeSelectedList = list;
    }
    if (this.timeframeSelectedList.length > 0) {
      this.yearListForFilter = JSON.parse(JSON.stringify(this.yearListTemp));
      const selectedList = this.timeframeSelectedList.map(res => res.item_id);
      this.yearList = this.yearListForFilter.filter(year => {
        year.halfList = year.halfList.filter(half => {
          half.quarterList = half.quarterList.filter(quarter => {
            quarter.monthList = quarter.monthList.filter(month => selectedList.includes(month.title));
            return quarter.monthList.find(month => selectedList.includes(month.title))
          });
          return half.quarterList.length > 0;
        });
        return year.halfList.length > 0;
      });
    }
  }

  checkIfInList = (key: string) => {
    return this.measureList.find(el => el.code === key && el.checked);
  }
  checkIfToShow = (index) => {
    if (index > -1 && (index + 1) < this.measureList.length) {
      return (this.measureList[index].parentId !== this.measureList[index + 1].parentId) && this.measureList.find(el => el.checked && this.measureList[index].parentId === el.parentId)
    }
  }
  checkIfToShowColumn(value) {
    return true || (this.measureList && this.measureList.find(el => (el.tag === value) && el.checked));
  }
  getName() {

  }
  getObject = (year, month, quarter, half) => {
    if (year.show) {
      return year;
    }
    if (quarter.show) {
      return quarter;
    }
    if (month.show) {
      return month;
    }
    if (half.show) {
      return half;
    }
  }
  headerValueChanged = (dropdownValue, week) => {
    console.log('week => ', week);
    console.log('Value => ', dropdownValue);
  }
  displayFn(week): string {
    return week && week.title ? week.title : '';
  }
  getCurrentWeekList = () => {
    return
  }
  trackyFn(item) {
    return item.calendarId || item.title;
  }
  collapseYear = (year: PhysicalYear) => {
    const object = {
      ...year.measures,
    };
    Object.keys(object).forEach(key => {
      if (!(key.endsWith('Comments') || key.endsWith('comments'))) {
        year.measures[key] = 0;
      }
    });
    year.halfList.forEach(half => {
      half.quarterList.forEach(quarter => {
        quarter.monthList.forEach(month => {
          month.weekList.forEach(week => {
            Object.keys(object).forEach(key => {
              if (!(key.endsWith('Comments') || key.endsWith('comments'))) {
                year.measures[key] += +week.measures[key];
              }
            });
          });
        })
      })
    })
    this.roundWeekValues();
    return year;
  }
  public roundWeekValues = () => {
    this.yearListGrid.forEach(year => {
      this.convertValues(year);
      year.halfList.forEach(half => {
        this.convertValues(half);
        half.quarterList.forEach(quarter => {
          this.convertValues(quarter);
          quarter.monthList.forEach(month => {
            this.convertValues(month);
            month.weekList.forEach(week => {
              this.convertValues(week);
            });
          })
        })
      });
    })
  }
  public convertValues = (week) => {
    Object.keys(week.measures).forEach(key => {
      if (!isNaN(week.measures[key]) && week.measures[key])
        week.measures[key] = Number.isInteger(week.measures[key]) ? week.measures[key] : Number(parseFloat(week.measures[key]).toFixed(2));
    });
  }
  valueChange = (value, column, fieldCode) => {
    if (value) {
      column.calendarIds = this.getCalendarIds(column);
      let forecast = new FbForecast(null);
      forecast.itemIds = this.dropdownObjects.itemIds.toString();
      forecast.channelIds = this.dropdownObjects.channelIds.toString();
      forecast.calendarIds = this.dropdownObjects.calendarIds.toString();
      forecast.userId = this.storage.get(USER_ID);
      forecast.versionTypeId = this.versionTypeId;
      const measure = this.measureList.find(m => m.code === fieldCode);
      const fbForecasts = this.forecastList.filter(row => row.measureId === measure.measureId && column.calendarIds.includes(row.calendarId))
      forecast.fbForecasts = fbForecasts.map(f => ({ ...f, value: +value / fbForecasts.length, isEdited: true }));
      this.configurationService.AddFbForecast(forecast).subscribe(res => {
        this.configurationService.yearListUpdated.next(this.yearListGrid);
      });
    }
  }
  public getCalendarIds = (column) => {
    let calendarIds = [];
    if (column.isYear) {
      calendarIds = this.calendarList.filter(row => row.fy === column.title).map(el => el.calendarId);
      return calendarIds;
    }
    else if (column.isHalf) {
      calendarIds = this.calendarList.filter(row => row.fy === column.yearValue && row.hy === column.title).map(el => el.calendarId);
      return calendarIds;
    }
    else if (column.isQuarter) {
      calendarIds = this.calendarList.filter(row => row.fy === column.yearValue && row.hy === column.half).map(el => el.calendarId);
      return calendarIds;
    }
    else if (column.isMonth) {
      calendarIds = this.calendarList.filter(row => row.fy === column.yearValue && row.quarter === column.quarter && row.month === column.title).map(el => el.calendarId);
      return calendarIds;
    } else {
      return [column.calendarId]
    }
  }
  showValues = (selected, year, select) => {
    if (selected.value === 'Year') {
      this.gridListUI.forEach(row => {
        if (row.yearValue === year.yearValue) {
          row.isSelected = row.isYear ? true : false;
        }
      });
    }
    if (selected.value === 'Half Year') {
      this.gridListUI.forEach(row => {
        if (row.yearValue === year.yearValue) {
          row.isSelected = row.isHalf ? true : false;
          if (row.isSelected) {
            row = this.collapseHalf(row);
          }
        }
      });
    }
    if (selected.value === 'Quarter') {
      this.gridListUI.forEach(row => {
        if (row.yearValue === year.yearValue) {
          if (year.title === 'H1') {
            row.isSelected = row.isQuarter && row.half === 'H1' ? true : false;
            if (row.title === 'H2') {
              if (this.gridListUI.find(r => r.isSelected && r.isQuarter && r.half === 'H2')) {
                row.isSelected = false;
              } else {
                row.isSelected = year.isQuarter ? false : true;
              }
            }
          }
          else if (year.title === 'H2') {
            row.isSelected = row.isQuarter && row.half === 'H2' ? true : false;
            if (row.title === 'H1') {
              if (this.gridListUI.find(r => r.isSelected && r.isQuarter && r.half === 'H1')) {
                row.isSelected = false;
              } else {
                row.isSelected = year.isQuarter ? false : true;
              }
            }
            row = this.collapseHalf(row);
          } else {
            row.isSelected = row.isQuarter ? true : false;
          }
        }
        if (row.isSelected) {
          row = this.collapseQuarter(row);
        }
      });
    }
    if (selected.value === 'Monthly') {
      this.gridListUI.forEach(row => {
        if (row.yearValue === year.yearValue) {
          row.isSelected = row.isMonth ? true : false;
        }
        if (row.isSelected) {
          row = this.collapseMonth(row);
        }
      });
    }
    if (selected.value === 'Weekly') {
      this.gridListUI.forEach(row => {
        if (row.yearValue === year.yearValue) {
          row.isSelected = row.isWeek ? true : false;
        }
      });
    }
  }
  collapseHalf = (half: HalfYear): any => {
    const object = {
      ...half.measures,
    };
    Object.keys(object).forEach(key => {
      if (!(key.endsWith('Comments') || key.endsWith('comments'))) {
        half.measures[key] = 0;
      }
    });
    half.quarterList.forEach(quarter => {
      quarter.monthList.forEach(month => {
        month.weekList.forEach(week => {
          Object.keys(object).forEach(key => {
            if (half && week) {
              if (!(key.endsWith('Comments') || key.endsWith('comments'))) {
                half.measures[key] += +week.measures[key];
              }

            }

          });
        });
      })
    });
    this.roundWeekValues();
    return half;
  }
  collapseQuarter = (quarter: Quarter): any => {
    const object = {
      ...quarter.measures,
    };
    Object.keys(object).forEach(key => {
      if (!(key.endsWith('Comments') || key.endsWith('comments'))) {
        quarter.measures[key] = 0;
      }
    });
    quarter.monthList.forEach(month => {
      month.weekList.forEach(week => {
        Object.keys(object).forEach(key => {
          if (!(key.endsWith('Comments') || key.endsWith('comments'))) {
            quarter.measures[key] += week.measures[key];
          }

        });
      });
    });
    this.roundWeekValues();
    return quarter;
  }
  collapseMonth = (month: Month): any => {
    const object = {
      ...month.measures,
    };
    Object.keys(object).forEach(key => {
      if (!(key.endsWith('Comments') || key.endsWith('comments'))) {

        month.measures[key] = 0;
      }
    });
    month.weekList.forEach(week => {
      Object.keys(object).forEach(key => {
        if (!(key.endsWith('Comments') || key.endsWith('comments'))) {
          month.measures[key] += +week.measures[key];
        }
      });
    });
    this.roundWeekValues();
    return month;
  }
  mapGridList(value) {
    this.onClickEvent.emit(value)
  }
}
