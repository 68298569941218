<div class="container">
  <div class="user-page">
    <div class="row">
      <div class="col-md-1 d-block d-sm-block d-md-none mobile-icon">
        <div class="btn-show">
          <button (click)="openAddDialog({})" mat-icon-button color="primary">
            <i class="material-icons">
              add_circle
            </i>
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <form action="" class="form-fields show-search">
          <div class="form-group">
            <mat-form-field>
              <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-md-8 d-none d-sm-none d-md-block tablet-icon">
        <div class="btn-show">
          <button (click)="openAddDialog({})" mat-icon-button color="primary">
            <i class="material-icons">
              add_circle
            </i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material"  [rowDragManaged]="true" [animateRows]="true"
       [frameworkComponents]="frameworkComponents" [defaultColDef]="defaultColDef"
       [columnDefs]="columnDefs" [rowData]="workgroupList" (gridReady)="onGridReady($event)"
      [modules]="modules" #grid>
    </ag-grid-angular>
  </div>
</div>
<ng-template #Adddialog let-data>
  <h1 mat-dialog-title>Workgroup</h1>
  <div mat-dialog-content>
    <form [formGroup]="businessInfoForm">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <input matInput formControlName="workgroupName" placeholder="Enter Workgroup Name">
          </mat-form-field>
        </div>
      </div>
      <div class="action-btns text-right">
        <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit()">Save</button>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #templateDialog let-data>
  <div class="container">
    <div class="user-page">
      <div class="row">
        <div class="col-md-1 d-block d-sm-block d-md-none mobile-icon">
          <div class="btn-show">
            <button (click)="openAddDialog({})" mat-icon-button color="primary">
              <i class="material-icons">
                add_circle
              </i>
            </button>
          </div>
        </div>
        <div class="col-md-4">
          <form action="" class="form-fields show-search">
            <div class="form-group">
              <mat-form-field>
                <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
          </form>
        </div>
        <div class="col-md-8 d-none d-sm-none d-md-block tablet-icon">
          <div class="btn-show">
            <button (click)="openAddAttributeDialog({})" mat-icon-button color="primary">
              <i class="material-icons">
                add_circle
              </i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="all-show-grid">
      <ag-grid-angular class="ag-theme-material"   [suppressMenuHide]="true"
         groupHeaders suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
        (cellDoubleClicked)="onCellClicked($event)" [gridOptions]="gridOptions" [columnDefs]="columnAttDefs"
        [rowData]="attributeList" (gridReady)="onGridReady($event)" [modules]="modules" [rowDragManaged]="true"
        [animateRows]="true" #grid></ag-grid-angular>
    </div>
  </div>
</ng-template>
<ng-template #AttributeDialog let-data>
  <h1 mat-dialog-title>Business Attributes</h1>
  <div mat-dialog-content>
    <form [formGroup]="businessInfoAttForm">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <input matInput formControlName="title" placeholder="Enter Title">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-select formControlName="answerTypeId" placeholder="Select Type">
              <mat-option *ngFor="let res of answerTypeList; let i = index" [value]="res.answerTypeId">
                {{res.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="action-btns text-right">
        <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit()">Save</button>
      </div>
    </form>
  </div>
</ng-template>
