<div class="all-show-grid">
  <ag-grid-angular #quarterlyGrid class="ag-theme-material" [sideBar]="false" [suppressMenuHide]="true" groupHeaders
    toolPanelSuppressGroups toolPanelSuppressValues [suppressRowTransform]="suppressRowTransform"
    (cellValueChanged)="cellValueChanged(true)" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
    [rowData]="quarterlyData" (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>

<hr class="theme-hr" />

<div class="w-100 d-flex align-items-center justify-content-center">
  <fusioncharts [width]="width" [height]="height" [type]="type" [dataFormat]="dataFormat" [dataSource]="dataSource">
  </fusioncharts>
</div>
