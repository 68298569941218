import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CALENDER_ACTIVITY_COL_DEFS } from 'src/common/algo-column-definitions';
import { USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  selector: 'app-calender-activity',
  templateUrl: './calender-activity.component.html',
  styleUrls: ['./calender-activity.component.css']
})
export class CalenderActivityComponent implements OnInit {
  public calenderActivityForm: FormGroup;
  public submitted = false;
  public isEdit = false;
  public columnDefs = CALENDER_ACTIVITY_COL_DEFS;
  private gridApi: any;
  public getRowHeight;
  public  headerHeight;
  public channelList = [];
  public gridOptions: any;
  public modules = AllCommunityModules;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private formBuilder: FormBuilder,
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public dialogRefActivity: MatDialogRef<CalenderActivityComponent>) {
    this.gridOptions = {
      frameworkComponents: {
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.headerHeight = 40;
    this.gridOptions.getRowHeight = function(params) {
      return 40;
    };
  }

  ngOnInit() {
    this.populateList();
    this.initializeForm();
  }
  public populateList = () => {
    this.configurationService.getAllCalenderActivities({ clientId: 1 }).subscribe((res: any) => {
      this.channelList = res;
    }, error => {
      this.spinner.hide();
    });
  }
  initializeForm() {
    this.calenderActivityForm = this.formBuilder.group({
      calendarActivityId: [0],
      calendarActivityName: ['', Validators.required],
      abbreviation: ['', Validators.required],
      clientId: [1],
      active: [true],
      currentUserId: [this.storage.get(USER_ID)]
    });
  }
  populateForm(event): void {
    if (event && event.data) {
      this.isEdit = true;
      const dataToPopulate = event.data;
      Object.keys(this.calenderActivityForm.controls).forEach(key => {
        if (dataToPopulate[key] !== null) {
          this.calenderActivityForm.controls[key].setValue(dataToPopulate[key]);
        }
      });
    }
  }
  public onSubmit = () => {
    this.submitted = true;
    if (this.calenderActivityForm.valid) {
      const model = { ...this.calenderActivityForm.value };
      this.spinner.show();
      if (this.isEdit) {
        this.configurationService.updateCalenderActivities(model).subscribe(res => {
          this.populateList();
          this.isEdit = false;
          this.submitted = false;
          this.initializeForm();
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      } else {
        this.configurationService.addCalenderActivities(model).subscribe(res => {
          this.populateList();
          this.submitted = false;
          this.initializeForm();
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      }
    }
  }
  Cancel_Click() {
    this.isEdit = false;
    this.initializeForm();
    // this.dialogRefActivity.close();
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

}
