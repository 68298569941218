import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import * as DocumentEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

export const CK_EDITOR_CONFIG = {
  forcePasteAsPlainText: false,
  pasteFromWordRemoveFontStyles: false,
  pasteFromWordRemoveStyles: false,
  allowedContent: true,
  extraAllowedContent: 'p(mso*,Normal)',
  height: 300,
  removePlugins: ['ImageUpload', 'MediaEmbed'],
  link: {
    decorators: {
      isExternal: {
        mode: 'manual',
        label: 'Open in a new tab',
        attributes: {
          target: '_blank'
        }
      }
    }
  }
};
@Component({
  selector: 'app-notification-configuration',
  templateUrl: './notification-configuration.component.html',
  styleUrls: ['./notification-configuration.component.css']
})
export class NotificationConfigurationComponent implements OnInit {
  public loading = false;
  public data = [];
  public notficationForm: FormGroup;
  public Editor = DocumentEditor;
  public submitted = false;
  public isEdit = false;
  public ckeditorConfig = CK_EDITOR_CONFIG;
  public selectedNotification: any;
  public charachterLimitExceeded = false;
  public tempNotificationList = [];
  public gridData: any;
  public columnDefs = [
    {
      headerName: 'Announcement',
      field: 'content',
      cellRenderer: (params) => {
        return `<div innerHTML=${params.value}></div>`;
      },
      width: 500
    },
    {
      headerName: 'Status',
      field: 'isPublish',
      cellRenderer: (params) => {
        return `<span>${params.value ? 'Published' : 'Draft'}</span>`;
      },
      width: 150
    },
    {
      headerName: '',
      field: 'edit',
      resizable: true,
      suppressMenu: true,
      suppressSorting: true,
      pinned: 'right',
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        onClick: this.openDialog.bind(this),
      },
      width: 80
    },
    {
      headerName: '',
      field: 'delete',
      resizable: true,
      filter: false,
      pinned: 'right',
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveGoalDialog.bind(this),
      },
      width: 80
    },
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public modules = AllCommunityModules;
  @ViewChild('dialogRef') dialog: TemplateRef<any>;

  constructor(
    public fb: FormBuilder,
    public toastr: NgxToasterService,
    public spinner: NgxSpinnerService,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public matDialog: MatDialog,
    public matDialogRef: MatDialogRef<any>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>, ) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }

  ngOnInit() {
    this.initializeForm();
    this.getNotificationList();
  }
  public getNotificationList = () => {
    this.loading = true;
    this.configurationService.AnnouncementGetAll({}).subscribe(res => {
      this.data = res ? res.data || [] : [];
      this.tempNotificationList = this.data;
    });
  }
  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }
  public initializeForm = () => {
    this.notficationForm = this.fb.group({
      AnnouncementId: [0],
      content: ['', Validators.required],
      orderNumber: [],
      isPublish: [true]
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: 'Announcement' }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeNotification(row.rowData);
      }
    });
  }
  onChange = (value) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = value.editor.getData();
    this.charachterLimitExceeded = (tmp.textContent || tmp.innerText || '').length > 450 ? true : false;
  }
  /**
   * Notification Modal
   */
  public openModal = (template: TemplateRef<any>, modelToEdit?: any) => {
    if (modelToEdit) {
      Object.keys(modelToEdit).forEach(key => {
        if ((modelToEdit[key] !== undefined || modelToEdit[key] !== null) && key !== 'dragging') {
          this.notficationForm.controls[key].setValue(modelToEdit[key]);
        }
      });
      this.isEdit = true;
    }
  }
  /**
   * OnSubmit
   */
  public onSubmit(data) {
    this.submitted = true;
    if (data && data.content && !this.charachterLimitExceeded) {
      const model = data;
      this.spinner.show();
      if (this.isEdit) {
        this.updateNotification(model);
      } else {
        this.configurationService.AnnouncementAdd(model).subscribe(res => {
          this.spinner.hide();
          this.submitted = false;
          this.matDialogRef.close();
          this.initializeForm();
          this.toastr.success(' Announcement', 'Notification added successfully');
          this.getNotificationList();
        }, error => {
          this.submitted = false;
          this.matDialogRef.close();
          this.spinner.hide();
          this.toastr.error(' Announcement', error.error.text);
        });
      }
    }
  }
  public updateNotification = (model: any) => {
    this.configurationService.AnnouncementUpdate(model).subscribe(res => {
      this.spinner.hide();
      this.initializeForm();
      this.submitted = false;
      this.isEdit = false;
      this.matDialogRef.close();
      this.toastr.success(' Announcement', 'Notification updated successfully');
      this.getNotificationList();
    }, error => {
      this.submitted = false;
      this.isEdit = false;
      this.spinner.hide();
      this.matDialogRef.close();
      this.toastr.error(' Announcement', error.error.text);
    });
  }
  openDialog(data, templateRef?: TemplateRef<any>): void {
    this.isEdit = data.rowData ? true : false;
    this.matDialogRef = this.matDialog.open(this.dialog, { width: '500px', data: data.rowData || { content: '', isPublish: true } });
  }
  /**
   * update Publish Status
   */
  public updatePublishStatus(notification) {
    if (notification) {
      this.updateNotification(notification);
    }
  }
  /**
   * In-Active Notification
   */
  public inactiveNotification(notification: any, template: TemplateRef<any>) {
    this.selectedNotification = notification;
  }
  /**
   * Remove Notification
   */
  public removeNotification(selectedNotification: any) {
    if (selectedNotification) {
      const model = {
        ...selectedNotification,
        active: false
      };
      this.spinner.show();
      this.configurationService.AnnouncementActivate(model).subscribe(res => {
        if (res) {
          this.spinner.hide();
          this.toastr.success(' Announcement', 'Notification removed successfully');
          this.data = this.data.filter(row => row.dealerAnnouncementId !== model.dealerAnnouncementId);
          this.gridOptions.api.setRowData(this.data);
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error(' Announcement', error.error.text);
      });
    }
  }
}
