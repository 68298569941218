import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessInformationService {

  constructor(public http: HttpClient) { }
  /* Business Attributes CRUD Model */

  addBusinessAttribute = (BusinessAttribute: any) => {
    return this.http.post<any>(`${environment.AppBussinessAttribute}/add`, BusinessAttribute);
  }
  updateBusinessAttribute = (BusinessAttribute: any) => {
    return this.http.put(`${environment.AppBussinessAttribute}/update`, BusinessAttribute);
  }
  activateBusinessAttribute = (BusinessAttribute: any) => {
    return this.http.put(`${environment.AppBussinessAttribute}/activate`, BusinessAttribute);
  }
  searchBusinessAttribute = (searchText: string) => {
    return this.http.get<any>(`${environment.AppBussinessAttribute}/search?search=${searchText}`);
  }
  getBusinessAttribute = (BusinessAttributeId: string) => {
    return this.http.get<any>(`${environment.AppBussinessAttribute}/get?BusinessAttributeId=${BusinessAttributeId}`);
  }
  getAllBusinessAttributes = (params: any) => {
    return this.http.get<any>(`${environment.AppBussinessAttribute}/getall`, { params });
  }
  getBusinessAttributeList = (params: any) => {
    return this.http.get<any>(`${environment.AppBussinessAttribute}/getlist`, { params });
  }
  /* Business Attributes CRUD Model */

  addWorkGroup = (WorkGroup: any) => {
    return this.http.post<any>(`${environment.AppWorkgroup}/add`, WorkGroup);
  }
  updateWorkGroup = (WorkGroup: any) => {
    return this.http.put(`${environment.AppWorkgroup}/update`, WorkGroup);
  }
  activateWorkGroup = (WorkGroup: any) => {
    return this.http.put(`${environment.AppWorkgroup}/activate`, WorkGroup);
  }
  searchWorkGroup = (searchText: string) => {
    return this.http.get<any>(`${environment.AppWorkgroup}/search?search=${searchText}`);
  }
  getWorkGroup = (WorkGroupId: string) => {
    return this.http.get<any>(`${environment.AppWorkgroup}/get?WorkGroupId=${WorkGroupId}`);
  }
  getAllWorkGroups = (params: any) => {
    return this.http.get<any>(`${environment.AppWorkgroup}/getall`, { params });
  }
  getWorkGroupList = (params: any) => {
    return this.http.get<any>(`${environment.AppWorkgroup}/getlist`, { params });
  }
  /* Business Information CRUD Model */

  addBusinessInformation = (BusinessInformation: any) => {
    return this.http.post<any>(`${environment.AppBussinessInformation}/addBussinessInformation`, BusinessInformation);
  }
  updateBusinessInformation = (BusinessInformation: any) => {
    return this.http.put(`${environment.AppBussinessInformation}/updateBussinessInformation`, BusinessInformation);
  }
  activateBusinessInformation = (BusinessInformation: any) => {
    return this.http.put(`${environment.AppBussinessInformation}/activate`, BusinessInformation);
  }
  searchBusinessInformation = (searchText: string) => {
    return this.http.get<any>(`${environment.AppBussinessInformation}/search?search=${searchText}`);
  }
  getBusinessInformation = (clientId: string) => {
    return this.http.get<any>(`${environment.AppBussinessInformation}/getBussinessInformation?clientId=${clientId}`);
  }
  getAllBusinessInformations = (params: any) => {
    return this.http.get<any>(`${environment.AppBussinessInformation}/getall`, { params });
  }
  getBusinessInformationList = (params: any) => {
    return this.http.get<any>(`${environment.AppBussinessInformation}/getPivotlist`, { params });
  }
  addLevel = (Level: any) => {
    return this.http.post<any>(`${environment.AppLevel}/add`, Level);
  }
  updateLevel = (Level: any) => {
    return this.http.put(`${environment.AppLevel}/update`, Level);
  }
  activateLevel = (Level: any) => {
    return this.http.put(`${environment.AppLevel}/activate`, Level);
  }
  searchLevel = (searchText: string) => {
    return this.http.get<any>(`${environment.AppLevel}/search?search=${searchText}`);
  }
  getLevel = (levelId: string) => {
    return this.http.get<any>(`${environment.AppLevel}/get?levelId=${levelId}`);
  }
  getAllLevels = (params: any) => {
    return this.http.get<any>(`${environment.AppLevel}/getall`, { params });
  }
  getLevelList = (params: any) => {
    return this.http.get<any>(`${environment.AppLevel}/getList`, { params });
  }
  getUserLevelList = (params: any) => {
    return this.http.get<any>(`${environment.AppUserLevel}/GetAvailableUsers`, { params });
  }
  addUserLevel = (Level: any) => {
    return this.http.post<any>(`${environment.AppUserLevel}/addUserLevel`, Level);
  }
  updateUserLevel = (Level: any) => {
    return this.http.put(`${environment.AppUserLevel}/updateUserLevel`, Level);
  }
}
