import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Calendar } from 'src/modules/forecast-engin/features/forecast-manager/models/Calendar';


@Injectable()
export class ForecastLookupService {

  // Subjects

  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */

  // Observables

  constructor(private http: HttpClient) {
  }


  GetAccountslist() {
    return this.http.get(`${environment.ConfigurationLookup}/GetAccountslist`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetRegionList() {
    return this.http.get(`${environment.ConfigurationLookup}/GetRegionList`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetCountryList() {
    return this.http.get(`${environment.ConfigurationLookup}/GetCountryList`)
      .pipe(map(response => {
        return response;
      }));
  }


  GetPromotionEventList() {
    return this.http.get(`${environment.ConfigurationLookup}/GetPromotionEventList`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetPlanningMonthByStartDate(startDate) {
    return this.http.get(`${environment.ConfigurationLookup}/GetPlanningMonth?startdate=${startDate}`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetLookupItemsList() {
    return this.http.get(`${environment.MappingManager}/itemgetlist`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetFiscalYearList() {
    return this.http.get(`${environment.ConfigurationLookup}/GetFiscalYearList`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetPlanningMonthList() {
    return this.http.get(`${environment.ConfigurationLookup}/GetPlanningMonthList`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetPlanningWeekList(body = {}) {
    const query = Object.keys(body).map(key => {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.ConfigurationLookup}/GetPlanningWeekList?${query}`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetForecastRetailerList(params): Observable<any> {
    return this.http.get<any>(
      `${environment.ForecastMapping}/GetForecastRetailerList`,
      { params }
    );
  }

  GetSeasonalityList(): Observable<any> {
    return this.http.get<any>(
      `${environment.ForecastMapping}/GetSeasonalityList`,
    );
  }

  GetForecastWeekPeriodList(): Observable<any> {
    return this.http.get<any>(
      `${environment.ForecastMapping}/GetForecastWeekPeriodList`,
    );
  }

  GetItemsListByRetailer(params): Observable<any> {
    return this.http.post<any>(
      `${environment.ForecastMapping}/GetItemsListByRetailer`,
      params
    );
  }

  ForecastManagerMeasuresGetList(): Observable<any> {
    return this.http.get<Calendar[]>(
      `${environment.ForecastMapping}/forecastManagerMeasuresGetList`,
    );
  }


  GetProductHierarchyList(): Observable<any> {
    return this.http.get<any>(`${environment.ForecastManager}/getHierarchyMetadataList`,)
      .pipe(map(response => {
        return response;
      }));
  }

  UpdateProductHierarchyLIst(body): Observable<any> {
    return this.http.post<any>(`${environment.ForecastManager}/insertHierarchyMetadataItem`, body)
      .pipe(map(response => {
        return response;
      }));
  }

}
