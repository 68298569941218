<h5 class="m-2" style="background: #f4f4f4; padding: 10px;">Company's Summary</h5>
<div class="row m-0">
    <div class="col-md-4 d-flex">
        <strong style="width: 50%;" class="mr-2">No of Stores</strong>
        <mat-form-field appearance="fill">
            <input matInput disabled value="120" />
        </mat-form-field>
    </div>
    <div class="col-md-4 d-flex">
        <strong style="width: 50%;" class="mr-2">No of Items</strong>
        <mat-form-field appearance="fill">
            <input matInput disabled value="650" />
        </mat-form-field>
    </div>
    <div class="col-md-4 d-flex">
        <strong style="width: 50%;" class="mr-2">No of SKUs</strong>
        <mat-form-field appearance="fill">
            <input matInput disabled value="1270" />
        </mat-form-field>
    </div>
    <div class="col-md-4 d-flex">
        <strong style="width: 50%;" class="mr-2">Inventory at Stores</strong>
        <mat-form-field appearance="fill">
            <input matInput disabled value="972000" />
        </mat-form-field>
    </div>
    <div class="col-md-4 d-flex">
        <strong style="width: 50%;" class="mr-2">Inventory at Warehouses</strong>
        <mat-form-field appearance="fill">
            <input matInput disabled value="108000" />
        </mat-form-field>
    </div>
    <div class="col-md-4 d-flex">
        <strong style="width: 50%;" class="mr-2">On Order</strong>
        <mat-form-field appearance="fill">
            <input matInput disabled value="73000" />
        </mat-form-field>
    </div>
    <div class="col-md-4 d-flex">
        <strong style="width: 50%;" class="mr-2">Sales YTD</strong>
        <mat-form-field appearance="fill">
            <input matInput disabled value="600000000" />
        </mat-form-field>
    </div>
    <div class="col-md-4 d-flex">
        <strong style="width: 50%;" class="mr-2">Sales MTD</strong>
        <mat-form-field appearance="fill">
            <input matInput disabled value="18000000" />
        </mat-form-field>
    </div>
    <div class="col-md-4 d-flex">
        <strong style="width: 50%;" class="mr-2">ROS</strong>
        <mat-form-field appearance="fill">
            <input matInput disabled value="0.36" />
        </mat-form-field>
    </div>
</div>

<hr class="theme-hr" />

<h5 class="m-2" style="background: #f4f4f4; padding: 10px;">Graph View</h5>
<div class="w-100 d-flex align-items-center justify-content-center">
    <fusioncharts [width]="width" [height]="height" [type]="type" [dataFormat]="dataFormat" [dataSource]="dataSource">
    </fusioncharts>
</div>

<hr class="theme-hr" />


<div class="m-2 d-flex align-items-center justify-content-between" style="background: #f4f4f4; padding: 10px;">
    <div class="d-flex align-items-center">
        <h5 style="min-width: 100px;">Item View
            <span matBadge="40" matBadgeOverlap="false" matBadgeColor="accent"></span>
        </h5>

        <mat-slide-toggle class="ml-5" color="warn" [(ngModel)]="orderingDetails" (change)="toggleChanged()">
            Ordering Details
        </mat-slide-toggle>
        <mat-slide-toggle class="ml-5" color="warn" [(ngModel)]="locationDetails" (change)="toggleChanged()">
            Location Details
        </mat-slide-toggle>
        <mat-slide-toggle class="ml-5" color="warn" [(ngModel)]="productDetails" (change)="toggleChanged()">
            Product Details
        </mat-slide-toggle>

        <mat-form-field appearance="outline" class="w-25 ml-3">
            <mat-select value="Last 26 Weeks">
                <mat-option value="Last 26 Weeks">
                    Last 26 Weeks
                </mat-option>
                <mat-option value="Last 52 Weeks">
                    Last 52 Weeks
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-slide-toggle class="ml-3" color="warn">
            Active
        </mat-slide-toggle>
    </div>

    <div class="d-flex align-items-center">
        <button matTooltip="Show/Hide Columns" class="grid-bar-icon ml-3" mat-icon-button color="primary">
            <i class="material-icons">
            ballot
            </i>
        </button>
        <button matTooltip="Export" class="grid-bar-icon ml-3" mat-icon-button color="primary">
            <i class="material-icons">
            insert_drive_file
            </i>
        </button>

        <button class="import-export-button ml-5" mat-flat-button color="accent"
            [matMenuTriggerFor]="actionMenu">Actions</button>
        <mat-menu #actionMenu="matMenu">
            <button mat-menu-item>Add Item for Ordering</button>
            <button mat-menu-item>Ordering Status</button>
        </mat-menu>
    </div>
</div>

<div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material" [gridOptions]="gridOptions" [rowModelType]="rowModelType"
        [pagination]="true" [paginationPageSize]="paginationPageSize" [columnDefs]="columnDefs" [modules]="modules"
        [suppressMenuHide]="true"
        suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
        [rowSelection]="rowSelection" [rowData]="gridData" #replenishmentGrid>
    </ag-grid-angular>
</div>
