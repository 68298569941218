import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError } from 'rxjs';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { ForecastConfirmationModalComponent } from '../../../../facebook/features/fb-forecast/forecast-confirmation-modal/forecast-confirmation-modal.component';
import { ItemAccount } from '../../../../items/item.model';
import { Promotion } from '../models/promotion.model';

@Component({
  selector: 'app-facebook-promotion-form',
  templateUrl: './facebook-promotion-form.component.html',
  styleUrls: ['./facebook-promotion-form.component.scss']
})
export class FacebookPromotionFormComponent implements OnInit {
  promotionModel = new Promotion()
  promotionModelTemp = new Promotion()
  promotionMethods = ['Method 1', 'Method 2', 'Method 3']
  status = [{ id: false, value: 'Inactive' }, { id: true, value: 'Active' }]
  public modules = AllCommunityModules;
  public showPromoForm = false;
  @Input() isEdit
  @Output() updateList = new EventEmitter()
  public columnDefs = [
    {
      headerName: 'Account',
      field: 'accountName'
    },
    {
      headerName: 'Item Description',
      field: 'description',
    },
    {
      headerName: 'Currency',
      field: 'currency',
      minWidth: 50,
      resizable: true,

    },
    {
      headerName: 'Discount Customer',
      field: 'discountCost',
      minWidth: 50,
      resizable: true,

    },
    {
      headerName: 'MSRP',
      field: 'msrp',
    },
    {
      headerName: 'Discount Percent',
      field: 'discountPercent',
      minWidth: 50,
      resizable: true,
      valueFormatter: this.percentFormatter.bind(this)

    },

    {
      headerName: 'Stackable',
      field: 'stackAble',
      valueFormatter: this.stackAbleFormatter.bind(this)
    },

    {
      headerName: 'Status',
      field: 'active',
      valueFormatter: this.activeFormatter.bind(this)
    },


  ];
  public gridOptions: any;
  gridApi: any;
  defaultColDef: any;
  headerHeight = 45;
  rowModelType: string;
  serverSideStoreType: string;
  paginationPageSize: number;
  cacheBlockSize: number;
  @Input() promotionList = [];
  itemList: any;
  accountSettingList: any;
  isDuplicated = false;
  editPermission: any;
  currencyList: any = [];
  constructor(
    readonly configurationService: ConfigurationService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    readonly toaster: NgxToasterService) {
    this.gridOptions = {
      pagination: true,
      paginationPageSize: 15
    };

    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true
    }
  }

  async ngOnInit() {
    this.populateList()
    this.spinner.show();
    const itemResponse = await this.getItemManagerList().toPromise().catch(error => { throwError(error) });
    if (itemResponse && itemResponse.data) {
      this.itemList = itemResponse.data;
    }
    this.configurationService.promotionModel.subscribe(data => {
      if (data.isEdit) {
        this.editPermission = data.editPermission
        let params = {
          PromotionPlannerId: data.data.promotionPlannerId
        }
        this.configurationService.PromotionPlannerGet(params).subscribe(resp => {
          this.promotionModel = resp;
          this.promotionModelTemp = JSON.parse(JSON.stringify(resp));
          if (this.promotionModel.promotionProductAccount && this.promotionModel.promotionProductAccount.length > 0) {
            this.promotionModel.productId = this.promotionModel.promotionProductAccount[0].productId;
            this.promotionModel.accountIds = this.promotionModel.promotionProductAccount.map(el => el.accountId);
            this.onItemChange(this.promotionModel.productId);
          }
          this.loadData();
          this.spinner.hide();
        })
      } else {
        this.editPermission = true
        this.promotionModel = data.data
        this.loadData();
        this.spinner.hide();
      }
    })
  }

  loadData() {
    this.paginationPageSize = 100;
    this.cacheBlockSize = 20;
    this.configurationService.GetLookupByCode('forecastmethod').subscribe(resp => {
      this.promotionMethods = resp;
    })

  }
  public getItemManagerList = () => {
    const params = {
      offset: 0,
      pageSize: 1000000,
      active: true
    }
    let url = `offSet=${params.offset}&pageSize=${params.pageSize}&active=true`
    return this.configurationService.ItemManagerGetAll(url)
  }
  onMethodChange() {

  }
  onItemChange(productId) {
    this.getAccountSettingList(productId);
    const product = this.itemList.find(el => el.itemManagerId === productId);
    if (product) {
      this.promotionModel.description = product.description
      this.promotionModel.upcCodelist = product.itemManagerUPC
      this.promotionModel.cost = product.cost;
    }

  }
  public getAccountSettingList = (itemModel) => {
    this.configurationService.AccountsListGetByItem(itemModel).subscribe(res => {
      this.accountSettingList = (res || []);
    }, error => {
      this.accountSettingList = [];
    });
  }
  onAccountChange(accountId) {
    const selectedAccounts = this.accountSettingList.filter(el => accountId.includes(el.itemAccountId))
    this.promotionModel.accountName = selectedAccounts;
  }

  addAccount() {
    let upc = ""

    // if (this.promotionModel.upcCodelist.length > 0) {
    //   this.promotionModel.upcCodelist.forEach(element => {
    //     upc = upc + element.upcCode + ','
    //   });
    // }
    this.promotionModel.accountName.forEach(element => {
      this.promotionModel.promotionProductAccount.push({
        accountName: element.accountName,
        accountId: element.itemAccountId,
        msrp: this.promotionModel.msrp,
        discountCost: this.promotionModel.discountCost,
        productId: this.promotionModel.productId,
        description: this.promotionModel.description,
        currency: this.promotionModel.currency,
        discountPercent: this.promotionModel.discountPercent,
        active: this.promotionModel.activeAccount,
        stackAble: this.promotionModel.stackable
      })
    });
    this.gridApi.setRowData(this.promotionModel.promotionProductAccount)
  }




  onGridReady(params: any) {
    this.gridApi = params.api;
  }
  save() {
    if (!this.checkIfAllRequiredValuesFilled() && !this.isDuplicated) {
      this.dialog.open(ForecastConfirmationModalComponent, {
        width: '686px',
        panelClass: ['fb-dialog-wrapper2', 'fb-dialog-wrapper4'],
        data: {
          label: 'This promotion cannot be saved. You have not entered all of mandatory fields', isOnLoad: false, goBack: true,

        },
        disableClose: true
      })
    }
    else if (this.isDuplicated) {
      this.toaster.error('Duplicate Name', 'Promotion name cannot be duplicated');
    } else {
      this.dialog.open(ForecastConfirmationModalComponent, {
        width: '596px',
        panelClass: ['fb-dialog-wrapper2', 'fb-dialog-wrapper5'],
        data: {
          label: 'Would you like to save this Promotion?', isOnLoad: false, changeNameConfirmation: true,

        },
        disableClose: true
      }).afterClosed().subscribe(command => {
        if (command && command.isNameSave) {
          delete this.promotionModel.upcCodelist
          this.spinner.show();
          if (this.isEdit) {
            this.configurationService.PromotionPlannerGetUpdate(this.promotionModel).subscribe(resp => {
              this.updateList.emit(true);
              this.spinner.hide();
            })
          } else {
            this.configurationService.PromotionPlannerGetAdd(this.promotionModel).subscribe(resp => {
              this.updateList.emit(true);
              this.spinner.hide();
            })
          }

        }
      })
    }

  }
  public populateList = () => {
    this.configurationService.CurrencyGetAll({ clientId: 1 }).subscribe((res: any) => {
      this.currencyList = res;
    });
  }
  checkIfAllRequiredValuesFilled() {
    if (!this.promotionModel.name) {
      return false
    }
    return true
  }
  public exportExcel = (event) => {
    event.stopPropagation();
    let params = {
      fileName: 'promotionsAcounts.csv',
      columnSeparator: ','
    };
    this.gridApi.exportDataAsCsv(params);

  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  onEvent(event) {
    event.stopPropagation();
  }
  checkIfDuplicated = (value) => {
    if (value) {
      const scenarioList = [
        ...this.promotionList,
      ];
      const list =
        this.isEdit && this.promotionModelTemp.name
          ? scenarioList.filter((row) => row.name !== this.promotionModelTemp.name)
          : scenarioList;
      this.isDuplicated = list.find(
        (row) => row.name.trim().toLowerCase() === value.trim().toLowerCase()
      )
        ? true
        : false;
    } else {
      this.isDuplicated = false;
    }
  }
  percentFormatter(params) {
    if (params.value)
      return params.value + '%'
    return ""
  }
  stackAbleFormatter(params) {
    if (params.value)
      return 'Yes'
    return ""
  }
  activeFormatter(params) {
    if (params.value)
      return 'Active'
    return ""
  }
}

