import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-insight-customer-detail-view',
  templateUrl: './insight-customer-detail-view.component.html',
  styleUrls: ['./insight-customer-detail-view.component.scss']
})
export class InsightCustomerDetailViewComponent implements OnInit {
  @Input() data;
  constructor() { }

  ngOnInit() {
    if (this.data) {
      this.data = JSON.parse(this.data);
      this.data = this.data.rowData;
    }
  }
  getText() {
    let a = moment(this.data.dateGenerated);
    let b = moment(this.data.expiryDate);
    let diff = b.diff(a, 'days');
    return `Expires in ${diff} days`;
  }
}
