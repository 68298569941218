<div class="scrolling-wrapper">
  <span class="material-icons-round" (click)="scrollLeft()" *ngIf="index > 0 && scrollText !== 'All'">
    arrow_left
  </span>
  <a class="text" title="{{ scrollText }}">{{ scrollText }}</a>
  <span
    *ngIf="leafNodes.length > 0 && index < leafNodes.length - 1 && scrollText !== 'All'"
    class="material-icons-round"
    (click)="scrollRight()"
  >
    arrow_right
  </span>
  <div *ngIf = "productList.length > 0 && !selectedProduct" class="form-group scrolling-dropdown">
    <app-dropdown-treeview-select
      [config]="config"
      [items]="productList"
      (valueChange)="setValues($event)"
      (filterChange)="onFilterChange($event)"
    >
    </app-dropdown-treeview-select>
  </div>
</div>
