<div class="container-fluid">

  <div class="grid-action-btn-bar">
    <div class="title-label">
      Location Hierarchy Manager
    </div>

    <button class="import-export-button" mat-flat-button color="accent"
      [matMenuTriggerFor]="importExportMenu">Export/Import</button>
    <mat-menu #importExportMenu="matMenu">
      <button mat-menu-item (click)="onExportData()">Export Template</button>
      <button mat-menu-item (click)="importNewLocationWeeklyData()">Import
        <input class="hidden" type="file" id="upload-new-item-approval-promotion-data"
          name="upload-promotion-planner-data" accept=".csv" (change)="validateUploadedCSV($event)">
      </button>
    </mat-menu>

    <button class="add-icon-btn ml-10" (click)="addEditLocationHierarchyRow()" matTooltip="Add Hierarchy" mat-icon-button
      color="primary" type="button">
      <i class="material-icons">
        add_circleg
      </i>
    </button>
  </div>

  <div class="all-show-grid items-wrapper-grid">
    <ag-grid-angular #forecastHierarchyGrid class="ag-theme-material" [gridOptions]="gridOptions"
      [columnDefs]="locationGridColumnDefs" [defaultColDef]="defaultColDef" [rowData]="gridData"
      (gridReady)="onGridReady($event)" [modules]="modules">
    </ag-grid-angular>
  </div>

  <div style="width: 0; height: 0;">
    <ag-grid-angular #forecastHierarchyExportGridHidden class="ag-theme-material hide-grid" [gridOptions]="gridOptions"
      [columnDefs]="columnHiddenDefs" [defaultColDef]="defaultColDef" [rowData]="hiddenGridData"
      (gridReady)="onHiddenGridReady($event)" [modules]="modules">
    </ag-grid-angular>
  </div>
</div>
