import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwError } from 'rxjs/internal/observable/throwError';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/models/User';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
@Component({
  selector: 'app-create-update-team',
  templateUrl: './create-update-team.component.html',
  styleUrls: ['./create-update-team.component.css']
})
export class CreateUpdateTeamComponent implements OnInit {
  public registerForm: FormGroup;
  public isPhoneNumberValid = true;
  public submitted = false;
  public phoneNumberRegex = /^([0-9]){9}$/;
  public emailMaxChars = 50;
  public id: number;
  public isEdit = false;
  public loading = false;
  public selectedTeam: any;
  public teamList = [];
  public TeamClaims: any;
  public isRolsCalims: boolean;
  public oneAtATime: boolean = false;
  public customers = [];
  public settingList = ['Buyer Settings', 'Product Settings', 'Calendar Settings', 'Currency Settings', 'Finance Settings'];
  @Input() data;
  productList = [];
  productFlatList = [];
  usersList = [];
  usersListUI = [];
  productSettings;
  public permissions = [
  ]
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public userService: UsersService,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    public config: ConfigurationService
  ) {
  }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm; }
  ngOnInit() {
    this.getProductList();
    this.initializeDropDowns();
    this.initializeForm();

    this.data = this.config.teamsModel
    this.data = this.data ? JSON.parse(this.data) : null;
    if (this.data && this.data.rowData && this.data.rowData.teamId) {
      this.id = this.data.rowData.teamId;
      this.isEdit = true;
      this.getSelectedTeam(this.id.toString());
    }
    this.customers = [{ id: 1, name: "Best Buy", isChecked: false }, { id: 2, name: "Target", isChecked: false }]
  }
  public getProductList = () => {
    this.config.GetItemList({}).subscribe(async (res) => {
      this.productSettings = await this.config.GetProductSetting({ teamId: this.id }).toPromise().catch(error => throwError(error));
      res = res.map(row => ({ ...row, item: row.itemName, isChecked: (this.productSettings?.items || []).find(item => row.itemId === item.itemId) ? true : false }));
      this.productFlatList = res;
      this.productList = res.map(menu => ({ ...menu, id: menu.itemId, children: this.getChildren(menu, res) }));
      this.productList = this.productList.filter(menu => !menu.parentId);
      // const list = this.getNestedChildren(res, 0);
      const nested = res.reduce((initial, value, index, original) => {
        if (!value.parentId) {
          if (initial.left.length) this.checkLeftOvers(initial.left, value)
          delete value.parentId
          value.root = true;
          initial.nested.push(value)
        }
        else {
          let parentFound = this.findParent(initial.nested, value)
          if (parentFound) this.checkLeftOvers(initial.left, value)
          else initial.left.push(value)
        }
        return index < original.length - 1 ? initial : initial.nested
      }, { nested: [], left: [] })
      // this.productList = [...this.productList, ...list];
      this.productList.forEach(menu => {
        menu = nested.find(item => item.itemId === menu.itemId);
      });
    });
  }
  public getChildren = (menu, list) => {
    const filterd = list.filter(row => row.parentId === menu.itemId);
    if (filterd && filterd.length > 0) {
      return filterd;
    } else {
      return [];
    }
  }
  checkLeftOvers(leftOvers, possibleParent) {
    for (let i = 0; i < leftOvers.length; i++) {
      if (leftOvers[i].parentId === possibleParent.itemId) {
        delete leftOvers[i].parentId
        possibleParent.children ? possibleParent.children.push(leftOvers[i]) : possibleParent.children = [leftOvers[i]]
        possibleParent.count = possibleParent.children.length
        const addedObj = leftOvers.splice(i, 1)
        this.checkLeftOvers(leftOvers, addedObj[0])
      }
    }
  }
  findParent(possibleParents, possibleChild) {
    let found = false
    for (let i = 0; i < possibleParents.length; i++) {
      if (possibleParents[i].itemId === possibleChild.parentId) {
        found = true
        delete possibleChild.parentId
        if (possibleParents[i].children) possibleParents[i].children.push(possibleChild)
        else possibleParents[i].children = [possibleChild]
        possibleParents[i].count = possibleParents[i].children.length
        return true
      } else if (possibleParents[i].children) found = this.findParent(possibleParents[i].children, possibleChild)
    }
    return found;
  }

  getNestedChildren(arr, parentId) {
    var out = []
    for (var i in arr) {
      if (arr[i].parentId === parentId) {
        var children = this.getNestedChildren(arr, arr[i].itemId)

        if (children.length) {
          arr[i].children = children
        }
        out.push(arr[i])
      }
    }
    return out
  }
  public getParams() {
    this.route.queryParams.subscribe(params => {
      if (params && params.id) {
        this.id = Number(atob(params.id));
        if (this.id) {
          this.isEdit = true;
          this.getSelectedTeam(this.id.toString());

        }
      }
    });
  }
  public getSelectedTeam = (id: string) => {
    this.spinner.show()
    this.userService.GetTeamById(id).subscribe(res => {
      if (res) {
        this.getSelectedTeamGroupList(this.id.toString());
        this.selectedTeam = res;
        this.populateForm(this.selectedTeam);
        this.spinner.hide()
      }
    }, error => {
      this.spinner.hide()
    });
  }
  public getSelectedTeamGroupList = (id: string) => {
    this.spinner.show();
    const params = {
      teamId: id
    }
    this.config.TeamScreenGroupGetList(params).subscribe(res => {
      if (res) {
        this.selectedTeam.permissionList = JSON.parse(JSON.stringify(res)).splice(0, 5) || [];
        this.selectedTeam.permissions = JSON.parse(JSON.stringify(res)).splice(5) || [];
        this.spinner.hide()
      }
    }, error => {
      this.spinner.hide()
    });
  }

  public populateForm = (user: User) => {
    Object.keys(this.f.controls).forEach(key => {
      if (key) {
        this.f.controls[key].setValue(user[key]);
      }
    });
  }
  public initializeDropDowns = async () => {
    const userRespone = await this.userService.GetAllUsers({ userTypeId: 1 }).toPromise().catch(error => throwError(error));
    if (userRespone && userRespone.data) {
      this.usersList = userRespone.data;
      this.usersListUI = JSON.parse(JSON.stringify(this.usersList));
    }
    this.userService.getTeams().subscribe((res: any) => {
      this.teamList = res;
    });
  }
  initializeForm() {
    this.registerForm = this.formBuilder.group({
      teamId: [0],
      teamName: ['', Validators.required],
      active: [true],
      applicationUser: [],
      users: [],
    });

  }

  // Cancel_Click() {
  //   this.router.navigate(['/Users']);
  // }
  onSubmit(errorTemplate, errorTeamCodeTemplate) {
    this.submitted = true;
    if (this.f.valid) {
      if (this.checkIfAlreadyExists(this.f.value)) {
        this.dialogRef = this.dialog.open(errorTemplate);
        return;
      }

      this.loading = true;
      let model = {
        ...this.f.value,
      };
      this.spinner.show();
      if (this.isEdit) {
        model = {
          ...model,
        };
        const groupModel = {
          teamId: model.teamId,
          teamScreenGroups: [...this.selectedTeam.permissionList, ...this.selectedTeam.permissions]
        }
        this.userService.UpdateTeam(model).subscribe(res => {
          (model.applicationUser || []).forEach(element => {
            const reqModel = {
              teamId: res,
              ...element
            }
            this.userService.AddUserTeam(reqModel).subscribe(res => { });
          });
          this.config.TeamScreenGroupAdd(groupModel).subscribe(response => {
            this.spinner.hide();
            this.config.updateListing.next('Team');
            this.initializeDropDowns();
            this.toastr.success('', 'Team updated successfully');
          }, error => {
            this.spinner.hide();
          })
          this.loading = false;
          this.userService.listUpdate.next(true);
        }, error => {
          this.spinner.hide();
          this.toastr.error('Error', 'Something went wrong team not updated successfully');
          this.loading = false;
        });
      } else {
        this.userService.CreateTeam(model).subscribe(res => {
          this.spinner.hide();
          (model.applicationUser || []).forEach(element => {
            const reqModel = {
              teamId: res,
              ...element
            }
            this.userService.AddUserTeam(reqModel).subscribe(res => { });
          });
          this.config.updateListing.next('Team');
          this.initializeDropDowns();
          this.toastr.success('', 'Team Created successfully');
          this.initializeForm()
          this.userService.listUpdate.next(true);
          this.loading = false;
        }, error => {
          this.spinner.hide();
          this.toastr.error('Error', 'Something went wrong team not created successfully');
          this.loading = false;
        });
      }
    }
  }
  checkIfAlreadyExists(team) {
    if (this.teamList.length == 0)
      return
    const list = this.isEdit ? this.teamList.filter(row => row.teamName !== this.selectedTeam.teamName) : this.teamList;
    return list.find(row => row.teamName.toLowerCase() === team.teamName.toLowerCase());
  }

  addTeam = () => { }
  removeUser = (user) => {
    let value = this.f.controls.applicationUser.value;
    value = value.filter(u => u.userId !== user.userId);
    this.f.controls.applicationUser.setValue(value);
  }
  addUserToList = () => {
    if (this.f.controls.users.value) {
      const selectedUser = this.f.controls.users.value;
      const value = this.f.controls.applicationUser.value ? this.f.controls.applicationUser.value : [];
      value.push(selectedUser);
      const ids = value.map(el => el.userId);
      this.f.controls.applicationUser.setValue(value);
      this.usersList = this.usersListUI.filter(row => !ids.includes(row.userId));
    }
  }
}
