import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReplenishmentService } from 'src/services/replenishment.service';

// <a (click)="viewChartHandler()">View Chart</a>

@Component({
  selector: 'ag-grid-line-chart-cell-renderer',
  template: `<div class="chart-cell" (click)="viewChartHandler()">
    <fusioncharts
      [chartConfig]=chartConfig
      [dataSource]="dataSource">
    </fusioncharts>
</div>`,
})
export class AgGridLineChartCellRenderer implements ICellRendererAngularComp, OnDestroy {
  params: any;

  dataSource: any = {
    chart: {
      theme: "fusion",
      showXAxisLine: 0,
      showYAxisValues: 0,
      showValues: 0,
      chartLeftMargin: "0",
      chartTopMargin: "0",
      chartRightMargin: "0",
      chartBottomMargin: "0",
      canvasPadding: "0",
      drawAnchors: "0",
      divLineAlpha: "0"
    },
    categories: [
        {
          category: [
            {
              label: "Jan"
            },
            {
              label: "Feb"
            },
            {
              label: "Mar"
            },
            {
              label: "Apr"
            },
            {
              label: "May"
            },
            {
              label: "Jun"
            },
            {
              label: "Jul"
            },
            {
              label: "Aug"
            },
            {
              label: "Sep"
            },
            {
              label: "Oct"
            },
            {
              label: "Nov"
            },
            {
              label: "Dec"
            }
          ]
        }
      ],
      dataset: [
        {
          seriesname: "Sales",
          renderas: "line",
          data: []
        },
        {
          seriesname: "Stock",
          renderas: "line",
          data: []
        }
      ]
  }

  chartConfig: Object = {
    width: '200',
    height: '80',
    type: "mscombi2d",
    dataFormat: 'json',
  };

  constructor(private replenishmentService: ReplenishmentService) {
  }

  agInit(params: any): void {
    this.params = params;
    this.populateGraphView();
  }

  refresh(params?: any): boolean {
    this.populateGraphView();
    return true;
  }

  populateGraphView() {
    this.dataSource.categories[0].category.forEach((e, i) => {
      for (let j=0; j<2; j++) {
        const randomNumber = Math.random() *10;
        this.dataSource.dataset[j].data[i] = { value: randomNumber.toString()};
      }
    });
  }

  viewChartHandler() {
    this.replenishmentService.replenishmentGraphSubject.next(this.dataSource.dataset);
  }

  ngOnDestroy() {
    // no need to remove the button click handler 
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}