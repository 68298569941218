<div class="container">
  <div class="user-page">
    <div class="row">
      <div class="col-md-4">
        <form action="" class="form-fields show-search">
          <div class="form-group">
            <mat-form-field>
              <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material"
       [suppressMenuHide]="true"  groupHeaders suppressRowClickSelection
      toolPanelSuppressGroups toolPanelSuppressValues (cellDoubleClicked)="onCellClicked($event)"
      [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="allActivityList" (gridReady)="onGridReady($event)"
      [modules]="modules" #grid></ag-grid-angular>
  </div>


</div>
