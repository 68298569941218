import { Component, OnInit, ViewChild, Inject, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FieldsSettingsModel, NodeCheckEventArgs, TreeView } from '@syncfusion/ej2-angular-navigations';
import * as XLSX from 'xlsx';
import { TreeGridModel } from '@syncfusion/ej2-angular-treegrid';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { USER_ID } from 'src/common/keys';
import { BusinessInformationService } from 'src/services/business-information.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';

@Component({
  selector: 'app-levels',
  templateUrl: './levels.component.html',
  styleUrls: ['./levels.component.scss']
})
export class LevelsComponent implements OnInit {
  public columnDefs = [
    {
      headerName: 'First Name',
      field: 'firstName',
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
    },
    {
      headerName: 'Email',
      field: 'userName'
    },
    {
      headerName: '',
      field: 'delete',
      resizable: true,
      filter: false,
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveUserDialog.bind(this),
      },
      width: 80
    }
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public attributeList = [];
  public answerTypeList = [];
  public modules = AllCommunityModules;
  public user: any;
  public businessInfoForm: FormGroup;
  public isEdit = false;
  @ViewChild('dialog') dialogAddTemplate: TemplateRef<any>;
  @ViewChild('ExcelImport') excelImport: TemplateRef<any>;
  @ViewChild('tree') public tree: TreeView;
  public files = [];
  public registerForm: FormGroup;
  public submitted = false;
  public field = {};
  public levelList = [];
  public groupDefaultExpanded;
  public getDataPath;
  public autoGroupColumnDef;
  public allowEditing: boolean = true;
  public level: any;
  public workgroupList = [];
  public userList = [];
  public tempUserList = [];
  public linkedUserList = [];
  public selectAll = false;
  public currentLevel;
  public importListField = [];
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public businessInfoService: BusinessInformationService,
    public dialogAddRef: MatDialogRef<any>,
    public addUserDialogRef: MatDialogRef<any>,
    public dialogUserListRef: MatDialogRef<any>,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public userService: UsersService) {
    this.gridOptions = {
      frameworkComponents: {
        deleteButtonRenderer: ButtonRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }

  ngOnInit() {
    this.getUserList();
    this.getDataPath = (data) => {
      return data.parentId;
    };
    this.businessInfoService.getWorkGroupList({}).subscribe(workgroupList => {
      this.workgroupList = (workgroupList || []);
    });
    this.populateList();
    this.initializeForm();
  }
  openExcelImportDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(this.excelImport, { width: '500px', data: data || {} });
  }
  onRemove = () => { }
  public populateList = () => {
    this.businessInfoService.getLevelList({ clientId: 1 }).subscribe((res: any) => {
      this.levelList = res;
      this.levelList.forEach(level => {
        level.parentId = level.parentId ? level.parentId : null;
        if (this.levelList.find(result => level.levelId === result.parentId)) {
          level.hasChild = true;
        }
      });
      this.field = { dataSource: this.levelList, id: 'levelId', parentID: 'parentId', text: 'levelName', hasChildren: 'hasChild' };
    });
    this.levelList.forEach(level => {
      level.parentId = level.parentId ? level.parentId : null;
      if (this.levelList.find(result => level.levelId === result.parentId)) {
        level.hasChild = true;
      }
    });
    this.field = { dataSource: this.levelList, id: 'levelId', parentID: 'parentId', text: 'levelName', hasChildren: 'hasChild' };
  }
  public editing(args: NodeCheckEventArgs) {
    // if (args.node.parentNode.parentNode.nodeName !== 'LI') {
    //   args.cancel = true;
    // }
  }
  public removeNode = (nodeData: any) => {
    let list = (this.field as any).dataSource;
    const model = {
      levelId: nodeData.levelId,
      active: false
    };
    this.businessInfoService.activateLevel(model).subscribe(flag => {
      if (flag) {
        list = (this.field as any).dataSource.filter(res => res.levelId !== nodeData.levelId);
        this.field = { dataSource: list, id: 'levelId', parentID: 'parentId', text: 'levelName', hasChildren: 'hasChild' };
        this.tree.expandAll();
      }
    });
  }
  initializeForm() {
    this.registerForm = this.formBuilder.group({
      levelId: [0],
      tasks: [],
      approvals: [],
      views: [],
      levelName: [''],
      parentId: [0],
      active: true,
      currentUserId: [this.storage.get(USER_ID)]
    });
  }
  populateForm(event): void {
    if (event && event.data) {
      this.isEdit = true;
      const dataToPopulate = event.data;
      this.linkedUserList = dataToPopulate.userlevels;
      // this.gridOptions.api.setRowData(this.linkedUserList);
      Object.keys(this.registerForm.controls).forEach(key => {
        if (dataToPopulate[key]) {
          this.registerForm.controls[key].setValue(dataToPopulate[key]);
        }
      });
    }
  }
  onNodeEdited($event) {
    const nodeData = $event.nodeData;
    const newCategory = {
      categoryId: nodeData.id,
      categoryName: $event.newText,
      clientId: nodeData.id,
      parentId: nodeData.parentID,
      active: true
    };
    // this.businessInfoService.updateCategory(newCategory).subscribe(res => {
    //   if (res) {
    //     this.toastr.success('Success', 'Category Updated Successfully');
    //   }
    // });
  }
  Cancel_Click() {
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  openCategoryDialog(nodeData: any, templateRef: TemplateRef<any>, isEdit: boolean) {
    this.linkedUserList = [];
    if (isEdit) {
      this.isEdit = true;
      this.populateForm({ data: nodeData });
    } else {
      this.isEdit = false;
      this.initializeForm();
    }
    // tslint:disable-next-line: no-use-before-declare
    this.dialogAddRef = this.dialog.open(templateRef, {
      width: '500px',
      data: nodeData,
      disableClose: true
    });

    this.dialogAddRef.afterClosed().subscribe(result => {
      if (result && result.parentId) {
        if (this.isEdit) {
          this.updateLevel(result);
        } else {
          this.addNewLevel(result);
        }
      } else if (result) {
        if (this.isEdit) {
          this.updatedParentLevel(result);
        } else {
          this.addParentLevel(result);
        }
      } else {
        this.isEdit = false;
        this.initializeForm();
      }
    });
  }
  public linkUserToLevel = (id?) => {
    const list = this.linkedUserList.filter(row => !row.levelId);
    const params = {
      userLevels: list
    };
    if (id) {
      params.userLevels = list.map(row => ({ ...row, levelId: id }));
      this.businessInfoService.addUserLevel(params).subscribe(res => {
        this.populateList();
        this.getUserList();
        this.linkedUserList = [];
        this.gridOptions.api.setRowData(this.linkedUserList);
      });
    }
  }
  openConfirmationDialog(nodeData: any) {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { ...nodeData, headerName: nodeData.levelName },
      disableClose: true
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeNode(result);
      }
    });
  }
  public addNewLevel = (result: any) => {
    if (result) {
      const model = {
        ...result,
      };
      // model.tasks = model.tasks.map(res =>)
      this.businessInfoService.addLevel(model).subscribe(res => {
        if (res) {
          this.linkUserToLevel(res);
          result.levelId = res;
          const list = (this.field as any).dataSource;
          list.forEach(element => {
            if (element.levelId === result.parentId) {
              element.hasChild = true;
            }
          });
          list.push(result);
          this.field = { dataSource: list, id: 'levelId', parentID: 'parentId', text: 'levelName', hasChildren: 'hasChild' };
          this.tree.refresh();
          this.submitted = false;
        }
      });
    }
  }
  public updateLevel = (result: any) => {
    if (result) {
      const model = {
        ...screen,
        ...result,
        clientId: 1
      };
      this.businessInfoService.updateLevel(model).subscribe(res => {
        if (res) {
          this.linkUserToLevel(model.levelId);
          const list = (this.field as any).dataSource;
          list.forEach(element => {
            if (element.levelId === model.parentId) {
              element.hasChild = true;
            }
          });
          const index = list.findIndex(row => row.levelId === model.levelId);
          if (index > -1) {
            list[index] = model;
            this.field = { dataSource: list, id: 'levelId', parentID: 'parentId', text: 'levelName', hasChildren: 'hasChild' };
            this.tree.refresh();
          }
          // this.populateList();
          this.submitted = false;
          this.isEdit = false;
        }
      });
    }
  }
  public compareFn = (a, b) => {
    return a.workgroupId === b.workgroupId;
  }
  public addParentLevel = (result: any) => {
    if (result) {
      const model = {
        ...result,
        clientId: 1
      };
      this.spinner.show();
      this.businessInfoService.addLevel(model).subscribe(res => {
        if (res) {
          this.linkUserToLevel(res);
          this.submitted = false;
          this.populateList();
          this.spinner.hide();
        }
      }, error => {
        this.spinner.hide();
      });
    }
  }
  public updatedParentLevel = (result: any) => {
    if (result) {
      const model = {
        ...screen,
        ...result,
        clientId: 1
      };
      this.businessInfoService.updateLevel(model).subscribe(res => {
        if (res) {
          this.linkUserToLevel(model.levelId);
          this.submitted = false;
          this.isEdit = false;
          this.populateList();
        }
      });
    }
  }
  public getUserList = () => {
    const params = {
      active: true
    };
    this.businessInfoService.getUserLevelList({}).subscribe(res => {
      this.userList = res;
      this.tempUserList = res;
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit(data: any) {
    this.submitted = true;
    if (this.registerForm.valid) {
      if (this.isEdit) {
        this.dialogAddRef.close({ levelId: 0, ...this.registerForm.value });
      } else {
        this.dialogAddRef.close({ levelId: 0, ...this.registerForm.value, parentId: data.levelId });
      }
    }
  }
  public removeSpaces = (levelName: string) => {
    if (levelName) {
      return levelName.trim();
    }
  }
  public openDialog = (template, data) => {
    const dialogRef = this.dialog.open(template, { width: '800px', data: data, disableClose: true });
  }
  public openDialogUserList = (template, data) => {
    this.dialogUserListRef = this.dialog.open(template, { width: '800px', height: '600px', data: data, disableClose: true });
    this.dialogUserListRef.afterClosed().subscribe(res => {
    })
  }
  public selectAllSelected = () => {

  }
  public onSave = async (data) => {
    const list = data.filter(row => row.show);
    const requestVM = {
      userLevels: list
    };
    if (this.currentLevel) {

    }
    if (this.linkedUserList.length === 0) {
      this.linkedUserList = list;
    } else {
      list.forEach(element => {
        if (!(this.linkedUserList.find(res => res.userId === element.userId))) {
          this.linkedUserList.push(element);
        }
      });
    }
    this.gridOptions.api.setRowData(this.linkedUserList);
    this.addUserDialogRef.close();
  }
  public addLevelBeforeUser = (model) => {
    return this.businessInfoService.addLevel(model);
  }
  getTasks = (list) => {
    if (list && list.length > 0) {
      return list.map(el => el.workgroupName).toString();
    }
  }
  getUsers = (list) => {
    if (list && list.length > 0) {
      return list.map(el => el.userName).toString();
    }
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.title },
      disableClose: true
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.removeItemMaster(row.rowData);
      }
    });
  }
  openRemoveUserDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: `${row.rowData.firstName} ${row.rowData.lastName}` },
      disableClose: true
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeUserFromLevel(row.rowData);
      }
    });
  }
  public removeUserFromLevel = (user) => {
    if (user.levelId) {
      this.linkedUserList = this.linkedUserList.filter(row => user.userId !== row.userId);
      const params = {
        userLevels: this.linkedUserList
      };
      this.businessInfoService.updateUserLevel(params).subscribe(res => {
        this.getUserList();
        this.gridOptions.api.setRowData(this.linkedUserList);
      });
    } else {
      this.linkedUserList = this.linkedUserList.filter(row => user.userId !== row.userId);
      this.gridOptions.api.setRowData(this.linkedUserList);
    }
  }
  openAddDialog(data, templateRef?: TemplateRef<any>): void {
    if (data && data.rowData) {
      this.isEdit = true;
    }
    this.addUserDialogRef = this.dialog.open(this.dialogAddTemplate, {
      width: '500px', data: data || {},
      disableClose: true
    });
  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  onSelect(event: any) {
    /* wire up file reader */
    // const target: any = <DataTransfer>(event.target);
    if (event.addedFiles.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(event.addedFiles[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      // console.log(data);
      data.forEach(row => {
        row = Object.keys(row).map(key => row[key.replace(/\s/g, '')] = row[key]); // Data will be logged in array format containing objects
      });
      const list = data.filter((row: any) => !row.ParentLevelTitle);
      if (list.length > 0) {
        list.forEach((row: any, i) => {
          const object = {
            levelName: row.LevelTitle,
            tasks: row.Tasks ? row.Tasks.split(',').map((el, index) => ({ workgroupName: el, workgroupId: index })) : [],
            approvals: row.Approvals ? row.Approvals.split(',').map((el, index) => ({ workgroupName: el, workgroupId: index })) : [],
            views: row.View ? row.View.split(',').map((el, index) => ({ workgroupName: el, workgroupId: index })) : [],
            userlevels: row.UserEmails ? row.UserEmails.split(',').map((el, index) => ({ email: el, userName: el.split('@')[0] })) : [],
            parentId: null,
            levelId: this.importListField.length + 1
          };
          this.importListField.push(object);
          this.mapParentChild(data, row, object.levelId);
          this.importListField.forEach(level => {
            level.parentId = level.parentId ? level.parentId : null;
            if (this.importListField.find(result => level.levelId === result.parentId)) {
              level.hasChild = true;
            }
          });
          this.field = { dataSource: this.importListField, id: 'levelId', parentID: 'parentId', text: 'levelName', hasChildren: 'hasChild' };
          this.tree.refresh();
          this.dialogRef.close();
        });
      }
    };
  }
  public mapParentChild(data, row, parentId) {
    const list = data.filter(el => el.ParentLevelTitle === row.LevelTitle);
    list.forEach(element => {
      const obj = {
        levelName: element.LevelTitle,
        tasks: element.Tasks ? element.Tasks.split(',').map((el, index) => ({ workgroupName: el, workgroupId: index })) : [],
        approvals: element.Approvals ? element.Approvals.split(',').map((el, index) => ({ workgroupName: el, workgroupId: index })) : [],
        views: element.View ? element.View.split(',').map((el, index) => ({ workgroupName: el, workgroupId: index })) : [],
        userlevels: element.UserEmails ? element.UserEmails.split(',').map((el, index) => ({ email: el, userName: el.split('@')[0] })) : [],
        parentId: parentId,
        levelId: this.importListField.length + 1
      };
      this.importListField.push(obj);
      const innerList = data.filter(el => el.ParentLevelTitle === element.LevelTitle);
      if (innerList.length > 0) {
        this.mapParentChild(data, element, obj.levelId);
      }
    });



  }
  public getTemplate() {
  }
  public addUserListToLevel = () => {
    this.dialogUserListRef.close();
  }
}
