import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-forecast-confirmation-modal',
  templateUrl: './forecast-confirmation-modal.component.html',
  styleUrls: ['./forecast-confirmation-modal.component.scss']
})
export class ForecastConfirmationModalComponent implements OnInit {

  name
  constructor(
    public dialogRef: MatDialogRef<ForecastConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }
  public saveAndClose = () => {
    this.dialogRef.close(this.data);
  }
  public close = () => {
    this.data.isCanceled = true;
    this.dialogRef.close(this.data);
  }
  public onNoClick = () => {
    this.dialogRef.close();
  }
  public restoreSession = () => {
    this.data.restoreSession = true;
    this.dialogRef.close(this.data);
  }
  public openSavedVersions = () => {
    this.data.openSaved = true;
    this.dialogRef.close(this.data);
  }
  public onSave = () => {
    this.data.isSave = true;
    this.dialogRef.close(this.data);
  }
  public onCommit = () => {
    this.data.isCommit = true;
    this.dialogRef.close(this.data);
  }
  public onSaveCommit = () => {
    this.data.isSaveCommit = true;
    this.dialogRef.close(this.data);
  }
  onNameSave(){
    this.data.isNameSave = true;
    this.data.name = this.name
    this.dialogRef.close(this.data);
  }
  onDelete(){
    this.data.isDelete = true;
    this.dialogRef.close(this.data);
  }
}
