import { Component, OnInit } from '@angular/core';
import { tiersData, tiersDataSummary } from '../../mockData';
import { generateGridColumns, generateSecondGridColumns } from './ag-grid';

@Component({
  selector: 'app-price-tiers',
  templateUrl: './price-tiers.component.html',
  styleUrls: ['./price-tiers.component.scss']
})
export class PriceTiersComponent implements OnInit {

  // Grid Variables
  private gridApi;
  gridData = tiersData;

  gridColumnApi;

  columnDefs = generateGridColumns();
  defaultColDef;
  suppressRowTransform = true;

  secondGridData = tiersDataSummary;
  secondGridColumns = generateSecondGridColumns();
  private secondGridApi;

  // GRAPH DATA
  classesData = [
    'Formal',
    'Casual',
    'Sports/Athleisure',
    'Sandal/Slippers',
    'Accessories'
  ];
  selectedClass = 0;

  chartData = [
    {
      label: "Good",
      value: "290"
    },
    {
      label: "Better",
      value: "260"
    },
    {
      label: "Best",
      value: "180"
    }
  ];
  // STEP 3 - Chart Configuration
  dataSource = {
    chart: {
      //Set the chart caption
      caption: "Price Tiers",
      //Set the theme for your chart
      theme: "fusion"
    },
    // Chart Data - from step 2
    data: this.chartData
  };

  selectedSeason = '1';
  seasons = [
    {
      id: '1',
      name: 'SS-22'
    },
    {
      id: '3',
      name: 'SS-21'
    },
    {
      id: '4',
      name: 'SS-20'
    }
  ];

  selectedBrand = '1';
  brands = [
    {
      id: '1',
      name: 'Nike'
    },
    {
      id: '3',
      name: 'Outfitters'
    },
    {
      id: '4',
      name: 'Levis'
    }
  ];

  selectedDepartment = '1';
  departments = [
    {
      id: '1',
      name: 'Men'
    },
    {
      id: '2',
      name: 'Women'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.cellValueChanged();
  }

  cellValueChanged(type = false, event = null) {
    console.log(event);
    setTimeout(() => {
      if (event && event.colDef.field == "fy2022") {

        this.gridData[3].fy2022 = Number(this.gridData[0].fy2022) + Number(this.gridData[1].fy2022) + Number(this.gridData[2].fy2022);
        this.gridData[3].fy2021 = Number(this.gridData[0].fy2021) + Number(this.gridData[1].fy2021) + Number(this.gridData[2].fy2021);
        this.gridData[7].fy2022 = Number(this.gridData[4].fy2022) + Number(this.gridData[5].fy2022) + Number(this.gridData[6].fy2022);
        this.gridData[7].fy2021 = Number(this.gridData[4].fy2021) + Number(this.gridData[5].fy2021) + Number(this.gridData[6].fy2021);
        this.gridData[11].fy2022 = Number(this.gridData[8].fy2022) + Number(this.gridData[9].fy2022) + Number(this.gridData[10].fy2022);
        this.gridData[11].fy2021 = Number(this.gridData[8].fy2021) + Number(this.gridData[9].fy2021) + Number(this.gridData[10].fy2021);
        this.gridData[15].fy2022 = Number(this.gridData[12].fy2022) + Number(this.gridData[13].fy2022) + Number(this.gridData[14].fy2022);
        this.gridData[15].fy2021 = Number(this.gridData[12].fy2021) + Number(this.gridData[13].fy2021) + Number(this.gridData[14].fy2021);
        this.gridData[19].fy2022 = Number(this.gridData[16].fy2022) + Number(this.gridData[17].fy2022) + Number(this.gridData[18].fy2022);
        this.gridData[19].fy2021 = Number(this.gridData[16].fy2021) + Number(this.gridData[17].fy2021) + Number(this.gridData[18].fy2021);
        for (let i = 0; i < 20; i++) {
          this.gridData[i].growth = (Number(this.gridData[i].fy2022) / Number(this.gridData[i].fy2021) - 1) * 100;
          let index = 0;
          if (i <= 3) index = 3;
          else if (i <= 7) index = 7;
          else if (i <= 11) index = 11;
          else if (i <= 15) index = 15;
          else if (i <= 19) index = 19;
          this.gridData[i].fy2022Cont = (Number(this.gridData[i].fy2022) / Number(this.gridData[index].fy2022)) * 100;
          this.gridData[i].fy2021Cont = (Number(this.gridData[i].fy2021) / Number(this.gridData[index].fy2021)) * 100;
        }
        this.gridApi.setRowData(this.gridData);

        // SECOND GRID
        this.secondGridData[0].plan = Number(this.gridData[3].fy2022);
        this.secondGridData[1].plan = Number(this.gridData[7].fy2022);
        this.secondGridData[2].plan = Number(this.gridData[11].fy2022);
        this.secondGridData[3].plan = Number(this.gridData[15].fy2022);
        this.secondGridData[4].plan = Number(this.gridData[19].fy2022);

        this.secondGridData[5].plan = Number(this.secondGridData[0].plan) + Number(this.secondGridData[1].plan)
          + Number(this.secondGridData[2].plan) + Number(this.secondGridData[3].plan) + Number(this.secondGridData[4].plan);

        this.secondGridData[0].var = Number(this.secondGridData[0].target) - Number(this.secondGridData[0].plan);
        this.secondGridData[1].var = Number(this.secondGridData[1].target) - Number(this.secondGridData[1].plan);
        this.secondGridData[2].var = Number(this.secondGridData[2].target) - Number(this.secondGridData[2].plan);
        this.secondGridData[3].var = Number(this.secondGridData[3].target) - Number(this.secondGridData[3].plan);
        this.secondGridData[4].var = Number(this.secondGridData[4].target) - Number(this.secondGridData[4].plan);
        this.secondGridData[5].var = Number(this.secondGridData[5].target) - Number(this.secondGridData[5].plan);

        this.secondGridApi.setRowData(this.secondGridData);
      }
      this.classChanged();
    }, 200);
  }

  classChanged() {
    this.dataSource.data = [
      {
        label: "Good",
        value: this.gridData[this.selectedClass * 4].fy2022.toString()
      },
      {
        label: "Better",
        value: this.gridData[(this.selectedClass * 4) + 1].fy2022.toString()
      },
      {
        label: "Best",
        value: this.gridData[(this.selectedClass * 4) + 2].fy2022.toString()
      }
    ];
  }

  onSecondGridReady(params) {
    this.secondGridApi = params.api;
  }

}
