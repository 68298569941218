<div *ngIf="!hideScreen" id="fb-forecast-new-planner" class="container-fluid">
  <div class="btn-wrapper" *ngIf="this.timeFrameListWithWeeks.length > 0" [hidden]="gridMapped">
    <div class="form-group orderClassForm">
      <mat-select #Account class="form-control disti-dropdown" placeholder="Select Account"
        [formControl]="accountDetail" [ngModelOptions]="{ standalone: true }"
        (selectionChange)="selectAccounts($event.value)" [disableOptionCentering]="true" multiple
        panelClass="orderClass">
        <mat-select-trigger>
          {{ getOptionsText(Account) }}
        </mat-select-trigger>
        <input (input)="filterAccounts($event.target.value)" placeholder="Filter" class="form-control" />
        <mat-option *ngFor="let distributor of accountDetailList; let i = index" [value]="distributor.distributorName">
          {{ distributor.distributorName }}
        </mat-option>
      </mat-select>
    </div>
    <div class="form-group orderClassForm">
      <mat-select #orderSorce class="form-control disti-dropdown" placeholder="Select Order Source"
        [(ngModel)]="orderSource" [ngModelOptions]="{ standalone: true }"
        (selectionChange)="selectAccounts($event.value)" multiple [disableOptionCentering]="true"
        panelClass="orderClass">
        <mat-select-trigger>
          {{ getOptionsText(orderSorce) }}
        </mat-select-trigger>
        <input (input)="filterOrderSource($event.target.value)" placeholder="Filter" class="form-control" />
        <mat-option *ngFor="let orderSource of orderSourceList; let i = index" [value]="orderSource.distributorName">
          {{ orderSource.distributorName }}
        </mat-option>
      </mat-select>
    </div>
    <div class="form-group orderClassForm">
      <mat-select #order class="form-control disti-dropdown" placeholder="Select Order Status" [(ngModel)]="orderStatus"
        [ngModelOptions]="{ standalone: true }" (selectionChange)="selectAccounts($event.value)" multiple
        [disableOptionCentering]="true" panelClass="orderClass">
        <mat-select-trigger>
          {{ getOptionsText(order) }}
        </mat-select-trigger>
        <input (input)="filterOrderStatus($event.target.value)" placeholder="Filter" class="form-control" />
        <mat-option *ngFor="let distributor of orderStatusList; let i = index" [value]="distributor.distributorName">
          {{ distributor.distributorName }}
        </mat-option>
      </mat-select>
    </div>
    <div class="form-group">
      <microsoft-treeview-dropdown [type]="'Timeframe'" searchPlaceholderText='selected Frame'
        [items]="timeFrameListWithWeeks" (valueChange)="setValue($event)">
      </microsoft-treeview-dropdown>
    </div>
    <div class="form-group" *ngIf="showVersionList">
      <mat-form-field>
        <mat-label>Choose Version</mat-label>
        <mat-select>
          <mat-option *ngFor="let version of versionList" [value]="version.versionTypeId">{{version.versionTypeName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <!-- [disabled]="itemIds.length == 0 || channelIds.length ==0 || calendarIds.length==0" -->
      <button (click)="mapGridList(false)"
        class="btn btn-primary adjust-btn">{{isBuidlNew ? 'Apply' : 'Adjust'}}</button>
    </div>
  </div>

  <div [hidden]="!gridMapped">
    <mat-accordion class="fb-forecast-accordion order-panel">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
          <mat-panel-title> Order Manager </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="data-wrapper btn-wrappers">
          <button type="submit" class="btn btn-primary d-block" (click)="applyUDP()">Apply</button>
        </div>
        <div class="container-fluid item-wrapper fb-forecast-planner" [style.height]="isAnyOpened() ? '70vh' : ''">
          <div class="row method-planner-bottom w-100">
            <div class="col-md-3 fb-line align">
              <div *ngIf="accountDetail">
                <h3>Adjust Data Set</h3>
                <form>
                  <div class="form-group">
                    <label>Account</label>
                    <mat-select #Account [(ngModel)]="accountDetail" placeholder="Select Account" multiple
                      [disableOptionCentering]="true">
                      <mat-select-trigger>
                        {{ getOptionsText(Account) }}
                      </mat-select-trigger>
                      <input (input)="filterAccounts($event.target.value)" placeholder="Filter" class="form-control" />
                      <mat-option *ngFor="let item of accountDetailList" [value]="item.distributorName">
                        {{ item.distributorName }}
                      </mat-option>
                    </mat-select>
                  </div>
                  <div class="form-group">
                    <label>Order Source</label>
                    <mat-select #orderSorce [(ngModel)]="orderSource" placeholder="Select Order Status" multiple
                      [disableOptionCentering]="true">
                      <mat-select-trigger>
                        {{ getOptionsText(orderSorce) }}
                      </mat-select-trigger>
                      <input (input)="filterOrderSource($event.target.value)" placeholder="Filter"
                        class="form-control" />
                      <mat-option *ngFor="let item of orderSourceList" [value]="item.distributorName">
                        {{ item.distributorName }}
                      </mat-option>
                    </mat-select>
                  </div>
                  <div class="form-group">
                    <label>Order Status</label>
                    <mat-select #order [(ngModel)]="orderStatus" placeholder="Select Order Source" multiple
                      [disableOptionCentering]="true">
                      <mat-select-trigger>
                        {{ getOptionsText(order) }}
                      </mat-select-trigger>
                      <input (input)="filterOrderStatus($event.target.value)" placeholder="Filter"
                        class="form-control" />
                      <mat-option *ngFor="let item of orderStatusList" [value]="item.distributorName">
                        {{ item.distributorName }}
                      </mat-option>
                    </mat-select>
                  </div>
                  <div class="form-group">
                    <label>Timeframe</label>
                    <microsoft-treeview-dropdown *ngIf="isRestoreSession || isBuidlNew" [type]="'Timeframe'"
                      [isReadonly]="true" [items]="restoreCalendarList" (valueChange)="setRestoredValue($event)">
                    </microsoft-treeview-dropdown>
                    <input type="text" id="timeframe" *ngIf="isOpenSaved" [value]="calanderValue" [readOnly]="true" />
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-3" style="margin-left: 25px;">
              <h3>Order Filters</h3>
              <div>
                <form>
                  <div class=" form-group">
                    <label>Batch ID</label>
                    <mat-select #BatchId placeholder="" (selectionChange)="onSelectionChanged($event)" [multiple]="true"
                      [disableOptionCentering]="true">
                      <mat-select-trigger>
                        {{ getOptionsText(BatchId) }}
                      </mat-select-trigger>
                      <input (input)="filterBatchId($event.target.value)" placeholder="Filter" class="form-control" />
                      <mat-option *ngFor="let item of batchId" [value]="item.name">
                        {{ item.name }}
                      </mat-option>
                    </mat-select>
                  </div>
                  <div class="form-group">
                    <label>Item</label>
                    <mat-select #Item placeholder="" (selectionChange)="onSelectionChanged($event)" [multiple]="true"
                      [disableOptionCentering]="true">
                      <mat-select-trigger>
                        {{ getOptionsText(Item) }}
                      </mat-select-trigger>
                      <input (input)="filterItem($event.target.value)" placeholder="Filter" class="form-control" />
                      <mat-option *ngFor="let item of item" [value]="item.name">
                        {{ item.name }}
                      </mat-option>
                    </mat-select>
                  </div>
                  <div class="form-group">
                    <label>Location</label>
                    <mat-select #Location placeholder="" (selectionChange)="onSelectionChanged($event)"
                      [multiple]="true" [disableOptionCentering]="true">
                      <mat-select-trigger>
                        {{ getOptionsText(Location) }}
                      </mat-select-trigger>
                      <input (input)="filterLocation($event.target.value)" placeholder="Filter" class="form-control" />
                      <mat-option *ngFor="let item of location" [value]="item.name">
                        {{ item.name }}
                      </mat-option>
                    </mat-select>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-3" style="margin-top: 50px; margin-left: 25px;">
              <div>
                <form>
                  <div class="form-group">
                    <label>Purchase Order #</label>
                    <mat-select #PurchaseOrder (selectionChange)="onSelectionChanged($event)" [multiple]="true"
                      [disableOptionCentering]="true">
                      <mat-select-trigger>
                        {{ getOptionsText(PurchaseOrder) }}
                      </mat-select-trigger>
                      <input (input)="filterPurchaseOrder($event.target.value)" placeholder="Filter"
                        class="form-control" />
                      <mat-option *ngFor="let item of purchaseOrder" [value]="item.name">
                        {{ item.name }}</mat-option>
                    </mat-select>
                  </div>
                  <div class="form-group">
                    <label>Requested Delivery Date</label>
                    <mat-select #RequestedDeliverDate (selectionChange)="onSelectionChanged($event)" [multiple]="true"
                      [disableOptionCentering]="true">
                      <mat-select-trigger>
                        {{ getOptionsText(RequestedDeliverDate) }}
                      </mat-select-trigger>
                      <input (input)="filterRequestedDeliverDate($event.target.value)" placeholder="Filter"
                        class="form-control" />
                      <mat-option *ngFor="let item of requestedDeliverDate" [value]="item.name">
                        {{ item.name }}
                      </mat-option>
                    </mat-select>
                  </div>
                  <div class="form-group">
                    <label>Order Date</label>
                    <mat-select #OrderDate (selectionChange)="onSelectionChanged($event)" [multiple]="true"
                      [disableOptionCentering]="true">
                      <mat-select-trigger>
                        {{ getOptionsText(OrderDate) }}
                      </mat-select-trigger>
                      <input (input)="filterOrderDate($event.target.value)" placeholder="Filter" class="form-control" />
                      <mat-option *ngFor="let item of orderDate" [value]="item.name">
                        {{ item.name }}
                      </mat-option>
                    </mat-select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ng-template #saveForecast>
          <h1 mat-dialog-title>What would you like to name your planner?</h1>
          <div mat-dialog-content>
            <mat-form-field appearance="outline">
              <input matInput placeholder="Enter Planner Name" [(ngModel)]="plannerName"
                [ngModelOptions]="{standalone: true}">
            </mat-form-field>
          </div>
          <div class="action-panel text-right">
            <button mat-raised-button class="btn btn-outline m-left" color="primary"
              (click)="ref.close()">Cancel</button>
            <button mat-raised-button class="btn btn-primary m-left" color="warn"
              (click)="ref.close(true)">Save</button>
          </div>

        </ng-template>
        <ng-template #commitForecast>
          <h1 mat-dialog-title>Congratulations! you have successfully committed</h1>
          <div mat-dialog-content>
          </div>
          <div class="action-panel text-right">
            <button mat-raised-button class="btn btn-outline m-left" color="primary" (click)="ref.close()">Go
              Back</button>
          </div>

        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
    <app-summary-view [gridMapped]="gridMapped" [summaryArray]="summaryArray"></app-summary-view>
    <app-adjust-order-quantity [gridMapped]="gridMapped" [adjustOrderQuantity]="adjustOrderQuantity">
    </app-adjust-order-quantity>
    <app-order-list [gridMapped]="gridMapped" [adjustOrderQuantity]="adjustOrderQuantity"></app-order-list>
  </div>
