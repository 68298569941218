import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, throwError } from 'rxjs';
import { CustomHeaderComponent } from '../goal-settings/custom-header.component';
import { ButtonRendererComponent } from '../goal-settings/button-renderer.component';
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'app-source-channel-wise-config',
  templateUrl: './source-channel-wise-config.component.html',
  styleUrls: ['./source-channel-wise-config.component.css']
})
export class SourceChannelWiseConfigComponent implements OnInit, OnDestroy {
  resetVar: boolean = true;
  afuConfig = {
    uploadAPI: {
      url: "https://example-file-upload-api"
    },
    multiple: false,
    formatsAllowed: ".xls,.xlsx",
    replaceTexts: {
      selectFileBtn: 'Select Files',
      resetBtn: 'Reset',
      uploadBtn: 'Export Data',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded !',
      afterUploadMsg_error: 'Upload Failed !'
    }
  };
  public submitted = false;
  public isEdit = false;
  public columnDefs: any = [{
    headerName: 'Year',
    field: 'year',
    pinned: 'left'
  }];
  private gridApi: any;
  public sourceHeaderTypeList = [];
  public sourceHeaderTypeListTemp = [];
  public sourceList = [];
  public sources = [];
  public source: any;
  public gridOptions: any;
  public modules = AllCommunityModules;
  public clientId = 1;
  public editSubscription: Subscription;
  public removeSubscription: Subscription;
  public sourceTypeList = [];
  public defaultList = [];
  public channelList = [];
  public channel: any;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public dialogRef: MatDialogRef<DialogSourceChannelSettingsComponent>) {
    this.gridOptions = {
      frameworkComponents: { agColumnHeader: CustomHeaderComponent, buttonRenderer: ButtonRendererComponent, },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  delete(rowData: any) {
    const row = {
      SourceMixRowId: rowData.SourceMixRow.SourceMixRowId,
      active: false,
    };
    this.spinner.show();
    if (row.SourceMixRowId) {
      this.configurationService.activateSourceMixRow(row).subscribe(res => {
        this.sourceList = this.sourceList.filter(source => row.SourceMixRowId !== source.SourceMixRow.SourceMixRowId);
        this.gridOptions.api.setRowData(this.sourceList);
        this.spinner.hide();
        this.toastr.success('Success', 'Source removed successfully');
      }, error => {
        this.spinner.hide();
      });
    } else {
      this.sourceList = this.sourceList.filter(source => rowData !== source);
      this.spinner.hide();
    }

  }
  ngOnInit() {
    this.populateList();
    this.editSubscription = this.configurationService.getEditColumnSubject().subscribe(res => {
      if (res) {
        this.openColumnDialog(true, res);
      }
    });
    this.removeSubscription = this.configurationService.getRemoveColumnSubject().subscribe(res => {
      if (res) {
        this.openRemoveColumnDialog(res);
      }
    });
  }
  ngOnDestroy() {
    this.editSubscription.unsubscribe();
    this.removeSubscription.unsubscribe();
  }
  public populateDefaultYears = async (value, isChannel) => {
    this.source = isChannel ? this.source : value;
    this.channel = isChannel ? value : this.channel;
    if (this.source && this.channel) {
      this.defaultList = [];
      this.columnDefs = [];
      const sourceHeaderList = await this.configurationService.getChannelSourceWiseHeaderList({ sourceId: this.source.sourcingId, clientId: 1, active: true, channelId: this.channel.channelId }).toPromise().catch(error => throwError(error));
      const yearList = [];
      this.sourceHeaderTypeList = sourceHeaderList;
      this.sourceHeaderTypeListTemp = this.sourceHeaderTypeList;
      this.sourceHeaderTypeListTemp = this.sourceHeaderTypeListTemp.map(sourceHeader => ({ id: sourceHeader.channelSourceWiseHeaderId, headerName: sourceHeader.name, field: sourceHeader.code, active: sourceHeader.active }));
      this.columnDefs = [{
        headerName: 'Year',
        field: 'Year',
        pinned: 'left'
      },
      {
        headerName: 'Total',
        field: 'Total',
        pinned: 'right'
      }];
      this.sourceHeaderTypeListTemp.forEach(sourceHeader => {
        this.columnDefs.push({
          ...sourceHeader, editable: true, valueSetter: function (params) {
            params.data[params.colDef.field] = Number(params.newValue);
            return true;
          }
        });
      });
      this.gridOptions.api.setColumnDefs(this.columnDefs);
      const headerList = await this.configurationService.getSourceMixHeaderList({ sourceId: this.source.sourcingId, clientId: 1, active: true, channelId: this.channel.channelId }).toPromise().catch(error => throwError(error));
      if (headerList.length > 0) {
        headerList.forEach(header => {
          const year = header && header.name ? header.name.split('-')[1] : '';
          if (year && !yearList.includes(year)) {
            yearList.push({ sourceMixHeaderId: header.sourceMixHeaderId, year: year });
          }
        });
      }
      const sourceList = await this.configurationService.getSourceMixFormList({ sourceId: this.source.sourcingId, clientId: 1, channelId: this.channel.channelId }).toPromise().catch(error => throwError(error));
      const sourceChannelWiseList = await this.configurationService.getChannelSourceWiseRowList({ sourceId: this.source.sourcingId, clientId: 1, channelId: this.channel.channelId }).toPromise().catch(error => throwError(error));
      if (sourceList && sourceList.pivotList && sourceList.pivotList.length > 0) {
        const list = [];
        this.sourceList = sourceList.pivotList;
        yearList.forEach(year => {
          let object = {};
          object['Total'] = 0;
          for (let i = 0; i < this.sourceList.length; i++) {
            const keyList = Object.keys(this.sourceList[i]).filter(key => key.substring(7, 11) === year.year);
            keyList.forEach(key => {
              if (this.sourceList[i][key]) {
                object['Total'] = object['Total'] + this.sourceList[i][key];
                object['Year'] = year.year;
                object['SourceMixHeaderId'] = year.sourceMixHeaderId;
                object['ChannelId'] = this.channel.channelId;
                object['SourceId'] = this.source.sourcingId;
                object['ClientId'] = 1;
                if (sourceChannelWiseList && sourceChannelWiseList.pivotList.length > 0) {
                  const pivotList = sourceChannelWiseList.pivotList;
                  const yearRow = pivotList.find(row => row.Year === year.year);
                  if (yearRow) {
                    delete yearRow.Year;
                    delete yearRow.Name;
                  }
                  object = {
                    ...object,
                    ...yearRow
                  };
                }
              }
            });
          }
          list.push(object);
        });
        this.defaultList = list;
        this.gridOptions.api.setRowData(this.defaultList);
      }
    }
  }
  public populateList = () => {
    this.configurationService.getAllSources({ clientId: 1 }).subscribe(res => {
      this.sources = res.data;
    });
    this.configurationService.getAllChannels({ clientId: 1 }).subscribe(res => {
      this.channelList = res.data;
    });
  }
  populateForm(event): void {
    if (event && event.data) {
      this.isEdit = true;
      const dataToPopulate = event.data;
    }
  }
  public onSubmit = () => {
    this.submitted = true;
    let model = this.gridOptions.rowData;
    const columnList = this.columnDefs.filter(col => col.id).map(res => ({ ChannelSourceWiseHeaderId: res.id, ChannelId: this.channel.channelId, Name: res.headerName, SourceId: this.source.sourcingId, ClientId: this.clientId, Code: res.field, Active: res.active || true }));
    model = {
      listOfChannelSourceWiseHeader: columnList,
      ChannelSourceWiseRowList: model
    };
    this.spinner.show();
    this.configurationService.addChannelSourceWiseRow(model).subscribe(res => {
      this.spinner.hide();
      this.toastr.success('Success', 'Source saved successfully');
    }, error => {
      this.spinner.hide();
    });
  }
  Cancel_Click() {
  }

  DocUpload($event) {
    console.log("Apply ACtion on Excel here ...");

  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  openColumnDialog(isEdit?: boolean, column?: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogRef = this.dialog.open(DialogSourceChannelSettingsComponent, {
      width: '250px',
      data: { ...column, isEdit: isEdit, columnDefs: this.columnDefs },
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.oldColumn) {
          this.updateColumnDef(result);
        } else {
          this.addColumnDef(result.headerName);
        }
        this.gridOptions.api.setColumnDefs(this.columnDefs);
      }
    });
  }
  public addColumnDef = (headerName: string) => {
    const model = {
      name: headerName,
      ClientId: this.clientId,
      SourceId: this.source.sourcingId,
      ChannelId: this.channel.channelId
    };
    this.spinner.show();
    this.configurationService.addChannelSourceWiseHeader(model).subscribe(res => {
      if (res) {
        const newModel = { id: res.channelSourceWiseHeaderId || res.ChannelSourceWiseHeaderId, headerName: res.name, field: res.code, active: res.active };
        this.columnDefs.push({ ...newModel, editable: true });
        this.sourceList.forEach(source => {
          source[res.code] = null;
        });
        this.gridOptions.api.setColumnDefs(this.columnDefs);
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
    });
  }
  public updateColumnDef = (result: any) => {
    const model = {
      Name: result.headerName,
      Code: result.field,
      Active: result.active,
      ClientId: this.clientId,
      SourceMixHeaderId: result.id,
      SourcingId: this.source.sourcingId,
      ChannelId: this.channel.channelId
    };
    this.spinner.show();
    this.configurationService.updateChannelSourceWiseHeader(model).subscribe(res => {
      if (res) {
        this.columnDefs.forEach(col => {
          if (col.headerName === result.oldColumn) {
            col.headerName = result.headerName;
            col.field = result.field;
          }
        });
        this.gridOptions.api.setColumnDefs(this.columnDefs);
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }
  openRemoveColumnDialog(columnToRemove?: string): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: columnToRemove
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sourceHeaderTypeListTemp = this.columnDefs;
        this.removeColumn(result);
      }
    });
  }
  public removeColumn = (column: any) => {
    if (column) {
      const model = {
        channelSourceWiseHeaderId: column.id,
        active: false
      };
      this.spinner.show();
      this.configurationService.activateChannelSourceWiseHeader(model).subscribe(res => {
        if (res) {
          this.columnDefs = this.columnDefs.filter(col => col.id !== column.id);
          if (this.gridOptions.rowData.length > 0) {
            this.gridOptions.rowData.forEach(row => {
              if (column && row && column.field in row) {
                delete row[column.field];
              }
            });
            this.gridOptions.api.setRowData(this.gridOptions.rowData);
          }
          this.gridOptions.api.setColumnDefs(this.columnDefs);
          this.spinner.hide();
        }
      }, error => {
        this.spinner.hide();
      });
    }
  }
  openRemoveSourceDialog(sourceToRemove: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: { headerName: sourceToRemove.rowData.SourceMixRow.name || sourceToRemove.rowData.SourceMixRow.Name }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.delete(sourceToRemove.rowData);
      }
    });
  }
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { element } from 'protractor';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'header-name-dialog',
  templateUrl: 'header-name-dialog.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DialogSourceChannelSettingsComponent {
  public headerName = '';
  public showErrorMessage = false;
  monthList = [
    { value: 'Jan', name: 'January' },
    { value: 'Feb', name: 'February' },
    { value: 'Mar', name: 'March' },
    { value: 'Apr', name: 'April' },
    { value: 'May', name: 'May' },
    { value: 'Jun', name: 'June' },
    { value: 'Jul', name: 'July' },
    { value: 'Aug', name: 'August' },
    { value: 'Sep', name: 'September' },
    { value: 'Oct', name: 'October' },
    { value: 'Nov', name: 'November' },
    { value: 'Dec', name: 'December' }];
  constructor(
    public dialogRef: MatDialogRef<DialogSourceChannelSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data && this.data.isEdit) {
      const headerName = this.data.headerName;
      const date = _moment(`${headerName.split('-')[1]}-${this.monthList.findIndex(res => res === headerName.split('-')[0]) + 1}-01`);
    }
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit() {
    if (this.headerName) {
      if (!this.data.isEdit) {
        this.data.field = this.headerName.replace(/[^A-Za-z0-9 ]/ig, '');
      }
      const newColumn = {
        ...this.data,
        headerName: this.headerName,
        oldColumn: this.data.displayName,
      };
      const columnIfExists = this.data.columnDefs.find(column => column.headerName.toLowerCase() === this.headerName.toLowerCase());
      if (!columnIfExists) {
        this.showErrorMessage = false;
        this.dialogRef.close(newColumn);
      } else {
        this.showErrorMessage = true;
      }
    }
  }
  public getMonthYearValue = (date: any) => {
    return `${this.monthList[date.month()]}-${date.year()}`;
  }
}
