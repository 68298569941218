import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-data-management',
  templateUrl: './file-management.component.html',
  styleUrls: ['./file-management.component.scss']
})
export class FileManagementComponent implements OnInit {

  showDataModule = false;
  fileObj: any;

  newVersionSaved = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  loadDataModule(e) {
    this.showDataModule = false;
    this.fileObj = e;
    setTimeout(() => {
      this.showDataModule = true;
    }, 300);
  }

  updateFilesModule(e) {
    if (!e) {
      this.showDataModule = false;
      this.fileObj = null;
    } else {
      this.newVersionSaved.next(e);
    }
  }

}
