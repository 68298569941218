<div class="">
  <div class="item-search-wrapper">
    <form action="" class="form-fields show-search">
      <div class="form-group">
        <mat-form-field floatLabel='never'>
          <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </form>
    <div class="item-search-btn">
      <!-- <img src="../../assets/images/add-icon.svg" alt=""> -->
      <img src="../../assets/images/excel.svg" alt="" (click)="exportExcel()">
      <button class="btn btn-primary" *ngIf="createPermission" (click)="openPromoForm(null)">Add Promo</button>
    </div>
  </div>
  <div class="all-show-grid items-wrapper-grid">
    <ag-grid-angular class="ag-theme-material" [gridOptions]="gridOptions" [defaultColDef]="defaultColDef"
      [pagination]="true" [headerHeight]="headerHeight" [rowData]="promotionList"
      [paginationPageSize]="paginationPageSize" [columnDefs]="columnDefs" (gridReady)="onGridReady($event)"
      [modules]="modules" #grid>
    </ag-grid-angular>
  </div>
  <app-facebook-promotion-form [promotionList] = "promotionList" (close)="closePanel()" [isEdit] = "isEdit" (updateList)="updateList()" *ngIf="showPromoForm"></app-facebook-promotion-form>
</div>
