<div class="d-flex align-items-center justify-content-between" style="background: #f4f4f4; padding: 10px;">
    <div class="d-flex align-items-center">
        <h5 style="min-width: 100px;">Item Details
            <span matBadge="40" matBadgeOverlap="false" matBadgeColor="accent"></span>
        </h5>
    </div>

    <div class="d-flex align-items-center">
        <button matTooltip="Show/Hide Columns" class="grid-bar-icon ml-3" mat-icon-button color="primary">
            <i class="material-icons">
            ballot
            </i>
        </button>
        <button matTooltip="Export" class="grid-bar-icon ml-3" mat-icon-button color="primary">
            <i class="material-icons">
            insert_drive_file
            </i>
        </button>

        <button class="import-export-button ml-3" mat-flat-button color="accent"
            [matMenuTriggerFor]="actionMenu">Actions</button>
        <mat-menu #actionMenu="matMenu">
            <button mat-menu-item>Export to Excel File</button>
            <button mat-menu-item>Export to Text File</button>
            <button mat-menu-item>Export to CSV File</button>
        </mat-menu>
    </div>
</div>

<div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material" [gridOptions]="gridOptions" [rowModelType]="rowModelType"
        [pagination]="true" [paginationPageSize]="paginationPageSize" [columnDefs]="columnDefs" [modules]="modules"
        [suppressMenuHide]="true" suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
        [rowSelection]="rowSelection" [rowData]="itemsGridData" #itemsGrid>
    </ag-grid-angular>
</div>