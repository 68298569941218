<div class="container-fluid" style="margin-top: 15px; min-height: 500px;">
    <form [formGroup]="addSeasonForm">
        <div class="row">
            <div class="col-md-5 d-flex align-items-center justify-content-between">
                <strong>Season Name</strong>
                <mat-form-field appearance="fill">
                    <input matInput formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-md-3 d-flex align-items-center justify-content-between">
                <strong>Start Date</strong>
                <retail-calendar [events]="events" class="w-100" [selectedDate]="addSeasonForm.get('startDate').value" (dateChanged)="addSeasonForm.get('startDate').setValue($event)"></retail-calendar>
            </div>
            <div class="col-md-3 d-flex align-items-center justify-content-between">
                <strong>End Date</strong>
                <retail-calendar [events]="events" class="w-100" [selectedDate]="addSeasonForm.get('endDate').value" (dateChanged)="addSeasonForm.get('endDate').setValue($event)"></retail-calendar>
            </div>
            <div class="col-md-1 d-flex align-items-center">
                <button mat-raised-button color="accent" class="mx-2" [disabled]="addSeasonForm.invalid" (click)="addSeason()">SUBMIT
                </button>
            </div>
        </div>
    </form>
    <hr class="theme-hr mt-2" />
    
    <div class="seasonsData mt-3 mx-2" *ngFor="let item of seasonsArray; let seasonIndex = index;">
        <div class="d-flex align-items-center headerData">
            <h6 class="fieldName mb-0"><strong>{{item.name}}</strong></h6>
            <h6 class="fieldName ml-3 mb-0"><strong>{{item.startDate | date:"MM/dd/yy"}}</strong></h6>
            <h6 class="fieldName ml-3 mb-0"><strong>{{item.endDate | date:"MM/dd/yy"}}</strong></h6>
            <button (click)="addDrop(seasonIndex)" class="mx-2" mat-mini-fab color="accent" matTooltip="Add Drop">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div style="max-height: 50vh; overflow-y: auto;">
            <div class="d-flex align-items-center rowData mb-2" *ngFor="let dropItem of item.drops; let i=index">
                <h6 class="mb-0 fieldName"><strong>Drop {{i+1}}:</strong></h6>
                <h6 class="mb-0 fieldName ml-3">{{dropItem.name}}</h6>
                <h6 class="mb-0 fieldName ml-3">{{dropItem.date | date:"MM/dd/yy"}}</h6>
            </div>
        </div>
        <hr class="theme-hr mt-2" />
    </div>
</div>
