export const yearlyData = [
    {
        name: 'Revenue',
        fy2020: 10000000,
        fy2021: 12000000,
        fy2022: 15000000
    },
    {
        name: 'YoY Sales',
        fy2020: '15',
        fy2021: '20',
        fy2022: '25'
    },
    {
        name: '2 Year CAGR',
        fy2020: '13',
        fy2021: '18',
        fy2022: '20'
    },
    {
        name: '3 Year CAGR',
        fy2020: '10',
        fy2021: '16',
        fy2022: '15'
    },
    {
        name: 'SS Growth',
        fy2020: '10',
        fy2021: '12',
        fy2022: '15'
    },
    {
        name: 'SS Revenue',
        fy2020: 8000000,
        fy2021: 9600000,
        fy2022: 13800000
    },
    {
        name: 'NS Revenue',
        fy2020: 2000000,
        fy2021: 2400000,
        fy2022: 1200000
    },
    {
        name: 'GMROI %',
        fy2020: '152.0',
        fy2021: '160.0',
        fy2022: '165.0'
    }
];

export const quarterlyData = [
    {
        year: 'FY 2022',
        measure: 'Revenue $$',
        totals: 15000000,
        q1: 4500000,
        q2: 4140000,
        q3: 4320000,
        q4: 5040000,
        editable: true
    },
    {
        year: '',
        measure: 'Revenue Cont. %',
        totals: 100,
        q1: 25,
        q2: 23,
        q3: 24,
        q4: 28,
        editable: true
    },
    {
        year: '',
        measure: 'TY vs Adj. LY %',
        totals: 50,
        q1: 44,
        q2: 50,
        q3: 50,
        q4: 56,
        editable: true
    },
    {
        year: '',
        measure: 'Gross Margin %',
        totals: 56,
        q1: 56,
        q2: 61,
        q3: 56,
        q4: 52,
        editable: true
    },

    {
        year: 'FY 2021',
        measure: 'Revenue $$',
        totals: 12000000,
        q1: 3120000,
        q2: 2760000,
        q3: 2880000,
        q4: 3240000
    },
    {
        year: '',
        measure: 'Revenue Cont. %',
        totals: 100,
        q1: 26,
        q2: 23,
        q3: 24,
        q4: 27
    },
    {
        year: '',
        measure: 'TY vs Adj. LY %',
        totals: 20,
        q1: 25,
        q2: 10,
        q3: 15,
        q4: 30
    },
    {
        year: '',
        measure: 'Gross Margin %',
        totals: 54,
        q1: 56,
        q2: 55,
        q3: 52,
        q4: 53
    },

    {
        year: 'FY 2020',
        measure: 'Revenue $$',
        totals: 10000000,
        q1: 2500000,
        q2: 2500000,
        q3: 2500000,
        q4: 2500000
    },
    {
        year: '',
        measure: 'Revenue Cont. %',
        totals: 100,
        q1: 25,
        q2: 25,
        q3: 25,
        q4: 25
    },
    {
        year: '',
        measure: 'Gross Margin %',
        totals: 52,
        q1: 53,
        q2: 53,
        q3: 50,
        q4: 52
    }
];

export const planManagaerGridData = [
    {
        time: 'FY2022',
        plan: 'Plan 1',
        channel: 'Retail',
        brand: 'Nike',
        group: 'Men',
        createdBy: 'David',
        status: 'Submit',
        approvedBy: '-'
    },
    {
        time: 'FY2022',
        plan: 'Plan 2',
        channel: 'Wholescale',
        brand: 'Nike',
        group: 'Women',
        createdBy: 'John',
        status: 'Approved',
        approvedBy: 'Richard'
    },
    {
        time: 'FY2022',
        plan: 'Plan 3',
        channel: 'Online',
        brand: 'Nike',
        group: 'All',
        createdBy: 'Mark',
        status: 'Workbook',
        approvedBy: '-'
    }
];

export const topDownGridData = [
    {
        measures: 'TY TD Revenue $$',
        totals: '15000000',
        jan: '1220000',
        feb: '1097000',
        mar: '1218000',
        apr: '1097000',
        may: '1218000',
        jun: '1340000',
        jul: '1097000',
        aug: '1218000',
        sep: '1340000',
        oct: '1097000',
        nov: '1340000',
        dec: '1218000',
        editable: true
    },
    {
        measures: 'TY TD Revenue Cont. %',
        totals: '100',
        jan: '8',
        feb: '8',
        mar: '8',
        apr: '9',
        may: '8',
        jun: '9',
        jul: '8',
        aug: '8',
        sep: '9',
        oct: '8',
        nov: '9',
        dec: '8',
        editable: true
    },
    {
        measures: 'TY vs Adj. LY %',
        totals: '22',
        jan: '22',
        feb: '22',
        mar: '22',
        apr: '22',
        may: '22',
        jun: '22',
        jul: '22',
        aug: '22',
        sep: '22',
        oct: '22',
        nov: '22',
        dec: '22',
        editable: false
    },
    {
        measures: 'TY Sale Units',
        totals: '13612',
        jan: '1743',
        feb: '813',
        mar: '1107',
        apr: '1219',
        may: '1107',
        jun: '1787',
        jul: '914',
        aug: '1015',
        sep: '1047',
        oct: '1097',
        nov: '893',
        dec: '870',
        editable: false
    },
    {
        measures: 'TY ASP',
        totals: '1065',
        jan: '700',
        feb: '1350',
        mar: '1100',
        apr: '900',
        may: '1100',
        jun: '750',
        jul: '1200',
        aug: '1200',
        sep: '1280',
        oct: '1000',
        nov: '1500',
        dec: '1400',
        editable: true
    },
    {
        measures: 'TY TD Revenue @ Cost',
        totals: '6484930',
        jan: '671000',
        feb: '493650',
        mar: '499380',
        apr: '438800',
        may: '523740',
        jun: '643200',
        jul: '482680',
        aug: '535920',
        sep: '616400',
        oct: '438800',
        nov: '629800',
        dec: '511560',
        editable: false
    },
    {
        measures: 'TY TD Gross Margin %',
        totals: '55',
        jan: '45',
        feb: '55',
        mar: '59',
        apr: '60',
        may: '57',
        jun: '52',
        jul: '56',
        aug: '56',
        sep: '54',
        oct: '60',
        nov: '53',
        dec: '58',
        editable: true
    },
    {
        measures: 'TY Inventory @ Cost',
        totals: '',
        jan: '1859000',
        feb: '2165350',
        mar: '1865970',
        apr: '2727170',
        may: '2203430',
        jun: '1560230',
        jul: '2277550',
        aug: '1741630',
        sep: '2125230',
        oct: '2686430',
        nov: '2556630',
        dec: '2045070',
        editable: false
    },
    {
        measures: 'TY Inventory Units',
        totals: '',
        jan: '2716',
        feb: '3681',
        mar: '3573',
        apr: '5605',
        may: '4497',
        jun: '2711',
        jul: '4555',
        aug: '3540',
        sep: '4691',
        oct: '5867',
        nov: '6085',
        dec: '5215',
        editable: false
    },
    {
        measures: 'TY OTB @ Cost',
        totals: '7000000',
        jan: '1000000',
        feb: '800000',
        mar: '200000',
        apr: '1300000',
        may: '',
        jun: '',
        jul: '1200000',
        aug: '',
        sep: '1000000',
        oct: '1000000',
        nov: '500000',
        dec: '',
        editable: true
    },
    {
        measures: 'TY OTB Units',
        totals: '16641',
        jan: '2273',
        feb: '1778',
        mar: '1000',
        apr: '3250',
        may: '',
        jun: '',
        jul: '2759',
        aug: '',
        sep: '2198',
        oct: '2273',
        nov: '1111',
        dec: '',
        editable: false
    },
    {
        measures: 'TY OTB AUC',
        totals: '421',
        jan: '440',
        feb: '450',
        mar: '200',
        apr: '400',
        may: '',
        jun: '',
        jul: '435',
        aug: '',
        sep: '455',
        oct: '440',
        nov: '450',
        dec: '',
        editable: true
    },
    {
        measures: 'TY Forward Covers @ Cost',
        totals: '',
        jan: '117',
        feb: '133',
        mar: '105',
        apr: '149',
        may: '119',
        jun: '86',
        jul: '129',
        aug: '93',
        sep: '121',
        oct: '129',
        nov: '128',
        dec: '101',
        editable: false
    },
    {
        measures: 'TY Forward Covers Units',
        totals: '',
        jan: '78',
        feb: '96',
        mar: '78',
        apr: '132',
        may: '109',
        jun: '82',
        jul: '130',
        aug: '105',
        sep: '148',
        oct: '143',
        nov: '149',
        dec: '116',
        editable: false
    },
    {
        measures: 'TY ITOs @ Cost',
        totals: '3.01',
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
        editable: false
    },
    {
        measures: 'TY ITOs @ Units',
        totals: '3.10',
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
        editable: false
    },
    {
        measures: 'Adj. LY Revenue $$',
        totals: '11900000',
        jan: '1000000',
        feb: '900000',
        mar: '1000000',
        apr: '900000',
        may: '1000000',
        jun: '1100000',
        jul: '900000',
        aug: '1000000',
        sep: '1100000',
        oct: '900000',
        nov: '1100000',
        dec: '1000000',
        editable: false
    },
    {
        measures: 'Adj. LY Units',
        totals: '929',
        jan: '1504',
        feb: '702',
        mar: '957',
        apr: '1053',
        may: '957',
        jun: '1544',
        jul: '789',
        aug: '877',
        sep: '905',
        oct: '947',
        nov: '772',
        dec: '752',
        editable: false
    },
    {
        measures: 'Adj. LY ASP',
        totals: '12806',
        jan: '665',
        feb: '1283',
        mar: '1045',
        apr: '855',
        may: '1045',
        jun: '713',
        jul: '1140',
        aug: '1140',
        sep: '1216',
        oct: '950',
        nov: '1425',
        dec: '1330',
        editable: false
    },
    {
        measures: 'Adj. LY Revenue Cont. %',
        totals: '',
        jan: '8',
        feb: '8',
        mar: '8',
        apr: '9',
        may: '8',
        jun: '9',
        jul: '8',
        aug: '8',
        sep: '9',
        oct: '8',
        nov: '9',
        dec: '8',
        editable: false
    }
];

export const tiersData = [
    {
        class: 'Formal',
        tiers: 'Good',
        fy2022: 6000000,
        fy2021: 9000000,
        growth: -33,
        fy2022Cont: 27,
        fy2021Cont: 47
    },
    {
        class: '',
        tiers: 'Better',
        fy2022: 10000000,
        fy2021: 2000000,
        growth: 400,
        fy2022Cont: 45,
        fy2021Cont: 11
    },
    {
        class: '',
        tiers: 'Best',
        fy2022: 6000000,
        fy2021: 8000000,
        growth: -25,
        fy2022Cont: 27,
        fy2021Cont: 42
    },
    {
        class: '',
        totals: true,
        tiers: '',
        fy2022: 22000000,
        fy2021: 19000000,
        growth: 16,
        fy2022Cont: 100,
        fy2021Cont: 100
    },
    {
        class: 'Casual',
        tiers: 'Good',
        fy2022: 8000000,
        fy2021: 9000000,
        growth: -11,
        fy2022Cont: 28,
        fy2021Cont: 45
    },
    {
        class: '',
        tiers: 'Better',
        fy2022: 9000000,
        fy2021: 9000000,
        growth: 0,
        fy2022Cont: 31,
        fy2021Cont: 45
    },
    {
        class: '',
        tiers: 'Best',
        fy2022: 12000000,
        fy2021: 2000000,
        growth: 500,
        fy2022Cont: 41,
        fy2021Cont: 10
    },
    {
        class: '',
        totals: true,
        tiers: '',
        fy2022: 29000000,
        fy2021: 20000000,
        growth: 45,
        fy2022Cont: 100,
        fy2021Cont: 100
    },
    {
        class: 'Sports/Athleisure',
        tiers: 'Good',
        fy2022: 5000000,
        fy2021: 80000000,
        growth: -38,
        fy2022Cont: 23,
        fy2021Cont: 33
    },
    {
        class: '',
        tiers: 'Better',
        fy2022: 6000000,
        fy2021: 10000000,
        growth: -40,
        fy2022Cont: 27,
        fy2021Cont: 42
    },
    {
        class: '',
        tiers: 'Best',
        fy2022: 11000000,
        fy2021: 6000000,
        growth: 83,
        fy2022Cont: 50,
        fy2021Cont: 25
    },
    {
        class: '',
        totals: true,
        tiers: '',
        fy2022: 22000000,
        fy2021: 24000000,
        growth: -8,
        fy2022Cont: 100,
        fy2021Cont: 100
    },
    {
        class: 'Sandal/Slippers',
        tiers: 'Good',
        fy2022: 4000000,
        fy2021: 10000000,
        growth: -60,
        fy2022Cont: 18,
        fy2021Cont: 45
    },
    {
        class: '',
        tiers: 'Better',
        fy2022: 12000000,
        fy2021: 7000000,
        growth: 71,
        fy2022Cont: 55,
        fy2021Cont: 32
    },
    {
        class: '',
        tiers: 'Best',
        fy2022: 6000000,
        fy2021: 5000000,
        growth: 20,
        fy2022Cont: 27,
        fy2021Cont: 23
    },
    {
        class: '',
        totals: true,
        tiers: '',
        fy2022: 22000000,
        fy2021: 22000000,
        growth: 0,
        fy2022Cont: 100,
        fy2021Cont: 100
    },
    {
        class: 'Accessories',
        tiers: 'Good',
        fy2022: 2000000,
        fy2021: 11000000,
        growth: -82,
        fy2022Cont: 14,
        fy2021Cont: 35
    },
    {
        class: '',
        tiers: 'Better',
        fy2022: 10000000,
        fy2021: 9000000,
        growth: 11,
        fy2022Cont: 71,
        fy2021Cont: 29
    },
    {
        class: '',
        tiers: 'Best',
        fy2022: 2000000,
        fy2021: 11000000,
        growth: -82,
        fy2022Cont: 14,
        fy2021Cont: 35
    },
    {
        class: '',
        totals: true,
        tiers: '',
        fy2022: 14000000,
        fy2021: 31000000,
        growth: -55,
        fy2022Cont: 100,
        fy2021Cont: 100
    }
];

export const tiersDataSummary = [
    {
        class: 'Formal',
        target: 25000000,
        plan: 22000000,
        var: 3000000
    },
    {
        class: 'Casual',
        target: 29000000,
        plan: 29000000,
        var: null
    },
    {
        class: 'Sports/Athleisure',
        target: 20000000,
        plan: 22000000,
        var: 2000000
    },
    {
        class: 'Sandal/Slippers',
        target: 21000000,
        plan: 22000000,
        var: 1000000
    },
    {
        class: 'Accessories',
        target: 15000000,
        plan: 14000000,
        var: 1000000
    },
    {
        class: '',
        totals: true,
        target: 110000000,
        plan: 109000000,
        var: 1000000
    }
];
