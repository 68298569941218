<!-- Main Content area -->
<mat-accordion class="fb-forecast-accordion item-wrapper">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
      <mat-panel-title>
        Data View
      </mat-panel-title>

    </mat-expansion-panel-header>
    <div class="data-wrapper btn-wrapper">
      <img src="assets/images/graph.svg" alt="" (click)="openMesuresDialog()">
      <mat-select placeholder="Export/Import" class="form-control">
        <mat-option>Export/Import</mat-option>
        <mat-option value="Export Current View">Export Current View</mat-option>
        <mat-option value="Export template">Export template</mat-option>
        <mat-option value="Import">Import</mat-option>
      </mat-select>
      <button class="btn btn-primary" (click)="mapGridList(false)">Save or Commit</button>
    </div>

    <div class="container-fluid" id="fb-forecast-grid">

      <div>
        <app-fb-forecast-scrolling [productList]="selectedProducts" [productTree]="productTree">
        </app-fb-forecast-scrolling>
        <table *ngIf="yearListGrid && yearListGrid.length > 0" id="forcast-grid" class="table">
          <tbody>
            <td style="padding: 0px !important">
              <tr class="td-padding-none">
                <td>
              <tr style="height: 45px !important; margin-bottom: 2px;">
                <label style="height: 45px !important; ">&nbsp;</label>
              </tr>
              <ng-container *ngFor="let label of leftGroupList">
                <tr [attr.rowspan]="label.rowspan" style="
                  writing-mode: vertical-rl;
                  text-orientation: mixed;
                  text-align: center;
                  align-items: center;
                  display: flex;
                  font-size: large;
                  " [ngStyle]="{
                    background: label.background,
                    height: label.rowspan * 42 + 'px'
                  }" *ngIf="label.rowspan > 0">
                  <label [ngStyle]="{
                    height: label.rowspan * 42 + 'px'
                  }" style="
                   padding-right: 12px;
                  color: #fff;
                  min-width: 50px;
                  max-width: 50px;
                  text-align: center;
                  font-size: 14px;
                  font-weight: bold;
    transform: rotate(
180deg
 );
                  ">{{ label.measureName }}</label>
                </tr>
                <tr style="height: 40px !important; margin-bottom: 2px;">
                  <label style="height: 40px !important; margin-bottom: 2px;">&nbsp;</label>
                </tr>
              </ng-container>
            </td>
            </tr>
            </td>
            <td style="padding: 0px !important">
              <tr class="td-padding">
                <td>
              <tr style="height: 45px !important; margin-bottom: 2px;">
                <label style="height: 45px !important; ">&nbsp;</label>
              </tr>
              <ng-container *ngFor="let label of measureList; let i = index">
                <tr [ngStyle]="{ background: 'rgb(238, 238, 238)' }" *ngIf="label.checked" style="height: 40px;
              display: flex;
              align-items: center; margin-bottom: 2px;   padding-left: 15px;     margin-right: 2px;">
                  <label *ngIf="!label.isDropdown" style="min-width: 300px; max-width: 300px">{{ label.measureName
                    }}</label>
                  <mat-select [(ngModel)]="label.measureName" [ngModelOptions]="{ standalone: true }"
                    style="height: 40px" style="min-width: 300px; max-width: 300px" *ngIf="label.isDropdown">
                    <mat-option class="not-empty-select" *ngFor="
              let res of methodList;
              let i = index
              " [value]="res">
                      {{ res }}
                    </mat-option>
                  </mat-select>
                </tr>
                <tr *ngIf="checkIfToShow(i)" style="height: 40px !important; margin-bottom: 2px;">
                  <label [id]="i" style="height: 40px !important; margin-bottom: 2px;">&nbsp;</label>
                </tr>
              </ng-container>
            </td>
            </tr>
            </td>
            <td style="overflow-x: scroll; width: 100%; padding: 0px !important"
              [ngStyle]="{ 'max-width': getWidth() }">
              <tr class="td-padding">
                <td *ngFor="let year of gridData" style="text-align: center" align="center">
              <tr style="height: 45px;
              display: block;">
                <mat-select [placeholder]="year.title" #select (selectionChange)="showValues($event, year, select)">
                  <mat-select-trigger>
                    {{year.title}}
                  </mat-select-trigger>
                  <mat-option *ngFor="let option of timeframeList" [value]="option.headerName">{{option.headerName}}
                  </mat-option>
                </mat-select>
              </tr>
              <ng-container [ngTemplateOutlet]="yearColumn" [ngTemplateOutletContext]="{week:year}">
              </ng-container>
            </td>
            </tr>
            </td>
          </tbody>
        </table>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<!-- Month Cummulated Column Start-->
<ng-template #otherColumn let-week="week">
  <td class="input-fields" style="text-align: center;">
    <tr> <label style="height:35px !important">&nbsp;</label></tr>
    <!-- Algo -->
    <ng-container *ngFor="let measure of measureList; let i=index">
      <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList(measure.code)">
        <input type="text" [disabled]="measure.type === 1" [ngModel]="week[measure.code] | number : '1.2-2'"
          (ngModelChange)="week[measure.code] = +$event" [ngModelOptions]="{standalone: true}" [id]="measure.code">
      </tr>
    </ng-container>
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('BaselineSTForecast')">

      <input type="text" [disabled]="false" [ngModel]="week.Algo.BaselineSTForecast | number : '1.2-2'"
        (ngModelChange)="week.Algo.BaselineSTForecast = +$event" [ngModelOptions]="{standalone: true}"
        id="BaselineSTForecast">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoPromoUnits')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoPromoUnits | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoPromoUnits = +$event" [ngModelOptions]="{standalone: true}" id="AlgoPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoTotalSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="AlgoTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoConstrainedSTFcst')">

      <input type="text" [ngModel]="week.Algo.AlgoConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoConstrainedSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="AlgoConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoDPTotalSTFcst')">

      <input [ngModel]="week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst | percent" [ngModelOptions]="{standalone: true}"
        id="AlgoTotalSTFcstvartoDPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoCOTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoCOTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Algo ST')"> <label>&nbsp;</label></tr> -->
    <!-- Algo -->

    <!-- DemandPlanning -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPBaselineSTFcst')">

      <input type="text" [disabled]="false" [ngModel]="week.DemandPlanning.DPBaselineSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPBaselineSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="DPBaselineSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPManualFcstAdjustment')">

      <input [disabled]="false" type="text" [ngModel]="week.DemandPlanning.DPManualFcstAdjustment | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPManualFcstAdjustment = +$event" [ngModelOptions]="{standalone: true}"
        (change)="DPManualFcstAdjustmentUpdated(week)" id="DPManualFcstAdjustment">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPPromoUnits')">

      <input [disabled]="false" type="text" (change)="DPPromoUnitsUpdated(week)"
        [ngModel]="week.DemandPlanning.DPPromoUnits | number : '1.2-2'" [ngModelOptions]="{standalone: true}"
        id="DPPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPTotalSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="DPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPConstrainedSTFcst')">

      <input type="text" [ngModel]="week.DemandPlanning.DPConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPConstrainedSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="DPConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.DemandPlanning.DPComments"
        [ngModelOptions]="{standalone: true}" id="DPComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoCOTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoCOTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoCOTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoAlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoAlgoTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoAlgoTotalSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Demand Planning ST')"> <label>&nbsp;</label></tr> -->
    <!-- DemandPlanning -->

    <!-- ChannelOps -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COBaselineSTForecast')">
      <input type="text" [disabled]="false" [ngModel]="week.ChannelOps.COBaselineSTForecast | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COBaselineSTForecast = +$event" [ngModelOptions]="{standalone: true}"
        id="COBaselineSTForecast">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COManualFcstAdjustment')">

      <input [disabled]="false" type="text" [ngModel]="week.ChannelOps.COManualFcstAdjustment | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COManualFcstAdjustment = +$event" [ngModelOptions]="{standalone: true}"
        (change)="COManualFcstAdjustmentUpdated(week)" id="COManualFcstAdjustment">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COPromoUnits')">

      <input [disabled]="false" type="text" (change)="COPromoUnitsUpdated(week)"
        [ngModel]="week.ChannelOps.COPromoUnits | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COPromoUnits = +$event" [ngModelOptions]="{standalone: true}"
        id="COPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COTotalSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="COTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COConstrainedSTFcst')">

      <input type="text" [ngModel]="week.ChannelOps.COConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COConstrainedSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="COConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.ChannelOps.COComments"
        [ngModelOptions]="{standalone: true}" id="COComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoDPTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoDPTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoDPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoAlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoAlgoTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoAlgoTotalSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Channel Ops ST')"> <label>&nbsp;</label></tr> -->

    <!-- ChannelOps -->

    <!-- CurrentSellThrough -->
    <!-- <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerSTFcst')">

      <input [disabled]="false" type="text" (change)="CustomerSTFcstUpdated(week)"
        [ngModel]="week.CurrentSellThrough.CustomerSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.CustomerSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSTFcst')">

      <input [disabled]="false" type="text" (change)="ConsensusSTFcstUpdated(week)"
        [ngModel]="week.CurrentSellThrough.ConsensusSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="ConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvartoCustomerSTFcst')">

      <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst = +$event"
        [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvartoCustomerSTFcst">

    </tr> -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcst')">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSTFcst')">

    </tr> -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvartoConsensusSTFcst')">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvartoCustomerSTFcst')">

    </tr> -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ProjectedLostSales')">

      <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ProjectedLostSales | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ProjectedLostSales = +$event" [ngModelOptions]="{standalone: true}"
        id="ProjectedLostSales">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualST')">

      <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.ActualST | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ActualST = +$event" [ngModelOptions]="{standalone: true}"
        id="ActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('WeeklyTrend')">

      <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.WeeklyTrend | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.WeeklyTrend = +$event" [ngModelOptions]="{standalone: true}"
        id="WeeklyTrend">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('PromoIndicator')">

      <input type="text" [disabled]="false" [(ngModel)]="week.CurrentSellThrough.PromoIndicator"
        [ngModelOptions]="{standalone: true}" id="PromoIndicator">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CPFRComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.CurrentSellThrough.CPFRComments"
        [ngModelOptions]="{standalone: true}" id="CPFRComments">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerInStockEstimate')">

      <input [disabled]="false" type="text" [ngModel]="week.CurrentSellThrough.CustomerInStockEstimate | percent"
        [ngModelOptions]="{standalone: true}" id="CustomerInStockEstimate">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstUnits')">

      <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstUnits | number:'1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ActualVartoConsensusFcstUnits = +$event"
        [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstPst')">

      <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstPst | percent"
        [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstPst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative ST')"> <label>&nbsp;</label></tr> -->

    <!-- CurrentSellThrough -->

    <!-- LastYearSales -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualST')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYActualST | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYActualST = +$event" [ngModelOptions]="{standalone: true}" id="LYActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYPromoIndicator')">

      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYPromoIndicator"
        [ngModelOptions]="{standalone: true}" id="LYPromoIndicator">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCOComments')">

      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYCOComments"
        [ngModelOptions]="{standalone: true}" id="LYCOComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCustomerInStockEstimate')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYCustomerInStockEstimate | percent"
        [ngModelOptions]="{standalone: true}" id="LYCustomerInStockEstimate">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYConsensusFcst')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYConsensusFcst | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYConsensusFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="LYConsensusFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarPst')">

      <input type="text" [disabled]="true" [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarPst | percent"
        [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarPst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarUnits')">

      <input type="text" [disabled]="true"
        [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarUnits | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYActualtoConsensusFcstVarUnits = +$event"
        [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarUnits">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Last Year ST')"> <label>&nbsp;</label></tr> -->

    <!-- LastYearSales -->

    <!-- StAccuracy -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.CustomerSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="CustomerSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.AlgoTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.COTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.DPTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvsActualST">

    </tr> -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvsActualST')">

    </tr>
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConstrainedSTFcstvsActualST | percent"
      [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvsActualST">

    </tr> -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvsActualST')">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConsensusSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvsActualST">

    </tr>
    <tr *ngIf="checkIfToShowColumn('ST Accuracy')"> <label>&nbsp;</label></tr> -->

    <!-- StAccuracy -->

    <!-- SellIn -->
    <!-- <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerRequestedSellInReceiveWk')">

      <input [disabled]="false" type="text" (change)="CustomerRequestedSellInReceiveWkUpdated(week)"
        [ngModel]="week.SellIn.CustomerRequestedSellInReceiveWk | number"
        (ngModelChange)="week.SellIn.CustomerRequestedSellInReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerRequestedSellInReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('FinalSellInCommitReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.FinalSellInCommitReceiveWk | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="FinalSellInCommitReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitvartoCustomerRequest')">

      <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitvartoCustomerRequest | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitvartoCustomerRequest = +$event"
        [ngModelOptions]="{standalone: true}" id="FinalSellInCommitvartoCustomerRequest">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoSellInFcstReceiveWk')">

      <input type="text" [disabled]="false" [ngModel]="week.SellIn.AlgoSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.AlgoSellInFcstReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="AlgoSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.DPSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.DPSellInFcstReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="DPSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.COSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.COSellInFcstReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="COSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.ConsensusSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.ConsensusSellInFcstReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="ConsensusSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitsShipWk')">

      <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitsShipWk | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitsShipWk = +$event" [ngModelOptions]="{standalone: true}"
        id="FinalSellInCommitsShipWk">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative SI')"> <label>&nbsp;</label></tr> -->

    <!-- SellIn -->

    <!-- CustomerInventory -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerIOHProjection')"> -->

    <!-- CustomerInventory -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerIOHProjection')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerIOHProjection | number"
        (ngModelChange)="week.CustomerInventory.CustomerIOHProjection = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerIOHProjection">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('IOHProjection')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.IOHProjection | number"
        (ngModelChange)="week.CustomerInventory.IOHProjection = +$event" [ngModelOptions]="{standalone: true}"
        id="IOHProjection">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerFWOSCalc')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerFWOSCalc | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.CustomerFWOSCalc = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerFWOSCalc">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBFWOSCalc')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FBFWOSCalc | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FBFWOSCalc = +$event" [ngModelOptions]="{standalone: true}"
        id="FBFWOSCalc">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoCustomerTarget')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoCustomerTarget | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FWOSvartoCustomerTarget = +$event" [ngModelOptions]="{standalone: true}"
        id="FWOSvartoCustomerTarget">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoFBTarget')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoFBTarget | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FWOSvartoFBTarget = +$event" [ngModelOptions]="{standalone: true}"
        id="FWOSvartoFBTarget">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerStoreOH')">

      <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerStoreOH | number"
        (ngModelChange)="week.CustomerInventory.CustomerStoreOH = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerStoreOH">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerDCOH')">

      <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerDCOH | number"
        (ngModelChange)="week.CustomerInventory.CustomerDCOH = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerDCOH">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Customer Inventory')"> <label>&nbsp;</label></tr> -->

    <!-- CustomerInventory -->

    <!-- FBInventory -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBTotalOnHand')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBTotalOnHand | number"
        (ngModelChange)="week.FBInventory.FBTotalOnHand = +$event" [ngModelOptions]="{standalone: true}"
        id="FBTotalOnHand">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBAvailableAllocation')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBAvailableAllocation | number"
        (ngModelChange)="week.FBInventory.FBAvailableAllocation = +$event" [ngModelOptions]="{standalone: true}"
        id="FBAvailableAllocation">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnOrder')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.OnOrder | number"
        (ngModelChange)="week.FBInventory.OnOrder = +$event" [ngModelOptions]="{standalone: true}" id="OnOrder">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InTransit')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InTransit | number"
        (ngModelChange)="week.FBInventory.InTransit = +$event" [ngModelOptions]="{standalone: true}" id="InTransit">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InventoryReceived')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InventoryReceived | number"
        (ngModelChange)="week.FBInventory.InventoryReceived = +$event" [ngModelOptions]="{standalone: true}"
        id="InventoryReceived">

    </tr>
    <tr *ngIf="checkIfToShowColumn('FB Inventory')"> <label>&nbsp;</label></tr> -->

    <!-- FBInventory -->

    <!-- KPI -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeDeliveryPerformance')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeDeliveryPerformance | percent"
        (ngModelChange)="week.KPI.OnTimeDeliveryPerformance = +$event" [ngModelOptions]="{standalone: true}"
        id="OnTimeDeliveryPerformance">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeInFull')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeInFull  | percent"
        (ngModelChange)="week.KPI.OnTimeInFull = +$event" [ngModelOptions]="{standalone: true}" id="OnTimeInFull">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerChargebacks')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.CustomerChargebacks | currency"
        (ngModelChange)="week.KPI.CustomerChargebacks = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerChargebacks">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('StandardDeliveryPst')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.StandardDeliveryPst  | percent"
        (ngModelChange)="week.KPI.StandardDeliveryPst = +$event" [ngModelOptions]="{standalone: true}"
        id="StandardDeliveryPst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ExpeditedDeliveryPst')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.ExpeditedDeliveryPst  | percent"
        (ngModelChange)="week.KPI.ExpeditedDeliveryPst = +$event" [ngModelOptions]="{standalone: true}"
        id="ExpeditedDeliveryPst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('KPIs')"> <label>&nbsp;</label></tr> -->

    <!-- KPI -->
  </td>
</ng-template>
<!-- Month Cummulated Column End -->

<!-- Quarter Cummulated Column Start -->

<ng-template #quarterColumn let-week="week">
  <td class="input-fields" style="text-align: center;">
    <tr style="height:30px !important"> <label style="height:30px !important">&nbsp;</label></tr>
    <!-- <tr style="height:29px !important"> <label style="height:29px !important">&nbsp;</label></tr> -->
    <!-- Algo -->
    {{week.title}}
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('BaselineSTForecast')">

      <input type="text" [disabled]="false" [ngModel]="week.Algo.BaselineSTForecast | number : '1.2-2'"
        (ngModelChange)="week.Algo.BaselineSTForecast = +$event" [ngModelOptions]="{standalone: true}"
        id="BaselineSTForecast">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoPromoUnits')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoPromoUnits | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoPromoUnits = +$event" [ngModelOptions]="{standalone: true}" id="AlgoPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoTotalSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="AlgoTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoConstrainedSTFcst')">

      <input type="text" [ngModel]="week.Algo.AlgoConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoConstrainedSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="AlgoConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoDPTotalSTFcst')">

      <input [ngModel]="week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst | percent" [ngModelOptions]="{standalone: true}"
        id="AlgoTotalSTFcstvartoDPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoCOTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoCOTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Algo ST')"> <label>&nbsp;</label></tr>
    <!-- Algo -->

    <!-- DemandPlanning -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPBaselineSTFcst')">

      <input type="text" [disabled]="false" [ngModel]="week.DemandPlanning.DPBaselineSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPBaselineSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="DPBaselineSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPManualFcstAdjustment')">

      <input [disabled]="false" type="text" [ngModel]="week.DemandPlanning.DPManualFcstAdjustment | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPManualFcstAdjustment = +$event" [ngModelOptions]="{standalone: true}"
        (change)="DPManualFcstAdjustmentUpdated(week)" id="DPManualFcstAdjustment">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPPromoUnits')">

      <input [disabled]="false" type="text" (change)="DPPromoUnitsUpdated(week)"
        [ngModel]="week.DemandPlanning.DPPromoUnits | number : '1.2-2'" [ngModelOptions]="{standalone: true}"
        id="DPPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPTotalSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="DPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPConstrainedSTFcst')">

      <input type="text" [ngModel]="week.DemandPlanning.DPConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPConstrainedSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="DPConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.DemandPlanning.DPComments"
        [ngModelOptions]="{standalone: true}" id="DPComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoCOTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoCOTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoCOTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoAlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoAlgoTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoAlgoTotalSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Demand Planning ST')"> <label>&nbsp;</label></tr>
    <!-- DemandPlanning -->

    <!-- ChannelOps -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COBaselineSTForecast')">
      <input type="text" [disabled]="false" [ngModel]="week.ChannelOps.COBaselineSTForecast | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COBaselineSTForecast = +$event" [ngModelOptions]="{standalone: true}"
        id="COBaselineSTForecast">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COManualFcstAdjustment')">

      <input [disabled]="false" type="text" [ngModel]="week.ChannelOps.COManualFcstAdjustment | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COManualFcstAdjustment = +$event" [ngModelOptions]="{standalone: true}"
        (change)="COManualFcstAdjustmentUpdated(week)" id="COManualFcstAdjustment">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COPromoUnits')">

      <input [disabled]="false" type="text" (change)="COPromoUnitsUpdated(week)"
        [ngModel]="week.ChannelOps.COPromoUnits | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COPromoUnits = +$event" [ngModelOptions]="{standalone: true}"
        id="COPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COTotalSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="COTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COConstrainedSTFcst')">

      <input type="text" [ngModel]="week.ChannelOps.COConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COConstrainedSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="COConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.ChannelOps.COComments"
        [ngModelOptions]="{standalone: true}" id="COComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoDPTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoDPTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoDPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoAlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoAlgoTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoAlgoTotalSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Channel Ops ST')"> <label>&nbsp;</label></tr>

    <!-- ChannelOps -->

    <!-- CurrentSellThrough -->
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerSTFcst')">

      <input [disabled]="false" type="text" (change)="CustomerSTFcstUpdated(week)"
        [ngModel]="week.CurrentSellThrough.CustomerSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.CustomerSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSTFcst')">

      <input [disabled]="false" type="text" (change)="ConsensusSTFcstUpdated(week)"
        [ngModel]="week.CurrentSellThrough.ConsensusSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="ConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvartoCustomerSTFcst')">

      <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst = +$event"
        [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvartoCustomerSTFcst">

    </tr>
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcst')">

        <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="ConstrainedSTFcst">

      </tr> -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvartoConsensusSTFcst')">

        <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst = +$event"
        [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvartoConsensusSTFcst">

      </tr> -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ProjectedLostSales')">

      <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ProjectedLostSales | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ProjectedLostSales = +$event" [ngModelOptions]="{standalone: true}"
        id="ProjectedLostSales">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualST')">

      <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.ActualST | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ActualST = +$event" [ngModelOptions]="{standalone: true}"
        id="ActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('WeeklyTrend')">

      <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.WeeklyTrend | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.WeeklyTrend = +$event" [ngModelOptions]="{standalone: true}"
        id="WeeklyTrend">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('PromoIndicator')">

      <input type="text" [disabled]="false" [(ngModel)]="week.CurrentSellThrough.PromoIndicator"
        [ngModelOptions]="{standalone: true}" id="PromoIndicator">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CPFRComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.CurrentSellThrough.CPFRComments"
        [ngModelOptions]="{standalone: true}" id="CPFRComments">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerInStockEstimate')">

      <input [disabled]="false" type="text" [ngModel]="week.CurrentSellThrough.CustomerInStockEstimate | percent"
        [ngModelOptions]="{standalone: true}" id="CustomerInStockEstimate">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstUnits')">

      <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstUnits | number:'1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ActualVartoConsensusFcstUnits = +$event"
        [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstPst')">

      <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstPst | percent"
        [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstPst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative ST')"> <label>&nbsp;</label></tr>

    <!-- CurrentSellThrough -->

    <!-- LastYearSales -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualST')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYActualST | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYActualST = +$event" [ngModelOptions]="{standalone: true}" id="LYActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYPromoIndicator')">

      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYPromoIndicator"
        [ngModelOptions]="{standalone: true}" id="LYPromoIndicator">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCOComments')">

      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYCOComments"
        [ngModelOptions]="{standalone: true}" id="LYCOComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCustomerInStockEstimate')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYCustomerInStockEstimate | percent"
        [ngModelOptions]="{standalone: true}" id="LYCustomerInStockEstimate">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYConsensusFcst')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYConsensusFcst | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYConsensusFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="LYConsensusFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarPst')">

      <input type="text" [disabled]="true" [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarPst | percent"
        [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarPst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarUnits')">

      <input type="text" [disabled]="true"
        [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarUnits | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYActualtoConsensusFcstVarUnits = +$event"
        [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarUnits">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Last Year ST')"> <label>&nbsp;</label></tr>

    <!-- LastYearSales -->

    <!-- StAccuracy -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.CustomerSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="CustomerSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.AlgoTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.COTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.DPTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvsActualST">

    </tr>
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvsActualST')">

        <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConstrainedSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvsActualST">

      </tr> -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConsensusSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvsActualST">

    </tr>
    <tr *ngIf="checkIfToShowColumn('ST Accuracy')"> <label>&nbsp;</label></tr>

    <!-- StAccuracy -->

    <!-- SellIn -->
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerRequestedSellInReceiveWk')">

      <input [disabled]="false" type="text" (change)="CustomerRequestedSellInReceiveWkUpdated(week)"
        [ngModel]="week.SellIn.CustomerRequestedSellInReceiveWk | number"
        (ngModelChange)="week.SellIn.CustomerRequestedSellInReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerRequestedSellInReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('FinalSellInCommitReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.FinalSellInCommitReceiveWk | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="FinalSellInCommitReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitvartoCustomerRequest')">

      <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitvartoCustomerRequest | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitvartoCustomerRequest = +$event"
        [ngModelOptions]="{standalone: true}" id="FinalSellInCommitvartoCustomerRequest">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoSellInFcstReceiveWk')">

      <input type="text" [disabled]="false" [ngModel]="week.SellIn.AlgoSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.AlgoSellInFcstReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="AlgoSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.DPSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.DPSellInFcstReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="DPSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.COSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.COSellInFcstReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="COSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.ConsensusSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.ConsensusSellInFcstReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="ConsensusSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitsShipWk')">

      <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitsShipWk | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitsShipWk = +$event" [ngModelOptions]="{standalone: true}"
        id="FinalSellInCommitsShipWk">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative SI')"> <label>&nbsp;</label></tr>

    <!-- SellIn -->


    <!-- CustomerInventory -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerIOHProjection')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerIOHProjection | number"
        (ngModelChange)="week.CustomerInventory.CustomerIOHProjection = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerIOHProjection">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('IOHProjection')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.IOHProjection | number"
        (ngModelChange)="week.CustomerInventory.IOHProjection = +$event" [ngModelOptions]="{standalone: true}"
        id="IOHProjection">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerFWOSCalc')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerFWOSCalc | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.CustomerFWOSCalc = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerFWOSCalc">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBFWOSCalc')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FBFWOSCalc | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FBFWOSCalc = +$event" [ngModelOptions]="{standalone: true}"
        id="FBFWOSCalc">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoCustomerTarget')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoCustomerTarget | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FWOSvartoCustomerTarget = +$event" [ngModelOptions]="{standalone: true}"
        id="FWOSvartoCustomerTarget">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoFBTarget')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoFBTarget | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FWOSvartoFBTarget = +$event" [ngModelOptions]="{standalone: true}"
        id="FWOSvartoFBTarget">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerStoreOH')">

      <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerStoreOH | number"
        (ngModelChange)="week.CustomerInventory.CustomerStoreOH = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerStoreOH">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerDCOH')">

      <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerDCOH | number"
        (ngModelChange)="week.CustomerInventory.CustomerDCOH = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerDCOH">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Customer Inventory')"> <label>&nbsp;</label></tr>

    <!-- CustomerInventory -->

    <!-- FBInventory -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBTotalOnHand')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBTotalOnHand | number"
        (ngModelChange)="week.FBInventory.FBTotalOnHand = +$event" [ngModelOptions]="{standalone: true}"
        id="FBTotalOnHand">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBAvailableAllocation')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBAvailableAllocation | number"
        (ngModelChange)="week.FBInventory.FBAvailableAllocation = +$event" [ngModelOptions]="{standalone: true}"
        id="FBAvailableAllocation">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnOrder')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.OnOrder | number"
        (ngModelChange)="week.FBInventory.OnOrder = +$event" [ngModelOptions]="{standalone: true}" id="OnOrder">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InTransit')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InTransit | number"
        (ngModelChange)="week.FBInventory.InTransit = +$event" [ngModelOptions]="{standalone: true}" id="InTransit">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InventoryReceived')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InventoryReceived | number"
        (ngModelChange)="week.FBInventory.InventoryReceived = +$event" [ngModelOptions]="{standalone: true}"
        id="InventoryReceived">

    </tr>
    <tr *ngIf="checkIfToShowColumn('FB Inventory')"> <label>&nbsp;</label></tr>

    <!-- FBInventory -->

    <!-- KPI -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeDeliveryPerformance')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeDeliveryPerformance | percent"
        (ngModelChange)="week.KPI.OnTimeDeliveryPerformance = +$event" [ngModelOptions]="{standalone: true}"
        id="OnTimeDeliveryPerformance">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeInFull')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeInFull  | percent"
        (ngModelChange)="week.KPI.OnTimeInFull = +$event" [ngModelOptions]="{standalone: true}" id="OnTimeInFull">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerChargebacks')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.CustomerChargebacks | currency"
        (ngModelChange)="week.KPI.CustomerChargebacks = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerChargebacks">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('StandardDeliveryPst')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.StandardDeliveryPst  | percent"
        (ngModelChange)="week.KPI.StandardDeliveryPst = +$event" [ngModelOptions]="{standalone: true}"
        id="StandardDeliveryPst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ExpeditedDeliveryPst')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.ExpeditedDeliveryPst  | percent"
        (ngModelChange)="week.KPI.ExpeditedDeliveryPst = +$event" [ngModelOptions]="{standalone: true}"
        id="ExpeditedDeliveryPst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('KPIs')"> <label>&nbsp;</label></tr>

    <!-- KPI -->
  </td>
</ng-template>
<!-- Quarter Cummulated Column End -->

<!-- Half Cummulated Column Start -->
<ng-template #halfColumn let-week="week">
  <td class="input-fields" style="text-align: center;">
    <tr style="height:27px !important"> <label style="height:27px !important">&nbsp;</label></tr>
    <!-- <tr style="height:27px !important"> <label style="height:27px !important">&nbsp;</label></tr>
        <tr style="height:28px !important"> <label style="height:28px !important">&nbsp;</label></tr> -->
    <!-- Algo -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('BaselineSTForecast')">

      <input type="text" [disabled]="false" [ngModel]="week.Algo.BaselineSTForecast | number : '1.2-2'"
        (ngModelChange)="week.Algo.BaselineSTForecast = +$event" [ngModelOptions]="{standalone: true}"
        id="BaselineSTForecast">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoPromoUnits')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoPromoUnits | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoPromoUnits = +$event" [ngModelOptions]="{standalone: true}" id="AlgoPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoTotalSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="AlgoTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoConstrainedSTFcst')">

      <input type="text" [ngModel]="week.Algo.AlgoConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoConstrainedSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="AlgoConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoDPTotalSTFcst')">

      <input [ngModel]="week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst | percent" [ngModelOptions]="{standalone: true}"
        id="AlgoTotalSTFcstvartoDPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoCOTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoCOTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Algo ST')"> <label>&nbsp;</label></tr>
    <!-- Algo -->

    <!-- DemandPlanning -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPBaselineSTFcst')">

      <input type="text" [disabled]="false" [ngModel]="week.DemandPlanning.DPBaselineSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPBaselineSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="DPBaselineSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPManualFcstAdjustment')">

      <input [disabled]="false" type="text" [ngModel]="week.DemandPlanning.DPManualFcstAdjustment | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPManualFcstAdjustment = +$event" [ngModelOptions]="{standalone: true}"
        (change)="DPManualFcstAdjustmentUpdated(week)" id="DPManualFcstAdjustment">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPPromoUnits')">

      <input [disabled]="false" type="text" (change)="DPPromoUnitsUpdated(week)"
        [ngModel]="week.DemandPlanning.DPPromoUnits | number : '1.2-2'" [ngModelOptions]="{standalone: true}"
        id="DPPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPTotalSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="DPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPConstrainedSTFcst')">

      <input type="text" [ngModel]="week.DemandPlanning.DPConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPConstrainedSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="DPConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.DemandPlanning.DPComments"
        [ngModelOptions]="{standalone: true}" id="DPComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoCOTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoCOTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoCOTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoAlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoAlgoTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoAlgoTotalSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Demand Planning ST')"> <label>&nbsp;</label></tr>
    <!-- DemandPlanning -->

    <!-- ChannelOps -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COBaselineSTForecast')">
      <input type="text" [disabled]="false" [ngModel]="week.ChannelOps.COBaselineSTForecast | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COBaselineSTForecast = +$event" [ngModelOptions]="{standalone: true}"
        id="COBaselineSTForecast">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COManualFcstAdjustment')">

      <input [disabled]="false" type="text" [ngModel]="week.ChannelOps.COManualFcstAdjustment | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COManualFcstAdjustment = +$event" [ngModelOptions]="{standalone: true}"
        (change)="COManualFcstAdjustmentUpdated(week)" id="COManualFcstAdjustment">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COPromoUnits')">

      <input [disabled]="false" type="text" (change)="COPromoUnitsUpdated(week)"
        [ngModel]="week.ChannelOps.COPromoUnits | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COPromoUnits = +$event" [ngModelOptions]="{standalone: true}"
        id="COPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COTotalSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="COTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COConstrainedSTFcst')">

      <input type="text" [ngModel]="week.ChannelOps.COConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COConstrainedSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="COConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.ChannelOps.COComments"
        [ngModelOptions]="{standalone: true}" id="COComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoDPTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoDPTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoDPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoAlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoAlgoTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoAlgoTotalSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Channel Ops ST')"> <label>&nbsp;</label></tr>

    <!-- ChannelOps -->

    <!-- CurrentSellThrough -->
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerSTFcst')">

      <input [disabled]="false" type="text" (change)="CustomerSTFcstUpdated(week)"
        [ngModel]="week.CurrentSellThrough.CustomerSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.CustomerSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSTFcst')">

      <input [disabled]="false" type="text" (change)="ConsensusSTFcstUpdated(week)"
        [ngModel]="week.CurrentSellThrough.ConsensusSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="ConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvartoCustomerSTFcst')">

      <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst = +$event"
        [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvartoCustomerSTFcst">

    </tr>
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ConstrainedSTFcst | number : '1.2-2'"
          (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcst = +$event" [ngModelOptions]="{standalone: true}"
          id="ConstrainedSTFcst">

        </tr> -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvartoConsensusSTFcst')">

          <input type="text" [disabled]="true"
          [ngModel]="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst | number : '1.2-2'"
          (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst = +$event"
          [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvartoConsensusSTFcst">

        </tr> -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ProjectedLostSales')">

      <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ProjectedLostSales | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ProjectedLostSales = +$event" [ngModelOptions]="{standalone: true}"
        id="ProjectedLostSales">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualST')">

      <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.ActualST | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ActualST = +$event" [ngModelOptions]="{standalone: true}"
        id="ActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('WeeklyTrend')">

      <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.WeeklyTrend | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.WeeklyTrend = +$event" [ngModelOptions]="{standalone: true}"
        id="WeeklyTrend">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('PromoIndicator')">

      <input type="text" [disabled]="false" [(ngModel)]="week.CurrentSellThrough.PromoIndicator"
        [ngModelOptions]="{standalone: true}" id="PromoIndicator">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CPFRComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.CurrentSellThrough.CPFRComments"
        [ngModelOptions]="{standalone: true}" id="CPFRComments">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerInStockEstimate')">

      <input [disabled]="false" type="text" [ngModel]="week.CurrentSellThrough.CustomerInStockEstimate | percent"
        [ngModelOptions]="{standalone: true}" id="CustomerInStockEstimate">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstUnits')">

      <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstUnits | number:'1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ActualVartoConsensusFcstUnits = +$event"
        [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstPst')">

      <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstPst | percent"
        [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstPst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative ST')"> <label>&nbsp;</label></tr>

    <!-- CurrentSellThrough -->

    <!-- LastYearSales -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualST')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYActualST | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYActualST = +$event" [ngModelOptions]="{standalone: true}" id="LYActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYPromoIndicator')">

      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYPromoIndicator"
        [ngModelOptions]="{standalone: true}" id="LYPromoIndicator">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCOComments')">

      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYCOComments"
        [ngModelOptions]="{standalone: true}" id="LYCOComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCustomerInStockEstimate')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYCustomerInStockEstimate | percent"
        [ngModelOptions]="{standalone: true}" id="LYCustomerInStockEstimate">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYConsensusFcst')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYConsensusFcst | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYConsensusFcst = +$event" [ngModelOptions]="{standalone: true}"
        id="LYConsensusFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarPst')">

      <input type="text" [disabled]="true" [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarPst | percent"
        [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarPst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarUnits')">

      <input type="text" [disabled]="true"
        [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarUnits | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYActualtoConsensusFcstVarUnits = +$event"
        [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarUnits">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Last Year ST')"> <label>&nbsp;</label></tr>

    <!-- LastYearSales -->

    <!-- StAccuracy -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.CustomerSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="CustomerSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.AlgoTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.COTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.DPTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvsActualST">

    </tr>
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvsActualST')">

          <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConstrainedSTFcstvsActualST | percent"
          [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvsActualST">

        </tr> -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConsensusSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvsActualST">

    </tr>
    <tr *ngIf="checkIfToShowColumn('ST Accuracy')"> <label>&nbsp;</label></tr>

    <!-- StAccuracy -->

    <!-- SellIn -->
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerRequestedSellInReceiveWk')">

      <input [disabled]="false" type="text" (change)="CustomerRequestedSellInReceiveWkUpdated(week)"
        [ngModel]="week.SellIn.CustomerRequestedSellInReceiveWk | number"
        (ngModelChange)="week.SellIn.CustomerRequestedSellInReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerRequestedSellInReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('FinalSellInCommitReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.FinalSellInCommitReceiveWk | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="FinalSellInCommitReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitvartoCustomerRequest')">

      <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitvartoCustomerRequest | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitvartoCustomerRequest = +$event"
        [ngModelOptions]="{standalone: true}" id="FinalSellInCommitvartoCustomerRequest">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoSellInFcstReceiveWk')">

      <input type="text" [disabled]="false" [ngModel]="week.SellIn.AlgoSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.AlgoSellInFcstReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="AlgoSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.DPSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.DPSellInFcstReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="DPSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.COSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.COSellInFcstReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="COSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.ConsensusSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.ConsensusSellInFcstReceiveWk = +$event" [ngModelOptions]="{standalone: true}"
        id="ConsensusSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitsShipWk')">

      <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitsShipWk | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitsShipWk = +$event" [ngModelOptions]="{standalone: true}"
        id="FinalSellInCommitsShipWk">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative SI')"> <label>&nbsp;</label></tr>

    <!-- SellIn -->


    <!-- CustomerInventory -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerIOHProjection')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerIOHProjection | number"
        (ngModelChange)="week.CustomerInventory.CustomerIOHProjection = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerIOHProjection">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('IOHProjection')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.IOHProjection | number"
        (ngModelChange)="week.CustomerInventory.IOHProjection = +$event" [ngModelOptions]="{standalone: true}"
        id="IOHProjection">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerFWOSCalc')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerFWOSCalc | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.CustomerFWOSCalc = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerFWOSCalc">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBFWOSCalc')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FBFWOSCalc | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FBFWOSCalc = +$event" [ngModelOptions]="{standalone: true}"
        id="FBFWOSCalc">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoCustomerTarget')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoCustomerTarget | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FWOSvartoCustomerTarget = +$event" [ngModelOptions]="{standalone: true}"
        id="FWOSvartoCustomerTarget">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoFBTarget')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoFBTarget | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FWOSvartoFBTarget = +$event" [ngModelOptions]="{standalone: true}"
        id="FWOSvartoFBTarget">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerStoreOH')">

      <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerStoreOH | number"
        (ngModelChange)="week.CustomerInventory.CustomerStoreOH = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerStoreOH">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerDCOH')">

      <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerDCOH | number"
        (ngModelChange)="week.CustomerInventory.CustomerDCOH = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerDCOH">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Customer Inventory')"> <label>&nbsp;</label></tr>

    <!-- CustomerInventory -->

    <!-- FBInventory -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBTotalOnHand')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBTotalOnHand | number"
        (ngModelChange)="week.FBInventory.FBTotalOnHand = +$event" [ngModelOptions]="{standalone: true}"
        id="FBTotalOnHand">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBAvailableAllocation')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBAvailableAllocation | number"
        (ngModelChange)="week.FBInventory.FBAvailableAllocation = +$event" [ngModelOptions]="{standalone: true}"
        id="FBAvailableAllocation">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnOrder')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.OnOrder | number"
        (ngModelChange)="week.FBInventory.OnOrder = +$event" [ngModelOptions]="{standalone: true}" id="OnOrder">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InTransit')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InTransit | number"
        (ngModelChange)="week.FBInventory.InTransit = +$event" [ngModelOptions]="{standalone: true}" id="InTransit">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InventoryReceived')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InventoryReceived | number"
        (ngModelChange)="week.FBInventory.InventoryReceived = +$event" [ngModelOptions]="{standalone: true}"
        id="InventoryReceived">

    </tr>
    <tr *ngIf="checkIfToShowColumn('FB Inventory')"> <label>&nbsp;</label></tr>

    <!-- FBInventory -->

    <!-- KPI -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeDeliveryPerformance')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeDeliveryPerformance | percent"
        (ngModelChange)="week.KPI.OnTimeDeliveryPerformance = +$event" [ngModelOptions]="{standalone: true}"
        id="OnTimeDeliveryPerformance">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeInFull')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeInFull  | percent"
        (ngModelChange)="week.KPI.OnTimeInFull = +$event" [ngModelOptions]="{standalone: true}" id="OnTimeInFull">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerChargebacks')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.CustomerChargebacks | currency"
        (ngModelChange)="week.KPI.CustomerChargebacks = +$event" [ngModelOptions]="{standalone: true}"
        id="CustomerChargebacks">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('StandardDeliveryPst')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.StandardDeliveryPst  | percent"
        (ngModelChange)="week.KPI.StandardDeliveryPst = +$event" [ngModelOptions]="{standalone: true}"
        id="StandardDeliveryPst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ExpeditedDeliveryPst')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.ExpeditedDeliveryPst  | percent"
        (ngModelChange)="week.KPI.ExpeditedDeliveryPst = +$event" [ngModelOptions]="{standalone: true}"
        id="ExpeditedDeliveryPst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('KPIs')"> <label>&nbsp;</label></tr>

    <!-- KPI -->
  </td>
</ng-template>
<ng-template #yearColumn let-week="week">
  <td class="input-fields" style="text-align: center;">
    <!-- Algo -->
    <ng-container *ngFor="let measure of measureList; let i=index">
      <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList(measure.code)">
        <input type="text" [disabled]="measure.type === 1" [ngModel]="week[measure.code] | number : '1.2-2'"
          (focusout)="valueChange(week[measure.code], week, measure.code)" (ngModelChange)=" week[measure.code]=+$event"
          [ngModelOptions]="{standalone: true}" [id]="measure.code">
      </tr>
      <tr *ngIf="checkIfToShow(i)" style="height: 40px !important; margin-bottom: 2px;">
        <label [id]="i" style="height: 40px !important; margin-bottom: 2px;">&nbsp;</label>
      </tr>
    </ng-container>
    <!-- <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('BaselineSTForecast')">
      <input type="text" [disabled]="false" [ngModel]="
          week.Algo.BaselineSTForecast
          | number: '1.2-2'
          " (ngModelChange)="
          week.Algo.BaselineSTForecast = +$event
          " (focusout)="
          valueChange(
          week.Algo.BaselineSTForecast,
          week,
          'BaselineSTForecast'
          )
          " [ngModelOptions]="{ standalone: true }" id="BaselineSTForecast" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('AlgoPromoUnits')">
      <input type="text" [disabled]="true" [ngModel]="
          week.Algo.AlgoPromoUnits | number: '1.2-2'
          " (ngModelChange)="
          week.Algo.AlgoPromoUnits = +$event
          " (focusout)="
          valueChange(
          week.Algo.AlgoPromoUnits,
          week,
          'AlgoPromoUnits'
          )
          " [ngModelOptions]="{ standalone: true }" id="AlgoPromoUnits" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('AlgoTotalSTFcst')">
      <input type="text" [disabled]="true" [ngModel]="
          week.Algo.AlgoTotalSTFcst | number: '1.2-2'
          " (focusout)="
          valueChange(
          week.Algo.AlgoTotalSTFcst,
          week,
          'AlgoTotalSTFcst'
          )
          " (ngModelChange)="
          week.Algo.AlgoTotalSTFcst = +$event
          " [ngModelOptions]="{ standalone: true }" id="AlgoTotalSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('AlgoConstrainedSTFcst')">
      <input type="text" [ngModel]="
          week.Algo.AlgoConstrainedSTFcst
          | number: '1.2-2'
          " (focusout)="
          valueChange(
          week.Algo.AlgoConstrainedSTFcst,
          week,
          'AlgoConstrainedSTFcst'
          )
          " (ngModelChange)="
          week.Algo.AlgoConstrainedSTFcst = +$event
          " [ngModelOptions]="{ standalone: true }" id="AlgoConstrainedSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('AlgoTotalSTFcstvartoLY')">
      <input type="text" [disabled]="true" [ngModel]="
          week.Algo.AlgoTotalSTFcstvartoLY | percent
          " (focusout)="
          valueChange(
          week.Algo.AlgoTotalSTFcstvartoLY,
          week,
          'AlgoTotalSTFcstvartoLY'
          )
          " [ngModelOptions]="{ standalone: true }" id="AlgoTotalSTFcstvartoLY" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="
        checkIfInList(
        'AlgoTotalSTFcstvartoDPTotalSTFcst'
        )
        ">
      <input [ngModel]="
        week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst
        | percent
        " (focusout)="
        valueChange(
        week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst,
        week,
        'AlgoTotalSTFcstvartoDPTotalSTFcst'
        )
        " [ngModelOptions]="{ standalone: true }" id="AlgoTotalSTFcstvartoDPTotalSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
      checkIfInList(
      'AlgoTotalSTFcstvartoCOTotalSTFcst'
      )
      ">
      <input type="text" [disabled]="true" [ngModel]="
      week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst
      | percent
      " (focusout)="
      valueChange(
      week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst,
      week,
      'AlgoTotalSTFcstvartoCOTotalSTFcst'
      )
      " [ngModelOptions]="{ standalone: true }" id="AlgoTotalSTFcstvartoCOTotalSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
    checkIfInList(
    'AlgoTotalSTFcstvartoConsensusSTFcst'
    )
    ">
      <input type="text" [disabled]="true" [ngModel]="
    week.Algo
    .AlgoTotalSTFcstvartoConsensusSTFcst
    | percent
    " (focusout)="
    valueChange(
    week.Algo.AlgoTotalSTFcstvartoConsensusSTFcst,
    week,
    'AlgoTotalSTFcstvartoConsensusSTFcst'
    )
    " [ngModelOptions]="{ standalone: true }" id="AlgoTotalSTFcstvartoConsensusSTFcst" />
    </tr>
    <tr *ngIf="checkIfToShowColumn('Algo ST')">
      <label>&nbsp;</label>
    </tr> -->
    <!-- Algo -->

    <!-- DemandPlanning -->
    <!-- <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('DPBaselineSTFcst')">
      <input type="text" [disabled]="false" [ngModel]="
    week.DemandPlanning.DPBaselineSTFcst
    | number: '1.2-2'
    " (focusout)="
    valueChange(
    week.DemandPlanning.DPBaselineSTFcst,
    week,
    'DPBaselineSTFcst'
    )
    " (ngModelChange)="
    week.DemandPlanning.DPBaselineSTFcst = +$event
    " [ngModelOptions]="{ standalone: true }" id="DPBaselineSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="checkIfInList('DPManualFcstAdjustment')">
      <input [disabled]="false" type="text" [ngModel]="
    week.DemandPlanning.DPManualFcstAdjustment
    | number: '1.2-2'
    " (focusout)="
    valueChange(
    week.DemandPlanning.DPManualFcstAdjustment,
    week,
    'DPManualFcstAdjustment'
    )
    " (ngModelChange)="
    week.DemandPlanning.DPManualFcstAdjustment = +$event
    " [ngModelOptions]="{ standalone: true }" (change)="DPManualFcstAdjustmentUpdated(week)"
        id="DPManualFcstAdjustment" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="checkIfInList('DPPromoUnits')">
      <input [disabled]="false" type="text" (change)="DPPromoUnitsUpdated(week)" [ngModel]="
    week.DemandPlanning.DPPromoUnits
    | number: '1.2-2'
    " (focusout)="
    valueChange(
    week.DemandPlanning.DPPromoUnits,
    week,
    'DPPromoUnits'
    )
    " [ngModelOptions]="{ standalone: true }" id="DPPromoUnits" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('DPTotalSTFcst')">
      <input type="text" [disabled]="true" [ngModel]="
    week.DemandPlanning.DPTotalSTFcst
    | number: '1.2-2'
    " (focusout)="
    valueChange(
    week.DemandPlanning.DPTotalSTFcst,
    week,
    'DPTotalSTFcst'
    )
    " (ngModelChange)="
    week.DemandPlanning.DPTotalSTFcst = +$event
    " [ngModelOptions]="{ standalone: true }" id="DPTotalSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('DPConstrainedSTFcst')">
      <input type="text" [ngModel]="
    week.DemandPlanning.DPConstrainedSTFcst
    | number: '1.2-2'
    " (focusout)="
    valueChange(
    week.DemandPlanning.DPConstrainedSTFcst,
    week,
    'DPConstrainedSTFcst'
    )
    " (ngModelChange)="
    week.DemandPlanning.DPConstrainedSTFcst = +$event
    " [ngModelOptions]="{ standalone: true }" id="DPConstrainedSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="checkIfInList('DPComments')">
      <input [disabled]="false" type="text" [(ngModel)]="week.DemandPlanning.DPComments" (focusout)="
    valueChange(
    week.DemandPlanning.DPComments,
    week,
    'DPComments'
    )
    " [ngModelOptions]="{ standalone: true }" id="DPComments" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('DPTotalSTFcstvartoLY')">
      <input type="text" [disabled]="true" [ngModel]="
    week.DemandPlanning.DPTotalSTFcstvartoLY
    | percent
    " [ngModelOptions]="{ standalone: true }" id="DPTotalSTFcstvartoLY" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
  checkIfInList(
  'DPTotalSTFcstvartoCOTotalSTFcst'
  )
  ">
      <input type="text" [disabled]="true" [ngModel]="
  week.DemandPlanning
  .DPTotalSTFcstvartoCOTotalSTFcst | percent


  " [ngModelOptions]="{ standalone: true }" id="DPTotalSTFcstvartoCOTotalSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList(
'DPTotalSTFcstvartoConsensusSTFcst'
)
">
      <input type="text" [disabled]="true" [ngModel]="
week.DemandPlanning
.DPTotalSTFcstvartoConsensusSTFcst
| percent
" [ngModelOptions]="{ standalone: true }" id="DPTotalSTFcstvartoConsensusSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList(
'DPTotalSTFcstvartoAlgoTotalSTFcst'
)
">
      <input type="text" [disabled]="true" [ngModel]="
week.DemandPlanning
.DPTotalSTFcstvartoAlgoTotalSTFcst
| percent
" [ngModelOptions]="{ standalone: true }" id="DPTotalSTFcstvartoAlgoTotalSTFcst" />
    </tr>
    <tr *ngIf="
checkIfToShowColumn('Demand Planning ST')
">
      <label>&nbsp;</label>
    </tr> -->
    <!-- DemandPlanning -->

    <!-- ChannelOps -->
    <!-- <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('COBaselineSTForecast')">
      <input type="text" [disabled]="false" [ngModel]="
  week.ChannelOps.COBaselineSTForecast
  | number: '1.2-2'
  " (focusout)="
  valueChange(
  week.ChannelOps.COBaselineSTForecast,
  week,
  'COBaselineSTForecast'
  )
  " (ngModelChange)="
  week.ChannelOps.COBaselineSTForecast = +$event
  " [ngModelOptions]="{ standalone: true }" id="COBaselineSTForecast" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="checkIfInList('COManualFcstAdjustment')">
      <input [disabled]="false" type="text" [ngModel]="
  week.ChannelOps.COManualFcstAdjustment
  | number: '1.2-2'
  " (focusout)="
  valueChange(
  week.ChannelOps.COManualFcstAdjustment,
  week,
  'COManualFcstAdjustment'
  )" (ngModelChange)="
  week.ChannelOps.COManualFcstAdjustment = +$event
  " [ngModelOptions]="{ standalone: true }" (change)="COManualFcstAdjustmentUpdated(week)"
        id="COManualFcstAdjustment" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="checkIfInList('COPromoUnits')">
      <input [disabled]="false" type="text" (change)="COPromoUnitsUpdated(week)" [ngModel]="
  week.ChannelOps.COPromoUnits
  | number: '1.2-2'
  " (focusout)="
  valueChange(
  week.ChannelOps.COPromoUnits,
  week,
  'COPromoUnits'
  )" (ngModelChange)="
  week.ChannelOps.COPromoUnits = +$event
  " [ngModelOptions]="{ standalone: true }" id="COPromoUnits" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('COTotalSTFcst')">
      <input type="text" [disabled]="true" [ngModel]="
  week.ChannelOps.COTotalSTFcst
  | number: '1.2-2'
  " (focusout)="
  valueChange(
  week.ChannelOps.COTotalSTFcst,
  week,
  'COTotalSTFcst'
  )" (ngModelChange)="
  week.ChannelOps.COTotalSTFcst = +$event
  " [ngModelOptions]="{ standalone: true }" id="COTotalSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('COConstrainedSTFcst')">
      <input type="text" [ngModel]="
  week.ChannelOps.COConstrainedSTFcst
  | number: '1.2-2'
  " (focusout)="
  valueChange(
  week.ChannelOps.COConstrainedSTFcst,
  week,
  'COConstrainedSTFcst'
  )" (ngModelChange)="
  week.ChannelOps.COConstrainedSTFcst = +$event
  " [ngModelOptions]="{ standalone: true }" id="COConstrainedSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="checkIfInList('COComments')">
      <input [disabled]="false" type="text" [(ngModel)]="week.ChannelOps.COComments" (focusout)="
  valueChange(
  week.ChannelOps.COComments,
  week,
  'COComments'
  )" [ngModelOptions]="{ standalone: true }" id="COComments" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('COTotalSTFcstvartoLY')">
      <input type="text" [disabled]="true" [ngModel]="
  week.ChannelOps.COTotalSTFcstvartoLY
  | percent
  " (focusout)="
  valueChange(
  week.ChannelOps.COTotalSTFcstvartoLY,
  week,
  'COTotalSTFcstvartoLY'
  )" [ngModelOptions]="{ standalone: true }" id="COTotalSTFcstvartoLY" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList(
'COTotalSTFcstvartoDPTotalSTFcst'
)
">
      <input type="text" [disabled]="true" [ngModel]="
week.ChannelOps
.COTotalSTFcstvartoDPTotalSTFcst | percent
" (focusout)="
valueChange(
week.ChannelOps.COTotalSTFcstvartoDPTotalSTFcst,
week,
'COTotalSTFcstvartoDPTotalSTFcst'
)" [ngModelOptions]="{ standalone: true }" id="COTotalSTFcstvartoDPTotalSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList(
'COTotalSTFcstvartoConsensusSTFcst'
)
">
      <input type="text" [disabled]="true" [ngModel]="
week.ChannelOps
.COTotalSTFcstvartoConsensusSTFcst
| percent
" (focusout)="
valueChange(
week.ChannelOps.COTotalSTFcstvartoConsensusSTFcst,
week,
'COTotalSTFcstvartoConsensusSTFcst'
)" [ngModelOptions]="{ standalone: true }" id="COTotalSTFcstvartoConsensusSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList(
'COTotalSTFcstvartoAlgoTotalSTFcst'
)
">
      <input type="text" [disabled]="true" [ngModel]="
week.ChannelOps
.COTotalSTFcstvartoAlgoTotalSTFcst
| percent
" (focusout)="
valueChange(
week.ChannelOps.COTotalSTFcstvartoAlgoTotalSTFcst,
week,
'COTotalSTFcstvartoAlgoTotalSTFcst'
)" [ngModelOptions]="{ standalone: true }" id="COTotalSTFcstvartoAlgoTotalSTFcst" />
    </tr>
    <tr *ngIf="checkIfToShowColumn('Channel Ops ST')">
      <label>&nbsp;</label>
    </tr> -->

    <!-- ChannelOps -->

    <!-- CurrentSellThrough -->
    <!-- <tr [ngStyle]="{ background: '#fff' }" *ngIf="checkIfInList('CustomerSTFcst')">
      <input [disabled]="false" type="text" (change)="CustomerSTFcstUpdated(week)" [ngModel]="
  week.CurrentSellThrough.CustomerSTFcst
  | number: '1.2-2'
  " (focusout)="
  valueChange(
  week.CurrentSellThrough.CustomerSTFcst,
  week,
  'CustomerSTFcst'
  )" (ngModelChange)="
  week.CurrentSellThrough.CustomerSTFcst = +$event
  " [ngModelOptions]="{ standalone: true }" id="CustomerSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="checkIfInList('ConsensusSTFcst')">
      <input [disabled]="false" type="text" (change)="ConsensusSTFcstUpdated(week)" [ngModel]="
  week.CurrentSellThrough.ConsensusSTFcst
  | number: '1.2-2'
  " (focusout)="
  valueChange(
  week.CurrentSellThrough.ConsensusSTFcst,
  week,
  'ConsensusSTFcst'
  )" (ngModelChange)="
  week.CurrentSellThrough.ConsensusSTFcst = +$event
  " [ngModelOptions]="{ standalone: true }" id="ConsensusSTFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList(
'ConsensusSTFcstvartoCustomerSTFcst'
)
">
      <input type="text" [disabled]="true" [ngModel]="
week.CurrentSellThrough
.ConsensusSTFcstvartoCustomerSTFcst
| number: '1.2-2'
" (ngModelChange)="
week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst = +$event
" [ngModelOptions]="{ standalone: true }" id="ConsensusSTFcstvartoCustomerSTFcst" />
    </tr> -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcst')">

  <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ConstrainedSTFcst | number : '1.2-2'"
  (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcst = +$event" [ngModelOptions]="{standalone: true}"
  id="ConstrainedSTFcst">

</tr> -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvartoConsensusSTFcst')">

  <input type="text" [disabled]="true"
  [ngModel]="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst | number : '1.2-2'"
  (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst = +$event"
  [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvartoConsensusSTFcst">

</tr> -->
    <!-- <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('ProjectedLostSales')">
      <input type="text" [disabled]="true" [ngModel]="
  week.CurrentSellThrough.ProjectedLostSales
  | number: '1.2-2'
  " (ngModelChange)="
  week.CurrentSellThrough.ProjectedLostSales = +$event
  " [ngModelOptions]="{ standalone: true }" id="ProjectedLostSales" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('ActualST')">
      <input type="text" [disabled]="false" [ngModel]="
  week.CurrentSellThrough.ActualST
  | number: '1.2-2'
  " (ngModelChange)="
  week.CurrentSellThrough.ActualST = +$event
  " (focusout)="
  valueChange(
  week.CurrentSellThrough.ActualST,
  week,
  'ActualST'
  )" [ngModelOptions]="{ standalone: true }" id="ActualST" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('WeeklyTrend')">
      <input type="text" [disabled]="false" [ngModel]="
  week.CurrentSellThrough.WeeklyTrend
  | number: '1.2-2'
  " (ngModelChange)="
  week.CurrentSellThrough.WeeklyTrend = +$event
  " (focusout)="
  valueChange(
  week.CurrentSellThrough.WeeklyTrend,
  week,
  'WeeklyTrend'
  )" [ngModelOptions]="{ standalone: true }" id="WeeklyTrend" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('PromoIndicator')">
      <input type="text" [disabled]="false" [(ngModel)]="
  week.CurrentSellThrough.PromoIndicator
  " (focusout)="
  valueChange(
  week.CurrentSellThrough.PromoIndicator,
  week,
  'PromoIndicator'
  )" [ngModelOptions]="{ standalone: true }" id="PromoIndicator" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="checkIfInList('CPFRComments')">
      <input [disabled]="false" type="text" [(ngModel)]="
  week.CurrentSellThrough.CPFRComments
  " (focusout)="
  valueChange(
  week.CurrentSellThrough.CPFRComments,
  week,
  'CPFRComments'
  )" [ngModelOptions]="{ standalone: true }" id="CPFRComments" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="checkIfInList('CustomerInStockEstimate')">
      <input [disabled]="false" type="text" [ngModel]="
  week.CurrentSellThrough
  .CustomerInStockEstimate | percent
  " (focusout)="
  valueChange(
  week.CurrentSellThrough.CustomerInStockEstimate,
  week,
  'CustomerInStockEstimate'
  )" [ngModelOptions]="{ standalone: true }" id="CustomerInStockEstimate" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList('ActualVartoConsensusFcstUnits')
">
      <input type="text" [disabled]="true" [ngModel]="
week.CurrentSellThrough
.ActualVartoConsensusFcstUnits
| number: '1.2-2'
" (focusout)="
valueChange(
week.CurrentSellThrough.ActualVartoConsensusFcstUnits,
week,
'ActualVartoConsensusFcstUnits'
)" (ngModelChange)="
week.CurrentSellThrough.ActualVartoConsensusFcstUnits = +$event
" [ngModelOptions]="{ standalone: true }" id="ActualVartoConsensusFcstUnits" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList('ActualVartoConsensusFcstPst')
">
      <input type="text" [disabled]="true" [ngModel]="
week.CurrentSellThrough
.ActualVartoConsensusFcstPst | percent
" (focusout)="
valueChange(
week.CurrentSellThrough.ActualVartoConsensusFcstPst,
week,
'ActualVartoConsensusFcstPst'
)" [ngModelOptions]="{ standalone: true }" id="ActualVartoConsensusFcstPst" />
    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative ST')">
      <label>&nbsp;</label>
    </tr> -->

    <!-- CurrentSellThrough -->

    <!-- LastYearSales -->
    <!-- <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('LYActualST')">
      <input type="text" [disabled]="false" [ngModel]="
  week.LastYearSales.LYActualST
  | number: '1.2-2'
  " (focusout)="
  valueChange(
  week.CurrentSellThrough.ActualVartoConsensusFcstPst,
  week,
  'ActualVartoConsensusFcstPst'
  )" (ngModelChange)="
  week.LastYearSales.LYActualST = +$event
  " [ngModelOptions]="{ standalone: true }" id="LYActualST" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('LYPromoIndicator')">
      <input type="text" [disabled]="false" [(ngModel)]="
  week.LastYearSales.LYPromoIndicator
  " (focusout)="
  valueChange(
  week.LastYearSales.LYPromoIndicator,
  week,
  'LYPromoIndicator'
  )" [ngModelOptions]="{ standalone: true }" id="LYPromoIndicator" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('LYCOComments')">
      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYCOComments" (focusout)="
  valueChange(
  week.LastYearSales.LYCOComments,
  week,
  'LYCOComments'
  )" [ngModelOptions]="{ standalone: true }" id="LYCOComments" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList('LYCustomerInStockEstimate')
">
      <input type="text" [disabled]="false" [ngModel]="
week.LastYearSales.LYCustomerInStockEstimate
| percent
" (focusout)="
valueChange(
week.LastYearSales.LYCustomerInStockEstimate,
week,
'LYCustomerInStockEstimate'
)" [ngModelOptions]="{ standalone: true }" id="LYCustomerInStockEstimate" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('LYConsensusFcst')">
      <input type="text" [disabled]="false" [ngModel]="
  week.LastYearSales.LYConsensusFcst
  | number: '1.2-2'
  " (focusout)="
  valueChange(
  week.LastYearSales.LYConsensusFcst,
  week,
  'LYConsensusFcst'
  )" (ngModelChange)="
  week.LastYearSales.LYConsensusFcst = +$event
  " [ngModelOptions]="{ standalone: true }" id="LYConsensusFcst" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList('LYActualtoConsensusFcstVarPst')
">
      <input type="text" [disabled]="true" [ngModel]="
week.LastYearSales
.LYActualtoConsensusFcstVarPst | percent
" [ngModelOptions]="{ standalone: true }" id="LYActualtoConsensusFcstVarPst" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList(
'LYActualtoConsensusFcstVarUnits'
)
">
      <input type="text" [disabled]="true" [ngModel]="
week.LastYearSales
.LYActualtoConsensusFcstVarUnits
| number: '1.2-2'
" (ngModelChange)="
week.LastYearSales.LYActualtoConsensusFcstVarUnits = +$event
" [ngModelOptions]="{ standalone: true }" id="LYActualtoConsensusFcstVarUnits" />
    </tr>
    <tr *ngIf="checkIfToShowColumn('Last Year ST')">
      <label>&nbsp;</label>
    </tr> -->

    <!-- LastYearSales -->

    <!-- StAccuracy -->
    <!-- <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList('CustomerSTFcstvsActualST')
">
      <input type="text" [disabled]="true" [ngModel]="
week.StAccuracy.CustomerSTFcstvsActualST
| percent
" [ngModelOptions]="{ standalone: true }" id="CustomerSTFcstvsActualST" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList('AlgoTotalSTFcstvsActualST')
">
      <input type="text" [disabled]="true" [ngModel]="
week.StAccuracy.AlgoTotalSTFcstvsActualST
| percent
" [ngModelOptions]="{ standalone: true }" id="AlgoTotalSTFcstvsActualST" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('COTotalSTFcstvsActualST')">
      <input type="text" [disabled]="true" [ngModel]="
  week.StAccuracy.COTotalSTFcstvsActualST
  | percent
  " [ngModelOptions]="{ standalone: true }" id="COTotalSTFcstvsActualST" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('DPTotalSTFcstvsActualST')">
      <input type="text" [disabled]="true" [ngModel]="
  week.StAccuracy.DPTotalSTFcstvsActualST
  | percent
  " [ngModelOptions]="{ standalone: true }" id="DPTotalSTFcstvsActualST" />
    </tr> -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvsActualST')">

  <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConstrainedSTFcstvsActualST | percent"
  [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvsActualST">

</tr> -->
    <!-- <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList('ConsensusSTFcstvsActualST')
">
      <input type="text" [disabled]="true" [ngModel]="
week.StAccuracy.ConsensusSTFcstvsActualST
| percent
" [ngModelOptions]="{ standalone: true }" id="ConsensusSTFcstvsActualST" />
    </tr>
    <tr *ngIf="checkIfToShowColumn('ST Accuracy')">
      <label>&nbsp;</label>
    </tr> -->

    <!-- StAccuracy -->

    <!-- SellIn -->
    <!-- <tr [ngStyle]="{ background: '#fff' }" *ngIf="
checkIfInList(
'CustomerRequestedSellInReceiveWk'
)
">
      <input [disabled]="false" type="text" (change)="
CustomerRequestedSellInReceiveWkUpdated(
week
)
" (focusout)="
valueChange(
week.SellIn.CustomerRequestedSellInReceiveWk,
week,
'CustomerRequestedSellInReceiveWk'
)" [ngModel]="
week.SellIn.CustomerRequestedSellInReceiveWk
| number
" (ngModelChange)="
week.SellIn.CustomerRequestedSellInReceiveWk = +$event
" [ngModelOptions]="{ standalone: true }" id="CustomerRequestedSellInReceiveWk" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="
checkIfInList('FinalSellInCommitReceiveWk')
">
      <input [disabled]="false" type="text" [ngModel]="
week.SellIn.FinalSellInCommitReceiveWk
| number
" (focusout)="
valueChange(
week.SellIn.FinalSellInCommitReceiveWk,
week,
'FinalSellInCommitReceiveWk'
)" (ngModelChange)="
week.SellIn.FinalSellInCommitReceiveWk = +$event
" [ngModelOptions]="{ standalone: true }" id="FinalSellInCommitReceiveWk" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList(
'FinalSellInCommitvartoCustomerRequest'
)
">
      <input type="text" [disabled]="true" [ngModel]="
week.SellIn
.FinalSellInCommitvartoCustomerRequest
| number
" (focusout)="
valueChange(
week.SellIn.FinalSellInCommitvartoCustomerRequest,
week,
'FinalSellInCommitvartoCustomerRequest'
)" (ngModelChange)="
week.SellIn.FinalSellInCommitvartoCustomerRequest = +$event
" [ngModelOptions]="{ standalone: true }" id="FinalSellInCommitvartoCustomerRequest" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('AlgoSellInFcstReceiveWk')">
      <input type="text" [disabled]="false" [ngModel]="
  week.SellIn.AlgoSellInFcstReceiveWk | number
  " (focusout)="
  valueChange(
  week.SellIn.AlgoSellInFcstReceiveWk,
  week,
  'AlgoSellInFcstReceiveWk'
  )" (ngModelChange)="
  week.SellIn.AlgoSellInFcstReceiveWk = +$event
  " [ngModelOptions]="{ standalone: true }" id="AlgoSellInFcstReceiveWk" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="checkIfInList('DPSellInFcstReceiveWk')">
      <input [disabled]="false" type="text" [ngModel]="
  week.SellIn.DPSellInFcstReceiveWk | number
  " (ngModelChange)="
  week.SellIn.DPSellInFcstReceiveWk = +$event
  " (focusout)="
  valueChange(
  week.SellIn.DPSellInFcstReceiveWk,
  week,
  'DPSellInFcstReceiveWk'
  )" [ngModelOptions]="{ standalone: true }" id="DPSellInFcstReceiveWk" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="checkIfInList('COSellInFcstReceiveWk')">
      <input [disabled]="false" type="text" [ngModel]="
  week.SellIn.COSellInFcstReceiveWk | number
  " (ngModelChange)="
  week.SellIn.COSellInFcstReceiveWk = +$event
  " (focusout)="
  valueChange(
  week.SellIn.COSellInFcstReceiveWk,
  week,
  'COSellInFcstReceiveWk'
  )" [ngModelOptions]="{ standalone: true }" id="COSellInFcstReceiveWk" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="
checkIfInList('ConsensusSellInFcstReceiveWk')
">
      <input [disabled]="false" type="text" [ngModel]="
week.SellIn.ConsensusSellInFcstReceiveWk
| number
" (focusout)="
valueChange(
week.SellIn.ConsensusSellInFcstReceiveWk,
week,
'ConsensusSellInFcstReceiveWk'
)" (ngModelChange)="
week.SellIn.ConsensusSellInFcstReceiveWk = +$event
" [ngModelOptions]="{ standalone: true }" id="ConsensusSellInFcstReceiveWk" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList('FinalSellInCommitsShipWk')
">
      <input type="text" [disabled]="true" [ngModel]="
week.SellIn.FinalSellInCommitsShipWk
| number
" (ngModelChange)="
week.SellIn.FinalSellInCommitsShipWk = +$event
" [ngModelOptions]="{ standalone: true }" id="FinalSellInCommitsShipWk" />
    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative SI')">
      <label>&nbsp;</label>
    </tr> -->

    <!-- SellIn -->

    <!-- CustomerInventory -->
    <!-- <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('CustomerIOHProjection')">
      <input type="text" [disabled]="true" [ngModel]="
  week.CustomerInventory.CustomerIOHProjection
  | number
  " (ngModelChange)="
  week.CustomerInventory.CustomerIOHProjection = +$event
  " [ngModelOptions]="{ standalone: true }" id="CustomerIOHProjection" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('IOHProjection')">
      <input type="text" [disabled]="true" [ngModel]="
  week.CustomerInventory.IOHProjection
  | number
  " (ngModelChange)="
  week.CustomerInventory.IOHProjection = +$event
  " [ngModelOptions]="{ standalone: true }" id="IOHProjection" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('CustomerFWOSCalc')">
      <input type="text" [disabled]="true" [ngModel]="
  week.CustomerInventory.CustomerFWOSCalc
  | number: '1.2-2'
  " (ngModelChange)="
  week.CustomerInventory.CustomerFWOSCalc = +$event
  " [ngModelOptions]="{ standalone: true }" id="CustomerFWOSCalc" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('FBFWOSCalc')">
      <input type="text" [disabled]="true" [ngModel]="
  week.CustomerInventory.FBFWOSCalc
  | number: '1.2-2'
  " (ngModelChange)="
  week.CustomerInventory.FBFWOSCalc = +$event
  " [ngModelOptions]="{ standalone: true }" id="FBFWOSCalc" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('FWOSvartoCustomerTarget')">
      <input type="text" [disabled]="true" [ngModel]="
  week.CustomerInventory
  .FWOSvartoCustomerTarget | number: '1.2-2'
  " (ngModelChange)="
  week.CustomerInventory.FWOSvartoCustomerTarget = +$event
  " [ngModelOptions]="{ standalone: true }" id="FWOSvartoCustomerTarget" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('FWOSvartoFBTarget')">
      <input type="text" [disabled]="true" [ngModel]="
  week.CustomerInventory.FWOSvartoFBTarget
  | number: '1.2-2'
  " (ngModelChange)="
  week.CustomerInventory.FWOSvartoFBTarget = +$event
  " [ngModelOptions]="{ standalone: true }" id="FWOSvartoFBTarget" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="checkIfInList('CustomerStoreOH')">
      <input [disabled]="false" type="text" [ngModel]="
  week.CustomerInventory.CustomerStoreOH
  | number
  " (ngModelChange)="
  week.CustomerInventory.CustomerStoreOH = +$event
  " [ngModelOptions]="{ standalone: true }" id="CustomerStoreOH" />
    </tr>
    <tr [ngStyle]="{ background: '#fff' }" *ngIf="checkIfInList('CustomerDCOH')">
      <input [disabled]="false" type="text" [ngModel]="
  week.CustomerInventory.CustomerDCOH | number
  " (ngModelChange)="
  week.CustomerInventory.CustomerDCOH = +$event
  " (focusout)="
  valueChange(
  week.CustomerInventory.CustomerDCOH,
  week,
  'CustomerDCOH'
  )" [ngModelOptions]="{ standalone: true }" id="CustomerDCOH" />
    </tr>
    <tr *ngIf="
checkIfToShowColumn('Customer Inventory')
">
      <label>&nbsp;</label>
    </tr> -->

    <!-- CustomerInventory -->

    <!-- FBInventory -->
    <!-- <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('FBTotalOnHand')">
      <input type="text" [disabled]="false" [ngModel]="
  week.FBInventory.FBTotalOnHand | number
  " (ngModelChange)="
  week.FBInventory.FBTotalOnHand = +$event
  " (focusout)="
  valueChange(
  week.FBInventory.FBTotalOnHand,
  week,
  'FBTotalOnHand'
  )" [ngModelOptions]="{ standalone: true }" id="FBTotalOnHand" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('FBAvailableAllocation')">
      <input type="text" [disabled]="false" [ngModel]="
  week.FBInventory.FBAvailableAllocation
  | number
  " (focusout)="
  valueChange(
  week.FBInventory.FBAvailableAllocation,
  week,
  'FBAvailableAllocation'
  )" (ngModelChange)="
  week.FBInventory.FBAvailableAllocation = +$event
  " [ngModelOptions]="{ standalone: true }" id="FBAvailableAllocation" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('OnOrder')">
      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.OnOrder | number" (ngModelChange)="
  week.FBInventory.OnOrder = +$event
  " (focusout)="
  valueChange(
  week.FBInventory.OnOrder,
  week,
  'OnOrder'
  )" [ngModelOptions]="{ standalone: true }" id="OnOrder" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('InTransit')">
      <input type="text" [disabled]="false" [ngModel]="
  week.FBInventory.InTransit | number
  " (ngModelChange)="
  week.FBInventory.InTransit = +$event
  " (focusout)="
  valueChange(
  week.FBInventory.InTransit,
  week,
  'InTransit'
  )" [ngModelOptions]="{ standalone: true }" id="InTransit" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('InventoryReceived')">
      <input type="text" [disabled]="false" [ngModel]="
  week.FBInventory.InventoryReceived | number
  " (focusout)="
  valueChange(
  week.FBInventory.InventoryReceived,
  week,
  'InventoryReceived'
  )" (ngModelChange)="
  week.FBInventory.InventoryReceived = +$event
  " [ngModelOptions]="{ standalone: true }" id="InventoryReceived" />
    </tr>
    <tr *ngIf="checkIfToShowColumn('FB Inventory')">
      <label>&nbsp;</label>
    </tr> -->

    <!-- FBInventory -->

    <!-- KPI -->
    <!-- <tr [ngStyle]="{ background: '#eee' }" *ngIf="
checkIfInList('OnTimeDeliveryPerformance')
">
      <input type="text" [disabled]="true" [ngModel]="
week.KPI.OnTimeDeliveryPerformance | percent
" (ngModelChange)="
week.KPI.OnTimeDeliveryPerformance = +$event
" [ngModelOptions]="{ standalone: true }" id="OnTimeDeliveryPerformance" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('OnTimeInFull')">
      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeInFull | percent" (ngModelChange)="
  week.KPI.OnTimeInFull = +$event
  " [ngModelOptions]="{ standalone: true }" id="OnTimeInFull" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('CustomerChargebacks')">
      <input type="text" [disabled]="true" [ngModel]="
  week.KPI.CustomerChargebacks | currency
  " (ngModelChange)="
  week.KPI.CustomerChargebacks = +$event
  " [ngModelOptions]="{ standalone: true }" id="CustomerChargebacks" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('StandardDeliveryPst')">
      <input type="text" [disabled]="true" [ngModel]="
  week.KPI.StandardDeliveryPst | percent
  " (ngModelChange)="
  week.KPI.StandardDeliveryPst = +$event
  " [ngModelOptions]="{ standalone: true }" id="StandardDeliveryPst" />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('ExpeditedDeliveryPst')">
      <input type="text" [disabled]="true" [ngModel]="
  week.KPI.ExpeditedDeliveryPst | percent
  " (ngModelChange)="
  week.KPI.ExpeditedDeliveryPst = +$event
  " [ngModelOptions]="{ standalone: true }" id="ExpeditedDeliveryPst" />
    </tr>
    <tr *ngIf="checkIfToShowColumn('KPIs')">
      <label>&nbsp;</label>
    </tr> -->

    <!-- KPI -->
  </td>
</ng-template>
