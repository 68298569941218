<!-- Main Content area -->
<div id="fb-forecast" class="container-fluid" [style.height] = "'100vh'">
  <div class="fb-forecast-accordion item-wrapper-panel">
    <div class="item-wrapper">
      <form [formGroup]="registerForm" class="form-fields">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="roleName">Role Name</label>
              <input type="text" id="teamName" formControlName="roleName" />
            </div>
            <div class="form-group">
              <label for="status">Status</label>
              <mat-slide-toggle formControlName="active">Active
              </mat-slide-toggle>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row no-gutters select-arrow">
              <div class="col-md-2">
                <label style="padding-top: 5px; font-size: 12px;" for="roleName">Add Users</label>
              </div>
              <div class="col-md-10">
                <div *ngFor="let user of (f.controls && f.controls.applicationUsers.value || []) ; let i=index"
                  class="form-group">
                  <label>User Name</label>
                  <input [value]="user.firstName + ' ' + user.lastName" readonly>
                  <i class="icon-cross-slim" (click)="removeUser(user)"></i>
                </div>
                <div class="form-group">
                  <label>Add Member</label>
                  <mat-select formControlName="users" (selectionChange)="addUserToList()">
                    <mat-option *ngFor="let user of (usersList || []); let i = index" [value]="user">{{
                      user.firstName + ' ' + user.lastName }}</mat-option>
                  </mat-select>
                  <i class="icon-add"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="row permission-wrapper">
        <div class="col-12">
          <h3>New Role Permissions</h3>
        </div>
        <div class="col-md-6">
          <div class="create-wrapper">
            <div class="form-group">
              <label for="products">Product Permissions</label>
              <app-treeview-dropdown [type]="'Channel'" [items]="channelDropdownList" (valueChange)="setValue($event)">
              </app-treeview-dropdown>
              <!-- <app-treeview-dropdown *ngIf="showProductList" [type]="'Item'" [items]="productList">
            </app-treeview-dropdown> -->
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="create-wrapper">
            <div class="form-group">
              <label for="products">Account Permissions</label>
              <app-treeview-dropdown [type]="'Item'" [items]="productList" (valueChange)="setValue($event)">
              </app-treeview-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="action-btns text-right">
        <button mat-raised-button class="btn btn-primary m-left" color="primary"
          (click)="onSubmit(errorTemplate,errorRoleCodeTemplate)">{{isEdit
          ? 'Update' : 'Create'}}</button>

      </div>
    </div>
  </div>
</div>


<ng-template #errorTemplate>
  <h1 mat-dialog-title>Error</h1>
  <div mat-dialog-content>
    <p>The name of role already exists. Please update the name.</p>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="dialogRef.close()">Ok</button>
  </div>
</ng-template>
<ng-template #errorRoleCodeTemplate>
  <h1 mat-dialog-title>Error</h1>
  <div mat-dialog-content>
    <p>This role code already exists. Please update the role code.</p>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="dialogRef.close()">Ok</button>
  </div>
</ng-template>
