<!-- Main Content area -->
<div class="container-fluid">
  <!-- <h2 class="form-heading">Levels</h2> -->
  <div class="action-panel text-right">
    <button style="width: 40px" class="btn btn-secondary" (click)="openExcelImportDialog({})" color="primary"
      mat-icon-button>
      <i style="font-size: 30px;cursor:pointer;" class="material-icons" aria-label="Column Show/Hide">
        import_export
      </i>
    </button>
    <button mat-raised-button class="btn btn-primary m-left" color="primary"
      (click)="openCategoryDialog({}, menuCreateTemplate,isEdit)">Add Level <i class="icon-add"></i></button>
  </div>
  <ejs-treeview id="tree" #tree [fields]="field" [allowEditing]="allowEditing" (nodeEditing)='editing($event)'
    (nodeEdited)='onNodeEdited($event)'>
    <ng-template #nodeTemplate="" let-data="">
      <div style="pointer-events: all;">
        <div class="list-wrapper">
          <div class="list-header">
            <h2>
              {{data.levelName}}
            </h2>
            <div class="action-panel">
              <button mat-raised-button class="btn btn-primary"
                (click)="openCategoryDialog(data,menuCreateTemplate, false)">
                <span>Sub Level</span>
                <i class="icon-add"></i>
              </button>
              <button style="width: 40px" class="btn btn-secondary" color="primary" mat-icon-button>
                <mat-icon (click)="openCategoryDialog(data,menuCreateTemplate, true)"><i style="cursor: pointer;"
                    class="material-icons">edit</i>
                </mat-icon>
              </button>
              <button style="width: 40px" class="btn btn-secondary" color="primary" mat-icon-button>
                <mat-icon (click)="openConfirmationDialog(data)"><i style="cursor: pointer;"
                    class="material-icons">clear</i>
                </mat-icon>
              </button>
            </div>
          </div>

          <div class="list-details row">
            <div class="list-details-inner col-md-4">
              <label>Tasks: </label>
              <span>
                {{getTasks(data.tasks)}}
              </span>
            </div>
            <div class="list-details-inner col-md-4">
              <label>Approvals: </label>
              <span>
                {{getTasks(data.approvals)}}
              </span>
            </div>
            <div class="list-details-inner col-md-4">
              <label>Views: </label>
              <span>
                {{getTasks(data.views)}}
              </span>
            </div>
            <div class="list-details-inner col-md-4">
              <label>Users: </label>
              <span>
                {{getUsers(data.userlevels)}}
              </span>
            </div>
          </div>
          <div class="internal-info">
          </div>
        </div>

      </div>
    </ng-template>
  </ejs-treeview>
</div>
<ng-template #menuCreateTemplate let-data>
  <div mat-dialog-content>
    <form [formGroup]="registerForm" class="form-fields">
      <div class="row">
        <div class="col-md-4 form-group">
          <mat-form-field>
            <input matInput formControlName="levelName" placeholder="Title">
          </mat-form-field>
          <mat-error *ngIf="submitted && registerForm.controls['levelName'].hasError('required')">
            *Required Field
          </mat-error>
        </div>
        <div class="col-md-4 form-group">
          <mat-form-field>
            <mat-select placeholder="Tasks" formControlName="tasks" [multiple]="true" [compareWith]="compareFn">
              <mat-option *ngFor="let res of workgroupList; let i = index" [value]="res">
                {{res.workgroupName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="submitted && registerForm.controls['tasks'].hasError('required')">
              *Required Field
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4 form-group">
          <mat-form-field>
            <mat-select placeholder="Approvals" formControlName="approvals" [multiple]="true" [compareWith]="compareFn">
              <mat-option *ngFor="let res of workgroupList; let i = index" [value]="res">
                {{res.workgroupName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="submitted && registerForm.controls['approvals'].hasError('required')">
              *Required Field
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4 form-group">
          <mat-form-field>
            <mat-select placeholder="Views" formControlName="views" [multiple]="true" [compareWith]="compareFn">
              <mat-option *ngFor="let res of workgroupList; let i = index" [value]="res">
                {{res.workgroupName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="submitted && registerForm.controls['views'].hasError('required')">
              *Required Field
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="action-panel text-right">
        <button mat-raised-button class="btn btn-primary m-left" type="submit"
          (click)="openDialogUserList(dialogListing, data)" color="primary">User</button>
        <button mat-raised-button class="btn btn-primary m-left" type="submit" (click)="onSubmit(data)"
          color="primary">Save</button>
        <button mat-raised-button class="btn m-left btn-light" color="warn"
          (click)="dialogAddRef.close()">Cancel</button>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #dialog let-data>
  <div class="show-hide">
    <h1 mat-dialog-title>Show/Hide Columns</h1>
    <span (click)="addUserDialogRef.close()" class="material-icons clear">clear</span>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <mat-checkbox maxlength="20" [(ngModel)]="selectAll" (ngModelChange)="selectAllSelected($event)">Select/Remove All
      </mat-checkbox>
      <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSave(userList)">Add</button>
    </div>
    <div mat-dialog-content>
      <div style="display: flex;flex-direction: column;">
        <mat-checkbox maxlength="20" *ngFor="let item of tempUserList" [(ngModel)]="item.show">{{item.firstName}}
          {{item.lastName}}
        </mat-checkbox>
      </div>
    </div>
  </div>

</ng-template>
<ng-template #dialogListing let-data>
  <div class="container add-sub-level">
    <div class="user-page">
      <div class="row">
        <div class="col-md-1 d-block d-sm-block d-md-none mobile-icon">
          <div class="btn-show">
            <button (click)="openAddDialog({})" mat-icon-button color="primary">
              <i class="material-icons">
                add_circle
              </i>
            </button>
          </div>
        </div>
        <div class="col-md-4">
          <form action="" class="form-fields show-search">
            <div class="form-group">
              <mat-form-field>
                <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
          </form>
        </div>
        <div class="col-md-8 d-none d-sm-none d-md-block tablet-icon">
          <div class="btn-show">
            <button (click)="addUserListToLevel()" mat-raised-button color="primary">Done
            </button>
            <button style="width: 40px" class="btn btn-secondary" (click)="openAddDialog({})" color="primary"
              mat-icon-button>
              <i style="font-size: 30px;cursor:pointer; color: #fff; margin-left: 0;" class="material-icons">
                add
              </i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="all-show-grid">
      <ag-grid-angular class="ag-theme-material"
        [suppressMenuHide]="true"  groupHeaders suppressRowClickSelection toolPanelSuppressGroups
        toolPanelSuppressValues [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="linkedUserList || []"
        (gridReady)="onGridReady($event)" [modules]="modules" [rowDragManaged]="true" [animateRows]="true" #grid>
      </ag-grid-angular>
    </div>
  </div>
</ng-template>
<ng-template #ExcelImport let-data>
  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <h1 mat-dialog-title>Import</h1>
      <a href="assets/HirarchyTemplate.xlsx" class="btn btn-primary" color="primary"
        (click)="getTemplate()">Download Template</a>
    </div>
    <div>
      <ngx-dropzone (change)="onSelect($event)">
        <ngx-dropzone-label>Drop files here!</ngx-dropzone-label>
        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>
    <!-- <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview> -->
  </div>
</ng-template>
