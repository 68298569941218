<!-- Main Content area -->
<div class="container">
  <div class="row">
  <div class="col-md-4 form-group">
    <mat-form-field>
      <mat-select placeholder="Select Channel" [(ngModel)]="channel" [ngModelOptions]="{standalone: true}"
        (ngModelChange)="getSameStoreFormList($event)">
        <mat-option *ngFor="let res of channelList; let i = index" [value]="res">
          {{res.channelName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  </div>
  <div *ngIf="channel" class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit()">Save</button>
    <button (click)="openStoreDialog()" class="btn btn-primary" color="primary" mat-raised-button>
      Add Store
      <i class="icon-add"></i>
    </button>
    <button mat-raised-button class="btn btn-primary" color="primary" (click)="openColumnDialog()">
      Add Header Name
      <i class="icon-add"></i>
    </button>
  </div>
  <div *ngIf="channel" class="all-show-grid"  >
    <ag-grid-angular   class="ag-theme-material"
       groupHeaders suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
      [pagination]="true" [gridOptions]="gridOptions" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20' [headerHeight]="headerHeight" [getRowHeight]="getRowHeight"
      [columnDefs]="columnDefs" [rowData]="storeList"
      (cellClicked)="populateForm($event)" (gridReady)="onGridReady($event)" [modules]="modules" #grid>
    </ag-grid-angular>
  </div>
</div>
