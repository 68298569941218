<div class="d-flex align-items-center m-2">
    <button mat-raised-button color="accent" class="mr-4" (click)="importTargets()">Import Targets</button>
    <h6 class="m-0">Company Level Monthly Targets</h6>
</div>
<div class="d-flex align-items-center">
    <div class="d-flex align-items-center m-2 mt-3">
        <mat-radio-group [(ngModel)]="radioValue" aria-label="Select an option">
            <mat-radio-button value="1">
                <mat-form-field appearance="outline">
                    <mat-label>Brand</mat-label>
                    <mat-select [disabled]="radioValue!='1'" [(ngModel)]="selectedBrand">
                        <mat-option *ngFor="let item of brands" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-radio-button>
            <mat-radio-button value="2" class="ml-5">
                <mat-form-field appearance="outline">
                    <mat-label>Department</mat-label>
                    <mat-select [disabled]="radioValue!='2'" [(ngModel)]="selectedGender">
                        <mat-option *ngFor="let item of genders" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-radio-button>
            <mat-radio-button value="3" class="ml-5">
                <mat-form-field appearance="outline">
                    <mat-label>Category</mat-label>
                    <mat-select [disabled]="radioValue!='3'" [(ngModel)]="selectedCategory">
                        <mat-option *ngFor="let item of categories" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>
<div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center m-2">
        <mat-slide-toggle [(ngModel)]="revenue" (change)="cellValueChanged()" labelPosition="before" color="warn">
            Revenue
        </mat-slide-toggle>
        <mat-slide-toggle [(ngModel)]="cost" (change)="cellValueChanged()" labelPosition="before" class="ml-5"
            color="warn">
            Cost
        </mat-slide-toggle>
        <mat-slide-toggle [(ngModel)]="unit" (change)="cellValueChanged()" labelPosition="before" class="ml-5"
            color="warn">
            Units
        </mat-slide-toggle>
    </div>
    <div class="separator"></div>
    <div class="d-flex align-items-center m-2">
        <mat-slide-toggle checked labelPosition="before" color="warn">
            Retail
        </mat-slide-toggle>
        <mat-slide-toggle checked labelPosition="before" class="ml-5" color="warn">
            Wholesale
        </mat-slide-toggle>
        <mat-slide-toggle checked labelPosition="before" class="ml-5" color="warn">
            Online
        </mat-slide-toggle>
    </div>
    <div class="separator"></div>
    <div class="m-2">
        <mat-form-field appearance="outline">
            <mat-select (selectionChange)="durationChanged()" [(ngModel)]="selectedDuration">
                <mat-option *ngFor="let item of durations" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material" [suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups
        toolPanelSuppressValues [frameworkComponents]="frameworkComponents" [columnDefs]="gridColumnDefs"
        [rowData]="gridData" [pagination]="false" (gridReady)="onGridReady($event)" [modules]="modules"
        (cellValueChanged)="cellValueChanged(true)" #topDownGrid>
    </ag-grid-angular>
</div>

<hr class="theme-hr" />

<div class="w-100 d-flex align-items-center justify-content-center">
<fusioncharts [width]="width" [height]="height" [type]="type" [dataFormat]="dataFormat" [dataSource]="dataSource">
</fusioncharts>
</div>