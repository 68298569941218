import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { FBMeasureProfile } from '../../fb-forecast/forecast-measures/models/fb-measure-profile.model';

@Component({
  selector: 'app-graph-measures',
  templateUrl: './graph-measures.component.html',
  styleUrls: ['./graph-measures.component.scss']
})
export class GraphMeasuresComponent implements OnInit {
  maxProfileCount = 20;
  public filteredMeasures = [];
  selectAll = false;
  constructor(
    public dialogRef: MatDialogRef<GraphMeasuresComponent>,
    public spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public config: ConfigurationService,
    public storage: LocalstorageService
  ) {}

  ngOnInit() {
    this.data.measureList = JSON.parse(JSON.stringify(this.data.measureList.filter(row => row.isGraph)));
    const udpMeasures = this.data.measureList.filter(row => row.parentId === 10)
    const normalMeasures = this.data.measureList.filter(row => row.parentId !== 10).sort((a,b) => a.measureName > b.measureName ? 1 : -1);
    this.data.measureList = [...normalMeasures, ...udpMeasures];
  }
  applyMeasure() {
    this.spinner.show();
    this.data.profileApplied = true;
    this.dialogRef.close(this.data);
  }
  selectAllChanged = (value) => {
    this.data.measureList.forEach((element) => {
      element.checked = value.checked;
    });
  };
}
