<!-- Main Content area -->
<div class="wrapper col-md-12">
  <mat-card-header>
    <mat-card-title>
      <!-- <mat-icon (click)="location.back()" class="cursor"> <i class="material-icons">
          arrow_back
        </i></mat-icon> -->
      <!-- <h3 class="form-heading">Customer</h3> -->
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="form-fields">
      <div class="row">
        <div class="col-md-4 form-group">
          <mat-form-field>
            <input matInput formControlName="channelName" placeholder="Channel Name">
          </mat-form-field>
        </div>
        <div class="col-md-4 form-group">
          <mat-form-field>
            <input matInput formControlName="description" placeholder="Description">
          </mat-form-field>
        </div>
        <div class="col-md-4 form-group">
          <label></label>
          <mat-slide-toggle formControlName="active">Active
          </mat-slide-toggle>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div class="action-btns text-right">
      <button mat-raised-button class="btn btn-primary m-left" color="primary"
        (click)="onSubmit()">{{isEdit ? 'Update' : 'Create'}}</button>
      <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="Cancel_Click()">Cancel</button>
    </div>
  </mat-card-actions>
</div>
<div class="all-show-grid col-md-12">
  <ag-grid-angular class="ag-theme-material"
       groupHeaders suppressRowClickSelection
    toolPanelSuppressGroups toolPanelSuppressValues domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20' [headerHeight]="headerHeight" [getRowHeight]="getRowHeight" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
    [rowData]="channelList" (cellClicked)="populateForm($event)" (gridReady)="onGridReady($event)" [modules]="modules" #grid></ag-grid-angular>
</div>

