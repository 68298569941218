<ng-template #tmplNode let-node="node" let-type="type">
    <div class="node-item" [attr.data-id]="node.id" [attr.id]="'node-'+node.id">

        <div class="node-title" [ngClass]="(node.id==selectedNode?.id)?'selected':''" (click)="selectNode(node)">
            {{node.children.length ? (node.isExpanded?'-&nbsp;':'+') : '&nbsp;&nbsp;&nbsp;'}} &nbsp;&nbsp;&nbsp;
            {{node.id}}
            <!-- <span class="item-notes"></span> -->
        </div>

        <div *ngIf="node.isExpanded && node.children.length" class="node-children" [id]="node.id">

            <div *ngFor="let child of node.children">
                <ng-container *ngTemplateOutlet="tmplNode,context:{node:child, type: type}"></ng-container>
            </div>

        </div>

    </div>
</ng-template>


<h1 mat-dialog-title>Merchandise Financial Planning Setup</h1>
<div mat-dialog-content>
    <mat-horizontal-stepper (selectionChange)="selectNode(null)" linear #stepper>
        <mat-step>
            <ng-template matStepLabel>Time</ng-template>
            <div class="row">
                <div class="col-5 dragDropBox">
                    <div [id]="'main1'">
                        <div *ngFor="let node of nodes">
                            <ng-container *ngTemplateOutlet="tmplNode,context:{node:node, type: '1'}"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-2 d-flex flex-column align-items-center justify-content-center">
                    <button mat-raised-button color="accent" (click)="add(1)" class="mb-2 w-100">Add ></button>
                    <button mat-raised-button color="accent" (click)="addAll(1)" class="mb-2 w-100">Add All >></button>
                    <button mat-raised-button color="accent" (click)="remove(1)" class="mb-2 w-100">&lt; Remove</button>
                    <button mat-raised-button color="accent" (click)="removeAll(1)" class="mb-2 w-100">&lt;&lt; Remove
                        All</button>
                </div>
                <div class="col-5 dragDropBox">
                    <div [id]="'main2'">
                        <div *ngFor="let node of dropNodes">
                            <ng-container *ngTemplateOutlet="tmplNode,context:{node:node, type: '2'}"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-items-center mt-2">
                <div class="mr-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Plan Name</mat-label>
                        <input [(ngModel)]="workbookName" matInput />
                    </mat-form-field>
                </div>
                <button mat-button matStepperNext color="accent">Next</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Channel</ng-template>
            <div class="row">
                <div class="col-5 dragDropBox">
                    <div>
                        <div *ngFor="let node of Channel_TREE_DATA">
                            <ng-container *ngTemplateOutlet="tmplNode,context:{node:node}"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-2 d-flex flex-column align-items-center justify-content-center">
                    <button mat-raised-button color="accent" (click)="add(2)" class="mb-2 w-100">Add ></button>
                    <button mat-raised-button color="accent" (click)="addAll(2)" class="mb-2 w-100">Add All >></button>
                    <button mat-raised-button color="accent" (click)="remove(2)" class="mb-2 w-100">&lt; Remove</button>
                    <button mat-raised-button color="accent" (click)="removeAll(2)" class="mb-2 w-100">&lt;&lt; Remove
                        All</button>
                </div>
                <div class="col-5 dragDropBox">
                    <div>
                        <div *ngFor="let node of drop_Channel_TREE_DATA">
                            <ng-container *ngTemplateOutlet="tmplNode,context:{node:node}"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-items-center mt-2">
                <div class="mr-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Plan Name</mat-label>
                        <input [(ngModel)]="workbookName" matInput />
                    </mat-form-field>
                </div>
                <button mat-button matStepperPrevious color="accent">Back</button>
                <button mat-button matStepperNext color="accent">Next</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Product</ng-template>
            <div class="row">
                <div class="col-5 dragDropBox">
                    <div *ngFor="let node of Product_TREE_DATA">
                        <ng-container *ngTemplateOutlet="tmplNode,context:{node:node, type: '2'}"></ng-container>
                    </div>
                </div>
                <div class="col-2 d-flex flex-column align-items-center justify-content-center">
                    <button mat-raised-button color="accent" (click)="add(3)" class="mb-2 w-100">Add ></button>
                    <button mat-raised-button color="accent" (click)="addAll(3)" class="mb-2 w-100">Add All >></button>
                    <button mat-raised-button color="accent" (click)="remove(3)" class="mb-2 w-100">&lt; Remove</button>
                    <button mat-raised-button color="accent" (click)="removeAll(3)" class="mb-2 w-100">&lt;&lt; Remove
                        All</button>
                </div>
                <div class="col-5 dragDropBox">
                    <div *ngFor="let node of drop_Product_TREE_DATA">
                        <ng-container *ngTemplateOutlet="tmplNode,context:{node:node, type: '2'}"></ng-container>
                    </div>
                </div>
            </div>
            <div class="row align-items-center mt-2">
                <div class="mr-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Plan Name</mat-label>
                        <input [(ngModel)]="workbookName" matInput />
                    </mat-form-field>
                </div>
                <button mat-button matStepperPrevious color="accent">Back</button>
                <button mat-button (click)="create()">Create Plan</button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>