<div class="container-fluid">
  <div class="wrapper col-md-12">
    <mat-card-header>
      <!-- <button class="close" mat-button (click)="onNoClick()">X</button> -->
      <mat-card-title>
      </mat-card-title>
    </mat-card-header>
    <mat-card-actions>
      <div class="action-btns text-right">
        <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit()">Save</button>
      </div>
    </mat-card-actions>
    <mat-card-content>
      <form [formGroup]="promotionForm" (ngSubmit)="onSubmit()" class="form-fields">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="disable_ripple" collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title> Promotion Information
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="promotionName" placeholder="Enter Promotion Name">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Customer" formControlName="customers" multiple>
                  <mat-option *ngFor="let res of customerList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field class="example-full-width">
                <input (focus)="picker1.open()" and (click)="picker1.open()" formControlName="startDate" matInput
                  [max]="promotionForm.controls.endDate.value" [matDatepicker]="picker1"
                  placeholder="Select Start Date">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error *ngIf="submitted && promotionForm.controls['startDate'].hasError('required')">
                  *Required Field
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field class="example-full-width">
                <input (focus)="picker.open()" and (click)="picker.open()" formControlName="endDate" matInput
                  [min]="promotionForm.controls.startDate.value" [matDatepicker]="picker" placeholder="Select End Date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="submitted && promotionForm.controls['endDate'].hasError('required')">
                  *Required Field
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Class" formControlName="classes" multiple>
                  <mat-option *ngFor="let res of classList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Sub Class" formControlName="subClasses" multiple>
                  <mat-option *ngFor="let res of subClassList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Fund Type" formControlName="fundTypeId">
                  <mat-option *ngFor="let res of fundTypeList; let i = index" [value]="res.fundTypeId">
                    {{res.fundTypeName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="updateRMF" placeholder="Enter Update RMF %">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="returnRate" placeholder="Enter Return Rate %">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="returnOffsetWeeks" placeholder="Enter Return Offset Weeks">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="shipmentOffsetWeeks" placeholder="Enter Shipment Offset Weeks">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <label></label>
              <mat-slide-toggle formControlName="active">Active
              </mat-slide-toggle>
            </div>
          </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="disable_ripple" collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title>Marketing Information
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="promotionDescription" placeholder="Enter Promotion Description">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Primary Placement" formControlName="primaryPlacement">
                  <mat-option [value]="true">Yes </mat-option>
                  <mat-option [value]="false">No </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Secondary Placement" formControlName="secondaryPlacement">
                  <mat-option [value]="true">Yes </mat-option>
                  <mat-option [value]="false">No </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Promotion Product Type" formControlName="productType" multiple>
                  <mat-option *ngFor="let res of promotionProductType; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Consumer Promotion" formControlName="consumerPromotion" multiple>
                  <mat-option *ngFor="let res of promotionProductType; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Advertisement Description" formControlName="advertisementDescription"
                  multiple>
                  <mat-option *ngFor="let res of advertisementDescList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="categoryStrategy" placeholder="Enter Category Strategy">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <label></label>
              <mat-slide-toggle formControlName="active">Active
              </mat-slide-toggle>
            </div>
          </div>
        </mat-expansion-panel>
      </form>
      <br>
      <app-sku-list-link *ngIf="skuPromotion" [skuPromotionList]="skuPromotion"></app-sku-list-link>
    </mat-card-content>
  </div>
</div>
