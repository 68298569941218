import { SharedDataService } from 'src/services/shared-data.service';
import { AllModules, ColumnApi, GridApi, GridOptions } from '@ag-grid-enterprise/all-modules';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationService } from 'src/services/configuration.service';
import { ForecastLookupService } from 'src/services/forecast-services/forecast-lookup-service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { defaultColDef, getSeasonalityColDefs } from '../ag-grid/grid-options';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { SeasonalityService } from 'src/services/seasonality-services/seasonality.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ClientPreferenceService } from 'src/services/client-services/client-preference.service';
import { ForecastManagerService } from 'src/services/forecast-services/forecast-manager.service';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';


interface SeasonalityHierarchy {
  retailer: string,
  retailerKey: string,
  brand?: string,
  productGroup?: string,
  productDescription?: string,
  sku?: string,

}

interface ValidateSeasonalityHierarchy {
  retailer: boolean,
  brand?: boolean,
  productGroup?: boolean,
  productDescription?: boolean,
  sku?: boolean,
}

@Component({
  selector: 'app-seasonality-setup',
  templateUrl: './seasonality-setup.component.html',
  styleUrls: ['./seasonality-setup.component.scss']
})
export class SeasonalitySetupComponent implements OnInit {
  // @Input() data?: any;

  SKIP_FORM_KEYS: any = ['startWeekDate', 'endWeekDate', 'namePrefix'];

  seasonalityForm = new FormGroup({
    active: new FormControl(true, Validators.required),
    seasonalityType: new FormControl('static', Validators.required),
    startWeekDate: new FormControl('', Validators.required),
    endWeekDate: new FormControl('', Validators.required),
    rollingWeek: new FormControl(52),
    creationType: new FormControl('standalone', Validators.required),
    seasonalityLevelId: new FormControl('', Validators.required),
    namePrefix: new FormControl('', Validators.required),
  });

  paginationPageSize = 50;

  cacheBlockSize = 50;
  public modules = AllModules;
  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  rowSelection = 'multiple';
  public columnDefs = getSeasonalityColDefs({}, this);
  public defaultColDef = defaultColDef;

  lookupItemList: any = [];
  seasonalityLevelList: any = [];
  numberOfWeeksDifference = 0;
  isValidWeeks: boolean = true;
  endDateMin: Date;
  endDateMax: Date;
  startDateMax: Date;
  labelDynamicText: any;
  isCopyOperation: boolean = false;
  seasonalityId;
  selectedAccounts = [];
  accountsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'retailerKey',
    textField: 'retailer',
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: 'No Data Available'
  };
  skuDescriptionDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'sku',
    textField: 'productDescription',
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: 'No Data Available'
  };

  skuDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'sku',
    textField: 'sku',
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: 'No Data Available'
  };

  clientWeekStartDay: number = 0;

  seasonalityHierarchyList: SeasonalityHierarchy[] = [];
  validSeasonalityHierarchyList: ValidateSeasonalityHierarchy[] = [];

  filteredRetailerLookupItems: Observable<any[]>;
  filteredBrandLookupItems: Observable<any[]>;
  filteredProductGroupLookupItems: Observable<any[]>;
  filteredProductDescriptionLookupItems: Observable<any[]>;
  filteredLookupSkus: Observable<any[]>;

  constructor(

    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public seasonalityService: SeasonalityService,
    public forecastLookupService: ForecastLookupService,
    public configurationService: ConfigurationService,
    public _dataService: SharedDataService,
    public dialogRef: MatDialogRef<SeasonalitySetupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public clientPreferenceService: ClientPreferenceService,
    public forecastManagerService: ForecastManagerService,
  ) { }

  get startWeekDate() {
    return this.seasonalityForm.get('startWeekDate');
  }

  get seasonalityType() {
    return this.seasonalityForm.get('seasonalityType');
  }

  get seasonalityLevelId() {
    return this.seasonalityForm.get('seasonalityLevelId');
  }

  get seasonalityLevelCode() {
    const value = this.seasonalityLevelId.value;
    const level = this.seasonalityLevelList.find(level => value && level.seasonalityLevelId === value);
    return level ? level.seasonalityLevelCode : '';
  }

  get checkWeekDiff() {
   var value=this.seasonalityForm.get('seasonalityType').value

    if (this.seasonalityForm.get('startWeekDate').value
      && this.seasonalityForm.get('endWeekDate').value && value=='static')  return true;
    else return false;
  }

  get getWeeksDiff() {
    return Math.floor(this.getDaysDiff / 7);
  }

  get getDaysDiff() {
    const date1 = new Date(this.seasonalityForm.get('startWeekDate').value) as any;
    const date2 = new Date(this.seasonalityForm.get('endWeekDate').value) as any;
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 8;
    return diffDays;
  }

  ngOnInit(): void {
    this.getLookupItemsList();
    if (this.data.seasonalityId) {
      this.initCopyProcess();
    }
    if (this.data.isSeasonalityPendingOperation) {
      this.setPendingCombination();
    }
    this.getSeasonalityLevels();
    this.loadClientPreference();
  }

  removeAutocompleteFocus() {
    let element = document.querySelector('.mat-autocomplete-panel');
    if (element) {
      element.parentNode.removeChild(element);
    }
  }

  setPendingCombination() {
    const seasonalityData = _.uniqBy(this.data.seasonalityData, a => { return [a.retailerKey, a.sku].join(); });
    this.seasonalityHierarchyList = seasonalityData;
    this.initValidSeasonalityHierarchyList(this.seasonalityHierarchyList.length);
  }

  initCopyProcess() {
    const data = this.data;
    this.isCopyOperation = data.isCopyOperation ? data.isCopyOperation : false;
    if (this.isCopyOperation) {
      this.seasonalityId = data.seasonalityId;
      this.getSeasonalityById(this.seasonalityId);
    }
  }

  getSeasonalityById(seasonalityId) {
    this.seasonalityService.GetSeasonalityById(seasonalityId).subscribe(seasonalityData => {
      this.loadSeasonalityFormValues(seasonalityData);
    });
  }

  loadSeasonalityFormValues(seasonalityData) {
    const formData = _.cloneDeep(_.omit(seasonalityData, this.SKIP_FORM_KEYS));
    this.seasonalityForm.patchValue(formData);
    this.seasonalityHierarchyList = seasonalityData.seasonalityHierarchyLevels;
    this.initValidSeasonalityHierarchyList(this.seasonalityHierarchyList.length);
    this.seasonalityTypeChange({ value: this.seasonalityForm.get('seasonalityType').value });
  }

  initValidSeasonalityHierarchyList(length) {
    for (let i = 0; i < length; i++) {
      this.pushValidSeasonalityHierarchyObject();
    }
  }

  pushValidSeasonalityHierarchyObject() {
    this.validSeasonalityHierarchyList.push({
      retailer: true,
      brand: true,
      productGroup: true,
      productDescription: true,
      sku: true
    });
  }

  getSeasonalityLevels() {
    this.seasonalityService.GetSeasonalityLookupList().subscribe((seasonalityLevel: any) => {
      this.seasonalityLevelList = this.whiteSeasonalityLevels(seasonalityLevel);
      this.setDefaultSeasonalityLevel();
    });
  }

  setDefaultSeasonalityLevel() {
    if (!this.isCopyOperation) {
      const defaultSelect = this.seasonalityLevelList.find(c => c.seasonalityLevelCode === 'ACSKU');
      this.seasonalityForm.get('seasonalityLevelId').setValue(defaultSelect.seasonalityLevelId);
    }
  }

  loadClientPreference() {
    this.clientWeekStartDay = this.clientPreferenceService.getClientWeekStartDay();
  }

  whiteSeasonalityLevels(seasonalityLevel: any) {
    let whiteSeasonalityLevel = [];

    seasonalityLevel.forEach(
      (value) => {
        if (value.seasonalityLevelCode === 'AC')
          value.seasonalityLevelDescription = this._dataService.getDataById(1);
        else if (value.seasonalityLevelCode === 'B')
          value.seasonalityLevelDescription = this._dataService.getDataById(2);
        else if (value.seasonalityLevelCode === 'PG')
          value.seasonalityLevelDescription = this._dataService.getDataById(3);
        else if (value.seasonalityLevelCode === 'BAC')
          value.seasonalityLevelDescription = `${this._dataService.getDataById(1)} & ${this._dataService.getDataById(2)}`;
        else if (value.seasonalityLevelCode === 'PGAC')
          value.seasonalityLevelDescription = `${this._dataService.getDataById(1)} & ${this._dataService.getDataById(3)}`;
        else if (value.seasonalityLevelCode === 'SK')
          value.seasonalityLevelDescription = this._dataService.getDataById(5);
        else if (value.seasonalityLevelCode === 'ACSKU')
          value.seasonalityLevelDescription = `${this._dataService.getDataById(1)} & ${this._dataService.getDataById(4)}`;
        whiteSeasonalityLevel.push(value);
      }
    );
    return whiteSeasonalityLevel;
  }

  getLookupItemsList() {
    this.spinner.show();
    this.forecastLookupService.GetLookupItemsList().subscribe((itemList: any) => {
      this.lookupItemList = itemList;
      this.spinner.hide();
    });
    this.getLatestCommitedWeek();
  }

  getLatestCommitedWeek() {
    this.forecastManagerService.GetLatestCommitedWeek().subscribe((response: any) => {
      this.startDateMax = response.committedDate;
    });
  }

  seasonalityTypeChange(event) {
    if (event.value === 'rolling') {
      this.seasonalityForm.controls['rollingWeek'].setValidators([Validators.required, Validators.min(52), Validators.max(156)]);
      this.seasonalityForm.controls['startWeekDate'].setValidators([]);
      this.seasonalityForm.controls['endWeekDate'].setValidators([]);
    } else {
      this.seasonalityForm.controls['startWeekDate'].setValidators([Validators.required]);
      this.seasonalityForm.controls['endWeekDate'].setValidators([Validators.required]);
      this.seasonalityForm.controls['rollingWeek'].setValidators([]);
    }
    this.seasonalityForm.get('rollingWeek').updateValueAndValidity();
    this.seasonalityForm.get('startWeekDate').updateValueAndValidity();
    this.seasonalityForm.get('endWeekDate').updateValueAndValidity();
  }

  startDateChange(event) {
    this.calculateWeeks();
    this.setEndDateMinMax();
  }

  setEndDateMinMax() {
    const startWeekDate = this.seasonalityForm.get('startWeekDate').value;
    if (startWeekDate) {
      this.endDateMax = this.subtractWeeks(51, new Date(startWeekDate));
      this.endDateMin = this.subtractWeeks(155, new Date(startWeekDate));
    }
    this.seasonalityForm.get('endWeekDate').setValue(this.endDateMax);
  }

  subtractWeeks(numOfWeeks, date: Date) {
    const dateCopy = new Date(date.getTime());
    dateCopy.setDate(dateCopy.getDate() - numOfWeeks * 7);
    return dateCopy;
  }

  endDateChange(event) {
    this.calculateWeeks();
  }

  calculateWeeks() {
    const startWeekDate = this.seasonalityForm.get('startWeekDate').value;
    const endWeekDate = this.seasonalityForm.get('endWeekDate').value;
    if (startWeekDate && endWeekDate) {
      this.numberOfWeeksDifference = this.differenceInWeeks(new Date(startWeekDate), new Date(endWeekDate));
      this.isValidWeeks = (this.numberOfWeeksDifference < 52 || this.numberOfWeeksDifference > 156) ? false : true;
    }
  }

  differenceInWeeks(startWeekDate: Date, endWeekDate: Date) {
    let diff = (startWeekDate.getTime() - endWeekDate.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7);
    return Math.round(diff) + 1;
  }

  applySeasonalityLevelControlValidator() {
    switch (this.seasonalityLevelCode) {
      case 'AC': {
        this.seasonalityForm.controls['brands'].setValidators([]);
        this.seasonalityForm.controls['productGroups'].setValidators([]);
        this.seasonalityForm.controls['skuIds'].setValidators([]);
        break;
      }
      case 'B':
      case 'BAC': {
        this.seasonalityForm.controls['brands'].setValidators([Validators.required]);
        this.seasonalityForm.controls['productGroups'].setValidators([]);
        this.seasonalityForm.controls['skuIds'].setValidators([]);
        break;
      }
      case 'PG':
      case 'PGAC': {
        this.seasonalityForm.controls['brands'].setValidators([]);
        this.seasonalityForm.controls['productGroups'].setValidators([Validators.required]);
        this.seasonalityForm.controls['skuIds'].setValidators([]);
        break;
      }
      case 'SK':
      case 'ACSKU': {
        this.seasonalityForm.controls['brands'].setValidators([]);
        this.seasonalityForm.controls['productGroups'].setValidators([]);
        this.seasonalityForm.controls['skuIds'].setValidators([Validators.required]);
        break;
      }
    }
    this.seasonalityForm.get('brands').updateValueAndValidity();
    this.seasonalityForm.get('productGroups').updateValueAndValidity();
    this.seasonalityForm.get('skuIds').updateValueAndValidity();
  }

  weekDayFilter = (date) => {
    return date.day() === this.clientWeekStartDay;
  }

  setHierarchiesBySeasonalityLevel() {
    switch (this.seasonalityLevelCode) {
      case 'AC': {
        this.seasonalityHierarchyList.forEach(item => {
          item.brand = null;
          item.productGroup = null;
          item.productDescription = null;
          item.sku = null;
        });
        break;
      }
      case 'B':
      case 'BAC': {
        this.seasonalityHierarchyList.forEach(item => {
          item.productGroup = null;
          item.productDescription = null;
          item.sku = null;
        });
        break;
      }
      case 'PG':
      case 'PGAC': {
        this.seasonalityHierarchyList.forEach(item => {
          item.productDescription = null;
          item.sku = null;
        });
        break;
      }
      case 'SK':
      case 'ACSKU': {
        break;
      }
    }
  }

  addNewHierarchy() {
    this.seasonalityHierarchyList.push({
      retailerKey: '',
      retailer: '',
    });
    this.pushValidHierarchyObject();
  }
  pushValidHierarchyObject() {
    this.validSeasonalityHierarchyList.push({
      retailer: true,
      brand: true,
      productGroup: true,
      productDescription: true,
      sku: true
    });
  }

  deleteHierarchyItem(item, index) {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        headerName: `Delete Hierarchy`,
        confirmationMessage: `Are you sure you want to delete this hierarchy?`
      }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.seasonalityHierarchyList.splice(index, 1);
        this.validSeasonalityHierarchyList.splice(index, 1);
      }
    });
  }

  validateSeasonalityLevelGridControls(control) {
    switch (control) {
      case 'brand': {
        if (['B', 'BAC', 'PG', 'PGAC', 'ACSKU', 'SK'].includes(this.seasonalityLevelCode)) {
          return true;
        }
      }
      case 'productGroup': {
        if (['PG', 'PGAC', 'ACSKU', 'SK'].includes(this.seasonalityLevelCode)) {
          return true;
        }
      }
      case 'sku': {
        if (['ACSKU', 'SK'].includes(this.seasonalityLevelCode)) {
          return true;
        }
      }
      default: {
        return false;
      }
    }
  }

  displayRetailerFn(item): string {
    return item && typeof item === 'object' ? (item.retailer ? `${item.retailer}` : '') : item;
  }

  displayBrandFn(item): string {
    return item && typeof item === 'object' ? (item.brand ? `${item.brand}` : '') : item;
  }

  displayProductGroupFn(item): string {
    return item && typeof item === 'object' ? (item.productGroup ? `${item.productGroup}` : '') : item;
  }

  displayProductDescriptionFn(item): string {
    return item && typeof item === 'object' ? (item.productDescription ? `${item.productDescription}` : '') : item;
  }

  displaySKUFn(item): string {
    return item && typeof item === 'object' ? (item.sku ? `${item.sku}` : '') : item;
  }

  onRetailerFocus(event, index) {
    const itemsList = _.uniqBy(this.lookupItemList, a => a.retailerKey);
    this.filteredRetailerLookupItems = of(itemsList);
  }

  onBrandFocus(event, index) {
    const retailerKey = this.seasonalityHierarchyList[index].retailerKey;
    const itemsList = _.uniqBy(_.filter(this.lookupItemList, b => b.retailerKey === retailerKey), a => a.brand);
    this.filteredBrandLookupItems = of(itemsList);
  }

  onProductGroupFocus(event, index) {
    const retailerKey = this.seasonalityHierarchyList[index].retailerKey;
    const brand = this.seasonalityHierarchyList[index].brand;
    const itemsList = _.uniqBy(_.filter(this.lookupItemList, b => b.retailerKey === retailerKey && b.brand === brand), a => a.productGroup);
    this.filteredProductGroupLookupItems = of(itemsList);
  }

  onProductDescriptionFocus(event, index) {
    const retailerKey = this.seasonalityHierarchyList[index].retailerKey;
    const brand = this.seasonalityHierarchyList[index].brand;
    const productGroup = this.seasonalityHierarchyList[index].productGroup;
    const itemsList = _.uniqBy(_.filter(this.lookupItemList, b => b.retailerKey === retailerKey && b.brand === brand && b.productGroup === productGroup), a => a.sku);
    this.filteredProductDescriptionLookupItems = of(itemsList);
  }

  onSKUFocus(event, index) {
    let value = event.target.value;
    const retailerKey = this.seasonalityHierarchyList[index].retailerKey;
    const brand = this.seasonalityHierarchyList[index].brand;
    const productGroup = this.seasonalityHierarchyList[index].productGroup;
    if (value) {
      this._filterLookupSkus(event, index);
    } else {
      const itemsList = this.lookupItemList.filter(item => {
        if (productGroup) {
          return item.retailerKey === retailerKey
            && item.brand === brand
            && item.productGroup === productGroup;
        } else if (brand) {
          return item.retailerKey === retailerKey && item.brand === brand;
        } else if (retailerKey) {
          return item.retailerKey === retailerKey;
        } else {
          return true;
        }
      });
      this.filteredLookupSkus = of(itemsList);
    }
  }

  _filterRetailerLookupItems(event, index) {
    let value = _.toLower(event.target.value);
    const itemsList = _.uniqBy(
      _.filter(this.lookupItemList, item => { return _.toLower(item.retailer).indexOf(value) > -1; }),
      a => a.retailerKey);
    this.filteredRetailerLookupItems = of(itemsList);
    this.validSeasonalityHierarchyList[index].retailer = this.isValidRetailerValue(value);
  }

  _filterBrandLookupItems(event, index) {
    let value = _.toLower(event.target.value);
    const retailerKey = this.seasonalityHierarchyList[index].retailerKey;
    const itemsList = _.uniqBy(
      _.filter(this.lookupItemList, item => {
        return item.retailerKey === retailerKey && _.toLower(item.brand).indexOf(value) > -1;
      }),
      a => a.brand);
    this.filteredBrandLookupItems = of(itemsList);
    this.validSeasonalityHierarchyList[index].brand = this.isValidBrandValue(value);
  }

  _filterProductGroupLookupItems(event, index) {
    let value = _.toLower(event.target.value);
    const retailerKey = this.seasonalityHierarchyList[index].retailerKey;
    const brand = this.seasonalityHierarchyList[index].brand;
    const itemsList = _.uniqBy(
      _.filter(this.lookupItemList, item => {
        return item.retailerKey === retailerKey &&
          item.brand === brand && _.toLower(item.productGroup).indexOf(value) > -1;
      }),
      a => a.productGroup);
    this.filteredProductGroupLookupItems = of(itemsList);
    this.validSeasonalityHierarchyList[index].productGroup = this.isValidProductGroupValue(value);
  }

  _filterProductDescriptionLookupItems(event, index) {
    let value = _.toLower(event.target.value);
    const retailerKey = this.seasonalityHierarchyList[index].retailerKey;
    const brand = this.seasonalityHierarchyList[index].brand;
    const productGroup = this.seasonalityHierarchyList[index].productGroup;
    const itemsList = _.uniqBy(
      _.filter(this.lookupItemList, item => {
        return item.retailerKey === retailerKey &&
          item.brand === brand &&
          item.productGroup === productGroup &&
          _.toLower(item.productDescription).indexOf(value) > -1;
      }),
      a => a.productDescription);
    this.filteredProductDescriptionLookupItems = of(itemsList);
    this.validSeasonalityHierarchyList[index].productDescription = this.isValidProductDescriptionValue(value);
  }

  _filterLookupSkus(event, index) {
    let value = event.target.value;
    const retailerKey = this.seasonalityHierarchyList[index].retailerKey;
    const brand = this.seasonalityHierarchyList[index].brand;
    const productGroup = this.seasonalityHierarchyList[index].productGroup;
    const itemsList = this.lookupItemList.filter(item => {
      if (productGroup) {
        return item.retailerKey === retailerKey
          && item.brand === brand
          && item.productGroup === productGroup
          && item.sku.indexOf(value) > -1;
      } else if (brand) {
        return item.retailerKey === retailerKey && item.brand === brand && item.sku.indexOf(value) > -1;
      } else if (retailerKey) {
        return item.retailerKey === retailerKey && item.sku.indexOf(value) > -1;
      } else {
        return item.sku.indexOf(value) > -1;
      }
    });
    this.filteredLookupSkus = of(itemsList);
    this.validSeasonalityHierarchyList[index].sku = this.isValidSKUValue(value);
  }

  retailerChange(item, index) {
    this.seasonalityHierarchyList[index].retailer = item.retailer;
    this.seasonalityHierarchyList[index].retailerKey = item.retailerKey;
    this.seasonalityHierarchyList[index].brand = null;
    this.seasonalityHierarchyList[index].productGroup = null;
    this.seasonalityHierarchyList[index].productDescription = null;
    this.seasonalityHierarchyList[index].sku = null;
    this.validSeasonalityHierarchyList[index].retailer = this.isValidRetailerValue(item.retailer);
    this.validSeasonalityHierarchyList[index].brand = true;
    this.validSeasonalityHierarchyList[index].productGroup = true;
    this.validSeasonalityHierarchyList[index].productDescription = true;
    this.validSeasonalityHierarchyList[index].sku = true;
  }

  brandChange(item, index) {
    this.seasonalityHierarchyList[index].brand = item.brand;
    this.seasonalityHierarchyList[index].productGroup = null;
    this.seasonalityHierarchyList[index].productDescription = null;
    this.seasonalityHierarchyList[index].sku = null;
    this.validSeasonalityHierarchyList[index].brand = this.isValidBrandValue(item.brand);
    this.validSeasonalityHierarchyList[index].productGroup = true;
    this.validSeasonalityHierarchyList[index].productDescription = true;
    this.validSeasonalityHierarchyList[index].sku = true;
  }

  productGroupChange(item, index) {
    this.seasonalityHierarchyList[index].productGroup = item.productGroup;
    this.seasonalityHierarchyList[index].productDescription = null;
    this.seasonalityHierarchyList[index].sku = null;
    this.validSeasonalityHierarchyList[index].productGroup = this.isValidProductGroupValue(item.productGroup);
    this.validSeasonalityHierarchyList[index].productDescription = true;
    this.validSeasonalityHierarchyList[index].sku = true;
  }

  productDescriptionChange(item, index) {
    this.seasonalityHierarchyList[index].productDescription = item.productDescription;
    this.seasonalityHierarchyList[index].sku = item.sku;
    this.validSeasonalityHierarchyList[index].productDescription = this.isValidProductDescriptionValue(item.productDescription);
  }

  skuChange(item, index) {
    this.seasonalityHierarchyList[index].retailer = item.retailer;
    this.seasonalityHierarchyList[index].retailerKey = item.retailerKey;
    this.seasonalityHierarchyList[index].brand = item.brand;
    this.seasonalityHierarchyList[index].productGroup = item.productGroup;
    this.seasonalityHierarchyList[index].productDescription = item.productDescription;
    this.seasonalityHierarchyList[index].sku = item.sku;
    this.validSeasonalityHierarchyList[index].sku = this.isValidSKUValue(item.sku);
  }

  isValidRetailerValue(value): boolean {
    if(value=='' || value==null )
    return false;
    const isValidObject = _.find(this.lookupItemList, item => item.retailer === value);
    return isValidObject ? true : false;
  }

  isValidBrandValue(value): boolean {
    if(value=='' || value==null )
    return false;
    const isValidObject = _.find(this.lookupItemList, item => item.brand === value);
    return isValidObject ? true : false;
  }

  isValidProductGroupValue(value): boolean {
    if(value=='' || value==null )
    return false;
    const isValidObject = _.find(this.lookupItemList, item => item.productGroup === value);
    return isValidObject ? true : false;
  }

  isValidProductDescriptionValue(value): boolean {
    if(value=='' || value==null )
    return false;
    const isValidObject = _.find(this.lookupItemList, item => item.productDescription === value);
    return isValidObject ? true : false;
  }

  isValidSKUValue(value): boolean {
    if(value=='' || value==null )
    return false;
    const isValidObject = _.find(this.lookupItemList, item => item.sku === value);
    return isValidObject ? true : false;
  }

  checkHierarchyRowsValidty(): boolean {
    let isHierarchyCorrect = this.seasonalityHierarchyList.length ? true : false;
    this.validSeasonalityHierarchyList.every(item => {
      if (!(item.retailer
        && item.brand
        && item.productGroup
        && item.productDescription
        && item.sku)) {
        isHierarchyCorrect = false;
        return false;
      }
      return true;
    });
    return isHierarchyCorrect;
  }

  checkLevelEmptyOrNull(): boolean {
    let isHierarchiesCorrect = true;
    switch (this.seasonalityLevelCode) {
      case 'AC': {
        for(let i=0;i<this.seasonalityHierarchyList.length;i++){
          this.validSeasonalityHierarchyList[i].retailer = this.isValidRetailerValue(this.seasonalityHierarchyList[i].retailer);
        }

        break;
      }
      case 'B':
      case 'BAC': {
        for(let i=0;i<this.seasonalityHierarchyList.length;i++){
          this.validSeasonalityHierarchyList[i].retailer = this.isValidRetailerValue(this.seasonalityHierarchyList[i].retailer);
          this.validSeasonalityHierarchyList[i].brand = this.isValidBrandValue(this.seasonalityHierarchyList[i].brand);

        }
        break;
      }
      case 'PG':
      case 'PGAC': {
        for(let i=0;i<this.seasonalityHierarchyList.length;i++){
          this.validSeasonalityHierarchyList[i].retailer = this.isValidRetailerValue(this.seasonalityHierarchyList[i].retailer);
          this.validSeasonalityHierarchyList[i].brand = this.isValidBrandValue(this.seasonalityHierarchyList[i].brand);
          this.validSeasonalityHierarchyList[i].productGroup = this.isValidProductGroupValue(this.seasonalityHierarchyList[i].productGroup);

        }
        break;
      }
      case 'SK':
      case 'ACSKU': {
        for(let i=0;i<this.seasonalityHierarchyList.length;i++){
          this.validSeasonalityHierarchyList[i].retailer = this.isValidRetailerValue(this.seasonalityHierarchyList[i].retailer);
          this.validSeasonalityHierarchyList[i].brand = this.isValidBrandValue(this.seasonalityHierarchyList[i].brand);
          this.validSeasonalityHierarchyList[i].productGroup = this.isValidProductGroupValue(this.seasonalityHierarchyList[i].productGroup);
          this.validSeasonalityHierarchyList[i].productDescription = this.isValidProductDescriptionValue(this.seasonalityHierarchyList[i].productDescription);
          this.validSeasonalityHierarchyList[i].sku = this.isValidSKUValue(this.seasonalityHierarchyList[i].sku);


        }
        break;
      }
    }
    return isHierarchiesCorrect;
  }

  checkLevelConsistency(): boolean {
    let isHierarchiesCorrect = true;
    switch (this.seasonalityLevelCode) {
      case 'AC': {
        this.seasonalityHierarchyList.every(item => {
          if (!item.retailerKey || item.brand || item.productGroup || item.productDescription || item.sku) {
            isHierarchiesCorrect = false;
            return false;
          }
          return true;
        });
        break;
      }
      case 'B':
      case 'BAC': {
        this.seasonalityHierarchyList.every(item => {
          if (!item.brand || item.productGroup || item.productDescription || item.sku) {
            isHierarchiesCorrect = false;
            return false;
          }
          return true;
        });
        break;
      }
      case 'PG':
      case 'PGAC': {
        this.seasonalityHierarchyList.every(item => {
          if (!item.productGroup || item.productDescription || item.sku) {
            isHierarchiesCorrect = false;
            return false;
          }
          return true;
        });
        break;
      }
      case 'SK':
      case 'ACSKU': {
        this.seasonalityHierarchyList.every(item => {
          if (!item.sku) {
            isHierarchiesCorrect = false;
            return false;
          }
          return true;
        });
        break;
      }
    }
    return isHierarchiesCorrect;
  }

  isAllHierarchiesUniq(): boolean {
    let isAllHierarchiesUniq = true;
    switch (this.seasonalityLevelCode) {
      case 'AC': {
        const uniqCombination = _.uniqBy(this.seasonalityHierarchyList, a => a.retailerKey);
        isAllHierarchiesUniq = uniqCombination.length === this.seasonalityHierarchyList.length;
        break;
      }
      case 'B':
      case 'BAC': {
        const uniqCombination = _.uniqBy(this.seasonalityHierarchyList, a => { return [a.retailerKey, a.brand].join() });
        isAllHierarchiesUniq = uniqCombination.length === this.seasonalityHierarchyList.length;
        break;
      }
      case 'PG':
      case 'PGAC': {
        const uniqCombination = _.uniqBy(this.seasonalityHierarchyList, a => { return [a.retailerKey, a.brand, a.productGroup].join() });
        isAllHierarchiesUniq = uniqCombination.length === this.seasonalityHierarchyList.length;
        break;
      }
      case 'SK':
      case 'ACSKU': {
        const uniqCombination = _.uniqBy(this.seasonalityHierarchyList, a => { return [a.retailerKey, a.brand, a.productGroup, a.sku].join() });
        isAllHierarchiesUniq = uniqCombination.length === this.seasonalityHierarchyList.length;
        break;
      }
    }
    return isAllHierarchiesUniq;
  }


  createSeasonality() {

    const isLevelNullOrEmpty =this.checkLevelEmptyOrNull();
    const isHierarchyValid = this.checkHierarchyRowsValidty();
    const isLevelConsistence = this.checkLevelConsistency();
    const isAllHierarchiesUniq = this.isAllHierarchiesUniq();

     if (this.seasonalityForm.valid
      && isLevelNullOrEmpty
      && this.isValidWeeks
      && isHierarchyValid
      && isLevelConsistence
      && isAllHierarchiesUniq) {
      const body = this.seasonalityForm.value;
      body.rollingWeek = body.seasonalityType === 'rolling' ? body.rollingWeek : null;
      body.startWeekDate = body.seasonalityType === 'static' ? body.startWeekDate : null;
      body.endWeekDate = body.seasonalityType === 'static' ? body.endWeekDate : null;
      const payload = {
        ...body,
        seasonalityHierarchyLevels: this.seasonalityHierarchyList
      };
      this.spinner.show();
      if (this.isCopyOperation) {
        payload.seasonalitySourceId = this.seasonalityId;
        this.seasonalityService.CopySeaonality(payload).subscribe((res: any) => {
          if (res && res.length) {
            const code = _.get(res, '0.messageCode', 400);
            const message = _.get(res, '0.messageText', 'Failed to create seasonality.');
            if (code === 200) {
              this.toastr.success('Success', `${message}`);
              this.dialogRef.close(true);
            } else {
              res.forEach(element => {
                this.toastr.error('Error', `${element.messageText}`);
              });
              const duplicateError = _.filter(res, a => a.messageCode === 402);
              if (duplicateError.length === res.length) {
                this.dialogRef.close(true);
              }
            }
          } else {
            this.toastr.success('Success', `Seasonality created successfully.`);
            this.dialogRef.close(true);
          }
          this.spinner.hide();
        }, err => {
          this.spinner.hide();
          this.toastr.error('Error', 'Failed to copy seasonality.');
        });
      } else {
        this.seasonalityService.CreatSeaonality(payload).subscribe((res: any) => {
          if (res && res.length) {
            const code = _.get(res, '0.messageCode', 400);
            const message = _.get(res, '0.messageText', 'Failed to create seasonality.');
            if (code === 200) {
              this.toastr.success('Success', `${message}`);
              this.dialogRef.close(true);
            } else {
              res.forEach(element => {
                this.toastr.error('Error', `${element.messageText}`);
              });
              const duplicateError = _.filter(res, a => a.messageCode === 402);
              if (duplicateError.length === res.length) {
                this.dialogRef.close(true);
              }
            }
          } else {
            this.toastr.success('Success', `Seasonality created successfully.`);
            this.dialogRef.close(true);
          }
          this.spinner.hide();
        }, err => {
          this.spinner.hide();
          this.toastr.error('Error', 'Failed to create seasonality.');
        });
      }
    } else {
      this.validateAllFormFields(this.seasonalityForm);
      this.toastr.error('Error', 'Please fill the form correctly.');
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
