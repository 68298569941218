<!-- Main Content area -->
<mat-accordion class="fb-forecast-accordion">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
      <mat-panel-title>
        Graph View
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="graph-img">
      <img src="assets/images/graph.svg" alt="" (click)="openMesuresDialog()">
    </div>

    <div class="container-fluid" id="fb-forecast-graph">
      <fusioncharts *ngIf="dataSource" [width]="width" [height]="height" [type]="type" [dataFormat]="dataFormat"
        [dataSource]="dataSource">
      </fusioncharts>
    </div>
  </mat-expansion-panel>
</mat-accordion>
