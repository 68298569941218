import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChildItemService } from 'src/services/Item-Management-Services';

@Component({
    selector: 'item-edit-cell-renderer',
    template: `<a class="product-title-link" (click)="btnEditClickHandler()">{{params.value || 'null'}}</a>`,
})
export class ChildItemEditRenderer implements ICellRendererAngularComp, OnDestroy {
    params: any;

    constructor(private childItemService: ChildItemService) {
    }

    agInit(params: any): void {
        this.params = params;
    }

    btnEditClickHandler() {
        this.childItemService.setChildGridActionSubject({ action: 'edit', actionData: this.params.data });
    }

    refresh(params?: any): boolean {
        return true;
    }

    ngOnDestroy() {
        // no need to remove the button click handler 
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}