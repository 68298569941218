import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  selector: 'app-decay-curve-form',
  templateUrl: './decay-curve-form.component.html',
  styleUrls: ['./decay-curve-form.component.css']
})
export class DecayCurveFormComponent implements OnInit, AfterViewInit {
  public titleForm: FormGroup;
  public submitted = false;
  public customerList = ['Best Buy', 'Walmart', 'Target', 'Dollar General'];
  public curveTypeList = ['Shipped', 'POS'];
  public classList = ['TV', 'Theatrical'];
  public subClassList = [
    'DVD',
    'BD',
    '3D',
    'UHD'
  ];
  public isEdit = false;
  constructor(
    public fb: FormBuilder,
    public configurationService: ConfigurationService,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialogRef: MatDialogRef<DecayCurveFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.initializeForm();
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    if (this.data.rowData && this.data.rowData.decayCurveId) {
      this.isEdit = true;
      const model = JSON.parse(JSON.stringify(this.data.rowData));
      model.customer = (model.customer || '').split(',');
      this.initializeFormWithValues(model);
    }
  }
  /**
   * initializeFormWithValues
   */
  public initializeFormWithValues(data) {
    Object.keys(this.titleForm.controls).forEach(key => {
      this.titleForm.controls[key].setValue(data[key]);
    });
  }
  public initializeForm = () => {
    this.titleForm = this.fb.group({
      decayCurveId: [0],
      decayCurveName: [],
      startDate: [],
      endDate: [],
      class: [],
      subClass: [],
      customer: [],
      curveType: [],
      totalUnits: [],
      active: [true]
    });
  }
  public onSubmit = () => {
    this.submitted = true;
    if (this.titleForm.valid) {
      const model = { ...this.titleForm.value };
      model.customer = `${model.customer}`;
      this.spinner.show();
      if (this.isEdit) {
        this.configurationService.DecayCurveUpdate(model).subscribe(res => {
          this.toastr.success('Decay Curve', 'Item updated successfully.');
          this.spinner.hide();
          if (this.dialogRef) {
            this.dialogRef.close(true);
          }
          this.initializeForm();
          this.isEdit = false;
        }, error => {
          this.spinner.hide();
        });
      } else {
        this.configurationService.DecayCurveAdd(model).subscribe(res => {
          this.toastr.success('Decay Curve', 'Item added successfully.');
          this.titleForm.controls['decayCurveId'].setValue(res);
          this.isEdit = true;
          if (this.dialogRef) {
            this.dialogRef.close(true);
          }
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      }
    }
  }
  public Cancel_Click = () => {

  }
}
