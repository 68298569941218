<div class="container-fluid">
  <div class="wrapper col-md-12">
    <mat-card-header>
      <mat-card-title>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="titleForm" (ngSubmit)="onSubmit()" class="form-fields">
        <div class="row">
          <div class="col-md-4 form-group">
            <mat-form-field>
              <input matInput formControlName="titleName" placeholder="Title Name">
            </mat-form-field>
          </div>
          <div class="col-md-4 form-group">
            <mat-form-field>
              <mat-select placeholder="Release Source" formControlName="releaseSource">
                <mat-option *ngFor="let res of []; let i = index" [value]="res.releaseSourceId">
                  {{res.releaseSourceName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4 form-group">
            <mat-form-field>
              <mat-select placeholder="Studio" formControlName="studio">
                <mat-option *ngFor="let res of []; let i = index" [value]="res.studioId">
                  {{res.studioName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 form-group">
            <mat-form-field>
              <mat-select placeholder="Genre" formControlName="genre">
                <mat-option *ngFor="let res of []; let i = index" [value]="res.genreId">
                  {{res.genreName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4 form-group">
            <mat-form-field>
              <input disabled matInput formControlName="vendor" placeholder="Vendor">
            </mat-form-field>
          </div>
          <div class="col-md-4 form-group">
            <mat-form-field>
              <mat-select placeholder="Rating" formControlName="rating">
                <mat-option *ngFor="let res of []; let i = index" [value]="res.genreId">
                  {{res.genreName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 form-group">
            <mat-form-field>
              <mat-select placeholder="Working Group" formControlName="workingGroup">
                <mat-option *ngFor="let res of []; let i = index" [value]="res.genreId">
                  {{res.genreName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4 form-group">
            <label>Available for Dummy SKU pre-order</label>
            <mat-checkbox style="padding-left: 5px;" formControlName="available">
            </mat-checkbox>
          </div>
          <div class="col-md-4 form-group">
            <mat-form-field class="example-full-width">
              <input (focus)="picker1.open()" and (click)="picker1.open()" formControlName="releaseDate" matInput
                [matDatepicker]="picker1" placeholder="Choose a date">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
              <mat-error *ngIf="submitted && titleForm.controls['releaseDate'].hasError('required')">
                *Required Field
              </mat-error>
            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col-md-4 form-group">
            <mat-form-field class="example-full-width">
              <input (focus)="picker2.open()" and (click)="picker2.open()" formControlName="streetDate" matInput
                [matDatepicker]="picker2" placeholder="Choose a date">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
              <mat-error *ngIf="submitted && titleForm.controls['streetDate'].hasError('required')">
                *Required Field
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4 form-group">
            <mat-form-field class="example-full-width">
              <input (focus)="picker3.open()" and (click)="picker3.open()" formControlName="preAwarenessDate" matInput
                [matDatepicker]="picker3" placeholder="Choose a date">
              <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-datepicker #picker3></mat-datepicker>
              <mat-error *ngIf="submitted && titleForm.controls['preAwarenessDate'].hasError('required')">
                *Required Field
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4 form-group">
            <mat-form-field class="example-full-width">
              <input (focus)="picker4.open()" and (click)="picker4.open()" formControlName="announceDate" matInput
                [matDatepicker]="picker4" placeholder="Choose a date">
              <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
              <mat-datepicker #picker4></mat-datepicker>
              <mat-error *ngIf="submitted && titleForm.controls['announceDate'].hasError('required')">
                *Required Field
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 form-group">
            <mat-form-field>
              <textarea matInput formControlName="comment" placeholder="Comemnts">
                </textarea>
            </mat-form-field>
          </div>
          <div class="col-md-4 form-group">
            <label></label>
            <mat-slide-toggle formControlName="status">Active
            </mat-slide-toggle>
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <div class="action-btns text-right">
        <button mat-raised-button class="btn btn-primary m-left" color="primary"
          (click)="onSubmit()">{{false ? 'Update' : 'Create'}}</button>
        <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="Cancel_Click()">Cancel</button>
      </div>
    </mat-card-actions>
  </div>
</div>
