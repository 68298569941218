import { Component, OnInit } from '@angular/core';
import { cacheBlockSize, currencyGridColDefs, paginationPageSize } from './ag-grid/gridOptions';
import { AllCommunityModules, ColDef } from '@ag-grid-community/all-modules';
import { CAD_data, EUR_data, GBP_data } from './mockData';

@Component({
  selector: 'app-apparel-country',
  templateUrl: './apparel-country.component.html',
  styleUrls: ['./apparel-country.component.scss']
})
export class ApparelCountryComponent implements OnInit {

  // Graph Variables
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel: "Months";
  public showYAxisLabel = true;
  public yAxisLabel: "Conversion Rates";
  graph1Data = [];
  graph2Data = [];
  graph3Data = [];

  // Grid Variables
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  public gridColumnDefs: ColDef[] = currencyGridColDefs;
  public modules = AllCommunityModules;
  public gridOptions: any;
  private gridApi;
  frameworkComponents = {};

  countries = [
    "Afghanistan",
    "Euro",
    "Aland Islands (Finland)",
    "Albania",
    "Algeria",
    "American Samoa (USA)",
    "Andorra",
    "Angola",
    "Anguilla (UK)",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba (Netherlands)",
    "Ascension Island (UK)",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda (UK)",
    "Bhutan",
    "Bolivia",
    "Bonaire (Netherlands)",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "British Indian Ocean Territory (UK)",
    "British Virgin Islands (UK)",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canadian Dollar",
    "Caribbean Netherlands (Netherlands)",
    "Cayman Islands (UK)",
    "Central African Republic",
    "Chad",
    "Chatham Islands (New Zealand)",
    "Chile",
    "China",
    "Christmas Island (Australia)",
    "Cocos (Keeling) Islands (Australia)",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic of the",
    "Congo, Republic of the",
    "Cook Islands (New Zealand)",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Curacao (Netherlands)",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (UK)",
    "Faroe Islands (Denmark)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana (France)",
    "French Polynesia (France)",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar (UK)",
    "Greece",
    "Greenland (Denmark)",
    "Grenada",
    "Guadeloupe (France)",
    "Guam (USA)",
    "Guatemala",
    "Guernsey (UK)",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong (China)",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "International Monetary Fund (IMF)",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man (UK)",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey (UK)",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau (China)",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique (France)",
    "Mauritania",
    "Mauritius",
    "Mayotte (France)",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat (UK)",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia (France)",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue (New Zealand)",
    "Norfolk Island (Australia)",
    "Northern Mariana Islands (USA)",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn Islands (UK)",
    "Poland",
    "Portugal",
    "Puerto Rico (USA)",
    "Qatar",
    "Reunion (France)",
    "Romania",
    "Russia",
    "Rwanda",
    "Saba (Netherlands)",
    "Saint Barthelemy (France)",
    "Saint Helena (UK)",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (France)",
    "Saint Pierre and Miquelon (France)",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Eustatius (Netherlands)",
    "Sint Maarten (Netherlands)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "Great Britan Pound",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen (Norway)",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau (New Zealand)",
    "Tonga",
    "Trinidad and Tobago",
    "Tristan da Cunha (UK)",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (UK)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "US Virgin Islands (USA)",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City (Holy See)",
    "Venezuela",
    "Vietnam",
    "Wake Island (USA)",
    "Wallis and Futuna (France)",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  abrevs = [
    "AFN",
    "EUR",
    "EUR",
    "ALL",
    "DZD",
    "USD",
    "EUR",
    "AOA",
    "XCD",
    "XCD",
    "ARS",
    "AMD",
    "AWG",
    "SHP",
    "AUD",
    "EUR",
    "AZN",
    "BSD",
    "BHD",
    "BDT",
    "BBD",
    "BYN",
    "EUR",
    "BZD",
    "XOF",
    "BMD",
    "BTN",
    "BOB",
    "USD",
    "BAM",
    "BWP",
    "BRL",
    "USD",
    "USD",
    "BND",
    "BGN",
    "XOF",
    "BIF",
    "CVE",
    "KHR",
    "XAF",
    "CAD",
    "USD",
    "KYD",
    "XAF",
    "XAF",
    "NZD",
    "CLP",
    "CNY",
    "AUD",
    "AUD",
    "COP",
    "KMF",
    "CDF",
    "XAF",
    "none",
    "CRC",
    "XOF",
    "HRK",
    "CUP",
    "ANG",
    "EUR",
    "CZK",
    "DKK",
    "DJF",
    "XCD",
    "DOP",
    "USD",
    "EGP",
    "USD",
    "XAF",
    "ERN",
    "EUR",
    "SZL",
    "ETB",
    "FKP",
    "none",
    "FJD",
    "EUR",
    "EUR",
    "EUR",
    "XPF",
    "XAF",
    "GMD",
    "GEL",
    "EUR",
    "GHS",
    "GIP",
    "EUR",
    "DKK",
    "XCD",
    "EUR",
    "USD",
    "GTQ",
    "GGP",
    "GNF",
    "XOF",
    "GYD",
    "HTG",
    "HNL",
    "HKD",
    "HUF",
    "ISK",
    "INR",
    "IDR",
    "XDR",
    "IRR",
    "IQD",
    "EUR",
    "IMP",
    "ILS",
    "EUR",
    "JMD",
    "JPY",
    "JEP",
    "JOD",
    "KZT",
    "KES",
    "AUD",
    "EUR",
    "KWD",
    "KGS",
    "LAK",
    "EUR",
    "LBP",
    "LSL",
    "LRD",
    "LYD",
    "CHF",
    "EUR",
    "EUR",
    "MOP",
    "MGA",
    "MWK",
    "MYR",
    "MVR",
    "XOF",
    "EUR",
    "USD",
    "EUR",
    "MRU",
    "MUR",
    "EUR",
    "MXN",
    "USD",
    "MDL",
    "EUR",
    "MNT",
    "EUR",
    "XCD",
    "MAD",
    "MZN",
    "MMK",
    "NAD",
    "AUD",
    "NPR",
    "EUR",
    "XPF",
    "NZD",
    "NIO",
    "XOF",
    "NGN",
    "NZD",
    "AUD",
    "USD",
    "KPW",
    "MKD",
    "NOK",
    "OMR",
    "PKR",
    "USD",
    "ILS",
    "USD",
    "PGK",
    "PYG",
    "PEN",
    "PHP",
    "NZD",
    "PLN",
    "EUR",
    "USD",
    "QAR",
    "EUR",
    "RON",
    "RUB",
    "RWF",
    "USD",
    "EUR",
    "SHP",
    "XCD",
    "XCD",
    "EUR",
    "EUR",
    "XCD",
    "WST",
    "EUR",
    "STN",
    "SAR",
    "XOF",
    "RSD",
    "SCR",
    "SLL",
    "SGD",
    "USD",
    "ANG",
    "EUR",
    "EUR",
    "SBD",
    "SOS",
    "ZAR",
    "GBP",
    "KRW",
    "SSP",
    "EUR",
    "LKR",
    "SDG",
    "SRD",
    "NOK",
    "SEK",
    "CHF",
    "SYP",
    "TWD",
    "TJS",
    "TZS",
    "THB",
    "USD",
    "XOF",
    "NZD",
    "TOP",
    "TTD",
    "GBP",
    "TND",
    "TRY",
    "TMT",
    "USD",
    "AUD",
    "UGX",
    "UAH",
    "AED",
    "GBP",
    "USD",
    "UYU",
    "USD",
    "UZS",
    "VUV",
    "EUR",
    "VES",
    "VND",
    "USD",
    "XPF",
    "YER",
    "ZMW",
    "USD"
  ];

  dropDownsData = [];

  reportingCountry = 240;
  unit1 = 210;
  unit2 = 1;
  unit3 = 41; 

  monthsArray = [];

  selectedDataSource = [];

  unit1DataSource = [];
  unit2DataSource = [];
  unit3DataSource = [];

  conversionRates = [false, false, false] as any[];
  conversionRate;

  constructor() { }

  ngOnInit(): void {
    this.countries.forEach((element, index) => {
      const obj = {
        id: index + 1,
        label: this.countries[index + 1],
        abbrev: this.abrevs[index + 1]
      }
      this.dropDownsData.push(obj);
    });

    for (let i=0; i<6; i++) {
      ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'].forEach((e) => {
        this.monthsArray.push(e + '-' + (i+19));
      });
    }

    this.unit1DataSource = this.fillData(GBP_data);
    this.unit2DataSource = this.fillData(EUR_data);
    this.unit3DataSource = this.fillData(CAD_data);

    this.graph1Data  = this.generateGraphData(this.unit1DataSource);
    this.graph2Data  = this.generateGraphData(this.unit2DataSource);
    this.graph3Data  = this.generateGraphData(this.unit3DataSource);

    this.conversionRate = this.unit1;
    this.conversionRates = [
      {
        id: this.unit1,
        label: '',
        abbrev: 'GBP'
      },
      {
        id: this.unit2,
        label: '',
        abbrev: 'EUR'
      },
      {
        id: this.unit3,
        label: '',
        abbrev: 'CAD'
      }
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.conversionChanged();
  }

  gridDataUpdated(params) {
    if (this.conversionRate == this.unit1) this.graph1Data = this.generateGraphData(this.selectedDataSource);
    if (this.conversionRate == this.unit2) this.graph2Data = this.generateGraphData(this.selectedDataSource);
    if (this.conversionRate == this.unit3) this.graph3Data = this.generateGraphData(this.selectedDataSource);
  }

  fillData(dataArray: Array<any>) {
    const arrayName = [];
    this.monthsArray.forEach((e, index) => {
      arrayName.push(
        {
          month: e,
          rate: dataArray[index]
        }
      )
    });
    return arrayName;
  }

  selectionChanged(type: number, valueSelected) {
    this.conversionRates[type] = this.dropDownsData[valueSelected-1];
    if (type == 0) {
      this.graph1Data  = this.generateGraphData(this.unit1DataSource);
    } else if (type == 1) {
      this.graph2Data  = this.generateGraphData(this.unit2DataSource);
    } else {
      this.graph3Data  = this.generateGraphData(this.unit3DataSource);
    }
  }

  conversionChanged() {
    if (this.conversionRate == this.unit1) this.selectedDataSource = this.unit1DataSource;
    if (this.conversionRate == this.unit2) this.selectedDataSource = this.unit2DataSource;
    if (this.conversionRate == this.unit3) this.selectedDataSource = this.unit3DataSource;
    this.gridApi.setRowData(this.selectedDataSource);
  }

  generateGraphData(arrayName: Array<any>) {
    const graphData = [];
    for (let i=0; i<6; i++) {
      const obj = {
        name: (2019 + i).toString(),
        series: []
      };
      ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'].forEach((e, index) => {
        const monthObj = {
          name: e,
          value: arrayName[(i*12)+index].rate
        }
        obj.series.push(monthObj);
      });
      graphData.push(obj);
    }
    return graphData
  }

  getTitle(type: number) {
    if (type == 0) return this.unit1 ? this.dropDownsData[this.unit1-1].label: '';
    if (type == 1) return this.unit2 ? this.dropDownsData[this.unit2-1].label: '';
    if (type == 2) return this.unit3 ? this.dropDownsData[this.unit3-1].label: '';
  }
}
