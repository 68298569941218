import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CHANNEL_COL_DEFS } from 'src/common/algo-column-definitions';
import { USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.css']
})
export class ChannelComponent implements OnInit {

  public registerForm: FormGroup;
  public submitted = false;
  public isEdit = false;
  public columnDefs = CHANNEL_COL_DEFS;
  private gridApi: any;
  public getRowHeight;
  public  headerHeight;
  public channelList = [];
  public gridOptions: any;
  public modules = AllCommunityModules;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService) {
    this.gridOptions = {
      frameworkComponents: {
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.headerHeight = 40;
    this.gridOptions.getRowHeight = function(params) {
      return 40;
    };
  }

  ngOnInit() {
    this.populateList();
    this.initializeForm();
  }
  public populateList = () => {
    this.configurationService.getAllChannels({ clientId: 1 }).subscribe((res: any) => {
      this.channelList = res.data;
    });
  }
  initializeForm() {
    this.registerForm = this.formBuilder.group({
      channelName: ['', Validators.required],
      channelId: [0],
      clientId: [1, Validators.required],
      description: ['', Validators.required],
      active: true,
      currentUserId: [this.storage.get(USER_ID)]
    });
  }
  populateForm(event): void {
    if (event && event.data) {
      this.isEdit = true;
      const dataToPopulate = event.data;
      Object.keys(this.registerForm.controls).forEach(key => {
        if (dataToPopulate[key]) {
          this.registerForm.controls[key].setValue(dataToPopulate[key]);
        }
      });
    }
  }
  public onSubmit = () => {
    this.submitted = true;
    if (this.registerForm.valid) {
      const model = { ...this.registerForm.value };
      this.spinner.show();
      if (this.isEdit) {
        this.configurationService.updateChannel(model).subscribe(res => {
          this.populateList();
          this.isEdit = false;
          this.submitted = false;
          this.initializeForm();
          this.spinner.hide();
        }, error => {

        });
      } else {
        this.configurationService.addChannel(model).subscribe(res => {
          this.populateList();
          this.submitted = false;
          this.initializeForm();
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      }
    }
  }
  Cancel_Click() {
    this.submitted = false;
    this.isEdit = false;
    this.initializeForm();
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

}
