import { Component, OnInit } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { CalenderActivityComponent } from './calender-activity/calender-activity.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { EventTypeAddComponent } from './event-type-add/event-type-add.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CALENDER_COL_DEF } from 'src/common/algo-column-definitions';
import { Calender } from 'src/models/interfaces/configuration.model';
import { ConfigurationService } from 'src/services/configuration.service';
@Component({
  selector: 'app-calender-list',
  templateUrl: './calender-list.component.html',
  styleUrls: ['./calender-list.component.css']
})
export class CalenderListComponent implements OnInit {
  public eventTypeList = [];
  private gridApi: any;
  public gridOptions: any;
 public getRowHeight;
 public  headerHeight;
  public modules = AllCommunityModules;
  public columnDefs = CALENDER_COL_DEF;
  public selectedCalender: Calender;
  public categoryList = [];
  public calendarActivityList = [];
  public isView = true;
  constructor(
    public configurationService: ConfigurationService,
    public spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<CalenderActivityComponent>,
    public dialogRefEventType: MatDialogRef<EventTypeAddComponent>,
    public dialog: MatDialog) {
    this.gridOptions = {
      frameworkComponents: {
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.headerHeight = 40;
    this.gridOptions.getRowHeight = function(params) {
      return 40;
    };
  }

  ngOnInit() {
    this.populateList();
    this.populateListDropdowns();
  }
  public populateListDropdowns = () => {
    this.configurationService.getCategoryList({ clientId: 1, parent: true }).subscribe((res: any) => {
      this.categoryList = res;
    });
    this.configurationService.getAllCalenderActivities({ clientId: 1 }).subscribe(res => {
      this.calendarActivityList = res;
    });
  }
  public populateList = (showSpinner?: boolean) => {
    this.spinner.show();
    this.configurationService.getGetCalenderEventGroup({ clientId: 1 }).subscribe((res: any) => {
      this.eventTypeList = res.data;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }
  public addToList = (newEvent) => {
    if (newEvent) {
      this.configurationService.getGetCalenderEventGroup({ clientId: 1 }).subscribe((res: any) => {
        this.eventTypeList = res.data;
        this.spinner.hide();
      });
    }
  }
  public cellClicked = (event) => {
    this.selectedCalender = event.data;
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  public getCalenderData = (event: any) => {
    if (event) {
      return event.calenders || [];
    }
  }
  public openCalenderActivity = () => {
    this.dialogRef = this.dialog.open(CalenderActivityComponent, {
      width: '800px',
      height: '550px'
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
  public openEventType = () => {
    this.dialogRefEventType = this.dialog.open(EventTypeAddComponent, {
      width: '500px'
    });
    this.dialogRefEventType.afterClosed().subscribe(result => {
      if (result) {
        this.populateList();
      }
    });
  }
}
