import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MfpService {

  yearlyTotal = 15000000;
  updateTotal = new Subject();
  constructor() { }
}
