<div class="row m-0">

    <div class="all-show-grid col-md-6">
        <ag-grid-angular class="ag-theme-material" [suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups
            toolPanelSuppressValues [frameworkComponents]="frameworkComponents" [columnDefs]="gridColumnDefs"
            [rowData]="yearlyData" [pagination]="false" (gridReady)="onGridReady($event)" [modules]="modules"
            (cellValueChanged)="cellValueChanged(true)" #planogramGrid>
        </ag-grid-angular>
    </div>

    <div class="col-md-6">
        <fusioncharts [width]="width" [height]="height" [type]="type" [dataFormat]="dataFormat"
            [dataSource]="dataSource">
        </fusioncharts>
    </div>

</div>