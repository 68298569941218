<div class="container">
  <form action="" [formGroup]="calendarSetupForm" >
    <div class="row">
      <div class="col-md-6">
        <label for="calendarType">Calendar Type</label>
        <mat-form-field appearance="fill">
          <mat-select [disabled]="isCalendarSaved" name="calendarType" formControlName="calendarType"
            (selectionChange)="calendarTypeChange($event)">
            <mat-option *ngFor="let item of calendarData" [value]="item.value">{{item.value}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <ng-container *ngIf="calendarType.value === 'NRF'">
        <div class="col-md-6">
          <label for="calendarType">NRF Type</label>
          <mat-form-field appearance="fill">
            <mat-select [disabled]="isCalendarSaved" name="calendarNRFType" formControlName="calendarNRFType"  >
              <mat-option *ngFor="let nrf of nrfTypes" [value]="nrf.key">{{nrf.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
    <div class="row">
      <ng-container *ngIf="calendarType.value === 'Custom'">
        <div class="col-md-6">
          <label for="fyStartMonth">Fy Start Month</label>
          <mat-form-field appearance="fill">
            <mat-select [disabled]="isCalendarSaved" name="fyStartMonth" formControlName="fyStartMonth"
              (selectionChange)="fyStartMonthChange($event)">
              <mat-option *ngFor="let month of monthList" [value]="month.key">{{month.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <label for="fyEndMonth">Fy End Month</label>
          <mat-form-field appearance="fill">
            <mat-select [disabled]="isCalendarSaved" name="fyEndMonth" formControlName="fyEndMonth"
              (selectionChange)="fyEndMonthChange($event)">
              <mat-option *ngFor="let month of monthList" [value]="month.key">{{month.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <label for="weekStartDay">Week Start Day</label>
          <mat-form-field appearance="fill">
            <mat-select [disabled]="isCalendarSaved" name="weekStartDay" formControlName="weekStartDay"
              (selectionChange)="weekStartDayChange($event)">
              <mat-option *ngFor="let day of weekDayList" [value]="day.key">{{day.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <label for="weekEndDay">Week End Day</label>
          <mat-form-field appearance="fill">
            <mat-select [disabled]="isCalendarSaved" name="weekEndDay" formControlName="weekEndDay"
              (selectionChange)="weekEndDayChange($event)">
              <mat-option *ngFor="let day of weekDayList" [value]="day.key">{{day.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>

      <div class="col-md-6">
        <label for="fyStartYear">Calendar Start Year</label>
        <multi-datepicker [disabled]="isCalendarSaved" mode="YEAR" formControlName="fyStartYear" [max]="startYearMax">
        </multi-datepicker>
      </div>
      <div class="col-md-6">
        <label for="fyEndYear">Calendar End Year</label>
        <multi-datepicker mode="YEAR" formControlName="fyEndYear" [min]="endYearMin"></multi-datepicker>
      </div>
    </div>

    <div class="action-btns">
      <button type="button" mat-stroked-button color="accent" (click)="viewCalendarDetail()" class="ml-10">View</button>
      <button mat-flat-button color="accent" class="ml-10" (click)="saveCalendar()">Save</button>
    </div>
  </form>
</div>
<hr />
<div class="container-fluid" *ngIf="calendarDateList.length">
  <div class="grid-action-btn-bar">
    <button matTooltip="Show/Hide Columns" class="grid-bar-icon mh-auto" mat-icon-button color="accent"
      (click)="openColumnDialog()">
      <i class="material-icons">
        ballot
      </i>
    </button>
    <div class="grid-actions inline-flex">
      <div *ngIf="isFilteredApplied" class="row-info filtered-info">
        <span><strong>Filtered Rows:</strong> {{gridData.length}} </span>
        <button mat-button color="accent" (click)="clearGridFilters()">Clear All Filters</button>
      </div>
    </div>
    <button matTooltip="Export" class="grid-bar-icon ml-10 mh-auto" mat-icon-button color="primary"
      (click)="onExportGridData()">
      <i class="material-icons">
        insert_drive_file
      </i>
    </button>
    <div class="toggle-btn">
      <mat-slide-toggle class="mr-10 ml-10" [(ngModel)]="isWeekendDateView" (change)="calendarViewChange($event)"
      title="*Toggle for Weekend Dates View">Weekend Dates View</mat-slide-toggle>
    </div>

    <mat-form-field appearance="fill" style="width: 20% !important;">
      <mat-select name="yearList" [(ngModel)]="selectedYear" (selectionChange)="selectYear($event)">
        <mat-option *ngFor="let year of yearList" [value]="year">{{year}}</mat-option>
      </mat-select>
    </mat-form-field>

  </div>
  <div class="all-show-grid">
    <ag-grid-angular #calendarSetupGrid id="calendar-data-grid" class="ag-theme-material calendar-view-grid"
      [modules]="modules" [columnDefs]="columnDefs" [gridOptions]="gridOptions" [rowData]="gridData"
      [defaultColDef]="defaultColDef" [animateRows]="true" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
