<div class="p-2">
    <mat-tab-group (selectedTabChange)="selectedTabChange()">
        <mat-tab label="Strategic Planning">
            <app-strategic-planning></app-strategic-planning>
        </mat-tab>
        <mat-tab label="Plan Manager">
            <app-plan-manager></app-plan-manager>
        </mat-tab>
        <mat-tab label="Top Down Planning">
            <app-top-down-planning></app-top-down-planning>
        </mat-tab>
        <mat-tab label="Price Tiers Targets">
            <app-price-tiers></app-price-tiers>
        </mat-tab>
    </mat-tab-group>
</div>
