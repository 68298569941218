export const Global_Planning =
  [
    {
      "measureId": 1,
      "parentId": 0,
      "measureName": "Algo ST Fcsts",
      "background": "rgb(68, 16, 79)",
      "code": "AlgoSTFcsts",
      "type": 1
    },
    {
      "measureId": 9,
      "parentId": 1,
      "measureName": "Algo Baseline Fcst",
      "background": "#8d009d",
      "code": "AlgoBaselineFcst",
      "type": 1
    },
    {
      "measureId": 10,
      "parentId": 1,
      "measureName": "Algo Promo Units",
      "background": "#8d009d",
      "code": "AlgoPromoUnits",
      "type": 1
    },
    {
      "measureId": 11,
      "parentId": 1,
      "measureName": "Algo Total Forecast",
      "background": "#8d009d",
      "code": "AlgoTotalForecast",
      "type": 1
    },

    {
      "measureId": 12,
      "parentId": 1,
      "measureName": "Algo TF vs GDP Total Forecast",
      "background": "#8d009d",
      "code": "AlgoTFvsGDPTotalForecast",
      "type": 1
    },

    {
      "measureId": 13,
      "parentId": 1,
      "measureName": "Algo TF vs UDP Likely Fcst",
      "background": "#8d009d",
      "code": "AlgoTFvsUDPLikelyFcst",
      "type": 1
    },
    {
      "measureId": 2,
      "parentId": 0,
      "measureName": "Demand Planning",
      "background": "rgb(141, 0, 157)",
      "code": "DemandPlanning",
      "type": 1
    },
    {
      "measureId": 14,
      "parentId": 2,
      "measureName": "GDP Baseline Fcst",
      "background": "#8d009d",
      "code": "GDPBaselineFcst",
      "type": 1
    },

    {
      "measureId": 15,
      "parentId": 2,
      "measureName": "GDP Promo Units",
      "background": "#8d009d",
      "code": "GDPPromoUnits",
      "type": 2
    },

    {
      "measureId": 16,
      "parentId": 2,
      "measureName": "GDP Manual Fcst Adjustment",
      "background": "#8d009d",
      "code": "GDPManualFcstAdjustment",
      "type": 2
    },

    {
      "measureId": 17,
      "parentId": 2,
      "measureName": "GDP Total Forecast",
      "background": "#8d009d",
      "code": "GDPTotalForecast",
      "type": 1
    },

    {
      "measureId": 18,
      "parentId": 2,
      "measureName": "GDP Comments",
      "background": "#8d009d",
      "code": "GDPComments",
      "type": 2
    },

    {
      "measureId": 19,
      "parentId": 2,
      "measureName": "RDP Total Forecast",
      "background": "#8d009d",
      "code": "RDPTotalForecast",
      "type": 1
    },

    {
      "measureId": 20,
      "parentId": 2,
      "measureName": "UPD Likely Total Fcst",
      "background": "#8d009d",
      "code": "UPDLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 21,
      "parentId": 2,
      "measureName": "Customer ST Fcst",
      "background": "#8d009d",
      "code": "CustomerSTFcst",
      "type": 1
    },

    {
      "measureId": 22,
      "parentId": 2,
      "measureName": "Sales Goal",
      "background": "#8d009d",
      "code": "SalesGoal",
      "type": 2
    },

    {
      "measureId": 23,
      "parentId": 2,
      "measureName": "CPFR Forecast",
      "background": "#8d009d",
      "code": "CPFRForecast",
      "type": 1
    },

    {
      "measureId": 24,
      "parentId": 2,
      "measureName": "ODP Total Fcst",
      "background": "#8d009d",
      "code": "ODPTotalFcst",
      "type": 1
    },

    {
      "measureId": 25,
      "parentId": 2,
      "measureName": "ODP Total Fcst vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "ODPTotalFcstvsUDPLikelyTotalFcst",
      "type": 1
    },

    {
      "measureId": 26,
      "parentId": 2,
      "measureName": "GDP Total Fcst vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "GDPTotalFcstvsUDPLikelyTotalFcst",
      "type": 1
    },

    {
      "measureId": 27,
      "parentId": 2,
      "measureName": "GDP Total Fcst vs Customer ST Fcst",
      "background": "#8d009d",
      "code": "GDPTotalFcstvsCustomerSTFcst",
      "type": 1
    },

    {
      "measureId": 28,
      "parentId": 2,
      "measureName": "GDP Total Fcst vs Sales Goal",
      "background": "#8d009d",
      "code": "GDPTotalFcstvsSalesGoal",
      "type": 1
    },

    {
      "measureId": 29,
      "parentId": 2,
      "measureName": "GDP Total Fcst vs CPFR Forecast",
      "background": "#8d009d",
      "code": "GDPTotalFcstvsCPFRForecast",
      "type": 1
    },

    {
      "measureId": 30,
      "parentId": 2,
      "measureName": "GDP Total Fcst vs Actual ST",
      "background": "#8d009d",
      "code": "GDPTotalFcstvsActualST",
      "type": 1
    },
    {
      "measureId": 31,
      "parentId": 2,
      "measureName": "UDP Likely Total Fcst vs Actual ST",
      "background": "#8d009d",
      "code": "UDPLikelyTotalFcstvsActualST",
      "type": 1
    },
    {
      "measureId": 32,
      "parentId": 2,
      "measureName": "UPD Likely Total Fcst vs Customer ST Fcst",
      "background": "#8d009d",
      "code": "UPDLikelyTotalFcstvsCustomerSTFcst",
      "type": 1
    },

    {
      "measureId": 33,
      "parentId": 2,
      "measureName": "CPFR Forecast vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "CPFRForecastvsUDPLikelyTotalFcst",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 0,
      "measureName": "Actual Sales",
      "background": "rgb(216, 26, 209)",
      "code": "ActualSales",
      "type": 1
    },

    {
      "measureId": 34,
      "parentId": 3,
      "measureName": "Actual ST",
      "background": "#8d009d",
      "code": "ActualST",
      "type": 1
    },

    {
      "measureId": 35,
      "parentId": 3,
      "measureName": "Weekly Trend",
      "background": "#8d009d",
      "code": "WeeklyTrend",
      "type": 1
    },

    {
      "measureId": 36,
      "parentId": 3,
      "measureName": "Promo Indicator",
      "background": "#8d009d",
      "code": "PromoIndicator",
      "type": 1
    },

    {
      "measureId": 37,
      "parentId": 3,
      "measureName": "Promo Price",
      "background": "#8d009d",
      "code": "PromoPrice",
      "type": 1
    },

    {
      "measureId": 38,
      "parentId": 3,
      "measureName": "CPFR Comments",
      "background": "#8d009d",
      "code": "CPFRComments",
      "type": 1
    },

    {
      "measureId": 39,
      "parentId": 3,
      "measureName": "Customer In-Stock Estimate",
      "background": "#8d009d",
      "code": "CustomerInStockEstimate",
      "type": 1
    },

    {
      "measureId": 40,
      "parentId": 3,
      "measureName": "Webstore Traffic",
      "background": "#8d009d",
      "code": "WebstoreTraffic",
      "type": 1
    },

    {
      "measureId": 41,
      "parentId": 3,
      "measureName": "Webstore Conversion",
      "background": "#8d009d",
      "code": "WebstoreConversion",
      "type": 1
    },

    {
      "measureId": 42,
      "parentId": 3,
      "measureName": "Actual ST vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "ActualSTvsUDPLikelyTotalFcst",
      "type": 1
    },

    {
      "measureId": 43,
      "parentId": 3,
      "measureName": "Actual ST vs UDP Likely Total Fcst %",
      "background": "#8d009d",
      "code": "ActualSTvsUDPLikelyTotalFcst%",
      "type": 1
    },

    {
      "measureId": 44,
      "parentId": 3,
      "measureName": "Actual ST vs ODP",
      "background": "#8d009d",
      "code": "ActualSTvsODP",
      "type": 1
    },

    {
      "measureId": 45,
      "parentId": 3,
      "measureName": "Actual ST vs ODP %",
      "background": "#8d009d",
      "code": "ActualSTvsODP%",
      "type": 1
    },

    {
      "measureId": 46,
      "parentId": 3,
      "measureName": "Actual ST vs Customer ST Fcst",
      "background": "#8d009d",
      "code": "ActualSTvsCustomerSTFcst",
      "type": 1
    },

    {
      "measureId": 47,
      "parentId": 3,
      "measureName": "Actual ST vs CPFR Forecast",
      "background": "#8d009d",
      "code": "ActualSTvsCPFRForecast",
      "type": 1
    },

    {
      "measureId": 4,
      "parentId": 0,
      "measureName": "Scenario Planning",
      "background": "rgb(239, 12, 94)",
      "code": "ScenarioPlanning",
      "type": 1
    },

    {
      "measureId": 48,
      "parentId": 4,
      "measureName": "GDP High Total Forecast",
      "background": "#8d009d",
      "code": "GDPHighTotalForecast",
      "type": 1
    },

    {
      "measureId": 49,
      "parentId": 4,
      "measureName": "GDP Low Total Forecast",
      "background": "#8d009d",
      "code": "GDPLowTotalForecast",
      "type": 1
    },

    {
      "measureId": 50,
      "parentId": 4,
      "measureName": "RDP High Total Forecast",
      "background": "#8d009d",
      "code": "RDPHighTotalForecast",
      "type": 1
    },

    {
      "measureId": 51,
      "parentId": 4,
      "measureName": "RDP Low Total Forecast",
      "background": "#8d009d",
      "code": "RDPLowTotalForecast",
      "type": 1
    },

    {
      "measureId": 52,
      "parentId": 4,
      "measureName": "UDP High Total Fcst",
      "background": "#8d009d",
      "code": "UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 53,
      "parentId": 4,
      "measureName": "UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 54,
      "parentId": 4,
      "measureName": "UDP High Total Fcst vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "UDPHighTotalFcstvsUDPLikelyTotalFcst",
      "type": 1
    },

    {
      "measureId": 55,
      "parentId": 4,
      "measureName": "UDP Low Total Fcst vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "UDPLowTotalFcstvsUDPLikelyTotalFcst",
      "type": 1
    },

    {
      "measureId": 55,
      "parentId": 4,
      "measureName": "GDP High Total Fcst vs GDP Total Fcst",
      "background": "#8d009d",
      "code": "GDPHighTotalFcstvsGDPTotalFcst",
      "type": 1
    },

    {
      "measureId": 56,
      "parentId": 4,
      "measureName": "GDP Low Total Fcst vs GDP Total Fcst",
      "background": "#8d009d",
      "code": "GDPLowTotalFcstvsGDPTotalFcst",
      "type": 1
    },

    {
      "measureId": 57,
      "parentId": 4,
      "measureName": "GDP High Total Fcst vs UDP High Total Fcst",
      "background": "#8d009d",
      "code": "GDPHighTotalFcstvsUDPHighTotalFcst",
      "type": 1
    },

    {
      "measureId": 58,
      "parentId": 4,
      "measureName": "GDP Low Total Fcst vs UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "GDPLowTotalFcstvsUDPLowTotalFcst",
      "type": 1
    },
    {
      "measureId": 59,
      "parentId": 4,
      "measureName": "GDP High Total Forecast vs RDP High Total Forecast",
      "background": "#8d009d",
      "code": "GDPHighTotalForecastvsRDPHighTotalForecast",
      "type": 1
    },

    {
      "measureId": 60,
      "parentId": 4,
      "measureName": "GDP Total Forecast vs RDP Total Forecast",
      "background": "#8d009d",
      "code": "GDPTotalForecastvsRDPTotalForecast",
      "type": 1
    },

    {
      "measureId": 61,
      "parentId": 4,
      "measureName": "GDP Low Total Forecast vs RDP Low Total Forecast",
      "background": "#8d009d",
      "code": "GDPLowTotalForecastvsRDPLowTotalForecast",
      "type": 1
    },
    {
      "measureId": 5,
      "parentId": 0,
      "measureName": "Last Year ST",
      "background": "rgb(252, 107, 107)",
      "code": "LastYearST",
      "type": 2
    },

    {
      "measureId": 135,
      "parentId": 5,
      "measureName": "LY Actual ST",
      "background": "#8d009d",
      "code": "LYActualST",
      "type": 1
    },

    {
      "measureId": 63,
      "parentId": 5,
      "measureName": "LY Promo Indicator",
      "background": "#8d009d",
      "code": "LYPromoIndicator",
      "type": 1
    },

    {
      "measureId": 64,
      "parentId": 5,
      "measureName": "LY CPFR Comments",
      "background": "#8d009d",
      "code": "LYCPFRComments",
      "type": 1
    },

    {
      "measureId": 65,
      "parentId": 5,
      "measureName": "LY Customer In-Stock Estimate",
      "background": "#8d009d",
      "code": "LYCustomerInStockEstimate",
      "type": 1
    },

    {
      "measureId": 66,
      "parentId": 5,
      "measureName": "TY Actual ST vs LY Actual ST",
      "background": "#8d009d",
      "code": "TYActualSTvsLYActualST",
      "type": 1
    },

    {
      "measureId": 67,
      "parentId": 5,
      "measureName": "GDP Total Fcst vs LY Actual ST",
      "background": "#8d009d",
      "code": "GDPTotalFcstvsLYActualST",
      "type": 1
    },
    {
      "measureId": 68,
      "parentId": 5,
      "measureName": "UDP Likely Total Fcst vs LY Actual ST",
      "background": "#8d009d",
      "code": "UDPLikelyTotalFcstvsLYActualST",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 0,
      "measureName": "Sell-In",
      "background": "rgb(254, 172, 71)",
      "code": "Sell-In",
      "type": 1
    },

    {
      "measureId": 70,
      "parentId": 6,
      "measureName": "UDP Likely Sell-In Fcst",
      "background": "#8d009d",
      "code": "UDPLikelySell-InFcst",
      "type": 1
    },

    {
      "measureId": 71,
      "parentId": 6,
      "measureName": "UDP Low Sell-In Fcst",
      "background": "#8d009d",
      "code": "UDPLowSell-InFcst",
      "type": 1
    },

    {
      "measureId": 72,
      "parentId": 6,
      "measureName": "UDP High Sell-In Fcst",
      "background": "#8d009d",
      "code": "UDPHighSell-InFcst",
      "type": 1
    },

    {
      "measureId": 73,
      "parentId": 6,
      "measureName": "CPFR Sell-In Fcst",
      "background": "#8d009d",
      "code": "CPFRSell-InFcst",
      "type": 1
    },

    {
      "measureId": 74,
      "parentId": 6,
      "measureName": "Customer Receipt Fcst",
      "background": "#8d009d",
      "code": "CustomerReceiptFcst",
      "type": 1
    },

    {
      "measureId": 75,
      "parentId": 6,
      "measureName": "Final Allocation",
      "background": "#8d009d",
      "code": "FinalAllocation",
      "type": 1
    },

    {
      "measureId": 76,
      "parentId": 6,
      "measureName": "Available Allocation",
      "background": "#8d009d",
      "code": "AvailableAllocation",
      "type": 1
    },

    {
      "measureId": 77,
      "parentId": 6,
      "measureName": "Final Allocation vs Available Allocation",
      "background": "#8d009d",
      "code": "FinalAllocationvsAvailableAllocation",
      "type": 1
    },

    {
      "measureId": 78,
      "parentId": 6,
      "measureName": "Final Allocation vs UDP Likely Sell-In Fcst",
      "background": "#8d009d",
      "code": "FinalAllocationvsUDPLikelySell-InFcst",
      "type": 1
    },

    {
      "measureId": 79,
      "parentId": 6,
      "measureName": "Final Allocation vs Customer Receipt Fcst",
      "background": "#8d009d",
      "code": "FinalAllocationvsCustomerReceiptFcst",
      "type": 1
    },

    {
      "measureId": 80,
      "parentId": 6,
      "measureName": "Final Allocation vs CPFR Sell-In Fcst",
      "background": "#8d009d",
      "code": "FinalAllocationvsCPFRSell-InFcst",
      "type": 1
    },

    {
      "measureId": 81,
      "parentId": 6,
      "measureName": "UDP Low Sell-In vs UDP Likely Sell-In",
      "background": "#8d009d",
      "code": "UDPLowSell-InvsUDPLikelySell-In",
      "type": 1
    },

    {
      "measureId": 82,
      "parentId": 6,
      "measureName": "UDP High Sell-In vs UDP Likely Sell-In",
      "background": "#8d009d",
      "code": "UDPHighSell-InvsUDPLikelySell-In",
      "type": 1
    },

    {
      "measureId": 83,
      "parentId": 6,
      "measureName": "UDP Likely Sell-In vs CPFR Sell-In",
      "background": "#8d009d",
      "code": "UDPLikelySell-InvsCPFRSell-In",
      "type": 1
    },

    {
      "measureId": 84,
      "parentId": 6,
      "measureName": "Sell Out",
      "background": "#8d009d",
      "code": "SellOut",
      "type": 1
    },

    {
      "measureId": 85,
      "parentId": 6,
      "measureName": "In Transit",
      "background": "#8d009d",
      "code": "InTransit",
      "type": 1
    },

    {
      "measureId": 86,
      "parentId": 6,
      "measureName": "Open Orders",
      "background": "#8d009d",
      "code": "OpenOrders",
      "type": 1
    },

    {
      "measureId": 87,
      "parentId": 6,
      "measureName": "Shipments",
      "background": "#8d009d",
      "code": "Shipments",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 0,
      "measureName": "Inventory",
      "background": "rgb(67, 232, 168)",
      "code": "Inventory",
      "type": 2
    },

    {
      "measureId": 88,
      "parentId": 7,
      "measureName": "UDP Likely Beginning On Hand",
      "background": "#8d009d",
      "code": "UDPLikelyBeginningOnHand",
      "type": 1
    },

    {
      "measureId": 89,
      "parentId": 7,
      "measureName": "UDP Likely Ending Inventory",
      "background": "#8d009d",
      "code": "UDPLikelyEndingInventory",
      "type": 1
    },

    {
      "measureId": 90,
      "parentId": 7,
      "measureName": "UDP Likely FWOS",
      "background": "#8d009d",
      "code": "UDPLikelyFWOS",
      "type": 1
    },

    {
      "measureId": 91,
      "parentId": 7,
      "measureName": "CPFR Projected Ending Inventory",
      "background": "#8d009d",
      "code": "CPFRProjectedEndingInventory",
      "type": 1
    },

    {
      "measureId": 92,
      "parentId": 7,
      "measureName": "CPFR FWOS",
      "background": "#8d009d",
      "code": "CPFRFWOS",
      "type": 1
    },

    {
      "measureId": 93,
      "parentId": 7,
      "measureName": "Target FWOS",
      "background": "#8d009d",
      "code": "TargetFWOS",
      "type": 1
    },

    {
      "measureId": 94,
      "parentId": 7,
      "measureName": "UDP Likely Target Inventory (units)",
      "background": "#8d009d",
      "code": "UDPLikelyTargetInventory(units)",
      "type": 1
    },

    {
      "measureId": 95,
      "parentId": 7,
      "measureName": "UDP Likely Target Inventory vs UDP Likely Projected Ending Inventory",
      "background": "#8d009d",
      "code": "UDPLikelyTargetInventoryvsUDPLikelyProjectedEndingInventory",
      "type": 1
    },

    {
      "measureId": 96,
      "parentId": 7,
      "measureName": "UDP Likely FWOS vs Target FWOS",
      "background": "#8d009d",
      "code": "UDPLikelyFWOSvsTargetFWOS",
      "type": 1
    },

    {
      "measureId": 97,
      "parentId": 7,
      "measureName": "CPFR FWOS vs Target FWOS",
      "background": "#8d009d",
      "code": "CPFRFWOSvsTargetFWOS",
      "type": 1
    },

    {
      "measureId": 98,
      "parentId": 7,
      "measureName": "Landed Supply",
      "background": "#8d009d",
      "code": "LandedSupply",
      "type": 1
    },

    {
      "measureId": 8,
      "parentId": 0,
      "measureName": "Versions",
      "background": "rgb(28, 159, 173)",
      "code": "Versions",
      "type": 1
    },

    {
      "measureId": 99,
      "parentId": 8,
      "measureName": "V1 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V1UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 100,
      "parentId": 8,
      "measureName": "V1 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V1UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 101,
      "parentId": 8,
      "measureName": "V1 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V1UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 102,
      "parentId": 8,
      "measureName": "V2 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V2UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 103,
      "parentId": 8,
      "measureName": "V2 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V2UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 104,
      "parentId": 8,
      "measureName": "V2 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V2UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 105,
      "parentId": 8,
      "measureName": "V3 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V3UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 106,
      "parentId": 8,
      "measureName": "V3 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V3UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 107,
      "parentId": 8,
      "measureName": "V3 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V3UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 108,
      "parentId": 8,
      "measureName": "V4 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V4UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 109,
      "parentId": 8,
      "measureName": "V4 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V4UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 110,
      "parentId": 8,
      "measureName": "V4 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V4UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 111,
      "parentId": 8,
      "measureName": "V5 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V5UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 112,
      "parentId": 8,
      "measureName": "V5 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V5UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 113,
      "parentId": 8,
      "measureName": "V5 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V5UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 114,
      "parentId": 8,
      "measureName": "V6 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V6UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 115,
      "parentId": 8,
      "measureName": "V6 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V6UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 116,
      "parentId": 8,
      "measureName": "V6 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V6UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 117,
      "parentId": 8,
      "measureName": "V7 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V7UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 118,
      "parentId": 8,
      "measureName": "V7 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V7UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 119,
      "parentId": 8,
      "measureName": "V7 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V7UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 120,
      "parentId": 8,
      "measureName": "V8 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V8UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 121,
      "parentId": 8,
      "measureName": "V8 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V8UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 122,
      "parentId": 8,
      "measureName": "V8 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V8UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 123,
      "parentId": 8,
      "measureName": "V9 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V9UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 124,
      "parentId": 8,
      "measureName": "V9 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V9UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 125,
      "parentId": 8,
      "measureName": "V9 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V9UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 126,
      "parentId": 8,
      "measureName": "V10 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V10UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 127,
      "parentId": 8,
      "measureName": "V10 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V10UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 128,
      "parentId": 8,
      "measureName": "V10 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V10UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 129,
      "parentId": 8,
      "measureName": "V11 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V11UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 130,
      "parentId": 8,
      "measureName": "V11 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V11UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 131,
      "parentId": 8,
      "measureName": "V11 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V11UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 132,
      "parentId": 8,
      "measureName": "V12 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V12UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 133,
      "parentId": 8,
      "measureName": "V12 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V12UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 134,
      "parentId": 8,
      "measureName": "V12 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V12UDPHighTotalFcst",
      "type": 3
    }]
export const Regional_Planning =
  [
    {
      "measureId": 1,
      "parentId": 0,
      "measureName": "Algo ST Fcsts",
      "background": "rgb(68, 16, 79)",
      "code": "AlgoSTFcsts",
      "type": 1
    },
    {
      "measureId": 1,
      "parentId": 1,
      "measureName": "Algo Baseline Fcst",
      "background": "#8d009d",
      "code": "AlgoBaselineFcst",
      "type": 1
    },
    {
      "measureId": 1,
      "parentId": 1,
      "measureName": "Algo Promo Units",
      "background": "#8d009d",
      "code": "AlgoPromoUnits",
      "type": 1
    },
    {
      "measureId": 1,
      "parentId": 1,
      "measureName": "Algo Total Forecast",
      "background": "#8d009d",
      "code": "AlgoTotalForecast",
      "type": 1
    },
    {
      "measureId": 1,
      "parentId": 1,
      "measureName": "Algo TF vs UDP Likely Fcst",
      "background": "#8d009d",
      "code": "AlgoTFvsUDPLikelyFcst",
      "type": 1
    },

    {
      "measureId": 1,
      "parentId": 0,
      "measureName": "Algo TF vs RDP Total Forecast",
      "background": "#8d009d",
      "code": "AlgoTFvsRDPTotalForecast",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 0,
      "measureName": "Demand Planning",
      "background": "rgb(141, 0, 157)",
      "code": "DemandPlanning",
      "type": 1
    },
    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "GDP Total Forecast",
      "background": "#8d009d",
      "code": "GDPTotalForecast",
      "type": 1
    },
    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "RDP Baseline Fcst",
      "background": "#8d009d",
      "code": "RDPBaselineFcst",
      "type": 2
    },
    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "RDP Promo Units",
      "background": "#8d009d",
      "code": "RDPPromoUnits",
      "type": 2
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "RDP Manual Fcst Adjustment",
      "background": "#8d009d",
      "code": "RDPManualFcstAdjustment",
      "type": 2
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "RDP Total Forecast",
      "background": "#8d009d",
      "code": "RDPTotalForecast",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "RDP Comments",
      "background": "#8d009d",
      "code": "RDPComments",
      "type": 2
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "UPD Likely Total Fcst",
      "background": "#8d009d",
      "code": "UPDLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "Customer ST Fcst",
      "background": "#8d009d",
      "code": "CustomerSTFcst",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "Sales Goal",
      "background": "#8d009d",
      "code": "SalesGoal",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "CPFR Forecast",
      "background": "#8d009d",
      "code": "CPFRForecast",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "ODP Total Fcst",
      "background": "#8d009d",
      "code": "ODPTotalFcst",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "ODP Total Fcst vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "ODPTotalFcstvsUDPLikelyTotalFcst",
      "type": 1
    },
    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "RDP Total Fcst vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "RDPTotalFcstvsUDPLikelyTotalFcst",
      "type": 1
    },
    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "RDP Total Fcst vs Customer ST Fcst",
      "background": "#8d009d",
      "code": "RDPTotalFcstvsCustomerSTFcst",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "RDP Total Fcst vs Sales Goal",
      "background": "#8d009d",
      "code": "RDPTotalFcstvsSalesGoal",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "RDP Total Fcst vs CPFR Forecast",
      "background": "#8d009d",
      "code": "RDPTotalFcstvsCPFRForecast",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "RDP Total Fcst vs Actual ST",
      "background": "#8d009d",
      "code": "RDPTotalFcstvsActualST",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "UDP Likely Total Fcst vs Actual ST",
      "background": "#8d009d",
      "code": "UDPLikelyTotalFcstvsActualST",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "UPD Likely Total Fcst vs Customer ST Fcst",
      "background": "#8d009d",
      "code": "UPDLikelyTotalFcstvsCustomerSTFcst",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "CPFR Forecast vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "CPFRForecastvsUDPLikelyTotalFcst",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 0,
      "measureName": "Actual Sales",
      "background": "rgb(216, 26, 209)",
      "code": "ActualSales",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Actual ST",
      "background": "#8d009d",
      "code": "ActualST",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Weekly Trend",
      "background": "#8d009d",
      "code": "WeeklyTrend",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Promo Indicator",
      "background": "#8d009d",
      "code": "PromoIndicator",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Promo Price",
      "background": "#8d009d",
      "code": "PromoPrice",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "CPFR Comments",
      "background": "#8d009d",
      "code": "CPFRComments",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Customer In-Stock Estimate",
      "background": "#8d009d",
      "code": "CustomerInStockEstimate",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Webstore Traffic",
      "background": "#8d009d",
      "code": "WebstoreTraffic",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Webstore Conversion",
      "background": "#8d009d",
      "code": "WebstoreConversion",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Actual ST vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "ActualSTvsUDPLikelyTotalFcst",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Actual ST vs UDP Likely Total Fcst %",
      "background": "#8d009d",
      "code": "ActualSTvsUDPLikelyTotalFcst%",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Actual ST vs ODP",
      "background": "#8d009d",
      "code": "ActualSTvsODP",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Actual ST vs ODP %",
      "background": "#8d009d",
      "code": "ActualSTvsODP%",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Actual ST vs Customer ST Fcst",
      "background": "#8d009d",
      "code": "ActualSTvsCustomerSTFcst",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Actual ST vs CPFR Forecast",
      "background": "#8d009d",
      "code": "ActualSTvsCPFRForecast",
      "type": 1
    },

    {
      "measureId": 4,
      "parentId": 0,
      "measureName": "Scenario Planning",
      "background": "rgb(239, 12, 94)",
      "code": "ScenarioPlanning",
      "type": 1
    },

    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "GDP High Total Forecast",
      "background": "#8d009d",
      "code": "GDPHighTotalForecast",
      "type": 1
    },

    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "GDP Low Total Forecast",
      "background": "#8d009d",
      "code": "GDPLowTotalForecast",
      "type": 1
    },

    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "RDP High Total Forecast",
      "background": "#8d009d",
      "code": "RDPHighTotalForecast",
      "type": 1
    },

    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "RDP Low Total Forecast",
      "background": "#8d009d",
      "code": "RDPLowTotalForecast",
      "type": 1
    },

    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "UDP High Total Fcst",
      "background": "#8d009d",
      "code": "UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "UDP High Total Fcst vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "UDPHighTotalFcstvsUDPLikelyTotalFcst",
      "type": 1
    },

    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "UDP Low Total Fcst vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "UDPLowTotalFcstvsUDPLikelyTotalFcst",
      "type": 1
    },
    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "RDP High Total Fcst vs RDP Total Fcst",
      "background": "#8d009d",
      "code": "RDPHighTotalFcstvsRDPTotalFcst",
      "type": 1
    },

    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "RDP Low Total Fcst vs RDP Total Fcst",
      "background": "#8d009d",
      "code": "RDPLowTotalFcstvsRDPTotalFcst",
      "type": 1
    },

    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "RDP High Total Fcst vs UDP High Total Fcst",
      "background": "#8d009d",
      "code": "RDPHighTotalFcstvsUDPHighTotalFcst",
      "type": 1
    },

    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "RDP Low Total Fcst vs UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "RDPLowTotalFcstvsUDPLowTotalFcst",
      "type": 1
    },

    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "RDP High Total Forecast vs GDP High Total Forecast",
      "background": "#8d009d",
      "code": "RDPHighTotalForecastvsGDPHighTotalForecast",
      "type": 1
    },

    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "RDP Total Forecast vs GDP Total Forecast",
      "background": "#8d009d",
      "code": "RDPTotalForecastvsGDPTotalForecast",
      "type": 1
    },

    {
      "measureId": 4,
      "parentId": 4,
      "measureName": "RDP Low Total Forecast vs GDP Low Total Forecast",
      "background": "#8d009d",
      "code": "RDPLowTotalForecastvsGDPLowTotalForecast",
      "type": 1
    },

    {
      "measureId": 5,
      "parentId": 0,
      "measureName": "Last Year ST",
      "background": "rgb(252, 107, 107)",
      "code": "LastYearST",
      "type": 1
    },

    {
      "measureId": 5,
      "parentId": 5,
      "measureName": "LY Actual ST",
      "background": "#8d009d",
      "code": "LYActualST",
      "type": 1
    },

    {
      "measureId": 5,
      "parentId": 5,
      "measureName": "LY Promo Indicator",
      "background": "#8d009d",
      "code": "LYPromoIndicator",
      "type": 1
    },

    {
      "measureId": 5,
      "parentId": 5,
      "measureName": "LY CPFR Comments",
      "background": "#8d009d",
      "code": "LYCPFRComments",
      "type": 1
    },

    {
      "measureId": 5,
      "parentId": 5,
      "measureName": "LY Customer In-Stock Estimate",
      "background": "#8d009d",
      "code": "LYCustomerInStockEstimate",
      "type": 1
    },

    {
      "measureId": 5,
      "parentId": 5,
      "measureName": "TY Actual ST vs LY Actual ST",
      "background": "#8d009d",
      "code": "TYActualSTvsLYActualST",
      "type": 1
    },
    {
      "measureId": 5,
      "parentId": 5,
      "measureName": "RDP Total Fcst vs LY Actual ST",
      "background": "#8d009d",
      "code": "RDPTotalFcstvsLYActualST",
      "type": 1
    },

    {
      "measureId": 5,
      "parentId": 5,
      "measureName": "UDP Likely Total Fcst vs LY Actual ST",
      "background": "#8d009d",
      "code": "UDPLikelyTotalFcstvsLYActualST",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 0,
      "measureName": "Sell-In",
      "background": "rgb(254, 172, 71)",
      "code": "Sell-In",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "UDP Likely Sell-In Fcst",
      "background": "#8d009d",
      "code": "UDPLikelySell-InFcst",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "UDP Low Sell-In Fcst",
      "background": "#8d009d",
      "code": "UDPLowSell-InFcst",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "UDP High Sell-In Fcst",
      "background": "#8d009d",
      "code": "UDPHighSell-InFcst",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "CPFR Sell-In Fcst",
      "background": "#8d009d",
      "code": "CPFRSell-InFcst",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Customer Receipt Fcst",
      "background": "#8d009d",
      "code": "CustomerReceiptFcst",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Final Allocation",
      "background": "#8d009d",
      "code": "FinalAllocation",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Available Allocation",
      "background": "#8d009d",
      "code": "AvailableAllocation",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Final Allocation vs Available Allocation",
      "background": "#8d009d",
      "code": "FinalAllocationvsAvailableAllocation",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Final Allocation vs UDP Likely Sell-In Fcst",
      "background": "#8d009d",
      "code": "FinalAllocationvsUDPLikelySell-InFcst",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Final Allocation vs Customer Receipt Fcst",
      "background": "#8d009d",
      "code": "FinalAllocationvsCustomerReceiptFcst",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Final Allocation vs CPFR Sell-In Fcst",
      "background": "#8d009d",
      "code": "FinalAllocationvsCPFRSell-InFcst",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "UDP Low Sell-In vs UDP Likely Sell-In",
      "background": "#8d009d",
      "code": "UDPLowSell-InvsUDPLikelySell-In",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "UDP High Sell-In vs UDP Likely Sell-In",
      "background": "#8d009d",
      "code": "UDPHighSell-InvsUDPLikelySell-In",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "UDP Likely Sell-In vs CPFR Sell-In",
      "background": "#8d009d",
      "code": "UDPLikelySell-InvsCPFRSell-In",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Sell Out",
      "background": "#8d009d",
      "code": "SellOut",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "In Transit",
      "background": "#8d009d",
      "code": "InTransit",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Open Orders",
      "background": "#8d009d",
      "code": "OpenOrders",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Shipments",
      "background": "#8d009d",
      "code": "Shipments",
      "type": 1
    },
    {
      "measureId": 7,
      "parentId": 0,
      "measureName": "Inventory",
      "background": "rgb(67, 232, 168)",
      "code": "Inventory",
      "type": 2
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "UDP Likely Beginning On Hand",
      "background": "#8d009d",
      "code": "UDPLikelyBeginningOnHand",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "UDP Likely Ending Inventory",
      "background": "#8d009d",
      "code": "UDPLikelyEndingInventory",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "UDP Likely FWOS",
      "background": "#8d009d",
      "code": "UDPLikelyFWOS",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "CPFR Projected Ending Inventory",
      "background": "#8d009d",
      "code": "CPFRProjectedEndingInventory",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "CPFR FWOS",
      "background": "#8d009d",
      "code": "CPFRFWOS",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "Target FWOS",
      "background": "#8d009d",
      "code": "TargetFWOS",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "UDP Likely Target Inventory (units)",
      "background": "#8d009d",
      "code": "UDPLikelyTargetInventory(units)",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "UDP Likely Target Inventory vs UDP Likely Projected Ending Inventory",
      "background": "#8d009d",
      "code": "UDPLikelyTargetInventoryvsUDPLikelyProjectedEndingInventory",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "UDP Likely FWOS vs Target FWOS",
      "background": "#8d009d",
      "code": "UDPLikelyFWOSvsTargetFWOS",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "CPFR FWOS vs Target FWOS",
      "background": "#8d009d",
      "code": "CPFRFWOSvsTargetFWOS",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "Landed Supply",
      "background": "#8d009d",
      "code": "LandedSupply",
      "type": 1
    },

    {
      "measureId": 8,
      "parentId": 0,
      "measureName": "Versions",
      "background": "rgb(28, 159, 173)",
      "code": "Versions",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V1 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V1UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V1 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V1UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V1 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V1UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V2 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V2UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V2 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V2UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V2 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V2UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V3 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V3UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V3 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V3UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V3 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V3UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V4 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V4UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V4 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V4UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V4 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V4UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V5 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V5UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V5 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V5UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V5 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V5UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V6 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V6UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V6 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V6UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V6 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V6UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V7 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V7UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V7 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V7UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V7 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V7UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V8 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V8UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V8 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V8UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V8 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V8UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V9 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V9UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V9 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V9UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V9 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V9UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V10 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V10UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V10 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V10UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V10 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V10UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V11 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V11UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V11 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V11UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V11 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V11UDPHighTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V12 UDP Low Total Fcst",
      "background": "#8d009d",
      "code": "V12UDPLowTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V12 UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "V12UDPLikelyTotalFcst",
      "type": 3
    },

    {
      "measureId": 8,
      "parentId": 8,
      "measureName": "V12 UDP High Total Fcst",
      "background": "#8d009d",
      "code": "V12UDPHighTotalFcst",
      "type": 3
    }]

export const Retail_Direct =
  [
    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "UPD Likely Total Fcst",
      "background": "#8d009d",
      "code": "UPDLikelyTotalFcst",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "Customer ST Fcst",
      "background": "#8d009d",
      "code": "CustomerSTFcst",
      "type": 2
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "CPFR Forecast",
      "background": "#8d009d",
      "code": "CPFRForecast",
      "type": 2
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "ODP Total Fcst",
      "background": "#8d009d",
      "code": "ODPTotalFcst",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "ODP Total Fcst vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "ODPTotalFcstvsUDPLikelyTotalFcst",
      "type": 1
    },


    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "UDP Likely Total Fcst vs Actual ST",
      "background": "#8d009d",
      "code": "UDPLikelyTotalFcstvsActualST",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "UPD Likely Total Fcst vs Customer ST Fcst",
      "background": "#8d009d",
      "code": "UPDLikelyTotalFcstvsCustomerSTFcst",
      "type": 1
    },

    {
      "measureId": 2,
      "parentId": 2,
      "measureName": "CPFR Forecast vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "CPFRForecastvsUDPLikelyTotalFcst",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 0,
      "measureName": "Actual Sales",
      "background": "rgb(216, 26, 209)",
      "code": "ActualSales",
      "type": 2
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Actual ST",
      "background": "#8d009d",
      "code": "ActualST",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Weekly Trend",
      "background": "#8d009d",
      "code": "WeeklyTrend",
      "type": 2
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Promo Indicator",
      "background": "#8d009d",
      "code": "PromoIndicator",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Promo Price",
      "background": "#8d009d",
      "code": "PromoPrice",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "CPFR Comments",
      "background": "#8d009d",
      "code": "CPFRComments",
      "type": 2
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Customer In-Stock Estimate",
      "background": "#8d009d",
      "code": "CustomerInStockEstimate",
      "type": 2
    },
    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Actual ST vs UDP Likely Total Fcst",
      "background": "#8d009d",
      "code": "ActualSTvsUDPLikelyTotalFcst",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Actual ST vs UDP Likely Total Fcst %",
      "background": "#8d009d",
      "code": "ActualSTvsUDPLikelyTotalFcst%",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Actual ST vs ODP",
      "background": "#8d009d",
      "code": "ActualSTvsODP",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Actual ST vs ODP %",
      "background": "#8d009d",
      "code": "ActualSTvsODP%",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Actual ST vs Customer ST Fcst",
      "background": "#8d009d",
      "code": "ActualSTvsCustomerSTFcst",
      "type": 1
    },

    {
      "measureId": 3,
      "parentId": 3,
      "measureName": "Actual ST vs CPFR Forecast",
      "background": "#8d009d",
      "code": "ActualSTvsCPFRForecast",
      "type": 1
    },
    {
      "measureId": 6,
      "parentId": 0,
      "measureName": "Sell-In",
      "background": "rgb(254, 172, 71)",
      "code": "Sell-In",
      "type": 2
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "UDP Likely Sell-In Fcst",
      "background": "#8d009d",
      "code": "UDPLikelySell-InFcst",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "CPFR Sell-In Fcst",
      "background": "#8d009d",
      "code": "CPFRSell-InFcst",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Customer Receipt Fcst",
      "background": "#8d009d",
      "code": "CustomerReceiptFcst",
      "type": 2
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Final Allocation",
      "background": "#8d009d",
      "code": "FinalAllocation",
      "type": 2
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Available Allocation",
      "background": "#8d009d",
      "code": "AvailableAllocation",
      "type": 2
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Final Allocation vs Available Allocation",
      "background": "#8d009d",
      "code": "FinalAllocationvsAvailableAllocation",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Final Allocation vs UDP Likely Sell-In Fcst",
      "background": "#8d009d",
      "code": "FinalAllocationvsUDPLikelySell-InFcst",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Final Allocation vs Customer Receipt Fcst",
      "background": "#8d009d",
      "code": "FinalAllocationvsCustomerReceiptFcst",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Final Allocation vs CPFR Sell-In Fcst",
      "background": "#8d009d",
      "code": "FinalAllocationvsCPFRSell-InFcst",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Sell Out",
      "background": "#8d009d",
      "code": "SellOut",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "In Transit",
      "background": "#8d009d",
      "code": "InTransit",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Open Orders",
      "background": "#8d009d",
      "code": "OpenOrders",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Shipments",
      "background": "#8d009d",
      "code": "Shipments",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Open Orders vs Final Allocation",
      "background": "#8d009d",
      "code": "OpenOrdersvsFinalAllocation",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Pallet Qty",
      "background": "#8d009d",
      "code": "PalletQty",
      "type": 1
    },

    {
      "measureId": 6,
      "parentId": 6,
      "measureName": "Casepack Qty",
      "background": "#8d009d",
      "code": "CasepackQty",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 0,
      "measureName": "Inventory",
      "background": "rgb(67, 232, 168)",
      "code": "Inventory",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "UDP Likely Beginning On Hand",
      "background": "#8d009d",
      "code": "UDPLikelyBeginningOnHand",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "UDP Likely Ending Inventory",
      "background": "#8d009d",
      "code": "UDPLikelyEndingInventory",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "UDP Likely FWOS",
      "background": "#8d009d",
      "code": "UDPLikelyFWOS",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "CPFR Projected Ending Inventory",
      "background": "#8d009d",
      "code": "CPFRProjectedEndingInventory",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "CPFR FWOS",
      "background": "#8d009d",
      "code": "CPFRFWOS",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "Target FWOS",
      "background": "#8d009d",
      "code": "TargetFWOS",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "UDP Likely Target Inventory (units)",
      "background": "#8d009d",
      "code": "UDPLikelyTargetInventory(units)",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "UDP Likely Target Inventory vs UDP Likely Projected Ending Inventory",
      "background": "#8d009d",
      "code": "UDPLikelyTargetInventoryvsUDPLikelyProjectedEndingInventory",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "UDP Likely FWOS vs Target FWOS",
      "background": "#8d009d",
      "code": "UDPLikelyFWOSvsTargetFWOS",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "CPFR FWOS vs Target FWOS",
      "background": "#8d009d",
      "code": "CPFRFWOSvsTargetFWOS",
      "type": 1
    },

    {
      "measureId": 7,
      "parentId": 7,
      "measureName": "Landed Supply",
      "background": "#8d009d",
      "code": "LandedSupply",
      "type": 1
    },

  ]
