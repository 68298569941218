export const GBP_data = [
    0.775477,
    0.768832,
    0.759735,
    0.767821,
    0.777814,
    0.788865,
    0.80178,
    0.822511,
    0.809424,
    0.789734,
    0.776478,
    0.763323,
    0.764843,
    0.771086,
    0.809713,
    0.805067,
    0.812361,
    0.799388,
    0.789464,
    0.761266,
    0.772376,
    0.770195,
    0.75717,
    0.744745,
    0.733131,
    0.720941,
    0.721552,
    0.722889,
    0.711017,
    0.713579,
    0.724037,
    0.724858,
    0.727466,
    0.730862,
    0.743874,
    0.749625187406297,
    0.769230769230769,
    0.789265982636148,
    0.809716599190283,
    0.796812749003984,
    0.81366965012205,
    0.822368421052632,
    0.817661488143908,
    0.840336134453782,
    0.817661488143908,
    0.815660685154976,
    0.824402308326463,
    0.810372771474878,
    0.808407437348424,
    0.788643533123028,
    0.766283524904215,
    0.763941940412529,
    0.78740157480315,
    0.771604938271605,
    0.749063670411985,
    0.745156482861401,
    0.76103500761035,
    0.750187546886722,
    0.773395204949729,
    0.796178343949045,
    0.799360511590728,
    0.78003120124805,
    0.78003120124805,
    0.757575757575758,
    0.749625187406297,
    0.749625187406297,
    0.772797527047913,
    0.768049155145929,
    0.791765637371338,
    0.791139240506329,
    0.805152979066023,
    0.796178343949045
];

export const EUR_data = [
    0.875631,
    0.880959,
    0.885152,
    0.890132,
    0.893524,
    0.884948,
    0.891499,
    0.897508,
    0.907199,
    0.903815,
    0.904806,
    0.900067,
    0.901049,
    0.916057,
    0.904858,
    0.920221,
    0.914601,
    0.888586,
    0.873021,
    0.845299,
    0.848377,
    0.849583,
    0.844585,
    0.821311,
    0.822154,
    0.826854,
    0.840161,
    0.83578,
    0.82331,
    0.830711,
    0.845645,
    0.849618,
    0.849398,
    0.862308,
    0.87763,
    1.132,
    1.107,
    1.083,
    1.06,
    1.058,
    1.038,
    1.033,
    1.034,
    1.003,
    1.021,
    1.046,
    1.016,
    1.011,
    1.003,
    1.028,
    1.054,
    1.047,
    1.027,
    1.04,
    1.071,
    1.083,
    1.098,
    1.089,
    1.09,
    1.084,
    1.073,
    1.092,
    1.079,
    1.1,
    1.095,
    1.087,
    1.059,
    1.077,
    1.073,
    1.073,
    1.059,
    1.052
];

export const CAD_data = [
    1.330202,
    1.320638,
    1.337235,
    1.338409,
    1.345505,
    1.330281,
    1.310256,
    1.327759,
    1.324762,
    1.318787,
    1.323737,
    1.317169,
    1.307925,
    1.328071,
    1.393565,
    1.405301,
    1.398399,
    1.355756,
    1.350647,
    1.324277,
    1.321976,
    1.320232,
    1.306551,
    1.281255,
    1.272969,
    1.269604,
    1.256901,
    1.249967,
    1.213141,
    1.222946,
    1.251287,
    1.260335,
    1.26635,
    1.24427,
    1.258366,
    1.279,
    1.321,
    1.361,
    1.402,
    1.369,
    1.375,
    1.392,
    1.399,
    1.438,
    1.412,
    1.381,
    1.362,
    1.356,
    1.363,
    1.334,
    1.302,
    1.303,
    1.331,
    1.294,
    1.279,
    1.259,
    1.244,
    1.233,
    1.27,
    1.285,
    1.308,
    1.281,
    1.292,
    1.284,
    1.278,
    1.29,
    1.301,
    1.262,
    1.273,
    1.279,
    1.295,
    1.299
];
