import {
  Component,
  OnInit,
  ViewEncapsulation,
  TemplateRef,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { NgxToasterService } from '../../../../../services/ngx-toaster.service';
import { LocalstorageService } from '../../../../../services/localstorage.service';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { FbForecast } from '../../../../../common/facebook.models';
import { USER_ID } from '../../../../../common/keys';
import { ForecastConfirmationModalComponent } from '../../fb-forecast/forecast-confirmation-modal/forecast-confirmation-modal.component';
import {
  AccountMeasuresResponse,
  FB_Template,
  HalfYear,
  Month,
  PhysicalYear,
  Quarter,
  Week,
} from '../../global.models';
import { ImportPlannerComponent } from '../../shared/planner-import/planner-import.component';
import {
  addCommas,
  CreateTimeframeUIList,
  EqualValueMeasures,
  onKeyDown,
  setFocusOnNextInput,
} from 'src/common/functions';

import { ExportAllTemplateComponent } from '../../shared/export-all-template/export-all-template.component';
import * as moment from 'moment';
import { InlineWorker } from '../inline-worker';
import { Workbook, Column } from 'exceljs';
import * as fs from 'file-saver';
import { RetailIndirectPlannerMeasureProfileComponent } from '../retail-indirect-planner-measure-profile/retail-indirect-planner-measure-profile.component';
@Component({
  selector: 'app-retail-indirect-planner-grid',
  templateUrl: './retail-indirect-planner-grid.component.html',
  styleUrls: ['./retail-indirect-planner-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailIndirectPlannerGridComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() selectedProducts;
  @Input() selectedChannel;
  @Input() productTree;
  @Input() calendarIds;
  @Input() title;
  @Input() userFBForecastId;
  @Output() onClickEvent = new EventEmitter();
  @Output() updateGridList = new EventEmitter();
  @Input() fbForecast;
  @Input() selectedDistibutor;
  @Input() selectedAccounts = [];
  @Input() distributorList = [];
  @Input() invItemIds;
  @Input() invChannelIds;
  @Input() masterMeasureList = [];
  selectedProduct;
  public feededMeasures = [132];
  exportOptions = null;
  public oldList = [];
  methodList = [
    'Algo Baseline ST Forecast',
    'DP Baseline ST Fcst',
    'CO Baseline ST Fcst',
    'Linear',
    'Curve',
    'Exponential',
    'Logarithmic',
    'Power',
    'Parabola',
    'Moving Average',
    'Single Expotential Smoothing',
    'Adaptive Smoothing',
    'Holts Linear Trend',
    'Period Percentage Change',
    'Seasonal Smoothing Method',
    'Seasonal Smoothing Average',
    'Seasonal Percentage Change',
    'Additive Decomposition',
    'Winters Additive',
  ];
  @Input() leftGroupList = [];
  @Input() measureList = [];
  @Input() dropdownObjects;
  @Input() forecastList = [];
  @Input() itemsList = [];
  @Input() accountList = [];
  @Input() versionTypeId = 2;
  @Input() fbForecastSelectedId;
  public flatCalendarIds = [];
  public selectedValue;
  width = '100%';
  height = '500';
  type = 'msline';
  dataFormat = 'json';
  dataSource;
  public submitted = false;
  public isEdit = false;
  public columnDefs = [];
  private gridApi: any;
  public tenureTypeList = [];
  public tenureTypeListTemp = [];
  public rowList = [];
  public gridOptions: any;
  public getRowHeight;
  public getRowStyle;
  public headerHeight;
  public modules = AllCommunityModules;
  public clientId = 1;
  public channel = null;
  public csvRecords: any;
  public customerSelectedList = [];
  public selectedItemIds = [];
  public timeframeSelectedList = [];
  public lastYearCalendarList = [];
  public itemSeletedList = [];
  public spreadEqualMeasures = EqualValueMeasures;
  public timeframeList = [
    {
      headerName: 'Year',
    },
    {
      headerName: 'Half Year',
    },
    {
      headerName: 'Quarter',
    },
    {
      headerName: 'Monthly',
    },
    {
      headerName: 'Weekly',
    },
  ];
  public versionList = [];
  public monthList = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredCustomers: Observable<any[]>;
  filteredItems: Observable<any[]>;
  filteredTimeframe: Observable<any[]>;
  public seletedYear = null;
  public seletedVersion = null;
  public selectedMonth = null;
  public defaultRow = {};
  public selectAll = true;
  searchCustomer = new FormControl();
  searchItem = new FormControl();
  searchTimeframe = new FormControl();
  files: File[] = [];
  @Input() public yearListGrid = [];
  @Input() public calendarList = [];
  @Input() viewPermission: any;
  @Input() editPermission: any;
  @Input() public forecastMethod;
  @Input() public seasonalitySourceLevel;
  @Input() public movingAverageWeeks;
  @Input() public isOpenSaved;
  @Input() public isRestoreSession;
  yearList = [];
  totalCalculatedList = [];
  tempColDefs = [];
  yearListTemp = [];
  yearListForFilter = [];
  graphList = [];
  tempGroupList = [];
  labelList;
  channelSelectedList;
  chooserList = [];
  gridListUI = [];
  selectedProfile: any;
  profileMeasureList: any;
  savedMeasures: any[];
  @Input() account: any;
  @Input() product: any;
  public selectedChannelIds = [];
  public sessionUpdate = false;
  public collapsedObject;
  public openingOH = 0;
  public targetWOS = 4;
  public leadTime = 14;
  public AccountUnconstrainedSellIn = 100;
  public forecastSubscription = new Subscription();
  public methodSubscription = new Subscription();
  public selectedList = [];
  public selectedOperationList = [];
  public fbTemplateId = FB_Template.Retail_InDirect;
  @Input() measureKeys = [];
  public lowHeirarichyMeasures = [
    'ActualSTCumulative',
    'CustomerSTFcstCumulative',
    'UDPLikelyTotalFcstCumulative',
    'CPFRSellThruOverrideFcstCumulative',
    'AccountConstrainedSellInFcstCumulative',
    'IndirectAccountConstrainedSellInCumulative',
    'DistiActualSTCumulative',
    'DistiCustomerSTFcstCumulative',
    'DistiUDPLikelyTotalFcstCumulative',
    'DistiCPFRSellThruOverrideFcstCumulative',
    'DistiAccountConstrainedSellInFcstCumulative',
    'DistiConstrainedSellInFcstCumulative',
    'FBProjectedEndingOnHand',
    'FBTargetEndingOnHand',
    'FBProjectedFWOS',
  ];

  get DistiMeasures() {
    return this.measureList
      .filter((row) => row.isDisti)
      .sort((a, b) => a.distiSort - b.distiSort);
  }
  get AccountMeasures() {
    return this.measureList
      .filter((row) => row.isAccount)
      .sort((a, b) => a.sort - b.sort);
  }
  get AccountTotalMeasures() {
    return this.measureList
      .filter((row) => row.isAccSubtotal)
      .sort((a, b) => a.sort - b.sort);
  }
  get DistiAccountTotalMeasures() {
    return this.measureList
      .filter((row) => row.isDistAccTotal)
      .sort((a, b) => a.sort - b.sort);
  }
  get DistiParentMeasures() {
    return this.leftGroupList
      .filter((row) => row.isDisti)
      .sort((a, b) => a.distiSort - b.distiSort)
      .map((row) => ({
        ...row,
        rowspan: this.DistiMeasures.filter(
          (measure) => measure.parentId === row.measureId
        ).length,
      }))
      .filter((el) => el.rowspan);
  }
  get AccountParentMeasures() {
    return this.leftGroupList
      .filter((row) => row.isAccount)
      .sort((a, b) => a.sort - b.sort)
      .map((row) => ({
        ...row,
        rowspan: this.AccountMeasures.filter(
          (measure) => measure.parentId === row.measureId
        ).length,
      }))
      .filter((el) => el.rowspan);
  }
  get AccountTotalParentMeasures() {
    return this.leftGroupList
      .filter((row) => row.isAccSubtotal)
      .sort((a, b) => a.sort - b.sort)
      .map((row) => ({
        ...row,
        rowspan: this.AccountTotalMeasures.filter(
          (measure) => measure.parentId === row.measureId
        ).length,
      }))
      .filter((el) => el.rowspan);
  }
  get DistiAccountTotalParentMeasures() {
    return this.leftGroupList
      .filter((row) => row.isDistAccTotal)
      .sort((a, b) => a.sort - b.sort)
      .map((row) => ({
        ...row,
        rowspan: this.DistiAccountTotalMeasures.filter(
          (measure) => measure.parentId === row.measureId
        ).length,
      })).filter((el) => el.rowspan);
  }
  public currentAccount(year, account) {
    return year.riAccountMeasures.find(
      (el) => el.channelId === account.itemAccountId
    );
  }
  public loading = false;
  constructor(
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    public dialogRef: MatDialogRef<any>,
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef
  ) {
    this.configurationService.openImportDialog$.subscribe((res) => {
      if (res && res.isIndirectPlanner) {
        this.import();
      }
    });
  }
  get gridData() {
    return this.gridListUI.filter((row) => row.isSelected);
  }
  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event) {
    // we make sure only draggables on the document elements are selecte
    let title = event.target.getAttribute('title');
    if (!this.selectedList.find((row) => row.measure === title)) {
      this.selectedList.forEach((row) => {
        row.field.style.background = 'rgb(238, 238, 238)';
      });
      this.selectedList = [];
      if (this.selectedList.length > 1) {
        this.toastr.success('Success', 'Clipboard cleared!');
      }
    }
  }
  ngOnInit() {
    this.configurationService.updateGridList$.subscribe((res) => {
      if (res) {
        this.yearListGrid = res;
        this.createUIList(true);
      }
    });
    this.tempGroupList = JSON.parse(JSON.stringify(this.leftGroupList));
  }
  ngOnDestroy() {
    this.forecastSubscription.unsubscribe();
    this.methodSubscription.unsubscribe();
    this.configurationService.tempForecastList = [];
  }
  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges &&
      simpleChanges.measureList &&
      simpleChanges.measureList.currentValue
    ) {
      this.measureList = simpleChanges.measureList.currentValue;
      this.savedMeasures = this.measureList;
    }
    if (
      simpleChanges &&
      simpleChanges.leftGroupList &&
      simpleChanges.leftGroupList.currentValue
    ) {
      this.leftGroupList = simpleChanges.leftGroupList.currentValue;
      this.tempGroupList = JSON.parse(JSON.stringify(this.leftGroupList));
    }
    if (
      simpleChanges &&
      simpleChanges.yearListGrid &&
      simpleChanges.yearListGrid.currentValue
    ) {
      this.yearListGrid = simpleChanges.yearListGrid.currentValue;
      // this.yearListGrid.forEach((year) => this.collapseYear(year));
      this.createUIList(true);
    }
    if (
      simpleChanges &&
      simpleChanges.account &&
      simpleChanges.account.currentValue
    ) {
      this.account = simpleChanges.account.currentValue;
    }
    if (
      simpleChanges &&
      simpleChanges.product &&
      simpleChanges.product.currentValue
    ) {
      this.product = simpleChanges.product.currentValue;
    }
    if (
      simpleChanges &&
      simpleChanges.viewPermission &&
      simpleChanges.viewPermission.currentValue
    ) {
      this.viewPermission = simpleChanges.viewPermission.currentValue;
    }
    if (
      simpleChanges &&
      simpleChanges.editPermission &&
      simpleChanges.editPermission.currentValue
    ) {
      this.editPermission = simpleChanges.editPermission.currentValue;
    }
    this.filterLeftGroups();
  }
  // import() {
  //   this.dialog
  //     .open(ImportPlannerComponent, {
  //       width: '1200px',
  //       panelClass: 'fb-dialog-wrapper',

  //       data: {
  //         forecastList: this.forecastList,
  //         gridListUI: this.gridListUI,
  //         dropdownObjects: this.dropdownObjects,
  //         userFBForecastId: this.userFBForecastId || 0,
  //         itemsList: this.itemsList,
  //         accountList: this.accountList,
  //         measureList: this.measureList,
  //         calendarList: this.calendarList,
  //         versionTypeId: this.versionTypeId,
  //         fbForecastSelectedId: this.fbForecastSelectedId,
  //         fbTemplateId: FB_Template.Retail_InDirect,
  //         isIndirectPlanner: true,
  //       },
  //     })
  //     .afterClosed()
  //     .subscribe((command) => {
  //       this.configurationService.openImportDialog.next(false);
  //       if (command && command.list.length > 0 && command.isIndirectPlanner) {
  //         let forecastObject = {
  //           measureId: 0,
  //           calendarIds: '',
  //           itemIds: this.dropdownObjects.toString(),
  //           channelIds: this.dropdownObjects.toString(),
  //           value: 0,
  //           textValue: '',
  //           versionTypeId: this.versionTypeId,
  //           fbTemplateId: FB_Template.Retail_Direct,
  //           userId: this.storage.get(USER_ID),
  //           distiId: this.selectedDistibutor.distributorId,
  //           userFBForecastId: this.userFBForecastId || 0,
  //         };
  //         let tasks = [];
  //         const accountForecast = this.importAccountRows(command) || [];
  //         const distiForecast = this.importDistiRows(command) || [];
  //         tasks.push(this.configurationService.FbForecastSpreadingAddList(distiForecast),this.configurationService.FbForecastSpreadingAdd(accountForecast))
  //         forkJoin(tasks).subscribe(
  //           (res) => {
  //             const model = {
  //               userId: this.storage.get(USER_ID),
  //               userFBForecastId: this.userFBForecastId || 0,
  //               fbTemplateId: FB_Template.Retail_InDirect,
  //               RestoreSession: true,
  //               VersionTypeId: this.versionTypeId,
  //               itemIds: this.dropdownObjects.itemIds.toString(),
  //               channelIds: this.dropdownObjects.channelIds.toString(),
  //               invItemIds: this.invItemIds,
  //               invChannelIds: this.invChannelIds,
  //               calendarIds: this.calendarIds.flat().toString(),
  //               distiId: this.selectedDistibutor.distributorId,
  //               isSessionUpdate: true,
  //             };
  //             this.updateGridList.emit(model);
  //             this.toastr.success(
  //               'Retail In-Direct Planner',
  //               'Measures data imported successfully'
  //             );
  //             this.spinner.hide();
  //           },
  //           (error) => {
  //             this.spinner.hide();
  //           }
  //         );
  //       }
  //     });
  // }
  // public importDistiRows(command) {
  //   if (command && command.distiListUpload.length > 0) {
  //     let forecastObject = {
  //       measureId: 0,
  //       calendarIds: '',
  //       itemIds: this.selectedItemIds.toString(),
  //       channelIds: this.selectedChannelIds.toString(),
  //       value: 0,
  //       textValue: '',
  //       versionTypeId: this.versionTypeId,
  //       fbTemplateId: FB_Template.Retail_InDirect,
  //       userId: this.storage.get(USER_ID),
  //       userFBForecastId: this.userFBForecastId || 0,
  //     };
  //     let forecast = new FbForecast(null);
  //     command.distiListUpload.forEach((calendar) => {
  //       forecast.itemIds = this.dropdownObjects.itemIds.toString();
  //       forecast.channelIds = this.dropdownObjects.channelIds.toString();
  //       forecast.calendarIds = this.dropdownObjects.calendarIds.toString();
  //       forecast.userId = this.storage.get(USER_ID);
  //       forecast.distiId = calendar.distiId;
  //       forecast.versionTypeId = this.versionTypeId;
  //       forecast.fbTemplateId = FB_Template.Retail_InDirect;
  //       forecast.forecastMethodId = 38;
  //       forecast.seasonalitySourceLevelId = 8;
  //       forecast.movingAverageWeeksId = 20;
  //       forecast.userFBForecastId = this.userFBForecastId || 0;
  //       forecastObject.measureId = calendar.measureId;
  //       forecastObject.value = calendar.value;
  //       forecastObject.textValue = calendar.textValue;
  //       forecastObject.itemIds = calendar.itemId;
  //       forecastObject.channelIds = calendar.channelId;
  //       forecastObject.calendarIds = calendar.calendarId.toString();
  //       forecast.fbweeks.push(JSON.parse(JSON.stringify(forecastObject)));
  //     });
  //     return forecast;
  //   }
  // }
  // public importAccountRows(command) {
  //   if (
  //     command &&
  //     command.accountListUpload.length > 0 &&
  //     command.isIndirectPlanner
  //   ) {
  //     let forecastObject = {
  //       measureId: 0,
  //       calendarIds: '',
  //       itemIds: this.selectedItemIds.toString(),
  //       channelIds: this.selectedChannelIds.toString(),
  //       value: 0,
  //       textValue: '',
  //       versionTypeId: this.versionTypeId,
  //       fbTemplateId: FB_Template.Retail_InDirect,
  //       userId: this.storage.get(USER_ID),
  //       userFBForecastId: this.userFBForecastId || 0,
  //     };
  //     let forecast = new FbForecast(null);
  //     command.accountListUpload.forEach((calendar) => {
  //       forecast.itemIds = this.dropdownObjects.itemIds.toString();
  //       forecast.channelIds = this.dropdownObjects.channelIds.toString();
  //       forecast.calendarIds = this.dropdownObjects.calendarIds.toString();
  //       forecast.userId = this.storage.get(USER_ID);
  //       forecast.versionTypeId = this.versionTypeId;
  //       forecast.fbTemplateId = FB_Template.Retail_InDirect;
  //       forecast.forecastMethodId = 38;
  //       forecast.seasonalitySourceLevelId = 8;
  //       forecast.movingAverageWeeksId = 20;
  //       forecast.userFBForecastId = this.userFBForecastId || 0;
  //       forecastObject.measureId = calendar.measureId;
  //       forecastObject.value = calendar.value;
  //       forecastObject.textValue = calendar.textValue;
  //       forecastObject.itemIds = calendar.itemId;
  //       forecastObject.channelIds = calendar.channelId;
  //       forecastObject.calendarIds = calendar.calendarId.toString();
  //       forecast.fbweeks.push(JSON.parse(JSON.stringify(forecastObject)));
  //    });
  //     return forecast;
  //   }
  // }
  import() {
    this.dialog
      .open(ImportPlannerComponent, {
        width: '1200px',
        panelClass: 'fb-dialog-wrapper',

        data: {
          forecastList: this.forecastList,
          gridListUI: this.gridListUI,
          dropdownObjects: this.dropdownObjects,
          userFBForecastId: this.userFBForecastId || 0,
          itemsList: this.itemsList,
          accountList: this.accountList,
          measureList: this.measureList,
          calendarList: this.calendarList,
          versionTypeId: this.versionTypeId,
          fbForecastSelectedId: this.fbForecastSelectedId,
          fbTemplateId: FB_Template.Retail_InDirect,
          isIndirectPlanner: true,
          distributorList: this.distributorList,
        },
      })
      .afterClosed()
      .subscribe((command) => {
        this.configurationService.openImportDialog.next(false);
        const distiTasks = this.importDistiRows(command) || [];
        const accountTasks = this.importAccountRows(command) || [];
        const tasks = [...distiTasks, ...accountTasks];
        forkJoin(tasks).subscribe(
          (res) => {
            const updatedValues = {
              itemIds:
                this.selectedItemIds.length > 0 &&
                this.selectedItemIds.find((el) => el)
                  ? this.selectedItemIds
                  : this.dropdownObjects.itemIds,
              channelIds:
                this.selectedChannelIds.length > 0 &&
                this.selectedChannelIds.find((el) => el)
                  ? this.selectedChannelIds
                  : this.dropdownObjects.channelIds,
              calendarIds: this.dropdownObjects.calendarIds,
              isSessionUpdate: true,
            };
            this.updateGridList.emit(updatedValues);
            this.toastr.success(
              'Retail Indirect Planner',
              'Measures data imported successfully'
            );
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
      });
  }
  public importDistiRows(command) {
    if (command && command.distiListUpload.length > 0) {
      let models = [];
      let tasks = [];
      let forecastObject = {
        measureId: 0,
        calendarIds: '',
        itemIds: this.selectedItemIds.toString(),
        channelIds: this.selectedChannelIds.toString(),
        value: 0,
        textValue: '',
        versionTypeId: this.versionTypeId,
        fbTemplateId: FB_Template.Retail_InDirect,
        userId: this.storage.get(USER_ID),
        userFBForecastId: this.userFBForecastId || 0,
      };
      command.distiListUpload.forEach((calendar) => {
        let forecast = new FbForecast(null);
        forecast.itemIds = this.dropdownObjects.itemIds.toString();
        forecast.channelIds = this.dropdownObjects.channelIds.toString();
        forecast.calendarIds = this.dropdownObjects.calendarIds.toString();
        forecast.userId = this.storage.get(USER_ID);
        forecast.distiId = calendar.distiId;
        forecast.versionTypeId = this.versionTypeId;
        forecast.fbTemplateId = FB_Template.Retail_InDirect;
        forecast.forecastMethodId = 38;
        forecast.seasonalitySourceLevelId = 8;
        forecast.movingAverageWeeksId = 20;
        forecast.userFBForecastId = this.userFBForecastId || 0;
        forecastObject.measureId = calendar.measureId;
        forecastObject.value = calendar.value;
        forecastObject.textValue = calendar.textValue;
        forecastObject.itemIds = calendar.itemId;
        forecastObject.channelIds = calendar.channelId;
        forecastObject.calendarIds = calendar.calendarId.toString();
        forecast.fbweek = JSON.parse(JSON.stringify(forecastObject));
        models.push(forecast);
      });
      models.forEach((m) => {
        tasks.push(this.configurationService.FbForecastSpreadingAddDisti(m));
      });
      return tasks;
    }
  }
  public importAccountRows(command) {
    if (
      command &&
      command.accountListUpload.length > 0 &&
      command.isIndirectPlanner
    ) {
      let forecastObject = {
        measureId: 0,
        calendarIds: '',
        itemIds: this.selectedItemIds.toString(),
        channelIds: this.selectedChannelIds.toString(),
        value: 0,
        textValue: '',
        versionTypeId: this.versionTypeId,
        fbTemplateId: FB_Template.Retail_InDirect,
        userId: this.storage.get(USER_ID),
        userFBForecastId: this.userFBForecastId || 0,
      };
      let tasks = [];
      let models = [];
      command.accountListUpload.forEach((calendar) => {
        let forecast = new FbForecast(null);
        forecast.itemIds = this.dropdownObjects.itemIds.toString();
        forecast.channelIds = this.dropdownObjects.channelIds.toString();
        forecast.calendarIds = this.dropdownObjects.calendarIds.toString();
        forecast.userId = this.storage.get(USER_ID);
        forecast.versionTypeId = this.versionTypeId;
        forecast.fbTemplateId = FB_Template.Retail_InDirect;
        forecast.forecastMethodId = 38;
        forecast.seasonalitySourceLevelId = 8;
        forecast.movingAverageWeeksId = 20;
        forecast.userFBForecastId = this.userFBForecastId || 0;
        forecastObject.measureId = calendar.measureId;
        forecastObject.value = calendar.value;
        forecastObject.textValue = calendar.textValue;
        forecastObject.itemIds = calendar.itemId;
        forecastObject.channelIds = calendar.channelId;
        forecastObject.calendarIds = calendar.calendarId.toString();
        forecast.fbweek = JSON.parse(JSON.stringify(forecastObject));
        models.push(forecast);
      });
      models.forEach((m) => {
        tasks.push(this.configurationService.FbForecastSpreadingAdd(m));
      });
      return tasks;
    }
  }
  openMesuresDialog() {
    this.profileMeasureList = JSON.parse(
      JSON.stringify(
        this.masterMeasureList.map((row) => ({ ...row, checked: false }))
      )
    );
    this.dialog
      .open(RetailIndirectPlannerMeasureProfileComponent, {
        width: '1200px',
        panelClass: 'fb-dialog-wrapper',

        data: { measureList: this.profileMeasureList },
      })
      .afterClosed()
      .subscribe((command) => {
        if (command) {
          if (command.isCreateProfile) {
            this.selectedProfile = command;
            this.dialog
              .open(ForecastConfirmationModalComponent, {
                width: '700px',
                data: {
                  label: 'What would you like to name your profile?',
                  isOnLoad: false,
                  changeNameConfirmation: true,
                  isInputRequired: true,
                  inputLabel: 'Enter name',
                },
                disableClose: true,
              })
              .afterClosed()
              .subscribe((command) => {
                if (command.isNameSave) {
                  let selectedMeasures =
                    this.selectedProfile.measureList.filter((x) => x.checked);
                  let measureIds = '';
                  selectedMeasures.forEach((element) => {
                    measureIds = measureIds + element.measureId + ',';
                  });
                  measureIds = measureIds.slice(0, -1);
                  let model = {
                    fbMeasureProfileId: 0,
                    measureIds: measureIds,
                    fbMeasureProfileName: command.name,
                    userId: this.storage.get(USER_ID),
                    applied: this.selectedProfile.isFirst,
                  };
                  this.configurationService
                    .FbForecastSaveMeasureProfile(model)
                    .subscribe((resp) => {
                      let selectedMeasures =
                        this.selectedProfile.measureList.filter(
                          (x) => x.checked
                        );
                      this.measureList = selectedMeasures.sort(
                        (a, b) => a.sort - b.sort
                      );
                      const parentMeasures = this.masterMeasureList.filter(
                        (row) => !row.parentId
                      );
                      this.leftGroupList = parentMeasures.map((row) => ({
                        ...row,
                        rowspan: this.measureList.filter(
                          (measure) =>
                            measure.parentId == row.measureId && measure.checked
                        ).length,
                      }));
                      this.measureList = this.measureList
                        .sort((a, b) => a.sort - b.sort)
                        .filter((row) => row.parentId)
                        .map((row) => ({ ...row, checked: true }))
                        .sort((a, b) => a.sort - b.sort);
                      this.filterLeftGroups();
                      this.cdr.detectChanges();
                    });
                }
              });
          }
          if (command.profileApplied) {
            this.selectedProfile = command;
            let selectedMeasures = this.selectedProfile.measureList.filter(
              (x) => x.checked
            );
            if (selectedMeasures.length)
              this.measureList = selectedMeasures.sort(
                (a, b) => a.sort - b.sort
              );
            else this.measureList = this.savedMeasures;
            const parentMeasures = this.masterMeasureList.filter(
              (row) => !row.parentId
            );
            this.leftGroupList = parentMeasures.map((row) => ({
              ...row,
              rowspan: this.measureList.filter(
                (measure) =>
                  measure.parentId == row.measureId && measure.checked
              ).length,
            }));
            this.measureList = this.measureList
              .sort((a, b) => a.sort - b.sort)
              .filter((row) => row.parentId)
              .map((row) => ({ ...row, checked: true }))
              .sort((a, b) => a.sort - b.sort);
            this.filterLeftGroups();
            this.cdr.detectChanges();
          }
          if (command.maxCountReached) {
            this.dialog.open(ForecastConfirmationModalComponent, {
              width: '700px',
              panelClass: 'fb-dialog-wrapper',
              data: {
                label:
                  'Max profile creation limit has been reached. You can create only 20 profiles',
                isOnLoad: false,
                confirmation: true,
              },
              disableClose: true,
            });
          }
        }
      });
  }
  public createUIList = (flag: boolean, selected?: string, currentYear?) => {
    const yearValues = this.gridData.map((item) => item.title);
    const list = [];
    let treeList = CreateTimeframeUIList(this.yearListGrid);
    treeList.forEach((year) => {
      if (this.gridData.length == 0) {
        year.isSelected = true;
        year.isYear = true;
        year.yearValue = year.title;
        this.collapseYear(year);
      } else {
        year.isYear = true;
        year.yearValue = year.title;
        year.isSelected = yearValues.includes(year.title) ? true : false;
        if (year.isSelected) {
          this.collapseYear(year);
        }
      }
      list.push(year);
      year.halfList.forEach((half) => {
        half.isHalf = true;
        half.yearValue = year.title;
        half.isSelected = yearValues.includes(half.title) ? true: false;
        if (half.isSelected) {
          this.collapseHalf(half);
        }
        list.push(half);
        half.quarterList.forEach((quarter) => {
          quarter.half = half.title;
          quarter.yearValue = year.title;
          quarter.isQuarter = true;
          quarter.isSelected = yearValues.includes(quarter.title) ? true: false;
          if (quarter.isSelected) {
            this.collapseQuarter(quarter);
          }
          list.push(quarter);
          quarter.monthList.forEach((month) => {
            month.quarter = quarter.title;
            month.yearValue = year.title;
            month.isSelected = yearValues.includes(month.title) ? true: false;
            month.isMonth = true;
            if (month.isSelected) {
              this.collapseMonth(month);
            }
            list.push(month);
            month.weekList.forEach((week) => {
              week.weekCount = week.weekCount > 0 ? week.weekCount : 0;
              week.weekCount += month.weekList.length;
              week.month = month.title;
              week.yearValue = year.title;
              week.isWeek = true;
              week.isSelected = yearValues.includes(week.title)
                  ? true
                  : false;
              list.push(week);
            });
          });
        });
      });
    });
    this.oldList = this.gridListUI;
    this.gridListUI = list;
    this.spinner.hide();
    if (document.getElementById('forcast-grid')) {
      this.cdr.detectChanges();
      document.getElementById('forcast-grid').click();
    }
    this.configurationService.tempForecastList = this.gridListUI;
  };
  openFullScreen = (id: string) => {
    const domGrid = document.getElementById(id) as any;
    if (domGrid) {
      domGrid.webkitRequestFullscreen();
    }
  };
  export() {
    let exportListDisti = [];
    let exportListAccount = [];
    let exportListSubTotal = [];
    let exportListDistiAccTotal = [];
    const currentUIList = JSON.parse(JSON.stringify(this.gridData));
    const currentItem = this.itemsList.find(
      (row) => row.itemManagerId == +this.invItemIds.toString()
    );
    this.DistiMeasures.forEach((measure) => {
      if (currentItem) {
        const exportObject = {
          measureName: measure.measureName,
          measureCode: measure.code,
          measureId: measure.measureId,
          itemName: currentItem.sku,
          description: currentItem.description,
          channelId: '',
          account: '',
          accountName: '',
          value: 0,
          textValue: '',
          itemId: currentItem.itemManagerId,
          type: 'Distributor',
          distributorName: this.selectedDistibutor.distributorName,
          salesforceId: this.selectedDistibutor.distributoR_SFID,
        };
        exportListDisti.push(exportObject);
      }
    });
    this.selectedAccounts.forEach((acc) => {
      this.AccountMeasures.forEach((measure) => {
        if (currentItem) {
          const exportObject = {
            measureName: measure.measureName,
            measureCode: measure.code,
            measureId: measure.measureId,
            itemName: currentItem.sku,
            description: currentItem.description,
            account: acc.salesforceId,
            channelId: acc.itemAccountId,
            accountName: acc.accountName,
            value: 0,
            textValue: '',
            itemId: currentItem.itemManagerId,
            type: 'Account',
            distributorName: this.selectedDistibutor.distributorName,
            salesforceId: acc.salesforceId,
          };
          exportListAccount.push(exportObject);
        }
      });
    });
    this.AccountTotalMeasures.forEach((measure) => {
      if (currentItem) {
        const exportObject = {
          measureName: measure.measureName,
          measureCode: measure.code,
          measureId: measure.measureId,
          itemName: currentItem.sku,
          description: currentItem.description,
          channelId: '',
          account: '',
          accountName: '',
          value: 0,
          textValue: '',
          itemId: currentItem.itemManagerId,
          type: 'Distributor',
          distributorName: this.selectedDistibutor.distributorName,
          salesforceId: this.selectedDistibutor.distributoR_SFID,
        };
        exportListSubTotal.push(exportObject);
      }
    });
    this.DistiAccountTotalMeasures.forEach((measure) => {
      if (currentItem) {
        const exportObject = {
          measureName: measure.measureName,
          measureCode: measure.code,
          measureId: measure.measureId,
          itemName: currentItem.sku,
          description: currentItem.description,
          channelId: '',
          account: '',
          accountName: '',
          value: 0,
          textValue: '',
          itemId: currentItem.itemManagerId,
          type: 'Distributor',
          distributorName: this.selectedDistibutor.distributorName,
          salesforceId: this.selectedDistibutor.distributoR_SFID,
        };
        exportListDistiAccTotal.push(exportObject);
      }
    });
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('RETAIL_INDIRECT');

    const columns = [
      {
        header: 'MeasureName',
        key: 'measureName',
        width: 50,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'SKU',
        key: 'itemName',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'Description',
        key: 'description',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'Disti/Account',
        key: 'type',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'Distributor',
        key: 'distributorName',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'AccountName',
        key: 'accountName',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'SFID',
        key: 'salesforceId',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
    ];
    this.gridData.forEach((el) => {
      const calendar = {
        header: el.title,
        key: el.title,
        width: 20,
        style: {
          font: { bold: true },
          numFmt: '#,##',
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
        },
      };
      columns.push(calendar as any);
    });
    let commulativeList = [];
    exportListDisti.forEach((el) => {
      currentUIList.forEach((week) => {
        if (el.measureCode.indexOf('Comment') < 0) {
          if (el.measureCode.indexOf('Pct') > -1) {
            if (week['riDistiMeasure'][el.measureCode] !== 'Multiple %') {
              week['riDistiMeasure'][el.measureCode] = +week['riDistiMeasure'][el.measureCode].toString().replaceAll(',', '').replaceAll('%', '');
              el[week.title] = week['riDistiMeasure'][el.measureCode] + '%';
            } else {
              el[week.title] = week['riDistiMeasure'][el.measureCode];
            }
          } else {
            el[week.title] = Math.round(Number(week['riDistiMeasure'][el.measureCode].toString().replaceAll(',', ''))
            );
          }
        } else if (el.measureCode.indexOf('Comment') > 0) {
          el[week.title] = week['riDistiMeasure'][el.measureCode];
        }
      });
    });
    exportListSubTotal.forEach((el) => {
      currentUIList.forEach((week) => {
        if (el.measureCode.indexOf('Comment') < 0) {
          if (el.measureCode.indexOf('Pct') > -1) {
            if (week['riSubTotalMeasures'][el.measureCode] !== 'Multiple %') {
              week['riSubTotalMeasures'][el.measureCode] = +week['riSubTotalMeasures'][el.measureCode].toString().replaceAll(',', '').replaceAll('%', '');
              el[week.title] = week['riSubTotalMeasures'][el.measureCode] + '%';
            } else {
              el[week.title] = week['riSubTotalMeasures'][el.measureCode];
            }
          } else {
            el[week.title] = Math.round(Number(week['riSubTotalMeasures'][el.measureCode].toString().replaceAll(',', ''))
            );
          }
        } else if (el.measureCode.indexOf('Comment') > 0) {
          el[week.title] = week['riSubTotalMeasures'][el.measureCode];
        }
      });
    });
    exportListDistiAccTotal.forEach((el) => {
      currentUIList.forEach((week) => {
        if (el.measureCode.indexOf('Comment') < 0) {
          if (el.measureCode.indexOf('Pct') > -1) {
            if (week['riTotalMeasures'][el.measureCode] !== 'Multiple %') {
              week['riTotalMeasures'][el.measureCode] = +week['riTotalMeasures'][el.measureCode].toString().replaceAll(',', '').replaceAll('%', '');
              el[week.title] = week['riTotalMeasures'][el.measureCode] + '%';
            } else {
              el[week.title] = week['riTotalMeasures'][el.measureCode];
            }
          } else {
            el[week.title] = Math.round(Number(week['riTotalMeasures'][el.measureCode].toString().replaceAll(',', ''))
            );
          }
        } else if (el.measureCode.indexOf('Comment') > 0) {
          el[week.title] = week['riTotalMeasures'][el.measureCode];
        }
      });
    });
    exportListAccount.forEach((el) => {
      currentUIList.forEach((week) => {
        const curr = week.riAccountMeasures.find(
          (acc) => acc.channelId === el.channelId
        );
        if (el.measureCode.indexOf('Comment') < 0) {
          if (el.measureCode.indexOf('Pct') > -1) {
            if (curr['measures'][el.measureCode] !== 'Multiple %') {
              curr['measures'][el.measureCode] = +curr['measures'][el.measureCode].toString().replaceAll(',', '').replaceAll('%', '');
              el[week.title] = curr['measures'][el.measureCode] + '%';
            } else {
              el[week.title] = curr['measures'][el.measureCode];
            }
          } else {
            el[week.title] = Math.round(Number(curr['measures'][el.measureCode].toString().replaceAll(',', ''))
            );
          }
        } else if (el.measureCode.indexOf('Comment') > 0) {
          el[week.title] = curr['measures'][el.measureCode];
        }
      });
    });
    commulativeList = [
      ...exportListDisti,
      ...exportListAccount,
      ...exportListSubTotal,
      ...exportListDistiAccTotal,
    ];
    worksheet.columns = columns as any;
    commulativeList.forEach((e) => {
      worksheet.addRow(e, 'n');
    });
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, `Forecast${new Date().toISOString()}.xlsx`);
    });
  }
  searchTree() {
    const list = this.gridListUI.filter((row) => row.isSelected);
    return list;
  }

  getWidth = () => {
    return window.screen.width - 550 + 'px';
  };
  checkIfInList = (key: string) => {
    return this.measureList.find((el) => el.code === key && el.checked);
  };
  checkIfToShow = (index, list) => {
    if (index > -1 && index + 1 < list.length) {
      return (
        list[index].parentId !== list[index + 1].parentId &&
        list.find((el) => el.checked && list[index].parentId === el.parentId)
      );
    }
  };
  checkIfToShowColumn(value) {
    return (
      true ||
      (this.measureList &&
        this.measureList.find((el) => el.tag === value && el.checked))
    );
  }
  headerValueChanged = (dropdownValue, week) => {
    console.log('week => ', week);
    console.log('Value => ', dropdownValue);
  };
  displayFn(week): string {
    return week && week.title ? week.title : '';
  }
  getCurrentWeekList = () => {
    return;
  };
  trackyFn(item) {
    return item.calendarId || item.title;
  }
  public mapCummulatedObject = (element, key, week, keyName, weekList) => {
    element[keyName][key] =
      key.indexOf('Comment') > -1 || this.spreadEqualMeasures.includes(key)
        ? element[keyName][key]
        : +element[keyName][key]?.toString().replaceAll(',', '');
    if (week.isFirst) {
      element['riSubTotalMeasures']['FBOpeningOnHand'] =
        week['riSubTotalMeasures']['FBOpeningOnHand'];
      element['riTotalMeasures']['FBOpeningOnHand'] =
        week['riTotalMeasures']['FBOpeningOnHand'];
    }
    if (this.lowHeirarichyMeasures.includes(key)) {
      element[keyName][key] = week[keyName][key];
    } else if (this.spreadEqualMeasures.includes(key)) {
      if (key.indexOf('Pct') > 0) {
        element[keyName][key] = week[keyName][key];
      } else {
        element[keyName][key] =
          element[keyName][key] > week[keyName][key]
            ? element[keyName][key]
            : week[keyName][key];
      }
    } else if (key.indexOf('Comment') < 0 && key !== 'FBOpeningOnHand' && key.indexOf('Pct') < 0) {
      element[keyName][key] += +(week[keyName][key] || 0)
        .toString()
        .replaceAll(',', '');
      element.measures[key] = Math.round(Number(element.measures[key]));
    } else if (key.indexOf('Comment') > 0) {
      element[keyName][key] = week[keyName][key];
    }
    if (
      key === 'AccountEndingonHandInventory' ||
      key === 'DistiEndingonHandInventory'
    ) {
      if (new Date() >= new Date(week.title)) {
        element[keyName][key] = week[keyName][key];
      }
    }
  };
  valueChange = (value, column, fieldCode, parentKey) => {
    column.calendarIds = this.getCalendarIds(column);
    let forecast = new FbForecast(null);
    forecast.itemIds = this.dropdownObjects.itemIds.toString();
    forecast.channelIds = this.dropdownObjects.channelIds.toString();
    forecast.calendarIds = this.dropdownObjects.calendarIds.toString();
    forecast.userId = this.storage.get(USER_ID);
    forecast.versionTypeId = this.versionTypeId;
    forecast.fbTemplateId = FB_Template.Retail_InDirect;
    forecast.forecastMethodId = 38;
    forecast.seasonalitySourceLevelId = 8;
    forecast.movingAverageWeeksId = 20;
    forecast.userFBForecastId = this.userFBForecastId || 0;
    const measure = this.measureList.find((m) => m.code === fieldCode);
    if (
      (this.selectedItemIds && this.selectedItemIds.length < 1) ||
      this.selectedItemIds.findIndex((el) => !el) > -1
    ) {
      this.selectedItemIds = this.dropdownObjects.itemIds;
    }
    if (
      (this.selectedChannelIds && this.selectedChannelIds.length < 1) ||
      this.selectedChannelIds.findIndex((el) => !el) > -1
    ) {
      this.selectedChannelIds = this.dropdownObjects.channelIds;
    }
    let weeks = [];
    if (column.isWeek) {
      weeks = this.gridListUI.filter(
        (row) =>
          row.isWeek &&
          row.yearValue === column.yearValue &&
          row.calendarId === column.calendarId
      );
    } else if (column.isMonth) {
      weeks = this.gridListUI.filter(
        (row) =>
          row.isWeek &&
          row.month === column.title &&
          row.fy === column.yearValue
      );
    } else if (column.isQuarter) {
      weeks = this.gridListUI.filter(
        (row) =>
          row.isWeek &&
          row.quarter === column.title &&
          row.fy === column.yearValue
      );
    } else if (column.isHalf) {
      weeks = this.gridListUI.filter(
        (row) =>
          row.isWeek && row.hy === column.title && row.fy === column.yearValue
      );
    } else if (column.isYear) {
      weeks = this.gridListUI.filter(
        (row) => row.isWeek && row.fy === column.yearValue
      );
    }
    let updatedValue = value;
    if (fieldCode.indexOf('Comment') < 0) {
      updatedValue = +updatedValue.toString().replaceAll(',', '').replaceAll('%', '')
      if (fieldCode.endsWith('Pct')) {
        if (updatedValue < -100) {
          updatedValue = -100;
          column[parentKey][fieldCode] = -100+'%';
        }
      }
    }
    let forecastObject = {
      measureId: measure.measureId,
      calendarIds: weeks.map((el) => el.calendarId).toString(),
      itemIds: this.invItemIds.toString(),
      channelIds: (column.channelId || 0).toString(),
      value: fieldCode.indexOf('Comment') < 0 ? updatedValue : 0,
      textValue: fieldCode.indexOf('Comment') > -1 ? updatedValue : '',
      versionTypeId: this.versionTypeId,
      fbTemplateId: FB_Template.Retail_InDirect,
      userId: this.storage.get(USER_ID),
      userFBForecastId: this.userFBForecastId || 0,
    };
    forecast.fbweek = forecastObject;
    const apiMethodName =
      parentKey === 'riDistiMeasure' ? 'FbForecastSpreadingAddDisti': 'FbForecastSpreadingAdd';
    if (parentKey !== 'measures') {
      forecast.distiId = this.selectedDistibutor.distributorId;
    }
    this.configurationService[apiMethodName](forecast).subscribe((res) => {
      if (res) {
        if (!this.isRestoreSession && !this.isOpenSaved) {
          this.sessionUpdate = true;
        }
        if (parentKey === 'measures') {
          const index = this.fbForecast.weeks.findIndex(
            (row) => row.calendarId === column.calendarId
          );
          if (index > -1) {
            const accountIndex = this.fbForecast.weeks[
              index
            ].riAccountMeasures.findIndex(
              (el) => el.channelId === column.channelId
            );
            if (accountIndex > -1)
              this.fbForecast.weeks[index].riAccountMeasures[accountIndex][
                parentKey
              ][fieldCode] = updatedValue;
          }
        } else {
          const index = this.fbForecast.weeks.findIndex(
            (row) => row.calendarId === column.calendarId
          );
          if (index > -1) {
            this.fbForecast.weeks[index][parentKey][fieldCode] = updatedValue;
          }
        }

        setTimeout(() => {
          this.cdr.detectChanges();
          document.getElementById('forcast-grid').click();
        }, 1);
        this.configurationService.yearListUpdated.next(this.gridListUI);
      }
    });
  };
  public calculateForecastMeasures(fieldCode, column, parentKey) {
    if (
      fieldCode.indexOf('Pct') > -1 &&
      column[parentKey][fieldCode] !== 'Multiple %'
    ) {
      column[parentKey][fieldCode] = column[parentKey][fieldCode]
        .toString()
        .replaceAll('%', '');
    }
    if (
      fieldCode.indexOf('Pct') > -1 &&
      column[parentKey][fieldCode] !== 'Multiple %'
    ) {
      column[parentKey][fieldCode] = column[parentKey][fieldCode] + '%';
    }
    this.cdr.detectChanges();
  }
  public updateWeekValues = (keyCode, value, column, res, parentKey) => {
    if (column.isWeek) {
      const changedWeek = res.find(
        (row) => row.calendarId === column.calendarId
      );
      if (keyCode.indexOf('Comment') < 0) {
        column[parentKey][keyCode] = this.addCommas(
          changedWeek[parentKey],
          keyCode
        );
      } else {
        column[parentKey][keyCode] = changedWeek[parentKey][keyCode];
      }
      if (
        keyCode === 'GDPBaselineFcst' ||
        keyCode == 'GDPPromoUnits' ||
        keyCode == 'GDPManualFcstAdjustment' ||
        keyCode == 'GDPManualFcstAdjustmentPct'
      ) {
        this.calculateForecastMeasures(keyCode, column, parentKey);
      }
    }
  };
  collapseYear = (year: PhysicalYear) => {
    this.loading = true;
    year.halfList.forEach((half) => {
      half.quarterList.forEach((quarter) => {
        quarter.monthList.forEach((month) => {
          this.measureKeys.forEach((subKey) => {
            month.riAccountMeasures = [];
            quarter.riAccountMeasures = [];
            half.riAccountMeasures = [];
            year.riAccountMeasures = [];
            const keys = [
              'riDistiMeasure',
              'riSubTotalMeasures',
              'riTotalMeasures',
              'measures',
            ];
            keys.forEach((key) => {
              if (subKey.indexOf('Comment') < 0) {
                year[key][subKey] = 0;
                month[key][subKey] = 0;
                quarter[key][subKey] = 0;
                half[key][subKey] = 0;
              } else {
                year[key][subKey] = '';
                month[key][subKey] = '';
                quarter[key][subKey] = '';
                half[key][subKey] = '';
              }
            });
          });
        });
      });
    });
    const accounts = [];
    year.riAccountMeasures = [];
    year.halfList.forEach((half) => {
      half.quarterList.forEach((quarter) => {
        quarter.monthList.forEach((month) => {
          month.weekAccounts = [];
          month.weekList.forEach((week) => {
            this.DistiMeasures.forEach((key) => {
              if (key.code.indexOf('Pct') > -1) {
                this.calculateForecastMeasures(key.code, week, 'riDistiMeasure');
              }
              this.mapCummulatedObject(
                year,
                key.code,
                week,
                'riDistiMeasure',
                month.weekList
              );
            });
            this.AccountTotalMeasures.forEach((key) => {
              if (key.code.indexOf('Pct') > -1) {
                this.calculateForecastMeasures(key.code, week, 'riSubTotalMeasures');
              }
              this.mapCummulatedObject(
                year,
                key.code,
                week,
                'riSubTotalMeasures',
                month.weekList
              );
            });
            this.DistiAccountTotalMeasures.forEach((key) => {
              if (key.code.indexOf('Pct') > -1) {
                this.calculateForecastMeasures(key.code, week, 'riTotalMeasures');
              }
              this.mapCummulatedObject(
                year,
                key.code,
                week,
                'riTotalMeasures',
                month.weekList
              );
            });
            accounts.push(
              ...JSON.parse(JSON.stringify(week.riAccountMeasures))
            );
          });
        });
      });
    });
    this.mapAccounts(accounts, year);
    year.halfList.forEach((half) => {
      half.quarterList.forEach((quarter) => {
        quarter.monthList.forEach((month) => {
          this.mapMultipleTextObjYear(year, half, quarter, month);
        });
      });
    });
    return year;
  };
  public mapMultipleTextObjYear(year, half, quarter, month) {
    this.measureKeys.forEach((key) => {
      const keys = ['riDistiMeasure', 'riSubTotalMeasures', 'riTotalMeasures'];
      keys.forEach((parentKey) => {
        if (key.indexOf('Pct') > 0) {
          year[parentKey][key] = year.halfList.every(
            (row) => row[parentKey][key] == year[parentKey][key].toString().replaceAll('%', '')
          )
            ? year[parentKey][key]
            : 'Multiple %';
          // half[parentKey][key] = half.quarterList.every(
          //   (row) => row[parentKey][key] == half[parentKey][key]
          // )
          //   ? half[parentKey][key]
          //   : 'Multiple %';
          // quarter[parentKey][key] = quarter.monthList.every(
          //   (row) => row[parentKey][key] == quarter[parentKey][key]
          // )
          //   ? quarter[parentKey][key]
          //   : 'Multiple %';
          // month[parentKey][key] = month.weekList.every(
          //   (row) => row[parentKey][key] == month[parentKey][key]
          // )
          //   ? month[parentKey][key]
          //   : 'Multiple %';
        }
        if (key.indexOf('Comment') > 0) {
          year[parentKey][key] = year.halfList.every(
            (row) => row[parentKey][key] === year[parentKey][key]
          )
            ? year[parentKey][key]
            : 'Multiple Comments';
          half[parentKey][key] = half.quarterList.every(
            (row) => row[parentKey][key] === half[parentKey][key]
          )
            ? half[parentKey][key]
            : 'Multiple Comments';
          quarter[parentKey][key] = quarter.monthList.every(
            (row) => row[parentKey][key] === quarter[parentKey][key]
          )
            ? quarter[parentKey][key]
            : 'Multiple Comments';
          month[parentKey][key] = month.weekList.every(
            (row) => row[parentKey][key] === month[parentKey][key]
          )
            ? month[parentKey][key]
            : 'Multiple Comments';
        }
        if (
          key.indexOf('Comment') < 0 &&
          !this.spreadEqualMeasures.includes(key)
        ) {
          month[parentKey][key] = month[parentKey][key]
            ?.toString()
            .replace(/,/g, '');

          quarter[parentKey][key] = quarter[parentKey][key]
            ?.toString()
            .replace(/,/g, '');

          half[parentKey][key] = half[parentKey][key]
            ?.toString()
            .replace(/,/g, '');

          year[parentKey][key] = year[parentKey][key]
            ?.toString()
            .replace(/,/g, '');

          this.addCommas(year[parentKey], key);
          this.addCommas(half[parentKey], key);
          this.addCommas(quarter[parentKey], key);
          this.addCommas(month[parentKey], key);
          month.weekList.forEach((week) => {
            this.addCommas(week[parentKey], key);
          });
        }
      });
      if (key.indexOf('Pct') > 0 || key.indexOf('Comment') > 0) {
        this.mapIfDifferentValuesYear(year, key);
      }
      year.riAccountMeasures.forEach((acc) => {
        this.addCommas(acc['measures'], key);
      });
    });
    this.loading = false;
  }
  public mapMultipleTextObjHalf(half, quarter, month) {
    this.measureKeys.forEach((key) => {
      const keys = ['riDistiMeasure', 'riSubTotalMeasures', 'riTotalMeasures'];
      keys.forEach((parentKey) => {
        if (key.indexOf('Pct') > 0) {
          half[parentKey][key] = half.quarterList.every(
            (row) => row[parentKey][key] == half[parentKey][key].toString().replaceAll('%', '')
          )
            ? half[parentKey][key]
            : 'Multiple %';
          // quarter[parentKey][key] = quarter.monthList.every(
          //   (row) => row[parentKey][key] == quarter[parentKey][key].toString().replaceAll('%', '')
          // )
          //   ? quarter[parentKey][key]
          //   : 'Multiple %';
          // month[parentKey][key] = month.weekList.every(
          //   (row) => row[parentKey][key] == month[parentKey][key].toString().replaceAll('%', '')
          // )
          //   ? month[parentKey][key]
          //   : 'Multiple %';
        }
        if (key.indexOf('Comment') > 0) {
          half[parentKey][key] = half.quarterList.every(
            (row) => row[parentKey][key] === half[parentKey][key]
          )
            ? half[parentKey][key]
            : 'Multiple Comments';
          quarter[parentKey][key] = quarter.monthList.every(
            (row) => row[parentKey][key] === quarter[parentKey][key]
          )
            ? quarter[parentKey][key]
            : 'Multiple Comments';
          month[parentKey][key] = month.weekList.every(
            (row) => row[parentKey][key] === month[parentKey][key]
          )
            ? month[parentKey][key]
            : 'Multiple Comments';
        }
        if (
          key.indexOf('Comment') < 0 &&
          !this.spreadEqualMeasures.includes(key)
        ) {
          month[parentKey][key] = month[parentKey][key]
            ?.toString()
            .replace(/,/g, '');

          quarter[parentKey][key] = quarter[parentKey][key]
            ?.toString()
            .replace(/,/g, '');

          half[parentKey][key] = half[parentKey][key]
            ?.toString()
            .replace(/,/g, '');

          this.addCommas(half[parentKey], key);
          this.addCommas(quarter[parentKey], key);
          this.addCommas(month[parentKey], key);
          month.weekList.forEach((week) => {
            this.addCommas(week[parentKey], key);
          });
        }
      });
      if (key.indexOf('Pct') > 0 || key.indexOf('Comment') > 0) {
        this.mapIfDifferentValuesHalf(half, key);
      }
      half.riAccountMeasures.forEach((acc) => {
        this.addCommas(acc['measures'], key);
      });
    });
  }
  public mapMultipleTextObjQuarter(quarter, month) {
    this.measureKeys.forEach((key) => {
      const keys = ['riDistiMeasure', 'riSubTotalMeasures', 'riTotalMeasures'];
      keys.forEach((parentKey) => {
        if (key.indexOf('Pct') > 0) {
          quarter[parentKey][key] = quarter.monthList.every((row) => row[parentKey][key] == quarter[parentKey][key].toString().replaceAll('%', '')) ? quarter[parentKey][key] : 'Multiple %';
          // month[parentKey][key] = month.weekList.every((row) => row[parentKey][key] == month[parentKey][key].toString().replaceAll('%', '')) ? month[parentKey][key]: 'Multiple %';
        }
        if (key.indexOf('Comment') > 0) {
          quarter[parentKey][key] = quarter.monthList.every(
            (row) => row[parentKey][key] === quarter[parentKey][key]
          )
            ? quarter[parentKey][key]
            : 'Multiple Comments';
          month[parentKey][key] = month.weekList.every(
            (row) => row[parentKey][key] === month[parentKey][key]
          )
            ? month[parentKey][key]
            : 'Multiple Comments';
        }
        if (
          key.indexOf('Comment') < 0 &&
          !this.spreadEqualMeasures.includes(key)
        ) {
          month[parentKey][key] = month[parentKey][key]
            ?.toString()
            .replace(/,/g, '');
          quarter[parentKey][key] = quarter[parentKey][key]
            ?.toString()
            .replace(/,/g, '');
          this.addCommas(quarter[parentKey], key);
          this.addCommas(month[parentKey], key);
          month.weekList.forEach((week) => {
            this.addCommas(week[parentKey], key);
          });
          month.riAccountMeasures.forEach((acc) => {
            this.addCommas(acc['measures'], key);
          });
        }
      });
      if (key.indexOf('Pct') > 0 || key.indexOf('Comment') > 0) {
        this.mapIfDifferentValuesQuarter(quarter, key);
      }
      quarter.riAccountMeasures.forEach((acc) => {
        this.addCommas(acc['measures'], key);
      });
    });
  }
  public mapMultipleTextObjMonth(month) {
    this.measureKeys.forEach((key) => {
      const keys = ['riDistiMeasure', 'riSubTotalMeasures', 'riTotalMeasures'];
      keys.forEach((parentKey) => {
        if (key.indexOf('Pct') > 0) {
          month[parentKey][key] = month.weekList.every(
            (row) => row[parentKey][key] == month[parentKey][key]
          )
            ? month[parentKey][key]
            : 'Multiple %';
        }
        if (key.indexOf('Comment') > 0) {
          month[parentKey][key] = month.weekList.every(
            (row) => row[parentKey][key] === month[parentKey][key]
          )
            ? month[parentKey][key]
            : 'Multiple Comments';
        }
        if (
          key.indexOf('Comment') < 0 &&
          !this.spreadEqualMeasures.includes(key)
        ) {
          month[parentKey][key] = month[parentKey][key]
            ?.toString()
            .replace(/,/g, '');
          this.addCommas(month[parentKey], key);
          month.weekList.forEach((week) => {
            this.addCommas(week[parentKey], key);
          });
        }
      });
      this.mapIfDifferentValuesMonth(month, key);
    });
  }
  mapIfDifferentValuesMonth(element, key) {
    const accountValues = [];
    element.riAccountMeasures.forEach((acc) => {
      element.weekList.forEach((week) => {
        const riAccount = week.riAccountMeasures.find(
          (row) => row.channelId === acc.channelId
        );
        if (riAccount) {
          accountValues.push(riAccount);
        }
      });
      if (key.indexOf('Pct') > 0) {
        acc['measures'][key] = accountValues.every(
          (row) => row['measures'][key] === acc['measures'][key]
        )
          ? acc['measures'][key]
          : 'Multiple %';
      }
      if (key.indexOf('Comment') > 0) {
        acc['measures'][key] = accountValues.every(
          (row) => row['measures'][key] === acc['measures'][key]
        )
          ? acc['measures'][key]
          : 'Multiple Comments';
      }
      this.addCommas(acc['measures'], key);
    });
  }
  mapIfDifferentValuesQuarter(quarter, key) {
    const accountValues = [];
    quarter.riAccountMeasures.forEach((acc) => {
      quarter.monthList.forEach((month) => {
        month.weekList.forEach((week) => {
          const riAccount = week.riAccountMeasures.find(
            (row) => row.channelId === acc.channelId
          );
          if (riAccount) {
            accountValues.push(riAccount);
          }
        });
        if (key.indexOf('Pct') > 0) {
          acc['measures'][key] = accountValues.every(
            (row) => row['measures'][key] == acc['measures'][key]
          )
            ? acc['measures'][key]
            : 'Multiple %';
        }
        if (key.indexOf('Comment') > 0) {
          acc['measures'][key] = accountValues.every(
            (row) => row['measures'][key] === acc['measures'][key]
          )
            ? acc['measures'][key]
            : 'Multiple Comments';
        }
        this.addCommas(acc['measures'], key);
      });
    });
  }
  mapIfDifferentValuesHalf(half, key) {
    const accountValues = [];
    half.riAccountMeasures.forEach((acc) => {
      half.quarterList.forEach((quarter) => {
        quarter.monthList.forEach((month) => {
          month.weekList.forEach((week) => {
            const riAccount = week.riAccountMeasures.find(
              (row) => row.channelId === acc.channelId
            );
            if (riAccount) {
              accountValues.push(riAccount);
            }
          });
          if (key.indexOf('Pct') > 0) {
            acc['measures'][key] = accountValues.every(
              (row) => row['measures'][key] == acc['measures'][key]
            )
              ? acc['measures'][key]
              : 'Multiple %';
          }
          if (key.indexOf('Comment') > 0) {
            acc['measures'][key] = accountValues.every(
              (row) => row['measures'][key] == acc['measures'][key]
            )
              ? acc['measures'][key]
              : 'Multiple Comments';
          }
          this.addCommas(acc['measures'], key);
        });
      });
    });
  }
  mapIfDifferentValuesYear(year, key) {
    year.riAccountMeasures.forEach((acc) => {
      year.halfList.forEach((half) => {
        const accountValues = [];
        half.quarterList.forEach((quarter) => {
          quarter.monthList.forEach((month) => {
            month.weekList.forEach((week) => {
              const riAccount = week.riAccountMeasures.find(
                (row) => row.channelId === acc.channelId
              );
              if (riAccount) {
                accountValues.push(riAccount);
              }
            });
            if (key.indexOf('Pct') > 0) {
              acc['measures'][key] = accountValues.every(
                (row) => row['measures'][key] == acc['measures'][key]
              )
                ? acc['measures'][key]
                : 'Multiple %';
            }
            if (key.indexOf('Comment') > 0) {
              acc['measures'][key] = accountValues.every(
                (row) => row['measures'][key] == acc['measures'][key]
              )
                ? acc['measures'][key]
                : 'Multiple Comments';
            }
            this.addCommas(acc['measures'], key);
          });
        });
      });
    });
  }
  collapseHalf = (half: HalfYear): any => {
    half.quarterList.forEach((quarter) => {
      quarter.monthList.forEach((month) => {
        this.measureKeys.forEach((subKey) => {
          const keys = [
            'riDistiMeasure',
            'riSubTotalMeasures',
            'riTotalMeasures',
            'measures',
          ];
          keys.forEach((key) => {
            if (subKey.indexOf('Comment') < 0) {
              month[key][subKey] = 0;
              quarter[key][subKey] = 0;
              half[key][subKey] = 0;
            } else {
              month[key][subKey] = '';
              quarter[key][subKey] = '';
              half[key][subKey] = '';
            }
          });
        });
      });
    });
    const accounts = [];
    half.riAccountMeasures = [];
    half.quarterList.forEach((quarter) => {
      quarter.monthList.forEach((month) => {
        month.weekList.forEach((week) => {
          this.DistiMeasures.forEach((key) => {
            this.mapCummulatedObject(
              half,
              key.code,
              week,
              'riDistiMeasure',
              month.weekList
            );
          });
          this.AccountTotalMeasures.forEach((key) => {
            this.mapCummulatedObject(
              half,
              key.code,
              week,
              'riSubTotalMeasures',
              month.weekList
            );
          });
          this.DistiAccountTotalMeasures.forEach((key) => {
            this.mapCummulatedObject(
              half,
              key.code,
              week,
              'riTotalMeasures',
              month.weekList
            );
          });
          accounts.push(...JSON.parse(JSON.stringify(week.riAccountMeasures)));
        });
      });
    });
    this.mapAccounts(accounts, half);
    half.quarterList.forEach((quarter) => {
      quarter.monthList.forEach((month) => {
        this.mapMultipleTextObjHalf(half, quarter, month);
      });
    });

    return half;
  };
  collapseQuarter = (quarter: Quarter): any => {
    quarter.monthList.forEach((month) => {
      this.measureKeys.forEach((subKey) => {
        const keys = [
          'riDistiMeasure',
          'riSubTotalMeasures',
          'riTotalMeasures',
          'measures',
        ];
        keys.forEach((key) => {
          if (subKey.indexOf('Comment') < 0 && key.indexOf('Pct') < 0) {
            month[key][subKey] = 0;
            quarter[key][subKey] = 0;
          } else if (key.indexOf('Pct') > -1) {
            month[key][subKey] = '0%';
            quarter[key][subKey] = '0%';
          } else {
            month[key][subKey] = '';
            quarter[key][subKey] = '';
          }
        });
      });
    });
    const accounts = [];
    quarter.riAccountMeasures = [];
    quarter.monthList.forEach((month) => {
      month.weekList.forEach((week) => {
        this.DistiMeasures.forEach((key) => {
          this.mapCummulatedObject(
            quarter,
            key.code,
            week,
            'riDistiMeasure',
            month.weekList
          );
        });
        this.AccountTotalMeasures.forEach((key) => {
          this.mapCummulatedObject(
            quarter,
            key.code,
            week,
            'riSubTotalMeasures',
            month.weekList
          );
        });
        this.DistiAccountTotalMeasures.forEach((key) => {
          this.mapCummulatedObject(
            quarter,
            key.code,
            week,
            'riTotalMeasures',
            month.weekList
          );
        });
        accounts.push(...JSON.parse(JSON.stringify(week.riAccountMeasures)));
      });
    });
    this.mapAccounts(accounts, quarter);
    quarter.monthList.forEach((month) => {
      this.mapMultipleTextObjQuarter(quarter, month);
    });
    return quarter;
  };
  collapseMonth = (month: Month): any => {
    this.measureKeys.forEach((subKey) => {
      const keys = [
        'riDistiMeasure',
        'riSubTotalMeasures',
        'riTotalMeasures',
        'measures',
      ];
      keys.forEach((key) => {
        if (subKey.indexOf('Comment') < 0) {
          month[key][subKey] = 0;
        } else {
          month[key][subKey] = '';
        }
      });
    });
    const accounts = [];
    month.riAccountMeasures = [];
    month.weekList.forEach((week) => {
      this.DistiMeasures.forEach((key) => {
        this.mapCummulatedObject(
          month,
          key.code,
          week,
          'riDistiMeasure',
          month.weekList
        );
      });
      this.AccountTotalMeasures.forEach((key) => {
        this.mapCummulatedObject(
          month,
          key.code,
          week,
          'riSubTotalMeasures',
          month.weekList
        );
      });
      this.DistiAccountTotalMeasures.forEach((key) => {
        this.mapCummulatedObject(
          month,
          key.code,
          week,
          'riTotalMeasures',
          month.weekList
        );
      });
      accounts.push(...JSON.parse(JSON.stringify(week.riAccountMeasures)));
    });
    this.mapAccounts(accounts, month);
    this.mapMultipleTextObjMonth(month);
    return month;
  };
  public mapAccounts(accounts, element) {
    const accountIds = this.dropdownObjects.channelIds.toString().split(',');
    accountIds.forEach((row) => {
      let defaultIRAccount = new AccountMeasuresResponse();
      this.AccountMeasures.forEach((key) => {
        const currentAccounts = accounts.filter((el) => el.channelId === +row);
        currentAccounts.forEach((acc) => {
          defaultIRAccount.channelId = acc.channelId;
          this.mapCummulatedObject(
            defaultIRAccount,
            key.code,
            acc,
            'measures',
            []
          );
          this.addCommas(defaultIRAccount['measures'], key.code);
        });
      });
      defaultIRAccount.channelId = +row;
      element.riAccountMeasures.push(defaultIRAccount);
    });
  }
  public getCalendarIds = (column) => {
    let calendarIds = [];
    if (column.isYear) {
      calendarIds = this.calendarList
        .filter((row) => row.fy === column.title)
        .map((el) => el.calendarId);
      return calendarIds;
    } else if (column.isHalf) {
      calendarIds = this.calendarList
        .filter((row) => row.fy === column.yearValue && row.hy === column.title)
        .map((el) => el.calendarId);
      return calendarIds;
    } else if (column.isQuarter) {
      calendarIds = this.calendarList
        .filter((row) => row.fy === column.yearValue && row.hy === column.half)
        .map((el) => el.calendarId);
      return calendarIds;
    } else if (column.isMonth) {
      calendarIds = this.calendarList
        .filter(
          (row) =>
            row.fy === column.yearValue &&
            row.quarter === column.quarter &&
            row.month === column.title
        )
        .map((el) => el.calendarId);
      return calendarIds;
    } else {
      return [column.calendarId];
    }
  };
  showValues = (selected, year, select) => {
    this.collapsedObject = {
      selected: selected,
      year: year,
    };
    if (selected) {
      this.setupLongRunningProcess({
        year: year,
        gridListUI: this.gridListUI,
        selected: selected.value,
      });
    }
  };
  mapGridList(value) {
    this.onClickEvent.emit(value);
  }
  public filterLeftGroups = () => {
    this.leftGroupList = this.leftGroupList.filter((el) => el.rowspan > 0);
  };
  public selectionChanged = (selectedItem) => {
    if (selectedItem.isChannel) {
      this.selectedChannelIds = [selectedItem.value];
    }
    if (selectedItem.isProduct) {
      this.selectedItemIds = [selectedItem.value];
      this.selectedProduct = selectedItem.value;
    }
    const updatedValues = {
      itemIds:
        this.selectedItemIds.length > 0 && this.selectedItemIds.find((el) => el)
          ? this.selectedItemIds
          : this.dropdownObjects.itemIds,
      channelIds:
        this.selectedChannelIds.length > 0 &&
        this.selectedChannelIds.find((el) => el)
          ? this.selectedChannelIds
          : this.dropdownObjects.channelIds,
      calendarIds: this.dropdownObjects.calendarIds,
      isSessionUpdate: true,
    };
    this.updateGridList.emit(updatedValues);
    // this.updateUIList(updatedValues);
  };
  getLeafNodes(nodes, result = []) {
    for (var i = 0, length = nodes.length; i < length; i++) {
      if (
        !nodes[i].internalChildren ||
        nodes[i].internalChildren.length === 0
      ) {
        result.push(nodes[i]);
      } else {
        result = this.getLeafNodes(nodes[i].internalChildren, result);
      }
    }
    return result;
  }
  onKeyDown = (event: KeyboardEvent, measureCode: string, week) => {
    return onKeyDown(event, measureCode, week);
  };
  addCommas = (week, field) => {
    return addCommas(week, field);
  };
  openExportDialog() {
    const dropdownObjects = {
      itemIds:
        this.selectedItemIds.length > 0 && this.selectedItemIds.find((el) => el)
          ? this.selectedItemIds
          : this.dropdownObjects.itemIds,
      channelIds:
        this.selectedChannelIds.length > 0 &&
        this.selectedChannelIds.find((el) => el)
          ? this.selectedChannelIds
          : this.dropdownObjects.channelIds,
      calendarIds: this.dropdownObjects.calendarIds,
    };
    this.dialog
      .open(ExportAllTemplateComponent, {
        width: '1200px',
        panelClass: 'fb-dialog-wrapper',
        data: {
          measureList: this.measureList,
          itemsList: this.itemsList,
          accountList: this.accountList,
          forecastList: this.forecastList,
          calendarList: this.calendarList,
          gridListUI: this.gridListUI,
          dropdownObjects: dropdownObjects,
          userFBForecastId: this.userFBForecastId || 0,
          versionTypeId: this.versionTypeId,
          selectedDistibutor: this.selectedDistibutor,
          fbTemplateId: FB_Template.Retail_InDirect,
          weeks: this.fbForecast.weeks,
          invItemIds: this.invItemIds,
        },
      })
      .afterClosed()
      .subscribe((command) => {
        if (command && command.selectedMeasure) {
        }
      });
  }
  setupLongRunningProcess(evt) {
    this.spinner.show();
    const worker = new InlineWorker(() => {
      // START OF WORKER THREAD CODE\
      function getUIList(event) {

        if (event.selected && event.selected == 'Year') {
          event.gridListUI.forEach((row) => {
              row.isSelected = row.isYear ? true : false;
          });
        } else if (event.selected && event.selected == 'Half Year') {
          event.gridListUI.forEach((row) => {
              row.isSelected = row.isHalf ? true : false;
          });
        }
        if (event.selected && event.selected == 'Quarter') {
          event.gridListUI.forEach((row) => {
            row.isSelected = row.isQuarter ? true : false;
          });
        }
        if (event.selected && event.selected == 'Monthly') {
          event.gridListUI.forEach((row) => {
              row.isSelected = row.isMonth ? true : false;
          });
        } else if (event.selected && event.selected == 'Weekly') {
          event.gridListUI.forEach((row) => {
            row.isSelected = row.isWeek ? true : false;
        });
        }
        return event.gridListUI;
      }
      const CreateUIList = (event) => {
        // this is from DedicatedWorkerGlobalScope ( because of that we have postMessage and onmessage methods )
        // and it can't see methods of this class
        // @ts-ignore

        this.postMessage({
          gridListUI: getUIList(event),
          selected: event.selected,
          year: event.year,
        });
      };

      // @ts-ignore

      this.onmessage = (evt) => {
        CreateUIList(evt.data);
      };
      // END OF WORKER THREAD CODE
    });

    worker.postMessage(evt);

    worker.onmessage().subscribe((data) => {
      this.gridListUI = data.data.gridListUI;
      if (data.data.selected !== 'Weekly') {
        this.gridData.forEach((row) => {
            if (row.isYear) {
              this.collapseYear(row);
            } else if (row.isHalf) {
              this.collapseHalf(row);
            } else if (row.isQuarter) {
              this.collapseQuarter(row);
            } else if (row.isMonth) {
              this.collapseMonth(row);
            }
        });
      }
      this.oldList = this.gridListUI;
      this.spinner.hide();
      worker.terminate();
      this.cdr.detectChanges();
    });
    worker.onerror().subscribe((data) => {
      console.log(data);
    });
  }
  setFocus = (index, field, measureIndex) => {
    this.selectedList.forEach((row) => {
      row.field.style.background = 'rgb(238, 238, 238)';
    });
    this.selectedList = [];
    this.selectedOperationList = [];
    setFocusOnNextInput(
      index,
      field,
      this.gridData,
      this.measureList,
      measureIndex
    );
  };
  setFocusAccount = (index, field, measureIndex, accIndex) => {
    this.selectedList.forEach((row) => {
      row.field.style.background = 'rgb(238, 238, 238)';
    });
    this.selectedList = [];
    this.selectedOperationList = [];
    if (index < this.gridData.length - 1) {
      const dynamicId = `${index + 1}${field}${measureIndex}${accIndex}`;
      document.getElementById(dynamicId).focus();
    } else {
      const measure = this.measureList[measureIndex + 1];
      document.getElementById(`0${measure.code}${accIndex}`).focus();
    }
  };
  onPaste(event, week, measureCode, parentKey) {
    let clipboardData = event.clipboardData || (window as any).clipboardData;
    event = event || window.event; // Event object 'ev'
    var key = event.which || event.keyCode; // Detecting keyCode

    // Detecting Ctrl
    var ctrl = event.ctrlKey
      ? event.ctrlKey
      : key == 17 || key == 91
      ? true
      : false;
    ctrl = ctrl || event.metaKey;

    // If key pressed is V and if ctrl is true.
    if (key == 86 && ctrl) {
      navigator.clipboard
        .readText()
        .then((pastedText: string) => {
          pastedText = (pastedText as any).replaceAll('\r', '');
          pastedText = (pastedText as any).replaceAll('\n', '');
          if (this.selectedList.length > 1) {
            this.selectedList.forEach((row) => {
              row.field.style.background = 'rgb(238, 238, 238)';
              this.valueChange(pastedText, row.week, measureCode, parentKey);
            });
            this.selectedList = [];
          } else if (pastedText && pastedText.split('\t').length > 0) {
            let uiListIndex = -1;
            if (week.week) {
              uiListIndex = this.gridData.findIndex(
                (row) => row.title == week.week
              );
            } else {
              uiListIndex = this.gridData.findIndex(
                (row) => row.title == week.title
              );
            }

            if (pastedText) {
              const values = pastedText.trim().split('\t');
              // Check if it is Account Week
              if (week.week && week.channelId) {
                for (let index = 0; index < values.length; index++) {
                  if (this.gridData[uiListIndex + index]) {
                    const accountIndex = this.gridData[uiListIndex + index][
                      'riAccountMeasures'
                    ].findIndex((row) => row.channelId === week.channelId);
                    if (accountIndex > -1) {
                      this.gridData[uiListIndex + index]['riAccountMeasures'][
                        accountIndex
                      ][parentKey][measureCode] = values[index];
                      this.valueChange(
                        this.gridData[uiListIndex + index]['riAccountMeasures'][
                          accountIndex
                        ][parentKey][measureCode],
                        this.gridData[uiListIndex + index]['riAccountMeasures'][
                          accountIndex
                        ],
                        measureCode,
                        parentKey
                      );
                    }
                  }
                }
              } else {
                for (let index = 0; index < values.length; index++) {
                  if (this.gridData[uiListIndex + index]) {
                    this.gridData[uiListIndex + index][parentKey][measureCode] =
                      values[index];
                    this.valueChange(
                      this.gridData[uiListIndex + index][parentKey][
                        measureCode
                      ],
                      this.gridData[uiListIndex + index],
                      measureCode,
                      parentKey
                    );
                  }
                }
              }
            }
          }
        })
        .catch((err) => {
          console.error('Failed to read clipboard contents: ', err);
        });
    } else if (key == 67 && ctrl) {
      if (this.selectedList.length > 0) {
        let value = '';
        this.selectedList.forEach((row) => {
          value += row.week[parentKey][row.measure] + '\t';
          row.field.style.background = 'rgb(238, 238, 238)';
        });
        navigator.clipboard.writeText(value);
        this.selectedList = [];
        this.toastr.success('Success', 'Values copied!');
      } else {
        navigator.clipboard.writeText(week[parentKey][measureCode]);
      }
    } else if ((key == 46 || key == 8) && this.selectedList.length > 0) {
      this.selectedList.forEach((row) => {
        row.field.style.background = 'rgb(238, 238, 238)';
        if (row.measure.indexOf('Comment') > 0) {
          row.week[parentKey][measureCode] = '';
        } else {
          row.week[parentKey][measureCode] = 0;
        }
        this.valueChange(
          row.week[parentKey][measureCode],
          row.week,
          measureCode,
          parentKey
        );
        this.selectedList = [];
      });
    }
    return true;
  }
  markYearsSelected(evt) {
    this.spinner.show();
    const worker = new InlineWorker(() => {
      // START OF WORKER THREAD CODE\
      function getUIList(event) {
        event.gridListUI.forEach((el) => {
          if (
            event.oldList.find(
              (row) => row.title === el.title && row.isSelected
            )
          ) {
            el.isSelected = true;
          }
        });
        if (!event.gridListUI.find((row) => row.isSelected)) {
          event.gridListUI = event.gridListUI.map((el) => {
            return {
              ...el,
              isSelected: el.isYear ? true : false,
            };
          });
        }
        return event.gridListUI;
      }
      const CreateUIList = (event) => {
        // this is from DedicatedWorkerGlobalScope ( because of that we have postMessage and onmessage methods )
        // and it can't see methods of this class
        // @ts-ignore

        this.postMessage({
          gridListUI: getUIList(event),
        });
      };

      // @ts-ignore

      this.onmessage = (evt) => {
        CreateUIList(evt.data);
      };
      // END OF WORKER THREAD CODE
    });

    worker.postMessage(evt);

    worker.onmessage().subscribe((data) => {
      this.gridListUI = data.data.gridListUI;
      this.spinner.hide();
      worker.terminate();
      this.cdr.detectChanges();
    });
    worker.onerror().subscribe((data) => {
      console.log(data);
    });
  }
  checkIfMousedown = (e, week, measure, field: HTMLInputElement) => {
    if (this.configurationService.isMouseDown) {
      if (this.selectedList.find((row) => row.measure === measure)) {
        const fields = {
          week,
          measure,
          field,
        };
        let currentWeekIndex = -1;
        if (week.week) {
          currentWeekIndex = this.selectedList.findIndex(
            (row) => row.week.title === week.week
          );
        } else {
          currentWeekIndex = this.selectedList.findIndex(
            (row) => row.week.title === week.title
          );
        }

        if (currentWeekIndex === -1) {
          this.selectedList.push(fields);
          field.style.background = '#87CEFA';
          field.select();
        } else if (currentWeekIndex !== -1) {
          let sumIndex = 0;
          if (this.selectedList.length > 1) {
            sumIndex = 1;
          }
          this.selectedList[
            currentWeekIndex + sumIndex
          ].field.style.background = 'rgb(238, 238, 238)';
          this.selectedList.splice(currentWeekIndex + sumIndex, 1);
        }
      } else if (this.selectedList.length === 0) {
        const fields = {
          week,
          measure,
          field,
        };
        this.selectedList.push(fields);
        field.style.background = '#87CEFA';
        field.select();
      }
    }
  };
  preventMouseDown(event, year, code, field) {
    event.preventDefault();
    this.configurationService.setMousedown(1);
    this.selectedList.forEach((row) => {
      row.field.style.background = 'rgb(238, 238, 238)';
    });
    this.selectedList = [];
    this.checkIfMousedown(event, year, code, field);
  }
  onInput = () => {
    this.selectedList.forEach((row) => {
      row.field.style.background = 'rgb(238, 238, 238)';
    });
    this.selectedList = [];
  };
  applyScrollToOther(id) {
    return true;
    const scrolledEl = document.getElementById(id);
    const distiEl = document.getElementById('distiRows');
    const accountTotalEl = document.getElementById('accountTotalRows');
    const distiAccEl = document.getElementById('distiAccountTotalRows');
    const elements = [distiEl, accountTotalEl, distiAccEl];
    this.selectedAccounts.forEach((row, index) => {
      let el = document.getElementById('accountRows' + index);
      elements.push(el);
    });
    if (distiAccEl && accountTotalEl && distiEl && scrolledEl) {
      const scrollOptions: ScrollToOptions = { left: scrolledEl.scrollLeft };
      elements.forEach((el) => {
        el.scrollTo(scrollOptions);
      });
    }
  }
}
