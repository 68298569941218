import {
  AllCommunityModules,
  IDatasource,
} from '@ag-grid-community/all-modules';
import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NameRendererComponent } from '../../../common/name-renderer';
import { ToggleButtonRendererComponent } from '../../../common/toggle-button-renderer';
import { ConfigurationService } from '../../../services/configuration.service';
import { ItemAccount } from '../item.model';
import { AccountFormComponent } from './account-form/account-form.component';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { HttpParams } from '@angular/common/http';
import { USER_INFO } from 'src/common/keys';
import { LocalstorageService } from 'src/services/localstorage.service';
import { ServerSideStoreType } from 'ag-grid-community';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
})
export class AccountComponent implements OnInit {
  public modules = AllModules;
  public showAccountForm = false;
  accountList: ItemAccount[];
  public columnDefs = [
    {
      field: '-', // would be used as a reference
      hide: true, //- hide in grid data
      lockVisible: true, // - disable visibility changing via menu
      filter: 'agTextColumnFilter', //- require for setFilterModel
      filterParams: {
        newRowsAction: 'keep',
      },
    },
    {
      headerName: 'Account Name',
      field: 'accountName',
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openAccountForm.bind(this),
      },
    },
    {
      headerName: 'Salesforce ID',
      field: 'salesforceId',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Channel',
      field: 'channelName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Region',
      field: 'regionName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Country',
      field: 'countryName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Distributor',
      field: 'distributorName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Parent Account',
      field: 'accountTypeName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Salesforce ID',
      field: 'salesforceId',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Sub-Region',
      field: 'subRegion',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Partner Type',
      field: 'partnerType',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Lead Time (Days)',
      field: 'leadtimedays',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Disti Lead Time (Days)',
      field: 'distileadtimedays',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Country Proper',
      field: 'countryProper',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Region Proper',
      field: 'regionProper',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Status',
      field: 'active',
      minWidth: 50,
      resizable: true,
      cellRenderer: (params) => {
        return params.value ? 'Active' : 'Inactive';
      },
    },
  ];
  public gridOptions: any;
  gridApi: any;
  defaultColDef: any;
  headerHeight = 45;
  public accountModel = new ItemAccount();
  rowModelType: string;
  serverSideStoreType = ServerSideStoreType.Partial;
  paginationPageSize: number;
  cacheBlockSize: number;
  viewPermission: boolean;
  editPermission: boolean;
  createPermission: any;
  public searchText = '';
  constructor(
    readonly configurationService: ConfigurationService,
    public storage: LocalstorageService
  ) {
    this.gridOptions = {
      frameworkComponents: {
        nameRenderer: NameRendererComponent,
      },
      rowSelection: 'single',
      cacheBlockSize: 100,
      maxBlocksInCache: 2,
      rowModelType: 'serverSide',
      pagination: true,
      paginationPageSize: 100,
    };

    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true,
    };
  }

  ngOnInit() {
    let userTeams = this.storage.get(USER_INFO).teams;
    userTeams.forEach((element) => {
      element.teamScreenGroup.forEach((group) => {
        if (
          group.screenGroupId == 2 &&
          group.viewPermission &&
          !this.viewPermission
        ) {
          this.viewPermission = true;
        }
        if (
          group.screenGroupId == 2 &&
          group.editPermission &&
          !this.editPermission
        ) {
          this.editPermission = true;
        }
        if (
          group.screenGroupId == 2 &&
          group.createPermission &&
          !this.createPermission
        ) {
          this.createPermission = true;
        }
      });
    });
    this.rowModelType = 'serverSide';
    this.paginationPageSize = 100;
    this.cacheBlockSize = 20;
    // this.getItemAccountList();
  }
  public getItemAccountList = () => {
    const params = {
      offset: 0,
      pageSize: 15,
      active: true,
    };
    this.configurationService.ItemAccountGetAll(params).subscribe((res) => {
      this.accountList = res.data || [];
    });
  };
  onFilterTextBoxChanged(event) {
    this.gridApi.setFilterModel({"-":{filter: this.searchText}})
  }  openAccountForm(rowModel) {
    if (rowModel && rowModel.rowData) {
      this.configurationService.accountModel = rowModel.rowData;
      this.configurationService.accountModel.editPermission =
        this.editPermission;
      this.showAccountForm = true;
    } else {
      this.configurationService.accountModel.editPermission = true;
      this.showAccountForm = true;
    }
  }
  public closePanel = () => {
    this.showAccountForm = false;
    this.configurationService.accountModel = null;
    this.configurationService.accountModel = new ItemAccount();
  };
  onGridReady(params: any) {
    console.log('onGridReady');
    this.gridApi = params.api;
    var datasource = {
      getRows: (params) => {
        console.log(
          'Getting datasource rows, start: ' +
            params.request.startRow +
            ', end: ' +
            params.request.endRow
        );
        let url = `offSet=${
          params.request.startRow ? params.request.startRow : 0
        }&pageSize=${
          params.request.endRow ? params.request.endRow : 40
        }&active=true&searchText=${this.searchText}`;
        if (params.request.sortModel.length > 0) {
          let isSortAscending =
            params.request.sortModel[0].sort == 'desc' ? false : true;
          url =
            url +
            '&sortColumn=' +
            params.request.sortModel[0].colId +
            '&SortAscending=' +
            isSortAscending;
        } else {
          let isSortAscending = false;
          url =
            url +
            '&sortColumn=' +
            'ItemAccountId' +
            '&SortAscending=' +
            isSortAscending;
        }
        this.configurationService
          .ItemAccountGetAll(url)
          .subscribe((response) => {
            params.successCallback(response.data, response.totalRecord);
          });
      },
    };
    params.api.setServerSideDatasource(datasource);
  }
  updateList() {
    this.gridApi.purgeServerSideCache();
  }
  private getRowData(startRow: number, endRow: number) {
    let params: HttpParams = new HttpParams()
      .append('startRow', `${startRow}`)
      .append('endRow', `${endRow}`);
    return this.configurationService.ItemAccountGetAll(params);
  }
}
