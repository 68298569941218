<h1 mat-dialog-title>Please select the data format(s) you want to export</h1>
<div mat-dialog-content>
  <div class="form-group">
    <mat-select
      [multiple]="true"
      appearance="outline"
      class="form-control"
      placeholder="Select Measures"
      [(ngModel)]="selectedMeasures"
    >
      <mat-option
        *ngFor="let measure of measureList; let i = index"
        [value]="measure"
      >
        {{ measure.measureName }}
      </mat-option>
    </mat-select>
  </div>
  <!-- <div class="container-fluid item-wrapper">
    <div class="row inner-data" *ngFor="let item of exportTemplates">
      <div class="col-6">
        <p>{{ item.name }}</p>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <div class="column d-flex align-items-center">
          <mat-checkbox [(ngModel)]="item.checked"> </mat-checkbox>
        </div>
      </div>
    </div>
  </div> -->
  <div
    class="action-panel text-right btn-wrapper"
    style="
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;
    "
  >
    <div style="display: flex">
      <button
        mat-raised-button
        class="btn btn-primary m-right"
        color="warn"
        (click)="dialogRef.close()"
      >
        Close
      </button>
      <button
        mat-raised-button
        class="btn btn-primary m-right"
        color="warn"
        [disabled]="!selectedMeasures || selectedMeasures.length < 1"
        (click)="checkWhichToExport()"
      >
        Export
      </button>
    </div>
  </div>
</div>
