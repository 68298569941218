<div class="container-fluid promo-manager-page">

  <div class="dialog-close-btn">
    <h1 mat-dialog-title>Pending Seasonality List</h1>
    <button mat-icon-button (click)="onNoClick()">
      <i class="material-icons">
        close
      </i>
    </button>
  </div>

  <div class="grid-action-btn-bar">
    <button style="margin-left:8px" matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="accent"
      (click)="openColumnDialog()">
      <i class="material-icons">
        ballot
      </i>
    </button>
    <div class="grid-actions inline-flex">
      <div *ngIf="selectedRows.length" class="row-info">
        <spnan><strong>{{selectedRows.length}}</strong> rows selected out of <strong>{{totalRows}}</strong>
        </spnan>
        <button mat-button color="accent" (click)="clearSelectedRows()">Clear Selection</button>
      </div>
      <div *ngIf="isFilteredApplied" class="row-info filtered-info">
        <span><strong>Filtered Rows:</strong> {{totalRows}} </span>
        <button mat-button color="accent" (click)="clearGridFilters()">Clear All Filters</button>
      </div>
    </div>

    <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary"
      (click)="onExportGridData()">
      <i class="material-icons">
        insert_drive_file
      </i>
    </button>

    <button class="add-icon-btn ml-10" (click)="openSeasonalitySetupDetail(selectedRows)" matTooltip="Add Seasonality"
      mat-icon-button color="primary" type="button">
      <i class="material-icons">
        add_circle
      </i>
    </button>

  </div>

  <div class="all-show-grid">
    <ag-grid-angular #pendingSeasonalityGrid id="pending-seasonality-data-grid"
      class="ag-theme-material pending-seasonality-manager-grid" [modules]="modules" [columnDefs]="columnDefs"
      [gridOptions]="gridOptions" [defaultColDef]="defaultColDef" [suppressRowClickSelection]="true"
      [rowSelection]="rowSelection" [animateRows]="true" [rowModelType]="rowModelType"
      [serverSideStoreType]="serverSideStoreType" [pagination]="true" [paginationPageSize]="paginationPageSize"
      [cacheBlockSize]="cacheBlockSize" (selectionChanged)="rowSelectionChanged($event)"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
    <div style="width: 0; height: 0;">
      <ag-grid-angular #bottomDataGridHidden class="ag-theme-material outlier-grid" id="outlier-data-grid-hidden"
        rowSelection="multiple" [gridOptions]="bottomDataGridOptions"
        [modules]="modules" [rowModelType]="'clientSide'" [columnDefs]="columnDefs"
         [pagination]="false" [suppressColumnVirtualisation]="true"
        [suppressMaxRenderedRowRestriction]="true" (gridReady)="onBottomHiddenGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>


</div>
