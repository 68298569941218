<!-- Main Content area -->
<div *ngIf="!hideScreen" id="fb-forecast-new-planner" class="container-fluid">
  <div class="btn-wrapper" [hidden]="!isShowTreeDropDowns">
    <div class="form-group">
      <app-treeview-dropdown
        [type]="'Channel'"
        [items]="channelDropdownList"
        (valueChange)="setValue($event)"
      >
      </app-treeview-dropdown>
    </div>
    <div class="form-group">
      <app-treeview-dropdown
        [type]="'Item'"
        [items]="productList"
        (valueChange)="setValue($event)"
      >
      </app-treeview-dropdown>
    </div>
    <div class="form-group">
      <app-treeview-dropdown
        [type]="'Timeframe'"
        [items]="calendarListDropdown"
        (valueChange)="setValue($event)"
      >
      </app-treeview-dropdown>
    </div>
    <div>
      <button
        (click)="mapGridList(false)"
        [disabled]="
          !isDeleted ||
          itemIds.length == 0 ||
          channelIds.length == 0 ||
          calendarIds.length == 0
        "
        class="btn btn-primary adjust-btn"
      >Build
      </button>
    </div>
  </div>
  <div [hidden]="!gridMapped">
    <mat-accordion class="fb-forecast-accordion">
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title> Data Manager </mat-panel-title>
        </mat-expansion-panel-header>
        <div
          class="container-fluid item-wrapper fb-forecast-planner"
          [style.height]="isAnyOpened() ? '70vh' : ''"
        >
          <div class="row method-planner-bottom">
            <div class="col-md-6 fb-line align">
              <div>
                <h3>View Data Set</h3>
                <form>
                  <div class="form-group">
                    <label>Product</label>
                    <app-treeview-dropdown
                      [type]="'Item'"
                      [isReadonly]="true"
                      [items]="restoredProductList"
                      style="z-index: 1"
                      (valueChange)="setRestoredValue($event)"
                    >
                    </app-treeview-dropdown>
                  </div>
                  <div class="form-group">
                    <label>Timeframe</label>
                    <app-treeview-dropdown
                      [type]="'Timeframe'"
                      [isReadonly]="true"
                      [items]="restoreCalendarList"
                      (valueChange)="setRestoredValue($event)"
                    >
                    </app-treeview-dropdown>
                  </div>
                  <div class="form-group">
                    <label>Channel</label>
                    <app-treeview-dropdown
                      [isReadonly]="true"
                      [type]="'Channel'"
                      [items]="restoredChannelList"
                      (valueChange)="setRestoredValue($event)"
                    >
                    </app-treeview-dropdown>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6 align">
              <div>
                <h3>Refresh Measures</h3>
                <form>
                  <div class="form-group">
                    <label>Select Measure</label>
                    <mat-select
                      placeholder=""
                      (selectionChange)="onSelectionChanged($event)"
                      [multiple]="true"
                    >
                      <mat-option
                        *ngFor="let item of refreshMeasuresList"
                        [value]="item.measureId"
                        >{{ item.measureName }}
                      </mat-option>
                    </mat-select>
                  </div>
                </form>
              </div>
              <div class="row">
                <div class="col-12 btn-wrapper">
                  <button
                    class="btn btn-primary m-0"
                    (click)="refreshMeasure()"
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <app-inventory-planner-grid
      [yearListGrid]="yearListGrid"
      *ngIf="yearListGrid.length > 0"
      [viewPermission]="viewPermission"
      [editPermission]="editPermission"
      [account]="account"
      [product]="product"
      [leftGroupList]="leftGroupList"
      [measureList]="measureList"
      [dropdownObjects]="dropdownObjects"
      [forecastList]="forecastList"
      [versionTypeId]="versionTypeId"
      [calendarList]="timeframeList"
      [selectedProducts]="productTree"
      [selectedChannel]="channelTree"
      [productTree]="productTree"
      (onClickEvent)="save()"
      [itemsList]="itemsList"
      [accountList]="accountList"
      (updateGridList)="updateCriteria($event)"
      [fbForecastSelectedId]="fbForecast?.fbForecastSelectedId"
      [forecastMethod]="forecastMethod"
      [seasonalitySourceLevel]="seasonalitySourceLevel"
      [movingAverageWeeks]="movingAverageWeeks"
      [calendarIds]="calendarIds"
      [userFBForecastId]="userFBForecastId"
      [fbForecast]="fbForecast"
      [isRestoreSession]="isRestoreSession"
      [isOpenSaved]="isOpenSaved"
      [measureKeys]="measureKeys"
      [invItemIds]="invItemIds"
      [invChannelIds]="invChannelIds"
      [masterMeasureList]="masterMeasureList"
    >
    </app-inventory-planner-grid>
  </div>
</div>
<ng-template #saveForecast>
  <h1 mat-dialog-title>What would you like to name your planner?</h1>
  <div mat-dialog-content>
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="Enter Planner Name"
        [(ngModel)]="plannerName"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>
  <div class="action-panel text-right btn-wrapper">
    <button
      mat-raised-button
      class="btn btn-primary m-left"
      color="primary"
      (click)="ref.close()"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      class="btn btn-primary m-left"
      color="warn"
      (click)="ref.close(true)"
    >
      Save
    </button>
  </div>
</ng-template>
<ng-template #commitForecast>
  <div mat-dialog-content>
    <p>Are you sure you want to commit your changes?</p>
  </div>
  <div class="action-panel text-right btn-wrapper">
    <button
      mat-raised-button
      class="btn btn-primary m-left"
      color="primary"
      (click)="ref.close()"
    >
      No
    </button>
    <button
      mat-raised-button
      class="btn btn-primary m-left"
      color="warn"
      (click)="commitFacebookPlanner()"
    >
      Yes
    </button>
  </div>
</ng-template>
<!-- <div id="fb-forecast">
  <div class="item-wrapper">
  <mat-tab-group *ngIf="!hideScreen" animationDuration="0ms">
    <mat-tab label="Retail Indirect">
      <div class="fb-forecast-accordion item-wrapper-panel" id="fb-forecast">
        <div class="container-fluid item-wrapper" id="fb-forecast-new-planner">
          <form class="account-form">
            <div class="row">
              <div class="col-md-3 padding-right">
                <div class="form-group">
                  <label for="Distributor" class="required">Distributor</label>
                  <mat-select placeholder="Synnex US">
                    <mat-option> </mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="col-md-3 padding-right">
                <div class="form-group">
                  <label for="Product" class="required">Product</label>
                  <mat-select placeholder="Quest">
                    <mat-option> </mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="col-md-3 padding-right">
                <div class="form-group">
                  <label for="Sku" class="required">Sku#</label>
                  <mat-select placeholder="135130-MD">
                    <mat-option> </mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="col-md-3 padding-right">
                <div class="form-group">
                  <label for="Timeframe" class="required">Timeframe</label>
                  <mat-select placeholder="All">
                    <mat-option> </mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="fb-forecast-accordion item-wrapper">
        <div class="data-wrapper btn-wrapper">
          <img src="assets/images/graph.svg" alt="" />
          <mat-select
            [(ngModel)]="exportOptions"
            placeholder="Export/Import"
            class="form-control"
          >
            <mat-option [value]="null">Export/Import</mat-option>
            <mat-option [value]="null">Export Current View</mat-option>
            <mat-option [value]="null">Export</mat-option>
            <mat-option [value]="null" *ngIf="editPermission">Import</mat-option>
          </mat-select>
          <button class="btn btn-primary">Save or Commit</button>
        </div>
      </div>

    </mat-tab>
    <mat-tab label="Retail Direct"> </mat-tab>
    <mat-tab label="DTC"> </mat-tab>
    <mat-tab label="ENT"> </mat-tab>
  </mat-tab-group>
</div>
</div> -->
