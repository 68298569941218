<h1 mat-dialog-title>Show/Hide Measures</h1>
<div mat-dialog-content>
  <div class="container-fluid item-wrapper">
    <!-- <mat-form-field class="example-full-width" floatLabel="never">
      <input matInput placeholder="Search" (input) ="searchForMeasure($event.target.value)" >
    </mat-form-field> -->
    <div class="row no-gutters">
      <div class="col-md-12">
        <h2>Selected Measures</h2>
        <div class="inner-data">
          <label class="main-label">
            <mat-checkbox (change) = "selectAllChanged($event)" [(ngModel)]="selectAll"> Select All </mat-checkbox>
          </label>
          <label
            class="main-label"
            *ngFor="let item of data.measureList; let i = index"
          >
            <mat-checkbox [(ngModel)]="item.checked">
              {{ item.measureName }}
            </mat-checkbox>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="action-panel text-right btn-wrapper"
  style="
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #707070;
    padding-top: 30px;"
>
  <div style="display: flex">
    <button
      mat-raised-button
      class="btn btn-primary m-right"
      color="warn"
      (click)="applyMeasure()"
    >
      Apply
    </button>
  </div>
</div>
