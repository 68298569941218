<div mat-dialog-content>
  <!-- <mat-form-field>
    <input matInput [(ngModel)]="headerName" placeholder="Enter Header Name">
  </mat-form-field> -->
  <mat-form-field>
    <mat-label>Month and Year</mat-label>
    <input matInput [matDatepicker]="dp" [formControl]="date">
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp startView="month" (yearSelected)="chosenYearHandler($event)"
      (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
    </mat-datepicker>
  </mat-form-field>
  <mat-error *ngIf="showErrorMessage">Column already exists</mat-error>
</div>
<div class="action-panel text-right">
  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit()">Save</button>
  <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="onNoClick()">Cancel</button>
</div>
