<div class="container-fluid promotion-detail-page">

    <hr class="theme-hr" />
    <!-- Form Expanstion Panel -->
    <mat-accordion class="inpage-expansion-panel">
        <form [formGroup]="promotionPlannerDetailForm" class="item-dialog-form" (ngSubmit)="checkActivePromtions()">
            <mat-expansion-panel [(expanded)]="detailPanelOpenState" (opened)="detailPanelOpenState = true"
                (closed)="detailPanelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Promotion Details
                    </mat-panel-title>
                    <mat-panel-description>
                        <div class="row panel-header-action-button">
                            <div class="col-md-12 d-none d-sm-none d-md-block tablet-icon">
                                <div class="btn-show">

                                    <mat-slide-toggle [disabled]="!isRetailerTeam" (toggleChange)="toggleChange($event)"
                                        formControlName="active">{{getStatusLabel}}
                                    </mat-slide-toggle>
                                    <button *ngIf="isRetailerTeam" type="submit" mat-button mat-flat-button
                                        color="accent">Save</button>
                                    <button *ngIf="isRetailerTeam" type="button" mat-button mat-flat-button
                                        color="accent" (click)="copyPromotion($event)">Copy</button>
                                    <button *ngIf="isRetailerTeam" matTooltip="Add New Promotion" mat-icon-button
                                        color="primary" type="button" (click)="addNewPromotion($event)">
                                        <i class="material-icons">
                                            add_circle
                                        </i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="row promotion-detail-fields">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4 product-field-label">
                                <strong>Promotion Name*</strong>
                            </div>
                            <div class="col-md-8">
                                <mat-form-field appearance="fill">
                                    <input [readonly]="!isRetailerTeam" matInput
                                        formControlName="promotionItemPlannerName">
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 product-field-label">
                                <strong>Planning Month*</strong>
                            </div>
                            <div class="col-md-8">
                                <mat-form-field appearance="fill">
                                    <input readonly matInput formControlName="planningMonth">
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 product-field-label">
                                <strong>Start Date*</strong>
                            </div>
                            <div class="col-md-8">
                                <mat-form-field appearance="fill">
                                    <input matInput [readonly]="!isRetailerTeam" [matDatepicker]="startDate"
                                        (dateChange)="startDateChanged()" formControlName="startDate">
                                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                                    <mat-datepicker [disabled]="!isRetailerTeam" #startDate></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 product-field-label">
                                <strong>End Date*</strong>
                            </div>
                            <div class="col-md-8">
                                <mat-form-field appearance="fill">
                                    <input matInput [readonly]="!isRetailerTeam" [matDatepicker]="endDate"
                                        formControlName="endDate">
                                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                                    <mat-datepicker [disabled]="!isRetailerTeam" #endDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">

                            <div class="col-md-4 product-field-label">
                                <strong>Account(s)*</strong>
                            </div>
                            <div class="col-md-8">
                                <mat-form-field appearance="fill">
                                    <mat-select multiple formControlName="accountIds"
                                        [disabled]="disabledAccountSelection" (selectionChange)="accountIdsChange()">
                                        <mat-option *ngFor="let account of accountList" [value]="account.accountId">
                                            {{account.accountName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 product-field-label">
                                <strong>Fixture*</strong>
                            </div>
                            <div class="col-md-8">
                                <mat-form-field appearance="fill">
                                    <mat-select [disabled]="!isRetailerTeam" formControlName="fixtureId">
                                        <mat-option *ngFor="let fixture of fixtureList" [value]="fixture.fixtureId">
                                            {{fixture.fixtureName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 product-field-label">
                                <strong>Class Name*</strong>
                            </div>
                            <div class="col-md-8">
                                <mat-form-field appearance="fill">
                                    <mat-select [disabled]="!isRetailerTeam" multiple formControlName="classIds"
                                        (selectionChange)="classIdsChange()">
                                        <mat-option *ngFor="let class of classList" [value]="class.classId">
                                            {{class.className}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 product-field-label">
                                <strong>Comments</strong>
                            </div>
                            <div class="col-md-8">
                                <mat-form-field appearance="fill">
                                    <textarea matInput [readonly]="!isRetailerTeam" formControlName="comments" rows="3"
                                        cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </form>
    </mat-accordion>
    <!-- End Form Expansion Panel -->

    <hr class="theme-hr" />
    <ng-container *ngIf="isPromotionSaved || isEditMode">
        <app-promotion-planner-planogram [fixtureObject]="selectedFixtureObject" [itemDetailData]="itemDetailGridData"
            [promotionDetail]="formData" (itemPositionChanges)="saveChangedItemPositions($event)">
        </app-promotion-planner-planogram>
    </ng-container>

    <!-- Promotion Planner Detail Tabs -->
    <mat-accordion *ngIf="isPromotionSaved || isEditMode || isCopyMode" class="inpage-expansion-panel">
        <mat-expansion-panel expanded="true" (opened)="itemDetailGridPanelOpenState = true"
            (closed)="itemDetailGridPanelOpenState = false">

            <mat-expansion-panel-header>
                <mat-panel-title>
                    Item View
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-tab-group class="promotion-planner-detail-tabs" (selectedTabChange)="onTabChanged($event);">
                <!-- Item Detail Tab -->
                <mat-tab>
                    <ng-template matTabLabel>
                        <span [matBadge]="acceptedItemsCount" matBadgeOverlap="false" matBadgeColor="accent">Item
                            Details</span>
                    </ng-template>
                    <div class="promotion-planner-tab-btn tablet-icon">
                        <div class="btn-show px-4">
                            <button *ngIf="isItemDetailGridDataUpdated && !isCopyMode" class="grid-bar-icon"
                                type="button" mat-button mat-flat-button color="accent"
                                (click)="saveUpdatedItemDetailGridData($event)">
                                Save
                            </button>
                            <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="primary"
                                (click)="openItemDetailColumnDialog($event)">
                                <i class="material-icons">
                                    ballot
                                </i>
                            </button>
                            <button matTooltip="Export" class="grid-bar-icon" mat-icon-button color="primary"
                                (click)="onExportItemDetailGridData($event)">
                                <i class="material-icons">
                                    insert_drive_file
                                </i>
                            </button>

                            <button *ngIf="!isCopyMode" class="import-export-button" mat-flat-button color="accent"
                                [matMenuTriggerFor]="importExportMenu">Export/Import</button>
                            <mat-menu #importExportMenu="matMenu">
                                <button mat-menu-item (click)="exportPromotionDetailDataTemplate()">Export</button>
                                <button mat-menu-item (click)="importPromotionDetailDataByTemplate()">Import
                                    <input class="hidden" type="file" id="upload-promotion-planner-detail-data"
                                        name="upload-promotion-planner-detail-data" accept=".csv"
                                        (change)="validatePromotionDetailUploadedCSV($event)">
                                </button>
                            </mat-menu>

                            <button *ngIf="!isCopyMode" class="import-export-button" mat-flat-button color="accent"
                                [matMenuTriggerFor]="actionMenu" (click)="handleExport($event)">Actions</button>
                            <mat-menu #actionMenu="matMenu">
                                <button mat-menu-item (click)="performActionOnDetailItems('Add')">Add</button>
                                <button mat-menu-item *ngIf="!isRetailerTeam" (click)="deleteItems()">Delete</button>
                                <ng-container *ngIf="isRetailerTeam">
                                    <ng-container *ngFor="let action of actionsList">
                                        <button mat-menu-item
                                            (click)="performActionOnDetailItems(action)">{{action.presentTenseAction}}</button>
                                    </ng-container>
                                </ng-container>
                                <button mat-menu-item (click)="performActionOnDetailItems('Copy')">Copy</button>
                                <button mat-menu-item (click)="performActionOnDetailItems('Move')">Move</button>
                            </mat-menu>

                        </div>
                    </div>

                    <div class="all-show-grid">
                        <ag-grid-angular class="ag-theme-material" (sortChanged)="onItemGridStateChange($event)"
                            (columnVisible)="onItemGridStateChange($event)"
                            (columnMoved)="onItemGridStateChange($event)" (columnPinned)="onItemGridStateChange($event)"
                            [suppressRowClickSelection]="true" [overlayLoadingTemplate]="overlayLoadingTemplate"
                            [suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups toolPanelSuppressValues
                            [gridOptions]="itemDetailGridOptions" [components]="components"
                            [columnDefs]="itemDetailGridColumnDefs" [editType]="editType"
                            [defaultColDef]="defaultColDef" [rowData]="itemDetailGridData" [pagination]="true"
                            [rowSelection]="rowSelection" [rowMultiSelectWithClick]="true"
                            [stopEditingWhenGridLosesFocus]="true"
                            (selectionChanged)="itemDetailGridRowSelectionChanged($event)"
                            [isRowSelectable]="isItemDetailGridRowSelectable"
                            (cellValueChanged)="itemDetailGridDataUpdated($event)"
                            [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
                            (gridReady)="onItemDetailGridReady($event)" [modules]="modules"
                            [id]="promotionItemDetailGridId" (cellKeyDown)="onCellKeyDown($event, itemDetailGridApi)">
                        </ag-grid-angular>
                    </div>
                </mat-tab>
                <!-- End Item Detail Tab -->

                <!-- Weekly Forecast Tab -->
                <mat-tab label="Weekly Forecast">
                    <div class="promotion-planner-tab-btn tablet-icon">
                        <div class="btn-show px-1">
                            <button *ngIf="isWeeklyForecastGridDataUpdated" class="grid-bar-icon" type="button"
                                mat-button mat-flat-button color="accent"
                                (click)="saveUpdatedWeeklyForecastGridData($event)">
                                Save
                            </button>
                            <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="primary"
                                (click)="openWeeklyForecastColumnDialog($event)">
                                <i class="material-icons">
                                    ballot
                                </i>
                            </button>
                        </div>
                    </div>

                    <div class="all-show-grid">
                        <ag-grid-angular class="ag-theme-material forecast-grid"
                            (sortChanged)="onForecastGridStateChange($event)"
                            (columnVisible)="onForecastGridStateChange($event)"
                            (columnMoved)="onForecastGridStateChange($event)"
                            (columnPinned)="onForecastGridStateChange($event)" [suppressRowClickSelection]="true"
                            [overlayLoadingTemplate]="overlayLoadingTemplate" [suppressMenuHide]="true" groupHeaders
                            toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="weeklyForecastGridOptions"
                            [components]="components" [columnDefs]="weeklyForecastGridColumnDefs" [editType]="editType"
                            [defaultColDef]="defaultColDef" [rowData]="weeklyForecastGridData" [pagination]="true"
                            [rowSelection]="rowSelection" [rowMultiSelectWithClick]="true"
                            [stopEditingWhenGridLosesFocus]="true"
                            (cellValueChanged)="weeklyForecastGridDataUpdated($event)"
                            [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
                            (gridReady)="onWeeklyForecastGridReady($event)" [modules]="modules"
                            [id]="promotionWeeklyForecastGridId"
                            (cellKeyDown)="onCellKeyDown($event, weeklyForecastGridApi)">
                        </ag-grid-angular>
                    </div>
                </mat-tab>
                <!-- End Weekly Forecast Tab -->
            </mat-tab-group>


        </mat-expansion-panel>
    </mat-accordion>
    <!-- End Promotion Planner Detail Tabs -->

</div>