export const itemsData = [
    {
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Blue 128 GB",
        "SKU": "1245678",
        "Retail Price": "1999",
        "Cost": "719",
        "Supplier Name": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "6",
        "Lead Time St dev": "0.04",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "1",
        "Target Fill Rate": "0.87",
        "Fixed Order QTY": "668",
        "Order Up To": "531"
    },
    {
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Pink 128 GB",
        "SKU": "1245679",
        "Retail Price": "1999",
        "Cost": "693",
        "Supplier Name": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "4",
        "Lead Time St dev": "0.03",
        "One Time Order Cost": "1",
        "Inventory Retention Cost": "2",
        "Target Fill Rate": "0.91",
        "Fixed Order QTY": "589",
        "Order Up To": "783"
    },
    {
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Graphite 128 GB",
        "SKU": "77889911",
        "Retail Price": "1999",
        "Cost": "760",
        "Supplier Name": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "6",
        "Lead Time St dev": "0.12",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "1",
        "Target Fill Rate": "0.86",
        "Fixed Order QTY": "789",
        "Order Up To": "788"
    },
    {
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Silver 128 GB",
        "SKU": "77889912",
        "Retail Price": "1999",
        "Cost": "590",
        "Supplier Name": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "2",
        "Lead Time St dev": "0.14",
        "One Time Order Cost": "3",
        "Inventory Retention Cost": "1",
        "Target Fill Rate": "0.94",
        "Fixed Order QTY": "594",
        "Order Up To": "562"
    },
    {
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Blue 256 GB",
        "SKU": "9988899",
        "Retail Price": "1999",
        "Cost": "678",
        "Supplier Name": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "3",
        "Lead Time St dev": "0.2",
        "One Time Order Cost": "3",
        "Inventory Retention Cost": "3",
        "Target Fill Rate": "0.93",
        "Fixed Order QTY": "652",
        "Order Up To": "633"
    },
    {
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Pink 256GB",
        "SKU": "9988899",
        "Retail Price": "5999",
        "Cost": "1600",
        "Supplier Name": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "2",
        "Lead Time St dev": "0.09",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "1",
        "Target Fill Rate": "0.98",
        "Fixed Order QTY": "679",
        "Order Up To": "582"
    },
    {
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Graphite 256 GB",
        "SKU": "5566999",
        "Retail Price": "5999",
        "Cost": "1600",
        "Supplier Name": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "2",
        "Lead Time St dev": "0.06",
        "One Time Order Cost": "1",
        "Inventory Retention Cost": "2",
        "Target Fill Rate": "0.98",
        "Fixed Order QTY": "669",
        "Order Up To": "648"
    },
    {
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Silver 256 GB",
        "SKU": "5566999",
        "Retail Price": "5999",
        "Cost": "1600",
        "Supplier Name": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "3",
        "Lead Time St dev": "0.17",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "1",
        "Target Fill Rate": "0.97",
        "Fixed Order QTY": "538",
        "Order Up To": "628"
    }
];

export const replenishmentManagerData = [
    {
        "Region": "North",
        "Country": "Can",
        "State": "AB",
        "City": "ED",
        "Channel": "Retail",
        "Cluster": "A",
        "Retailer": "Best Buy",
        "Store": "6645",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Blue 128 GB",
        "SKU": "1245678",
        "Retail Price": "1999",
        "Cost": "719",
        "Gross Margin %": "64.07842475",
        "Sales This Week": "15",
        "Avg Weekly Sales": "58",
        "Sales St Dev": "0.1",
        "Sales Graph": "",
        "Forecast": "11",
        "Total Inventory at Stores": "1200",
        "Inventory At Warehouse": "1200",
        "Total Inventory": "2400",
        "Suggested Order QTY": "58",
        "Total In-Transit Stock": "121",
        "Weeks of Supply": "3",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "3",
        "Lead Time St dev": "1",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.87",
        "Store Lead Time": "4"
    },
    {
        "Region": "Noth",
        "Country": "Can",
        "State": "AB",
        "City": "ED",
        "Channel": "Retail",
        "Cluster": "A1",
        "Retailer": "Walmart",
        "Store": "2644",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Pink 128 GB",
        "SKU": "1245679",
        "Retail Price": "1999",
        "Cost": "693",
        "Gross Margin %": "54.97748874",
        "Sales This Week": "20",
        "Avg Weekly Sales": "292",
        "Sales St Dev": "0.15",
        "Sales Graph": "",
        "Forecast": "13",
        "Total Inventory at Stores": "800",
        "Inventory At Warehouse": "1200",
        "Total Inventory": "2000",
        "Suggested Order QTY": "250",
        "Total In-Transit Stock": "86",
        "Weeks of Supply": "3",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "2",
        "Lead Time St dev": "1",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.91",
        "Store Lead Time": "4"
    },
    {
        "Region": "Center",
        "Country": "US",
        "State": "NY",
        "City": "NY",
        "Channel": "Retail",
        "Cluster": "B",
        "Retailer": "Best Buy",
        "Store": "2304",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Graphite 128 GB",
        "SKU": "77889911",
        "Retail Price": "1999",
        "Cost": "760",
        "Gross Margin %": "59.1361946",
        "Sales This Week": "15",
        "Avg Weekly Sales": "255",
        "Sales St Dev": "0.2",
        "Sales Graph": "",
        "Forecast": "17",
        "Total Inventory at Stores": "200",
        "Inventory At Warehouse": "300",
        "Total Inventory": "500",
        "Suggested Order QTY": "216",
        "On Order": "150",
        "Total In-Transit Stock": "108",
        "Weeks of Supply": "6",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "6",
        "Lead Time St dev": "3",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.86",
        "Store Lead Time": "3"
    },
    {
        "Region": "Center",
        "Country": "US",
        "State": "NY",
        "City": "NY",
        "Channel": "Retail",
        "Cluster": "B1",
        "Retailer": "Walmart",
        "Store": "5506",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Silver 128 GB",
        "SKU": "77889912",
        "Retail Price": "1999",
        "Cost": "590",
        "Gross Margin %": "57.50887492",
        "Sales This Week": "17",
        "Avg Weekly Sales": "141",
        "Sales St Dev": "0.18",
        "Sales Graph": "",
        "Forecast": "13",
        "Total Inventory at Stores": "400",
        "Inventory At Warehouse": "600",
        "Total Inventory": "1000",
        "Suggested Order QTY": "126",
        "On Order": "200",
        "Total In-Transit Stock": "138",
        "Weeks of Supply": "4",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "4",
        "Lead Time St dev": "2",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.94",
        "Store Lead Time": "1"
    },
    {
        "Region": "Center",
        "Country": "US",
        "State": "NY",
        "City": "NY",
        "Channel": "Retail",
        "Cluster": "A",
        "Retailer": "Best Buy",
        "Store": "3638",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Blue 256 GB",
        "SKU": "9988899",
        "Retail Price": "1999",
        "Cost": "678",
        "Gross Margin %": "63.83734036",
        "Sales This Week": "22",
        "Avg Weekly Sales": "184",
        "Sales St Dev": "0.16",
        "Sales Graph": "",
        "Forecast": "10",
        "Total Inventory at Stores": "1800",
        "Inventory At Warehouse": "1700",
        "Total Inventory": "3500",
        "Suggested Order QTY": "165",
        "Total In-Transit Stock": "139",
        "Weeks of Supply": "4",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "6",
        "Lead Time St dev": "2",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.93",
        "Store Lead Time": "4"
    },
    {
        "Region": "North",
        "Country": "Can",
        "State": "AB",
        "City": "ED",
        "Channel": "Retail",
        "Cluster": "B",
        "Retailer": "Best Buy",
        "Store": "5337",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Pink 256GB",
        "SKU": "9988899",
        "Retail Price": "5999",
        "Cost": "1600",
        "Gross Margin %": "67.8661303",
        "Sales Graph": "",
        "Forecast": "29",
        "Total Inventory at Stores": "360",
        "Inventory At Warehouse": "640",
        "Total Inventory": "1000",
        "Suggested Order QTY": "0",
        "On Order": "200",
        "Receiving This Week": "1000",
        "Total In-Transit Stock": "360",
        "Weeks of Supply": "6",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "2",
        "Lead Time St dev": "1",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.98",
        "Store Lead Time": "2"
    },
    {
        "Region": "North",
        "Country": "US",
        "State": "NY",
        "City": "NY",
        "Channel": "WS",
        "Cluster": "C",
        "Retailer": "Walmart",
        "Store": "2335",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Graphite 256 GB",
        "SKU": "5566999",
        "Retail Price": "5999",
        "Cost": "1600",
        "Gross Margin %": "67.8661303",
        "Sales Graph": "",
        "Forecast": "25",
        "Total Inventory at Stores": "80",
        "Inventory At Warehouse": "350",
        "Total Inventory": "430",
        "Suggested Order QTY": "0",
        "On Order": "150",
        "Receiving This Week": "430",
        "Total In-Transit Stock": "80",
        "Weeks of Supply": "5",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "5",
        "Lead Time St dev": "1",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.98",
        "Store Lead Time": "2"
    },
    {
        "Region": "North",
        "Country": "US",
        "State": "NY",
        "City": "NY",
        "Channel": "WS",
        "Cluster": "A",
        "Retailer": "Walmart",
        "Store": "2618",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Silver 256 GB",
        "SKU": "5566999",
        "Retail Price": "5999",
        "Cost": "1600",
        "Gross Margin %": "67.8661303",
        "Sales Graph": "",
        "Forecast": "12",
        "Total Inventory at Stores": "120",
        "Inventory At Warehouse": "600",
        "Total Inventory": "720",
        "Suggested Order QTY": "0",
        "On Order": "200",
        "Receiving This Week": "720",
        "Total In-Transit Stock": "120",
        "Weeks of Supply": "6",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "3",
        "Lead Time St dev": "2",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.97",
        "Store Lead Time": "4"
    }
];

export const orderingStatusData = [
    {
        "Retailer": "Best Buy",
        "Store": "1507",
        "SKU Description": "iPhone 12 Pro Max Blue 128 GB",
        "SKU": "1245678",
        "Supplier": "Apple",
        "Cost": "250",
        "Sales This Week": "15",
        "Avg Weekly Sales": "58",
        "Sales Graph": "",
        "13 WKS Forecast": "22",
        "Total Inventory": "2400",
        "Suggested Order QTY": "200",
        "Order QTY": "0"
    },
    {
        "Retailer": "Walmart",
        "Store": "1525",
        "SKU Description": "iPhone 12 Pro Max Pink 128 GB",
        "SKU": "1245679",
        "Supplier": "Apple",
        "Cost": "252",
        "Sales This Week": "20",
        "Avg Weekly Sales": "292",
        "Sales Graph": "",
        "13 WKS Forecast": "25",
        "Total Inventory": "2000",
        "Suggested Order QTY": "250",
        "Order QTY": "0"
    },
    {
        "Retailer": "Best Buy",
        "Store": "5400",
        "SKU Description": "iPhone 12 Pro Max Graphite 128 GB",
        "SKU": "77889911",
        "Supplier": "Apple",
        "Cost": "195",
        "Sales This Week": "15",
        "Avg Weekly Sales": "255",
        "Sales Graph": "",
        "13 WKS Forecast": "21",
        "Total Inventory": "500",
        "On Order": "150",
        "Suggested Order QTY": "150",
        "Order QTY": "150"
    },
    {
        "Retailer": "Walmart",
        "Store": "3746",
        "SKU Description": "iPhone 12 Pro Max Silver 128 GB",
        "SKU": "77889912",
        "Supplier": "Apple",
        "Cost": "273",
        "Sales This Week": "17",
        "Avg Weekly Sales": "141",
        "Sales Graph": "",
        "13 WKS Forecast": "18",
        "Total Inventory": "1000",
        "On Order": "200",
        "Suggested Order QTY": "200",
        "Order QTY": "200"
    },
    {
        "Retailer": "Best Buy",
        "Store": "5158",
        "SKU Description": "iPhone 13 Pro Max Blue 256 GB",
        "SKU": "9988899",
        "Supplier": "Apple",
        "Cost": "181",
        "Sales This Week": "22",
        "Avg Weekly Sales": "184",
        "Sales Graph": "",
        "13 WKS Forecast": "25",
        "Total Inventory": "3500",
        "Suggested Order QTY": "150",
        "Order QTY": "150"
    },
    {
        "Retailer": "Best Buy",
        "Store": "3148",
        "SKU Description": "iPhone 13 Pro Max Pink 256GB",
        "SKU": "9988899",
        "Supplier": "Apple",
        "Cost": "196",
        "Sales This Week": "12",
        "Avg Weekly Sales": "75",
        "Sales Graph": "",
        "13 WKS Forecast": "27",
        "Total Inventory": "1000",
        "On Order": "200",
        "Suggested Order QTY": "200",
        "Order QTY": "200"
    },
    {
        "Retailer": "Walmart",
        "Store": "1564",
        "SKU Description": "iPhone 13 Pro Max Graphite 256 GB",
        "SKU": "5566999",
        "Supplier": "Apple",
        "Cost": "239",
        "Sales This Week": "15",
        "Avg Weekly Sales": "105",
        "Sales Graph": "",
        "13 WKS Forecast": "26",
        "Total Inventory": "430",
        "On Order": "150",
        "Order QTY": "0"
    },
    {
        "Retailer": "Walmart",
        "Store": "3735",
        "SKU Description": "iPhone 13 Pro Max Silver 256 GB",
        "SKU": "5566999",
        "Supplier": "Apple",
        "Cost": "211",
        "Sales This Week": "25",
        "Avg Weekly Sales": "270",
        "Sales Graph": "",
        "13 WKS Forecast": "15",
        "Total Inventory": "720",
        "On Order": "200",
        "Order QTY": "0"
    }
];

export const skuData = [
    {
        "Retailer": "Best Buy",
        "Store": "1864",
        "SKU Description": "iPhone 12 Pro Max Blue 128 GB",
        "Retail Price": "1999",
        "Sales TW": "2",
        "Avg Weekly Sales": "12",
        "Sales Graph": "",
        "13 WKS Forecast": "10",
        "Total Inventory": "12",
        "Weeks Of Supply": "4",
        "On Order": "27"
    },
    {
        "Retailer": "Walmart",
        "Store": "4661",
        "SKU Description": "iPhone 12 Pro Max Blue 128 GB",
        "Retail Price": "1999",
        "Sales TW": "1",
        "Avg Weekly Sales": "20",
        "Sales Graph": "",
        "13 WKS Forecast": "13",
        "Total Inventory": "16",
        "Weeks Of Supply": "3",
        "On Order": "21"
    },
    {
        "Retailer": "Best Buy",
        "Store": "2092",
        "SKU Description": "iPhone 12 Pro Max Blue 128 GB",
        "Retail Price": "1999",
        "Sales TW": "6",
        "Avg Weekly Sales": "20",
        "Sales Graph": "",
        "13 WKS Forecast": "14",
        "Total Inventory": "20",
        "Weeks Of Supply": "4",
        "On Order": "23"
    },
    {
        "Retailer": "Walmart",
        "Store": "6100",
        "SKU Description": "iPhone 12 Pro Max Blue 128 GB",
        "Retail Price": "1999",
        "Sales TW": "5",
        "Avg Weekly Sales": "23",
        "Sales Graph": "",
        "13 WKS Forecast": "10",
        "Total Inventory": "11",
        "Weeks Of Supply": "3",
        "On Order": "18"
    },
    {
        "Retailer": "Best Buy",
        "Store": "3945",
        "SKU Description": "iPhone 12 Pro Max Blue 128 GB",
        "Retail Price": "1999",
        "Sales TW": "4",
        "Avg Weekly Sales": "21",
        "Sales Graph": "",
        "13 WKS Forecast": "27",
        "Total Inventory": "19",
        "Weeks Of Supply": "2",
        "On Order": "21"
    },
    {
        "Retailer": "Best Buy",
        "Store": "6161",
        "SKU Description": "iPhone 12 Pro Max Blue 128 GB",
        "Retail Price": "5999",
        "Sales TW": "7",
        "Avg Weekly Sales": "21",
        "Sales Graph": "",
        "13 WKS Forecast": "14",
        "Total Inventory": "11",
        "Weeks Of Supply": "3",
        "On Order": "17"
    },
    {
        "Retailer": "Walmart",
        "Store": "4387",
        "SKU Description": "iPhone 12 Pro Max Blue 128 GB",
        "Retail Price": "5999",
        "Sales TW": "2",
        "Avg Weekly Sales": "22",
        "Sales Graph": "",
        "13 WKS Forecast": "14",
        "Total Inventory": "16",
        "Weeks Of Supply": "4",
        "On Order": "15"
    },
    {
        "Retailer": "Walmart",
        "Store": "5564",
        "SKU Description": "iPhone 12 Pro Max Blue 128 GB",
        "Retail Price": "5999",
        "Sales TW": "3",
        "Avg Weekly Sales": "17",
        "Sales Graph": "",
        "13 WKS Forecast": "16",
        "Total Inventory": "16",
        "Weeks Of Supply": "3",
        "On Order": "32"
    }
];

export const storesData = [
    {
        "Brand": "Apple",
        "SKU Description": "iPhone 12 Pro Max Blue 128 GB",
        "SKU Number": "1245678",
        "Retail Price": "1999",
        "Sales TW": "2",
        "Avg Weekly Sales": "3",
        "Sales Graph": "",
        "13 WKS Forecast": "13",
        "Current Stock": "7",
        "Sales TD": "31",
        "Weeks oF Supply": "4",
        "On Order": "24"
    },
    {
        "Brand": "Apple",
        "SKU Description": "iPhone 12 Pro Max Pink 128 GB",
        "SKU Number": "1245679",
        "Retail Price": "1999",
        "Sales TW": "9",
        "Avg Weekly Sales": "4",
        "Sales Graph": "",
        "13 WKS Forecast": "13",
        "Current Stock": "8",
        "Sales TD": "39",
        "Weeks oF Supply": "2",
        "On Order": "15"
    },
    {
        "Brand": "Apple",
        "SKU Description": "iPhone 12 Pro Max Graphite 128 GB",
        "SKU Number": "77889911",
        "Retail Price": "1999",
        "Sales TW": "8",
        "Avg Weekly Sales": "4",
        "Sales Graph": "",
        "13 WKS Forecast": "29",
        "Current Stock": "8",
        "Sales TD": "42",
        "Weeks oF Supply": "4",
        "On Order": "30"
    },
    {
        "Brand": "Apple",
        "SKU Description": "iPhone 12 Pro Max Silver 128 GB",
        "SKU Number": "77889912",
        "Retail Price": "1999",
        "Sales TW": "7",
        "Avg Weekly Sales": "4",
        "Sales Graph": "",
        "13 WKS Forecast": "23",
        "Current Stock": "9",
        "Sales TD": "35",
        "Weeks oF Supply": "3",
        "On Order": "19"
    },
    {
        "Brand": "Apple",
        "SKU Description": "iPhone 13 Pro Max Blue 256 GB",
        "SKU Number": "9988899",
        "Retail Price": "1999",
        "Sales TW": "7",
        "Avg Weekly Sales": "5",
        "Sales Graph": "",
        "13 WKS Forecast": "22",
        "Current Stock": "9",
        "Sales TD": "55",
        "Weeks oF Supply": "4",
        "On Order": "29"
    },
    {
        "Brand": "Apple",
        "SKU Description": "iPhone 13 Pro Max Pink 256GB",
        "SKU Number": "9988899",
        "Retail Price": "5999",
        "Sales TW": "9",
        "Avg Weekly Sales": "2",
        "Sales Graph": "",
        "13 WKS Forecast": "13",
        "Current Stock": "6",
        "Sales TD": "46",
        "Weeks oF Supply": "4",
        "On Order": "27"
    },
    {
        "Brand": "Apple",
        "SKU Description": "iPhone 13 Pro Max Graphite 256 GB",
        "SKU Number": "5566999",
        "Retail Price": "5999",
        "Sales TW": "8",
        "Avg Weekly Sales": "2",
        "Sales Graph": "",
        "13 WKS Forecast": "14",
        "Current Stock": "9",
        "Sales TD": "46",
        "Weeks oF Supply": "2",
        "On Order": "15"
    },
    {
        "Brand": "Apple",
        "SKU Description": "iPhone 13 Pro Max Silver 256 GB",
        "SKU Number": "5566999",
        "Retail Price": "5999",
        "Sales TW": "7",
        "Avg Weekly Sales": "3",
        "Sales Graph": "",
        "13 WKS Forecast": "24",
        "Current Stock": "7",
        "Sales TD": "55",
        "Weeks oF Supply": "3",
        "On Order": "16"
    }
];

export const POData = [
    {
        "Supplier": "Apple",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Blue 128 GB",
        "SKU Number": "1245678",
        "Ordering Date": "3/2/22",
        "Expected Arrival Date": "3/25/22",
        "Cost": "1",
        "Order QTY": "462"
    },
    {
        "Supplier": "Apple",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Pink 128 GB",
        "SKU Number": "1245679",
        "Ordering Date": "3/2/22",
        "Expected Arrival Date": "3/25/22",
        "Cost": "1",
        "Order QTY": "438"
    },
    {
        "Supplier": "Apple",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Graphite 128 GB",
        "SKU Number": "77889911",
        "Ordering Date": "3/5/22",
        "Expected Arrival Date": "3/27/22",
        "Cost": "2",
        "Order QTY": "557"
    },
    {
        "Supplier": "Apple",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Silver 128 GB",
        "SKU Number": "77889912",
        "Ordering Date": "3/5/22",
        "Expected Arrival Date": "3/27/22",
        "Cost": "1",
        "Order QTY": "479"
    },
    {
        "Supplier": "Apple",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Blue 256 GB",
        "SKU Number": "9988899",
        "Ordering Date": "3/5/22",
        "Expected Arrival Date": "3/27/22",
        "Cost": "2",
        "Order QTY": "553"
    },
    {
        "Supplier": "Apple",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Pink 256GB",
        "SKU Number": "9988899",
        "Ordering Date": "3/11/22",
        "Expected Arrival Date": "4/5/22",
        "Cost": "2",
        "Order QTY": "559"
    },
    {
        "Supplier": "Apple",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Graphite 256 GB",
        "SKU Number": "5566999",
        "Ordering Date": "3/11/22",
        "Expected Arrival Date": "4/5/22",
        "Cost": "1",
        "Order QTY": "313"
    },
    {
        "Supplier": "Apple",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Silver 256 GB",
        "SKU Number": "5566999",
        "Ordering Date": "3/11/22",
        "Expected Arrival Date": "4/5/22",
        "Cost": "2",
        "Order QTY": "307"
    }
];

export const itemViewCompleteData = [
    {
        "Region": "North",
        "Country": "Can",
        "State": "AB",
        "City": "ED",
        "Channel": "Retail",
        "Cluster": "A",
        "Retailer": "Best Buy",
        "Store": "4686",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Blue 128 GB",
        "SKU": "1245678",
        "Retail Price": "1999",
        "Cost": "719",
        "Gross Margin %": "64.07842475",
        "Sales This Week": "15",
        "Avg Weekly Sales": "58",
        "Sales St Dev": "0.1",
        "Sales Graph": "",
        "Forecast": "14",
        "Total Inventory at Stores": "1200",
        "Inventory At Warehouse": "1200",
        "Total Inventory": "2400",
        "Total In-Transit Stock": "121",
        "Weeks of Supply": "4",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "6",
        "Lead Time St dev": "3",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.87",
        "Store Lead Time": "3",
        "Suggested Order QTY": "58"
    },
    {
        "Region": "Noth",
        "Country": "Can",
        "State": "AB",
        "City": "ED",
        "Channel": "Retail",
        "Cluster": "A1",
        "Retailer": "Walmart",
        "Store": "5302",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Pink 128 GB",
        "SKU": "1245679",
        "Retail Price": "1999",
        "Cost": "693",
        "Gross Margin %": "54.97748874",
        "Sales This Week": "20",
        "Avg Weekly Sales": "292",
        "Sales St Dev": "0.15",
        "Sales Graph": "",
        "Forecast": "11",
        "Total Inventory at Stores": "800",
        "Inventory At Warehouse": "1200",
        "Total Inventory": "2000",
        "Total In-Transit Stock": "86",
        "Weeks of Supply": "6",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "5",
        "Lead Time St dev": "3",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.91",
        "Store Lead Time": "2",
        "Suggested Order QTY": "250"
    },
    {
        "Region": "Center",
        "Country": "US",
        "State": "NY",
        "City": "NY",
        "Channel": "Retail",
        "Cluster": "B",
        "Retailer": "Best Buy",
        "Store": "5062",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Graphite 128 GB",
        "SKU": "77889911",
        "Retail Price": "1999",
        "Cost": "760",
        "Gross Margin %": "59.1361946",
        "Sales This Week": "15",
        "Avg Weekly Sales": "255",
        "Sales St Dev": "0.2",
        "Sales Graph": "",
        "Forecast": "15",
        "Total Inventory at Stores": "200",
        "Inventory At Warehouse": "300",
        "Total Inventory": "500",
        "On Order": "150",
        "Total In-Transit Stock": "108",
        "Weeks of Supply": "3",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "5",
        "Lead Time St dev": "2",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.86",
        "Store Lead Time": "4",
        "Suggested Order QTY": "216"
    },
    {
        "Region": "Center",
        "Country": "US",
        "State": "NY",
        "City": "NY",
        "Channel": "Retail",
        "Cluster": "B1",
        "Retailer": "Walmart",
        "Store": "4484",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 12 Pro Max",
        "SKU Description": "iPhone 12 Pro Max Silver 128 GB",
        "SKU": "77889912",
        "Retail Price": "1999",
        "Cost": "590",
        "Gross Margin %": "57.50887492",
        "Sales This Week": "17",
        "Avg Weekly Sales": "141",
        "Sales St Dev": "0.18",
        "Sales Graph": "",
        "Forecast": "27",
        "Total Inventory at Stores": "400",
        "Inventory At Warehouse": "600",
        "Total Inventory": "1000",
        "On Order": "200",
        "Total In-Transit Stock": "138",
        "Weeks of Supply": "6",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "3",
        "Lead Time St dev": "3",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.94",
        "Store Lead Time": "2",
        "Suggested Order QTY": "126"
    },
    {
        "Region": "Center",
        "Country": "US",
        "State": "NY",
        "City": "NY",
        "Channel": "Retail",
        "Cluster": "A",
        "Retailer": "Best Buy",
        "Store": "3094",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Blue 256 GB",
        "SKU": "9988899",
        "Retail Price": "1999",
        "Cost": "678",
        "Gross Margin %": "63.83734036",
        "Sales This Week": "22",
        "Avg Weekly Sales": "184",
        "Sales St Dev": "0.16",
        "Sales Graph": "",
        "Forecast": "16",
        "Total Inventory at Stores": "1800",
        "Inventory At Warehouse": "1700",
        "Total Inventory": "3500",
        "Total In-Transit Stock": "139",
        "Weeks of Supply": "6",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "2",
        "Lead Time St dev": "3",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.93",
        "Store Lead Time": "1",
        "Suggested Order QTY": "165"
    },
    {
        "Region": "North",
        "Country": "Can",
        "State": "AB",
        "City": "ED",
        "Channel": "Retail",
        "Cluster": "B",
        "Retailer": "Best Buy",
        "Store": "1451",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Pink 256GB",
        "SKU": "9988899",
        "Retail Price": "5999",
        "Cost": "1600",
        "Gross Margin %": "67.8661303",
        "Sales Graph": "",
        "Forecast": "28",
        "Total Inventory at Stores": "360",
        "Inventory At Warehouse": "640",
        "Total Inventory": "1000",
        "On Order": "200",
        "Receiving This Week": "1000",
        "Total In-Transit Stock": "360",
        "Weeks of Supply": "5",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "6",
        "Lead Time St dev": "2",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.98",
        "Store Lead Time": "1",
        "Suggested Order QTY": "0"
    },
    {
        "Region": "North",
        "Country": "US",
        "State": "NY",
        "City": "NY",
        "Channel": "WS",
        "Cluster": "C",
        "Retailer": "Walmart",
        "Store": "4501",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Graphite 256 GB",
        "SKU": "5566999",
        "Retail Price": "5999",
        "Cost": "1600",
        "Gross Margin %": "67.8661303",
        "Sales Graph": "",
        "Forecast": "27",
        "Total Inventory at Stores": "80",
        "Inventory At Warehouse": "350",
        "Total Inventory": "430",
        "On Order": "150",
        "Receiving This Week": "430",
        "Total In-Transit Stock": "80",
        "Weeks of Supply": "5",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "4",
        "Lead Time St dev": "2",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.98",
        "Store Lead Time": "2",
        "Suggested Order QTY": "0"
    },
    {
        "Region": "North",
        "Country": "US",
        "State": "NY",
        "City": "NY",
        "Channel": "WS",
        "Cluster": "A",
        "Retailer": "Walmart",
        "Store": "6382",
        "Brand": "Apple",
        "Product Group": "Smart Phone",
        "Product Family": "iphone",
        "Item Description": "iPhone 13 Pro Max",
        "SKU Description": "iPhone 13 Pro Max Silver 256 GB",
        "SKU": "5566999",
        "Retail Price": "5999",
        "Cost": "1600",
        "Gross Margin %": "67.8661303",
        "Sales Graph": "",
        "Forecast": "16",
        "Total Inventory at Stores": "120",
        "Inventory At Warehouse": "600",
        "Total Inventory": "720",
        "On Order": "200",
        "Receiving This Week": "720",
        "Total In-Transit Stock": "120",
        "Weeks of Supply": "6",
        "Supplier": "ABC",
        "MOQ": "200",
        "Avg Lead Time": "6",
        "Lead Time St dev": "3",
        "One Time Order Cost": "2",
        "Inventory Retention Cost": "5",
        "Target Fill Rate": "0.97",
        "Store Lead Time": "3",
        "Suggested Order QTY": "0"
    }
];
