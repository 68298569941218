<!-- Main Content area -->
<div class="container-fluid" style="min-height: 100vh">
  <div class="row" style="zoom: 70%">
    <div class="col-md-3 form-group">
      <label>Timeframe</label>
      <ng-multiselect-dropdown
        [settings]="dropdownSettings"
        *ngIf="monthList && monthList.length > 0"
        style="max-height: 20px !important"
        [placeholder]="'Timeframe'"
        [data]="monthList"
        type="number"
        [(ngModel)]="timeframeSelectedList"
        (onSelectAll)="filterListByMonth($event)"
        (onDeSelect)="filterListByMonth(null)"
        (onSelect)="filterListByMonth(null)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2 form-group">
      <label>Customer</label>
      <ng-multiselect-dropdown
        [settings]="dropdownSettingsSingle"
        *ngIf="customerList && customerList.length > 0"
        [placeholder]="'Customer'"
        [data]="customerList"
        [(ngModel)]="customerSelectedList"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2 form-group">
      <label>Channel</label>
      <ng-multiselect-dropdown
        [settings]="dropdownSettingsSingle"
        *ngIf="channelList && channelList.length > 0"
        [placeholder]="'Channel'"
        [data]="channelList"
        [(ngModel)]="channelSelectedList"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2 form-group">
      <mat-form-field class="example-full-width">
        <input
          type="text"
          placeholder="{{ getName() }}"
          aria-label="Number"
          matInput
          (input)="filterChanged($event.target.value)"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete  #auto="matAutocomplete">
          <mat-option disabled>Select Product</mat-option>
          <mat-tree
            [dataSource]="dataSourceDropdown"
            [treeControl]="treeControl"
          >
            <mat-tree-node
              *matTreeNodeDef="let node"
              matTreeNodeToggle
              matTreeNodePadding
            >
              <button mat-icon-button disabled></button>
              <mat-checkbox
                class="checklist-leaf-node"
                [checked]="checklistSelection.isSelected(node)"
                (change)="todoLeafItemSelectionToggle(node)"
                >{{ node.item }}</mat-checkbox
              >
            </mat-tree-node>

            <mat-tree-node
              *matTreeNodeDef="let node; when: hasChild"
              matTreeNodePadding
            >
              <button
                mat-icon-button
                matTreeNodeToggle
                [attr.aria-label]="'toggle ' + node.filename"
              >
                <mat-icon class="mat-icon-rtl-mirror">
                  {{
                    treeControl.isExpanded(node)
                      ? "expand_more"
                      : "chevron_right"
                  }}
                </mat-icon>
              </button>
              <mat-checkbox
                [checked]="descendantsAllSelected(node)"
                [indeterminate]="descendantsPartiallySelected(node)"
                (change)="todoItemSelectionToggle(node)"
              >
                {{ node.item }}</mat-checkbox
              >
            </mat-tree-node>
          </mat-tree>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-md-3 form-group">
      <label>Version</label>
      <ng-multiselect-dropdown
        [placeholder]="'Version'"
        [data]="versionList"
        [(ngModel)]="seletedVersion"
        [settings]="dropdownSettingsSingle"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
  </div>
  <mat-card-title
    style="zoom: 70%"
    [ngStyle]="{
      visibility:
        customerSelectedList.length > 0 &&
        seletedVersion &&
        checklistSelection.selected.length > 0 &&
        timeframeSelectedList.length > 0 &&
        dataSource
          ? 'visible'
          : 'collapse'
    }"
  >
    Graphic View
  </mat-card-title>
  <mat-card
    [ngStyle]="{
      visibility:
        customerSelectedList.length > 0 &&
        seletedVersion &&
        checklistSelection.selected.length > 0 &&
        timeframeSelectedList.length > 0 &&
        dataSource
          ? 'visible'
          : 'collapse'
    }"
    class="mat-card-box"
    style="border-top: 2px solid #d81ad1"
  >
    <mat-card-header> </mat-card-header>
    <mat-card-content>
      <fusioncharts
        *ngIf="dataSource"
        [width]="width"
        [height]="height"
        [type]="type"
        [dataFormat]="dataFormat"
        [dataSource]="dataSource"
      >
      </fusioncharts>
    </mat-card-content>
  </mat-card>
  <div
    [ngStyle]="{
      visibility:
        customerSelectedList.length > 0 &&
        seletedVersion &&
        checklistSelection.selected.length > 0 &&
        timeframeSelectedList.length > 0 &&
        dataSource
          ? 'visible'
          : 'collapse'
    }"
    class="action-panel text-right"
  >
    <span (click)="openDialog({}, dialog)">
      <i
        style="font-size: 42px; cursor: pointer"
        class="material-icons"
        aria-label="Column Show/Hide"
      >
        list_alt
      </i>
    </span>
    <span (click)="openDialog({}, dialogGraphFields)">
      <i
        style="font-size: 42px; cursor: pointer"
        class="material-icons"
        aria-label="Column Show/Hide"
      >
        trending_up
      </i>
    </span>
    <span (click)="openExcelImportDialog({})">
      <i
        style="font-size: 42px; cursor: pointer"
        class="material-icons"
        aria-label="Column Show/Hide"
      >
        import_export
      </i>
    </span>
    <span
      (click)="openFullScreen('forcast-grid')"
      matTooltip="Full Screen"
      matTooltipPosition="above"
    >
      <i
        style="font-size: 42px; cursor: pointer"
        class="material-icons"
        aria-label="Full Screen"
      >
        fullscreen
      </i>
    </span>
    <span>
      <button
        mat-raised-button
        class="btn btn-primary"
        [matMenuTriggerFor]="menu"
      >
        Save
      </button>
      <mat-menu #menu="matMenu" class="user-logout">
        <mat-menu></mat-menu>
        <!-- <a mat-menu-item>Setting & Administration</a>
          <a mat-menu-item>View Profile</a> -->
        <a style="color: #fff" mat-menu-item>Save</a>
        <a style="color: #fff" mat-menu-item>Save as</a>
        <a style="color: #fff" mat-menu-item>Commit</a>
      </mat-menu>
    </span>
  </div>

  <div
    style="zoom: 70%"
    *ngIf="
      customerSelectedList.length > 0 &&
      seletedVersion &&
      checklistSelection.selected.length > 0 &&
      timeframeSelectedList.length > 0
    "
    [ngStyle]="{
      visibility:
        customerSelectedList.length > 0 &&
        seletedVersion &&
        checklistSelection.selected.length > 0 &&
        timeframeSelectedList.length > 0
          ? 'visible'
          : 'collapse'
    }"
  >
    <table id="forcast-grid" class="table">
      <!-- Data Label Start -->
      <td style="padding: 0px !important">
        <tr class="td-padding-none">
          <td style="text-align: left" align="left">
            <tr style="text-align: center; font-size: 20px">
              <label>{{ getName() }}</label>
            </tr>
            <tr>
              <td>
                <tr>
                  <label>&nbsp;</label>
                </tr>
                <tr>
                  <td>
                    <tr>
                      <label>&nbsp;</label>
                    </tr>
                    <tr>
                      <td>
                        <tr>
                          <label>&nbsp;</label>
                        </tr>
                        <tr>
                          <td>
                            <tr style="height: 34px !important">
                              <label style="height: 35px !important"
                                >&nbsp;</label
                              >
                            </tr>
                            <ng-container *ngFor="let label of leftGroupList">
                              <tr
                                [attr.rowspan]="label.rowspan"
                                style="
                                  text-align: center;
                                  align-items: center;
                                  display: flex;
                                  font-size: large;
                                "
                                [ngStyle]="{
                                  background: label.color,
                                  height: label.rowspan * 34.29 + 'px'
                                }"
                              >
                                <label
                                  style="
                                    min-width: 150px;
                                    max-width: 150px;
                                    height: 34px;
                                    text-align: center;
                                  "
                                  >{{ label.name }}</label
                                >
                              </tr>
                              <tr style="height: 34px !important">
                                <label style="height: 35px !important"
                                  >&nbsp;</label
                                >
                              </tr>
                            </ng-container>
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </td>
                </tr>
              </td>
            </tr>
          </td>
        </tr>
      </td>
      <!-- Data Label End -->
      <td style="padding: 0px !important">
        <tr class="td-padding">
          <td style="text-align: left" align="left">
            <tr>
              <label>&nbsp;</label>
            </tr>
            <tr>
              <td>
                <tr>
                  <label>&nbsp;</label>
                </tr>
                <tr>
                  <td>
                    <tr>
                      <label>&nbsp;</label>
                    </tr>
                    <tr>
                      <td>
                        <tr>
                          <label>&nbsp;</label>
                        </tr>
                        <tr>
                          <td>
                            <tr style="height: 34px !important">
                              <label style="height: 35px !important"
                                >&nbsp;</label
                              >
                            </tr>
                            <ng-container
                              *ngFor="let label of chooserList; let i = index"
                            >
                              <tr
                                [ngStyle]="{ background: label.background }"
                                *ngIf="label.checked"
                                style="height: 34px"
                              >
                                <label
                                  *ngIf="!label.isDropdown"
                                  style="min-width: 320px; max-width: 320px"
                                  >{{ label.name }}</label
                                >
                                <mat-select
                                  [ngModel]="label.key"
                                  (ngModelChange)="filterListByMethod($event)"
                                  [ngModelOptions]="{ standalone: true }"
                                  style="height: 34px"
                                  style="min-width: 300px; max-width: 300px"
                                  *ngIf="label.isDropdown"
                                >
                                  <mat-option
                                    class="not-empty-select"
                                    *ngFor="
                                      let res of methodList;
                                      let i = index
                                    "
                                    [value]="res.key"
                                  >
                                    {{ res.name }}
                                  </mat-option>
                                </mat-select>
                              </tr>
                              <tr
                                *ngIf="checkIfToShow(i)"
                                style="height: 34px !important"
                              >
                                <label [id]="i" style="height: 35px !important"
                                  >&nbsp;</label
                                >
                              </tr>
                            </ng-container>
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </td>
                </tr>
              </td>
            </tr>
          </td>
        </tr>
      </td>
      <!-- Data Table Start-->
      <td
        style="overflow-x: scroll; width: 100%; padding: 0px !important"
        [ngStyle]="{ 'max-width': getWidth() }"
      >
        <tr class="td-padding">
          <td
            *ngFor="let year of yearList"
            style="text-align: center"
            align="center"
          >
            <tr>
              <label
                >{{ year.title
                }}<i
                  (click)="collapseYear(year)"
                  style="font-size: 15px"
                  class="material-icons"
                  >remove_red_eye</i
                ></label
              >
            </tr>
            <tr>
              <td
                [class.d-none]="year.show"
                *ngFor="let half of year.halfList"
                style="text-align: center"
                align="center"
              >
                <tr>
                  <label
                    >{{ half.title
                    }}<i
                      (click)="collapseHalf(half)"
                      style="font-size: 15px"
                      class="material-icons"
                      >remove_red_eye</i
                    ></label
                  >
                </tr>
                <tr>
                  <td
                    [class.d-none]="year.show || half.show"
                    *ngFor="let quarter of half.quarterList"
                    style="text-align: center"
                  >
                    <tr>
                      <label
                        >{{ quarter.title
                        }}<i
                          (click)="collapseQuarter(quarter)"
                          style="font-size: 15px"
                          class="material-icons"
                          >remove_red_eye</i
                        ></label
                      >
                    </tr>
                    <tr>
                      <td
                        [class.d-none]="year.show || half.show || quarter.show"
                        *ngFor="let month of quarter.monthList"
                        style="text-align: center"
                      >
                        <tr>
                          <label
                            >{{ month.title
                            }}<i
                              (click)="collapseMonth(month)"
                              style="font-size: 15px"
                              class="material-icons"
                              >remove_red_eye</i
                            ></label
                          >
                        </tr>
                        <tr>
                          <ng-container
                            [ngTemplateOutlet]="
                              !year.show &&
                              !half.show &&
                              !quarter.show &&
                              !month.show
                                ? monthColumn
                                : otherColumn
                            "
                            [ngTemplateOutletContext]="{
                              week: getObject(year, month, quarter, {})
                            }"
                          >
                          </ng-container>
                          <ng-template #monthColumn>
                            <td
                              class="input-fields"
                              *ngFor="
                                let week of month.weekList;
                                let monthIndex = index
                              "
                              style="text-align: center"
                            >
                              <tr style="background: #fff">
                                <label style="height: 35px !important">{{
                                  week.title
                                }}</label>
                              </tr>
                              <!-- ForecastMethod -->
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('Linear')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.ForecastMethod.Linear"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="Linear"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('Curve')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.ForecastMethod.Curve"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="Curve"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('Exponential')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.ForecastMethod.Exponential"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="Exponential"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('Logarithmic')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.ForecastMethod.Logarithmic"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="Logarithmic"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('Power')"
                              >
                                <input
                                  [ngModel]="
                                    week.ForecastMethod.Power | percent
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="Power"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('Parabola')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="week.ForecastMethod.Parabola"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="Parabola"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('MovingAverage')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethod.MovingAverage
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="MovingAverage"
                                />
                              </tr>
                              <!-- ForecastMethod -->

                              <!-- ForecastMethod -->
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('SingleExpotentialSmoothing')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethod
                                      .SingleExpotentialSmoothing
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="SingleExpotentialSmoothing"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('AdaptiveSmoothing')"
                              >
                                <input
                                  [disabled]="true"
                                  type="number"
                                  [(ngModel)]="
                                    week.ForecastMethod.AdaptiveSmoothing
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  (change)="DPManualFcstAdjustmentUpdated(week)"
                                  id="AdaptiveSmoothing"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('HoltsLinearTrend')"
                              >
                                <input
                                  [disabled]="true"
                                  type="number"
                                  (change)="DPPromoUnitsUpdated(week)"
                                  [(ngModel)]="
                                    week.ForecastMethod.HoltsLinearTrend
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="HoltsLinearTrend"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('PeriodPercentageChange')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethod.PeriodPercentageChange
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="PeriodPercentageChange"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('SeasonalSmoothingMethod')"
                              >
                                <input
                                  [disabled]="true"
                                  type="text"
                                  [(ngModel)]="
                                    week.ForecastMethod.SeasonalSmoothingMethod
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="SeasonalSmoothingMethod"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('SeasonalSmoothingAverage')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethod.SeasonalSmoothingAverage
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="SeasonalSmoothingAverage"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('SeasonalPercentageChange')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethod.SeasonalPercentageChange
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="SeasonalPercentageChange"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('AdditiveDecomposition')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethod.AdditiveDecomposition
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AdditiveDecomposition"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('WintersAdditive')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethod.WintersAdditive
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="WintersAdditive"
                                />
                              </tr>

                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('CurrentAlgoBaselineSTForecast')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethod
                                      .CurrentAlgoBaselineSTForecast
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="CurrentAlgoBaselineSTForecast"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#fff' }"
                                *ngIf="checkIfInList('DPBaselineSTFcst')"
                              >
                                <input
                                  [disabled]="false"
                                  type="number"
                                  (change)="updateDPBaseLineValues(week)"
                                  [(ngModel)]="
                                    week.ForecastMethod.DPBaselineSTFcst
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="DPBaselineSTFcst"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#fff' }"
                                *ngIf="checkIfInList('COBaselineSTForecast')"
                              >
                                <input
                                  [disabled]="false"
                                  type="number"
                                  (change)="updateCOBaseLineValues(week)"
                                  [(ngModel)]="
                                    week.ForecastMethod.COBaselineSTForecast
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="COBaselineSTForecast"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('ActualSales')"
                              >
                                <input
                                  [disabled]="true"
                                  type="number"
                                  [(ngModel)]="week.ForecastMethod.ActualSales"
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ActualSales"
                                />
                              </tr>
                              <tr>
                                <label>&nbsp;</label>
                              </tr>

                              <!-- ForecastMethod -->
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('LinearvartoActual')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .LinearvartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="LinearvartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('CurvevartoActual')"
                              >
                                <input
                                  [disabled]="true"
                                  type="text"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .CurvevartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="CurvevartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('ExponentialvartoActual')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .ExponentialvartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ExponentialvartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('LogarithmicvartoActual')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .LogarithmicvartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="LogarithmicvartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('PowervartoActual')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .PowervartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="PowervartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('ParabolavartoActual')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .ParabolavartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ParabolavartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('MovingAveragevartoActual')
                                "
                              >
                                <input
                                  [disabled]="true"
                                  type="number"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .MovingAveragevartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="MovingAveragevartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'SingleExpotentialSmoothingvartoActual'
                                  )
                                "
                              >
                                <input
                                  [disabled]="true"
                                  type="number"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .SingleExpotentialSmoothingvartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="SingleExpotentialSmoothingvartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('AdaptiveSmoothingvartoActual')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .AdaptiveSmoothingvartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AdaptiveSmoothingvartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('HoltsLinearTrendvartoActual')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .HoltsLinearTrendvartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="HoltsLinearTrendvartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'PeriodPercentageChangevartoActual'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .PeriodPercentageChangevartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="PeriodPercentageChangevartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'SeasonalSmoothingMethodvartoActual'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .SeasonalSmoothingMethodvartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="SeasonalSmoothingMethodvartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'SeasonalSmoothingAveragevartoActual'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .SeasonalSmoothingAveragevartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="SeasonalSmoothingAveragevartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'SeasonalPercentageChangevartoActual'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .SeasonalPercentageChangevartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="SeasonalPercentageChangevartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'AdditiveDecompositionvartoActual'
                                  )
                                "
                              >
                                <input
                                  type="text"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .AdditiveDecompositionvartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AdditiveDecompositionvartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('WintersAdditivevartoActual')
                                "
                              >
                                <input
                                  [disabled]="true"
                                  type="text"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .WintersAdditivevartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="WintersAdditivevartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('AlgoBaselineSTvartoActual')
                                "
                              >
                                <input
                                  [disabled]="true"
                                  type="number"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .AlgoBaselineSTvartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AlgoBaselineSTvartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('DPBaselineSTvartoActual')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .DPBaselineSTvartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="DPBaselineSTvartoActual"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('COBaselineSTvartoActual')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodVarianceActual
                                      .COBaselineSTvartoActual
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="COBaselineSTvartoActual"
                                />
                              </tr>
                              <tr>
                                <label>&nbsp;</label>
                              </tr>

                              <!-- ForecastMethodUnitVarianceActual -->
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('LinearvartoActualUnits')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .LinearvartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="LinearvartoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('CurvevartoActualUnits')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .CurvevartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="CurvevartoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('ExponentialvartoActualUnits')
                                "
                              >
                                <input
                                  type="text"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .ExponentialvartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ExponentialvartoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('LogarithmicvartoActualUnits')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .LogarithmicvartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="LogarithmicvartoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="checkIfInList('PowervartoActualUnits')"
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .PowervartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="PowervartoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('ParabolavartoActualUnits')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .ParabolavartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="ParabolavartoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('MovingAveragevartoActualUnits')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .MovingAveragevartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="MovingAveragevartoActualUnits"
                                />
                              </tr>

                              <!-- ForecastMethodUnitVarianceActual -->

                              <!-- ForecastMethodUnitVarianceActual -->
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'SingleExpotentialSmoothingvartoActualUnits'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .SingleExpotentialSmoothingvartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="SingleExpotentialSmoothingvartoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'AdaptiveSmoothingvartoActualUnits'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .AdaptiveSmoothingvartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AdaptiveSmoothingvartoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'HoltsLinearTrendvartoActualUnits'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .HoltsLinearTrendvartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="HoltsLinearTrendvartoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'PeriodPercentageChangevartoActualUnits'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .PeriodPercentageChangevartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="PeriodPercentageChangevartoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'SeasonalSmoothingMethodvartoActualUnits'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .SeasonalSmoothingMethodvartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="SeasonalSmoothingMethodvartoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'SeasonalSmoothingAveragevartoActualUnits'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .SeasonalSmoothingAveragevartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="SeasonalSmoothingAveragevartoActualUnits"
                                />
                              </tr>

                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'SeasonalPercentageChangevartoActualUnits'
                                  )
                                "
                              >
                                <input
                                  [disabled]="true"
                                  type="number"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .SeasonalPercentageChangevartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="SeasonalPercentageChangevartoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'AdditiveDecompositionvartoActualUnits'
                                  )
                                "
                              >
                                <input
                                  [disabled]="true"
                                  type="number"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .AdditiveDecompositionvartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AdditiveDecompositionvartoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList(
                                    'WintersAdditivevartoActualUnits'
                                  )
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .WintersAdditivevartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="WintersAdditivevartoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('AlgoBaselineSTtoActualUnits')
                                "
                              >
                                <input
                                  type="number"
                                  [disabled]="true"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .AlgoBaselineSTtoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="AlgoBaselineSTtoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('DPBaselineSTtoActualUnits')
                                "
                              >
                                <input
                                  [disabled]="true"
                                  type="number"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .DPBaselineSTtoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="DPBaselineSTtoActualUnits"
                                />
                              </tr>
                              <tr
                                [ngStyle]="{ background: '#eee' }"
                                *ngIf="
                                  checkIfInList('COBaselineSTvartoActualUnits')
                                "
                              >
                                <input
                                  [disabled]="true"
                                  type="number"
                                  [(ngModel)]="
                                    week.ForecastMethodUnitVarianceActual
                                      .COBaselineSTvartoActualUnits
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                  id="COBaselineSTvartoActualUnits"
                                />
                              </tr>
                              <tr>
                                <label>&nbsp;</label>
                              </tr>

                              <!-- ForecastMethodUnitVarianceActual -->
                            </td>
                          </ng-template>
                        </tr>
                      </td>
                      <ng-container
                        [ngTemplateOutlet]="
                          !year.show && !half.show && !quarter.show
                            ? null
                            : quarterColumn
                        "
                        [ngTemplateOutletContext]="{
                          week: getObject(year, {}, quarter, {})
                        }"
                      >
                      </ng-container>
                    </tr>
                  </td>
                  <ng-container
                    [ngTemplateOutlet]="
                      !year.show && !half.show ? null : halfColumn
                    "
                    [ngTemplateOutletContext]="{
                      week: getObject(year, {}, {}, half)
                    }"
                  >
                  </ng-container>
                </tr>
              </td>
              <ng-container
                [ngTemplateOutlet]="!year.show ? null : yearColumn"
                [ngTemplateOutletContext]="{
                  week: getObject(year, {}, {}, {})
                }"
              >
              </ng-container>
            </tr>
          </td>
        </tr>
      </td>
      <!-- Data Table End-->
    </table>
  </div>
</div>
<ng-template #dialog let-data>
  <h1 mat-dialog-title>Show/Hide Measures</h1>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <mat-checkbox
      maxlength="20"
      type="number"
      [(ngModel)]="selectAll"
      (ngModelChange)="selectAllSelected($event)"
    >
      Select/Remove All
    </mat-checkbox>
    <button
      mat-raised-button
      class="btn btn-primary m-left"
      color="primary"
      (click)="onSave()"
    >
      Save & Close
    </button>
  </div>
  <div mat-dialog-content>
    <div style="display: flex; flex-direction: column">
      <ng-container *ngFor="let item of chooserList || []">
        <ng-container>
          <mat-checkbox maxlength="20" type="number" [(ngModel)]="item.checked">
            {{ item.name }}
          </mat-checkbox>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #dialogGraphFields let-data>
  <h1 mat-dialog-title>Show/Hide Measures</h1>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <mat-checkbox
      maxlength="20"
      type="number"
      [(ngModel)]="selectAll"
      (ngModelChange)="selectAllGraphSelected($event)"
    >
      Select/Remove All
    </mat-checkbox>
    <button
      mat-raised-button
      class="btn btn-primary m-left"
      color="primary"
      (click)="onSave()"
    >
      Save & Close
    </button>
  </div>
  <div mat-dialog-content>
    <div style="display: flex; flex-direction: column">
      <ng-container *ngFor="let item of graphChooserList || []">
        <ng-container>
          <mat-checkbox maxlength="20" type="number" [(ngModel)]="item.checked">
            {{ item.name }}
          </mat-checkbox>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #ExcelImport let-data>
  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <h1 mat-dialog-title>Import/Export</h1>
      <button
        mat-raised-button
        class="btn btn-primary"
        color="primary"
        (click)="exportToExcel()"
      >
        Export to Excel
      </button>
    </div>
    <div>
      <ngx-dropzone (change)="onSelect($event)">
        <ngx-dropzone-label>Drop files here!</ngx-dropzone-label>
        <ngx-dropzone-preview
          *ngFor="let f of files"
          [removable]="true"
          (removed)="onRemove(f)"
        >
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>
    <!-- <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
      </ngx-dropzone-image-preview> -->
  </div>
</ng-template>
<!-- Month Cummulated Column Start-->
<ng-template #otherColumn let-week="week">
  <td class="input-fields" style="text-align: center">
    <tr>
      <label style="height: 35px !important">&nbsp;</label>
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Linear')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Linear"
        [ngModelOptions]="{ standalone: true }"
        id="Linear"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Curve')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Curve"
        [ngModelOptions]="{ standalone: true }"
        id="Curve"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Exponential')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Exponential"
        [ngModelOptions]="{ standalone: true }"
        id="Exponential"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Logarithmic')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Logarithmic"
        [ngModelOptions]="{ standalone: true }"
        id="Logarithmic"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Power')">
      <input
        [ngModel]="week.ForecastMethod.Power | percent"
        [ngModelOptions]="{ standalone: true }"
        id="Power"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Parabola')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Parabola"
        [ngModelOptions]="{ standalone: true }"
        id="Parabola"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('MovingAverage')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.MovingAverage"
        [ngModelOptions]="{ standalone: true }"
        id="MovingAverage"
      />
    </tr>
    <!-- ForecastMethod -->

    <!-- ForecastMethod -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SingleExpotentialSmoothing')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.SingleExpotentialSmoothing"
        [ngModelOptions]="{ standalone: true }"
        id="SingleExpotentialSmoothing"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdaptiveSmoothing')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="week.ForecastMethod.AdaptiveSmoothing"
        [ngModelOptions]="{ standalone: true }"
        (change)="DPManualFcstAdjustmentUpdated(week)"
        id="AdaptiveSmoothing"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('HoltsLinearTrend')"
    >
      <input
        [disabled]="true"
        type="number"
        (change)="DPPromoUnitsUpdated(week)"
        [(ngModel)]="week.ForecastMethod.HoltsLinearTrend"
        [ngModelOptions]="{ standalone: true }"
        id="HoltsLinearTrend"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PeriodPercentageChange')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.PeriodPercentageChange"
        [ngModelOptions]="{ standalone: true }"
        id="PeriodPercentageChange"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingMethod')"
    >
      <input
        [disabled]="true"
        type="text"
        [(ngModel)]="week.ForecastMethod.SeasonalSmoothingMethod"
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingMethod"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingAverage')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.SeasonalSmoothingAverage"
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingAverage"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalPercentageChange')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.SeasonalPercentageChange"
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalPercentageChange"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdditiveDecomposition')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.AdditiveDecomposition"
        [ngModelOptions]="{ standalone: true }"
        id="AdditiveDecomposition"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('WintersAdditive')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.WintersAdditive"
        [ngModelOptions]="{ standalone: true }"
        id="WintersAdditive"
      />
    </tr>

    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('CurrentAlgoBaselineSTForecast')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.CurrentAlgoBaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="CurrentAlgoBaselineSTForecast"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#fff' }"
      *ngIf="checkIfInList('DPBaselineSTFcst')"
    >
      <input
        [disabled]="false"
        type="number"
        (change)="updateDPBaseLineValues(week)"
        [(ngModel)]="week.ForecastMethod.DPBaselineSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTFcst"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#fff' }"
      *ngIf="checkIfInList('COBaselineSTForecast')"
    >
      <input
        [disabled]="false"
        type="number"
        (change)="updateCOBaseLineValues(week)"
        [(ngModel)]="week.ForecastMethod.COBaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTForecast"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('ActualSales')">
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="week.ForecastMethod.ActualSales"
        [ngModelOptions]="{ standalone: true }"
        id="ActualSales"
      />
    </tr>
    <tr>
      <label>&nbsp;</label>
    </tr>

    <!-- ForecastMethod -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LinearvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.LinearvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="LinearvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('CurvevartoActual')"
    >
      <input
        [disabled]="true"
        type="text"
        [(ngModel)]="week.ForecastMethodVarianceActual.CurvevartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="CurvevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ExponentialvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.ExponentialvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="ExponentialvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LogarithmicvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.LogarithmicvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="LogarithmicvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PowervartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.PowervartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="PowervartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ParabolavartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.ParabolavartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="ParabolavartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('MovingAveragevartoActual')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="week.ForecastMethodVarianceActual.MovingAveragevartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="MovingAveragevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SingleExpotentialSmoothingvartoActual')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodVarianceActual
            .SingleExpotentialSmoothingvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SingleExpotentialSmoothingvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdaptiveSmoothingvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.AdaptiveSmoothingvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdaptiveSmoothingvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('HoltsLinearTrendvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.HoltsLinearTrendvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="HoltsLinearTrendvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PeriodPercentageChangevartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.PeriodPercentageChangevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="PeriodPercentageChangevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingMethodvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.SeasonalSmoothingMethodvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingMethodvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingAveragevartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.SeasonalSmoothingAveragevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingAveragevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalPercentageChangevartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.SeasonalPercentageChangevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalPercentageChangevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdditiveDecompositionvartoActual')"
    >
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.AdditiveDecompositionvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdditiveDecompositionvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('WintersAdditivevartoActual')"
    >
      <input
        [disabled]="true"
        type="text"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.WintersAdditivevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="WintersAdditivevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AlgoBaselineSTvartoActual')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.AlgoBaselineSTvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="AlgoBaselineSTvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('DPBaselineSTvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.DPBaselineSTvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('COBaselineSTvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.COBaselineSTvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTvartoActual"
      />
    </tr>
    <tr>
      <label>&nbsp;</label>
    </tr>

    <!-- ForecastMethodUnitVarianceActual -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LinearvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.LinearvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="LinearvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('CurvevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.CurvevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="CurvevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ExponentialvartoActualUnits')"
    >
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.ExponentialvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="ExponentialvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LogarithmicvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.LogarithmicvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="LogarithmicvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PowervartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.PowervartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="PowervartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ParabolavartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.ParabolavartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="ParabolavartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('MovingAveragevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.MovingAveragevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="MovingAveragevartoActualUnits"
      />
    </tr>

    <!-- ForecastMethodUnitVarianceActual -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SingleExpotentialSmoothingvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SingleExpotentialSmoothingvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SingleExpotentialSmoothingvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdaptiveSmoothingvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .AdaptiveSmoothingvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdaptiveSmoothingvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('HoltsLinearTrendvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.HoltsLinearTrendvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="HoltsLinearTrendvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PeriodPercentageChangevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .PeriodPercentageChangevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="PeriodPercentageChangevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingMethodvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SeasonalSmoothingMethodvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingMethodvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingAveragevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SeasonalSmoothingAveragevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingAveragevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalPercentageChangevartoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SeasonalPercentageChangevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalPercentageChangevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdditiveDecompositionvartoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .AdditiveDecompositionvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdditiveDecompositionvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('WintersAdditivevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.WintersAdditivevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="WintersAdditivevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AlgoBaselineSTtoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.AlgoBaselineSTtoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="AlgoBaselineSTtoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('DPBaselineSTtoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.DPBaselineSTtoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTtoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('COBaselineSTvartoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.COBaselineSTvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTvartoActualUnits"
      />
    </tr>
    <tr>
      <label>&nbsp;</label>
    </tr>

    <!-- ForecastMethodUnitVarianceActual -->
  </td>
</ng-template>
<!-- Month Cummulated Column End -->

<!-- Quarter Cummulated Column Start -->

<ng-template #quarterColumn let-week="week">
  <td class="input-fields" style="text-align: center">
    <tr style="height: 30px !important">
      <label style="height: 30px !important">&nbsp;</label>
    </tr>
    <tr style="height: 29px !important">
      <label style="height: 29px !important">&nbsp;</label>
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Linear')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Linear"
        [ngModelOptions]="{ standalone: true }"
        id="Linear"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Curve')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Curve"
        [ngModelOptions]="{ standalone: true }"
        id="Curve"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Exponential')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Exponential"
        [ngModelOptions]="{ standalone: true }"
        id="Exponential"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Logarithmic')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Logarithmic"
        [ngModelOptions]="{ standalone: true }"
        id="Logarithmic"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Power')">
      <input
        [ngModel]="week.ForecastMethod.Power | percent"
        [ngModelOptions]="{ standalone: true }"
        id="Power"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Parabola')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Parabola"
        [ngModelOptions]="{ standalone: true }"
        id="Parabola"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('MovingAverage')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.MovingAverage"
        [ngModelOptions]="{ standalone: true }"
        id="MovingAverage"
      />
    </tr>
    <!-- ForecastMethod -->

    <!-- ForecastMethod -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SingleExpotentialSmoothing')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.SingleExpotentialSmoothing"
        [ngModelOptions]="{ standalone: true }"
        id="SingleExpotentialSmoothing"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdaptiveSmoothing')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="week.ForecastMethod.AdaptiveSmoothing"
        [ngModelOptions]="{ standalone: true }"
        (change)="DPManualFcstAdjustmentUpdated(week)"
        id="AdaptiveSmoothing"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('HoltsLinearTrend')"
    >
      <input
        [disabled]="true"
        type="number"
        (change)="DPPromoUnitsUpdated(week)"
        [(ngModel)]="week.ForecastMethod.HoltsLinearTrend"
        [ngModelOptions]="{ standalone: true }"
        id="HoltsLinearTrend"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PeriodPercentageChange')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.PeriodPercentageChange"
        [ngModelOptions]="{ standalone: true }"
        id="PeriodPercentageChange"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingMethod')"
    >
      <input
        [disabled]="true"
        type="text"
        [(ngModel)]="week.ForecastMethod.SeasonalSmoothingMethod"
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingMethod"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingAverage')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.SeasonalSmoothingAverage"
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingAverage"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalPercentageChange')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.SeasonalPercentageChange"
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalPercentageChange"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdditiveDecomposition')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.AdditiveDecomposition"
        [ngModelOptions]="{ standalone: true }"
        id="AdditiveDecomposition"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('WintersAdditive')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.WintersAdditive"
        [ngModelOptions]="{ standalone: true }"
        id="WintersAdditive"
      />
    </tr>

    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('CurrentAlgoBaselineSTForecast')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.CurrentAlgoBaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="CurrentAlgoBaselineSTForecast"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#fff' }"
      *ngIf="checkIfInList('DPBaselineSTFcst')"
    >
      <input
        [disabled]="false"
        type="number"
        (change)="updateDPBaseLineValues(week)"
        [(ngModel)]="week.ForecastMethod.DPBaselineSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTFcst"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#fff' }"
      *ngIf="checkIfInList('COBaselineSTForecast')"
    >
      <input
        [disabled]="false"
        type="number"
        (change)="updateCOBaseLineValues(week)"
        [(ngModel)]="week.ForecastMethod.COBaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTForecast"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('ActualSales')">
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="week.ForecastMethod.ActualSales"
        [ngModelOptions]="{ standalone: true }"
        id="ActualSales"
      />
    </tr>
    <tr>
      <label>&nbsp;</label>
    </tr>

    <!-- ForecastMethod -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LinearvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.LinearvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="LinearvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('CurvevartoActual')"
    >
      <input
        [disabled]="true"
        type="text"
        [(ngModel)]="week.ForecastMethodVarianceActual.CurvevartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="CurvevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ExponentialvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.ExponentialvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="ExponentialvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LogarithmicvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.LogarithmicvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="LogarithmicvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PowervartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.PowervartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="PowervartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ParabolavartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.ParabolavartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="ParabolavartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('MovingAveragevartoActual')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="week.ForecastMethodVarianceActual.MovingAveragevartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="MovingAveragevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SingleExpotentialSmoothingvartoActual')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodVarianceActual
            .SingleExpotentialSmoothingvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SingleExpotentialSmoothingvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdaptiveSmoothingvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.AdaptiveSmoothingvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdaptiveSmoothingvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('HoltsLinearTrendvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.HoltsLinearTrendvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="HoltsLinearTrendvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PeriodPercentageChangevartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.PeriodPercentageChangevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="PeriodPercentageChangevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingMethodvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.SeasonalSmoothingMethodvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingMethodvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingAveragevartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.SeasonalSmoothingAveragevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingAveragevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalPercentageChangevartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.SeasonalPercentageChangevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalPercentageChangevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdditiveDecompositionvartoActual')"
    >
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.AdditiveDecompositionvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdditiveDecompositionvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('WintersAdditivevartoActual')"
    >
      <input
        [disabled]="true"
        type="text"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.WintersAdditivevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="WintersAdditivevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AlgoBaselineSTvartoActual')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.AlgoBaselineSTvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="AlgoBaselineSTvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('DPBaselineSTvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.DPBaselineSTvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('COBaselineSTvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.COBaselineSTvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTvartoActual"
      />
    </tr>
    <tr>
      <label>&nbsp;</label>
    </tr>

    <!-- ForecastMethodUnitVarianceActual -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LinearvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.LinearvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="LinearvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('CurvevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.CurvevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="CurvevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ExponentialvartoActualUnits')"
    >
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.ExponentialvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="ExponentialvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LogarithmicvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.LogarithmicvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="LogarithmicvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PowervartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.PowervartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="PowervartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ParabolavartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.ParabolavartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="ParabolavartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('MovingAveragevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.MovingAveragevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="MovingAveragevartoActualUnits"
      />
    </tr>
    <!-- ForecastMethodUnitVarianceActual -->

    <!-- ForecastMethodUnitVarianceActual -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SingleExpotentialSmoothingvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SingleExpotentialSmoothingvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SingleExpotentialSmoothingvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdaptiveSmoothingvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .AdaptiveSmoothingvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdaptiveSmoothingvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('HoltsLinearTrendvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.HoltsLinearTrendvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="HoltsLinearTrendvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PeriodPercentageChangevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .PeriodPercentageChangevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="PeriodPercentageChangevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingMethodvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SeasonalSmoothingMethodvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingMethodvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingAveragevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SeasonalSmoothingAveragevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingAveragevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalPercentageChangevartoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SeasonalPercentageChangevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalPercentageChangevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdditiveDecompositionvartoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .AdditiveDecompositionvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdditiveDecompositionvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('WintersAdditivevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.WintersAdditivevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="WintersAdditivevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AlgoBaselineSTtoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.AlgoBaselineSTtoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="AlgoBaselineSTtoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('DPBaselineSTtoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.DPBaselineSTtoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTtoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('COBaselineSTvartoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.COBaselineSTvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTvartoActualUnits"
      />
    </tr>
    <tr>
      <label>&nbsp;</label>
    </tr>

    <!-- ForecastMethodUnitVarianceActual -->
  </td>
</ng-template>
<!-- Quarter Cummulated Column End -->

<!-- Half Cummulated Column Start -->
<ng-template #halfColumn let-week="week">
  <td class="input-fields" style="text-align: center">
    <tr style="height: 27px !important">
      <label style="height: 27px !important">&nbsp;</label>
    </tr>
    <tr style="height: 27px !important">
      <label style="height: 27px !important">&nbsp;</label>
    </tr>
    <tr style="height: 28px !important">
      <label style="height: 28px !important">&nbsp;</label>
    </tr>
    <!-- ForecastMethod -->
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Linear')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Linear"
        [ngModelOptions]="{ standalone: true }"
        id="Linear"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Curve')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Curve"
        [ngModelOptions]="{ standalone: true }"
        id="Curve"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Exponential')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Exponential"
        [ngModelOptions]="{ standalone: true }"
        id="Exponential"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Logarithmic')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Logarithmic"
        [ngModelOptions]="{ standalone: true }"
        id="Logarithmic"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Power')">
      <input
        [ngModel]="week.ForecastMethod.Power | percent"
        [ngModelOptions]="{ standalone: true }"
        id="Power"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Parabola')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Parabola"
        [ngModelOptions]="{ standalone: true }"
        id="Parabola"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('MovingAverage')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.MovingAverage"
        [ngModelOptions]="{ standalone: true }"
        id="MovingAverage"
      />
    </tr>
    <!-- ForecastMethod -->

    <!-- ForecastMethod -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SingleExpotentialSmoothing')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.SingleExpotentialSmoothing"
        [ngModelOptions]="{ standalone: true }"
        id="SingleExpotentialSmoothing"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdaptiveSmoothing')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="week.ForecastMethod.AdaptiveSmoothing"
        [ngModelOptions]="{ standalone: true }"
        (change)="DPManualFcstAdjustmentUpdated(week)"
        id="AdaptiveSmoothing"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('HoltsLinearTrend')"
    >
      <input
        [disabled]="true"
        type="number"
        (change)="DPPromoUnitsUpdated(week)"
        [(ngModel)]="week.ForecastMethod.HoltsLinearTrend"
        [ngModelOptions]="{ standalone: true }"
        id="HoltsLinearTrend"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PeriodPercentageChange')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.PeriodPercentageChange"
        [ngModelOptions]="{ standalone: true }"
        id="PeriodPercentageChange"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingMethod')"
    >
      <input
        [disabled]="true"
        type="text"
        [(ngModel)]="week.ForecastMethod.SeasonalSmoothingMethod"
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingMethod"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingAverage')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.SeasonalSmoothingAverage"
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingAverage"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalPercentageChange')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.SeasonalPercentageChange"
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalPercentageChange"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdditiveDecomposition')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.AdditiveDecomposition"
        [ngModelOptions]="{ standalone: true }"
        id="AdditiveDecomposition"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('WintersAdditive')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.WintersAdditive"
        [ngModelOptions]="{ standalone: true }"
        id="WintersAdditive"
      />
    </tr>

    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('CurrentAlgoBaselineSTForecast')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.CurrentAlgoBaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="CurrentAlgoBaselineSTForecast"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#fff' }"
      *ngIf="checkIfInList('DPBaselineSTFcst')"
    >
      <input
        [disabled]="false"
        type="number"
        (change)="updateDPBaseLineValues(week)"
        [(ngModel)]="week.ForecastMethod.DPBaselineSTFcst"
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTFcst"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#fff' }"
      *ngIf="checkIfInList('COBaselineSTForecast')"
    >
      <input
        [disabled]="false"
        type="number"
        (change)="updateCOBaseLineValues(week)"
        [(ngModel)]="week.ForecastMethod.COBaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTForecast"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('ActualSales')">
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="week.ForecastMethod.ActualSales"
        [ngModelOptions]="{ standalone: true }"
        id="ActualSales"
      />
    </tr>
    <tr>
      <label>&nbsp;</label>
    </tr>

    <!-- ForecastMethod -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LinearvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.LinearvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="LinearvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('CurvevartoActual')"
    >
      <input
        [disabled]="true"
        type="text"
        [(ngModel)]="week.ForecastMethodVarianceActual.CurvevartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="CurvevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ExponentialvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.ExponentialvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="ExponentialvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LogarithmicvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.LogarithmicvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="LogarithmicvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PowervartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.PowervartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="PowervartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ParabolavartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.ParabolavartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="ParabolavartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('MovingAveragevartoActual')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="week.ForecastMethodVarianceActual.MovingAveragevartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="MovingAveragevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SingleExpotentialSmoothingvartoActual')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodVarianceActual
            .SingleExpotentialSmoothingvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SingleExpotentialSmoothingvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdaptiveSmoothingvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.AdaptiveSmoothingvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdaptiveSmoothingvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('HoltsLinearTrendvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.HoltsLinearTrendvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="HoltsLinearTrendvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PeriodPercentageChangevartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.PeriodPercentageChangevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="PeriodPercentageChangevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingMethodvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.SeasonalSmoothingMethodvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingMethodvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingAveragevartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.SeasonalSmoothingAveragevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingAveragevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalPercentageChangevartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.SeasonalPercentageChangevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalPercentageChangevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdditiveDecompositionvartoActual')"
    >
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.AdditiveDecompositionvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdditiveDecompositionvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('WintersAdditivevartoActual')"
    >
      <input
        [disabled]="true"
        type="text"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.WintersAdditivevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="WintersAdditivevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AlgoBaselineSTvartoActual')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.AlgoBaselineSTvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="AlgoBaselineSTvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('DPBaselineSTvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.DPBaselineSTvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('COBaselineSTvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.COBaselineSTvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTvartoActual"
      />
    </tr>

    <!-- ForecastMethodUnitVarianceActual -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LinearvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.LinearvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="LinearvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('CurvevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.CurvevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="CurvevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ExponentialvartoActualUnits')"
    >
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.ExponentialvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="ExponentialvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LogarithmicvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.LogarithmicvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="LogarithmicvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PowervartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.PowervartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="PowervartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ParabolavartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.ParabolavartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="ParabolavartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('MovingAveragevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.MovingAveragevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="MovingAveragevartoActualUnits"
      />
    </tr>

    <!-- ForecastMethodUnitVarianceActual -->

    <!-- ForecastMethodUnitVarianceActual -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SingleExpotentialSmoothingvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SingleExpotentialSmoothingvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SingleExpotentialSmoothingvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdaptiveSmoothingvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .AdaptiveSmoothingvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdaptiveSmoothingvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('HoltsLinearTrendvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.HoltsLinearTrendvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="HoltsLinearTrendvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PeriodPercentageChangevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .PeriodPercentageChangevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="PeriodPercentageChangevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingMethodvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SeasonalSmoothingMethodvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingMethodvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingAveragevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SeasonalSmoothingAveragevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingAveragevartoActualUnits"
      />
    </tr>
    <tr>
      <label>&nbsp;</label>
    </tr>

    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalPercentageChangevartoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SeasonalPercentageChangevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalPercentageChangevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdditiveDecompositionvartoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .AdditiveDecompositionvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdditiveDecompositionvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('WintersAdditivevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.WintersAdditivevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="WintersAdditivevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AlgoBaselineSTtoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.AlgoBaselineSTtoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="AlgoBaselineSTtoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('DPBaselineSTtoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.DPBaselineSTtoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTtoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('COBaselineSTvartoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.COBaselineSTvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTvartoActualUnits"
      />
    </tr>
    <tr>
      <label>&nbsp;</label>
    </tr>

    <!-- ForecastMethodUnitVarianceActual -->
    <!-- KPI -->
  </td>
</ng-template>
<!-- Half Cummulated Column End -->

<!-- Year Cummulated Column Start -->
<ng-template #yearColumn let-week="week">
  <td class="input-fields" style="text-align: center">
    <tr style="height: 27px !important">
      <label style="height: 27px !important">&nbsp;</label>
    </tr>
    <tr style="height: 27px !important">
      <label style="height: 27px !important">&nbsp;</label>
    </tr>
    <tr style="height: 27px !important">
      <label style="height: 27px !important">&nbsp;</label>
    </tr>
    <tr style="height: 26px !important">
      <label style="height: 26px !important">&nbsp;</label>
    </tr>
    <!-- ForecastMethod -->
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Linear')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Linear"
        [ngModelOptions]="{ standalone: true }"
        id="Linear"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Curve')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Curve"
        [ngModelOptions]="{ standalone: true }"
        id="Curve"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Exponential')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Exponential"
        [ngModelOptions]="{ standalone: true }"
        id="Exponential"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Logarithmic')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Logarithmic"
        [ngModelOptions]="{ standalone: true }"
        id="Logarithmic"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Power')">
      <input
        [ngModel]="week.ForecastMethod.Power | percent"
        [ngModelOptions]="{ standalone: true }"
        id="Power"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('Parabola')">
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.Parabola"
        [ngModelOptions]="{ standalone: true }"
        id="Parabola"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('MovingAverage')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.MovingAverage"
        [ngModelOptions]="{ standalone: true }"
        id="MovingAverage"
      />
    </tr>
    <!-- ForecastMethod -->

    <!-- ForecastMethod -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SingleExpotentialSmoothing')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.SingleExpotentialSmoothing"
        [ngModelOptions]="{ standalone: true }"
        id="SingleExpotentialSmoothing"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdaptiveSmoothing')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="week.ForecastMethod.AdaptiveSmoothing"
        [ngModelOptions]="{ standalone: true }"
        (change)="DPManualFcstAdjustmentUpdated(week)"
        id="AdaptiveSmoothing"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('HoltsLinearTrend')"
    >
      <input
        [disabled]="true"
        type="number"
        (change)="DPPromoUnitsUpdated(week)"
        [(ngModel)]="week.ForecastMethod.HoltsLinearTrend"
        [ngModelOptions]="{ standalone: true }"
        id="HoltsLinearTrend"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PeriodPercentageChange')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.PeriodPercentageChange"
        [ngModelOptions]="{ standalone: true }"
        id="PeriodPercentageChange"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingMethod')"
    >
      <input
        [disabled]="true"
        type="text"
        [(ngModel)]="week.ForecastMethod.SeasonalSmoothingMethod"
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingMethod"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingAverage')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.SeasonalSmoothingAverage"
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingAverage"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalPercentageChange')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.SeasonalPercentageChange"
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalPercentageChange"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdditiveDecomposition')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.AdditiveDecomposition"
        [ngModelOptions]="{ standalone: true }"
        id="AdditiveDecomposition"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('WintersAdditive')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.WintersAdditive"
        [ngModelOptions]="{ standalone: true }"
        id="WintersAdditive"
      />
    </tr>

    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('CurrentAlgoBaselineSTForecast')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethod.CurrentAlgoBaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="CurrentAlgoBaselineSTForecast"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#fff' }"
      *ngIf="checkIfInList('DPBaselineSTFcst')"
    >
      <input
        [disabled]="false"
        type="number"
        (change)="updateDPBaseLineValues(week)"
        [(ngModel)]="week.ForecastMethod.COBaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTForecast"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#fff' }"
      *ngIf="checkIfInList('COBaselineSTForecast')"
    >
      <input
        [disabled]="false"
        type="number"
        (change)="updateCOBaseLineValues(week)"
        [(ngModel)]="week.ForecastMethod.COBaselineSTForecast"
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTForecast"
      />
    </tr>
    <tr [ngStyle]="{ background: '#eee' }" *ngIf="checkIfInList('ActualSales')">
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="week.ForecastMethod.ActualSales"
        [ngModelOptions]="{ standalone: true }"
        id="ActualSales"
      />
    </tr>
    <tr>
      <label>&nbsp;</label>
    </tr>

    <!-- ForecastMethod -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LinearvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.LinearvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="LinearvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('CurvevartoActual')"
    >
      <input
        [disabled]="true"
        type="text"
        [(ngModel)]="week.ForecastMethodVarianceActual.CurvevartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="CurvevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ExponentialvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.ExponentialvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="ExponentialvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LogarithmicvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.LogarithmicvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="LogarithmicvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PowervartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.PowervartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="PowervartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ParabolavartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.ParabolavartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="ParabolavartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('MovingAveragevartoActual')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="week.ForecastMethodVarianceActual.MovingAveragevartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="MovingAveragevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SingleExpotentialSmoothingvartoActual')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodVarianceActual
            .SingleExpotentialSmoothingvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SingleExpotentialSmoothingvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdaptiveSmoothingvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.AdaptiveSmoothingvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdaptiveSmoothingvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('HoltsLinearTrendvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.HoltsLinearTrendvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="HoltsLinearTrendvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PeriodPercentageChangevartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.PeriodPercentageChangevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="PeriodPercentageChangevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingMethodvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.SeasonalSmoothingMethodvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingMethodvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingAveragevartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.SeasonalSmoothingAveragevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingAveragevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalPercentageChangevartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.SeasonalPercentageChangevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalPercentageChangevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdditiveDecompositionvartoActual')"
    >
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.AdditiveDecompositionvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdditiveDecompositionvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('WintersAdditivevartoActual')"
    >
      <input
        [disabled]="true"
        type="text"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.WintersAdditivevartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="WintersAdditivevartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AlgoBaselineSTvartoActual')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodVarianceActual.AlgoBaselineSTvartoActual
        "
        [ngModelOptions]="{ standalone: true }"
        id="AlgoBaselineSTvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('DPBaselineSTvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.DPBaselineSTvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTvartoActual"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('COBaselineSTvartoActual')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="week.ForecastMethodVarianceActual.COBaselineSTvartoActual"
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTvartoActual"
      />
    </tr>
    <!-- ForecastMethodUnitVarianceActual -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LinearvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.LinearvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="LinearvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('CurvevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.CurvevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="CurvevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ExponentialvartoActualUnits')"
    >
      <input
        type="text"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.ExponentialvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="ExponentialvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('LogarithmicvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.LogarithmicvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="LogarithmicvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PowervartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.PowervartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="PowervartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('ParabolavartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.ParabolavartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="ParabolavartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('MovingAveragevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.MovingAveragevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="MovingAveragevartoActualUnits"
      />
    </tr>
    <!-- ForecastMethodUnitVarianceActual -->

    <!-- ForecastMethodUnitVarianceActual -->
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SingleExpotentialSmoothingvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SingleExpotentialSmoothingvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SingleExpotentialSmoothingvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdaptiveSmoothingvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .AdaptiveSmoothingvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdaptiveSmoothingvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('HoltsLinearTrendvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.HoltsLinearTrendvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="HoltsLinearTrendvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('PeriodPercentageChangevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .PeriodPercentageChangevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="PeriodPercentageChangevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingMethodvartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SeasonalSmoothingMethodvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingMethodvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalSmoothingAveragevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SeasonalSmoothingAveragevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalSmoothingAveragevartoActualUnits"
      />
    </tr>
    <tr>
      <label>&nbsp;</label>
    </tr>

    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('SeasonalPercentageChangevartoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .SeasonalPercentageChangevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="SeasonalPercentageChangevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AdditiveDecompositionvartoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual
            .AdditiveDecompositionvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="AdditiveDecompositionvartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('WintersAdditivevartoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.WintersAdditivevartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="WintersAdditivevartoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('AlgoBaselineSTtoActualUnits')"
    >
      <input
        type="number"
        [disabled]="true"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.AlgoBaselineSTtoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="AlgoBaselineSTtoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('DPBaselineSTtoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.DPBaselineSTtoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="DPBaselineSTtoActualUnits"
      />
    </tr>
    <tr
      [ngStyle]="{ background: '#eee' }"
      *ngIf="checkIfInList('COBaselineSTvartoActualUnits')"
    >
      <input
        [disabled]="true"
        type="number"
        [(ngModel)]="
          week.ForecastMethodUnitVarianceActual.COBaselineSTvartoActualUnits
        "
        [ngModelOptions]="{ standalone: true }"
        id="COBaselineSTvartoActualUnits"
      />
    </tr>
    <tr>
      <label>&nbsp;</label>
    </tr>

    <!-- ForecastMethodUnitVarianceActual -->
  </td>
</ng-template>
<!-- Year Cummulated Column End -->
