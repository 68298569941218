import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-import-targets-dialog',
  templateUrl: './import-targets-dialog.component.html',
  styleUrls: ['./import-targets-dialog.component.scss']
})
export class ImportTargetsDialogComponent implements OnInit {

  spreadingLogic = '2';
  logics = [
    {
      id: '1',
      name: 'LY'
    },
    {
      id: '2',
      name: 'Adj. LY'
    },
    {
      id: '3',
      name: 'LLY'
    },
    {
      id: '4',
      name: 'Avg. Last 2 Years'
    },
    {
      id: '5',
      name: 'Avg. Last 3 Years'
    }
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ImportTargetsDialogComponent>) { }

  ngOnInit(): void {
  }
 
  onNoClick() {
    this.dialogRef.close(false);
  }

  done() {
    this.onNoClick();
  }

}
