import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationService } from 'src/services/configuration.service';

interface calendarObj {
  MonthId: number,
  MonthIndex: number,
  MonthName: string,
  WeekStartDate: string,
  YearName: number,
  YearId: number,
  weekId: number,
  CalendarEventDate: string,
  CalendarEventId: number,
  CalendarEventName: string,
  ColorHex: string,
  SeasonId: number,
  SeasonName: string,
  SubSeasonId: number,
  SubSeasonName: string
};

@Component({
  selector: 'retail-calendar',
  templateUrl: './retail-calendar.component.html',
  styleUrls: ['./retail-calendar.component.scss']
})
export class RetailCalendarComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() selectedDate: Date;
  @Input() disabled = false;
  @Input() events = [];
  @Output() dateChanged = new EventEmitter();

  selectedYear = (new Date()).getFullYear();
  selectedMonth = (new Date()).getMonth() + 1;
  weekDays = [];

  showCalendar = false;
  weeks = [];

  protected readonly weekDaysOriginalOrder = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  private retailCalendarData = {} as any;
  loadingData = false;
  monthsData = {};

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showCalendar = false;
    }
  }

  constructor(private eRef: ElementRef, private spinner: NgxSpinnerService, private configurationService: ConfigurationService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.fetchAndPatchCalendarData();
  }

  ngOnChanges() {
    this.fetchAndPatchCalendarData();
  }

  protected fetchAndPatchCalendarData() {
    if (this.loadingData) return;
    this.loadingData = true;
    this.spinner.show();
    this.configurationService.fetchRetailCalendarData()
      .then((res: calendarObj[]) => {
        this.retailCalendarData = {};
        res.forEach((element, index) => {
          !this.retailCalendarData[element.YearName] && (this.retailCalendarData[element.YearName] = {});
          !this.retailCalendarData[element.YearName][element.MonthId] && (this.retailCalendarData[element.YearName][element.MonthId] = {});
          if (!this.retailCalendarData[element.YearName][element.MonthId][element.WeekStartDate]) {
            this.retailCalendarData[element.YearName][element.MonthId][element.WeekStartDate] = {
              events: []
            }
          } 
          if (element.CalendarEventDate) {
            const obj = {
              CalendarEventDate: new Date(element.CalendarEventDate),
              CalendarEventId: element.CalendarEventId,
              CalendarEventName: element.CalendarEventName,
              ColorHex: '#' + element.ColorHex
            };
            this.retailCalendarData[element.YearName][element.MonthId][element.WeekStartDate].events.push(obj);
          }
          this.monthsData[element.MonthId] = { MonthName: element.MonthName, MonthIndex: element.MonthIndex};
        });
        this.setInitialCalendarVariables(res);
        this.generateDates();
      })
      .finally(() => {
        this.spinner.hide();
        this.loadingData = false;
      });
  }

  protected generateDates() {
    this.selectedDate && this.selectedDate.setHours(0, 0, 0, 0);
    this.weeks = [];
    this.weekDays = [];
    const weekKeys = Object.keys(this.retailCalendarData[this.selectedYear][this.selectedMonth]);
    const weekValues = Object.values(this.retailCalendarData[this.selectedYear][this.selectedMonth]) as any[];
    for (let i = 0; i < weekKeys.length; i++) {
      !this.weeks[i] && (this.weeks[i] = []);
      for (let j = 0; j < 7; j++) {
        const date = new Date(weekKeys[i]);
        date.setDate(date.getDate() + j);
        date.setHours(0, 0, 0, 0);
        const dateObj = {
          date: date,
          class: '',
          events: []
        };
        if (weekValues[i].events.length) {
          weekValues[i].events.forEach(element => {
            if (element.CalendarEventDate.toISOString() == date.toISOString()) {
              dateObj.events.push(element);
            }
          });
        }
        this.events.forEach(event => {
          if ((new Date(event.CalendarEventDate)).toISOString() == date.toISOString()) {
            dateObj.events.push(event);
          }
        });
        this.weeks[i].push(dateObj);
      }
    }
    const weekStart = this.weeks[0][0].date.getDay();
    for (let i = 0; i < 7; i++) {
      this.weekDays[i] = this.weekDaysOriginalOrder[(i + weekStart) % 7];
    }
  }

  selectDate(date) {
    this.selectedDate = date;
    this.dateChanged.next(this.selectedDate);
  }

  protected setInitialCalendarVariables(calendarData: calendarObj[]) {
    const calendarDate = this.selectedDate || new Date();
    calendarDate.setHours(0, 0, 0, 0);
    this.selectedYear = calendarDate.getFullYear();
    this.selectedMonth = calendarDate.getMonth() + 1;
    !this.retailCalendarData[this.selectedYear] && (this.selectedYear = new Date().getFullYear());
    for (let i = 0; i < calendarData.length; i++) {
      const weekDatesArray = [];
      for (let j = 0; j < 7; j++) {
        let weekStartDate = new Date(calendarData[i].WeekStartDate);
        weekStartDate.setHours(0, 0, 0, 0);
        weekStartDate = new Date(weekStartDate.setDate(weekStartDate.getDate() + j));
        weekStartDate.setHours(0, 0, 0, 0);
        weekDatesArray.push(weekStartDate.toISOString());
      }
      if (weekDatesArray.indexOf(calendarDate.toISOString()) > -1) {
        this.selectedYear = calendarData[i].YearName;
        this.selectedMonth = calendarData[i].MonthId;
        break;
      }
    }
  }

  changeYear(next: boolean) {
    this.selectedYear = next ? this.selectedYear + 1 : this.selectedYear - 1;
    if (this.retailCalendarData[this.selectedYear]) this.generateDates();
    else this.selectedYear = next ? this.selectedYear - 1 : this.selectedYear + 1;
  }

  changeMonth(next: boolean) {

    let monthIndex = this.monthsData[this.selectedMonth].MonthIndex;
    if (next) {
      if (monthIndex == 12) {
        if (this.retailCalendarData[this.selectedYear+1]) {
          this.selectedYear++;
          this.selectedMonth = this.getNewMonth(1);
        }
      } else {
        this.selectedMonth = this.getNewMonth(monthIndex+1);
      }
    } else {
      if (monthIndex == 1) {
        if (this.retailCalendarData[this.selectedYear-1]) {
          this.selectedYear--;
          this.selectedMonth = this.getNewMonth(12);
        }
      } else {
        this.selectedMonth = this.getNewMonth(monthIndex-1);
      }
    }
    this.generateDates();
  }

  protected getNewMonth(newIndex) {
    let monthIndex = this.monthsData[this.selectedMonth].MonthIndex;
    const monthKeys = Object.keys(this.monthsData);
    const monthValues = Object.values(this.monthsData); 
    let selectedMonth = '1';
    monthValues.forEach((element: any, index) => {
      if (element.MonthIndex == newIndex) {
        selectedMonth = monthKeys[index];
      }
    });
    return Number(selectedMonth);
  }

  toggleCalendar() {
    if (this.disabled) return;
    else {
      if (!this.showCalendar) this.fetchAndPatchCalendarData();
      this.showCalendar = !this.showCalendar;
    }
  }

}
