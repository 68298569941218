import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map, shareReplay, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class ItemManagerService {

  // Behavioral Subjects
  public accountListSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  public classListSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  public subClassListSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  public ratingListSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  public actionsListSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  public fiscalYearListSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  public planningMonthListSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  public planningWeekListSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  public fixtureListSubject: BehaviorSubject<any> = new BehaviorSubject([]);


  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */
  setAccountListSubject = (value: any) => { this.accountListSubject.next(value); };
  setClassListSubject = (value: any) => { this.classListSubject.next(value); };
  setSubClassListSubject = (value: any) => { this.subClassListSubject.next(value); };
  setRatingListSubject = (value: any) => { this.ratingListSubject.next(value); };
  setActionsListSubject = (value: any) => { this.actionsListSubject.next(value); };
  setFiscalYearListSubject = (value: any) => { this.fiscalYearListSubject.next(value); };
  setPlanningMonthListSubject = (value: any) => { this.planningMonthListSubject.next(value); };
  setPlanningWeekListSubject = (value: any) => { this.planningWeekListSubject.next(value); };
  setFixtureListSubject = (value: any) => { this.fixtureListSubject.next(value); };

  // Observables
  getAccountListSubject = () => this.accountListSubject.asObservable();
  getClassListSubject = () => this.classListSubject.asObservable();
  getSubClassListSubject = () => this.subClassListSubject.asObservable();
  getRatingListSubject = () => this.ratingListSubject.asObservable();
  getActionsListSubject = () => this.actionsListSubject.asObservable();
  getFiscalYearListSubject = () => this.fiscalYearListSubject.asObservable();
  getPlanningMonthListSubject = () => this.planningMonthListSubject.asObservable();
  getPlanningWeekListSubject = () => this.planningWeekListSubject.asObservable();
  getFixtureListSubject = () => this.fixtureListSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  FilterActionByScreen(actionsList = [], ScreenCode = null) {
    let filteredList = [];
    switch (ScreenCode) {
      case 'Filter': {
        filteredList = actionsList;
        break;
      }
      case 'PPD': {
        filteredList = _.filter(actionsList, a => !['Pitched'].includes(a.actionName));
        break;
      }
      default: {
        filteredList = _.filter(actionsList, a => !['Pitched', 'Deleted'].includes(a.actionName));
        break;
      }
    }
    return filteredList;
  }

  GetAllRatings() {
    return this.http.get(`${environment.AppProductRating}/getlist`)
      .pipe(map((response: any) => {
        this.setRatingListSubject(response);
      }));
  }

  GetAllClasses() {
    return this.http.get(`${environment.AppProductClass}/getlist`)
      .pipe(map((response: any) => {
        this.setClassListSubject(response);
      }));
  }

  GetAllSubClasses() {
    return this.http.get(`${environment.AppProductSubClass}/getlist`)
      .pipe(map((response: any) => {
        this.setSubClassListSubject(response);
      }));
  }

  GetAllAccounts() {
    return this.http.get(`${environment.AppProductAccount}/getlist`)
      .pipe(map((response: any) => {
        this.setAccountListSubject(response);
      }));
  }

  GetActionsList() {
    return this.http.get(`${environment.AppProductLookup}/GetActionsList`)
      .pipe(map((response: any) => {
        this.setActionsListSubject(response);
      }));
  }

  GetFiscalYearList() {
    return this.http.get(`${environment.AppProductLookup}/GetFiscalYearList`)
      .pipe(map((response: any) => {
        this.setFiscalYearListSubject(response);
      }));
  }

  GetPlanningMonthList(body={}) {
    const query = Object.keys(body).map(key=> {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppProductLookup}/GetPlanningMonthList?${query}`)
      .pipe(map((response: any) => {
        this.setPlanningMonthListSubject(response);
      }));
  }

  GetPlanningWeekList(body={}) {
    const query = Object.keys(body).map(key=> {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppProductLookup}/GetPlanningWeekList?${query}`)
      .pipe(map((response: any) => {
        this.setPlanningWeekListSubject(response);
      }));
  }

  GetAllFixtures() {
    return this.http.get(`${environment.AppFixtureMapping}/getlist`)
      .pipe(map((response: any) => {
        this.setFixtureListSubject(response);
      }));
  }

  GetPlanningMonthByStartDate(startDate) {
    return this.http.get(`${environment.AppProductLookup}/GetPlanningMonthByStartDate?StartDate=${startDate}`)
      .pipe(map((response: any) => {
        return response;
      }));
  }

}
