import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Router } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { throwError } from 'rxjs';

import { NgxSpinnerService } from 'ngx-spinner';import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ViewRendererComponent } from 'src/common/view-renderer';
import { BusinessInformationService } from 'src/services/business-information.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
;

@Component({
  selector: 'app-business-information',
  templateUrl: './business-information.component.html',
  styleUrls: ['./business-information.component.css']
})
export class BusinessInformationComponent implements OnInit {

  public columnDefs = [];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public businessInfoList = [];
  public attributeList = [];
  public modules = AllCommunityModules;
  public user: any;
  public businessInfoForm: FormGroup;
  public isEdit = false;
  public answerTypeList = [
    { answerTypeId: 1, name: 'Text' },
    { answerTypeId: 2, name: 'Single Select' },
    { answerTypeId: 3, name: 'Multi Select' }
  ];
  @ViewChild('Adddialog') dialogAddTemplate: TemplateRef<any>;
  constructor(
    public router: Router,
    public businessInfoService: BusinessInformationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<any>,
    public storage: LocalstorageService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent,
        nameRenderer: NameRendererComponent,
        viewRenderer: ViewRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    this.getBusinessInfoList();
    this.getAttributeList();
  }
  getBusinessInfoList() {
    this.businessInfoService.getBusinessInformationList({}).subscribe(res => {
      this.businessInfoList = res.pivotList;
    });
  }
  public getAttributeList = async () => {
    let businessAtt = await this.businessInfoService.getBusinessAttributeList({ active: true }).toPromise().catch(error => throwError(error));
    if (businessAtt && businessAtt.length > 0) {
      businessAtt = businessAtt.map(row => ({ ...row, optionList: this.getOptionList(row) }));
      this.attributeList = businessAtt;
      businessAtt.forEach(element => {
        if (element.title === 'Main Business Name') {
          this.columnDefs.push({
            headerName: element.title,
            field: element.code,
            cellRenderer: 'nameRenderer',
            cellRendererParams: {
              onClick: this.openAddDialog.bind(this),
            },
            pinned: 'left',
            width: 150
          });
        } else {
          this.columnDefs.push({
            headerName: element.title,
            field: element.code,
            type: element.answerTypeId
          });
        }
      });
    }
    // this.columnDefs.push({
    //   headerName: '',
    //   field: 'delete',
    //   resizable: true,
    //   filter: false,
    //   cellRenderer: 'deleteButtonRenderer',
    //   cellRendererParams: {
    //     onClick: this.openRemoveGoalDialog.bind(this),
    //   },
    //   width: 80
    // });
    const index = this.columnDefs.findIndex(column => column.field.startsWith('BusinessModel'));
    this.columnDefs[index] = {
      ...this.columnDefs[index],
      cellRenderer: (params) => {
        if (params.value === '1') {
          return 'Business to Business';
        }
        if (params.value === '2') {
          return 'Business to Customer';
        }
      }
    };
    this.columnDefs.forEach((col, i) => {
      if (col.type === 4) {
        this.columnDefs[i] = {
          ...this.columnDefs[i],
          cellRenderer: 'viewRenderer'
        };
      }
    });
    this.gridOptions.api.setColumnDefs(this.columnDefs);
    this.businessInfoForm = this.initializeForm(this.attributeList);
  }
  public getOptionList = (businessAttribute) => {
    switch (businessAttribute.bussinessAttributeId) {
      case 5: { return [{ optionId: 1, name: 'Business to Business' }, { optionId: 2, name: 'Business to Customer' }]; }
    }
  }
  public initializeForm = (attributeList: any) => {
    const group: any = {};
    attributeList.forEach(question => {
      group[question.code] = question.required ? new FormControl(question.value || '', Validators.required)
        : new FormControl('');
    });
    return new FormGroup(group);
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: 'Company' }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeItemMaster(row.rowData);
      }
    });
  }
  public removeItemMaster(selectedItem: any) {
    if (selectedItem) {
      const model = {
        ...selectedItem,
        active: false
      };
      this.spinner.show();
      this.businessInfoService.activateBusinessInformation(model).subscribe(res => {
        if (res) {
          this.spinner.hide();
          this.toastr.success('Business Information', 'Item removed successfully');
          this.businessInfoList = this.businessInfoList.filter(row => row.ClientId !== model.ClientId);
          this.gridOptions.api.setRowData(this.businessInfoList);
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('Business Information', error.error.text);
      });
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }
  public onSubmit = (data?: any) => {
    const model = this.businessInfoForm.value;
    const modelList = [];
    Object.keys(model).forEach(key => {
      let valueObject = {};
      if (this.isEdit) {
        valueObject = data.find(row => row.code === key);
      } else {
        valueObject = this.attributeList.find(row => row.code === key);
      }
      if (valueObject) {
        valueObject = {
          ...valueObject,
          value: model[key],
        };
        modelList.push(valueObject);
      }
    });
    const requestVM = {
      requestVM: modelList
    };
    this.spinner.show();
    if (this.isEdit) {
      this.businessInfoService.updateBusinessInformation(requestVM).subscribe(res => {
        this.getBusinessInfoList();
        this.dialogRef.close();
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
    } else {
      this.businessInfoService.addBusinessInformation(requestVM).subscribe(res => {
        this.getBusinessInfoList();
        this.dialogRef.close();
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
    }
  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  onCellClicked($event) {

    this.router.navigate(['addUser'], {
      queryParams: {
        id: btoa($event.data.userId)
      }
    });
  }
  public navigateToRoles = () => {
    this.router.navigate(['Roles']);
  }
  async openAddDialog(data, templateRef?: TemplateRef<any>) {
    let bgInfo = {};
    if (data && data.rowData) {
      this.isEdit = true;
      Object.keys(this.businessInfoForm.controls).forEach(key => {
        this.businessInfoForm.controls[key].setValue(data.rowData[key]);
      });
      bgInfo = await this.businessInfoService.getBusinessInformation(data.rowData.ClientId).toPromise().catch(error => throwError(error));
    }
    this.dialogRef = this.dialog.open(this.dialogAddTemplate, { width: '800px', data: bgInfo || {} });
    this.dialogRef.afterClosed().subscribe(res => {
      this.isEdit = false;
      this.businessInfoForm.reset();
    });
  }
}
