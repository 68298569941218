import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { NgxToasterService } from '../../../../services/ngx-toaster.service';
import { LocalstorageService } from '../../../../services/localstorage.service';
import { ConfigurationService } from '../../../../services/configuration.service';
import * as moment from 'moment';
import {
  Calendar,
  FbChannel,
  FbForecast,
  ForecastCell,
  Measure,
  UserFbForecast,
  Version,
} from '../../../../common/facebook.models';
import { TreeviewItem } from 'ngx-treeview';
import { USER_ID, USER_INFO } from '../../../../common/keys';
import {
  FB_Template,
  HalfYear,
  Month,
  PhysicalYear,
  Quarter,
  Week,
} from '../global.models';
import { throwError } from 'rxjs';

import { InlineWorker } from './inline-worker';
import { environment } from '../../../../environments/environment';
import { ForecastConfirmationModalComponent } from '../fb-forecast/forecast-confirmation-modal/forecast-confirmation-modal.component';
import { ForecastSavedVersionsComponent } from '../fb-forecast/forecast-saved-versions/forecast-saved-versions.component';
import { ForecastDeletePlannerModalComponent } from '../fb-forecast/fb-forecast-delete-planner-modal/fb-forecast-delete-planner-modal.component';
import { RetailIndirectPlannerMeasureProfileComponent } from './retail-indirect-planner-measure-profile/retail-indirect-planner-measure-profile.component';
import {
  addCommas,
  CreateAccountScrollingList,
  CreateCalendarList,
  CreateCalendarListWithoutWeeks,
  CreateItemTree,
  CreateProductScrollingList,
} from 'src/common/functions';

@Component({
  selector: 'app-retail-indirect-planner',
  templateUrl: './retail-indirect-planner.component.html',
  styleUrls: ['./retail-indirect-planner.component.scss'],
})
export class RetailIndirectPlannerComponent implements OnInit {
  @Input() data: any;
  maxPlannerCount = environment.maxPlannerSaveCount;
  public clientId = 1;
  public measureList: Measure[];
  public channelList: FbChannel[];
  public timeframeList: Calendar[];
  public versionList: Version[];
  public fbForecastList: UserFbForecast[];
  public channles = [];
  public customers = [];
  public countries = [];
  public continentList = [];
  public channelDropdownList: TreeviewItem[];
  public calendarListDropdown: TreeviewItem[];
  public leftGroupList = [];
  public productFlatList = [];
  public productList = [];
  public itemIds = [];
  public channelIds = [];
  public calendarIds = [];
  public productTreeNodes = [];
  public dropdownObjects;
  public updatedDropdownObject;
  public yearListGrid = [];
  public yearList = [];
  public forecastList = [];
  public restoredProductList = [];
  public restoredChannelList = [];
  public restoreCalendarList = [];
  public distributorList = [];
  public scenarioPlanningList: Measure[] = [];
  public timeFrameListWithWeeksUPD = [];
  public calendarIdsUDP = [];
  public calendarIdsScenario = [];
  public udpfcstList = [];
  public udpMeasuresList = [];
  public fcstScenarioPlanningList = [];
  public forecastMethodList;
  public seasonalitySourceLevelList;
  public averageWeekList;
  public fbForecast;
  showVersionList = false;
  className = '';
  public hideScreen = true;
  public versionTypeId = 2;
  public userFBForecastId = 0;
  public selectedScenario;
  public selectedDistibutor;
  public selectedAccounts = [];
  plannerName = '';
  @ViewChild('saveForecast') saveForecastTemplate: TemplateRef<any>;
  @ViewChild('commitForecast') commitForecast: TemplateRef<any>;
  savedPlanner: {};
  selectedProducts = [];
  productTree = [];
  channelTree = [];
  isOpenSaved: boolean;
  isBuidlNew: boolean;
  isRestoreSession: boolean;
  gridMapped: boolean;
  channelValue: any = '';
  calanderValue: any = '';
  productValue: any = '';
  isShowTreeDropDowns: boolean;
  public itemsList = [];
  public accountList = [];
  public productSettingList = [];
  public channelSettingList = [];
  public tempForecastList = [];
  public timeFrameListWithWeeks = [];
  public TimeframeWithWeeksUDP = [];
  public TimeframeWithWeeksScenario = [];
  refreshMeasureIds;
  selectedChannelIds = [];
  product: string;
  account: string;
  viewPermission = true;
  editPermission = false;
  readOnlyMeasres: Measure[];
  editViaUploadMeasures: Measure[];
  public forecastMethod;
  public seasonalitySourceLevel;
  public movingAverageWeeks;
  public selectedForecastMeasure;
  public selectedUDPMeasure;
  public adjustment;
  public selectedScenarioForecastMeasure;
  public forecastMethodValue = '';
  public seasonalitySourceLevelValue = '';
  public movingAverageWeeksValue = '';
  public date = new Date();
  public measureKeys = [];
  public tempDistributorList = [];
  public invItemIds = [];
  public invChannelIds = [];
  public masterMeasureList = [];
  public DefaultMeasures = [
    'DistiActualST',
    'DistiActualSTCumulative',
    'UDPLikelyTotalFcst',
    'UDPLikelyTotalFcstCumulative',
    'DistiCustomerSTFcst',
    'DistiCustomerSTFcstCumulative',
    'DistiCPFRSellThruOverrideFcst',
    'DistiCPFRSellThruOverrideFcstCumulative',
    'DistiCPFRComments',
    'DistiAvailableAllocation',
    'DistiFinalSellInShipped',
    'DistiConstrainedSellInFcstCumulative',
    'DistiConstrainedSellOut',
    'DistiInTransit',
    'DistiOpenOrders',
    'DistiShipments',
    'DistiProjectedDeliveries',
    'DistiDeliveredQty',
    'DistiEndingonHandInventory',
    'DistiConstrainedEndingInventory',
    'DistiConstrainedFWOS',
    'DistiUDPLikelyTotalFcst',
    'DistiUDPLikelyTotalFcstCumulative',
  ];
  isDeleted = true;
  get refreshMeasuresList() {
    return this.masterMeasureList.filter(row => row.isRefresh);
  }
  constructor(
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    public dialogRef: MatDialogRef<any>,
    public ref: MatDialogRef<any>,
    public configurationService: ConfigurationService,
    public dialog: MatDialog
  ) {}
  async ngOnInit() {
    if (this.data) {
      this.data = JSON.parse(this.data);
    }
    let userTeams = this.storage.get(USER_INFO).teams;
    userTeams.forEach((element) => {
      element.teamScreenGroup.forEach((group) => {
        if (group.screenGroupId == 5 && group.viewPermission) {
          this.viewPermission = true;
        }
        if (group.screenGroupId == 5 && group.editPermission) {
          this.editPermission = true;
        }
      });
    });
    this.getUserForecast();
    this.configurationService
      .AccountSettingMappingDistributorGetList({})
      .subscribe((res) => {
        this.distributorList =
          res
            .filter((row) => row.isRIP)
            .sort((a, b) => (a.distributorName > b.distributorName ? 1 : -1)) ||
          [];
        this.tempDistributorList = this.distributorList;
      });
  }
  public getUserForecast = async () => {
    const model = {
      userId: this.storage.get(USER_ID),
      userFBForecastId: 0,
      fbTemplateId: FB_Template.Retail_InDirect,
    };
    this.fbForecast = await this.configurationService
      .GetUserSession(model)
      .toPromise()
      .catch((error) => throwError(error));
    this.getHelpingLists();
    if (
      this.fbForecast.itemIds &&
      this.fbForecast.channelIds &&
      this.fbForecast.calendarIds
    ) {
      this.openActionDialog(true);
    } else {
      this.openActionDialog(false);
    }
  };
  public getHelpingLists() {
    this.configurationService
      .measuresGetList({ FBTemplateId: FB_Template.Retail_InDirect })
      .subscribe((res: Measure[]) => {
        this.masterMeasureList = res;
        let distiMeasures = res.filter((row) => row.isDisti);
        const otherMeasures = res.filter((row) => !row.isDisti);
        let distiDefault = distiMeasures.filter(
          (row) => this.DefaultMeasures.includes(row.code) || !row.parentId
        );
        this.measureList = [...distiDefault, ...otherMeasures];
        this.readOnlyMeasres = this.measureList.filter(
          (x) => x.templateId == 1
        );
        this.editViaUploadMeasures = this.measureList.filter(
          (x) => x.templateId == 2
        );
        this.extractParentsFromMeasures();
      });
    const params = {
      offset: 0,
      pageSize: 100000,
      active: true,
    };

    let url = `offSet=${params.offset ? params.offset : 0}&pageSize=${
      params.pageSize ? params.pageSize : 10
    }&active=true`;
    this.configurationService.ItemAccountGetAll(url).subscribe((res) => {
      this.accountList = res.data || [];
      this.accountList = this.accountList.filter(
        (row) => row.partnerType === 'RETAILER INDIRECT'
      );
    });
  }

  public getDefaultDropdowns = async (forecast?: any) => {
    const params = {
      offset: 0,
      pageSize: 100000,
      active: true,
    };

    let url = `offSet=${params.offset ? params.offset : 0}&pageSize=${
      params.pageSize ? params.pageSize : 10
    }&active=true`;
    this.configurationService.ItemManagerGetAll(url).subscribe((res) => {
      this.itemsList = res.data || [];
      this.GetProductSettingList(forecast);
    });
    this.configurationService
      .calendarGetList({})
      .subscribe((res: Calendar[]) => {
        this.timeframeList = res;
        const calendarIds = [];
        let startMonthDate = moment().add(-2, 'M');
        let endMonthDate = moment().add(3, 'M');
        this.timeframeList.forEach((calendar) => {
          const currentMonth = moment(calendar.weekStartDate).month();
          const currentYear = moment(calendar.weekStartDate).year();
          if (
            (startMonthDate.month() <= currentMonth &&
              startMonthDate.year() === currentYear) ||
            (endMonthDate.month() >= currentMonth &&
              endMonthDate.year() === currentYear)
          ) {
            calendarIds.push(calendar.calendarId);
          }
        });
        if (calendarIds.length > 0 && this.isBuidlNew) {
          this.fbForecast.calendarIds = calendarIds.toString();
          this.createCalendarListDropdown(this.fbForecast);
        } else {
          this.createCalendarListDropdown(forecast);
        }
      });
    const params1 = {
      userId: this.storage.get(USER_ID),
      fbTemplateId: FB_Template.Retail_InDirect,
    };
    this.configurationService
      .userFBForecastGetList(params1)
      .subscribe((res: UserFbForecast[]) => {
        this.fbForecastList = res;
      });
  };
  public GetProductSettingList = (forecast?: any) => {
    this.configurationService
      .ProductSettingMappingGetList({})
      .subscribe((res) => {
        this.productSettingList = res;
        this.createProductDropdownList(res, forecast);
      });
  };
  public createProductDropdownList = (productSettingList, forecast) => {
    this.productList = CreateItemTree(
      productSettingList,
      this.itemsList,
      forecast
    );
    if (forecast) {
      const itemIds = forecast.itemIds;
      this.mapProductScrollingList(itemIds);
    }
  };
  public mapProductScrollingList = (itemIds) => {
    this.productTree = CreateProductScrollingList(
      itemIds,
      this.itemsList,
      this.productSettingList
    );
    this.productTreeNodes = this.productTree;
    let leafNodes = this.getLeafNodes(this.productTree);
    this.productTree = leafNodes;
  };
  public createCalendarListDropdown = (forecast?: any) => {
    this.calendarListDropdown = CreateCalendarList(
      this.calendarListDropdown,
      this.timeframeList,
      forecast
    );
  };
  public GetAccountSettingList = (forecast?: any) => {
    this.configurationService
      .AccountSettingMappingGetList({})
      .subscribe((res) => {
        this.channelSettingList = res;
      });
  };
  getLeafNodes(nodes, result = []) {
    if (nodes) {
      for (var i = 0, length = nodes.length; i < length; i++) {
        if (!nodes[i].children || nodes[i].children.length === 0) {
          result.push(nodes[i]);
        } else {
          result = this.getLeafNodes(nodes[i].children, result);
        }
      }
      return result;
    }
  }
  public extractParentsFromMeasures = (forecast?: any) => {
    const groupList = this.measureList.filter((measure) => !measure.parentId);
    this.leftGroupList = groupList
      .map((row) => ({
        ...row,
        rowspan: this.measureList.filter(
          (measure) => measure.parentId === row.measureId
        ).length,
      }))
      .sort((a, b) => a.sort - b.sort);
    this.measureList = this.measureList
      .filter((row) => row.parentId)
      .map((row) => ({ ...row, checked: true }))
      .sort((a, b) => a.sort - b.sort);
  };
  openFullScreen = (id: string) => {
    const domGrid = document.getElementById(id) as any;
    if (domGrid) {
      domGrid.webkitRequestFullscreen();
    }
  };

  openActionDialog(restoreSession: boolean): void {
    // tslint:disable-next-line: no-use-before-declare
    const sessionLabel = restoreSession
      ? 'Your last session ended abruptly, would you like to return to where you left off?'
      : 'Would you like to open a saved planner or build new?';
    this.dialog
      .open(ForecastConfirmationModalComponent, {
        width: '848px',
        panelClass: ['fb-dialog-wrapper2', 'fb-dialog-wrapper3'],
        data: {
          isRestoreSession: restoreSession,
          label: sessionLabel,
          isOnLoad: restoreSession ? false : true,
          isSaveAndCommit: false,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((command) => {
        if (command && command.isCanceled) {
          this.closeScreen();
        } else if (command && command.openSaved) {
          this.isOpenSaved = true;
          this.openSavedVersionDialog();
        } else if (command && command.restoreSession) {
          this.restoreSession();
          this.isRestoreSession = true;
          this.hideScreen = false;
        } else {
          this.hideScreen = false;
          this.deleteFbForecast();
          this.getDefaultDropdowns();
          this.isBuidlNew = true;
          this.isShowTreeDropDowns = true;
        }
      });
  }
  public deleteFbForecast() {
    this.isDeleted = false;
    const model = {
      userId: this.storage.get(USER_ID),
      userFBForecastId: 0,
      fbTemplateId: FB_Template.Retail_InDirect,
    }
    this.configurationService.fbForecastDefaultDelete(model).subscribe(res => {
      this.isDeleted = true;
    }, error => {
      this.isDeleted = true;
    })
  }
  public openSavedPlanner = async (version) => {
    const model = {
      userId: this.storage.get(USER_ID),
      userFBForecastId: version.userFBForecastId,
      fbTemplateId: FB_Template.Retail_InDirect,
    };
    this.userFBForecastId = version.userFBForecastId;
    this.fbForecast = await this.configurationService
      .GetUserSession(model)
      .toPromise()
      .catch((error) => throwError(error));
    this.versionTypeId = 3;
    this.userFBForecastId = version.userFBForecastId;
    this.createProductDropdownList(this.productSettingList, this.fbForecast);
    const selectedAccount = this.accountList.find((row) =>
      this.fbForecast.channelIds.includes(row.itemAccountId.toString())
    );
    if (selectedAccount) {
      const disti = this.distributorList.find(
        (row) => row.distributorId === selectedAccount.distributorId
      );
      if (disti) {
        this.selectAccounts(disti);
      }
    }
    this.getDefaultDropdowns(this.fbForecast);
  };
  public restoreSession = async () => {
    const selectedAccount = this.accountList.find((row) =>
      this.fbForecast.channelIds.includes(row.itemAccountId.toString())
    );
    if (selectedAccount) {
      const disti = this.distributorList.find(
        (row) => row.distributorId === selectedAccount.distributorId
      );
      if (disti) {
        this.selectAccounts(disti);
      }
    }
    this.getDefaultDropdowns(this.fbForecast);
  };
  public openSavedVersionDialog = () => {
    const dialogRef = this.dialog.open(ForecastSavedVersionsComponent, {
      width: '848px',
      panelClass: 'fb-dialog-wrapper2',
      data: { isInDirectInventory: true },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.version) {
        this.openSavedPlanner(res.version);
        this.plannerName = res.version.name;
        this.userFBForecastId = res.version.userFBForecastId;
        const model = {
          ...this.data,
          version: res.version,
        };
        this.configurationService.savedPlannerOpened.next(model);
        this.hideScreen = false;
      }
    });
  };
  public openDeletePlannerDialog = () => {
    const dialogRef = this.dialog.open(ForecastDeletePlannerModalComponent, {
      width: '848px',
      panelClass: 'fb-dialog-wrapper2',
      data: {},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.version) {
        this.openSavedPlanner(res.version);
        this.configurationService.savedPlannerOpened.next(res.version);
        this.hideScreen = false;
      }
    });
  };
  public closeScreen = () => {
    this.configurationService.removeMenu.next(this.data);
  };
  setValue = (selectedDropdown) => {
    switch (selectedDropdown.type) {
      case 'Item': {
        this.itemIds = selectedDropdown.value;
        break;
      }
      case 'Channel': {
        this.channelIds = selectedDropdown.value;
        this.selectedChannelIds = selectedDropdown.value;
        break;
      }
      case 'Timeframe': {
        this.calendarIds = selectedDropdown.value;
        break;
      }
      case 'TimeframeWithWeeksUDP': {
        this.calendarIdsUDP = selectedDropdown.value;
        break;
      }
      case 'TimeframeWithWeeksScenario': {
        this.calendarIdsScenario = selectedDropdown.value;
        break;
      }
    }
    this.channelIds =
      this.channelIds.filter((el) => typeof el === 'number') || [];
    this.itemIds = this.itemIds.filter((el) => typeof el === 'number') || [];
    this.dropdownObjects = {
      itemIds: this.itemIds || [],
      channelIds: this.channelIds,
      calendarIds: this.calendarIds || [],
    };
    this.updatedDropdownObject = this.dropdownObjects;
    if (this.isOpenSaved || this.isRestoreSession) {
      this.mapGridList(true);
    }
  };
  setDataManagerValues(selectedDropdown) {
    switch (selectedDropdown.type) {
      case 'TimeframeWithWeeksUDP': {
        this.calendarIdsUDP = selectedDropdown.value;
        break;
      }
      case 'TimeframeWithWeeksScenario': {
        this.calendarIdsScenario = selectedDropdown.value;
        break;
      }
    }
  }
  setRestoredValue = (selectedDropdown) => {
    switch (selectedDropdown.type) {
      case 'Item': {
        this.itemIds = selectedDropdown.value;
        break;
      }
      case 'Channel': {
        this.channelIds = selectedDropdown.value;
        this.selectedChannelIds = selectedDropdown.value;
        break;
      }
      case 'Timeframe': {
        this.calendarIds = selectedDropdown.value;
        break;
      }
    }
    this.channelIds =
      this.channelIds.filter((el) => typeof el === 'number') || [];
    this.itemIds = this.itemIds.filter((el) => typeof el === 'number') || [];
    this.dropdownObjects = {
      itemIds: this.itemIds || [],
      channelIds: this.channelIds,
      calendarIds: this.calendarIds || [],
    };
  };

  public mapGridList = async (isOnload) => {
    this.product = '';
    this.account = '';
    this.itemIds.forEach((element) => {
      this.product = this.itemsList.find(
        (x) => x.itemManagerId == Number(element)
      )
        ? this.product +
          this.itemsList.find((x) => x.itemManagerId == Number(element))
            .brandName +
          ';'
        : this.product;
    });
    this.channelIds.forEach((element) => {
      this.account = this.accountList.find(
        (x) => x.accountTypeId == Number(element)
      )
        ? this.account +
          this.accountList.find((x) => x.accountTypeId == Number(element))
            .regionName +
          ';'
        : this.account;
    });
    this.gridMapped = true;
    if ((this.isOpenSaved || this.isRestoreSession) && !isOnload) {
      this.isOpenSaved = false;
      this.isRestoreSession = false;
    }
    this.spinner.show();
    if (this.isBuidlNew && this.isShowTreeDropDowns) {
      if (
        this.fbForecast &&
        this.fbForecast.itemIds &&
        this.fbForecast.itemIds.match(/^[a-z0-9]+$/i)
      ) {
        this.fbForecast.itemIds = this.itemIds;
      }
      this.isShowTreeDropDowns = false;
    }
    this.mapProductScrollingList(this.itemIds.toString());
    this.forecastList = [];
    this.createGridList();
  };
  public createGridList = async (event?) => {
    if (event && event.itemIds && event.channelIds && event.calendarIds) {
      this.forecastList = [];
      event.isScrolling = true;
      this.traverseSelectedOptions(event);
    } else {
      if (
        this.dropdownObjects.itemIds.length &&
        this.dropdownObjects.channelIds.length &&
        this.dropdownObjects.calendarIds.length
      ) {
        this.traverseSelectedOptions(this.dropdownObjects);
      }
    }
  };
  public traverseSelectedOptions = (event) => {
    const calendarIds = [];
    if (this.calendarIds) {
      event.calendarIds.flat().forEach((calendar) => {
        calendarIds.push(calendar);
      });
    }
    let leafNodesProduct = this.getLeafNodes(this.productTree);
    let leafNodesChannel = this.getLeafNodes(this.channelTree);
    leafNodesProduct = leafNodesProduct.sort((a, b) =>
      a.text > b.text ? 1 : -1
    );
    leafNodesChannel = leafNodesChannel.sort((a, b) =>
      a.text > b.text ? 1 : -1
    );
    const selectedChannel = leafNodesChannel[0];
    const channelId = selectedChannel ? selectedChannel.value : '';
    const selectedProduct = leafNodesProduct[0];
    const itemId = selectedProduct ? selectedProduct.value : '';
    event.calendarIds = calendarIds;
    this.invItemIds =
      event.itemIds.length === 1 ? event.itemIds.toString() : itemId.toString();
    this.invChannelIds =
      event.channelIds.length === 1
        ? event.channelIds.toString()
        : channelId.toString();
    if (this.isRestoreSession || this.isOpenSaved) {
      const model = {
        userId: this.storage.get(USER_ID),
        userFBForecastId: this.userFBForecastId || 0,
        fbTemplateId: FB_Template.Retail_InDirect,
        RestoreSession: true,
        VersionTypeId: this.versionTypeId,
        itemIds: this.dropdownObjects.itemIds.toString(),
        channelIds: this.dropdownObjects.channelIds.toString(),
        invItemIds:
          event.itemIds.length === 1
            ? event.itemIds.toString()
            : itemId.toString(),
        invChannelIds:
          event.channelIds.length === 1
            ? event.channelIds.toString()
            : channelId.toString(),
        calendarIds: event.calendarIds.flat().toString(),
        distiId: this.selectedDistibutor.distributorId,
      };
      this.configurationService
        .fbForecastGetPerformanceRI(model)
        .subscribe((res) => {
          this.fbForecast = res;
          this.mapWeeksList();
          this.spinner.hide();
        });
    } else {
      const getModel = {
        userId: this.storage.get(USER_ID),
        userFBForecastId: this.userFBForecastId || 0,
        fbTemplateId: FB_Template.Retail_InDirect,
        VersionTypeId: this.versionTypeId,
        itemIds: event.itemIds.toString(),
        channelIds: event.channelIds.toString(),
        invItemIds: itemId.toString(),
        invChannelIds: channelId.toString(),
        calendarIds: event.calendarIds.flat().toString(),
        distiId: this.selectedDistibutor.distributorId,
      };
      const addModel = {
        userId: this.storage.get(USER_ID),
        userFBForecastId: this.userFBForecastId || 0,
        fbTemplateId: FB_Template.Retail_InDirect,
        VersionTypeId: this.versionTypeId,
        itemIds: event.itemIds.toString(),
        channelIds: event.channelIds.toString(),
        invItemIds: itemId.toString(),
        invChannelIds: channelId.toString(),
        calendarIds: event.calendarIds.flat().toString(),
        distiId: this.selectedDistibutor.distributorId,
      };
      this.configurationService
        .FbForecastDefaultAdd(addModel)
        .subscribe((res) => {});
      this.spinner.show();
      this.configurationService
        .fbForecastGetPerformanceRI(getModel)
        .subscribe((res) => {
          this.fbForecast = res;
          this.mapWeeksList();
          this.spinner.hide();
        });
    }
  };
  public mapWeeksList() {
    this.yearListGrid = new Array();
    this.yearListGrid = this.fbForecast.weeks.map((row) => {
      const measures = [
        'AccountConstrainedFWOS',
        'DistiConstrainedFWOS',
        'FBProjectedFWOS',
      ];
      Object.keys(row['riDistiMeasure']).forEach((measure) => {
        if (measures.includes(measure)) {
          row['riDistiMeasure'][measure] = Number(
            Number(row['riDistiMeasure'][measure]).toFixed(2)
          );
        } else if (measure.indexOf('Comment') < 0) {
          if (measure.endsWith('Pct')) {
            if (!row['riDistiMeasure'][measure].endsWith('%')) {
              row['riDistiMeasure'][measure] = Math.round(
                Number(row['riDistiMeasure'][measure])
              );
              row['riDistiMeasure'][measure] = row['riDistiMeasure'][measure] + '%';
            }
          } else {
            row['riDistiMeasure'][measure] = Math.round(
              Number(row['riDistiMeasure'][measure])
            );
          }
        }
        row['riDistiMeasure'][measure] = addCommas(row['riDistiMeasure'], measure);
      });
      Object.keys(row['riSubTotalMeasures']).forEach((measure) => {
        if (measures.includes(measure)) {
          row['riSubTotalMeasures'][measure] = Number(
            Number(row['riSubTotalMeasures'][measure]).toFixed(2)
          );
        } else if (measure.indexOf('Comment') < 0) {
          if (measure.endsWith('Pct')) {
            if (!row['riSubTotalMeasures'][measure].endsWith('%')) {
              row['riSubTotalMeasures'][measure] = Math.round(
                Number(row['riSubTotalMeasures'][measure])
              );
              row['riSubTotalMeasures'][measure] =
                row['riSubTotalMeasures'][measure] + '%';
            }
          } else {
            row['riSubTotalMeasures'][measure] = Math.round(
              Number(row['riSubTotalMeasures'][measure])
            );
          }
        }
        row['riSubTotalMeasures'][measure] = addCommas(row['riSubTotalMeasures'], measure);
      });
      Object.keys(row['riTotalMeasures']).forEach((measure) => {
        if (measures.includes(measure)) {
          row['riTotalMeasures'][measure] = Number(
            Number(row['riTotalMeasures'][measure]).toFixed(2)
          );
        } else if (measure.indexOf('Comment') < 0) {
          if (measure.endsWith('Pct')) {
            if (!row['riTotalMeasures'][measure].endsWith('%')) {
              row['riTotalMeasures'][measure] = Math.round(
                Number(row['riTotalMeasures'][measure])
              );
              row['riTotalMeasures'][measure] =
                row['riTotalMeasures'][measure] + '%';
            }
          } else {
            row['riTotalMeasures'][measure] = Math.round(
              Number(row['riTotalMeasures'][measure])
            );
          }
        }
        row['riTotalMeasures'][measure] = addCommas(row['riTotalMeasures'], measure);
      });
      let accountMeasures = [];
      row.riAccountMeasures.forEach((account) => {
        account.isWeek = true;
        account.week = new Date(row.weekStartDate).toLocaleDateString();
        account.calendarId = row.calendarId;
        account.yearValue = row.fy;
        accountMeasures = Object.keys(account['measures']);
        accountMeasures.forEach((measure) => {
          if (measures.includes(measure)) {
            account['measures'][measure] = Number(
              Number(account['measures'][measure]).toFixed(2)
            );
          } else if (measure.indexOf('Comment') < 0) {
            if (measure.endsWith('Pct')) {
              if (!account['measures'][measure].endsWith('%')) {
                account['measures'][measure] = Math.round(
                  Number(account['measures'][measure])
                );
                account['measures'][measure] =
                  account['measures'][measure] + '%';
              }
            } else {
              account['measures'][measure] = Math.round(
                Number(account['measures'][measure])
              );
            }
            addCommas(account['measures'], measure);
          }
        });
      });
      this.measureKeys = [
        ...Object.keys(row['riDistiMeasure']),
        ...Object.keys(row['riSubTotalMeasures']),
        ...Object.keys(row['riTotalMeasures']),
        ...accountMeasures,
      ];
      return {
        ...row,
        title: new Date(row.weekStartDate).toLocaleDateString(),
      };
    });
    this.restoredProductList = [...this.productTreeNodes];
    this.createCalendarListDropdownWithWeeks(this.fbForecast);
  }
  public createCalendarListDropdownWithWeeks = (forecast?: any) => {
    const _timeframeList = this.timeframeList.filter((item) =>
      (forecast.calendarIds as string).includes(item.calendarId.toString())
    );
    const calendarWithoutWeeks = CreateCalendarListWithoutWeeks(
      _timeframeList,
      this.timeFrameListWithWeeks,
      forecast
    );
    this.restoreCalendarList = calendarWithoutWeeks;
  };
  openMesuresDialog() {
    this.dialog
      .open(RetailIndirectPlannerMeasureProfileComponent, {
        width: '1200px',
        panelClass: 'fb-dialog-wrapper',
        data: { measureList: this.measureList },
      })
      .afterClosed()
      .subscribe((command) => {
        if (command) {
          console.log(command);
        }
      });
  }
  adjustData() {
    this.dialog
      .open(ForecastConfirmationModalComponent, {
        width: '848px',
        panelClass: 'fb-dialog-wrapper2',
        data: {
          label:
            'Would you like to save your current forecast planner before adjusting?',
          adjustData: true,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((command) => {
        if (command) {
          if (command.isSave) {
            this.forecastList = [];
            this.createGridList();
          }
        }
      });
  }
  save() {
    this.dialog
      .open(ForecastConfirmationModalComponent, {
        width: '848px',
        panelClass: ['fb-dialog-wrapper2', 'fb-dialog-wrapper3'],
        data: {
          label: 'How would you like to proceed?',
          isOnLoad: false,
          isSaveAndCommit: true,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((command) => {
        if (command) {
          if (command.isSave) {
            if (!this.isBuidlNew && !this.isRestoreSession) {
              const model = {
                itemIds: this.itemIds.toString(),
                channelIds: this.channelIds.toString(),
                calendarIds: this.calendarIds.toString(),
                userId: this.storage.get(USER_ID),
                name: this.plannerName,
                fbTemplateId: FB_Template.Retail_InDirect,
                userFBForecastId: this.userFBForecastId,
                active: true,
              };
              this.spinner.show();
              this.configurationService
                .userFBForecastUpdate(model)
                .subscribe((resp) => {
                  this.spinner.hide();
                });
            } else {
              const params = {
                userId: this.storage.get(USER_ID),
                fbTemplateId: FB_Template.Retail_InDirect,
              };
              this.configurationService
                .userFBForecastGetList(params)
                .subscribe((res) => {
                  if (res.length >= this.maxPlannerCount) {
                    this.dialog
                      .open(ForecastDeletePlannerModalComponent, {
                        width: '848px',
                        panelClass: 'fb-dialog-wrapper2',
                        data: { versionList: res, isInDirectInventory: true },
                        disableClose: true,
                      })
                      .afterClosed()
                      .subscribe((command) => {
                        if (command && command.version) {
                          let versions = command.version;
                          this.dialog
                            .open(ForecastConfirmationModalComponent, {
                              width: '848px',
                              panelClass: 'fb-dialog-wrapper2',
                              data: {
                                label:
                                  'Are you sure you want to delete this planner?',
                                isOnLoad: false,
                                isDeletePlanner: true,
                              },
                              disableClose: true,
                            })
                            .afterClosed()
                            .subscribe((command) => {
                              if (command.isDelete) {
                                let model = {
                                  versionTypeId: null,
                                  userFBForecastIds: '',
                                  fbTemplateId: FB_Template.Retail_InDirect,
                                };
                                model.versionTypeId = 3;
                                for (var i = 0; i < versions.length; i++) {
                                  model.userFBForecastIds =
                                    model.userFBForecastIds +
                                    versions[i].userFBForecastId +
                                    ',';
                                }
                                model.userFBForecastIds =
                                  model.userFBForecastIds.slice(0, -1);
                                this.configurationService
                                  .userFBForecastDeleteByVersionID(model)
                                  .subscribe((resp) => {
                                    this.saveForecast();
                                  });
                              }
                            });
                        }
                      });
                  } else {
                    this.saveForecast();
                  }
                });
            }
          } else if (command.isCommit) {
            this.commitForecastPlanner();
          } else if (command.isSaveCommit) {
            this.saveForecast(command.isSaveCommit);
          }

          //
        }
      });
  }
  public saveForecast = (saveCommit?) => {
    if (saveCommit && this.userFBForecastId) {
      const model = {
        itemIds: this.itemIds.toString(),
        channelIds: this.channelIds.toString(),
        calendarIds: this.calendarIds.toString(),
        userId: this.storage.get(USER_ID),
        name: this.plannerName,
        fbTemplateId: FB_Template.Retail_InDirect,
        userFBForecastId: this.userFBForecastId,
        active: true,
      };
      this.spinner.show();
      this.configurationService
        .userFBForecastUpdate(model)
        .subscribe((resp) => {
          this.spinner.hide();
          this.commitForecastPlanner();
        });
    } else {
      this.dialog
        .open(ForecastConfirmationModalComponent, {
          width: '848px',
          panelClass: 'fb-dialog-wrapper2',
          data: {
            label: 'What would you like to name your planner?',
            isOnLoad: false,
            changeNameConfirmation: true,
            isInputRequired: true,
            inputLabel: 'Enter name',
          },
          disableClose: true,
        })
        .afterClosed()
        .subscribe((command) => {
          if (command) {
            this.plannerName = command.name;
            this.savePlanner(saveCommit);
          }
        });
    }
  };
  public savePlanner = (saveCommit?) => {
    const params = {
      userId: this.storage.get(USER_ID),
      fbTemplateId: FB_Template.Retail_InDirect,
    };
    this.configurationService.userFBForecastGetList(params).subscribe((res) => {
      if (res.find((x) => x.name == this.plannerName)) {
        this.dialog.open(ForecastConfirmationModalComponent, {
          width: '848px',
          panelClass: 'fb-dialog-wrapper2',
          data: {
            label:
              'Planner with this name already exists. Please update name and try again',
            isOnLoad: false,
            confirmation: true,
          },
          disableClose: true,
        });
      } else {
        const model = {
          itemIds: this.itemIds.toString(),
          channelIds: this.channelIds.toString(),
          calendarIds: this.calendarIds.toString(),
          userId: this.storage.get(USER_ID),
          name: this.plannerName,
          forecastMethodId: 38,
          seasonalitySourceLevelId: 8,
          movingAverageWeeksId: 20,
          fbTemplateId: FB_Template.Retail_InDirect,
          active: true,
          versionTypeId: this.versionTypeId,
        };
        this.spinner.show();
        this.configurationService.userFBForecastAdd(model).subscribe((res) => {
          this.savedPlanner = model;
          this.spinner.hide();
          this.versionTypeId = 3;
          this.userFBForecastId = res;
          this.isOpenSaved = true;
          this.isRestoreSession = false;
          this.isBuidlNew = false;
          const version = {
            ...this.data,
            version: model,
          };
          this.configurationService.savedPlannerOpened.next(version);
          if (saveCommit) {
            this.commitForecastPlanner();
          }
        });
      }
    });
  };
  public commitForecastPlanner = () => {
    this.ref = this.dialog.open(this.commitForecast, {
      width: '848px',
      panelClass: 'fb-dialog-wrapper2',
    });
  };
  public commitFacebookPlanner = () => {
    const model = {
      itemIds: this.itemIds.toString(),
      channelIds: this.channelIds.toString(),
      calendarIds: this.calendarIds.toString(),
      userId: this.storage.get(USER_ID),
      fbTemplateId: FB_Template.Retail_InDirect,
      forecastMethodId: 38,
      seasonalitySourceLevelId: 8,
      movingAverageWeeksId: 20,
      userFBForecastId: this.userFBForecastId || 0,
    };
    this.spinner.show();
    this.configurationService.FBForecastCommit(model).subscribe((res) => {
      this.spinner.hide();
      this.ref.close();
      this.dialog.open(ForecastConfirmationModalComponent, {
        width: '686px',
        panelClass: ['fb-dialog-wrapper2', 'fb-dialog-wrapper4'],
        data: {
          label: 'Congratulations! You have successfully committed!',
          isOnLoad: false,
          goBack: true,
        },
        disableClose: true,
      });
    });
  };
  checkIfMaxCountReached() {
    if (!this.isBuidlNew && !this.isRestoreSession) {
      this.save();
      return;
    }

    const params = {
      userId: this.storage.get(USER_ID),
      fbTemplateId: FB_Template.Retail_InDirect,
    };
    this.configurationService.userFBForecastGetList(params).subscribe((res) => {
      if (res.length >= this.maxPlannerCount) {
        this.dialog
          .open(ForecastDeletePlannerModalComponent, {
            width: '848px',

            data: { versionList: res, isInDirectInventory: true },
            disableClose: true,
          })
          .afterClosed()
          .subscribe((command) => {
            if (command && command.version) {
              let model = {
                versionTypeId: null,
                userFBForecastIds: '',
                fbTemplateId: FB_Template.Retail_InDirect,
              };
              model.versionTypeId = 3;
              for (var i = 0; i < command.version.length; i++) {
                model.userFBForecastIds =
                  model.userFBForecastIds +
                  command.version[i].userFBForecastId +
                  ',';
              }
              model.userFBForecastIds = model.userFBForecastIds.slice(0, -1);
              this.configurationService
                .userFBForecastDeleteByVersionID(model)
                .subscribe((resp) => {});
              this.save();
            }
          });
      } else {
        this.save();
      }
    });
  }
  onSelectionChanged(event) {
    if (event.value.includes(-1) > 0) {
      this.refreshMeasureIds = '';
    } else {
      this.refreshMeasureIds = event.value;
    }
  }
  isAnyOpened = () => {
    return document.getElementsByClassName('dropdown show').length > 0;
  };
  FBForecastSelectedUpdateByDropDownType = (type, value) => {
    let model;
    model = {
      fbForecastSelectedId: this.fbForecast.fbForecastSelectedId,
      updateDropDownType: type,
      UserId: this.storage.get(USER_ID),
      FBTemplateId: FB_Template.Retail_InDirect,
    };
    if (type === 'ForecastMethod') {
      model.ForecastMethodId = value.commonCodeValueId;
    } else if (type === 'SeasonalitySourceLevel') {
      model.SeasonalitySourceLevelId = value.commonCodeValueId;
    } else if (type === 'MovingAverageWeeks') {
      model.MovingAverageWeeksId = value.commonCodeValueId;
    }
    this.configurationService
      .FBForecastSelectedUpdateByDropDownType(model)
      .subscribe(
        (res) => {},
        (error) => {
          this.spinner.hide();
        }
      );
  };
  updateCriteria(event) {
    this.updatedDropdownObject = event;
    if (event.isSessionUpdate && !this.userFBForecastId) {
      this.isRestoreSession = true;
    } else if (event.isSessionUpdate && this.userFBForecastId) {
      this.isRestoreSession = false;
      this.isOpenSaved = true;
    }
    this.spinner.show();
    this.traverseSelectedOptions(event);
  }
  selectAccounts(distributor) {
    const accountIds = this.accountList
      .filter((row) => distributor.distributorId === row.distributorId)
      .map((row) => row.itemAccountId);
    this.selectedChannelIds = accountIds;
    this.channelIds = accountIds;
    this.dropdownObjects = {
      itemIds: this.itemIds || [],
      channelIds: this.channelIds,
      calendarIds: this.calendarIds || [],
    };
    if (!this.selectedDistibutor) {
      this.selectedDistibutor = distributor;
    }
    this.selectedAccounts = this.accountList.filter(
      (row) => distributor.distributorId === row.distributorId
    );
  }
  filterDistributors(value) {
    this.distributorList = this.tempDistributorList
      .filter(
        (row) =>
          row.distributorName
            .toLocaleLowerCase()
            .indexOf(value.toLocaleLowerCase()) > -1
      )
      .sort((a, b) => (a.distributorName > b.distributorName ? 1 : -1));
  }
  displayFn(value) {
    return value?.distributorName;
  }
  refreshMeasure() {
    this.dialog
      .open(ForecastConfirmationModalComponent, {
        width: '848px',
        panelClass: 'fb-dialog-wrapper2',
        data: {
          label:
            'Are you sure you want to refresh these measures? Your current data will be lost.',
          isOnLoad: false,
          refreshMeasure: true,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((command) => {
        if (command) {
          this.refreshForecastMeasures();
        }
      });
  }
  public refreshForecastMeasures = () => {
    const model = {
      itemIds: this.dropdownObjects.itemIds.toString(),
      channelIds: this.dropdownObjects.channelIds.toString(),
      calendarIds: this.dropdownObjects.calendarIds.toString(),
      userId: this.storage.get(USER_ID),
      measureIds: this.refreshMeasureIds.toString(),
      userFBForecastId: this.userFBForecastId,
      fbTemplateId: FB_Template.Retail_InDirect,
      versionTypeId: this.versionTypeId,
      distiId: this.selectedDistibutor.distributorId,
      fbForecastSelectedId: this.fbForecast ? this.fbForecast.fbForecastSelectedId: '',
    };
    const measureCodes = this.measureList
      .filter((row) => this.refreshMeasureIds.includes(row.measureId))
      .map((el) => el.code);
      this.spinner.show();
    this.configurationService
      .DefaultAddRefresh(model)
      .subscribe((res) => {
        this.traverseSelectedOptions(this.updatedDropdownObject);

      }, error => {
        this.spinner.hide();
      });
  };
}
