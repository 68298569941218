export const generateYearlyGridColumns = () => {
    const gridColumnDefs = [];

    const yearObj: any = {
        headerName: 'Year',
        field: 'name',
        width: 200,
        minWidth: 200,
        resizable: false,
        editable: false,
        cellStyle: params => {
            return { color: 'black', backgroundColor: '#e5e5e5' };
        }
    };
    gridColumnDefs.push(yearObj);

    const fy2020Obj: any = {
        headerName: 'FY 2020',
        field: 'fy2020',
        width: 150,
        minWidth: 150,
        resizable: false,
        editable: false,
        cellRenderer: function (params) {
            return params.data.fy2020 ? Number(params.data.fy2020).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
        },
        cellStyle: params => {
            return { color: 'black', backgroundColor: '#e5e5e5' };
        },
    };
    gridColumnDefs.push(fy2020Obj);

    const fy2021Obj: any = {
        headerName: 'FY 2021',
        field: 'fy2021',
        width: 150,
        minWidth: 150,
        resizable: false,
        editable: false,
        cellRenderer: function (params) {
            return params.data.fy2020 ? Number(params.data.fy2020).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
        },
        cellStyle: params => {
            return { color: 'black', backgroundColor: '#e5e5e5' };
        }
    };
    gridColumnDefs.push(fy2021Obj);

    const fy2022Obj: any = {
        headerName: 'FC 2022',
        field: 'fy2022',
        width: 150,
        minWidth: 150,
        resizable: false,
        editable: params => {
            if (['Revenue', 'SS Growth', 'GMROI %'].includes(params.data.name)) {
                return true;
            } else return false;
        },
        cellStyle: params => {
            if (['Revenue', 'SS Growth', 'GMROI %'].includes(params.data.name)) {
                return
            } else return { color: 'black', backgroundColor: '#e5e5e5' };
        },
        valueFormatter: params => {
            return params.data.fy2022 ? Number(params.data.fy2022).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''
        }
    };
    gridColumnDefs.push(fy2022Obj);

    return gridColumnDefs;
}

export const generateQuarterlyGridColumns = () => {
    const quarters = [
        {
            id: 'q1',
            name: 'Q1 (Jan-Mar)'
        },
        {
            id: 'q2',
            name: 'Q2 (Apr-Jun)'
        },
        {
            id: 'q3',
            name: 'Q3 (Jul-Sep)'
        },
        {
            id: 'q4',
            name: 'Q4 (Oct-Dec)'
        }
    ]
    const columnDefs = [
        {
            field: 'year',
            headerName: 'Years',
            pinned: 'left',
            rowSpan: function (params) {
                if ((params.data.year === 'FY 2022' || params.data.year === 'FY 2021') && params.data.measure == 'TY TD Revenue $$') {
                    return 4
                } else if (params.data.year === 'FY 2020' && params.data.measure == 'TY TD Revenue $$') {
                    return 3
                } else return 0
            },
            cellStyle: params => {
                return { color: 'black', backgroundColor: '#e5e5e5' };
            }
        },
        {
            field: 'measure',
            headerName: 'Measures',
            cellStyle: params => {
                return { color: 'black', backgroundColor: '#e5e5e5' };
            }
        },
        {
            field: 'totals',
            headerName: 'Totals',
            valueFormatter: params => {
                return params.data.totals ? Number(params.data.totals).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''
            },
            cellStyle: params => {
                return { color: 'black', backgroundColor: '#e5e5e5' };
            }
        }
    ];
    quarters.forEach(q => {
        const obj = {
            field: q.id,
            headerName: q.name,
            editable: params => {
                if (params.data.editable) {
                    return true;
                } else return false;
            },
            cellStyle: params => {
                if (params.data.editable) {
                    return;
                } else {
                    return { color: 'black', backgroundColor: '#e5e5e5' };
                }
            },
            valueFormatter: params => {
                return params.data[q.id] ? Number(params.data[q.id]).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''
            }
        };
        columnDefs.push(obj);
    });
    return columnDefs;
}
