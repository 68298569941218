<mat-accordion>
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Data Module&ensp;(<small><b>{{fileName + ' - ' + versionName}}</b></small>)
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container-fluid" style="margin-top: 15px;">
            <!-- FILTERS -->
            <div class="d-flex">
                <button mat-raised-button color="accent" (click)="openConfirmationDialog('reset')">Reset to Original</button>
                <button mat-stroked-button color="accent" class="ml-5" (click)="openConfirmationDialog('clear')">Clear Data</button>
                <button mat-raised-button color="accent" class="ml-2" [disabled]="!changesMade" (click)="saveChanges()">Save Data</button>
                <button mat-raised-button color="accent" class="ml-5" [disabled]="selectedGridNodes.length == 0"
                    (click)="openConfirmationDialog('delete')">Delete</button>
                <button mat-raised-button color="accent" class="ml-2"
                    (click)="replaceBlanksWithZeros()">REPLACE BLANKS WITH ZEROS</button>
                <button mat-raised-button color="accent" class="ml-2" (click)="openPopUp(3)">Transform Data</button>
                <button mat-raised-button color="accent" class="ml-2" (click)="openPopUp(1)">Manage Exceptions</button>
                <button mat-raised-button color="accent" class="ml-5" matTooltip="Apply transformations, exceptions & outliers rules/changes" (click)="updateStats()">Calculate</button>
            </div>
            <div class="d-flex align-items-center justify-content-between my-3" style="flex-wrap: wrap;">
                <div class="d-flex align-items-center" *ngIf="!isVariableView" style="flex-wrap: wrap;">
                    <mat-slide-toggle (change)="loadFileData()" [(ngModel)]="showFaulty" labelPosition="before"
                        color="warn">
                        Show Faulty
                    </mat-slide-toggle>
                    <mat-slide-toggle (change)="loadFileData()" [(ngModel)]="showDuplicates" labelPosition="before"
                        class="ml-5" color="warn">
                        Show Duplicates
                    </mat-slide-toggle>
                    <mat-slide-toggle (change)="loadFileData()" [(ngModel)]="showBlanks" labelPosition="before"
                        class="ml-5" color="warn">
                        Show Blanks
                    </mat-slide-toggle>
                    <mat-slide-toggle (change)="loadFileData()" [(ngModel)]="showExceptions" labelPosition="before"
                        class="ml-5" color="warn">
                        Show Exceptions
                    </mat-slide-toggle>
                    <mat-slide-toggle (change)="loadFileData()" [(ngModel)]="showOutliers" labelPosition="before"
                        class="ml-5" color="warn">
                        Show Outliers
                    </mat-slide-toggle>
                    <mat-slide-toggle (change)="loadFileData()" [(ngModel)]="showValid" labelPosition="before"
                        class="ml-5" color="warn">
                        Show Valid
                    </mat-slide-toggle>
                </div>
                <div class="d-flex" *ngIf="isVariableView"></div>
                <div class="d-flex align-items-center">
                    <label class="mb-0 mx-5" style="font-weight: 500;">Use Toggle to Modify Variable Details</label>
                    <h6 class="mb-0 mx-2">Data View</h6>
                    <mat-slide-toggle color="warn" [(ngModel)]="isVariableView"></mat-slide-toggle>
                    <h6 class="mb-0 mx-2">Variable View</h6>
                </div>
            </div>
            <h6 *ngIf="!isVariableView && gridApi" class="d-flex align-items center text-danger mx-2 justify-content-end">{{selectedGridNodes.length}} out of {{gridApi?.getDisplayedRowCount()}} rows selected
                <mat-icon (click)="clearFilter()" class="cursor-pointer ml-5" matTooltip="Clear Filters">clear_all</mat-icon>
            </h6>

            <!-- GRID VIEW-->
            <div [ngStyle]="isVariableView ? {width: '0', 'height': '0'} : {'height': '100%', 'display': 'flex', 'flex-direction': 'column'}" class="all-show-grid mt-3">
                <ag-grid-angular
                        style="flex: 1 1 auto;"
                        #topGrid [suppressMenuHide]="true"
                        suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
                        [components]="frameworkComponents"
                        class="ag-theme-material"
                        [modules]="modules"
                        [rowModelType]="rowModelType"
                        [serverSideStoreType]="serverSideStoreType" [pagination]="true"
                        (cellValueChanged)="gridDataUpdated($event)" [stopEditingWhenGridLosesFocus]="true"
                        [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize" [rowSelection]="rowSelection"
                        (selectionChanged)="onSelectionChanged($event)"
                        [gridOptions]="topOptions"
                        id="topGridDataModule"
                        (gridReady)="onGridReady($event)"
                        [columnDefs]="gridColumnDefs">
                </ag-grid-angular>

                <ag-grid-angular
                        style="flex: none; height: 445px;"
                        #bottomGrid
                        suppressCellSelection
                        [frameworkComponents]="secondFrameworkComponents"
                        class="bottomGrid ag-theme-material"
                        [modules]="modules"
                        [rowData]="bottomGridData"
                        [gridOptions]="bottomOptions"
                        headerHeight="0"
                        (gridReady)="onSecondGridReady($event)"
                        [rowStyle]="{ fontWeight: 'bold' }"
                        [columnDefs]="bottomGridColumns"
                        id="bottomGrid"
                        (cellClicked)="onCellClicked($event)">
                </ag-grid-angular>
            </div>

            <!-- VARIABLE VIEW -->
            <div class="variableView ml-2" *ngIf="isVariableView && gridColumnDefs.length">
                <div class="d-flex headerData">
                    <h6 class="srNo mb-0"><strong>#</strong></h6>
                    <h6 class="fieldName ml-3 mb-0"><strong>Field Name</strong></h6>
                    <h6 class="fieldName ml-3 mb-0"><strong>Field Type</strong></h6>
                    <h6 class="fieldName mb-0 text-center" style="width: 120px;"><strong>Unique ID</strong></h6>
                    <h6 class="fieldName mb-0 text-center" style="width: 120px;"><strong>Group By</strong></h6>
                </div>
                <div style="max-height: 50vh; overflow-y: auto;">
                    <div class="d-flex align-items-center rowData">
                        <div class="d-flex align-items-center" style="margin-left: 267px;">
                            <h6 class="mb-0 mr-2">Attribute</h6>
                            <app-three-state-custom-toggle-button [disabled]="versionNo==1" [toggleValue]="toggleAll" (toggleChanged)="toggleChanged($event)"></app-three-state-custom-toggle-button>
                            <h6 class="mb-0 ml-3">Measure</h6>
                        </div>
                    </div>
                    <ng-container
                    *ngFor="let colItem of gridColumnDefs; let i=index">
                        <div class="d-flex align-items-center rowData mb-2" *ngIf="colItem.headerName">
                            <h6 class="mb-0 srNo">{{i}}</h6>
                            <h6 class="mb-0 fieldName ml-3 d-flex align-items-center justify-content-between">
                                {{colItem.headerName}}<mat-icon (click)="updateName(colItem)" matTooltip="Edit name"
                                    class="mx-2">edit</mat-icon>
                            </h6>
                            <div class="d-flex ml-2 align-items-center">
                                <h6 class="mb-0 mx-2">Attribute</h6>
                                <mat-slide-toggle [disabled]="versionNo==1" color="warn" [(ngModel)]="colItem.isMetricVariable" (change)="changesMade=true; slideToggleChanged();"></mat-slide-toggle>
                                <h6 class="mb-0 ml-2">Measure</h6>
                            </div>

                            <div class="d-flex ml-5 align-items-center justify-content-center" style="width: 120px;">
                                <mat-radio-button [disabled]="versionNo==1" value="{{colItem.field}}" [checked]="colItem.field==selectedUniqueID"
                                    (change)="radioChanged($event)" name="uniqueIDgroup"></mat-radio-button>
                            </div>
                            <div class="d-flex align-items-center justify-content-center" style="width: 120px;">
                                <mat-checkbox [disabled]="versionNo==1" [checked]="groupBy === colItem.field"
                                    (change)="checkboxChanged(colItem.field, $event)"></mat-checkbox>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <hr class="theme-hr"/>

            <!-- CORRELATION THRESHHOLD -->
            <div class="row">
                <div class="col-md-5">
                    <strong>Correlation threshhold</strong>
                    <div class="d-flex align-items-center w-100">
                        <mat-form-field appearance="fill" style="width: 50% !important;">
                            <input matInput readonly [value]="correlationValue | number : '1.0-2'" />
                        </mat-form-field>
                        <mat-icon class="add-minus-btn cursor-pointer ml-2" (click)="updateCorrelationValue(0)">remove
                        </mat-icon>
                        <mat-icon class="add-minus-btn cursor-pointer" (click)="updateCorrelationValue(1)">add
                        </mat-icon>
                        <button class="ml-2" (click)="saveCorrelationValue()" [disabled]="!correlationUpdated" mat-raised-button color="accent">UPDATE</button>
                    </div>
                </div>
                <div class="col-md-7"></div>
            </div>

            <!-- STATS -->
            <div class="d-flex w-100" style="overflow-x: auto;">
                <table class="outliers-table">
                    <tr>
                        <th>Correlation Matrix</th>
                        <th *ngFor="let item of correlationMatrix | keyvalue">{{returnVariableLabel(item.key)}}</th>
                    </tr>
                    <tr *ngFor="let item of correlationMatrix | keyvalue">
                        <td>{{returnVariableLabel(item.key)}}</td>
                        <td [ngClass]="getCorrelationClass(itemValue.value)" *ngFor="let itemValue of item.value | keyvalue">{{itemValue.value}}</td>
                    </tr>
                </table>
            </div>

            <hr class="theme-hr" />

            <!-- NUMBERS -->
            <div class="membership-items" *ngIf="dataStats">
                <div class="right">
                    <div class="cluster-item-div">
                        <label>Total Rows</label>
                        <div>{{dataStats.total_rows}}</div>
                    </div>
                    <div class="cluster-item-div">
                        <label>Valid Rows</label>
                        <div>{{dataStats.valid_rows}}</div>
                    </div>
                    <div class="cluster-item-div">
                        <label>Rows with Outliers</label>
                        <div>{{dataStats.outliers_rows}}</div>
                    </div>
                    <div class="cluster-item-div">
                        <label>Rows with Duplicates</label>
                        <div>{{dataStats.duplicate_rows}}</div>
                    </div>
                    <div class="cluster-item-div">
                        <label>Rows with Exceptions</label>
                        <div>{{dataStats.exception_rows}}</div>
                    </div>
                    <div class="cluster-item-div">
                        <label>Rows with Blanks</label>
                        <div>{{dataStats.blank_rows}}</div>
                    </div>
                    <div class="cluster-item-div">
                        <label>Rows with Faulty Data</label>
                        <div>{{dataStats.faulty_rows}}</div>
                    </div>
                </div>
            </div>

            <!-- QUESTIONS & NEXT BUTTON -->
            <div class="row mt-3">
                <div class="col-12">
                    <mat-checkbox labelPosition="before" color="warn" [(ngModel)]="excludeOutliers">
                        Do you want to exclude outliers?
                    </mat-checkbox>
                </div>
                <div class="col-12">
                    <mat-checkbox labelPosition="before" color="warn" [(ngModel)]="excludeExceptions">
                        Do you want to exclude exceptions?
                    </mat-checkbox>
                </div>
                <div class="col-12">
                    <mat-checkbox labelPosition="before" color="warn" [(ngModel)]="excludeDuplicates">
                        Do you want to remove duplicates?
                    </mat-checkbox>
                </div>
                <div class="row justify-content-end w-100">
                    <button (click)="saveAs()" mat-raised-button color="accent">SAVE AS</button>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
