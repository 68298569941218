import { Component, OnInit } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { waitForAsync } from '@angular/core/testing';
import { throwError } from 'rxjs';
import { formatNumber } from '@angular/common';
import { ConfigurationService } from 'src/services/configuration.service';
@Component({
  selector: 'app-summary-by-year',
  templateUrl: './summary-by-year.component.html',
  styleUrls: ['./summary-by-year.component.css']
})
export class SummaryByYearComponent implements OnInit {
  public columnDefs = [
    {
      headerName: 'Channel',
      field: 'channelName',
      pinned: 'left',
      width: 120
    }
  ];
  public columnDefsPercentage = [];
  public columnDefsVariation = [];
  public columnDefsPercentageVariation = [];
  public gridApi: any;
  public channelList = [];
  public gridOptions: any;
  public gridOptionsPercentage: any;
  public gridOptionsPercentageVariation: any;
  public gridOptionsVariation: any;
  public getRowHeight;
  public headerHeight;
  public goalTenureTypes = [];
  public gridData = [];
  public gridList = [];
  public gridVariationList = [];
  public gridPercentageList = [];
  public gridPercentageListVariation = [];
  public modules = AllCommunityModules;
  constructor(public configurationService: ConfigurationService) {
    this.gridOptions = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.gridOptionsPercentage = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.gridOptionsVariation = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.gridOptionsPercentageVariation = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.headerHeight = 30;
    this.gridOptions.getRowHeight = function(params) {
      return 30;
    };
    this.gridOptionsPercentage.getRowHeight = function(params) {
      return 30;
    };
    this.gridOptionsVariation.getRowHeight = function(params) {
      return 30;
    };
    this.gridOptionsPercentageVariation.getRowHeight = function(params) {
      return 30;
    };
  }

  ngOnInit() {
    this.populateList();
  }
  currencyFormatter(params) {
    // return `${formatNumber(params.value, 'en', '2.0-2')}`;
    return `${Math.floor(typeof params.value === 'number' ? params.value : 0)}%`;
  }
  currencyFormatterVaraition(params) {
    return `${Math.floor(typeof params.value === 'number' ? params.value : 0)}`;
  }
  public populateList = () => {
    // Get All Channels
    this.configurationService.getAllChannels({ clientId: 1 }).subscribe((response) => {
      this.channelList = response.data;
      if (this.channelList.length > 0) {
        this.channelList.forEach(async (channel: any) => {
          const channelObject = { channelName: channel.channelName };
          const tempTenureList = [];
          const tenureTypeList = await this.configurationService.getGoalTenureTypeList({ channelId: channel.channelId, clientId: 1, active: true }).toPromise().catch(error => { this.gridList.push({ channelName: channel.channelName }); return throwError(error); });
          // Tenure Type List
          if (tenureTypeList && tenureTypeList.length > 0) {
            this.goalTenureTypes = tenureTypeList;
            this.goalTenureTypes.forEach(tenureType => {
              const year = tenureType && tenureType.name ? tenureType.name.split('-')[1] : '';
              if (year && !tempTenureList.includes(year)) {
                tempTenureList.push({ field: `Customer${year}`, name: year });
              }
            });
            if (tempTenureList.length > 0) {
              tempTenureList.forEach(column => {
                if (!this.columnDefs.find(columnDef => columnDef && columnDef.field === column.field)) {
                  (this.columnDefs as any).push({
                    headerName: column.name,
                    field: column.field,
                    width: 80
                  });
                }
                channelObject[column.field] = 0;
              });
              if (this.columnDefs.length > 1) {
                this.gridOptions.api.setColumnDefs(this.columnDefs);
              }
            }
          }
          // Goals
          const values = await this.configurationService.getGoalList({ channelId: channel.channelId, clientId: 1, }).toPromise().catch(error => {
            if (this.channelList.length > 0 && this.gridList.length === this.channelList.length) {
              this.mapTotalObject();
            }
            return throwError(error);
          });
          if (values && values.pivotList && values.pivotList.length > 0) {
            const listToSum = ['LFL Sales', 'Normalised NPI on SS', 'Category Initiatives', 'NS Normalized Sales', 'New Store Normalised Sales'];
            const gridData = values.pivotList;
            this.gridData.push(values.pivotList);
            this.gridData = (this.gridData as any).flat();
            gridData.forEach(goal => {
              this.columnDefs.forEach(column => {
                Object.keys(goal).forEach(key => {
                  if (key.substring(7, 11) === column.headerName && listToSum.includes(goal.GoalType.name)) {
                    if (column.field in channelObject) {
                      channelObject[column.field] += goal[key] ? goal[key] : 0;
                    }
                  }
                });
              });
            });
            this.gridList.push(channelObject);
            if (this.channelList.length > 0 && this.gridList.length === this.channelList.length) {
              this.mapTotalObject();
            }
          } else {
            this.gridList.push({ channelName: channel.channelName });
            if (this.channelList.length > 0 && this.gridList.length === this.channelList.length) {
              this.mapTotalObject();
            }
          }
        });
      }
    });
  }
  public mapTotalObject = () => {
    const totalObject: any = {};
    this.gridList.forEach(row => {
      Object.keys(row).forEach(key => {
        totalObject.channelName = 'Total';
        if (key !== 'channelName') {
          totalObject[key] = totalObject[key] !== null && totalObject[key] !== undefined ? totalObject[key] + row[key] : row[key];
        }
      });
    });
    this.gridList.push(totalObject);
    this.gridList.forEach(goal => {
      this.columnDefs.forEach(column => {
        if (column.field in goal) {

        } else {
          goal[column.field] = goal[column.field] ? goal[column.field] : 0;
        }
      });
    });
    this.gridOptions.api.setRowData(this.gridList);
    this.mapPercentageList(JSON.parse(JSON.stringify(this.gridList)));
    this.mapVariationList(JSON.parse(JSON.stringify(this.gridList)));
  }
  public mapVariationList = (goalList: any) => {
    const keys = ['channelName'];
    for (let i = 0; i < goalList.length; i++) {
      const keyList = Object.keys(goalList[i]);
      for (let j = 1; j < keyList.length; j++) {
        if (goalList[i][keyList[j]] && goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = goalList[i][keyList[j + 1]] > 0 ? ((goalList[i][keyList[j]] - goalList[i][keyList[j + 1]])) : goalList[i][keyList[j + 1]];
        } else if (goalList[i][keyList[j]] && !goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = keyList[j] !== 'channelName' ?  goalList[i][keyList[j]] : goalList[i][keyList[j]] ? goalList[i][keyList[j]] : 0;
        }
      }
      this.gridVariationList.push(goalList[i]);
      this.gridOptionsVariation.api.setRowData(this.gridVariationList);
    }
    this.mapPercentageListVariation(JSON.parse(JSON.stringify((this.gridVariationList))));
    this.columnDefsVariation = this.columnDefs.map(column => (column.field !== 'channelName' ? {
      ...column,
      valueFormatter: this.currencyFormatterVaraition
    } : { ...column }));
  }
  public mapPercentageListVariation = (goalList: any) => {
    const keys = ['channelName'];
    for (let i = 0; i < goalList.length; i++) {
      const keyList = Object.keys(goalList[i]);
      for (let j = 1; j < keyList.length; j++) {
        if (goalList[i][keyList[j]] && goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = goalList[i][keyList[j + 1]] > 0 ? (((goalList[i][keyList[j]] - goalList[i][keyList[j + 1]]) / goalList[i][keyList[j + 1]]) * 100) : goalList[i][keyList[j + 1]];
        } else if (goalList[i][keyList[j]] && !goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = keyList[j] !== 'channelName' ? 0 : goalList[i][keyList[j]] ? goalList[i][keyList[j]] : 0;
        }
      }
      this.gridPercentageListVariation.push(goalList[i]);
      this.gridOptionsPercentageVariation.api.setRowData(this.gridPercentageListVariation);
    }
    this.columnDefsPercentageVariation = this.columnDefs.map(column => (column.field !== 'channelName' ? {
      ...column,
      valueFormatter: this.currencyFormatter
    } : { ...column }));
  }
  public mapPercentageList = (goalList: any) => {
    const keys = ['channelName'];
    for (let i = 0; i < goalList.length; i++) {
      const keyList = Object.keys(goalList[i]);
      for (let j = 1; j < keyList.length; j++) {
        if (goalList[i][keyList[j]] && goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = goalList[i][keyList[j + 1]] > 0 ? (((goalList[i][keyList[j]] - goalList[i][keyList[j + 1]]) / goalList[i][keyList[j + 1]]) * 100) : goalList[i][keyList[j + 1]];
        } else if (goalList[i][keyList[j]] && !goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = keyList[j] !== 'channelName' ? 0 : goalList[i][keyList[j]] ? goalList[i][keyList[j]] : 0;
        }
      }
      this.gridPercentageList.push(goalList[i]);
      this.gridOptionsPercentage.api.setRowData(this.gridPercentageList);
    }
    this.columnDefsPercentage = this.columnDefs.map(column => (column.field !== 'channelName' ? {
      ...column,
      valueFormatter: this.currencyFormatter
    } : { ...column }));
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

}
