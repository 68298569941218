import { Component, OnInit } from '@angular/core';
import { generateGridColumns } from './agGridConfig';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { topDownGridData } from '../../mockData';
import { MatDialog } from '@angular/material/dialog';
import { ImportTargetsDialogComponent } from './import-targets-dialog/import-targets-dialog.component';
import { MfpService } from 'src/services/mfp-services/mfp.service';

@Component({
  selector: 'app-top-down-planning',
  templateUrl: './top-down-planning.component.html',
  styleUrls: ['./top-down-planning.component.scss']
})
export class TopDownPlanningComponent implements OnInit {

  selectedBrand = '1';
  brands = [
    {
      id: '1',
      name: 'Nike'
    },
    {
      id: '2',
      name: 'Bata'
    },
    {
      id: '3',
      name: 'Outfitters'
    },
    {
      id: '4',
      name: 'Levis'
    }
  ]

  selectedGender = '1';
  genders = [
    {
      id: '1',
      name: 'Male'
    },
    {
      id: '2',
      name: 'Female'
    }
  ];

  selectedCategory = '1';
  categories = [
    {
      id: '1',
      name: 'Formal'
    },
    {
      id: '2',
      name: 'Casual'
    },
    {
      id: '3',
      name: 'Royal'
    }
  ];

  selectedDuration = '1';
  durations = [
    {
      id: '1',
      name: 'Fiscal Year'
    },
    {
      id: '2',
      name: 'H1'
    },
    {
      id: '3',
      name: 'H2'
    },
    {
      id: '4',
      name: 'Q1'
    },
    {
      id: '5',
      name: 'Q2'
    },
    {
      id: '6',
      name: 'Q3'
    },
    {
      id: '7',
      name: 'Q4'
    }
  ];

  revenue = true;
  cost = false;
  unit = false;
  radioValue = '1';

  // Grid Variables
  public gridColumnDefs = generateGridColumns(this.selectedDuration);
  public modules = AllCommunityModules;
  private gridApi;
  frameworkComponents = {};

  gridData = topDownGridData;

  filteredGridData = [];

  dataSource = {
    chart: {
      caption: "Month Level Targets",
      exportenabled: "0",
      theme: "fusion"
    },
    categories: [
      {
        category: [
          {
            label: "Jan"
          },
          {
            label: "Feb"
          },
          {
            label: "Mar"
          },
          {
            label: "Apr"
          },
          {
            label: "May"
          },
          {
            label: "Jun"
          },
          {
            label: "Jul"
          },
          {
            label: "Aug"
          },
          {
            label: "Sep"
          },
          {
            label: "Oct"
          },
          {
            label: "Nov"
          },
          {
            label: "Dec"
          }
        ]
      }
    ],
    dataset: [
      {
        seriesname: "TY TD Revenue $$",
        renderas: "line",
        data: []
      },
      {
        seriesname: "Adj. LY Revenue",
        renderas: "line",
        data: []
      }
    ]
  };

  width = 1100;
  height = 600;
  type = "mscombi2d";
  dataFormat = "json";

  constructor(private dialog: MatDialog, private mfpService: MfpService) { }

  ngOnInit(): void {
    this.gridData[0].totals = this.mfpService.yearlyTotal as any;
  }

  importTargets() {
    const columnsDialogRef = this.dialog.open(ImportTargetsDialogComponent, {
      width: '450px',
      disableClose: true
    });

    columnsDialogRef.afterClosed().subscribe(result => {
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.mfpService.updateTotal
      .subscribe(() => {
        this.gridData[0].totals = this.mfpService.yearlyTotal as any;
        this.cellValueChanged();
      });
    setTimeout(() => {
      this.cellValueChanged(true);
    }, 500);
  }

  durationChanged() {
    this.gridData[0].totals = this.mfpService.yearlyTotal as any;
    this.cellValueChanged(true);
  }

  cellValueChanged(type = false) {
    this.gridApi.setColumnDefs([]);
    const monthsArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    setTimeout(() => {
      let totalRevenue = 0;
      // this.mfpService.yearlyTotal = 0;
      monthsArray.forEach((m, index) => {
        this.gridData[0][m.toLowerCase()] = (Number(this.gridData[0].totals) * Number(this.gridData[1][m.toLowerCase()])) / 100;
        // this.gridData[1][m.toLowerCase()] = (Number(this.gridData[0][m.toLowerCase()]) / Number(this.gridData[0].totals) * 100) as any;
        this.gridData[2][m.toLowerCase()] = ((Number(this.gridData[0][m.toLowerCase()]) / Number(this.gridData[16][m.toLowerCase()]) - 1) * 100) as any;
        this.gridData[3][m.toLowerCase()] = (Number(this.gridData[0][m.toLowerCase()]) / Number(this.gridData[4][m.toLowerCase()])) as any;
        this.gridData[5][m.toLowerCase()] = (Number(this.gridData[0][m.toLowerCase()]) * (1 - (Number(this.gridData[6][m.toLowerCase()]) / 100))) as any;
        index > 0 && (this.gridData[7][m.toLowerCase()] = (Number(this.gridData[7][monthsArray[index - 1].toLowerCase()]) + Number(this.gridData[9][m.toLowerCase()]) - Number(this.gridData[5][m.toLowerCase()])) as any);
        index > 0 && (this.gridData[8][m.toLowerCase()] = (Number(this.gridData[8][monthsArray[index - 1].toLowerCase()]) + Number(this.gridData[10][m.toLowerCase()]) - Number(this.gridData[3][m.toLowerCase()])) as any);
        this.gridData[10][m.toLowerCase()] = (Number(this.gridData[9][m.toLowerCase()]) / Number(this.gridData[11][m.toLowerCase()])) as any;
        this.gridData[18][m.toLowerCase()] = (Number(this.gridData[4][m.toLowerCase()]) * 0.95) as any;
        this.gridData[17][m.toLowerCase()] = (Number(this.gridData[16][m.toLowerCase()]) / Number(this.gridData[18][m.toLowerCase()])) as any;
        this.gridData[19][m.toLowerCase()] = (Number(this.gridData[16][m.toLowerCase()]) / Number(this.gridData[16].totals) * 100) as any;

        let monthsArrayTemp = [];
        if (this.selectedDuration == '1') {
          monthsArrayTemp = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        } else if (this.selectedDuration == '2') {
          monthsArrayTemp = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
        } else if (this.selectedDuration == '3') {
          monthsArrayTemp = ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        } else if (this.selectedDuration == '4') {
          monthsArrayTemp = ['Jan', 'Feb', 'Mar'];
        } else if (this.selectedDuration == '5') {
          monthsArrayTemp = ['Apr', 'May', 'Jun'];
        } else if (this.selectedDuration == '6') {
          monthsArrayTemp = ['Jul', 'Aug', 'Sep'];
        } else if (this.selectedDuration == '7') {
          monthsArrayTemp = ['Oct', 'Nov', 'Dec'];
        }
        if (monthsArrayTemp.indexOf(m) > -1)
          totalRevenue = totalRevenue + Number(this.gridData[0][m.toLowerCase()]);
        // this.mfpService.yearlyTotal = this.mfpService.yearlyTotal + Number(this.gridData[0][m.toLowerCase()]);
      });
      type && this.mfpService.updateTotal.next(this.mfpService.yearlyTotal);
      this.gridData[0].totals = totalRevenue as any;

      this.gridData[2].totals = ((Number(this.gridData[0].totals) / Number(this.gridData[16].totals) - 1) * 100) as any;

      this.gridData[7].jan = (1530000 + Number(this.gridData[9].jan) - Number(this.gridData[5].jan)) as any;
      this.gridData[7].feb = (Number(this.gridData[7].jan) + Number(this.gridData[9].feb) - Number(this.gridData[5].feb)) as any;
      this.gridData[7].mar = (Number(this.gridData[7].feb) + Number(this.gridData[9].mar) - Number(this.gridData[5].mar)) as any;
      this.gridData[7].apr = (Number(this.gridData[7].mar) + Number(this.gridData[9].apr) - Number(this.gridData[5].apr)) as any;
      this.gridData[7].may = (Number(this.gridData[7].apr) + Number(this.gridData[9].may) - Number(this.gridData[5].may)) as any;
      this.gridData[7].jun = (Number(this.gridData[7].may) + Number(this.gridData[9].jun) - Number(this.gridData[5].jun)) as any;
      this.gridData[7].jul = (Number(this.gridData[7].jun) + Number(this.gridData[9].jul) - Number(this.gridData[5].jul)) as any;
      this.gridData[7].aug = (Number(this.gridData[7].jul) + Number(this.gridData[9].aug) - Number(this.gridData[5].aug)) as any;
      this.gridData[7].sep = (Number(this.gridData[7].aug) + Number(this.gridData[9].sep) - Number(this.gridData[5].sep)) as any;
      this.gridData[7].oct = (Number(this.gridData[7].sep) + Number(this.gridData[9].oct) - Number(this.gridData[5].oct)) as any;
      this.gridData[7].nov = (Number(this.gridData[7].oct) + Number(this.gridData[9].nov) - Number(this.gridData[5].nov)) as any;
      this.gridData[7].dec = (Number(this.gridData[7].nov) + Number(this.gridData[9].dec) - Number(this.gridData[5].dec)) as any;

      this.gridData[8].jan = (2186 + Number(this.gridData[10].jan) - Number(this.gridData[3].jan)) as any;
      this.gridData[8].feb = (Number(this.gridData[8].jan) + Number(this.gridData[10].feb) - Number(this.gridData[3].feb)) as any;
      this.gridData[8].mar = (Number(this.gridData[8].feb) + Number(this.gridData[10].mar) - Number(this.gridData[3].mar)) as any;
      this.gridData[8].apr = (Number(this.gridData[8].mar) + Number(this.gridData[10].apr) - Number(this.gridData[3].apr)) as any;
      this.gridData[8].may = (Number(this.gridData[8].apr) + Number(this.gridData[10].may) - Number(this.gridData[3].may)) as any;
      this.gridData[8].jun = (Number(this.gridData[8].may) + Number(this.gridData[10].jun) - Number(this.gridData[3].jun)) as any;
      this.gridData[8].jul = (Number(this.gridData[8].jun) + Number(this.gridData[10].jul) - Number(this.gridData[3].jul)) as any;
      this.gridData[8].aug = (Number(this.gridData[8].jul) + Number(this.gridData[10].aug) - Number(this.gridData[3].aug)) as any;
      this.gridData[8].sep = (Number(this.gridData[8].aug) + Number(this.gridData[10].sep) - Number(this.gridData[3].sep)) as any;
      this.gridData[8].oct = (Number(this.gridData[8].sep) + Number(this.gridData[10].oct) - Number(this.gridData[3].oct)) as any;
      this.gridData[8].nov = (Number(this.gridData[8].oct) + Number(this.gridData[10].nov) - Number(this.gridData[3].nov)) as any;
      this.gridData[8].dec = (Number(this.gridData[8].nov) + Number(this.gridData[10].dec) - Number(this.gridData[3].dec)) as any;

      this.gridData[12].jan = (Number(this.gridData[7].jan) / (Number(this.gridData[5].feb) + Number(this.gridData[5].mar) + Number(this.gridData[5].apr)) * 90) as any;
      this.gridData[12].feb = (Number(this.gridData[7].feb) / (Number(this.gridData[5].mar) + Number(this.gridData[5].apr) + Number(this.gridData[5].may)) * 90) as any;
      this.gridData[12].mar = (Number(this.gridData[7].mar) / (Number(this.gridData[5].apr) + Number(this.gridData[5].may) + Number(this.gridData[5].jun)) * 90) as any;
      this.gridData[12].apr = (Number(this.gridData[7].apr) / (Number(this.gridData[5].may) + Number(this.gridData[5].jun) + Number(this.gridData[5].jul)) * 90) as any;
      this.gridData[12].may = (Number(this.gridData[7].may) / (Number(this.gridData[5].jun) + Number(this.gridData[5].jul) + Number(this.gridData[5].aug)) * 90) as any;
      this.gridData[12].jun = (Number(this.gridData[7].jun) / (Number(this.gridData[5].jul) + Number(this.gridData[5].aug) + Number(this.gridData[5].sep)) * 90) as any;
      this.gridData[12].jul = (Number(this.gridData[7].jul) / (Number(this.gridData[5].aug) + Number(this.gridData[5].sep) + Number(this.gridData[5].oct)) * 90) as any;
      this.gridData[12].aug = (Number(this.gridData[7].aug) / (Number(this.gridData[5].sep) + Number(this.gridData[5].oct) + Number(this.gridData[5].nov)) * 90) as any;
      this.gridData[12].sep = (Number(this.gridData[7].sep) / (Number(this.gridData[5].oct) + Number(this.gridData[5].nov) + Number(this.gridData[5].dec)) * 90) as any;
      this.gridData[12].oct = (Number(this.gridData[7].oct) / (Number(this.gridData[5].nov) + Number(this.gridData[5].dec) + (Number(this.gridData[5].jan) * 1.1)) * 90) as any;
      this.gridData[12].nov = (Number(this.gridData[7].nov) / (Number(this.gridData[5].dec) + (Number(this.gridData[5].jan) * 1.1) + (Number(this.gridData[5].feb) * 1.1)) * 90) as any;
      this.gridData[12].dec = (Number(this.gridData[7].dec) / ((Number(this.gridData[5].jan) * 1.1) + (Number(this.gridData[5].feb) * 1.1) + (Number(this.gridData[5].mar) * 1.1)) * 90) as any;

      this.gridData[13].jan = (Number(this.gridData[8].jan) / (Number(this.gridData[3].feb) + Number(this.gridData[3].mar) + Number(this.gridData[3].apr)) * 90) as any;
      this.gridData[13].feb = (Number(this.gridData[8].feb) / (Number(this.gridData[3].mar) + Number(this.gridData[3].apr) + Number(this.gridData[3].may)) * 90) as any;
      this.gridData[13].mar = (Number(this.gridData[8].mar) / (Number(this.gridData[3].apr) + Number(this.gridData[3].may) + Number(this.gridData[3].jun)) * 90) as any;
      this.gridData[13].apr = (Number(this.gridData[8].apr) / (Number(this.gridData[3].may) + Number(this.gridData[3].jun) + Number(this.gridData[3].jul)) * 90) as any;
      this.gridData[13].may = (Number(this.gridData[8].may) / (Number(this.gridData[3].jun) + Number(this.gridData[3].jul) + Number(this.gridData[3].aug)) * 90) as any;
      this.gridData[13].jun = (Number(this.gridData[8].jun) / (Number(this.gridData[3].jul) + Number(this.gridData[3].aug) + Number(this.gridData[3].sep)) * 90) as any;
      this.gridData[13].jul = (Number(this.gridData[8].jul) / (Number(this.gridData[3].aug) + Number(this.gridData[3].sep) + Number(this.gridData[3].oct)) * 90) as any;
      this.gridData[13].aug = (Number(this.gridData[8].aug) / (Number(this.gridData[3].sep) + Number(this.gridData[3].oct) + Number(this.gridData[3].nov)) * 90) as any;
      this.gridData[13].sep = (Number(this.gridData[8].sep) / (Number(this.gridData[3].oct) + Number(this.gridData[3].nov) + Number(this.gridData[3].dec)) * 90) as any;
      this.gridData[13].oct = (Number(this.gridData[8].oct) / (Number(this.gridData[3].nov) + Number(this.gridData[3].dec) + (Number(this.gridData[3].jan) * 1.1)) * 90) as any;
      this.gridData[13].nov = (Number(this.gridData[8].nov) / (Number(this.gridData[3].dec) + (Number(this.gridData[3].jan) * 1.1) + (Number(this.gridData[3].feb) * 1.1)) * 90) as any;
      this.gridData[13].dec = (Number(this.gridData[8].dec) / ((Number(this.gridData[3].jan) * 1.1) + (Number(this.gridData[3].feb) * 1.1) + (Number(this.gridData[3].mar) * 1.1)) * 90) as any;

      this.filteredGridData = [];
      this.gridData.forEach((g, index) => {
        if (this.revenue && [0, 1, 2, 16, 19].indexOf(index) > -1) {
          this.filteredGridData.push(g);
        }
        else if (this.unit && [3, 4, 8, 10, 11, 13, 17, 18].indexOf(index) > -1) {
          this.filteredGridData.push(g);
        }
        else if (this.cost && [5, 6, 7, 9, 12, 14, 15].indexOf(index) > -1) {
          this.filteredGridData.push(g);
        }
      });
      this.gridColumnDefs = generateGridColumns(this.selectedDuration);
      this.gridApi.setColumnDefs(this.gridColumnDefs);
      this.gridApi.setRowData(this.filteredGridData);

      this.dataSource.dataset[0].data = [
        {
          value: this.gridData[0].jan
            .toString()
        },
        {
          value: this.gridData[0].feb
            .toString()
        },
        {
          value: this.gridData[0].mar
            .toString()
        },
        {
          value: this.gridData[0].apr
            .toString()
        },
        {
          value: this.gridData[0].may
            .toString()
        },
        {
          value: this.gridData[0].jun
            .toString()
        },
        {
          value: this.gridData[0].jul
            .toString()
        },
        {
          value: this.gridData[0].aug
            .toString()
        },
        {
          value: this.gridData[0].sep
            .toString()
        },
        {
          value: this.gridData[0].oct
            .toString()
        },
        {
          value: this.gridData[0].nov
            .toString()
        },
        {
          value: this.gridData[0].dec
            .toString()
        }
      ];

      this.dataSource.dataset[1].data = [
        {
          value: this.gridData[16].jan.toString()
        },
        {
          value: this.gridData[16].feb.toString()
        },
        {
          value: this.gridData[16].mar.toString()
        },
        {
          value: this.gridData[16].apr.toString()
        },
        {
          value: this.gridData[16].may.toString()
        },
        {
          value: this.gridData[16].jun.toString()
        },
        {
          value: this.gridData[16].jul.toString()
        },
        {
          value: this.gridData[16].aug.toString()
        },
        {
          value: this.gridData[16].sep.toString()
        },
        {
          value: this.gridData[16].oct.toString()
        },
        {
          value: this.gridData[16].nov.toString()
        },
        {
          value: this.gridData[16].dec.toString()
        }
      ];
    }, 200);
  }

}
