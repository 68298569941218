import { Template } from '@angular/compiler/src/render3/r3_ast';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError } from 'rxjs';
import { ConfigurationService } from '../../../../services/configuration.service';
import { Item, ItemUPC } from '../../item.model';

@Component({
  selector: 'app-item-attributes',
  templateUrl: './item-attributes.component.html',
  styleUrls: ['./item-attributes.component.css']
})
export class ItemAttributesComponent implements OnInit, OnChanges {
  @Input() itemModel = new Item();
  @Input() submitted;
  accTypeList = ['1P','3P','N/A']
  upCArray: string = '';
  files = [];
  public brandList = [];
  public productGroupList = [];
  public productFamilyList = [];
  public parentProductList = [];
  public brandLoading = false;
  public productGroupLoading = false;
  public productFamilyLoading = false;
  public parentProductLoading = false;
  @ViewChild('file', { static: false }) imageFile: ElementRef;
  constructor(
    public spinner: NgxSpinnerService,
    public config: ConfigurationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<any>
  ) { }

  async ngOnInit() {
    if (this.itemModel) {
      this.itemModel.itemManagerUPC = this.itemModel.itemManagerUPC && this.itemModel.itemManagerUPC.length > 0 ? this.itemModel.itemManagerUPC : [];
      if (this.itemModel.itemManagerUPC.length < 1) {
        const upc = new ItemUPC();
        this.itemModel.itemManagerUPC.push(upc);
      }
      this.brandList = await this.config.ProductSettingMappingBrandGetList({}).toPromise().catch(error => throwError(error));
      if (this.itemModel.brandId) {
        this.productGroupList = await this.config.ProductSettingMappingProductGroupGetList({ brandId: this.itemModel.brandId }).toPromise().catch(error => throwError(error));
      }
      if (this.itemModel.brandId && this.itemModel.productGroupId) {
        this.productFamilyList = await this.config.ProductSettingMappingProductFamilyGetList({ productGroupId: this.itemModel.productGroupId }).toPromise().catch(error => throwError(error));
      }
      if (this.itemModel.brandId && this.itemModel.productGroupId && this.itemModel.productFamilyId) {
        this.parentProductList = await this.config.ProductSettingMappingParentProductGetList({ productFamilyId: this.itemModel.productFamilyId }).toPromise().catch(error => throwError(error));
      }
    }
  }
  public GetBrandList = (resetFlag = true) => {
    this.brandLoading = true;
    this.brandList = [];
    this.config.ProductSettingMappingBrandGetList({}).subscribe(res => {
      if (resetFlag) {
        this.productGroupList = [];
        this.productFamilyList = [];
        this.parentProductList = [];
      }
      this.brandList = res;
      this.brandLoading = false;
    })
  }
  public GetProductGroupList = (brandId, resetFlag = true) => {
    this.productGroupLoading = true;
    this.config.ProductSettingMappingProductGroupGetList({ brandId: brandId }).subscribe(res => {
      this.productGroupList = res;
      this.productFamilyList = [];
      this.parentProductList = [];
      if (resetFlag) {
        this.itemModel.productGroupId = null;
        this.itemModel.productFamilyId = null;
        this.itemModel.parentProductId = null;
      }
      this.productGroupLoading = false;
    })
  }
  public GetProductFamilyList = (productGroupId, resetFlag = true) => {
    this.productFamilyLoading = true;
    this.parentProductList = [];
    this.config.ProductSettingMappingProductFamilyGetList({ productGroupId: productGroupId }).subscribe(res => {
      this.productFamilyList = res;
      if (resetFlag) {
        this.itemModel.productFamilyId = null;
        this.itemModel.parentProductId = null;
      }
      this.productFamilyLoading = false;
    })
  }
  public GetParentProductList = (productFamilyId, resetFlag = false) => {
    this.parentProductLoading = true;
    this.config.ProductSettingMappingParentProductGetList({ productFamilyId: productFamilyId }).subscribe(res => {
      this.parentProductList = res;
      if (resetFlag) {
        this.itemModel.parentProductId = null;
      }
      this.parentProductLoading = false;
    })
  }
  ngOnChanges(simpleChange: SimpleChanges) {
    if (simpleChange && simpleChange.itemModel && simpleChange.itemModel.currentValue) {
      this.itemModel = simpleChange.itemModel.currentValue;
    }
  }
  addNewUPC = () => {
    const upc = new ItemUPC();
    this.itemModel.itemManagerUPC.push(upc);
  }
  removeUPC = (upc: ItemUPC) => {
    this.itemModel.itemManagerUPC = this.itemModel.itemManagerUPC.filter(row => row.upcCode !== upc.upcCode);
  }
  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
    this.files.forEach(file => {
      this.getFile(file)
    })
  }
  private getFile(file: any) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.uploadFile((reader.result as any).split(',')[1], file.name);
    };
    return reader;
  }
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
  public uploadFile = (file, fileName) => {
    this.spinner.show();
    this.config.DocumentAdd({ file, fileName }).subscribe(res => {
      this.itemModel.documentId = res.documentId;
      this.itemModel.documentPath = res.documentPath;
      this.dialogRef.close();
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }
  public deleteImage = () => {
    this.itemModel.image = null;
    this.itemModel.documentId = null;
  }
  public openImageDialog = (template: TemplateRef<any>) => {
    this.dialogRef = this.dialog.open(template, { width: '500px' });
  }
}
