import { Component, OnInit, ViewEncapsulation, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialogRef, MatDialog} from '@angular/material/dialog';
import {  MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {  MatChipInputEvent } from '@angular/material/chips';
import * as XLSX from 'xlsx';
import { Observable, of } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DropdownRendererComponent } from 'src/common/dropdown-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-forecast-v2',
  templateUrl: './forecast-v2.component.html',
  styleUrls: ['./forecast-v2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForecastV2Component implements OnInit {

  width = '100%';
  height = '300';
  type = "msline";
  dataFormat = "json";
  dataSource;
  public submitted = false;
  public isEdit = false;
  public columnDefs = [];
  public columnDefs1 = [];

  public likeItemList = [
    {
      'likeItemSetup':'Like Item 1',
      'itemId': 'FB0001',
      'itemDescription': 'Oculus Quest 2 256GB',
      'customer': 'Best Buy',
      'weight': '50 %',
      'historyStartDate': '10/11/2020',
      'historyEndDate': '12/13/2020',
    },
    {
      'likeItemSetup':'Like Item 2',
      'itemId': 'FB0002',
      'itemDescription': 'Oculus Quest 2 64GB',
      'customer': 'Best Buy',
      'weight': '30 %',
      'historyStartDate': '10/11/2020',
      'historyEndDate': '12/13/2020',
    },
    {
      'likeItemSetup':'Like Item 3',
      'itemId': 'FB0003',
      'itemDescription': 'Oculus Quest',
      'customer': 'Walmart',
      'weight': '20 %',
      'historyStartDate': '05/19/2019',
      'historyEndDate': '07/21/2019',
    }
  ];
  private gridApi: any;
  dropdownSettings = {};
  dropdownSettingsSingle = {};
  public tenureTypeList = [];
  public tenureTypeListTemp = [];
  public rowList = [];
  public goalTypeList = [];
  public gridOptions: any;
  public getRowHeight;
  public getRowStyle;
  public headerHeight;
  public modules = AllCommunityModules;
  public clientId = 1;
  public channelList = [{ channelId: 1, channelName: 'Retail' }];
  public channel = null;
  public csvRecords: any;
  public customerSelectedList = [];
  public selectedItemIds = [];
  public itemIdList = ['FB0009','FB000038',
    'FB000084',
    'FB000063'];
  public customerList = [
    'Amazon AU',
    'Amazon CA',
    'Amazon JPN',
    'Amazon US',
    'Best Buy CA',
    'Best Buy US',
    'Synnex CA',
    'Synnex US',
    'WMT'

  ];
  public timeframeSelectedList = [];
  public itemSeletedList = [];
  public timeframeList = [];
  public versionList = [];
  public monthList = [];
  public itemList = [
    'Like Item 1 Sales History','Like Item 2 Sales History','Like Item 3 Sales History','New Item Algo Forecast'
  ];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredCustomers: Observable<any[]>;
  filteredItems: Observable<any[]>;
  filteredTimeframe: Observable<any[]>;
  public seletedYear = null;
  public seletedVersion = null;
  public selectedMonth = null;
  public defaultRow = {};
  public selectAll = true;
  searchCustomer = new FormControl();
  searchItem = new FormControl();
  searchTimeframe = new FormControl();
  files: File[] = [];
  @ViewChild('ExcelImport') excelImport: TemplateRef<any>;
  @ViewChild('dialog') dialogTemplate: TemplateRef<any>;
  @ViewChild('searchItemInput', { static: true }) searchItemInput: ElementRef;
  @ViewChild('searchCustomerInput', { static: true }) searchCustomerInput: ElementRef;
  tempList = [];
  totalCalculatedList = [];
  chartDatasets = [];
  simulatedChartDatasets = [];
  barChartDatasets = [];
  simulatedBarChartDatasets = [];
  xAxis = [];
  yAxis = [];
  xAxisSimulated = [];
  view = [700, 700];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  legendTitle = 'Legend';
  legendPosition = 'right';
  showXAxisLabel = true;
  xAxisLabel = 'Sales QTY';
  showYAxisLabel = true;
  yAxisLabel = 'Time Period';
  showGridLines = true;
  innerPadding = '10%';
  animations: boolean = true;
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5']
  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#7aa3e5']
  };

  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = '';
  tempColDefs = [];
  chooserList = [
    { name: 'Like Item 1 Sales History', checked: true },
    { name: 'Like Item 1 Sales History', checked: true },
    { name: 'Like Item 1 Sales History', checked: true },
    { name: 'New Item Algo Forecast', checked: true },

  ]
  public fg;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    public dialogRef: MatDialogRef<any>,
    public configurationService: ConfigurationService,
    public dialog: MatDialog) {
    this.gridOptions = {
      rowClassRules: {
        // apply green to 2008
        // 'rag-green-outer': function (params) { return params.data.label === 'Final Total Forecast'; },
        // 'rag-grey-outer': function (params) { return (params.data.columnValue === 'Quest 128GB' || params.data.columnValue === 'Portal + Black' || params.data.columnValue === 'Quest 64GB'); },
        // 'rag-border-outer': function (params) {
        //   return params.data.label === 'Notes';
        // },
        // 'rag-border-top-outer': function (params) {
        //   return params.data.label === 'Primary Method Base';
        // },
        // // apply amber 2004
        // 'rag-amber-outer': function(params) { return params.data.year === 2004; },

        // // apply red to 2000
        // 'rag-red-outer': function(params) { return params.data.year === 2000; }
      },
      frameworkComponents: {
        dropdownRenderer: DropdownRendererComponent
      },
      // pagination: true,
      // paginationAutoPageSize: true,
      // suppressMenuHide: true,
    };
    this.headerHeight = 30;
    this.gridOptions.getRowHeight = function (params) {
      return 30;
    };
    this.gridOptions.getRowStyle = function (params) {
      // if (params.data && params.data.apBudgetTenureName && typeof params.data.apBudgetTenureName === 'string') {
      //   if (params.data && params.data.apBudgetTenureName && params.data.apBudgetTenureName.endsWith('FY21')) {
      //     return { 'background-color': '#D6EAF8' };
      //   }
      //   if (params.data && params.data.apBudgetTenureName && params.data.apBudgetTenureName.startsWith('Q')) {
      //     return { 'background-color': '#3498DB' };
      //   }
      //   if (params.data && params.data.apBudgetTenureName && params.data.apBudgetTenureName.startsWith('WK')) {
      //     return { 'background-color': '' };
      //   }
      // }
    };

    this.fg = this.formBuilder.group({
      applicationInsightsId: [0],
      date: new Date('1/24/2021'),
      itemId: 'FB0009',
      itemDescription: 'Quest 3 256GB',
      customer: 'Best Buy',
      wklyBaselineRunRate: '6000',
      isPublish: true,
    });
  }
  openDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(this.dialogTemplate, { width: '500px', data: data || {} });
  }
  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
    this.dropdownSettingsSingle = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
    this.customerList = (this.customerList as any).map(customer => ({
      item_id: customer,
      item_text: customer
    }));
    this.itemList = (this.itemList as any).map(item => ({
      item_id: item,
      item_text: item
    }));
    this.versionList.push(
      'Commit',
      'V1',
      'V2',
      'V3'
    );
    for (let index = 0; index < 12; index++) {
      const date = new Date(2009, index, 10);  // 2009-11-10
      const month = date.toLocaleString('default', { month: 'long' });
      const shortMonth = date.toLocaleString('default', { month: 'short' });
      this.monthList.push(
        {
          item_id: `${shortMonth}`, item_text: `${month} FY20`
        });
    }
    this.customerList = (this.customerList as any).sort((a, b) => a.item_id < b.item_id ? -1 : 1)
    this.itemList = (this.itemList as any).sort((a, b) => a.item_id < b.item_id ? -1 : 1)
    this.searchCustomer.setValue('');
    this.searchItem.setValue('');
    this.columnDefs = [
      {
        headerName: '',
        width: 200,
        pinned: 'left',
        field: 'label',
        // cellRenderer: 'dropdownRenderer',
        // cellRendererParams: (params) => {
        //   const items = ['Primary Method Base', 'Expotential Smoothing', 'Linear Regression', `Holt's Double Expotential Smoothing`];
        //   const obj = {
        //     isDropDown: items.includes(params.data.label) ? true : false, // pass the field value here
        //     items: items,
        //     onChange: this.filterData.bind(this),
        //   }
        //   return obj;

        // },
        cellStyle: params => params.rowData && params.rowData.rowIndex % 2 === 0 ? { 'background-color': 'white' } : { 'background-color': 'lightgray' }
      },
      {
        headerName: 'January FY21',
        field: '',
        children: [
          {
            headerName: '01/03/2021',
            width: 120,
            field: 'janw1',
            suppressSizeToFit: true,
            valueSetter: function (params) {
              params.data[params.colDef.field] = Number(params.newValue);
              return true
            },
            editable: false
          },
          {
            headerName: '01/10/2021',
            width: 120,
            field: 'janw2',
            suppressSizeToFit: true,
            valueSetter: function (params) {
              params.data[params.colDef.field] = Number(params.newValue);
              return true
            },
            editable: false
          },
          {
            headerName: '01/17/2021',
            width: 120,
            field: 'janw3',
            suppressSizeToFit: true,
            valueSetter: function (params) {
              params.data[params.colDef.field] = Number(params.newValue);
              return true
            },
            editable: false
          },
          {
            headerName: '01/24/2021',
            width: 120,
            field: 'janw4',
            suppressSizeToFit: true,
            valueSetter: function (params) {
              params.data[params.colDef.field] = Number(params.newValue);
              return true
            },
            editable: false
          }
        ]
      },
      {
        headerName: 'February FY21',
        field: '',
        children: [
          {
            headerName: '01/31/2021',
            width: 120,
            field: 'febw1',
            suppressSizeToFit: true,
            valueSetter: function (params) {
              params.data[params.colDef.field] = Number(params.newValue);
              return true
            },
            editable: false
          },
          {
            headerName: '02/07/2021',
            width: 120,
            field: 'febw2',
            suppressSizeToFit: true,
            valueSetter: function (params) {
              params.data[params.colDef.field] = Number(params.newValue);
              return true
            },
            editable: false
          },
          {
            headerName: '02/14/2021',
            width: 120,
            field: 'febw3',
            suppressSizeToFit: true,
            valueSetter: function (params) {
              params.data[params.colDef.field] = Number(params.newValue);
              return true
            },
            editable: false
          },
          {
            headerName: '02/21/2021',
            width: 120,
            field: 'febw4',
            suppressSizeToFit: true,
            valueSetter: function (params) {
              params.data[params.colDef.field] = Number(params.newValue);
              return true
            },
            editable: false
          }
        ]
      },
      {
        headerName: 'March FY21',
        field: '',
        children: [
          {
            headerName: '02/28/2021',
            width: 120,
            field: 'marw1',
            suppressSizeToFit: true,
            valueSetter: function (params) {
              params.data[params.colDef.field] = Number(params.newValue);
              return true
            },
            editable: false
          },
          {
            headerName: '03/07/2021',
            width: 120,
            field: 'marw2',
            suppressSizeToFit: true,
            valueSetter: function (params) {
              params.data[params.colDef.field] = Number(params.newValue);
              return true
            },
            editable: false
          },
          {
            headerName: '03/14/2021',
            width: 120,
            field: 'marw3',
            suppressSizeToFit: true,
            valueSetter: function (params) {
              params.data[params.colDef.field] = Number(params.newValue);
              return true
            },
            editable: false
          },
          {
            headerName: '03/21/2021',
            width: 120,
            field: 'marw4',
            suppressSizeToFit: true,
            valueSetter: function (params) {
              params.data[params.colDef.field] = Number(params.newValue);
              return true
            },
            editable: false
          }
          ,
          {
            headerName: '03/28/2021',
            width: 120,
            field: 'marw5',
            suppressSizeToFit: true,
            valueSetter: function (params) {
              params.data[params.colDef.field] = Number(params.newValue);
              return true
            },
            editable: false
          }
        ]
      },

    ];
    this.columnDefs1 = [
      {
        headerName: '',
        width: 110,
        field: 'likeItemSetup',
        pinned: 'left',
        // cellRenderer: (params) => {
        //   return `<a href="javascript:void(0)" style="color: #232323">${params.value}</a>`;
        // },
      },
      {
        headerName: 'Item ID',
        field: 'itemId',
        cellRenderer: 'dropdownRenderer',
        cellRendererParams: (params) => {
          const items = ['FB0001','FB0002','FB0003','FB0011', 'FB0011', 'FB0012', `FB0013`];
          const obj = {
            isDropDown:  true , // pass the field value here
            items: items,
            //onChange: this.filterData.bind(this),
          }
          return obj;

        },
        cellStyle:  { 'font-weight':'normal' },
        width: 110,

      },
      {
        headerName: 'Item Description',
        field: 'itemDescription',
        width: 175,

      },
      {
        headerName: 'Customer',
        field: 'customer',
        width: 125,

        // cellRenderer: (params) => {
        //   return `${params.value ? (new Date(params.value)).toLocaleDateString() : ''}`;
        // }
      },
      {
        headerName: 'Weight',
        field: 'weight',
        width: 115,

        // cellRenderer: (params) => {
        //   return `${params.value ? (params.value+' %') : ''}`;
        // }
      },
      {
        headerName: 'History Start Date',
        field: 'historyStartDate',
        width: 175,

        // cellRenderer: (params) => {
        //   return `${params.value ? (new Date(params.value)).toLocaleDateString() : ''}`;
        // }
      },
      {
        headerName: 'History End Date',
        field: 'historyEndDate',
        width: 170,

        // cellRenderer: (params) => {
        //   return `${params.value ? (new Date(params.value)).toLocaleDateString() : ''}`;
        // }
      }
    ];
    this.tempColDefs = JSON.parse(JSON.stringify(this.columnDefs));
    const defaultObject = {
      label: 'Final Base',
      janw1: [],
      janw2: [],
      janw3: [],
      janw4: 7563,
      febw1: 7098,
      febw2: 6423,
      febw3: 6099,
      febw4: 6089,
      marw1: 6051,
      marw2: 6111,
      marw3: 6099,
      marw4: 6067,
      marw5: 6043,
    };
    // this.columnDefs.forEach((column) => this.defaultRow[column.field] = Math.random());
    const manupulatedList = [];
    const obj = JSON.parse(JSON.stringify(this.defaultRow));
    for (let index = 1; index <= 48; index++) {
      this.rowList.push(defaultObject);
    }
    // this.configurationService.importExcel('forcast-data').subscribe(res => {
    //   this.loadFile(res);
    // });
    this.rowList = [{  "label": "Like Item 1 Sales History", "janw1": 0, "janw2": 0, "janw3": 0, "janw4": 7563,  "febw1": 7098, "febw2": 6423, "febw3": 6099, "febw4": 6089,  "marw1": 6051, "marw2": 6111, "marw3": 6099, "marw4": 6067, "marw5": 6043},
    {  "label": "Like Item 2 Sales History", "janw1": 0, "janw2": 0, "janw3": 0, "janw4": 10987,  "febw1": 9567, "febw2": 8734, "febw3": 7453, "febw4": 6080,  "marw1": 6709, "marw2": 6809, "marw3": 6685, "marw4": 6754, "marw5": 6859},
    {  "label": "Like Item 3 Sales History", "janw1": 0, "janw2": 0, "janw3": 0, "janw4": 5023,  "febw1": 2008, "febw2": 1907, "febw3": 1987, "febw4": 2091,  "marw1": 1983, "marw2": 1978, "marw3": 1954, "marw4": 1923, "marw5": 1998},
    {  "label": "New Item Algo Forecast", "janw1": 0, "janw2": 0, "janw3": 0, "janw4": 8370,  "febw1": 8045, "febw2": 6908, "febw3": 6009, "febw4": 6045,  "marw1": 6089, "marw2": 6102, "marw3": 6098, "marw4": 6100, "marw5": 6087}]
    this.tempList = JSON.parse(JSON.stringify(this.rowList));
    this.calculateListValues();
  }
  public calculateListValues = (event?, changedList?) => {
    if (event) {
      const index = this.rowList.findIndex(row => row.label === event.data.label && row.columnValue === event.data.columnValue);
      if (index > -1) {
        event.newValue = +event.newValue;
        this.tempList[index][event.colDef.field] = Number(event.newValue);
      }
    }
    this.tempList.forEach(row => {
      if (row.label === 'Total Forecast' || row.label === 'Mostly likely Method' || (row.label === 'Final Total Forecast' && row.columnValue === 'Calculated')) {
        Object.keys(row).forEach(key => {
          if (key !== 'label' && key !== 'firstValue' && key !== 'columnValue') {
            row[key] = 0;
          }
        });
      }
    });
    this.tempList.forEach(row => {
      const selectedColumnList = this.chooserList.filter(row => !row.checked).map(el => el.name);
      if (row.label === 'Total Forecast') {
        let list = ['Primary Method Base', 'Expotential Smoothing', 'Linear Regression', `Holt's Double Expotential Smoothing`, 'Event Lift', 'Seasonality'];
        if (selectedColumnList.length > 0) {
          list = list.filter(item => !selectedColumnList.includes(item));
        }
        let toFindList = changedList ? changedList : this.tempList;
        const calculationList = toFindList.filter(item => list.includes(item.label) && item.columnValue === row.columnValue);
        calculationList.forEach(element => {
          Object.keys(row).forEach(key => {
            const columns = this.timeframeSelectedList.map(res => res.item_id.toLowerCase());
            if (key !== 'label' && key !== 'firstValue' && key !== 'columnValue' && columns.length < 1) {
              if (element.label !== 'Seasonality') {
                row[key] = +row[key];
                element[key] = +element[key];
                row[key] += +element[key];
              }
            }
            if (key !== 'label' && key !== 'firstValue' && key !== 'columnValue' && columns.length > 0) {
              const flag = columns.findIndex((a, b) => key.indexOf(a) > -1) > -1;
              if (element.label !== 'Seasonality' && flag) {
                row[key] = +row[key];
                element[key] = +element[key];
                row[key] += +element[key];
              }
            }
          });
        });
        calculationList.forEach(element => {
          Object.keys(row).forEach(key => {
            const columns = this.timeframeSelectedList.map(res => res.item_id.toLowerCase());
            if (key !== 'label' && key !== 'firstValue' && key !== 'columnValue' && columns.length < 1) {
              if (element.label === 'Seasonality') {
                row[key] = +row[key];
                row[key] *= +element[key];
              }
            }
            if (key !== 'label' && key !== 'firstValue' && key !== 'columnValue' && columns.length > 0) {
              const flag = columns.findIndex((a, b) => key.indexOf(a) > -1) > -1;
              if (element.label === 'Seasonality' && flag) {
                row[key] = +row[key];
                row[key] *= +element[key];
              }
            }
          });
        });
      }


      if (row.label === 'Mostly likely Method' && row.columnValue === 'Calculated') {
        const items = ['Primary Method Base', 'Expotential Smoothing', 'Linear Regression', `Holt's Double Expotential Smoothing`];
        let toFindList = changedList ? changedList : this.tempList;
        const calculationList = toFindList.filter(item => (items.includes(item.label)) && item.columnValue !== 'Calculated');
        calculationList.forEach(element => {
          Object.keys(row).forEach(key => {
            if (key !== 'label' && key !== 'firstValue' && key !== 'columnValue') {
              row[key] = +row[key];
              row[key] += +element[key];
            }
          });
        });
      }
      if (row.label === 'Final Total Forecast' && row.columnValue === 'Calculated') {
        let toFindList = changedList ? changedList : this.tempList;
        const calculationList = toFindList.filter(item => (item.label === 'Final Total Forecast') && item.columnValue !== 'Calculated');
        calculationList.forEach(element => {
          Object.keys(row).forEach(key => {
            if (key !== 'label' && key !== 'firstValue' && key !== 'columnValue') {
              row[key] = +row[key];
              row[key] += +element[key];
            }
          });
        });
      }
    });
    this.totalCalculatedList = this.tempList.filter(item => item.columnValue === 'Calculated');
    const selectedColumnList = this.chooserList.filter(row => !row.checked).map(el => el.name);
    let list = this.tempList;
    if (selectedColumnList.length > 0) {
      list = this.tempList.filter(row => !selectedColumnList.includes(row.label));
    }
    for (let index = 0; index < list.length; index++) {
      Object.keys(list[index]).forEach(key => {
        if (!isNaN(list[index][key])) {
          if (list[index].label !== 'Seasonality') {
            list[index][key] = list[index][key] ? Number(list[index][key]).toFixed(0) : 0;
          }
        }
      })
    }
    list = list.filter(row => row.columnValue !== 'Calculated');
    setTimeout(() => {
      if (this.gridOptions && this.gridOptions.api) {
        this.gridOptions.api.setRowData(list);
      }
    }, 100);
    this.totalCalculatedList = this.rowList;
    this.applyGraph(this.rowList);
    const objList = [];
    const object = this.rowList[1];
    this.chartDatasets = this.rowList.map(row => ({
      name: row.label,
      series: this.getSeries(object)
    }))
    console.log(objList);
    for (let index = 0; index < this.rowList.length; index++) {
      Object.keys(this.rowList[index]).forEach(key => {
        if (!isNaN(this.rowList[index][key])) {
          if (this.rowList[index].label !== 'Seasonality') {
            this.rowList[index][key] = this.rowList[index][key] ? Number(this.rowList[index][key]).toFixed(0) : 0;
          }
        }
      })
    }

    // this.rowList = list;
  }
  getSeries = (object) => {
    const objList = [];
    Object.keys(object).forEach(key => {
      if (key !== 'label' && key !== 'firstValue' && key !== 'columnValue') {
        const newObject = {
          name: key,
          value: Number(object[key])
        }
        objList.push(newObject)
      }
    });
    return objList;
  }
  openExcelImportDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(this.excelImport, { width: '500px', data: data || {} });
  }
  onCellValueChanged = (value) => {
    this.calculateListValues(value);
  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  public onSubmit = () => {
  }
  selectAllSelected(flag: boolean): void {
    if (flag) {
      this.chooserList = this.chooserList.map(row => ({ ...row, checked: true }))
    } else {
      this.chooserList = this.chooserList.map(row => ({ ...row, checked: false }))
    }
  }
  public onSave = () => {
    // let newColumnDefs = this.columnDefs.filter(column => column.show);
    // const cols = JSON.parse(JSON.stringify(this.tempColDefs));
    // const list = cols.map(column => {
    //   if (column.children) {
    //     column.children = column.children.filter(col => col.show);
    //     column.children = column.children.map(col => ({ ...col, editable: params => { return params.data['label'] !== 'Total Forecast' } }))
    //   }
    //   return column;
    // });
    // newColumnDefs = newColumnDefs.length > 0 ? list.push(...newColumnDefs) : list as any;
    // this.gridOptions.api.setColumnDefs(newColumnDefs);
    this.calculateListValues();
    this.dialogRef.close();
  }
  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }
  exportToExcel = () => {
    this.gridApi.exportDataAsCsv();
  }
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
  loadFile(res) {

    /* wire up file reader */
    this.spinner.show();
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(res);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary', cellDates: true });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data);
      this.rowList = data;
      console.log(JSON.stringify(this.rowList));
      // for (let i = 0; i < this.ItemMasterList.length; i++) {
      //   if (this.ItemMasterList[i]['']) {
      //     this.ItemMasterList[i][''] =
      //   }
      // }
      this.spinner.hide();
    };
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.itemSeletedList.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.searchItem.setValue('');
  }

  remove(fruit: string): void {
    const index = this.itemSeletedList.indexOf(fruit);

    if (index >= 0) {
      this.itemSeletedList.splice(index, 1);
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.itemSeletedList.push(event.option.viewValue);
    this.searchItemInput.nativeElement.value = '';
    this.searchItem.setValue(null);
  }
  selectedCustomer(event: MatAutocompleteSelectedEvent): void {
    this.customerSelectedList.push(event.option.viewValue);
    this.searchCustomerInput.nativeElement.value = '';
    this.searchCustomer.setValue(null);
  }
  removeCustomer(fruit: string): void {
    const index = this.customerSelectedList.indexOf(fruit);

    if (index >= 0) {
      this.customerSelectedList.splice(index, 1);
    }
  }
  addCustomer(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our Customer
    if ((value || '').trim()) {
      this.customerSelectedList.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.searchCustomer.setValue('');
  }
  onItemSelect(item?: boolean, items?) {
    var columns = [];
    var newColumnDefs = [];
    columns = this.timeframeSelectedList.map(res => res.item_id.toLowerCase());
    // const columnDefs = this.columnDefs;
    if (columns && columns.length > 0 && !item) {
      const columnDefs = JSON.parse(JSON.stringify(this.tempColDefs));
      const list = columnDefs.map(column => {
        if (column.children) {
          // || col.field.startsWith('q') || col.field.startsWith('totalYear')
          column.children = column.children.filter(col => columns.findIndex((a, b) => col.field.indexOf(a) > -1) > -1);
          column.children = column.children.map(col => ({ ...col, editable: params => { return params.data['label'] !== 'Total Forecast' } }))
        }
        return column;
      });
      newColumnDefs = newColumnDefs.length > 0 ? list.push(...newColumnDefs) : list as any;
      newColumnDefs = newColumnDefs.filter(col => columns.findIndex((a, b) => ((col.field === '' || col.pinned))) > -1);
      // newColumnDefs = newColumnDefs.filter(col => (col.field === '' || col.pinned));
      newColumnDefs = newColumnDefs.map(col => ({ ...col, cellRenderer: col.field === 'firstValue' ? params => `<b>${params.value ? params.value : ''}</b>` : null }))
      for (let i = 0; i < newColumnDefs.length; i++) {
        if (newColumnDefs[i].field === 'label') {
          newColumnDefs[i] = {
            ...newColumnDefs[i],
            cellRenderer: 'dropdownRenderer',
            cellRendererParams: (params) => {
              const items = ['Primary Method Base', 'Expotential Smoothing', 'Linear Regression', `Holt's Double Expotential Smoothing`];
              const obj = {
                isDropDown: items.includes(params.data.label) ? true : false, // pass the field value here
                onChange: this.filterData.bind(this),
              }
              return obj;
            },
          }
        }
      }
      this.gridOptions.api.setColumnDefs(newColumnDefs);
    } else {
      if (item) {
        this.timeframeSelectedList = items
      }
      this.gridOptions.api.setColumnDefs(this.columnDefs);
    }
    // if (columns && columns.length > 0) {
    //   columnDefs.forEach(colm => {
    //     colm.children = colm.children && colm.children.length > 0 ? colm && colm.children.filter(col => columns.findIndex((a, b) => col.field.indexOf(a) > -1) > -1) : colm.children
    //   });
    // }
    this.calculateListValues();
  }
  onSelectAll(items: any) {
    this.onItemSelect(true, items)
  }
  openFullScreen = (id: string) => {
    const domGrid = document.getElementById(id) as any;
    if (domGrid) {
      domGrid.webkitRequestFullscreen();
    }
  }
  applyGraph(totalCalculatedList) {
    const data = {
      chart: {
        caption: "",
        yaxisname: "",
        subcaption: "",
        showhovereffect: "1",
        lineThickness: "4",
        "width": "100%",
        "height": "150px",
        numbersuffix: "",
        "paletteColors": "#8D009D,#F8486E, #1C9FAD, #2480D3",
        drawcrossline: "1",
        plottooltext: "<b>$dataValue</b>",
        theme: "fusion"
      },
      categories: [
        {
          category: this.getLabels()
        }
      ],
      dataset: totalCalculatedList.map(item => ({ seriesname: item.label, data: this.getList(item) }))
    };
    this.dataSource = data as any;
  }
  public getLabels = () => {
    const el = this.totalCalculatedList[0];
    const list = [];
    Object.keys(el).forEach(key => {
      const columns = this.timeframeSelectedList.map(res => res.item_id.toLowerCase());
      if (key !== 'label' && key !== 'firstValue' && key !== 'columnValue' && columns.length < 1) {
        list.push({ label: this.getKeyValue(key) });
      }
      if (key !== 'label' && key !== 'firstValue' && key !== 'columnValue' && columns.length > 0) {
        const flag = columns.findIndex((a, b) => key.indexOf(a) > -1) > -1;
        if (flag) {
          list.push({ label: this.getKeyValue(key) });
        }
      }
    });
    return list;
  }
  getKeyValue(key) {
    const list = [
      { key: 'janw1', value: '01/03/2021' },
      { key: 'janw2', value: '01/10/2021' },
      { key: 'janw3', value: '01/17/2021' },
      { key: 'janw4', value: '01/24/2021' },
      { key: 'febw1', value: '01/31/2021' },
      { key: 'febw2', value: '02/07/2021' },
      { key: 'febw3', value: '02/14/2021' },
      { key: 'febw4', value: '02/21/2021' },
      { key: 'marw1', value: '02/28/2021' },
      { key: 'marw2', value: '03/07/2021' },
      { key: 'marw3', value: '03/14/2021' },
      { key: 'marw4', value: '03/21/2021' },
      { key: 'marw5', value: '03/28/2021' },
    ];
    return list.find(item => item.key === key).value;
  }
  getList = (row) => {
    const list = [];
    Object.keys(row).forEach(key => {
      if (key !== 'label' && key !== 'firstValue' && key !== 'columnValue') {
        list.push(row[key]);
      }
    });
    return list.map(item => ({ value: item }))
  }
  filterData = (value) => {
    const index = value.rowData.rowIndex + 4;
    const list = JSON.parse(JSON.stringify(this.tempList));
    Object.keys(list[index]).forEach(key => {
      if (key !== 'firstValue' && key !== 'columnValue') {
        if ((key.endsWith('w1') || key.endsWith('w2') || key.endsWith('w3') || key.endsWith('w4') || key.endsWith('w5'))) {
          if (value.event.value === 'Linear Regression') {
            list[index][key] = +list[index][key];
            list[index][key] *= 1.2;
          } else if (value.event.value === `Holt's Double Expotential Smoothing`) {
            list[index][key] = +list[index][key];
            list[index][key] *= 0.8;
          } else {
            list[index][key] = +this.rowList[index][key];
            // list[index][key] *= 0.8;
          }
        }
        if (key === 'label') {
          list[index][key] = value.event.value;
        }
      }
    });
    this.tempList = [...list];
    this.calculateListValues(null, list);
  }
}
