import { ColumnApi, CsvExportParams, GridApi } from '@ag-grid-enterprise/all-modules';
import * as _ from 'lodash';
import * as moment from 'moment';

const SKIP_COLUMNS = ['select', 'cellAction', 'Image'];

interface IGridExportParams {
  fileName: string,
  colDefs: any[],
  allColumns?: boolean,
  isExportTemplate?: boolean,
  columnKeys?: any[],
  onlySelected?: boolean
}

const charsMap = { ' ': '', '&': 'And' };

export const getColumnKeys = (colDefs) => {
  const columnKeys = [];
  colDefs.forEach(col => {
    if (col.children) {
      col.field && columnKeys.push(col.field);
      columnKeys.push(...(_.map(col.children, a => a.field)))
    } else if (col.field !== 'cellAction' && col.field !== 'select') {
      columnKeys.push(col.field);
    }
  });
  return columnKeys;
}

export const gridDataExportParams = (exportGridParams: IGridExportParams) => {
  const csvExportParams: CsvExportParams = {
    fileName: exportGridParams.fileName,
    allColumns: exportGridParams.allColumns || false,
    skipGroups: true,
    columnGroups: true,
    onlySelected: exportGridParams.onlySelected || false,
    columnKeys: exportGridParams.allColumns ? [] : _.differenceWith(exportGridParams.columnKeys, SKIP_COLUMNS, _.isEqual),
    processCellCallback: (params) => {
      if (params.column.getColDef().type === 'date') {
        return `${params.value ? (new Date(params.value)).toLocaleDateString() : ''}`;
      }
      if (params.column.getColDef().field === 'upc' || params.column.getColDef().field === 'UPC') {
        return `="${params.value}"`;
      }
      return params.value;
    },
    processHeaderCallback: (params) => {
      return exportGridParams.isExportTemplate ? _.get(params, 'column.colDef.headerName', '').replace(/[\s\&]/g, m => charsMap[m]) : _.get(params, 'column.colDef.headerName', '');
    }
  };
  return csvExportParams;
}


export const downloadFromLink = (linkData, fileName) => {
  const link = document.createElement("a");
  link.setAttribute('download', fileName);
  link.href = _.get(linkData, 'document.documentPath');
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const downloadFromBase64 = (linkData, name) => {
  const blob = b64toBlob(linkData, 'csv');
  const blobUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  if (link.download !== undefined) {
    link.href = blobUrl;
    link.target = '_blank';
    link.download = name;
    link.click();
    link.remove();
    URL.revokeObjectURL(blobUrl);
  };
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export const getFilterModel = (gridFilters) => {
  const filterFields = _.keys(gridFilters);
  const filterModel:any = [];
  filterFields.forEach(field => {
    filterModel.push({
      field: field,
      filterType: gridFilters[field].filterType,
      type: gridFilters[field].type || gridFilters[field].filterType,
      filter: gridFilters[field].filter || gridFilters[field].values,
      ...gridFilters[field].dateFrom && { dateFrom: gridFilters[field].dateFrom }
    });
  });
  return filterModel;
}

export const getSortModel = (gridSorting) => {
  const sortModel:any = [];
  gridSorting.forEach(field => {
    sortModel.push({
      field: field.colId,
      order: field.sort
    });
  });
  return sortModel;
}

export const getUniqueId = (parts = 2): string => {
  const stringArr = [];
  for (let i = 0; i < parts; i++) {
    const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    stringArr.push(S4);
  }
  return stringArr.join('_');
}

// FOR AG GRID SCROLL
export const registerHorizontalScroll = (id): MutationObserver => {

  const gridLayout = document.getElementById(id);
  if (!gridLayout) return null;

  // remove scroll if already cloned
  document.getElementById(`cloned-${id}`) && document.getElementById(`cloned-${id}`).remove();

  // css class selectors
  const headerSelector = '.ag-header';
  const scrollSelector = '.ag-body-horizontal-scroll';
  const scrollViewportSelector = '.ag-body-horizontal-scroll-viewport';
  const scrollContainerSelector = '.ag-body-horizontal-scroll-container';

  // get scrollbar elements
  const scrollElement = gridLayout.querySelector(scrollSelector);
  const scrollViewportElement = gridLayout.querySelector(scrollViewportSelector);
  const scrollContainerElement = gridLayout.querySelector(scrollContainerSelector);

  // create scrollbar clones
  const cloneElement = scrollElement.cloneNode(true) as Element;
  const cloneViewportElement = cloneElement.querySelector(scrollViewportSelector);
  const cloneContainerElement = cloneElement.querySelector(scrollContainerSelector);

  // insert scrollbar clone
  cloneElement.setAttribute('id', `cloned-${id}`);
  const headerElement = gridLayout.querySelector(headerSelector);
  headerElement.insertAdjacentElement('afterend', cloneElement);

  // add event listeners to keep scroll position synchronized
  scrollViewportElement.addEventListener('scroll', () => cloneViewportElement.scrollTo({ left: scrollViewportElement.scrollLeft }));
  cloneViewportElement.addEventListener('scroll', () => scrollViewportElement.scrollTo({ left: cloneViewportElement.scrollLeft }));

  // create a mutation observer to keep scroll size synchronized
  const mutationObserver = new MutationObserver(mutationList => {
    for (const mutation of mutationList) {
      switch (mutation.target) {
        case scrollElement:
          cloneElement.setAttribute('style', scrollElement.getAttribute('style'));
          break;
        case scrollViewportElement:
          cloneViewportElement.setAttribute('style', scrollViewportElement.getAttribute('style'));
          break;
        case scrollContainerElement:
          cloneContainerElement.setAttribute('style', scrollContainerElement.getAttribute('style'));
          break;
      }
    }
  });

  // start observing the scroll elements for `style` attribute changes
  mutationObserver.observe(scrollElement, { attributeFilter: ['style'], subtree: true });
  return mutationObserver;
}

// AG GRID ON KEY DOWN EVENT ON CELL
export const onCellKeyDown = (e, gridApiRef: GridApi) => {
  if (gridApiRef.getEditingCells().length == 0)
    return;
  const keyPressed = e.event.key;
  getEditableCell(keyPressed, gridApiRef, e.rowIndex, e.colDef.field);
}

const getEditableCell = (keyPressed, gridApiRef: GridApi, nextIndex, column = null) => {
  if (keyPressed === 'ArrowUp') {
    nextIndex = nextIndex - 1;
  }
  if (keyPressed === 'ArrowDown') {
    nextIndex = nextIndex + 1;
  }
  if (nextIndex >= 0 && (keyPressed === 'ArrowUp' || keyPressed === 'ArrowDown')) {
    // PAGINATION CONDITIONS WON"T WORK IN GROUPED ROWS
    if (nextIndex >= ((gridApiRef.paginationGetCurrentPage() + 1) * gridApiRef.paginationGetPageSize())
      || nextIndex < (gridApiRef.paginationGetCurrentPage() * gridApiRef.paginationGetPageSize())) {
      return;
    }
    gridApiRef.stopEditing();
    gridApiRef.deselectAll();
    gridApiRef.clearFocusedCell();
    gridApiRef.startEditingCell({
      rowIndex: nextIndex,
      colKey: column
    });
    if (gridApiRef.getDisplayedRowAtIndex(nextIndex)) {
      // IF GROUPED ROW -> EXPAND AND SELECT CHILD ITEMS
      if (gridApiRef.getEditingCells().length == 0) {
        gridApiRef.getDisplayedRowAtIndex(nextIndex).setExpanded(true);
        setTimeout(() => {
          getEditableCell(keyPressed, gridApiRef, nextIndex, column);
        }, 100);
      }
    } else {
      if (keyPressed === 'ArrowDown') { // END REACHED
        keyPressed = 'ArrowUp';
        getEditableCell(keyPressed, gridApiRef, nextIndex, column);
      }
    }
  }
}

export const overlayLoadingTemplate = `<div class="ag-loading">
<span class="ag-loading-icon" ref="eLoadingIcon"><span class="ag-icon ag-icon-loading" unselectable="on" role="presentation"></span></span>
<span class="ag-loading-text" ref="eLoadingText">Loading</span>
</div>`;
