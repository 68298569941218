<!-- Main Content area -->
<div class="container-fluid" style="zoom: 70%">
  <div class="row">
    <div class="col-md-3 form-group">
      <label>Timeframe</label>
      <ng-multiselect-dropdown
        [settings]="dropdownSettings"
        *ngIf="monthList && monthList.length > 0"
        style="max-height: 20px !important"
        [placeholder]="'Timeframe'"
        [data]="monthList"
        [(ngModel)]="timeframeSelectedList"
        (onDeSelect)="onItemSelect()"
        (onSelect)="onItemSelect()"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2 form-group">
      <label>Item ID</label>
      <ng-multiselect-dropdown
        [settings]="dropdownSettingsSingle"
        *ngIf="itemIdList && itemIdList.length > 0"
        [placeholder]="'Item ID'"
        [data]="itemIdList"
        [(ngModel)]="selectedItemIds"
        (onSelect)="onItemSelect()"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2 form-group">
      <label>Item</label>
      <ng-multiselect-dropdown
        [settings]="dropdownSettingsSingle"
        *ngIf="itemList && itemList.length > 0"
        [placeholder]="'Item'"
        [data]="itemList"
        [(ngModel)]="itemSeletedList"
        (onSelect)="onItemSelect()"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2 form-group">
      <label>Version</label>
      <ng-multiselect-dropdown
        [settings]="dropdownSettingsSingle"
        *ngIf="versionList && versionList.length > 0"
        [placeholder]="'Version'"
        [data]="versionList"
        [(ngModel)]="seletedVersion"
        (onSelect)="onItemSelect()"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-3 form-group">
      <label>Customer</label>
      <ng-multiselect-dropdown
        [settings]="dropdownSettingsSingle"
        *ngIf="customerList && customerList.length > 0"
        [placeholder]="'Customer'"
        [data]="customerList"
        [(ngModel)]="customerSelectedList"
        (onSelect)="onItemSelect()"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
  </div>
  <mat-card-title
    [ngStyle]="{
      visibility:
        customerSelectedList.length > 0 &&
        seletedVersion &&
        itemSeletedList.length > 0 &&
        timeframeSelectedList.length > 0
          ? 'visible'
          : 'collapse'
    }"
  >
    Multi-Method Model
  </mat-card-title>
  <mat-card
    [ngStyle]="{
      visibility:
        customerSelectedList.length > 0 &&
        seletedVersion &&
        itemSeletedList.length > 0 &&
        timeframeSelectedList.length > 0
          ? 'visible'
          : 'collapse'
    }"
    class="mat-card-box"
    style="border-top: 2px solid #d81ad1"
  >
    <mat-card-header> </mat-card-header>
    <mat-card-content>
      <fusioncharts
        [width]="width"
        [height]="height"
        [type]="type"
        [dataFormat]="dataFormat"
        [dataSource]="dataSource"
      >
      </fusioncharts>
    </mat-card-content>
  </mat-card>

  <!-- <div class="row">
    <div
      *ngIf="customerSelectedList.length > 0 && seletedVersion && itemSeletedList.length > 0 && timeframeSelectedList.length > 0"
      class="col-md-4 form-group">
      <mat-form-field>
        <input matInput placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </div> -->
  <div
    style="zoom: 70%"
    *ngIf="
      customerSelectedList.length > 0 &&
      seletedVersion &&
      itemSeletedList.length > 0 &&
      timeframeSelectedList.length > 0
    "
    class="action-panel text-right"
  >
    <span (click)="openDialog({})">
      <i
        style="font-size: 42px; cursor: pointer"
        class="material-icons"
        aria-label="Column Show/Hide"
      >
        list_alt
      </i>
    </span>
    <span (click)="openExcelImportDialog({})">
      <i
        style="font-size: 42px; cursor: pointer"
        class="material-icons"
        aria-label="Column Show/Hide"
      >
        import_export
      </i>
    </span>
    <span
      (click)="openFullScreen('forcast-grid')"
      matTooltip="Full Screen"
      matTooltipPosition="above"
    >
      <i
        style="font-size: 42px; cursor: pointer"
        class="material-icons"
        aria-label="Full Screen"
      >
        fullscreen
      </i>
    </span>
    <!-- <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit()">Save</button> -->
  </div>

  <div
    [ngStyle]="{
      visibility:
        customerSelectedList.length > 0 &&
        seletedVersion &&
        itemSeletedList.length > 0 &&
        timeframeSelectedList.length > 0
          ? 'visible'
          : 'collapse'
    }"
    class="all-show-grid container-fluid"
    id="forcast-grid"
  >
    <ag-grid-angular
      class="ag-theme-material budget-grid"


      groupHeaders
      suppressRowClickSelection
      toolPanelSuppressGroups

      toolPanelSuppressValues
      [gridOptions]="gridOptions"
      [rowStyle]="getRowStyle"
      [headerHeight]="headerHeight"
      [getRowHeight]="getRowHeight"
      [columnDefs]="columnDefs"
      [rowData]="rowList"
      (cellValueChanged)="onCellValueChanged($event)"
      (gridReady)="onGridReady($event)"
      [modules]="modules"
      #grid
    >
    </ag-grid-angular>
  </div>
  <!-- <app-line-chart [chartDatasets]="chartDatasets" [xAxis]="xAxis" [yAxis]="yAxis"></app-line-chart> -->
</div>
<ng-template #dialog let-data>
  <h1 mat-dialog-title>Show/Hide Measures</h1>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <mat-checkbox
      maxlength="20"
      [(ngModel)]="selectAll"
      (ngModelChange)="selectAllSelected($event)"
      >Select/Remove All
    </mat-checkbox>
    <button
      mat-raised-button
      class="btn btn-primary m-left"
      color="primary"
      (click)="onSave()"
    >
      Save & Close
    </button>
  </div>
  <div mat-dialog-content>
    <div style="display: flex; flex-direction: column">
      <ng-container *ngFor="let item of chooserList || []">
        <ng-container>
          <mat-checkbox maxlength="20" [(ngModel)]="item.checked">
            {{ item.name }}
          </mat-checkbox>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #ExcelImport let-data>
  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <h1 mat-dialog-title>Import/Export</h1>
      <button
        mat-raised-button
        class="btn btn-primary"
        color="primary"
        (click)="exportToExcel()"
      >
        Export to Excel
      </button>
    </div>
    <div>
      <ngx-dropzone (change)="onSelect($event)">
        <ngx-dropzone-label>Drop files here!</ngx-dropzone-label>
        <ngx-dropzone-preview
          *ngFor="let f of files"
          [removable]="true"
          (removed)="onRemove(f)"
        >
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>
    <!-- <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview> -->
  </div>
</ng-template>
