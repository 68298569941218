import { formatDate } from '@angular/common';

export const USER_COL_DEFS = [
  {
    headerName: 'Email',
    field: 'email',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'First Name',
    field: 'firstName',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'User Type',
    field: 'userTypeName',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'Role',
    field: 'roleNames',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'Status',
    field: 'active',
    minWidth: 50,
    resizable: true,
    cellRenderer: (params) => params.value === true ? 'Active' : 'Inactive',
    cellStyle: (params) => {
      if (params.value === false) {
        return { color: 'red', 'font-weight': '600' };
      } else {
        return { color: 'green', 'font-weight': '500' };
      }
    }
  },
];
export const ROLE_COL_DEFS = [
  {
    headerName: 'Role Name',
    field: 'roleName',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'Status',
    field: 'active',
    minWidth: 50,
    resizable: true,
    cellRenderer: (params) => params.value === true ? 'Active' : 'Inactive',
    cellStyle: (params) => {
      if (params.value === false) {
        return { color: 'red', 'font-weight': '600' };
      } else {
        return { color: 'green', 'font-weight': '500' };
      }
    }
  },
];
export const CALENDER_COL_DEF = [
  {
    headerName: 'Description',
    field: 'description',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'Start Date',
    field: 'startDate',
    minWidth: 50,
    resizable: true,
    cellRenderer: (params) => {
      return params.value
        ? formatDate(params.value, 'dd MMM, y', 'en')
        : '';
    }
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    minWidth: 50,
    resizable: true,
    cellRenderer: (params) => {
      return params.value
        ? formatDate(params.value, 'dd MMM, y', 'en')
        : '';
    }
  },
  {
    headerName: 'Calendar Activity',
    field: 'calendarActivityName',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'Category',
    field: 'categoryName',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'Status',
    field: 'active',
    minWidth: 50,
    resizable: true,
    cellRenderer: (params) => params.value === true ? 'Active' : 'Inactive',
    cellStyle: (params) => {
      if (params.value === false) {
        return { color: 'red', 'font-weight': '600' };
      } else {
        return { color: 'green', 'font-weight': '500' };
      }
    }
  }
];
export const CHANNEL_COL_DEFS = [
  {
    headerName: 'Name',
    field: 'channelName',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'Description',
    field: 'description',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'Status',
    field: 'active',
    minWidth: 50,
    resizable: true,
    cellRenderer: (params) => params.value === true ? 'Active' : 'Inactive',
    cellStyle: (params) => {
      if (params.value === false) {
        return { color: 'red', 'font-weight': '600' };
      } else {
        return { color: 'green', 'font-weight': '500' };
      }
    }
  }
];
export const CALENDER_ACTIVITY_COL_DEFS = [
  {
    headerName: 'Calendar Activity',
    field: 'calendarActivityName',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'Abbreviation',
    field: 'abbreviation',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'Status',
    field: 'active',
    minWidth: 50,
    resizable: true,
    cellRenderer: (params) => params.value === true ? 'Active' : 'Inactive',
    cellStyle: (params) => {
      if (params.value === false) {
        return { color: 'red', 'font-weight': '600' };
      } else {
        return { color: 'green', 'font-weight': '500' };
      }
    }
  }
];
export const SOURCE_COL_DEFS = [
  {
    headerName: 'Name',
    field: 'sourcingName',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'Description',
    field: 'description',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'Status',
    field: 'active',
    minWidth: 50,
    resizable: true,
    cellRenderer: (params) => params.value === true ? 'Active' : 'Inactive',
    cellStyle: (params) => {
      if (params.value === false) {
        return { color: 'red', 'font-weight': '600' };
      } else {
        return { color: 'green', 'font-weight': '500' };
      }
    }
  }
];
export const CUSTOMER_DISTRIBUTOR_COL_DEFS = [
  {
    headerName: 'Distributor',
    field: 'distributorName',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'Description',
    field: 'description',
    minWidth: 50,
    resizable: true
  },
  {
    headerName: 'Status',
    field: 'active',
    minWidth: 50,
    resizable: true,
    cellRenderer: (params) => params.value === true ? 'Active' : 'Inactive',
    cellStyle: (params) => {
      if (params.value === false) {
        return { color: 'red', 'font-weight': '600' };
      } else {
        return { color: 'green', 'font-weight': '500' };
      }
    }
  }
];
export const CATEGORY_COL_DEFS = [
  {
    headerName: 'Name',
    field: 'categoryName',
    minWidth: 50,
    resizable: true,
  },
  {
    headerName: "Parent Id",
    field: "parentId",
    width: 120,
    enableRowGroup: true

  },
  // {
  //   headerName: 'Status',
  //   field: 'active',
  //   minWidth: 50,
  //   resizable: true,
  //   cellRenderer: (params) => params.value === true ? 'Active' : 'Inactive',
  //   cellStyle: (params) => {
  //     if (params.value === false) {
  //       return { color: 'red', 'font-weight': '600' };
  //     } else {
  //       return { color: 'green', 'font-weight': '500' };
  //     }
  //   }
  // }
];
export const SUMMARY_BY_YEAR_VIEW_COL_DEFS = [
  {
    headerName: 'Channel',
    field: 'channel',
    resizable: false,
    pinned: 'left',
    // rowSpan: (params) => {
    //   var athlete = params.data.channel;
    //   if (athlete === "Retail") {
    //     return 2;
    //   } else {
    //     return 1;
    //   }
    // },
    // cellClassRules: { "cell-span": "true" },
    // custom header component,
  },
  {
    headerName: 'Y-06',
    // custom header component,
    children: [
      { headerName: '21-20', field: 'A2120', editable: true },
    ]
  },
  {
    headerName: 'Y-05',
    children: [
      { headerName: '20-19', field: 'A2019', editable: true },
    ]
  },
  {
    headerName: 'Y-04',
    children: [
      { headerName: '18-19', field: 'A1819', editable: true },
    ]
  },
  {
    headerName: 'Y-03',
    children: [
      { headerName: '17-18', field: 'A1718', editable: true },
    ]
  },
  {
    headerName: 'Y-02',
    children: [
      { headerName: '16-17', field: 'A1617', editable: true },
    ]
  },
  {
    headerName: 'Y-01',
    children: [
      { headerName: '15-16', field: 'A1516', editable: true },
    ]
  }
];
export const SUMMARY_VARIATION_COL_DEFS = [
  {
    headerName: 'Channel',
    field: 'channel',
    resizable: false,
    pinned: 'left',
  },
  {
    headerName: 'Y-06',
    // custom header component,
    children: [
      { headerName: '21-20', field: 'A2120', editable: true },
    ]
  },
  {
    headerName: 'Y-05',
    children: [
      { headerName: '20-19', field: 'A2019', editable: true },
    ]
  },
  {
    headerName: 'Y-04',
    children: [
      { headerName: '18-19', field: 'A1819', editable: true },
    ]
  }
];
export const SUMMARY_STORE_VARIATION_COL_DEFS = [
  {
    headerName: 'Channel',
    field: 'channel',
    resizable: false,
    pinned: 'left',
  },
  {
    headerName: 'Y-06',
    // custom header component,
    children: [
      { headerName: '21-20', field: 'A2120', editable: true },
    ]
  },
  {
    headerName: 'Y-05',
    children: [
      { headerName: '20-19', field: 'A2019', editable: true },
    ]
  },
  {
    headerName: 'Y-04',
    children: [
      { headerName: '18-19', field: 'A1819', editable: true },
    ]
  },
  {
    headerName: 'Stores',
    field: 'store',
    editable: true
  }
];
export const SUMMARY_CATEGORY_COL_DEFS = [
  {
    headerName: 'Category',
    field: 'category',
    resizable: false,
    pinned: 'left',
  },
  {
    headerName: 'TY', field: 'ty', editable: true
  },
  {
    headerName: 'LY - 01', field: 'ly01', editable: true
  },
  {
    headerName: 'LY - 02', field: 'ly02', editable: true
  }
];
export const SUMMARY_CATEGORY_VARIATION_COL_DEFS = [
  {
    headerName: 'TY', field: 'ty', editable: true
  },
  {
    headerName: 'LY - 01', field: 'ly01', editable: true
  },
  {
    headerName: 'LY - 02', field: 'ly02', editable: true
  }
];
export const SUMMARY_CATEGORY_HEADER_COL_DEFS = [
  {
    headerName: 'TY', field: 'ty', editable: true
  },
  {
    headerName: 'LY - 01', field: 'ly01', editable: true
  },
  {
    headerName: 'LY - 02', field: 'ly02', editable: true
  }
];
export const CHANNEL_WISE_SOURCE_WISE_BREAKUP_COL_DEFS = [
  {
    headerName: 'Source',
    field: 'source',
    resizable: false,
    pinned: 'left',
    // rowSpan: (params) => {
    //   var athlete = params.data.channel;
    //   if (athlete === "Retail") {
    //     return 2;
    //   } else {
    //     return 1;
    //   }
    // },
    // cellClassRules: { "cell-span": "true" },
    // custom header component,
  },
  {
    headerName: 'Years',
    field: 'years',
    resizable: false,
    pinned: 'left',
    // rowSpan: (params) => {
    //   var athlete = params.data.channel;
    //   if (athlete === "Retail") {
    //     return 2;
    //   } else {
    //     return 1;
    //   }
    // },
    // cellClassRules: { "cell-span": "true" },
    // custom header component,
  },

  {
    headerName: 'July',
    field: 'A2120',
    editable: true
    // custom header component,

  },
  {
    headerName: 'Aug',
    field: 'A2019',
    editable: true
  },
  {
    headerName: 'Sep',
    field: 'A1918',
    editable: true
  },
  {
    headerName: 'Oct',
    field: 'A1718',
    editable: true
  },
  {
    headerName: 'Nov',
    field: 'A1617',
    editable: true
  },
  {
    headerName: 'Dec',
    field: 'A1516',
    editable: true
  }
  ,
  {
    headerName: 'Jan',
    field: 'A3120',
    editable: true
  }
  ,
  {
    headerName: 'Feb',
    field: 'A3019',
    editable: true
  }
  ,
  {
    headerName: 'Mar',
    field: 'A3918',
    editable: true
  }
  ,
  {
    headerName: 'Apr',
    field: 'A3817',
    editable: true
  }
  ,
  {
    headerName: 'May',
    field: 'A3716',
    editable: true
  }
  ,
  {
    headerName: 'Jun',
    field: 'A3615',
    editable: true
  }
  ,
  {
    headerName: 'Total',
    field: 'A3815',
    editable: true
  }
];
export const MONTH_WISE_SAME_COL_DEFS = [
  {
    headerName: 'Years',
    field: 'years',
    resizable: false,
    pinned: 'left',
    // rowSpan: (params) => {
    //   var athlete = params.data.channel;
    //   if (athlete === "Retail") {
    //     return 2;
    //   } else {
    //     return 1;
    //   }
    // },
    // cellClassRules: { "cell-span": "true" },
    // custom header component,
  },
  {
    headerName: 'July',
    field: 'A2120',
    editable: true
    // custom header component,

  },
  {
    headerName: 'Aug',
    field: 'A2019',
    editable: true
  },
  {
    headerName: 'Sep',
    field: 'A1918',
    editable: true
  },
  {
    headerName: 'Oct',
    field: 'A1718',
    editable: true
  },
  {
    headerName: 'Nov',
    field: 'A1617',
    editable: true
  },
  {
    headerName: 'Dec',
    field: 'A1516',
    editable: true
  }
  ,
  {
    headerName: 'Jan',
    field: 'A3120',
    editable: true
  }
  ,
  {
    headerName: 'Feb',
    field: 'A3019',
    editable: true
  }
  ,
  {
    headerName: 'Mar',
    field: 'A3918',
    editable: true
  }
  ,
  {
    headerName: 'Apr',
    field: 'A3817',
    editable: true
  }
  ,
  {
    headerName: 'May',
    field: 'A3716',
    editable: true
  }
  ,
  {
    headerName: 'Jun',
    field: 'A3615',
    editable: true
  }
  ,
  {
    headerName: 'Total',
    field: 'A3815',
    editable: true
  }


];
export const PERC_MONTH_WISE_SAME_COL_DEFS = [

  {
    headerName: 'July',
    field: 'A2120',
    editable: true
    // custom header component,

  },
  {
    headerName: 'Aug',
    field: 'A2019',
    editable: true
  },
  {
    headerName: 'Sep',
    field: 'A1918',
    editable: true
  },
  {
    headerName: 'Oct',
    field: 'A1718',
    editable: true
  },
  {
    headerName: 'Nov',
    field: 'A1617',
    editable: true
  },
  {
    headerName: 'Dec',
    field: 'A1516',
    editable: true
  }
  ,
  {
    headerName: 'Jan',
    field: 'A3120',
    editable: true
  }
  ,
  {
    headerName: 'Feb',
    field: 'A3019',
    editable: true
  }
  ,
  {
    headerName: 'Mar',
    field: 'A3918',
    editable: true
  }
  ,
  {
    headerName: 'Apr',
    field: 'A3817',
    editable: true
  }
  ,
  {
    headerName: 'May',
    field: 'A3716',
    editable: true
  }
  ,
  {
    headerName: 'Jun',
    field: 'A3615',
    editable: true
  }
  ,
  {
    headerName: 'Total',
    field: 'A3815',
    editable: true
  }


];
export const SIMPLE_MONTH_WISE_SAME_COL_DEFS = [

  {
    headerName: '',
    field: 'A2120',
    editable: true

  }


];
export const SIMPLE_MONTH_WISE_SAME_COL_DEFS1 = [

  {
    headerName: 'SS',
    children: [
      { headerName: '19-20', field: 'A1516', editable: true },
    ]
    // custom header component,

  },
  {
    headerName: 'New',
    children: [
      { headerName: '19-20', field: 'A1517', editable: true },
    ]
    // custom header component,

  }


];
export const CHANNEL_WISE_SOURCE_WISE_COL_DEFS = [
  {
    headerName: 'Channel',
    field: 'channel',
    resizable: false,
    pinned: 'left',
    // rowSpan: (params) => {
    //   var athlete = params.data.channel;
    //   if (athlete === "Retail") {
    //     return 2;
    //   } else {
    //     return 1;
    //   }
    // },
    // cellClassRules: { "cell-span": "true" },
    // custom header component,
  },
  {
    headerName: 'Y-06',
    // custom header component,
    children: [
      { headerName: '21-20', field: 'A2120', editable: true },
    ]
  },
  {
    headerName: 'Y-05',
    children: [
      { headerName: '20-19', field: 'A2019', editable: true },
    ]
  },
  {
    headerName: 'Y-04',
    children: [
      { headerName: '18-19', field: 'A1819', editable: true },
    ]
  },
  {
    headerName: 'Y-03',
    children: [
      { headerName: '17-18', field: 'A1718', editable: true },
    ]
  },
  {
    headerName: 'Y-02',
    children: [
      { headerName: '16-17', field: 'A1617', editable: true },
    ]
  },
  {
    headerName: 'Y-01',
    children: [
      { headerName: '15-16', field: 'A1516', editable: true },
    ]
  }
];
export const SOURCE_WISE_DEPARTMENT_WISE_COL_DEFS = [
  {
    headerName: 'Source',
    field: 'source',
    resizable: false,
    pinned: 'left'
  },
  {
    headerName: 'Department',
    field: 'department',
    resizable: false,
    pinned: 'left'
  },
  {
    headerName: 'Total',
    headerStyle: "display:flex;justify-content:center;align-items:center;",
    pinned: 'left',
    children: [
      {
        headerName: 'Sales',
        children: [
          { headerName: '2017-2018', field: 'A1718', editable: true, pinned: 'left' },
          { headerName: '2018-2019', field: 'A1819', editable: true, pinned: 'left' },
          { headerName: '2019-2020', field: 'A1920', editable: true, pinned: 'left' }
        ]
      },
    ]
  }
  , {
    headerName: 'July',
    headerStyle: "display:flex;justify-content:center;align-items:center;",
    children: [
      {
        headerName: 'Sales',
        children: [
          { headerName: '2017-2018', field: 'JSA1718', editable: true },
          { headerName: '2018-2019', field: 'JSA1819', editable: true },
          { headerName: '2019-2020', field: 'JSA1920', editable: true }
        ]
      }, {
        headerName: 'Growth',
        children: [
          { headerName: 'TY/LY-02', field: 'JGA1718', editable: true },
          { headerName: 'TY/LY-01', field: 'JGA1819', editable: true },
        ]
      }, {
        headerName: 'Contribution',
        children: [
          { headerName: '17-18', field: 'JCA1718', editable: true },
          { headerName: '18-19', field: 'JCA1819', editable: true },
          { headerName: '19-20', field: 'JCA1920', editable: true }
        ]
      }
    ]
  }, {
    headerName: 'August',
    headerStyle: "display:flex;justify-content:center;align-items:center;",
    children: [
      {
        headerName: 'Sales',
        children: [
          { headerName: '2017-2018', field: 'ASA1718', editable: true },
          { headerName: '2018-2019', field: 'ASA1819', editable: true },
          { headerName: '2019-2020', field: 'ASA1920', editable: true }
        ]
      }, {
        headerName: 'Growth',
        children: [
          { headerName: 'TY/LY-02', field: 'AGA1718', editable: true },
          { headerName: 'TY/LY-01', field: 'AGA1819', editable: true },
        ]
      }, {
        headerName: 'Contribution',
        children: [
          { headerName: '17-18', field: 'ACA1718', editable: true },
          { headerName: '18-19', field: 'ACA1819', editable: true },
          { headerName: '19-20', field: 'ACA1920', editable: true }
        ]
      }
    ]
  }, {
    headerName: 'September',
    headerStyle: "display:flex;justify-content:center;align-items:center;",
    children: [
      {
        headerName: 'Sales',
        children: [
          { headerName: '2017-2018', field: 'SSA1718', editable: true },
          { headerName: '2018-2019', field: 'SSA1819', editable: true },
          { headerName: '2019-2020', field: 'SSA1920', editable: true }
        ]
      }, {
        headerName: 'Growth',
        children: [
          { headerName: 'TY/LY-02', field: 'SGA1718', editable: true },
          { headerName: 'TY/LY-01', field: 'SGA1819', editable: true },
        ]
      }, {
        headerName: 'Contribution',
        children: [
          { headerName: '17-18', field: 'SCA1718', editable: true },
          { headerName: '18-19', field: 'SCA1819', editable: true },
          { headerName: '19-20', field: 'SCA1920', editable: true }
        ]
      }
    ]
  }
];
export const SOURCE_WISE_DEPARTMENT_WISE_COL_DEFSL = [
  {
    headerName: '',
    field: 'channel',
    resizable: false,
    pinned: 'left',
  }
];
export const SOURCE_WISE_DEPARTMENT_WISE_COL_DEFSR = [
  {
    headerName: 'Total',
    field: 'total',
    resizable: false,
    pinned: 'left',
  }
];
export const CHANNEL_WISE_STORE_WISE_COL_DEFS = [
  {
    headerName: 'Channel',
    field: 'channel',
    enableRowGroup: true,
  },
  {
    headerName: 'Store',
    field: 'store',
    enableRowGroup: true,
  },
  {
    headerName: 'Total',
    headerStyle: 'display:flex;justify-content:center;align-items:center;',
    children: [
      {
        headerName: 'Sales',
        children: [
          { headerName: '2017-2018', field: 'S1718', editable: true },
          { headerName: '2018-2019', field: 'S1819', editable: true },
          { headerName: '2019-2020', field: 'S1920', editable: true }
        ]
      },
      {
        headerName: 'Growth',
        children: [
          { headerName: 'TY/LY-02', field: 'TYLY02', editable: true },
          { headerName: 'TY/LY-01', field: 'TYLY01', editable: true }
        ]
      },
      {
        headerName: 'Contribution',
        children: [
          { headerName: '17-8', field: 'C1718', editable: true },
          { headerName: '18-19', field: 'C1819', editable: true },
          { headerName: '19-20', field: 'C1920', editable: true }
        ]
      },
    ]
  },
];
