<div *ngIf="userTeam !== INVALID_TEAM" class="container-fluid item-promotion-page" style="margin-top: 15px;">

  <!-- Filter Section Start -->
  <div class="row promotion-grid-filter-row">
    <div class="promotion-filter-field">
      <mat-form-field appearance="fill">
        <input matInput (keydown.enter)="filterPromotions()" type="search" placeholder="New Item Promotion Name"
          [(ngModel)]="releasePromotionName">
        <mat-icon class="search-action-icon" (click)="filterPromotions()" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="promotion-filter-field">
      <mat-form-field appearance="fill">
        <mat-select multiple placeholder="Fiscal Year" [(ngModel)]="fiscalYears" (selectionChange)="fiscalYearChange()">
          <mat-option *ngFor="let year of fiscalYearList" [value]="year.yearId">{{year.yearName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="promotion-filter-field">
      <mat-form-field appearance="fill">
        <mat-select multiple placeholder="Planning Month" [(ngModel)]="planningMonths"
          (selectionChange)="filterPromotions()">
          <mat-option *ngFor="let month of planningMonthList" [value]="month.monthId">{{month.monthName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="promotion-filter-field">
      <mat-form-field appearance="fill">
        <mat-select multiple placeholder="SKU Status" [(ngModel)]="actionIds" (selectionChange)="filterPromotions()">
          <mat-option *ngFor="let action of actionsList" [value]="action.actionId">{{action.actionName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="promotion-filter-field">
      <mat-form-field appearance="fill">
        <mat-select multiple placeholder="Account" [(ngModel)]="accountIds" [disabled]="disabledAccountSelection"
          (selectionChange)="filterPromotions()">
          <mat-option *ngFor="let account of accountList" [value]="account.accountId">{{account.accountName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="promotion-filter-clear-btn">
      <button mat-flat-button color="accent" (click)="clearPromotionFilters()">Clear</button>
    </div>
  </div>
  <!-- End Filter Section End -->

  <!-- Grid Expanstion Panel -->
  <mat-accordion class="inpage-expansion-panel">
    <mat-expansion-panel expanded="true" (opened)="gridPanelOpenState = true" (closed)="gridPanelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title class="section-title">
          New Item Promotion List
        </mat-panel-title>
        <mat-panel-description>
          <div class="row panel-header-action-button">
            <div class="col-md-12 d-none d-sm-none d-md-block tablet-icon">
              <div class="btn-show">
                <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="primary"
                  (click)="openColumnDialog($event)">
                  <i class="material-icons">
                    ballot
                  </i>
                </button>
                <button matTooltip="Export" class="grid-bar-icon" mat-icon-button color="primary"
                  (click)="onExportGridData($event)">
                  <i class="material-icons">
                    insert_drive_file
                  </i>
                </button>

                <button class="import-export-button" *ngIf="isRetailerTeam" mat-flat-button color="accent"
                  [matMenuTriggerFor]="importExportMenu" (click)="handleExport($event)">Export/Import</button>
                <mat-menu #importExportMenu="matMenu">
                  <button mat-menu-item (click)="exportNewItemWeeklyDataTemplate()">Export</button>
                  <button mat-menu-item (click)="importNewItemWeeklyData()">Import
                    <input class="hidden" type="file" id="upload-new-item-promotion-data"
                      name="upload-new-item-promotion-data" accept=".csv" (change)="validateUploadedCSV($event)">
                  </button>
                </mat-menu>

                <button matTooltip="Add New Item Promotion" mat-icon-button color="primary"
                  (click)="openAddPromotionView($event)">
                  <i class="material-icons">
                    add_circle
                  </i>
                </button>
              </div>
            </div>

          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="all-show-grid">
        <ag-grid-angular class="ag-theme-material" (sortChanged)="onGridStateChange($event)"
          (columnVisible)="onGridStateChange($event)" (columnMoved)="onGridStateChange($event)"
          [overlayLoadingTemplate]="overlayLoadingTemplate"
          (columnPinned)="onGridStateChange($event)" [suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups
          toolPanelSuppressValues [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions"
          [columnDefs]="gridColumnDefs" [defaultColDef]="defaultColDef" [rowData]="gridData" [pagination]="true"
          [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize" (gridReady)="onGridReady($event)"
          [modules]="modules" #promotionGrid>
        </ag-grid-angular>
      </div>

      <ag-grid-angular *ngIf="isExportingTemplate" class="ag-theme-material hide-grid" [gridOptions]="gridOptions"
        [columnDefs]="forecastGridColDef" [defaultColDef]="defaultColDef" [rowData]="forecastGridData"
        (gridReady)="forecastGridReady($event)" [modules]="modules" #forecastPromotionGrid>
      </ag-grid-angular>

    </mat-expansion-panel>
  </mat-accordion>
  <!-- End Grid Expanstion Panel -->
</div>

<app-privilege-error *ngIf="userTeam === INVALID_TEAM"></app-privilege-error>