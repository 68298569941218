import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-item-plan',
  templateUrl: './item-plan.component.html',
  styleUrls: ['./item-plan.component.css']
})
export class ItemPlanComponent implements OnInit {
  public submitted = false;
  public isEdit = false;
  public columnDefs = [];
  private gridApi: any;
  public tenureTypeList = [];
  public tenureTypeListTemp = [];
  public rowList = [];
  public goalTypeList = [];
  public gridOptions: any;
  public getRowHeight;
  public getRowStyle;
  public headerHeight;
  public modules = AllCommunityModules;
  public clientId = 1;
  public channelList = [{ channelId: 1, channelName: 'Retail' }];
  public channel = null;
  public csvRecords: any;
  public customerSelectedList = [];
  public customerList = [];
  public classSelectedList = [];
  public classList = [];
  public yearList = [];
  public monthList = [];
  public seletedYear = null;
  public selectedMonth = null;
  public defaultRow = {};
  files: File[] = [];
  public selectAll = true;
  @ViewChild('ExcelImport') excelImport: TemplateRef<any>;
  @ViewChild('dialog') dialogTemplate: TemplateRef<any>;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog) {
    this.gridOptions = {
      frameworkComponents: {},
      // pagination: true,
      // paginationAutoPageSize: true,
      // suppressMenuHide: true,
    };
    this.headerHeight = 40;
    this.gridOptions.getRowHeight = function (params) {
      return 40;
    };
    this.gridOptions.getRowStyle = function (params) {
      if (params.data && params.data.apBudgetTenureName && typeof params.data.apBudgetTenureName === 'string') {
        if (params.data && params.data.apBudgetTenureName && params.data.apBudgetTenureName.endsWith('FY21')) {
          return { 'background-color': '#D6EAF8' };
        }
        if (params.data && params.data.apBudgetTenureName && params.data.apBudgetTenureName.startsWith('Q')) {
          return { 'background-color': '#3498DB' };
        }
        if (params.data && params.data.apBudgetTenureName && params.data.apBudgetTenureName.startsWith('WK')) {
          return { 'background-color': '' };
        }
      }
    };
  }

  ngOnInit() {
    this.customerList.push(
      { apCustomerId: 1, apCustomerName: 'Best Buy' },
      { apCustomerId: 2, apCustomerName: 'Walmart' },
      { apCustomerId: 3, apCustomerName: 'Amazon' },
      // { apCustomerId: 4, apCustomerName: 'Target' },
      { apCustomerId: 5, apCustomerName: 'Dollar Tree' },
      { apCustomerId: 6, apCustomerName: 'Sam\s Club' }
    );
    this.classList.push(
      { apClassId: 1, apClassName: 'New Release' },
      { apClassId: 2, apClassName: 'Recent Release' },
      { apClassId: 3, apClassName: 'Theatrical Catalog' },
      // { apClassId: 4, apClassName: 'Target' },
      { apClassId: 5, apClassName: 'TV' }
    );
    for (let index = 0; index < 12; index++) {
      const date = new Date(2009, index, 10);  // 2009-11-10
      const month = date.toLocaleString('default', { month: 'long' });
      this.monthList.push(
        {
          monthId: index + 1, monthName: `${month}`
        });
    }
    console.log(this.monthList);
    for (let index = new Date().getFullYear() + 5; index > 1980; index--) {
      this.yearList.push(index);
    }
    this.columnDefs.push(
      {
        headerName: 'Week Ending',

        editable: false,
        width: 200,
        field: 'weekEnding',
      },
      {
        headerName: 'Total',
        editable: false,
        width: 150,
        field: 'total'
      },
      {
        headerName: '01-05-20',

        editable: false,
        width: 200,
        field: 'col010520',
      },
      {
        headerName: '01-12-20',
        editable: false,
        width: 150,
        field: 'col011220'
      },
      {
        headerName: '01-29-20',

        editable: false,
        width: 200,
        field: 'col012920',
      },
      {
        headerName: '02-02-20',
        editable: false,
        width: 150,
        field: 'col020220'
      },
      {
        headerName: '02-09-20',

        editable: false,
        width: 200,
        field: 'col020920',
      },
      {
        headerName: '02-16-20',
        editable: false,
        width: 150,
        field: 'col021620'
      }
    );

    this.columnDefs.forEach((column) => this.defaultRow[column.field] = Math.random());
    const manupulatedList = [];
    const obj = JSON.parse(JSON.stringify(this.defaultRow));
    // for (let index = 1; index <= 48; index++) {
    //   this.rowList.push({ ...obj });
    // }
    this.manipulateObject(this.rowList);
    // for (let index = 1; index <= 48; index++) {
    //   if (index % 4 === 0) {
    //     this.rowList.unshift({ ...obj, isMonth: true });
    //     const chunk = this.rowList.slice(index - 3, index + 1);
    //   }
    // }
    // for (let index = 1; index <= 60; index++) {
    //   if (index % 15 === 0) {
    //     this.rowList.unshift({ ...obj, isQuarter: true, isMonth: false });
    //   }
    // }
    // this.rowList.push({ ...this.defaultRow, isTotalYear: true, isQuarter: false, isMonth: false });
  }
  openDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(this.dialogTemplate, { width: '500px', data: data || {} });
  }
  openExcelImportDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(this.excelImport, { width: '500px', data: data || {} });
  }
  public mapList = async (event) => {
    const budgetList = await this.configurationService.GetBudgetList({ apCustomerId: 2, tenure: 2020 }).toPromise();
    console.log(budgetList);
  }
  onCellValueChanged = (value) => {
    this.manipulateObjectOnValueChange(this.rowList);
  }
  public manipulateObject = (rowList: any) => {
    let list = [];
    for (let index = 0; index < 31; index++) {
      let rowElement = JSON.parse(JSON.stringify(this.defaultRow));
      if (index === 0) {
        rowElement.weekEnding = 'Actual POS Units';
        rowElement.total = '3585';
        rowElement.col010520 = '1829';
        rowElement.col011220 = '1756';
        rowElement.col012920 = '';
        rowElement.col020220 = '';
        rowElement.col020920 = '';
        rowElement.col021620 = '';
      } else if (index === 1) {
        rowElement.weekEnding = 'FCST POS Units';
        rowElement.total = '10000';
        rowElement.col010520 = '1500';
        rowElement.col011220 = '1500';
        rowElement.col012920 = '1500';
        rowElement.col020220 = '1500';
        rowElement.col020920 = '2000';
        rowElement.col021620 = '2000';
      }
      else if (index === 2) {
        rowElement.weekEnding = 'Fcst POS Base Units';
        rowElement.total = '9000';
        rowElement.col010520 = '1500';
        rowElement.col011220 = '1500';
        rowElement.col012920 = '1500';
        rowElement.col020220 = '1500';
        rowElement.col020920 = '2000';
        rowElement.col021620 = '2000';
      }
      else if (index === 3) {
        rowElement.weekEnding = 'Fcst Incremental Ad POS Units';
        rowElement.total = '1000';
        rowElement.col010520 = '0';
        rowElement.col011220 = '0';
        rowElement.col012920 = '0';
        rowElement.col020220 = '0';
        rowElement.col020920 = '500';
        rowElement.col021620 = '500';
      }
      else if (index === 4) {
        rowElement.weekEnding = 'Actual ARP';
        rowElement.total = '';
        rowElement.col010520 = '9.99';
        rowElement.col011220 = '12.99';
        rowElement.col012920 = '';
        rowElement.col020220 = '';
        rowElement.col020920 = '';
        rowElement.col021620 = '';
      }
      else if (index === 5) {
        rowElement.weekEnding = 'Fcst ARP';
        rowElement.total = '';
        rowElement.col010520 = '9.99';
        rowElement.col011220 = '12.99';
        rowElement.col012920 = '9.99';
        rowElement.col020220 = '9.99';
        rowElement.col020920 = '7.99';
        rowElement.col021620 = '12.99';
      }
      else if (index === 6) {
        rowElement.weekEnding = 'Actual POS $';
        rowElement.total = '$ 41,082';
        rowElement.col010520 = '$ 18,272';
        rowElement.col011220 = '$ 22,810';
        rowElement.col012920 = '$ -';
        rowElement.col020220 = '$ -';
        rowElement.col020920 = '$ -';
        rowElement.col021620 = '$ -';
      }
      else if (index === 7) {
        rowElement.weekEnding = 'Fcst POS $';
        rowElement.total = '$ 106,400';
        rowElement.col010520 = '$ 14,985';
        rowElement.col011220 = '$ 19,985';
        rowElement.col012920 = '$ 14,485';
        rowElement.col020220 = '$ 1,985';
        rowElement.col020920 = '$ 15,985';
        rowElement.col021620 = '$ 25,980';
      }
      else if (index === 8) {
        rowElement.weekEnding = 'Seasonal Index';
        rowElement.total = '';
        rowElement.col010520 = '1.08';
        rowElement.col011220 = '1.07';
        rowElement.col012920 = '1.12';
        rowElement.col020220 = '1.12';
        rowElement.col020920 = '1.12';
        rowElement.col021620 = '1.11';
      } else if (index === 9) {
        rowElement.weekEnding = 'Decay %';
        rowElement.total = '';
        rowElement.col010520 = '8%';
        rowElement.col011220 = '7%';
        rowElement.col012920 = '12%';
        rowElement.col020220 = '12%';
        rowElement.col020920 = '12%';
        rowElement.col021620 = '11%';
      }
      else if (index === 10) {
        rowElement.weekEnding = 'Ad Flag';
        rowElement.total = '';
        rowElement.col010520 = 'YES';
        rowElement.col011220 = 'YES';
        rowElement.col012920 = 'NO';
        rowElement.col020220 = 'YES';
        rowElement.col020920 = 'NO';
        rowElement.col021620 = 'YES';
      }
      else if (index === 11) {
        rowElement.weekEnding = 'Store Count';
        rowElement.total = '';
        rowElement.col010520 = '3400';
        rowElement.col011220 = '3400';
        rowElement.col012920 = '3400';
        rowElement.col020220 = '3400';
        rowElement.col020920 = '3600';
        rowElement.col021620 = '3600';
      }
      else if (index === 12) {
        rowElement.weekEnding = 'Fixtures';
        rowElement.total = '';
        rowElement.col010520 = 'In-line';
        rowElement.col011220 = 'In-line';
        rowElement.col012920 = 'In-line';
        rowElement.col020220 = 'In-line';
        rowElement.col020920 = 'In-line, Endcap';
        rowElement.col021620 = 'In-line, Endcap';
      }
      else if (index === 13) {
        rowElement.weekEnding = 'Promo Themes';
        rowElement.total = '';
        rowElement.col010520 = 'In-line Reset';
        rowElement.col011220 = 'In-line Reset';
        rowElement.col012920 = 'In-line Reset';
        rowElement.col020220 = 'In-line Reset';
        rowElement.col020920 = 'Reset';
        rowElement.col021620 = 'Reset';
      }
      else if (index === 14) {
        rowElement.weekEnding = '';
        rowElement.total = '';
        rowElement.col010520 = '';
        rowElement.col011220 = '';
        rowElement.col012920 = '';
        rowElement.col020220 = '';
        rowElement.col020920 = '';
        rowElement.col021620 = '';
      }
      else if (index === 15) {
        rowElement.weekEnding = 'Actual Ship Units';
        rowElement.total = '3000';
        rowElement.col010520 = '1500';
        rowElement.col011220 = '1500';
        rowElement.col012920 = '';
        rowElement.col020220 = '';
        rowElement.col020920 = '';
        rowElement.col021620 = '';
      }
      else if (index === 16) {
        rowElement.weekEnding = 'Fcst Ship Units';
        rowElement.total = '8300';
        rowElement.col010520 = '1200';
        rowElement.col011220 = '1200';
        rowElement.col012920 = '2000';
        rowElement.col020220 = '1500';
        rowElement.col020920 = '1200';
        rowElement.col021620 = '1200';
      }
      else if (index === 17) {
        rowElement.weekEnding = 'Fcst Ship Base Units';
        rowElement.total = '7200';
        rowElement.col010520 = '1200';
        rowElement.col011220 = '1200';
        rowElement.col012920 = '1200';
        rowElement.col020220 = '1200';
        rowElement.col020920 = '1200';
        rowElement.col021620 = '1200';
      }
      else if (index === 18) {
        rowElement.weekEnding = 'Fcst Incremental Ad Ship Units';
        rowElement.total = '1100';
        rowElement.col010520 = '0';
        rowElement.col011220 = '0';
        rowElement.col012920 = '800';
        rowElement.col020220 = '300';
        rowElement.col020920 = '0';
        rowElement.col021620 = '0';
      }
      else if (index === 19) {
        rowElement.weekEnding = 'Actual Ship $';
        rowElement.total = '0';
        rowElement.col010520 = '0';
        rowElement.col011220 = '';
        rowElement.col012920 = '';
        rowElement.col020220 = '';
        rowElement.col020920 = '';
        rowElement.col021620 = '';
      }
      else if (index === 20) {
        rowElement.weekEnding = '';
        rowElement.total = '';
        rowElement.col010520 = '';
        rowElement.col011220 = '';
        rowElement.col012920 = '';
        rowElement.col020220 = '';
        rowElement.col020920 = '';
        rowElement.col021620 = '';
      }
      else if (index === 21) {
        rowElement.weekEnding = 'Base Unit Cost';
        rowElement.total = '';
        rowElement.col010520 = '$ 4.50';
        rowElement.col011220 = '$ 4.50';
        rowElement.col012920 = '$ 4.50';
        rowElement.col020220 = '$ 4.50';
        rowElement.col020920 = '$ 4.50';
        rowElement.col021620 = '$ 4.50';
      }
      else if (index === 22) {
        rowElement.weekEnding = 'Retailer Unit Cost';
        rowElement.total = '$ 6.50';
        rowElement.col010520 = '$ 6.50';
        rowElement.col011220 = '$ 6.50';
        rowElement.col012920 = '$ 6.50';
        rowElement.col020220 = '$ 6.50';
        rowElement.col020920 = '$ 6.50';
        rowElement.col021620 = '$ 6.50';
      }

      else if (index === 23) {
        rowElement.weekEnding = '% Markup';
        rowElement.total = '44%';
        rowElement.col010520 = '44%';
        rowElement.col011220 = '44%';
        rowElement.col012920 = '44%';
        rowElement.col020220 = '44%';
        rowElement.col020920 = '44%';
        rowElement.col021620 = '44%';
      }else if (index === 24) {
        rowElement.weekEnding = 'RMF $';
        rowElement.total = '$ 1,000';
        rowElement.col010520 = '0';
        rowElement.col011220 = '0';
        rowElement.col012920 = '800';
        rowElement.col020220 = '300';
        rowElement.col020920 = '0';
        rowElement.col021620 = '0';
      }
      else if (index === 25) {
        rowElement.weekEnding = 'Net Margin $ (Less RMF $)';
        rowElement.total = '$ 20,170';
        rowElement.col010520 = '$ 3,658';
        rowElement.col011220 = '$ 3,512';
        rowElement.col012920 = '$3,000';
        rowElement.col020220 = '$ 3,500';
        rowElement.col020920 = '$ 3,500';
        rowElement.col021620 = '$ 3,500';
      }
      else if (index === 26) {
        rowElement.weekEnding = '';
        rowElement.total = '';
        rowElement.col010520 = '';
        rowElement.col011220 = '';
        rowElement.col012920 = '';
        rowElement.col020220 = '';
        rowElement.col020920 = '';
        rowElement.col021620 = '';
      }
      else if (index === 27) {
        rowElement.weekEnding = 'Actual Return Units';
        rowElement.total = '262';
        rowElement.col010520 = '121';
        rowElement.col011220 = '141';
        rowElement.col012920 = '';
        rowElement.col020220 = '';
        rowElement.col020920 = '';
        rowElement.col021620 = '';
      }
      else if (index === 28) {
        rowElement.weekEnding = 'Fcst Return Units';
        rowElement.total = '610';
        rowElement.col010520 = '110';
        rowElement.col011220 = '100';
        rowElement.col012920 = '100';
        rowElement.col020220 = '100';
        rowElement.col020920 = '100';
        rowElement.col021620 = '100';
      }
      else if (index === 29) {
        rowElement.weekEnding = 'Actual Return $';
        rowElement.total = '$ 1,703';
        rowElement.col010520 = '$ 787';
        rowElement.col011220 = '$ 917';
        rowElement.col012920 = '$ -';
        rowElement.col020220 = '$ -';
        rowElement.col020920 = '$ -';
        rowElement.col021620 = '$ -';
      }
      else if (index === 30) {
        rowElement.weekEnding = 'Fcst Return $';
        rowElement.total = '$ 3,965';
        rowElement.col010520 = '$ 715';
        rowElement.col011220 = '$ 650';
        rowElement.col012920 = '$ 650';
        rowElement.col020220 = '$ 650';
        rowElement.col020920 = '$ 650';
        rowElement.col021620 = '$ 650';
      }

      list.push(JSON.parse(JSON.stringify(rowElement)));
    }
    this.rowList = [];
    this.rowList = list;
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowList);
    }
  }
  onFilterTextBoxChanged(event) {
    // this.gridOptions.api.setQuickFilter(event.target.value);
  }
  public manipulateObjectOnValueChange = (rowList: any) => {
    let list = [];
    const defaultObject = JSON.parse(JSON.stringify(this.defaultRow));
    for (let index = 1; index < rowList.length; index = index + 5) {
      let monthElement = JSON.parse(JSON.stringify(this.defaultRow));
      let w1 = rowList[index];
      let w2 = rowList[index + 1];
      let w3 = rowList[index + 2];
      let w4 = rowList[index + 3];
      if (index === 1) {
        monthElement.apBudgetTenureName = `${this.monthList[0].monthName} FY21`;
      }
      if (index % 6 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[1].monthName} FY21`;
      }
      if (index % 11 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[2].monthName} FY21`;
      }
      if (index % 17 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[3].monthName} FY21`;
      }
      if (index % 22 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[4].monthName} FY21`;
      }
      if (index % 27 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[5].monthName} FY21`;
      }
      if (index % 33 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[6].monthName} FY21`;
      }
      if (index % 38 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[7].monthName} FY21`;
      }
      if (index % 43 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[8].monthName} FY21`;
      }
      if (index % 49 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[9].monthName} FY21`;
      }
      if (index % 54 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[10].monthName} FY21`;
      }
      if (index % 59 === 0) {
        monthElement.apBudgetTenureName = `${this.monthList[11].monthName} FY21`;
      }
      this.createList(monthElement, w1, w2, w3, w4);
      list.push(JSON.parse(JSON.stringify(monthElement)));
      list.push(JSON.parse(JSON.stringify(w1)));
      list.push(JSON.parse(JSON.stringify(w2)));
      list.push(JSON.parse(JSON.stringify(w3)));
      list.push(JSON.parse(JSON.stringify(w4)));
      if (index === 11) {
        let q1 = {};
        Object.keys(this.defaultRow).forEach(key => {
          if (key !== 'apBudgetTenureName') {
            q1[key] = list[0][key] + list[5][key] + list[10][key];
          }
        });
        q1['apBudgetTenureName'] = 'Q1';
        list.push(JSON.parse(JSON.stringify(q1)));
      }
      if (index === 27) {
        let q2 = {};
        Object.keys(this.defaultRow).forEach(key => {
          if (key !== 'apBudgetTenureName') {
            q2[key] = list[16][key] + list[21][key] + list[26][key];
          }
        });
        q2['apBudgetTenureName'] = 'Q2';
        list.push(JSON.parse(JSON.stringify(q2)));
      }
      if (index === 43) {
        let q3 = {};
        Object.keys(this.defaultRow).forEach(key => {
          if (key !== 'apBudgetTenureName') {
            q3[key] = list[32][key] + list[37][key] + list[42][key];
          }
        });
        q3['apBudgetTenureName'] = 'Q3';
        list.push(JSON.parse(JSON.stringify(q3)));
      }
      if (index === 59) {
        let q4 = {};
        Object.keys(this.defaultRow).forEach(key => {
          if (key !== 'apBudgetTenureName') {
            q4[key] = list[48][key] + list[53][key] + list[58][key];
          }
        });
        q4['apBudgetTenureName'] = 'Q4';
        list.push(JSON.parse(JSON.stringify(q4)));
        let total = {};
        Object.keys(this.defaultRow).forEach(key => {
          if (key !== 'apBudgetTenureName') {
            total[key] = list[15][key] + list[31][key] + list[47][key] + list[63][key];
          }
        });
        total['apBudgetTenureName'] = 'Total Year';
        list.push(JSON.parse(JSON.stringify(total)));
      }
      if (index === 11 || index === 27 || index === 43 || index === 59) {
        index++;
      }
    }
    this.rowList = [];
    this.rowList = list;
    if (this.gridOptions && this.gridOptions.api) {
      setTimeout(() => {
        this.gridOptions.api.setRowData(this.rowList);
      }, 100);
    }
  }
  public createList = (monthElement, w1, w2, w3, w4) => {
    w1.apBudgetTenureName = `WK1`;
    w2.apBudgetTenureName = `WK2`;
    w3.apBudgetTenureName = `WK3`;
    w4.apBudgetTenureName = `WK4`;

    monthElement.unitBudget = w1.unitBudget + w2.unitBudget + w3.unitBudget + w4.unitBudget;
    monthElement.unitFcst = w1.unitFcst + w2.unitFcst + w3.unitFcst + w4.unitFcst;
    monthElement.unitAct = w1.unitAct + w2.unitAct + w3.unitAct + w4.unitAct;
    monthElement.unitFcstToAct = (monthElement.unitFcst / monthElement.unitAct) - 1;
    w1.unitFcstToAct = (w1.unitFcst / w1.unitAct) - 1;
    w2.unitFcstToAct = (w2.unitFcst / w2.unitAct) - 1;
    w3.unitFcstToAct = (w3.unitFcst / w3.unitAct) - 1;
    w4.unitFcstToAct = (w4.unitFcst / w4.unitAct) - 1;
    monthElement.unitLy = w1.unitLy + w2.unitLy + w3.unitLy + w4.unitLy;;
    monthElement.unitFcstToLy = (monthElement.unitFcst / monthElement.unitLy) - 1;
    w1.unitFcstToLy = (w1.unitFcst / w1.unitLy) - 1;
    w2.unitFcstToLy = (w2.unitFcst / w2.unitLy) - 1;
    w3.unitFcstToLy = (w3.unitFcst / w3.unitLy) - 1;
    w4.unitFcstToLy = (w4.unitFcst / w4.unitLy) - 1;

    monthElement.shippedBudget = w1.shippedBudget + w2.shippedBudget + w3.shippedBudget + w4.shippedBudget;
    monthElement.shippedFcst = w1.shippedFcst + w2.shippedFcst + w3.shippedFcst + w4.shippedFcst;
    monthElement.shippedAct = w1.shippedAct + w2.shippedAct + w3.shippedAct + w4.shippedAct;
    monthElement.shippedFcsttoBudget = (monthElement.shippedFcst / monthElement.shippedAct) - 1;
    w1.shippedFcsttoBudget = (w1.shippedFcst / w1.shippedAct) - 1;
    w2.shippedFcsttoBudget = (w2.shippedFcst / w2.shippedAct) - 1;
    w3.shippedFcsttoBudget = (w3.shippedFcst / w3.shippedAct) - 1;
    w4.shippedFcsttoBudget = (w4.shippedFcst / w4.shippedAct) - 1;
    monthElement.shippedLY = w1.shippedLY + w2.shippedLY + w3.shippedLY + w4.shippedLY;
    monthElement.shippedFcsttoLY = (monthElement.shippedFcst / monthElement.shippedLY) - 1;
    w1.shippedFcsttoLY = (w1.shippedFcst / w1.shippedLY) - 1;
    w2.shippedFcsttoLY = (w2.shippedFcst / w2.shippedLY) - 1;
    w3.shippedFcsttoLY = (w3.shippedFcst / w3.shippedLY) - 1;
    w4.shippedFcsttoLY = (w4.shippedFcst / w4.shippedLY) - 1;

    monthElement.posUnitBudget = w1.posUnitBudget + w2.posUnitBudget + w3.posUnitBudget + w4.posUnitBudget;
    monthElement.posUnitFcst = w1.posUnitFcst + w2.posUnitFcst + w3.posUnitFcst + w4.posUnitFcst;
    monthElement.posUnitAct = w1.posUnitAct + w2.posUnitAct + w3.posUnitAct + w4.posUnitAct;
    monthElement.posUnitFcstToBudget = (monthElement.posUnitFcst / monthElement.posUnitAct) - 1;
    w1.posUnitFcstToBudget = (w1.posUnitFcst / w1.posUnitAct) - 1;
    w2.posUnitFcstToBudget = (w2.posUnitFcst / w2.posUnitAct) - 1;
    w3.posUnitFcstToBudget = (w3.posUnitFcst / w3.posUnitAct) - 1;
    w4.posUnitFcstToBudget = (w4.posUnitFcst / w4.posUnitAct) - 1;
    monthElement.posUnitLy = w1.posUnitLy + w2.posUnitLy + w3.posUnitLy + w4.posUnitLy;
    monthElement.posUnitFcstToLy = (monthElement.posUnitFcst / monthElement.posUnitLy) - 1;
    w1.posUnitFcstToLy = (w1.posUnitFcst / w1.posUnitLy) - 1;
    w2.posUnitFcstToLy = (w2.posUnitFcst / w2.posUnitLy) - 1;
    w3.posUnitFcstToLy = (w3.posUnitFcst / w3.posUnitLy) - 1;
    w4.posUnitFcstToLy = (w4.posUnitFcst / w4.posUnitLy) - 1;

    monthElement.posBudget = w1.posBudget + w2.posBudget + w3.posBudget + w4.posBudget;
    monthElement.posFcst = w1.posFcst + w2.posFcst + w3.posFcst + w4.posFcst;
    monthElement.posAct = w1.posAct + w2.posAct + w3.posAct + w4.posAct;
    monthElement.posFcstToBudget = (monthElement.posFcst / monthElement.posAct) - 1;
    w1.posFcstToBudget = (w1.posFcst / w1.posAct) - 1;
    w2.posFcstToBudget = (w2.posFcst / w2.posAct) - 1;
    w3.posFcstToBudget = (w3.posFcst / w3.posAct) - 1;
    w4.posFcstToBudget = (w4.posFcst / w4.posAct) - 1;
    monthElement.posLy = w1.posLy + w2.posLy + w3.posLy + w4.posLy;
    monthElement.posFcstToLy = (monthElement.posFcst / monthElement.posLy) - 1;
    w1.posFcstToLy = (w1.posFcst / w1.posLy) - 1;
    w2.posFcstToLy = (w2.posFcst / w2.posLy) - 1;
    w3.posFcstToLy = (w3.posFcst / w3.posLy) - 1;
    w4.posFcstToLy = (w4.posFcst / w4.posLy) - 1;

    monthElement.rmfBudget = w1.rmfBudget + w2.rmfBudget + w3.rmfBudget + w4.rmfBudget;
    monthElement.rmfFcst = w1.rmfFcst + w2.rmfFcst + w3.rmfFcst + w4.rmfFcst;
    monthElement.rmfAct = w1.rmfAct + w2.rmfAct + w3.rmfAct + w4.rmfAct;
    monthElement.rmfFcstToBudget = (monthElement.rmfFcst / monthElement.rmfAct) - 1;
    w1.rmfFcstToBudget = (w1.rmfFcst / w1.rmfAct) - 1;
    w2.rmfFcstToBudget = (w2.rmfFcst / w2.rmfAct) - 1;
    w3.rmfFcstToBudget = (w3.rmfFcst / w3.rmfAct) - 1;
    w4.rmfFcstToBudget = (w4.rmfFcst / w4.rmfAct) - 1;
    monthElement.rmfLy = w1.rmfLy + w2.rmfLy + w3.rmfLy + w4.rmfLy;
    monthElement.rmfFcstToLy = (monthElement.rmfFcst / monthElement.rmfLy) - 1;
    w1.rmfFcstToLy = (w1.rmfFcst / w1.rmfLy) - 1;
    w2.rmfFcstToLy = (w2.rmfFcst / w2.rmfLy) - 1;
    w3.rmfFcstToLy = (w3.rmfFcst / w3.rmfLy) - 1;
    w4.rmfFcstToLy = (w4.rmfFcst / w4.rmfLy) - 1;

    monthElement.arpBudget = w1.arpBudget + w2.arpBudget + w3.arpBudget + w4.arpBudget;
    monthElement.arpFcst = w1.arpFcst + w2.arpFcst + w3.arpFcst + w4.arpFcst;
    monthElement.arpAct = w1.arpAct + w2.arpAct + w3.arpAct + w4.arpAct;
    monthElement.arpFcstToBudget = (monthElement.arpFcst / monthElement.arpAct) - 1;
    w1.arpFcstToBudget = (w1.arpFcst / w1.arpAct) - 1;
    w2.arpFcstToBudget = (w2.arpFcst / w2.arpAct) - 1;
    w3.arpFcstToBudget = (w3.arpFcst / w3.arpAct) - 1;
    w4.arpFcstToBudget = (w4.arpFcst / w4.arpAct) - 1;
    monthElement.arpLy = w1.arpLy + w2.arpLy + w3.arpLy + w4.arpLy;
    monthElement.arpFcstToLy = (monthElement.arpFcst / monthElement.arpLy) - 1;
    w1.arpFcstToLy = (w1.arpFcst / w1.arpLy) - 1;
    w2.arpFcstToLy = (w2.arpFcst / w2.arpLy) - 1;
    w3.arpFcstToLy = (w3.arpFcst / w3.arpLy) - 1;
    w4.arpFcstToLy = (w4.arpFcst / w4.arpLy) - 1;
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  selectAllSelected(flag: boolean): void {
    if (flag) {
      this.columnDefs.forEach(column => column.show = true);
    } else {
      this.columnDefs.forEach(column => column.show = false);
    }
  }
  public onSave = () => {
    const newColumnDefs = this.columnDefs.filter(column => column.show);
    this.gridOptions.api.setColumnDefs(newColumnDefs);
    this.dialogRef.close();
  }
  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }
  exportToExcel = () => {
    this.gridApi.exportDataAsCsv();
  }
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
  public onSubmit = () => {

  }
}
