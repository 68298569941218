import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-replenishment-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {

  inventoryPolicy = '';
  orderingPolicy = '';

  constructor() { }

  ngOnInit(): void {
  }

  preventEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

}
