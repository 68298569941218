<div *ngIf="userTeam !== INVALID_TEAM" class="container-fluid promotion-planner-page" style="margin-top: 15px;">
	<app-promotion-planner-filters (filterEvent)="applyFilters($event)" [gridApiRef]="gridApi">
	</app-promotion-planner-filters>

	<div class="row">
		<div class="col-md-12 d-none d-sm-none d-md-block tablet-icon">
			<div class="btn-show px-4">
				<div class="section-title">Promotion List</div>
				<button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="primary"
					(click)="openColumnDialog()">
					<i class="material-icons">
						ballot
					</i>
				</button>
				<button matTooltip="Export" class="grid-bar-icon" mat-icon-button color="primary"
					(click)="onExportGridData()">
					<i class="material-icons">
						insert_drive_file
					</i>
				</button>

				<button class="import-export-button" *ngIf="isRetailerTeam" mat-flat-button color="accent"
					[matMenuTriggerFor]="importExportMenu">Export/Import</button>
				<mat-menu #importExportMenu="matMenu">
					<button mat-menu-item (click)="exportDataTemplate(true)">Export</button>
					<button mat-menu-item (click)="importGeneralDataByTemplate()">Import
						<input class="hidden" type="file" id="upload-general-promotion-planner-data"
							name="upload-general-promotion-planner-data" accept=".csv"
							(change)="validateUploadedCSV($event)">
					</button>
					<button mat-menu-item (click)="exportDataTemplate(false)">Export (Merchant View)</button>
					<button mat-menu-item (click)="importMerchantDataByTemplate()">Import (Merchant View)
						<input class="hidden" type="file" id="upload-merchant-promotion-planner-data"
							name="upload-merchant-promotion-planner-data" accept=".csv"
							(change)="validateUploadedCSV($event)">
					</button>
				</mat-menu>

				<button *ngIf="isRetailerTeam" matTooltip="Add New Promotion" mat-icon-button color="primary"
					(click)="addNewPromotion()">
					<i class="material-icons">
						add_circle
					</i>
				</button>
			</div>
		</div>
	</div>

	<div class="all-show-grid">
		<ag-grid-angular class="ag-theme-material" (sortChanged)="onGridStateChange($event)"
			(columnVisible)="onGridStateChange($event)" (columnMoved)="onGridStateChange($event)"
			[overlayLoadingTemplate]="overlayLoadingTemplate" (columnPinned)="onGridStateChange($event)"
			[suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups toolPanelSuppressValues
			[frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions" [columnDefs]="gridColumnDefs"
			[defaultColDef]="defaultColDef" [rowData]="gridData" [pagination]="true"
			[paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
			(gridReady)="onGridReady($event)" [modules]="modules" #promotionPlannerGrid>
		</ag-grid-angular>
	</div>

	<ag-grid-angular *ngIf="isExportingTemplate" class="ag-theme-material hide-grid" [gridOptions]="gridOptions"
		[columnDefs]="exportGridColDef" [defaultColDef]="defaultColDef" [rowData]="exportGridData"
		(gridReady)="exportGridReady($event)" [modules]="modules" #promotionPlannerExportGrid>
	</ag-grid-angular>

</div>

<app-privilege-error *ngIf="userTeam === INVALID_TEAM"></app-privilege-error>