<div class="container summary-wrapper">
  <div class="row">
    <div class="col-md-12">
      <div class="all-show-grid">
        <label class="grid-head">New Store</label>
        <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20'
           groupHeaders suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
          [pagination]="true" [gridOptions]="gridOptions" [headerHeight]="headerHeight" [getRowHeight]="getRowHeight" [columnDefs]="columnDefs" [rowData]="storeList"
          (gridReady)="onGridReady($event)" [modules]="modules" #grid>
        </ag-grid-angular>
      </div>
      <div class="col-md-12">
        <div class="all-show-grid">
          <label class="grid-head">%Age Contribution</label>
          <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20'
             groupHeaders suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
            [pagination]="true" [gridOptions]="gridOptionsPercentage" [headerHeight]="headerHeight" [getRowHeight]="getRowHeight" [columnDefs]="columnDefsPercentage" [rowData]="storeListPercentage"
            (gridReady)="onGridReady($event)" [modules]="modules" #grid>
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>



