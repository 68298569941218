<div class="container-fluid">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="">Channel</label>
        <mat-select [class.error]="submitted && !accountSetting.channelId" [(ngModel)]="accountSetting.channelId"
          [disabled]="(accountSetting && accountSetting.accountSettingId) || !itemModel.editPermission"
          (selectionChange)="onChannelChange(accountSetting.channelId)">
          <mat-option *ngFor="let channel of channelList" [value]="channel.channelId">{{channel.channelName}}
          </mat-option>
        </mat-select>
      </div>
      <div class="form-group">
        <label for="">Region</label>
        <mat-select [class.error]="submitted && !accountSetting.regionId" (selectionChange)="onRegionChange(accountSetting.regionId)"
          [multiple]="accountSetting && !accountSetting.accountSettingId" [(ngModel)]="accountSetting.regionId"
          name="continentId" [disabled]="(accountSetting && accountSetting.accountSettingId) || !itemModel.editPermission"
          [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let region of regionList" [value]="region.continentId">{{region.continentName}}
          </mat-option>
        </mat-select>
      </div>
      <div class="form-group">
        <label for="">Country</label>
        <mat-select [class.error]="submitted && !accountSetting.countryId" (selectionChange)="onCountryChange(accountSetting.countryId)" [(ngModel)]="accountSetting.countryId"
          [multiple]="accountSetting && !accountSetting.accountSettingId"
          [disabled]="(accountSetting && accountSetting.accountSettingId) ||  !itemModel.editPermission">
          <mat-option *ngFor="let country of countryList" [value]="country.countryId">{{country.countryName}}
          </mat-option>
        </mat-select>
      </div>
      <div class="form-group">
        <label for="country">Distributor</label>
        <mat-select [class.error]="submitted && !accountSetting.distributorId" [(ngModel)]="accountSetting.distributorId" [disabled]="accountSetting && accountSetting.accountSettingId"
          name="distributorId" [multiple]="accountSetting && !accountSetting.accountSettingId"
          [disabled]="(accountSetting && accountSetting.accountSettingId) || !itemModel.editPermission" [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let distributor of distributorList" [value]="distributor.distributorId">
            {{distributor.distributorName}}
          </mat-option>
        </mat-select>
      </div>
      <div class="form-group">
        <label for="">Account</label>
        <mat-select [class.error]="submitted && !accountSetting.itemAccountId"  [(ngModel)]="accountSetting.itemAccountId"
         [disabled]="(accountSetting && accountSetting.accountId) || !itemModel.editPermission">
          <mat-option *ngFor="let account of accountList" [value]="account.itemAccountId">{{account.accountName}}
          </mat-option>
        </mat-select>
      </div>
      <div class="form-group">
        <label for="item">Forecast Start Date</label>
        <mat-form-field [class.error]="submitted && !accountSetting.foreCastStartDate" floatLabel='never' >
          <input [max] = "accountSetting.foreCastEndDate" [disabled]="!itemModel.editPermission" [(ngModel)]="accountSetting.foreCastStartDate" (focus)="picker1.open()" and (click)="picker1.open()"
            matInput [matDatepicker]="picker1">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <button *ngIf="accountSetting && accountSetting.accountSettingId" class="btn btn-primary"
          (click)="updateRows(accountSetting.foreCastStartDate,'foreCastStartDate')">Update</button>
      </div>
      <div class="form-group">
        <label for="item">Forecast End Date</label>
        <mat-form-field [class.error]="submitted && !accountSetting.foreCastEndDate" floatLabel='never'>
          <input [min] = "accountSetting.foreCastStartDate" [disabled]="!itemModel.editPermission" [(ngModel)]="accountSetting.foreCastEndDate" (focus)="picker2.open()" and (click)="picker2.open()"
            matInput [matDatepicker]="picker2" [max]="endDate">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        <button *ngIf="accountSetting && accountSetting.accountSettingId" class="btn btn-primary"
          (click)="updateRows(accountSetting.foreCastEndDate,'foreCastEndDate')">Update</button>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="item">Target WOS</label>
        <input  [disabled]="!itemModel.editPermission"[class.error]="submitted && !accountSetting.targetWOS" [(ngModel)]="accountSetting.targetWOS" type="number">
        <button *ngIf="accountSetting && accountSetting.accountSettingId" class="btn btn-primary"
          (click)="updateRows(accountSetting.targetWOS,'targetWOS')">Update</button>
      </div>
      <div class="form-group">
        <label for="item">Lead Time (Days)</label>
        <input [disabled]="!itemModel.editPermission" [class.error]="submitted && !accountSetting.leadTimeA" [(ngModel)]="accountSetting.leadTimeA" type="number">
        <button *ngIf="accountSetting && accountSetting.accountSettingId" class="btn btn-primary"
          (click)="updateRows(accountSetting.leadTimeA,'leadTimeA')">Update</button>
      </div>
      <div class="form-group">
        <label for="">Order Frequency</label>
        <mat-select [class.error]="submitted && !accountSetting.orderFrequencyId" [(ngModel)]="accountSetting.orderFrequencyId" [disabled]="!itemModel.editPermission">
          <mat-option [value]="order.id" *ngFor="let order of orderFrequencyList">{{order.value}}</mat-option>
        </mat-select>
        <button *ngIf="accountSetting && accountSetting.accountSettingId" class="btn btn-primary"
          (click)="updateRows(accountSetting.orderFrequencyId,'orderFrequencyId')">Update</button>
      </div>
      <div class="form-group">
        <label for="item">Customer ID #</label>
        <input [class.error]="submitted && !accountSetting.customerId" [(ngModel)]="accountSetting.customerId" type="number" [disabled]="!itemModel.editPermission">
        <button *ngIf="accountSetting && accountSetting.accountSettingId" class="btn btn-primary"
          (click)="updateRows(accountSetting.customerId,'customerId')">Update</button>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="item">Casepack Quantity</label>
            <input [class.error]="submitted && !accountSetting.casepackQuant" [(ngModel)]="accountSetting.casepackQuant" type="text" [disabled]="!itemModel.editPermission">

          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Round to Casepacks?</label>
            <mat-select [class.error]="submitted && (!accountSetting.roundToCasepacks && !accountSetting.roundToCasepacks !== false)" [(ngModel)]="accountSetting.roundToCasepacks" [disabled]="!itemModel.editPermission">
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
            <button *ngIf="accountSetting && accountSetting.accountSettingId" class="btn btn-primary" [disabled]="!itemModel.editPermission"
              (click)="updateRows(accountSetting.roundToCasepacks,'roundToCasepacks')">Update</button>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="item">Pallet Quantity</label>
            <input [class.error]="submitted && !accountSetting.palletQuan" [(ngModel)]="accountSetting.palletQuan" type="text" [disabled]="!itemModel.editPermission">

          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Round to Pallets?</label>
            <mat-select [class.error]="submitted && (!accountSetting.roundToPallets && !accountSetting.roundToPallets !== false)" [(ngModel)]="accountSetting.roundToPallets" [disabled]="!itemModel.editPermission">
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
            <button *ngIf="accountSetting && accountSetting.accountSettingId" class="btn btn-primary"
              (click)="updateRows(accountSetting.roundToPallets,'roundToPallets')">Update</button>

          </div>
        </div>
      </div>




    </div>
    <div class="col-12 btn-wrapper">
      <button *ngIf="accountSetting && !accountSetting.accountSettingId" class="btn btn-primary" (click)="addAccountSetting()" [disabled]="!itemModel.editPermission">Add Account</button>
      <button *ngIf="accountSetting && accountSetting.accountSettingId" class="btn btn-primary"
        (click)="resetForm()">Reset</button>
    </div>
    <div class="search-wrapper col-12">
      <div class="form-group">
        <input (keyup)="onFilterTextBoxChanged($event)" type="text" placeholder="Search">
        <i class="icon-search1"></i>
      </div>
      <div class="icon-wrapper">
        <i class="icon-bin"  (click)="deleteSelectedNodes(deleteConfirmation)"></i>
        <i class="icon-docs"></i>
      </div>
    </div>
  </div>
</div>
<div class="items-wrapper-grid account-setting-grid">
  <ag-grid-angular class="ag-theme-material" [gridOptions]="gridOptions" [defaultColDef]="defaultColDef"
    [headerHeight]="headerHeight" [columnDefs]="columnDefs" [rowData]="accountSettingList"
    (gridReady)="onGridReady($event)" [modules]="modules" (selectionChanged)="onSelectionChanged($event)" #grid2>
  </ag-grid-angular>
</div>

<ng-template #deleteConfirmation>
  <div mat-dialog-content>
    <p> Are you sure you want to delete these accounts? </p>
  </div>
  <hr>
  <div class="item-wrapper">
    <div class="action-panel text-right btn-wrapper">
      <button mat-raised-button class="btn btn-secondary m-left" (click)="dialogRef.close()">Cancel</button>
      <button mat-raised-button class="btn btn-primary m-left" (click)="onSubmitDelete()"> Save
      </button>
    </div>
  </div>

</ng-template>
