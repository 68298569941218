import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { USER_ID } from 'src/common/keys';
import { NameRendererComponent } from 'src/common/name-renderer';
import { BusinessInformationService } from 'src/services/business-information.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-business-attributes',
  templateUrl: './business-attributes.component.html',
  styleUrls: ['./business-attributes.component.css']
})
export class BusinessAttributesComponent implements OnInit {
  public columnDefs = [
    {
      headerName: 'Title',
      field: 'title',
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openAddDialog.bind(this),
      },
      pinned: 'left',
      width: 250
    },
    {
      headerName: 'Type',
      field: 'type'
    },
    {
      headerName: '',
      field: 'delete',
      resizable: true,
      filter: false,
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveGoalDialog.bind(this),
      },
      width: 80
    }
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public attributeList = [];
  public answerTypeList = [];
  public modules = AllCommunityModules;
  public user: any;
  public businessInfoForm: FormGroup;
  public isEdit = false;
  @ViewChild('Adddialog') dialogAddTemplate: TemplateRef<any>;
  constructor(
    public router: Router,
    public businessInfoService: BusinessInformationService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    public storage: LocalstorageService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent,
        nameRenderer: NameRendererComponent,
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    this.initializeForm();
    this.getAttributeList();
  }
  public getAttributeList = () => {
    this.answerTypeList = [
      { answerTypeId: 1, name: 'Text' },
      { answerTypeId: 2, name: 'Single Select' },
      { answerTypeId: 3, name: 'Multi Select' },
      { answerTypeId: 4, name: 'View' }
    ];
    this.businessInfoService.getBusinessAttributeList({}).subscribe(res => {
      this.attributeList = res.map(row => ({
        ...row, type: this.answerTypeList.find(ans => ans.answerTypeId === row.answerTypeId).name
      }));
    });
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.title }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeItemMaster(row.rowData);
      }
    });
  }
  public removeItemMaster(selectedItem: any) {
    if (selectedItem) {
      const model = {
        ...selectedItem,
        active: false
      };
      this.spinner.show();
      this.businessInfoService.activateBusinessAttribute(model).subscribe(res => {
        if (res) {
          this.spinner.hide();
          this.toastr.success('Master Items', 'Item removed successfully');
          this.attributeList = this.attributeList.filter(row => row.bussinessAttributeId !== model.bussinessAttributeId);
          this.gridOptions.api.setRowData(this.attributeList);
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('Master Items', error.error.text);
      });
    }
  }
  public initializeForm = () => {
    this.businessInfoForm = this.formBuilder.group({
      title: [],
      bussinessAttributeId: [0],
      answerTypeId: [],
      code: [],
      createdById: [this.storage.get(USER_ID)],
      active: true
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  onCellClicked($event) {

    this.router.navigate(['addUser'], {
      queryParams: {
        id: btoa($event.data.userId)
      }
    });
  }
  public navigateToRoles = () => {
    this.router.navigate(['Roles']);
  }
  openAddDialog(data, templateRef?: TemplateRef<any>): void {
    if (data && data.rowData) {
      this.isEdit = true;
      Object.keys(this.businessInfoForm.controls).forEach(key => {
        this.businessInfoForm.controls[key].setValue(data.rowData[key]);
      });
    }
    this.dialogRef = this.dialog.open(this.dialogAddTemplate, { width: '500px', data: data || {} });
  }
  public onSubmit = () => {
    const model = this.businessInfoForm.value;
    this.spinner.show();
    if (this.isEdit) {
      this.businessInfoService.updateBusinessAttribute(model).subscribe(res => {
        const index = this.attributeList.findIndex(row => row.bussinessAttributeId === model.bussinessAttributeId);
        if (index > -1) {
          this.attributeList[index] = model;
        }
        this.attributeList = this.attributeList.map(row => ({
          ...row, type: this.answerTypeList.find(ans => ans.answerTypeId === row.answerTypeId).name
        }));
        this.gridOptions.api.setRowData(this.attributeList);
        this.dialogRef.close();
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
    } else {
      this.businessInfoService.addBusinessAttribute(model).subscribe(res => {
        model.bussinessAttributeId = res;
        this.attributeList.push(model);
        this.attributeList = this.attributeList.map(row => ({
          ...row, type: this.answerTypeList.find(ans => ans.answerTypeId === row.answerTypeId).name
        }));
        this.gridOptions.api.setRowData(this.attributeList);
        this.dialogRef.close();
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
    }
  }
}
