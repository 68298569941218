import { Component, OnInit, ViewEncapsulation, TemplateRef, ViewChild, ElementRef, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable, of, Subscription } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { NgxToasterService } from '../../../../../services/ngx-toaster.service';
import { LocalstorageService } from '../../../../../services/localstorage.service';
import { ConfigurationService } from '../../../../../services/configuration.service';

import { GraphMeasuresComponent } from '../../shared/graph-measures/graph-measures.component';

@Component({
  selector: 'app-inventory-planner-graph',
  templateUrl: './inventory-planner-graph.component.html',
  styleUrls: ['./inventory-planner-graph.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryPlannerGraphComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dropdownObjects;
  @Input() yearListGrid;
  width = '100%';
  height = '500';
  type = "msline";
  dataFormat = "json";
  dataSource;
  public submitted = false;
  public isEdit = false;
  public columnDefs = [];
  private gridApi: any;
  dropdownSettings = {};
  dropdownSettingsSingle = {};
  public tenureTypeList = [];
  public tenureTypeListTemp = [];
  public rowList = [];
  public goalTypeList = [];
  public gridOptions: any;
  public getRowHeight;
  public getRowStyle;
  public headerHeight;
  public modules = AllCommunityModules;
  public clientId = 1;
  public channel = null;
  public csvRecords: any;
  public customerSelectedList = [];
  public selectedItemIds = [];
  public timeframeSelectedList = [];
  public itemSeletedList = [];
  public timeframeList = [];
  public versionList = [];
  public monthList = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredCustomers: Observable<any[]>;
  filteredItems: Observable<any[]>;
  filteredTimeframe: Observable<any[]>;
  public seletedYear = null;
  public seletedVersion = null;
  public selectedMonth = null;
  public defaultRow = {};
  public selectAll = true;
  tempList = [];
  totalCalculatedList = [];
  chartDatasets = [];
  simulatedChartDatasets = [];
  barChartDatasets = [];
  simulatedBarChartDatasets = [];
  xAxis = [];
  yAxis = [];
  xAxisSimulated = [];
  view = [700, 700];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  legendTitle = 'Legend';
  legendPosition = 'right';
  showXAxisLabel = true;
  xAxisLabel = 'Sales QTY';
  showYAxisLabel = true;
  yAxisLabel = 'Time Period';
  showGridLines = true;
  innerPadding = '10%';
  animations: boolean = true;
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#44104f,#8d009d,#d81ad2,#ef0c5f,#fc6b6b,#feac47,#43e8a9,#1c9ead,#53b6f4,#2481d3,#44104f,#8d009d']
  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#7aa3e5']
  };

  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = '';
  tempColDefs = [];
  yearListTemp = [];
  yearListForFilter = [];
  graphList = [];
  @Input() leftGroupList = [];
  @Input() measureList = [];
  tempGroupList = [];
  labelList;
  channelSelectedList;
  graphChooserList = [];
  public sub: Subscription;
  savedMeasures: any[];
  profileMeasureList: any;
  selectedProfile: any;
  public loading = false;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    public dialogRef: MatDialogRef<any>,
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef) {
  }

  openDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(templateRef, { width: '500px', data: data || {} });
  }
  ngOnInit() {
    this.tempGroupList = this.leftGroupList ? JSON.parse(JSON.stringify(this.leftGroupList)) : [];
    this.configurationService.yearListUpdated$.subscribe(res => {
      if (res) {
        this.yearListGrid = [];
        this.yearListGrid = res;
        this.mapListsForMap();
        this.cdr.detectChanges();
      }
    })
  }
  ngOnChanges(simpleChange: SimpleChanges) {
    if (simpleChange && simpleChange.measureList && simpleChange.measureList.currentValue) {
      this.measureList = simpleChange.measureList.currentValue
      this.savedMeasures = this.measureList;
      this.graphChooserList = this.measureList.map((el, index) => ({ ...el, checked: el.isGraphDefault ? true : false }))
    }
    this.mapListsForMap();
  }
  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
  checkIfInList = (key: string) => {
    return this.measureList.find(el => el.code === key && el.checked);
  }
  public mapListsForMap = () => {
    this.graphList = [];
    const chartList = [];
    this.loading = true;
    this.graphChooserList.forEach(row => {
      if (row.checked) {
        let obj = {};
        obj[row.code] = [];
        chartList.push(obj);
      }
    });
    const list = this.yearListGrid;
    if (list.length > 0) {
      list.forEach(week => {
        let object = {
          ...week.measures,
          title: week.title
        }
        this.graphList.push(object);
        chartList.forEach((el) => {
          let obj = {};
          let value = object[Object.keys(el)[0]];
          value = value ? value.toString() : '';
          value = value.replaceAll(',', '');
          obj[week.title] = value;
          el[Object.keys(el)[0]].push(obj);
        })
      });
      this.applyGraph(chartList);
    }
    this.loading = false;
  }
  getSeries = (object) => {
    const objList = [];
    Object.keys(object).forEach(key => {
      if (key !== 'secondValue' && key !== 'firstValue' && key !== 'columnValue') {
        const newObject = {
          name: key,
          value: Number(object[key])
        }
        objList.push(newObject)
      }
    });
    return objList;
  }
  applyGraph(totalCalculatedList) {
    this.dataSource = null;
    const data = {
      chart: {
        caption: "",
        yaxisname: "",
        subcaption: "",
        showhovereffect: "1",
        lineThickness: "4",
        "width": "100%",
        "height": "150px",
        numbersuffix: "",
        paletteColors: '#44104f,#8d009d,#d81ad2,#ef0c5f,#fc6b6b,#feac47,#43e8a9,#1c9ead,#53b6f4,#2481d3,#44104f,#8d009d',
        drawcrossline: "1",
        plottooltext: "$seriesName <b>$dataValue</b> ",
        theme: "fusion"
      },
      categories: [
        {
          category: this.getLabels()
        }
      ],
      dataset: totalCalculatedList.map(item => (
        { seriesname: this.graphChooserList.find(el => el.code === Object.keys(item)[0]).measureName,
           data: item[Object.keys(item)[0]].map(row => ({ value: row[Object.keys(row)[0]] ? +row[Object.keys(row)[0]] : 0 })) }))
    };
    this.dataSource = data as any;
    this.cdr.detectChanges();
  }
  openMesuresDialog() {
    this.profileMeasureList = JSON.parse(JSON.stringify(this.savedMeasures.map(row => ({ ...row, checked: false }))))
    this.dialog.open(GraphMeasuresComponent, {
      width: '1200px',
      panelClass: 'fb-dialog-wrapper',

      data: { measureList: this.profileMeasureList },
    }).afterClosed().subscribe(command => {
      if (command) {
        if (command.profileApplied) {
          this.selectedProfile = command
          let selectedMeasures = this.selectedProfile.measureList.filter(x => x.checked)
          if (selectedMeasures.length)
          this.graphChooserList = selectedMeasures;
          else
          this.graphChooserList = selectedMeasures;
          this.mapListsForMap();
          this.spinner.hide();
        }
      }
    });
  }
  public getLabels = () => {
    // const el = totalCalculatedList[0];
    const list = [];
    const dateList = this.graphList.map(row => ({ label: row.title }));
    return dateList;
  }
}
