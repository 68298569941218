import * as _ from 'lodash';

export const getFilterModel = (gridFilters) => {
  const filterFields = _.keys(gridFilters);
  const filterModel: any = [];
  filterFields.forEach(field => {
    filterModel.push({
      field: field,
      filterType: gridFilters[field].filterType,
      type: gridFilters[field].type || gridFilters[field].filterType,
      filter: gridFilters[field].filter,
      filterValues: gridFilters[field].values,
      ...gridFilters[field].dateFrom && { dateFrom: gridFilters[field].dateFrom }
    });
  });
  return filterModel;
}

export const getSortModel = (gridSorting) => {
  const sortModel: any = [];
  gridSorting.forEach(field => {
    sortModel.push({
      field: field.colId,
      order: field.sort
    });
  });
  return sortModel;
}


export const numberValueFormatter2Decimal = (params) => {
  if (params.value) {
    return Number(Number(params.value).toFixed(2));
  } else {
    return '';
  }
}


export const tooltipcellRenderer = (params) => {
  return params.value ? `<span title="${params.value}">${params.value}</span>` : '';
}

export const currencyRenderer = (params) => {
  return `${params.value ? '$'+Number(params.value).toFixed(2) : ''}`;
}
