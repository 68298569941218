<div class="insight-detail-wrapper">
  <div class="insight-detail-title">
    <p>{{data?.insights}}</p>
    <h5>Value: <span>{{data?.value | currency:'USD'}}</span></h5>
  </div>
  <div class="row no-gutters insight-details-item-wrapper">
    <div class="col-md-6">
      <div class="row no-gutters">
        <div class="col-md-6 inner-div-left">
          <img [src]="data.documentPath" alt="1" class="img-fluid">
        </div>
        <div class="col-md-6 inner-div">
          <div class="insight-detail-items">
            <p>Retailer</p>
            <h3>{{data?.retailerName}}</h3>
          </div>
          <div class="insight-detail-items">
            <p>Country</p>
            <h3>{{data?.countryName}}</h3>
          </div>
          <div class="insight-detail-items">
            <p>Product</p>
            <h3>{{data?.productName}}</h3>
          </div>
        </div>
        <div class="col-md-12">
          <div class="insight-detail-items full">
            <p>Action</p>
            <h3>{{data?.suggestedAction}}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 inner-div">
      <div class="insight-detail-items">
        <p>Timeframe</p>
        <h3>{{data?.dateGenerated | date:'MM/dd/yyyy'}} - {{data?.expiryDate | date:'MM/dd/yyyy'}}
          {{data.isExpired ? '':  getText()}}</h3>
      </div>
      <div class="insight-detail-table">
        <div class="insight-detail-table-head">
          <h6>Name</h6>
          <h6>Value</h6>
        </div>
        <div class="insight-detail-table-content">
          <div *ngFor="let metric of data?.metricApplicationInsightsList" class="table-row">
            <h6>{{metric.metricApplicationDescription}}</h6>
            <h6>{{metric.value | number:'1.2-2'}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="data.graphPath" class="insight-graph-wrapper">
    <div class="insight-detail-title">
    </div>
    <div class="row">
      <div class="col-12">
        <img [src]="data.graphPath" alt="insight-graph" class="img-fluid">
      </div>
    </div>
  </div>
</div>
