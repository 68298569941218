<div class="container-fluid">
  <div class="wrapper col-md-12">
    <mat-card-header>
      <mat-card-title>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="titleForm" (ngSubmit)="onSubmit()" class="form-fields">
        <div class="row">
          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput formControlName="decayCurveName" placeholder="Decay Curve Name">
            </mat-form-field>
          </div>
          <div class="col-md-3 form-group">
            <mat-form-field class="example-full-width">
              <input (focus)="picker1.open()" and (click)="picker1.open()" formControlName="startDate" matInput
                [max]="titleForm.controls.endDate.value" [matDatepicker]="picker1" placeholder="Select Start Date">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
              <mat-error *ngIf="submitted && titleForm.controls['startDate'].hasError('required')">
                *Required Field
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3 form-group">
            <mat-form-field class="example-full-width">
              <input (focus)="picker.open()" and (click)="picker.open()" formControlName="endDate" matInput
                [min]="titleForm.controls.startDate.value" [matDatepicker]="picker" placeholder="Select End Date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="submitted && titleForm.controls['endDate'].hasError('required')">
                *Required Field
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3 form-group">
            <mat-form-field>
              <mat-select placeholder="Select Class" formControlName="class">
                <mat-option *ngFor="let res of classList; let i = index" [value]="res">
                  {{res}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Class" formControlName="subClass">
                  <mat-option *ngFor="let res of subClassList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Customer" formControlName="customer" multiple>
                  <mat-option *ngFor="let res of customerList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Curve Type" formControlName="curveType">
                  <mat-option *ngFor="let res of curveTypeList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="totalUnits" placeholder="Total Units">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <label>Active</label>
              <mat-slide-toggle formControlName="active">Active
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <div class="action-btns text-right">
        <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit()">Save</button>
      </div>
    </mat-card-actions>
  </div>
</div>
