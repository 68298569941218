import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AllModules, ColumnApi, GridApi, GridOptions } from '@ag-grid-enterprise/all-modules';
import { getSeasonalityWeeksColDefs } from '../ag-grid/grid-options';
import { SeasonalityService } from 'src/services/seasonality-services/seasonality.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import { ClientPreferenceService } from 'src/services/client-services/client-preference.service';
import { SharedDataService } from 'src/services/shared-data.service';
import { getNumericCellEditor } from 'src/common/ag-numeric-editor';
import { ForecastManagerService } from 'src/services/forecast-services/forecast-manager.service';

@Component({
  selector: 'app-edit-seasonality',
  templateUrl: './edit-seasonality.component.html',
  styleUrls: ['./edit-seasonality.component.css']
})
export class EditSeasonalityComponent implements OnInit {
  @Input() data?: any;
  endDateMin: Date;
  endDateMax: Date;
  startDateMax: Date;
  seasonalityEditForm = new FormGroup({
    active: new FormControl(true, Validators.required),
    seasonalityId: new FormControl('', Validators.required),
    seasonalityName: new FormControl('', Validators.required),
    seasonalityType: new FormControl('static'),
    startWeekDate: new FormControl(''),
    endWeekDate: new FormControl(''),
    rollingWeek: new FormControl(''),
    seasonalityLevelName: new FormControl('', Validators.required),
    retailer: new FormControl('', Validators.required),
    brands: new FormControl([]),
    productGroups: new FormControl([]),
    skuDescriptions: new FormControl([]),
    sku: new FormControl([]),
  });
  isFormValueUpdated: boolean = false;
  isApplyBtnClicked:boolean= false
  components = {
    numericCellEditor: getNumericCellEditor()
  }

  SKIP_KEYS = ['SeasonalityId'];
  dataSource: any = {
    chart: {
      caption: "Seasonality Graph",
      theme: "fusion",
      labelDisplay: "rotate",
      slantLabel: "1",
      maxLabelHeight: "100"
    },
    categories: [
      {
        category: []
      }
    ],
    dataset: [
      {
        seriesname: "Original",
        color: "#ff0000",
        data: []
      },
      {
        seriesname: "Adjusted",
        color: "#0000ff",
        data: []
      }
    ]
  };
  chartConfig: Object = {
    type: 'msline',
    dataFormat: 'json',
  };
  loadChartData: boolean = false;
  isGridLoaded: boolean = false;

  weekList: any = [];

  public modules = AllModules;
  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  public defaultColDef = {
    filter: false,
    sortable: false,
    minWidth: 50,
    resizable: true,
    suppressMenu: true
  };
  public columnDefs = getSeasonalityWeeksColDefs();
  gridData: any = [];
  gridUpdatedCells: any = [];
  isGridDataUpdated: boolean = false;

  seasonalityData: any;
  numberOfWeeksDifference = 0;
  isValidWeeks: boolean = true;

  brandList: any = [];
  productGroupList: any = [];
  skuDescriptionList: any = [];
  skuList: any = [];
  seasonalityLevelCode: any;

  clientWeekStartDay: number = 0;

  constructor(
    public seasonalityService: SeasonalityService,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public clientPreferenceService: ClientPreferenceService,
    public _dataService: SharedDataService,
    public forecastManagerService: ForecastManagerService,
  ) { }

  get seasonalityType() {
    return this.seasonalityEditForm.get('seasonalityType');
  }

  get checkWeekDiff() {
    if (this.seasonalityEditForm.get('startWeekDate').value
      && this.seasonalityEditForm.get('endWeekDate').value) return true;
    else return false;
  }

  get getWeeksDiff() {
    return Math.floor(this.getDaysDiff / 7);
  }

  get getDaysDiff() {
    const date1 = new Date(this.seasonalityEditForm.get('startWeekDate').value) as any;
    const date2 = new Date(this.seasonalityEditForm.get('endWeekDate').value) as any;
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 8;
    return diffDays;
  }

  ngOnInit() {
    this.getLookupData();
    this.initEditMode();
    this.loadClientPreference();
  }

  getLookupData() {
    this.getLatestCommitedWeek();
  }

  getLatestCommitedWeek() {
    this.forecastManagerService.GetLatestCommitedWeek().subscribe((response: any) => {
      this.startDateMax = response.committedDate;
    });
  }

  loadClientPreference() {
    this.clientWeekStartDay = this.clientPreferenceService.getClientWeekStartDay();
  }

  initEditMode() {
    this.data = JSON.parse(this.data ? this.data : {});
    this.getSeasonalityById(this.data.seasonalityData.seasonalityId);
  }

  getSeasonalityById(seasonalityId) {
    this.seasonalityService.GetSeasonalityById(seasonalityId).subscribe(seasonalityData => {
      this.seasonalityData = seasonalityData;
      this.loadSeasonalityFormValues();
    });
  }

  loadSeasonalityFormValues() {
    const formData = Object.assign({}, this.seasonalityData);
    this.seasonalityLevelCode = this.seasonalityData.seasonalityLevelCode;
    this.seasonalityEditForm.patchValue(formData);
    const retailer = _.get(this.seasonalityData, 'seasonalityHierarchyLevels.0.retailer', '');
    this.brandList = _.compact(_.map(_.get(this.seasonalityData, 'seasonalityHierarchyLevels', []), a => a.brand));
    this.productGroupList = _.compact(_.map(_.get(this.seasonalityData, 'seasonalityHierarchyLevels', []), a => a.productGroup));
    this.skuDescriptionList = _.compact(_.map(_.get(this.seasonalityData, 'seasonalityHierarchyLevels', []), a => a.productDescription));
    this.skuList = _.compact(_.map(_.get(this.seasonalityData, 'seasonalityHierarchyLevels', []), a => a.sku));
    this.seasonalityEditForm.get('retailer').setValue(retailer);
    this.seasonalityEditForm.get('brands').setValue(this.brandList);
    this.seasonalityEditForm.get('productGroups').setValue(this.productGroupList);
    this.seasonalityEditForm.get('skuDescriptions').setValue(this.skuDescriptionList);
    this.seasonalityEditForm.get('sku').setValue(this.skuList);
    this.seasonalityEditForm.get('seasonalityLevelName').setValue(this.whiteLabelSeasonalityLevel());
    this.startDateChange({});
    this.applyCustomValidators();
    this.getSeasonalityGridData();
    this.listenFormValueChanges();
  }

  listenFormValueChanges() {
    const initialValue = this.seasonalityEditForm.value;
    this.seasonalityEditForm.valueChanges.subscribe(form => {
      this.isFormValueUpdated = Object.keys(initialValue).some(key => form[key] != initialValue[key]);
      if(!this.isFormValueUpdated)
      this.isApplyBtnClicked=this.isFormValueUpdated;
    });
  }

  whiteLabelSeasonalityLevel() {
    let whiteLabelSeasonalityLevel = '';
    if (this.seasonalityLevelCode === 'AC')
      whiteLabelSeasonalityLevel = this._dataService.getDataById(1);
    else if (this.seasonalityLevelCode === 'B')
      whiteLabelSeasonalityLevel = this._dataService.getDataById(2);
    else if (this.seasonalityLevelCode === 'PG')
      whiteLabelSeasonalityLevel = this._dataService.getDataById(3);
    else if (this.seasonalityLevelCode === 'BAC')
      whiteLabelSeasonalityLevel = this._dataService.getDataById(2) + ' & ' + this._dataService.getDataById(1);
    else if (this.seasonalityLevelCode === 'PGAC')
      whiteLabelSeasonalityLevel = this._dataService.getDataById(3) + ' & ' + this._dataService.getDataById(1);
    else if (this.seasonalityLevelCode === 'ACSK')
      whiteLabelSeasonalityLevel = this._dataService.getDataById(1) + ' & ' + this._dataService.getDataById(5);
    else if (this.seasonalityLevelCode === 'SK')
      whiteLabelSeasonalityLevel = this._dataService.getDataById(5);
    else if (this.seasonalityLevelCode === 'ACSKU')
      whiteLabelSeasonalityLevel = this._dataService.getDataById(1) + ' & ' + this._dataService.getDataById(4);

    return whiteLabelSeasonalityLevel;
  }

  applyCustomValidators() {
    const seasonalityType = this.seasonalityType.value;
    if (seasonalityType === 'rolling') {
      this.seasonalityEditForm.controls['rollingWeek'].setValidators([Validators.required, Validators.min(52), Validators.max(156)]);
      this.seasonalityEditForm.controls['startWeekDate'].setValidators([]);
      this.seasonalityEditForm.controls['endWeekDate'].setValidators([]);
    } else {
      this.seasonalityEditForm.controls['startWeekDate'].setValidators([Validators.required]);
      this.seasonalityEditForm.controls['endWeekDate'].setValidators([Validators.required]);
      this.seasonalityEditForm.controls['rollingWeek'].setValidators([]);
    }
    this.seasonalityEditForm.get('rollingWeek').updateValueAndValidity();
    this.seasonalityEditForm.get('startWeekDate').updateValueAndValidity();
    this.seasonalityEditForm.get('endWeekDate').updateValueAndValidity();
  }


  validateSeasonalityLevelControls(control) {
    switch (control) {
      case 'brand': {
        if (this.seasonalityLevelCode === 'B' || this.seasonalityLevelCode === 'BAC') {
          return true;
        }
        break;
      }
      case 'productGroup': {
        if (this.seasonalityLevelCode === 'PG' || this.seasonalityLevelCode === 'PGAC') {
          return true;
        }
        break;
      }
      case 'skuDescription': {
        if (this.seasonalityLevelCode === 'SK') {
          return true;
        }
        break;
      }
      case 'sku': {
        if (this.seasonalityLevelCode === 'ACSKU') {
          return true;
        }
        break;
      }

      default: {
        return false;
      }
    }
  }

  weekDayFilter = (date) => {
    return date.day() === this.clientWeekStartDay;
  }

  startDateChange(event) {
    this.calculateWeeks();
    this.setEndDateMinMax();
  }

  setEndDateMinMax() {
    const startWeekDate = this.seasonalityEditForm.get('startWeekDate').value;
    if (startWeekDate) {
      this.endDateMax = this.subtractWeeks(51, new Date(startWeekDate));
      this.endDateMin = this.subtractWeeks(155, new Date(startWeekDate));
    }
  }

  calculateWeeks() {
    const startWeekDate = this.seasonalityEditForm.get('startWeekDate').value;
    const endWeekDate = this.seasonalityEditForm.get('endWeekDate').value;
    if (startWeekDate && endWeekDate) {
      this.numberOfWeeksDifference = this.differenceInWeeks(new Date(startWeekDate), new Date(endWeekDate));
      this.isValidWeeks = (this.numberOfWeeksDifference < 52 || this.numberOfWeeksDifference > 156) ? false : true;
    }
  }

  differenceInWeeks(startWeekDate: Date, endWeekDate: Date) {
    let diff = (startWeekDate.getTime() - endWeekDate.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7);
    return Math.round(diff) + 1;
  }

  subtractWeeks(numOfWeeks, date: Date) {
    const dateCopy = new Date(date.getTime());
    dateCopy.setDate(dateCopy.getDate() - numOfWeeks * 7);
    return dateCopy;
  }

  endDateChange(event) {
    this.calculateWeeks();
  }

  updateSeasonality() {
    if (this.seasonalityEditForm.valid && this.isValidWeeks) {
      const body = this.seasonalityEditForm.value;
      body.rollingWeek = body.seasonalityType === 'rolling' ? body.rollingWeek : null;
      body.startWeekDate = body.seasonalityType === 'static' ? body.startWeekDate : null;
      body.endWeekDate = body.seasonalityType === 'static' ? body.endWeekDate : null;
      this.spinner.show();
      this.seasonalityService.UpdateSeaonality(body).subscribe(res => {
        this.spinner.hide();
        const code = _.get(res, '0.messageCode', 400);
        const message = _.get(res, '0.messageText', 'Failed to create seasonality.');
        if (code === 200) {
          this.isFormValueUpdated=false;
          this.isApplyBtnClicked=false;
          this.toastr.success('Success', `${message}`);
        } else {
          this.toastr.error('Error', `${message}`);
        }
      }, err => {
        this.spinner.hide();
        this.toastr.error('Error', 'Seasonality failed to updated.');
      });
    } else {
      this.validateAllFormFields(this.seasonalityEditForm);
      this.toastr.error('Error', 'Please fill the form correctly.');
    }
  }



  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  applyDataChanges() {
    if (this.seasonalityEditForm.valid && this.isValidWeeks) {
      this.getSeasonalityGridData();
      this.isApplyBtnClicked=true;
    } else {
      this.toastr.error('Error', 'Please fill the form correctly.');
    }
  }

  getSeasonalityGridData() {
    const seasonalityType = this.seasonalityType.value;
    const payload = {
      seasonalityId: this.seasonalityEditForm.get('seasonalityId').value,
      startWeekDate: seasonalityType === 'static' ? this.seasonalityEditForm.get('startWeekDate').value : null,
      endWeekDate: seasonalityType === 'static' ? this.seasonalityEditForm.get('endWeekDate').value : null,
      rollingWeek: seasonalityType === 'rolling' ? this.seasonalityEditForm.get('rollingWeek').value : null,
    };
    this.spinner.show();
    this.seasonalityService.GetSeasonalityGridData(payload).subscribe((gridData: any) => {
      this.weekList = this.creatWeekList(gridData);
      this.createSeasonalityWeekColDef(this.weekList);
      this.createGridData(gridData);
      this.spinner.hide();
    });
  }

  creatWeekList(data) {
    const weekList: any = [];
    const weekMeta = _.keys(_.omit(_.get(data, '0', {}), this.SKIP_KEYS));;
    weekMeta.forEach(week => {
      /*
        0: Year
        1: WeekNumber
        2: WeekStartDate
      */
      const splitWeek = week.split('#');
      weekList.push({
        field: week,
        weekNumber: Number(splitWeek[1]),
        weekStartDate: splitWeek[2]
      });
    });
    return _.orderBy(weekList, ['weekNumber'], ['asc']);
  }

  createSeasonalityWeekColDef(weekList) {
    this.columnDefs = getSeasonalityWeeksColDefs(weekList);
    if (this.gridApi) {
      this.gridApi.setColumnDefs([]);
      setTimeout(() => {
        this.gridApi.setColumnDefs(this.columnDefs);
        this.gridApi.refreshHeader();
      }, 100);
    }
  }

  createGridData(data) {
    const gridData: any = [];
    data.forEach(seasonality => {
      const originalSeasonality: any = {};
      const adjustedSeasonality: any = {};
      this.weekList.forEach(week => {
        /*
          0: original value
          1: adjusted value
        */
        originalSeasonality.values = 'Seasonality';
        adjustedSeasonality.values = 'Adjusted';
        const splitedValue = (seasonality[week.field] ? seasonality[week.field] : '').split('_');
        originalSeasonality[week.field] = splitedValue[0];
        adjustedSeasonality[week.field] = splitedValue[1];
      });

      gridData.push(originalSeasonality);
      gridData.push(adjustedSeasonality);
    });
    this.gridData = gridData;
    this.gridApi && this.gridApi.setRowData(this.gridData);
    this.isGridLoaded = true;
    this.createChartData();
  }

  createChartData() {
    const weekList = this.weekList.map(week => {
      return { label: `${moment(week.weekStartDate).format('MM/DD/YYYY')}-W${week.weekNumber.toString().slice(-2)}` };
    });

    const orgChartData = {
      seriesname: 'Original',
      data: []
    };

    const adjChartData = {
      seriesname: 'Adjusted',
      data: []
    };

    for (let i = 0; i < this.gridData.length; i++) {
      this.weekList.forEach(week => {
        /*
          0: original value
          1: adjusted value
        */
        // const splitedValue = (this.gridData[i][week.field] ? seasonality[week.field] : '').split('_');
        if (i === 0) {
          orgChartData.data.push({ value: this.gridData[i][week.field] });
        } else {
          adjChartData.data.push({ value: this.gridData[i][week.field] });
        }
      });
    }
    this.dataSource.categories[0].category = weekList;
    this.dataSource.dataset = [orgChartData, adjChartData];
    this.loadChartData = true;
  }

  updateChartData() {
    this.createChartData();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
  }

  onExportGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: this.data.seasonalityData?.seasonalityName,
      colDefs: this.columnDefs,
      allColumns: true
    });
    this.gridApi.exportDataAsCsv(csvExportParams);
  }

  gridDataUpdated(params) {
    if ((params.newValue || params.oldValue) && (params.newValue != params.oldValue)) {
      params.newValue = Number(params.newValue).toString();
      const seasonalityId = this.seasonalityEditForm.get('seasonalityId').value;
      const column = params.column.colDef.field;
      const weekMetadat = column.split('#');
      weekMetadat[1] = Number(weekMetadat[1]);
      /*
        0: Report Year
        1: Week Number
        2: Week Start Date
      */
      params.column.colDef.cellStyle = {
        'background-color': 'yellow'
      };

      params.api.refreshCells({
        columns: [params.column.getId()],
        rowNodes: [params.node],
        force: true // without this line, the cell style is not refreshed at the first time
      });

      this.isGridDataUpdated = true;
      const prvUpdatedRowStateIndex = _.findIndex(this.gridUpdatedCells,
        a => a.weekNumber === weekMetadat[1]
      );
      if (prvUpdatedRowStateIndex < 0) {
        this.gridUpdatedCells.push({
          weekNumber: weekMetadat[1],
          seasonalityId: seasonalityId,
          adjustedValue: params.newValue
        });
      } else {
        this.gridUpdatedCells[prvUpdatedRowStateIndex] = {
          weekNumber: weekMetadat[1],
          seasonalityId: seasonalityId,
          adjustedValue: params.newValue
        };
      }
    }
  }

  saveUpdatedGridData() {
    this.spinner.show();
    this.seasonalityService.AdjustSeasonalityIndexValue({ seasonalityAdjustedValues: this.gridUpdatedCells }).subscribe(res => {
      this.gridApi.setColumnDefs(this.columnDefs);
      this.gridUpdatedCells = [];
      this.isGridDataUpdated = false;
      this.gridApi.redrawRows();
      this.spinner.hide();
      this.toastr.success('Success', `Weeks data updated successfully.`);
    }, err => {
      this.toastr.error('Error', `Failed to update weeks data.`);
      this.spinner.hide();
    });
  }

}
