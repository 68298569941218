import { Component, OnInit, OnDestroy } from '@angular/core';
import { AllModules, GridApi, ColumnApi, Grid, ColumnState } from '@ag-grid-enterprise/all-modules';
import * as _ from 'lodash';
import {
  paginationPageSize,
  cacheBlockSize,
  rowSelection,
  promotionGridColDefs,
  approvalForecastExportGridColDefs,
  defaultColDef
} from '../../ag-grid/gridOptions';
import { ItemPromotionDetailComponent } from '../item-promotion-detail/item-promotion-detail.component';
import { Subscription } from 'rxjs';
import { PromotionItemEditRenderer } from '../../ag-grid/item-edit-renderer.component';
import { gridDataExportParams, overlayLoadingTemplate } from '../../../util/util';
import { INVALID_TEAM, NEW_ITEM_PROMOTION_LIST, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { ItemManagerService, ItemPromotionService } from 'src/services/Item-Management-Services';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';

@Component({
  selector: 'app-item-promotion-list',
  templateUrl: './item-promotion-list.component.html',
  styleUrls: ['./item-promotion-list.component.css']
})
export class ItemPromotionListComponent implements OnInit, OnDestroy {

  INVALID_TEAM = INVALID_TEAM;

  // Filter Variables
  releasePromotionName: string = '';
  fiscalYears: any = [];
  planningMonths: any = [];
  actionIds: string = '';
  accountIds: any = '';

  isExportingTemplate: boolean = false;
  gridPanelOpenState: boolean = true;

  // Grid Variables
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  rowSelection = rowSelection;
  public gridColumnDefs = promotionGridColDefs;
  public defaultColDef = defaultColDef;
  public modules = AllModules;
  private gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: any;
  public gridColumnState: ColumnState[] = [];
  gridData: any[] = [];
  frameworkComponents = {
    promotionItemEditRenderer: PromotionItemEditRenderer
  }

  forecastGridData: any = [];
  forecastGridColDef: any = [];
  forecastGridApi: GridApi;

  public editSubscription: Subscription;
  public accountSubscription: Subscription;
  public actionSubscription: Subscription;
  public fiscalYearSubscription: Subscription;
  public planningMonthSubscription: Subscription;
  accountList: any = [];
  actionsList: any = [];
  planningMonthList: any = [];
  fiscalYearList: any = [];

  userTeam: any = '';
  isRetailerTeam: boolean = false;
  disabledAccountSelection: boolean = false;

  screenPreference: any;
  screenFilters: any;

  overlayLoadingTemplate = overlayLoadingTemplate;

  constructor(
    public dialog: MatDialog,
    public configurationService: ConfigurationService,
    public itemPromotionService: ItemPromotionService,
    private itemManagerService: ItemManagerService,
    public toastr: NgxToasterService,
    private usersService: UsersService,
    private userPreferenceService: UserPreferenceService) { }

  ngOnInit() {
    this.getUserSecreenPrefereence();
    this.getFilterContorlsData();
    this.setRoleBaseAccess();
    this.initiateValues();
    this.editSubscription = this.itemPromotionService.getPromotionEditSubject().subscribe(selectedRow => {
      if (selectedRow) {
        this.navigateToPromotionDetail({ isEdit: true, formData: selectedRow });
      }
    });
    this.updatePromotionList();
  }

  getUserSecreenPrefereence() {
    this.screenPreference = this.userPreferenceService.getPreferenceByKeys(NEW_ITEM_PROMOTION_LIST, NEW_ITEM_PROMOTION_LIST);
    this.gridColumnState = this.screenPreference.columnState ? JSON.parse(this.screenPreference.columnState) : [];
    this.screenFilters = this.screenPreference.screenFilters ? JSON.parse(this.screenPreference.screenFilters) : null;
    if (this.screenFilters) {
      this.releasePromotionName = this.screenFilters.PromotionItemName;
      this.fiscalYears = this.screenFilters.FiscalYears;
      this.planningMonths = this.screenFilters.PlanningMonths;
      this.actionIds = this.screenFilters.ActionIds;
      this.accountIds = this.screenFilters.AccountIds;
    }
  }

  initiateValues() {
    this.accountSubscription = this.itemManagerService.getAccountListSubject().subscribe((accountList: any) => {
      if (accountList && accountList.length) {
        this.accountList = accountList;
      }
    });
    this.actionSubscription = this.itemManagerService.getActionsListSubject().subscribe((actionsList: any) => {
      if (actionsList && actionsList.length) {
        this.actionsList = this.itemManagerService.FilterActionByScreen(actionsList, 'Filter');
      }
    });
    this.fiscalYearSubscription = this.itemManagerService.getFiscalYearListSubject().subscribe((fiscalYearList: any) => {
      if (fiscalYearList && fiscalYearList.length) {
        this.fiscalYearList = fiscalYearList;
        this.setDefaulYear();
      }
    });
    this.planningMonthSubscription = this.itemManagerService.getPlanningMonthListSubject().subscribe((planningMonthList: any) => {
      if (planningMonthList && planningMonthList.length) {
        this.planningMonthList = planningMonthList;
      }
    });
  }

  updatePromotionList() {
    this.gridApi && this.gridApi.showLoadingOverlay();
    this.itemPromotionService.getPromotionUpsertSubject().subscribe(promotionItem => {
      if (promotionItem) {
        const index = _.findIndex(this.gridData, a => a.promotionItemId === promotionItem.promotionItemId);
        if (index < 0) {
          this.gridData.push(promotionItem);
        } else {
          this.gridData[index] = {
            ...this.gridData[index],
            ...promotionItem
          };
        }
        this.gridApi.setRowData(this.gridData);
      }
      this.gridApi && this.gridApi.hideOverlay();
    }, err => {
      this.gridApi && this.gridApi.hideOverlay();
    });
  }

  setRoleBaseAccess() {
    this.userTeam = this.usersService.checkLoginUserTeam();
    switch (this.userTeam) {
      case VENDOR_TEAM_CODE: {
        this.accountIds = [this.usersService.getTeamRoleAccount()];
        this.disabledAccountSelection = true;
        this.isRetailerTeam = false;
        break;
      }
      case RETAILER_TEAM_CODE: {
        this.isRetailerTeam = true;
        break;
      }
      case INVALID_TEAM: {
        console.log('Valid Team Not assigned');
        break;
      }
    }
  }

  getFilterContorlsData() {
    this.itemManagerService.GetAllAccounts().toPromise();
    this.itemManagerService.GetActionsList().toPromise();
    this.itemManagerService.GetFiscalYearList().toPromise();
    this.itemManagerService.GetPlanningMonthList().toPromise();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.gridApi && this.gridApi.showLoadingOverlay();
    this.autoSizeAll();
    this.applyColumnState();
  }

  applyColumnState() {
    if (this.colApi && this.gridColumnState && this.gridColumnState.length) {
      setTimeout(() => {
        this.colApi.applyColumnState({ state: this.gridColumnState });
      }, 100);
    }
  }

  onGridStateChange(e) {
    this.gridColumnState = this.colApi.getColumnState();
    this.screenPreference.columnState = this.gridColumnState;
    this.updateScreenPreference();
  }

  updateScreenPreference() {
    this.userPreferenceService.upsertColumnStatePreference(this.screenPreference).subscribe(res => {
      console.log(res);
    });
  }

  updateScreenFilterPreference() {
    this.userPreferenceService.upsertFilterPreference(this.screenPreference).subscribe(res => {
      console.log(res);
    });
  }

  public getItemPromotionList = (offSet = 0, pageSize = 100) => {
    this.gridApi && this.gridApi.showLoadingOverlay();
    this.itemPromotionService.GetAllPromotionItems(offSet, pageSize).subscribe((res: any) => {
      this.gridData = res;
      this.gridApi && this.gridApi.hideOverlay();
    },
      err => {
        this.gridApi && this.gridApi.hideOverlay();
      });
  }

  autoSizeAll() {
    this.gridApi.sizeColumnsToFit();
  }

  openColumnDialog(event) {
    event.stopPropagation();
    this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        columnOrder: ['promotionItemName', 'accountName', 'startDate', 'releaseDate', 'endDate']
      },
      width: '700px'
    });
  }

  onExportGridData(event) {
    event.stopPropagation();
    const csvExportParams = gridDataExportParams({
      fileName: 'NewPromotionsList',
      colDefs: this.gridColumnDefs,
      allColumns: true
    });
    this.gridApi.exportDataAsCsv(csvExportParams);
  }

  openAddPromotionView(event) {
    event.stopPropagation();
    this.navigateToPromotionDetail();
  }

  handleExport(event) {
    event.stopPropagation();
  }

  navigateToPromotionDetail(data = { isEdit: false, formData: {} }) {
    const promotionDetailComponent = {
      menuId: '399',
      name: 'New Item Promotion Detail',
      selector: 'app-item-promotion-detail',
      displayName: 'New Item Promotions',
      module: 'ItemManagerModule',
      data: data,
      component: ItemPromotionDetailComponent
    }
    this.configurationService.menuRendererSubject.next(promotionDetailComponent);
  }

  setDefaulYear() {
    const defaultYear = _.find(this.fiscalYearList, a => a.defaultYear);
    if ((defaultYear && this.fiscalYears && !this.fiscalYears.length)
      || (defaultYear && !this.screenFilters)
      || (defaultYear && this.screenFilters && !this.screenFilters.FiscalYears)
      || (defaultYear && this.screenFilters && this.screenFilters.FiscalYears && !this.screenFilters.FiscalYears.length)) {
      this.fiscalYears = [defaultYear.yearId];
    }

    this.fiscalYearChange();

  }

  async fiscalYearChange() {
    if (this.fiscalYears && this.fiscalYears.length) {
      await this.itemManagerService.GetPlanningMonthList({ YearIds: this.fiscalYears }).toPromise();
      setTimeout(() => {
        if (this.planningMonths) {
          const isMonthAvailable = _.find(this.planningMonthList, a => this.planningMonths.includes(a.monthId));
          if (!isMonthAvailable) {
            this.planningMonths = [];
          }
        }
        this.filterPromotions();
      }, 0);
    } else {
      this.planningMonthList = [];
    }
  }

  filterPromotions() {
    const eventBody = {
      ...this.releasePromotionName && { PromotionItemName: this.releasePromotionName },
      ...(this.fiscalYears && this.fiscalYears.length) && { FiscalYears: this.fiscalYears },
      ...(this.planningMonths && this.planningMonths.length) && { PlanningMonths: this.planningMonths },
      ...this.actionIds && { ActionIds: this.actionIds },
      ...this.accountIds && { AccountIds: this.accountIds }
    };
    this.screenPreference.screenFilters = eventBody;
    this.updateScreenFilterPreference();
    this.gridApi && this.gridApi.showLoadingOverlay();
    this.itemPromotionService.GetFilterPromotionItemList(eventBody).subscribe((res: any) => {
      this.gridData = res;
      this.gridApi && this.gridApi.hideOverlay();
    },
    err => {
      this.gridApi && this.gridApi.hideOverlay();
    });
  }

  clearPromotionFilters() {
    this.releasePromotionName = '';
    this.planningMonths = [];
    this.fiscalYears = [];
    this.actionIds = '';
    this.accountIds = this.disabledAccountSelection ? this.accountIds : '';
    this.setDefaulYear();
  }

  exportNewItemWeeklyDataTemplate() {
    const promotionItemIds = _.join(_.map(this.gridData, a => a.promotionItemId), ',');
    if (promotionItemIds.length > 1500) {
      this.toastr.warning('Warning', 'Too many promotions specified, please filter the promotion data.');
    } else {
      this.itemPromotionService.GetPromotionItemDetailWeeklyForecastNewItemPromoList({
        PromotionItemIds: promotionItemIds
      }).subscribe((res: any) => {
        this.forecastGridData = _.orderBy(res, ['releaseDate'], ['desc']);
        this.forecastGridColDef = approvalForecastExportGridColDefs(this.isRetailerTeam);
        this.isExportingTemplate = true;
        setTimeout(() => {
          this.exportDataTemplate();
        }, 200);
      });
    }
  }

  forecastGridReady(params) {
    this.forecastGridApi = params.api;
  }

  exportDataTemplate() {
    const csvExportParams = gridDataExportParams({
      fileName: 'NewItemsPromotionWeeklyDataTemplate',
      colDefs: this.forecastGridColDef,
      isExportTemplate: true,
      allColumns: true
    });
    this.forecastGridApi.exportDataAsCsv(csvExportParams);
  }

  importNewItemWeeklyData() {
    (document.getElementById('upload-new-item-promotion-data') as any).value = '';
    document.getElementById('upload-new-item-promotion-data').click();
  }

  validateUploadedCSV(fileInput: any) {
    const reader = new FileReader();
    if (fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        const importDataFile = reader.result as any;
        /*
          Files Validations Will go here
        */
        this.uploadImportedCsvFile(importDataFile);
      }
    }
  }

  uploadImportedCsvFile(importDataFile) {
    this.itemPromotionService.ImportNewItemPromotionsList({
      fileBase64: importDataFile
    }).subscribe(
      (res: any) => {
        this.toastr.success('Success', 'Promotion Data Imported Successfully.');
      },
      (err: any) => {
        this.toastr.error('Error', 'Promotion Data Import Failed.');
      });
  }

  ngOnDestroy() {
    this.editSubscription.unsubscribe();
    this.accountSubscription.unsubscribe();
    this.actionSubscription.unsubscribe();
    this.fiscalYearSubscription.unsubscribe();
    this.planningMonthSubscription.unsubscribe();
  }

}
