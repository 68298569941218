import { AllCommunityModules, GridApi } from '@ag-grid-community/all-modules';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NameRendererComponent } from '../../common/name-renderer';
import { ConfigurationService } from '../../services/configuration.service';
import { Item } from './item.model';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { LocalstorageService } from 'src/services/localstorage.service';
import { USER_INFO } from 'src/common/keys';
import { formatDate } from '@angular/common';
import { ServerSideStoreType } from 'ag-grid-community';
@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss'],
})
export class ItemsComponent implements OnInit, OnDestroy {
  public modules = AllModules;

  public columnDefs = [
    {
      field: '-', // would be used as a reference
      hide: true, //- hide in grid data
      lockVisible: true, // - disable visibility changing via menu
      filter: 'agTextColumnFilter', //- require for setFilterModel
      filterParams: {
        newRowsAction: 'keep',
      },
    },
    {
      headerName: 'Image',
      field: 'image',
      minWidth: 50,
      resizable: true,
      cellRenderer: (params) => {
        return params.data.documentPath
          ? `<img class='item-image' src=${params.data.documentPath}>`
          : `<img class='item-image' src='assets/images/thumbnail.png'>`;
      },
    },
    {
      headerName: 'Item description',
      field: 'description',
      minWidth: 50,
      resizable: true,
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.addItem.bind(this),
      },
    },
    {
      headerName: 'SKU',
      field: 'sku',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Brand',
      field: 'brandName',
      minWidth: 50,
      resizable: true,
    },

    {
      headerName: 'Product Group',
      field: 'productGroupName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Product Family',
      field: 'productFamilyName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Parent Product',
      field: 'parentProductName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Product Category',
      field: 'productCategory',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Product Status',
      field: 'productStatus',
      minWidth: 50,
      resizable: true,
    },
    ,
    {
      headerName: 'MSRP',
      field: 'msrp',
      minWidth: 50,
      resizable: true,
    },
    ,
    {
      headerName: 'Cost',
      field: 'cost',
      minWidth: 50,
      resizable: true,
    },
    ,
    {
      headerName: 'Color',
      field: 'color',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Capacity',
      field: 'capacity',
      minWidth: 50,
      resizable: true,
    },
    ,
    {
      headerName: 'Screen Size',
      field: 'screenSize',
      minWidth: 50,
      resizable: true,
    },
    ,
    {
      headerName: 'Street Date',
      field: 'streetDate',
      minWidth: 50,
      resizable: true,
      cellRenderer: (params) => {
        return params.value ? formatDate(params.value, 'dd MMM, y', 'en') : '';
      },
    },
    {
      headerName: 'Status',
      field: 'productStatus',
      minWidth: 50,
      resizable: true,
    },
  ];
  public gridOptions: any;
  gridApi: GridApi;
  defaultColDef: any;
  public itemModel = new Item(new Item());
  public defaultModel = new Item(new Item());
  itemList: Item[];
  showForm = false;
  headerHeight = 45;
  cacheBlockSize: number;
  paginationPageSize: number;
  rowModelType: string;
  viewPermission: boolean;
  editPermission: boolean;
  createPermission: boolean;
  serverSideStoreType = ServerSideStoreType.Partial;
  public searchText = '';
  constructor(
    readonly configurationService: ConfigurationService,
    public storage: LocalstorageService
  ) {
    this.gridOptions = {
      frameworkComponents: {
        nameRenderer: NameRendererComponent,
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true,
    };
  }
  ngOnDestroy() {
    this.closeItemPanel();
  }
  ngOnInit() {
    let userTeams = this.storage.get(USER_INFO).teams;
    userTeams.forEach((element) => {
      element.teamScreenGroup.forEach((group) => {
        if (
          group.screenGroupId == 1 &&
          group.viewPermission &&
          !this.viewPermission
        ) {
          this.viewPermission = true;
        }
        if (
          group.screenGroupId == 1 &&
          group.editPermission &&
          !this.editPermission
        ) {
          this.editPermission = true;
        }
        if (
          group.screenGroupId == 1 &&
          group.createPermission &&
          !this.createPermission
        ) {
          this.createPermission = true;
        }
      });
    });
    this.rowModelType = 'serverSide';
    this.paginationPageSize = 10;
    this.cacheBlockSize = 10;
    //this.getItemManagerList();
  }

  public getItemManagerList = () => {
    const params = {
      offset: 0,
      pageSize: 15,
      active: true,
    };
    this.configurationService.ItemManagerGetAll(params).subscribe((res) => {
      this.itemList = res.data || [];
    });
  };
  onFilterTextBoxChanged(event) {
    this.gridApi.setFilterModel({"-":{filter: this.searchText}})
  }
  onGridReady = (params) => {
    console.log('onGridReady');
    this.gridApi = params.api;
    var datasource = {
      getRows: (params) => {
        console.log(
          'Getting datasource rows, start: ' +
            params.startRow +
            ', end: ' +
            params.endRow
        );
        console.log(
          'Getting datasource rows, start: ' +
            params.request.startRow +
            ', end: ' +
            params.request.endRow
        );
        let url = `offSet=${
          params.request.startRow ? params.request.startRow : 0
        }&pageSize=${
          params.request.endRow ? params.request.endRow : 40
        }&active=true&searchText=${this.searchText}`;
        if (params.request.sortModel.length > 0) {
          let isSortAscending =
            params.request.sortModel[0].sort == 'desc' ? false : true;
          url =
            url +
            '&sortColumn=' +
            params.request.sortModel[0].colId +
            '&SortAscending=' +
            isSortAscending;
        } else {
          let isSortAscending = false;
          url =
            url +
            '&sortColumn=' +
            'ItemManagerId' +
            '&SortAscending=' +
            isSortAscending;
        }
        this.configurationService
          .ItemManagerGetAll(url)
          .subscribe((response) =>
            params.successCallback(response.data, response.totalRecord)
          );
      },
    };
    params.api.setServerSideDatasource(datasource);
  };
  addItem(item?) {
    this.closeItemPanel();
    if (item && item.rowData) {
      item.rowData.editPermission = this.editPermission;
      this.configurationService.itemModel = item.rowData;
      this.showForm = true;
    } else {
      if (item) item.rowData.editPermission = true;
      this.showForm = true;
    }
  }
  closeItemPanel = () => {
    this.configurationService.itemModel = this.defaultModel;
    this.showForm = false;
  };
  updateList() {
    this.gridApi.purgeServerSideCache();
  }
}
