<div class="master-item-page">

  <app-promotion-planner-filters [isDialog]="true" (filterEvent)="applyFilters($event)" [gridApiRef]="gridApi"></app-promotion-planner-filters>

  <div class="promotion-planner-grid">
    <div class="all-show-grid">
      <ag-grid-angular class="ag-theme-material" [suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups
        toolPanelSuppressValues [frameworkComponents]="frameworkComponents" [rowSelection]="rowSelection"
        [overlayLoadingTemplate]="overlayLoadingTemplate"
        (selectionChanged)="promotionPlannerGridRowSelectionChanged($event)" [gridOptions]="gridOptions"
        [columnDefs]="gridColumnDefs" [rowData]="gridData" [pagination]="true" [paginationPageSize]="paginationPageSize"
        [cacheBlockSize]="cacheBlockSize" (gridReady)="onGridReady($event)" [modules]="modules" #promotionPlannerGrid>
      </ag-grid-angular>
    </div>
  </div>

  <div mat-dialog-actions style="justify-content: flex-end;">
    <button mat-button mat-flat-button color="accent" (click)="onDoneClick()">Done</button>
  </div>
</div>