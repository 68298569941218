import { Component, OnInit } from '@angular/core';
import { quarterlyData } from 'src/modules/merchandise-financial-planning/mockData';
import { MfpService } from 'src/services/mfp-services/mfp.service';
import { generateQuarterlyGridColumns } from '../agGridConfig';

@Component({
  selector: 'app-quarterly-planning',
  templateUrl: './quarterly-planning.component.html',
  styleUrls: ['./quarterly-planning.component.scss']
})
export class QuarterlyPlanningComponent implements OnInit {

  // Grid Variables
  private gridApi;
  quarterlyData = quarterlyData;

  gridColumnApi;

  columnDefs = generateQuarterlyGridColumns();
  defaultColDef;
  suppressRowTransform = true;

  dataSource = {
    chart: {
      caption: "Quarterly Revenues",
      exportenabled: "0",
      theme: "fusion"
    },
    categories: [
      {
        category: [
          {
            label: "Q1"
          },
          {
            label: "Q2"
          },
          {
            label: "Q3"
          },
          {
            label: "Q4"
          }
        ]
      }
    ],
    dataset: [
      {
        seriesname: "FY-2022",
        renderas: "line",
        data: [
          {
            value: "8000000"
          },
          {
            value: "11000000"
          },
          {
            value: "13200000"
          },
          {
            value: "8000000"
          }
        ]
      },
      {
        seriesname: "FY-2021",
        renderas: "line",
        data: [
          {
            value: "6386000"
          },
          {
            value: "7942700"
          },
          {
            value: "7260520"
          },
          {
            value: "6386000"
          }
        ]
      },
      {
        seriesname: "FY-2020",
        renderas: "line",
        data: [
          {
            value: "5386000"
          },
          {
            value: "6942700"
          },
          {
            value: "6260520"
          },
          {
            value: "5786000"
          }
        ]
      }
    ]
  };

  width = '100%';
  height = '100%';
  type = "mscombi2d";
  dataFormat = "json";

  constructor(private mfpService: MfpService) {
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      filter: false,
      sortable: false,
      resizable: true,
    };
  }

  ngOnInit(): void {
    this.quarterlyData[0].totals = this.mfpService.yearlyTotal
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.mfpService.updateTotal
    .subscribe(() => {
      this.quarterlyData[0].totals = this.mfpService.yearlyTotal;
      this.cellValueChanged();
    });
    this.gridColumnApi = params.columnApi;
    this.cellValueChanged();
  }

  cellValueChanged(type = false) {
    this.mfpService.yearlyTotal = Number(this.quarterlyData[0].totals);
    type && this.mfpService.updateTotal.next(this.mfpService.yearlyTotal);
    this.quarterlyData[1].totals = Number(this.quarterlyData[1].q1) + Number(this.quarterlyData[1].q2)
      + Number(this.quarterlyData[1].q3) + Number(this.quarterlyData[1].q4);
    ['q1', 'q2', 'q3', 'q4'].forEach((q, index) => {
      this.quarterlyData[0][q] = (Number(this.quarterlyData[0].totals) * Number(this.quarterlyData[1][q])) / 100;
      this.quarterlyData[2][q] = (Number(this.quarterlyData[0][q]) / Number(this.quarterlyData[4][q]) - 1) * 100;
      this.quarterlyData[6][q] = (Number(this.quarterlyData[4][q]) / Number(this.quarterlyData[8][q]) - 1) * 100;
    });
    this.quarterlyData[6].totals = (Number(this.quarterlyData[4].totals) / Number(this.quarterlyData[8].totals) - 1) * 100;
    this.gridApi.setRowData(this.quarterlyData);

    this.dataSource.dataset[0].data = [
      {
        value: this.quarterlyData[0].q1.toString()
      },
      {
        value: this.quarterlyData[0].q2.toString()
      },
      {
        value: this.quarterlyData[0].q3.toString()
      },
      {
        value: this.quarterlyData[0].q4.toString()
      }
    ];

    this.dataSource.dataset[1].data = [
      {
        value: this.quarterlyData[4].q1.toString()
      },
      {
        value: this.quarterlyData[4].q2.toString()
      },
      {
        value: this.quarterlyData[4].q3.toString()
      },
      {
        value: this.quarterlyData[4].q4.toString()
      }
    ];

    this.dataSource.dataset[2].data = [
      {
        value: this.quarterlyData[8].q1.toString()
      },
      {
        value: this.quarterlyData[8].q2.toString()
      },
      {
        value: this.quarterlyData[8].q3.toString()
      },
      {
        value: this.quarterlyData[8].q4.toString()
      }
    ];
  }

}
