import { GridApi, } from '@ag-grid-community/all-modules';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/services/configuration.service';
import { NameRendererComponent } from 'src/common/name-renderer';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { OrderList } from 'src/modules/items/item.model';
import { LocalstorageService } from 'src/services/localstorage.service';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { MatDialog } from '@angular/material/dialog';
import { OrderListMeasuresNewComponentComponent } from '../order-list-measures-new-component/order-list-measures-new-component.component';
import { ForecastConfirmationModalComponent } from 'src/modules/facebook/features/fb-forecast/forecast-confirmation-modal/forecast-confirmation-modal.component';
import { USER_ID } from 'src/common/keys';
@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {
  @Input() gridMapped: any;
  public rowSelection;
  @Input() leftGroupList = [];
  @Input() measureList = [];
  @Input() dropdownObjects;
  @Input() forecastList = [];
  @Input() itemsList = [];
  @Input() accountList = [];
  @Input() versionTypeId = 2;
  @Input() fbForecastSelectedId;

  public modules = AllModules;

  public columnDefs = [
    {
      headerName: 'Multi Select',
      field: 'is',
      minWidth: 50,
      resizable: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressMenu: true,

    },
    {
      headerName: 'Batch ID',
      field: 'batchId',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Order Line ID',
      field: 'orderLineId',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Order Status',
      field: 'orderStatusName',
      minWidth: 50,
      resizable: true,
    },

    {
      headerName: 'Order Date',
      field: 'orderDate',
      minWidth: 50,
      resizable: true,
      valueFormatter: this.dateFormatter.bind(this)
    },
    {
      headerName: 'Delivery Date',
      field: 'deliveryDate',
      minWidth: 50,
      resizable: true,
      valueFormatter: this.dateFormatter.bind(this)
    },
    {
      headerName: 'Order Source',
      field: 'orderSource',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Purchase Order',
      field: 'purchaseOrder',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Item Code',
      field: 'itemCode',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Retailer SKU',
      field: 'retailerSKU',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'EAN/UPC',
      field: 'upcCode',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Description',
      field: 'description',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Release Date',
      field: 'releaseDate',
      minWidth: 50,
      resizable: true,
      valueFormatter: this.dateFormatter.bind(this)
    },
    {
      headerName: 'Location Code',
      field: 'locationCode',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Ship TO',
      field: 'shipTO',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'State',
      field: 'state',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'X DOC DC',
      field: 'x_DOC_DC',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Order Qty',
      field: 'orderQty',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Original Order Qty',
      field: 'originalOrdQty',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Unconstrained Qty',
      field: 'unconstrainedQty',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'DOS',
      field: 'dos',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'MIN',
      field: 'min',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'MAX',
      field: 'max',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'On Hand',
      field: 'onHand',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'In Transit',
      field: 'inTransit',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'On Order',
      field: 'onOrder',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Effective Inventory',
      field: 'effectiveInventory',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Forecast WK1',
      field: 'forecastWK1',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Forecast WK2',
      field: 'forecastWK2',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Forecast WK3',
      field: 'forecastWK3',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Forecast WK4',
      field: 'forecastWK4',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Sales CW',
      field: 'salesCW',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Sales CW-1',
      field: 'salesCW_1',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Visibility',
      field: 'active',
      minWidth: 50,
      resizable: true,
    },
  ];
  public gridOptions: any;
  gridApi: GridApi;
  defaultColDef: any;
  headerHeight = 45;
  paginationPageSize: number;
  rowModelType: string;
  public searchText = '';
  orderList: OrderList[];
  profileMeasureList: any;
  @Input() masterMeasureList =

    [
      {
        "measureId": 1,
        "parentId": 0,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Batch ID",
        "background": "#44104f",
        "code": "",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 1,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 2,
        "parentId": 0,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Order Line ID",
        "background": "#8d009d",
        "code": "",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 2,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 3,
        "parentId": 0,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Order Status",
        "background": "#d81ad2",
        "code": "",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 3,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 4,
        "parentId": 0,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Order Date",
        "background": "#ef0c5f",
        "code": "",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 4,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 5,
        "parentId": 0,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Delivery Date",
        "background": "#fc6b6b",
        "code": "",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 5,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 10,
        "parentId": 0,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Order Source",
        "background": "#2481d3",
        "code": "",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 6,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 6,
        "parentId": 0,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Purchase Order",
        "background": "#feac47",
        "code": "",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 7,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 7,
        "parentId": 0,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Item Code",
        "background": "#43e8a9",
        "code": "",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 7,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 8,
        "parentId": 0,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Retailer SKU",
        "background": "#1c9ead",
        "code": "",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 8,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 11,
        "parentId": 0,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "EAN/UPC",
        "background": "#44104f",
        "code": "",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 9,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 9,
        "parentId": 0,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Description",
        "background": "#53b6f4",
        "code": "",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 10,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 12,
        "parentId": 0,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Release Date",
        "background": "#8d009d",
        "code": "",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 12,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 13,
        "parentId": 1,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Location Code",
        "background": "",
        "code": "AlgoBaselineFcst",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 13,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 1,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 14,
        "parentId": 1,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Ship TO",
        "background": "",
        "code": "AlgoPromoUnits",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 14,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 1,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 15,
        "parentId": 1,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "State",
        "background": "",
        "code": "AlgoTotalForecast",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 15,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": true,
        "isGraphDefault": true,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 16,
        "parentId": 1,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "X DOC DC",
        "background": "",
        "code": "AlgoTFvsGDPTotalForecastPct",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 16,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 17,
        "parentId": 1,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Pre-Review Qty",
        "background": "",
        "code": "AlgoTFvsUDPLikelyTotalFcstPct",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 17,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 301,
        "parentId": 1,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Order Qty",
        "background": "",
        "code": "AlgoTotalFcstvsGDPTotalFcst",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 18,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 302,
        "parentId": 1,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "MIN",
        "background": "",
        "code": "AlgoTotalFcstvsUDPLikelyTotalFcst",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 19,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 303,
        "parentId": 1,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "MAX",
        "background": "",
        "code": "AlgoBaselinevsGDPBaselineFcst",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 20,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 304,
        "parentId": 1,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "On Hand",
        "background": "",
        "code": "AlgoBaselinevsUDPLikelyBaselineFcst",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 21,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 305,
        "parentId": 1,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "In Transit",
        "background": "",
        "code": "AlgoPromovsGDPPromoFcst",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 22,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 19,
        "parentId": 2,
        "fbTemplateId": 0,
        "templateId": 2,
        "measureName": "On Order",
        "background": "",
        "code": "GDPBaselineFcst",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 23,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 1,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 21,
        "parentId": 2,
        "fbTemplateId": 0,
        "templateId": 2,
        "measureName": "Effective Inventory",
        "background": "",
        "code": "GDPManualFcstAdjustment",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 24,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 1,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 20,
        "parentId": 2,
        "fbTemplateId": 0,
        "templateId": 2,
        "measureName": "Forecast",
        "background": "",
        "code": "GDPPromoUnits",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 25,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 1,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 242,
        "parentId": 2,
        "fbTemplateId": 0,
        "templateId": 2,
        "measureName": "Sales CW",
        "background": "",
        "code": "GDPPromoFcstAdjustmentPct",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 26,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 1,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 22,
        "parentId": 2,
        "fbTemplateId": 0,
        "templateId": 2,
        "measureName": "Sales CW-1",
        "background": "",
        "code": "GDPManualFcstAdjustmentPct",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 27,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": false,
        "isGraphDefault": false,
        "isGraphSort": 0,
        "isRefresh": 1,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      },
      {
        "measureId": 23,
        "parentId": 2,
        "fbTemplateId": 0,
        "templateId": 1,
        "measureName": "Visibility",
        "background": "",
        "code": "GDPTotalForecast",
        "fbMesaureActive": false,
        "isSum": false,
        "isException": false,
        "isComment": false,
        "sort": 28,
        "distiSort": 0,
        "isDisti": null,
        "isAccount": null,
        "isAccSubtotal": null,
        "isDistAccTotal": null,
        "isGraph": true,
        "isGraphDefault": true,
        "isGraphSort": 0,
        "isRefresh": 0,
        "createdById": null,
        "createdByName": null,
        "createdDate": null,
        "modifiedById": null,
        "modifiedByName": null,
        "modifiedDate": null,
        "active": false,
        "searchText": null,
        "currentUserId": null
      }

    ];
  selectedProfile: any;
  savedMeasures: any[];
  constructor(
    readonly configurationService: ConfigurationService,
    public storage: LocalstorageService,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef

  ) {
    this.gridOptions = {
      frameworkComponents: {
        nameRenderer: NameRendererComponent,
      },
      pagination: true,
      paginationPageSize: 50,
    };
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true,
    };
    this.rowSelection = 'multiple';

  }
  ngOnDestroy() {
    // this.closeItemPanel();
  }
  ngOnInit() {
    this.rowModelType = 'clientSide';
    this.getItemManagerList();

  }

  public getItemManagerList = () => {
    const params = {
      offset: 0,
      pageSize: 80,
      active: true,
      searchText: ''
    };
    this.configurationService.OrderListGetAll(params).subscribe((res) => {
      this.orderList = res.data || [];
    });
  };
  onFilterTextBoxChanged(event) {
    this.gridApi.setFilterModel({ "-": { filter: this.searchText } })
  }
  onGridReady = (params) => {
    console.log('onGridReady');
    this.gridApi = params.api;
  };

  updateList() {
    this.gridApi.purgeServerSideCache();
  }
  dateFormatter(params) {
    if (params.value)
      return new Date(params.value).toLocaleDateString("en-US")
    return ""
  }
  public filterLeftGroups = () => {
    this.leftGroupList = this.leftGroupList.filter((el) => el.rowspan > 0);
  };
  openMesuresDialog() {
    this.profileMeasureList = JSON.parse(
      JSON.stringify(
        this.masterMeasureList.map((row) => ({ ...row, checked: false }))
      )
    );
    this.dialog
      .open(OrderListMeasuresNewComponentComponent, {
        width: '1200px',
        panelClass: 'fb-dialog-wrapper',

        data: { measureList: this.profileMeasureList },
      })
      .afterClosed()
      .subscribe((command) => {
        if (command) {
          if (command.isCreateProfile) {
            this.selectedProfile = command;
            this.dialog
              .open(ForecastConfirmationModalComponent, {
                width: '700px',
                data: {
                  label: 'What would you like to name your profile?',
                  isOnLoad: false,
                  changeNameConfirmation: true,
                  isInputRequired: true,
                  inputLabel: 'Enter name',
                },
                disableClose: true,
              })
              .afterClosed()
              .subscribe((command) => {
                if (command.isNameSave) {
                  let selectedMeasures =
                    this.selectedProfile.measureList.filter((x) => x.checked);
                  let measureIds = '';
                  selectedMeasures.forEach((element) => {
                    measureIds = measureIds + element.measureId + ',';
                  });
                  measureIds = measureIds.slice(0, -1);
                  let model = {
                    fbMeasureProfileId: 0,
                    measureIds: measureIds,
                    fbMeasureProfileName: command.name,
                    userId: this.storage.get(USER_ID),
                    applied: this.selectedProfile.isFirst,
                  };
                  this.configurationService
                    .FbForecastSaveMeasureProfile(model)
                    .subscribe((resp) => {
                      let selectedMeasures =
                        this.selectedProfile.measureList.filter(
                          (x) => x.checked
                        );
                      this.measureList = selectedMeasures.sort(
                        (a, b) => a.sort - b.sort
                      );
                      const parentMeasures = this.masterMeasureList.filter(
                        (row) => !row.parentId
                      );
                      this.leftGroupList = parentMeasures.map((row) => ({
                        ...row,
                        rowspan: this.measureList.filter(
                          (measure) =>
                            measure.parentId == row.measureId && measure.checked
                        ).length,
                      }));
                      this.measureList = this.measureList
                        .sort((a, b) => a.sort - b.sort)
                        .filter((row) => row.parentId)
                        .map((row) => ({ ...row, checked: true }))
                        .sort((a, b) => a.sort - b.sort);
                      this.filterLeftGroups();
                      this.cdr.detectChanges();
                    });
                }
              });
          }
          if (command.profileApplied) {
            this.selectedProfile = command;
            let selectedMeasures = this.selectedProfile.measureList.filter(
              (x) => x.checked
            );
            if (selectedMeasures.length)
              this.measureList = selectedMeasures.sort(
                (a, b) => a.sort - b.sort
              );
            else this.measureList = this.savedMeasures;
            const parentMeasures = this.masterMeasureList.filter(
              (row) => !row.parentId
            );
            this.leftGroupList = parentMeasures.map((row) => ({
              ...row,
              rowspan: this.measureList.filter(
                (measure) =>
                  measure.parentId == row.measureId && measure.checked
              ).length,
            }));
            this.measureList = this.measureList
              .sort((a, b) => a.sort - b.sort)
              .filter((row) => row.parentId)
              .map((row) => ({ ...row, checked: true }))
              .sort((a, b) => a.sort - b.sort);
            this.filterLeftGroups();
            this.cdr.detectChanges();
          }
          if (command.maxCountReached) {
            this.dialog.open(ForecastConfirmationModalComponent, {
              width: '700px',
              panelClass: 'fb-dialog-wrapper',
              data: {
                label:
                  'Max profile creation limit has been reached. You can create only 20 profiles',
                isOnLoad: false,
                confirmation: true,
              },
              disableClose: true,
            });
          }
        }
      });
  }
}
