import {
  Component,
  OnInit,
  ViewEncapsulation,
  TemplateRef,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { Observable, of, Subscription, forkJoin } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { NgxToasterService } from '../../../../../services/ngx-toaster.service';
import { LocalstorageService } from '../../../../../services/localstorage.service';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { FbForecast } from '../../../../../common/facebook.models';
import { USER_ID } from '../../../../../common/keys';
import { ForecastConfirmationModalComponent } from '../../fb-forecast/forecast-confirmation-modal/forecast-confirmation-modal.component';
import {
  FB_Template,
  HalfYear,
  Month,
  PhysicalYear,
  Quarter,
  Week,
} from '../../global.models';
import { ImportPlannerComponent } from '../../shared/planner-import/planner-import.component';
import {
  addCommas,
  CreateTimeframeUIList,
  EqualValueMeasures,
  onKeyDown,
  setFocusOnNextInput,
} from 'src/common/functions';

import { ExportAllTemplateComponent } from '../../shared/export-all-template/export-all-template.component';
import * as moment from 'moment';
import { InventoryPlannerMeasureProfileComponent } from '../inventory-planner-measure-profile/inventory-planner-measure-profile.component';
import { InlineWorker } from '../inline-worker';
import { Workbook, Column } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-inventory-planner-grid',
  templateUrl: './inventory-planner-grid.component.html',
  styleUrls: ['./inventory-planner-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryPlannerGridComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() selectedProducts;
  @Input() selectedChannel;
  @Input() productTree;
  @Input() calendarIds;
  @Input() userFBForecastId;
  @Output() onClickEvent = new EventEmitter();
  @Output() updateGridList = new EventEmitter();
  @Input() fbForecast;
  @Input() invItemIds;
  @Input() invChannelIds;
  @Input() masterMeasureList = [];
  public feededMeasures = [132];
  exportOptions = null;
  public oldList = [];
  methodList = [
    'Algo Baseline ST Forecast',
    'DP Baseline ST Fcst',
    'CO Baseline ST Fcst',
    'Linear',
    'Curve',
    'Exponential',
    'Logarithmic',
    'Power',
    'Parabola',
    'Moving Average',
    'Single Expotential Smoothing',
    'Adaptive Smoothing',
    'Holts Linear Trend',
    'Period Percentage Change',
    'Seasonal Smoothing Method',
    'Seasonal Smoothing Average',
    'Seasonal Percentage Change',
    'Additive Decomposition',
    'Winters Additive',
  ];
  @Input() leftGroupList = [];
  @Input() measureList = [];
  @Input() dropdownObjects;
  @Input() forecastList = [];
  @Input() itemsList = [];
  @Input() accountList = [];
  @Input() versionTypeId = 2;
  @Input() fbForecastSelectedId;
  public flatCalendarIds = [];
  public selectedValue;
  width = '100%';
  height = '500';
  type = 'msline';
  dataFormat = 'json';
  dataSource;
  public submitted = false;
  public isEdit = false;
  public columnDefs = [];
  private gridApi: any;
  public tenureTypeList = [];
  public tenureTypeListTemp = [];
  public rowList = [];
  public gridOptions: any;
  public getRowHeight;
  public getRowStyle;
  public headerHeight;
  public modules = AllCommunityModules;
  public clientId = 1;
  public channel = null;
  public csvRecords: any;
  public customerSelectedList = [];
  public selectedItemIds = [];
  public timeframeSelectedList = [];
  public lastYearCalendarList = [];
  public itemSeletedList = [];
  public spreadEqualMeasures = EqualValueMeasures;
  public timeframeList = [
    {
      headerName: 'Year',
    },
    {
      headerName: 'Half Year',
    },
    {
      headerName: 'Quarter',
    },
    {
      headerName: 'Monthly',
    },
    {
      headerName: 'Weekly',
    },
  ];
  public versionList = [];
  public monthList = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredCustomers: Observable<any[]>;
  filteredItems: Observable<any[]>;
  filteredTimeframe: Observable<any[]>;
  public seletedYear = null;
  public seletedVersion = null;
  public selectedMonth = null;
  public defaultRow = {};
  public selectAll = true;
  searchCustomer = new FormControl();
  searchItem = new FormControl();
  searchTimeframe = new FormControl();
  files: File[] = [];
  @Input() public yearListGrid = [];
  @Input() public calendarList = [];
  @Input() viewPermission: any;
  @Input() editPermission: any;
  @Input() public forecastMethod;
  @Input() public seasonalitySourceLevel;
  @Input() public movingAverageWeeks;
  @Input() public isOpenSaved;
  @Input() public isRestoreSession;
  yearList = [];
  totalCalculatedList = [];
  tempColDefs = [];
  yearListTemp = [];
  yearListForFilter = [];
  graphList = [];
  tempGroupList = [];
  labelList;
  channelSelectedList;
  chooserList = [];
  gridListUI = [];
  selectedProfile: any;
  profileMeasureList: any;
  savedMeasures: any[];
  @Input() account: any;
  @Input() product: any;
  public selectedChannelIds = [];
  public sessionUpdate = false;
  public collapsedObject;
  public openingOH = 0;
  public targetWOS = 4;
  public leadTime = 14;
  public AccountUnconstrainedSellIn = 100;
  public forecastSubscription = new Subscription();
  public methodSubscription = new Subscription();
  public selectedList = [];
  public selectedOperationList = [];
  public fbTemplateId = FB_Template.Retail_Direct;
  @Input() measureKeys = [];
  public lowHeirarichyMeasures = [
    'ActualSTCumulative',
    'CustomerSTFcstCumulative',
    'UDPLikelyTotalFcstCumulative',
    'CPFRSellThruOverrideFcstCumulative',
    'AccountConstrainedSellInFcstCumulative',
  ];
  constructor(
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    public dialogRef: MatDialogRef<any>,
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef
  ) {
    this.configurationService.openImportDialog$.subscribe((res) => {
      if (res && res.isDirectInventory) {
        this.import();
      }
    });
    this.configurationService.updateWeeks$.subscribe((res) => {
      if (res && res.isDirectInventory) {
        res.weeks.forEach((row) => {
          const weekIndex = this.gridListUI.findIndex(
            (el) => row.calendarId === el.calendarId
          );
          if (weekIndex > -1) {
            this.gridListUI[weekIndex]['measures'][res.measureCode] =
              this.addCommas(row['measures'], res.measureCode);
          }
        });
        if (
          this.collapsedObject &&
          this.collapsedObject.selected &&
          this.collapsedObject.year
        ) {
          this.showValues(
            this.collapsedObject?.selected,
            this.collapsedObject?.year,
            null
          );
        } else {
          const year = this.gridListUI.find((row) => row.isSelected);
          this.collapseYear(year);
        }
        this.cdr.detectChanges();
      }
    });
  }
  get gridData() {
    return this.gridListUI.filter((row) => row.isSelected);
  }
  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event) {
    // we make sure only draggables on the document elements are selecte
    let title = event.target.getAttribute('title');
    if (!this.selectedList.find((row) => row.measure === title)) {
      this.selectedList.forEach((row) => {
        row.field.style.background = 'rgb(238, 238, 238)';
      });
      this.selectedList = [];
      if (this.selectedList.length > 1) {
        this.toastr.success('Success', 'Clipboard cleared!');
      }
    }
  }
  ngOnInit() {
    this.tempGroupList = JSON.parse(JSON.stringify(this.leftGroupList));
  }
  ngOnDestroy() {
    this.forecastSubscription.unsubscribe();
    this.methodSubscription.unsubscribe();
    this.configurationService.tempForecastList = [];
  }
  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges &&
      simpleChanges.measureList &&
      simpleChanges.measureList.currentValue
    ) {
      this.measureList = simpleChanges.measureList.currentValue;
      this.savedMeasures = this.measureList;
    }
    if (
      simpleChanges &&
      simpleChanges.leftGroupList &&
      simpleChanges.leftGroupList.currentValue
    ) {
      this.leftGroupList = simpleChanges.leftGroupList.currentValue;
      this.tempGroupList = JSON.parse(JSON.stringify(this.leftGroupList));
    }
    if (
      simpleChanges &&
      simpleChanges.yearListGrid &&
      simpleChanges.yearListGrid.currentValue
    ) {
      this.yearListGrid = simpleChanges.yearListGrid.currentValue;
      // this.yearListGrid.forEach((year) => this.collapseYear(year));
      this.createUIList(true);
    }
    if (
      simpleChanges &&
      simpleChanges.account &&
      simpleChanges.account.currentValue
    ) {
      this.account = simpleChanges.account.currentValue;
    }
    if (
      simpleChanges &&
      simpleChanges.product &&
      simpleChanges.product.currentValue
    ) {
      this.product = simpleChanges.product.currentValue;
    }
    if (
      simpleChanges &&
      simpleChanges.viewPermission &&
      simpleChanges.viewPermission.currentValue
    ) {
      this.viewPermission = simpleChanges.viewPermission.currentValue;
    }
    if (
      simpleChanges &&
      simpleChanges.editPermission &&
      simpleChanges.editPermission.currentValue
    ) {
      this.editPermission = simpleChanges.editPermission.currentValue;
    }
    this.filterLeftGroups();
  }
  import() {
    this.dialog
      .open(ImportPlannerComponent, {
        width: '1200px',
        panelClass: 'fb-dialog-wrapper',

        data: {
          forecastList: this.forecastList,
          gridListUI: this.gridListUI,
          dropdownObjects: this.dropdownObjects,
          userFBForecastId: this.userFBForecastId || 0,
          itemsList: this.itemsList,
          accountList: this.accountList,
          measureList: this.measureList,
          calendarList: this.calendarList,
          versionTypeId: this.versionTypeId,
          fbForecastSelectedId: this.fbForecastSelectedId,
          fbTemplateId: FB_Template.Retail_Direct,
          isDirectInventory: true,
        },
      })
      .afterClosed()
      .subscribe((command) => {
        this.configurationService.openImportDialog.next(false);
        if (command && command.list.length > 0 && command.isDirectInventory) {
          let forecastObject = {
            measureId: 0,
            calendarIds: '',
            itemIds: this.selectedItemIds.toString(),
            channelIds: this.selectedChannelIds.toString(),
            value: 0,
            textValue: '',
            versionTypeId: this.versionTypeId,
            fbTemplateId: FB_Template.Retail_Direct,
            userId: this.storage.get(USER_ID),
            userFBForecastId: this.userFBForecastId || 0,
          };
          let tasks = [];
          let models = [];
          command.list.forEach((calendar) => {
            let forecast = new FbForecast(null);
            forecast.itemIds = this.dropdownObjects.itemIds.toString();
            forecast.channelIds = this.dropdownObjects.channelIds.toString();
            forecast.calendarIds = this.dropdownObjects.calendarIds.toString();
            forecast.userId = this.storage.get(USER_ID);
            forecast.versionTypeId = this.versionTypeId;
            forecast.fbTemplateId = FB_Template.Retail_Direct;
            forecast.forecastMethodId = 38;
            forecast.seasonalitySourceLevelId = 8;
            forecast.movingAverageWeeksId = 20;
            forecast.userFBForecastId = this.userFBForecastId || 0;
            forecastObject.measureId = calendar.measureId;
            forecastObject.value = calendar.value;
            forecastObject.textValue = calendar.textValue;
            forecastObject.itemIds = calendar.itemId;
            forecastObject.channelIds = calendar.channelId;
            forecastObject.calendarIds = calendar.calendarId.toString();
            forecast.fbweek = JSON.parse(JSON.stringify(forecastObject));
            models.push(forecast);
          });
          models.forEach((m) => {
            tasks.push(this.configurationService.FbForecastSpreadingAdd(m));
          });
          forkJoin(tasks).subscribe(
            (res) => {
              const updatedValues = {
                itemIds:
                  this.selectedItemIds.length > 0 &&
                  this.selectedItemIds.find((el) => el)
                    ? this.selectedItemIds
                    : this.dropdownObjects.itemIds,
                channelIds:
                  this.selectedChannelIds.length > 0 &&
                  this.selectedChannelIds.find((el) => el)
                    ? this.selectedChannelIds
                    : this.dropdownObjects.channelIds,
                calendarIds: this.dropdownObjects.calendarIds,
                isSessionUpdate: true,
              };
              this.updateGridList.emit(updatedValues);
              this.toastr.success(
                'Direct Inventory Planner',
                'Measures data imported successfully'
              );
              this.spinner.hide();
            },
            (error) => {
              this.spinner.hide();
            }
          );
        }
      });
  }
  // import() {
  //   this.dialog
  //     .open(ImportPlannerComponent, {
  //       width: '1200px',
  //       panelClass: 'fb-dialog-wrapper',

  //       data: {
  //         forecastList: this.forecastList,
  //         gridListUI: this.gridListUI,
  //         dropdownObjects: this.dropdownObjects,
  //         userFBForecastId: this.userFBForecastId || 0,
  //         itemsList: this.itemsList,
  //         accountList: this.accountList,
  //         measureList: this.measureList,
  //         calendarList: this.calendarList,
  //         versionTypeId: this.versionTypeId,
  //         fbForecastSelectedId: this.fbForecastSelectedId,
  //         fbTemplateId: FB_Template.Global_Planning,
  //         isDirectInventory: true,
  //       },
  //     })
  //     .afterClosed()
  //     .subscribe((command) => {
  //       this.configurationService.openImportDialog.next(false);
  //       if (command && command.list.length > 0 && command.isDirectInventory) {
  //         let forecastObject = {
  //           measureId: 0,
  //           calendarIds: '',
  //           itemIds: this.selectedItemIds.toString(),
  //           channelIds: this.selectedChannelIds.toString(),
  //           value: 0,
  //           textValue: '',
  //           versionTypeId: this.versionTypeId,
  //           fbTemplateId: FB_Template.Retail_Direct,
  //           userId: this.storage.get(USER_ID),
  //           userFBForecastId: this.userFBForecastId || 0,
  //         };
  //         let tasks = [];
  //         let models = [];

  //         let forecast = this.setForecast();
  //         command.list.forEach((calendar) => {
  //           if(forecast.fbWeeks.length % 1000 === 0){
  //             forecast = this.setForecast();
  //             models.push(forecast);
  //           }
  //           forecastObject.measureId = calendar.measureId;
  //           forecastObject.value = calendar.value;
  //           forecastObject.textValue = calendar.textValue;
  //           forecastObject.itemIds = calendar.itemId;
  //           forecastObject.channelIds = calendar.channelId;
  //           forecastObject.calendarIds = calendar.calendarId.toString();

  //           forecast.fbWeeks.push(JSON.parse(JSON.stringify(forecastObject)));
  //         });

  //         models.forEach((m) => {
  //           tasks.push(this.configurationService.FbForecastSpreadingAddList(m));
  //         });
  //         forkJoin(tasks).subscribe(
  //           (res) => {
  //             const updatedValues = {
  //               itemIds:
  //                 this.selectedItemIds.length > 0 &&
  //                 this.selectedItemIds.find((el) => el)
  //                   ? this.selectedItemIds
  //                   : this.dropdownObjects.itemIds,
  //               channelIds:
  //                 this.selectedChannelIds.length > 0 &&
  //                 this.selectedChannelIds.find((el) => el)
  //                   ? this.selectedChannelIds
  //                   : this.dropdownObjects.channelIds,
  //               calendarIds: this.dropdownObjects.calendarIds,
  //               isSessionUpdate: true,
  //             };
  //             this.updateGridList.emit(updatedValues);
  //             this.toastr.success(
  //               'Direct Inventory Planner',
  //               'Measures data imported successfully'
  //             );
  //             this.spinner.hide();
  //           },
  //           (error) => {
  //             this.spinner.hide();
  //           }
  //         );
  //       }
  //     });
  // }
  private setForecast(): FbForecast {
    let forecast = new FbForecast(null);
    forecast.itemIds = this.dropdownObjects.itemIds.toString();
    forecast.channelIds = this.dropdownObjects.channelIds.toString();
    forecast.calendarIds = this.dropdownObjects.calendarIds.toString();
    forecast.userId = this.storage.get(USER_ID);
    forecast.versionTypeId = this.versionTypeId;
    forecast.fbTemplateId = FB_Template.Retail_Direct;
    forecast.forecastMethodId = 38;
    forecast.seasonalitySourceLevelId = 8;
    forecast.movingAverageWeeksId = 20;
    forecast.userFBForecastId = this.userFBForecastId || 0;
    forecast.fbWeeks = [];
    return forecast;
  }
  openMesuresDialog() {
    this.profileMeasureList = JSON.parse(
      JSON.stringify(
        this.masterMeasureList.map((row) => ({ ...row, checked: false }))
      )
    );
    this.dialog
      .open(InventoryPlannerMeasureProfileComponent, {
        width: '1200px',
        panelClass: 'fb-dialog-wrapper',

        data: { measureList: this.profileMeasureList },
      })
      .afterClosed()
      .subscribe((command) => {
        if (command) {
          if (command.isCreateProfile) {
            this.selectedProfile = command;
            this.dialog
              .open(ForecastConfirmationModalComponent, {
                width: '700px',
                data: {
                  label: 'What would you like to name your profile?',
                  isOnLoad: false,
                  changeNameConfirmation: true,
                  isInputRequired: true,
                  inputLabel: 'Enter name',
                },
                disableClose: true,
              })
              .afterClosed()
              .subscribe((command) => {
                if (command.isNameSave) {
                  let selectedMeasures =
                    this.selectedProfile.measureList.filter((x) => x.checked);
                  let measureIds = '';
                  selectedMeasures.forEach((element) => {
                    measureIds = measureIds + element.measureId + ',';
                  });
                  measureIds = measureIds.slice(0, -1);
                  let model = {
                    fbMeasureProfileId: 0,
                    measureIds: measureIds,
                    fbMeasureProfileName: command.name,
                    userId: this.storage.get(USER_ID),
                    applied: this.selectedProfile.isFirst,
                  };
                  this.configurationService
                    .FbForecastSaveMeasureProfile(model)
                    .subscribe((resp) => {
                      let selectedMeasures =
                        this.selectedProfile.measureList.filter(
                          (x) => x.checked
                        );
                      this.measureList = selectedMeasures.sort(
                        (a, b) => a.sort - b.sort
                      );
                      const parentMeasures = this.masterMeasureList.filter(
                        (row) => !row.parentId
                      );
                      this.leftGroupList = parentMeasures.map((row) => ({
                        ...row,
                        rowspan: this.measureList.filter(
                          (measure) =>
                            measure.parentId == row.measureId && measure.checked
                        ).length,
                      }));
                      this.measureList = this.measureList
                        .sort((a, b) => a.sort - b.sort)
                        .filter((row) => row.parentId)
                        .map((row) => ({ ...row, checked: true }))
                        .sort((a, b) => a.sort - b.sort);
                      this.filterLeftGroups();
                      this.cdr.detectChanges();
                    });
                }
              });
          }
          if (command.profileApplied) {
            this.selectedProfile = command;
            let selectedMeasures = this.selectedProfile.measureList.filter(
              (x) => x.checked
            );
            if (selectedMeasures.length)
              this.measureList = selectedMeasures.sort(
                (a, b) => a.sort - b.sort
              );
            else this.measureList = this.savedMeasures;
            const parentMeasures = this.masterMeasureList.filter(
              (row) => !row.parentId
            );
            this.leftGroupList = parentMeasures.map((row) => ({
              ...row,
              rowspan: this.measureList.filter(
                (measure) =>
                  measure.parentId == row.measureId && measure.checked
              ).length,
            }));
            this.measureList = this.measureList
              .sort((a, b) => a.sort - b.sort)
              .filter((row) => row.parentId)
              .map((row) => ({ ...row, checked: true }))
              .sort((a, b) => a.sort - b.sort);
            this.filterLeftGroups();
            this.cdr.detectChanges();
          }
          if (command.maxCountReached) {
            this.dialog.open(ForecastConfirmationModalComponent, {
              width: '700px',
              panelClass: 'fb-dialog-wrapper',
              data: {
                label:
                  'Max profile creation limit has been reached. You can create only 20 profiles',
                isOnLoad: false,
                confirmation: true,
              },
              disableClose: true,
            });
          }
        }
      });
  }
  public createUIList = (flag: boolean, selected?: string, currentYear?) => {
    const yearValues = this.gridData.map((item) => item.title);
    const list = [];
    let treeList = CreateTimeframeUIList(this.yearListGrid);
    treeList.forEach((year) => {
      if (this.gridData.length == 0) {
        year.isSelected = true;
        year.isYear = true;
        year.yearValue = year.title;
        this.collapseYear(year);
      } else {
        year.isYear = true;
        year.yearValue = year.title;
        year.isSelected = yearValues.includes(year.title) ? true : false;
        if (year.isSelected) {
          this.collapseYear(year);
        }
      }
      list.push(year);
      year.halfList.forEach((half) => {
        half.isHalf = true;
        half.yearValue = year.title;
        half.isSelected = yearValues.includes(half.title) ? true: false;
        if (half.isSelected) {
          this.collapseHalf(half);
        }
        list.push(half);
        half.quarterList.forEach((quarter) => {
          quarter.half = half.title;
          quarter.yearValue = year.title;
          quarter.isQuarter = true;
          quarter.isSelected = yearValues.includes(quarter.title) ? true: false;
          if (quarter.isSelected) {
            this.collapseQuarter(quarter);
          }
          list.push(quarter);
          quarter.monthList.forEach((month) => {
            month.quarter = quarter.title;
            month.yearValue = year.title;
            month.isSelected = yearValues.includes(month.title) ? true: false;
            month.isMonth = true;
            if (month.isSelected) {
              this.collapseMonth(month);
            }
            list.push(month);
            month.weekList.forEach((week) => {
              week.weekCount = week.weekCount > 0 ? week.weekCount : 0;
              week.weekCount += month.weekList.length;
              week.month = month.title;
              week.yearValue = year.title;
              week.isWeek = true;
              week.isSelected = yearValues.includes(week.title)
                  ? true
                  : false;
              list.push(week);
            });
          });
        });
      });
    });
    this.oldList = this.gridListUI;
    this.gridListUI = list;
    this.spinner.hide();
    if (document.getElementById('forcast-grid')) {
      this.cdr.detectChanges();
      document.getElementById('forcast-grid').click();
    }
    this.configurationService.tempForecastList = this.gridListUI;
  };
  public checkLastYearMeasures = () => {
    if (this.lastYearCalendarList.length > 0) {
      const measureCodes = [
        'LYActualST',
        'LYPromoIndicator',
        'LYCPFRComments',
        'LYCustomerInStockEstimate',
      ];
      const measureIds = this.measureList
        .filter((row) => measureCodes.includes(row.code))
        .map((el) => el.measureId);
      const calendarIds = this.lastYearCalendarList
        .filter((row, index, self) => self.indexOf(row) === index)
        .map((row) => row.calendarId);
      const model = {
        itemIds: this.dropdownObjects.itemIds.toString(),
        channelIds: this.dropdownObjects.channelIds.toString(),
        calendarIds: calendarIds.toString(),
        userId: this.storage.get(USER_ID),
        measureIds: measureIds.toString(),
        userFBForecastId: this.userFBForecastId || 0,
        fbTemplateId: FB_Template.Retail_Direct,
        fbForecastSelectedId: this.fbForecastSelectedId,
      };
      this.configurationService
        .FbForecastMasterRefreshGet(model)
        .subscribe((res) => {
          const forecasts = res.fbForecasts;
          this.configurationService.tempForecastList.forEach(
            (el, forcastIndex) => {
              const index = forecasts.findIndex(
                (row) =>
                  row.measureId === el.measureId &&
                  row.channelId === el.channelId &&
                  row.itemId === el.itemId &&
                  row.calendarId === el.calendarId
              );
              if (index > -1) {
                this.configurationService.tempForecastList[forcastIndex].value =
                  forecasts[index].value;
              }
            }
          );
        });
    }
    this.cdr.detectChanges();
  };
  selectAllSelected(flag: boolean): void {
    if (flag) {
      this.measureList = this.measureList.map((row) => ({
        ...row,
        checked: true,
      }));
    } else {
      this.measureList = this.measureList.map((row) => ({
        ...row,
        checked: false,
      }));
    }
  }
  public onSave = () => {
    this.dialogRef.close();
    let groups = JSON.parse(JSON.stringify(this.tempGroupList));
    const list = this.measureList.filter((row) => row.checked);
    groups.forEach((group) => {
      group.rowspan = list.filter((el) => el.tag === group.name).length;
      if (group.name === 'ST Accuracy' && group.rowspan > 0) {
        // group.rowspan = group.rowspan + 1;
      }
    });
    groups = groups.filter((group) => group.rowspan);
    this.leftGroupList = groups;
  };
  openFullScreen = (id: string) => {
    const domGrid = document.getElementById(id) as any;
    if (domGrid) {
      domGrid.webkitRequestFullscreen();
    }
  };
  export() {
    let exportList = [];
    const currentUIList = JSON.parse(JSON.stringify(this.gridData));
    const currentItem = this.itemsList.find(
      (row) => row.itemManagerId == +this.invItemIds.toString()
    );
    const currentAccount = this.accountList.find(
      (row) => row.itemAccountId == +this.invChannelIds.toString()
    );
    this.measureList.forEach((measure) => {
      if (currentItem) {
        const exportObject = {
          measureName: measure.measureName,
          measureCode: measure.code,
          measureId: measure.measureId,
          itemName: currentItem.sku,
          description: currentItem.description,
          channelId: currentAccount.itemAccountId,
          account: currentAccount.salesforceId,
          accountName: currentAccount.accountName,
          value: 0,
          textValue: '',
          itemId: currentItem.itemManagerId,
        };
        exportList.push(exportObject);
      }
    });
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('RETAIL_INDIRECT');

    const columns = [
      {
        header: 'MeasureName',
        key: 'measureName',
        width: 50,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'SKU',
        key: 'itemName',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'Description',
        key: 'description',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'AccountName',
        key: 'accountName',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'Account',
        key: 'account',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
    ];
    this.gridData.forEach((el) => {
      const calendar = {
        header: el.title,
        key: el.title,
        width: 20,
        style: {
          font: { bold: true },
          numFmt: '#,##',
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
        },
      };
      columns.push(calendar as any);
    });
    let commulativeList = [];
    exportList.forEach((el) => {
      currentUIList.forEach((week) => {
        if (el.measureCode.indexOf('Comment') < 0) {
          if (el.measureCode.indexOf('Pct') > -1) {
            if (week['measures'][el.measureCode] !== 'Multiple %') {
              week['measures'][el.measureCode] = +week['measures'][el.measureCode].toString().replaceAll(',', '').replaceAll('%', '');
              el[week.title] = week['measures'][el.measureCode] + '%';
            } else {
              el[week.title] = week['measures'][el.measureCode];
            }
          } else {
            el[week.title] = Math.round(Number(week['measures'][el.measureCode].toString().replaceAll(',', ''))
            );
          }
        } else if (el.measureCode.indexOf('Comment') > 0) {
          el[week.title] = week['measures'][el.measureCode];
        }
      });
    });
    commulativeList = [...exportList];
    worksheet.columns = columns as any;
    commulativeList.forEach((e) => {
      worksheet.addRow(e, 'n');
    });
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, `Forecast${new Date().toISOString()}.xlsx`);
    });
  }

  getWidth = () => {
    return window.screen.width - 550 + 'px';
  };
  checkIfInList = (key: string) => {
    return this.measureList.find((el) => el.code === key && el.checked);
  };
  checkIfToShow = (index) => {
    if (index > -1 && index + 1 < this.measureList.length) {
      return (
        this.measureList[index].parentId !==
          this.measureList[index + 1].parentId &&
        this.measureList.find(
          (el) => el.checked && this.measureList[index].parentId === el.parentId
        )
      );
    }
  };
  checkIfToShowColumn(value) {
    return (
      true ||
      (this.measureList &&
        this.measureList.find((el) => el.tag === value && el.checked))
    );
  }
  displayFn(week): string {
    return week && week.title ? week.title : '';
  }
  trackyFn(item) {
    return item.calendarId || item.title;
  }
  public mapCummulatedObject = (element, key, week) => {
    element.measures[key] =
      key.indexOf('Comment') > -1 || this.spreadEqualMeasures.includes(key)
        ? element.measures[key]
        : +element.measures[key].toString().replaceAll(',', '');
    if (this.spreadEqualMeasures.includes(key)) {
      if (key.indexOf('Pct') > 0) {
        element.measures[key] = week.measures[key];
      } else {
        element.measures[key] =
          element.measures[key] > week.measures[key]
            ? element.measures[key]
            : week.measures[key];
      }
    } else if (
      key.indexOf('Comment') < 0 &&
      !this.lowHeirarichyMeasures.includes(key) &&
      key.indexOf('Pct') < 0
    ) {
      element.measures[key] += +(week.measures[key] || 0)
        .toString()
        .replaceAll(',', '');
      element.measures[key] = Math.round(Number(element.measures[key]));
    } else if (key.indexOf('Comment') > 0) {
      element.measures[key] = week.measures[key];
    }
    if (
      key === 'AccountEndingonHandInventory' ||
      key === 'DistiEndingonHandInventory'
    ) {
      if (new Date() >= new Date(week.title)) {
        element.measures[key] = week.measures[key];
      }
    }
    const measures = ['AccountConstrainedFWOS', 'AccountTargetFWOS'];
    if (measures.includes(key)) {
      element['measures'][key] = Number(
        Number(element['measures'][key]).toFixed(2)
      );
    }
  };
  valueChange = (value, column, fieldCode) => {
    column.calendarIds = this.getCalendarIds(column);
    let forecast = new FbForecast(null);
    forecast.itemIds = this.dropdownObjects.itemIds.toString();
    forecast.channelIds = this.dropdownObjects.channelIds.toString();
    forecast.calendarIds = this.dropdownObjects.calendarIds.toString();
    forecast.userId = this.storage.get(USER_ID);
    forecast.versionTypeId = this.versionTypeId;
    forecast.fbTemplateId = FB_Template.Retail_Direct;
    forecast.forecastMethodId = 38;
    forecast.seasonalitySourceLevelId = 8;
    forecast.movingAverageWeeksId = 20;
    forecast.userFBForecastId = this.userFBForecastId || 0;
    const measure = this.measureList.find((m) => m.code === fieldCode);
    if (
      (this.selectedItemIds && this.selectedItemIds.length < 1) ||
      this.selectedItemIds.findIndex((el) => !el) > -1
    ) {
      this.selectedItemIds = this.dropdownObjects.itemIds;
    }
    if (
      (this.selectedChannelIds && this.selectedChannelIds.length < 1) ||
      this.selectedChannelIds.findIndex((el) => !el) > -1
    ) {
      this.selectedChannelIds = this.dropdownObjects.channelIds;
    }
    let weeks = [];
    if (column.isWeek) {
      weeks = this.gridListUI.filter(
        (row) =>
          row.isWeek &&
          row.yearValue === column.yearValue &&
          row.calendarId === column.calendarId
      );
    } else if (column.isMonth) {
      weeks = this.gridListUI.filter(
        (row) =>
          row.isWeek &&
          row.month === column.title &&
          row.fy === column.yearValue
      );
    } else if (column.isQuarter) {
      weeks = this.gridListUI.filter(
        (row) =>
          row.isWeek &&
          row.quarter === column.title &&
          row.fy === column.yearValue
      );
    } else if (column.isHalf) {
      weeks = this.gridListUI.filter(
        (row) =>
          row.isWeek && row.hy === column.title && row.fy === column.yearValue
      );
    } else if (column.isYear) {
      weeks = this.gridListUI.filter(
        (row) => row.isWeek && row.fy === column.yearValue
      );
    }
    let updatedValue;
    if (fieldCode.indexOf('Comment') < 0) {
      value = +value.toString().replaceAll(',', '').replaceAll('%', '')
      if (fieldCode.endsWith('Pct')) {
        if (value < -100) {
          value = -100;
          column['measures'][fieldCode] = -100+'%';
        }
      }
    }
    updatedValue = value;
    let forecastObject = {
      measureId: measure.measureId,
      calendarIds: weeks.map((el) => el.calendarId).toString(),
      itemIds: this.invItemIds.toString(),
      channelIds: this.invChannelIds.toString(),
      value: fieldCode.indexOf('Comment') < 0 ? updatedValue : 0,
      textValue: fieldCode.indexOf('Comment') > -1 ? updatedValue : '',
      versionTypeId: this.versionTypeId,
      fbTemplateId: FB_Template.Retail_Direct,
      userId: this.storage.get(USER_ID),
      userFBForecastId: this.userFBForecastId || 0,
    };
    forecast.fbweek = forecastObject;
    this.configurationService
      .FbForecastSpreadingAdd(forecast)
      .subscribe((res) => {
        if (res) {
          if (!this.isRestoreSession && !this.isOpenSaved) {
            this.sessionUpdate = true;
          }
          weeks.forEach((week) => {
            const index = this.fbForecast.weeks.findIndex(
              (row) => row.calendarId === week.calendarId
            );
            if (index > -1) {
              this.fbForecast.weeks[index]['measures'][fieldCode] =
                updatedValue;
            }
          });
          this.updateWeekValues(fieldCode, updatedValue, column, res.weeks);
          setTimeout(() => {
            this.cdr.detectChanges();
            document.getElementById('forcast-grid').click();
          }, 1);
          this.configurationService.yearListUpdated.next(this.gridListUI);
        }
      });
  };
  public calculateForecastMeasures(fieldCode, column) {
    if (
      column['measures'][fieldCode] &&
      column['measures'][fieldCode].toString() == '-'
    ) {
      return;
    }
    if (
      fieldCode.indexOf('Pct') > -1 &&
      column['measures'][fieldCode] !== 'Multiple %'
    ) {
      column['measures'][fieldCode] = column['measures'][fieldCode].toString().replaceAll('%', '');
    }
    if (
      fieldCode.indexOf('Pct') > -1 &&
      column['measures'][fieldCode] !== 'Multiple %'
    ) {
      column['measures'][fieldCode] = Math.round( Number(column['measures'][fieldCode])) + '%';
    }
    this.cdr.detectChanges();
  }
  public updateWeekValues = (keyCode, value, column, res) => {
    const calendarIds = [];
    this.calendarIds.forEach((element) => {
      element.forEach((id) => {
        calendarIds.push(id);
      });
    });
    const index = this.gridListUI.findIndex((row) => row === column);
    if (this.gridListUI[index].isYear) {
      this.gridListUI[index].halfList.forEach((half) => {
        half.quarterList.forEach((quarter) => {
          quarter.monthList.forEach((month) => {
            month.weekList.forEach((week) => {
              const changedWeek = res.find(
                (row) => row.calendarId === week.calendarId
              );
              if (keyCode.indexOf('Comment') < 0) {
                week['measures'][keyCode] = this.addCommas(
                  changedWeek['measures'],
                  keyCode
                );
              } else {
                week['measures'][keyCode] = changedWeek['measures'][keyCode];
              }
              if (
                keyCode === 'GDPBaselineFcst' ||
                keyCode == 'GDPPromoUnits' ||
                keyCode == 'GDPManualFcstAdjustment' ||
                keyCode == 'GDPManualFcstAdjustmentPct' ||
                keyCode.indexOf('Pct') > -1
              ) {
                this.calculateForecastMeasures(keyCode, week);
              }
            });
          });
        });
      });
    } else if (this.gridListUI[index].isHalf) {
      this.gridListUI[index].quarterList.forEach((quarter) => {
        quarter.monthList.forEach((month) => {
          month.weekList.forEach((week) => {
            const changedWeek = res.find(
              (row) => row.calendarId === week.calendarId
            );
            if (keyCode.indexOf('Comment') < 0) {
              week['measures'][keyCode] = this.addCommas(
                changedWeek['measures'],
                keyCode
              );
            } else {
              week['measures'][keyCode] = changedWeek['measures'][keyCode];
            }
            if (
              keyCode === 'GDPBaselineFcst' ||
              keyCode == 'GDPPromoUnits' ||
              keyCode == 'GDPManualFcstAdjustment' ||
              keyCode == 'GDPManualFcstAdjustmentPct' ||
              keyCode.indexOf('Pct') > -1
            ) {
              this.calculateForecastMeasures(keyCode, week);
            }
          });
        });
      });
    } else if (this.gridListUI[index].isQuarter) {
      this.gridListUI[index].monthList.forEach((month) => {
        month.weekList.forEach((week) => {
          const changedWeek = res.find(
            (row) => row.calendarId === week.calendarId
          );
          if (keyCode.indexOf('Comment') < 0) {
            week['measures'][keyCode] = this.addCommas(
              changedWeek['measures'],
              keyCode
            );
          } else {
            week['measures'][keyCode] = changedWeek['measures'][keyCode];
          }
          if (
            keyCode === 'GDPBaselineFcst' ||
            keyCode == 'GDPPromoUnits' ||
            keyCode == 'GDPManualFcstAdjustment' ||
            keyCode == 'GDPManualFcstAdjustmentPct' ||
            keyCode.indexOf('Pct') > -1
          ) {
            this.calculateForecastMeasures(keyCode, week);
          }
        });
      });
    } else if (this.gridListUI[index].isMonth) {
      this.gridListUI[index].weekList.forEach((week) => {
        const changedWeek = res.find(
          (row) => row.calendarId === week.calendarId
        );
        if (keyCode.indexOf('Comment') < 0) {
          week['measures'][keyCode] = this.addCommas(
            changedWeek['measures'],
            keyCode
          );
        } else {
          week['measures'][keyCode] = changedWeek['measures'][keyCode];
        }
        if (
          keyCode === 'GDPBaselineFcst' ||
          keyCode == 'GDPPromoUnits' ||
          keyCode == 'GDPManualFcstAdjustment' ||
          keyCode == 'GDPManualFcstAdjustmentPct' ||
          keyCode.indexOf('Pct') > -1
        ) {
          this.calculateForecastMeasures(keyCode, week);
        }
      });
    } else if (this.gridListUI[index].isWeek) {
      const changedWeek = res.find(
        (row) => row.calendarId === this.gridListUI[index].calendarId
      );
      if (keyCode.indexOf('Comment') < 0) {
        this.gridListUI[index]['measures'][keyCode] = this.addCommas(
          changedWeek['measures'],
          keyCode
        );
      } else {
        this.gridListUI[index]['measures'][keyCode] =
          changedWeek['measures'][keyCode];
      }
      if (
        keyCode === 'GDPBaselineFcst' ||
        keyCode == 'GDPPromoUnits' ||
        keyCode == 'GDPManualFcstAdjustment' ||
        keyCode == 'GDPManualFcstAdjustmentPct' ||
        keyCode.indexOf('Pct') > -1
      ) {
        this.calculateForecastMeasures(keyCode, this.gridListUI[index]);
      }
    }
  };
  collapseYear = (year: PhysicalYear) => {
    const object = {
      ...year.measures,
    };
    year.halfList.forEach((half) => {
      half.quarterList.forEach((quarter) => {
        quarter.monthList.forEach((month) => {
          this.measureKeys.forEach((key) => {
            if (key.indexOf('Comment') < 0) {
              year.measures[key] = 0;
              month.measures[key] = 0;
              quarter.measures[key] = 0;
              half.measures[key] = 0;
            } else {
              year.measures[key] = '';
              month.measures[key] = '';
              quarter.measures[key] = '';
              half.measures[key] = '';
            }
          });
        });
      });
    });
    year.halfList.forEach((half) => {
      half.quarterList.forEach((quarter) => {
        quarter.monthList.forEach((month) => {
          month.weekList.forEach((week) => {
            this.measureKeys.forEach((key) => {
              if (key.indexOf('Pct') > -1) {
                this.calculateForecastMeasures(key, week);
              }
              this.mapCummulatedObject(month, key, week);
              this.mapCummulatedObject(quarter, key, week);
              this.mapCummulatedObject(half, key, week);
              this.mapCummulatedObject(year, key, week);
              if (this.lowHeirarichyMeasures.includes(key)) {
                month['measures'][key] = week.measures[key];
                quarter['measures'][key] = month.measures[key];
                half['measures'][key] = quarter.measures[key];
                year['measures'][key] = half.measures[key];
              }
            });
          });
        });
      });
    });
    year.halfList.forEach((half) => {
      half.quarterList.forEach((quarter) => {
        quarter.monthList.forEach((month) => {
          this.measureKeys.forEach((key) => {
            if (key.indexOf('Pct') > 0) {
              year['measures'][key] = year.halfList.every(
                (row) => row['measures'][key] == year['measures'][key]
              )
                ? year['measures'][key]
                : 'Multiple %';
              half['measures'][key] = half.quarterList.every(
                (row) => row['measures'][key] == half['measures'][key]
              )
                ? half['measures'][key]
                : 'Multiple %';
              quarter['measures'][key] = quarter.monthList.every(
                (row) => row['measures'][key] == quarter['measures'][key]
              )
                ? quarter['measures'][key]
                : 'Multiple %';
              month['measures'][key] = month.weekList.every(
                (row) => row['measures'][key] == month['measures'][key]
              )
                ? month['measures'][key]
                : 'Multiple %';
            }
            if (key.indexOf('Comment') > 0) {
              year['measures'][key] = year.halfList.every(
                (row) => row['measures'][key] === year['measures'][key]
              )
                ? year['measures'][key]
                : 'Multiple Comments';
              half['measures'][key] = half.quarterList.every(
                (row) => row['measures'][key] === half['measures'][key]
              )
                ? half['measures'][key]
                : 'Multiple Comments';
              quarter['measures'][key] = quarter.monthList.every(
                (row) => row['measures'][key] === quarter['measures'][key]
              )
                ? quarter['measures'][key]
                : 'Multiple Comments';
              month['measures'][key] = month.weekList.every(
                (row) => row['measures'][key] === month['measures'][key]
              )
                ? month['measures'][key]
                : 'Multiple Comments';
            }
            if (
              key.indexOf('Comment') < 0 &&
              !this.spreadEqualMeasures.includes(key)
            ) {
              month['measures'][key] = month['measures'][key]

                .toString()
                .replace(/,/g, '');

              quarter['measures'][key] = quarter['measures'][key]

                .toString()
                .replace(/,/g, '');

              half['measures'][key] = half['measures'][key]

                .toString()
                .replace(/,/g, '');

              year['measures'][key] = year['measures'][key]

                .toString()
                .replace(/,/g, '');

              this.addCommas(year.measures, key);
              this.addCommas(half.measures, key);
              this.addCommas(quarter.measures, key);
              this.addCommas(month.measures, key);
              month.weekList.forEach((week) => {
                this.addCommas(week.measures, key);
              });
            }
          });
        });
      });
    });
    return year;
  };
  collapseHalf = (half: HalfYear): any => {
    const object = {
      ...half.measures,
    };
    half.quarterList.forEach((quarter) => {
      quarter.monthList.forEach((month) => {
        this.measureKeys.forEach((key) => {
          if (key.indexOf('Comment') < 0) {
            month.measures[key] = 0;
            quarter.measures[key] = 0;
            half.measures[key] = 0;
          } else {
            month.measures[key] = '';
            quarter.measures[key] = '';
            half.measures[key] = '';
          }
        });
      });
    });
    half.quarterList.forEach((quarter) => {
      quarter.monthList.forEach((month) => {
        month.weekList.forEach((week) => {
          this.measureKeys.forEach((key) => {
            // this.calculateForecastMeasures(key,week)
            this.mapCummulatedObject(month, key, week);
            this.mapCummulatedObject(quarter, key, week);
            this.mapCummulatedObject(half, key, week);
            if (this.lowHeirarichyMeasures.includes(key)) {
              month['measures'][key] = week.measures[key];
              quarter['measures'][key] = month.measures[key];
              half['measures'][key] = quarter.measures[key];
            }
          });
        });
      });
    });
    half.quarterList.forEach((quarter) => {
      quarter.monthList.forEach((month) => {
        this.measureKeys.forEach((key) => {
          if (key.indexOf('Pct') > 0) {
            half['measures'][key] = half.quarterList.every(
              (row) => row['measures'][key] == half['measures'][key]
            )
              ? half['measures'][key]
              : 'Multiple %';
            quarter['measures'][key] = quarter.monthList.every(
              (row) => row['measures'][key] == quarter['measures'][key]
            )
              ? quarter['measures'][key]
              : 'Multiple %';
            month['measures'][key] = month.weekList.every(
              (row) => row['measures'][key] == month['measures'][key]
            )
              ? month['measures'][key]
              : 'Multiple %';
          }
          if (key.indexOf('Comment') > 0) {
            half['measures'][key] = half.quarterList.every(
              (row) => row['measures'][key] === half['measures'][key]
            )
              ? half['measures'][key]
              : 'Multiple Comments';
            quarter['measures'][key] = quarter.monthList.every(
              (row) => row['measures'][key] === quarter['measures'][key]
            )
              ? quarter['measures'][key]
              : 'Multiple Comments';
            month['measures'][key] = month.weekList.every(
              (row) => row['measures'][key] === month['measures'][key]
            )
              ? month['measures'][key]
              : 'Multiple Comments';
          }
          if (
            key.indexOf('Comment') < 0 &&
            !this.spreadEqualMeasures.includes(key)
          ) {
            month['measures'][key] = month['measures'][key]

              .toString()
              .replace(/,/g, '');

            quarter['measures'][key] = quarter['measures'][key]

              .toString()
              .replace(/,/g, '');

            half['measures'][key] = half['measures'][key]

              .toString()
              .replace(/,/g, '');
            this.addCommas(half.measures, key);
            this.addCommas(quarter.measures, key);
            this.addCommas(month.measures, key);
            month.weekList.forEach((week) => {
              this.addCommas(week.measures, key);
            });
          }
        });
      });
    });

    return half;
  };
  collapseQuarter = (quarter: Quarter): any => {
    const object = {
      ...quarter.measures,
    };
    quarter.monthList.forEach((month) => {
      this.measureKeys.forEach((key) => {
        if (key.indexOf('Comment') < 0) {
          month.measures[key] = 0;
          quarter.measures[key] = 0;
        } else {
          month.measures[key] = '';
          quarter.measures[key] = '';
        }
      });
    });
    quarter.monthList.forEach((month) => {
      month.weekList.forEach((week) => {
        this.measureKeys.forEach((key) => {
          // this.calculateForecastMeasures(key,week)
          this.mapCummulatedObject(month, key, week);
          this.mapCummulatedObject(quarter, key, week);
          if (this.lowHeirarichyMeasures.includes(key)) {
            month['measures'][key] = week.measures[key];
            quarter['measures'][key] = month.measures[key];
          }
        });
      });
    });
    quarter.monthList.forEach((month) => {
      this.measureKeys.forEach((key) => {
        if (key.indexOf('Pct') > 0) {
          quarter['measures'][key] = quarter.monthList.every(
            (row) => row['measures'][key] == quarter['measures'][key]
          )
            ? quarter['measures'][key]
            : 'Multiple %';
          month['measures'][key] = month.weekList.every(
            (row) => row['measures'][key] == month['measures'][key]
          )
            ? month['measures'][key]
            : 'Multiple %';
        }
        if (key.indexOf('Comment') > 0) {
          quarter['measures'][key] = quarter.monthList.every(
            (row) => row['measures'][key] === quarter['measures'][key]
          )
            ? quarter['measures'][key]
            : 'Multiple Comments';
          month['measures'][key] = month.weekList.every(
            (row) => row['measures'][key] === month['measures'][key]
          )
            ? month['measures'][key]
            : 'Multiple Comments';
        }
        if (
          key.indexOf('Comment') < 0 &&
          !this.spreadEqualMeasures.includes(key)
        ) {
          month['measures'][key] = month['measures'][key]

            .toString()
            .replace(/,/g, '');

          quarter['measures'][key] = quarter['measures'][key]

            .toString()
            .replace(/,/g, '');
          this.addCommas(quarter.measures, key);
          this.addCommas(month.measures, key);
          month.weekList.forEach((week) => {
            this.addCommas(week.measures, key);
          });
        }
      });
    });
    return quarter;
  };
  collapseMonth = (month: Month): any => {
    const object = {
      ...month.measures,
    };
    this.measureKeys.forEach((key) => {
      if (key.indexOf('Comment') < 0) {
        month.measures[key] = 0;
      } else {
        month.measures[key] = '';
      }
    });
    month.weekList.forEach((week) => {
      this.measureKeys.forEach((key) => {
        // this.calculateForecastMeasures(key,week)
        this.mapCummulatedObject(month, key, week);
      });
    });
    this.measureKeys.forEach((key) => {
      if (key.indexOf('Pct') > 0) {
        month['measures'][key] = month.weekList.every(
          (row) => row['measures'][key] == month['measures'][key]
        )
          ? month['measures'][key]
          : 'Multiple %';
      }
      if (key.indexOf('Comment') > 0) {
        month['measures'][key] = month.weekList.every(
          (row) => row['measures'][key] === month['measures'][key]
        )
          ? month['measures'][key]
          : 'Multiple Comments';
      }
      if (
        key.indexOf('Comment') < 0 &&
        !this.spreadEqualMeasures.includes(key)
      ) {
        month['measures'][key] = month['measures'][key]

          .toString()
          .replace(/,/g, '');
        this.addCommas(month.measures, key);
        month.weekList.forEach((week) => {
          this.addCommas(week.measures, key);
          if (this.lowHeirarichyMeasures.includes(key)) {
            month['measures'][key] = week.measures[key];
          }
        });
      }
    });
    return month;
  };
  public getCalendarIds = (column) => {
    let calendarIds = [];
    if (column.isYear) {
      calendarIds = this.calendarList
        .filter((row) => row.fy === column.title)
        .map((el) => el.calendarId);
      return calendarIds;
    } else if (column.isHalf) {
      calendarIds = this.calendarList
        .filter((row) => row.fy === column.yearValue && row.hy === column.title)
        .map((el) => el.calendarId);
      return calendarIds;
    } else if (column.isQuarter) {
      calendarIds = this.calendarList
        .filter((row) => row.fy === column.yearValue && row.hy === column.half)
        .map((el) => el.calendarId);
      return calendarIds;
    } else if (column.isMonth) {
      calendarIds = this.calendarList
        .filter(
          (row) =>
            row.fy === column.yearValue &&
            row.quarter === column.quarter &&
            row.month === column.title
        )
        .map((el) => el.calendarId);
      return calendarIds;
    } else {
      return [column.calendarId];
    }
  };
  showValues = (selected, year, select) => {
    this.collapsedObject = {
      selected: selected,
      year: year,
    };
    if (selected) {
      this.setupLongRunningProcess({
        year: year,
        gridListUI: this.gridListUI,
        selected: selected.value,
      });
    }
  };
  mapGridList(value) {
    this.onClickEvent.emit(value);
  }
  public filterLeftGroups = () => {
    this.leftGroupList = this.leftGroupList.filter((el) => el.rowspan > 0);
  };
  public selectionChanged = (selectedItem) => {
    if (selectedItem.isChannel) {
      this.selectedChannelIds = [selectedItem.value];
    }
    if (selectedItem.isProduct) {
      this.selectedItemIds = [selectedItem.value];
    }
    const updatedValues = {
      itemIds:
        this.selectedItemIds.length > 0 && this.selectedItemIds.find((el) => el)
          ? this.selectedItemIds
          : this.dropdownObjects.itemIds,
      channelIds:
        this.selectedChannelIds.length > 0 &&
        this.selectedChannelIds.find((el) => el)
          ? this.selectedChannelIds
          : this.dropdownObjects.channelIds,
      calendarIds: this.dropdownObjects.calendarIds,
      isSessionUpdate: true,
    };
    this.updateGridList.emit(updatedValues);
    // this.updateUIList(updatedValues);
  };
  getLeafNodes(nodes, result = []) {
    for (var i = 0, length = nodes.length; i < length; i++) {
      if (
        !nodes[i].internalChildren ||
        nodes[i].internalChildren.length === 0
      ) {
        result.push(nodes[i]);
      } else {
        result = this.getLeafNodes(nodes[i].internalChildren, result);
      }
    }
    return result;
  }
  onKeyDown = (event: KeyboardEvent, measureCode: string, week) => {
    return onKeyDown(event, measureCode, week);
  };
  addCommas = (week, field) => {
    return addCommas(week, field);
  };
  openExportDialog() {
    const dropdownObjects = {
      itemIds:
        this.selectedItemIds.length > 0 && this.selectedItemIds.find((el) => el)
          ? this.selectedItemIds
          : [this.invItemIds],
      channelIds:
        this.selectedChannelIds.length > 0 &&
        this.selectedChannelIds.find((el) => el)
          ? this.selectedChannelIds
          : [this.invChannelIds],
      calendarIds: this.dropdownObjects.calendarIds,
    };
    this.dialog
      .open(ExportAllTemplateComponent, {
        width: '1200px',
        panelClass: 'fb-dialog-wrapper',
        data: {
          measureList: this.measureList,
          itemsList: this.itemsList,
          accountList: this.accountList,
          forecastList: this.forecastList,
          calendarList: this.calendarList,
          gridListUI: this.gridListUI,
          dropdownObjects: dropdownObjects,
          userFBForecastId: this.userFBForecastId || 0,
          versionTypeId: this.versionTypeId,
          weeks: this.fbForecast.weeks,
          fbTemplateId: FB_Template.Retail_Direct,
        },
      })
      .afterClosed()
      .subscribe((command) => {
        if (command && command.selectedMeasure) {
        }
      });
  }
  setupLongRunningProcess(evt) {
    this.spinner.show();
    const worker = new InlineWorker(() => {
      // START OF WORKER THREAD CODE\
      function getUIList(event) {

        if (event.selected && event.selected == 'Year') {
          event.gridListUI.forEach((row) => {
              row.isSelected = row.isYear ? true : false;
          });
        } else if (event.selected && event.selected == 'Half Year') {
          event.gridListUI.forEach((row) => {
              row.isSelected = row.isHalf ? true : false;
          });
        }
        if (event.selected && event.selected == 'Quarter') {
          event.gridListUI.forEach((row) => {
            row.isSelected = row.isQuarter ? true : false;
          });
        }
        if (event.selected && event.selected == 'Monthly') {
          event.gridListUI.forEach((row) => {
              row.isSelected = row.isMonth ? true : false;
          });
        } else if (event.selected && event.selected == 'Weekly') {
          event.gridListUI.forEach((row) => {
            row.isSelected = row.isWeek ? true : false;
        });
        }
        return event.gridListUI;
      }
      const CreateUIList = (event) => {
        // this is from DedicatedWorkerGlobalScope ( because of that we have postMessage and onmessage methods )
        // and it can't see methods of this class
        // @ts-ignore

        this.postMessage({
          gridListUI: getUIList(event),
          selected: event.selected,
          year: event.year,
        });
      };

      // @ts-ignore

      this.onmessage = (evt) => {
        CreateUIList(evt.data);
      };
      // END OF WORKER THREAD CODE
    });

    worker.postMessage(evt);

    worker.onmessage().subscribe((data) => {
      this.gridListUI = data.data.gridListUI;
      if (data.data.selected !== 'Weekly') {
        this.gridData.forEach((row) => {
            if (row.isYear) {
              this.collapseYear(row);
            } else if (row.isHalf) {
              this.collapseHalf(row);
            } else if (row.isQuarter) {
              this.collapseQuarter(row);
            } else if (row.isMonth) {
              this.collapseMonth(row);
            }
        });
      }
      this.oldList = this.gridListUI;
      this.spinner.hide();
      worker.terminate();
      this.cdr.detectChanges();
    });
    worker.onerror().subscribe((data) => {
      console.log(data);
    });
  }
  setFocus = (index, field, measureIndex) => {
    this.selectedList.forEach((row) => {
      row.field.style.background = 'rgb(238, 238, 238)';
    });
    this.selectedList = [];
    this.selectedOperationList = [];
    setFocusOnNextInput(
      index,
      field,
      this.gridData,
      this.measureList,
      measureIndex
    );
  };
  onPaste(event, week, measureCode) {
    let clipboardData = event.clipboardData || (window as any).clipboardData;
    event = event || window.event; // Event object 'ev'
    var key = event.which || event.keyCode; // Detecting keyCode

    // Detecting Ctrl
    var ctrl = event.ctrlKey
      ? event.ctrlKey
      : key == 17 || key == 91
      ? true
      : false;
    ctrl = ctrl || event.metaKey;

    // If key pressed is V and if ctrl is true.
    if (key == 86 && ctrl) {
      navigator.clipboard
        .readText()
        .then((pastedText: string) => {
          pastedText = (pastedText as any).replaceAll('\r', '');
          pastedText = (pastedText as any).replaceAll('\n', '');
          if (this.selectedList.length > 1) {
            this.selectedList.forEach((row) => {
              row.field.style.background = 'rgb(238, 238, 238)';
              this.valueChange(pastedText, row.week, measureCode);
            });
            this.selectedList = [];
          } else if (pastedText && pastedText.split('\t').length > 0) {
            const uiListIndex = this.gridData.findIndex(
              (row) => row.title == week.title
            );
            if (pastedText) {
              const values = pastedText.trim().split('\t');
              for (let index = 0; index < values.length; index++) {
                if (this.gridData[uiListIndex + index]) {
                  this.gridData[uiListIndex + index]['measures'][measureCode] =
                    values[index];
                  this.valueChange(
                    this.gridData[uiListIndex + index]['measures'][measureCode],
                    this.gridData[uiListIndex + index],
                    measureCode
                  );
                  this.calculateForecastMeasures(
                    measureCode,
                    this.gridData[uiListIndex + index]
                  );
                }
              }
            }
          }
        })
        .catch((err) => {
          console.error('Failed to read clipboard contents: ', err);
        });
    } else if (key == 67 && ctrl) {
      if (this.selectedList.length > 0) {
        let value = '';
        this.selectedList.forEach((row) => {
          value += row.week['measures'][row.measure] + '\t';
          row.field.style.background = 'rgb(238, 238, 238)';
        });
        navigator.clipboard.writeText(value);
        this.selectedList = [];
        this.toastr.success('Success', 'Values copied!');
      } else {
        navigator.clipboard.writeText(week['measures'][measureCode]);
      }
    } else if ((key == 46 || key == 8) && this.selectedList.length > 0) {
      this.selectedList.forEach((row) => {
        row.field.style.background = 'rgb(238, 238, 238)';
        if (row.measure.indexOf('Comment') > 0) {
          row.week['measures'][measureCode] = '';
        } else {
          row.week['measures'][measureCode] = 0;
        }
        this.valueChange(
          row.week['measures'][measureCode],
          row.week,
          measureCode
        );
        this.selectedList = [];
      });
    }
    return true;
  }
  markYearsSelected(evt) {
    this.spinner.show();
    const worker = new InlineWorker(() => {
      // START OF WORKER THREAD CODE\
      function getUIList(event) {
        event.gridListUI.forEach((el) => {
          if (
            event.oldList.find(
              (row) => row.title === el.title && row.isSelected
            )
          ) {
            el.isSelected = true;
          }
        });
        if (!event.gridListUI.find((row) => row.isSelected)) {
          event.gridListUI = event.gridListUI.map((el) => {
            return {
              ...el,
              isSelected: el.isYear ? true : false,
            };
          });
        }
        return event.gridListUI;
      }
      const CreateUIList = (event) => {
        // this is from DedicatedWorkerGlobalScope ( because of that we have postMessage and onmessage methods )
        // and it can't see methods of this class
        // @ts-ignore

        this.postMessage({
          gridListUI: getUIList(event),
        });
      };

      // @ts-ignore

      this.onmessage = (evt) => {
        CreateUIList(evt.data);
      };
      // END OF WORKER THREAD CODE
    });

    worker.postMessage(evt);

    worker.onmessage().subscribe((data) => {
      this.gridListUI = data.data.gridListUI;
      this.spinner.hide();
      worker.terminate();
      this.cdr.detectChanges();
    });
    worker.onerror().subscribe((data) => {
      console.log(data);
    });
  }
  checkIfMousedown = (e, week, measure, field: HTMLInputElement) => {
    if (this.configurationService.isMouseDown) {
      if (this.selectedList.find((row) => row.measure === measure)) {
        const fields = {
          week,
          measure,
          field,
        };
        const currentWeekIndex = this.selectedList.findIndex(
          (row) => row.week.title === week.title
        );
        if (currentWeekIndex === -1) {
          this.selectedList.push(fields);
          field.style.background = '#87CEFA';
          field.select();
        } else if (currentWeekIndex !== -1) {
          let sumIndex = 0;
          if (this.selectedList.length > 1) {
            sumIndex = 1;
          }
          this.selectedList[
            currentWeekIndex + sumIndex
          ].field.style.background = 'rgb(238, 238, 238)';
          this.selectedList.splice(currentWeekIndex + sumIndex, 1);
        }
      } else if (this.selectedList.length === 0) {
        const fields = {
          week,
          measure,
          field,
        };
        this.selectedList.push(fields);
        field.style.background = '#87CEFA';
        field.select();
      }
    }
  };
  preventMouseDown(event, year, code, field) {
    event.preventDefault();
    this.configurationService.setMousedown(1);
    this.selectedList.forEach((row) => {
      row.field.style.background = 'rgb(238, 238, 238)';
    });
    this.selectedList = [];
    this.checkIfMousedown(event, year, code, field);
  }
  removeSelection = (measureCode: string, week, field) => {
    field.selectionStart = field.selectionEnd =
      week['measures'][measureCode].length;
    this.selectedList = [];
  };
  onInput = () => {
    this.selectedList.forEach((row) => {
      row.field.style.background = 'rgb(238, 238, 238)';
    });
    this.selectedList = [];
  };
}
