import { AllCommunityModules, GridApi } from '@ag-grid-community/all-modules';
import { CurrencyPipe, formatDate } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ToggleButtonRendererComponent } from 'src/common/toggle-button-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { InsightPOPUPComponent } from '../insight-popup/insight-popup.component';
import { InsightFormV2Component } from './insight-form-v2/insight-form-v2.component';
import * as moment from 'moment';
@Component({
  selector: 'app-insight-listing-v2',
  templateUrl: './insight-listing-v2.component.html',
  styleUrls: ['./insight-listing-v2.component.css'],
})
export class InsightListingV2Component implements OnInit {
  public columnDefs = [
    {
      headerName: 'Picture',
      field: 'documentPath',
      minWidth: 50,
      cellRenderer: (params) => {
        return `<img style="width: 40px;
        height: 40px; border-radius: 50%;" src=${
          params.data.documentPath
            ? params.data.documentPath
            : 'assets/images/thumbnails.png'
        }>`;
      },
      resizable: false,
      sortable: false,
      filter: false,
      suppressMenu: true,
    },
    {
      headerName: 'Date',
      field: 'dateGenerated',
      cellRenderer: (params) => {
        return params.value
          ? `${formatDate(params.value, 'dd/MM/yyyy', 'en-US')}`
          : '';
      },
    },
    {
      headerName: 'Insight',
      field: 'insights',
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openDialog.bind(this),
      },
    },
    {
      headerName: 'Retailer',
      field: 'retailerName',
    },
    {
      headerName: 'Country',
      field: 'countryName',
    },
    {
      headerName: 'Value',
      field: 'value',
      cellRenderer: (params) => {
        return params && params.value
          ? this.pipe.transform(params.value, 'USD')
          : '';
      },
    },
    {
      headerName: 'Publish',
      field: 'isPublish',
      pinned: 'right',
      cellRenderer: 'toggleButtonRenderer',
      cellRendererParams: {
        onChange: this.togglePublish.bind(this),
      },
      width: 150,
      resizable: false,
      sortable: false,
      filter: false,
      suppressMenu: true,
    },
    {
      headerName: '',
      field: 'delete',
      pinned: 'right',
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveGoalDialog.bind(this),
      },
      width: 80,
      resizable: false,
      sortable: false,
      filter: false,
      suppressMenu: true,
    },
    // {
    //   headerName: '',
    //   field: 'edit',
    //   resizable: true,
    //   suppressMenu: true,
    //   suppressSorting: true,
    //   pinned: 'right',
    //   cellRenderer: 'buttonRenderer',
    //   cellRendererParams: {
    //     onClick: this.openDialog.bind(this),
    //   },
    //   width: 80
    // }
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: GridApi;
  public insightList = [];
  public insightListGallery = [];
  public modules = AllCommunityModules;
  public user: any;
  headerHeight = 45;
  public isEdit = false;
  public listView = true;
  public icon = 'collections';
  public countryList = [];
  public retailerList = [];
  public categoryList = [];
  public divisionList = [];
  public metricList = [];
  public productList = [];
  public insightTempList = [];
  public statusList = [
    { value: true, statusName: 'Published' },
    { value: false, statusName: 'Not Published' },
  ];
  public activeList = [
    { value: true, statusName: 'Active' },
    { value: false, statusName: 'Expired' },
  ];
  public dateList = [];
  public retailer;
  public country;
  public product;
  public status;
  public date;
  public active;
  public defaultColDef;
  public selectedStartDate;
  public selectedEndDate;
  @ViewChild('dialogRef') dialog: TemplateRef<any>;
  constructor(
    public matDialog: MatDialog,
    public matDialogRef: MatDialogRef<InsightListingV2Component>,
    public dialogPopUpRef: MatDialogRef<InsightPOPUPComponent>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public toastr: NgxToasterService,
    public spinner: NgxSpinnerService,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public pipe: CurrencyPipe
  ) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent,
        toggleButtonRenderer: ToggleButtonRendererComponent,
        nameRenderer: NameRendererComponent,
      },
      pagination: true,
      paginationPageSize: 15,
    };
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true,
    };
  }
  ngOnInit() {
    this.getDefaultDropdowns();
  }
  togglePublish(event) {
    const model = {
      ...event.rowData,
      isPublish: event.event.checked,
    };
    this.configurationService.ApplicationInsightsPublish(model).subscribe(
      (res) => {
        this.toastr.success(
          'Publish',
          'App Insight published status updated successfully'
        );
      },
      (error) => {
        this.toastr.error('Publish', 'App Insight not published successfully');
      }
    );
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.matDialog.open(
      ConfirmationDialogComponent,
      {
        width: '500px',
        data: { headerName: 'Application Insight V2' },
      }
    );

    this.confirmationDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.removeNotification(row.rowData);
      }
    });
  }
  public removeNotification(selectedInsight: any) {
    if (selectedInsight) {
      const model = {
        ...selectedInsight,
        active: false,
      };
      // this.spinner.show();

      this.configurationService.ApplicationInsightDelete(model).subscribe(res => {
        this.toastr.success(
          'Application Insight',
          'Insight removed successfully'
        );
        this.insightList = this.insightList.filter(
          (row) => row.applicationInsightsId !== model.applicationInsightsId
        );
      })

    }
  }
  openDialog(data, templateRef?: TemplateRef<any>): void {
    this.isEdit = data.rowData ? true : false;
    const dialogRef = this.matDialog.open(InsightFormV2Component, {
      width: '80vw',
      data: data.rowData,
      maxHeight: '70vh',
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.getApplicationInsightList();
      }
    });
  }
  openDialogPopUp(data): void {
    this.isEdit = data.rowData ? true : false;
    const dialogRef = this.matDialog.open(InsightPOPUPComponent, {
      data: data,
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.getApplicationInsightList();
      }
    });
  }
  switchView() {
    if (this.listView) {
      this.listView = false;
      this.icon = 'article';
    } else {
      this.listView = true;
      this.icon = 'collections';
    }
  }
  public getDefaultDropdowns = async () => {
    let url = `offSet=${0}&pageSize=${99999}&active=true`;
    this.configurationService.ItemManagerGetAll(url).subscribe((res) => {
      this.productList = res.data;
    });
    this.GetRetailerList();
    this.GetCountryList();
    this.getApplicationInsightList();
  };
  public GetRetailerList = () => {
    this.configurationService
      .AccountSettingMappingChannelGetList({})
      .subscribe((res) => {
        this.retailerList = res;
      });
  };
  public GetCountryList = () => {
    this.configurationService.CountryGetList({}).subscribe((res) => {
      this.countryList = res;
    });
  };
  public getApplicationInsightList = () => {
    this.configurationService.ApplicationInsightsGetAll({}).subscribe((res) => {
      this.insightList = res.filter((el) => el.isPublish);
      this.insightList = this.insightList.map((insight) => ({
        ...insight,
        dimensions: this.mapDimensions(insight),
      }));
      this.insightTempList = JSON.parse(JSON.stringify(this.insightList));
    });
  };
  public mapDimensions = (insight) => {
    const country = this.countryList.find(
      (country) => country.commonCodeValueId === insight.appInsightsCountryId
    )
      ? this.countryList.find(
          (country) =>
            country.commonCodeValueId === insight.appInsightsCountryId
        ).value
      : null;
    const retailer = this.retailerList.find(
      (retailer) => retailer.commonCodeValueId === insight.appInsightsRetailerId
    )
      ? this.retailerList.find(
          (retailer) =>
            retailer.commonCodeValueId === insight.appInsightsRetailerId
        ).value
      : null;
    const category = this.categoryList.find(
      (category) => category.commonCodeValueId === insight.appInsightsCategoryId
    )
      ? this.categoryList.find(
          (category) =>
            category.commonCodeValueId === insight.appInsightsCategoryId
        ).value
      : null;
    const division = this.divisionList.find(
      (division) => division.commonCodeValueId === insight.appInsightsDivisionId
    )
      ? this.divisionList.find(
          (division) =>
            division.commonCodeValueId === insight.appInsightsDivisionId
        ).value
      : null;
    let string = [country, retailer, category, division].toString();
    return string.replace(/[,\s]{2,}/, '');
  };
  public mapStatus = (params) => {
    if (params && params.node.data) {
      if (params.node.data.isExpired) {
        return `Expired`;
      } else if (params.node.data.isPublish) {
        return `Active`;
      } else {
        return `Unpublished`;
      }
    }
  };
  public exportExcel = () => {
    try {
      this.gridApi.exportDataAsExcel();
    } catch {
      this.gridApi.exportDataAsCsv();
    }
  };
  public filterList = () => {
    let list = JSON.parse(JSON.stringify(this.insightList));
    if (this.status !== undefined) {
      list = this.insightTempList.filter((row) => {
        return row.isPublish === this.status;
      });
    }
    if (this.active !== undefined) {
      list = list.filter((row) => {
        return row.active === this.active;
      });
    }
    if (this.retailer !== undefined) {
      list = list.filter((row) => {
        return row.retailerId === this.retailer;
      });
    }
    if (this.country !== undefined) {
      list = list.filter((row) => {
        return row.countryId === this.country;
      });
    }
    if (this.product !== undefined) {
      list = list.filter((row) => {
        return row.productId === this.product;
      });
    }
    if (this.selectedStartDate !== undefined && this.selectedEndDate) {
      list = list.filter((row) => {
        const startDate = this.selectedStartDate.toLocaleDateString();
        const endDate = this.selectedEndDate.toLocaleDateString();
        const generatedDate = new Date(row.dateGenerated).toLocaleDateString();
        return (
          new Date(generatedDate) >= new Date(startDate) &&
          new Date(generatedDate) <= new Date(endDate)
        );
      });
    }
    this.gridApi.setRowData(list);
  };
  resetFilters = () => {
    this.status = undefined;
    this.active = undefined;
    this.retailer = undefined;
    this.country = undefined;
    this.product = undefined;
    this.selectedStartDate = undefined;
    this.selectedEndDate = undefined;
    this.gridApi.setRowData(this.insightList);
  };
}
