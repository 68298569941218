<div class="container-fluid" style="margin-top: 15px;">
    <ng-container *ngFor="let item of gridNames; let i=index">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{item}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="all-show-grid">
                    <ag-grid-angular class="ag-theme-material" [suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups
                        toolPanelSuppressValues [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions"
                        [columnDefs]="gridColumns[i]" [rowData]="dataSources[i]" [pagination]="true"
                        [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
                        [stopEditingWhenGridLosesFocus]="true" [modules]="modules" #planogramGrid>
                    </ag-grid-angular>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <hr class="theme-hr mt-2" />
    </ng-container>
</div>