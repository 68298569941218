import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { TitleFormComponent } from './title-form/title-form.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from 'src/services/User-services/user-services';
@Component({
  selector: 'app-title-listing',
  templateUrl: './title-listing.component.html',
  styleUrls: ['./title-listing.component.css']
})
export class TitleListingComponent implements OnInit {
  public columnDefs = [
    {
      headerName: 'Title ID',
      field: 'titleId',
      pinned: 'left'
    },
    {
      headerName: 'Title Name',
      field: 'titleName',
      pinned: 'left'
    },
    {
      headerName: 'Release Source',
      field: 'releaseSource'
    },
    {
      headerName: 'Working Group',
      field: 'workingGroup'
    },
    {
      headerName: 'Studio',
      field: 'studio'
    },
    {
      headerName: 'Studio',
      field: 'studio'
    },
    {
      headerName: 'Vendor',
      field: 'vendor'
    },
    {
      headerName: 'Rating',
      field: 'rating'
    },
    {
      headerName: 'Theatrical Release Date',
      field: 'releaseDate'
    },
    {
      headerName: 'Street Date',
      field: 'streetDate'
    },
    {
      headerName: 'Pre-Awareness Date',
      field: 'preAwarenessDate'
    },
    {
      headerName: 'Announce Date',
      field: 'announceDate'
    },
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public userList = [];
  public modules = AllCommunityModules;
  public user: any;
  constructor(public router: Router, public userService: UsersService, public dialog: MatDialog, public dialogRef: MatDialogRef<TitleFormComponent>) {
    this.gridOptions = {
      frameworkComponents: {
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    this.getUserList();
  }
  public getUserList = () => {
    const params = {
      active: true
    };
    this.userService.GetAllUsers({ userTypeId: 1 }).subscribe(res => {
      this.userList = (res as any).data;
      this.userList = this.userList.map(user => ({
        ...user,
        roleNames: this.getRoleNames(user.userRoles)
      }));
    });
  }
  public getRoleNames = (roleList: any) => {
    if (roleList && roleList.length > 0) {
      let names = '';
      roleList.forEach(element => {
        names = `${names}, ${element.roleName}`;
      });
      names = names.split(',').splice(1).join(',');
      return names || '';
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  onCellClicked($event) {

    this.router.navigate(['addUser'], {
      queryParams: {
        id: btoa($event.data.userId)
      }
    });
  }
  public navigateToRoles = () => {
    this.router.navigate(['Roles']);
  }
  public openModal = () => {
    this.dialogRef = this.dialog.open(TitleFormComponent, { width: '800px' });
  }
}
