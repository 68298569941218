import { Injectable } from '@angular/core';
import { TreeviewSelection, DefaultTreeviewI18n } from 'ngx-treeview';

@Injectable()
export class MicrosoftDropdownTreeviewSelectI18n extends DefaultTreeviewI18n {
  getText(selection: TreeviewSelection): string {
    if (selection.uncheckedItems.length === 0) {
      return 'All';
    }
    switch (selection.checkedItems.length) {
      case 0:
        return 'Select Timeframe';
      case 1:
        return selection.checkedItems[0].text;
      default:
        return `${selection.checkedItems.length} Weeks Selected`;
    }
  }
  getFilterNoItemsFoundText(): string {
    return 'No Options Found';
  }
}
