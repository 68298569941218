import { Component, OnInit } from '@angular/core';
import { generateYearlyGridColumns } from '../agGridConfig';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { yearlyData } from 'src/modules/merchandise-financial-planning/mockData';
import { MfpService } from 'src/services/mfp-services/mfp.service';

@Component({
  selector: 'app-yearly-planning',
  templateUrl: './yearly-planning.component.html',
  styleUrls: ['./yearly-planning.component.scss']
})
export class YearlyPlanningComponent implements OnInit {

  // Grid Variables
  public gridColumnDefs = generateYearlyGridColumns();
  public modules = AllCommunityModules;
  private gridApi;
  frameworkComponents = {};

  yearlyData = yearlyData;

  dataSource = {
    chart: {
      caption: "Fiscal Year Targets",
      exportenabled: "0",
      drawcrossline: "1",
      yaxisname: "",
      syaxisname: "",
      showvalues: "0",
      showanchors: "0",
      plothighlighteffect: "fadeout",
      theme: "fusion"
    },
    categories: [
      {
        category: [
          {
            label: "FY 2020"
          },
          {
            label: "FY 2021"
          },
          {
            label: "FY 2022"
          }
        ]
      }
    ],
    dataset: [
      {
        seriesname: "Revenue",
        data: [
          {
            value: "10000000"
          },
          {
            value: "12000000"
          },
          {
            value: "15000000"
          }
        ]
      },
      {
        seriesname: "2 Year CAGR",
        renderas: "line",
        parentyaxis: "S",
        data: [
          {
            value: "8000000"
          },
          {
            value: "11000000"
          },
          {
            value: "13200000"
          }
        ]
      },
      {
        seriesname: "3 Year CAGR",
        renderas: "line",
        parentyaxis: "S",
        data: [
          {
            value: "6386000"
          },
          {
            value: "7942700"
          },
          {
            value: "7260520"
          }
        ]
      }
    ]
  };

  width = '100%';
  height = 400;
  type = "mscombidy2d";
  dataFormat = "json";

  constructor(private mfpService: MfpService) { }

  ngOnInit(): void {
    this.yearlyData[0].fy2022 = this.mfpService.yearlyTotal;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.mfpService.updateTotal
    .subscribe(() => {
      this.yearlyData[0].fy2022 = this.mfpService.yearlyTotal;
      this.cellValueChanged();
    });
    this.cellValueChanged();
  }

  cellValueChanged(type = false) {
    setTimeout(() => {
      this.mfpService.yearlyTotal = Number(this.yearlyData[0].fy2022);
      type && this.mfpService.updateTotal.next(this.mfpService.yearlyTotal);
      this.yearlyData[1].fy2022 = (Number(this.yearlyData[0].fy2022) / Number(this.yearlyData[0].fy2021) - 1) * 100;
      this.yearlyData[5].fy2022 = Number(this.yearlyData[0].fy2021) * (Number(this.yearlyData[4].fy2022) / 100) + Number(this.yearlyData[0].fy2021);
      this.yearlyData[6].fy2022 = Number(this.yearlyData[0].fy2022) - Number(this.yearlyData[5].fy2022);
      this.gridApi.setRowData(this.yearlyData);
      this.dataSource.dataset[0].data = [
        {
          value: this.yearlyData[0].fy2020.toString()
        },
        {
          value: this.yearlyData[0].fy2021.toString()
        },
        {
          value: this.yearlyData[0].fy2022.toString()
        }
      ];
      this.dataSource.dataset[1].data = [
        {
          value: this.yearlyData[2].fy2020.toString()
        },
        {
          value: this.yearlyData[2].fy2021.toString()
        },
        {
          value: this.yearlyData[2].fy2022.toString()
        }
      ];
      this.dataSource.dataset[2].data = [
        {
          value: this.yearlyData[3].fy2020.toString()
        },
        {
          value: this.yearlyData[3].fy2021.toString()
        },
        {
          value: this.yearlyData[3].fy2022.toString()
        }
      ];
    }, 200);
  }

}
