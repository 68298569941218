import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { throwError } from 'rxjs';
import { ConfigurationService } from 'src/services/configuration.service';
@Component({
  selector: 'app-month-wise-same',
  templateUrl: './month-wise-same.component.html',
  styleUrls: ['./month-wise-same.component.css']
})
export class MonthWiseSameComponent implements OnInit, OnChanges {

  public columnDefs = [
    {
      headerName: 'Years',
      field: 'year',
      pinned: 'left',
      width: 120,
      valueFormatter: (params) => params.value ? `${params.value}-${+params.value + 1}` : ''
    }
  ];
  @Input() isFromChanneWiseSource = false;
  public columnDefsVariation = [];
  public columnDefsPercentage = [];
  public columnDefsPercentageVariation = [];
  private gridApi: any;
  public sameStoreList = [];
  public gridOptions: any;
  public gridOptionsVariation: any;
  public gridOptionsPercentage: any;
  public gridOptionsPercentageVariation: any;
  public sameStoreHeader = [];
  public gridData = [];
  public gridList = [];
  public getRowHeight;
  public headerHeight;
  public gridListVariation = [];
  public gridListPercentage = [];
  public gridListPercentageVariation = [];
  public channelList = [];
  public modules = AllCommunityModules;
  @Input() channel: any;
  constructor(public configurationService: ConfigurationService) {
    this.gridOptions = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.gridOptionsVariation = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.gridOptionsPercentage = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.gridOptionsPercentageVariation = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.headerHeight = 30;
    this.gridOptions.getRowHeight = function (params) {
      return 30;
    };
    this.gridOptionsPercentage.getRowHeight = function (params) {
      return 30;
    };
    this.gridOptionsVariation.getRowHeight = function (params) {
      return 30;
    };
    this.gridOptionsPercentageVariation.getRowHeight = function (params) {
      return 30;
    };
  }

  ngOnInit() {
    this.configurationService.getAllChannels({ clientId: 1 }).subscribe(res => {
      this.channelList = res.data;
    });
  }
  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges && simpleChanges.channel && simpleChanges.channel.currentValue) {
      this.channel = simpleChanges.channel.currentValue;
      this.populateList();
    }
  }
  public populateList = async () => {
    if (this.channel) {
      this.gridList = [];
      const sameStoreObject = {};
      const sameStoreTempList = [];
      const yearList = [];
      const sameStoreList = await this.configurationService.getSameStoreHeaderList({ clientId: 1, active: true, channelId: this.channel.channelId }).toPromise().catch(error => {
        return throwError(error);
      });
      // Tenure Type List
      if (sameStoreList && sameStoreList.length > 0) {
        this.sameStoreHeader = sameStoreList;
        this.sameStoreHeader.forEach(store => {
          const year = store && store.name ? store.name.split('-')[1] : '';
          const month = store && store.name ? store.name.split('-')[0] : '';
          if (!yearList.includes(year)) {
            yearList.push(year);
          }
          if (store.code && !sameStoreTempList.includes(store.code)) {
            sameStoreTempList.push({ field: `${store.code}`, name: month });
          }
        });
        if (sameStoreTempList.length > 0) {
          sameStoreTempList.forEach(column => {
            if (!this.columnDefs.find(columnDef => columnDef && columnDef.field === column.field)) {
              (this.columnDefs as any).push({
                headerName: column.name,
                field: column.field,
                width: 80
              });
            }
            sameStoreObject[column.field] = 0;
          });
          if (this.columnDefs.length > 1) {
            (this.columnDefs as any).push({
              headerName: 'Total',
              field: 'total',
              pinned: 'right',
              width: 120
            });
            this.gridOptions.api.setColumnDefs(this.columnDefs);
          }
        }
      }
      // Goals
      const values = await this.configurationService.getSameStoreFormList({ clientId: 1, channelId: this.channel.channelId }).toPromise().catch(error => {
        if (this.gridList.length === yearList.length) {
          // this.mapTotalObject();
        }
        return throwError(error);
      });
      if (values && values.pivotList && values.pivotList.length > 0) {
        const gridData = values.pivotList;
        this.gridData.push(values.pivotList);
        this.gridData = (this.gridData as any).flat();
        yearList.forEach(year => {
          const newObject = JSON.parse(JSON.stringify(sameStoreObject));
          gridData.forEach(goal => {
            this.columnDefs.forEach(column => {
              Object.keys(goal).forEach(key => {
                if (column.field && column.field === key) {
                  if (column.field in newObject && year === key.substring(7, 11)) {
                    newObject[column.field] += goal[key] ? goal[key] : 0;
                    newObject['year'] = `${year}`;
                  } else {
                    newObject[column.field] = 0;
                  }
                }
              });
            });
          });
          this.gridList.push(newObject);
        });

        if (this.gridList.length === yearList.length) {
          for (let i = 0; i < yearList.length; i++) {
            this.gridList[i]['total'] = 0;
            this.gridList[i]['total'] = Object.values(this.gridList[i]).reduce((a, b) => +a + +b);
            this.gridList[i]['total'] = this.gridList[i]['total'] ? this.gridList[i]['total'] - Number(yearList[i]) : 0;
          }
        }
      } else {
        if (this.gridList.length === yearList.length) {
          for (let i = 0; i < yearList.length; i++) {
            this.gridList[i]['total'] = 0;
            this.gridList[i]['total'] = Object.values(this.gridList[i]).reduce((a, b) => +a + +b);
            this.gridList[i]['total'] = this.gridList[i]['total'] ? this.gridList[i]['total'] - Number(yearList[i]) : 0;
          }
        }
      }
      this.gridOptions.api.setRowData(this.gridList);
      if (!this.isFromChanneWiseSource) {
        this.gridListVariation = this.gridList.filter(row => row.year === new Date().getFullYear().toString());
        this.columnDefsVariation = this.columnDefs;
        this.gridOptionsVariation.api.setRowData(this.gridListVariation);
        this.mapTotalObject();
      }

    }
  }
  public mapTotalObject = () => {
    // const totalObject: any = {};
    // this.gridList.forEach(row => {
    //   Object.keys(row).forEach(key => {
    //     if (key !== 'sameStoreName' && key !== 'channelName') {
    //       if (key in row) {
    //         totalObject[key] = totalObject[key] !== null && totalObject[key] !== undefined ? totalObject[key] + row[key] : row[key];
    //       }
    //     }
    //   });
    //   totalObject.year = 'Total';
    // });
    // this.gridList.push(totalObject);
    // this.gridOptions.api.setRowData(this.gridList);
    this.mapPercentageList(JSON.parse(JSON.stringify(this.gridList)));
    // this.mapVariationList(JSON.parse(JSON.stringify(this.gridList)));
  }
  public mapPercentageList = (goalList: any) => {
    const keys = ['year'];
    for (let i = 0; i < goalList.length; i++) {
      let keyList = Object.keys(goalList[i]);
      keyList = keyList.filter(key => !keys.includes(key));
      for (let j = 0; j < keyList.length; j++) {
        if (goalList[i][keyList[j]] && goalList[i]['total']) {
          goalList[i][keyList[j]] = goalList[i][keyList[j + 1]] > 0 ? (((goalList[i][keyList[j]]) / goalList[i]['total']) * 100) : goalList[i][keyList[j + 1]];
        }
        if (keyList[j] === 'total') {
          goalList[i]['total'] = 100;
        }
      }
      this.gridListPercentage.push(goalList[i]);
      this.gridOptionsPercentage.api.setRowData(this.gridListPercentage);
    }
    this.columnDefsPercentage = this.columnDefs.map(column => (column.field !== 'year' ? {
      ...column,
      valueFormatter: this.currencyFormatter
    } : { ...column }));
    this.gridOptionsPercentage.api.setColumnDefs(this.columnDefsPercentage);
    this.columnDefsPercentageVariation = this.columnDefs.map(column => (column.field !== 'year' ? {
      ...column,
      valueFormatter: this.currencyFormatter
    } : { ...column }));
    this.gridListPercentageVariation = this.gridListPercentage.filter(row => row.year === new Date().getFullYear().toString());
    this.gridOptionsPercentageVariation.api.setRowData(this.gridListPercentageVariation);
  }
  public mapVariationList = (goalList: any) => {
    const keys = ['sameStoreName', 'channelName'];
    for (let i = 0; i < goalList.length; i++) {
      let keyList = Object.keys(goalList[i]);
      keyList = keyList.filter(key => !keys.includes(key));
      for (let j = 0; j < keyList.length; j++) {
        if (goalList[i][keyList[j]] && goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = goalList[i][keyList[j + 1]] > 0 ? ((goalList[i][keyList[j]] - goalList[i][keyList[j + 1]])) : goalList[i][keyList[j + 1]];
        } else if (goalList[i][keyList[j]] && !goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = keyList[j] !== 'channelName' ? goalList[i][keyList[j]] : goalList[i][keyList[j]] ? goalList[i][keyList[j]] : 0;
        }
      }
      this.gridListVariation.push(goalList[i]);
      this.gridOptionsVariation.api.setRowData(this.gridListVariation);
    }
    this.mapPercentageListVariation(JSON.parse(JSON.stringify((this.gridListVariation))));
    this.columnDefsVariation = this.columnDefs.map(column => (column.field !== 'channelName' && column.field !== 'sameStoreName' ? {
      ...column,
      valueFormatter: this.currencyFormatterVaraition
    } : { ...column }));
  }
  public mapPercentageListVariation = (goalList: any) => {
    const keys = ['sameStoreName', 'channelName'];
    for (let i = 0; i < goalList.length; i++) {
      let keyList = Object.keys(goalList[i]);
      keyList = keyList.filter(key => !keys.includes(key));
      for (let j = 0; j < keyList.length; j++) {
        if (goalList[i][keyList[j]] && goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = goalList[i][keyList[j + 1]] > 0 ? (((goalList[i][keyList[j]] - goalList[i][keyList[j + 1]]) / goalList[i][keyList[j + 1]]) * 100) : goalList[i][keyList[j + 1]];
        } else if (goalList[i][keyList[j]] && !goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = keyList[j] !== 'channelName' ? 0 : goalList[i][keyList[j]] ? goalList[i][keyList[j]] : 0;
        }
      }
      this.gridListPercentageVariation.push(goalList[i]);
      this.gridOptionsPercentageVariation.api.setRowData(this.gridListPercentageVariation);
    }
    this.columnDefsPercentageVariation = this.columnDefs.map(column => (column.field !== 'channelName' && column.field !== 'sameStoreName' ? {
      ...column,
      valueFormatter: this.currencyFormatter
    } : { ...column }));
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  currencyFormatter(params) {
    // return `${formatNumber(params.value, 'en', '2.0-2')}`;
    return `${Math.floor(typeof params.value === 'number' ? params.value : 0)}%`;
  }
  currencyFormatterVaraition(params) {
    return `${Math.floor(typeof params.value === 'number' ? params.value : 0)}`;
  }
}
