<div class="container-fluid planogram-optimizer" style="margin-top: 15px;" cdkDropListGroup>
  <!-- UPLOAD BTN -->
  <div class="row prv-week-upload-section mr-2">
    <div class="upload-btn-label">Upload previous planogram</div>
    <button [disabled]="uploadingPreviousWeekFile || loadingPreviousWeekPlanogram" mat-raised-button color="accent" class="upload-btn ml-4" (click)="importPrvWeekData()">{{uploadingPreviousWeekFile? "UPLOADING...": "UPLOAD"}}
      <input class="hidden" type="file" id="upload-prv-week-planogram-data" name="upload-prv-week-planogram-data"
        accept=".xls, .xlsx, application/excel, application/vnd.msexcel, text/anytext, application/vnd. ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        (change)="onFileSelect($event, 'previous')">
    </button>
    <button [disabled]="uploadingPreviousWeekFile || loadingPreviousWeekPlanogram" mat-raised-button color="accent" class="upload-btn ml-2" (click)="updateData()">UPDATE DATA
    </button>
  </div>

  <hr class="theme-hr" />

  <!-- Last Week Planogram Section -->
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Last Week's FOSNR Planogram
        </mat-panel-title>
      </mat-expansion-panel-header>

      <h6 class="p-5 text-center" *ngIf="!previousWeekGroups.FOSNR_B.length && !previousWeekGroups.FOSNR_B.length && !previousWeekGroups.FOSNR_B.length">
        {{loadingPreviousWeekPlanogram?'Loading... Please Wait!':'No data to show.'}}
      </h6>

      <div class="fosner-section expansion-panel-cls mh-100" *ngIf="!loadingPreviousWeekPlanogram && (previousWeekGroups.FOSNR_B.length && previousWeekGroups.FOSNR_B.length && previousWeekGroups.FOSNR_B.length)">

        <!-- B -->
        <div class="fosner-main-section">
          <div class="fosner-title-bar">FOSNR B</div>
          <div class="fosner-label">No Signage</div>
          <div class="fosner-item-section">
            <div class="fosner-row" *ngFor="let rowCounter of counter(5); let rowNumber = index;">
              <div [ngClass]="(rowNumber+columnNumber)%2==0?'':'bg-gray'" class="fosner-card"
                *ngFor="let columnCounter of counter(8); let columnNumber = index;">
                <div class="fosner-card">
                  <planogram-card *ngIf="previousWeekGroups.FOSNR_B[columnNumber + (rowNumber*8)]"
                    [cardData]="previousWeekGroups.FOSNR_B[columnNumber + (rowNumber*8)]"
                    [index]="columnNumber + (rowNumber*8)" [newWeekData]="false">
                  </planogram-card>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- C -->
        <div class="fosner-main-section ml-3">
          <div class="fosner-title-bar">FOSNR C</div>
          <div class="fosner-label max-w-190">No Signage</div>
          <div class="fosner-item-section">
            <div class="fosner-row" *ngFor="let rowCounter of counter(5); let rowNumber = index;">
              <div [ngClass]="(rowNumber+columnNumber)%2==0?'':'bg-gray'" class="fosner-card"
                *ngFor="let columnCounter of counter(2); let columnNumber = index;">
                <div class="fosner-card">
                  <planogram-card *ngIf="previousWeekGroups.FOSNR_C[columnNumber + (rowNumber*2)]"
                    [cardData]="previousWeekGroups.FOSNR_C[columnNumber + (rowNumber*2)]"
                    [index]="columnNumber + (rowNumber*2)" [newWeekData]="false">
                  </planogram-card>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- D -->
        <div class="fosner-main-section ml-3">
          <div class="fosner-title-bar">FOSNR D</div>
          <div class="fosner-label">No Signage</div>
          <div class="fosner-item-section">
            <div class="fosner-row" *ngFor="let rowCounter of counter(5); let rowNumber = index;">
              <div [ngClass]="(rowNumber+columnNumber)%2==0?'':'bg-gray'" class="fosner-card"
                *ngFor="let columnCounter of counter(8); let columnNumber = index;">
                <div class="fosner-card">
                  <planogram-card *ngIf="previousWeekGroups.FOSNR_D[columnNumber + (rowNumber*8)]"
                    [cardData]="previousWeekGroups.FOSNR_D[columnNumber + (rowNumber*8)]"
                    [index]="columnNumber + (rowNumber*8)" [newWeekData]="false">
                  </planogram-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- End Last Week Planogram Section -->

  <hr class="theme-hr" />

  <!-- This Week Tabel ~ START -->
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          New This Week
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- UPLOAD BTN -->
      <div class="row prv-week-upload-section my-2">
        <div class="row align-items-center">
          <div class="upload-btn-label">Upload new titles</div>
          <button [disabled]="uploadingThisWeekFile" mat-raised-button color="accent" class="upload-btn ml-4"  matTooltip="Upload File" (click)="importCurrentWeekData()">{{uploadingThisWeekFile?"UPLOADING...":"UPLOAD"}}
            <input class="hidden" type="file" id="upload-current-planogram-data"
              accept=".xls, .xlsx, application/excel, application/vnd.msexcel, text/anytext, application/vnd. ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              (change)="onFileSelect($event, 'current')">
          </button>
        </div>
          <button [disabled]="thisWeekData.length==0 || uploadingThisWeekFile" class="mx-4" matTooltip="Add New Item" mat-fab color="accent" (click)="addItemDialog()">
              <mat-icon>add_circle</mat-icon>
          </button>
      </div>
      <div class="all-show-grid">
        <ag-grid-angular class="ag-theme-material" [suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups
          toolPanelSuppressValues [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions"
          [columnDefs]="gridColumnDefs" [rowData]="thisWeekData" [pagination]="true"
          [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize" (gridReady)="onGridReady($event)"
          [modules]="modules" (cellValueChanged)="cellValueChanged($event)" #planogramGrid>
        </ag-grid-angular>
      </div>
      <!-- RUN BTN -->
      <div class="row d-flex justify-content-end pr-4">
        <button mat-raised-button color="accent" [disabled]="nextWeekRuns.length==3 || runningOptimizer || lockedPlanogram>-1" (click)="runOptimizer()">{{runningOptimizer ? 'PLEASE WAIT...' : 'RUN'}}
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- This Week Tabel ~ END -->


  <hr class="theme-hr" />

  <!-- Next Week Planogram Section -->
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Next Week's FOSNR Planogram
        </mat-panel-title>
      </mat-expansion-panel-header>
      <h6 class="p-5 text-center" *ngIf="nextWeekRuns.length==0">
        No data to show. Run optimizer to proceed.
      </h6>
      <mat-tab-group [(selectedIndex)]="nextWeekTabIndex">
        <mat-tab [label]="(lockedPlanogram==runIndex)?'Locked Run': 'Run ' + (runIndex+1)" *ngFor="let nextWeekOptimizer of nextWeekRuns; let runIndex=index;">
          <div class="fosner-section expansion-panel-cls">
            <!-- B -->
            <div class="fosner-main-section">
              <div class="fosner-title-bar">FOSNR B</div>
              <div class="fosner-label">No Signage</div>
              <div class="fosner-item-section">
                <div class="fosner-row" *ngFor="let rowCounter of counter(5); let rowNumber = index;">
                  <div [ngClass]="(rowNumber+columnNumber)%2==0?'':'bg-gray'" class="fosner-card"
                    *ngFor="let columnCounter of counter(8); let columnNumber = index;" cdkDropList
                    [cdkDropListData]="nextWeekOptimizer.FOSNR_B"
                    (cdkDropListDropped)="drop(runIndex, columnNumber + (rowNumber*8), draggableArrayTypes.NEW_FOSNER_B, nextWeekOptimizer.FOSNR_B[columnNumber + (rowNumber*8)]?.lock_tile)">
                    <div class="fosner-card" cdkDrag
                      [cdkDragDisabled]="nextWeekOptimizer.FOSNR_B[columnNumber + (rowNumber*8)]?.lock_tile?true:false"
                      (cdkDragStarted)="cdkDragStarted(columnNumber + (rowNumber*8), draggableArrayTypes.NEW_FOSNER_B)">
                      <planogram-card
                        *ngIf="nextWeekOptimizer.FOSNR_B[columnNumber + (rowNumber*8)]"
                        [cardData]="nextWeekOptimizer.FOSNR_B[columnNumber + (rowNumber*8)]"
                        [index]="columnNumber + (rowNumber*8)" [newWeekData]="true">
                      </planogram-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- C -->
            <div class="fosner-main-section ml-3">
              <div class="fosner-title-bar">FOSNR C</div>
              <div class="fosner-label max-w-190">No Signage</div>
              <div class="fosner-item-section">
                <div class="fosner-row" *ngFor="let rowCounter of counter(5); let rowNumber = index;">
                  <div [ngClass]="(rowNumber+columnNumber)%2==0?'':'bg-gray'" class="fosner-card"
                    *ngFor="let columnCounter of counter(2); let columnNumber = index;" cdkDropList
                    [cdkDropListData]="nextWeekOptimizer.FOSNR_C"
                    (cdkDropListDropped)="drop(runIndex, columnNumber + (rowNumber*2), draggableArrayTypes.NEW_FOSNER_C, nextWeekOptimizer.FOSNR_C[columnNumber + (rowNumber*2)]?.lock_tile)">
                    <div class="fosner-card" cdkDrag
                      [cdkDragDisabled]="nextWeekOptimizer.FOSNR_C[columnNumber + (rowNumber*2)]?.lock_tile?true:false"
                      (cdkDragStarted)="cdkDragStarted(columnNumber + (rowNumber*2), draggableArrayTypes.NEW_FOSNER_C)">
                      <planogram-card
                        *ngIf="nextWeekOptimizer.FOSNR_C[columnNumber + (rowNumber*2)]"
                        [cardData]="nextWeekOptimizer.FOSNR_C[columnNumber + (rowNumber*2)]"
                        [index]="columnNumber + (rowNumber*2)" [newWeekData]="true">
                      </planogram-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- D -->
            <div class="fosner-main-section ml-3">
              <div class="fosner-title-bar">FOSNR D</div>
              <div class="fosner-label">No Signage</div>
              <div class="fosner-item-section">
                <div class="fosner-row" *ngFor="let rowCounter of counter(5); let rowNumber = index;">
                  <div [ngClass]="(rowNumber+columnNumber)%2==0?'':'bg-gray'" class="fosner-card"
                    *ngFor="let columnCounter of counter(8); let columnNumber = index;" cdkDropList
                    [cdkDropListData]="nextWeekOptimizer.FOSNR_D"
                    (cdkDropListDropped)="drop(runIndex, columnNumber + (rowNumber*8), draggableArrayTypes.NEW_FOSNER_D, nextWeekOptimizer.FOSNR_D[columnNumber + (rowNumber*8)]?.lock_tile)">
                    <div class="fosner-card" cdkDrag
                      [cdkDragDisabled]="nextWeekOptimizer.FOSNR_D[columnNumber + (rowNumber*8)]?.lock_tile?true:false"
                      (cdkDragStarted)="cdkDragStarted(columnNumber + (rowNumber*8), draggableArrayTypes.NEW_FOSNER_D)">
                      <planogram-card
                        *ngIf="nextWeekOptimizer.FOSNR_D[columnNumber + (rowNumber*8)]"
                        [cardData]="nextWeekOptimizer.FOSNR_D[columnNumber + (rowNumber*8)]"
                        [index]="columnNumber + (rowNumber*8)" [newWeekData]="true">
                      </planogram-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <!-- LABELS -->
          <div class="container">
            <div class="row p-3 justify-content-between">
              <div class="label-div d-flex col-md align-items-center w25 my-2" *ngFor="let item of labels">
                <div class="clr-div" [ngClass]="item.color"></div>
                <label class="ml-2 mb-0">{{item.title}}</label>
              </div>
            </div>
          </div>
  
          <!-- LEFT OVERS -->
          <div class="left-overs fosner-section expansion-panel-cls">
            <div class="fosner-main-section">
              <div class="fosner-title-bar">Surplus</div>
              <div class="fosner-item-section">
                <div class="fosner-row">
                  <div [ngClass]="(columnNumber)%2==0?'':'bg-gray'" class="fosner-card"
                    *ngFor="let columnCounter of nextWeekOptimizer.LEFT_OVERS; let columnNumber = index;" cdkDropList
                    [cdkDropListData]="nextWeekOptimizer.LEFT_OVERS" (cdkDropListDropped)="drop(runIndex, columnNumber, draggableArrayTypes.LEFT_OVER)">
                    <div class="fosner-card" cdkDrag
                      (cdkDragStarted)="cdkDragStarted(columnNumber, draggableArrayTypes.LEFT_OVER)">
                      <planogram-card *ngIf="nextWeekOptimizer.LEFT_OVERS[columnNumber]"
                        [cardData]="nextWeekOptimizer.LEFT_OVERS[columnNumber]" [index]="columnNumber" [newWeekData]="true">
                      </planogram-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Download BTN -->
          <div class="row prv-week-upload-section mx-2 mt-2 justify-content-between" style="overflow: hidden;">
            <div class="row ml-2">
              <div class="upload-btn-label">{{lockedPlanogram>-1 ? 'Delete planogram': 'Save & lock planogram'}}</div>
              <button *ngIf="lockedPlanogram==-1" mat-raised-button color="accent" class="upload-btn ml-4" [disabled]="(lockingPlanogram==runIndex+1)||(lockedPlanogram==runIndex)" (click)="saveNewPlanogram(runIndex)">{{getSavingButtonTitle(runIndex)}}
              </button>
              <button *ngIf="lockedPlanogram>-1" mat-raised-button color="accent" class="upload-btn ml-4" [disabled]="lockingPlanogram>0" (click)="deletePlanogram()">{{lockingPlanogram?'Please wait...':'Delete'}}
              </button>
            </div>
            <div class="row">
              <button *ngIf="lockedPlanogram==-1" mat-stroked-button color="accent" class="upload-btn mr-4" (click)="discardRun(runIndex)">DISCARD PLANOGRAM
              </button>
              <div class="upload-btn-label ml-2">Download new planogram</div>
              <button mat-raised-button color="accent" class="upload-btn ml-4" (click)="exportAsXLSX(nextWeekOptimizer)">DOWNLOAD
              </button>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- End Next Week Planogram Section -->

  <!-- <hr class="theme-hr" /> -->

  <!-- FISCALS ~ START -->
  <!-- <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Fiscals
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="fiscals">
        <div class="fiscal-item">
          <div>
            <small>Expected Value of Planogram</small>
            <h1 class="purple-clr">$12,542.25</h1>
          </div>
        </div>
        <div class="fiscal-item border-cls">
          <div>
            <small>Value gained by titles added</small>
            <h1 class="purple-clr">+$6,548.24</h1>
          </div>
        </div>
        <div class="fiscal-item">
          <div>
            <small>Value lost by titles removed</small>
            <h1 class="purple-clr">-$2,487.21</h1>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion> -->
  <!-- Fiscals ~ END -->
</div>