import { AllCommunityModules, GridApi } from '@ag-grid-community/all-modules';
import { formatDate } from '@angular/common';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError } from 'rxjs';
import { CheckboxRendererComponent } from 'src/common/checkbox-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { ConfigurationService } from '../../../../services/configuration.service';
import { Item, AccountSetting } from '../../item.model';

@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.css'],
})
export class AccountSettingComponent implements OnInit {
  @Input() itemModel: Item;
  public accountSetting = new AccountSetting();
  public modules = AllCommunityModules;
  public columnDefs = [
    {
      headerName: '',
      field: 'accountSettingId',
      cellRenderer: 'checkboxRenderer',
      width: 50,
      cellRendererParams: {
        onClick: this.checkItem.bind(this),
      },
    },
    {
      headerName: 'Account',
      field: 'accountName',
      minWidth: 50,
      resizable: true,
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.addItem.bind(this),
      },
    },
    {
      headerName: 'Channel',
      field: 'channelName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Region',
      field: 'regionName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Country',
      field: 'countryName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Forecast Start Date',
      field: 'foreCastStartDate',
      minWidth: 50,
      resizable: true,
      cellRenderer: (params) => {
        return params.value ? formatDate(params.value, 'dd MMM, y', 'en') : '';
      },
    },
    {
      headerName: 'Forecast End Date',
      field: 'foreCastEndDate',
      minWidth: 50,
      resizable: true,
      cellRenderer: (params) => {
        return params.value ? formatDate(params.value, 'dd MMM, y', 'en') : '';
      },
    },
    {
      headerName: 'Status',
      field: 'active',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'CasePack Quantity',
      field: 'casepackQuant',
      minWidth: 50,
      resizable: true,
    },
    ,
    {
      headerName: 'Pallet Quantity',
      field: 'palletQuan',
      minWidth: 50,
      resizable: true,
    },
  ];
  public gridOptions: any;
  gridApi: GridApi;
  defaultColDef: any;
  public accountSettingList: AccountSetting[];
  rowSelection = '';
  channelList = [];
  countryList = [];
  regionList = [];
  orderFrequencyList = [];
  selectedNodes = [];
  headerHeight = 45;
  public accountList = [];
  public distributorList = [];
  public nodesToDelete = [];
  public submitted = false;
  endDate: Date;
  constructor(
    public config: ConfigurationService,
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService
  ) {
    // this.rowSelection = 'single';
    this.gridOptions = {
      frameworkComponents: {
        nameRenderer: NameRendererComponent,
        checkboxRenderer: CheckboxRendererComponent,
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }

  ngOnInit() {
    this.getDropdowns();
    this.getItemAccountList();
    this.getAccountSettingList();
    this.endDate = new Date();
    this.endDate.setDate(this.endDate.getDate() + 728);
  }
  async addItem(item) {
    if (item && item.rowData) {
      this.accountSetting = item.rowData;
      this.accountSetting.itemAccountId = this.accountSetting.accountId;
      if (this.accountSetting.channelId) {
        this.regionList = await this.config
          .AccountSettingMappingContinentGetList({
            channelId: this.accountSetting.channelId,
          })
          .toPromise()
          .catch((error) => throwError(error));
      }
      if (this.accountSetting.regionId) {
        this.countryList = await this.config
          .AccountSettingMappingCountryGetList({
            continentId: this.accountSetting.regionId,
          })
          .toPromise()
          .catch((error) => throwError(error));
      }
      if (this.accountSetting.countryId) {
        this.distributorList = await this.config
          .AccountSettingMappingDistributorGetList({
            countryId: this.accountSetting.countryId,
          })
          .toPromise()
          .catch((error) => throwError(error));
      }
    }
  }
  checkItem(item?) {
    if (item.event.checked) {
      this.nodesToDelete.push(item.rowData);
    } else {
      this.nodesToDelete = this.nodesToDelete.filter(
        (row) => row.accountSettingId !== item.rowData.accountSettingId
      );
    }
  }
  public getItemAccountList = () => {
    this.config.ItemAccountGetList({}).subscribe((res) => {
      this.accountList = res || [];
    });
  };
  public getAccountSettingList = () => {
    this.config
      .ItemAccountSettingGet({ itemManagerId: this.itemModel.itemManagerId })
      .subscribe(
        (res) => {
          this.accountSettingList = res || [];
        },
        (error) => {
          this.accountSettingList = [];
        }
      );
  };
  public getDropdowns = () => {
    this.config.AccountSettingMappingChannelGetList({}).subscribe((res) => {
      this.channelList = res;
    });
    this.config.GetLookupByCode('OrderFrequency').subscribe((res) => {
      this.orderFrequencyList = res;
    });
  };
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
  };
  addAccountSetting = () => {
    const itemModel = {
      ...this.itemModel,
    };
    if (this.itemModel.itemManagerId) {
      let requestVM = this.buildRequestVM(this.accountSetting);
      let valid = true;
      requestVM.forEach((el) => {
        Object.keys(el).forEach((key) => {
          if (!el[key] && el[key] != false) {
            valid = false;
          }
        });
      });
      if (valid) {
        let model = [];
        this.accountSetting.itemManagerId = this.itemModel.itemManagerId;
        model.push(this.accountSetting);
        let reqModel = [];
        if (this.accountSettingList.length > 0) {
          reqModel = requestVM.filter(
            (row) =>
              !this.accountSettingList.find(
                (el) =>
                  el.channelId === row.channelId &&
                  el.regionId === row.regionId &&
                  el.countryId === row.countryId &&
                  el.distributorId === row.distributorId &&
                  el.accountId === row.accountId
              )
          );
        } else {
          reqModel = requestVM;
        }
        requestVM = [...this.accountSettingList, ...reqModel];
        const requestModel = {
          accountSettings: requestVM,
        };
        this.spinner.show();
        this.config.ItemAccountSettingAdd(requestModel).subscribe((res) => {
          this.accountSetting.accountSettingId = res;
          this.submitted = false;
          this.regionList = [];
          this.distributorList = [];
          this.countryList = [];
          this.accountSetting = new AccountSetting();
          this.getAccountSettingList();
          this.spinner.hide();
        });
      }
    } else if (this.checkIfAllItemsProvided()) {
      this.config.ItemManagerAdd(itemModel).subscribe(
        (res) => {
          this.itemModel.itemManagerId = res;
          this.accountSetting.itemManagerId = res;
          let valid = true;
          const requestVM = this.buildRequestVM(this.accountSetting);
          requestVM.forEach((el) => {
            Object.keys(el).forEach((key) => {
              if (!el[key]) {
                valid = false;
              }
            });
          });
          if (valid) {
            let model = [];
            const requestModel = {
              accountSettings: requestVM,
            };
            this.submitted = true;
            this.spinner.show();
            this.config.ItemAccountSettingAdd(requestModel).subscribe((res) => {
              this.accountSetting.accountSettingId = res;
              this.submitted = false;
              this.regionList = [];
              this.distributorList = [];
              this.countryList = [];
              this.accountSetting = new AccountSetting();
              this.getAccountSettingList();
              this.spinner.hide();
            });
          }
        },
        (error) => {}
      );
    } else {
      this.submitted = true;
      this.toastr.error(
        'Item Manager',
        'Please enter all required fields for item manager'
      );
    }
  };
  public buildRequestVM = (accountSetting) => {
    const list = [];
    accountSetting.regionId.forEach((region) => {
      accountSetting.countryId.forEach((country) => {
        accountSetting.distributorId.forEach((distributor) => {
          const object = {
            ...accountSetting,
            channelId: accountSetting.channelId,
            countryId: country,
            regionId: region,
            distributorId: distributor,
            accountId: accountSetting.itemAccountId,
            itemManagerId: this.itemModel.itemManagerId
              ? this.itemModel.itemManagerId
              : 0,
          };
          const currentChannel = this.channelList.find(
            (el) => el.channelId === accountSetting.channelId
          );
          const currentRegion = this.regionList.find(
            (el) => el.continentId === region
          );
          const currentCountry = this.countryList.find(
            (el) => el.countryId === country
          );
          if (
            currentChannel.regionIds.includes(region) &&
            currentRegion.countryIds.includes(country) &&
            currentCountry.distributorIds.includes(distributor)
          ) {
            list.push(object);
          }
        });
      });
    });
    return list;
  };
  public onChannelChange = (channelId) => {
    this.regionList = [];
    let regionIds;
    if (this.accountSetting.regionId) {
      regionIds = JSON.parse(JSON.stringify(this.accountSetting.regionId));
    }
    this.config
      .AccountSettingMappingContinentGetList({ channelId: channelId })
      .subscribe((res) => {
        const index = this.channelList.findIndex(
          (el) => el.channelId === channelId
        );
        this.channelList[index].regionIds = res.map((el) => el.continentId);
        this.regionList = [...this.regionList, ...res];
        if (this.accountSetting.regionId) {
          regionIds = (this.accountSetting.regionId as any).filter((row) =>
            this.regionList.find((el) => el.regionId === row)
          );
        }
      });
    // channelId.forEach(element => {

    // });
    if (regionIds) {
      this.accountSetting.regionId = regionIds;
    }
  };
  public onRegionChange = (continentId) => {
    this.countryList = [];
    continentId.forEach((element) => {
      this.config
        .AccountSettingMappingCountryGetList({ continentId: element })
        .subscribe((res) => {
          const index = this.regionList.findIndex(
            (el) => el.continentId === element
          );
          this.regionList[index].countryIds = res.map((el) => el.countryId);
          this.countryList = [...this.countryList, ...res];
        });
    });
  };
  public onCountryChange = (countryId) => {
    this.distributorList = [];
    countryId.forEach((element) => {
      this.config
        .AccountSettingMappingDistributorGetList({ countryId: element })
        .subscribe((res) => {
          const index = this.countryList.findIndex(
            (el) => el.countryId === element
          );
          this.countryList[index].distributorIds =
            res.map((el) => el.distributorId) || [];
          this.distributorList = [...this.distributorList, ...res];
        });
    });
  };
  async onSelectionChanged(event) {
    this.selectedNodes = event.api.getSelectedNodes();
    if (this.selectedNodes.length > 0) {
      this.selectedNodes = this.selectedNodes.map((node) => node.data);
      this.accountSetting = this.selectedNodes[this.selectedNodes.length - 1];
      this.accountSetting.itemAccountId = this.accountSetting.accountId;
      if (this.accountSetting.channelId) {
        this.regionList = await this.config
          .AccountSettingMappingContinentGetList({
            channelId: this.accountSetting.channelId,
          })
          .toPromise()
          .catch((error) => throwError(error));
      }
      if (this.accountSetting.regionId) {
        this.countryList = await this.config
          .AccountSettingMappingCountryGetList({
            continentId: this.accountSetting.regionId,
          })
          .toPromise()
          .catch((error) => throwError(error));
      }
      if (this.accountSetting.countryId) {
        this.distributorList = await this.config
          .AccountSettingMappingDistributorGetList({
            countryId: this.accountSetting.countryId,
          })
          .toPromise()
          .catch((error) => throwError(error));
      }
    } else {
      this.accountSetting = new AccountSetting();
    }
    // set value here
  }
  deleteSelectedNodes = (template: TemplateRef<any>) => {
    if (this.nodesToDelete && this.nodesToDelete.length > 0) {
      this.dialogRef = this.dialog.open(template, {
        width: '400px',
        panelClass: 'account',
      });
    }
  };
  onSubmitDelete = () => {
    if (this.nodesToDelete.length > 0) {
      this.spinner.show();
      const request = {
        accountSettingIds: this.nodesToDelete
          .map((el) => el.accountSettingId)
          .toString(),
      };
      this.config.ItemAccountSettingDelete(request).subscribe(
        (res) => {
          this.dialogRef.close();
          this.spinner.hide();
          this.accountSetting = new AccountSetting();
          this.getAccountSettingList();
        },
        (error) => {
          this.spinner.hide();
        }
      );
    }
  };
  public updateRows = (value, field: string) => {
    const requestModel = {
      ...this.accountSetting,
    };
    this.spinner.show();
    this.config.AccountSettingUpdate(requestModel).subscribe(
      (res) => {
        this.spinner.hide();
        this.regionList = [];
        this.distributorList = [];
        this.countryList = [];
        this.accountSetting = new AccountSetting();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  };
  resetForm = () => {
    this.accountSetting = new AccountSetting();
  };
  public checkIfAllItemsProvided = () => {
    if (
      this.itemModel.description &&
      this.itemModel.brandId &&
      this.itemModel.productGroupId &&
      this.itemModel.productFamilyId &&
      this.itemModel.msrp >= 0 &&
      this.itemModel.cost >= 0 &&
      this.itemModel.color &&
      this.itemModel.capacity &&
      this.itemModel.screenSize >= 0 &&
      this.itemModel.sku &&
      this.itemModel.productCategory &&
      this.itemModel.productStatus &&
      this.itemModel.streetDate
    ) {
      return true;
    } else {
      return false;
    }
  };
}
