import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ForecastConfirmationModalComponent } from 'src/modules/facebook/features/fb-forecast/forecast-confirmation-modal/forecast-confirmation-modal.component';
import { TreeviewItem } from 'ngx-treeview';
import { ConfigurationService } from 'src/services/configuration.service';
import { FB_Template } from 'src/modules/facebook/features/global.models';
import {
  Calendar,
  Measure,
  UserFbForecast,
  Version,
} from 'src/common/facebook.models';
import { LocalstorageService } from 'src/services/localstorage.service';
import { getCountOptions } from 'src/common/functions';
import { FormControl } from '@angular/forms';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-order-planner',
  templateUrl: './order-planner.component.html',
  styleUrls: ['./order-planner.component.scss'],
})
export class OrderPlannerComponent implements OnInit {
  public accountDetail = new FormControl('');
  @Input() data: any;
  isRestoreSession: boolean;
  public hideScreen = true;
  isBuidlNew: boolean;
  isShowTreeDropDowns: boolean;
  public versionList: Version[];
  public fbForecastList: UserFbForecast[];
  public counterIsLessThanTwo: any;
  public channelSettingList = [];
  public MsForecast;
  public productSettingList = [];
  public accountList = [];
  public timeFrameListWithWeeks = [];
  public timeFrameListWithWeeksUPD = [];
  public fcstScenarioPlanningList = [];
  public scenarioPlanningList: Measure[] = [];
  public timeframeList: Calendar[];
  public channelDropdownList: TreeviewItem[];
  public calendarListDropdown: TreeviewItem[];
  public measureList: Measure[];
  readOnlyMeasres: Measure[];
  editViaUploadMeasures: Measure[];
  public leftGroupList = [];
  public itemsList = [];
  public productList = [];
  productTree = [];
  channelTree = [];
  public udpMeasuresList = [];
  public udpfcstList = [];
  public orderSource: any = '';
  public orderStatus: any = '';
  public accountDetailList: any = [];
  public orderSourceList: any = [];
  public orderStatusList: any = [];
  public restoreCalendarList = [];
  public restoreAccountDetailList = [];
  public restoreOrderSourceList = [];
  public restoreOrderStatusList = [];
  gridMapped: boolean;
  public batchId = [];
  public item = [];
  public location = [];
  public purchaseOrder = [];
  public requestedDeliverDate = [];
  public orderDate = [];
  public batchIdFilterArr = [];
  public itemFilterArr = [];
  public locationFilterArr = [];
  public purchaseOrderFilterArr = [];
  public requestedDeliverDateFilterArr = [];
  public orderDateFilterArr = [];

  public accountFilterArray = [];
  public orderSourceFilterArray = [];
  public orderStatusFilterArray = [];


  public summaryArray = [
    {
      originalOrder: {
        label: 'Total Original Order View',
        totalOriginalOrderView: [
          {
            label: 'Total Lines',
            value: '0,000,000',
          },
          {
            label: 'Total Dollars',
            value: '0,000,000',
          },
          {
            label: 'Total Quantity',
            value: '0,000,000',
          },
          {
            label: 'Batch Count',
            value: '0,000,000',
          },
          {
            label: 'Item Count',
            value: '0,000,000',
          },
          {
            label: 'Location Count',
            value: '0,000,000',
          },
        ],
      },
      adjustedOrder: {
        label: 'Total Adjusted Order View',
        adjustedOrderView: [
          {
            label: 'Total Lines',
            value: '0,000,000',
          },
          {
            label: 'Total Dollars',
            value: '0,000,000',
          },
          {
            label: 'Total Quantity',
            value: '0,000,000',
          },
          {
            label: 'Batch Count',
            value: '0,000,000',
          },
          {
            label: 'Item Count',
            value: '0,000,000',
          },
          {
            label: 'Location Count',
            value: '0,000,000',
          },
        ],
      },
      subsetOriginalOrder: {
        label: 'Subset Original Order View',
        subsetOriginalOrderView: [
          {
            label: 'Total Lines',
            value: '000000',
          },
          {
            label: 'Total Dollars',
            value: '0,000,000',
          },
          {
            label: 'Total Quantity',
            value: '0,000,000',
          },
          {
            label: 'Batch Count',
            value: '0,000,000',
          },
          {
            label: 'Item Count',
            value: '0,000,000',
          },
          {
            label: 'Location Count',
            value: '0,000,000',
          },
        ],
      },
      subsetAdjustedOrder: {
        label: 'Subset Adjusted Order View',
        subsetAdjustedOrderView: [
          {
            label: 'Total Lines',
            value: '0,000,000',
          },
          {
            label: 'Total Dollars',
            value: '0,000,000',
          },
          {
            label: 'Total Quantity',
            value: '0,000,000',
          },
          {
            label: 'Batch Count',
            value: '0,000,000',
          },
          {
            label: 'Item Count',
            value: '0,000,000',
          },
          {
            label: 'Location Count',
            value: '0,000,000',
          },
        ],
      },
    },
  ];
  public adjustOrderQuantity = [
    {
      absoluteAdjustment: {
        label: 'Absolute Adjustment',
        absoluteAdjustmentValue: [
          {
            label: 'Absolute Units',
            value: '0,000,000',
          },
          {
            label: 'Absolute Units +/-',
            value: '0,000,000',
          },
          {
            label: 'Absolute %',
            value: '0,000,000',
          },
        ],
      },
      absoluteMinMax: {
        label: 'Absolute MIN/MAX',
        absoluteMinMaxValue: [
          {
            label: 'MIN Absolute',
            value: '0,000,000',
          },
          {
            label: 'MAX Absolute',
            value: '0,000,000',
          }
        ],
      },
      minMax: {
        label: 'MIN/MAX +/-',
        minMaxValue: [
          {
            label: 'MIN +/-',
            value: '000000',
          },
          {
            label: 'MAX +/-',
            value: '0,000,000',
          },
          {
            label: 'MIN %',
            value: '0,000,000',
          },
          {
            label: 'MAX %',
            value: '0,000,000',
          }
        ],
      },
      absoluteDos: {
        label: 'Absolute DOS',
        absoluteDosValue: [
          {
            label: 'DOS',
            value: '0,000,000',
          },
          {
            label: 'DOS +/-',
            value: '0,000,000',
          },
          {
            label: 'DOS +/-',
            value: '0,000,000',
          }
        ],
      },
      forecastAdjustment: {
        label: 'Forecast Adjustment',
        forecastAdjustmentValue: [
          {
            label: 'Adjust Forecast +/-',
            value: '0,000,000',
          },
          {
            label: 'Adjust Forecast %',
            value: '0,000,000',
          },
        ],
      },
      atp: {
        label: 'ATP',
        atpValue: [
          {
            label: 'Apply ATP',
            value: '0,000,000',
          },
          {
            label: 'Fill OOS First',
            value: '0,000,000',
          },
          {
            label: 'Exceed Max',
            value: '0,000,000',
          },
          {
            label: 'Below Min',
            value: '0,000,000',
          },
        ],
      },
    },

  ]

  constructor(
    private dialog: MatDialog,
    private matDialogRef: MatDialogRef<ForecastConfirmationModalComponent>,
    public configurationService: ConfigurationService,
    public storage: LocalstorageService,
    public toastr: NgxToasterService,
    public spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.data = JSON.parse(this.data);
    }
    // this.spinner.show();
    this.openActionDialog(false);
    this.getDataList();
  }

  public getDataList() {
    this.accountDetailList.push(
      { distributorName: 'Amazon', distributorId: 1 },
      { distributorName: 'Walmart', distributorId: 2 }
    );
    this.accountFilterArray = this.accountDetailList;
    this.orderSourceList.push(
      { distributorName: 'Replenishment', distributorId: 1 },
      { distributorName: 'New Items', distributorId: 2 },
      { distributorName: 'Edi/External', distributorId: 3 },
      { distributorName: 'Promo', distributorId: 4 },
      { distributorName: 'Manual', distributorId: 5 }
    );
    this.orderSourceFilterArray = this.orderSourceList;
    this.orderStatusList.push(
      { distributorName: 'Review', distributorId: 1 },
      { distributorName: 'Released', distributorId: 2 },
      { distributorName: 'Confirmed', distributorId: 3 },
      { distributorName: 'Shipped', distributorId: 4 },
      { distributorName: 'Delivered', distributorId: 5 }
    );
    this.orderStatusFilterArray = this.orderStatusList;
    this.orderSource = [this.orderSourceList[0].distributorName];
  }

  openActionDialog(restoreSession: boolean): void {
    // tslint:disable-next-line: no-use-before-declare
    const sessionLabel = restoreSession
      ? 'Your last session ended abruptly, would you like to return to where you left off?'
      : 'Would you like to open a saved planner or build new?';
    this.dialog
      .open(ForecastConfirmationModalComponent, {
        width: '848px',
        panelClass: ['fb-dialog-wrapper2', 'fb-dialog-wrapper3'],
        data: {
          isRestoreSession: restoreSession,
          label: sessionLabel,
          isOnLoad: restoreSession ? false : true,
          isSaveAndCommit: false,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((command) => {
        if (command && command.isCanceled) {
          this.closeScreen();
        } else if (command && command.openSaved) {
          this.matDialogRef.close();
        } else if (command && command.restoreSession) {
          this.restoreSession();
          this.isRestoreSession = true;
          this.hideScreen = false;
        } else {
          this.hideScreen = false;
          this.getDefaultDropdowns();
          this.isBuidlNew = true;
          this.isShowTreeDropDowns = true;
        }
      });
    // this.spinner.hide();
  }

  public restoreSession = async () => {
    this.getDefaultDropdowns(this.MsForecast);
  };
  public getDefaultDropdowns = async (forecast?: any) => {
    this.spinner.show();
    this.getMeasuresByCode();
    this.configurationService
      .measuresGetList({ FBTemplateId: FB_Template.Global_Planning })
      .subscribe((res: Measure[]) => {
        this.measureList = res;
        this.readOnlyMeasres = this.measureList.filter(
          (x) => x.templateId == 1
        );
        this.editViaUploadMeasures = this.measureList.filter(
          (x) => x.templateId == 2
        );
        this.extractParentsFromMeasures(forecast);
      });
    const params = {
      offset: 0,
      pageSize: 100000,
      active: true,
    };

    let url = `offSet=${params.offset ? params.offset : 0}&pageSize=${params.pageSize ? params.pageSize : 10
      }&active=true`;
    this.configurationService
      .calendarGetList({})
      .subscribe((res: Calendar[]) => {
        this.timeframeList = res;
        this.createCalendarListDropdownWithWeeks(this.MsForecast);
        this.spinner.hide();
      });
  };
  public mapChannelScrollingList = (channelIds) => {
    if (channelIds) {
      let ids = channelIds.split(',');
      const accounts = this.accountList.filter((row) =>
        ids.includes(row.itemAccountId.toString())
      );
      const accoutSettingList = this.channelSettingList.filter((account) =>
        accounts.find(
          (el) =>
            el.channelId === account.channelId &&
            el.regionId === account.continentId &&
            el.countryId === account.countryId &&
            el.distributorId === account.distributorId
        )
      );
      const accoutSettingListTemp = accoutSettingList;
      const selectedChannelIds = [];
      accoutSettingList.forEach((account) => {
        if (selectedChannelIds.includes(account.channelId)) {
        } else {
          selectedChannelIds.push(account.channelId);
        }
      });
      let treeList = [];
      selectedChannelIds.forEach((channel) => {
        let channelObject = {
          channelName: accoutSettingList.find(
            (acc) => acc.channelId === channel
          )
            ? accoutSettingList.find((acc) => acc.channelId === channel)
              .channelName
            : '',
          name: accoutSettingList.find((acc) => acc.channelId === channel)
            ? accoutSettingList.find((acc) => acc.channelId === channel)
              .channelName
            : '',
          channelId: channel,
          children: [],
          isChannel: true,
          isDelete: true,
        };
        const regionIds = accoutSettingListTemp
          .filter((el) => el.channelId === channel)
          .map((el) => el.continentId)
          .filter((value, index, self) => self.indexOf(value) === index);
        regionIds.forEach((regionId) => {
          let regionObject = {
            continentName: accoutSettingList.find(
              (acc) => acc.continentId === regionId
            )
              ? accoutSettingList.find((acc) => acc.continentId === regionId)
                .continentName
              : '',
            name: accoutSettingList.find((acc) => acc.continentId === regionId)
              ? accoutSettingList.find((acc) => acc.continentId === regionId)
                .continentName
              : '',
            continentId: regionId,
            channelId: channel,
            children: [],
            isRegion: true,
            isDelete: true,
          };
          const countryIds = accoutSettingListTemp
            .filter(
              (el) => el.continentId === regionId && el.channelId === channel
            )
            .map((el) => el.countryId)
            .filter((value, index, self) => self.indexOf(value) === index);
          countryIds.forEach((countryId) => {
            let countryObject = {
              countryName: accoutSettingList.find(
                (acc) => acc.countryId === countryId
              )
                ? accoutSettingList.find((acc) => acc.countryId === countryId)
                  .countryName
                : '',
              name: accoutSettingList.find((acc) => acc.countryId === countryId)
                ? accoutSettingList.find((acc) => acc.countryId === countryId)
                  .countryName
                : '',
              countryId: countryId,
              continentId: regionId,
              channelId: channel,
              children: [],
              isCountry: true,
              isDelete: true,
            };
            let currentAccounts = accounts.filter(
              (account) =>
                account.countryId === countryId &&
                account.regionId === regionId &&
                account.channelId === channel
            );
            currentAccounts = currentAccounts.map((element) => ({
              name: element.accountName,
              isAccount: true,
              ...element,
            }));
            countryObject.children = currentAccounts;
            if (countryObject.countryId) {
              regionObject.children.push(countryObject);
            }
            if (countryObject.children.length > 0) {
              channelObject.isDelete = false;
              regionObject.isDelete = false;
              countryObject.isDelete = false;
            }
          });
          if (regionObject.continentId) {
            channelObject.children.push(regionObject);
          }
        });
        treeList.push(channelObject);
      });

      const tree = [];
      treeList.forEach((channel) => {
        let channelObject = new TreeviewItem({
          text: channel.name,
          collapsed: true,
          value: channel.channelId + 'CH',
          checked: false,
          children: channel.children.map((region) => {
            return new TreeviewItem({
              text: region.name,
              collapsed: true,
              value: region.continentId + 'R',
              checked: false,
              children: region.children.map((country) => {
                return new TreeviewItem({
                  text: country.name,
                  collapsed: true,
                  value: country.countryId + 'C',
                  checked: false,
                  children: country.children.map((account) => {
                    return new TreeviewItem({
                      text: account.name,
                      collapsed: true,
                      value: account.itemAccountId,
                      ...account,
                      checked: false,
                    });
                  }),
                });
              }),
            });
          }),
        });
        tree.push(channelObject);
      });
      this.channelTree = tree;
    }
  };
  public getMeasuresByCode = () => {
    this.configurationService
      .DataManagerMeasuresGetList({
        code: 'SPFCST',
        fbTemplateId: FB_Template.Global_Planning,
      })
      .subscribe((res) => {
        this.fcstScenarioPlanningList = res;
      });
    this.configurationService
      .DataManagerMeasuresGetList({
        code: 'SP',
        fbTemplateId: FB_Template.Global_Planning,
      })
      .subscribe((res) => {
        this.scenarioPlanningList = res;
      });
    this.configurationService
      .DataManagerMeasuresGetList({
        code: 'UDPFCST',
        fbTemplateId: FB_Template.Global_Planning,
      })
      .subscribe((res) => {
        this.udpMeasuresList = res;
      });
    this.configurationService
      .DataManagerMeasuresGetList({
        code: 'UDP',
        fbTemplateId: FB_Template.Global_Planning,
      })
      .subscribe((res) => {
        this.udpfcstList = res;
      });
  };
  public extractParentsFromMeasures = (forecast?: any) => {
    const groupList = this.measureList.filter((measure) => !measure.parentId);
    this.leftGroupList = groupList.map((row) => ({
      ...row,
      rowspan: this.measureList.filter(
        (measure) => measure.parentId === row.measureId
      ).length,
    }));
    this.measureList = this.measureList
      .filter((row) => row.parentId)
      .map((row) => ({ ...row, checked: true }))
      .sort((a, b) => a.sort - b.sort);
  };
  public createCalendarListDropdownWithWeeks = (forecast?: any) => {
    if (this.timeframeList && this.timeframeList.length > 0) {
      this.timeFrameListWithWeeks = [];
      this.timeFrameListWithWeeksUPD = [];
      const uniqueYears = [
        ...new Set(
          this.timeframeList
            .filter((row) =>
              row && row?.calendarId ? row.calendarId.toString() : false
            )
            .map((item: Calendar) => item.fy)
        ),
      ]; // ['FY18']
      uniqueYears.forEach((year: string, index) => {
        const yearList = this.timeframeList.filter(
          (row) => row.fy === year && row.calendarId.toString()
          // forecast?.calendarIds.split(',').includes(row.calendarId.toString())
        );
        const uniqueHalfYears = [
          ...new Set(yearList.map((item: Calendar) => item.hy)),
        ]; // ['H1']
        const uniqueQuarters = [];
        const uniquemonths = [];
        yearList.forEach((y) => {
          if (
            uniqueQuarters.findIndex(
              (x) => x.quarter === y.quarter && x.hy === y.hy
            ) < 0
          ) {
            uniqueQuarters.push({ quarter: y.quarter, hy: y.hy });
          }
          if (
            uniquemonths.findIndex(
              (x) => x.quarter === y.quarter && x.month === y.month
            ) < 0
          ) {
            uniquemonths.push({ quarter: y.quarter, month: y.month });
          }
          y.checked = y.calendarIds
            ? y.calendarId.toString()
            : // forecast && forecast.calendarIds
            //   ? forecast.calendarIds
            //       .split(',')
            //       .find((val) => val === y.calendarId.toString())
            false;
        });
        // const uniqueQuarters = yearList.filter(year => year.quarter)
        const obj = new TreeviewItem({
          text: year,
          value: year,
          checked: false,
          collapsed: true,
          children: uniqueHalfYears.map(
            (h: string) =>
              new TreeviewItem({
                text: h,
                value: h,
                checked: false,
                collapsed: true,
                children: uniqueQuarters
                  .filter((e) => e.hy === h)
                  .map(
                    (q) =>
                      new TreeviewItem({
                        text: q.quarter,
                        value: q.quarter,
                        checked: false,
                        collapsed: true,
                        children: uniquemonths
                          .filter((el) => el.quarter === q.quarter)
                          .map((m) => ({
                            children: yearList
                              .filter(
                                (el) =>
                                  el.month === m.month &&
                                  el.quarter === q.quarter &&
                                  el.hy === h
                              )
                              .map(
                                (row) =>
                                  new TreeviewItem({
                                    text: new Date(
                                      row.weekStartDate
                                    ).toLocaleDateString(),
                                    value: row.calendarId,
                                    checked: false,
                                    collapsed: true,
                                  })
                              ),
                            text: m.month,
                            checked: yearList
                              .filter(
                                (el) =>
                                  el.month === m.month &&
                                  el.quarter === q.quarter &&
                                  el.hy === h
                              )
                              .find((el) => !el.checked)
                              ? false
                              : true,
                            value: yearList
                              .filter(
                                (el) =>
                                  el.month === m.month &&
                                  el.quarter === q.quarter &&
                                  el.hy === h
                              )
                              .map((cal) => cal.calendarId),
                          })),
                      })
                  ),
              })
          ),
        });
        this.timeFrameListWithWeeks.push(obj);
        this.timeFrameListWithWeeksUPD.push(obj);
      });
    }
  };

  filterAccounts(value) {
    if (value) {
      this.accountDetailList = this.accountFilterArray.filter((res) => {
        return res.distributorName
          .toLocaleLowerCase()
          .match(value.toLocaleLowerCase());
      });
      // this.accountDetailList = this.accountDetailList
      //   .filter(
      //     (row) =>
      //       row.distributorName
      //         .toLocaleLowerCase()
      //         .indexOf(value.toLocaleLowerCase()) > -1
      //   )
      //   .sort((a, b) => (a.distributorName > b.distributorName ? 1 : -1));
    } else {
      this.accountDetailList = [];
      this.orderStatusList = [];
      this.orderSourceList = [];
      this.getDataList();
    }
  }
  filterOrderSource(value) {
    if (value) {
      this.orderSourceList = this.orderSourceFilterArray.filter((row) => {
        return row.distributorName
          .toLocaleLowerCase()
          .match(value.toLocaleLowerCase());
      });
    } else {
      this.accountDetailList = [];
      this.orderStatusList = [];
      this.orderSourceList = [];
      this.getDataList();
    }
  }
  filterOrderStatus(value) {
    if (value) {
      this.orderStatusList = this.orderStatusFilterArray.filter((row) => {
        return row.distributorName
          .toLocaleLowerCase()
          .match(value.toLocaleLowerCase());
      });
    } else {
      this.accountDetailList = [];
      this.orderStatusList = [];
      this.orderSourceList = [];
      this.getDataList();
    }
  }
  filterOrderDate(value) {
    if (value) {
      this.orderDate = this.orderDateFilterArr.filter((res) => {
        return res.name.toLocaleLowerCase().match(value.toLocaleLowerCase());
      });
    } else {
      this.batchIdFilterArr = [];
      this.itemFilterArr = [];
      this.locationFilterArr = [];
      this.purchaseOrderFilterArr = [];
      this.requestedDeliverDateFilterArr = [];
      this.orderDateFilterArr = [];
      this.fetchData();
    }
  }
  filterRequestedDeliverDate(value) {
    if (value) {
      this.requestedDeliverDate = this.requestedDeliverDateFilterArr.filter(
        (row) => {
          return row.name.toLocaleLowerCase().match(value.toLocaleLowerCase());
        }
      );
    } else {
      this.batchIdFilterArr = [];
      this.itemFilterArr = [];
      this.locationFilterArr = [];
      this.purchaseOrderFilterArr = [];
      this.requestedDeliverDateFilterArr = [];
      this.orderDateFilterArr = [];
      this.fetchData();
    }
  }
  filterPurchaseOrder(value) {
    if (value) {
      this.purchaseOrder = this.purchaseOrderFilterArr.filter((row) => {
        return row.name.toLocaleLowerCase().match(value.toLocaleLowerCase());
      });
    } else {
      this.batchIdFilterArr = [];
      this.itemFilterArr = [];
      this.locationFilterArr = [];
      this.purchaseOrderFilterArr = [];
      this.requestedDeliverDateFilterArr = [];
      this.orderDateFilterArr = [];
      this.fetchData();
    }
  }
  filterLocation(value) {
    if (value) {
      this.location = this.locationFilterArr.filter((res) => {
        return res.name.toLocaleLowerCase().match(value.toLocaleLowerCase());
      });
    } else {
      this.batchIdFilterArr = [];
      this.itemFilterArr = [];
      this.locationFilterArr = [];
      this.purchaseOrderFilterArr = [];
      this.requestedDeliverDateFilterArr = [];
      this.orderDateFilterArr = [];
      this.fetchData();
    }
  }
  filterItem(value) {
    if (value) {
      this.item = this.itemFilterArr.filter((row) => {
        return row.name.toLocaleLowerCase().match(value.toLocaleLowerCase());
      });
    } else {
      this.batchIdFilterArr = [];
      this.itemFilterArr = [];
      this.locationFilterArr = [];
      this.purchaseOrderFilterArr = [];
      this.requestedDeliverDateFilterArr = [];
      this.orderDateFilterArr = [];
      this.fetchData();
    }
  }
  filterBatchId(value) {
    if (value) {
      this.batchId = this.batchIdFilterArr.filter((row) => {
        return row.name.toLocaleLowerCase().match(value.toLocaleLowerCase());
      });
    } else {
      this.batchIdFilterArr = [];
      this.itemFilterArr = [];
      this.locationFilterArr = [];
      this.purchaseOrderFilterArr = [];
      this.requestedDeliverDateFilterArr = [];
      this.orderDateFilterArr = [];
      this.fetchData();
    }
  }

  selectAccounts(distributor) {
    const accountIds = this.orderSourceList
      .filter((row) => distributor.distributorId === row.distributorId)
      .map((row) => row.itemAccountId);
    // this.selectedChannelIds = accountIds;
    // this.channelIds = accountIds;
    // this.dropdownObjects = {
    //   itemIds: this.itemIds || [],
    //   channelIds: this.channelIds,
    //   calendarIds: this.calendarIds || [],
    // };
    // if (!this.selectedDistibutor) {
    //   this.selectedDistibutor = distributor;
    // }
    // this.selectedAccounts = this.accountList
    // .filter((row) => distributor.distributorId === row.distributorId)
  }
  setValue = (selectedDropdown) => {
    switch (
    selectedDropdown.type
    // case 'Item': {
    //   this.itemIds = selectedDropdown.value;
    //   break;
    // }
    // case 'Channel': {
    //   this.channelIds = selectedDropdown.value;
    //   this.selectedChannelIds = selectedDropdown.value;
    //   break;
    // }
    // case 'Timeframe': {
    //   this.calendarIds = selectedDropdown.value;
    //   break;
    // }
    // case 'TimeframeWithWeeksUDP': {
    //   this.calendarIdsUDP = selectedDropdown.value;
    //   break;
    // }
    // case 'TimeframeWithWeeksScenario': {
    //   this.calendarIdsScenario = selectedDropdown.value;
    //   break;
    // }
    ) {
    }
    // this.channelIds =
    //   this.channelIds.filter((el) => typeof el === 'number') || [];
    // this.itemIds = this.itemIds.filter((el) => typeof el === 'number') || [];
    // this.dropdownObjects = {
    //   itemIds: this.itemIds || [],
    //   channelIds: this.channelIds,
    //   calendarIds: this.calendarIds || [],
    // };
    // this.updatedDropdownObject = this.dropdownObjects;
    // if (this.isOpenSaved || this.isRestoreSession) {
    //   this.mapGridList(true);
    // }
  };

  getOptionsText(select) {
    return getCountOptions(select);
  }

  public closeScreen = () => {
    this.configurationService.removeMenu.next(this.data);
  };

  applyUDP() {
    // const forecastItems = this.configurationService.tempForecastList.filter(
    //   (el) => {
    //     return (
    //       el.measureId === this.selectedForecastMeasure.measureId &&
    //       this.calendarIdsUDP.includes(el.calendarId)
    //     );
    //   }
    // );
    // const measure = this.measureList.find(
    //   (row) => row.measureId === this.selectedUDPMeasure.measureId
    // );
    // const UDPItems = this.configurationService.tempForecastList.filter((el) => {
    //   return (
    //     el.measureId === this.selectedUDPMeasure.measureId &&
    //     this.calendarIdsUDP.includes(el.calendarId)
    //   );
    // });
    // forecastItems.forEach((row) => {
    //   const index = UDPItems.findIndex(
    //     (el) =>
    //       el.calendarId === row.calendarId &&
    //       el.itemId === row.itemId &&
    //       el.channelId === row.channelId
    //   );
    //   if (index > -1) {
    //     UDPItems[index].value = row.value;
    //     UDPItems[index].measureCode = measure?.code;
    //   }
    // });
    // let forecast = new FbForecast(null);
    // forecast.itemIds = this.dropdownObjects.itemIds.toString();
    // forecast.channelIds = this.dropdownObjects.channelIds.toString();
    // forecast.calendarIds = this.dropdownObjects.calendarIds.flat().toString();
    // forecast.userId = this.storage.get(USER_ID);
    // forecast.versionTypeId = this.versionTypeId;
    // forecast.fbTemplateId = FB_Template.Global_Planning;
    // forecast.forecastMethodId = this.forecastMethod;
    // forecast.seasonalitySourceLevelId = this.seasonalitySourceLevel;
    // forecast.movingAverageWeeksId = this.movingAverageWeeks;
    // forecast.fbForecasts = UDPItems;
    // forecast.userFBForecastId = this.userFBForecastId || 0;
    // if (UDPItems.length > 0) {
    //   this.spinner.show();
    //   this.configurationService.AddFbForecast(forecast).subscribe(
    //     (res) => {
    //       UDPItems.forEach((row) => {
    //         const index = this.configurationService.tempForecastList.findIndex(
    //           (el) =>
    //             row.measureId === el.measureId &&
    //             el.calendarId === row.calendarId &&
    //             el.itemId === row.itemId &&
    //             el.channelId === row.channelId
    //         );
    //         if (index > -1) {
    //           this.configurationService.tempForecastList[index].value =
    //             row.value;
    //         }
    //       });
    //       this.configurationService.updateForecastList.next(UDPItems);
    //       this.selectedUDPMeasure = null;
    //       this.selectedForecastMeasure = null;
    //       this.createCalendarListDropdownWithWeeks(this.fbForecast);
    //       this.spinner.hide();
    //     },
    //     (error) => {
    //       this.spinner.hide();
    //     }
    //   );
    // }
  }

  isAnyOpened = () => {
    return document.getElementsByClassName('dropdown show').length > 0;
  };
  public mapGridList = async (isOnload) => {
    // this.product = '';
    // this.account = '';
    // this.itemIds.forEach((element) => {
    //   this.product = this.itemsList.find(
    //     (x) => x.itemManagerId == Number(element)
    //   )
    //     ? this.product +
    //       this.itemsList.find((x) => x.itemManagerId == Number(element))
    //         .brandName +
    //       ';'
    //     : this.product;
    // });
    // this.channelIds.forEach((element) => {
    //   this.account = this.accountList.find(
    //     (x) => x.accountTypeId == Number(element)
    //   )
    //     ? this.account +
    //       this.accountList.find((x) => x.accountTypeId == Number(element))
    //         .regionName +
    //       ';'
    //     : this.account;
    // });
    this.gridMapped = true;
    // if ((this.isOpenSaved || this.isRestoreSession) && !isOnload) {
    //   this.isOpenSaved = false;
    //   this.isRestoreSession = false;
    // }
    // this.spinner.show();
    if (this.isBuidlNew && this.isShowTreeDropDowns) {
      this.restoreAccountDetailList = [...this.accountDetailList];
      this.restoreCalendarList = [...this.timeFrameListWithWeeks];
      this.restoreOrderSourceList = [...this.orderSourceList];
      this.restoreOrderStatusList = [...this.orderStatusList];
      this.fetchData();
      // if (
      //   this.fbForecast &&
      //   this.fbForecast.itemIds &&
      //   this.fbForecast.itemIds.match(/^[a-z0-9]+$/i)
      // ) {
      //   this.fbForecast.itemIds = this.itemIds;
      // }
      // let model;
      // model = {
      //   userId: this.storage.get(USER_ID),
      //   userFBForecastId: 0,
      //   itemIds:
      //     this.itemIds.length > 0
      //       ? this.itemIds.toString()
      //       : this.fbForecast.itemIds?.toString() || '',
      //   channelIds:
      //     this.channelIds && this.channelIds.length > 0
      //       ? this.channelIds?.toString()
      //       : this.fbForecast.channelIds || '',
      //   calendarIds:
      //     this.calendarIds.length > 0
      //       ? this.calendarIds.toString()
      //       : this.fbForecast.calendarIds?.toString() || '',
      //   fbTemplateId: FB_Template.Retail_Direct,
      // };
      // this.fbForecast = await this.configurationService
      //   .GetFbForecast(model)
      //   .toPromise()
      //   .catch((error) => {
      //     this.spinner.hide();
      //     throwError(error);
      //   });
      // if (
      //   this.fbForecast &&
      //   this.fbForecast.fbForecasts &&
      //   this.fbForecast.fbForecasts.length > 0
      // ) {
      //   this.fbForecast.fbForecasts = this.fbForecast.fbForecasts.map((row) => {
      //     return {
      //       fbForecastId: row.fbForecastId,
      //       itemId: row.itemId,
      //       channelId: row.channelId,
      //       measureId: row.measureId,
      //       calendarId: row.calendarId,
      //       isEdited: true,
      //       value: row.value,
      //       textValue: row.textValue || '',
      //       userFBForecastId: row.userFBForecastId || 0,
      //       versionTypeId: this.versionTypeId,
      //       userId: this.storage.get(USER_ID),
      //       fbTemplateId: FB_Template.Retail_Direct,
      //       isMaster: true,
      //     };
      //   });
      //   model = {
      //     ...model,
      //     versionTypeId: 2,
      //     fbForecasts: this.fbForecast.fbForecasts,
      //   };
      //   this.configurationService.AddFbForecast(model).subscribe((res) => {});
      // }
      // this.isShowTreeDropDowns = false;
    }
    // this.mapChannelScrollingList(this.channelIds.toString());
    // this.mapProductScrollingList(this.itemIds.toString());
    // this.forecastList = [];
    // this.createGridList();
  };

  setRestoredValue = (selectedDropdown) => {
    switch (
    selectedDropdown.type
    //   case 'Item': {
    //     this.itemIds = selectedDropdown.value;
    //     break;
    //   }
    //   case 'Channel': {
    //     this.channelIds = selectedDropdown.value;
    //     this.selectedChannelIds = selectedDropdown.value;
    //     break;
    //   }
    //   case 'Timeframe': {
    //     this.calendarIds = selectedDropdown.value;
    //     break;
    //   }
    // }
    // this.channelIds =
    //   this.channelIds.filter((el) => typeof el === 'number') || [];
    // this.itemIds = this.itemIds.filter((el) => typeof el === 'number') || [];
    // this.dropdownObjects = {
    //   itemIds: this.itemIds || [],
    //   channelIds: this.channelIds,
    //   calendarIds: this.calendarIds || [],
    ) {
    }
  };

  onSelectionChanged(event) {
    if (event.value.includes(-1) > 0) {
      //   this.refreshMeasureIds = [
      //     -1,
      //     ...this.measureList.map((el) => el.measureId),
      //   ];
      //   this.selectedMeasures = this.refreshMeasureIds;
      // } else {
      //   this.refreshMeasureIds = event.value;
    }
  }
  public fetchData() {
    this.batchId.push(
      { id: 1, name: '12345WAu' },
      { id: 2, name: '123456ASD' },
      { id: 3, name: '12222RE' },
      { id: 4, name: 'RA22111' }
    );
    this.batchIdFilterArr = this.batchId;

    this.item.push(
      { id: 1, name: 'Microsoft Aws' },
      { id: 2, name: 'Microsoft Azure' },
      { id: 3, name: 'Microsoft 365' },
      { id: 4, name: 'Asp.netCore' }
    );
    this.itemFilterArr = this.item;
    this.location.push(
      { id: 1, name: 'Punjab' },
      { id: 2, name: 'Lahore' },
      { id: 3, name: 'Khyber Pakhton Khan' },
      { id: 4, name: 'Balochistan' }
    );
    this.locationFilterArr = this.location;
    this.purchaseOrder.push(
      { id: 1, name: 'order1' },
      { id: 2, name: 'order2' },
      { id: 3, name: 'order3' },
      { id: 4, name: 'order4' }
    );
    this.purchaseOrderFilterArr = this.purchaseOrder;
    this.requestedDeliverDate.push(
      { id: 1, name: '31 Dec' },
      { id: 2, name: '20 Dec' },
      { id: 3, name: '5 Jan' },
      { id: 4, name: '10 October' }
    );
    this.requestedDeliverDateFilterArr = this.requestedDeliverDate;
    this.orderDate.push(
      { id: 1, name: '1 jan 21' },
      { id: 2, name: '2 Feb 21' },
      { id: 3, name: '30 October 21' },
      { id: 4, name: '1 November 21' }
    );
    this.orderDateFilterArr = this.orderDate;
  }
}
