import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllCommunityModules, CellClickedEvent } from '@ag-grid-community/all-modules';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DecayCurveFormComponent } from './decay-curve-form/decay-curve-form.component';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-decay-curve',
  templateUrl: './decay-curve.component.html',
  styleUrls: ['./decay-curve.component.css']
})
export class DecayCurveComponent implements OnInit {

  public classList = [{ classCode: 1, classCodeDescription: 'TV' }, { classCode: 2, classCodeDescription: 'Theatrical' }];
  public subClassList = [
    { subClassCode: 1, subClassCodeDescription: 'DVD' },
    { subClassCode: 2, subClassCodeDescription: 'BD' },
    { subClassCode: 3, subClassCodeDescription: '3D' },
    { subClassCode: 4, subClassCodeDescription: 'UHD' }
  ];
  public columnDefs = [
    {
      headerName: 'Decay Curve Name',
      field: 'decayCurveName',
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openModal.bind(this),
      },
      pinned: 'left',
      width: 150
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
      cellRenderer: (params) => {
        return `${params.value ? (new Date(params.value)).toLocaleDateString() : ''}`;
      }
    },
    {
      headerName: 'End Date',
      field: 'endDate',
      cellRenderer: (params) => {
        return `${params.value ? (new Date(params.value)).toLocaleDateString() : ''}`;
      }
    },
    {
      headerName: 'Class',
      field: 'class',
      width: 150
    },
    {
      headerName: 'SubClass',
      field: 'subClass',
      width: 150
    },
    {
      headerName: 'Customer',
      field: 'customer'
    },
    {
      headerName: 'Curve Type',
      field: 'curveType'
    },
    // {
    //   headerName: 'Total Units',
    //   field: 'totalUnits'
    // },
    {
      headerName: '',
      field: 'delete',
      resizable: true,
      filter: false,
      pinned: 'right',
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveGoalDialog.bind(this),
      },
      width: 80
    },
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public DecayCurveList = [];
  public modules = AllCommunityModules;
  public user: any;
  constructor(public router: Router,
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DecayCurveFormComponent>,
    public matDialogRef: MatDialogRef<any>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent,
        nameRenderer: NameRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    this.getDecayCurveList();
  }
  public getDecayCurveList = () => {
    this.configurationService.DecayCurveGetAll({}).subscribe(res => {
      this.DecayCurveList = res;
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.itemDesciption }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeDecayCurve(row.rowData);
      }
    });
  }
  public removeDecayCurve(selectedItem: any) {
    if (selectedItem) {
      const model = {
        ...selectedItem,
        active: false
      };
      this.spinner.show();
      this.configurationService.DecayCurveActivate(model).subscribe(res => {
        if (res) {
          this.spinner.hide();
          this.toastr.success('Decay Curve', 'Item removed successfully');
          this.DecayCurveList = this.DecayCurveList.filter(row => row.decayCurveId !== model.decayCurveId);
          this.gridOptions.api.setRowData(this.DecayCurveList);
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('Decay Curve', error.error.text);
      });
    }
  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  // onCellClicked($event: CellClickedEvent) {
  //   if ($event && $event.colDef.field === 'decayCurveName') {
  //     this.openModal($event.data);
  //   }
  // }
  public openModal (data?: any) {
    const dialogRef = this.dialog.open(DecayCurveFormComponent, { width: '800px', data: { ...data } });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDecayCurveList();
      }
    });
  }
}
