import { TreeviewItem } from 'ngx-treeview';
import { Month,HalfYear, PhysicalYear, Quarter } from 'src/modules/facebook/features/global.models';
import { Calendar } from './facebook.models';

export const EqualValueMeasures = [
  'GDPManualFcstAdjustmentPct',
  'GDPPromoFcstAdjustmentPct',
  'RDPManualFcstAdjustmentPct',
  'RDPPromoFcstAdjustmentPct',
  'CustomerInStockEstimate',
  'AccountTargetFWOS',
  'DistiTargetFWOS',
  'AlgoTFvsGDPTotalForecastPct',
  'AlgoTFvsUDPLikelyTotalFcstPct',
  'AlgoTFvsRDPTotalForecastPct',
  'GDPTotalFcstvsUDPLikelyTotalFcstPct',
  'GDPTotalFcstvsCustomerSTFcstPct',
  'GDPTotalFcstvsSalesGoalPct',
  'GDPTotalFcstvsCOSellThruOverrideFcstPct',
  'GDPTotalFcstvsActualSTPct',
  'RDPTotalFcstvsUDPLikelyTotalFcstPct',
  'RDPTotalFcstvsCustomerSTFcstPct',
  'RDPTotalFcstvsSalesGoalPct',
  'RDPTotalFcstvsCOSellThruOverrideFcstPct',
  'RDPTotalFcstvsActualSTPct',
  'UDPLikelyTotalFcstvsActualSTPct',
  'UDPLikelyTotalFcstvsCustomerSTFcstPct',
  'CPFRSTOverrideFcstvsUDPLikelyTotalFcstPct',
  'CustomerInStockEstimatePct',
  'ActualSTvsUDPLikelyTotalFcstPct',
  'ActualSTvsConstrainedSTFcstPct',
  'ActualSTvsCustomerSTFcstPct',
  'ActualSTvsCPFRSTOverrideFcstPct',
  'ActualSTWOWPct',
  'UDPHighTotalFcstvsUDPLikelyTotalFcstPct',
  'UDPLowTotalFcstvsUDPLikelyTotalFcstPct',
  'GDPHighTotalFcstvsGDPTotalFcstPct',
  'GDPLowTotalFcstvsGDPTotalFcstPct',
  'GDPHighTotalFcstvsUDPHighTotalFcstPct',
  'GDPLowTotalFcstvsUDPLowTotalFcstPct',
  'RDPHighTotalFcstvsRDPTotalFcstPct',
  'RDPLowTotalFcstvsRDPTotalFcstPct',
  'RDPHighTotalFcstvsUDPHighTotalFcstPct',
  'RDPLowTotalFcstvsUDPLowTotalFcstPct',
  'GDPHighTotalFcstvsRDPHighTotalFcstPct',
  'GDPTotalForecastvsRDPTotalForecastPct',
  'GDPLowTotalForecastvsRDPLowTotalForecastPct',
  'RDPHighTotalForecastvsGDPHighTotalForecastPct',
  'RDPTotalForecastvsGDPTotalForecastPct',
  'RDPLowTotalForecastvsGDPLowTotalForecastPct',
  'LYCustomerInStockEstimatePct',
  'TYActualSTvsLYActualSTPct',
  'GDPTotalFcstvsLYActualSTPct',
  'RDPTotalFcstvsLYActualSTPct',
  'UDPLikelyTotalFcstvsLYActualSTPct',
  'GDPPromoFcstAdjustmentPct',
  'RDPPromoFcstAdjustmentPct',
  'TYDistiActualSTvsLYDistiActualSTPct'
];
export const onKeyDown = (event: KeyboardEvent, measureCode, week?) => {
  if (measureCode && measureCode.indexOf('Comment') < 0) {
    const keys = ['Backspace', 'ArrowRight', 'ArrowLeft', '-'];
    const currentInput = (event.target as any).value;
    return keys.includes(event.key) ||
      (event.key === '.' && currentInput.indexOf('.') < 0) ||
      event.key.match(/^\d+(\.\d+)?$/)
      ? true
      : false;
  }
};
export const addCommas = (week, field: string) => {
  const measures = [
    'AccountConstrainedFWOS',
    'AccountTargetFWOS',
    'DistiConstrainedFWOS',
    'FBProjectedFWOS',
  ];
  if (
    !measures.includes(field) &&
    field.indexOf('Comment') < 0 &&
    week[field] &&
    week[field].toString().indexOf('-') < 0 &&
    field.indexOf('Pct') < 0
  ) {
    week[field] = week[field] ? week[field].toString() : '';
    week[field] = week[field].replaceAll(',', '');
    week[field] = Math.round(Number(week[field])).toLocaleString('en');
    return week[field];
  } else if (measures.includes(field)) {
    week[field] = week[field] ? week[field].toString() : '';
    week[field] = week[field].replaceAll(',', '');
    week[field] = Number(Number(week[field]).toFixed(2)).toLocaleString('en');
  }
  return week[field];
};
export const setFocusOnNextInput = (
  index,
  field,
  gridData,
  measureList,
  measureIndex
) => {
  if (index < gridData.length - 1) {
    const dynamicId = `${index + 1}${field}${measureIndex}`;
    document.getElementById(dynamicId).focus();
  } else {
    const measure = measureList[measureIndex + 1];
    document.getElementById(`0${measure.code}`).focus();
  }
};

export const getCountOptions = (select) => {
  return select.value && select.value.length > 1
    ? `${select.value.length} Options Selected`
    : select.value?.toString();
};
export const CreateCalendarListWithWeeks = (
  timeframeList,
  timeFrameListWithWeeks,
  forecast
) => {
  if (timeframeList && timeframeList.length > 0 && forecast) {
    timeFrameListWithWeeks = [];
    const uniqueYears = [
      ...new Set(
        timeframeList
          .filter((row) =>
            forecast && forecast?.calendarIds
              ? forecast?.calendarIds
                  .split(',')
                  .includes(row.calendarId.toString())
              : false
          )
          .map((item: Calendar) => item.fy)
      ),
    ]; // ['FY18']
    uniqueYears.forEach((year: string, index) => {
      const yearList = timeframeList.filter(
        (row) =>
          row.fy === year &&
          forecast?.calendarIds.split(',').includes(row.calendarId.toString())
      );
      const uniqueHalfYears = [
        ...new Set(yearList.map((item: Calendar) => item.hy)),
      ]; // ['H1']
      const uniqueQuarters = [];
      const uniquemonths = [];
      yearList.forEach((y) => {
        if (
          uniqueQuarters.findIndex(
            (x) => x.quarter === y.quarter && x.hy === y.hy
          ) < 0
        ) {
          uniqueQuarters.push({ quarter: y.quarter, hy: y.hy });
        }
        if (
          uniquemonths.findIndex(
            (x) => x.quarter === y.quarter && x.month === y.month
          ) < 0
        ) {
          uniquemonths.push({ quarter: y.quarter, month: y.month });
        }
        y.checked =
          forecast && forecast.calendarIds
            ? forecast.calendarIds
                .split(',')
                .find((val) => val === y.calendarId.toString())
            : false;
      });
      // const uniqueQuarters = yearList.filter(year => year.quarter)
      const obj = new TreeviewItem({
        text: year,
        value: year,
        checked: false,
        children: uniqueHalfYears.map(
          (h: string) =>
            new TreeviewItem({
              text: h,
              value: h,
              checked: false,
              children: uniqueQuarters
                .filter((e) => e.hy === h)
                .map(
                  (q) =>
                    new TreeviewItem({
                      text: q.quarter,
                      value: q.quarter,
                      checked: false,
                      children: uniquemonths
                        .filter((el) => el.quarter === q.quarter)
                        .map((m) => ({
                          children: yearList
                            .filter(
                              (el) =>
                                el.month === m.month &&
                                el.quarter === q.quarter &&
                                el.hy === h
                            )
                            .map(
                              (row) =>
                                new TreeviewItem({
                                  text: new Date(
                                    row.weekStartDate
                                  ).toLocaleDateString(),
                                  value: row.calendarId,
                                  checked: false,
                                })
                            ),
                          text: m.month,
                          checked: yearList
                            .filter(
                              (el) =>
                                el.month === m.month &&
                                el.quarter === q.quarter &&
                                el.hy === h
                            )
                            .find((el) => !el.checked)
                            ? false
                            : true,
                          value: yearList
                            .filter(
                              (el) =>
                                el.month === m.month &&
                                el.quarter === q.quarter &&
                                el.hy === h
                            )
                            .map((cal) => cal.calendarId),
                        })),
                    })
                ),
            })
        ),
      });
      timeFrameListWithWeeks.push(obj);
    });
  }
  return timeFrameListWithWeeks;
};
export const CreateCalendarListWithoutWeeks = (
  timeframeList,
  timeFrameListWithWeeks,
  forecast
) => {
  if (timeframeList && timeframeList.length > 0 && forecast) {
    timeFrameListWithWeeks = [];
    const uniqueYears = [
      ...new Set(
        timeframeList
          .filter((row) =>
            forecast && forecast?.calendarIds
              ? forecast?.calendarIds
                  .split(',')
                  .includes(row.calendarId.toString())
              : false
          )
          .map((item: Calendar) => item.fy)
      ),
    ]; // ['FY18']
    uniqueYears.forEach((year: string, index) => {
      const yearList = timeframeList.filter(
        (row) =>
          row.fy === year &&
          forecast?.calendarIds.split(',').includes(row.calendarId.toString())
      );
      const uniqueHalfYears = [
        ...new Set(yearList.map((item: Calendar) => item.hy)),
      ]; // ['H1']
      const uniqueQuarters = [];
      const uniquemonths = [];
      yearList.forEach((y) => {
        if (
          uniqueQuarters.findIndex(
            (x) => x.quarter === y.quarter && x.hy === y.hy
          ) < 0
        ) {
          uniqueQuarters.push({ quarter: y.quarter, hy: y.hy });
        }
        if (
          uniquemonths.findIndex(
            (x) => x.quarter === y.quarter && x.month === y.month
          ) < 0
        ) {
          uniquemonths.push({ quarter: y.quarter, month: y.month });
        }
        y.checked =
          forecast && forecast.calendarIds
            ? forecast.calendarIds
                .split(',')
                .find((val) => val === y.calendarId.toString())
            : false;
      });
      // const uniqueQuarters = yearList.filter(year => year.quarter)
      const obj = new TreeviewItem({
        text: year,
        value: year,
        checked: true,
        disabled: true,
        children: uniqueHalfYears.map(
          (h: string) =>
            new TreeviewItem({
              text: h,
              value: h,
              checked: true,
              disabled: true,
              children: uniqueQuarters
                .filter((e) => e.hy === h)
                .map(
                  (q) =>
                    new TreeviewItem({
                      text: q.quarter,
                      value: q.quarter,
                      checked: true,
                      disabled: true,
                      children: uniquemonths
                        .filter((el) => el.quarter === q.quarter)
                        .map((m) => ({
                          text: m.month,
                          checked: true,
                          disabled: true,
                          value: yearList
                            .filter(
                              (el) =>
                                el.month === m.month &&
                                el.quarter === q.quarter &&
                                el.hy === h
                            )
                            .map((cal) => cal.calendarId),
                        })),
                    })
                ),
            })
        ),
      });
      timeFrameListWithWeeks.push(obj);
    });
  }
  return timeFrameListWithWeeks;
};
export const CreateCalendarList = (
  calendarListDropdown,
  timeframeList,
  forecast
) => {
  calendarListDropdown = [];
  const uniqueYears = [
    ...new Set(timeframeList.map((item: Calendar) => item.fy)),
  ]; // ['FY18']
  uniqueYears.forEach((year: string, index) => {
    const yearList = timeframeList.filter((row) => row.fy === year);
    const uniqueHalfYears = [
      ...new Set(yearList.map((item: Calendar) => item.hy)),
    ]; // ['H1']
    const uniqueQuarters = [];
    const uniquemonths = [];
    yearList.forEach((y) => {
      if (
        uniqueQuarters.findIndex(
          (x) => x.quarter === y.quarter && x.hy === y.hy
        ) < 0
      ) {
        uniqueQuarters.push({ quarter: y.quarter, hy: y.hy });
      }
      if (
        uniquemonths.findIndex(
          (x) => x.quarter === y.quarter && x.month === y.month
        ) < 0
      ) {
        uniquemonths.push({ quarter: y.quarter, month: y.month });
      }
      y.checked =
        forecast && forecast.calendarIds
          ? forecast.calendarIds
              .split(',')
              .find((val) => val === y.calendarId.toString())
          : false;
    });
    // const uniqueQuarters = yearList.filter(year => year.quarter)
    const obj = new TreeviewItem({
      text: year,
      value: year,
      collapsed: true,
      checked: false,
      children: uniqueHalfYears.map(
        (h: string) =>
          new TreeviewItem({
            text: h,
            value: h,
            collapsed: true,
            checked: false,
            children: uniqueQuarters
              .filter((e) => e.hy === h)
              .map(
                (q) =>
                  new TreeviewItem({
                    text: q.quarter,
                    value: q.quarter,
                    collapsed: true,
                    checked: false,
                    children: uniquemonths
                      .filter((el) => el.quarter === q.quarter)
                      .map((m) => ({
                        text: m.month,
                        collapsed: true,
                        checked: yearList
                          .filter(
                            (el) =>
                              el.month === m.month &&
                              el.quarter === q.quarter &&
                              el.hy === h
                          )
                          .find((el) => !el.checked)
                          ? false
                          : true,
                        value: yearList
                          .filter(
                            (el) =>
                              el.month === m.month &&
                              el.quarter === q.quarter &&
                              el.hy === h
                          )
                          .map((cal) => cal.calendarId),
                      })),
                  })
              ),
          })
      ),
    });
    calendarListDropdown.push(obj);
  });
  return calendarListDropdown;
};
export const CreateAccountTree = (accoutSettingList, accountList, forecast) => {
  const channelIds = [];
  const accoutSettingListTemp = accoutSettingList;
  accoutSettingList.forEach((account) => {
    if (channelIds.includes(account.channelId)) {
    } else {
      channelIds.push(account.channelId);
    }
  });
  let treeList = [];
  channelIds.forEach((channel) => {
    let channelObject = {
      channelName: accoutSettingList.find((acc) => acc.channelId === channel)
        ? accoutSettingList.find((acc) => acc.channelId === channel).channelName
        : '',
      name: accoutSettingList.find((acc) => acc.channelId === channel)
        ? accoutSettingList.find((acc) => acc.channelId === channel).channelName
        : '',
      channelId: channel,
      children: [],
      isChannel: true,
      isDelete: true,
    };
    const regionIds = accoutSettingListTemp
      .filter((el) => el.channelId === channel)
      .map((el) => el.continentId)
      .filter((value, index, self) => self.indexOf(value) === index);
    regionIds.forEach((regionId) => {
      let regionObject = {
        continentName: accoutSettingList.find(
          (acc) => acc.continentId === regionId
        )
          ? accoutSettingList.find((acc) => acc.continentId === regionId)
              .continentName
          : '',
        name: accoutSettingList.find((acc) => acc.continentId === regionId)
          ? accoutSettingList.find((acc) => acc.continentId === regionId)
              .continentName
          : '',
        continentId: regionId,
        channelId: channel,
        children: [],
        isRegion: true,
        isDelete: true,
      };
      const countryIds = accoutSettingListTemp
        .filter((el) => el.continentId === regionId && el.channelId === channel)
        .map((el) => el.countryId)
        .filter((value, index, self) => self.indexOf(value) === index);
      countryIds.forEach((countryId) => {
        let countryObject = {
          countryName: accoutSettingList.find(
            (acc) => acc.countryId === countryId
          )
            ? accoutSettingList.find((acc) => acc.countryId === countryId)
                .countryName
            : '',
          name: accoutSettingList.find((acc) => acc.countryId === countryId)
            ? accoutSettingList.find((acc) => acc.countryId === countryId)
                .countryName
            : '',
          countryId: countryId,
          continentId: regionId,
          channelId: channel,
          children: [],
          isCountry: true,
          isDelete: true,
        };
        let accounts = accountList.filter(
          (account) =>
            account.countryId === countryId &&
            account.regionId === regionId &&
            account.channelId === channel
        );
        accounts = accounts.map((element) => ({
          name: element.accountName,
          isAccount: true,
          ...element,
        }));
        countryObject.children = accounts;
        if (countryObject.countryId) {
          regionObject.children.push(countryObject);
        }
        if (countryObject.children.length > 0) {
          channelObject.isDelete = false;
          regionObject.isDelete = false;
          countryObject.isDelete = false;
        }
      });
      if (regionObject.continentId) {
        channelObject.children.push(regionObject);
      }
    });
    treeList.push(channelObject);
  });

  const tree = [];
  treeList.forEach((channel) => {
    let channelObject = new TreeviewItem({
      text: channel.name,
      collapsed: true,
      value: channel.channelId + 'CH',
      checked:
        forecast && forecast.channelIds
          ? forecast.channelIds.includes((channel.channelId + 'CH').toString())
          : false,
      children: channel.children.map((region) => {
        return new TreeviewItem({
          text: region.name,
          collapsed: true,
          value: region.continentId + 'R',
          checked:
            forecast && forecast.channelIds
              ? forecast.channelIds.includes(
                  (region.continentId + 'R').toString()
                )
              : false,
          children: region.children.map((country) => {
            return new TreeviewItem({
              text: country.name,
              collapsed: true,
              value: country.countryId + 'C',
              checked:
                forecast && forecast.channelIds
                  ? forecast.channelIds.includes(
                      (country.countryId + 'C').toString()
                    )
                  : false,
              children: country.children.map((account) => {
                return new TreeviewItem({
                  text: account.name,
                  collapsed: true,
                  value: account.itemAccountId,
                  ...account,
                  checked:
                    forecast && forecast.channelIds
                      ? forecast.channelIds.includes(
                          account.itemAccountId.toString()
                        )
                      : false,
                });
              }),
            });
          }),
        });
      }),
    });
    tree.push(channelObject);
  });
  return tree;
};
export const CreateAccountScrollingList = (
  channelIds,
  accountList,
  channelSettingList
) => {
  if (channelIds) {
    let ids = channelIds.split(',');
    const accounts = accountList.filter((row) =>
      ids.includes(row.itemAccountId.toString())
    );
    const accoutSettingList = channelSettingList.filter((account) =>
      accounts.find(
        (el) =>
          el.channelId === account.channelId &&
          el.regionId === account.continentId &&
          el.countryId === account.countryId &&
          el.distributorId === account.distributorId
      )
    );
    const accoutSettingListTemp = accoutSettingList;
    const selectedChannelIds = [];
    accoutSettingList.forEach((account) => {
      if (selectedChannelIds.includes(account.channelId)) {
      } else {
        selectedChannelIds.push(account.channelId);
      }
    });
    let treeList = [];
    selectedChannelIds.forEach((channel) => {
      let channelObject = {
        channelName: accoutSettingList.find((acc) => acc.channelId === channel)
          ? accoutSettingList.find((acc) => acc.channelId === channel)
              .channelName
          : '',
        name: accoutSettingList.find((acc) => acc.channelId === channel)
          ? accoutSettingList.find((acc) => acc.channelId === channel)
              .channelName
          : '',
        channelId: channel,
        children: [],
        isChannel: true,
        isDelete: true,
      };
      const regionIds = accoutSettingListTemp
        .filter((el) => el.channelId === channel)
        .map((el) => el.continentId)
        .filter((value, index, self) => self.indexOf(value) === index);
      regionIds.forEach((regionId) => {
        let regionObject = {
          continentName: accoutSettingList.find(
            (acc) => acc.continentId === regionId
          )
            ? accoutSettingList.find((acc) => acc.continentId === regionId)
                .continentName
            : '',
          name: accoutSettingList.find((acc) => acc.continentId === regionId)
            ? accoutSettingList.find((acc) => acc.continentId === regionId)
                .continentName
            : '',
          continentId: regionId,
          channelId: channel,
          children: [],
          isRegion: true,
          isDelete: true,
        };
        const countryIds = accoutSettingListTemp
          .filter(
            (el) => el.continentId === regionId && el.channelId === channel
          )
          .map((el) => el.countryId)
          .filter((value, index, self) => self.indexOf(value) === index);
        countryIds.forEach((countryId) => {
          let countryObject = {
            countryName: accoutSettingList.find(
              (acc) => acc.countryId === countryId
            )
              ? accoutSettingList.find((acc) => acc.countryId === countryId)
                  .countryName
              : '',
            name: accoutSettingList.find((acc) => acc.countryId === countryId)
              ? accoutSettingList.find((acc) => acc.countryId === countryId)
                  .countryName
              : '',
            countryId: countryId,
            continentId: regionId,
            channelId: channel,
            children: [],
            isCountry: true,
            isDelete: true,
          };
          let currentAccounts = accounts.filter(
            (account) =>
              account.countryId === countryId &&
              account.regionId === regionId &&
              account.channelId === channel
          );
          currentAccounts = currentAccounts.map((element) => ({
            name: element.accountName,
            isAccount: true,
            ...element,
          }));
          countryObject.children = currentAccounts;
          if (countryObject.countryId) {
            regionObject.children.push(countryObject);
          }
          if (countryObject.children.length > 0) {
            channelObject.isDelete = false;
            regionObject.isDelete = false;
            countryObject.isDelete = false;
          }
        });
        if (regionObject.continentId) {
          channelObject.children.push(regionObject);
        }
      });
      treeList.push(channelObject);
    });

    const tree = [];
    treeList.forEach((channel) => {
      let channelObject = new TreeviewItem({
        text: channel.name,
        collapsed: true,
        value: channel.channelId + 'CH',
        checked: true,
        disabled: true,
        children: channel.children.map((region) => {
          return new TreeviewItem({
            text: region.name,
            collapsed: true,
            value: region.continentId + 'R',
            checked: true,
            disabled: true,
            children: region.children.map((country) => {
              return new TreeviewItem({
                text: country.name,
                collapsed: true,
                value: country.countryId + 'C',
                checked: true,
                disabled: true,
                children: country.children.map((account) => {
                  return new TreeviewItem({
                    text: account.name,
                    collapsed: true,
                    value: account.itemAccountId,
                    ...account,
                    checked: true,
                    disabled: true,
                  });
                }),
              });
            }),
          });
        }),
      });
      tree.push(channelObject);
    });
    return tree;
  }
};
export const CreateItemTree = (productSettingList, itemsList, forecast) => {
  const brandIds = [];
  productSettingList = productSettingList.filter((product) =>
    itemsList.find(
      (el) =>
        el.brandId === product.brandId &&
        el.productGroupId === product.productGroupId &&
        el.productFamilyId === product.productFamilyId &&
        el.parentProductId === product.parentProductId
    )
  );
  productSettingList.forEach((account) => {
    if (brandIds.includes(account.brandId)) {
    } else {
      brandIds.push(account.brandId);
    }
  });
  let treeList = [];
  brandIds.forEach((brand) => {
    let brandObject = {
      brandName: productSettingList.find((acc) => acc.brandId === brand)
        ? productSettingList.find((acc) => acc.brandId === brand).brandName
        : '',
      name: productSettingList.find((acc) => acc.brandId === brand)
        ? productSettingList.find((acc) => acc.brandId === brand).brandName
        : '',
      brandId: brand,
      children: [],
      isBrand: true,
    };
    const productGroupIds = productSettingList
      .filter((el) => el.brandId === brand)
      .map((el) => el.productGroupId)
      .filter((value, index, self) => self.indexOf(value) === index);
    productGroupIds.forEach((productGroupId) => {
      let productGroupObject = {
        productGroupName: productSettingList.find(
          (acc) => acc.productGroupId === productGroupId
        )
          ? productSettingList.find(
              (acc) => acc.productGroupId === productGroupId
            ).productGroupName
          : '',
        name: productSettingList.find(
          (acc) => acc.productGroupId === productGroupId
        )
          ? productSettingList.find(
              (acc) => acc.productGroupId === productGroupId
            ).productGroupName
          : '',
        productGroupId: productGroupId,
        brandId: brand,
        children: [],
        isProductGroup: true,
      };
      const productFamilyIds = productSettingList
        .filter(
          (el) => el.productGroupId === productGroupId && el.brandId === brand
        )
        .map((el) => el.productFamilyId)
        .filter((value, index, self) => self.indexOf(value) === index);
      productFamilyIds.forEach((productFamilyId) => {
        let productFamilyObject = {
          productFamilyName: productSettingList.find(
            (acc) => acc.productFamilyId === productFamilyId
          )
            ? productSettingList.find(
                (acc) => acc.productFamilyId === productFamilyId
              ).productFamilyName
            : '',
          name: productSettingList.find(
            (acc) => acc.productFamilyId === productFamilyId
          )
            ? productSettingList.find(
                (acc) => acc.productFamilyId === productFamilyId
              ).productFamilyName
            : '',
          productFamilyId: productFamilyId,
          productGroupId: productGroupId,
          brandId: brand,
          children: [],
          isProductFamily: true,
        };
        const parentProductIds = productSettingList
          .filter(
            (el) =>
              el.productFamilyId === productFamilyId &&
              el.productGroupId === productGroupId &&
              el.brandId === brand
          )
          .map((el) => el.parentProductId)
          .filter((value, index, self) => self.indexOf(value) === index);
        parentProductIds.forEach((parentProductId) => {
          let products = itemsList.filter(
            (row) =>
              row.parentProductId === parentProductId &&
              row.productFamilyId === productFamilyId &&
              row.productGroupId === productGroupId &&
              row.brandId === brand
          );
          products = products.map((row) => ({
            name: row.description,
            isProduct: true,
            ...row,
          }));
          let parentProductObject = {
            parentProductName: productSettingList.find(
              (acc) => acc.parentProductId === parentProductId
            )
              ? productSettingList.find(
                  (acc) => acc.parentProductId === parentProductId
                ).parentProductName
              : '',
            name: productSettingList.find(
              (acc) => acc.parentProductId === parentProductId
            )
              ? productSettingList.find(
                  (acc) => acc.parentProductId === parentProductId
                ).parentProductName
              : '',
            parentProductId: parentProductId,
            productGroupId: productGroupId,
            brandId: brand,
            children: products,
            isParentProduct: true,
          };
          if (parentProductObject.parentProductId) {
            productFamilyObject.children.push(parentProductObject);
          }
        });
        if (productFamilyObject.productFamilyId) {
          productGroupObject.children.push(productFamilyObject);
        }
      });
      if (productGroupObject.productGroupId) {
        brandObject.children.push(productGroupObject);
      }
    });
    treeList.push(brandObject);
  });
  const tree = [];
  treeList.forEach((brand) => {
    let brandObject = new TreeviewItem({
      text: brand.name,
      collapsed: true,
      value: brand.brandId + 'B',
      children: brand.children.map((productGroup) => {
        return new TreeviewItem({
          text: productGroup.name,
          collapsed: true,
          value: productGroup.productGroupId + 'PG',
          children: productGroup.children.map((productFamily) => {
            return new TreeviewItem({
              text: productFamily.name,
              collapsed: true,
              value: productFamily.productFamilyId + 'PF',
              children: productFamily.children.map((parentProduct) => {
                return new TreeviewItem({
                  text: parentProduct.name,
                  collapsed: true,
                  value: parentProduct.parentProductId + 'PP',
                  checked:
                    forecast && forecast.itemIds
                      ? forecast.itemIds.includes(
                          parentProduct.parentProductId.toString()
                        )
                      : false,
                  children: parentProduct.children.map((product) => {
                    return new TreeviewItem({
                      text: product.name,
                      collapsed: true,
                      value: product.itemManagerId,
                      checked:
                        forecast && forecast.itemIds
                          ? forecast.itemIds.includes(
                              product.itemManagerId.toString()
                            )
                          : false,
                    });
                  }),
                });
              }),
              checked:
                forecast && forecast.itemIds
                  ? forecast.itemIds.includes(
                      productFamily.productFamilyId.toString()
                    )
                  : false,
            });
          }),
          checked:
            forecast && forecast.itemIds
              ? forecast.itemIds.includes(
                  productGroup.productGroupId.toString()
                )
              : false,
        });
      }),
      checked:
        forecast && forecast.itemIds
          ? forecast.itemIds.includes(brand.brandId.toString())
          : false,
    });

    tree.push(brandObject);
  });
  return tree;
};
export const CreateProductScrollingList = (
  itemIds,
  itemsList,
  itemSettingList
) => {
  if (itemIds) {
    let ids = itemIds.split(',');
    const items = itemsList.filter((row) =>
      ids.includes(row.itemManagerId.toString())
    );
    const productSettingList = itemSettingList.filter((product) =>
      items.find(
        (el) =>
          el.brandId === product.brandId &&
          el.productGroupId === product.productGroupId &&
          el.productFamilyId === product.productFamilyId &&
          el.parentProductId === product.parentProductId
      )
    );
    const selectedBrandIds = [];
    productSettingList.forEach((account) => {
      if (selectedBrandIds.includes(account.brandId)) {
      } else {
        selectedBrandIds.push(account.brandId);
      }
    });
    let treeList = [];
    selectedBrandIds.forEach((brand) => {
      let brandObject = {
        brandName: productSettingList.find((acc) => acc.brandId === brand)
          ? productSettingList.find((acc) => acc.brandId === brand).brandName
          : '',
        name: productSettingList.find((acc) => acc.brandId === brand)
          ? productSettingList.find((acc) => acc.brandId === brand).brandName
          : '',
        brandId: brand,
        children: [],
        isBrand: true,
      };
      const productGroupIds = productSettingList
        .filter((el) => el.brandId === brand)
        .map((el) => el.productGroupId)
        .filter((value, index, self) => self.indexOf(value) === index);
      productGroupIds.forEach((productGroupId) => {
        let productGroupObject = {
          productGroupName: productSettingList.find(
            (acc) => acc.productGroupId === productGroupId
          )
            ? productSettingList.find(
                (acc) => acc.productGroupId === productGroupId
              ).productGroupName
            : '',
          name: productSettingList.find(
            (acc) => acc.productGroupId === productGroupId
          )
            ? productSettingList.find(
                (acc) => acc.productGroupId === productGroupId
              ).productGroupName
            : '',
          productGroupId: productGroupId,
          brandId: brand,
          children: [],
          isProductGroup: true,
        };
        const productFamilyIds = productSettingList
          .filter(
            (el) => el.productGroupId === productGroupId && el.brandId === brand
          )
          .map((el) => el.productFamilyId)
          .filter((value, index, self) => self.indexOf(value) === index);
        productFamilyIds.forEach((productFamilyId) => {
          let productFamilyObject = {
            productFamilyName: productSettingList.find(
              (acc) => acc.productFamilyId === productFamilyId
            )
              ? productSettingList.find(
                  (acc) => acc.productFamilyId === productFamilyId
                ).productFamilyName
              : '',
            name: productSettingList.find(
              (acc) => acc.productFamilyId === productFamilyId
            )
              ? productSettingList.find(
                  (acc) => acc.productFamilyId === productFamilyId
                ).productFamilyName
              : '',
            productFamilyId: productFamilyId,
            productGroupId: productGroupId,
            brandId: brand,
            children: [],
            isProductFamily: true,
          };
          const parentProductIds = productSettingList
            .filter(
              (el) =>
                el.productFamilyId === productFamilyId &&
                el.productGroupId === productGroupId &&
                el.brandId === brand
            )
            .map((el) => el.parentProductId)
            .filter((value, index, self) => self.indexOf(value) === index);
          parentProductIds.forEach((parentProductId) => {
            let products = items.filter(
              (row) =>
                row.parentProductId === parentProductId &&
                row.productFamilyId === productFamilyId &&
                row.productGroupId === productGroupId &&
                row.brandId === brand
            );
            products = products.map((row) => ({
              name: row.description,
              isProduct: true,
              ...row,
            }));
            let parentProductObject = {
              parentProductName: productSettingList.find(
                (acc) => acc.parentProductId === parentProductId
              )
                ? productSettingList.find(
                    (acc) => acc.parentProductId === parentProductId
                  ).parentProductName
                : '',
              name: productSettingList.find(
                (acc) => acc.parentProductId === parentProductId
              )
                ? productSettingList.find(
                    (acc) => acc.parentProductId === parentProductId
                  ).parentProductName
                : '',
              parentProductId: parentProductId,
              productGroupId: productGroupId,
              brandId: brand,
              children: products,
              isParentProduct: true,
            };
            if (parentProductObject.parentProductId) {
              productFamilyObject.children.push(parentProductObject);
            }
          });
          if (productFamilyObject.productFamilyId) {
            productGroupObject.children.push(productFamilyObject);
          }
        });
        if (productGroupObject.productGroupId) {
          brandObject.children.push(productGroupObject);
        }
      });
      treeList.push(brandObject);
    });
    const tree = [];
    treeList.forEach((brand) => {
      let brandObject = new TreeviewItem({
        text: brand.name,
        value: brand.brandId + 'B',
        collapsed: true,
        checked: true,
        disabled: true,
        children: brand.children.map((productGroup) => {
          return new TreeviewItem({
            text: productGroup.name,
            collapsed: true,
            checked: true,
            disabled: true,
            value: productGroup.productGroupId + 'PG',
            children: productGroup.children.map((productFamily) => {
              return new TreeviewItem({
                text: productFamily.name,
                collapsed: true,
                checked: true,
                disabled: true,
                value: productFamily.productFamilyId + 'PF',
                children: productFamily.children.map((parentProduct) => {
                  return new TreeviewItem({
                    text: parentProduct.name,
                    collapsed: true,
                    value: parentProduct.parentProductId + 'PP',
                    checked: true,
                    disabled: true,
                    children: parentProduct.children.map((product) => {
                      return new TreeviewItem({
                        text: product.name,
                        collapsed: true,
                        value: product.itemManagerId,
                        checked: true,
                        disabled: true,
                      });
                    }),
                  });
                }),
              });
            }),
          });
        }),
      });
      tree.push(brandObject);
    });
    return tree;
  }
};
export const CreateTimeframeUIList = (yearListGrid) => {
  const treeList = [];
  const uniqueYears = [...new Set(yearListGrid.map((item) => item.fy))]; // ['FY18']
  uniqueYears.forEach((year: string, index) => {
    const yearList = yearListGrid.filter((el) => el.fy == year);
    const uniqueHalfYears = [...new Set(yearList.map((item) => item.hy))]; // ['H1']
    const uniqueQuarters = [];
    const uniquemonths = [];
    yearList.forEach((y) => {
      if (
        uniqueQuarters.findIndex(
          (x) => x.quarter == y.quarter && x.hy == y.hy
        ) < 0
      ) {
        uniqueQuarters.push({ quarter: y.quarter, hy: y.hy });
      }
      if (
        uniquemonths.findIndex(
          (x) => x.quarter == y.quarter && x.month == y.month
        ) < 0
      ) {
        uniquemonths.push({ quarter: y.quarter, month: y.month });
      }
    });
    let object: any = {
      ...new PhysicalYear(),
      halfList: uniqueHalfYears.map((h) => ({
        ...new HalfYear(),
        title: h,
        quarterList: uniqueQuarters
          .filter((e) => e.hy == h)
          .map((quarter) => ({
            ...new Quarter(),
            title: quarter.quarter,
            monthList: uniquemonths
              .filter((el) => el.quarter == quarter.quarter)
              .map((month) => ({
                ...new Month(),
                title: month.month,
                weekList: yearListGrid.filter(
                  (row) =>
                    row.fy == year &&
                    row.hy == h &&
                    row.quarter == quarter.quarter &&
                    row.month == month.month
                ),
              })),
          })),
      })),
      title: year,
    };
    treeList.push(object);
  });
  return treeList;
}
