<ngx-charts-line-chart #chart
[view]="view"
[scheme]="colorScheme"
[legend]="false"
[showXAxisLabel]="''"
[showYAxisLabel]="''"
[xAxis]="xAxis"
[yAxis]="yAxis"
[xAxisLabel]="''"
[yAxisLabel]="''"
[timeline]="true"
[results]="chartDatasets"
(select)="onSelectChart()"
(activate)="onActivate()"
(deactivate)="onDeactivate()"
>
</ngx-charts-line-chart>
