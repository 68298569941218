import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-summary-view',
  templateUrl: './summary-view.component.html',
  styleUrls: ['./summary-view.component.scss'],
})
export class SummaryViewComponent implements OnInit {
  @Input() gridMapped: any;
  @Input() summaryArray: any;
  constructor() {}

  ngOnInit(): void {}
}
