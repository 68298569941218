<div class="d-flex justify-content-between align-items-center w-100 p-2">
    <h6 class="m-0">
        List of Plans
    </h6>

    <div>
        <button mat-flat-button color="accent" [matMenuTriggerFor]="actionBtn">Actions</button>
        <mat-menu #actionBtn="matMenu">
            <button mat-menu-item (click)="createNew()">Create New</button>
            <button mat-menu-item>Modify</button>
            <button mat-menu-item>Accept</button>
            <button mat-menu-item>Reject</button>
        </mat-menu>
    </div>
</div>

<div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material" [suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups
        toolPanelSuppressValues [frameworkComponents]="frameworkComponents" [columnDefs]="gridColumnDefs"
        [rowData]="gridData" [pagination]="false" (gridReady)="onGridReady($event)" [modules]="modules"
        #planManagerGrid>
    </ag-grid-angular>
</div>
