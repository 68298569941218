import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';

import { USER_ID } from '../../../../../common/keys';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { LocalstorageService } from '../../../../../services/localstorage.service';
import { FB_Template } from '../../global.models';
import { ForecastDeletePlannerModalComponent } from '../fb-forecast-delete-planner-modal/fb-forecast-delete-planner-modal.component';
import { ForecastConfirmationModalComponent } from '../forecast-confirmation-modal/forecast-confirmation-modal.component';

@Component({
  selector: 'app-forecast-saved-versions',
  templateUrl: './forecast-saved-versions.component.html',
  styleUrls: ['./forecast-saved-versions.component.scss'],
})
export class ForecastSavedVersionsComponent implements OnInit {
  versionList = [];
  constructor(
    public dialogRef: MatDialogRef<ForecastSavedVersionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public config: ConfigurationService,
    public storage: LocalstorageService,
    public spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getVersionList();
  }
  public getVersionList = () => {
    let templateId;
    if (this.data.isGlobal) {
      templateId = FB_Template.Global_Planning;
    } else if (this.data.isRegional) {
      templateId = FB_Template.Regional_Planning;
    } else if (this.data.isDirectInventory) {
      templateId = FB_Template.Retail_Direct;
    } else if (this.data.isInDirectInventory) {
      templateId = FB_Template.Retail_InDirect;
    }
    const params = {
      userId: this.storage.get(USER_ID),
      fbTemplateId: templateId,
    };
    if (this.data.isItem) {
      this.config.userItemForecastGetList(params).subscribe((res) => {
        this.versionList = res;
      });
    } else
      this.config.userFBForecastGetList(params).subscribe((res) => {
        this.versionList = res;
      });
  };
  public saveAndClose = () => {
    this.dialogRef.close(this.data);
  };
  public close = () => {
    this.config.removeMenu.next('app-forecast');
    this.dialogRef.close(this.data);
  };
  public onNoClick = () => {
    this.dialogRef.close();
  };
  public openSavedVersions = () => {
    this.data.openSaved = true;
    this.dialogRef.close(this.data);
  };
  public deleteVersion(version) {
    this.dialog
      .open(ForecastConfirmationModalComponent, {
        width: '848px',
        panelClass: 'fb-dialog-wrapper2',
        data: {
          label: 'Are you sure you want to delete this planner?',
          isOnLoad: false,
          isDeletePlanner: true,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((command) => {
        if (command && command.isDelete) {
          let templateId;
          if (this.data.isGlobal) {
            templateId = FB_Template.Global_Planning;
          } else if (this.data.isRegional) {
            templateId = FB_Template.Regional_Planning;
          } else if (this.data.isDirectInventory) {
            templateId = FB_Template.Retail_Direct;
          } else if (this.data.isInDirectInventory) {
            templateId = FB_Template.Retail_InDirect;
          }
          let model = {
            versionTypeId: null,
            userFBForecastIds: '',
            fbTemplateId: templateId,
            UserId: this.storage.get(USER_ID),
          };
          model.versionTypeId = 3;
          model.userFBForecastIds = version.userFBForecastId.toString();
          this.spinner.show();
          this.config.userFBForecastDeleteByVersionID(model).subscribe(
            (resp) => {
              this.versionList = this.versionList.filter(
                (row) => row.userFBForecastId !== version.userFBForecastId
              );
              this.spinner.hide();
            },
            (error) => {
              this.spinner.hide();
            }
          );
        }
      });
  }
  public openPlanner = () => {
    this.data.version = this.versionList.find((el) => el.checked);
    if (this.data.version) {
      this.dialogRef.close(this.data);
    } else {
      return;
    }
  };
}
