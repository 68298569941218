<div class="container">
  <!-- Main Content area -->
  <form [formGroup]="calenderActivityForm" class="form-fields">
    <div class="row">
      <div class="col-md-4 form-group">
        <mat-form-field class="example-full-width">
          <input placeholder="Calender Activity" formControlName="calendarActivityName" matInput>
          <mat-error *ngIf="submitted && calenderActivityForm.controls.calendarActivityName.hasError('required')">
            *Required Field</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 form-group">
        <mat-form-field class="example-full-width">
          <input placeholder="Calender Abbreviation" formControlName="abbreviation" matInput>
          <mat-error *ngIf="submitted && calenderActivityForm.controls.abbreviation.hasError('required')">*Required
            Field</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 form-group">
        <label></label>
        <mat-slide-toggle formControlName="active">Active
        </mat-slide-toggle>
      </div>
    </div>
  </form>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" type="submit" (click)="onSubmit()"
      color="primary">{{isEdit ? 'Update' : 'Create'}}</button>
    <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="Cancel_Click()">Cancel</button>
  </div>
</div>
<div class="all-show-grid">
  <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20' [headerHeight]="headerHeight" [getRowHeight]="getRowHeight"  groupHeaders suppressRowClickSelection
    toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptions" [columnDefs]="columnDefs"
    [rowData]="channelList" (cellClicked)="populateForm($event)" (gridReady)="onGridReady($event)" [modules]="modules"
    #grid></ag-grid-angular>
</div>
