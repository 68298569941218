<!-- Main Content area -->
<div class="container-fluid" style="min-height: 100vh;">
  <div class="row" style="zoom:70%">
    <div class="col-md-3 form-group">
      <!-- <label>Timeframe</label> -->
      <ng-multiselect-dropdown *ngIf="monthList && monthList.length > 0" [settings]="dropdownSettings"
        style="max-height: 20px !important;" [placeholder]="'Timeframe'" [data]="monthList" type="text"
        [(ngModel)]="timeframeSelectedList" (onSelectAll)="filterListByMonth($event)"
        (onDeSelect)="filterListByMonth(null)" (onSelect)="filterListByMonth(null)">
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2 form-group">
      <!-- <label>Customer</label> -->
      <ng-multiselect-dropdown *ngIf="customerList && customerList.length > 0" [settings]="dropdownSettingsSingle"
        [placeholder]="'Customer'" [data]="customerList" [(ngModel)]="customerSelectedList"
        (onSelectAll)="onSelectAll($event)">
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2 form-group">
      <mat-form-field class="example-full-width">
        <input type="text" placeholder="{{getName()}}" aria-label="Number" matInput
          (input)="filterChanged($event.target.value)" [matAutocomplete]="auto">
        <mat-autocomplete  #auto="matAutocomplete">
          <mat-option disabled>Select Product</mat-option>
          <mat-tree [dataSource]="dataSourceDropdown" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
              <button mat-icon-button disabled></button>
              <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
                (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
            </mat-tree-node>

            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <mat-checkbox [checked]="descendantsAllSelected(node)"
                [indeterminate]="descendantsPartiallySelected(node)" (change)="todoItemSelectionToggle(node)">
                {{node.item}}</mat-checkbox>
            </mat-tree-node>
          </mat-tree>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-md-2 form-group">
      <!-- <label>Channel</label> -->
      <ng-multiselect-dropdown *ngIf="channelList && channelList.length > 0" [settings]="dropdownSettingsSingle"
        [placeholder]="'Channel'" [data]="channelList" [(ngModel)]="channelSelectedList"
        (onSelectAll)="onSelectAll($event)">
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-3 form-group">
      <!-- <label>Version</label> -->
      <ng-multiselect-dropdown *ngIf="versionList && versionList.length > 0" [settings]="dropdownSettingsSingle"
        [placeholder]="'Version'" [data]="versionList" [(ngModel)]="seletedVersion" (onSelectAll)="onSelectAll($event)">
      </ng-multiselect-dropdown>
    </div>
  </div>
  <mat-card-title style="zoom:70%"
    [ngStyle]="{'visibility': (customerSelectedList.length > 0 && channelSelectedList && seletedVersion && checklistSelection.selected.length > 0 && timeframeSelectedList.length > 0 && dataSource) ? 'visible' : 'collapse'}">
    Multi-Item Model
  </mat-card-title>
  <mat-card
    [ngStyle]="{'visibility': (customerSelectedList.length > 0 && channelSelectedList && seletedVersion && checklistSelection.selected.length > 0 && timeframeSelectedList.length > 0 && dataSource) ? 'visible' : 'collapse'}"
    class="mat-card-box" style="border-top: 2px solid #D81AD1;">
    <mat-card-header>
      Graphic View
    </mat-card-header>
    <mat-card-content>
      <fusioncharts *ngIf="dataSource" [width]="width" [height]="height" [type]="type" [dataFormat]="dataFormat"
        [dataSource]="dataSource">
      </fusioncharts>
    </mat-card-content>
  </mat-card>
  <div
    [ngStyle]="{'visibility': (customerSelectedList.length > 0 && channelSelectedList && seletedVersion && checklistSelection.selected.length > 0 && timeframeSelectedList.length > 0 && dataSource) ? 'visible' : 'collapse'}"
    class="action-panel text-right">
    <span (click)="openDialog({}, dialog)">
      <i style="font-size: 42px; cursor: pointer;" class="material-icons" aria-label="Column Show/Hide">
        list_alt
      </i>
    </span>
    <span (click)="openDialog({}, dialogGraphFields)">
      <i style="font-size: 42px; cursor: pointer;" class="material-icons" aria-label="Column Show/Hide">
        trending_up
      </i>
    </span>
    <span (click)="openExcelImportDialog({})">
      <i style="font-size: 42px;cursor:pointer;" class="material-icons" aria-label="Column Show/Hide">
        import_export
      </i>
    </span>
    <span (click)="openFullScreen('forcast-grid')" matTooltip="Full Screen" matTooltipPosition="above">
      <i style="font-size: 42px;cursor:pointer;" class="material-icons" aria-label="Full Screen">
        fullscreen
      </i>
    </span>
    <span>
      <button mat-raised-button class="btn btn-primary" [matMenuTriggerFor]="menu">
        Save
      </button>
      <mat-menu #menu="matMenu" class="user-logout">
        <mat-menu></mat-menu>
        <!-- <a mat-menu-item>Setting & Administration</a>
          <a mat-menu-item>View Profile</a> -->
        <a style="color: #fff" mat-menu-item>Save</a>
        <a style="color: #fff" mat-menu-item>Save as</a>
        <a style="color: #fff" mat-menu-item>Commit</a>
      </mat-menu>
    </span>
  </div>
  <div style="zoom:70%">
    <h3  *ngIf="customerSelectedList.length > 0 && channelSelectedList && seletedVersion && checklistSelection.selected.length > 0 && timeframeSelectedList.length > 0">Data View</h3>
    <table id="forcast-grid"
      *ngIf="customerSelectedList.length > 0 && channelSelectedList && seletedVersion && checklistSelection.selected.length > 0 && timeframeSelectedList.length > 0"
      [ngStyle]="{'visibility': (customerSelectedList.length > 0 && channelSelectedList && seletedVersion && checklistSelection.selected.length > 0 && timeframeSelectedList.length > 0 && dataSource) ? 'visible' : 'collapse'}"
      class="table">
      <!-- Data Label Start -->
      <td style="padding: 0px !important;">
        <tr class="td-padding-none">
          <td style="text-align: left;" align="left">
        <tr style="text-align: center; font-size: 20px"> <label>{{getName()}}</label></tr>
        <tr>
          <td>
        <tr> <label>&nbsp;</label></tr>
        <tr>
          <td>
        <tr> <label>&nbsp;</label></tr>
        <tr>
          <td>
        <tr> <label>&nbsp;</label></tr>
        <tr>
          <td>
            <!-- <tr style="height:34px !important"> <label style="height:35px !important">&nbsp;</label></tr> -->
            <ng-container *ngFor="let label of leftGroupList">
        <tr [attr.rowspan]="label.rowspan" style="writing-mode: vertical-rl;
          text-orientation: mixed;text-align: center;align-items: center; display: flex;
          font-size: large;" [ngStyle]="{'background': label.color, 'height': (label.rowspan * 34.29)+'px'}">
          <label [ngStyle]="{'height': (label.rowspan * 34.29)+'px'}"
            style="padding-right: 10px;min-width: 50px;max-width: 50px;text-align: center;">{{label.name}}</label>

        </tr>
        <tr style="height:34px !important"> <label style="height:35px !important">&nbsp;</label></tr>
        </ng-container>

      </td>
      </tr>
      </td>
      </tr>
      </td>
      </tr>
      </td>
      </tr>
      </td>
      </tr>
      </td>
      <!-- Data Label End -->
      <td style="padding: 0px !important;">
        <tr class="td-padding">
          <td style="text-align: left;" align="left">
        <tr> <label>&nbsp;</label></tr>
        <tr>
          <td>
        <tr> <label>&nbsp;</label></tr>
        <tr>
          <td>
        <tr> <label>&nbsp;</label></tr>
        <tr>
          <td>
        <tr> <label>&nbsp;</label></tr>
        <tr>
          <td>
        <tr style="height:34px !important"> <label style="height:35px !important">&nbsp;</label></tr>
        <ng-container *ngFor="let label of chooserList; let i=index">
          <tr [ngStyle]="{background: label.background}" *ngIf="label.checked" style="height: 34px">
            <label *ngIf="!label.isDropdown" style="min-width: 300px;max-width: 300px;">{{label.name}}</label>
            <mat-select [(ngModel)]="label.name" [ngModelOptions]="{standalone: true}" style="height: 34px"
              style="min-width: 300px;max-width: 300px;" *ngIf="label.isDropdown">
              <mat-option class="not-empty-select" *ngFor="let res of methodList; let i = index" [value]="res">
                {{res}}
              </mat-option>
            </mat-select>
          </tr>
          <tr *ngIf="checkIfToShow(i)" style="height:34px !important"> <label [id]="i"
              style="height:35px !important">&nbsp;</label></tr>
        </ng-container>
      </td>
      </tr>
      </td>
      </tr>
      </td>
      </tr>
      </td>
      </tr>
      </td>
      </tr>
      </td>
      <!-- Data Table Start-->
      <td style="overflow-x: scroll;width: 100%;padding: 0px !important;" [ngStyle]="{'max-width':getWidth() }">
        <tr class="td-padding">
          <td *ngFor="let year of yearList" style="text-align: center;" align="center">
        <tr> <label>{{year.title}}<i (click)="collapseYear(year)" style="font-size: 15px;"
              class="material-icons">remove_red_eye</i></label></tr>
        <tr>
          <td [class.d-none]="year.show" *ngFor="let half of year.halfList" style="text-align: center;" align="center">
        <tr> <label>{{half.title}}<i (click)="collapseHalf(half)" style="font-size: 15px;"
              class="material-icons">remove_red_eye</i></label></tr>
        <tr>
          <td [class.d-none]="year.show || half.show" *ngFor="let quarter of half.quarterList"
            style="text-align: center;">
        <tr> <label>{{quarter.title}}<i (click)="collapseQuarter(quarter)" style="font-size: 15px;"
              class="material-icons">remove_red_eye</i></label>
        </tr>
        <tr>
          <td [class.d-none]="year.show || half.show || quarter.show" *ngFor="let month of quarter.monthList"
            style="text-align: center;">
        <tr> <label>{{month.title}}<i (click)="collapseMonth(month)" style="font-size: 15px;"
              class="material-icons">remove_red_eye</i></label>
        </tr>
        <tr>
          <ng-container
            [ngTemplateOutlet]="!year.show && !half.show && !quarter.show && !month.show ? monthColumn : otherColumn"
            [ngTemplateOutletContext]="{week: getObject(year, month, quarter, {})}">
          </ng-container>
          <ng-template #monthColumn>
            <td class="input-fields" *ngFor="let week of month.weekList; let monthIndex=index"
              style="text-align: center;">
        <tr style="background: #fff"> <label style="height:35px !important">{{week.title}}</label></tr>
        <!-- Algo -->
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('BaselineSTForecast')">

          <input type="text" [disabled]="false" [ngModel]="week.Algo.BaselineSTForecast | number : '1.2-2'"
            (ngModelChange)="week.Algo.BaselineSTForecast = $event" [ngModelOptions]="{standalone: true}"
            id="BaselineSTForecast">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoPromoUnits')">

          <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoPromoUnits | number : '1.2-2'"
            (ngModelChange)="week.Algo.AlgoPromoUnits = $event" [ngModelOptions]="{standalone: true}"
            id="AlgoPromoUnits">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcst | number : '1.2-2'"
            (ngModelChange)="week.Algo.AlgoTotalSTFcst = $event" [ngModelOptions]="{standalone: true}"
            id="AlgoTotalSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoConstrainedSTFcst')">

          <input type="text" [ngModel]="week.Algo.AlgoConstrainedSTFcst | number : '1.2-2'"
            (ngModelChange)="week.Algo.AlgoConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
            id="AlgoConstrainedSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoLY')">

          <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoLY | percent"
            [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoLY">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoDPTotalSTFcst')">

          <input [ngModel]="week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst | percent" [ngModelOptions]="{standalone: true}"
            id="AlgoTotalSTFcstvartoDPTotalSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoCOTotalSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst | percent"
            [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoCOTotalSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoConsensusSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoConsensusSTFcst | percent"
            [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoConsensusSTFcst">

        </tr>
        <tr *ngIf="checkIfToShowColumn('Algo ST')"> <label>&nbsp;</label></tr>
        <!-- Algo -->

        <!-- DemandPlanning -->
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPBaselineSTFcst')">

          <input type="text" [disabled]="false" [ngModel]="week.DemandPlanning.DPBaselineSTFcst | number : '1.2-2'"
            (ngModelChange)="week.DemandPlanning.DPBaselineSTFcst = $event" [ngModelOptions]="{standalone: true}"
            id="DPBaselineSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPManualFcstAdjustment')">

          <input [disabled]="false" type="text"
            [ngModel]="week.DemandPlanning.DPManualFcstAdjustment | number : '1.2-2'"
            (ngModelChange)="week.DemandPlanning.DPManualFcstAdjustment = $event" [ngModelOptions]="{standalone: true}"
            (change)="DPManualFcstAdjustmentUpdated(week)" id="DPManualFcstAdjustment">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPPromoUnits')">

          <input [disabled]="false" type="text" (change)="DPPromoUnitsUpdated(week)"
            [ngModel]="week.DemandPlanning.DPPromoUnits | number : '1.2-2'" [ngModelOptions]="{standalone: true}"
            id="DPPromoUnits">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcst | number : '1.2-2'"
            (ngModelChange)="week.DemandPlanning.DPTotalSTFcst = $event" [ngModelOptions]="{standalone: true}"
            id="DPTotalSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPConstrainedSTFcst')">

          <input type="text" [ngModel]="week.DemandPlanning.DPConstrainedSTFcst | number : '1.2-2'"
            (ngModelChange)="week.DemandPlanning.DPConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
            id="DPConstrainedSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPComments')">

          <input [disabled]="false" type="text" [(ngModel)]="week.DemandPlanning.DPComments"
            [ngModelOptions]="{standalone: true}" id="DPComments">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoLY')">

          <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoLY | percent"
            [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoLY">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoCOTotalSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoCOTotalSTFcst | percent"
            [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoCOTotalSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoConsensusSTFcst')">

          <input type="text" [disabled]="true"
            [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoConsensusSTFcst | percent"
            [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoConsensusSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoAlgoTotalSTFcst')">

          <input type="text" [disabled]="true"
            [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoAlgoTotalSTFcst | percent"
            [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoAlgoTotalSTFcst">

        </tr>
        <tr *ngIf="checkIfToShowColumn('Demand Planning ST')"> <label>&nbsp;</label></tr>
        <!-- DemandPlanning -->

        <!-- ChannelOps -->
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COBaselineSTForecast')">
          <input type="text" [disabled]="false" [ngModel]="week.ChannelOps.COBaselineSTForecast | number : '1.2-2'"
            (ngModelChange)="week.ChannelOps.COBaselineSTForecast = $event" [ngModelOptions]="{standalone: true}"
            id="COBaselineSTForecast">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COManualFcstAdjustment')">

          <input [disabled]="false" type="text" [ngModel]="week.ChannelOps.COManualFcstAdjustment | number : '1.2-2'"
            (ngModelChange)="week.ChannelOps.COManualFcstAdjustment = $event" [ngModelOptions]="{standalone: true}"
            (change)="COManualFcstAdjustmentUpdated(week)" id="COManualFcstAdjustment">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COPromoUnits')">

          <input [disabled]="false" type="text" (change)="COPromoUnitsUpdated(week)"
            [ngModel]="week.ChannelOps.COPromoUnits | number : '1.2-2'"
            (ngModelChange)="week.ChannelOps.COPromoUnits = $event" [ngModelOptions]="{standalone: true}"
            id="COPromoUnits">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcst | number : '1.2-2'"
            (ngModelChange)="week.ChannelOps.COTotalSTFcst = $event" [ngModelOptions]="{standalone: true}"
            id="COTotalSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COConstrainedSTFcst')">

          <input type="text" [ngModel]="week.ChannelOps.COConstrainedSTFcst | number : '1.2-2'"
            (ngModelChange)="week.ChannelOps.COConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
            id="COConstrainedSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COComments')">

          <input [disabled]="false" type="text" [(ngModel)]="week.ChannelOps.COComments"
            [ngModelOptions]="{standalone: true}" id="COComments">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoLY')">

          <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoLY | percent"
            [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoLY">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoDPTotalSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoDPTotalSTFcst | percent"
            [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoDPTotalSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoConsensusSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoConsensusSTFcst | percent"
            [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoConsensusSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoAlgoTotalSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoAlgoTotalSTFcst | percent"
            [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoAlgoTotalSTFcst">

        </tr>
        <tr *ngIf="checkIfToShowColumn('Channel Ops ST')"> <label>&nbsp;</label></tr>

        <!-- ChannelOps -->

        <!-- CurrentSellThrough -->
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerSTFcst')">

          <input [disabled]="false" type="text" (change)="CustomerSTFcstUpdated(week)"
            [ngModel]="week.CurrentSellThrough.CustomerSTFcst | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.CustomerSTFcst = $event" [ngModelOptions]="{standalone: true}"
            id="CustomerSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSTFcst')">

          <input [disabled]="false" type="text" (change)="ConsensusSTFcstUpdated(week)"
            [ngModel]="week.CurrentSellThrough.ConsensusSTFcst | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcst = $event" [ngModelOptions]="{standalone: true}"
            id="ConsensusSTFcst">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvartoCustomerSTFcst')">

          <input type="text" [disabled]="true"
            [ngModel]="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst = $event"
            [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvartoCustomerSTFcst">

        </tr>
        <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ConstrainedSTFcst | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
            id="ConstrainedSTFcst">

        </tr> -->
        <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvartoConsensusSTFcst')">

          <input type="text" [disabled]="true"
            [ngModel]="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst = $event"
            [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvartoConsensusSTFcst">

        </tr> -->
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ProjectedLostSales')">

          <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ProjectedLostSales | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ProjectedLostSales = $event" [ngModelOptions]="{standalone: true}"
            id="ProjectedLostSales">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualST')">

          <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.ActualST | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ActualST = $event" [ngModelOptions]="{standalone: true}"
            id="ActualST">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('WeeklyTrend')">

          <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.WeeklyTrend | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.WeeklyTrend = $event" [ngModelOptions]="{standalone: true}"
            id="WeeklyTrend">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('PromoIndicator')">

          <input type="text" [disabled]="false" [(ngModel)]="week.CurrentSellThrough.PromoIndicator"
            [ngModelOptions]="{standalone: true}" id="PromoIndicator">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CPFRComments')">

          <input [disabled]="false" type="text" [(ngModel)]="week.CurrentSellThrough.CPFRComments"
            [ngModelOptions]="{standalone: true}" id="CPFRComments">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerInStockEstimate')">

          <input [disabled]="false" type="text" [ngModel]="week.CurrentSellThrough.CustomerInStockEstimate | percent"
            [ngModelOptions]="{standalone: true}" id="CustomerInStockEstimate">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstUnits')">

          <input type="text" [disabled]="true"
            [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstUnits | number:'1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ActualVartoConsensusFcstUnits = $event"
            [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstUnits">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstPst')">

          <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstPst | percent"
            [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstPst">

        </tr>
        <tr *ngIf="checkIfToShowColumn('Collaborative ST')"> <label>&nbsp;</label></tr>

        <!-- CurrentSellThrough -->

        <!-- LastYearSales -->
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualST')">

          <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYActualST | number:'1.2-2'"
            (ngModelChange)="week.LastYearSales.LYActualST = $event" [ngModelOptions]="{standalone: true}"
            id="LYActualST">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYPromoIndicator')">

          <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYPromoIndicator"
            [ngModelOptions]="{standalone: true}" id="LYPromoIndicator">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCOComments')">

          <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYCOComments"
            [ngModelOptions]="{standalone: true}" id="LYCOComments">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCustomerInStockEstimate')">

          <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYCustomerInStockEstimate | percent"
            [ngModelOptions]="{standalone: true}" id="LYCustomerInStockEstimate">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYConsensusFcst')">

          <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYConsensusFcst | number:'1.2-2'"
            (ngModelChange)="week.LastYearSales.LYConsensusFcst = $event" [ngModelOptions]="{standalone: true}"
            id="LYConsensusFcst">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarPst')">

          <input type="text" [disabled]="true" [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarPst | percent"
            [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarPst">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarUnits')">

          <input type="text" [disabled]="true"
            [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarUnits | number:'1.2-2'"
            (ngModelChange)="week.LastYearSales.LYActualtoConsensusFcstVarUnits = $event"
            [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarUnits">

        </tr>
        <tr *ngIf="checkIfToShowColumn('Last Year ST')"> <label>&nbsp;</label></tr>

        <!-- LastYearSales -->

        <!-- StAccuracy -->
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerSTFcstvsActualST')">

          <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.CustomerSTFcstvsActualST | percent"
            [ngModelOptions]="{standalone: true}" id="CustomerSTFcstvsActualST">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvsActualST')">

          <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.AlgoTotalSTFcstvsActualST | percent"
            [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvsActualST">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvsActualST')">

          <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.COTotalSTFcstvsActualST | percent"
            [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvsActualST">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvsActualST')">

          <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.DPTotalSTFcstvsActualST | percent"
            [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvsActualST">

        </tr>
        <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvsActualST')">

          <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConstrainedSTFcstvsActualST | percent"
            [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvsActualST">

        </tr> -->
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvsActualST')">

          <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConsensusSTFcstvsActualST | percent"
            [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvsActualST">

        </tr>
        <tr *ngIf="checkIfToShowColumn('ST Accuracy')"> <label>&nbsp;</label></tr>

        <!-- StAccuracy -->

        <!-- SellIn -->
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerRequestedSellInReceiveWk')">

          <input [disabled]="false" type="text" (change)="CustomerRequestedSellInReceiveWkUpdated(week)"
            [ngModel]="week.SellIn.CustomerRequestedSellInReceiveWk | number"
            (ngModelChange)="week.SellIn.CustomerRequestedSellInReceiveWk = $event"
            [ngModelOptions]="{standalone: true}" id="CustomerRequestedSellInReceiveWk">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('FinalSellInCommitReceiveWk')">

          <input [disabled]="false" type="text" [ngModel]="week.SellIn.FinalSellInCommitReceiveWk | number"
            (ngModelChange)="week.SellIn.FinalSellInCommitReceiveWk = $event" [ngModelOptions]="{standalone: true}"
            id="FinalSellInCommitReceiveWk">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitvartoCustomerRequest')">

          <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitvartoCustomerRequest | number"
            (ngModelChange)="week.SellIn.FinalSellInCommitvartoCustomerRequest = $event"
            [ngModelOptions]="{standalone: true}" id="FinalSellInCommitvartoCustomerRequest">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoSellInFcstReceiveWk')">

          <input type="text" [disabled]="false" [ngModel]="week.SellIn.AlgoSellInFcstReceiveWk | number"
            (ngModelChange)="week.SellIn.AlgoSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
            id="AlgoSellInFcstReceiveWk">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPSellInFcstReceiveWk')">

          <input [disabled]="false" type="text" [ngModel]="week.SellIn.DPSellInFcstReceiveWk | number"
            (ngModelChange)="week.SellIn.DPSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
            id="DPSellInFcstReceiveWk">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COSellInFcstReceiveWk')">

          <input [disabled]="false" type="text" [ngModel]="week.SellIn.COSellInFcstReceiveWk | number"
            (ngModelChange)="week.SellIn.COSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
            id="COSellInFcstReceiveWk">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSellInFcstReceiveWk')">

          <input [disabled]="false" type="text" [ngModel]="week.SellIn.ConsensusSellInFcstReceiveWk | number"
            (ngModelChange)="week.SellIn.ConsensusSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
            id="ConsensusSellInFcstReceiveWk">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitsShipWk')">

          <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitsShipWk | number"
            (ngModelChange)="week.SellIn.FinalSellInCommitsShipWk = $event" [ngModelOptions]="{standalone: true}"
            id="FinalSellInCommitsShipWk">

        </tr>
        <tr *ngIf="checkIfToShowColumn('Collaborative SI')"> <label>&nbsp;</label></tr>

        <!-- SellIn -->


        <!-- CustomerInventory -->
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerIOHProjection')">

          <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerIOHProjection | number"
            (ngModelChange)="week.CustomerInventory.CustomerIOHProjection = $event"
            [ngModelOptions]="{standalone: true}" id="CustomerIOHProjection">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('IOHProjection')">

          <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.IOHProjection | number"
            (ngModelChange)="week.CustomerInventory.IOHProjection = $event" [ngModelOptions]="{standalone: true}"
            id="IOHProjection">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerFWOSCalc')">

          <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerFWOSCalc | number:'1.2-2'"
            (ngModelChange)="week.CustomerInventory.CustomerFWOSCalc = $event" [ngModelOptions]="{standalone: true}"
            id="CustomerFWOSCalc">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBFWOSCalc')">

          <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FBFWOSCalc | number:'1.2-2'"
            (ngModelChange)="week.CustomerInventory.FBFWOSCalc = $event" [ngModelOptions]="{standalone: true}"
            id="FBFWOSCalc">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoCustomerTarget')">

          <input type="text" [disabled]="true"
            [ngModel]="week.CustomerInventory.FWOSvartoCustomerTarget | number:'1.2-2'"
            (ngModelChange)="week.CustomerInventory.FWOSvartoCustomerTarget = $event"
            [ngModelOptions]="{standalone: true}" id="FWOSvartoCustomerTarget">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoFBTarget')">

          <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoFBTarget | number:'1.2-2'"
            (ngModelChange)="week.CustomerInventory.FWOSvartoFBTarget = $event" [ngModelOptions]="{standalone: true}"
            id="FWOSvartoFBTarget">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerStoreOH')">

          <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerStoreOH | number"
            (ngModelChange)="week.CustomerInventory.CustomerStoreOH = $event" [ngModelOptions]="{standalone: true}"
            id="CustomerStoreOH">

        </tr>
        <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerDCOH')">

          <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerDCOH | number"
            (ngModelChange)="week.CustomerInventory.CustomerDCOH = $event" [ngModelOptions]="{standalone: true}"
            id="CustomerDCOH">

        </tr>
        <tr *ngIf="checkIfToShowColumn('Customer Inventory')"> <label>&nbsp;</label></tr>

        <!-- CustomerInventory -->

        <!-- FBInventory -->
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBTotalOnHand')">

          <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBTotalOnHand | number"
            (ngModelChange)="week.FBInventory.FBTotalOnHand = $event" [ngModelOptions]="{standalone: true}"
            id="FBTotalOnHand">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBAvailableAllocation')">

          <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBAvailableAllocation | number"
            (ngModelChange)="week.FBInventory.FBAvailableAllocation = $event" [ngModelOptions]="{standalone: true}"
            id="FBAvailableAllocation">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnOrder')">

          <input type="text" [disabled]="false" [ngModel]="week.FBInventory.OnOrder | number"
            (ngModelChange)="week.FBInventory.OnOrder = $event" [ngModelOptions]="{standalone: true}" id="OnOrder">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InTransit')">

          <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InTransit | number"
            (ngModelChange)="week.FBInventory.InTransit = $event" [ngModelOptions]="{standalone: true}" id="InTransit">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InventoryReceived')">

          <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InventoryReceived | number"
            (ngModelChange)="week.FBInventory.InventoryReceived = $event" [ngModelOptions]="{standalone: true}"
            id="InventoryReceived">

        </tr>
        <tr *ngIf="checkIfToShowColumn('FB Inventory')"> <label>&nbsp;</label></tr>

        <!-- FBInventory -->

        <!-- KPI -->
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeDeliveryPerformance')">

          <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeDeliveryPerformance | percent"
            (ngModelChange)="week.KPI.OnTimeDeliveryPerformance = $event" [ngModelOptions]="{standalone: true}"
            id="OnTimeDeliveryPerformance">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeInFull')">

          <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeInFull  | percent"
            (ngModelChange)="week.KPI.OnTimeInFull = $event" [ngModelOptions]="{standalone: true}" id="OnTimeInFull">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerChargebacks')">

          <input type="text" [disabled]="true" [ngModel]="week.KPI.CustomerChargebacks | currency"
            (ngModelChange)="week.KPI.CustomerChargebacks = $event" [ngModelOptions]="{standalone: true}"
            id="CustomerChargebacks">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('StandardDeliveryPst')">

          <input type="text" [disabled]="true" [ngModel]="week.KPI.StandardDeliveryPst  | percent"
            (ngModelChange)="week.KPI.StandardDeliveryPst = $event" [ngModelOptions]="{standalone: true}"
            id="StandardDeliveryPst">

        </tr>
        <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ExpeditedDeliveryPst')">

          <input type="text" [disabled]="true" [ngModel]="week.KPI.ExpeditedDeliveryPst  | percent"
            (ngModelChange)="week.KPI.ExpeditedDeliveryPst = $event" [ngModelOptions]="{standalone: true}"
            id="ExpeditedDeliveryPst">

        </tr>
        <tr *ngIf="checkIfToShowColumn('KPIs')"> <label>&nbsp;</label></tr>

        <!-- KPI -->
      </td>
      </ng-template>
      </tr>
      </td>
      <ng-container [ngTemplateOutlet]="!year.show && !half.show && !quarter.show ? null : quarterColumn"
        [ngTemplateOutletContext]="{week: getObject(year, {}, quarter, {})}">
      </ng-container>
      </tr>
      </td>
      <ng-container [ngTemplateOutlet]="!year.show && !half.show ? null : halfColumn"
        [ngTemplateOutletContext]="{week: getObject(year, {}, {}, half)}">
      </ng-container>
      </tr>
      </td>
      <ng-container [ngTemplateOutlet]="!year.show  ? null : yearColumn"
        [ngTemplateOutletContext]="{week: getObject(year, {}, {}, {})}">
      </ng-container>
      </tr>
      </td>

      </tr>
      </td>
      <!-- Data Table End-->

    </table>
  </div>
</div>
<ng-template #dialog let-data>
  <h1 mat-dialog-title>Show/Hide Measures</h1>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <mat-checkbox maxlength="20" type="text" [(ngModel)]="selectAll" (ngModelChange)="selectAllSelected($event)">
      Select/Remove All
    </mat-checkbox>
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSave()">Save &
      Close</button>
  </div>
  <div mat-dialog-content>
    <div style="display: flex;flex-direction: column;">
      <ng-container *ngFor="let item of chooserList || []">
        <ng-container>
          <mat-checkbox maxlength="20" type="text" [(ngModel)]="item.checked">
            {{item.name}}
          </mat-checkbox>
        </ng-container>
      </ng-container>

    </div>
  </div>
</ng-template>
<ng-template #dialogGraphFields let-data>
  <h1 mat-dialog-title>Show/Hide Measures</h1>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <mat-checkbox maxlength="20" type="text" [(ngModel)]="selectAll" (ngModelChange)="selectAllGraphSelected($event)">
      Select/Remove All
    </mat-checkbox>
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSave()">Save &
      Close</button>
  </div>
  <div mat-dialog-content>
    <div style="display: flex;flex-direction: column;">
      <ng-container *ngFor="let item of graphChooserList || []">
        <ng-container>
          <mat-checkbox maxlength="20" type="text" [(ngModel)]="item.checked">
            {{item.name}}
          </mat-checkbox>
        </ng-container>
      </ng-container>

    </div>
  </div>
</ng-template>
<ng-template #ExcelImport let-data>
  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <h1 mat-dialog-title>Import/Export</h1>
      <button mat-raised-button class="btn btn-primary" color="primary" (click)="exportToExcel()">Export to
        Excel</button>
    </div>
    <div>
      <ngx-dropzone (change)="onSelect($event)">
        <ngx-dropzone-label>Drop files here!</ngx-dropzone-label>
        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>
    <!-- <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
      </ngx-dropzone-image-preview> -->
  </div>
</ng-template>
<!-- Month Cummulated Column Start-->
<ng-template #otherColumn let-week="week">
  <td class="input-fields" style="text-align: center;">
    <tr> <label style="height:35px !important">&nbsp;</label></tr>
    <!-- Algo -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('BaselineSTForecast')">

      <input type="text" [disabled]="false" [ngModel]="week.Algo.BaselineSTForecast | number : '1.2-2'"
        (ngModelChange)="week.Algo.BaselineSTForecast = $event" [ngModelOptions]="{standalone: true}"
        id="BaselineSTForecast">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoPromoUnits')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoPromoUnits | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoPromoUnits = $event" [ngModelOptions]="{standalone: true}" id="AlgoPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoTotalSTFcst = $event" [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoConstrainedSTFcst')">

      <input type="text" [ngModel]="week.Algo.AlgoConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="AlgoConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoDPTotalSTFcst')">

      <input [ngModel]="week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst | percent" [ngModelOptions]="{standalone: true}"
        id="AlgoTotalSTFcstvartoDPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoCOTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoCOTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Algo ST')"> <label>&nbsp;</label></tr>
    <!-- Algo -->

    <!-- DemandPlanning -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPBaselineSTFcst')">

      <input type="text" [disabled]="false" [ngModel]="week.DemandPlanning.DPBaselineSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPBaselineSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="DPBaselineSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPManualFcstAdjustment')">

      <input [disabled]="false" type="text" [ngModel]="week.DemandPlanning.DPManualFcstAdjustment | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPManualFcstAdjustment = $event" [ngModelOptions]="{standalone: true}"
        (change)="DPManualFcstAdjustmentUpdated(week)" id="DPManualFcstAdjustment">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPPromoUnits')">

      <input [disabled]="false" type="text" (change)="DPPromoUnitsUpdated(week)"
        [ngModel]="week.DemandPlanning.DPPromoUnits | number : '1.2-2'" [ngModelOptions]="{standalone: true}"
        id="DPPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPTotalSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="DPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPConstrainedSTFcst')">

      <input type="text" [ngModel]="week.DemandPlanning.DPConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="DPConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.DemandPlanning.DPComments"
        [ngModelOptions]="{standalone: true}" id="DPComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoCOTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoCOTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoCOTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoAlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoAlgoTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoAlgoTotalSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Demand Planning ST')"> <label>&nbsp;</label></tr>
    <!-- DemandPlanning -->

    <!-- ChannelOps -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COBaselineSTForecast')">
      <input type="text" [disabled]="false" [ngModel]="week.ChannelOps.COBaselineSTForecast | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COBaselineSTForecast = $event" [ngModelOptions]="{standalone: true}"
        id="COBaselineSTForecast">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COManualFcstAdjustment')">

      <input [disabled]="false" type="text" [ngModel]="week.ChannelOps.COManualFcstAdjustment | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COManualFcstAdjustment = $event" [ngModelOptions]="{standalone: true}"
        (change)="COManualFcstAdjustmentUpdated(week)" id="COManualFcstAdjustment">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COPromoUnits')">

      <input [disabled]="false" type="text" (change)="COPromoUnitsUpdated(week)"
        [ngModel]="week.ChannelOps.COPromoUnits | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COPromoUnits = $event" [ngModelOptions]="{standalone: true}" id="COPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COTotalSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="COTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COConstrainedSTFcst')">

      <input type="text" [ngModel]="week.ChannelOps.COConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="COConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.ChannelOps.COComments"
        [ngModelOptions]="{standalone: true}" id="COComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoDPTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoDPTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoDPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoAlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoAlgoTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoAlgoTotalSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Channel Ops ST')"> <label>&nbsp;</label></tr>

    <!-- ChannelOps -->

    <!-- CurrentSellThrough -->
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerSTFcst')">

      <input [disabled]="false" type="text" (change)="CustomerSTFcstUpdated(week)"
        [ngModel]="week.CurrentSellThrough.CustomerSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.CustomerSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSTFcst')">

      <input [disabled]="false" type="text" (change)="ConsensusSTFcstUpdated(week)"
        [ngModel]="week.CurrentSellThrough.ConsensusSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="ConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvartoCustomerSTFcst')">

      <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst = $event"
        [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvartoCustomerSTFcst">

    </tr>
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ConstrainedSTFcst | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
            id="ConstrainedSTFcst">

        </tr> -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvartoConsensusSTFcst')">

          <input type="text" [disabled]="true"
            [ngModel]="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst = $event"
            [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvartoConsensusSTFcst">

        </tr> -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ProjectedLostSales')">

      <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ProjectedLostSales | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ProjectedLostSales = $event" [ngModelOptions]="{standalone: true}"
        id="ProjectedLostSales">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualST')">

      <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.ActualST | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ActualST = $event" [ngModelOptions]="{standalone: true}" id="ActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('WeeklyTrend')">

      <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.WeeklyTrend | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.WeeklyTrend = $event" [ngModelOptions]="{standalone: true}"
        id="WeeklyTrend">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('PromoIndicator')">

      <input type="text" [disabled]="false" [(ngModel)]="week.CurrentSellThrough.PromoIndicator"
        [ngModelOptions]="{standalone: true}" id="PromoIndicator">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CPFRComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.CurrentSellThrough.CPFRComments"
        [ngModelOptions]="{standalone: true}" id="CPFRComments">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerInStockEstimate')">

      <input [disabled]="false" type="text" [ngModel]="week.CurrentSellThrough.CustomerInStockEstimate | percent"
        [ngModelOptions]="{standalone: true}" id="CustomerInStockEstimate">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstUnits')">

      <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstUnits | number:'1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ActualVartoConsensusFcstUnits = $event"
        [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstPst')">

      <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstPst | percent"
        [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstPst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative ST')"> <label>&nbsp;</label></tr>

    <!-- CurrentSellThrough -->

    <!-- LastYearSales -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualST')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYActualST | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYActualST = $event" [ngModelOptions]="{standalone: true}" id="LYActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYPromoIndicator')">

      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYPromoIndicator"
        [ngModelOptions]="{standalone: true}" id="LYPromoIndicator">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCOComments')">

      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYCOComments"
        [ngModelOptions]="{standalone: true}" id="LYCOComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCustomerInStockEstimate')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYCustomerInStockEstimate | percent"
        [ngModelOptions]="{standalone: true}" id="LYCustomerInStockEstimate">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYConsensusFcst')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYConsensusFcst | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYConsensusFcst = $event" [ngModelOptions]="{standalone: true}"
        id="LYConsensusFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarPst')">

      <input type="text" [disabled]="true" [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarPst | percent"
        [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarPst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarUnits')">

      <input type="text" [disabled]="true"
        [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarUnits | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYActualtoConsensusFcstVarUnits = $event"
        [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarUnits">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Last Year ST')"> <label>&nbsp;</label></tr>

    <!-- LastYearSales -->

    <!-- StAccuracy -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.CustomerSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="CustomerSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.AlgoTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.COTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.DPTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvsActualST">

    </tr>
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvsActualST')">

          <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConstrainedSTFcstvsActualST | percent"
            [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvsActualST">

        </tr> -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConsensusSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvsActualST">

    </tr>
    <tr *ngIf="checkIfToShowColumn('ST Accuracy')"> <label>&nbsp;</label></tr>

    <!-- StAccuracy -->

    <!-- SellIn -->
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerRequestedSellInReceiveWk')">

      <input [disabled]="false" type="text" (change)="CustomerRequestedSellInReceiveWkUpdated(week)"
        [ngModel]="week.SellIn.CustomerRequestedSellInReceiveWk | number"
        (ngModelChange)="week.SellIn.CustomerRequestedSellInReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerRequestedSellInReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('FinalSellInCommitReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.FinalSellInCommitReceiveWk | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="FinalSellInCommitReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitvartoCustomerRequest')">

      <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitvartoCustomerRequest | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitvartoCustomerRequest = $event"
        [ngModelOptions]="{standalone: true}" id="FinalSellInCommitvartoCustomerRequest">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoSellInFcstReceiveWk')">

      <input type="text" [disabled]="false" [ngModel]="week.SellIn.AlgoSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.AlgoSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="AlgoSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.DPSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.DPSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="DPSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.COSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.COSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="COSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.ConsensusSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.ConsensusSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="ConsensusSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitsShipWk')">

      <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitsShipWk | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitsShipWk = $event" [ngModelOptions]="{standalone: true}"
        id="FinalSellInCommitsShipWk">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative SI')"> <label>&nbsp;</label></tr>

    <!-- SellIn -->


    <!-- CustomerInventory -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerIOHProjection')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerIOHProjection | number"
        (ngModelChange)="week.CustomerInventory.CustomerIOHProjection = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerIOHProjection">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('IOHProjection')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.IOHProjection | number"
        (ngModelChange)="week.CustomerInventory.IOHProjection = $event" [ngModelOptions]="{standalone: true}"
        id="IOHProjection">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerFWOSCalc')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerFWOSCalc | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.CustomerFWOSCalc = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerFWOSCalc">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBFWOSCalc')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FBFWOSCalc | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FBFWOSCalc = $event" [ngModelOptions]="{standalone: true}"
        id="FBFWOSCalc">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoCustomerTarget')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoCustomerTarget | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FWOSvartoCustomerTarget = $event" [ngModelOptions]="{standalone: true}"
        id="FWOSvartoCustomerTarget">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoFBTarget')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoFBTarget | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FWOSvartoFBTarget = $event" [ngModelOptions]="{standalone: true}"
        id="FWOSvartoFBTarget">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerStoreOH')">

      <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerStoreOH | number"
        (ngModelChange)="week.CustomerInventory.CustomerStoreOH = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerStoreOH">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerDCOH')">

      <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerDCOH | number"
        (ngModelChange)="week.CustomerInventory.CustomerDCOH = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerDCOH">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Customer Inventory')"> <label>&nbsp;</label></tr>

    <!-- CustomerInventory -->

    <!-- FBInventory -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBTotalOnHand')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBTotalOnHand | number"
        (ngModelChange)="week.FBInventory.FBTotalOnHand = $event" [ngModelOptions]="{standalone: true}"
        id="FBTotalOnHand">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBAvailableAllocation')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBAvailableAllocation | number"
        (ngModelChange)="week.FBInventory.FBAvailableAllocation = $event" [ngModelOptions]="{standalone: true}"
        id="FBAvailableAllocation">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnOrder')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.OnOrder | number"
        (ngModelChange)="week.FBInventory.OnOrder = $event" [ngModelOptions]="{standalone: true}" id="OnOrder">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InTransit')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InTransit | number"
        (ngModelChange)="week.FBInventory.InTransit = $event" [ngModelOptions]="{standalone: true}" id="InTransit">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InventoryReceived')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InventoryReceived | number"
        (ngModelChange)="week.FBInventory.InventoryReceived = $event" [ngModelOptions]="{standalone: true}"
        id="InventoryReceived">

    </tr>
    <tr *ngIf="checkIfToShowColumn('FB Inventory')"> <label>&nbsp;</label></tr>

    <!-- FBInventory -->

    <!-- KPI -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeDeliveryPerformance')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeDeliveryPerformance | percent"
        (ngModelChange)="week.KPI.OnTimeDeliveryPerformance = $event" [ngModelOptions]="{standalone: true}"
        id="OnTimeDeliveryPerformance">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeInFull')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeInFull  | percent"
        (ngModelChange)="week.KPI.OnTimeInFull = $event" [ngModelOptions]="{standalone: true}" id="OnTimeInFull">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerChargebacks')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.CustomerChargebacks | currency"
        (ngModelChange)="week.KPI.CustomerChargebacks = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerChargebacks">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('StandardDeliveryPst')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.StandardDeliveryPst  | percent"
        (ngModelChange)="week.KPI.StandardDeliveryPst = $event" [ngModelOptions]="{standalone: true}"
        id="StandardDeliveryPst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ExpeditedDeliveryPst')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.ExpeditedDeliveryPst  | percent"
        (ngModelChange)="week.KPI.ExpeditedDeliveryPst = $event" [ngModelOptions]="{standalone: true}"
        id="ExpeditedDeliveryPst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('KPIs')"> <label>&nbsp;</label></tr>

    <!-- KPI -->
  </td>
</ng-template>
<!-- Month Cummulated Column End -->

<!-- Quarter Cummulated Column Start -->

<ng-template #quarterColumn let-week="week">
  <td class="input-fields" style="text-align: center;">
    <tr style="height:30px !important"> <label style="height:30px !important">&nbsp;</label></tr>
    <tr style="height:29px !important"> <label style="height:29px !important">&nbsp;</label></tr>
    <!-- Algo -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('BaselineSTForecast')">

      <input type="text" [disabled]="false" [ngModel]="week.Algo.BaselineSTForecast | number : '1.2-2'"
        (ngModelChange)="week.Algo.BaselineSTForecast = $event" [ngModelOptions]="{standalone: true}"
        id="BaselineSTForecast">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoPromoUnits')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoPromoUnits | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoPromoUnits = $event" [ngModelOptions]="{standalone: true}" id="AlgoPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoTotalSTFcst = $event" [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoConstrainedSTFcst')">

      <input type="text" [ngModel]="week.Algo.AlgoConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="AlgoConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoDPTotalSTFcst')">

      <input [ngModel]="week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst | percent" [ngModelOptions]="{standalone: true}"
        id="AlgoTotalSTFcstvartoDPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoCOTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoCOTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Algo ST')"> <label>&nbsp;</label></tr>
    <!-- Algo -->

    <!-- DemandPlanning -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPBaselineSTFcst')">

      <input type="text" [disabled]="false" [ngModel]="week.DemandPlanning.DPBaselineSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPBaselineSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="DPBaselineSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPManualFcstAdjustment')">

      <input [disabled]="false" type="text" [ngModel]="week.DemandPlanning.DPManualFcstAdjustment | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPManualFcstAdjustment = $event" [ngModelOptions]="{standalone: true}"
        (change)="DPManualFcstAdjustmentUpdated(week)" id="DPManualFcstAdjustment">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPPromoUnits')">

      <input [disabled]="false" type="text" (change)="DPPromoUnitsUpdated(week)"
        [ngModel]="week.DemandPlanning.DPPromoUnits | number : '1.2-2'" [ngModelOptions]="{standalone: true}"
        id="DPPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPTotalSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="DPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPConstrainedSTFcst')">

      <input type="text" [ngModel]="week.DemandPlanning.DPConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="DPConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.DemandPlanning.DPComments"
        [ngModelOptions]="{standalone: true}" id="DPComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoCOTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoCOTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoCOTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoAlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoAlgoTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoAlgoTotalSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Demand Planning ST')"> <label>&nbsp;</label></tr>
    <!-- DemandPlanning -->

    <!-- ChannelOps -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COBaselineSTForecast')">
      <input type="text" [disabled]="false" [ngModel]="week.ChannelOps.COBaselineSTForecast | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COBaselineSTForecast = $event" [ngModelOptions]="{standalone: true}"
        id="COBaselineSTForecast">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COManualFcstAdjustment')">

      <input [disabled]="false" type="text" [ngModel]="week.ChannelOps.COManualFcstAdjustment | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COManualFcstAdjustment = $event" [ngModelOptions]="{standalone: true}"
        (change)="COManualFcstAdjustmentUpdated(week)" id="COManualFcstAdjustment">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COPromoUnits')">

      <input [disabled]="false" type="text" (change)="COPromoUnitsUpdated(week)"
        [ngModel]="week.ChannelOps.COPromoUnits | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COPromoUnits = $event" [ngModelOptions]="{standalone: true}" id="COPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COTotalSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="COTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COConstrainedSTFcst')">

      <input type="text" [ngModel]="week.ChannelOps.COConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="COConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.ChannelOps.COComments"
        [ngModelOptions]="{standalone: true}" id="COComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoDPTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoDPTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoDPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoAlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoAlgoTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoAlgoTotalSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Channel Ops ST')"> <label>&nbsp;</label></tr>

    <!-- ChannelOps -->

    <!-- CurrentSellThrough -->
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerSTFcst')">

      <input [disabled]="false" type="text" (change)="CustomerSTFcstUpdated(week)"
        [ngModel]="week.CurrentSellThrough.CustomerSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.CustomerSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSTFcst')">

      <input [disabled]="false" type="text" (change)="ConsensusSTFcstUpdated(week)"
        [ngModel]="week.CurrentSellThrough.ConsensusSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="ConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvartoCustomerSTFcst')">

      <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst = $event"
        [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvartoCustomerSTFcst">

    </tr>
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ConstrainedSTFcst | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
            id="ConstrainedSTFcst">

        </tr> -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvartoConsensusSTFcst')">

          <input type="text" [disabled]="true"
            [ngModel]="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst = $event"
            [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvartoConsensusSTFcst">

        </tr> -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ProjectedLostSales')">

      <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ProjectedLostSales | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ProjectedLostSales = $event" [ngModelOptions]="{standalone: true}"
        id="ProjectedLostSales">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualST')">

      <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.ActualST | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ActualST = $event" [ngModelOptions]="{standalone: true}" id="ActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('WeeklyTrend')">

      <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.WeeklyTrend | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.WeeklyTrend = $event" [ngModelOptions]="{standalone: true}"
        id="WeeklyTrend">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('PromoIndicator')">

      <input type="text" [disabled]="false" [(ngModel)]="week.CurrentSellThrough.PromoIndicator"
        [ngModelOptions]="{standalone: true}" id="PromoIndicator">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CPFRComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.CurrentSellThrough.CPFRComments"
        [ngModelOptions]="{standalone: true}" id="CPFRComments">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerInStockEstimate')">

      <input [disabled]="false" type="text" [ngModel]="week.CurrentSellThrough.CustomerInStockEstimate | percent"
        [ngModelOptions]="{standalone: true}" id="CustomerInStockEstimate">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstUnits')">

      <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstUnits | number:'1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ActualVartoConsensusFcstUnits = $event"
        [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstPst')">

      <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstPst | percent"
        [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstPst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative ST')"> <label>&nbsp;</label></tr>

    <!-- CurrentSellThrough -->

    <!-- LastYearSales -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualST')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYActualST | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYActualST = $event" [ngModelOptions]="{standalone: true}" id="LYActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYPromoIndicator')">

      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYPromoIndicator"
        [ngModelOptions]="{standalone: true}" id="LYPromoIndicator">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCOComments')">

      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYCOComments"
        [ngModelOptions]="{standalone: true}" id="LYCOComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCustomerInStockEstimate')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYCustomerInStockEstimate | percent"
        [ngModelOptions]="{standalone: true}" id="LYCustomerInStockEstimate">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYConsensusFcst')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYConsensusFcst | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYConsensusFcst = $event" [ngModelOptions]="{standalone: true}"
        id="LYConsensusFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarPst')">

      <input type="text" [disabled]="true" [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarPst | percent"
        [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarPst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarUnits')">

      <input type="text" [disabled]="true"
        [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarUnits | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYActualtoConsensusFcstVarUnits = $event"
        [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarUnits">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Last Year ST')"> <label>&nbsp;</label></tr>

    <!-- LastYearSales -->

    <!-- StAccuracy -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.CustomerSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="CustomerSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.AlgoTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.COTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.DPTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvsActualST">

    </tr>
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvsActualST')">

          <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConstrainedSTFcstvsActualST | percent"
            [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvsActualST">

        </tr> -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConsensusSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvsActualST">

    </tr>
    <tr *ngIf="checkIfToShowColumn('ST Accuracy')"> <label>&nbsp;</label></tr>

    <!-- StAccuracy -->

    <!-- SellIn -->
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerRequestedSellInReceiveWk')">

      <input [disabled]="false" type="text" (change)="CustomerRequestedSellInReceiveWkUpdated(week)"
        [ngModel]="week.SellIn.CustomerRequestedSellInReceiveWk | number"
        (ngModelChange)="week.SellIn.CustomerRequestedSellInReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerRequestedSellInReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('FinalSellInCommitReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.FinalSellInCommitReceiveWk | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="FinalSellInCommitReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitvartoCustomerRequest')">

      <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitvartoCustomerRequest | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitvartoCustomerRequest = $event"
        [ngModelOptions]="{standalone: true}" id="FinalSellInCommitvartoCustomerRequest">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoSellInFcstReceiveWk')">

      <input type="text" [disabled]="false" [ngModel]="week.SellIn.AlgoSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.AlgoSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="AlgoSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.DPSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.DPSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="DPSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.COSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.COSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="COSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.ConsensusSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.ConsensusSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="ConsensusSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitsShipWk')">

      <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitsShipWk | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitsShipWk = $event" [ngModelOptions]="{standalone: true}"
        id="FinalSellInCommitsShipWk">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative SI')"> <label>&nbsp;</label></tr>

    <!-- SellIn -->


    <!-- CustomerInventory -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerIOHProjection')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerIOHProjection | number"
        (ngModelChange)="week.CustomerInventory.CustomerIOHProjection = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerIOHProjection">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('IOHProjection')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.IOHProjection | number"
        (ngModelChange)="week.CustomerInventory.IOHProjection = $event" [ngModelOptions]="{standalone: true}"
        id="IOHProjection">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerFWOSCalc')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerFWOSCalc | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.CustomerFWOSCalc = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerFWOSCalc">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBFWOSCalc')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FBFWOSCalc | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FBFWOSCalc = $event" [ngModelOptions]="{standalone: true}"
        id="FBFWOSCalc">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoCustomerTarget')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoCustomerTarget | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FWOSvartoCustomerTarget = $event" [ngModelOptions]="{standalone: true}"
        id="FWOSvartoCustomerTarget">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoFBTarget')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoFBTarget | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FWOSvartoFBTarget = $event" [ngModelOptions]="{standalone: true}"
        id="FWOSvartoFBTarget">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerStoreOH')">

      <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerStoreOH | number"
        (ngModelChange)="week.CustomerInventory.CustomerStoreOH = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerStoreOH">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerDCOH')">

      <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerDCOH | number"
        (ngModelChange)="week.CustomerInventory.CustomerDCOH = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerDCOH">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Customer Inventory')"> <label>&nbsp;</label></tr>

    <!-- CustomerInventory -->

    <!-- FBInventory -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBTotalOnHand')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBTotalOnHand | number"
        (ngModelChange)="week.FBInventory.FBTotalOnHand = $event" [ngModelOptions]="{standalone: true}"
        id="FBTotalOnHand">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBAvailableAllocation')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBAvailableAllocation | number"
        (ngModelChange)="week.FBInventory.FBAvailableAllocation = $event" [ngModelOptions]="{standalone: true}"
        id="FBAvailableAllocation">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnOrder')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.OnOrder | number"
        (ngModelChange)="week.FBInventory.OnOrder = $event" [ngModelOptions]="{standalone: true}" id="OnOrder">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InTransit')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InTransit | number"
        (ngModelChange)="week.FBInventory.InTransit = $event" [ngModelOptions]="{standalone: true}" id="InTransit">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InventoryReceived')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InventoryReceived | number"
        (ngModelChange)="week.FBInventory.InventoryReceived = $event" [ngModelOptions]="{standalone: true}"
        id="InventoryReceived">

    </tr>
    <tr *ngIf="checkIfToShowColumn('FB Inventory')"> <label>&nbsp;</label></tr>

    <!-- FBInventory -->

    <!-- KPI -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeDeliveryPerformance')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeDeliveryPerformance | percent"
        (ngModelChange)="week.KPI.OnTimeDeliveryPerformance = $event" [ngModelOptions]="{standalone: true}"
        id="OnTimeDeliveryPerformance">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeInFull')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeInFull  | percent"
        (ngModelChange)="week.KPI.OnTimeInFull = $event" [ngModelOptions]="{standalone: true}" id="OnTimeInFull">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerChargebacks')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.CustomerChargebacks | currency"
        (ngModelChange)="week.KPI.CustomerChargebacks = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerChargebacks">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('StandardDeliveryPst')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.StandardDeliveryPst  | percent"
        (ngModelChange)="week.KPI.StandardDeliveryPst = $event" [ngModelOptions]="{standalone: true}"
        id="StandardDeliveryPst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ExpeditedDeliveryPst')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.ExpeditedDeliveryPst  | percent"
        (ngModelChange)="week.KPI.ExpeditedDeliveryPst = $event" [ngModelOptions]="{standalone: true}"
        id="ExpeditedDeliveryPst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('KPIs')"> <label>&nbsp;</label></tr>

    <!-- KPI -->
  </td>
</ng-template>
<!-- Quarter Cummulated Column End -->

<!-- Half Cummulated Column Start -->
<ng-template #halfColumn let-week="week">
  <td class="input-fields" style="text-align: center;">
    <tr style="height:27px !important"> <label style="height:27px !important">&nbsp;</label></tr>
    <tr style="height:27px !important"> <label style="height:27px !important">&nbsp;</label></tr>
    <tr style="height:28px !important"> <label style="height:28px !important">&nbsp;</label></tr>
    <!-- Algo -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('BaselineSTForecast')">

      <input type="text" [disabled]="false" [ngModel]="week.Algo.BaselineSTForecast | number : '1.2-2'"
        (ngModelChange)="week.Algo.BaselineSTForecast = $event" [ngModelOptions]="{standalone: true}"
        id="BaselineSTForecast">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoPromoUnits')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoPromoUnits | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoPromoUnits = $event" [ngModelOptions]="{standalone: true}" id="AlgoPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoTotalSTFcst = $event" [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoConstrainedSTFcst')">

      <input type="text" [ngModel]="week.Algo.AlgoConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="AlgoConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoDPTotalSTFcst')">

      <input [ngModel]="week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst | percent" [ngModelOptions]="{standalone: true}"
        id="AlgoTotalSTFcstvartoDPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoCOTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoCOTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Algo ST')"> <label>&nbsp;</label></tr>
    <!-- Algo -->

    <!-- DemandPlanning -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPBaselineSTFcst')">

      <input type="text" [disabled]="false" [ngModel]="week.DemandPlanning.DPBaselineSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPBaselineSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="DPBaselineSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPManualFcstAdjustment')">

      <input [disabled]="false" type="text" [ngModel]="week.DemandPlanning.DPManualFcstAdjustment | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPManualFcstAdjustment = $event" [ngModelOptions]="{standalone: true}"
        (change)="DPManualFcstAdjustmentUpdated(week)" id="DPManualFcstAdjustment">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPPromoUnits')">

      <input [disabled]="false" type="text" (change)="DPPromoUnitsUpdated(week)"
        [ngModel]="week.DemandPlanning.DPPromoUnits | number : '1.2-2'" [ngModelOptions]="{standalone: true}"
        id="DPPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPTotalSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="DPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPConstrainedSTFcst')">

      <input type="text" [ngModel]="week.DemandPlanning.DPConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="DPConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.DemandPlanning.DPComments"
        [ngModelOptions]="{standalone: true}" id="DPComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoCOTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoCOTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoCOTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoAlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoAlgoTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoAlgoTotalSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Demand Planning ST')"> <label>&nbsp;</label></tr>
    <!-- DemandPlanning -->

    <!-- ChannelOps -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COBaselineSTForecast')">
      <input type="text" [disabled]="false" [ngModel]="week.ChannelOps.COBaselineSTForecast | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COBaselineSTForecast = $event" [ngModelOptions]="{standalone: true}"
        id="COBaselineSTForecast">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COManualFcstAdjustment')">

      <input [disabled]="false" type="text" [ngModel]="week.ChannelOps.COManualFcstAdjustment | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COManualFcstAdjustment = $event" [ngModelOptions]="{standalone: true}"
        (change)="COManualFcstAdjustmentUpdated(week)" id="COManualFcstAdjustment">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COPromoUnits')">

      <input [disabled]="false" type="text" (change)="COPromoUnitsUpdated(week)"
        [ngModel]="week.ChannelOps.COPromoUnits | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COPromoUnits = $event" [ngModelOptions]="{standalone: true}" id="COPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COTotalSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="COTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COConstrainedSTFcst')">

      <input type="text" [ngModel]="week.ChannelOps.COConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="COConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.ChannelOps.COComments"
        [ngModelOptions]="{standalone: true}" id="COComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoDPTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoDPTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoDPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoAlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoAlgoTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoAlgoTotalSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Channel Ops ST')"> <label>&nbsp;</label></tr>

    <!-- ChannelOps -->

    <!-- CurrentSellThrough -->
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerSTFcst')">

      <input [disabled]="false" type="text" (change)="CustomerSTFcstUpdated(week)"
        [ngModel]="week.CurrentSellThrough.CustomerSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.CustomerSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSTFcst')">

      <input [disabled]="false" type="text" (change)="ConsensusSTFcstUpdated(week)"
        [ngModel]="week.CurrentSellThrough.ConsensusSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="ConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvartoCustomerSTFcst')">

      <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst = $event"
        [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvartoCustomerSTFcst">

    </tr>
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ConstrainedSTFcst | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
            id="ConstrainedSTFcst">

        </tr> -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvartoConsensusSTFcst')">

          <input type="text" [disabled]="true"
            [ngModel]="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst = $event"
            [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvartoConsensusSTFcst">

        </tr> -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ProjectedLostSales')">

      <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ProjectedLostSales | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ProjectedLostSales = $event" [ngModelOptions]="{standalone: true}"
        id="ProjectedLostSales">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualST')">

      <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.ActualST | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ActualST = $event" [ngModelOptions]="{standalone: true}" id="ActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('WeeklyTrend')">

      <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.WeeklyTrend | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.WeeklyTrend = $event" [ngModelOptions]="{standalone: true}"
        id="WeeklyTrend">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('PromoIndicator')">

      <input type="text" [disabled]="false" [(ngModel)]="week.CurrentSellThrough.PromoIndicator"
        [ngModelOptions]="{standalone: true}" id="PromoIndicator">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CPFRComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.CurrentSellThrough.CPFRComments"
        [ngModelOptions]="{standalone: true}" id="CPFRComments">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerInStockEstimate')">

      <input [disabled]="false" type="text" [ngModel]="week.CurrentSellThrough.CustomerInStockEstimate | percent"
        [ngModelOptions]="{standalone: true}" id="CustomerInStockEstimate">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstUnits')">

      <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstUnits | number:'1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ActualVartoConsensusFcstUnits = $event"
        [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstPst')">

      <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstPst | percent"
        [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstPst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative ST')"> <label>&nbsp;</label></tr>

    <!-- CurrentSellThrough -->

    <!-- LastYearSales -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualST')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYActualST | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYActualST = $event" [ngModelOptions]="{standalone: true}" id="LYActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYPromoIndicator')">

      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYPromoIndicator"
        [ngModelOptions]="{standalone: true}" id="LYPromoIndicator">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCOComments')">

      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYCOComments"
        [ngModelOptions]="{standalone: true}" id="LYCOComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCustomerInStockEstimate')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYCustomerInStockEstimate | percent"
        [ngModelOptions]="{standalone: true}" id="LYCustomerInStockEstimate">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYConsensusFcst')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYConsensusFcst | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYConsensusFcst = $event" [ngModelOptions]="{standalone: true}"
        id="LYConsensusFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarPst')">

      <input type="text" [disabled]="true" [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarPst | percent"
        [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarPst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarUnits')">

      <input type="text" [disabled]="true"
        [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarUnits | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYActualtoConsensusFcstVarUnits = $event"
        [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarUnits">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Last Year ST')"> <label>&nbsp;</label></tr>

    <!-- LastYearSales -->

    <!-- StAccuracy -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.CustomerSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="CustomerSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.AlgoTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.COTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.DPTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvsActualST">

    </tr>
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvsActualST')">

          <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConstrainedSTFcstvsActualST | percent"
            [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvsActualST">

        </tr> -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConsensusSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvsActualST">

    </tr>
    <tr *ngIf="checkIfToShowColumn('ST Accuracy')"> <label>&nbsp;</label></tr>

    <!-- StAccuracy -->

    <!-- SellIn -->
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerRequestedSellInReceiveWk')">

      <input [disabled]="false" type="text" (change)="CustomerRequestedSellInReceiveWkUpdated(week)"
        [ngModel]="week.SellIn.CustomerRequestedSellInReceiveWk | number"
        (ngModelChange)="week.SellIn.CustomerRequestedSellInReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerRequestedSellInReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('FinalSellInCommitReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.FinalSellInCommitReceiveWk | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="FinalSellInCommitReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitvartoCustomerRequest')">

      <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitvartoCustomerRequest | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitvartoCustomerRequest = $event"
        [ngModelOptions]="{standalone: true}" id="FinalSellInCommitvartoCustomerRequest">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoSellInFcstReceiveWk')">

      <input type="text" [disabled]="false" [ngModel]="week.SellIn.AlgoSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.AlgoSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="AlgoSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.DPSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.DPSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="DPSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.COSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.COSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="COSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.ConsensusSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.ConsensusSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="ConsensusSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitsShipWk')">

      <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitsShipWk | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitsShipWk = $event" [ngModelOptions]="{standalone: true}"
        id="FinalSellInCommitsShipWk">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative SI')"> <label>&nbsp;</label></tr>

    <!-- SellIn -->


    <!-- CustomerInventory -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerIOHProjection')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerIOHProjection | number"
        (ngModelChange)="week.CustomerInventory.CustomerIOHProjection = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerIOHProjection">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('IOHProjection')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.IOHProjection | number"
        (ngModelChange)="week.CustomerInventory.IOHProjection = $event" [ngModelOptions]="{standalone: true}"
        id="IOHProjection">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerFWOSCalc')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerFWOSCalc | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.CustomerFWOSCalc = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerFWOSCalc">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBFWOSCalc')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FBFWOSCalc | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FBFWOSCalc = $event" [ngModelOptions]="{standalone: true}"
        id="FBFWOSCalc">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoCustomerTarget')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoCustomerTarget | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FWOSvartoCustomerTarget = $event" [ngModelOptions]="{standalone: true}"
        id="FWOSvartoCustomerTarget">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoFBTarget')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoFBTarget | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FWOSvartoFBTarget = $event" [ngModelOptions]="{standalone: true}"
        id="FWOSvartoFBTarget">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerStoreOH')">

      <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerStoreOH | number"
        (ngModelChange)="week.CustomerInventory.CustomerStoreOH = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerStoreOH">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerDCOH')">

      <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerDCOH | number"
        (ngModelChange)="week.CustomerInventory.CustomerDCOH = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerDCOH">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Customer Inventory')"> <label>&nbsp;</label></tr>

    <!-- CustomerInventory -->

    <!-- FBInventory -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBTotalOnHand')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBTotalOnHand | number"
        (ngModelChange)="week.FBInventory.FBTotalOnHand = $event" [ngModelOptions]="{standalone: true}"
        id="FBTotalOnHand">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBAvailableAllocation')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBAvailableAllocation | number"
        (ngModelChange)="week.FBInventory.FBAvailableAllocation = $event" [ngModelOptions]="{standalone: true}"
        id="FBAvailableAllocation">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnOrder')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.OnOrder | number"
        (ngModelChange)="week.FBInventory.OnOrder = $event" [ngModelOptions]="{standalone: true}" id="OnOrder">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InTransit')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InTransit | number"
        (ngModelChange)="week.FBInventory.InTransit = $event" [ngModelOptions]="{standalone: true}" id="InTransit">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InventoryReceived')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InventoryReceived | number"
        (ngModelChange)="week.FBInventory.InventoryReceived = $event" [ngModelOptions]="{standalone: true}"
        id="InventoryReceived">

    </tr>
    <tr *ngIf="checkIfToShowColumn('FB Inventory')"> <label>&nbsp;</label></tr>

    <!-- FBInventory -->

    <!-- KPI -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeDeliveryPerformance')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeDeliveryPerformance | percent"
        (ngModelChange)="week.KPI.OnTimeDeliveryPerformance = $event" [ngModelOptions]="{standalone: true}"
        id="OnTimeDeliveryPerformance">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeInFull')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeInFull  | percent"
        (ngModelChange)="week.KPI.OnTimeInFull = $event" [ngModelOptions]="{standalone: true}" id="OnTimeInFull">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerChargebacks')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.CustomerChargebacks | currency"
        (ngModelChange)="week.KPI.CustomerChargebacks = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerChargebacks">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('StandardDeliveryPst')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.StandardDeliveryPst  | percent"
        (ngModelChange)="week.KPI.StandardDeliveryPst = $event" [ngModelOptions]="{standalone: true}"
        id="StandardDeliveryPst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ExpeditedDeliveryPst')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.ExpeditedDeliveryPst  | percent"
        (ngModelChange)="week.KPI.ExpeditedDeliveryPst = $event" [ngModelOptions]="{standalone: true}"
        id="ExpeditedDeliveryPst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('KPIs')"> <label>&nbsp;</label></tr>

    <!-- KPI -->
  </td>
</ng-template>
<ng-template #yearColumn let-week="week">
  <td class="input-fields" style="text-align: center;">
    <tr style="height:27px !important"> <label style="height:27px !important">&nbsp;</label></tr>
    <tr style="height:26px !important"> <label style="height:27px !important">&nbsp;</label></tr>
    <tr style="height:26px !important"> <label style="height:27px !important">&nbsp;</label></tr>
    <tr style="height:28px !important"> <label style="height:28px !important">&nbsp;</label></tr>
    <!-- Algo -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('BaselineSTForecast')">

      <input type="text" [disabled]="false" [ngModel]="week.Algo.BaselineSTForecast | number : '1.2-2'"
        (ngModelChange)="week.Algo.BaselineSTForecast = $event" [ngModelOptions]="{standalone: true}"
        id="BaselineSTForecast">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoPromoUnits')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoPromoUnits | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoPromoUnits = $event" [ngModelOptions]="{standalone: true}" id="AlgoPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoTotalSTFcst = $event" [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoConstrainedSTFcst')">

      <input type="text" [ngModel]="week.Algo.AlgoConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.Algo.AlgoConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="AlgoConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoDPTotalSTFcst')">

      <input [ngModel]="week.Algo.AlgoTotalSTFcstvartoDPTotalSTFcst | percent" [ngModelOptions]="{standalone: true}"
        id="AlgoTotalSTFcstvartoDPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoCOTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoCOTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoCOTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.Algo.AlgoTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Algo ST')"> <label>&nbsp;</label></tr>
    <!-- Algo -->

    <!-- DemandPlanning -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPBaselineSTFcst')">

      <input type="text" [disabled]="false" [ngModel]="week.DemandPlanning.DPBaselineSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPBaselineSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="DPBaselineSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPManualFcstAdjustment')">

      <input [disabled]="false" type="text" [ngModel]="week.DemandPlanning.DPManualFcstAdjustment | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPManualFcstAdjustment = $event" [ngModelOptions]="{standalone: true}"
        (change)="DPManualFcstAdjustmentUpdated(week)" id="DPManualFcstAdjustment">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPPromoUnits')">

      <input [disabled]="false" type="text" (change)="DPPromoUnitsUpdated(week)"
        [ngModel]="week.DemandPlanning.DPPromoUnits | number : '1.2-2'" [ngModelOptions]="{standalone: true}"
        id="DPPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPTotalSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="DPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPConstrainedSTFcst')">

      <input type="text" [ngModel]="week.DemandPlanning.DPConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.DemandPlanning.DPConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="DPConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.DemandPlanning.DPComments"
        [ngModelOptions]="{standalone: true}" id="DPComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoCOTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoCOTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoCOTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvartoAlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.DemandPlanning.DPTotalSTFcstvartoAlgoTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvartoAlgoTotalSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Demand Planning ST')"> <label>&nbsp;</label></tr>
    <!-- DemandPlanning -->

    <!-- ChannelOps -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COBaselineSTForecast')">
      <input type="text" [disabled]="false" [ngModel]="week.ChannelOps.COBaselineSTForecast | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COBaselineSTForecast = $event" [ngModelOptions]="{standalone: true}"
        id="COBaselineSTForecast">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COManualFcstAdjustment')">

      <input [disabled]="false" type="text" [ngModel]="week.ChannelOps.COManualFcstAdjustment | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COManualFcstAdjustment = $event" [ngModelOptions]="{standalone: true}"
        (change)="COManualFcstAdjustmentUpdated(week)" id="COManualFcstAdjustment">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COPromoUnits')">

      <input [disabled]="false" type="text" (change)="COPromoUnitsUpdated(week)"
        [ngModel]="week.ChannelOps.COPromoUnits | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COPromoUnits = $event" [ngModelOptions]="{standalone: true}" id="COPromoUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcst | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COTotalSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="COTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COConstrainedSTFcst')">

      <input type="text" [ngModel]="week.ChannelOps.COConstrainedSTFcst | number : '1.2-2'"
        (ngModelChange)="week.ChannelOps.COConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="COConstrainedSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.ChannelOps.COComments"
        [ngModelOptions]="{standalone: true}" id="COComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoLY')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoLY | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoLY">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoDPTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoDPTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoDPTotalSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoConsensusSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoConsensusSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvartoAlgoTotalSTFcst')">

      <input type="text" [disabled]="true" [ngModel]="week.ChannelOps.COTotalSTFcstvartoAlgoTotalSTFcst | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvartoAlgoTotalSTFcst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Channel Ops ST')"> <label>&nbsp;</label></tr>

    <!-- ChannelOps -->

    <!-- CurrentSellThrough -->
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerSTFcst')">

      <input [disabled]="false" type="text" (change)="CustomerSTFcstUpdated(week)"
        [ngModel]="week.CurrentSellThrough.CustomerSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.CustomerSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSTFcst')">

      <input [disabled]="false" type="text" (change)="ConsensusSTFcstUpdated(week)"
        [ngModel]="week.CurrentSellThrough.ConsensusSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcst = $event" [ngModelOptions]="{standalone: true}"
        id="ConsensusSTFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvartoCustomerSTFcst')">

      <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ConsensusSTFcstvartoCustomerSTFcst = $event"
        [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvartoCustomerSTFcst">

    </tr>
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcst')">

          <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ConstrainedSTFcst | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcst = $event" [ngModelOptions]="{standalone: true}"
            id="ConstrainedSTFcst">

        </tr> -->
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvartoConsensusSTFcst')">

          <input type="text" [disabled]="true"
            [ngModel]="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst | number : '1.2-2'"
            (ngModelChange)="week.CurrentSellThrough.ConstrainedSTFcstvartoConsensusSTFcst = $event"
            [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvartoConsensusSTFcst">

        </tr> -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ProjectedLostSales')">

      <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ProjectedLostSales | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ProjectedLostSales = $event" [ngModelOptions]="{standalone: true}"
        id="ProjectedLostSales">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualST')">

      <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.ActualST | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ActualST = $event" [ngModelOptions]="{standalone: true}" id="ActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('WeeklyTrend')">

      <input type="text" [disabled]="false" [ngModel]="week.CurrentSellThrough.WeeklyTrend | number : '1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.WeeklyTrend = $event" [ngModelOptions]="{standalone: true}"
        id="WeeklyTrend">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('PromoIndicator')">

      <input type="text" [disabled]="false" [(ngModel)]="week.CurrentSellThrough.PromoIndicator"
        [ngModelOptions]="{standalone: true}" id="PromoIndicator">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CPFRComments')">

      <input [disabled]="false" type="text" [(ngModel)]="week.CurrentSellThrough.CPFRComments"
        [ngModelOptions]="{standalone: true}" id="CPFRComments">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerInStockEstimate')">

      <input [disabled]="false" type="text" [ngModel]="week.CurrentSellThrough.CustomerInStockEstimate | percent"
        [ngModelOptions]="{standalone: true}" id="CustomerInStockEstimate">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstUnits')">

      <input type="text" [disabled]="true"
        [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstUnits | number:'1.2-2'"
        (ngModelChange)="week.CurrentSellThrough.ActualVartoConsensusFcstUnits = $event"
        [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstUnits">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ActualVartoConsensusFcstPst')">

      <input type="text" [disabled]="true" [ngModel]="week.CurrentSellThrough.ActualVartoConsensusFcstPst | percent"
        [ngModelOptions]="{standalone: true}" id="ActualVartoConsensusFcstPst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative ST')"> <label>&nbsp;</label></tr>

    <!-- CurrentSellThrough -->

    <!-- LastYearSales -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualST')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYActualST | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYActualST = $event" [ngModelOptions]="{standalone: true}" id="LYActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYPromoIndicator')">

      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYPromoIndicator"
        [ngModelOptions]="{standalone: true}" id="LYPromoIndicator">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCOComments')">

      <input type="text" [disabled]="false" [(ngModel)]="week.LastYearSales.LYCOComments"
        [ngModelOptions]="{standalone: true}" id="LYCOComments">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYCustomerInStockEstimate')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYCustomerInStockEstimate | percent"
        [ngModelOptions]="{standalone: true}" id="LYCustomerInStockEstimate">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYConsensusFcst')">

      <input type="text" [disabled]="false" [ngModel]="week.LastYearSales.LYConsensusFcst | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYConsensusFcst = $event" [ngModelOptions]="{standalone: true}"
        id="LYConsensusFcst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarPst')">

      <input type="text" [disabled]="true" [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarPst | percent"
        [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarPst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('LYActualtoConsensusFcstVarUnits')">

      <input type="text" [disabled]="true"
        [ngModel]="week.LastYearSales.LYActualtoConsensusFcstVarUnits | number:'1.2-2'"
        (ngModelChange)="week.LastYearSales.LYActualtoConsensusFcstVarUnits = $event"
        [ngModelOptions]="{standalone: true}" id="LYActualtoConsensusFcstVarUnits">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Last Year ST')"> <label>&nbsp;</label></tr>

    <!-- LastYearSales -->

    <!-- StAccuracy -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.CustomerSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="CustomerSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.AlgoTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="AlgoTotalSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('COTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.COTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="COTotalSTFcstvsActualST">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('DPTotalSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.DPTotalSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="DPTotalSTFcstvsActualST">

    </tr>
    <!-- <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConstrainedSTFcstvsActualST')">

          <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConstrainedSTFcstvsActualST | percent"
            [ngModelOptions]="{standalone: true}" id="ConstrainedSTFcstvsActualST">

        </tr> -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ConsensusSTFcstvsActualST')">

      <input type="text" [disabled]="true" [ngModel]="week.StAccuracy.ConsensusSTFcstvsActualST | percent"
        [ngModelOptions]="{standalone: true}" id="ConsensusSTFcstvsActualST">

    </tr>
    <tr *ngIf="checkIfToShowColumn('ST Accuracy')"> <label>&nbsp;</label></tr>

    <!-- StAccuracy -->

    <!-- SellIn -->
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerRequestedSellInReceiveWk')">

      <input [disabled]="false" type="text" (change)="CustomerRequestedSellInReceiveWkUpdated(week)"
        [ngModel]="week.SellIn.CustomerRequestedSellInReceiveWk | number"
        (ngModelChange)="week.SellIn.CustomerRequestedSellInReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerRequestedSellInReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('FinalSellInCommitReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.FinalSellInCommitReceiveWk | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="FinalSellInCommitReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitvartoCustomerRequest')">

      <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitvartoCustomerRequest | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitvartoCustomerRequest = $event"
        [ngModelOptions]="{standalone: true}" id="FinalSellInCommitvartoCustomerRequest">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('AlgoSellInFcstReceiveWk')">

      <input type="text" [disabled]="false" [ngModel]="week.SellIn.AlgoSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.AlgoSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="AlgoSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('DPSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.DPSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.DPSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="DPSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('COSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.COSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.COSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="COSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('ConsensusSellInFcstReceiveWk')">

      <input [disabled]="false" type="text" [ngModel]="week.SellIn.ConsensusSellInFcstReceiveWk | number"
        (ngModelChange)="week.SellIn.ConsensusSellInFcstReceiveWk = $event" [ngModelOptions]="{standalone: true}"
        id="ConsensusSellInFcstReceiveWk">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FinalSellInCommitsShipWk')">

      <input type="text" [disabled]="true" [ngModel]="week.SellIn.FinalSellInCommitsShipWk | number"
        (ngModelChange)="week.SellIn.FinalSellInCommitsShipWk = $event" [ngModelOptions]="{standalone: true}"
        id="FinalSellInCommitsShipWk">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Collaborative SI')"> <label>&nbsp;</label></tr>

    <!-- SellIn -->


    <!-- CustomerInventory -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerIOHProjection')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerIOHProjection | number"
        (ngModelChange)="week.CustomerInventory.CustomerIOHProjection = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerIOHProjection">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('IOHProjection')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.IOHProjection | number"
        (ngModelChange)="week.CustomerInventory.IOHProjection = $event" [ngModelOptions]="{standalone: true}"
        id="IOHProjection">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerFWOSCalc')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.CustomerFWOSCalc | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.CustomerFWOSCalc = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerFWOSCalc">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBFWOSCalc')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FBFWOSCalc | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FBFWOSCalc = $event" [ngModelOptions]="{standalone: true}"
        id="FBFWOSCalc">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoCustomerTarget')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoCustomerTarget | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FWOSvartoCustomerTarget = $event" [ngModelOptions]="{standalone: true}"
        id="FWOSvartoCustomerTarget">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FWOSvartoFBTarget')">

      <input type="text" [disabled]="true" [ngModel]="week.CustomerInventory.FWOSvartoFBTarget | number:'1.2-2'"
        (ngModelChange)="week.CustomerInventory.FWOSvartoFBTarget = $event" [ngModelOptions]="{standalone: true}"
        id="FWOSvartoFBTarget">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerStoreOH')">

      <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerStoreOH | number"
        (ngModelChange)="week.CustomerInventory.CustomerStoreOH = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerStoreOH">

    </tr>
    <tr [ngStyle]="{background: '#fff'}" *ngIf="checkIfInList('CustomerDCOH')">

      <input [disabled]="false" type="text" [ngModel]="week.CustomerInventory.CustomerDCOH | number"
        (ngModelChange)="week.CustomerInventory.CustomerDCOH = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerDCOH">

    </tr>
    <tr *ngIf="checkIfToShowColumn('Customer Inventory')"> <label>&nbsp;</label></tr>

    <!-- CustomerInventory -->

    <!-- FBInventory -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBTotalOnHand')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBTotalOnHand | number"
        (ngModelChange)="week.FBInventory.FBTotalOnHand = $event" [ngModelOptions]="{standalone: true}"
        id="FBTotalOnHand">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('FBAvailableAllocation')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.FBAvailableAllocation | number"
        (ngModelChange)="week.FBInventory.FBAvailableAllocation = $event" [ngModelOptions]="{standalone: true}"
        id="FBAvailableAllocation">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnOrder')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.OnOrder | number"
        (ngModelChange)="week.FBInventory.OnOrder = $event" [ngModelOptions]="{standalone: true}" id="OnOrder">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InTransit')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InTransit | number"
        (ngModelChange)="week.FBInventory.InTransit = $event" [ngModelOptions]="{standalone: true}" id="InTransit">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('InventoryReceived')">

      <input type="text" [disabled]="false" [ngModel]="week.FBInventory.InventoryReceived | number"
        (ngModelChange)="week.FBInventory.InventoryReceived = $event" [ngModelOptions]="{standalone: true}"
        id="InventoryReceived">

    </tr>
    <tr *ngIf="checkIfToShowColumn('FB Inventory')"> <label>&nbsp;</label></tr>

    <!-- FBInventory -->

    <!-- KPI -->
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeDeliveryPerformance')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeDeliveryPerformance | percent"
        (ngModelChange)="week.KPI.OnTimeDeliveryPerformance = $event" [ngModelOptions]="{standalone: true}"
        id="OnTimeDeliveryPerformance">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('OnTimeInFull')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.OnTimeInFull  | percent"
        (ngModelChange)="week.KPI.OnTimeInFull = $event" [ngModelOptions]="{standalone: true}" id="OnTimeInFull">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('CustomerChargebacks')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.CustomerChargebacks | currency"
        (ngModelChange)="week.KPI.CustomerChargebacks = $event" [ngModelOptions]="{standalone: true}"
        id="CustomerChargebacks">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('StandardDeliveryPst')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.StandardDeliveryPst  | percent"
        (ngModelChange)="week.KPI.StandardDeliveryPst = $event" [ngModelOptions]="{standalone: true}"
        id="StandardDeliveryPst">

    </tr>
    <tr [ngStyle]="{background: '#eee'}" *ngIf="checkIfInList('ExpeditedDeliveryPst')">

      <input type="text" [disabled]="true" [ngModel]="week.KPI.ExpeditedDeliveryPst  | percent"
        (ngModelChange)="week.KPI.ExpeditedDeliveryPst = $event" [ngModelOptions]="{standalone: true}"
        id="ExpeditedDeliveryPst">

    </tr>
    <tr *ngIf="checkIfToShowColumn('KPIs')"> <label>&nbsp;</label></tr>

    <!-- KPI -->
  </td>
</ng-template>
<!-- Year Cummulated Column End -->
