import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-name-dialog',
  templateUrl: './update-name-dialog.component.html',
  styleUrls: ['./update-name-dialog.component.scss']
})
export class UpdateNameDialogComponent implements OnInit {

  name = '';
  constructor(public dialogRef: MatDialogRef<UpdateNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.name = JSON.parse(JSON.stringify(data.name));
    }

  ngOnInit(): void {
  }

  onSaveClick() {
    this.dialogRef.close(this.name);
  }
  
  onNoClick() {
    this.dialogRef.close(false);
  }

}
