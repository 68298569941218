import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-title-form',
  templateUrl: './title-form.component.html',
  styleUrls: ['./title-form.component.css']
})
export class TitleFormComponent implements OnInit {
  public titleForm: FormGroup;
  public submitted = false;
  constructor(public fb: FormBuilder) { }

  ngOnInit() {
    this.initializeForm();
  }
  public initializeForm = () => {
    this.titleForm = this.fb.group({
      titleName: [],
      releaseSource: [],
      studio: [],
      genre: [],
      vendor: [],
      rating: [],
      workingGroup: [],
      available: [],
      releaseDate: [],
      streetDate: [],
      preAwarenessDate: [],
      announceDate: [],
      comment: []
    });
  }
  public onSubmit = () => {

  }
  public Cancel_Click = () => {

  }
}
