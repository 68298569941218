import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';

@Injectable()
export class PromoService {

  // Subjects
  public promoGridActionCellSubject: Subject<any> = new Subject();
  public promoSetupActionSubject: Subject<any> = new Subject();


  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */
  setPromoGridActionCellSubject = (value: any) => { this.promoGridActionCellSubject.next(value); };
  setPromoSetupActionSubject = (value: any) => { this.promoSetupActionSubject.next(value); };

  // Observables
  getPromoGridActionCellSubject = () => this.promoGridActionCellSubject.asObservable();
  getPromoSetupActionSubject = () => this.promoSetupActionSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  GetTrendManagerList() {
    return this.http.get(`${environment.PromoManager}/gettrendmanagerlist`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetLikePromoList() {
    return this.http.get(`${environment.PromoManager}/getlikepromolist`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetPromotionPlannerList(body = {}) {
    return this.http.post(`${environment.PromoManager}/promotionplannergetlist`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  GetPromotionHistoryList(body) {
    return this.http.post(`${environment.PromoManager}/getpromotionHistoryList`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  GetPromotionplannerById(promotionPlannerId) {
    return this.http.get(`${environment.PromoManager}/getpromotionplannerbyid?promotionPlannerId=${promotionPlannerId}`)
      .pipe(map(response => {
        return response;
      }));
  }

  AddPromoPlanner(body) {
    return this.http.post(`${environment.PromoManager}/addpromoplanner`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  UpdatePromo(body) {
    return this.http.put(`${environment.PromoManager}/update`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  CopyPromo(body) {
    return this.http.post(`${environment.PromoManager}/addpromocopy`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  DeletePromo(body) {
    return this.http.post(`${environment.PromoManager}/delete`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  ChangePromoStatus(body) {
    return this.http.put(`${environment.PromoManager}/activate`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  promotionKeyValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.GetPromotionPlannerKeyCheck({
        promoKey: control.value,
        promotionPlannerId: _.get(control, '_parent.value.promotionPlannerId', null)
      }).pipe(
        map(res => {
          // if res is true, promo name exists, return true
          return res ? { promoNameExists: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }

  GetPromotionPlannerKeyCheck(body) {
    return this.http.post(`${environment.PromoManager}/getpromotionplannerkeycheck`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  ExportPromotionPlannerTemplate() {
    return this.http.get(`${environment.PromoManager}/exportPromotionTemplate`)
      .pipe(map(data => {
        return data;
      }));
  }

  ImportPromotionPlannerTemplate(body) {
    return this.http.post(`${environment.PromoManager}/importpromotion`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  GetPromotionPlannerItemList(promotionPlannerId) {
    return this.http.get(`${environment.PromoManager}/getPromotionPlannerItemList?promotionPlannerId=${promotionPlannerId}`)
      .pipe(map(response => {
        return response;
      }));
  }

  InsertUpdatePromotionPlannerItems(body) {
    return this.http.post(`${environment.PromoManager}/insertUpdatePromotionPlannerItems`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  DeletePromoItems(body) {
    return this.http.post(`${environment.PromoManager}/deletePromotionPlannerItems`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  DePromotionPlannerGetList(body = {}) {
    const query = Object.keys(body).map(key => {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.PromoManager}/DePromotionPlannerGetList?${query}`)
      .pipe(map(response => {
        return response;
      }));
  }


  InsertUpdateDePromotionPlanner(body) {
    return this.http.post(`${environment.PromoManager}/InsertUpdateDePromotionPlanner`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  ExportPromotionItemPlannerTemplate() {
    return this.http.get(`${environment.PromoManager}/exportpromotionitemtemplate`)
      .pipe(map(data => {
        return data;
      }));
  }

  ImportPromotionItemPlannerTemplate(body) {
    return this.http.post(`${environment.PromoManager}/importpromotionItem`, body)
      .pipe(map(data => {
        return data;
      }));
  }


  TriggerPromoManagerPipeline(body = {}) {
    return this.http.get(`${environment.PromoManager}/usppopulatepromotiondata`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  AzurePipelineTriggerGetList(body = {}) {
    return this.http.get(`${environment.PromoManager}/AzurePipelineTriggerGetList`)
      .pipe(map(azpipeline => {
        return azpipeline;
      }));
  }

}
