
<div mat-dialog-content>
  <p> {{data.label}} </p>
  <mat-form-field appearance="outline" *ngIf="data.isInputRequired">
    <input
      matInput
      placeholder="{{data.inputLabel}}"
      [(ngModel)]="name"
      [ngModelOptions]="{ standalone: true }"
    />
  </mat-form-field>
</div>
<div class="action-panel text-right btn-wrapper" *ngIf="data.isOnLoad">
  <!-- <button mat-raised-button class="btn btn-secondary m-left">Discard Current</button> -->
  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="close()">Cancel</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="openSavedVersions()"> Open
    Saved</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="onNoClick()">Build New</button>
</div>
<div class="action-panel text-right btn-wrapper" *ngIf="data.isSaveAndCommit">
  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onNoClick()">Cancel</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="onSave()"> Save</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="onCommit()">Commit</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="onSaveCommit()">Save & Commit</button>
</div>
<div class="action-panel text-right btn-wrapper" *ngIf="data.isCommit">
  <button mat-raised-button class="btn btn-secondary m-left">Discard Current</button>
  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onNoClick()">Cancel</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="onCommit()">Commit</button>
</div>
<div class="action-panel text-right btn-wrapper" *ngIf="data.isDeletePlanner">

  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onDelete()">Delete</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="onNoClick()">Cancel</button>
</div>
<div class="action-panel text-right btn-wrapper" *ngIf="data.isRestoreSession">
  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="close()">Cancel</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="openSavedVersions()">Open
    Saved</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="onNoClick()">Build New</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="restoreSession()">Restore
    Session</button>

</div>
<div class="action-panel text-right btn-wrapper" *ngIf="data.confirmation">
  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="close()">Ok</button>
</div>
<div class="action-panel text-right btn-wrapper" *ngIf="data.goBack">
  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="close()">Go Back</button>
</div>
<div class="action-panel text-right btn-wrapper" *ngIf="data.changeNameConfirmation">
  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onNoClick()">Cancel</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="onNameSave()"> Save</button>
</div>
<div class="action-panel text-right btn-wrapper" *ngIf="data.adjustData">
  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="close()">Discard Current</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="close()"> Cancel
    Saved</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="onSave()">Save</button>
</div>
<div class="action-panel text-right btn-wrapper" *ngIf="data.refreshMeasure">
  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onNoClick()">Cancel</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="onSave()"> Proceed</button>
</div>
<div class="action-panel text-right btn-wrapper" *ngIf="data.importError">

  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="onSave()"> Retry</button>
</div>

