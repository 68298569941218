import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {
  @Input() chartDatasets = [];
  @Input() xAxis = [];
  @Input() yAxis = [];
  view: any[] = [700, 300];
  colorScheme = {
    domain: ['#E44D25', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  constructor() { }

  ngOnInit() {
  }
  public onSelectChart = () => {
  }
  public onActivate = () => {
  }
  public onDeactivate = () => {
  }
}
