import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";

@Component({
  selector: 'depromo-week-cell-editor',
  template: `<div class="depromo-grid-week-icon-btn">
                    <button mat-icon-button (click)="addWeek()">
                        <mat-icon>add_circle</mat-icon>
                    </button>
                <div class="value-display">{{params.value}}</div>
                    <button mat-icon-button (click)="removeWeek()">
                        <mat-icon>remove_circle</mat-icon>
                    </button>
                </div>`
})
export class DepromoWeekCellEditor implements ICellRendererAngularComp, OnDestroy {
  params: any;
  value: any;

  constructor() {
  }

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
  }

  refresh(params?: any): boolean {
    return true;
  }

  addWeek() {
    this.params.value = (this.params.value ? Number(this.params.value) : 0) + 1;
    if (this.value != this.params.value) {
      this.params.data.isUpdated = true;
    }
  }

  removeWeek() {
    this.params.value = (this.params.value ? Number(this.params.value) : 0) == 0 ? 0 : (this.params.value ? Number(this.params.value) : 0) - 1;
    if (this.value != this.params.value) {
      this.params.data.isUpdated = true;
    }
  }

  ngOnDestroy() {
    // no need to remove the button click handler 
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}