import { Component, OnInit, ViewChild, TemplateRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PromotionFormV2Component } from '../promotion-form-v2/promotion-form-v2.component';
import { LocalstorageService } from 'src/services/localstorage.service';
import { ConfigurationService } from 'src/services/configuration.service';
import { UsersService } from 'src/services/User-services/user-services';
@Component({
  selector: 'app-sku-list-link-v2',
  templateUrl: './sku-list-link-v2.component.html',
  styleUrls: ['./sku-list-link-v2.component.css']
})
export class SkuListLinkV2Component implements OnInit, OnChanges {
  @ViewChild('dialog') dialogTemplate: TemplateRef<any>;
  @ViewChild('Adddialog') dialogAddTemplate: TemplateRef<any>;
  @ViewChild('ExcelImport') excelImport: TemplateRef<any>;
  @Input() skuPromotionList: any;
  public classList = [{ classCode: 1, classCodeDescription: 'TV' }, { classCode: 2, classCodeDescription: 'Theatrical' }];
  public subClassList = [
    { subClassCode: 1, subClassCodeDescription: 'DVD' },
    { subClassCode: 2, subClassCodeDescription: 'BD' },
    { subClassCode: 3, subClassCodeDescription: '3D' },
    { subClassCode: 4, subClassCodeDescription: 'UHD' }
  ];
  public selectAll = true;
  afuConfig = {
    uploadAPI: {
      url: 'https://example-file-upload-api'
    },
    multiple: false,
    formatsAllowed: '.xls,.xlsx',
    replaceTexts: {
      selectFileBtn: 'Select Files',
      resetBtn: 'Reset',
      uploadBtn: 'Export Data',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded !',
      afterUploadMsg_error: 'Upload Failed !'
    }
  };
  public importList = ['Export Vendor Template', 'Export BBY Template', 'Import']
  public columnDefs = [
    // {
    //   headerName: '',
    //   field: 'selected',
    //   checkboxSelection: true
    // },
    {
      headerName: 'Customer',
      show: true,
      field: 'customers'
    },
    {
      headerName: 'Country',
      show: true,
      field: 'country'
    },
    {
      headerName: 'Product Type',
      show: true,
      field: 'productType'
    },
    {
      headerName: 'Item #',
      show: true,
      field: 'itemNumber'
    },
    {
      headerName: 'Item Description',
      show: true,
      field: 'itemDescription'
    },
    {
      headerName: 'UPC',
      show: true,
      field: 'upc'
    },
    {
      headerName: 'Unit Cost',
      show: true,
      field: 'unitCost'
    },
    {
      headerName: 'Regular Retail',
      show: true,
      field: 'regularRetail'
    },
    {
      headerName: 'Unit Margin Credit',
      show: true,
      field: 'unitMarginCredit'
    },
    {
      headerName: 'Algo Promo Total Forecast',
      show: true,
      field: 'algoPromoTotalForecast'
    },
    {
      headerName: 'cOPromoTotalForecast',
      show: true,
      field: 'CO Promo Total Forecast'
    }
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public skuPromotion =
    [
      {
        "customers":"Best Buy",
        "country":"US",
        "productType":"Oculus",
        "itemNumber":"FB332931",
        "itemDescription":"Quest 2 64GB",
        "upc":"1112233441",
        "unitCost":"$ 149.50",
        "regularRetail":"$ 299",
        "unitMarginCredit":"$ 30",
        "algoPromoTotalForecast":"1200",
        "cOPromoTotalForecast":"1500",
      },

      {
        "customers":"Best Buy",
        "country":"US",
        "productType":"Oculus",
        "itemNumber":"FB332932",
        "itemDescription":"Quest 2 256GB",
        "upc":"1112233442",
        "unitCost":"$ 199.50",
        "regularRetail":"$ 399",
        "unitMarginCredit":"$ 40",
        "algoPromoTotalForecast":"900",
        "cOPromoTotalForecast":"1000",
      },

      {
        "customers":"Best Buy",
        "country":"US",
        "productType":"Oculus Accessories",
        "itemNumber":"FB332933",
        "itemDescription":"Oculus Quest 2 Carrying Case",
        "upc":"1112233442",
        "unitCost":"$ 24.50",
        "regularRetail":"$ 49",
        "unitMarginCredit":"$ 5",
        "algoPromoTotalForecast":"300",
        "cOPromoTotalForecast":"250",
      },
    {
        "customers":"Best Buy",
        "country":"US",
        "productType":"Oculus Accessories",
        "itemNumber":"FB332934",
        "itemDescription":"Oculus Quest 2 Elite Strap",
        "upc":"1112233442",
        "unitCost":"$ 24.50",
        "regularRetail":"$ 49",
        "unitMarginCredit":"$ 5",
        "algoPromoTotalForecast":"325",
        "cOPromoTotalForecast":"300",
      }

  ];

  public modules = AllCommunityModules;
  public user: any;
  public skuList = [
    {
      "customers":"Best Buy",
      "country":"US",
      "productType":"Oculus",
      "itemNumber":"FB332931",
      "itemDescription":"Quest 2 64GB",
      "upc":"1112233441",
      "unitCost":"$ 149.50",
      "regularRetail":"$ 299",
      "unitMarginCredit":"$ 30",
      "algoPromoTotalForecast":"1200",
      "cOPromoTotalForecast":"1500",
    },

    {
      "customers":"Best Buy",
      "country":"US",
      "productType":"Oculus",
      "itemNumber":"FB332932",
      "itemDescription":"Quest 2 256GB",
      "upc":"1112233442",
      "unitCost":"$ 199.50",
      "regularRetail":"$ 399",
      "unitMarginCredit":"$ 40",
      "algoPromoTotalForecast":"900",
      "cOPromoTotalForecast":"1000",
    },

    {
      "customers":"Best Buy",
      "country":"US",
      "productType":"Oculus Accessories",
      "itemNumber":"FB332933",
      "itemDescription":"Oculus Quest 2 Carrying Case",
      "upc":"1112233442",
      "unitCost":"$ 24.50",
      "regularRetail":"$ 49",
      "unitMarginCredit":"$ 5",
      "algoPromoTotalForecast":"300",
      "cOPromoTotalForecast":"250",
    },
  {
      "customers":"Best Buy",
      "country":"US",
      "productType":"Oculus Accessories",
      "itemNumber":"FB332934",
      "itemDescription":"Oculus Quest 2 Elite Strap",
      "upc":"1112233442",
      "unitCost":"$ 24.50",
      "regularRetail":"$ 49",
      "unitMarginCredit":"$ 5",
      "algoPromoTotalForecast":"325",
      "cOPromoTotalForecast":"300",
    }

];
  public selectAllSKU = false;
  files: File[] = [];

  constructor(
    public router: Router,
    public userService: UsersService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PromotionFormV2Component>,
    public configurationService: ConfigurationService,
    public storage: LocalstorageService) {
    this.gridOptions = {
      frameworkComponents: {
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    //this.getSKUList();
  }
  public getSKUList = () => {
    this.configurationService.SKUGetAll({}).subscribe(res => {
      this.skuList = res.map(sku => ({ ...sku, selected: false }));
    });
  }
  ngOnChanges(simpleChange: SimpleChanges) {
    if (simpleChange && simpleChange.skuPromotionList && simpleChange.skuPromotionList.currentValue) {
      this.skuPromotion = simpleChange.skuPromotionList.currentValue;
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    if (this.gridOptions.api) {
      this.skuPromotion = this.skuPromotion.length > 0 ? this.skuPromotion : this.storage.get('SKULIST');
      this.gridOptions.api.setRowData(this.skuPromotion);
    }
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  onCellClicked($event) {
  }
  openFullScreenMode() {
    const domGrid = document.getElementById('myGrid') as any;
    if (domGrid) {
      domGrid.webkitRequestFullscreen();
    }
  }
  openDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(this.dialogTemplate, { width: '500px', data: data || {} });
  }
  openAddDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(this.dialogAddTemplate, { width: '500px', data: data || {} });
  }
  openExcelImportDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(this.excelImport, { width: '500px', data: data || {} });
  }
  selectAllSelected(flag: boolean): void {
    if (flag) {
      this.columnDefs.forEach(column => column.show = true);
    } else {
      this.columnDefs.forEach(column => column.show = false);
    }
  }
  public selectionChanged = (selection) => {
    if (selection && selection.value === 'Import') {
      this.openExcelImportDialog({});
    }
  }
  public onSave = () => {
    const newColumnDefs = this.columnDefs.filter(column => column.show);
    this.gridOptions.api.setColumnDefs(newColumnDefs);
    this.dialogRef.close();
  }

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }
  exportToExcel = () => {
    this.gridApi.exportDataAsCsv();
  }
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
}
