export const generateGridColumns = () => {
    const gridColumnDefs = [
        {
            headerName: '',
            field: '',
            checkboxSelection: true,
            headerCheckboxSelection: false,
            resizable: false,
            width: 80,
            pinned: 'left',
        },
        {
            headerName: 'Time',
            field: 'time',
            width: 180,
            minWidth: 180,
            resizable: false,
            editable: false
        },
        {
            headerName: 'Plan',
            field: 'plan',
            width: 180,
            minWidth: 180,
            resizable: false,
            editable: false,
            cellRenderer: function (params) {
                return '<a><u>' + params.value + '</u></a>'
            }
        },
        {
            headerName: 'Channel',
            field: 'channel',
            width: 180,
            minWidth: 180,
            resizable: false,
            editable: false
        },
        {
            headerName: 'Brand',
            field: 'brand',
            width: 180,
            minWidth: 180,
            resizable: false,
            editable: false
        },
        {
            headerName: 'Product Group',
            field: 'group',
            width: 180,
            minWidth: 180,
            resizable: false,
            editable: false
        },
        {
            headerName: 'Created By',
            field: 'createdBy',
            width: 180,
            minWidth: 180,
            resizable: false,
            editable: false
        },
        {
            headerName: 'Status',
            field: 'status',
            width: 180,
            minWidth: 180,
            resizable: false,
            editable: false
        },
        {
            headerName: 'Approved By',
            field: 'approvedBy',
            width: 180,
            minWidth: 180,
            resizable: false,
            editable: false
        }
    ];
    return gridColumnDefs;
};
