import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { ToggleButtonRendererComponent } from 'src/common/toggle-button-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { InsightFormComponent } from './insight-form/insight-form.component';

@Component({
  selector: 'app-insight-listing',
  templateUrl: './insight-listing.component.html',
  styleUrls: ['./insight-listing.component.css']
})
export class InsightListingComponent implements OnInit {
  public columnDefs = [
    {
      headerName: 'Date',
      field: 'dateGenerated',
      cellRenderer: params => {
        return `${formatDate(params.value, 'dd/MM/yyyy', 'en-US')}`
      }
    },
    {
      headerName: 'Dimensions',
      field: 'dimensions',
      width: 420
    },
    {
      headerName: 'Value',
      field: 'value'
    },
    {
      headerName: 'Status',
      field: 'isPublish',
      cellRenderer: params => {
        return this.mapStatus(params)
      }
    },
    {
      headerName: '',
      field: 'isPublish',
      resizable: true,
      filter: false,
      pinned: 'right',
      cellRenderer: 'toggleButtonRenderer',
      cellRendererParams: {
        onChange: this.togglePublish.bind(this),
      },
      width: 150
    },
    {
      headerName: '',
      field: 'delete',
      resizable: true,
      filter: false,
      pinned: 'right',
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveGoalDialog.bind(this),
      },
      width: 80
    },
    {
      headerName: '',
      field: 'edit',
      resizable: true,
      suppressMenu: true,
      suppressSorting: true,
      pinned: 'right',
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        onClick: this.openDialog.bind(this),
      },
      width: 80
    }
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public insightList = [];
  public modules = AllCommunityModules;
  public user: any;
  public isEdit = false;
  public countryList = [];
  public retailerList = [];
  public categoryList = [];
  public divisionList = [];
  public metricList = [];
  @ViewChild('dialogRef') dialog: TemplateRef<any>;
  constructor(
    public matDialog: MatDialog,
    public matDialogRef: MatDialogRef<InsightListingComponent>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public toastr: NgxToasterService,
    public spinner: NgxSpinnerService,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public pipe: CurrencyPipe
  ) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent,
        toggleButtonRenderer: ToggleButtonRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    this.getDefaultDropdowns();
  }
  togglePublish(event) {
    const model = {
      ...event.rowData,
      isPublish: event.event.checked,
    }
    this.configurationService.ApplicationInsightsPublish(model).subscribe(res => {
      if (res) {
        this.toastr.success('Publish', 'App Insight published successfully');
      }
    }, error => {
      this.toastr.error('Publish', 'App Insight not published successfully');
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: 'Application Insight' }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeNotification(row.rowData);
      }
    });
  }
  public removeNotification(selectedInsight: any) {
    if (selectedInsight) {
      const model = {
        ...selectedInsight,
        active: false
      };
      this.spinner.show();
      this.configurationService.ApplicationInsightsActivate(model).subscribe(res => {
        if (res) {
          this.spinner.hide();
          this.toastr.success('Application Insight', 'Insight removed successfully');
          this.insightList = this.insightList.filter(row => row.applicationInsightsId !== model.applicationInsightsId);
          this.gridOptions.api.setRowData(this.insightList);
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('Application Insight', error.error.text);
      });
    }
  }
  openDialog(data, templateRef?: TemplateRef<any>): void {
    this.isEdit = data.rowData ? true : false;
    const dialogRef = this.matDialog.open(InsightFormComponent, { width: '80vw', data: data.rowData });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.getApplicationInsightList();
      }
    })
  }
  public getDefaultDropdowns = async () => {
    this.countryList = await this.configurationService.LookupGetByCode('AP_Country').toPromise();
    this.retailerList = await this.configurationService.LookupGetByCode('AP_Retailer').toPromise();
    this.categoryList = await this.configurationService.LookupGetByCode('AP_Product_Category').toPromise();
    this.divisionList = await this.configurationService.LookupGetByCode('AP_Product_Division').toPromise();
    this.metricList = await this.configurationService.LookupGetByCode('AP_Metric').toPromise();
    this.getApplicationInsightList();
  }
  public getApplicationInsightList = () => {
    this.configurationService.ApplicationInsightsGetAll({}).subscribe(res => {
      this.insightList = res;
      this.insightList = this.insightList.map(insight => ({
        ...insight,
        dimensions: this.mapDimensions(insight)
      }))
    });
  }
  public mapDimensions = (insight) => {
    const country = this.countryList.find(country => country.commonCodeValueId === insight.appInsightsCountryId) ? this.countryList.find(country => country.commonCodeValueId === insight.appInsightsCountryId).value : null;
    const retailer = this.retailerList.find(retailer => retailer.commonCodeValueId === insight.appInsightsRetailerId) ? this.retailerList.find(retailer => retailer.commonCodeValueId === insight.appInsightsRetailerId).value : null;
    const category = this.categoryList.find(category => category.commonCodeValueId === insight.appInsightsCategoryId) ? this.categoryList.find(category => category.commonCodeValueId === insight.appInsightsCategoryId).value : null;
    const division = this.divisionList.find(division => division.commonCodeValueId === insight.appInsightsDivisionId) ? this.divisionList.find(division => division.commonCodeValueId === insight.appInsightsDivisionId).value : null;
    let string = [country, retailer, category, division].toString();
    return string.replace(/[,\s]{2,}/, "");
  }
  public mapStatus = (params) => {
    if (params && params.node.data) {
      if (params.node.data.isExpired) {
        return `Expired`;
      } else if (params.node.data.isPublish) {
        return `Active`;
      } else {
        return `Unpublished`
      }
    }
  }
}
