<div class="action-panel">
  <button mat-raised-button class="btn btn-primary" (click)="isView = !isView"><p>{{isView ? 'Edit' : 'View'}}</p> <i class="icon-rules"></i></button>
</div>
<ng-container *ngIf="isView;else edit">
  <app-calender-setting></app-calender-setting>
</ng-container>
<ng-template #edit>
  <div class="container view-specific">
    <div class="action-panel">
      <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="openCalenderActivity()">Calender
        Activity <i class="icon-calendar"></i></button>
      <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="openEventType()">Add
        Season <i class="icon-add"></i></button>
    </div>
    <section class="white-section label-area  label-area">
      <div id="app-create-customer" class="create-customer-wrap">
        <div *ngFor="let group of eventTypeList">
          <div class="doc-group-listing">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{group.description}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="doc-listing-inner">
                <!-- <h2>{{group.description}}</h2> -->
                <div class="doc-listing-content">
                  <app-calender-view [id]="group?.eventTypesId" (updateGrid)="addToList($event)"
                    [eventTypeId]="group.eventTypesId" [selectedCalender]="selectedCalender"
                    [eventTypeList]="eventTypeList" [categoryList]="categoryList"
                    [calendarActivityList]="calendarActivityList">
                  </app-calender-view>
                  <div class="all-show-grid">
                    <ag-grid-angular class="ag-theme-material"  groupHeaders
                      suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
                      [gridOptions]="gridOptions" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20' [headerHeight]="headerHeight" [getRowHeight]="getRowHeight" [columnDefs]="columnDefs" (cellClicked)="cellClicked($event)"
                      [rowData]="getCalenderData(group)" (gridReady)="onGridReady($event)" [modules]="modules" #grid>
                    </ag-grid-angular>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
    </section>
  </div>
</ng-template>
