import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GridApi } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { InsightListingV2Component } from 'src/modules/layout/features/app-insight-v2/insight-listing-v2/insight-listing-v2.component';
import { InsightPOPUPComponent } from 'src/modules/layout/features/app-insight-v2/insight-popup/insight-popup.component';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { ToggleButtonRendererComponent } from 'src/common/toggle-button-renderer';

@Component({
  selector: 'app-insight-customer-list',
  templateUrl: './insight-customer-list.component.html',
  styleUrls: ['./insight-customer-list.component.scss'],
})
export class InsightCustomerListComponent implements OnInit {
  public columnDefs = [
    {
      headerName: 'Image',
      field: 'documentPath',
      minWidth: 50,
      cellRenderer: (params) => {
        return `<img style="width: 40px;
        height: 40px; border-radius: 50%;" src=${
          params.data.documentPath
            ? params.data.documentPath
            : 'assets/images/thumbnails.png'
        }>`;
      },
      resizable: false,
      sortable: false,
      filter: false,
      suppressMenu: true,
    },
    {
      headerName: 'Date',
      field: 'dateGenerated',
      cellRenderer: (params) => {
        return params.value
          ? `${formatDate(params.value, 'dd/MM/yyyy', 'en-US')}`
          : '';
      },
    },
    {
      headerName: 'Insight',
      field: 'insights',
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openDialog.bind(this),
      },
    },
    {
      headerName: 'Retailer',
      field: 'retailerName',
    },
    {
      headerName: 'Country',
      field: 'countryName',
    },
    {
      headerName: 'Products',
      field: 'productName',
    },
    {
      headerName: 'Value',
      field: 'value',
      cellRenderer: (params) => {
        return params && params.value
          ? this.pipe.transform(params.value, 'USD')
          : '';
      },
    },
    {
      headerName: 'Published',
      field: 'isPublish',
      pinned: 'right',
      cellRenderer: 'toggleButtonRenderer',
      cellRendererParams: {
        onChange: this.togglePublish.bind(this),
      },
      width: 150,
      resizable: false,
      sortable: false,
      filter: false,
      suppressMenu: true,
    },
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: GridApi;
  public insightList = [];
  public insightListGallery = [];
  public modules = AllCommunityModules;
  public user: any;
  headerHeight = 45;
  public isEdit = false;
  public listView = true;
  public icon = 'collections';
  public countryList = [];
  public retailerList = [];
  public categoryList = [];
  public divisionList = [];
  public metricList = [];
  public productList = [];
  public insightTempList = [];
  public statusList = [
    { value: true, statusName: 'Published' },
    { value: false, statusName: 'Not Published' },
  ];
  public activeList = [
    { value: true, statusName: 'Active' },
    { value: false, statusName: 'Expired' },
  ];
  public dateList = [];
  public retailer;
  public country;
  public product;
  public status;
  public date;
  public active;
  public defaultColDef;
  public selectedStartDate;
  public selectedEndDate;
  public selectedSorting = 'value';
  @ViewChild('dialogRef') dialog: TemplateRef<any>;
  constructor(
    public matDialog: MatDialog,
    public matDialogRef: MatDialogRef<InsightListingV2Component>,
    public dialogPopUpRef: MatDialogRef<InsightPOPUPComponent>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public toastr: NgxToasterService,
    public spinner: NgxSpinnerService,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public pipe: CurrencyPipe
  ) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent,
        toggleButtonRenderer: ToggleButtonRendererComponent,
        nameRenderer: NameRendererComponent,
      },
      pagination: true,
      paginationPageSize: 15,
    };
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true,
    };
  }
  ngOnInit() {
    this.getDefaultDropdowns();
  }
  togglePublish(event) {
    const model = {
      ...event.rowData,
      isPublish: event.event.checked,
    };
    this.configurationService.ApplicationInsightsPublish(model).subscribe(
      (res) => {
        this.getApplicationInsightList();
        this.toastr.success(
          'Publish',
          'App Insight published status updated successfully'
        );
      },
      (error) => {
        this.toastr.error('Publish', 'App Insight not published successfully');
      }
    );
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }
  public sortList = () => {
    if (this.selectedSorting === 'value') {
      this.insightList = this.insightList.sort((a, b) => a.value - b.value);
    } else if (this.selectedSorting === 'name') {
      this.insightList = this.insightList.sort(
        (a, b) => a.insights - b.insights
      );
    }
  };
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  openDialog(data, templateRef?: TemplateRef<any>): void {
    this.openInsightViewRenderer(data);
  }
  openDialogPopUp(data): void {
    this.openInsightViewRenderer(data);
  }
  openInsightViewRenderer(data: any): void {
    const selector = {
      menuId: '1069',
      name: 'Insight Details',
      selector: 'app-insight-customer-detail-view',
      displayName: 'Insight Details',
      module: 'AppInsight',
      data,
    };
    this.configurationService.menuRendererSubject.next(selector);
  }
  switchView() {
    if (this.listView) {
      this.listView = false;
      this.icon = 'article';
    } else {
      this.listView = true;
      this.icon = 'collections';
    }
  }
  public getDefaultDropdowns = async () => {
    let url = `offSet=${0}&pageSize=${99999}&active=true`;
    this.configurationService.ItemManagerGetAll(url).subscribe((res) => {
      this.productList = res.data;
    });
    this.GetRetailerList();
    this.GetCountryList();
    this.getApplicationInsightList();
  };
  public GetRetailerList = () => {
    this.configurationService
      .AccountSettingMappingChannelGetList({})
      .subscribe((res) => {
        this.retailerList = res;
      });
  };
  public GetCountryList = () => {
    this.configurationService.CountryGetList({}).subscribe((res) => {
      this.countryList = res;
    });
  };
  public getApplicationInsightList = () => {
    this.configurationService.ApplicationInsightsGetAll({}).subscribe((res) => {
      this.insightList = res.filter((el) => el.isPublish);
      this.insightList = this.insightList.map((insight) => ({
        ...insight,
        dimensions: this.mapDimensions(insight),
      }));
      this.insightTempList = JSON.parse(JSON.stringify(this.insightList));
    });
  };
  public mapDimensions = (insight) => {
    const country = this.countryList.find(
      (country) => country.commonCodeValueId === insight.appInsightsCountryId
    )
      ? this.countryList.find(
          (country) =>
            country.commonCodeValueId === insight.appInsightsCountryId
        ).value
      : null;
    const retailer = this.retailerList.find(
      (retailer) => retailer.commonCodeValueId === insight.appInsightsRetailerId
    )
      ? this.retailerList.find(
          (retailer) =>
            retailer.commonCodeValueId === insight.appInsightsRetailerId
        ).value
      : null;
    const category = this.categoryList.find(
      (category) => category.commonCodeValueId === insight.appInsightsCategoryId
    )
      ? this.categoryList.find(
          (category) =>
            category.commonCodeValueId === insight.appInsightsCategoryId
        ).value
      : null;
    const division = this.divisionList.find(
      (division) => division.commonCodeValueId === insight.appInsightsDivisionId
    )
      ? this.divisionList.find(
          (division) =>
            division.commonCodeValueId === insight.appInsightsDivisionId
        ).value
      : null;
    let string = [country, retailer, category, division].toString();
    return string.replace(/[,\s]{2,}/, '');
  };
  public mapStatus = (params) => {
    if (params && params.node.data) {
      if (params.node.data.isExpired) {
        return `Expired`;
      } else if (params.node.data.isPublish) {
        return `Active`;
      } else {
        return `Unpublished`;
      }
    }
  };
  public exportExcel = () => {
    try {
      this.gridApi.exportDataAsExcel();
    } catch {
      this.gridApi.exportDataAsCsv();
    }
  };
  public filterList = () => {
    let list = JSON.parse(JSON.stringify(this.insightList));
    if (this.status !== undefined) {
      list = this.insightTempList.filter((row) => {
        return row.isPublish === this.status;
      });
    }
    if (this.active !== undefined) {
      list = list.filter((row) => {
        return row.active === this.active;
      });
    }
    if (this.retailer !== undefined) {
      list = list.filter((row) => {
        return row.retailerId === this.retailer;
      });
    }
    if (this.country !== undefined) {
      list = list.filter((row) => {
        return row.countryId === this.country;
      });
    }
    if (this.product !== undefined) {
      list = list.filter((row) => {
        return row.productId === this.product;
      });
    }
    if (this.selectedStartDate !== undefined && this.selectedEndDate) {
      list = list.filter((row) => {
        return (
          new Date(row.dateGenerated) >= this.selectedStartDate &&
          new Date(row.dateGenerated) <= this.selectedEndDate
        );
      });
    }
    this.gridApi.setRowData(list);
  };
  resetFilters = () => {
    this.status = undefined;
    this.active = undefined;
    this.retailer = undefined;
    this.country = undefined;
    this.product = undefined;
    this.selectedStartDate = undefined;
    this.selectedEndDate = undefined;
    this.gridApi.setRowData(this.insightList);
  };
}
