<!-- Main Content area -->
<div class="wrapper col-md-12">
  <mat-card-header>
    <mat-card-title>
      <!-- <mat-icon (click)="location.back()" class="cursor"> <i class="material-icons">
          arrow_back
        </i></mat-icon> -->
      <!-- <h3 class="form-heading">Customer</h3> -->
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="registerForm" class="form-fields">
      <div class="row">
        <div class="col-md-3 form-group">
          <mat-form-field class="example-full-width">
            <input (focus)="picker1.open()" and (click)="picker1.open()" formControlName="startDate" matInput
              [max]="registerForm.controls && registerForm.controls.endDate?.value" (dateChange)="filterByDate()"
              [matDatepicker]="picker1" placeholder="Select Start Date">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-error *ngIf="submitted && registerForm.controls['startDate'].hasError('required')">
              *Required Field
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3 form-group">
          <mat-form-field class="example-full-width">
            <input (focus)="picker2.open()" and (click)="picker2.open()" formControlName="endDate" matInput
              (dateChange)="filterByDate()" [min]="registerForm.controls && registerForm.controls.startDate?.value"
              [matDatepicker]="picker2" placeholder="Select End Date">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-error *ngIf="submitted && registerForm.controls['endDate'].hasError('required')">
              *Required Field
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3 form-group">
          <mat-form-field>
            <mat-select placeholder="Select Status" formControlName="selectedStatus" (selectionChange) = "filterByDate()">
              <mat-option *ngFor="let res of statusList; let i = index" [value]="res">
                {{res.suggestionStatusName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field>
            <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
      </div>
    </form>

  </mat-card-content>
  <mat-card-actions>
    <div class="action-btns text-right">
      <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="Cancel_Click()">Reset</button>
    </div>
  </mat-card-actions>
</div>
<div class="all-show-grid col-md-12">
  <ag-grid-angular class="ag-theme-material"   domLayout='autoHeight'
    paginationAutoPageSize='false' paginationPageSize='20' [headerHeight]="headerHeight" [getRowHeight]="getRowHeight"
    [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="suggestList" (cellClicked)="populateForm($event)"
    (gridReady)="onGridReady($event)" [modules]="modules" #grid></ag-grid-angular>
</div>
<ng-template #suggest let-data>
  <h1 mat-dialog-title>Suggest Admin</h1>
  <div mat-dialog-content>
    <form>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field class="example-full-width">
            <input (focus)="picker1.open()" [(ngModel)]="data.created_at" [ngModelOptions]="{standalone: true}" and
              (click)="picker1.open()" matInput [max]="registerForm.controls && registerForm.controls.endDate?.value"
              [matDatepicker]="picker1" disabled>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput [(ngModel)]="data.id" [ngModelOptions]="{standalone: true}" disabled>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-select placeholder="Select Status" [(ngModel)]="data.suggestion_statusId"
              [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let res of statusList; let i = index" [value]="res.suggestionStatusId">
                {{res.suggestionStatusName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <textarea class="form-control" [(ngModel)]="data.description"
            [ngModelOptions]="{standalone: true}"></textarea>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-12">
          <textarea class="form-control" [(ngModel)]="data.notes" [ngModelOptions]="{standalone: true}"></textarea>
        </div>
      </div>
      <br>
      <div class="action-btns text-right">
        <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit(data)">Save</button>
      </div>
    </form>
    <ag-grid-angular class="ag-theme-material"   domLayout='autoHeight'
      paginationAutoPageSize='false' paginationPageSize='20' [headerHeight]="headerHeight" [getRowHeight]="getRowHeight"
      [gridOptions]="gridOptionsInner" [columnDefs]="columnDefsInner" [rowData]="data.suggestLogs"
      (gridReady)="onGridInnerReady($event)" [modules]="modules"></ag-grid-angular>
  </div>
</ng-template>
