import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Component, OnInit } from '@angular/core';
import { AgGridLineChartCellRenderer } from '../../ag-grid/ag-grid-line-chart-cell-renderer.component';
import { generateGridColumns, paginationPageSize, rowSelection } from '../../ag-grid/gridOptions';
import { storesData } from '../../MockData';

@Component({
  selector: 'app-replenishment-store-view',
  templateUrl: './store-view.component.html',
  styleUrls: ['./store-view.component.scss']
})
export class StoreViewComponent implements OnInit {

  public gridOptions: any;
  paginationPageSize: number;
  rowModelType: string;
  gridData: any[];
  columnDefs: any[];
  rowSelection: string;
  modules = AllModules;

  // GRAPH VARIABLES
  dataSource = {
    chart: {
      caption: "Quarterly Revenue and Growth Trend",
      exportenabled: "0",
      drawcrossline: "1",
      yaxisname: "In Billions($)",
      syaxisname: "",
      showvalues: "0",
      showanchors: "0",
      plothighlighteffect: "fadeout",
      theme: "fusion"
    },
    categories: [
      {
        category: [
          {
            label: "Q1. 1 2018"
          },
          {
            label: "Q1. 2 2018"
          },
          {
            label: "Q1. 3 2018"
          },
          {
            label: "Q1. 4 2018"
          },
          {
            label: "Q2. 1 2018"
          },
          {
            label: "Q2. 2 2018"
          },
          {
            label: "Q2. 3 2018"
          },
          {
            label: "Q2. 4 2018"
          },
          {
            label: "Q3. 1 2018"
          }
        ]
      }
    ],
    dataset: [
      {
        seriesname: "Revenue",
        data: [
          {
            value: "10000000"
          },
          {
            value: "12000000"
          },
          {
            value: "15000000"
          },
          {
            value: "20000000"
          },
          {
            value: "18000000"
          },
          {
            value: "30000000"
          },
          {
            value: "22000000"
          },
          {
            value: "26000000"
          },
          {
            value: "38000000"
          }
        ]
      },
      {
        seriesname: "Growth",
        renderas: "line",
        parentyaxis: "S",
        data: [
          {
            value: "5"
          },
          {
            value: "15"
          },
          {
            value: "2"
          },
          {
            value: "-8"
          },
          {
            value: "-12"
          },
          {
            value: "50"
          },
          {
            value: "35"
          },
          {
            value: "37"
          },
          {
            value: "56"
          }
        ]
      }
    ]
  };

  width = '100%';
  height = '400';
  type = "mscombidy2d";
  dataFormat = "json";

  constructor() { }

  ngOnInit(): void {
    this.rowModelType = 'clientSide';
    this.paginationPageSize = paginationPageSize;
    this.gridOptions = {
      frameworkComponents: {
        agGridLineChartCellRenderer: AgGridLineChartCellRenderer
      },
      pagination: true,
      paginationPageSize: this.paginationPageSize,
    };
    this.rowSelection = rowSelection;
    this.gridData = storesData;
    const keys = Object.keys(this.gridData[0]);
    this.columnDefs = generateGridColumns(keys);
  }

}
