import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { NgxToasterService } from '../../../../services/ngx-toaster.service';
import { LocalstorageService } from '../../../../services/localstorage.service';
import { ConfigurationService } from '../../../../services/configuration.service';

import { ForecastConfirmationModalComponent } from './forecast-confirmation-modal/forecast-confirmation-modal.component';
import { Calendar, FbChannel, FbForecast, ForecastCell, Measure, UserFbForecast, Version } from '../../../../common/facebook.models';
import { TreeviewItem } from 'ngx-treeview';
import { USER_ID } from '../../../../common/keys';
import { throwError } from 'rxjs';
import { ForecastSavedVersionsComponent } from './forecast-saved-versions/forecast-saved-versions.component';
import { InlineWorker } from './inline-worker';
import { environment } from '../../../../environments/environment';
import { ForecastDeletePlannerModalComponent } from './fb-forecast-delete-planner-modal/fb-forecast-delete-planner-modal.component';
import { ValueCache } from '@ag-grid-community/core';
import { ForecastMeasuresComponent } from './forecast-measures/forecast-measures.component';
import { Retail_Direct, Global_Planning, Regional_Planning } from '../../mock-forecast';
import { HalfYear, Month, PhysicalYear, Quarter, Week } from '../global.models';

@Component({
  selector: 'app-fb-forecast',
  templateUrl: './fb-forecast.component.html',
  styleUrls: ['./fb-forecast.component.scss'],
})
export class FbForecastComponent implements OnInit {
  maxPlannerCount = environment.maxPlannerSaveCount
  public clientId = 1;
  public measureList: Measure[];
  public channelList: FbChannel[];
  public timeframeList: Calendar[];
  public versionList: Version[];
  public fbForecastList: UserFbForecast[];
  public channles = [];
  public customers = [];
  public countries = [];
  public continentList = [];
  public channelDropdownList: TreeviewItem[];
  public calendarListDropdown: TreeviewItem[];
  public leftGroupList = [];
  public productFlatList = [];
  public productList = [];
  public itemIds = [];
  public channelIds = [];
  public calendarIds = [];
  public dropdownObjects;
  public yearListGrid = []
  public yearList = [];
  public forecastList = [];
  public fbForecast;
  showVersionList = false
  className = '';
  public hideScreen = true;
  public versionTypeId = 2;
  public userFBForecastId = 0;
  plannerName = '';
  @ViewChild('saveForecast') saveForecastTemplate: TemplateRef<any>;
  @ViewChild('commitForecast') commitForecast: TemplateRef<any>;
  @Input() data;
  savedPlanner: {};
  selectedProducts = [];
  productTree = [];
  isOpenSaved: boolean;
  isBuidlNew: boolean;
  isRestoreSession: boolean;
  gridMapped: boolean;
  channelValue: any = '';
  calanderValue: any = '';
  productValue: any = '';
  isShowTreeDropDowns: boolean;
  public itemsList = [];
  public accountList = [];
  public productSettingList = [];
  public channelSettingList = [];
  refreshMeasureIds;
  constructor(
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    public dialogRef: MatDialogRef<any>,
    public ref: MatDialogRef<any>,
    public configurationService: ConfigurationService,
    public dialog: MatDialog) {
  }
  async ngOnInit() {
    this.getUserForecast();
    if (this.showVersionList) {
      this.className = 'col-md-3 form-group'
    } else {
      this.className = 'col-md-3 form-group'
    }
  }
  public getUserForecast = async () => {
    const model = {
      userId: this.storage.get(USER_ID),
      userFBForecastId: 0
    }
    this.fbForecast = await this.configurationService.GetFbForecast(model).toPromise().catch(error => throwError(error));
    if (this.fbForecast.itemIds && this.fbForecast.channelIds && this.fbForecast.calendarIds) {
      this.openActionDialog(true);
    } else {
      this.openActionDialog(false);
    }
  }
  public getDefaultDropdowns = (forecast?: any) => {
    this.configurationService.measuresGetList({}).subscribe((res: Measure[]) => {
      // this.measureList = res;
      this.measureList = Global_Planning;
      this.extractParentsFromMeasures(forecast);
    });
    const params = {
      offset: 0,
      pageSize: 100000,
      active: true
    }

    let url = `offSet=${params.offset ? params.offset : 0}&pageSize=${params.pageSize ? params.pageSize : 10}&active=true`
    this.configurationService.ItemAccountGetAll(url).subscribe(res => {
      this.accountList = (res.data || []);
      this.GetAccountSettingList(forecast);
    })
    this.configurationService.ItemManagerGetAll(url).subscribe(res => {
      this.itemsList = (res.data || []);
      this.GetProductSettingList(forecast);
    })
    // this.configurationService.channelGetList({}).subscribe((res: FbChannel[]) => {
    //   this.channelList = res;
    //   this.createParentChildListForChannel(forecast);
    // });
    this.configurationService.calendarGetList({}).subscribe((res: Calendar[]) => {
      this.timeframeList = res;
      this.createCalendarListDropdown(forecast);
    });
    this.configurationService.versionTypeGetList({}).subscribe((res: Version[]) => {
      this.versionList = res;
    })
    this.configurationService.userFBForecastGetList({}).subscribe((res: UserFbForecast[]) => {
      this.fbForecastList = res;

    });
    // this.getProductList(forecast);
  }
  public GetProductSettingList = (forecast?: any) => {
    this.configurationService.ProductSettingMappingGetList({}).subscribe((res) => {
      this.productSettingList = res;
      this.createProductDropdownList(res, forecast);
    });
  }
  public createProductDropdownList = (productSettingList, forecast) => {
    const brandIds = [];
    productSettingList.forEach(account => {
      if (brandIds.includes(account.brandId)) {
      } else {
        brandIds.push(account.brandId);
      }
    });
    let treeList = [];
    brandIds.forEach(brand => {
      let brandObject = {
        brandName: productSettingList.find(acc => acc.brandId === brand) ? productSettingList.find(acc => acc.brandId === brand).brandName : '',
        name: productSettingList.find(acc => acc.brandId === brand) ? productSettingList.find(acc => acc.brandId === brand).brandName : '',
        brandId: brand,
        children: [],
        isBrand: true
      }
      const productGroupIds = productSettingList.filter(el => el.brandId === brand).map(el => el.productGroupId).filter((value, index, self) => self.indexOf(value) === index);
      productGroupIds.forEach(productGroupId => {
        let productGroupObject = {
          productGroupName: productSettingList.find(acc => acc.productGroupId === productGroupId) ? productSettingList.find(acc => acc.productGroupId === productGroupId).productGroupName : '',
          name: productSettingList.find(acc => acc.productGroupId === productGroupId) ? productSettingList.find(acc => acc.productGroupId === productGroupId).productGroupName : '',
          productGroupId: productGroupId,
          brandId: brand,
          children: [],
          isProductGroup: true
        }
        const productFamilyIds = productSettingList.filter(el => el.productGroupId === productGroupId && el.brandId === brand).map(el => el.productFamilyId).filter((value, index, self) => self.indexOf(value) === index);
        productFamilyIds.forEach(productFamilyId => {
          let productFamilyObject = {
            productFamilyName: productSettingList.find(acc => acc.productFamilyId === productFamilyId) ? productSettingList.find(acc => acc.productFamilyId === productFamilyId).productFamilyName : '',
            name: productSettingList.find(acc => acc.productFamilyId === productFamilyId) ? productSettingList.find(acc => acc.productFamilyId === productFamilyId).productFamilyName : '',
            productFamilyId: productFamilyId,
            productGroupId: productGroupId,
            brandId: brand,
            children: [],
            isProductFamily: true
          }
          const parentProductIds = productSettingList.filter(el => el.productFamilyId === productFamilyId && el.productGroupId === productGroupId && el.brandId === brand).map(el => el.parentProductId).filter((value, index, self) => self.indexOf(value) === index);
          parentProductIds.forEach(parentProductId => {
            let products = this.itemsList.filter(row => row.parentProductId === parentProductId && row.productFamilyId === productFamilyId && row.productGroupId === productGroupId && row.brandId === brand)
            products = products.map(row => ({ name: row.description, isProduct: true, ...row }))
            let parentProductObject = {
              parentProductName: productSettingList.find(acc => acc.parentProductId === parentProductId) ? productSettingList.find(acc => acc.parentProductId === parentProductId).parentProductName : '',
              name: productSettingList.find(acc => acc.parentProductId === parentProductId) ? productSettingList.find(acc => acc.parentProductId === parentProductId).parentProductName : '',
              parentProductId: parentProductId,
              productGroupId: productGroupId,
              brandId: brand,
              children: products,
              isParentProduct: true
            }
            if (parentProductObject.parentProductId) {
              productFamilyObject.children.push(parentProductObject)
            }
          });
          if (productFamilyObject.productFamilyId) {
            productGroupObject.children.push(productFamilyObject)
          }
        });
        if (productGroupObject.productGroupId) {
          brandObject.children.push(productGroupObject)
        }
      });
      treeList.push(brandObject);
    });
    const tree = [];
    treeList.forEach(brand => {
      let brandObject = new TreeviewItem({
        text: brand.name,
        value: brand.brandId,
        children: brand.children.map(productGroup => {
          return new TreeviewItem({
            text: productGroup.name,
            value: productGroup.productGroupId,
            children: productGroup.children.map(productFamily => {
              return new TreeviewItem({
                text: productFamily.name,
                value: productFamily.productFamilyId,
                children: productFamily.children.map(parentProduct => {
                  return new TreeviewItem({
                    text: parentProduct.name,
                    value: parentProduct.parentProductId,
                    children: parentProduct.children.map(product => {
                      return new TreeviewItem({
                        text: product.name,
                        value: product.itemManagerId,
                        children: [],
                        checked: forecast && forecast.itemIds
                          ? forecast.itemIds.includes((product.itemManagerId).toString()) : false,
                      })
                    }),
                    checked: forecast && forecast.itemIds
                      ? forecast.itemIds.includes((parentProduct.parentProductId).toString()) : false,
                  });

                }),
                checked: forecast && forecast.itemIds
                  ? forecast.itemIds.includes((productFamily.productFamilyId).toString()) : false
              })

            }),
            checked: forecast && forecast.itemIds
              ? forecast.itemIds.includes((productGroup.productGroupId).toString()) : false,
          })

        }),
        checked: forecast && forecast.itemIds
          ? forecast.itemIds.includes((brand.brandId).toString()) : false
      })

      tree.push(brandObject);
    })
    this.productList = tree;
    if (forecast) {
      let test = []
      if (forecast.itemIds) {
        let ids = forecast.itemIds.split(',')
        ids.forEach(itemId => {
          this.productList.forEach(element => {
            if (this.search(element, Number(itemId))) {
              if (!test.filter(x => x.value == element.value).length) {
                test.push(element)

              }
            }
          })
        });
        //this.productTree = test
        let leafNodes = (this.getLeafNodes(test))
        leafNodes.forEach(element => {
          ids.forEach(itemId => {
            if (element.value == Number(itemId)) {
              this.productValue = element.text
              return
            }
          })
        });
      }
    }
  }
  public GetAccountSettingList = (forecast?: any) => {
    this.configurationService.AccountSettingMappingGetList({}).subscribe((res) => {
      this.channelSettingList = res;
      this.createChannelDropdownList(res, forecast)
    });
  }
  public createChannelDropdownList = (accoutSettingList, forecast) => {
    const accoutSettingListTemp = accoutSettingList;
    const channelIds = [];
    accoutSettingList.forEach(account => {
      if (channelIds.includes(account.channelId)) {
      } else {
        channelIds.push(account.channelId);
      }
    });
    let treeList = [];
    channelIds.forEach(channel => {
      let channelObject = {
        channelName: accoutSettingList.find(acc => acc.channelId === channel) ? accoutSettingList.find(acc => acc.channelId === channel).channelName : '',
        name: accoutSettingList.find(acc => acc.channelId === channel) ? accoutSettingList.find(acc => acc.channelId === channel).channelName : '',
        channelId: channel,
        children: [],
        isChannel: true
      }
      const regionIds = accoutSettingListTemp.filter(el => el.channelId === channel).map(el => el.continentId).filter((value, index, self) => self.indexOf(value) === index);
      regionIds.forEach(regionId => {
        let regionObject = {
          continentName: accoutSettingList.find(acc => acc.continentId === regionId) ? accoutSettingList.find(acc => acc.continentId === regionId).continentName : '',
          name: accoutSettingList.find(acc => acc.continentId === regionId) ? accoutSettingList.find(acc => acc.continentId === regionId).continentName : '',
          continentId: regionId,
          channelId: channel,
          children: [],
          isRegion: true
        }
        const countryIds = accoutSettingListTemp.filter(el => el.continentId === regionId && el.channelId === channel).map(el => el.countryId).filter((value, index, self) => self.indexOf(value) === index);
        countryIds.forEach(countryId => {
          let accounts = this.accountList.filter(account => account.countryId === countryId && account.regionId === regionId && account.channelId === channel)
          accounts = accounts.map(element => ({
            name: element.accountName,
            ...element,
            isAccount: true
          }));
          let countryObject = {
            countryName: accoutSettingList.find(acc => acc.countryId === countryId) ? accoutSettingList.find(acc => acc.countryId === countryId).countryName : '',
            name: accoutSettingList.find(acc => acc.countryId === countryId) ? accoutSettingList.find(acc => acc.countryId === countryId).countryName : '',
            countryId: countryId,
            continentId: regionId,
            channelId: channel,
            children: accounts,
            isCountry: true
          }
          if (countryObject.countryId) {
            regionObject.children.push(countryObject)
          }
        });
        if (regionObject.continentId) {
          channelObject.children.push(regionObject)
        }
      });
      treeList.push(channelObject);
    });
    const tree = [];
    treeList.forEach(channel => {
      let channelObject = new TreeviewItem({
        text: channel.name,
        value: channel.channelId + 'CH',
        checked: forecast && forecast.channelIds
          ? forecast.channelIds.includes((channel.channelId + 'CH').toString()) : false,
        children: channel.children.map(region => {
          return new TreeviewItem({
            text: region.name,
            value: region.continentId + 'R',
            checked: forecast && forecast.channelIds
              ? forecast.channelIds.includes((region.continentId + 'R').toString()) : false,
            children: region.children.map(country => {
              return new TreeviewItem({
                text: country.name,
                value: country.countryId + 'C',
                checked: forecast && forecast.channelIds
                  ? forecast.channelIds.includes((country.countryId + 'C').toString()) : false,
                children: country.children.map(account => {
                  return new TreeviewItem({
                    text: account.name,
                    value: account.itemAccountId,
                    checked: forecast && forecast.channelIds
                      ? forecast.channelIds.includes((account.itemAccountId).toString()) : false,
                  });
                })
              })
            })
          })
        })
      })
      tree.push(channelObject);
    })
    this.channelDropdownList = tree;
    if (forecast) {
      let test = []
      if (forecast.channelIds) {
        let ids = forecast.channelIds.split(',')
        ids.forEach(channelId => {
          this.channelDropdownList.forEach(element => {
            if (this.search(element, Number(channelId))) {
              if (!test.filter(x => x.value == element.value).length) {
                test.push(element)

              }
            }
          })
        });
        //this.productTree = test
        let leafNodes = (this.getLeafNodes(test))
        leafNodes.forEach(element => {
          ids.forEach(channelId => {
            if (element.value == Number(channelId)) {
              this.channelValue = element.text
              return
            }
          })
        });
      }
    }
  }
  public addDropdownNames = (forecast) => {
    if (forecast) {
      if (forecast.itemIds) {
        let ids = forecast.itemIds.split(',')
        let test = [];
        ids.forEach(itemId => {
          this.productList.forEach(element => {
            if (this.search(element, Number(itemId))) {
              if (!test.filter(x => x.value == element.value).length) {
                test.push(element)

              }
            }
          })
        });
        this.productTree = test
        let leafNodes = (this.getLeafNodes(test))
        leafNodes.forEach(element => {
          ids.forEach(itemId => {
            if (element.value == Number(itemId)) {
              this.productValue = element.text
              return
            }
          })
        });
      }
    }
  }
  // public getProductList = (forecast?: any) => {

  //   this.configurationService.GetItemList({}).subscribe((res) => {
  //     this.productFlatList = res;
  //     this.productList = res;
  //     this.mapProductList(this.productList, forecast);
  //   });
  // }
  // public mapProductList(data, forecast?: any) {
  //   const idMapping = data.reduce((acc, el, i) => {
  //     acc[el.itemId] = i;
  //     return acc;
  //   }, {});
  //   let root;
  //   data.forEach(el => {
  //     // Handle the root element
  //     if (el.parentId === 0) {
  //       root = el;
  //       return;
  //     }
  //     // Use our mapping to locate the parent element in our data array
  //     const parentEl = data[idMapping[el.parentId]];
  //     // Add our current el to its parent's `children` array
  //     parentEl.children = [...(parentEl.children || []), el];
  //   });
  //   data = data.filter(channel => !row.parentId);
  //   this.productList = this.convertToTree(data, forecast);
  //   let test = [];
  //   let scrollItems = [];
  //   if (forecast) {
  //     if (forecast.itemIds) {
  //       let ids = forecast.itemIds.split(',')
  //       ids.forEach(itemId => {
  //         this.productList.forEach(element => {
  //           if (this.search(element, Number(itemId))) {
  //             if (!test.filter(x => x.value == element.value).length) {
  //               test.push(element)

  //             }
  //           }
  //         })
  //       });
  //       this.productTree = test
  //       let leafNodes = (this.getLeafNodes(test))
  //       leafNodes.forEach(element => {
  //         ids.forEach(itemId => {
  //           if (element.value == Number(itemId)) {
  //             this.productValue = element.text
  //             return
  //           }
  //         })
  //       });
  //     }
  //   }
  // }
  search = (tree, target) => {
    if (tree.value.length) {
      for (var i = 0; i < tree.value.length; i++) {
        if (tree.value[i] === target) {
          return tree
        }
      }
    }
    else if (tree.value === target) {
      return tree;
    }
    if (tree.children) {
      for (const child of tree.children) {
        const res = this.search(child, target);

        if (res) {
          return res;
        }
      }
    } else {
      return null
    }

  };
  public convertToTree = (data, forecast?: any): TreeviewItem[] => {
    let list = [];
    data.forEach(row => {
      let level = 0;
      let obj = new TreeviewItem({ text: row.itemName || row.name, value: (row.itemId || row.id), checked: forecast && forecast.itemIds ? forecast.itemIds.includes((row.itemId || row.id).toString()) : false })
      if (row.children && row.children.length > 0) {
        obj.children = this.convertToTree(row.children, forecast);

      }
      list.push(obj)
    });
    console.log(list)

    return list;
  }
  public createParentChildListForChannel = (forecast?: any) => {
    this.channelList.forEach((element: FbChannel) => {
      const channelObject = {
        channelId: element.channelId,
        channelName: element.channelName
      };
      this.channles.push(channelObject);
      const customerObj = {
        customerId: element.customerId,
        customerName: element.customerName,
        channelId: element.channelId,
      };
      this.customers.push(customerObj);
      const countryObject = {
        countryId: element.countryId,
        countryName: element.countryName,
        channelId: element.channelId,
      };
      this.countries.push(countryObject);
      const continentObject = {
        continentid: element.continentid,
        continentName: element.continentName,
        channelId: element.channelId,
      };
      if (this.continentList.findIndex(cont => cont.continentid === element.continentid) < 0) {
        this.continentList.push(continentObject);
      }
    });
    this.channelDropdownList = [];
    this.continentList.forEach(continent => {
      let object = new TreeviewItem({
        text: continent.continentName,
        value: continent.continentid,
        checked: false,
        children: this.countries.filter(country => country.channelId === continent.channelId).map(cont => (
          {
            text: cont.countryName,
            value: cont.countryId,
            checked: false,
            children: this.customers.filter(customer => customer.channelId === cont.channelId).map(cust => (
              this.mapCustomers(cust, cont, forecast)
            ))
          }
        ))
      });
      this.channelDropdownList.push(object);
    });
    let test = []
    if (forecast) {
      if (forecast.channelIds) {
        let ids = forecast.channelIds.split(',')
        ids.forEach(channelId => {
          this.channelDropdownList.forEach(element => {
            if (this.search(element, Number(channelId))) {
              if (!test.filter(x => x.value == element.value).length)
                test.push(element)

            }
          })
        });
        this.productTree = test
        let leafNodes = (this.getLeafNodes(test))
        leafNodes.forEach(element => {
          ids.forEach(channelId => {
            if (element.value == Number(channelId)) {
              this.channelValue = element.text
              return
            }
          })
        });
      }
    }
  }
  public createCalendarListDropdown = (forecast?: any) => {
    this.calendarListDropdown = [];
    const uniqueYears = [...new Set(this.timeframeList.map((item: Calendar) => item.fy))]; // ['FY18']
    uniqueYears.forEach((year: string, index) => {
      const yearList = this.timeframeList.filter(row => row.fy === year);
      const uniqueHalfYears = [...new Set(yearList.map((item: Calendar) => item.hy))]; // ['H1']
      const uniqueQuarters = [];
      const uniquemonths = [];
      yearList.forEach(y => {
        if (uniqueQuarters.findIndex(x => x.quarter === y.quarter && x.hy === y.hy) < 0) {
          uniqueQuarters.push({ quarter: y.quarter, hy: y.hy });
        }
        if (uniquemonths.findIndex(x => x.quarter === y.quarter && x.month === y.month) < 0) {
          uniquemonths.push({ quarter: y.quarter, month: y.month });
        }
        y.checked = forecast && forecast.calendarIds ? forecast.calendarIds.split(',').find(val => val === y.calendarId.toString()) : false
      })
      // const uniqueQuarters = yearList.filter(year => year.quarter)
      const obj = new TreeviewItem({
        text: year,
        value: year,
        checked: false,
        children: uniqueHalfYears.map((h: string) => new TreeviewItem({
          text: h,
          value: h,
          checked: false,
          children: uniqueQuarters.filter(e => e.hy === h).map(q => new TreeviewItem({
            text: q.quarter,
            value: q.quarter,
            checked: false,
            children: uniquemonths.filter(el => el.quarter === q.quarter).map(m => ({
              text: m.month,
              checked: yearList.filter(el => el.month === m.month && el.quarter === q.quarter && el.hy === h).find(el => !el.checked) ? false : true,
              value: yearList.filter(el => el.month === m.month && el.quarter === q.quarter && el.hy === h).map(cal => cal.calendarId)
            }))
          }))
        }))
      })
      this.calendarListDropdown.push(obj);
    });
    let test = []
    if (forecast) {
      if (forecast.calendarIds) {
        let ids = forecast.calendarIds.split(',')
        ids.forEach(channelId => {
          this.calendarListDropdown.forEach(element => {
            if (this.search(element, Number(channelId))) {
              if (!test.filter(x => x.value == element.value).length)
                test.push(element)
            }
          })
        });
        this.productTree = test
        this.selectedProducts = test
        let leafNodes = (this.getLeafNodes(this.selectedProducts))
        leafNodes.forEach(element => {
          ids.forEach(channelId => {
            if (element.value.filter(x => x == Number(channelId)).length > 0) {
              this.calanderValue = element.text
              return
            }
          })
        });
      }
    }
  }
  getLeafNodes(nodes, result = []) {
    for (var i = 0, length = nodes.length; i < length; i++) {
      if (!nodes[i].children || nodes[i].children.length === 0) {
        result.push(nodes[i]);
      } else {
        result = this.getLeafNodes(nodes[i].children, result);
      }
    }
    return result;
  }
  public extractParentsFromMeasures = (forecast?: any) => {
    const groupList = this.measureList.filter(measure => !measure.parentId);
    this.leftGroupList = groupList.map(row => ({ ...row, rowspan: this.measureList.filter(measure => measure.parentId === row.measureId).length }))
    this.measureList = this.measureList.filter(row => row.parentId).map(row => ({ ...row, checked: true })).sort((a, b) => a.parentId - b.parentId);

  }
  public mapCustomers = (cust, cont, forecast?: any) => {
    return {
      text: cust.customerName,
      value: cust.customerId,
      checked: false,
      children: this.channles.filter(customer => customer.channelId === cont.channelId).map(channel => (
        {
          text: channel.channelName,
          value: channel.channelId,
          checked: forecast && forecast.itemIds ? forecast.channelIds.includes(channel.channelId.toString()) : false
        }
      ))
    }
  }
  openFullScreen = (id: string) => {
    const domGrid = document.getElementById(id) as any;
    if (domGrid) {
      domGrid.webkitRequestFullscreen();
    }
  }

  openActionDialog(restoreSession: boolean): void {
    // tslint:disable-next-line: no-use-before-declare
    const sessionLabel = restoreSession ?
      'Your last session ended abruptly, would you like to return to where you left off?'
      : 'Would you like to open a saved planner or build new?'
    this.dialog.open(ForecastConfirmationModalComponent, {
      width: '848px',
      panelClass: ['fb-dialog-wrapper2', 'fb-dialog-wrapper3'],
      data: { isRestoreSession: restoreSession, label: sessionLabel, isOnLoad: restoreSession ? false : true, isSaveAndCommit: false },
      disableClose: true
    }).afterClosed().subscribe(command => {
      if (command && command.isCanceled) {
        this.closeScreen();
      } else if (command && command.openSaved) {
        this.isOpenSaved = true
        this.openSavedVersionDialog();
      } else if (command && command.restoreSession) {
        this.restoreSession();
        this.isRestoreSession = true
        this.hideScreen = false;
      } else {
        this.hideScreen = false;
        this.getDefaultDropdowns();
        this.isBuidlNew = true
        this.isShowTreeDropDowns = true
      }
    });
  }
  public openSavedPlanner = async (version) => {
    const model = {
      userId: this.storage.get(USER_ID),
      userFBForecastId: version.userFBForecastId
    }
    this.userFBForecastId = version.userFBForecastId
    this.fbForecast = await this.configurationService.GetFbForecast(model).toPromise().catch(error => throwError(error));
    this.versionTypeId = 3;
    this.userFBForecastId = version.userFBForecastId;
    this.createChannelDropdownList(this.channelSettingList, this.fbForecast)
    this.createProductDropdownList(this.productSettingList, this.fbForecast)
    this.getDefaultDropdowns(this.fbForecast);
    this.addDropdownNames(this.fbForecast)
  }
  public restoreSession = async () => {
    // this.spinner.show();
    // const model = {
    //   userId: this.storage.get(USER_ID),
    //   userFBForecastId: 0,
    //   itemIds: this.fbForecast.itemIds,
    //   channelIds: this.fbForecast.channelIds,
    //   calendarIds: this.fbForecast.calendarIds,
    // }
    // this.fbForecast = await this.configurationService.GetFbForecast(model).toPromise().catch(error => throwError(error));
    // this.spinner.hide();
    this.createChannelDropdownList(this.channelSettingList, this.fbForecast)
    this.createProductDropdownList(this.productSettingList, this.fbForecast)
    this.getDefaultDropdowns(this.fbForecast);
  }
  public openSavedVersionDialog = () => {
    const dialogRef = this.dialog.open(ForecastSavedVersionsComponent, {
      width: '848px',
      panelClass: 'fb-dialog-wrapper2',
      data: {},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.version) {
        this.openSavedPlanner(res.version);
        this.plannerName = res.version.name
        this.userFBForecastId = res.version.userFBForecastId
        this.configurationService.savedPlannerOpened.next(res.version)
        this.hideScreen = false;
      }
    })
  }
  public openDeletePlannerDialog = () => {
    const dialogRef = this.dialog.open(ForecastDeletePlannerModalComponent, {
      width: '848px',
      panelClass: 'fb-dialog-wrapper2',
      data: {},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.version) {
        this.openSavedPlanner(res.version);
        this.configurationService.savedPlannerOpened.next(res.version)
        this.hideScreen = false;
      }
    })
  }
  public closeScreen = () => {
    this.configurationService.removeMenu.next('app-forecast');
  }
  setValue = (selectedDropdown) => {
    switch (selectedDropdown.type) {
      case 'Item': {
        this.itemIds = selectedDropdown.value;
        break;
      }
      case 'Channel': {
        this.channelIds = selectedDropdown.value;
        break;
      }
      case 'Timeframe': {
        this.calendarIds = selectedDropdown.value;
        break;
      }
    }
    this.dropdownObjects = {
      itemIds: this.itemIds || [],
      channelIds: this.channelIds || [],
      calendarIds: this.calendarIds || []
    };
    if (this.isOpenSaved || this.isRestoreSession) {
      this.mapGridList(true)
    }

  }

  public mapGridList = async (isOnload) => {
    this.gridMapped = true
    if ((this.isOpenSaved || this.isRestoreSession) && !isOnload) {
      this.isOpenSaved = false
      this.isRestoreSession = false
    }
    this.spinner.show()
    if (this.isBuidlNew && this.isShowTreeDropDowns) {
      const model = {
        userId: this.storage.get(USER_ID),
        userFBForecastId: 0,
        itemIds: this.fbForecast.itemIds,
        channelIds: this.fbForecast.channelIds,
        calendarIds: this.fbForecast.calendarIds,
      }
      this.fbForecast = await this.configurationService.GetFbForecast(model).toPromise().catch(error => {
        this.spinner.hide();
        throwError(error)
      });
      this.isShowTreeDropDowns = false;
    }
    this.createCalendarListDropdown(this.fbForecast);
    this.forecastList = [];
    if (this.dropdownObjects.itemIds.length && this.dropdownObjects.channelIds.length && this.dropdownObjects.calendarIds.length) {
      //this.spinner.show();
      this.dropdownObjects.itemIds.forEach(item => {
        this.dropdownObjects.channelIds.forEach(channel => {
          this.dropdownObjects.calendarIds.forEach(frame => {
            frame.forEach(calendar => {
              this.measureList.forEach(measure => {
                if (this.fbForecast && this.fbForecast.fbForecasts.length > 0) {
                  const forecastObject = this.fbForecast.fbForecasts.find(row => (row.measureId === measure.measureId) && (row.calendarId === calendar) && (row.channelId === channel));
                  if (forecastObject) {
                    this.forecastList.push(forecastObject);
                  } else {
                    const forecastObject: ForecastCell = {
                      fbForecastId: 0,
                      itemId: item,
                      channelId: channel,
                      measureId: measure.measureId,
                      calendarId: calendar,
                      isEdited: false,
                      value: 0,
                      userFBForecastId: this.userFBForecastId,
                      versionTypeId: this.versionTypeId,
                      userId: this.storage.get(USER_ID),
                    }
                    this.forecastList.push(forecastObject);
                  }
                } else {
                  const forecastObject: ForecastCell = {
                    fbForecastId: 0,
                    itemId: item,
                    channelId: channel,
                    measureId: measure.measureId,
                    calendarId: calendar,
                    isEdited: false,
                    value: 0,
                    userFBForecastId: this.userFBForecastId,
                    versionTypeId: this.versionTypeId,
                    userId: this.storage.get(USER_ID),
                  }
                  this.forecastList.push(forecastObject);
                }
              })
            })
          });
        })
      });
      this.mapCalendarValues();
    }
  }
  public mapCalendarValues = () => {
    const weekList = [];
    this.calendarIds.forEach(calendar => {
      calendar.forEach(cal => {
        let obj = {
          calendarId: cal,
          items: this.itemIds,
          channels: this.channelIds,
          measures: this.forecastList.filter(row => cal === row.calendarId).map(row => ({
            items: this.itemIds,
            channels: this.channelIds,
            source: this.forecastList.filter(row => cal === row.calendarId),
            ...new Week(),
          }))
        }
        weekList.push(obj)
      })

    });
    if (weekList && weekList.length > 0) {
      try {
        //this.CreateCalendarList(weekList);
        this.spinner.show()
        this.setupLongRunningProcess({
          weekList: weekList, timeframeList: this.timeframeList, calendarIds: this.calendarIds,
          physicalYear: JSON.stringify(new PhysicalYear()),
          halfYear: JSON.stringify(new HalfYear()),
          quarter: JSON.stringify(new Quarter()),
          month: JSON.stringify(new Month()),
          forecastList: this.forecastList,
          measureList: this.measureList,
          week: JSON.stringify(new Week())
        })

      } catch {
        this.spinner.hide();
      }
    }
  }
  setupLongRunningProcess(evt) {
    console.log(evt)
    const worker = new InlineWorker(() => {
      // START OF WORKER THREAD CODE\

      console.log('Start worker thread, wait for postMessage: ');
      function getTitle(week, timeFrame) {
        let index = timeFrame.findIndex(el => el.calendarId === week.calendarId);
        if (index > -1) {
          return timeFrame[index].weekStartDate ? new Date(timeFrame[index].weekStartDate).toLocaleDateString() : ''
        }
        return '';
      }
      function addWeekList(weekList, month, year, timeFrame, forecastList, measureList, weekobj) {
        weekList = weekList.map(week => ({ ...week, ...JSON.parse(weekobj) }));
        weekList = weekList.map(week => ({ ...week, calendar: timeFrame.find(time => time.calendarId === week.calendarId) }));
        weekList = weekList.filter(week => week.calendar && week.calendar.month === month.month && week.calendar.fy === year)
        weekList.forEach(element => {
          Object.keys(element).forEach(key => {
            if (typeof element[key] === 'object') {
              Object.keys(element[key]).forEach(subkey => {
                const measure = measureList.find(measure => measure.code === subkey);
                if (measure && measure.measureId) {
                  const forecast = forecastList.filter(f => f.calendarId === element.calendarId && f.measureId === measure.measureId && element.channels.includes(f.channelId) && element.items.includes(f.itemId));
                  if (forecast.length > 0) {
                    forecast.forEach(el => {
                      element[key][subkey] += el.value;
                    })
                  }
                }
              });
            }
          });
        });
        if (timeFrame.find(cal => cal.month === month.month)) {
          return weekList.map(row => ({ ...row, title: getTitle(row, timeFrame) }));
        }
        return [];
      }
      const calculateCountOfPrimeNumbers = (event) => {

        console.log('Start worker thread, wait for postMessage: ');
        console.log(event)
        const uniqueYears = [...new Set(event.data.timeframeList.map((item) => item.fy))]; // ['FY18']
        let years = [];
        uniqueYears.forEach((year: string, index) => {
          const yearList = event.data.timeframeList.filter(row => row.fy === year);
          const uniqueHalfYears = [...new Set(yearList.map((item) => item.hy))]; // ['H1']
          const uniqueQuarters = [];
          const uniquemonths = [];
          yearList.forEach(y => {
            if (uniqueQuarters.findIndex(x => x.quarter === y.quarter && x.hy === y.hy) < 0) {
              uniqueQuarters.push({ quarter: y.quarter, hy: y.hy });
            }
            if (uniquemonths.findIndex(x => x.quarter === y.quarter && x.month === y.month) < 0) {
              uniquemonths.push({ quarter: y.quarter, month: y.month });
            }
          });
          const timeFrame = event.data.timeframeList.filter(time => event.data.calendarIds.find(el => el.includes(time.calendarId)));
          const currentYear = JSON.parse(event.data.physicalYear);
          let object: any = {
            title: year,
            ...currentYear,
            halfList: uniqueHalfYears.map(h => ({
              ...JSON.parse(event.data.halfYear),
              title: h,
              quarterList: uniqueQuarters.filter(e => e.hy === h).filter(e => e.hy === h).map(quarter => ({
                ...JSON.parse(event.data.quarter),
                title: quarter.quarter,
                monthList: uniquemonths.filter(el => el.quarter === quarter.quarter).map(month => ({
                  ...JSON.parse(event.data.month),
                  title: month.month,
                  weekList: addWeekList(event.data.weekList, month, year, timeFrame, event.data.forecastList, event.data.measureList, event.data.week)
                }))
              }))
            }))
          };
          if (timeFrame.find(cal => cal.fy === year)) {
            years.push(object);
          }
          // const uniqueQuarters = yearList.filter(year => year.quarter
        });

        // this is from DedicatedWorkerGlobalScope ( because of that we have postMessage and onmessage methods )
        // and it can't see methods of this class
        // @ts-ignore
        this.postMessage({
          primeNumbers: years
        });
      };

      // @ts-ignore

      this.onmessage = (evt) => {
        console.log('Calculation started: ' + new Date());
        calculateCountOfPrimeNumbers(evt);
      };
      // END OF WORKER THREAD CODE
    });

    worker.postMessage(evt);

    worker.onmessage().subscribe((data) => {
      console.log('Calculation done: ', new Date() + ' ' + data.data);
      this.yearListGrid = data.data.primeNumbers;
      this.spinner.hide();
      worker.terminate();
    });

    worker.onerror().subscribe((data) => {
      console.log(data);
    });
  }
  public CreateCalendarList = (weekList) => {
    const uniqueYears = [...new Set(this.timeframeList.map((item: Calendar) => item.fy))]; // ['FY18']
    let years = [];
    uniqueYears.forEach((year: string, index) => {
      const yearList = this.timeframeList.filter(row => row.fy === year);
      const uniqueHalfYears = [...new Set(yearList.map((item: Calendar) => item.hy))]; // ['H1']
      const uniqueQuarters = [];
      const uniquemonths = [];
      yearList.forEach(y => {
        if (uniqueQuarters.findIndex(x => x.quarter === y.quarter && x.hy === y.hy) < 0) {
          uniqueQuarters.push({ quarter: y.quarter, hy: y.hy });
        }
        if (uniquemonths.findIndex(x => x.quarter === y.quarter && x.month === y.month) < 0) {
          uniquemonths.push({ quarter: y.quarter, month: y.month });
        }
      });
      const timeFrame = this.timeframeList.filter(time => this.calendarIds.find(el => el.includes(time.calendarId)));
      const currentYear = new PhysicalYear();
      let object: any = {
        title: year,
        ...currentYear,
        halfList: uniqueHalfYears.map(h => ({
          title: h,
          ...new HalfYear(),
          quarterList: uniqueQuarters.filter(e => e.hy === h).filter(e => e.hy === h).map(quarter => ({
            title: quarter.quarter,
            ...new Quarter(),
            monthList: uniquemonths.filter(el => el.quarter === quarter.quarter).map(month => ({
              title: month.month,
              ...new Month(),
              weekList: this.addWeekList(weekList, month, year, timeFrame)
            }))
          }))
        }))
      };
      if (timeFrame.find(cal => cal.fy === year)) {
        years.push(object);
      }
      // const uniqueQuarters = yearList.filter(year => year.quarter
    });
    this.yearListGrid = years;
  }
  public addWeekList = (weekList, month, year, timeFrame) => {
    weekList = weekList.map(week => ({ ...week, ...new Week() }));
    weekList = weekList.map(week => ({ ...week, calendar: timeFrame.find(time => time.calendarId === week.calendarId) }));
    weekList = weekList.filter(week => week.calendar && week.calendar.month === month.month && week.calendar.fy === year)
    weekList.forEach(element => {
      Object.keys(element).forEach(key => {
        if (typeof element[key] === 'object') {
          Object.keys(element[key]).forEach(subkey => {
            const measure = this.measureList.find(measure => measure.code === subkey);
            if (measure && measure.measureId) {
              const forecast = this.forecastList.filter(f => f.calendarId === element.calendarId && f.measureId === measure.measureId && element.channels.includes(f.channelId) && element.items.includes(f.itemId));
              if (forecast.length > 0) {
                forecast.forEach(el => {
                  element[key][subkey] += el.value;
                })
              }
            }
          });
        }
      });
    });
    if (timeFrame.find(cal => cal.month === month.month)) {
      return weekList.map(row => ({ ...row, title: this.getTitle(row, timeFrame) }));
    }
    return [];
  }
  public getTitle = (week, timeFrame) => {
    let index = timeFrame.findIndex(el => el.calendarId === week.calendarId);
    if (index > -1) {
      return timeFrame[index].weekStartDate ? new Date(timeFrame[index].weekStartDate).toLocaleDateString() : ''
    }
    return '';
  }
  openMesuresDialog() {
    this.dialog.open(ForecastMeasuresComponent, {
      width: '1200px',
      panelClass: 'fb-dialog-wrapper',
      data: { measureList: this.measureList },
    }).afterClosed().subscribe(command => {
      if (command) {
        console.log(command);
      }
    });
  }
  adjustData() {
    this.dialog.open(ForecastConfirmationModalComponent, {
      width: '848px',
      panelClass: 'fb-dialog-wrapper2',
      data: { label: 'Would you like to save your current forecast planner before adjusting?', adjustData: true },
      disableClose: true
    }).afterClosed().subscribe(command => {
      if (command) {

        //
      }
    });
  }

  save() {

    this.dialog.open(ForecastConfirmationModalComponent, {
      width: '848px',
      panelClass: ['fb-dialog-wrapper2', 'fb-dialog-wrapper3'],
      data: { label: 'How would you like to proceed?', isOnLoad: false, isSaveAndCommit: true },
      disableClose: true
    }).afterClosed().subscribe(command => {
      if (command) {
        if (command.isSave) {
          if (!this.isBuidlNew) {
            const model = {
              itemIds: this.itemIds.toString(),
              channelIds: this.channelIds.toString(),
              calendarIds: this.calendarIds.toString(),
              userId: this.storage.get(USER_ID),
              name: this.plannerName,
              userFBForecastId: this.userFBForecastId,
              active: true
            };
            this.spinner.show()
            this.configurationService.userFBForecastUpdate(model).subscribe(resp => {
              this.spinner.hide()
            })
          } else
            this.saveForecast();
        } else if (command.isCommit) {
          this.commitForecastPlanner();
        } else if (command.isSaveCommit) {
          this.commitForecastPlanner();
        }

        //
      }
    });

  }
  refreshMeasure() {
    this.dialog.open(ForecastConfirmationModalComponent, {
      width: '848px',
      panelClass: 'fb-dialog-wrapper2',
      data: {
        label: 'Are you sure you want to refresh these measures? Your current data will be lost.', isOnLoad: false, refreshMeasure: true,
      },
      disableClose: true
    }).afterClosed().subscribe(command => {
      if (command) {
        this.refreshForecastMeasures();
      }
    })
  }
  public refreshForecastMeasures = () => {
    let ids = '';
    this.calendarIds.forEach((el, index) => {
      ids += el.toString() + ',';
    })
    const model = {
      itemIds: this.itemIds.toString(),
      channelIds: this.channelIds.toString(),
      calendarIds: ids.toString().trim().replace(/,{1,}$/, ''),
      userId: this.storage.get(USER_ID),
      measureIds: this.refreshMeasureIds.join(','),
      userFBForecastId: this.userFBForecastId,
      fbForecastSelectedId: this.fbForecast ? this.fbForecast.fbForecastSelectedId : ''
    };
    this.configurationService.FbForecastMasterRefreshGet(model).subscribe(res => {
      const forecasts = res.fbForecasts;
      if (this.forecastList && this.forecastList.length > 0) {
        this.forecastList.forEach((el, forcastIndex) => {
          const index = forecasts.findIndex(row => row.measureId === el.measureId && row.channelId === el.channelId && row.itemId === el.itemId && row.calendarId === el.calendarId);
          if (index > -1) {
            this.forecastList[forcastIndex].value = forecasts[index].value;
          }
        });
        this.mapCalendarValues();
      }
    })

  }
  public saveForecast = () => {

    this.dialog.open(ForecastConfirmationModalComponent, {
      width: '848px',
      panelClass: 'fb-dialog-wrapper2',
      data: {
        label: 'What would you like to name your planner?', isOnLoad: false, changeNameConfirmation: true,
        isInputRequired: true, inputLabel: 'Enter name'
      },
      disableClose: true
    }).afterClosed().subscribe(command => {
      if (command) {
        this.plannerName = command.name
        this.savePlanner();
      }
    })


  }

  public savePlanner = () => {
    const params = {
      userId: this.storage.get(USER_ID)
    }
    this.configurationService.userFBForecastGetList(params).subscribe(res => {
      if (res.find(x => x.name == this.plannerName)) {
        this.dialog.open(ForecastConfirmationModalComponent, {
          width: '848px',
          panelClass: 'fb-dialog-wrapper2',
          data: {
            label: 'Planner with this name already exists. Please update name and try again', isOnLoad: false, confirmation: true
          },
          disableClose: true
        })
      } else {
        const model = {
          itemIds: this.itemIds.toString(),
          channelIds: this.channelIds.toString(),
          calendarIds: this.calendarIds.toString(),
          userId: this.storage.get(USER_ID),
          name: this.plannerName,
          active: true
        };
        this.spinner.show()
        this.configurationService.userFBForecastAdd(model).subscribe(res => {
          this.savedPlanner = model
          // this.selectedProducts = this.mapProductList(this.productList,this.savePlanner)
          this.spinner.hide()
          this.configurationService.savedPlannerOpened.next(model)
        })
      }
    })

  }
  public commitForecastPlanner = () => {
    this.ref = this.dialog.open(this.commitForecast, {
      width: '848px',
      panelClass: 'fb-dialog-wrapper2'
    });
  }
  public commitFacebookPlanner = () => {
    const model = {
      itemIds: this.itemIds.toString(),
      channelIds: this.channelIds.toString(),
      calendarIds: this.calendarIds.toString(),
      userId: this.storage.get(USER_ID),
    };
    this.spinner.show()
    this.configurationService.FBForecastCommit(model).subscribe(res => {
      this.spinner.hide()
      this.ref.close();
    })
  }
  checkIfMaxCountReached() {
    if (!this.isBuidlNew) {
      this.save()
      return
    }

    const params = {
      userId: this.storage.get(USER_ID)
    }
    this.configurationService.userFBForecastGetList(params).subscribe(res => {
      if (res.length >= this.maxPlannerCount) {
        this.dialog.open(ForecastDeletePlannerModalComponent, {
          width: '848px',
          panelClass: 'fb-dialog-wrapper2',
          data: { versionList: res },
          disableClose: true
        }).afterClosed().subscribe(command => {
          if (command && command.version) {
            let model = { versionTypeId: null, userFBForecastIds: '' }
            model.versionTypeId = 3
            for (var i = 0; i < command.version.length; i++) {
              model.userFBForecastIds = model.userFBForecastIds + command.version[i].userFBForecastId + ','
            }
            model.userFBForecastIds = model.userFBForecastIds.slice(0, -1)
            this.configurationService.userFBForecastDeleteByVersionID(model).subscribe(resp => {

            })
            this.save();
          }
        });

      }
      else {
        this.save()
      }
    })

  }
  onSelectionChanged(event) {
    if (event.value.indexOf(-1) > 0) {
      this.refreshMeasureIds = ''
    }
    else {
      this.refreshMeasureIds = event.value
    }
  }
  isAnyOpened = () => {
    return document.getElementsByClassName('dropdown show').length > 0;
  }
}

