import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OutlierService } from 'src/services/forecast-services/outlier-service';

@Component({
  selector: 'commit-action-cell-renderer',
  template: `<button *ngIf="params.value" mat-raised-button color="accent" (click)="onCommit($event)">Commit</button>`,
})
export class CommitActionCellRenderer implements ICellRendererAngularComp, OnDestroy {
  params: any;

  constructor(public outlierService: OutlierService) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  onCommit(params) {
    this.outlierService.setWeekCommitActionSubject(this.params.value);
  }

  refresh(params?: any): boolean {
    return true;
  }

  ngOnDestroy() {
    // no need to remove the button click handler 
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}