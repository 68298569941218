import { Component, OnInit } from '@angular/core';
import { planManagaerGridData } from '../../mockData';
import { generateGridColumns } from './agGridConfig';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { MatDialog } from '@angular/material/dialog';
import { MfpSetupComponent } from '../mfp-setup/mfp-setup.component';

@Component({
  selector: 'app-plan-manager',
  templateUrl: './plan-manager.component.html',
  styleUrls: ['./plan-manager.component.scss']
})
export class PlanManagerComponent implements OnInit {

   // Grid Variables
   public gridColumnDefs = generateGridColumns();
   public modules = AllCommunityModules;
   private gridApi;
   frameworkComponents = {};
 
   gridData = planManagaerGridData;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  createNew() {
    const columnsDialogRef = this.dialog.open(MfpSetupComponent, {
      width: '900px',
      disableClose: true
    });

    columnsDialogRef.afterClosed().subscribe(result => {
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

}
