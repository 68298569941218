<mat-accordion class="fb-forecast-accordion item-wrapper-panel" id="fb-forecast">
  <mat-expansion-panel class="item-wrapper" [expanded]="true">
    <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
      <mat-panel-title>
        {{isEdit ? itemModel.description : 'New Item'}}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-tab-group>
      <mat-tab label="Item Attributes">
        <app-item-attributes [submitted]="submitted" [itemModel]="itemModel"></app-item-attributes>
      </mat-tab>
      <!-- <mat-tab label="Inventory Attributes">
        <app-inventory-attributes [submitted]="submitted" [itemModel]="itemModel"></app-inventory-attributes>
      </mat-tab> -->
      <mat-tab label="Forecast Settings">
        <app-account-setting [itemModel]="itemModel"></app-account-setting>
      </mat-tab>
    </mat-tab-group>
    <div class="col-12 btn-wrapper">
      <button class="btn btn-secondary" (click)="closePanel.emit()">Close</button>
      <button class="btn btn-primary" (click)="onSubmit()">{{itemModel.itemManagerId ? 'Save' : 'Create'}}</button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
