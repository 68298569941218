<div class="container-fluid">
  <div class="user-page">
    <div class="row">
      <div class="col-md-1 d-block d-sm-block d-md-none mobile-icon">
        <div class="btn-show">
          <button (click)="openDialog({})" mat-icon-button color="primary">
            <i class="material-icons">
              add_circle
            </i>
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <form action="" class="form-fields show-search">
          <div class="form-group">
            <mat-form-field>
              <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-md-8 d-none d-sm-none d-md-block tablet-icon">
        <div class="btn-show">
          <button (click)="openDialog({})" mat-icon-button color="primary">
            <i class="material-icons">
              add_circle
            </i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material"  [suppressMenuHide]="true"
      groupHeaders suppressRowClickSelection  toolPanelSuppressGroups toolPanelSuppressValues
      [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="ItemMasterList" (gridReady)="onGridReady($event)"
      [modules]="modules" #grid></ag-grid-angular>
  </div>
</div>
<!-- <router-outlet name="promotion"></router-outlet> -->
<ng-template #dialogRef let-data>
  <h1 mat-dialog-title>Product Copy</h1>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-md-3">
        <mat-form-field>
          <input matInput [(ngModel)]="data.brand" placeholder="Enter Brand" maxlength="20">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <input matInput [(ngModel)]="data.modelNumber" placeholder="Enter Model Number" maxlength="20">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <input matInput [(ngModel)]="data.productName" placeholder="Enter Product Name" maxlength="20">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <input matInput [(ngModel)]="data.description" placeholder="Enter Description" maxlength="20">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <mat-form-field>
          <input matInput [(ngModel)]="data.featurBullets" placeholder="Enter Feature Bullets" maxlength="20">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <input matInput [(ngModel)]="data.shortDescription" placeholder="Enter Short Description" maxlength="20">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <input matInput [(ngModel)]="data.metaKeywords" placeholder="Enter Meta Keywords" maxlength="20">
        </mat-form-field>
      </div>
      <div class="col-md-3">

        <mat-form-field>
          <input matInput [(ngModel)]="data.metaDescription" placeholder="Enter Meta Description" maxlength="20">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSave(data)">Save</button>
    <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="matDialogRef.close()">Cancel</button>
  </div>
</ng-template>
