import { NestedTreeControl } from '@angular/cdk/tree';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTreeNestedDataSource } from '@angular/material/tree';

@Component({
  selector: 'app-mfp-setup',
  templateUrl: './mfp-setup.component.html',
  styleUrls: ['./mfp-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MfpSetupComponent implements OnInit {

  Time_TREE_DATA = [
    {
      id: 'FY 2022',
      children: [
        {
          id: 'HY-1 2022', children: [
            {
              id: 'Q-1 2022',
              children: []
            },
            {
              id: 'Q-2 2022',
              children: []
            }
          ]
        },
        {
          id: 'HY-2 2022', children: [
            {
              id: 'Q-3 2022',
              children: []
            },
            {
              id: 'Q-4 2022',
              children: []
            }
          ]
        }
      ],
    },
    {
      id: 'FY 2023',
      children: [
        {
          id: 'HY-1 2023', children: [
            {
              id: 'Q-1 2023',
              children: []
            },
            {
              id: 'Q-2 2023',
              children: []
            }
          ]
        },
        {
          id: 'HY-2 2023', children: [
            {
              id: 'Q-3 2023',
              children: []
            },
            {
              id: 'Q-4 2023',
              children: []
            }
          ]
        }
      ],
    },
  ];

  Channel_TREE_DATA = [
    { id: 'Retail', children: [] },
    { id: 'Wholesale', children: [] },
    { id: 'Online', children: [] }
  ];
  drop_Channel_TREE_DATA = [];

  Product_TREE_DATA = [
    {
      id: 'Brand',
      children: [
        {
          id: 'Nike',
          children: []
        }
      ]
    },
    {
      id: 'Categories',
      children: [
        {
          id: 'Men',
          children: []
        },
        {
          id: 'Women',
          children: []
        },
        {
          id: 'Boys',
          children: []
        },
        {
          id: 'Girls',
          children: []
        },
        {
          id: 'Sports/Athleisure',
          children: []
        },
        {
          id: 'Casual',
          children: []
        },
        {
          id: 'Formal',
          children: []
        },
        {
          id: 'Open',
          children: []
        },
        {
          id: 'Accessories',
          children: []
        }
      ]
    }
  ];
  drop_Product_TREE_DATA = [];

  workbookName = '';

  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<any>();

  droppedTreeControl = new NestedTreeControl<any>(node => node.children);
  droppedDataSource = new MatTreeNestedDataSource<any>();


  nodes: any[] = this.Time_TREE_DATA;
  dropNodes: any[] = [];

  // ids for connected drop lists
  dropTargetIds = [];
  nodeLookup = {};
  dropActionTodo: any = null;

  selectedNode = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MfpSetupComponent>,
    @Inject(DOCUMENT) private document: Document) {
    this.dataSource.data = this.Time_TREE_DATA;


    this.prepareDragDrop(this.nodes);
  }

  selectNode(node) {
    this.selectedNode = node;
    node && (node.isExpanded = !node.isExpanded);
  }

  add(step = 1) {
    if (!this.selectedNode) return;
    switch (step) {
      case 1:
        this.nodeMovingHandler(this.nodes, this.dropNodes);
        break;
      case 2:
        this.nodeMovingHandler(this.Channel_TREE_DATA, this.drop_Channel_TREE_DATA);
        break;
      case 3:
        this.nodeMovingHandler(this.Product_TREE_DATA, this.drop_Product_TREE_DATA);
        break;
    }
  }

  nodeMovingHandler(mainArray, droppedArray) {
    for (let i = 0; i < mainArray.length; i++) {
      if (mainArray[i].id == this.selectedNode.id) {
        droppedArray.push(mainArray[i]);
        mainArray.splice(i, 1);
        this.selectedNode = null;
        break;
      } else {
        this.nodeMovingHandler(mainArray[i].children, droppedArray);
      }
    }
  }

  addAll(step = 1) {
    switch (step) {
      case 1:
        this.nodes.forEach(n => {
          this.dropNodes.push(n);
        });
        this.nodes = [];
        break;

      case 2:
        this.Channel_TREE_DATA.forEach(n => {
          this.drop_Channel_TREE_DATA.push(n);
        });
        this.Channel_TREE_DATA = [];
        break;

      case 3:
        this.Product_TREE_DATA.forEach(n => {
          this.drop_Product_TREE_DATA.push(n);
        });
        this.Product_TREE_DATA = [];
        break;
    }
  }

  remove(step = 1) {
    if (!this.selectedNode) return;
    switch (step) {
      case 1:
        this.nodeMovingHandler(this.dropNodes, this.nodes);
        break;
      case 2:
        this.nodeMovingHandler(this.drop_Channel_TREE_DATA, this.Channel_TREE_DATA);
        break;
      case 3:
        this.nodeMovingHandler(this.drop_Product_TREE_DATA, this.Product_TREE_DATA);
        break;
    }
  }

  removeAll(step = 1) {
    switch (step) {
      case 1:
        this.dropNodes.forEach(n => {
          this.nodes.push(n);
        });
        this.dropNodes = [];
        break;
      case 2:
        this.drop_Channel_TREE_DATA.forEach(n => {
          this.Channel_TREE_DATA.push(n);
        });
        this.drop_Channel_TREE_DATA = [];
        break;
      case 3:
        this.drop_Product_TREE_DATA.forEach(n => {
          this.Product_TREE_DATA.push(n);
        });
        this.drop_Product_TREE_DATA = [];
        break;
    }
  }

  prepareDragDrop(nodes: any[]) {
    nodes.forEach(node => {
      this.dropTargetIds.push(node.id);
      this.nodeLookup[node.id] = node;
      this.prepareDragDrop(node.children);
    });
  }

  dragMoved(event) {
    setTimeout(() => {
      let e = this.document.elementFromPoint(event.pointerPosition.x, event.pointerPosition.y);

      if (!e) {
        this.clearDragInfo();
        return;
      }
      let container = e.classList.contains("node-item") ? e : e.closest(".node-item");
      if (!container) {
        this.clearDragInfo();
        return;
      }
      this.dropActionTodo = {
        targetId: container.getAttribute("data-id")
      };
      const targetRect = container.getBoundingClientRect();
      const oneThird = targetRect.height / 3;

      if (event.pointerPosition.y - targetRect.top < oneThird) {
        // before
        this.dropActionTodo["action"] = "before";
      } else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
        // after
        this.dropActionTodo["action"] = "after";
      } else {
        // inside
        this.dropActionTodo["action"] = "inside";
      }
      this.showDragInfo();
    }, 50);
  }

  drop(event, type) {
    console.log(this.dropActionTodo.targetId, type);
    if (!this.dropActionTodo) return;

    const draggedItemId = event.item.data;
    const parentItemId = event.previousContainer.id;
    const targetListId = this.getParentNodeId(this.dropActionTodo.targetId, this.nodes, 'main1');

    console.log(
      '\nmoving\n[' + draggedItemId + '] from list [' + parentItemId + ']',
      '\n[' + this.dropActionTodo.action + ']\n[' + this.dropActionTodo.targetId + '] from list [' + targetListId + ']');

    const draggedItem = this.nodeLookup[draggedItemId];

    const oldItemContainer = parentItemId != 'main' ? this.nodeLookup[parentItemId].children : this.nodes;
    const newContainer = targetListId != 'main' ? this.nodeLookup[targetListId].children : this.nodes;

    let i = oldItemContainer.findIndex(c => c.id === draggedItemId);
    oldItemContainer.splice(i, 1);

    switch (this.dropActionTodo.action) {
      case 'before':
      case 'after':
        const targetIndex = newContainer.findIndex(c => c.id === this.dropActionTodo.targetId);
        if (this.dropActionTodo.action == 'before') {
          newContainer.splice(targetIndex, 0, draggedItem);
        } else {
          newContainer.splice(targetIndex + 1, 0, draggedItem);
        }
        break;

      case 'inside':
        this.nodeLookup[this.dropActionTodo.targetId].children.push(draggedItem)
        this.nodeLookup[this.dropActionTodo.targetId].isExpanded = true;
        break;
    }

    this.clearDragInfo(true)
  }

  getParentNodeId(id: string, nodesToSearch: any[], parentId: string): string {
    for (let node of nodesToSearch) {
      if (node.id == id) return parentId;
      let ret = this.getParentNodeId(id, node.children, node.id);
      if (ret) return ret;
    }
    return null;
  }
  
  showDragInfo() {
    this.clearDragInfo();
    if (this.dropActionTodo) {
      this.document.getElementById("node-" + this.dropActionTodo.targetId).classList.add("drop-" + this.dropActionTodo.action);
    }
  }
  clearDragInfo(dropped = false) {
    if (dropped) {
      this.dropActionTodo = null;
    }
    this.document
      .querySelectorAll(".drop-before")
      .forEach(element => element.classList.remove("drop-before"));
    this.document
      .querySelectorAll(".drop-after")
      .forEach(element => element.classList.remove("drop-after"));
    this.document
      .querySelectorAll(".drop-inside")
      .forEach(element => element.classList.remove("drop-inside"));
  }

  ngOnInit(): void {
  }

  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

  next(stepper: MatStepper) {
    stepper.next();
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  create() {
    this.onNoClick();
  }
}
