<div class="">
  <div class="item-search-wrapper">
    <form action="" class="form-fields show-search">
      <div class="form-group">
        <mat-form-field floatLabel="never">
          <input
            matInput
            id="filter-text-box"
            placeholder="Search"
            [(ngModel)]="searchText"
            (ngModelChange)="onFilterTextBoxChanged($event)"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </form>
    <div class="item-search-btn">
      <img src="../../assets/images/add-icon.svg" alt="" />
      <img src="../../assets/images/excel.svg" alt="" />
      <button
        class="btn btn-primary"
        *ngIf="createPermission"
        (click)="openAccountForm(null)"
      >
        Add Account
      </button>
    </div>
  </div>
  <div class="all-show-grid items-wrapper-grid">
    <ag-grid-angular
      class="ag-theme-material"
      [gridOptions]="gridOptions"
      [defaultColDef]="defaultColDef"
      [rowModelType]="rowModelType"
      [pagination]="true"
      [headerHeight]="headerHeight"
      [paginationPageSize]="paginationPageSize"
      [cacheBlockSize]="cacheBlockSize"
      [serverSideStoreType]="serverSideStoreType"
      [columnDefs]="columnDefs"
      (gridReady)="onGridReady($event)"
      [modules]="modules"
      #grid
    >
    </ag-grid-angular>
  </div>
  <app-account-form
    (close)="closePanel()"
    (updateList)="updateList()"
    *ngIf="showAccountForm"
  >
  </app-account-form>
</div>
