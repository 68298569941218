<!-- Main Content area -->
<div *ngIf="!hideScreen" id="fb-forecast" class="container-fluid">
  <div class="btn-wrapper" [hidden]="!isShowTreeDropDowns">
    <div class="form-group">
      <app-treeview-dropdown [type]="'Channel'" [items]="channelDropdownList" (valueChange)="setValue($event)">
      </app-treeview-dropdown>
    </div>
    <div class="form-group">
      <app-treeview-dropdown [type]="'Item'" [items]="productList" (valueChange)="setValue($event)">
      </app-treeview-dropdown>
    </div>
    <div class="form-group">
      <app-treeview-dropdown [type]="'Timeframe'" [items]="calendarListDropdown" (valueChange)="setValue($event)">
      </app-treeview-dropdown>
    </div>
    <div class="form-group" *ngIf="showVersionList">
      <mat-form-field>
        <mat-label>Choose Version</mat-label>
        <mat-select>
          <mat-option *ngFor="let version of versionList" [value]="version.versionTypeId">{{ version.versionTypeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <button (click)="mapGridList(false)" [disabled]="
          itemIds.length == 0 ||
          channelIds.length == 0 ||
          calendarIds.length == 0
        " class="btn btn-primary adjust-btn">
        {{
        itemIds.length == 0 ||
        channelIds.length == 0 ||
        calendarIds.length == 0 ||
        (isBuidlNew && !gridMapped)
        ? "Build"
        : "Adjust"
        }}
      </button>
    </div>
  </div>
  <div [hidden]="(!gridMapped)">
    <mat-accordion class="fb-forecast-accordion">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
          <mat-panel-title>
            Data Manager
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container-fluid item-wrapper fb-forecast-planner" [style.height]="isAnyOpened() ? '50vh' : ''">
          <div class="row">
            <div class="col-md-8 fb-line">
              <h3>Adjust Data Set</h3>
              <form class="row">
                <div class="form-group col-md-6">
                  <label>Product</label>
                  <app-treeview-dropdown *ngIf="isRestoreSession || isBuidlNew" [type]="'Item'" [items]="productList"
                    style="z-index: 1;" (valueChange)="setValue($event)">
                  </app-treeview-dropdown>
                  <input type="text" id="product" *ngIf="isOpenSaved" [value]="productValue" [readOnly]="true">
                </div>
                <div class="form-group col-md-6">
                  <label>Timeframe</label>
                  <app-treeview-dropdown *ngIf="isRestoreSession || isBuidlNew" [type]="'Timeframe'" style="z-index: 1;"
                    [items]="calendarListDropdown" (valueChange)="setValue($event)">
                  </app-treeview-dropdown>
                  <input type="text" id="timeframe" *ngIf="isOpenSaved" [value]="calanderValue" [readOnly]="true">
                </div>
                <div class="form-group col-md-6">
                  <label>Channel</label>
                  <app-treeview-dropdown *ngIf="isRestoreSession || isBuidlNew" [type]="'Channel'"
                    [items]="channelDropdownList" (valueChange)="setValue($event)">
                  </app-treeview-dropdown>
                  <input type="text" id="channel" *ngIf="isOpenSaved" [value]="channelValue" [readOnly]="true">
                </div>
              </form>
              <div class="row">
                <div class="col-12 btn-wrapper">
                  <button class="btn btn-primary" (click)="adjustData()" *ngIf="!isOpenSaved"> {{
                    itemIds.length == 0 ||
                    channelIds.length == 0 ||
                    calendarIds.length == 0

                    ? "Build"
                    : "Adjust"
                    }}</button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <h3>Refresh Measures</h3>
              <form>
                <div class="form-group">
                  <label>Select Measure</label>
                  <mat-select placeholder="Measure type" (selectionChange)="onSelectionChanged($event)"
                    [(ngModel)]="selectedMeasures" [multiple]="true">
                    <mat-option [value]="-1">Select All</mat-option>
                    <mat-option *ngFor="let item of measureList" [value]="item.measureId">{{item.measureName}}
                    </mat-option>
                  </mat-select>
                </div>
              </form>
              <div class="col-12 btn-wrapper">
                <button class="btn btn-primary" style="margin-top: 40px;" (click)="refreshMeasure()">Refresh</button>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="fb-forecast-accordion">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
          <mat-panel-title>
            Method Manager
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container-fluid item-wrapper fb-forecast-planner">
          <div class="row method-planner-top">
            <div class="col-md-6 fb-line">
              <h3>Active Forecast Method (Updated 11:23 AM 1/28/2021)</h3>
              <form>
                <div class="form-group">
                  <label>Forecast Method</label>
                  <input readonly type="text" placeholder="Seasonal Smoothing">
                </div>
                <div class="form-group">
                  <label>Seasonality Source Level</label>
                  <input readonly type="text" placeholder="Subclass/Retailer">
                </div>
                <div class="form-group">
                  <label>Moving Average Weeks</label>
                  <input readonly type="text" placeholder="N/A">
                </div>
              </form>
            </div>
            <div class="col-md-6 fb-line2">
              <h3>Recommended Method</h3>
              <form>
                <div class="form-group">
                  <label>Forecast Method</label>
                  <input readonly type="text" placeholder="Double Exponential Smoothing">
                </div>
                <div class="form-group">
                  <label>Seasonality Source Level</label>
                  <input readonly type="text" placeholder="Subclass/Retailer">
                </div>
                <div class="form-group">
                  <label>Moving Average Weeks</label>
                  <input readonly type="text" placeholder="N/A">
                </div>
              </form>
            </div>
          </div>
          <form class="row method-planner-bottom">
            <div class="form-group col-md-3">
              <label>Forecast Method</label>
              <mat-select>
                <mat-option value="Measure type">Measure type</mat-option>
                <mat-option value="Measure type">Measure type</mat-option>
                <mat-option value="Measure type">Measure type</mat-option>
              </mat-select>
            </div>
            <div class="form-group col-md-3">
              <label>Seasonality Source Level</label>
              <mat-select>
                <mat-option value="Measure type">Measure type</mat-option>
                <mat-option value="Measure type">Measure type</mat-option>
                <mat-option value="Measure type">Measure type</mat-option>
              </mat-select>
            </div>
            <div class="form-group col-md-3">
              <label>Moving Average Weeks</label>
              <mat-select>
                <mat-option value="Measure type">Measure type</mat-option>
                <mat-option value="Measure type">Measure type</mat-option>
                <mat-option value="Measure type">Measure type</mat-option>
              </mat-select>
            </div>
            <div class="col-md-3 btn-wrapper">
              <button class="btn btn-primary">Apply</button>
            </div>
          </form>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <app-fb-forecast-graph *ngIf="leftGroupList.length > 0 && measureList.length > 0 && yearListGrid.length > 0"
      [leftGroupList]="leftGroupList" [yearListGrid]="yearListGrid" [measureList]="measureList"
      [dropdownObjects]="dropdownObjects">
    </app-fb-forecast-graph>

    <app-fb-forecast-grid [yearListGrid]="yearListGrid" *ngIf="
        leftGroupList.length > 0 &&
        measureList.length > 0 &&
        yearListGrid.length > 0
      " [leftGroupList]="leftGroupList" [measureList]="measureList" [dropdownObjects]="dropdownObjects"
      [forecastList]="forecastList" [versionTypeId]="versionTypeId" [calendarList]="timeframeList"
      [selectedProducts]="selectedProducts" [productTree]="productTree" (onClickEvent)="checkIfMaxCountReached()">
    </app-fb-forecast-grid>
  </div>

</div>
<ng-template #saveForecast>
  <h1 mat-dialog-title>What would you like to name your planner?</h1>
  <div mat-dialog-content>
    <mat-form-field appearance="outline">
      <input matInput placeholder="Enter Planner Name" [(ngModel)]="plannerName"
        [ngModelOptions]="{ standalone: true }" />
    </mat-form-field>
  </div>
  <div class="action-panel text-right btn-wrapper">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="ref.close()">
      Cancel
    </button>
    <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="ref.close(true)">
      Save
    </button>
  </div>
</ng-template>
<ng-template #commitForecast>
  <div mat-dialog-content>
    <p>Are you sure you want to commit your changes?</p>
  </div>
  <div class="action-panel text-right btn-wrapper">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="ref.close()">
      No
    </button>
    <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="commitFacebookPlanner()">
      Yes
    </button>
  </div>
</ng-template>
