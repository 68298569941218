import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AllCommunityModules, CellClickedEvent } from '@ag-grid-community/all-modules';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { rowDataBound } from '@syncfusion/ej2-angular-treegrid';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ViewRendererComponent } from 'src/common/view-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { FirebaseService } from 'src/services/firebase.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-dynamic-simulation',
  templateUrl: './dynamic-simulation.component.html',
  styleUrls: ['./dynamic-simulation.component.css']
})
export class DynamicSimulationComponent implements OnInit {
  @ViewChild('ExcelImport') excelImport: TemplateRef<any>;
  @ViewChild('dialog') dialogTemplate: TemplateRef<any>;
  public categoryList = [];
  public category;
  public files = [];
  public classList = [{ classCode: 1, classCodeDescription: 'TV' }, { classCode: 2, classCodeDescription: 'Theatrical' }];
  public subClassList = [
    { subClassCode: 1, subClassCodeDescription: 'DVD' },
    { subClassCode: 2, subClassCodeDescription: 'BD' },
    { subClassCode: 3, subClassCodeDescription: '3D' },
    { subClassCode: 4, subClassCodeDescription: 'UHD' }
  ];
  public columnDefs = [
    {
      headerName: 'Date',
      show: true,
      field: 'Date',
      cellRenderer: params => {
        return params.value ? params.value.toLocaleDateString() : '';
      }
    },
    {
      headerName: 'Days of Week',
      show: true,
      field: 'daysOfWeek'
    },
    {
      headerName: 'Day Type',
      show: true,
      field: 'dayType',
    },
    {
      headerName: 'Sale',
      show: true,
      field: 'SalesQTY',
    },
    {
      headerName: 'Inventory',
      show: true,
      field: 'TotalSOH',
    },
    {
      headerName: 'Sal Val',
      show: true,
      field: 'SalesRevenueConsumer',
    },
    {
      headerName: 'Sale Loss',
      show: true,
      field: 'SalesLoss',
    },
    {
      headerName: 'Sale Loss Value',
      show: true,
      field: 'SalesLossValue',
    },
    {
      headerName: 'Initial Opening Onhand',
      show: true,
      field: 'Onhand',
    },
    {
      headerName: 'Initial Est. Supply',
      show: true,
      field: 'Shipments',
    },
    {
      headerName: 'Simulated Opening onhand',
      show: true,
      field: 'SimOpeningOnhand',
    },
    {
      headerName: 'Simulated Supply',
      show: true,
      field: 'SimSupply',
    },
    {
      headerName: 'Simulated Add. Sales',
      show: true,
      field: 'SimAddSales',
    },
    {
      headerName: 'Simulated Add. Sales With Price +/-',
      show: true,
      field: 'simulatedAdditionSalePrice',
    },
    {
      headerName: 'Total Estimated Sales',
      show: true,
      field: 'TotalEstSales'
    },
    {
      headerName: 'Simulated Closing Onhand',
      show: true,
      field: 'SimClosingOnhand',
    },
  ];
  public selectedDiscountIncrease = -10;
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public ItemMasterList = [];
  public modules = AllCommunityModules;
  public user: any;
  public selectAll = false;
  startDate;
  endDate;
  productGroupList = ['Surface Computing'];
  businessUnitList = [
    'Surface Book 2 13in',
    'Surface Book 2 15in',
    'Surface Go',
    'Surface Laptop 2',
    'Surface Pro 5',
    'Surface Pro 6',
    'Surface Pro 7'
  ];
  retailKeyCodeList = [
    '6145601',
    '6145605',
    '6145606',
    '6181705',
    '6261393',
    '6261397',
    '6298021',
    '6298022',
    '6298032',
    '6298137',
    '6298140',
    '6298148',
    '6305982',
    '6306014',
    '6333202',
    '6347209',
    '6347210',
    '6374985',
    '6374987',
    '6374993',
  ];
  selectedProductGroup;
  selectedBusinessUnit;
  seletedRetailKeyCode;
  storeNumber;
  percentageList = [
    'Actual',
    '25%',
    '50%',
    '60%',
    '70%',
    '80%',
    '0.9'
  ];
  arrayList = {};
  selectedPercentage;
  file;
  chartDatasets = [];
  simulatedChartDatasets = [];
  barChartDatasets = [];
  simulatedBarChartDatasets = [];
  xAxis = [];
  yAxis = [];
  xAxisSimulated = [];
  view = [700, 700];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  legendTitle = 'Legend';
  legendPosition = 'right';
  showXAxisLabel = true;
  xAxisLabel = 'Sales QTY';
  showYAxisLabel = true;
  yAxisLabel = 'Time Period';
  showGridLines = true;
  innerPadding = '10%';
  animations: boolean = true;
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5']
  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#7aa3e5']
  };

  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = '';
  constructor(public router: Router,
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<any>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public firebase: FirebaseService) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent,
        nameRenderer: NameRendererComponent,
        viewRenderer: ViewRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
  }
  public openExcelFile = (value) => {
    switch (value) {
      case 'Actual': {
        if (!this.arrayList['Actual']) {
          this.firebase.getSimulationsExcel('Actual').subscribe(res => {
            this.loadFile(res, value);
          });
        } else {
          this.ItemMasterList = this.arrayList['Actual'];
          this.calculateColums();
        }
        break;
      }
      case '25%': {
        if (!this.arrayList['25%']) {
          this.firebase.getSimulationsExcel('25Percent').subscribe(res => {
            this.loadFile(res, value);
          });
        } else {
          this.ItemMasterList = this.arrayList['25Percent'];
          this.calculateColums();
        }
        break;
      }
      case '50%': {
        if (!this.arrayList['50%']) {
          this.firebase.getSimulationsExcel('50Percent').subscribe(res => {
            this.loadFile(res, value);
          });
        } else {
          this.ItemMasterList = this.arrayList['50Percent'];
          this.calculateColums();
        }
        break;
      }
      case '60%': {
        if (!this.arrayList['60%']) {
          this.firebase.getSimulationsExcel('60Percent').subscribe(res => {
            this.loadFile(res, value);
          });
        } else {
          this.ItemMasterList = this.arrayList['60%'];
          this.calculateColums();
        }
        break;
      }
      case '70%': {
        if (!this.arrayList['70%']) {
          this.firebase.getSimulationsExcel('70Percent').subscribe(res => {
            this.loadFile(res, value);
          });
        } else {
          this.ItemMasterList = this.arrayList['70%'];
          this.calculateColums();
        }
        break;
      }
      case '80%': {
        if (!this.arrayList['80%']) {
          this.firebase.getSimulationsExcel('80Percent').subscribe(res => {
            this.loadFile(res, value);
          });
        } else {
          this.ItemMasterList = this.arrayList['80%'];
          this.calculateColums();
        }
        break;
      }
      case '0.9': {
        if (!this.arrayList['0.9']) {
          this.firebase.getSimulationsExcel('PointNine').subscribe(res => {
            this.loadFile(res, value);
          });
        } else {
          this.ItemMasterList = this.arrayList['0.9'];
          this.calculateColums();
        }
        break;
      }
    }

  }
  public calculateColums = () => {
    this.ItemMasterList = this.ItemMasterList.map(row => ({
      ...row,
      simulatedAdditionSalePrice: this.mapColumnValue(row)
    }));
    this.chartDatasets = [{
      name: '',
      series: this.ItemMasterList.map(row => ({
        name: row.Date.toLocaleDateString(), value: row.SalesQTY ? row.SalesQTY : 0
      }))
    }];
    this.simulatedChartDatasets = [{
      name: '',
      series: this.ItemMasterList.map(row => ({
        name: row.Date.toLocaleDateString(), value: row.SimAddSales ? row.SimAddSales : 0
      }))
    }];
    this.xAxis = this.ItemMasterList.map(row => row.SalesQTY);
    this.yAxis = this.ItemMasterList.map(row => row.Date);
    this.xAxisSimulated = this.ItemMasterList.map(row => row.SalesQTY);
    this.chartDatasets = [
      {
        name: 'Inital Sale QTY',
        series:  this.ItemMasterList.map(row => ({name: row.Date, value: row.Onhand}))
      },
      {
        name: 'Initial Shipment QTY',
        series:  this.ItemMasterList.map(row => ({name: row.Date, value: row.Shipments}))
      },
    ];
    this.barChartDatasets = this.ItemMasterList.map(row => ({name: row.Date, value: row.SalesQTY}))
  }
  public mapColumnValue = (row) => {
    let calculatedValue = 0;
    if (this.selectedDiscountIncrease === 0) {
      calculatedValue = 0;
    } else if (this.selectedDiscountIncrease < 0) {
      calculatedValue = (row.SimAddSales * (1 + (2 * Math.abs(this.selectedDiscountIncrease)))) - row.SimAddSales;
    } else if (this.selectedDiscountIncrease > 0) {
      calculatedValue = (row.SimAddSales * (1 - (2 * Math.abs(this.selectedDiscountIncrease)))) - row.SimAddSales;
    }
    return calculatedValue;
  }
  filterChange = () => {
    let filteredData = [...this.ItemMasterList];
    if (this.startDate && this.endDate) {
      filteredData = filteredData.filter(row => {
        return ((row.Date > this.startDate) && (row.Date <= this.endDate));
      })
    }
    if (this.selectedProductGroup) {
      filteredData = filteredData.filter(row => {
        return row.ProductGrouping === this.selectedProductGroup;
      })
    }
    if (this.selectedBusinessUnit) {
      filteredData = filteredData.filter(row => {
        return row.BusinessUnit === this.selectedBusinessUnit;
      })
    }
    if (this.seletedRetailKeyCode && this.seletedRetailKeyCode.length > 0) {
      filteredData = filteredData.filter(row => {
        return this.seletedRetailKeyCode.includes(row.RetailKeyCode.toString());
      })
    }
    if (this.seletedRetailKeyCode && this.seletedRetailKeyCode.length < 1 && !this.startDate && !this.endDate && !this.selectedBusinessUnit) {
      this.gridOptions.api.setRowData(this.ItemMasterList);
    } else {
      this.gridOptions.api.setRowData(filteredData);
    }
    this.chartDatasets = [
      {
        name: 'Inital Sale QTY',
        series:  this.ItemMasterList.map(row => ({name: row.Date, value: row.Onhand}))
      },
      {
        name: 'Initial Shipment QTY',
        series:  this.ItemMasterList.map(row => ({name: row.Date, value: row.Shipments}))
      },
    ];
    this.barChartDatasets = this.ItemMasterList.map(row => ({name: row.Date, value: row.SalesQTY}))
    // this.barChartDatasets = this.ItemMasterList.map(row => ({ value: row.Date, name: row.SalesQTY }));
    this.simulatedBarChartDatasets = this.ItemMasterList.map(row => ({ value: row.Date, name: row.SimAddSales }));
    this.xAxis = this.ItemMasterList.map(row => row.SalesQTY);
    this.yAxis = this.ItemMasterList.map(row => row.Date);
    this.xAxisSimulated = this.ItemMasterList.map(row => row.SalesQTY);
  }
  loadFile(res, value) {

    /* wire up file reader */
    this.spinner.show();
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(res);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary', cellDates: true });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      this.ItemMasterList = data;
      this.arrayList[value] = data;
      // for (let i = 0; i < this.ItemMasterList.length; i++) {
      //   if (this.ItemMasterList[i]['']) {
      //     this.ItemMasterList[i][''] =
      //   }
      // }
      this.ItemMasterList = this.ItemMasterList.map(row => ({
        ...row,
        daysOfWeek: this.getWeekDay(row.Date),
        dayType: this.getDayType(this.getWeekDay(row.Date))
      }));
      this.calculateColums();
      this.spinner.hide();
    };
  }
  getDayType(day) {
    if (day === 'Sunday' || day === 'Saturday') {
      return 'Weekend';
    } else {
      return 'Weekday'
    }
  }
  getWeekDay(date) {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var dayName = days[date.getDay()];
    return dayName;
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  // onCellClicked($event: CellClickedEvent) {
  //   if ($event && $event.colDef.field === 'itemDesciption') {
  //     this.openModal($event.data);
  //   }
  // }
  openFullScreenMode() {
    const domGrid = document.getElementById('chart') as any;
    if (domGrid) {
      domGrid.webkitRequestFullscreen();
    }
  }
  selectAllSelected(flag: boolean): void {
    if (flag) {
      this.columnDefs.forEach(column => column.show = true);
    } else {
      this.columnDefs.forEach(column => column.show = false);
    }
  }
  public onSave = () => {
    const newColumnDefs = this.columnDefs.filter(column => column.show);
    this.gridOptions.api.setColumnDefs(newColumnDefs);
    this.matDialogRef.close();
  }
  openDialog(data, templateRef?: TemplateRef<any>): void {
    this.matDialogRef = this.dialog.open(this.dialogTemplate, { width: '500px', data: data || {} });
  }
}
