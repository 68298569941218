export class Promotion{
  name : string
  startDate : string
  endDate :string
  forecastingMethodId :string
  marginCredit :string
  salePrice :string
  productId :string
  accountIds : string
  promotionProductAccount: any =  []
  description :  string
  accountName:any = [];
  upcCodelist
  cost:string
  active
  product
  promoNumber
  promoEventType
  miscEventDetails
  offerType
  programName
  status
  promocomments
  discountCost
  msrp
  currency
  discountPercent
  activeAccount
  stackable
}
