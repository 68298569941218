import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PromotionFormComponent } from '../promotion-form/promotion-form.component';
import { CellClickedEvent } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';

@Component({
  selector: 'app-promotion-listing',
  templateUrl: './promotion-listing.component.html',
  styleUrls: ['./promotion-listing.component.css']
})
export class PromotionListingComponent implements OnInit {
  public fundTypeList = [{ fundTypeId: 1, fundTypeName: 'Promo' }, { fundTypeId: 2, fundTypeName: 'Base' }];
  public columnDefs = [
    {
      headerName: 'Promotion Name',
      field: 'promotionName',
      cellRenderer: (params) => {
        return `<a href="javascript:void(0)" style="color: #232323">${params.value}</a>`;
      },
    },
    {
      headerName: 'Customers',
      field: 'customers'
    },
    // {
    //   headerName: 'Planning Month',
    //   field: 'planningMonth'
    // },
    {
      headerName: 'Start Date',
      field: 'startDate',
      cellRenderer: (params) => {
        return `${params.value ? (new Date(params.value)).toLocaleDateString() : ''}`;
      }
    },
    {
      headerName: 'End Date',
      field: 'endDate',
      cellRenderer: (params) => {
        return `${params.value ? (new Date(params.value)).toLocaleDateString() : ''}`;
      }
    },
    {
      headerName: 'Update RMF',
      field: 'updateRMF'
    },
    {
      headerName: 'Return Rate',
      field: 'returnRate'
    },
    {
      headerName: 'Shipment Offset Weeks',
      field: 'shipmentOffsetWeeks'
    },
    {
      headerName: 'Return Offset Weeks',
      field: 'returnOffsetWeeks'
    },
    {
      headerName: 'Classes',
      field: 'classes'
    },
    {
      headerName: 'SubClasses',
      field: 'subClasses'
    },
    {
      headerName: 'Promotion Description',
      field: 'promotionDescription'
    },
    {
      headerName: 'Primary Placement',
      field: 'primaryPlacement',
      cellRenderer: (params) => {
        return `${params.value ? 'Yes' : 'No'}`;
      }
    },
    {
      headerName: 'Secondary Placement',
      field: 'secondaryPlacement',
      cellRenderer: (params) => {
        return `${params.value ? 'Yes' : 'No'}`;
      }
    },
    {
      headerName: 'Fund Type',
      field: 'fundTypeId',
      cellRenderer: (params) => {
        const fund = this.fundTypeList.find(fundType => params.value === fundType.fundTypeId);
        return `${fund ? fund.fundTypeName : ''}`;
      }
    },
    {
      headerName: 'Consumer Promotion',
      field: 'consumerPromotion'
    },
    {
      headerName: 'Advertisement Description',
      field: 'advertisementDescription'
    },
    {
      headerName: 'Category Strategy',
      field: 'categoryStrategy'
    },
    {
      headerName: '',
      field: 'delete',
      resizable: true,
      filter: false,
      pinned: 'right',
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveGoalDialog.bind(this),
      },
      width: 80
    },
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public promotionList = [];
  public modules = AllCommunityModules;
  public user: any;
  constructor(
    public router: Router,
    public userService: UsersService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PromotionFormComponent>,
    public configurationService: ConfigurationService,
    public matDialogRef: MatDialogRef<any>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    this.configurationService.promotionListSubject$.subscribe(res => {
      this.populateList();
    });
    this.populateList();
  }
  public populateList = () => {
    this.configurationService.PromotionGetAll({ active: true }).subscribe(res => {
      this.promotionList = res;
    });
  }
  public getRoleNames = (roleList: any) => {
    if (roleList && roleList.length > 0) {
      let names = '';
      roleList.forEach(element => {
        names = `${names}, ${element.roleName}`;
      });
      names = names.split(',').splice(1).join(',');
      return names || '';
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.promotionName }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeSKU(row.rowData);
      }
    });
  }
  public removeSKU(selectedItem: any) {
    if (selectedItem) {
      const model = {
        ...selectedItem,
        active: false
      };
      this.spinner.show();
      this.configurationService.PromotionActivate(model).subscribe(res => {
        if (res) {
          this.spinner.hide();
          this.toastr.success('Promotion', 'Promotion removed successfully');
          this.promotionList = this.promotionList.filter(row => row.promotionId !== model.promotionId);
          this.gridOptions.api.setRowData(this.promotionList);
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('Promotion', error.error.text);
      });
    }
  }
  onCellClicked($event: CellClickedEvent) {
    if ($event && $event.colDef.field === 'promotionName') {
      const selector = {
        menuId: Math.random(),
        name: 'Create Promotion',
        selector: 'app-promotion-form',
        displayName: 'Create Promotion',
        data: {}
      };
      selector.data = { isEdit: true, ...$event.data };
      this.configurationService.menuRendererSubject.next(selector);
    }
  }
  public openDialog = () => {
    const selector = {
      menuId: '451',
      name: 'Create Promotion',
      selector: 'app-promotion-form',
      displayName: 'Create Promotion',
      data: {}
    };

    this.configurationService.menuRendererSubject.next(selector);
    // this.dialogRef = this.dialog.open(PromotionFormComponent, { width: `${window.innerWidth}px`, height: `${window.innerHeight > 50 ? window.innerHeight - 50 : window.innerHeight}px`, panelClass: 'my-dialog' });
  }
}
