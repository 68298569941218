<div mat-dialog-content>
  <!-- <mat-form-field>
    <input matInput [(ngModel)]="goalType" placeholder="Enter Goal Name">
  </mat-form-field> -->
  <mat-form-field>
    <mat-select placeholder="Goal Type" [(ngModel)]="goalType">
      <mat-option *ngFor="let res of goalTypeList; let i = index" [value]="res">
        {{res.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="action-panel text-right">
  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit()">Save</button>
  <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="onNoClick()">Cancel</button>
</div>

