import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { USER_ID } from '../../../../../common/keys';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { LocalstorageService } from '../../../../../services/localstorage.service';
import { FBMeasureProfile } from './models/fb-measure-profile.model';

@Component({
  selector: 'app-forecast-measures',
  templateUrl: './forecast-measures.component.html',
  styleUrls: ['./forecast-measures.component.scss']
})
export class ForecastMeasuresComponent implements OnInit {
  savedProfiles: FBMeasureProfile[]
  selectedProfile: FBMeasureProfile
  maxProfileCount = 20;
  constructor(
    public dialogRef: MatDialogRef<ForecastMeasuresComponent>,
    public spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any, public config: ConfigurationService, public storage: LocalstorageService) {

  }

  ngOnInit() {
    this.getPrfiles()
  }
  getPrfiles() {
    let params = {
      UserId: this.storage.get(USER_ID),
    }
    this.spinner.show()
    this.config.FbForecastGetMeasureProfileList(params).subscribe(response => {
      this.savedProfiles = response
      this.spinner.hide()
      if (this.savedProfiles.length > 0) {
        const appliedProfile = this.savedProfiles.find(el => el.applied);
        this.selectedProfile = appliedProfile ? appliedProfile : this.savedProfiles[0]
        this.selectedProfile.checked = true;
        this.data.measureList.forEach(element => {
          if (this.selectedProfile.measureIds.indexOf(element.measureId.toString()) > -1)
            element.checked = true
          else
            element.checked = false
        })
      }
      else {
        this.data.measureList.forEach(element => {
          element.checked = false
        });
      }
    })

  }
  createProfile() {

    if (this.savedProfiles.length >= this.maxProfileCount) {
      this.data.maxCountReached = true
    } else {
      this.data.isCreateProfile = true
      this.data.isFirst = this.savedProfiles.length == 0
    }
    this.dialogRef.close(this.data)
  }
  profileChanged(event) {
    this.savedProfiles.forEach(res => {
      if (res !== event) {
        res.checked = false;
      } else {
        event.checked = true;
      }
    });
    this.selectedProfile = event;
    this.data.measureList.forEach(element => {
      if (event.measureIds.indexOf(element.measureId.toString()) > -1)
        element.checked = true
      else
        element.checked = false
    });
  }
  applyMeasure() {
    let model = {
      fbMeasureProfileId: this.selectedProfile.fbMeasureProfileId,
      active: true,
      applied: true,
      currentUserId: this.storage.get(USER_ID)
    }
    this.spinner.show()
    this.config.FbForecastActivateMeasureProfile(model).subscribe(resp => {
      this.data.profileApplied = true
      this.data.selectedProfile = this.selectedProfile
      this.dialogRef.close(this.data)
      this.spinner.hide()
    })

  }
  updateMeasure() {
    let selectedMeasures = this.data.measureList.filter(x => x.checked)
    let measureIds = ''
    selectedMeasures.forEach(element => {
      measureIds = measureIds + element.measureId + ','

    });
    measureIds = measureIds.slice(0, -1)
    this.selectedProfile.measureIds = measureIds
    let model = {
      ...this.selectedProfile,
      "userId": this.storage.get(USER_ID)
    }
    this.spinner.show()
    this.config.FbForecastUpdateMeasureProfile(model).subscribe(resp => {
      this.spinner.hide()
    })
  }

}
