import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Component, OnInit } from '@angular/core';
import { AgGridLineChartCellRenderer } from '../../ag-grid/ag-grid-line-chart-cell-renderer.component';
import { generateGridColumns, paginationPageSize, rowSelection } from '../../ag-grid/gridOptions';
import { orderingStatusData } from '../../MockData';

@Component({
  selector: 'app-replenishment-ordering-status',
  templateUrl: './ordering-status.component.html',
  styleUrls: ['./ordering-status.component.scss']
})
export class OrderingStatusComponent implements OnInit {

  public gridOptions: any;
  paginationPageSize: number;
  rowModelType: string;
  gridData: any[];
  columnDefs: any[];
  rowSelection: string;

  modules = AllModules;

  constructor() { }

  ngOnInit(): void {
    this.rowModelType = 'clientSide';
    this.paginationPageSize = paginationPageSize;
    this.gridOptions = {
      frameworkComponents: {
        agGridLineChartCellRenderer: AgGridLineChartCellRenderer
      },
      pagination: true,
      paginationPageSize: this.paginationPageSize,
    };
    this.rowSelection = rowSelection;
    this.gridData = orderingStatusData;
    const keys = Object.keys(this.gridData[0]);
    this.columnDefs = generateGridColumns(keys);
    this.gridData[0]['color'] = '#F8486E';
    this.gridData[1]['color'] = '#F8486E';
    this.gridData[2]['color'] = '#FF7C80';
    this.gridData[3]['color'] = '#FF7C80';
    this.gridData[4]['color'] = '#FF7C80';
    this.gridData[5]['color'] = '#FF7C80';
    this.gridData[6]['color'] = '#43E8A8';
    this.gridData[7]['color'] = '#43E8A8';
  }

}
