
<app-indirect-inventory-planner-graph
*ngIf="masterMeasureList.length > 0 && gridListUI.length > 0
"
[leftGroupList]="grid"
[yearListGrid]="gridData"
[measureList]="masterMeasureList"
[dropdownObjects]="dropdownObjects"
>
</app-indirect-inventory-planner-graph>
<mat-accordion class="fb-forecast-accordion item-wrapper">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
      <mat-panel-title> Distributor View </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="data-wrapper btn-wrapper direct-wrapper">
      <img src="assets/images/graph.svg" alt="" (click)="openMesuresDialog()" />
      <button class="btn btn-primary" (click)="selectionChanged(false)">
        Calculate
      </button>
      <mat-select
        [(ngModel)]="exportOptions"
        placeholder="Export/Import"
        class="form-control"
      >
        <mat-option [value]="null">Export/Import</mat-option>
        <mat-option [value]="null" (click)="export()"
          >Export Current View</mat-option
        >
        <mat-option [value]="null" (click)="openExportDialog()"
          >Export</mat-option
        >
        <mat-option [value]="null" *ngIf="editPermission" (click)="import()"
          >Import</mat-option
        >
      </mat-select>
      <button class="btn btn-primary" (click)="mapGridList(false)">
        Save or Commit
      </button>
    </div>

    <div class="container-fluid" id="fb-forecast-grid">
      <div>
        <table
          *ngIf="yearListGrid && yearListGrid.length > 0"
          id="forcast-grid"
          class="table"
        >
          <tbody>
            <td style="padding: 0px !important">
              <tr class="td-padding-none">
                <td>
                  <tr style="height: 45px !important; margin-bottom: 2px"></tr>
                  <ng-container *ngFor="let label of DistiParentMeasures">
                    <tr
                      [attr.rowspan]="label.rowspan"
                      style="
                        writing-mode: vertical-rl;
                        text-orientation: mixed;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        font-size: large;
                      "
                      [ngStyle]="{
                        background: label.background,
                        height: label.rowspan * 42 + 'px'
                      }"
                      *ngIf="label.rowspan > 0"
                    >
                      <label
                        [ngStyle]="{
                          height: label.rowspan * 42 + 'px'
                        }"
                        style="
                          padding-right: 12px;
                          color: #fff;
                          min-width: 50px;
                          max-width: 50px;
                          text-align: center;
                          font-size: 14px;
                          font-weight: bold;
                          transform: rotate(180deg);
                        "
                        >&nbsp;</label
                      >
                    </tr>
                    <tr style="height: 40px !important; margin-bottom: 2px">
                      <label style="height: 40px !important; margin-bottom: 2px"
                        >&nbsp;</label
                      >
                    </tr>
                  </ng-container>
                </td>
              </tr>
            </td>
            <td style="padding: 0px !important">
              <tr class="td-padding">
                <td>
                  <tr
                    style="
                      height: 45px !important;
                      display: flex;
                      align-items: center;
                      margin-left: -50px;
                    "
                  >
                    <app-fb-forecast-scrolling
                      [isProduct]="true"
                      (selectionChange)="selectionChanged($event)"
                      [productList]="selectedProducts"
                      [productTree]="productTree"
                      [fbTemplateId] = "fbTemplateId"
                    >
                    </app-fb-forecast-scrolling>
                    <app-fb-forecast-scrolling
                      [isChannel]="true"
                      (selectionChange)="selectionChanged($event)"
                      [productList]="selectedChannel"
                      [productTree]="productTree"
                      [fbTemplateId] = "fbTemplateId"
                      [selectedDistibutor] = "selectedDistibutor"
                    >
                    </app-fb-forecast-scrolling>
                  </tr>
                  <ng-container
                    *ngFor="let label of DistiMeasures; let i = index"
                  >
                    <tr
                      [ngStyle]="{ background: 'rgb(238, 238, 238)' }"
                      *ngIf="label.checked"
                      style="
                        height: 40px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 2px;
                        padding-left: 15px;
                        margin-right: 2px;
                      "
                    >
                      <label
                        *ngIf="!label.isDropdown"
                        style="min-width: 300px; max-width: 300px"
                        >{{ label.measureName }}</label
                      >
                      <mat-select
                        [(ngModel)]="label.measureName"
                        [ngModelOptions]="{ standalone: true }"
                        style="height: 40px"
                        style="min-width: 300px; max-width: 300px"
                        *ngIf="label.isDropdown"
                      >
                        <mat-option
                          class="not-empty-select"
                          *ngFor="let res of methodList; let i = index"
                          [value]="res"
                        >
                          {{ res }}
                        </mat-option>
                      </mat-select>
                    </tr>
                    <tr
                      *ngIf="checkIfToShow(i,DistiMeasures)"
                      style="height: 40px !important; margin-bottom: 2px"
                    >
                      <label
                        [id]="i"
                        style="height: 40px !important; margin-bottom: 2px"
                        >&nbsp;</label
                      >
                    </tr>
                  </ng-container>
                </td>
              </tr>
            </td>
            <td (scroll) = "applyScrollToOther('distiRows')" id="distiRows"
              style="overflow-x: scroll; width: 100%; padding: 0px !important"
              [ngStyle]="{ 'max-width': getWidth() }"
            >
              <tr class="td-padding">
                <td
                  *ngFor="let year of gridData; let j = index"
                  style="text-align: center"
                  align="center"
                >
                  <tr style="height: 45px; display: block">
                    <mat-select
                      [placeholder]="year.title"
                      #select
                      (selectionChange)="showValues($event, year, select)"
                    >
                      <mat-select-trigger>
                        {{ year.title }}
                      </mat-select-trigger>
                      <mat-option
                        *ngFor="let option of timeframeList"
                        [value]="option.headerName"
                        >{{ option.headerName }}
                      </mat-option>
                    </mat-select>
                  </tr>
                  <ng-container>
                    <td class="input-fields" style="text-align: center">
                      <!-- Algo -->
                      <ng-container
                        *ngFor="let measure of DistiMeasures; let i = index"
                      >
                        <tr
                          [ngStyle]="{ background: '#eee' }"
                          *ngIf="checkIfInList(measure.code)"
                        >
                          <input
                          draggable="false"
                          #field
                          id="{{ j }}{{ measure.code }}{{i}}"
                            type="text"
                            title="{{measure.code}}"
                            [disabled]="
                              measure.isDisti === 1 || !editPermission || !year.isWeek
                            "
                            [ngClass]="{
                              'disabled-input':
                                measure.isDisti === 1 || !editPermission || !year.isWeek
                            }"
                            [(ngModel)]="year['riDistiMeasure'][measure.code]"
                            (keydown.tab)="setFocus(j, measure.code, i);$event.preventDefault()"
                            (change)="
                              valueChange(
                                year['riDistiMeasure'][measure.code],
                                year,
                                measure.code,
                                'riDistiMeasure'
                              )
                            "
                            (ngModelChange)="
                            calculateForecastMeasures(measure.code, year, 'riDistiMeasure');
                              addCommas(year['riDistiMeasure'], measure.code)
                            "
                            (keydown)="onPaste($event, year, measure.code, 'riDistiMeasure')"
                            (keydown)="onKeyDown($event, measure.code,year)"
                            [ngModelOptions]="{ standalone: true }"
                            (mouseover) = "checkIfMousedown($event, year, measure.code, field)"
                            (mousedown) = "preventMouseDown($event, year, measure.code, field)"
                            (click) = "field.focus()"
                            (focus) = "field.select()"
                             (input)="onInput()"
                          />
                        </tr>
                        <tr
                          *ngIf="checkIfToShow(i,DistiMeasures)"
                          style="height: 40px !important; margin-bottom: 2px"
                        >
                          <label
                            [id]="i"
                            style="height: 40px !important; margin-bottom: 2px"
                            >&nbsp;</label
                          >
                        </tr>
                      </ng-container>
                    </td>
                  </ng-container>
                </td>
              </tr>
            </td>
          </tbody>
        </table>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<mat-accordion *ngIf = "!loading" class="fb-forecast-accordion item-wrapper">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
      <mat-panel-title> Account View </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor = "let account of selectedAccounts; let accIndex=index" class="container-fluid" id="fb-forecast-grid">
      <div>
        <table
          *ngIf="yearListGrid && yearListGrid.length > 0"
          id="forcast-grid"
          class="table"
        >
          <tbody>
            <td style="padding: 0px !important">
              <tr class="td-padding-none">
                <td>
                  <tr style="height: 45px !important; margin-bottom: 2px"></tr>
                  <ng-container *ngFor="let label of AccountParentMeasures">
                    <tr
                      [attr.rowspan]="label.rowspan"
                      style="
                        writing-mode: vertical-rl;
                        text-orientation: mixed;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        font-size: large;
                      "
                      [ngStyle]="{
                        background: label.background,
                        height: label.rowspan * 42 + 'px'
                      }"
                      *ngIf="label.rowspan > 0"
                    >
                      <label
                        [ngStyle]="{
                          height: label.rowspan * 42 + 'px'
                        }"
                        style="
                          padding-right: 12px;
                          color: #fff;
                          min-width: 50px;
                          max-width: 50px;
                          text-align: center;
                          font-size: 14px;
                          font-weight: bold;
                          transform: rotate(180deg);
                        "
                        >&nbsp;</label
                      >
                    </tr>
                    <tr style="height: 40px !important; margin-bottom: 2px">
                      <label style="height: 40px !important; margin-bottom: 2px"
                        >&nbsp;</label
                      >
                    </tr>
                  </ng-container>
                </td>
              </tr>
            </td>
            <td style="padding: 0px !important">
              <tr class="td-padding">
                <td>
                  <tr
                    style="
                      height: 45px !important;
                      display: flex;
                      align-items: center;
                      margin-left: -50px;
                    "
                  >
                    <app-fb-forecast-scrolling
                      [isProduct]="true"
                      (selectionChange)="selectionChanged($event)"
                      [productList]="selectedProducts"
                      [productTree]="productTree"
                      [fbTemplateId] = "fbTemplateId"
                      [selectedProduct] = "selectedProduct || dropdownObjects.itemIds"
                    >
                    </app-fb-forecast-scrolling>
                    <app-fb-forecast-scrolling
                      [isChannel]="true"
                      (selectionChange)="selectionChanged($event)"
                      [productList]="selectedChannel"
                      [productTree]="productTree"
                      [fbTemplateId] = "fbTemplateId"
                      [currentAccount] = "account"
                    >
                    </app-fb-forecast-scrolling>
                  </tr>
                  <ng-container
                    *ngFor="let label of AccountMeasures; let i = index"
                  >
                    <tr
                      [ngStyle]="{ background: 'rgb(238, 238, 238)' }"
                      *ngIf="label.checked"
                      style="
                        height: 40px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 2px;
                        padding-left: 15px;
                        margin-right: 2px;
                      "
                    >
                      <label
                        *ngIf="!label.isDropdown"
                        style="min-width: 300px; max-width: 300px"
                        >{{ label.measureName }}</label
                      >
                      <mat-select
                        [(ngModel)]="label.measureName"
                        [ngModelOptions]="{ standalone: true }"
                        style="height: 40px"
                        style="min-width: 300px; max-width: 300px"
                        *ngIf="label.isDropdown"
                      >
                        <mat-option
                          class="not-empty-select"
                          *ngFor="let res of methodList; let i = index"
                          [value]="res"
                        >
                          {{ res }}
                        </mat-option>
                      </mat-select>
                    </tr>
                    <tr
                      *ngIf="checkIfToShow(i,AccountMeasures)"
                      style="height: 40px !important; margin-bottom: 2px"
                    >
                      <label
                        [id]="i"
                        style="height: 40px !important; margin-bottom: 2px"
                        >&nbsp;</label
                      >
                    </tr>
                  </ng-container>
                </td>
              </tr>
            </td>
            <td (scroll) = "applyScrollToOther('accountRows'+accIndex)" id="accountRows{{accIndex}}"
              style="overflow-x: scroll; width: 100%; padding: 0px !important"
              [ngStyle]="{ 'max-width': getWidth() }"
            >
              <tr class="td-padding">
                <td
                  *ngFor="let year of gridData; let j = index"
                  style="text-align: center"
                  align="center"
                >
                  <tr style="height: 45px; display: block">
                    <mat-select
                      [placeholder]="year.title"
                      #select
                      (selectionChange)="showValues($event, year, select)"
                    >
                      <mat-select-trigger>
                        {{ year.title }}
                      </mat-select-trigger>
                      <mat-option
                        *ngFor="let option of timeframeList"
                        [value]="option.headerName"
                        >{{ option.headerName }}
                      </mat-option>
                    </mat-select>
                  </tr>
                  <ng-container>
                    <td class="input-fields" style="text-align: center">
                      <ng-container
                        *ngFor="let measure of AccountMeasures; let i = index"
                      >
                        <tr
                          [ngStyle]="{ background: '#eee' }"
                          *ngIf="checkIfInList(measure.code)"
                        >
                          <input
                          draggable="false"
                          #field
                          id="{{ j }}{{ measure.code }}{{i}}{{accIndex}}"
                            type="text"
                            title="{{measure.code}}"
                            [disabled]="
                              measure.isAccount === 1 || !editPermission || !year.isWeek
                            "
                            [ngClass]="{
                              'disabled-input':
                                measure.isAccount === 1 || !editPermission || !year.isWeek
                            }"
                            [(ngModel)]="currentAccount(year, account)['measures'][measure.code]"
                            (keydown.tab)="setFocusAccount(j, measure.code, i, accIndex);$event.preventDefault()"
                            (change)="
                              valueChange(
                                currentAccount(year, account)['measures'][measure.code],
                                currentAccount(year, account),
                                measure.code,
                                'measures'
                              )
                            "
                            (ngModelChange)="
                            calculateForecastMeasures(measure.code, currentAccount(year, account), 'measures');
                              addCommas(currentAccount(year, account)['measures'], measure.code)
                            "
                            (keydown)="onPaste($event, currentAccount(year, account), measure.code, 'measures')"
                            (keydown)="onKeyDown($event, measure.code,currentAccount(year, account))"
                            [ngModelOptions]="{ standalone: true }"
                            (mouseover) = "checkIfMousedown($event, currentAccount(year, account), measure.code, field)"
                            (mousedown) = "preventMouseDown($event, currentAccount(year, account), measure.code, field)"
                            (click) = "field.focus()"
                            (focus) = "field.select()"
                             (input)="onInput()"
                          />
                        </tr>
                        <tr
                          *ngIf="checkIfToShow(i,AccountMeasures)"
                          style="height: 40px !important; margin-bottom: 2px"
                        >
                          <label
                            [id]="i"
                            style="height: 40px !important; margin-bottom: 2px"
                            >&nbsp;</label
                          >
                        </tr>
                      </ng-container>
                    </td>
                  </ng-container>
                </td>
              </tr>
            </td>
          </tbody>
        </table>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<mat-accordion class="fb-forecast-accordion item-wrapper">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
      <mat-panel-title> All Accounts Total View </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container-fluid" id="fb-forecast-grid">
      <div>
        <table
          *ngIf="yearListGrid && yearListGrid.length > 0"
          id="forcast-grid"
          class="table"
        >
          <tbody>
            <td style="padding: 0px !important">
              <tr class="td-padding-none">
                <td>
                  <tr style="height: 45px !important; margin-bottom: 2px"></tr>
                  <ng-container *ngFor="let label of AccountTotalParentMeasures">
                    <tr
                      [attr.rowspan]="label.rowspan"
                      style="
                        writing-mode: vertical-rl;
                        text-orientation: mixed;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        font-size: large;
                      "
                      [ngStyle]="{
                        background: label.background,
                        height: label.rowspan * 42 + 'px'
                      }"
                      *ngIf="label.rowspan > 0"
                    >
                      <label
                        [ngStyle]="{
                          height: label.rowspan * 42 + 'px'
                        }"
                        style="
                          padding-right: 12px;
                          color: #fff;
                          min-width: 50px;
                          max-width: 50px;
                          text-align: center;
                          font-size: 14px;
                          font-weight: bold;
                          transform: rotate(180deg);
                        "
                        >&nbsp;</label
                      >
                    </tr>
                    <tr style="height: 40px !important; margin-bottom: 2px">
                      <label style="height: 40px !important; margin-bottom: 2px"
                        >&nbsp;</label
                      >
                    </tr>
                  </ng-container>
                </td>
              </tr>
            </td>
            <td style="padding: 0px !important">
              <tr class="td-padding">
                <td>
                  <tr
                    style="
                      height: 45px !important;
                      display: flex;
                      align-items: center;
                      margin-left: -50px;
                    "
                  >
                    <app-fb-forecast-scrolling
                      [isProduct]="true"
                      (selectionChange)="selectionChanged($event)"
                      [productList]="selectedProducts"
                      [productTree]="productTree"
                      [fbTemplateId] = "fbTemplateId"
                      [selectedProduct] = "selectedProduct || dropdownObjects.itemIds"
                    >
                    </app-fb-forecast-scrolling>
                    <app-fb-forecast-scrolling
                      [isChannel]="true"
                      (selectionChange)="selectionChanged($event)"
                      [productList]="selectedChannel"
                      [productTree]="productTree"
                      [fbTemplateId] = "fbTemplateId"
                      [scrollText] = "'All Accounts'"
                    >
                    </app-fb-forecast-scrolling>
                  </tr>
                  <ng-container
                    *ngFor="let label of AccountTotalMeasures; let i = index"
                  >
                    <tr
                      [ngStyle]="{ background: 'rgb(238, 238, 238)' }"
                      *ngIf="label.checked"
                      style="
                        height: 40px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 2px;
                        padding-left: 15px;
                        margin-right: 2px;
                      "
                    >
                      <label
                        *ngIf="!label.isDropdown"
                        style="min-width: 300px; max-width: 300px"
                        >{{ label.measureName }}</label
                      >
                      <mat-select
                        [(ngModel)]="label.measureName"
                        [ngModelOptions]="{ standalone: true }"
                        style="height: 40px"
                        style="min-width: 300px; max-width: 300px"
                        *ngIf="label.isDropdown"
                      >
                        <mat-option
                          class="not-empty-select"
                          *ngFor="let res of methodList; let i = index"
                          [value]="res"
                        >
                          {{ res }}
                        </mat-option>
                      </mat-select>
                    </tr>
                    <tr
                      *ngIf="checkIfToShow(i,AccountTotalMeasures)"
                      style="height: 40px !important; margin-bottom: 2px"
                    >
                      <label
                        [id]="i"
                        style="height: 40px !important; margin-bottom: 2px"
                        >&nbsp;</label
                      >
                    </tr>
                  </ng-container>
                </td>
              </tr>
            </td>
            <td (scroll) = "applyScrollToOther('accountTotalRows')" id="accountTotalRows"
              style="overflow-x: scroll; width: 100%; padding: 0px !important"
              [ngStyle]="{ 'max-width': getWidth() }"
            >
              <tr class="td-padding">
                <td
                  *ngFor="let year of gridData; let j = index"
                  style="text-align: center"
                  align="center"
                >
                  <tr style="height: 45px; display: block">
                    <mat-select
                      [placeholder]="year.title"
                      #select
                      (selectionChange)="showValues($event, year, select)"
                    >
                      <mat-select-trigger>
                        {{ year.title }}
                      </mat-select-trigger>
                      <mat-option
                        *ngFor="let option of timeframeList"
                        [value]="option.headerName"
                        >{{ option.headerName }}
                      </mat-option>
                    </mat-select>
                  </tr>
                  <ng-container>
                    <td class="input-fields" style="text-align: center">
                      <!-- Algo -->
                      <ng-container
                        *ngFor="let measure of AccountTotalMeasures; let i = index"
                      >
                        <tr
                          [ngStyle]="{ background: '#eee' }"
                          *ngIf="checkIfInList(measure.code)"
                        >
                          <input
                          draggable="false"
                          #field
                          id="{{ j }}{{ measure.code }}{{i}}"
                            type="text"
                            title="{{measure.code}}"
                            [disabled]="
                              measure.isAccSubtotal === 1 || !editPermission || !year.isWeek
                            "
                            [ngClass]="{
                              'disabled-input':
                                measure.isAccSubtotal === 1 || !editPermission || !year.isWeek
                            }"
                            [(ngModel)]="year['riSubTotalMeasures'][measure.code]"
                            (keydown.tab)="setFocus(j, measure.code, i);$event.preventDefault()"
                            (change)="
                              valueChange(
                                year['riSubTotalMeasures'][measure.code],
                                year,
                                measure.code,
                                'riSubTotalMeasures'
                              )
                            "
                            (ngModelChange)="
                            calculateForecastMeasures(measure.code, year, 'riSubTotalMeasures');
                              addCommas(year['riSubTotalMeasures'], measure.code)
                            "
                            (keydown)="onPaste($event, year, measure.code,'riSubTotalMeasures')"
                            (keydown)="onKeyDown($event, measure.code,year)"
                            [ngModelOptions]="{ standalone: true }"
                            (mouseover) = "checkIfMousedown($event, year, measure.code, field)"
                            (mousedown) = "preventMouseDown($event, year, measure.code, field)"
                            (click) = "field.focus()"
                            (focus) = "field.select()"
                             (input)="onInput()"
                          />
                        </tr>
                        <tr
                          *ngIf="checkIfToShow(i,AccountTotalMeasures)"
                          style="height: 40px !important; margin-bottom: 2px"
                        >
                          <label
                            [id]="i"
                            style="height: 40px !important; margin-bottom: 2px"
                            >&nbsp;</label
                          >
                        </tr>
                      </ng-container>
                    </td>
                  </ng-container>
                </td>
              </tr>
            </td>
          </tbody>
        </table>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<mat-accordion class="fb-forecast-accordion item-wrapper">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
      <mat-panel-title> Distributor + All Accounts Total View </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container-fluid" id="fb-forecast-grid">
      <div>
        <table
          *ngIf="yearListGrid && yearListGrid.length > 0"
          id="forcast-grid"
          class="table"
        >
          <tbody>
            <td style="padding: 0px !important">
              <tr class="td-padding-none">
                <td>
                  <tr style="height: 45px !important; margin-bottom: 2px"></tr>
                  <ng-container *ngFor="let label of DistiAccountTotalParentMeasures">
                    <tr
                      [attr.rowspan]="label.rowspan"
                      style="
                        writing-mode: vertical-rl;
                        text-orientation: mixed;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        font-size: large;
                      "
                      [ngStyle]="{
                        background: label.background,
                        height: label.rowspan * 42 + 'px'
                      }"
                      *ngIf="label.rowspan > 0"
                    >
                      <label
                        [ngStyle]="{
                          height: label.rowspan * 42 + 'px'
                        }"
                        style="
                          padding-right: 12px;
                          color: #fff;
                          min-width: 50px;
                          max-width: 50px;
                          text-align: center;
                          font-size: 14px;
                          font-weight: bold;
                          transform: rotate(180deg);
                        "
                        >&nbsp;</label
                      >
                    </tr>
                    <tr style="height: 40px !important; margin-bottom: 2px">
                      <label style="height: 40px !important; margin-bottom: 2px"
                        >&nbsp;</label
                      >
                    </tr>
                  </ng-container>
                </td>
              </tr>
            </td>
            <td style="padding: 0px !important">
              <tr class="td-padding">
                <td>
                  <tr
                    style="
                      height: 45px !important;
                      display: flex;
                      align-items: center;
                      margin-left: -50px;
                    "
                  >
                    <app-fb-forecast-scrolling
                      [isProduct]="true"
                      (selectionChange)="selectionChanged($event)"
                      [productList]="selectedProducts"
                      [productTree]="productTree"
                      [fbTemplateId] = "fbTemplateId"
                      [selectedProduct] = "selectedProduct || dropdownObjects.itemIds"
                    >
                    </app-fb-forecast-scrolling>
                    <app-fb-forecast-scrolling
                      [isChannel]="true"
                      (selectionChange)="selectionChanged($event)"
                      [productList]="selectedChannel"
                      [productTree]="productTree"
                      [scrollText] = "'Disti + All Accounts'"
                      [fbTemplateId] = "fbTemplateId"
                    >
                    </app-fb-forecast-scrolling>
                  </tr>
                  <ng-container
                    *ngFor="let label of DistiAccountTotalMeasures; let i = index"
                  >
                    <tr
                      [ngStyle]="{ background: 'rgb(238, 238, 238)' }"
                      *ngIf="label.checked"
                      style="
                        height: 40px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 2px;
                        padding-left: 15px;
                        margin-right: 2px;
                      "
                    >
                      <label
                        *ngIf="!label.isDropdown"
                        style="min-width: 300px; max-width: 300px"
                        >{{ label.measureName }}</label
                      >
                      <mat-select
                        [(ngModel)]="label.measureName"
                        [ngModelOptions]="{ standalone: true }"
                        style="height: 40px"
                        style="min-width: 300px; max-width: 300px"
                        *ngIf="label.isDropdown"
                      >
                        <mat-option
                          class="not-empty-select"
                          *ngFor="let res of methodList; let i = index"
                          [value]="res"
                        >
                          {{ res }}
                        </mat-option>
                      </mat-select>
                    </tr>
                    <tr
                      *ngIf="checkIfToShow(i,DistiAccountTotalMeasures)"
                      style="height: 40px !important; margin-bottom: 2px"
                    >
                      <label
                        [id]="i"
                        style="height: 40px !important; margin-bottom: 2px"
                        >&nbsp;</label
                      >
                    </tr>
                  </ng-container>
                </td>
              </tr>
            </td>
            <td id="distiAccountTotalRows"
            (scroll) = "applyScrollToOther('distiAccountTotalRows')"
              style="overflow-x: scroll; width: 100%; padding: 0px !important"
              [ngStyle]="{ 'max-width': getWidth() }"
            >
              <tr class="td-padding">
                <td
                  *ngFor="let year of gridData; let j = index"
                  style="text-align: center"
                  align="center"
                >
                  <tr style="height: 45px; display: block">
                    <mat-select
                      [placeholder]="year.title"
                      #select
                      (selectionChange)="showValues($event, year, select)"
                    >
                      <mat-select-trigger>
                        {{ year.title }}
                      </mat-select-trigger>
                      <mat-option
                        *ngFor="let option of timeframeList"
                        [value]="option.headerName"
                        >{{ option.headerName }}
                      </mat-option>
                    </mat-select>
                  </tr>
                  <ng-container>
                    <td class="input-fields" style="text-align: center">
                      <!-- Algo -->
                      <ng-container
                        *ngFor="let measure of DistiAccountTotalMeasures; let i = index"
                      >
                        <tr
                          [ngStyle]="{ background: '#eee' }"
                          *ngIf="checkIfInList(measure.code)"
                        >
                          <input
                          draggable="false"
                          #field
                          id="{{ j }}{{ measure.code }}{{i}}"
                            type="text"
                            title="{{measure.code}}"
                            [disabled]="
                              measure.isDistAccTotal === 1 || !editPermission || !year.isWeek
                            "
                            [ngClass]="{
                              'disabled-input':
                                measure.isDistAccTotal === 1 || !editPermission || !year.isWeek
                            }"
                            [(ngModel)]="year['riTotalMeasures'][measure.code]"
                            (keydown.tab)="setFocus(j, measure.code, i);$event.preventDefault()"
                            (change)="
                              valueChange(
                                year['riTotalMeasures'][measure.code],
                                year,
                                measure.code,
                                'riTotalMeasures'
                              )
                            "
                            (ngModelChange)="
                            calculateForecastMeasures(measure.code, year, 'riTotalMeasures');
                              addCommas(year['riTotalMeasures'], measure.code)
                            "
                            (keydown)="onPaste($event, year, measure.code, 'riTotalMeasures')"
                            (keydown)="onKeyDown($event, measure.code,year)"
                            [ngModelOptions]="{ standalone: true }"
                            (mouseover) = "checkIfMousedown($event, year, measure.code, field)"
                            (mousedown) = "preventMouseDown($event, year, measure.code, field)"
                            (click) = "field.focus()"
                            (focus) = "field.select()"
                             (input)="onInput()"
                          />
                        </tr>
                        <tr
                          *ngIf="checkIfToShow(i,DistiAccountTotalMeasures)"
                          style="height: 40px !important; margin-bottom: 2px"
                        >
                          <label
                            [id]="i"
                            style="height: 40px !important; margin-bottom: 2px"
                            >&nbsp;</label
                          >
                        </tr>
                      </ng-container>
                    </td>
                  </ng-container>
                </td>
              </tr>
            </td>
          </tbody>
        </table>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<ng-template #yearColumn let-week="week" let-index="index"> </ng-template>
