import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DemoService } from 'src/services/demo-services/demo-service';

@Component({
    selector: 'item-edit--demo-cell-renderer',
    template: `<a class="product-title-link" (click)="btnEditClickHandler()">{{params.value || 'null'}}</a>`,
})
export class ChildItemDemoEditRenderer implements ICellRendererAngularComp, OnDestroy {
    params: any;

    constructor(private demoService: DemoService) {
    }

    agInit(params: any): void {
        this.params = params;
    }

    btnEditClickHandler() {
        this.demoService.setChildGridActionSubject({ action: 'edit', actionData: this.params.data });
    }

    refresh(params?: any): boolean {
        return true;
    }

    ngOnDestroy() {
        // no need to remove the button click handler 
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}