import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  public category;
  constructor(private firestore: AngularFirestore, public http: HttpClient) { }
  // Template CRUD Model

  addNewTemplate = (Template) => {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('Template').add(Template).then(res => resolve(res), error => reject(error));
    });
  }
  UpdateTemplate = (Template) => {
    return new Promise<any>((resolve, reject) => {
      this.firestore.doc(`Template/${Template.id}`).update(Template).then(res => resolve(res), error => reject(error));
    });
  }
  DeleteTemplate = (Template) => {
    return new Promise<any>((resolve, reject) => {
      this.firestore.doc(`Template/${Template.id}`).delete().then(res => resolve(res), error => reject(error));
    });
  }
  getAllTemplates = () => {
    return this.firestore.collection('Template').snapshotChanges();
  }
  // Category CRUD Model

  addNewCategory = (Category) => {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('Category').add(Category).then(res => resolve(res), error => reject(error));
    });
  }
  UpdateCategory = (Category) => {
    return new Promise<any>((resolve, reject) => {
      this.firestore.doc(`Category/${Category.id}`).update(Category).then(res => resolve(res), error => reject(error));
    });
  }
  DeleteCategory = (Category) => {
    return new Promise<any>((resolve, reject) => {
      this.firestore.doc(`Category/${Category.id}`).delete().then(res => resolve(res), error => reject(error));
    });
  }
  getAllCategory = () => {
    return this.firestore.collection('Category').snapshotChanges();
  }
  // SKU CRUD Model

  addNewSKU = (SKU) => {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('SKU').add(SKU).then(res => resolve(res), error => reject(error));
    });
  }
  UpdateSKU = (SKU) => {
    return new Promise<any>((resolve, reject) => {
      this.firestore.doc(`SKU/${SKU.id}`).update(SKU).then(res => resolve(res), error => reject(error));
    });
  }
  DeleteSKU = (SKU) => {
    return new Promise<any>((resolve, reject) => {
      this.firestore.doc(`SKU/${SKU.id}`).delete().then(res => resolve(res), error => reject(error));
    });
  }
  getAllSKU = () => {
    return this.firestore.collection('SKU').snapshotChanges();
  }
  getSimulations = () => {
    return this.http.get('assets/simulations.json');
  }
  getSimulationsExcel = (fileName: string) => {
    return this.http.get(`assets/${fileName}.xlsx`, { responseType: 'blob' });
  }
  // Product CRUD Model
}
