import { yesNoRenderer } from "src/ag-grid/renderers/common-renderers/common-renderers";
import { tooltipcellRenderer } from "src/common/util/util";

export const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  filterParams: {
    excelMode: 'windows',
  },
  menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
};

export const getColDefs = (isCommitGrid: boolean = false, filterLookupModel: any = {}, componentSelf) => {
  const sharedDataService = componentSelf._dataService;
  const colDefs: any = [
    {
      headerName: '',
      field: 'select',
      width: 70,
      pinned: 'left',
      suppressSizeToFit: true,
      checkboxSelection: !isCommitGrid,
      headerCheckboxSelection: !isCommitGrid,
      filter: false,
      sortable: false,
      suppressMenu: true,
      cellClassRules: {
        'clear-top-border': (params) => params.data.saleValues === 'Adjusted',
        'selection-box': (params) => params.data.saleValues === 'Adjusted',
      },

    },
    {
      headerName: sharedDataService.getDataById(1),
      field: 'frl_account_name',
      width: 105,
      pinned: 'left',
      rowSpan: (params) => params.data.saleValues === 'Original' ? 2 : 1,
      cellClassRules: {
        'sku-info-row': (params) => params.data.saleValues === 'Original',
        'row-span': (params) => params.data.saleValues === 'Adjusted',
        'clear-top-border': (params) => params.data.saleValues === 'Adjusted',
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: async (params) => {
          const values = await filterLookupModel.account();
          params.success(values);
        },
        cellRenderer: tooltipcellRenderer,
      },
      cellRenderer: tooltipcellRenderer,
    },
    {
      headerName: sharedDataService.getDataById(2),
      field: 'brand',
      width: 85,
      pinned: 'left',
      rowSpan: (params) => params.data.saleValues === 'Original' ? 2 : 1,
      cellClassRules: {
        'sku-info-row': (params) => params.data.saleValues === 'Original',
        'row-span': (params) => params.data.saleValues === 'Adjusted',
        'clear-top-border': (params) => params.data.saleValues === 'Adjusted',
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: async (params) => {
          const values = await filterLookupModel.brand();
          params.success(values);
        },
        cellRenderer: tooltipcellRenderer,
      },
      cellRenderer: tooltipcellRenderer,
    },
    {
      headerName: sharedDataService.getDataById(3),
      field: 'product_group',
      width: 135,
      pinned: 'left',
      rowSpan: (params) => params.data.saleValues === 'Original' ? 2 : 1,
      cellClassRules: {
        'sku-info-row': (params) => params.data.saleValues === 'Original',
        'row-span': (params) => params.data.saleValues === 'Adjusted',
        'clear-top-border': (params) => params.data.saleValues === 'Adjusted',
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: async (params) => {
          const values = await filterLookupModel.product_group();
          params.success(values);
        },
        cellRenderer: tooltipcellRenderer,
      },
      cellRenderer: tooltipcellRenderer,
    },
    {
      headerName: sharedDataService.getDataById(5),
      field: 'PRODUCT_DESCRIPTION',
      width: 147,
      pinned: 'left',
      rowSpan: (params) => params.data.saleValues === 'Original' ? 2 : 1,
      cellClassRules: {
        'sku-info-row': (params) => params.data.saleValues === 'Original',
        'row-span': (params) => params.data.saleValues === 'Adjusted',
        'clear-top-border': (params) => params.data.saleValues === 'Adjusted',
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: async (params) => {
          const values = await filterLookupModel.product_description();
          params.success(values);
        },
        cellRenderer: tooltipcellRenderer,
      },
      cellRenderer: tooltipcellRenderer,
    },
    {
      headerName: sharedDataService.getDataById(4),
      field: 'item_key',
      width: 110,
      pinned: 'left',
      rowSpan: (params) => params.data.saleValues === 'Original' ? 2 : 1,
      cellClassRules: {
        'sku-info-row': (params) => params.data.saleValues === 'Original',
        'row-span': (params) => params.data.saleValues === 'Adjusted',
        'clear-top-border': (params) => params.data.saleValues === 'Adjusted',
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: async (params) => {
          const values = await filterLookupModel.sku();
          params.success(values);
        },
        cellRenderer: tooltipcellRenderer,
      },
      cellRenderer: tooltipcellRenderer,
    },
    {
      headerName: 'Mapping',
      field: 'IsMapped',
      width: 80,
      pinned: 'left',
      suppressMenu: true,
      rowSpan: (params) => params.data.saleValues === 'Original' ? 2 : 1,
      cellClassRules: {
        'sku-info-row': (params) => params.data.saleValues === 'Original',
        'row-span': (params) => params.data.saleValues === 'Adjusted',
        'clear-top-border': (params) => params.data.saleValues === 'Adjusted',
      },
      cellRenderer: yesNoRenderer,
      sortable: true,
    },
    {
      headerName: 'Selected Weeks',
      field: 'selectedWeeks',
      pinned: 'left',
      rowSpan: (params) => params.data.saleValues === 'Original' ? 2 : 1,
      cellClassRules: {
        'row-span': (params) => params.data.saleValues === 'Adjusted',
        'clear-top-border': (params) => params.data.saleValues === 'Adjusted',
        'trend-chart-cell': () => true,
      },
      cellRenderer: 'outlierLineChartCellRenderer',
      suppressColumnsToolPanel: true,
      filter: false,
      sortable: false,
      width:155
    },
    {
      headerName: 'Sale Values',
      field: 'saleValues',
      width: 95,
      suppressMenu: true,
      pinned: 'left',
      cellClassRules: {
        'adj-background': (params) => params.data.saleValues === 'Adjusted',
      },
      filter: false,
      sortable: false
    }
  ];

  return colDefs;
}
