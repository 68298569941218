<!-- Main Content area -->
<div *ngIf="!hideScreen" id="fb-forecast-new-planner" class="container-fluid">
  <div class="btn-wrapper" [hidden]="!isShowTreeDropDowns">
    <div class="form-group">
      <app-treeview-dropdown
        [type]="'Channel'"
        [items]="channelDropdownList"
        (valueChange)="setValue($event)"
      >
      </app-treeview-dropdown>
    </div>
    <div class="form-group">
      <app-treeview-dropdown
        [type]="'Item'"
        [items]="productList"
        (valueChange)="setValue($event)"
      >
      </app-treeview-dropdown>
    </div>
    <div class="form-group">
      <app-treeview-dropdown
        [type]="'Timeframe'"
        [items]="calendarListDropdown"
        (valueChange)="setValue($event)"
      >
      </app-treeview-dropdown>
    </div>
    <div class="form-group" *ngIf="showVersionList">
      <mat-form-field>
        <mat-label>Choose Version</mat-label>
        <mat-select>
          <mat-option
            *ngFor="let version of versionList"
            [value]="version.versionTypeId"
            >{{ version.versionTypeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <button
        (click)="mapGridList(false)"
        [disabled]="
        !isDeleted ||
          itemIds.length == 0 ||
          channelIds.length == 0 ||
          calendarIds.length == 0
        "
        class="btn btn-primary adjust-btn"
      >
      Build
      </button>
    </div>
  </div>
  <div [hidden]="!gridMapped">
    <mat-accordion class="fb-forecast-accordion">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title> Data Manager </mat-panel-title>
        </mat-expansion-panel-header>
        <div
          class="container-fluid item-wrapper fb-forecast-planner"
          [style.height]="isAnyOpened() ? '70vh' : ''"
        >
          <div class="row method-planner-bottom">
            <div class="col-md-4 fb-line align">
              <div>
                <h3>View Data Set</h3>
                <form>
                  <div class="form-group">
                    <label>Product</label>
                    <app-treeview-dropdown
                      [type]="'Item'"
                      [isReadonly]="true"
                      [items]="restoredProductList"
                      style="z-index: 1"
                      (valueChange)="setRestoredValue($event)"
                    >
                    </app-treeview-dropdown>
                  </div>
                  <div class="form-group">
                    <label>Timeframe</label>
                    <app-treeview-dropdown
                      [type]="'Timeframe'"
                      [isReadonly]="true"
                      [items]="restoreCalendarList"
                      (valueChange)="setRestoredValue($event)"
                    >
                    </app-treeview-dropdown>
                  </div>
                  <div class="form-group">
                    <label>Channel</label>
                    <app-treeview-dropdown
                      [isReadonly]="true"
                      [type]="'Channel'"
                      [items]="restoredChannelList"
                      (valueChange)="setRestoredValue($event)"
                    >
                    </app-treeview-dropdown>
                  </div>
                </form>
              </div>
              <!-- <div class="row">
                <div class="col-12 btn-wrapper">
                  <button
                    class="btn btn-primary m-0"
                    (click)="adjustData()"
                    *ngIf="!isOpenSaved"
                  >
                    {{
                      itemIds.length == 0 ||
                      channelIds.length == 0 ||
                      calendarIds.length == 0
                        ? "Build"
                        : "Adjust"
                    }}
                  </button>
                </div>
              </div> -->
            </div>
            <div class="col-md-4 fb-line align">
              <div>
                <h3>Refresh Measures</h3>
                <form>
                  <div class="form-group">
                    <label>Select Measure</label>
                    <mat-select
                      placeholder=""
                      (selectionChange)="onSelectionChanged($event)"
                      [multiple]="true"
                    >
                      <mat-option
                        *ngFor="let item of refreshMeasuresList"
                        [value]="item.measureId"
                        >{{ item.measureName }}
                      </mat-option>
                    </mat-select>
                  </div>
                </form>
              </div>
              <div class="row">
                <div class="col-12 btn-wrapper">
                  <button
                    class="btn btn-primary m-0"
                    (click)="refreshMeasure()"
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-3 fb-line align">
              <div>
                <h3>Scenario Planning</h3>
                <form>
                  <div class="form-group">
                    <label>Forecast Measure</label>
                    <mat-select [(ngModel)]="selectedScenarioForecastMeasure">
                      <mat-option
                        *ngFor="let item of fcstScenarioPlanningList"
                        [value]="item"
                        >{{ item.measureName }}</mat-option
                      >
                    </mat-select>
                  </div>
                  <div class="form-group">
                    <label>Scenario</label>
                    <mat-select [(ngModel)]="selectedScenario">
                      <mat-option
                        *ngFor="let item of scenarioPlanningList"
                        [value]="item"
                        >{{ item.measureName }}
                      </mat-option>
                    </mat-select>
                  </div>
                  <div class="form-group">
                    <label>Adjustment %</label>
                    <input type="text" [(ngModel)]="adjustment" />
                  </div>
                  <div class="form-group">
                    <label>Timeframe</label>
                    <app-treeview-dropdown
                      [type]="'TimeframeWithWeeksScenario'"
                      [items]="timeFrameListWithWeeks"
                      (valueChange)="setDataManagerValues($event)"
                    >
                    </app-treeview-dropdown>
                  </div>
                </form>
              </div>
              <div class="row">
                <div class="col-12 btn-wrapper">
                  <button class="btn btn-primary m-0" (click)="applyScenario()">
                    Apply
                  </button>
                </div>
              </div>
            </div> -->
            <div class="col-md-4">
              <div>
                <h3>Forecast Update</h3>
                <form>
                  <div class="form-group">
                    <label>Forecast Measure</label>
                    <mat-select [(ngModel)]="selectedForecastMeasure">
                      <mat-option
                        *ngFor="let item of udpfcstList"
                        [value]="item"
                        >{{ item.measureName }}</mat-option
                      >
                    </mat-select>
                  </div>
                  <div class="form-group">
                    <label>UDP Forecast</label>
                    <mat-select [(ngModel)]="selectedUDPMeasure">
                      <mat-option
                        *ngFor="let item of udpMeasuresList"
                        [value]="item"
                        >{{ item.measureName }}</mat-option
                      >
                    </mat-select>
                  </div>
                  <div class="form-group">
                    <label>Adjustment %</label>
                    <input type="text" [(ngModel)]="adjustment" />
                  </div>
                  <div class="form-group">
                    <label>Timeframe</label>
                    <app-treeview-dropdown
                      [type]="'TimeframeWithWeeksUDP'"
                      [items]="timeFrameListWithWeeksUPD"
                      (valueChange)="setDataManagerValues($event)"
                    >
                    </app-treeview-dropdown>
                  </div>
                </form>
              </div>
              <div class="row">
                <div class="col-md-12 btn-wrapper">
                  <button class="btn btn-primary m-0" (click)="applyUDP()">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- <mat-accordion class="fb-forecast-accordion">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title> Method Manager </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container-fluid item-wrapper fb-forecast-planner">
          <div class="row method-planner-top">
            <div class="col-md-6 fb-line">
              <h3>
                Active Forecast Method ({{ date | date: "hh:mm a MM/dd/yyyy" }})
              </h3>
              <form>
                <div class="form-group">
                  <label>Forecast Method</label>
                  <input readonly type="text" [value]="forecastMethodValue" />
                </div>
                <div class="form-group">
                  <label>Seasonality Source Level</label>
                  <input
                    readonly
                    type="text"
                    [value]="seasonalitySourceLevelValue"
                  />
                </div>
                <div class="form-group">
                  <label>Moving Average Weeks</label>
                  <input
                    readonly
                    type="text"
                    [value]="movingAverageWeeksValue"
                  />
                </div>
              </form>
            </div>
            <div class="col-md-6 fb-line2">
              <h3>Recommended Method</h3>
              <form>
                <div class="form-group">
                  <label>Forecast Method</label>
                  <input
                    readonly
                    type="text"
                    [value]="'Algo Forecast'"
                  />
                </div>
                <div class="form-group">
                  <label>Seasonality Source Level</label>
                  <input readonly type="text" [value]="'Item/Account'" />
                </div>
                <div class="form-group">
                  <label>Moving Average Weeks</label>
                  <input readonly type="text" [value]="'7 Weeks'" />
                </div>
              </form>
            </div>
          </div>
          <form class="row method-planner-bottom">
            <div class="form-group col-md-3">
              <label>Forecast Method</label>
              <mat-select
                placeholder=""
                [(ngModel)]="forecastMethod"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option
                  *ngFor="let method of forecastMethodList"
                  [value]="method"
                  >{{ method.value }}</mat-option
                >
              </mat-select>
            </div>
            <div class="form-group col-md-3">
              <label>Seasonality Source Level</label>
              <mat-select
                placeholder=""
                [(ngModel)]="seasonalitySourceLevel"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option
                  *ngFor="let method of seasonalitySourceLevelList"
                  [value]="method"
                  >{{ method.value }}</mat-option
                >
              </mat-select>
            </div>
            <div class="form-group col-md-3">
              <label>Moving Average Weeks</label>
              <mat-select
                placeholder=""
                [(ngModel)]="movingAverageWeeks"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option
                  *ngFor="let method of averageWeekList"
                  [value]="method"
                  >{{ method.value }}</mat-option
                >
              </mat-select>
            </div>
            <div class="col-md-3 btn-wrapper">
              <button class="btn btn-primary" (click)="apply()">Apply</button>
            </div>
          </form>
        </div>
      </mat-expansion-panel>
    </mat-accordion> -->
    <app-regional-demand-planner-grid-new
      [yearListGrid]="yearListGrid"
      *ngIf="yearListGrid.length > 0"
      [viewPermission]="viewPermission"
      [editPermission]="editPermission"
      [account]="account"
      [product]="product"
      [leftGroupList]="leftGroupList"
      [measureList]="measureList"
      [dropdownObjects]="dropdownObjects"
      [forecastList]="forecastList"
      [versionTypeId]="versionTypeId"
      [calendarList]="timeframeList"
      [selectedProducts]="productTree"
      [selectedChannel]="channelTree"
      [productTree]="productTree"
      (onClickEvent)="save()"
      [itemsList]="itemsList"
      [accountList]="accountList"
      (updateGridList)="updateCriteria($event)"
      [fbForecastSelectedId]="fbForecast?.fbForecastSelectedId"
      [forecastMethod]="forecastMethod"
      [seasonalitySourceLevel]="seasonalitySourceLevel"
      [movingAverageWeeks]="movingAverageWeeks"
      [calendarIds]="calendarIds"
      [userFBForecastId]="userFBForecastId"
      [fbForecast]="fbForecast"
      [isRestoreSession] = "isRestoreSession"
      [isOpenSaved] = "isOpenSaved"
      [guid] = "data.guid"
      [measureKeys] ="measureKeys"
      [masterMeasureList] = "masterMeasureList"
    >
    </app-regional-demand-planner-grid-new>
  </div>
</div>
<ng-template #saveForecast>
  <h1 mat-dialog-title>What would you like to name your planner?</h1>
  <div mat-dialog-content>
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="Enter Planner Name"
        [(ngModel)]="plannerName"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>
  <div class="action-panel text-right btn-wrapper">
    <button
      mat-raised-button
      class="btn btn-primary m-left"
      color="primary"
      (click)="ref.close()"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      class="btn btn-primary m-left"
      color="warn"
      (click)="ref.close(true)"
    >
      Save
    </button>
  </div>
</ng-template>
<ng-template #commitForecast>
  <div mat-dialog-content>
    <p>Are you sure you want to commit your changes?</p>
  </div>
  <div class="action-panel text-right btn-wrapper">
    <button
      mat-raised-button
      class="btn btn-primary m-left"
      color="primary"
      (click)="ref.close()"
    >
      No
    </button>
    <button
      mat-raised-button
      class="btn btn-primary m-left"
      color="warn"
      (click)="commitFacebookPlanner()"
    >
      Yes
    </button>
  </div>
</ng-template>
