import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataModuleService } from 'src/services/data-module.service';
import { conditionsDropdownData } from '../../constants/dropdowns';

@Component({
  selector: 'app-transform-data',
  templateUrl: './transform-data.component.html',
  styleUrls: ['./transform-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransformDataComponent implements OnInit {

  columns = [];
  selectedColumn = '';
  selectedFormArray: FormArray;
  transformationForm: FormGroup;

  conditionsDropdownData = conditionsDropdownData;

  constructor(public dialogRef: MatDialogRef<TransformDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder,
    private dataModuleService: DataModuleService,
    private toastrService: ToastrService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.columns = this.data.columns;
    this.selectedColumn = this.columns[0].field;
    this.initializeForm();
    this.fetchTransformationRules();
  }


  fetchTransformationRules() {
    this.spinner.show();
    this.dataModuleService.fetchTransformationRules(this.data.fileName, this.data.userId, this.data.versionNo)
      .subscribe((res: any) => {
        if (res.payload && res.payload.length) {
          const colKeys = [];
          this.columns.forEach(element => {
            colKeys.push(element.field)
          });
          res.payload[0].rule.forEach(element => {
            if (colKeys.indexOf(element.column) > -1) {
              const selectedFormArrayTemp = (this.transformationForm.get(element.column).get('rules')) as FormArray;
              element.rules.forEach(r => {
                selectedFormArrayTemp.push(this.addNewRule());
                selectedFormArrayTemp.controls[selectedFormArrayTemp.length - 1].patchValue(r);
                selectedFormArrayTemp.controls[selectedFormArrayTemp.length - 1].disable();
              });
            }
          });
        }
        this.selectedColumnChanged();
        this.spinner.hide();
      },
        err => {
          this.spinner.hide();
        });

  }

  initializeForm() {
    this.transformationForm = this.formBuilder.group({});
    this.columns.forEach(element => {
      this.transformationForm.addControl(element.field, new FormGroup(
        {
          rule_type: new FormControl('transformation'),
          rules: new FormArray([])
        }
      ));
    });
  }

  selectedColumnChanged() {
    this.selectedFormArray = (this.transformationForm.get(this.selectedColumn).get('rules')) as FormArray;
    this.selectedFormArray.valueChanges
      .subscribe((res: any) => {
        this.enableDisableFields();
      });
  }

  enableDisableFields() {
    for (let i = 0; i < this.selectedFormArray.controls.length; i++) {
      if (this.selectedFormArray.controls[i].enabled) {
        const element = this.selectedFormArray.controls[i].value;
        if (element.gate) {
          this.selectedFormArray.controls[i].get('filter_right').enable({ emitEvent: false });
          if (this.selectedFormArray.controls[i].get('filter_right').value &&
            [this.conditionsDropdownData[this.conditionsDropdownData.length - 1].id, this.conditionsDropdownData[this.conditionsDropdownData.length - 2].id].indexOf(this.selectedFormArray.controls[i].get('filter_right').value) == -1) {
            this.selectedFormArray.controls[i].get('value_right').enable({ emitEvent: false });
          } else {
            this.selectedFormArray.controls[i].get('value_right').disable({ emitEvent: false });
          }
        }
        if (this.selectedFormArray.controls[i].get('filter_left').value &&
          [this.conditionsDropdownData[this.conditionsDropdownData.length - 1].id, this.conditionsDropdownData[this.conditionsDropdownData.length - 2].id].indexOf(this.selectedFormArray.controls[i].get('filter_left').value) == -1) {
          this.selectedFormArray.controls[i].get('value_left').enable({ emitEvent: false });
        } else {
          this.selectedFormArray.controls[i].get('value_left').disable({ emitEvent: false });
        }
      }
    }
  }

  addNewRuleHandler() {
    this.selectedFormArray.push(this.addNewRule());
  }

  removeRule(index: number) {
    if (this.selectedFormArray.get(index + '').disabled) {
      this.toastrService.warning('Already saved transformation rules cannot be updated!');
    } else this.selectedFormArray.removeAt(index);
  }

  addNewRule(): FormGroup {
    return new FormGroup({
      filter_left: new FormControl(this.conditionsDropdownData[0].id, Validators.required),
      value_left: new FormControl(null, Validators.required),
      gate: new FormControl(null),
      filter_right: new FormControl({ value: null, disabled: true }, Validators.required),
      value_right: new FormControl({ value: null, disabled: true }, Validators.required),
      convert_to: new FormControl(null, Validators.required)
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSaveClick() {
    const transformationsKeys = Object.keys(this.transformationForm.getRawValue());
    const transformationsValues = Object.values(this.transformationForm.getRawValue());
    const transformationsArray = [];
    transformationsKeys.forEach((element, index) => {
      const excObj = transformationsValues[index] as any;
      excObj.column = element;
      transformationsArray.push(excObj);
    });
    const obj = {
      user_id: this.data.userId,
      file_name: this.data.fileName,
      rule_type: "transformation",
      version_no: this.data.versionNo,
      rule: transformationsArray
    };
    this.spinner.show();
    this.dataModuleService.saveTransformationRules(obj)
      .subscribe((res: any) => {
        this.toastrService.success('Transformations saved successfully.')
        this.spinner.hide();
        this.dialogRef.close(true);
      },
        err => {
          this.toastrService.error('Failed to save transformations.');
          this.spinner.hide();
        });
  }
}
