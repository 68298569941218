import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable()
export class OutlierService {

  // Subjects
  public outlierManagerGridActionSubject: Subject<any> = new Subject();
  public weekCommitActionSubject: Subject<any> = new Subject();

  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */
  setOutlierManagerGridActionSubject = (value: any) => { this.outlierManagerGridActionSubject.next(value); };
  setWeekCommitActionSubject = (value: any) => { this.weekCommitActionSubject.next(value); };

  // Observables
  getOutlierManagerGridActionSubject = () => this.outlierManagerGridActionSubject.asObservable();
  getWeekCommitActionSubject = () => this.weekCommitActionSubject.asObservable();

  constructor(private http: HttpClient) {
  }


  GetOutlierWeeksMetadata(numberOfWeeks) {
    return this.http.get(`${environment.outlierManager}/getweekmetadatalist?numberOfWeeks=${numberOfWeeks}`)
      .pipe(map(weekList => {
        return weekList;
      }));
  }

  GetOutlierWeeksData(requestBody) {
    return this.http.post(`${environment.outlierManager}/outlierlist`, requestBody)
      .pipe(map(response => {
        return response;
      }));
  }

  UpdateOutlierGrid(body: any) {
    return this.http.post(`${environment.outlierManager}/updategrid`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  CommitWeek(body: any) {
    return this.http.post(`${environment.outlierManager}/addcommit`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  UpdateOutlierResetSuggested(body: any) {
    return this.http.post(`${environment.outlierManager}/updateoutlierresetsuggested`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  UpdateOutlierAboveOriginal(body: any) {
    return this.http.post(`${environment.outlierManager}/updateoutlieraboveoriginal`, body)
      .pipe(map(response => {
        return response;
      }));
  }


  UpdateOutlierBelowOriginal(body: any) {
    return this.http.post(`${environment.outlierManager}/updateoutlierbeloworiginal`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  GetAccountList() {
    return this.http.get(`${environment.outlierManager}/getaccountslist`)
      .pipe(map(accountList => {
        return accountList;
      }));
  }

  GetBrandList() {
    return this.http.get(`${environment.outlierManager}/getbrandslist`)
      .pipe(map(brandList => {
        return brandList;
      }));
  }

  GetProductGroupList() {
    return this.http.get(`${environment.outlierManager}/getproductgrouplist`)
      .pipe(map(productgrouplist => {
        return productgrouplist;
      }));
  }

  GetProductDescriptionList() {
    return this.http.get(`${environment.ConfigurationLookup}/GetProductDescriptionList`)
      .pipe(map(productdescriptionlist => {
        return productdescriptionlist;
      }));
  }

  GetSKUList() {
    return this.http.get(`${environment.ConfigurationLookup}/GetSKUList`)
    .pipe(map(skulist => {
      return skulist;
    }));
  }

  GetEarliestUnadjustedWeekUpdatedValues() {
    return this.http.get(`${environment.outlierManager}/getearliestunadjustedweekupdatedvalues`)
      .pipe(map(updatedValuesList => {
        return updatedValuesList;
      }));
  }

}
