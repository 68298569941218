import { ColDef } from '@ag-grid-enterprise/all-modules';
import * as _ from 'lodash';
import * as moment from 'moment';
import { dateRenderer } from 'src/ag-grid/renderers/common-renderers/common-renderers';

export const paginationPageSize = 50;
export const cacheBlockSize = 50;
export const rowSelection = 'single';

export const defaultColDef = {
  filter: true,
  sortable: true,
  minWidth: 50,
  resizable: true,
  filterParams: { newRowsAction: 'keep' }
};

export const promotionPlannerExportGridColDef = (isGeneralView = false) => {
  const promotionPlannerExportGridColDef: ColDef[] = [
    {
      headerName: 'Promotion Item Planner Id (DO NOT EDIT)',
      field: 'promotionItemPlannerId'
    },
    {
      headerName: 'Promotion Name (DO NOT EDIT)',
      field: 'promotionItemPlannerName'
    },
    {
      headerName: 'Fixture (DO NOT EDIT)',
      field: 'fixtureName'
    },
    {
      headerName: 'Promo Start Date (DO NOT EDIT)',
      field: 'startDate',
      type: 'date'
    },
    {
      headerName: 'Promo End Date (DO NOT EDIT)',
      field: 'endDate',
      type: 'date'
    },
    ...isGeneralView ? [] : [{
      headerName: 'Status',
      field: 'actionName'
    }],
    {
      headerName: 'Internal SKU ID (DO NOT EDIT)',
      field: 'internalSKUId'
    },
    {
      headerName: 'Item Description (DO NOT EDIT)',
      field: 'itemDescription'
    },
    {
      headerName: 'UPC (DO NOT EDIT)',
      field: 'upc',
    },
    {
      headerName: 'Account (DO NOT EDIT)',
      field: 'accountName'
    },
    ...!isGeneralView ? [{
      headerName: 'B&M Promotion Forecast (Vendor)',
      field: 'bAndMPromotionForecastVendor'
    }] : [],
    {
      headerName: 'Release Date (DO NOT EDIT)',
      field: 'releaseDate',
      type: 'date'
    },
    {
      headerName: 'Regular Retail Price (DO NOT EDIT)',
      field: 'regRetailPrice'
    },
    ...isGeneralView ? [
      {
        headerName: 'Promo Price',
        field: 'promoPrice'
      },
      {
        headerName: 'Position',
        field: 'position'
      },
      {
        headerName: 'B&M Promotion Forecast (Best Buy)',
        field: 'bAndMPromotionForecastBestBuy'
      },
      {
        headerName: 'Dot com Forecast (Best Buy)',
        field: 'dotcomForecastBestBuy'
      }] : [],
    {
      headerName: 'On-Ad',
      field: `onAd`
    },
    {
      headerName: 'Week On-Ad',
      field: 'weekOnAd'
    },
    ...!isGeneralView ? [
      {
        headerName: 'Promo Price',
        field: 'promoPrice'
      },
      {
        headerName: 'Base Cost',
        field: 'baseCost'
      },
      {
        headerName: 'Base Cost Effective Date',
        field: 'baseCostEffectiveDate',
        type: 'date'
      },
    ] : [
      {
        headerName: 'On Ad Week Forecast',
        field: 'onAdWeekForecastBestBuy'
      },
      {
        headerName: 'All Weeks on Ad (DO NOT EDIT)',
        field: 'allWeeksOnAd'
      },
      {
        headerName: 'ROQ (Best Buy)',
        field: 'roqBestBuy'
      },
      {
        headerName: 'Dot Com ROQ (Best Buy)',
        field: 'roqDotComBestBuy'
      }
    ],
    {
      headerName: 'Margin Credit',
      field: 'marginCredit'
    },
    {
      headerName: 'Retek Cost (DO NOT EDIT)',
      field: 'retekCost'
    },
    {
      headerName: 'Class (DO NOT EDIT)',
      field: 'className'
    },
    ...isGeneralView ? [
      {
        headerName: 'SubClass (DO NOT EDIT)',
        field: 'subClassName'
      },
      {
        headerName: 'Status (DO NOT EDIT)',
        field: 'actionName'
      }
    ] : [],
    {
      headerName: 'Operating Gross Margin (DO NOT EDIT)',
      field: 'operatingGrossMargin'
    },
    {
      headerName: 'Operating Gross Margin % (DO NOT EDIT)',
      field: 'operatingGrossMarginPercentage'
    },
    {
      headerName: 'Notes',
      field: 'notes'
    }
  ];
  return promotionPlannerExportGridColDef;
}

export const promotionPlannerColDefs = (addSelectionBox = false) => {
  const promotionPlannerColDefs: ColDef[] = [
    ...addSelectionBox ? [{
      headerName: 'Select',
      field: 'select',
      width: 150,
      suppressSizeToFit: true,
      checkboxSelection: true
    }] : [],
    {
      headerName: 'Promotion Name',
      field: 'promotionItemPlannerName',
      cellRenderer: 'promotionPlannerEditRenderer'
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
      type: 'date',
      cellRenderer: dateRenderer
    },
    {
      headerName: 'End Date',
      field: 'endDate',
      type: 'date',
      cellRenderer: dateRenderer
    },
    {
      headerName: 'Fixture',
      field: 'fixtureName'
    },
    {
      headerName: 'Submitted Item Count',
      field: 'submittedItemCount',
      cellStyle: { textAlign: 'center' }
    },
    {
      headerName: 'Accepted Item Count',
      field: 'acceptedItemCount',
      cellStyle: { textAlign: 'center' }
    },
    {
      headerName: 'Account(s)',
      field: 'accountNames'
    }
  ];
  return promotionPlannerColDefs;
}


export const promotionPlannerItemDetailGridColDefs = (isRetailerTeam = false) => {
  const promotionItemDetailColDefs: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      width: 150,
      suppressSizeToFit: true,
      checkboxSelection: true,
      headerCheckboxSelection: true
    },
    {
      headerName: 'Image',
      width: 150,
      cellRenderer: (params) => {
        return `<img class="item-grid-thumbnail" 
          src="${_.get(_.pickBy(_.get(params, 'data.document', {}), (value, key) => { return value !== '' }), 'documentThumbnailPath', 'assets/images/default-grid-image.png')}" 
        />`;
      },
      filter: false,
      sortable: false
    },
    {
      headerName: 'Status',
      field: 'actionName',
      width: 150
    },
    {
      headerName: 'Notes',
      field: 'notes',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      }
    },
    {
      headerName: 'Position',
      field: 'position',
      width: 150,
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: '# of Facings',
      field: 'numberOfFacings',
      width: 150,
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'Internal SKU ID',
      field: 'internalSKUId'
    },
    {
      headerName: 'Item Description',
      field: 'itemDescription'
    },
    {
      headerName: 'Release Date',
      field: 'releaseDate',
      type: 'date',
      cellRenderer: dateRenderer
    },
    {
      headerName: 'UPC',
      field: 'upc',
      width: 150,
    },
    {
      headerName: 'Account',
      field: 'accountName'
    },
    {
      headerName: 'Class Code',
      field: 'classCode'
    },
    {
      headerName: 'Class Name',
      field: 'className'
    },
    {
      headerName: 'Subclass Code',
      field: 'subClassCode'
    },
    {
      headerName: 'Subclass Name',
      field: 'subClassName'
    },
    {
      headerName: 'Retek Perf Code',
      field: 'retekPerfCode',
      width: 150
    },
    {
      headerName: 'Regular Retail Price',
      field: 'regRetailPrice'
    },
    ...isRetailerTeam ? [{
      headerName: 'Promo Price',
      field: 'promoPrice',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor',
      cellClassRules: {
        'invalid-cell-value': (params) => {
          const promoPrice = _.get(params, 'value', 0);
          const regRetailPrice = _.get(params, 'data.regRetailPrice', 0);
          return promoPrice > regRetailPrice;
        }
      }
    }] : [],
    {
      headerName: 'B&M Promotion Forecast (Vendor)',
      field: 'bAndMPromotionForecastVendor',
      width: 290,
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (isRetailerTeam) {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'Dot com Forecast (Vendor)',
      field: 'dotcomForecastVendor',
      width: 235,
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (isRetailerTeam) {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'On Ad Week Forecast (Vendor)',
      field: 'onAdWeekForecastVendor',
      width: 270,
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (isRetailerTeam) {
          return false;
        }  else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'B&M Promotion Forecast (Best Buy)',
      field: 'bAndMPromotionForecastBestBuy',
      width: 300,
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam) {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'Dot com Forecast (Best Buy)',
      field: 'dotcomForecastBestBuy',
      width: 240,
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam) {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'On Ad Week Forecast (Best Buy)',
      field: 'onAdWeekForecastBestBuy',
      width: 270,
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam) {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'ROQ (Best Buy)',
      field: 'roqBestBuy',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam) {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'Dot Com ROQ (Best Buy)',
      field: 'roqDotComBestBuy',
      width: 225,
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam) {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'Retek Cost',
      field: 'retekCost'
    },
    {
      headerName: 'Base Cost',
      field: 'baseCost',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor',
      cellClassRules: {
        'invalid-cell-value': (params) => {
          const baseCost = _.get(params, 'value', 0);
          const regRetailPrice = _.get(params, 'data.regRetailPrice', 0);
          return baseCost > regRetailPrice;
        }
      }
    },
    {
      headerName: 'Base Cost Effective Date',
      field: 'baseCostEffectiveDate',
      type: 'date',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellRenderer: dateRenderer,
      cellEditor: 'datePicker'
    },
    {
      headerName: 'Net Cost',
      field: 'netCost',
      width: 150
    },
    {
      headerName: 'Margin Credit',
      field: 'marginCredit',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'Accrual %',
      field: 'accrualPercentage'
    },
    ...isRetailerTeam ? [{
      headerName: 'Operating Gross Margin',
      field: 'operatingGrossMargin'
    }] : [],
    ...isRetailerTeam ? [{
      headerName: 'Operating Gross Margin %',
      field: 'operatingGrossMarginPercentage'
    }] : [],
    ...isRetailerTeam ? [{
      headerName: 'Adjusted Gross Margin',
      field: 'adjustedGrossMargin'
    }] : [],
    ...isRetailerTeam ? [{
      headerName: 'Adjusted Gross Margin %',
      field: 'adjustedGrossMarginPercentage'
    }] : [],
    {
      headerName: 'Created By',
      field: 'createdByName'
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      type: 'date',
      cellRenderer: dateRenderer
    },
    {
      headerName: 'Last Updated By',
      field: 'modifiedByName'
    },
    {
      headerName: 'Last Updated Date',
      field: 'modifiedDate',
      type: 'date',
      cellRenderer: dateRenderer
    }
  ];
  return promotionItemDetailColDefs;
}

export const promotionPlannerWeeklyForecastColDefs = (weekDates = [], isRetailerTeam = false) => {
  const weeklyForecastColDefs = [];
  weeklyForecastColDefs.push({
    headerName: '',
    children: [
      // {
      //   headerName: '',
      //   field: 'select',
      //   pinned: 'left',
      //   width: 50,
      //   suppressSizeToFit: true,
      //   checkboxSelection: true
      // },
      {
        headerName: 'Status',
        field: 'actionName',
        pinned: 'left',
        width: 95
      },
      {
        headerName: 'Internal SKU ID',
        field: 'InternalSKUId',
        width: 140,
        pinned: 'left'
      },
      {
        headerName: 'Item Description',
        field: 'ItemDescription',
        width: 145,
        pinned: 'left'
      },
      {
        headerName: 'Release Date',
        field: 'ReleaseDate',
        pinned: 'left',
        width: 125,
        type: 'date',
        cellRenderer: dateRenderer
      },
      {
        headerName: 'UPC',
        field: 'UPC',
        pinned: 'left',
        width: 95
      },
      {
        headerName: 'Account',
        field: 'AccountName',
        width: 95,
        pinned: 'left'
      },
      {
        headerName: '# of Facings',
        field: 'NumberOfFacings',
        editable: (params) => {
          if (params.data.actionName === 'Deleted') {
            return false;
          } else if (!isRetailerTeam) {
            return false;
          } else {
            return true;
          }
        },
        cellEditor: 'numericCellEditor'
      },
      {
        headerName: 'B&M Promotion Forecast (Best Buy)',
        field: 'BAndMPromotionForecastBestBuy',
        width: 290,
        editable: (params) => {
          if (params.data.actionName === 'Deleted') {
            return false;
          } else if (!isRetailerTeam) {
            return false;
          } else {
            return true;
          }
        },
        cellEditor: 'numericCellEditor'
      },
      {
        headerName: 'Dot Com Forecast (Best Buy)',
        field: 'DotcomForecastBestBuy',
        width: 245,
        editable: (params) => {
          if (params.data.actionName === 'Deleted') {
            return false;
          } else if (!isRetailerTeam) {
            return false;
          } else {
            return true;
          }
        },
        cellEditor: 'numericCellEditor'
      },
      {
        headerName: 'On Ad Forecast (Best Buy)',
        field: 'OnAdWeekForecastBestBuy',
        width: 240,
        editable: (params) => {
          if (params.data.actionName === 'Deleted') {
            return false;
          } else if (!isRetailerTeam) {
            return false;
          } else {
            return true;
          }
        },
        cellEditor: 'numericCellEditor'
      }
    ]
  });
  weekDates.forEach((date: any) => {
    weeklyForecastColDefs.push({
      headerName: `${moment(date).format('MM/DD/YYYY')}`,
      field: `Week${date}`,
      marryChildren: true,
      headerClass: 'ag-column-group-class',
      cellStyle: { textAlign: 'center', justifyContent: 'center' },
      children: [
        {
          headerName: 'On Ad',
          field: `OnAd${date}`,
          headerClass: 'ag-column-group-first-header',
          cellStyle: { borderLeft: '2px solid lightgray' },
          editable: (params) => {
            if (params.data.actionName === 'Deleted') {
              return false;
            } else if (!isRetailerTeam) {
              return false;
            } else {
              return true;
            }
          },
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: extractValues(yesNoMapping)
          },
          filterParams: {
            valueFormatter: function (params) {
              return lookupValue(yesNoMapping, params.value);
            },
          },
          valueFormatter: params => {
            return lookupValue(yesNoMapping, params.value);
          }
        },
        ...isRetailerTeam ? [{
          headerName: 'Promo Price',
          field: `PromoPrice${date}`,
          editable: (params) => {
            if (params.data.actionName === 'Deleted') {
              return false;
            } else if (!isRetailerTeam) {
              return false;
            } else {
              return true;
            }
          },
          cellEditor: 'numericCellEditor'
        }] : [],
        {
          headerName: 'Margin Credit',
          field: `MarginCredit${date}`,
          editable: (params) => {
            if (params.data.actionName === 'Deleted') {
              return false;
            } else if (!isRetailerTeam) {
              return false;
            } else {
              return true;
            }
          },
          cellEditor: 'numericCellEditor'
        },
        {
          headerName: 'B&M Promo Week Forecast',
          field: `BAndMPromoForecast${date}`,
          width: 240,
          editable: (params) => {
            if (params.data.actionName === 'Deleted') {
              return false;
            } else if (!isRetailerTeam) {
              return false;
            } else {
              return true;
            }
          },
          cellEditor: 'numericCellEditor'
        },
        {
          headerName: 'Dot Com Week Forecast',
          field: `DotComForecast${date}`,
          width: 220,
          editable: (params) => {
            if (params.data.actionName === 'Deleted') {
              return false;
            } else if (!isRetailerTeam) {
              return false;
            } else {
              return true;
            }
          },
          cellEditor: 'numericCellEditor'
        }
      ]
    })
  });
  return weeklyForecastColDefs;
}

const yesNoMapping = {
  0: 'No',
  1: 'Yes'
};

const lookupValue = (mappings, key) => {
  return mappings[Number(key)];
}

const extractValues = (mappings) => {
  return (Object.keys(mappings)).map(Number);
}