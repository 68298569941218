import { AllCommunityModules, GridApi } from '@ag-grid-community/all-modules';
import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError } from 'rxjs/internal/observable/throwError';
import { max } from 'rxjs/operators';
import { ForecastSetting } from 'src/modules/items/item.model';
import { ConfigurationService } from 'src/services/configuration.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-new-item-planner-form',
  templateUrl: './new-item-planner-form.component.html',
  styleUrls: ['./new-item-planner-form.component.scss'],
})
export class NewItemPlannerFormComponent implements OnInit {
  @Input() items;
  @Input() accounts;
  @Input() launchCurvesList;
  @Input() seasonlaityIndexList = [];
  seasonlaityPatternProductList = [];
  seasonlaityPatternAccountList = [];
  @Input() newItemProductGroupList = [];
  @Input() newItemProductFamilyList = [];
  @Input() newItemParentProductList = [];
  @Input() newItemAccountRegionList = [];
  @Input() newItemAccountCountryList = [];
  @Input() newItemAccountDistributorList = [];
  @Input() calendarListDropdown = [];
  @Input() channelList = [];
  @Input() accountSettingList = [];
  @Input() timeframeList = [];
  @Input() selectedItem;
  @Input() editPermission;
  @Input() viewPermission;
  @Output() resetTreeList = new EventEmitter();
  selectedItemsList = [];
  productGroupList = [];
  brandList = [];
  productFamilyList = [];
  parentProductList = [];
  dataModel: any = {};
  productGroupLoading: boolean;
  brandLoading: boolean;
  productFamilyLoading: boolean;
  parentProductLoading: boolean;
  public gridOptions: any;
  defaultColDef: any;
  headerHeight = 45;
  public gridApi: GridApi;
  public columnDefs = [
    {
      headerName: '',
      field: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      resizable: true,
      width: 50,
    },
    {
      headerName: 'Account',
      field: 'accountName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Channel',
      field: 'channelName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Region',
      field: 'regionName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Country',
      field: 'countryName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Forecast Start Date',
      field: 'foreCastStartDate',
      minWidth: 50,
      resizable: true,
      cellRenderer: (params) => {
        return params.value ? formatDate(params.value, 'dd MMM, y', 'en') : '';
      },
    },
    {
      headerName: 'Forecast End Date',
      field: 'foreCastEndDate',
      minWidth: 50,
      resizable: true,
      cellRenderer: (params) => {
        return params.value ? formatDate(params.value, 'dd MMM, y', 'en') : '';
      },
    },
    {
      headerName: 'Baseline Run Rate',
      field: 'baselineRunRate',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Launch Curve',
      field: 'launchCurveName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Seasonality Index',
      field: 'seasonalityIndexName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Seasonality Pattren Product',
      field: 'seasonalityPatternProductName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Seasonality Pattren Account',
      field: 'seasonalityPatternAccountName',
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'Item/Account Status',
      field: 'active',
      cellRenderer: (params) => (params.value ? 'Active' : 'InActive'),
      minWidth: 50,
      resizable: true,
    },
    {
      headerName: 'NIP Status',
      field: 'nipActive',
      cellRenderer: (params) => (params.value ? 'Active' : 'InActive'),
      minWidth: 50,
      resizable: true,
    },
  ];
  public modules = AllCommunityModules;
  public rowSelection = 'multiple';
  public selectedGridNodes = [];
  constructor(private config: ConfigurationService, public spinner: NgxSpinnerService) {
    this.rowSelection = 'multiple';
    this.gridOptions = {
      angularCompileHeaders: true,
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
  };

  async ngOnInit() {
    this.brandLoading = true;
    this.brandList = await this.config
      .ProductSettingMappingBrandGetList({})
      .toPromise()
      .catch((error) => throwError(error));
    this.brandLoading = false;
    if (this.dataModel.brandId) {
      this.productGroupList = await this.config
        .ProductSettingMappingProductGroupGetList({
          brandId: this.dataModel.brandId,
        })
        .toPromise()
        .catch((error) => throwError(error));
    }
    if (this.dataModel.brandId && this.dataModel.productGroupId) {
      this.productFamilyList = await this.config
        .ProductSettingMappingProductFamilyGetList({
          productGroupId: this.dataModel.productGroupId,
        })
        .toPromise()
        .catch((error) => throwError(error));
    }
    if (
      this.dataModel.brandId &&
      this.dataModel.productGroupId &&
      this.dataModel.productFamilyId
    ) {
      this.parentProductList = await this.config
        .ProductSettingMappingParentProductGetList({
          productFamilyId: this.dataModel.productFamilyId,
        })
        .toPromise()
        .catch((error) => throwError(error));
    }
    this.config.AccountSettingMappingChannelGetList({}).subscribe((res) => {
      this.channelList = res;
    });
  }

  public GetProductGroupList = (brandId, resetFlag = true) => {
    this.productGroupLoading = true;
    this.config
      .ProductSettingMappingProductGroupGetList({ brandId: brandId })
      .subscribe((res) => {
        this.productGroupList = res;
        this.productFamilyList = [];
        this.parentProductList = [];
        if (resetFlag) {
          this.dataModel.productGroupId = null;
          this.dataModel.productFamilyId = null;
          this.dataModel.parentProductId = null;
        }
        this.productGroupLoading = false;
      });
  };
  public GetProductFamilyList = (productGroupId, resetFlag = true) => {
    this.productFamilyLoading = true;
    this.parentProductList = [];
    this.config
      .ProductSettingMappingProductFamilyGetList({
        productGroupId: productGroupId,
      })
      .subscribe((res) => {
        this.productFamilyList = res;
        if (resetFlag) {
          this.dataModel.productFamilyId = null;
          this.dataModel.parentProductId = null;
        }
        this.productFamilyLoading = false;
      });
  };
  public GetParentProductList = (productFamilyId, resetFlag = false) => {
    this.parentProductLoading = true;
    this.config
      .ProductSettingMappingParentProductGetList({
        productFamilyId: productFamilyId,
      })
      .subscribe((res) => {
        this.parentProductList = res;
        if (resetFlag) {
          this.dataModel.parentProductId = null;
        }
        this.parentProductLoading = false;
      });
  };
  public GetItemList() {
    if (this.items) {
      this.selectedItemsList = this.items.filter(
        (x) =>
          x.brandId == this.dataModel.brandId &&
          x.parentProductId == this.dataModel.parentProductId &&
          x.productFamilyId == this.dataModel.productFamilyId &&
          x.productGroupId == this.dataModel.productGroupId
      );
    }
  }
  createItemAccountLists() {
    if (this.dataModel.seasonalityIndexId) {
      const selectedSeasonalityIndex = this.seasonlaityIndexList.find(
        (el) => el.commonCodeValueId === this.dataModel.seasonalityIndexId
      );
      if (selectedSeasonalityIndex) {
        const itemHeirarchyValue = selectedSeasonalityIndex.value.split('/')[0];
        const accountHeirarchyValue =
          selectedSeasonalityIndex.value.split('/')[1];
        this.createProductList(itemHeirarchyValue);
        this.createAccountList(accountHeirarchyValue);
      }
    }
  }
  public createProductList = (itemHeirarchyValue) => {
    switch (itemHeirarchyValue) {
      case 'Brand': {
        this.seasonlaityPatternProductList = this.brandList;
        this.seasonlaityPatternProductList =
          this.seasonlaityPatternProductList =
            this.seasonlaityPatternProductList.map((el) => {
              return { id: el.brandId, name: el.brandName };
            });
        break;
      }
      case 'Product Group': {
        this.seasonlaityPatternProductList = this.newItemProductGroupList;
        this.seasonlaityPatternProductList =
          this.seasonlaityPatternProductList =
            this.seasonlaityPatternProductList.map((el) => {
              return { id: el.productGroupId, name: el.productGroupName };
            });
        break;
      }
      case 'Product Family': {
        this.seasonlaityPatternProductList = this.newItemProductFamilyList;
        this.seasonlaityPatternProductList =
          this.seasonlaityPatternProductList =
            this.seasonlaityPatternProductList.map((el) => {
              return { id: el.productFamilyId, name: el.productFamilyName };
            });
        break;
      }
      case 'Parent Product': {
        this.seasonlaityPatternProductList = this.newItemParentProductList;
        this.seasonlaityPatternProductList =
          this.seasonlaityPatternProductList =
            this.seasonlaityPatternProductList.map((el) => {
              return { id: el.parentProductId, name: el.parentProductName };
            });
        break;
      }
      case 'Item': {
        this.seasonlaityPatternProductList = this.items;
        this.seasonlaityPatternProductList =
          this.seasonlaityPatternProductList =
            this.seasonlaityPatternProductList.map((el) => {
              return { id: el.itemManagerId, name: el.description };
            });
        break;
      }
    }
  };
  public createAccountList = (accountHeirarchyValue) => {
    switch (accountHeirarchyValue) {
      case 'Channel': {
        this.seasonlaityPatternAccountList = this.channelList;
        this.seasonlaityPatternAccountList =
          this.seasonlaityPatternAccountList =
            this.seasonlaityPatternAccountList.map((el) => {
              return { id: el.channelId, name: el.channelName };
            });
        break;
      }
      case 'Region': {
        this.seasonlaityPatternAccountList = this.newItemAccountRegionList;
        this.seasonlaityPatternAccountList =
          this.seasonlaityPatternAccountList.map((el) => {
            return { id: el.continentId, name: el.continentName };
          });
        break;
      }
      case 'Country': {
        this.seasonlaityPatternAccountList = this.newItemAccountCountryList;
        this.seasonlaityPatternAccountList =
          this.seasonlaityPatternAccountList.map((el) => {
            return { id: el.countryId, name: el.countryName };
          });
        break;
      }
      case 'Distirbutor': {
        this.seasonlaityPatternAccountList = this.newItemAccountDistributorList;
        this.seasonlaityPatternAccountList =
          this.seasonlaityPatternAccountList.map((el) => {
            return { id: el.distributorId, name: el.distributorName };
          });
        break;
      }
      case 'Account': {
        this.seasonlaityPatternAccountList = this.accounts;
        this.seasonlaityPatternAccountList =
          this.seasonlaityPatternAccountList.map((el) => {
            return { id: el.itemAccountId, name: el.accountName };
          });
        break;
      }
    }
  };
  setValue = (selectedDropdown) => {
    switch (selectedDropdown.type) {
      case 'Timeframe': {
        this.dataModel.calendarIds = selectedDropdown.value;
        break;
      }
    }
  };
  async onSelectionChanged(event) {
    const nodes = event.api.getSelectedNodes();
    const selectedNodes = nodes.map((el) => el.data);
    this.selectedGridNodes = selectedNodes;
  }
  apply() {
    const nodes = this.gridApi.getSelectedNodes();
    this.accountSettingList.forEach((row) => {
      const seasonality = this.seasonlaityIndexList.find(
        (el) => el.commonCodeValueId === this.dataModel.seasonalityIndexId
      );
      const launchCurve = this.launchCurvesList.find(
        (el) => el.id === this.dataModel.launchCurveId
      );
      const seasonalityProdcut = this.seasonlaityPatternProductList.find(
        (el) => el.id === this.dataModel.seasonalityPatternProductId
      );
      const seasonalityAccount = this.seasonlaityPatternAccountList.find(
        (el) => el.id === this.dataModel.seasonalityPatternAccountId
      );
      if (
        this.selectedGridNodes.find(
          (r) => r.accountSettingId === row.accountSettingId
        )
      ) {
        (row.launchCurveName = launchCurve?.name || row.launchCurveName),
          (row.seasonalityIndexName =
            seasonality?.value || row.seasonalityIndexName),
          (row.seasonalityPatternProductName =
            seasonalityProdcut?.name || row.seasonalityPatternProductName),
          (row.seasonalityPatternAccountName =
            seasonalityAccount?.name || row.seasonalityPatternAccountName),
          (row.launchCurveId =
            this.dataModel.launchCurveId || row.launchCurveId),
          (row.seasonalityPatternProductId =
            this.dataModel.seasonalityPatternProductId ||
            row.seasonalityPatternProductId),
          (row.seasonalityPatternAccountId =
            this.dataModel.seasonalityPatternAccountId ||
            row.seasonalityPatternAccountId),
          (row.nipActive = this.dataModel.nipActive),
          (row.seasonalityIndexId =
            this.dataModel.seasonalityIndexId || row.seasonalityIndexId);
      }
    });
    this.gridApi.setRowData(this.accountSettingList);
    setTimeout(() => {
      const nodeIds = nodes.map((el) => el.data.accountSettingId);
      this.setSelectedItems(nodeIds);
    }, 1);
  }
  public getCalculation = () => {
    const nodes = this.gridApi.getSelectedNodes();
    let selectedAccounts = this.accountSettingList
      .filter((el) =>
        this.selectedGridNodes.find(
          (row) => row.accountSettingId === el.accountSettingId
        )
      )
      .map((el) => ({ ...el, oldItemId: this.dataModel.oldItemId }));
    const calendarIds = [];
    this.dataModel.calendarIds.forEach((element) => {
      element.forEach((calendarId) => {
        calendarIds.push(calendarId);
      });
    });
    const weeks = this.timeframeList
      .filter((el) => calendarIds.includes(el.calendarId))
      .map((el) => el.weekStartDate);
    var min = weeks.reduce(function (a, b) {
      return a < b ? a : b;
    });
    var max = weeks.reduce(function (a, b) {
      return a > b ? a : b;
    });
    this.config
      .GetNIPCalculation({
        accountSettings: selectedAccounts,
        weekMaxDate: max,
        weekMinDate: min,
      })
      .subscribe((res) => {
        const sellThroughSum = res
          .map((el) => el.sellThrough)
          .reduce((a, b) => a + b, 0);
        res.forEach((el) => {
          el.sellThroughPCT = el.sellThrough / sellThroughSum;
        });
        this.accountSettingList.forEach((el) => {
          const sellTH = res.find((row) => row.accountId === el.accountId);
          if (sellTH) {
            el.baselineRunRate =
              this.dataModel.baselineRunRate * sellTH.sellThroughPCT;
          }
        });
        this.gridApi.setRowData(this.accountSettingList);
        setTimeout(() => {
          const nodeIds = nodes.map((el) => el.data.accountSettingId);
          this.setSelectedItems(nodeIds);
        }, 1);
      });
  };
  setSelectedItems(nodeIds: Array<number>) {
    this.gridApi.forEachNode((node) => {
      if (nodeIds.includes(node.data.accountSettingId)) {
        node.setSelected(true);
      }
    });
  }
  public saveSettings() {
    const selectedAccounts = this.accountSettingList.filter((el) =>
      this.selectedGridNodes.find(
        (row) => row.accountSettingId === el.accountSettingId
      )
    );
    this.spinner.show();
    this.config
      .AccountSettingListUpdate({ accountSettings: selectedAccounts })
      .subscribe((res) => {
        this.spinner.hide();
        this.gridApi.setRowData(this.accountSettingList);
        this.dataModel = new ForecastSetting();
        this.seasonlaityPatternProductList = [];
        this.seasonlaityPatternAccountList = [];
        this.productGroupList = [];
        this.productFamilyList = [];
        this.parentProductList = [];
        this.selectedItemsList = [];
        this.resetTreeList.emit(true);
      }, error => {
        this.spinner.show();
      });
  }
}
