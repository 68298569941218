export const generateGridColumns = (duration) => {
    let monthsArray = [];
    if (duration == '1') {
        monthsArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    } else if (duration == '2') {
        monthsArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
    } else if (duration == '3') {
        monthsArray = ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    } else if (duration == '4') {
        monthsArray = ['Jan', 'Feb', 'Mar'];
    } else if (duration == '5') {
        monthsArray = ['Apr', 'May', 'Jun'];
    } else if (duration == '6') {
        monthsArray = ['Jul', 'Aug', 'Sep'];
    } else if (duration == '7') {
        monthsArray = ['Oct', 'Nov', 'Dec'];
    }
    const gridColumnDefs = [
        {
            headerName: 'Measures',
            field: 'measures',
            width: 200,
            minWidth: 200,
            resizable: false,
            editable: false,
            cellStyle: params => {
                return { color: 'black', backgroundColor: '#e5e5e5' };
            },
        },
        {
            headerName: 'Totals',
            field: 'totals',
            width: 200,
            minWidth: 200,
            resizable: false,
            editable: false,
            cellStyle: params => {
                return { color: 'black', backgroundColor: '#e5e5e5' };
            },
            valueFormatter: params => {
                return params.data.totals ? Number(params.data.totals).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''
            }
        }
    ] as any;
    monthsArray.forEach(m => {
        gridColumnDefs.push(
            {
                headerName: m,
                field: m.toLowerCase(),
                width: 150,
                minWidth: 150,
                resizable: true,
                editable: params => {
                    if (params.data.editable) {
                        return true;
                    } else return false;
                },
                cellStyle: params => {
                    if (params.data.editable) {
                        return;
                    } else return { color: 'black', backgroundColor: '#e5e5e5' };
                },
                valueFormatter: params => {
                    return params.data[m.toLowerCase()] ? Number(params.data[m.toLowerCase()]).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''
                }
            }
        )
    });
    return gridColumnDefs;
}
