import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllCommunityModules, CellClickedEvent } from '@ag-grid-community/all-modules';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SkuFormComponent } from './sku-form/sku-form.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ViewRendererComponent } from 'src/common/view-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-sku-listing',
  templateUrl: './sku-listing.component.html',
  styleUrls: ['./sku-listing.component.css']
})
export class SkuListingComponent implements OnInit {
  public classList = [{ classCode: 1, classCodeDescription: 'TV' }, { classCode: 2, classCodeDescription: 'Theatrical' }];
  public subClassList = [
    { subClassCode: 1, subClassCodeDescription: 'DVD' },
    { subClassCode: 2, subClassCodeDescription: 'BD' },
    { subClassCode: 3, subClassCodeDescription: '3D' },
    { subClassCode: 4, subClassCodeDescription: 'UHD' }
  ];
  public columnDefs = [
    {
      headerName: 'Item ID',
      field: 'skuId',
      pinned: 'left',
      width: 150
    },
    {
      headerName: 'Title ID',
      field: 'titleId',
      pinned: 'left',
      width: 150
    },
    {
      headerName: 'Description',
      field: 'itemDesciption',
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openModal.bind(this),
      },
    },
    {
      headerName: 'Street Date',
      field: 'streetDate',
      cellRenderer: (params) => {
        return `${params.value ? (new Date(params.value)).toLocaleDateString() : ''}`;
      }
    },
    {
      headerName: 'Customer',
      field: 'customer'
    },
    {
      headerName: 'Class',
      field: 'classCode',
      // cellRenderer: (params) => {
      //   const subClass = this.classList.find(sub => sub.classCode === Number(params.value));
      //   return `${subClass ? subClass.classCodeDescription : ''}`;
      // }
    },
    {
      headerName: 'Subclass',
      field: 'subClassCodeDescription',
      // cellRenderer: (params) => {
      //   const subClass = this.subClassList.find(sub => sub.subClassCode === params.value);
      //   return `${subClass ? subClass.subClassCodeDescription : ''}`;
      // }
    },
    {
      headerName: 'Item Plan',
      field: '',
      resizable: true,
      filter: false,
      pinned: 'right',
      cellRenderer: 'viewRenderer',
      cellRendererParams: {
        onClick: this.openViewRenderer.bind(this),
      },
      width: 150
    },
    {
      headerName: '',
      field: 'delete',
      resizable: true,
      filter: false,
      pinned: 'right',
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveGoalDialog.bind(this),
      },
      width: 80
    }
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public skuList = [];
  public modules = AllCommunityModules;
  public user: any;
  constructor(public router: Router,
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SkuFormComponent>,
    public matDialogRef: MatDialogRef<any>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent,
        nameRenderer: NameRendererComponent,
        viewRenderer: ViewRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    this.getSKUList();
  }
  public getSKUList = () => {
    this.configurationService.SKUGetAll({}).subscribe(res => {
      this.skuList = res;
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.itemDesciption }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeSKU(row.rowData);
      }
    });
  }
  openViewRenderer(row: any): void {
    const selector = {
      id: '451',
      name: 'Item Plan',
      selector: 'app-item-plan',
      displayName: 'Item Plan',
      data: {}
    };
    this.configurationService.menuRendererSubject.next(selector);
  }
  public removeSKU(selectedItem: any) {
    if (selectedItem) {
      const model = {
        ...selectedItem,
        active: false
      };
      this.spinner.show();
      this.configurationService.SKUActivate(model).subscribe(res => {
        if (res) {
          this.spinner.hide();
          this.toastr.success('Master Items', 'Item removed successfully');
          this.skuList = this.skuList.filter(row => row.skuId !== model.skuId);
          this.gridOptions.api.setRowData(this.skuList);
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('Master Items', error.error.text);
      });
    }
  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  // onCellClicked($event: CellClickedEvent) {
  //   if ($event && $event.colDef.field === 'itemDesciption') {
  //     this.openModal($event.data);
  //   }
  // }
  public openModal(data?: any) {
    const dialogRef = this.dialog.open(SkuFormComponent, { width: '800px', data: { ...data } });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSKUList();
      }
    });
  }
}
