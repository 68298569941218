<h1 mat-dialog-title>Select Planner(s) to open</h1>
<div mat-dialog-content>
  <table class="table">
    <thead>
      <th>File Name</th>
      <th>Date Created</th>
      <th>Last Updated</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
    </thead>
    <tbody>
      <tr *ngFor="let version of versionList">
        <td>{{version.name}}</td>
        <td>{{version.createdDate | date: 'MM/dd/yyyy'}}</td>
        <td>{{version.modifiedDate | date: 'MM/dd/yyyy'}}</td>
        <td>
          <mat-checkbox [(ngModel)]="version.checked" [ngModelOptions]="{standalone: true}"></mat-checkbox>
        </td>
        <td>
          <mat-icon class="pointer" (click) = "deleteVersion(version)">delete_outline</mat-icon>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="action-panel text-right">
  <button mat-raised-button class="btn btn-outline m-left" color="primary" (click)="close()">Cancel</button>
  <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="openPlanner()">Open
    Planners</button>
</div>
