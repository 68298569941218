<div style="position: relative;" class="w-100">
    <mat-form-field appearance="fill">
        <input matInput [disabled]="disabled" [value]="selectedDate | date : 'MM/dd/yy'" readonly (click)="toggleCalendar()"/>
        <mat-icon matSuffix (click)="toggleCalendar()">calendar_today</mat-icon>
    </mat-form-field>

    <div *ngIf="showCalendar" class="nrf-calendar d-flex flex-column align-items-center mb-3">
        <h4 class="d-flex align-items-center"><mat-icon matTooltip="previous" matTooltipPosition="above" class="mx-2" (click)="changeYear(false)">chevron_left</mat-icon>{{selectedYear}}<mat-icon matTooltip="next" matTooltipPosition="above" class="mx-2" (click)="changeYear(true)">chevron_right</mat-icon></h4>
        <h6 class="d-flex align-items-center justify-content-between"><mat-icon matTooltip="previous" matTooltipPosition="above" class="mx-2" (click)="changeMonth(false)">chevron_left</mat-icon>{{monthsData[selectedMonth].MonthName}}<mat-icon matTooltip="next" matTooltipPosition="above" class="mx-2" (click)="changeMonth(true)">chevron_right</mat-icon></h6>
        <div class="d-flex align-items-center pt-1" style="border-top: 1px solid black;"><label *ngFor="let day of weekDays" class="m-0 dayLabel text-center">{{day}}</label></div>
        <div class="week d-flex align-items-center" *ngFor="let week of weeks">
            <div (click)="selectDate(dateItem.date)" class="day d-flex align-items-center justify-content-center"
            [ngClass]="[selectedDate?.toISOString()==dateItem.date.toISOString()?'selected':'', dateItem.class]"
            *ngFor="let dateItem of week">
            <div class="events" *ngIf="dateItem.events.length">
                <div *ngFor="let event of dateItem.events" [ngStyle]="{background: event.ColorHex}" class="event" matTooltip="{{event.CalendarEventName}}" matTooltipPosition="above">
            </div>
            </div>
                <label class="m-0">{{dateItem.date | date : 'dd'}}</label>
            </div>
        </div>
    </div>
</div>
