<div class="container">
  <form [formGroup]="calenderActivityForm" class="form-fields">
    <div class="row">
      <div class="col-md-6 form-group">
        <mat-form-field class="example-full-width">
          <input placeholder="Season Name" formControlName="description" matInput>
          <mat-error *ngIf = "submitted && calenderActivityForm.controls.description.hasError('required')">*Reqiured Field</mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="col-md-4 form-group">
        <mat-form-field class="example-full-width">
          <input placeholder="Calender Abbreviation" formControlName="abbreviation" matInput>
        </mat-form-field>
      </div> -->
      <div class="col-md-6 form-group">
        <label></label>
        <mat-slide-toggle formControlName="active">Active
        </mat-slide-toggle>
      </div>
    </div>
    <div class="action-panel text-right">
      <button mat-raised-button class="btn btn-primary m-left" color="primary"
        (click)="onSubmit()">{{isEdit ? 'Update' : 'Create'}}</button>
      <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="Cancel_Click()">Cancel</button>
    </div>
  </form>
</div>
