import { Component, OnInit, ViewEncapsulation, TemplateRef, ViewChild, ElementRef, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { Observable, of, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { NgxToasterService } from '../../../../../services/ngx-toaster.service';
import { LocalstorageService } from '../../../../../services/localstorage.service';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { ForecastMeasuresComponent } from '../forecast-measures/forecast-measures.component';
import { ForecastConfirmationModalComponent } from '../forecast-confirmation-modal/forecast-confirmation-modal.component';
import { USER_ID } from '../../../../../common/keys';

@Component({
  selector: 'app-fb-forecast-graph',
  templateUrl: './fb-forecast-graph.component.html',
  styleUrls: ['./fb-forecast-graph.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FbForecastGraphComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dropdownObjects;
  @Input() yearListGrid;
  width = '100%';
  height = '500';
  type = "msline";
  dataFormat = "json";
  dataSource;
  public submitted = false;
  public isEdit = false;
  public columnDefs = [];
  private gridApi: any;
  dropdownSettings = {};
  dropdownSettingsSingle = {};
  public tenureTypeList = [];
  public tenureTypeListTemp = [];
  public rowList = [];
  public goalTypeList = [];
  public gridOptions: any;
  public getRowHeight;
  public getRowStyle;
  public headerHeight;
  public modules = AllCommunityModules;
  public clientId = 1;
  public channel = null;
  public csvRecords: any;
  public customerSelectedList = [];
  public selectedItemIds = [];
  public timeframeSelectedList = [];
  public itemSeletedList = [];
  public timeframeList = [];
  public versionList = [];
  public monthList = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredCustomers: Observable<any[]>;
  filteredItems: Observable<any[]>;
  filteredTimeframe: Observable<any[]>;
  public seletedYear = null;
  public seletedVersion = null;
  public selectedMonth = null;
  public defaultRow = {};
  public selectAll = true;
  tempList = [];
  totalCalculatedList = [];
  chartDatasets = [];
  simulatedChartDatasets = [];
  barChartDatasets = [];
  simulatedBarChartDatasets = [];
  xAxis = [];
  yAxis = [];
  xAxisSimulated = [];
  view = [700, 700];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  legendTitle = 'Legend';
  legendPosition = 'right';
  showXAxisLabel = true;
  xAxisLabel = 'Sales QTY';
  showYAxisLabel = true;
  yAxisLabel = 'Time Period';
  showGridLines = true;
  innerPadding = '10%';
  animations: boolean = true;
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5']
  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#7aa3e5']
  };

  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = '';
  tempColDefs = [];
  yearListTemp = [];
  yearListForFilter = [];
  graphList = [];
  @Input() leftGroupList = [];
  @Input() measureList = [];
  tempGroupList = [];
  labelList;
  channelSelectedList;
  graphChooserList = [
    { tag: 'Algo ST', name: 'Algo Baseline ST Forecast', key: 'BaselineSTForecast', checked: true },
    { tag: 'Algo ST', name: 'Algo Promo Units', key: 'AlgoPromoUnits', checked: false },
    { tag: 'Algo ST', name: 'Algo Total ST Fcst', key: 'AlgoTotalSTFcst', checked: true },
    { tag: 'Algo ST', name: 'Algo Constrained ST Fcst', key: 'AlgoConstrainedSTFcst', checked: false },
    { tag: 'Algo ST', name: 'Algo Total ST Fcst var to LY', key: 'AlgoTotalSTFcstvartoLY', checked: false },
    { tag: 'Algo ST', name: 'Algo Total ST Fcst var to DP Total ST Fcst', key: 'AlgoTotalSTFcstvartoDPTotalSTFcst', checked: false },
    { tag: 'Algo ST', name: 'Algo Total ST Fcst var to CO Total ST Fcst', key: 'AlgoTotalSTFcstvartoCOTotalSTFcst', checked: false },
    { tag: 'Algo ST', name: 'Algo Total ST Fcst var to Consensus ST Fcst', key: 'AlgoTotalSTFcstvartoConsensusSTFcst', checked: false },
    { tag: 'Demand Planning ST', name: 'DP Baseline ST Fcst', key: 'DPBaselineSTFcst', checked: false },
    { tag: 'Demand Planning ST', name: 'DP Manual Fcst Adjustment', key: 'DPManualFcstAdjustment', checked: false },
    { tag: 'Demand Planning ST', name: 'DP Promo Units', key: 'DPPromoUnits', checked: false },
    { tag: 'Demand Planning ST', name: 'DP Total ST Fcst', key: 'DPTotalSTFcst', checked: true },
    { tag: 'Demand Planning ST', name: 'DP Constrained ST Fcst', key: 'DPConstrainedSTFcst', checked: false },
    { tag: 'Demand Planning ST', name: 'DP Total ST Fcst var to LY', key: 'DPTotalSTFcstvartoLY', checked: false },
    { tag: 'Demand Planning ST', name: 'DP Total ST Fcst var to CO Total ST Fcst', key: 'DPTotalSTFcstvartoCOTotalSTFcst', checked: false },
    { tag: 'Demand Planning ST', name: 'DP Total ST Fcst var to Consensus ST Fcst', key: 'DPTotalSTFcstvartoConsensusSTFcst', checked: false },
    { tag: 'Demand Planning ST', name: 'DP Total ST Fcst var to Algo Total ST Fcst', key: 'DPTotalSTFcstvartoAlgoTotalSTFcst', checked: false },
    { tag: 'Channel Ops ST', name: 'CO Baseline ST Fcst', key: 'COBaselineSTForecast', checked: false },
    { tag: 'Channel Ops ST', name: 'CO Manual Fcst Adjustment', key: 'COManualFcstAdjustment', checked: false },
    { tag: 'Channel Ops ST', name: 'CO Promo Units', key: 'COPromoUnits', checked: false },
    { tag: 'Channel Ops ST', name: 'CO Total ST Fcst', key: 'COTotalSTFcst', checked: true },
    { tag: 'Channel Ops ST', name: 'CO Constrained ST Fcst', key: 'COConstrainedSTFcst', checked: false },
    { tag: 'Channel Ops ST', name: 'CO Total ST Fcst var to LY', key: 'COTotalSTFcstvartoLY', checked: false },
    { tag: 'Channel Ops ST', name: 'CO Total ST Fcst var to DP Total ST Fcst', key: 'COTotalSTFcstvartoDPTotalSTFcst', checked: false },
    { tag: 'Channel Ops ST', name: 'CO Total ST Fcst var to Consensus ST Fcst', key: 'COTotalSTFcstvartoConsensusSTFcst', checked: false },
    { tag: 'Channel Ops ST', name: 'CO Total ST Fcst var to Algo Total ST Fcst', key: 'COTotalSTFcstvartoAlgoTotalSTFcst', checked: false },
    { tag: 'Collaborative ST', name: 'Customer ST Fcst', key: 'CustomerSTFcst', checked: true },
    { tag: 'Collaborative ST', name: 'Consensus ST Fcst', key: 'ConsensusSTFcst', checked: true },
    { tag: 'Collaborative ST', name: 'Consensus ST Fcst var to Customer ST Fcst', key: 'ConsensusSTFcstvartoCustomerSTFcst', checked: false },
    // { tag: 'Collaborative ST', name: 'Constrained ST Fcst', key: 'ConstrainedSTFcst', checked: true },
    // { tag: 'Collaborative ST', name: 'Constrained ST Fcst var to Consensus ST Fcst', key: 'ConstrainedSTFcstvartoConsensusSTFcst', checked: false },
    { tag: 'Collaborative ST', name: 'Projected Lost Sales', key: 'ProjectedLostSales', checked: false },
    { tag: 'Collaborative ST', name: 'Actual ST', key: 'ActualST', checked: false },
    { tag: 'Collaborative ST', name: 'Weekly Trend', key: 'WeeklyTrend', checked: false },
    { tag: 'Collaborative ST', name: 'Promo Indicator', key: 'PromoIndicator', checked: false },
    { tag: 'Collaborative ST', name: 'Customer In-Stock Estimate', key: 'CustomerInStockEstimate', checked: false },
    { tag: 'Collaborative ST', name: 'Actual Var to Consensus Fcst Units', key: 'ActualVartoConsensusFcstUnits', checked: false },
    { tag: 'Collaborative ST', name: 'Actual Var to Consensus Fcst %', key: 'ActualVartoConsensusFcstPst', checked: false },
    { tag: 'Last Year ST', name: 'LY Actual ST', key: 'LYActualST', checked: false },
    { tag: 'Last Year ST', name: 'LY Promo Indicator', key: 'LYPromoIndicator', checked: false },
    { tag: 'Last Year ST', name: 'LY Customer In-Stock Estimate', key: 'LYCustomerInStockEstimate', checked: false },
    { tag: 'Last Year ST', name: 'LY Consensus ST Fcst', key: 'LYConsensusFcst', checked: false },
    { tag: 'Last Year ST', name: 'LY Actual to Consensus Fcst Var %', key: 'LYActualtoConsensusFcstVarPst', checked: false },
    { tag: 'Last Year ST', name: 'LY Actual to Consensus Fcst Var Units', key: 'LYActualtoConsensusFcstVarUnits', checked: false },
    { tag: 'ST Accuracy', name: 'Customer ST Fcst vs. Actual ST', key: 'CustomerSTFcstvsActualST', checked: false },
    { tag: 'ST Accuracy', name: 'Algo Total ST Fcst vs. Actual ST', key: 'AlgoTotalSTFcstvsActualST', checked: false },
    { tag: 'ST Accuracy', name: 'CO Total ST Fcst vs. Actual ST', key: 'COTotalSTFcstvsActualST', checked: false },
    { tag: 'ST Accuracy', name: 'DP Total ST Fcst vs Actual ST', key: 'DPTotalSTFcstvsActualST', checked: false },
    // { tag: 'ST Accuracy', name: 'Constrained ST Fcst vs. Actual ST', key: 'ConstrainedSTFcstvsActualST', checked: false },
    { tag: 'ST Accuracy', name: 'Consensus ST Fcst vs. Actual ST', key: 'ConsensusSTFcstvsActualST', checked: false },
    { tag: 'Collaborative SI', name: 'Customer Requested Sell-In (Receive Wk)', key: 'CustomerRequestedSellInReceiveWk', checked: false },
    { tag: 'Collaborative SI', name: 'Final Sell-In Commit (Receive Wk)', key: 'FinalSellInCommitReceiveWk', checked: false },
    { tag: 'Collaborative SI', name: 'Final Sell-In Commit var to Customer Request', key: 'FinalSellInCommitvartoCustomerRequest', checked: false },
    { tag: 'Collaborative SI', name: 'Algo Sell-In Fcst (Receive Wk)', key: 'AlgoSellInFcstReceiveWk', checked: false },
    { tag: 'Collaborative SI', name: 'DP Sell-In Fcst (Receive Wk)', key: 'DPSellInFcstReceiveWk', checked: false },
    { tag: 'Collaborative SI', name: 'CO Sell-In Fcst (Receive Wk)', key: 'COSellInFcstReceiveWk', checked: false },
    { tag: 'Collaborative SI', name: 'Consensus Sell-In Fcst (Receive Wk)', key: 'ConsensusSellInFcstReceiveWk', checked: false },
    { tag: 'Collaborative SI', name: 'Final Sell-In Commits (Ship Wk)', key: 'FinalSellInCommitsShipWk', checked: false },
    { tag: 'Customer Inventory', name: 'Customer IOH Projection', key: 'CustomerIOHProjection', checked: false },
    { tag: 'Customer Inventory', name: 'IOH Projection', key: 'IOHProjection', checked: false },
    { tag: 'Customer Inventory', name: 'Customer FWOS Calc', key: 'CustomerFWOSCalc', checked: false },
    { tag: 'Customer Inventory', name: 'FB FWOS Calc', key: 'FBFWOSCalc', checked: false },
    { tag: 'Customer Inventory', name: 'FWOS var to Customer Target', key: 'FWOSvartoCustomerTarget', checked: false },
    { tag: 'Customer Inventory', name: 'FWOS var to FB Target', key: 'FWOSvartoFBTarget', checked: false },
    { tag: 'Customer Inventory', name: 'Customer Store OH', key: 'CustomerStoreOH', checked: false },
    { tag: 'Customer Inventory', name: 'Customer DC OH', key: 'CustomerDCOH', checked: false },
    { tag: 'FB Inventory', name: 'FB Total On Hand', key: 'FBTotalOnHand', checked: false },
    { tag: 'FB Inventory', name: 'FB Available Allocation', key: 'FBAvailableAllocation', checked: false },
    { tag: 'FB Inventory', name: 'On Order', key: 'OnOrder', checked: false },
    { tag: 'KPIs', name: 'On Time Delivery Performance', key: 'OnTimeDeliveryPerformance', checked: false },
    { tag: 'KPIs', name: 'On Time In Full', key: 'OnTimeInFull', checked: false },
    { tag: 'KPIs', name: 'Customer Chargebacks', key: 'CustomerChargebacks', checked: false },
  ]
  public sub: Subscription;
  savedMeasures: any[];
  profileMeasureList: any;
  selectedProfile: any;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    public dialogRef: MatDialogRef<any>,
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef) {
  }

  openDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(templateRef, { width: '500px', data: data || {} });
  }
  ngOnInit() {
    this.tempGroupList = JSON.parse(JSON.stringify(this.leftGroupList));
    this.sub = this.configurationService.yearListUpdated$.subscribe(res => {
      if (res) {
        this.yearListGrid = [];
        this.yearListGrid = res;
        this.mapListsForMap();
        this.cdr.detectChanges();
      }
    })
  }
  ngOnChanges(simpleChange: SimpleChanges) {
    if (simpleChange && simpleChange.yearListGrid && simpleChange.yearListGrid.currentValue) {
      this.mapListsForMap();
    }
    if (simpleChange && simpleChange.measureList && simpleChange.measureList.currentValue) {
      this.measureList = simpleChange.measureList.currentValue
      this.savedMeasures = this.measureList
    }
  }
  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
  checkIfInList = (key: string) => {
    return this.measureList.find(el => el.code === key && el.checked);
  }
  public mapListsForMap = () => {
    this.graphList = [];
    const chartList = []
    this.graphChooserList.forEach(row => {
      if (row.checked && this.checkIfInList(row.key)) {
        let obj = {};
        obj[row.key] = [];
        chartList.push(obj);
      }
    });
    this.yearListGrid.forEach(year => {
      year.halfList.forEach(half => {
        half.quarterList.forEach(quarter => {
          quarter.monthList.forEach(month => {
            month.weekList.forEach(week => {
              let object = {
                ...week.GlobalMeasures,
                title: week.title
              }
              this.graphList.push(object);
              chartList.forEach((el) => {
                let obj = {};
                obj[week.title] = object[Object.keys(el)[0]];
                el[Object.keys(el)[0]].push(obj);
              })
            });
          })
        })
      })
    });
    this.applyGraph(chartList);
  }
  getSeries = (object) => {
    const objList = [];
    Object.keys(object).forEach(key => {
      if (key !== 'secondValue' && key !== 'firstValue' && key !== 'columnValue') {
        const newObject = {
          name: key,
          value: Number(object[key])
        }
        objList.push(newObject)
      }
    });
    return objList;
  }
  applyGraph(totalCalculatedList) {
    this.dataSource = null;
    const data = {
      chart: {
        caption: "",
        yaxisname: "",
        subcaption: "",
        showhovereffect: "1",
        lineThickness: "4",
        "width": "100%",
        "height": "150px",
        numbersuffix: "",
        "paletteColors": "#8D009D,#F8486E, #1C9FAD, #2480D3",
        drawcrossline: "1",
        plottooltext: "$seriesName <b>$dataValue</b> ",
        theme: "fusion"
      },
      categories: [
        {
          category: this.getLabels()
        }
      ],
      dataset: totalCalculatedList.map(item => ({ seriesname: this.graphChooserList.find(el => el.key === Object.keys(item)[0]).name, data: item[Object.keys(item)[0]].map(row => ({ value: row[Object.keys(row)[0]] ? +row[Object.keys(row)[0]] : 0 })) }))
    };
    this.dataSource = data as any;
  }
  openMesuresDialog(){
    this.profileMeasureList = JSON.parse(JSON.stringify(this.savedMeasures.map(row => ({ ...row, checked: false }))))
    this.dialog.open(ForecastMeasuresComponent, {
      width: '1200px',
      panelClass: 'fb-dialog-wrapper',

      data: {measureList : this.profileMeasureList },
    }).afterClosed().subscribe(command => {
      if(command){
        if(command.isCreateProfile){
          this.selectedProfile = command
          this.dialog.open(ForecastConfirmationModalComponent, {
            width: '700px',
            data: { label: 'What would you like to name your profile?', isOnLoad: false, changeNameConfirmation: true,
            isInputRequired : true, inputLabel : 'Enter name'},
            disableClose: true
          }).afterClosed().subscribe(command => {
            if(command.isNameSave){
              let selectedMeasures =  this.selectedProfile.measureList.filter(x=>x.checked)
              this.measureList = selectedMeasures
              let measureIds = ''
              selectedMeasures.forEach(element => {
                measureIds = measureIds + element.measureId + ','

              });
              measureIds = measureIds.slice(0, -1)
              let model = {
                "fbMeasureProfileId": 0,
                "measureIds": measureIds,
                "fbMeasureProfileName": command.name,
                "userId":  this.storage.get(USER_ID),
                "applied":  this.selectedProfile.isFirst
                }
                this.configurationService.FbForecastSaveMeasureProfile(model).subscribe(resp=>{
                  this.mapListsForMap();
                })
            }


          })
        }
        if(command.profileApplied){
          this.selectedProfile = command
          let selectedMeasures =  this.selectedProfile.measureList.filter(x=>x.checked)
          if(selectedMeasures.length)
          this.measureList = selectedMeasures
          else
          this.measureList = this.savedMeasures
          this.mapListsForMap();
        }
        if(command.maxCountReached){
          this.dialog.open(ForecastConfirmationModalComponent, {
            width: '700px',
            panelClass: 'fb-dialog-wrapper',
            data: {
              label: 'Max profile creation limit has been reached. You can create only 20 profiles', isOnLoad: false, confirmation: true
            },
            disableClose: true
          })
        }
      }

    });
  }
  public getLabels = () => {
    // const el = totalCalculatedList[0];
    const list = [];
    const dateList = this.graphList.map(row => ({ label: row.title }));
    return dateList;
    // Object.keys(el).forEach(key => {
    //   const columns = this.timeframeSelectedList.map(res => res.item_id.toLowerCase());
    //   if (key !== 'secondValue' && key !== 'firstValue' && key !== 'columnValue' && columns.length < 1) {
    //     list.push({ label: this.getKeyValue(key) });
    //   }
    //   if (key !== 'secondValue' && key !== 'firstValue' && key !== 'columnValue' && columns.length > 0) {
    //     const flag = columns.findIndex((a, b) => key.indexOf(a) > -1) > -1;
    //     if (flag) {
    //       list.push({ label: this.getKeyValue(key) });
    //     }
    //   }
    // });
    // return list;
  }
  getKeyValue(key) {
    const list = [
      { key: 'janw1', value: '01/05/2020' },
      { key: 'janw2', value: '01/12/2020' },
      { key: 'janw3', value: '01/19/2020' },
      { key: 'janw4', value: '01/26/2020' },
      { key: 'ttlJanuary', value: 'Total January' },
      { key: 'febw1', value: '02/02/2020' },
      { key: 'febw2', value: '02/09/2020' },
      { key: 'febw3', value: '02/16/2020' },
      { key: 'febw4', value: '02/23/2020' },
      { key: 'ttlFebruary', value: 'Total February' },
      { key: 'marchw1', value: '03/01/2020' },
      { key: 'marchw2', value: '03/08/2020' },
      { key: 'marchw3', value: '03/15/2020' },
      { key: 'marchw4', value: '03/22/2020' },
      { key: 'marchw5', value: '03/29/2020' },
      { key: 'ttlMarch', value: 'Total March' },
      { key: 'q1', value: 'Q1' },
      { key: 'aprilw1', value: '04/05/2020' },
      { key: 'aprilw2', value: '04/12/2020' },
      { key: 'aprilw3', value: '04/19/2020' },
      { key: 'aprilw4', value: '04/26/2020' },
      { key: 'ttlApril', value: 'Total April' },
      { key: 'mayw1', value: '05/03/2020' },
      { key: 'mayw2', value: '05/10/2020' },
      { key: 'mayw3', value: '05/17/2020' },
      { key: 'mayw4', value: '05/24/2020' },
      { key: 'ttlMay', value: 'Total May' },
      { key: 'junew1', value: '05/31/2020' },
      { key: 'junew2', value: '06/07/2020' },
      { key: 'junew3', value: '06/14/2020' },
      { key: 'junew4', value: '06/21/2020' },
      { key: 'junew5', value: '06/28/2020' },
      { key: 'ttlJune', value: 'Total June' },
      { key: 'q2', value: 'Q2' },
      { key: 'julyw1', value: '07/05/2020' },
      { key: 'julyw2', value: '07/12/2020' },
      { key: 'julyw3', value: '07/19/2020' },
      { key: 'julyw4', value: '07/26/2020' },
      { key: 'ttlJuly', value: 'Total July' },
      { key: 'augw1', value: '08/02/2020' },
      { key: 'augw2', value: '08/09/2020' },
      { key: 'augw3', value: '08/16/2020' },
      { key: 'augw4', value: '08/23/2020' },
      { key: 'ttlAugust', value: 'Total August' },
      { key: 'sepw1', value: '09/30/2020' },
      { key: 'sepw2', value: '09/06/2020' },
      { key: 'sepw3', value: '09/13/2020' },
      { key: 'sepw4', value: '09/20/2020' },
      { key: 'sepw5', value: '09/27/2020' },
      { key: 'ttlSeptember', value: 'Total September' },
      { key: 'q3', value: 'Q3' },
      { key: 'octw1', value: '10/04/2020' },
      { key: 'octw2', value: '10/11/2020' },
      { key: 'octw3', value: '10/18/2020' },
      { key: 'octw4', value: '10/25/2020' },
      { key: 'ttlOctober', value: 'Total October' },
      { key: 'novw1', value: '11/01/2020' },
      { key: 'novw2', value: '11/08/2020' },
      { key: 'novw3', value: '11/15/2020' },
      { key: 'novw4', value: '11/22/2020' },
      { key: 'ttlNovember', value: 'Total November' },
      { key: 'novw5', value: '11/29/2020' },
      { key: 'decw1', value: '12/06/2020' },
      { key: 'decw2', value: '12/13/2020' },
      { key: 'decw3', value: '12/20/2020' },
      { key: 'decw4', value: '12/27/2020' },
      { key: 'ttlDecember', value: 'Total December' },
      { key: 'q4', value: 'Q4' },
      { key: 'totalYear', value: 'Total Year' },
    ];
    return list.find(item => item.key === key) ? list.find(item => item.key === key).value : '';
  }
}
