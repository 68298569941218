<div class="d-flex align-items-center justify-content-end w-100 p-2">
    <button class="import-export-button" mat-flat-button color="accent" (click)="preventEvent($event)"
        [matMenuTriggerFor]="actionMenu">Actions</button>
    <mat-menu #actionMenu="matMenu">
        <button mat-menu-item>Restore Default Settings</button>
        <button mat-menu-item>Set as Default</button>
        <button mat-menu-item>Save & Apply</button>
    </mat-menu>
</div>   

<h5 class="m-2" style="background: #f4f4f4; padding: 10px;">Policy Setup</h5>
<div class="row m-0">
    <div class="col-md-6 d-flex">
        <strong style="width: 25%;" class="mr-2">Select Inventory Policy</strong>
        <mat-form-field appearance="fill">
            <mat-select [(ngModel)]="inventoryPolicy">
                <mat-option value="Periodic">
                    Periodic
                </mat-option>
                <mat-option value="Continuous">
                    Continuous
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-6 d-flex">
        <ng-container *ngIf="inventoryPolicy=='Periodic'">
            <strong style="width: 25%;" class="mr-2">Ordering Frequency (Periodic)</strong>
            <mat-form-field appearance="fill">
                <mat-select [(ngModel)]="orderingPolicy">
                    <mat-option value="Weekly">
                        Weekly
                    </mat-option>
                    <mat-option value="Fortnightly">
                        Fortnightly
                    </mat-option>
                    <mat-option value="Monthly">
                        Monthly
                    </mat-option>
                    <mat-option value="Custom">
                        Custom
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </div>
    <div class="col-md-6 d-flex">
        <strong style="width: 25%;" class="mr-2">Ordering Policy</strong>
        <mat-form-field appearance="fill">
            <mat-select>
                <mat-option value="Fixed Order Size">
                    Fixed Order Size
                </mat-option>
                <mat-option value="EOQ">
                    EOQ
                </mat-option>
                <mat-option value="Order Up-to">
                    Order Up-to
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-6 d-flex">
        <ng-container *ngIf="orderingPolicy=='Custom' && inventoryPolicy=='Periodic'">
            <strong style="width: 25%;" class="mr-2">Custom Re-Order Frequency (In Days)</strong>
            <mat-form-field appearance="fill">
                <input matInput />
            </mat-form-field>
        </ng-container>
    </div>
</div>

<hr class="theme-hr" />

<h5 class="m-2" style="background: #f4f4f4; padding: 10px;">Calculation Setup</h5>
<div class="row m-0">
    <div class="col-md-6 d-flex">
        <strong style="width: 25%;" class="mr-2">Forecast/EOQ Calculation Period</strong>
        <mat-form-field appearance="fill">
            <mat-select>
                <mat-option value="26 Weeks">
                    26 Weeks
                </mat-option>
                <mat-option value="52 Weeks">
                    52 Weeks
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<hr class="theme-hr" />

<h5 class="m-2" style="background: #f4f4f4; padding: 10px;">Alerts Setup</h5>
<div class="row m-0">
    <div class="col-md-6 d-flex">
        <strong style="width: 25%;" class="mr-2">Start Alerting</strong>
        <mat-form-field appearance="fill">
            <mat-select>
                <mat-option value="5 Days Before">
                    5 Days Before
                </mat-option>
                <mat-option value="4 Days Before">
                    4 Days Before
                </mat-option>
                <mat-option value="3 Days Before">
                    3 Days Before
                </mat-option>
                <mat-option value="2 Days Before">
                    2 Days Before
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-6 d-flex">
        <ng-container *ngIf="inventoryPolicy=='Continuous'">
            <strong style="width: 25%;" class="mr-2">Buffer Stock</strong>
            <mat-form-field appearance="fill">
                <mat-select>
                    <mat-option value="5%">
                        5%
                    </mat-option>
                    <mat-option value="10%">
                        10%
                    </mat-option>
                    <mat-option value="20%">
                        20%
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </div>
</div>
