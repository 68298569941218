<h1 mat-dialog-title>Import Targets</h1>
<div mat-dialog-content>
    <mat-form-field appearance="outline">
        <mat-label>Spreading Logic</mat-label>
        <mat-select [(ngModel)]="spreadingLogic">
            <mat-option *ngFor="let item of logics" [value]="item.id">{{item.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onNoClick()">Close</button>
    <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="done()">Done</button>
</div>