import { Component, OnInit, ViewChild, TemplateRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { UsersService } from 'src/services/User-services/user-services';
import { PromotionFormComponent } from '../promotion-form/promotion-form.component';
@Component({
  selector: 'app-sku-list-link',
  templateUrl: './sku-list-link.component.html',
  styleUrls: ['./sku-list-link.component.css']
})
export class SkuListLinkComponent implements OnInit, OnChanges {
  @ViewChild('dialog') dialogTemplate: TemplateRef<any>;
  @ViewChild('Adddialog') dialogAddTemplate: TemplateRef<any>;
  @ViewChild('ExcelImport') excelImport: TemplateRef<any>;
  @Input() skuPromotionList: any;
  public classList = [{ classCode: 1, classCodeDescription: 'TV' }, { classCode: 2, classCodeDescription: 'Theatrical' }];
  public subClassList = [
    { subClassCode: 1, subClassCodeDescription: 'DVD' },
    { subClassCode: 2, subClassCodeDescription: 'BD' },
    { subClassCode: 3, subClassCodeDescription: '3D' },
    { subClassCode: 4, subClassCodeDescription: 'UHD' }
  ];
  public selectAll = true;
  afuConfig = {
    uploadAPI: {
      url: 'https://example-file-upload-api'
    },
    multiple: false,
    formatsAllowed: '.xls,.xlsx',
    replaceTexts: {
      selectFileBtn: 'Select Files',
      resetBtn: 'Reset',
      uploadBtn: 'Export Data',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded !',
      afterUploadMsg_error: 'Upload Failed !'
    }
  };
  public importList = ['Export Vendor Template', 'Export BBY Template', 'Import']
  public columnDefs = [
    // {
    //   headerName: '',
    //   field: 'selected',
    //   checkboxSelection: true
    // },
    {
      headerName: 'Customer',
      show: true,
      field: 'sku.customer'
    },
    {
      headerName: 'Item #',
      show: true,
      field: 'sku.itemNumber'
    },
    {
      headerName: 'Item Description',
      show: true,
      field: 'sku.itemDesciption'
    },
    {
      headerName: 'UPC',
      show: true,
      field: 'sku.upc'
    },
    {
      headerName: 'Class',
      field: 'classCode',
      cellRenderer: (params) => {
        const subClass = this.classList.find(sub => sub.classCode === Number(params.value));
        return `${subClass ? subClass.classCodeDescription : ''}`;
      },
      show: true,
    },
    {
      headerName: 'Subclass',
      field: 'subClassCodeId',
      cellRenderer: (params) => {
        const subClass = this.subClassList.find(sub => sub.subClassCode === params.value);
        return `${subClass ? subClass.subClassCodeDescription : ''}`;
      },
      show: true,
    },
    {
      headerName: 'Street Date',
      show: true,
      field: 'sku.streetDate',
      cellRenderer: (params) => {
        return `${params.value ? (new Date(params.value)).toLocaleDateString() : ''}`;
      }
    },
    {
      headerName: 'ARP',
      show: true,
      field: 'sku.arp'
    },
    {
      headerName: 'Unit Cost',
      show: true,
      field: 'sku.unitCost'
    },
    {
      headerName: 'RMF',
      show: true,
      field: 'rmf'
    },
    {
      headerName: 'Ship Budget',
      editable: true,
      show: true,
      field: 'shipBudget'
    },
    {
      headerName: 'Ship V1 Plan',
      editable: true,
      show: true,
      field: 'shipV1Plan'
    },
    {
      headerName: 'POS Budget',
      editable: true,
      show: true,
      field: 'posV1Budget'
    },
    {
      headerName: 'POS V1 Plan',
      editable: true,
      show: true,
      field: 'posV1Plan'
    },
    {
      headerName: 'POS Decay Curve',
      editable: true,
      show: true,
      field: 'posDecayCurve'
    },
    {
      headerName: 'Ship Decay Curve',
      editable: true,
      show: true,
      field: 'shipDecayCurve'
    },
    {
      headerName: 'Discount %',
      editable: true,
      show: true,
      field: 'discount'
    },
    {
      headerName: 'Gross Box Office',
      show: true,
      field: 'sku.grossBoxOffice'
    },
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public skuPromotion = [];
  public modules = AllCommunityModules;
  public user: any;
  public skuList = [];
  public selectAllSKU = false;
  files: File[] = [];

  constructor(
    public router: Router,
    public userService: UsersService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PromotionFormComponent>,
    public configurationService: ConfigurationService,
    public storage: LocalstorageService) {
    this.gridOptions = {
      frameworkComponents: {
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    this.getSKUList();
  }
  public getSKUList = () => {
    this.configurationService.SKUGetAll({}).subscribe(res => {
      this.skuList = res.map(sku => ({ ...sku, selected: false }));
    });
  }
  ngOnChanges(simpleChange: SimpleChanges) {
    if (simpleChange && simpleChange.skuPromotionList && simpleChange.skuPromotionList.currentValue) {
      this.skuPromotion = simpleChange.skuPromotionList.currentValue;
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    if (this.gridOptions.api) {
      this.skuPromotion = this.skuPromotion.length > 0 ? this.skuPromotion : this.storage.get('SKULIST');
      this.gridOptions.api.setRowData(this.skuPromotion);
    }
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  onCellClicked($event) {
  }
  openFullScreenMode() {
    const domGrid = document.getElementById('myGrid') as any;
    if (domGrid) {
      domGrid.webkitRequestFullscreen();
    }
  }
  openDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(this.dialogTemplate, { width: '500px', data: data || {} });
  }
  openAddDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(this.dialogAddTemplate, { width: '500px', data: data || {} });
  }
  openExcelImportDialog(data, templateRef?: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(this.excelImport, { width: '500px', data: data || {} });
  }
  selectAllSelected(flag: boolean): void {
    if (flag) {
      this.columnDefs.forEach(column => column.show = true);
    } else {
      this.columnDefs.forEach(column => column.show = false);
    }
  }
  public selectionChanged = (selection) => {
    if (selection && selection.value === 'Import') {
      this.openExcelImportDialog({});
    }
  }
  public onSave = () => {
    const newColumnDefs = this.columnDefs.filter(column => column.show);
    this.gridOptions.api.setColumnDefs(newColumnDefs);
    this.dialogRef.close();
  }

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }
  exportToExcel = () => {
    this.gridApi.exportDataAsCsv();
  }
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
}
