import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationService } from '../../../../services/configuration.service';
import { ItemAccount } from '../../item.model';

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.css'],
})
export class AccountFormComponent implements OnInit, OnChanges, OnDestroy {
  s;
  public accountModel;
  public isEdit = false;
  public submitted = false;
  @Output() updateList = new EventEmitter();
  @Output() close = new EventEmitter();
  public regionList = [];
  public countryList = [];
  public distributorList = [];
  channelList: any;
  accountTypeList = [];
  public channelLoading = false;
  public continentLoading = false;
  public countryLoading = false;
  public distributorLoading = false;
  subRegion: any = [];
  partnerType: any=[];
  salesReporting: any=[];
  constructor(
    public config: ConfigurationService,
    public spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog
  ) {}
  ngOnChanges(changes): void {}

  ngOnInit() {
    this.accountModel = this.config.accountModel;
    if (this.accountModel.itemAccountId) {
      this.isEdit = true;
      if (this.accountModel.channelId) {
        this.onChannelChange(this.accountModel.channelId, false);
      }
      if (this.accountModel.channelId && this.accountModel.regionId) {
        this.onRegionChange(this.accountModel.regionId, false);
      }
      if (
        this.accountModel.channelId &&
        this.accountModel.regionId &&
        this.accountModel.countryId
      ) {
        this.onCountryChange(this.accountModel.countryId, false);
      }
    } else {
      this.accountModel.editPermission = true;
    }
    this.getDropdowns();
  }
  public getDropdowns = () => {
    this.channelLoading = true;
    this.config.AccountSettingMappingChannelGetList({}).subscribe((res) => {
      this.channelList = res;
      this.channelLoading = false;
    });
    this.config.GetLookupByCode('AccountType').subscribe((res) => {
      this.accountTypeList = res;
    });
    this.config.GetLookupByCode('SubRegion').subscribe((res) => {
      this.subRegion = res;
    });
    this.config.GetLookupByCode('PartnerType').subscribe((res) => {
      this.partnerType = res;
    });
    this.config.GetLookupByCode('SalesReporting').subscribe((res) => {
      this.salesReporting = res;
    });
  };
  public onChannelChange = (channelId, resetFlag = true) => {
    this.continentLoading = true;
    this.config
      .AccountSettingMappingContinentGetList({ channelId: channelId })
      .subscribe((res) => {
        this.regionList = res;
        if (resetFlag) {
          this.accountModel.regionId = null;
          this.accountModel.countryId = null;
          this.accountModel.distributorId = null;
        }
        this.continentLoading = false;
      });
  };
  public onRegionChange = (continentId, resetFlag = true) => {
    this.countryLoading = true;
    this.config
      .AccountSettingMappingCountryGetList({ continentId: continentId })
      .subscribe((res) => {
        this.countryList = res;
        if (resetFlag) {
          this.accountModel.countryId = null;
          this.accountModel.distributorId = null;
        }
        this.countryLoading = false;
      });
  };
  public onCountryChange = (countryId, resetFlag = true) => {
    this.distributorLoading = true;
    this.config
      .AccountSettingMappingDistributorGetList({ countryId: countryId })
      .subscribe((res) => {
        this.distributorList = res;
        if (resetFlag) {
          this.accountModel.distributorId = null;
        }
        this.distributorLoading = false;
      });
  };
  public onSubmit = () => {
    this.accountModel.countryId = Number(this.accountModel.countryId);
    this.submitted = true;
    const model = {
      ...this.accountModel,
      subRegion: this.accountModel.subRegion?.id,
      partnerType: this.accountModel.partnerType?.id
    };
    if (
      this.accountModel.accountName &&
      this.accountModel.channelId &&
      this.accountModel.regionId &&
      this.accountModel.distributorId
    ) {
      this.spinner.show();
      if (this.isEdit) {
        this.config.ItemAccountUpdate(model).subscribe(
          (res) => {
            this.spinner.hide();
            this.updateList.emit(true);
            this.close.emit(true);
            this.dialogRef.close();
          },
          (error) => {
            this.spinner.hide();
          }
        );
      } else {
        this.config.ItemAccountAdd(model).subscribe((res) => {
          this.accountModel.itemAccountId = res;
          this.isEdit = true;
          this.spinner.hide();
          this.updateList.emit(true);
          this.close.emit(true);
        });
      }
    }
  };
  public closeAccountForm = () => {
    this.accountModel = null;
    this.close.emit(true);
  };
  public openDialog = (template: TemplateRef<any>) => {
    this.dialogRef = this.dialog.open(template, {
      width: '400px',
      panelClass: 'account',
    });
  };
  ngOnDestroy() {
    this.accountModel = null;
  }
}
