import { MatDialog } from '@angular/material/dialog';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllModules, ColumnApi, GridApi, GridOptions } from '@ag-grid-enterprise/all-modules';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigurationService } from 'src/services/configuration.service';
import { ForecastManagerEditRenderComponent } from './ag-grid/forecast-manager-edit-renderer.component';
import { defaultColDef, getForecastManagerColDefs } from './ag-grid/grid-options';
import { ForecastLookupService } from 'src/services/forecast-services/forecast-lookup-service';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import * as _ from 'lodash';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { ForecastManagerService } from 'src/services/forecast-services/forecast-manager.service';
import { ForecastSetupComponent } from './forecast-setup/forecast-setup.component';
import { ForecastDataManagerComponent } from './forecast-data-manager/forecast-data-manager.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MappingService } from 'src/services/forecast-services/mapping-service';
import { SharedDataService } from 'src/services/shared-data.service';

@Component({
  selector: 'app-forecast-manager',
  templateUrl: './forecast-manager.component.html',
  styleUrls: ['./forecast-manager.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ForecastManagerComponent implements OnInit, OnDestroy {
  paginationPageSize = 50;
  cacheBlockSize = 50;
  public modules = AllModules;
  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  rowSelection = 'multiple';
  public defaultColDef = defaultColDef;
  public columnDefs = getForecastManagerColDefs({}, this);
  defaultExportParams: any;
  gridData: any = [];
  frameworkComponents = {
    ForecastManagerEditRenderComponent: ForecastManagerEditRenderComponent,
  };
  selectedRows: any = [];
  forecastManagerSession: any;
  isSessionOperation: boolean = false;
  forecastId: number = 0;

  public actionSubscription: Subscription;
  public forecastSetupSubscription: Subscription;

  SKUDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'sku',
    textField: 'sku',
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: 'No Data Available'
  };
  SKUList: any = [];
  selectedSKU = [];

  SKUDescrDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'productDescription',
    textField: 'productDescription',
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: 'No Data Available'
  };
  SKUDescrList: any = [];
  selectedSKUDescr = [];

  
  accountDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'accountName',
    textField: 'accountName',
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: 'No Data Available'
  };
  accountList: any = [];
  selectedAccount = [];

    
  seasonalityDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'seasonalityKey',
    textField: 'seasonalityLabel',
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: 'No Data Available'
  };
  seasonalityList: any = [];
  selectedSeasonality = [];

  filter = {
    sku: null,
    skuDescription: null,
    account: null,
    seasonality: null
  };

  constructor(
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public forecastManagerService: ForecastManagerService,
    public forecastLookupService: ForecastLookupService,
    private mappingService: MappingService,
    public _dataService: SharedDataService,
  ) {
    this.defaultExportParams = gridDataExportParams({
      fileName: 'Forecast-Manager',
      colDefs: this.columnDefs,
      allColumns: true,
    });
  }

  get isFilteredApplied(): boolean {
    return this.gridApi ? !_.isEmpty(this.gridApi.getFilterModel()) : false;
  }

  get getGridRowsCount(): Number {
    return this.gridApi ? this.gridApi.getModel().getRowCount(): 0;
  }

  clearGridFilters() {
    this.gridApi.setFilterModel(null);
  }

  ngOnInit() {
    this.forecastManagerGetUserSession();
    this.getForecastList();
    this.listenSubscriptions();
    this.getLookupData();
  }

  clearValues() {
    this.filter = {
      sku: null,
      skuDescription: null,
      account: null,
      seasonality: null
    };
    this.selectedAccount = [];
    this.selectedSKU = [];
    this.selectedSKUDescr = [];
    this.selectedSeasonality = [];
    this.getForecastList();
  }
  
  getLookupData() {
    this.forecastLookupService.GetAccountslist().subscribe(accountList => {
      this.accountList = accountList;
    });

    this.forecastLookupService.GetSeasonalityList().subscribe(seasonalityList => {
      this.seasonalityList = [];
      seasonalityList.forEach(e => {
        e['seasonalityLabel'] = e['seasonalityName'] + ' - ' + e['seasonalityKey'];
        this.seasonalityList.push(e);
      });
    });
    this.spinner.show();
    this.mappingService.GetLookupItemsList().subscribe((itemList: any) => {
      this.SKUList = _.uniqBy(itemList, a => a.sku);
      this.SKUDescrList = _.uniqBy(itemList, a => a.productDescription);
      this.spinner.hide();
    });

  }

  selectSKUChange() {
    const sku = [];
    this.selectedSKU.forEach(a => {
      sku.push(a.sku);
    });
    this.filter.sku = sku.length ? sku[0] : null;

    this.selectedSKUDescr = [];
    this.filter.skuDescription = null;
    this.filter.sku && this.SKUDescrList.forEach(e => {
      if (e.sku == this.filter.sku) {
        this.filter.skuDescription = e.productDescription;
        this.selectedSKUDescr = [e];
      }
    });

    this.getForecastList();
  }

  selectSKUDescrChange() {
    const skuDescr = [];
    this.selectedSKUDescr.forEach(a => {
      skuDescr.push(a.productDescription);
    });
    this.filter.skuDescription = skuDescr.length ? skuDescr[0] : null;

    this.selectedSKU = [];
    this.filter.sku = null;
    this.filter.skuDescription && this.SKUList.forEach(e => {
      if (e.productDescription == this.filter.skuDescription) {
        this.filter.sku = e.sku;
        this.selectedSKU = [e];
      }
    });

    this.getForecastList();
  }

  selectAccountChange() {
    const accounts = [];
    this.selectedAccount.forEach(a => {
      accounts.push(a.accountName);
    });
    this.filter.account = accounts.length ? accounts[0] : null;
    this.getForecastList();
  }
  
  selectSeasonalityChange() {
    const seasonality = [];
    this.selectedSeasonality.forEach(a => {
      seasonality.push(a.seasonalityKey);
    });
    this.filter.seasonality = seasonality.length ? seasonality[0] : null;
    this.getForecastList();
  }

  restoreSession() {
    this.forecastManagerGetUserSession(true);
  }

  public forecastManagerGetUserSession(restoreSession: boolean = false) {
    this.forecastManagerService.forecastManagerGetUserSession({}).subscribe((forecastManger: any) => {
      if (forecastManger.forecastId) {
        this.forecastManagerSession = forecastManger;
        if (restoreSession) {
          const forecastData = {
            forecastData: this.forecastManagerSession,
            isSessionRestore: true
          }
          this.openForecastDataManagerView(forecastData);
        }
      }
    })
  }

  listenSubscriptions() {
    this.actionSubscription = this.forecastManagerService.getForecastGridActionCellSubject().subscribe(data => {
      if (data) {
        const forecastData = {
          forecastData: data.forecastData
        };
        this.openForecastDataManagerView(forecastData);
      }
    });

    this.forecastSetupSubscription = this.forecastManagerService.getForecastSetupActionSubject().subscribe(data => {
      if (data) {
        this.getForecastList();
      }
    });
  }

  openForecastDataManagerView(forecastData) {
    const forecastDataManagerComponent = {
      menuId: '456',
      name: 'Forecast Data Manager',
      selector: 'app-forecast-data-manager',
      displayName: 'Forecast Data Manager',
      module: 'ForecastEnginModule',
      data: forecastData,
      component: ForecastDataManagerComponent
    }
    this.configurationService.menuRendererSubject.next(forecastDataManagerComponent);
  }

  getForecastList() {
    this.spinner.show();
    const obj = {
      retailerKey: this.filter.account || null,
      itemKey: this.filter.sku || null,
      seasonalityKey: this.filter.seasonality
    };
    this.forecastManagerService.GetForecastList(obj).subscribe(forecastList => {
      this.clearSelectedRows();
      this.gridData = forecastList;
      this.spinner.hide();
    });
  }


  rowSelectionChanged(params) {
    this.selectedRows = params.api.getSelectedRows();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
  }

  openForecastSetupDetail(data = {}): void {
    const forecastSetupComponent = {
      menuId: '459',
      name: 'Forecast setup ',
      selector: 'app-forecast-setup',
      displayName: 'Forecast setup ',
      module: 'ForecastEnginModule',
      data: data,
      component: ForecastSetupComponent
    }
    this.configurationService.menuRendererSubject.next(forecastSetupComponent);
  }

  deleteForecast() {
    if (this.selectedRows.length) {
      const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {
          headerName: `Delete Forecasts`,
          confirmationMessage: `Are you sure you want to delete these forecasts?`
        }
      });
      confirmationDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.spinner.show();
          this.forecastManagerService.DeleteForecast({ forecast: this.selectedRows }).subscribe(res => {
            if (res) {
              this.removeFromGrid();
              this.spinner.hide();
              this.toastr.success('Success', `Forecasts deleted successfully.`);
            };
          }, err => {
            this.toastr.error('Error', `System failed to delete forecasts.`);
            this.spinner.hide();
          });
        }
      });
    } else {
      this.toastr.warning('Warning', 'Please select rows first.');
    }
  }

  removeFromGrid() {
    this.selectedRows.forEach(row => {
      const index = this.gridData.findIndex(a => a.forecastId === row.forecastId);
      this.gridData.splice(index, 1);
    });
    this.clearSelectedRows();
    this.gridApi.setRowData(this.gridData);
  }

  clearSelectedRows() {
    this.gridApi.deselectAll();
  }

  copyForecast() {
    if (this.selectedRows.length === 1) {
      const copyData = {
        isCopyOperation: true,
        forecastId: this.selectedRows[0].forecastId
      }
      this.openForecastSetupDetail(copyData);
    } else {
      this.toastr.warning('Warning', 'Please select only one forecast to copy.')
    }
  }

  onExportGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'Forecast-Manager',
      colDefs: this.columnDefs,
      allColumns: false,
      columnKeys: _.map(this.columnDefs, a => a.field)
    });
    this.gridApi.exportDataAsCsv(csvExportParams);
  }

  openColumnDialog() {
    const dialogRef = this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select'],
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnDestroy(): void {
    this.actionSubscription.unsubscribe();
    this.forecastSetupSubscription.unsubscribe();
  }
}
