import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';

@Injectable()
export class SeasonalityService {

  // Subjects
  public seasonalityGridActionCellSubject: Subject<any> = new Subject();
  public seasonalityManagerGridChartActionSubject: Subject<any> = new Subject();

  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */
  setSeasonalityGridActionCellSubject = (value: any) => { this.seasonalityGridActionCellSubject.next(value); };
  setSeasonalityManagerGridChartActionSubject = (value: any) => { this.seasonalityManagerGridChartActionSubject.next(value); };

  // Observables
  getSeasonalityGridActionCellSubject = () => this.seasonalityGridActionCellSubject.asObservable();
  getSeasonalityManagerGridChartActionSubject = () => this.seasonalityManagerGridChartActionSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  GetSeasonalityList(body) {
    return this.http.post(`${environment.SeasonalityManager}/seasonalityGetList`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  GetPendingSeasonalityList(body) {
    return this.http.post(`${environment.SeasonalityManager}/seasonalityPendingGetList`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  SeasonalityGraphDataGetList(body) {
    return this.http.post(`${environment.SeasonalityManager}/seasonalityGraphDataGetList`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  GetSeasonalityLookupList() {
    return this.http.get(`${environment.ConfigurationLookup}/GetSeasonalityLevelList`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetSeasonalityById(seasonalityId) {
    return this.http.get(`${environment.SeasonalityManager}/getSeasonailtyById?seasonalityId=${seasonalityId}`)
      .pipe(map(response => {
        return response;
      }));
  }

  CreatSeaonality(body) {
    return this.http.post(`${environment.SeasonalityManager}/addSeasonality`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  UpdateSeaonality(body) {
    return this.http.post(`${environment.SeasonalityManager}/updateSeasonality`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  CopySeaonality(body) {
    return this.http.post(`${environment.SeasonalityManager}/copySeasonality`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  DeleteSeasonality(body) {
    return this.http.post(`${environment.SeasonalityManager}/deleteSeasonailty`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  UpdateSeasonalitiesStatus(body) {
    return this.http.post(`${environment.SeasonalityManager}/activate`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  ImportSeasonality(body) {
    return this.http.post(`${environment.SeasonalityManager}/importseasonality`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  GetSeasonalityGridData(body) {
    return this.http.post(`${environment.SeasonalityManager}/seasonalitysalegetlist`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  AdjustSeasonalityIndexValue(body) {
    return this.http.post(`${environment.SeasonalityManager}/adjustseasonalityindexvalue`, body)
    .pipe(map(response => {
      return response;
    }));
  }

  ExportSeasonalityTemplate() {
    return this.http.get(
      `${environment.SeasonalityManager}/exportSeasonalityTemplate`).pipe(map(response => {
        return response
      }));
  }

}
