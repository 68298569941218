import { Component, OnInit, ViewEncapsulation, Input, ViewChild, AfterViewInit } from '@angular/core';
import { AllModules, GridApi, ColumnApi, GridOptions } from '@ag-grid-enterprise/all-modules';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationService } from 'src/services/configuration.service';
import { ForecastLookupService } from 'src/services/forecast-services/forecast-lookup-service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import * as _ from 'lodash';
import { ForecastManagerService } from 'src/services/forecast-services/forecast-manager.service';
import { TreeviewItem } from 'ngx-treeview';
import TreeControlParams, { CreateForecastAccountTree, CreateForecastItemTree2, } from 'src/common/forecast-function';
import { Observable, of } from 'rxjs';
import { ClientPreferenceService } from 'src/services/client-services/client-preference.service';
import { gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import { defaultColDef, getForecastHiddenColDefs } from '../ag-grid/grid-options';
import { MatRadioChange } from '@angular/material/radio';
import { SharedDataService } from 'src/services/shared-data.service';


@Component({
  selector: 'app-forecast-setup',
  templateUrl: './forecast-setup.component.html',
  styleUrls: ['./forecast-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForecastSetupComponent implements OnInit {
  @Input() data?: any;
  public gridOptions: GridOptions;
  public modules = AllModules;
  public hiddenGridApi: GridApi;
  public hiddenColApi: ColumnApi;
  public defaultColDef = defaultColDef;
  public columnHiddenDefs = getForecastHiddenColDefs(this);
  gridData: any = [];
  // radioValues = [{ key: 'SMA', value: 'Simple Moving Average', }, { key: 'WMA', value: 'Weighted Moving Average' }];

  SKIP_FORM_KEYS: any = ['forecastFromWeek'];

  seasonalityTableId: string = `${new Date().getTime()}`;

  forecastForm = new FormGroup({
    forecastMethod: new FormControl('SMA', Validators.required),
    lookbackFromWeek: new FormControl('', Validators.required),
    lookbackToWeek: new FormControl('', Validators.required),
    forecastFromWeek: new FormControl('', Validators.required),
    forecastWeekPeriodId: new FormControl('', Validators.required),
    lockLookBackWeek: new FormControl(true),
  });

  weightageWeeks: any = [];
  forecastId: number = 0;
  numberOfWeeks: number = 0;
  isValidWeeks: boolean = true;
  lookBackMaxDate: Date = new Date();
  lookBackMinDate: Date;
  forecastMinDate: Date = new Date();
  isCopyOperation: boolean = false;

  seasonalityList: any = [];
  foreCastWeekList: any = [];
  public channelDropdownList: TreeviewItem[];
  channelTree = [];
  productTree = [];
  public productList = [];
  public itemSettingList = [];
  public accountSettingList = [];
  public selectedItemIds = [];
  public selectedRetailerKeys = [];
  public mappedItemIds: any = [];
  public mappedRetailerKeys: any = [];

  seasonalityIds: any;
  filteredSeasonalityList: Observable<any[]>;
  forecastHierarchyLevelWithSeasonality: any[] = [];

  clientWeekStartDay: number = 0;
  isForecastGenerated: boolean = false;


  startToDate = new Date();
  constructor(
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public forecastLookupService: ForecastLookupService,
    public configurationService: ConfigurationService,
    public forecastManagerService: ForecastManagerService,
    public clientPreferenceService: ClientPreferenceService,
    public _dataService: SharedDataService,
  ) { }

  get lookbackToWeek() {
    return this.forecastForm.get('lookbackToWeek');
  }

  get forecastMethod() {
    return this.forecastForm.get('forecastMethod');
  }

  get weightageWeeksSum() {
    return _.sum(_.map(this.weightageWeeks, a => a ? Number(a.weight) : 0));
  }

  ngOnInit(): void {
    this.loadClientPreference();
    this.addCustomValidators();
    this.data = JSON.parse(this.data ? this.data : {});
    if (this.data.isCopyOperation) {
      this.initCopyProcess();
    } else {
      this.getLookupData();
    }
    this.getLatestCommitedWeek();
  }

  onHiddenGridReady(params) {
    this.hiddenGridApi = params.api;
  }

  addCustomValidators() {
    setTimeout(() => {
      this.forecastForm.controls['lookbackToWeek'].setValidators([Validators.required, this.lookBackToWeekValidator]);
    }, 0);
  }

  lookBackToWeekValidator(control: FormControl) {
    let lookbackFromWeek = control.parent.controls['lookbackFromWeek'].value;
    const lookbackToWeek = control.value;
    if (lookbackFromWeek) {
      lookbackFromWeek = new Date(lookbackFromWeek);
      if (lookbackToWeek < lookbackFromWeek) {
        return {
          lookBackToWeeks: 'From Week should be less then To Week.'
        };
      }
      return null;
    } else {
      return null;
    }
  }

  getLookupData() {
    this.getAccountSettingList(this.mappedRetailerKeys.length ? this.mappedRetailerKeys : '');
    this.getSeasonalityList();
    this.getForecastWeekPeriodList();
  }

  loadClientPreference() {
    this.clientWeekStartDay = this.clientPreferenceService.getClientWeekStartDay();
  }

  initCopyProcess() {
    const data = this.data;
    this.isCopyOperation = data.isCopyOperation ? data.isCopyOperation : false;
    this.forecastId = data.forecastId;
    if (this.isCopyOperation) {
      this.getForecastById(this.forecastId);
    }
  }

  getForecastById(forecastId) {
    this.forecastManagerService.GetForecastById(forecastId).subscribe(forecastData => {
      this.loadForecastFormValues(forecastData);
    });
  }

  getLatestCommitedWeek() {
    this.forecastManagerService.GetLatestCommitedWeek().subscribe((response: any) => {
      this.lookBackMaxDate = response.committedDate;
      this.startToDate = response.committedDate;
    });
  }



  radioChange(event: MatRadioChange) {
    if (event.value === 'WMA') {
      this.forecastForm.get('lockLookBackWeek').setValue(false);
    } else {
      this.forecastForm.get('lockLookBackWeek').setValue(true);
    }
  }


  loadForecastFormValues(forecastData) {
    const formData = _.cloneDeep(_.omit(forecastData, this.SKIP_FORM_KEYS));
    this.forecastForm.patchValue(formData);
    if (this.forecastMethod.value === 'WMA') {
      this.weightageWeeks = forecastData.lookBackWeekWeights;
      this.forecastForm.get('lockLookBackWeek').setValue(false);
    } else {
      this.forecastForm.get('lockLookBackWeek').setValue(true);

    }
    this.mappedItemIds = forecastData.itemKey.split(',');
    this.mappedRetailerKeys = forecastData.retailerKey.split(',');
    this.getLookupData();
  }

  getSeasonalityList() {
    this.forecastLookupService.GetSeasonalityList()
      .subscribe((res) => {
        this.seasonalityList = res;
      });
  }

  getForecastWeekPeriodList() {
    this.forecastLookupService.GetForecastWeekPeriodList()
      .subscribe((res) => {
        this.foreCastWeekList = res;
      });
  }

  getAccountSettingList(mappedAccounts?: any) {
    this.forecastLookupService.GetForecastRetailerList({})
      .subscribe((res) => {
        this.accountSettingList = res;
        this.createAccountDropdownList(res, mappedAccounts);
      });
  };

  getItemSettingList(retailerList: any = { retailerDetails: [] }, mappedItems?: any) {
    this.forecastLookupService
      .GetItemsListByRetailer(retailerList)
      .subscribe((res) => {
        this.itemSettingList = res;
        this.createItemDropdownList(res, mappedItems);
      });
  };

  createAccountDropdownList(accoutSettingList, mappedAccounts) {
    this.channelDropdownList = CreateForecastAccountTree({
      dataList: accoutSettingList,
      mappedValueList: mappedAccounts
    } as TreeControlParams);
  };


  createItemDropdownList(productSettingList, mappedItems) {
    this.productList = CreateForecastItemTree2({
      dataList: productSettingList,
      mappedValueList: mappedItems
    } as TreeControlParams);
    if (this.isCopyOperation) {
      setTimeout(() => {
        // Clear mapped items after first selection
        this.mappedItemIds = [];
      }, 100);
    }
  };


  setValue(selectedDropdown) {
    switch (selectedDropdown.type) {
      case 'Item': {
        this.selectedItemIds = selectedDropdown.value;
        break;
      }
      case 'Channel': {
        this.selectedRetailerKeys = selectedDropdown.value;
        if (this.selectedRetailerKeys) {
          if (this.isCopyOperation && !this.selectedItemIds.length) {
            this.getItemSettingList({ retailerDetails: _.map(this.selectedRetailerKeys, a => { return { retailerKey: a } }) }, this.mappedItemIds);
          } else {
            this.getItemSettingList({ retailerDetails: _.map(this.selectedRetailerKeys, a => { return { retailerKey: a } }) });
          }
        }
        break;
      }
    }
  };

  lookbackFromWeekChange(event) {
    this.calculateWeeks();
    this.setToDateMinMax();
  }

  setToDateMinMax() {
    let lookbackFromWeek = this.forecastForm.get('lookbackFromWeek').value;
    let lookbackToWeek = this.forecastForm.get('lookbackToWeek').value;
    this.startToDate = lookbackToWeek || lookbackFromWeek;
    if (lookbackFromWeek) {
      lookbackFromWeek = new Date(lookbackFromWeek);
      lookbackFromWeek.setDate(lookbackFromWeek.getDate() + 7);
      this.lookBackMinDate = lookbackFromWeek;
    }
  }

  lookBackToWeekChange(event) {
    this.calculateWeeks();
    let lookbackToWeek = this.forecastForm.get('lookbackToWeek').value;
    let lookbackFromWeek = this.forecastForm.get('lookbackFromWeek').value;
    this.startToDate = lookbackToWeek || lookbackFromWeek;
  }

  calculateWeeks() {
    const lookbackFromWeek = this.forecastForm.get('lookbackFromWeek').value;
    const lookbackToWeek = this.forecastForm.get('lookbackToWeek').value;
    if (lookbackFromWeek && lookbackToWeek) {
      this.numberOfWeeks = this.differenceInWeeks(new Date(lookbackFromWeek), new Date(lookbackToWeek));
      this.createWeightageWeeks();
    }
  }

  createWeightageWeeks() {
    const prvWeeksList = _.cloneDeep(this.weightageWeeks);
    this.weightageWeeks = [];
    for (let i = 0; i < this.numberOfWeeks; i++) {
      if (prvWeeksList[i]) {
        this.weightageWeeks.push(prvWeeksList[i]);
      } else {
        this.weightageWeeks.push({ weekIndex: i + 1, weight: '' });
      }
    }
  }

  differenceInWeeks(startWeekDate: Date, endWeekDate: Date) {
    let diff = (startWeekDate.getTime() - endWeekDate.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7);
    return Math.abs(Math.round(diff)) + 1;
  }


  weekDayFilter = (date) => {
    return date.day() === this.clientWeekStartDay;
  }

  isWeightageWeekValid() {
    let valueSum = 0;
    let isValidWeeks = true;
    const forecastMethod = this.forecastMethod.value;
    if (forecastMethod === 'WMA') {
      this.weightageWeeks.every(week => {
        if (!week.weight) {
          isValidWeeks = false;
          return false;
        }
        valueSum = valueSum + week.weight;
        return true;
      });
      if (valueSum !== 100) {
        isValidWeeks = false;
      }
      this.isValidWeeks = isValidWeeks;
    }
    return isValidWeeks;
  }

  fetchItemAccountSeasonalities() {
    this.isWeightageWeekValid();
    if (this.forecastForm.valid && this.isValidWeeks && this.selectedRetailerKeys.length && this.selectedItemIds.length) {
      const body = this.forecastForm.value;
      const payload = {
        ...body
      };
      const forecastProductHierarchylist: any = [];
      this.selectedRetailerKeys.forEach(retailerKey => {
        this.selectedItemIds.forEach(itemKey => {
          const item = _.find(this.itemSettingList, a => a.itemKey === itemKey)
          forecastProductHierarchylist.push({
            retailerKey: retailerKey,
            itemKey: itemKey,
            brand: item.brand,
            productGroup: item.productGroup
          });
        });
      });
      payload.forecastProductHierarchylist = forecastProductHierarchylist;
      payload.lookBackWeekWeights = this.forecastMethod.value === 'WMA' ? this.weightageWeeks : [];

      this.spinner.show();
      this.forecastManagerService.GetForecastHierarchyLevelWithSeasonality(payload).subscribe((res: any) => {
        if (res) {
          this.forecastHierarchyLevelWithSeasonality = res;
          this.scrollToTop(this.seasonalityTableId);
          this.spinner.hide();
        }
      }, err => {
        this.spinner.hide();
        this.toastr.error('Error', 'Failed to get item account seasonalities.');
      });

    } else {
      this.validateAllFormFields(this.forecastForm);
      this.toastr.error('Error', 'Please fill the form correctly.');
    }
  }

  scrollToTop = (id: string) => {
    setTimeout(() => {
      const component = document.getElementById(id.toLowerCase());
      if (component) {
        component.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }, 200);
  };

  checkValidItemAccountSeasonality(): boolean {
    let isValidSeasonality = true;
    this.forecastHierarchyLevelWithSeasonality.every(item => {
      if (!item.seasonalityId) {
        isValidSeasonality = false;
        return false;
      }
      return true;
    });
    return isValidSeasonality;
  }

  generateForecast() {
    this.isWeightageWeekValid();
    const isValidSeasonality = this.checkValidItemAccountSeasonality()
    if (this.forecastForm.valid && this.isValidWeeks && this.selectedRetailerKeys.length && this.selectedItemIds.length && isValidSeasonality) {
      const body = this.forecastForm.value;
      const payload = {
        ...body
      };
      payload.forecastProductHierarchylist = this.forecastHierarchyLevelWithSeasonality;
      payload.lookBackWeekWeights = this.forecastMethod.value === 'WMA' ? this.weightageWeeks : [];

      this.spinner.show();
      this.forecastManagerService.CreatForecast(payload).subscribe((res: any) => {
        if (res) {
          this.isForecastGenerated = true;
          this.toastr.success('Success', `Forecast created successfully.`);
          this.spinner.hide();
          this.forecastManagerService.setForecastSetupActionSubject(true);
        }
      }, err => {
        this.spinner.hide();
        this.toastr.error('Error', 'Failed to create forecast.');
      });

    } else {
      this.validateAllFormFields(this.forecastForm);
      this.toastr.error('Error', 'Please fill the form correctly.');
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  displaySeasonalityNameFn(item): string {
    return item && (typeof item === 'object' && item.seasonalityName) ? item.seasonalityName : item;
  }

  displaySeasonalityKeyFn(item): string {
    return item && (typeof item === 'object' && item.seasonalityKey) ? item.seasonalityKey : item;
  }

  _filterSeasonalityByKey(event, hierarchyLevel) {
    let value = _.toLower(event.target.value);
    if (!value) {
      hierarchyLevel.seasonalityId = null;
    }
    const itemsList = _.filter(this.seasonalityList, item => { return _.toLower(item.seasonalityKey).indexOf(value) > -1 })
    this.filteredSeasonalityList = of(itemsList);
  }

  _filterSeasonalityByName(event, hierarchyLevel) {
    let value = _.toLower(event.target.value);
    if (!value) {
      hierarchyLevel.seasonalityId = null;
    }
    const itemsList = _.filter(this.seasonalityList, item => { return _.toLower(item.seasonalityName).indexOf(value) > -1 })
    this.filteredSeasonalityList = of(itemsList);



  }

  onSeasonalityFocus(event) {
    this.filteredSeasonalityList = of(this.seasonalityList);
  }

  selectItemAccountSeasonality = (item, hierarchyLevel) => {
    hierarchyLevel.seasonalityId = item.seasonalityId;
    hierarchyLevel.seasonalityKey = item.seasonalityKey;
    hierarchyLevel.seasonalityName = item.seasonalityName;
  }

  removeAutocompleteFocus() {

     document.getElementById('focusId').click();
  }



  onExportData() {
    this.gridData = this.forecastHierarchyLevelWithSeasonality;
    this.hiddenGridApi && this.hiddenGridApi.setRowData(this.gridData);
    setTimeout(() => {
      this.onExportSeasonalityData();
    }, 200);
  }

  onExportSeasonalityData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'Forecast-Hierarchies',
      colDefs: this.columnHiddenDefs,
      allColumns: true
    });
    this.hiddenGridApi.exportDataAsCsv(csvExportParams);
  }


}
