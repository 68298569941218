<!-- Main Content area -->
<app-global-planner-graph-new
  *ngIf="
    leftGroupList.length > 0 && masterMeasureList.length > 0 && gridListUI.length > 0
  "
  [leftGroupList]="grid"
  [yearListGrid]="gridData"
  [measureList]="masterMeasureList"
  [dropdownObjects]="dropdownObjects"
>
</app-global-planner-graph-new>
<mat-accordion class="fb-forecast-accordion item-wrapper">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
      <mat-panel-title> Data View </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="data-wrapper btn-wrapper">
      <img src="assets/images/graph.svg" alt="" (click)="openMesuresDialog()" />
      <button class="btn btn-primary" (click)="selectionChanged(false)">
        Calculate
      </button>
      <mat-select
        [(ngModel)]="exportOptions"
        placeholder="Export/Import"
        class="form-control"
      >
        <mat-option [value]="null">Export/Import</mat-option>
        <mat-option [value]="null" (click)="export()"
          >Export Current View</mat-option
        >
        <mat-option [value]="null" (click)="openExportDialog()"
          >Export</mat-option
        >
        <mat-option [value]="null" *ngIf="editPermission" (click)="import()"
          >Import</mat-option
        >
      </mat-select>
      <button class="btn btn-primary" (click)="mapGridList(false)">
        Save or Commit
      </button>
    </div>

    <!-- <div
      (scroll)="onWindowScroll($event)"
      id="navbarGlobal{{guid}}"
      class="left-dropdown animated fadeInDown table-header-sticky"
    >
      <app-fb-forecast-scrolling
        [isProduct]="true"
        (selectionChange)="selectionChanged($event)"
        [productList]="selectedProducts"
        [productTree]="productTree"
      >
      </app-fb-forecast-scrolling>
      <app-fb-forecast-scrolling
        [isChannel]="true"
        (selectionChange)="selectionChanged($event)"
        [productList]="selectedChannel"
        [productTree]="productTree"
      >
      </app-fb-forecast-scrolling>

      <div id="grid-header{{guid}}" class="wrapper-header">
        <div
          #scrollDiv
          class="header-right-sticky"
          *ngFor="let year of gridData; let j = index"
          style="text-align: center"
          align="center"
        >
          <div>
            <mat-select
              [placeholder]="year.title"
              #select
              (selectionChange)="showValues($event, year, select)"
            >
              <mat-select-trigger>
                {{ year.title }}
              </mat-select-trigger>
              <mat-option
                *ngFor="let option of timeframeList"
                [value]="option.headerName"
                >{{ option.headerName }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>
    </div> -->

    <div class="container-fluid" id="fb-forecast-grid">
      <div id="Global{{guid}}">
        <table
          *ngIf="yearListGrid && yearListGrid.length > 0"
          id="forcast-grid"
          class="table"
        >
          <tbody>
            <td style="padding: 0px !important">
              <tr class="td-padding-none">
                <td>
                  <tr

                    style="height: 45px !important; margin-bottom: 2px"
                  ></tr>
                  <ng-container *ngFor="let label of leftGroupList">
                    <tr
                      [attr.rowspan]="label.rowspan"
                      style="
                        writing-mode: vertical-rl;
                        text-orientation: mixed;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        font-size: large;
                      "
                      [ngStyle]="{
                        background: label.background,
                        height: label.rowspan * 42 + 'px'
                      }"
                      *ngIf="label.rowspan > 0"
                    >
                      <label
                        [ngStyle]="{
                          height: label.rowspan * 42 + 'px'
                        }"
                        style="
                          padding-right: 12px;
                          color: #fff;
                          min-width: 50px;
                          max-width: 50px;
                          text-align: center;
                          font-size: 14px;
                          font-weight: bold;
                          transform: rotate(180deg);
                        "
                        >&nbsp;</label
                      >
                    </tr>
                    <tr style="height: 40px !important; margin-bottom: 2px">
                      <label style="height: 40px !important; margin-bottom: 2px"
                        >&nbsp;</label
                      >
                    </tr>
                  </ng-container>
                </td>
              </tr>
            </td>
            <td style="padding: 0px !important" id="stickyGlobal{{guid}}">
              <tr class="td-padding">
                <td>
                  <tr class="left-dropdown animated fadeInDown" style="
                      height: 45px !important;
                      display: flex;
                      align-items: center;
                      margin-left: -50px;
                    ">
                <app-fb-forecast-scrolling #itemScroll [isProduct]="true" (selectionChange)="selectionChanged($event)"
                  [productList]="selectedProducts" [productTree]="productTree">
                </app-fb-forecast-scrolling>
                <app-fb-forecast-scrolling #accountScroll [isChannel]="true" (selectionChange)="selectionChanged($event)"
                  [productList]="selectedChannel" [productTree]="productTree">
                </app-fb-forecast-scrolling>
              </tr>
                  <ng-container
                    *ngFor="let label of measureList; let i = index"
                  >
                    <tr
                      [ngStyle]="{ background: 'rgb(238, 238, 238)' }"
                      *ngIf="label.checked"
                      style="
                        height: 40px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 2px;
                        padding-left: 15px;
                        margin-right: 2px;
                        min-width: 370px;
                      "
                    >
                      <label
                        *ngIf="!label.isDropdown"
                        style="min-width: 300px; max-width: 300px"
                        >{{ label.measureName }}</label
                      >
                      <mat-select
                        [(ngModel)]="label.measureName"
                        [ngModelOptions]="{ standalone: true }"
                        style="height: 40px"
                        style="min-width: 300px; max-width: 300px"
                        *ngIf="label.isDropdown"
                      >
                        <mat-option
                          class="not-empty-select"
                          *ngFor="let res of methodList; let i = index"
                          [value]="res"
                        >
                          {{ res }}
                        </mat-option>
                      </mat-select>
                    </tr>
                    <tr
                      *ngIf="checkIfToShow(i)"
                      style="height: 40px !important; margin-bottom: 2px"
                    >
                      <label
                        [id]="i"
                        style="height: 40px !important; margin-bottom: 2px"
                        >&nbsp;</label
                      >
                    </tr>
                  </ng-container>
                </td>
              </tr>
            </td>
            <td
              id="sticky{{guid}}"
              class="Global-data-row"
              style="overflow-x: scroll; width: 100%; padding: 0px !important"
              [ngStyle]="{ 'max-width': getWidth() }"
            >
              <tr class="td-padding">
                <td
                  *ngFor="let year of gridData; let j = index"
                  style="text-align: center"
                  align="center"
                >
                  <tr style="height: 45px; display: block;" class="right-dropdown animated fadeInDown">
                <mat-select [placeholder]="year.title" #select (selectionChange)="showValues($event, year, select)">
                  <mat-select-trigger>
                    {{ year.title }}
                  </mat-select-trigger>
                  <mat-option *ngFor="let option of timeframeList" [value]="option.headerName">{{ option.headerName }}
                  </mat-option>
                </mat-select>
              </tr>
                  <ng-container>
                    <td class="input-fields" style="text-align: center">
                      <!-- Algo -->
                      <ng-container
                        *ngFor="let measure of measureList; let i = index"
                      >
                        <tr
                          [ngStyle]="{ background: '#eee' }"
                          *ngIf="checkIfInList(measure.code)"
                        >
                          <input
                            draggable="false"
                            #field
                            id="{{ j }}{{ measure.code }}{{ i }}"
                            type="text"
                            title="{{ measure.code }}"
                            [disabled]="
                              measure.templateId === 1 || !editPermission
                            "
                            [ngClass]="{
                              'disabled-input':
                                measure.templateId === 1 || !editPermission
                            }"
                            [(ngModel)]="year['measures'][measure.code]"
                            (keydown.tab)="
                              setFocus(j, measure.code, i);
                              $event.preventDefault()
                            "
                            (change)="
                              valueChange(
                                year['measures'][measure.code],
                                year,
                                measure.code
                              )
                            "
                            (ngModelChange)="
                              calculateForecastMeasures(measure.code, year);
                              addCommas(year['measures'], measure.code)
                            "
                            (keydown)="onPaste($event, year, measure.code)"
                            (keydown)="onKeyDown($event, measure.code, year)"
                            [ngModelOptions]="{ standalone: true }"
                            (mouseover)="
                              checkIfMousedown(
                                $event,
                                year,
                                measure.code,
                                field
                              )
                            "
                            (mousedown)="
                              preventMouseDown(
                                $event,
                                year,
                                measure.code,
                                field
                              )
                            "
                            (click)="field.focus()"
                            (focus)="field.select()"
                            (dblclick) = "removeSelection(measure.code, year, field)"
                            (input) = "onInput()"
                          />
                        </tr>
                        <tr
                          *ngIf="checkIfToShow(i)"
                          style="height: 40px !important; margin-bottom: 2px"
                        >
                          <label
                            [id]="i"
                            style="height: 40px !important; margin-bottom: 2px"
                            >&nbsp;</label
                          >
                        </tr>
                      </ng-container>
                    </td>
                  </ng-container>
                </td>
              </tr>
            </td>
          </tbody>
        </table>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<ng-template #yearColumn let-week="week" let-index="index"> </ng-template>
