<div class="row m-0 mt-2">
    
    <div class="col-md-3">
        <mat-form-field appearance="outline">
            <mat-label>Season</mat-label>
            <mat-select [(ngModel)]="selectedSeason">
                <mat-option *ngFor="let item of seasons" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-md-3">
        <mat-form-field appearance="outline">
            <mat-label>Brand</mat-label>
            <mat-select [(ngModel)]="selectedBrand">
                <mat-option *ngFor="let item of brands" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-md-3">
        <mat-form-field appearance="outline">
            <mat-label>Department</mat-label>
            <mat-select [(ngModel)]="selectedDepartment">
                <mat-option *ngFor="let item of departments" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</div>

<div class="all-show-grid">
    <ag-grid-angular #quarterlyGrid class="ag-theme-material" [sideBar]="false" [suppressMenuHide]="true" groupHeaders
        toolPanelSuppressGroups toolPanelSuppressValues [suppressRowTransform]="suppressRowTransform"
        (cellValueChanged)="cellValueChanged(true, $event)" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
        [rowData]="gridData" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
</div>

<hr class="theme-hr" />

<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Price Tiers vs Top Down Targets Summary
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row m-0">
            <div class="all-show-grid col-md-6">
                <ag-grid-angular #secondGrid class="ag-theme-material" [sideBar]="false" [suppressMenuHide]="true"
                    groupHeaders toolPanelSuppressGroups toolPanelSuppressValues [columnDefs]="secondGridColumns"
                    [defaultColDef]="defaultColDef" [rowData]="secondGridData" (gridReady)="onSecondGridReady($event)">
                </ag-grid-angular>
            </div>

            <div class="col-md-6">
                <mat-form-field class="m-3" appearance="outline">
                    <mat-label>Class</mat-label>
                    <mat-select (selectionChange)="classChanged()" [(ngModel)]="selectedClass">
                        <mat-option *ngFor="let item of classesData; let i=index" [value]="i">{{item}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <fusioncharts width="500" height="300" type="Column2d" [dataSource]="dataSource">
                </fusioncharts>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
