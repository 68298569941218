<div class="row m-0 my-2" *ngFor="let item of importsData">
    <div class="col-3"><u><a style="color: #cc0ee7;" (click)="navigateToItems(item)">{{item}}</a></u></div>
    <div class="col-3">
        <button class="import-export-button" mat-flat-button color="accent"
            [matMenuTriggerFor]="importExportMenu">Export/Import</button>
        <mat-menu #importExportMenu="matMenu">
            <button mat-menu-item>Export</button>
            <button mat-menu-item>Import
                <input class="hidden" type="file" name="upload-file" accept=".csv">
            </button>
        </mat-menu>
    </div>
    <div class="col-3">Last Updated on 13th September 2022 at 17:23:17 PKT</div>
    <div class="col-3">
        <button mat-raised-button color="accent">
            Clear History
        </button>
    </div>
</div>

<div *ngIf="showItems" id="itemsDivReplenishment">
    <hr class="theme-hr" />

    <app-replenishment-item-view></app-replenishment-item-view>
</div>
