import { Component, OnInit, Input } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { throwError } from 'rxjs';
import { ConfigurationService } from 'src/services/configuration.service';
@Component({
  selector: 'app-category-summary',
  templateUrl: './category-summary.component.html',
  styleUrls: ['./category-summary.component.css']
})
export class CategorySummaryComponent implements OnInit {
  @Input() summaryByYearList: any;
  public columnDefs = [
    {
      headerName: 'Category',
      field: 'categoryName',
      pinned: 'left',
      width: 130
    }
  ];
  public columnDefsVariation = [];
  public channel: any;
  private gridApi: any;
  public categoryList = [];
  public gridOptions: any;
  public getRowHeight;
  public  headerHeight;
  public gridOptionsVariation: any;
  public categoryHeader = [];
  public gridData = [];
  public gridList = [];
  public gridListVariation = [];
  public channelList = [];
  public modules = AllCommunityModules;
  constructor(public configurationService: ConfigurationService) {
    this.gridOptions = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.gridOptionsVariation = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.headerHeight = 30;
    this.gridOptions.getRowHeight = function(params) {
      return 30;
    };
    this.gridOptionsVariation.getRowHeight = function(params) {
      return 30;
    };
  }

  ngOnInit() {
    this.populateList();
  }
  public populateList = () => {
    this.configurationService.getAllChannels({ clientId: 1 }).subscribe(res => {
      this.channelList = res.data;
    });
  }
  currencyFormatter(params) {
    return `${Math.floor(typeof params.value === 'number' ? params.value : 0)}%`;
  }
  public getCategoryMixFormList = () => {
    // Get All Same Stores
    this.gridList = [];
    this.configurationService.getCategoryList({ clientId: 1, parent: true, channelId: this.channel.channelId }).subscribe((response) => {
      this.categoryList = response;
      if (this.categoryList.length > 0) {
        this.categoryList.forEach(async (category: any) => {
          const categoryObject = { categoryName: category.categoryName };
          const categoryTempList = [];
          const categoryList = await this.configurationService.getCategoryMixHeaderList({ clientId: 1, active: true, CategoryId: category.categoryId, channelId: this.channel.channelId }).toPromise().catch(error => {
            return throwError(error);
          });
          // Tenure Type List
          if (categoryList && categoryList.length > 0) {
            this.categoryHeader = categoryList;
            this.categoryHeader.forEach(store => {
              const year = store && store.name ? store.name.split('-')[1] : '';
              if (year && !categoryTempList.includes(year)) {
                categoryTempList.push({ field: `Category${year}`, name: year });
              }
            });
            if (categoryTempList.length > 0) {
              categoryTempList.forEach(column => {
                if (!this.columnDefs.find(columnDef => columnDef && columnDef.field === column.field)) {
                  (this.columnDefs as any).push({
                    headerName: column.name,
                    field: column.field,
                    width: 80
                  });
                }
                categoryObject[column.field] = 0;
              });
              if (this.columnDefs.length > 1) {
                this.gridOptions.api.setColumnDefs(this.columnDefs);
              }
            }
          }
          // Goals
          const values = await this.configurationService.getCategoryMixFormList({ CategoryId: category.categoryId, clientId: 1, channelId: this.channel.channelId }).toPromise().catch(error => {
            // this.gridList.push({ categoryName: category.categoryName });
            if (this.categoryList.length > 0 && this.gridList.length === this.categoryList.length) {
              this.mapTotalObject();
            }
            return throwError(error);
          });
          if (values && values.pivotList && values.pivotList.length > 0) {
            const gridData = values.pivotList;
            this.gridData.push(values.pivotList);
            this.gridData = (this.gridData as any).flat();
            gridData.forEach(goal => {
              this.columnDefs.forEach(column => {
                Object.keys(goal).forEach(key => {
                  if (key.substring(7, 11) === column.headerName && goal['CategoryMixRow'].name === 'Category N') {
                    if (column.field in categoryObject) {
                      categoryObject[column.field] += goal[key] ? goal[key] : 0;
                    }
                  }
                });
              });
            });
            this.gridList.push(categoryObject);
            if (this.categoryList.length > 0 && this.gridList.length === this.categoryList.length) {
              this.mapTotalObject();
            }
          } else {
            this.gridList.push({ categoryName: category.categoryName });
            if (this.categoryList.length > 0 && this.gridList.length === this.categoryList.length) {
              this.mapTotalObject();
            }
          }
          this.gridOptions.api.setRowData(this.gridList);
          console.log(this.gridList);
        });
      }
    });
  }
  public mapTotalObject = () => {
    const totalObject: any = {};
    this.gridList.forEach(row => {
      Object.keys(row).forEach(key => {
        totalObject.categoryName = 'Total';
        if (key !== 'categoryName') {
          totalObject[key] = totalObject[key] !== null && totalObject[key] !== undefined ? totalObject[key] + row[key] : row[key];
        }
      });
    });
    this.columnDefs = this.columnDefs.map(column => (column.field !== 'categoryName' ? {
      ...column,
      valueFormatter: this.currencyFormatter
    } : { ...column }));
    this.gridList.push(totalObject);
    this.gridListVariation = [];
    this.mapVariationList(JSON.parse(JSON.stringify(this.gridList)));
    this.gridOptions.api.setRowData(this.gridList);
  }
  public mapVariationList = (goalList: any) => {
    const channelSummaryObject = this.summaryByYearList.find(goal => goal.channelName === this.channel.channelName);
    if (channelSummaryObject) {
      const keys = ['categoryName', 'channelName'];
      let channelKeyList = Object.keys(channelSummaryObject ? channelSummaryObject : {});
      if (channelKeyList.length > 0) {
        channelKeyList = channelKeyList.filter(key => !keys.includes(key));
        channelKeyList = channelKeyList.map(key => {
          const value = channelSummaryObject[key];
          const oldKey = key;
          delete channelSummaryObject[key];
          key = oldKey.includes('Channel') ? oldKey.replace('Channel', 'Category') : oldKey;
          channelSummaryObject[key] = value;
          return key;
        });
      }
      for (let i = 0; i < goalList.length; i++) {
        let keyList = Object.keys(goalList[i]);
        keyList = keyList.filter(key => !keys.includes(key));
        for (let j = 0; j < keyList.length; j++) {
          if ((goalList[i][keyList[j]]) && channelSummaryObject && channelSummaryObject[keyList[j]]) {
            goalList[i][keyList[j]] = (goalList[i][keyList[j]] / 100) * channelSummaryObject[keyList[j]];
          }
        }
        this.gridListVariation.push(goalList[i]);
        this.gridOptionsVariation.api.setRowData(this.gridListVariation);
      }
    }
    this.columnDefsVariation = this.columnDefs.map(column => (column.field !== 'categoryName' ? {
      ...column,
      valueFormatter: this.currencyFormatterVaraition
    } : { ...column }));
  }
  currencyFormatterVaraition(params) {
    return `${Math.floor(typeof params.value === 'number' ? params.value : 0)}`;
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
}
