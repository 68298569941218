<div class="container-fluid" style="margin-top: 15px;">
    <mat-tab-group>
        <mat-tab label="Setup">
            <app-replenishment-setup></app-replenishment-setup>
        </mat-tab>
        <mat-tab label="Data Import">
            <app-replenishment-data-imports></app-replenishment-data-imports>
        </mat-tab>
        <mat-tab label="Replenishment Manager">
            <app-replenishment-manager></app-replenishment-manager>
        </mat-tab>
        <mat-tab label="SKU View">
            <app-replenishment-sku-view></app-replenishment-sku-view>
        </mat-tab>
        <mat-tab label="Store View">
            <app-replenishment-store-view></app-replenishment-store-view>
        </mat-tab>
        <mat-tab label="Ordering Status">
            <app-replenishment-ordering-status></app-replenishment-ordering-status>
        </mat-tab>
        <mat-tab label="PO Summary">
            <app-replenishment-po-summary></app-replenishment-po-summary>
        </mat-tab>
        <!-- <mat-tab label="Item View Complete">
            <app-replenishment-item-view-complete></app-replenishment-item-view-complete>
        </mat-tab> -->
    </mat-tab-group>
</div>
