<mat-accordion
  class="fb-forecast-accordion item-wrapper-panel"
  id="fb-forecast"
>
  <mat-expansion-panel class="item-wrapper" [expanded]="true">
    <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
    </mat-expansion-panel-header>

    <mat-tab-group>
      <mat-tab label="New Item">
        <div class="container-fluid item-wrapper">
          <div class="row">
            <div class="col-md-6 padding-right">
              <div class="form-group">
                <label for="items">Select Item</label>
                <mat-form-field floatLabel="never">
                  <input
                    matInput
                    aria-label="SelectItem"
                    name="items"
                    [matAutocomplete]="auto"
                    [(ngModel)]="dataModel.selectedItem"
                    (input)="showFilteredItems($event.target.value)"
                  />
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="submitItemSearch($event.option.value)"
                    [displayWith]="getItemOptionText"
                  >
                    <mat-option
                      *ngFor="let item of filteredItems; let i = index"
                      [value]="item"
                    >
                      {{ item.description }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <app-new-item-planner-form
          [launchCurvesList]="launchCurvesList"
          [items]="itemsList"
          [accounts]="accountsList"
          [seasonlaityIndexList]="seasonlaityIndexList"
          [newItemProductGroupList]="newItemProductGroupList"
          [newItemProductFamilyList]="newItemProductFamilyList"
          [newItemParentProductList]="newItemParentProductList"
          [newItemAccountRegionList]="newItemAccountRegionList"
          [newItemAccountCountryList]="newItemAccountCountryList"
          [newItemAccountDistributorList]="newItemAccountDistributorList"
          [calendarListDropdown]="calendarListDropdown"
          [accountSettingList]="itemAccountSettingList"
          [timeframeList]="timeframeList"
          [selectedItem]="dataModel.selectedItem"
          (resetTreeList)="createCalendarListDropdown()"
          [editPermission]="editPermission"
          [viewPermission]="viewPermission"
        ></app-new-item-planner-form>
      </mat-tab>

      <mat-tab label="New Account">
        <div class="container-fluid item-wrapper">
          <div class="row">
            <div class="col-md-6 padding-right">
              <div class="form-group">
                <label for="accounts">Select Account</label>
                <mat-form-field floatLabel="never">
                  <input
                    matInput
                    aria-label="SelectItem"
                    [matAutocomplete]="auto1"
                    [(ngModel)]="dataModel.itemAccountId"
                    name="accounts"
                    [ngModelOptions]="{ standalone: true }"
                    (input)="showFilteredAccounts($event.target.value)"
                  />
                  <mat-autocomplete
                    #auto1="matAutocomplete"
                    [displayWith]="getAccountOptionText"
                    (optionSelected)="submitAccountSearch($event.option.value)"
                  >
                    <mat-option
                      *ngFor="let item of filteredAccounts; let i = index"
                      [value]="item"
                    >
                      {{ item.accountName }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <app-new-item-planner-account-form
          [launchCurvesList]="launchCurvesList"
          [items]="itemsList"
          [accounts]="accountsList"
          [seasonlaityIndexList]="seasonlaityIndexList"
          [newItemProductGroupList]="newItemProductGroupList"
          [newItemProductFamilyList]="newItemProductFamilyList"
          [newItemParentProductList]="newItemParentProductList"
          [newItemAccountRegionList]="newItemAccountRegionList"
          [newItemAccountCountryList]="newItemAccountCountryList"
          [newItemAccountDistributorList]="newItemAccountDistributorList"
          [calendarListDropdown]="calendarListDropdown"
          [accountSettingList]="accountSettingList"
          [timeframeList]="timeframeList"
          [editPermission]="editPermission"
          [viewPermission]="viewPermission"
        ></app-new-item-planner-account-form>
      </mat-tab>
    </mat-tab-group>
  </mat-expansion-panel>
</mat-accordion>
