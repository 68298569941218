<div class="container-fluid">

  <div class="action-panel text-right top">
    <h2 class="form-heading">Tab Configuration</h2>

    <!-- <div class="sub-menu-icon" (click)="openCategoryDialog({}, tabCreateTemplate,false)"><img
        src="../../assets/images/plus.png" alt=""></div> -->
  </div>
  <!-- <ejs-treeview id="tree" #tree [fields]="field" (nodeDragStop)="dragStop($event)" (nodeDragging)="nodeDrag($event)"
  [allowDragAndDrop]="true" [allowEditing]="allowEditing" (nodeEditing)='editing($event)'
  (nodeEdited)='onNodeEdited($event)'>
  <ng-template #nodeTemplate="" let-data="">
    <div>
      <div style="pointer-events: all;" class="action-panel">
        <p class="m-0"> {{data.applicationTabName}} </p>
        <div class="menu">
          <div [id]="data.roleId" class="menu-toggle menu-hide"
            (click)="clickEvent(data.roleId);data.status=  !data.status">
            <i class="icon-more-vertical"></i>
          </div>
          <div class="sub-menu" [ngClass]="data.status ? 'active' : 'inactive'">
            <div class="sub-menu-icon" (click)="openConfirmationDialog(data)"><img src="../../assets/images/bin.svg"
                alt=""></div>
            <div class="sub-menu-icon" (click)="openCategoryDialog(data,tabCreateTemplate, true)"><img
                src="../../assets/images/edit.svg" alt=""></div>
            <div class="sub-menu-icon" (click)="openCategoryDialog(data,tabCreateTemplate, false)"><img
                src="../../assets/images/plus.png" alt=""></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ejs-treeview> -->

<div class="all-show-grid items-wrapper-grid">
  <ag-grid-angular class="ag-theme-material" [defaultColDef]="defaultColDef" [gridOptions]="gridOptions"
    [columnDefs]="columnDefs" [headerHeight]="headerHeight" [rowHeight]="rowHeight" [rowData]="tabList" (gridReady)="onGridReady($event)" [modules]="modules" #grid>
  </ag-grid-angular>
</div>

</div>


<ng-template #tabCreateTemplate let-data>
  <div mat-dialog-content>
    <form [formGroup]="registerForm" class="form-fields">
      <div class="row">
        <div class="col-md-4 form-group">
          <mat-form-field class="example-full-width" floatLabel="never">

            <input  [style.font-size]="'18px'" matInput placeholder="Select Tabs"  [(ngModel)]="applicationTabName"
            [ngModelOptions]="{standalone: true}"
            [matAutocomplete]="tabAuto" (input)="_filterScreen($event)"
          [title]="applicationTabName.applicationTabName ? applicationTabName.applicationTabName : ''" />

          <mat-autocomplete [displayWith]="displayFn" #tabAuto="matAutocomplete"
            (optionSelected)="submitSearch($event.option.value)">
            <mat-option *ngFor="let item of filteredTabs | async" [value]="item">
              {{item.applicationTabName}}
            </mat-option>
          </mat-autocomplete>

          </mat-form-field>
        </div>
        <div class="col-md-4 form-group">
          <mat-form-field>
            <mat-label>Assign Roles</mat-label>
            <mat-select multiple formControlName="roleIds">
              <mat-option *ngFor="let role of roleList" [value]="role.roleId">{{ role.roleName }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="submitted && registerForm.controls['roleIds'].hasError('required')">
            *Required Field
          </mat-error>
        </div>
      </div>
      <div class="action-panel text-right">
        <button mat-raised-button class="btn btn-primary m-left" type="submit" (click)="onSubmit(data)"
          color="primary">Save</button>
        <button mat-raised-button class="btn m-left btn-light" color="warn"
          (click)="dialogAddRef.close()">Cancel</button>
      </div>
    </form>
  </div>
</ng-template>
