<div [hidden]="!gridMapped">
  <mat-accordion class="fb-forecast-accordion">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title> Summary View </mat-panel-title>
      </mat-expansion-panel-header>
      <form class="summary-form-view">
        <div *ngFor="let item of summaryArray">
          <div class="row">
            <div class="col-12">
              <mat-label><b class="mb-2 d-block">{{item.originalOrder?.label}}</b></mat-label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2" *ngFor="let order of item.originalOrder?.totalOriginalOrderView">
              <div class="form-group input-black">
                <label>{{order.label}}</label>
                <input type="text" maxlength="7" disabled [(ngModel)]="order.value" class="form-control" placeholder="">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-label><b class="mb-2 d-block">{{item.adjustedOrder?.label}}</b></mat-label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2" *ngFor="let order of item.adjustedOrder?.adjustedOrderView">
              <div class="form-group input-black">
                <label>{{order.label}}</label>
                <input type="text" maxlength="7" disabled [ngModel]="order.value" (ngModelChange)="order.value=$event"
                  class="form-control" placeholder="">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-label><b class="mb-2 d-block">{{item.subsetOriginalOrder?.label}}</b></mat-label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2" *ngFor="let order of item.subsetOriginalOrder?.subsetOriginalOrderView">
              <div class="form-group input-green">
                <label>{{order.label}}</label>
                <input type="text" maxlength="7" disabled [(ngModel)]="order.value" class="form-control" placeholder="">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-label><b class="mb-2 d-block">{{item.subsetAdjustedOrder?.label}}</b></mat-label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2" *ngFor="let order of item.subsetAdjustedOrder?.subsetAdjustedOrderView">
              <div class="form-group input-green">
                <label>{{order.label}}</label>
                <input type="text" maxlength="7" disabled [(ngModel)]="order.value" class="form-control" placeholder="">
              </div>
            </div>
          </div>
        </div>
      </form>
      <ng-template #saveForecast>
        <h1 mat-dialog-title>What would you like to name your planner?</h1>
        <div mat-dialog-content>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Enter Planner Name" [(ngModel)]="plannerName"
              [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
        <div class="action-panel text-right">
          <button mat-raised-button class="btn btn-outline m-left" color="primary" (click)="ref.close()">Cancel</button>
          <button mat-raised-button class="btn btn-primary m-left" color="warn" (click)="ref.close(true)">Save</button>
        </div>

      </ng-template>
      <ng-template #commitForecast>
        <h1 mat-dialog-title>Congratulations! you have successfully committed</h1>
        <div mat-dialog-content>
        </div>
        <div class="action-panel text-right">
          <button mat-raised-button class="btn btn-outline m-left" color="primary" (click)="ref.close()">Go
            Back</button>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
