<div class="container-fluid" style="margin-top: 15px;">
    <!-- DROPDOWNS -->
    <div class="row">
        <div class="d-flex align-items-center col-md-4">
            <strong>Reporting Currency</strong>
            <mat-form-field appearance="fill">
                <mat-select [(ngModel)]="reportingCountry">
                    <ng-container *ngFor="let item of dropDownsData">
                        <mat-option *ngIf="item.id != unit1 && item.id != unit2 && item.id != unit3" [value]="item.id">
                            {{item.label}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-8"></div>
    </div>
    <div class="row">
        <div class="d-flex align-items-center col-md-4">
            <strong>Business Unit 1</strong>
            <mat-form-field appearance="fill">
                <mat-select (selectionChange)="selectionChanged(0, unit1)" [(ngModel)]="unit1">
                    <ng-container *ngFor="let item of dropDownsData">
                        <mat-option *ngIf="item.id != reportingCountry && item.id != unit2 && item.id != unit3"
                            [value]="item.id">
                            {{item.abbrev}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="d-flex align-items-center col-md-4">
            <strong>Business Unit 2</strong>
            <mat-form-field appearance="fill">
                <mat-select (selectionChange)="selectionChanged(1, unit2)" [(ngModel)]="unit2">
                    <ng-container *ngFor="let item of dropDownsData">
                        <mat-option *ngIf="item.id != reportingCountry && item.id != unit1 && item.id != unit3"
                            [value]="item.id">
                            {{item.abbrev}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="d-flex align-items-center col-md-4">
            <strong>Business Unit 3</strong>
            <mat-form-field appearance="fill">
                <mat-select (selectionChange)="selectionChanged(2, unit3)" [(ngModel)]="unit3">
                    <ng-container *ngFor="let item of dropDownsData">
                        <mat-option *ngIf="item.id != reportingCountry && item.id != unit2 && item.id != unit1"
                            [value]="item.id">
                            {{item.abbrev}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <hr class="theme-hr mt-2" />

    <!-- GRID -->
    <div class="d-flex justify-content-end p-2">
        <div class="d-flex align-items-center">
            <p class="mx-3 w-100">Set Conversion Rate</p>
            <mat-form-field appearance="fill">
                <mat-select (selectionChange)="conversionChanged()" [(ngModel)]="conversionRate">
                    <ng-container *ngFor="let item of conversionRates">
                        <mat-option *ngIf="item" [value]="item.id">
                            {{item.abbrev}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="all-show-grid">
        <ag-grid-angular class="ag-theme-material" [suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups
            toolPanelSuppressValues [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions"
            [columnDefs]="gridColumnDefs" [rowData]="selectedDataSource" [pagination]="true"
            [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
            (cellValueChanged)="gridDataUpdated($event)" [stopEditingWhenGridLosesFocus]="true"
            (gridReady)="onGridReady($event)" [modules]="modules" #planogramGrid>
        </ag-grid-angular>
    </div>

    <!-- GRAPHS -->
    <div class="row mt-2">
        <div class="col-md-6 shadow-cls" #ContainerRef1 *ngIf="graph1Data.length">
            <h4 class="text-center m-2">{{getTitle(0)}}</h4>
            <ngx-charts-line-chart
                [view]="[ContainerRef1.offsetWidth, ContainerRef1.offsetWidth*0.6]"
                [results]="graph1Data"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel">
            </ngx-charts-line-chart>
        </div>
        <div class="col-md-6 shadow-cls" #ContainerRef2 *ngIf="graph2Data.length">
            <h4 class="text-center m-2">{{getTitle(1)}}</h4>
            <ngx-charts-line-chart
                [view]="[ContainerRef2.offsetWidth, ContainerRef2.offsetWidth*0.6]"
                [results]="graph2Data"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel">
            </ngx-charts-line-chart>
        </div>
        <div class="col-md-6 shadow-cls" #ContainerRef2 *ngIf="graph3Data.length">
            <h4 class="text-center m-2">{{getTitle(2)}}</h4>
            <ngx-charts-line-chart
                [view]="[ContainerRef2.offsetWidth, ContainerRef2.offsetWidth*0.6]"
                [results]="graph3Data"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel">
            </ngx-charts-line-chart>
        </div>
    </div>
</div>