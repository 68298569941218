<mat-accordion class="fb-forecast-accordion item-wrapper-panel" id="fb-forecast">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
      <mat-panel-title>
        {{isEdit ? accountModel.accountName : 'New Account'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container-fluid item-wrapper">
      <form class="account-form">
        <div class="row">
          <div class="col-md-6 padding-right">
            <div class="form-group">
              <label for="account-name" class="required">Account Name</label>
              <input class="loading-animated-div" [disabled]="!accountModel.editPermission"
                [class.error]="submitted && !accountModel.accountName" type="text"
                [(ngModel)]="accountModel.accountName" name="accountName" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="form-group">
              <label for="account-type">Salesforce ID</label>
              <input class="loading-animated-div" [disabled]="!accountModel.editPermission" type="text"
                [(ngModel)]="accountModel.salesforceId" name="saleforceId" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="form-group">
              <label for="country" class="required">Channel</label>
              <mat-select [class.error]="submitted && !accountModel.channelId"
                [disabled]="(channelLoading) || !accountModel.editPermission" [class.loading-div]="channelLoading"
                (selectionChange)="onChannelChange(accountModel.channelId)" [(ngModel)]="accountModel.channelId"
                name="countryId" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let channel of channelList" [value]="channel.channelId">{{channel.channelName}}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label for="region" class="required">Region</label>
              <mat-select [class.error]="submitted && !accountModel.regionId"
                [disabled]="(continentLoading) || !accountModel.editPermission" [class.loading-div]="continentLoading"
                (selectionChange)="onRegionChange(accountModel.regionId)" [(ngModel)]="accountModel.regionId"
                name="continentId" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let region of regionList" [value]="region.continentId">{{region.continentName}}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label for="country" class="required">Country</label>
              <mat-select [class.error]="submitted && !accountModel.countryId"
                [disabled]="(countryLoading) || !accountModel.editPermission" [class.loading-div]="countryLoading"
                [(ngModel)]="accountModel.countryId" (selectionChange)="onCountryChange(accountModel.countryId)"
                name="countryId" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let country of countryList" [value]="country.countryId">{{country.countryName}}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label for="country" class="required">Distributor</label>
              <mat-select [class.error]="submitted && !accountModel.distributorId"
                [disabled]="(distributorLoading) || !accountModel.editPermission"
                [class.loading-div]="distributorLoading" [(ngModel)]="accountModel.distributorId" name="distributorId"
                [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let distributor of distributorList" [value]="distributor.distributorId">
                  {{distributor.distributorName}}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label for="account-type">Parent Account</label>
              <mat-select [disabled]="!accountModel.editPermission" [(ngModel)]="accountModel.accountTypeId"
                name="accountTypeId" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let account of accountTypeList" [value]="account.id">{{account.value}}</mat-option>
              </mat-select>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="account-type">Sub-Region</label>
              <mat-select [disabled]="!accountModel.editPermission" [(ngModel)]="accountModel.subRegion"
              name="accountTypeId" [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let value of subRegion" [value]="value">{{value}}</mat-option>
            </mat-select>

            </div>
            <div class="form-group">
              <label for="account-type">Partner Type</label>
              <mat-select [disabled]="!accountModel.editPermission" [(ngModel)]="accountModel.partnerType"
              name="partnerType" [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let partner of partnerType" [value]="value">{{partner.value}}</mat-option>
            </mat-select>

            </div>
            <div class="form-group">
              <label for="account-type">Sales Reporting</label>
              <mat-select [disabled]="!accountModel.editPermission" [(ngModel)]="accountModel.partnerType"
              name="salesReporting" [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let value of salesReporting" [value]="value">{{value}}</mat-option>
            </mat-select>

            </div>
            <div class="form-group">
              <label for="account-type">Lead Time (Days)</label>
              <input class="loading-animated-div" [disabled]="!accountModel.editPermission" type="text"
                [(ngModel)]="accountModel.leadtimedays" name="leadTime" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="form-group">
              <label for="account-type">Disti Lead Time (Days)</label>
              <input class="loading-animated-div" [disabled]="!accountModel.editPermission" type="text"
                [(ngModel)]="accountModel.distileadtimedays" name="distiLeadTime" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="form-group">
              <label for="account-type">Country Proper</label>
              <input class="loading-animated-div" [disabled]="!accountModel.editPermission" type="text"
                [(ngModel)]="accountModel.countryProper" name="country" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="form-group">
              <label for="account-type">Region Proper</label>
              <input class="loading-animated-div" [disabled]="!accountModel.editPermission" type="text"
                [(ngModel)]="accountModel.regionProper" name="regionProper" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="form-group">
              <label for="">Active</label>
              <mat-slide-toggle [(ngModel)]="accountModel.active" [disabled]="!accountModel.editPermission">
              </mat-slide-toggle>
            </div>
          </div>
          <div class="col-12 account-btn-wrapper">
            <div class="info">
              <div class="labels">
                <h4>Created By:</h4>
                <h4>Emily</h4>
              </div>
              <div class="labels">
                <h4>Created:</h4>
                <h4>11/02/2021</h4>
              </div>
              <div class="labels">
                <h4>Last Modified By:</h4>
                <h4>Emily</h4>
              </div>
              <div class="labels">
                <h4>Last Modified:</h4>
                <h4>11/02/2021</h4>
              </div>
            </div>
            <div class="btn-wrapper">
              <button class="btn btn-secondary" (click)="closeAccountForm()">Close</button>
              <button class="btn btn-primary" [disabled]="!accountModel.editPermission"
                (click)="isEdit ? openDialog(saveConfirmation): onSubmit()">{{isEdit ?
                'Update' :
                'Create'}}</button>
            </div>
          </div>
        </div>
      </form>

    </div>
  </mat-expansion-panel>
  <ng-template #saveConfirmation>
    <div mat-dialog-content>
      <p> Are you sure you want to save and override the information in this account? </p>
    </div>
    <hr>
    <div class="item-wrapper">
      <div class="action-panel text-right btn-wrapper">
        <button mat-raised-button class="btn btn-secondary m-left" (click)="dialogRef.close()">Cancel</button>
        <button mat-raised-button class="btn btn-primary m-left" (click)="onSubmit()"> Save
        </button>
      </div>
    </div>
  </ng-template>
