export const generateGridColumns = () => {
    const gridColumnDefs = [
        {
            field: 'class',
            headerName: 'Class',
            rowSpan: function (params) {
                if (params.data.class) {
                    return 3
                }
                else return 1
            },
            colSpan: function (params) {
                if (params.data.totals) {
                    return 2
                }
                else return 1
            },
            cellStyle: params => {
                if (params.data.totals) { return { borderBottom: '1px solid gray', borderTop: '1px solid gray', color: 'black', backgroundColor: '#e5e5e5' } }
                else return { color: 'black', backgroundColor: '#e5e5e5' }
            },
            cellRenderer: function (params) {
                if (params.data.totals) {
                    return '<div class="text-center"><b>Totals</b></div>'
                } else {
                    return params.data.class;
                }
            }
        },
        {
            field: 'tiers',
            headerName: 'Price Tiers',
            cellStyle: params => {
                if (params.data.totals) { return { borderBottom: '1px solid gray', borderTop: '1px solid gray', color: 'black', backgroundColor: '#e5e5e5' } }
                else return { color: 'black', backgroundColor: '#e5e5e5' }
            }
        },
        {
            field: 'fy2021',
            headerName: 'FY 2021',
            cellRenderer: function (params) {
                if (params.data.totals) {
                    return '<div><b>' + params.data.fy2021 ? Number(params.data.fy2021).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '' + '</b></div>'
                } else {
                    return params.data.fy2021 ? Number(params.data.fy2021).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
                }
            },
            cellStyle: params => {
                if (params.data.totals) { return { borderBottom: '1px solid gray', borderTop: '1px solid gray', color: 'black', backgroundColor: '#e5e5e5' } }
                else return { color: 'black', backgroundColor: '#e5e5e5' }
            }
        },
        {
            field: 'fy2022',
            headerName: 'FY 2022',
            editable: params => {
                if (params.data.totals) {
                    return false;
                } else return true;
            },
            cellStyle: params => {
                if (params.data.totals) { return { borderBottom: '1px solid gray', borderTop: '1px solid gray', color: 'black', backgroundColor: '#e5e5e5' } }
                else return;
            },
            cellRenderer: function (params) {
                if (params.data.totals) {
                    return '<div><b>' + params.data.fy2022 ? Number(params.data.fy2022).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '' + '</b></div>'
                } else {
                    return params.data.fy2022 ? Number(params.data.fy2022).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
                }
            }
        },
        {
            field: 'growth',
            headerName: 'Growth %',
            editable: params => {
                if (params.data.totals) {
                    return false;
                } else return true;
            },
            cellStyle: params => {
                if (params.data.totals) { return { borderBottom: '1px solid gray', borderTop: '1px solid gray', color: 'black', backgroundColor: '#e5e5e5' } }
                else return;
            },
            cellRenderer: function (params) {
                if (params.data.totals) {
                    return '<div><b>' + params.data.growth ? Number(params.data.growth).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '' + '</b></div>'
                } else {
                    return params.data.growth ? Number(params.data.growth).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
                }
            }
        },
        {
            field: 'fy2021Cont',
            headerName: 'FY 2021 Cont %',
            cellRenderer: function (params) {
                if (params.data.totals) {
                    return '<div><b>' + params.data.fy2021Cont ? Number(params.data.fy2021Cont).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '' + '</b></div>'
                } else {
                    return params.data.fy2021Cont ? Number(params.data.fy2021Cont).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
                }
            },
            cellStyle: params => {
                if (params.data.totals) { return { borderBottom: '1px solid gray', borderTop: '1px solid gray', color: 'black', backgroundColor: '#e5e5e5' } }
                else return { color: 'black', backgroundColor: '#e5e5e5' }
            }
        },
        {
            field: 'fy2022Cont',
            headerName: 'FY 2022 Cont %',
            editable: params => {
                if (params.data.totals) {
                    return false;
                } else return true;
            },
            cellStyle: params => {
                if (params.data.totals) { return { borderBottom: '1px solid gray', borderTop: '1px solid gray', color: 'black', backgroundColor: '#e5e5e5' } }
                else return;
            },
            cellRenderer: function (params) {
                if (params.data.totals) {
                    return '<div><b>' + params.data.fy2022Cont ? Number(params.data.fy2022Cont).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '' + '</b></div>'
                } else {
                    return params.data.fy2022Cont ? Number(params.data.fy2022Cont).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
                }
            }
        }
    ];
    return gridColumnDefs;
};

export const generateSecondGridColumns = () => {
    const gridColumnDefs = [
        {
            field: 'class',
            headerName: 'Class',
            cellRenderer: function (params) {
                if (params.data.totals) {
                    return '<div><b>Grand Total</b></div>'
                } else {
                    return params.data.class;
                }
            },
            cellStyle: params => {
                if (params.data.totals) { return { borderBottom: '1px solid gray', borderTop: '1px solid gray', color: 'black', backgroundColor: '#e5e5e5' } }
                else return { color: 'black', backgroundColor: '#e5e5e5' }
            }
        },
        {
            field: 'target',
            headerName: 'Top Down Targets',
            cellRenderer: function (params) {
                if (params.data.totals) {
                    return '<div><b>' + params.data.target ? Number(params.data.target).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '' + '</b></div>'
                } else {
                    return params.data.target ? Number(params.data.target).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
                }
            },
            cellStyle: params => {
                if (params.data.totals) { return { borderBottom: '1px solid gray', borderTop: '1px solid gray', color: 'black', backgroundColor: '#e5e5e5' } }
                else return { color: 'black', backgroundColor: '#e5e5e5' }
            }
        },
        {
            field: 'plan',
            headerName: 'Price Tiers Plan',
            cellRenderer: function (params) {
                if (params.data.totals) {
                    return '<div><b>' + params.data.plan ? Number(params.data.plan).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '' + '</b></div>'
                } else {
                    return params.data.plan ? Number(params.data.plan).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
                }
            },
            cellStyle: params => {
                if (params.data.totals) { return { borderBottom: '1px solid gray', borderTop: '1px solid gray', color: 'black', backgroundColor: '#e5e5e5' } }
                else return { color: 'black', backgroundColor: '#e5e5e5' }
            }
        },
        {
            field: 'var',
            headerName: 'Var%',
            cellRenderer: function (params) {
                let res = '-';
                if (params.data.var && params.data.var < 0) {
                    res = '(' + Math.abs(params.data.var).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ')';
                } else {
                    if (params.data.var) {
                        res = params.data.var.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                }
                if (params.data.totals) {
                    return '<div><b>' + res + '</b></div>'
                } else {
                    return res;
                }
            },
            cellStyle: params => {
                if (params.data.totals) { return { borderBottom: '1px solid gray', borderTop: '1px solid gray', color: 'black', backgroundColor: '#e5e5e5' } }
                else return { color: 'black', backgroundColor: '#e5e5e5' }
            }
        }
    ];
    return gridColumnDefs;
};
