import { ColDef } from '@ag-grid-community/all-modules';
import { enforcementDropDownOptions } from '../contants/planogram-contants';

export const paginationPageSize = 50;
export const cacheBlockSize = 50;

export const planogramGridColDefs: ColDef[] = [
  {
    headerName: '',
    width: 100,
    field: 'cellAction',
    cellRenderer: 'planogramItemActionCellRenderer',
    suppressColumnsToolPanel: true
  },
  {
    headerName: 'Age',
    field: 'weeks_old',
    minWidth: 40,
    resizable: true,
  },
  {
    headerName: 'Street',
    field: 'street',
    minWidth: 40,
    resizable: true,
  },
  {
    headerName: 'Title',
    field: 'title',
    minWidth: 100,
    resizable: true,
  },
  {
    headerName: 'Vendor',
    field: 'studio',
    minWidth: 40,
    resizable: true,
  },
  {
    headerName: 'SKU',
    field: 'sku',
    minWidth: 40,
    resizable: true,
  },
  {
    headerName: 'UPC',
    field: 'upc',
    minWidth: 40,
    resizable: true,
  },
  {
    headerName: 'Forecast',
    field: 'street_week',
    minWidth: 40,
    resizable: true,
  },
  {
    headerName: 'TW Forecast',
    field: 'tw_forecast',
    minWidth: 40,
    resizable: true,
  },
  {
    headerName: 'Group',
    field: 'group',
    minWidth: 40,
    resizable: true,
    editable: true,
    cellEditor: 'numberCellEditor'
  },
  {
    headerName: 'Min Facings',
    field: 'minimum_facing',
    minWidth: 40,
    resizable: true,
    editable: true,
    cellEditor: 'numberCellEditor'
  },
  {
    headerName: 'Max Facings',
    field: 'maximum_facing',
    minWidth: 40,
    resizable: true,
    editable: true,
    cellEditor: 'numberCellEditor'
  },
  {
    headerName: 'Enforce Top Row?',
    field: 'enforce_top',
    minWidth: 40,
    resizable: true,
    cellRenderer: 'checkboxRenderer'
  },
  {
    headerName: 'FOSNR Enforcement',
    field: 'planogram_side',
    minWidth: 40,
    resizable: true,
    editable: true,
    cellRenderer: 'dropdownRendererComponent',
    cellRendererParams: (params) => {
      const items = enforcementDropDownOptions;
      const obj = {
        items: items,
        isDropDown: true,
        value: params.value,
        onChange: (e) => { 
          let colId = params.column.colId;
          params.node.setDataValue(colId, e.event.value);
        },
      }
      return obj;
    }
  }
];
