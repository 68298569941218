import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FieldsSettingsModel, NodeCheckEventArgs, TreeView } from '@syncfusion/ej2-angular-navigations';
import { CATEGORY_COL_DEFS } from 'src/common/algo-column-definitions';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-channel-view',
  templateUrl: './channel-view.component.html',
  styleUrls: ['./channel-view.component.scss']
})

export class ChannelViewComponent implements OnInit {
  @ViewChild('tree') public tree: TreeView;

  public registerForm: FormGroup;
  public submitted = false;
  public isEdit = false;
  public columnDefs = CATEGORY_COL_DEFS;
  private gridApi: any;
  public field = {};
  public categoryList = [];
  public gridOptions: any;
  public modules = AllCommunityModules;
  public groupDefaultExpanded;
  public getDataPath;
  public autoGroupColumnDef;
  public allowEditing: boolean = true;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public dialogAddRef: MatDialogRef<CategoryAddComponent>,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
    this.gridOptions = {
      frameworkComponents: {
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }

  ngOnInit() {
    this.getDataPath = (data) => {
      return data.parentId;
    };
    this.populateList();
    this.initializeForm();
  }
  public populateList = () => {
    this.configurationService.getCategoryList({ clientId: 1 }).subscribe((res: any) => {
      this.categoryList = res;
      this.categoryList.forEach(category => {
        if (this.categoryList.find(result => category.categoryId === result.parentId)) {
          category.hasChild = true;
        }
      });
      this.field = { dataSource: this.categoryList, id: 'categoryId', parentID: 'parentId', text: 'categoryName', hasChildren: 'hasChild' };
    });
  }
  public editing(args: NodeCheckEventArgs) {
    // if (args.node.parentNode.parentNode.nodeName !== 'LI') {
    //   args.cancel = true;
    // }
  }
  public removeNode = (nodeData: any) => {
    let list = (this.field as any).dataSource;
    const model = {
      categoryId: nodeData.categoryId,
      active: false
    };
    this.configurationService.activateCategory(model).subscribe(flag => {
      if (flag) {
        list = (this.field as any).dataSource.filter(res => res.categoryId !== nodeData.categoryId);
        this.field = { dataSource: list, id: 'categoryId', parentID: 'parentId', text: 'categoryName', hasChildren: 'hasChild' };
        this.tree.expandAll();
      }
    });
  }
  initializeForm() {
    this.registerForm = this.formBuilder.group({
      channelName: ['', Validators.required],
      channelId: [0],
      clientId: [1, Validators.required],
      description: ['', Validators.required],
      active: true,
      currentUserId: [this.storage.get(USER_ID)]
    });
  }
  populateForm(event): void {
    if (event && event.data) {
      this.isEdit = true;
      const dataToPopulate = event.data;
      Object.keys(this.registerForm.controls).forEach(key => {
        if (dataToPopulate[key]) {
          this.registerForm.controls[key].setValue(dataToPopulate[key]);
        }
      });
    }
  }
  onNodeEdited($event) {
    const nodeData = $event.nodeData;
    const newCategory = {
      categoryId: nodeData.id,
      categoryName: $event.newText,
      clientId: nodeData.id,
      parentId: nodeData.parentID,
      active: true
    };
    this.configurationService.updateCategory(newCategory).subscribe(res => {
      if (res) {
        this.toastr.success('Success', 'Category Updated Successfully');
      }
    });
  }
  Cancel_Click() {
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  openCategoryDialog(nodeData: any) {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogAddRef = this.dialog.open(CategoryAddComponent, {
      width: '500px',
      data: nodeData
    });

    this.dialogAddRef.afterClosed().subscribe(result => {
      if (result && result.parentID) {
        this.addNewCategory(result);
      } else {
        this.addParentCategory(result);
      }
    });
  }
  openConfirmationDialog(nodeData: any) {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { ...nodeData, headerName: nodeData.categoryName }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeNode(result);
      }
    });
  }
  public addNewCategory = (result: any) => {
    if (result) {
      const model = {
        ...result,
        clientId: 1
      };
      this.configurationService.addCategory(model).subscribe(res => {
        if (res) {
          result.categoryId = res;
          const list = (this.field as any).dataSource;
          list.forEach(element => {
            if (element.categoryId === result.parentId) {
              element.hasChild = true;
            }
          });
          list.push(result);
          this.field = { dataSource: list, id: 'categoryId', parentID: 'parentId', text: 'categoryName', hasChildren: 'hasChild' };
          this.tree.refresh();
        }
      });
    }
  }
  public addParentCategory = (result: any) => {
    if (result) {
      const model = {
        ...result,
        clientId: 1
      };
      this.configurationService.addCategory(model).subscribe(res => {
        if (res) {
          this.populateList();
        }
      });
    }
  }
}
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'category-dialog',
  templateUrl: 'category-name.html',
})
export class CategoryAddComponent {
  public category = '';
  constructor(
    public dialogRef: MatDialogRef<CategoryAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit() {
    if (this.category) {
      this.dialogRef.close({ categoryId: 0, parentId: this.data.categoryId, categoryName: this.category });
    }
  }
}
