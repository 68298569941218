import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CustomHeaderComponent } from '../goal-settings/custom-header.component';
import { ButtonRendererComponent } from '../goal-settings/button-renderer.component';
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
@Component({
  selector: 'app-category-settings',
  templateUrl: './category-settings.component.html',
  styleUrls: ['./category-settings.component.scss']
})
export class CategorySettingsComponent implements OnInit, OnDestroy {

  resetVar: boolean = true;
  afuConfig = {
    uploadAPI: {
      url: 'https://example-file-upload-api'
    },
    multiple: false,
    formatsAllowed: '.xls,.xlsx',
    replaceTexts: {
      selectFileBtn: 'Select Files',
      resetBtn: 'Reset',
      uploadBtn: 'Export Data',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded !',
      afterUploadMsg_error: 'Upload Failed !'
    }
  };

  public submitted = false;
  public isEdit = false;
  public columnDefs: any = [{
    headerName: 'Category',
    field: 'CategoryMixRow.Name',
    pinned: 'left'
  }];
  private gridApi: any;
  public categoryHeaderTypeList = [];
  public categoryHeaderTypeListTemp = [];
  public categoryList = [];
  public categories = [];
  public category: any;
  public gridOptions: any;
  public getRowHeight;
  public  headerHeight;
  public modules = AllCommunityModules;
  public clientId = 1;
  public editSubscription: Subscription;
  public removeSubscription: Subscription;
  public categoryTypeList = [];
  public channelList = [];
  public channel: any;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public dialogAddRef: MatDialogRef<DialogCategoryAddSettingsComponent>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public dialogRef: MatDialogRef<DialogCategorySettingsComponent>) {
    this.gridOptions = {
      frameworkComponents: { agColumnHeader: CustomHeaderComponent, buttonRenderer: ButtonRendererComponent, },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.headerHeight = 40;
    this.gridOptions.getRowHeight = function(params) {
      return 40;
    };

  }
  delete(rowData: any) {
    const row = {
      CategoryMixRowId: rowData.CategoryMixRow.CategoryMixRowId,
      active: false,
    };
    this.spinner.show();
    if (row.CategoryMixRowId) {
      this.configurationService.activateCategoryMixRow(row).subscribe(res => {
        this.categoryList = this.categoryList.filter(Category => row.CategoryMixRowId !== Category.CategoryMixRow.CategoryMixRowId);
        this.gridOptions.api.setRowData(this.categoryList);
        this.spinner.hide();
        this.toastr.success('Success', 'Category removed successfully');
      }, error => {
        this.spinner.hide();
      });
    } else {
      this.categoryList = this.categoryList.filter(Category => rowData !== Category);
      this.spinner.hide();
    }

  }
  ngOnInit() {
    this.populateList();
    this.editSubscription = this.configurationService.getEditColumnSubject().subscribe(res => {
      if (res) {
        this.openColumnDialog(true, res);
      }
    });
    this.removeSubscription = this.configurationService.getRemoveColumnSubject().subscribe(res => {
      if (res) {
        this.openRemoveColumnDialog(res);
      }
    });
  }
  ngOnDestroy() {
    this.editSubscription.unsubscribe();
    this.removeSubscription.unsubscribe();
  }
  public getCategoryMixFormList = async (Category: any) => {
    if (!this.channel && !this.category) {
      return;
    }
    this.spinner.show();
    this.categoryTypeList = await this.configurationService.getCategoryMixRowList({ CategoryId: 58, clientId: 1, channelId: this.channel.channelId }).toPromise();
    this.configurationService.getCategoryMixFormList({ CategoryId: this.category.categoryId, clientId: 1, channelId: this.channel.channelId }).subscribe((Categorys: any) => {
      if (Categorys.pivotList.length > 0) {
        this.categoryList = Categorys.pivotList || [];
        this.categoryList.forEach(element => {
          element.CategoryMixRow = {
            Name: element.CategoryMixRow.name,
            CategoryMixRowId: element.CategoryMixRow.categoryMixRowId,
            CategoryId: element.CategoryMixRow.categoryId,
            ClientId: element.CategoryMixRow.clientId,
            ChannelId: element.CategoryMixRow.channelId,
            Active: true
          };
        });
      }
      this.spinner.hide();
    }, error => {
      this.categoryList = this.categoryTypeList.map(category => ({
        CategoryMixRow: {
          Name: category.name,
          CategoryMixRowId: category.categoryMixRowId,
          CategoryId: this.category.categoryId,
          ClientId: 1,
          Active: category.active,
          ChannelId: this.channel.channelId
        }
      }));
      this.spinner.hide();
    });
    this.configurationService.getCategoryMixHeaderList({ CategoryId: this.category.categoryId, clientId: 1, active: true, channelId: this.channel.channelId }).subscribe((res: any) => {
      this.categoryHeaderTypeList = res;
      this.categoryHeaderTypeListTemp = this.categoryHeaderTypeList;
      this.categoryHeaderTypeListTemp = this.categoryHeaderTypeListTemp.map(CategoryHeader => ({ id: CategoryHeader.categoryMixHeaderId, headerName: CategoryHeader.name, field: CategoryHeader.code, active: CategoryHeader.active, channelId: CategoryHeader.channelId }));
      this.columnDefs = [{
        headerName: 'Category',
        field: 'CategoryMixRow.Name',
        pinned: 'left'
      }];
      this.categoryHeaderTypeListTemp.forEach(CategoryHeader => {
        this.columnDefs.push({
          ...CategoryHeader, editable: true, valueSetter: function (params) {
            params.data[params.colDef.field] = Number(params.newValue);
            return true;
          }
        });
      });
      this.gridOptions.api.setColumnDefs(this.columnDefs);
      this.spinner.hide();
    }, error => {
      this.columnDefs = [{
        headerName: 'Category',
        field: 'CategoryMixRow.Name',
        pinned: 'left'
      }];
      this.spinner.hide();
    });
  }
  public populateList = () => {
    this.configurationService.getCategoryList({ clientId: 1, parent: true }).subscribe(res => {
      this.categories = res || [];
    });
    this.configurationService.getAllChannels({ clientId: 1 }).subscribe(res => {
      this.channelList = res.data;
    });
  }
  populateForm(event): void {
    if (event && event.data) {
      this.isEdit = true;
      const dataToPopulate = event.data;
    }
  }
  public onSubmit = () => {
    this.submitted = true;
    let model = this.gridOptions.rowData;
    const list = ['CategoryMixRow.Name', 'cancel'];
    this.columnDefs.forEach(column => {
      if (!list.includes(column.field)) {
        model.forEach(element => {
          element[column.field] = element[column.field] ? element[column.field] : 0;
        });
      }
    });
    const columnList = this.columnDefs.filter(col => col.id).map(res => ({ CategoryMixHeaderId: res.id, Name: res.headerName, CategoryId: this.category.categoryId, ClientId: this.clientId, Code: res.field, Active: res.active || true, ChannelId: this.channel.channelId }));
    model = {
      listOfCategoryMixHeader: columnList,
      categoryMixRowList: model
    };
    this.spinner.show();
    this.configurationService.addCategoryMixForm(model).subscribe(res => {
      this.spinner.hide();
      this.toastr.success('Success', 'Category saved successfully');
    }, error => {
      this.spinner.hide();
    });
  }
  Cancel_Click() {
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  openCategoryDialog() {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogAddRef = this.dialog.open(DialogCategoryAddSettingsComponent, {
      width: '500px',
      data: this.columnDefs
    });

    this.dialogAddRef.afterClosed().subscribe(result => {
      if (result) {
        const list = ['CategoryMixRow.Name', 'cancel'];
        const defaultFields = {};
        this.columnDefs.forEach(column => {
          if (!list.includes(column.field)) {
            defaultFields[column.field] = 0;
          }
        });
        result = {
          CategoryMixRow: { ...result, CategoryId: this.category.categoryId, ClientId: this.clientId },
          ...defaultFields
        };
        this.categoryList.push(result);
        this.gridOptions.api.setRowData(this.categoryList);
      }
    });
  }
  openColumnDialog(isEdit?: boolean, column?: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogRef = this.dialog.open(DialogCategorySettingsComponent, {
      width: '500px',
      data: { ...column, isEdit: isEdit, columnDefs: this.columnDefs },
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.oldColumn) {
          this.updateColumnDef(result);
        } else {
          this.addColumnDef(result.headerName);
        }
        this.gridOptions.api.setColumnDefs(this.columnDefs);
      }
    });
  }
  public addColumnDef = (headerName: string) => {
    const model = {
      name: headerName,
      clientId: this.clientId,
      CategoryId: this.category.categoryId,
      ChannelId: this.channel.channelId
    };
    this.spinner.show();
    this.configurationService.addCategoryMixHeader(model).subscribe(res => {
      if (res) {
        const newModel = { id: res.CategoryMixHeaderId || res.categoryMixHeaderId, headerName: res.name, field: res.code, active: res.active };
        this.columnDefs.push({
          ...newModel, editable: true, valueSetter: function (params) {
            params.data[params.colDef.field] = Number(params.newValue);
            return true;
          }
        });
        this.categoryList.forEach(Category => {
          Category[res.code] = null;
        });
        this.gridOptions.api.setColumnDefs(this.columnDefs);
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
    });
  }
  public updateColumnDef = (result: any) => {
    const model = {
      name: result.headerName,
      code: result.field,
      active: result.active,
      clientId: this.clientId,
      CategoryMixHeaderId: result.id,
      CategoryId: this.category.categoryId,
      ChannelId: this.channel.channelId
    };
    this.spinner.show();
    this.configurationService.updateCategoryMixHeader(model).subscribe(res => {
      if (res) {
        this.columnDefs.forEach(col => {
          if (col.headerName === result.oldColumn) {
            col.headerName = result.headerName;
            col.field = result.field;
          }
        });
        this.gridOptions.api.setColumnDefs(this.columnDefs);
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }
  openRemoveColumnDialog(columnToRemove?: string): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: columnToRemove
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.categoryHeaderTypeListTemp = this.columnDefs;
        this.removeColumn(result);
      }
    });
  }
  public removeColumn = (column: any) => {
    if (column) {
      const model = {
        CategoryMixHeaderId: column.id,
        active: false
      };
      this.spinner.show();
      this.configurationService.activateCategoryMixHeader(model).subscribe(res => {
        if (res) {
          this.columnDefs = this.columnDefs.filter(col => col.id !== column.id);
          this.gridOptions.api.setColumnDefs(this.columnDefs);
          this.spinner.hide();
        }
      }, error => {
        this.spinner.hide();
      });
    }
  }
  openRemoveCategoryDialog(CategoryToRemove: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: CategoryToRemove.rowData.CategoryMixRow.name || CategoryToRemove.rowData.CategoryMixRow.Name }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.delete(CategoryToRemove.rowData);
      }
    });
  }
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'header-name-dialog',
  templateUrl: 'header-name-dialog.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DialogCategorySettingsComponent {
  public headerName = '';
  date = new FormControl(_moment());
  public showErrorMessage = false;
  monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  constructor(
    public dialogRef: MatDialogRef<DialogCategorySettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data && this.data.isEdit) {
      const headerName = this.data.headerName;
      const date = _moment(`${headerName.split('-')[1]}-${this.monthList.findIndex(res => res === headerName.split('-')[0]) + 1}-01`);
      this.date.setValue(date);
    }
  }
  chosenYearHandler(normalizedYear: any) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit() {
    if (this.date.value) {
      if (!this.data.isEdit) {
        this.data.field = this.headerName.replace(/[^A-Za-z0-9 ]/ig, '');
      }
      this.headerName = this.date.value ? this.getMonthYearValue(this.date.value) : '';
      const newColumn = {
        ...this.data,
        headerName: this.headerName,
        oldColumn: this.data.displayName,
      };
      const columnIfExists = this.data.columnDefs.find(column => column.headerName.toLowerCase() === this.headerName.toLowerCase());
      if (!columnIfExists) {
        this.showErrorMessage = false;
        this.dialogRef.close(newColumn);
      } else {
        this.showErrorMessage = true;
      }
    }
  }
  public getMonthYearValue = (date: any) => {
    return `${this.monthList[date.month()]}-${date.year()}`;
  }
}
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'category-dialog',
  templateUrl: 'category-name-dialog.html',
})
export class DialogCategoryAddSettingsComponent {
  public category = '';
  constructor(
    public dialogRef: MatDialogRef<DialogCategoryAddSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit() {
    if (this.category) {
      this.dialogRef.close({ Name: this.category, CategoryMixRowId: 0 });
    }
  }
}
