export const paginationPageSize = 10;
export const cacheBlockSize = 50;
const dropDownNumbers = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];

export const businessMetaDataGridColDefs = (columns) => {
  const columnsArray = [];
  columns.forEach((element: string, index) => {
    const obj = {
      headerName: element,
      field: element,
      minWidth: 50,
      resizable: true,
    }
    const objDropDown =  {
      headerName: element,
      field: element,
      minWidth: 50,
      resizable: true,
      editable: true,
      cellRenderer: 'dropdownRendererComponent',
      cellRendererParams: (params) => {
        const items = dropDownNumbers;
        const obj_ = {
          items: items,
          isDropDown: true,
          value: params.value,
          onChange: (e) => { 
            let colId = params.column.colId;
            params.node.setDataValue(colId, e.event.value);
          },
        }
        return obj_;
      }
    };
    const dateObj = {
      headerName: element,
      field: element,
      minWidth: 50,
      resizable: true,
      editable: true,
      cellRenderer: 'agGridDatePickerRenderer',
      cellRendererParams: (params) => {
        const obj_date = {
          value: params.value,
          onChange: (e) => { 
            let colId = params.column.colId;
            params.node.setDataValue(colId, e.event.value);
          },
        }
        return obj_date;
      }
    };
    if (element.toLowerCase().includes('date')) {
      columnsArray.push(dateObj); 
    }
    else if (index>2 && element.toLowerCase().includes('code')) {
      columnsArray.push(objDropDown)
    } else {
      columnsArray.push(obj);
    }
  });
  return columnsArray;
}
