<h1 mat-dialog-title>Select File(s) to import</h1>
<div mat-dialog-content>
  <div class="container-fluid item-wrapper">
    <br />
    <div>
      <ngx-dropzone (change)="importFile($event)">
        <ngx-dropzone-label>Select File to Import</ngx-dropzone-label>
        <ngx-dropzone-preview
          *ngFor="let f of files"
          [removable]="true"
          (removed)="onRemove(f)"
        >
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>
    <br />
    <div class="d-flex justify-content-end">
      <button mat-raised-button class="btn btn-primary m-right" color="primary" (click)="closeDialog()">Cancel</button>
    </div>
  </div>
</div>
<ng-template #importError>
  <div mat-dialog-content>
    <p>
      Error: Algo was unable to import this file, please check the data format
      or export a template to utilize
    </p>
  </div>
  <div
    class="action-panel text-right btn-wrapper"
    style="
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
  >
    <div style="display: flex">
      <button
        style="margin-left: 0px"
        mat-raised-button
        class="btn btn-primary m-right"
        color="warn"
        (click)="dialogRefError.close(); closeDialog()"
      >
        Retry
      </button>
      <button
        mat-raised-button
        class="btn btn-primary m-right"
        color="warn"
        (click)="dialogRefError.close()"
      >
        Cancel
      </button>
    </div>
  </div>
</ng-template>
