<div class="container-fluid">
  <div class="row">
    <div class="col-md-3">
      <img
        *ngIf="!itemModel.documentPath"
        src="assets/images/thumbnail.png"
        alt=""
        class="img-fluid"
      />
      <img
        *ngIf="itemModel.documentPath"
        [src]="itemModel.documentPath"
        alt=""
        class="img-fluid"
      />
      <ul>
        <li>
          <a (click)="openImageDialog(DocumentImport)" href="javascript:void(0)"
            >Upload Image</a
          >
        </li>
        <li>
          <a (click)="deleteImage()" href="javascript:void(0)">Clear Image</a>
        </li>
      </ul>
      <div class="info">
        <div class="labels">
          <h4>Created By:</h4>
          <h4>{{ itemModel.createdByName }}</h4>
        </div>
        <div class="labels">
          <h4>Created:</h4>
          <h4>{{ itemModel.createdDate | date: "short" }}</h4>
        </div>
        <div class="labels">
          <h4>Last Modified By:</h4>
          <h4>{{ itemModel.modifiedByName }}</h4>
        </div>
        <div class="labels">
          <h4>Last Modified:</h4>
          <h4>{{ itemModel.modifiedDate | date: "short" }}</h4>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="form-group">
        <label for="item">Item description</label>
        <input
          [class.error]="submitted && !itemModel.description"
          type="text"
          id="item"
          [(ngModel)]="itemModel.description"
        />
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="">SKU</label>
            <input
              [class.error]="submitted && !itemModel.sku"
              type="text"
              [readOnly]="!itemModel.editPermission"
              [(ngModel)]="itemModel.sku"
            />
          </div>

          <div class="form-group">
            <label for="">Brand</label>
            <mat-select
              [class.error]="submitted && !itemModel.brandId"
              [disabled]="brandLoading || !itemModel.editPermission"
              [class.loading-div]="brandLoading"
              (selectionChange)="GetProductGroupList(itemModel.brandId)"
              [(ngModel)]="itemModel.brandId"
            >
              <mat-option
                *ngFor="let brand of brandList"
                [value]="brand.brandId"
                >{{ brand.brandName }}</mat-option
              >
            </mat-select>
          </div>
          <div class="form-group">
            <label for="">Product Group</label>
            <mat-select
              [class.error]="submitted && !itemModel.productGroupId"
              [disabled]="productGroupLoading || !itemModel.editPermission"
              [class.loading-div]="productGroupLoading"
              (selectionChange)="GetProductFamilyList(itemModel.productGroupId)"
              [(ngModel)]="itemModel.productGroupId"
            >
              <mat-option
                *ngFor="let group of productGroupList"
                [value]="group.productGroupId"
              >
                {{ group.productGroupName }}</mat-option
              >
            </mat-select>
          </div>
          <div class="form-group">
            <label for="">Product Family</label>
            <mat-select
              [class.error]="submitted && !itemModel.productFamilyId"
              [disabled]="productFamilyLoading || !itemModel.editPermission"
              [class.loading-div]="productFamilyLoading"
              (selectionChange)="
                GetParentProductList(itemModel.productFamilyId, true)
              "
              [(ngModel)]="itemModel.productFamilyId"
            >
              <mat-option
                *ngFor="let family of productFamilyList"
                [value]="family.productFamilyId"
              >
                {{ family.productFamilyName }}</mat-option
              >
            </mat-select>
          </div>
          <div class="form-group">
            <label for="">Parent Product</label>
            <mat-select
              [class.error]="submitted && !itemModel.parentProductId"
              [disabled]="parentProductLoading || !itemModel.editPermission"
              [class.loading-div]="parentProductLoading"
              [(ngModel)]="itemModel.parentProductId"
            >
              <mat-option
                *ngFor="let parent of parentProductList"
                [value]="parent.parentProductId"
              >
                {{ parent.parentProductName }}</mat-option
              >
            </mat-select>
          </div>
          <div class="form-group">
            <label for="">Product Category</label>
            <input
              [class.error]="submitted && !itemModel.productCategory"
              type="text"
              [readOnly]="!itemModel.editPermission"
              [(ngModel)]="itemModel.productCategory"
            />
          </div>
          <div class="form-group">
            <label for="">Product Status</label>
            <input
              [class.error]="submitted && !itemModel.productStatus"
              type="text"
              [readOnly]="!itemModel.editPermission"
              [(ngModel)]="itemModel.productStatus"
            />
          </div>
          <div class="form-group">
            <label for="">Acc Type</label>
            <mat-select
            [class.error]="submitted && !itemModel.accType"
              [disabled]="!itemModel.editPermission"
              [(ngModel)]="itemModel.accType"
            >
              <mat-option *ngFor="let value of accTypeList" [value]="value">
                {{ value }}</mat-option
              >
            </mat-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="">MSRP</label>
            <input
              [class.error]="submitted && !itemModel.msrp"
              type="number"
              [(ngModel)]="itemModel.msrp"
              [readOnly]="!itemModel.editPermission"
            />
          </div>
          <div class="form-group">
            <label for="">Cost</label>
            <input
              [class.error]="submitted && !itemModel.cost"
              type="number"
              [(ngModel)]="itemModel.cost"
              [readOnly]="!itemModel.editPermission"
            />
          </div>
          <div class="form-group">
            <label for="">Color</label>
            <input
              [class.error]="submitted && !itemModel.color"
              type="text"
              [(ngModel)]="itemModel.color"
              [readOnly]="!itemModel.editPermission"
            />
          </div>
          <div class="form-group">
            <label for="">Capacity</label>
            <input
              [class.error]="submitted && !itemModel.capacity"
              type="number"
              [(ngModel)]="itemModel.capacity"
              [readOnly]="!itemModel.editPermission"
            />
          </div>
          <div class="form-group">
            <label for="">Screen Size</label>
            <input
              [class.error]="submitted && !itemModel.screenSize"
              type="number"
              [(ngModel)]="itemModel.screenSize"
              [readOnly]="!itemModel.editPermission"
            />
          </div>
          <div class="form-group">
            <label for="StreetDate">Street Date</label>
            <mat-form-field
              [class.error]="submitted && !itemModel.streetDate"
              floatLabel="never"
            >
              <input
                [disabled]="!itemModel.editPermission"
                [(ngModel)]="itemModel.streetDate"
                (focus)="picker1.open()"
                and
                (click)="picker1.open()"
                matInput
                [matDatepicker]="picker1"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- <div class="form-group">
            <label for="">Street Date</label>
            <input [class.error]="submitted && !itemModel.streetDate" type="number" [(ngModel)]="itemModel.streetDate" [readOnly]="!itemModel.editPermission">
          </div> -->
          <div class="form-group">
            <label for="">Active</label>
            <mat-slide-toggle></mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #DocumentImport let-data>
  <div mat-dialog-content>
    <div>
      <ngx-dropzone (change)="onSelect($event)" [multiple]="false">
        <ngx-dropzone-label>Drop Image here!</ngx-dropzone-label>
        <ngx-dropzone-preview
          *ngFor="let f of files"
          [removable]="true"
          (removed)="onRemove(f)"
        >
          <ngx-dropzone-label style="outline: none"
            >{{ f.name }} ({{ f.type }})</ngx-dropzone-label
          >
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>
    <div class="action-panel text-right">
      <button
        mat-raised-button
        class="btn btn-secondary m-left"
        (click)="dialogRef.close()"
      >
        Cancel
      </button>
    </div>
  </div>
</ng-template>
