import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ViewRendererComponent } from 'src/common/view-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-product-copy',
  templateUrl: './product-copy.component.html',
  styleUrls: ['./product-copy.component.css']
})
export class ProductCopyComponent implements OnInit {

  public classList = [{ classCode: 1, classCodeDescription: 'TV' }, { classCode: 2, classCodeDescription: 'Theatrical' }];
  public subClassList = [
    { subClassCode: 1, subClassCodeDescription: 'DVD' },
    { subClassCode: 2, subClassCodeDescription: 'BD' },
    { subClassCode: 3, subClassCodeDescription: '3D' },
    { subClassCode: 4, subClassCodeDescription: 'UHD' }
  ];
  public columnDefs = [
    {
      headerName: 'Brand',
      field: 'brand',
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openDialog.bind(this),
      },
      pinned: 'left',
      width: 150
    },
    {
      headerName: 'Model Number',
      field: 'modelNumber',
      width: 150
    },
    {
      headerName: 'Product Name',
      field: 'productName',
      width: 150
    },
    {
      headerName: 'Description',
      field: 'description',
      width: 150
    },
    {
      headerName: 'Feature  Bullets',
      field: 'featurBullets',
      width: 150
    },
    {
      headerName: 'Short Description',
      field: 'shortDescription',
      width: 150
    },
    {
      headerName: 'Meta Keywords',
      field: 'metaKeywords',
      width: 150
    },
    {
      headerName: 'Meta Description',
      field: 'metaDescription',
      width: 150
    },
    // {
    //   headerName: 'Product Copy',
    //   field: '',
    //   resizable: true,
    //   filter: false,
    //   cellRenderer: 'viewRenderer',
    //   cellRendererParams: {
    //     onClick: this.openViewRenderer.bind(this),
    //   },
    //   width: 150
    // }
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public ItemMasterList = [];
  public modules = AllCommunityModules;
  public user: any;
  @ViewChild('dialogRef') dialogRef: TemplateRef<any>;
  constructor(public router: Router,
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<any>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent,
        nameRenderer: NameRendererComponent,
        viewRenderer: ViewRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    this.ItemMasterList = [
      {
        brand: 'Brand1',
        modelNumber: 'Model1',
        productName: 'Product1',
        description: 'Description1',
        featurBullets: 'feature1',
        shortDescription: 'Short Description',
        metaKeywords: 'MetaKey 1',
        metaDescription: 'MetaDesc 1'
      },
      {
        brand: 'Brand2',
        modelNumber: 'Model2',
        productName: 'Product2',
        description: 'Description2',
        featurBullets: 'feature2',
        shortDescription: 'Short Description',
        metaKeywords: 'MetaKey 2',
        metaDescription: 'MetaDesc 2'
      },
      {
        brand: 'Brand3',
        modelNumber: 'Model3',
        productName: 'Product3',
        description: 'Description3',
        featurBullets: 'feature3',
        shortDescription: 'Short Description',
        metaKeywords: 'MetaKey 3',
        metaDescription: 'MetaDesc 3'
      },
    ]
    // this.getItemMasterList();
    // this.configurationService.itemMasterListSubject$.subscribe(res => {
    //   if (res) {
    //     this.getItemMasterList();
    //   }
    // });
  }
  public getItemMasterList = () => {
    this.configurationService.ItemMasterGetAll({}).subscribe(res => {
      this.ItemMasterList = res;
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.sku }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeItemMaster(row.rowData);
      }
    });
  }
  openViewRenderer(row: any): void {
    // const selector = {
    //   id: '451',
    //   name: 'Item Plan',
    //   selector: 'app-item-plan',
    //   displayName: 'Item Plan',
    //   data: {}
    // };
    // this.configurationService.menuRendererSubject.next(selector);
  }
  public removeItemMaster(selectedItem: any) {
    if (selectedItem) {
      const model = {
        ...selectedItem,
        active: false
      };
      this.spinner.show();
      this.configurationService.ItemMasterActivate(model).subscribe(res => {
        if (res) {
          this.spinner.hide();
          this.toastr.success('Master Items', 'Item removed successfully');
          this.ItemMasterList = this.ItemMasterList.filter(row => row.itemMasterId !== model.itemMasterId);
          this.gridOptions.api.setRowData(this.ItemMasterList);
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('Master Items', error.error.text);
      });
    }
  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  // onCellClicked($event: CellClickedEvent) {
  //   if ($event && $event.colDef.field === 'itemDesciption') {
  //     this.openModal($event.data);
  //   }
  // }
  public onSave = (data) => {
    this.ItemMasterList.push(data);
    this.gridOptions.api.setRowData(this.ItemMasterList);
  }
  openDialog(data): void {
    const renderData = data && data.rowData ? data.rowData : {};
    this.matDialogRef = this.dialog.open(this.dialogRef, { width: '800px', data: renderData });
  }
  public openModal(data?: any) {
    const selector = {
      id: '461',
      name: 'PDM Creation',
      selector: 'app-pdm-form',
      displayName: 'PDM Creation',
      data: data
    };
    this.configurationService.menuRendererSubject.next(selector);
  }
}
