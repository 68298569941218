import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Component, OnInit } from '@angular/core';
import { AgGridLineChartCellRenderer } from '../../ag-grid/ag-grid-line-chart-cell-renderer.component';
import { generateGridColumns, paginationPageSize, rowSelection } from '../../ag-grid/gridOptions';
import { itemViewCompleteData, replenishmentManagerData } from '../../MockData';

@Component({
  selector: 'app-replenishment-manager',
  templateUrl: './replenishment-manager.component.html',
  styleUrls: ['./replenishment-manager.component.scss']
})
export class ReplenishmentManagerComponent implements OnInit {


  // grid variables
  public gridOptions: any;
  paginationPageSize: number;
  rowModelType: string;
  gridData: any[];
  columnDefs: any[];
  rowSelection: string;
  modules = AllModules;

  // graph variables
  dataSource = {
    chart: {
      exportenabled: "0",
      theme: "fusion"
    },
    categories: [
      {
        category: [
          {
            label: "Jan"
          },
          {
            label: "Feb"
          },
          {
            label: "Mar"
          },
          {
            label: "Apr"
          },
          {
            label: "May"
          },
          {
            label: "Jun"
          },
          {
            label: "Jul"
          },
          {
            label: "Aug"
          },
          {
            label: "Sep"
          },
          {
            label: "Oct"
          },
          {
            label: "Nov"
          },
          {
            label: "Dec"
          }
        ]
      }
    ],
    dataset: [
      {
        seriesname: "Sales",
        renderas: "line",
        data: []
      },
      {
        seriesname: "Stock",
        renderas: "line",
        data: []
      },
      {
        seriesname: "Replenishment",
        renderas: "line",
        data: []
      },
      {
        seriesname: "Sales St Dev",
        renderas: "line",
        data: []
      },
      {
        seriesname: "Forecast",
        renderas: "line",
        data: []
      }
    ]
  };
  width = 1100;
  height = 600;
  type = "mscombi2d";
  dataFormat = "json";

  orderingDetailsItems = [
    'On Order',
    'Receiving This Week',
    'Total In-Transit Stock',
    'Weeks of Supply',
    'Supplier',
    'MOQ',
    'Avg Lead Time',
    'Lead Time St dev',
    'One Time Order Cost',
    'Inventory Retention Cost',
    'Target Fill Rate',
    'Store Lead Time'
  ];

  locationDetailsItems = [
    'Region', 'Country', 'State', 'City', 'Channel', 'Cluster'
  ];

  productDetailsItems = [
    'Brand',
    'Product Group',
    'Product Family',
    'Item Description',
    'Cost',
    'Gross Margin %',
    'Sales St Dev',
    'Inventory at Store',
    'Inventory At Warehouse',
    'Total Inventory'
  ];

  locationDetails = false;
  orderingDetails = false;
  productDetails = false;

  constructor() { }

  ngOnInit(): void {
    this.rowModelType = 'clientSide';
    this.paginationPageSize = paginationPageSize;
    this.gridOptions = {
      frameworkComponents: {
        agGridLineChartCellRenderer: AgGridLineChartCellRenderer
      },
      pagination: true,
      paginationPageSize: this.paginationPageSize,
    };
    this.rowSelection = rowSelection;
    this.gridData = itemViewCompleteData;
    this.toggleChanged();
    this.populateGraphView();
  }

  populateGraphView() {
    this.dataSource.categories[0].category.forEach((e, i) => {
      for (let j=0; j<5; j++) {
        const randomNumber = Math.random() *10;
        this.dataSource.dataset[j].data[i] = { value: randomNumber.toString()};
      }
    });
  }

  getUpdatedKeys(keys) {
    let newKeys = [];
    keys.forEach(k => {
      let isPresent = true;
      if (!this.locationDetails && this.locationDetailsItems.indexOf(k) > -1) isPresent = false;
      if (!this.orderingDetails && this.orderingDetailsItems.indexOf(k) > -1) isPresent = false;
      if (!this.productDetails && this.productDetailsItems.indexOf(k) > -1) isPresent = false;
      isPresent && newKeys.push(k);
    });
    return newKeys;
  }

  toggleChanged() {
    let keys = Object.keys(this.gridData[0]);
    keys = this.getUpdatedKeys(keys);
    this.columnDefs = generateGridColumns(keys);
  }

}
