<div class="container-fluid">
  <div class="wrapper col-md-12">
    <mat-card-header>
      <div class="col-md-4 form-group">
        <mat-form-field>
          <mat-select placeholder="Select Category" [(ngModel)]="selectedCategory"
            [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let res of categoryList; let i = index" [value]="res.id">
              {{res.categoryName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <button class="close" mat-button (click)="onNoClick()">X</button> -->
      <mat-card-title>
      </mat-card-title>
    </mat-card-header>
    <mat-card-actions>
      <div class="action-btns text-right">
        <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit()">Save</button>
      </div>
    </mat-card-actions>
    <mat-card-content>
      <form *ngIf="selectedCategory" [formGroup]="pdmForm" (ngSubmit)="onSubmit()" class="form-fields">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="disable_ripple" collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title> Item Description
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="sku" placeholder="Enter SKU">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="department" placeholder="Enter Department">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="class" placeholder="Enter Class">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="subclass" placeholder="Enter Subclass">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="productDescription" placeholder="Enter Product Description">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="posDescription" placeholder="Enter POS Description">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="signDescription" placeholder="Enter Sign Description">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="upc" placeholder="Enter UPC">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="upcType" placeholder="Enter UPC Type">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="preSKUInd" placeholder="Enter Pre SKU Ind">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="modelNumber" placeholder="Enter Model Number">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="brandName" placeholder="Enter Brand Name">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="supplierGLN" placeholder="Enter Supplier GLN">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="supplierGLNName" placeholder="Enter Supplier GLN Name">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="category" placeholder="Enter Category">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Rating" formControlName="rating">
                  <mat-option *ngFor="let res of ratingList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Enter Label or Studio Supplier" formControlName="labelStudioSupplier">
                  <mat-option *ngFor="let res of LabelStudioSupplierList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="productLine" placeholder="Enter Product Line">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput type="number" min=0 max="9999" maxlength="4" formControlName="numberofDiscs"
                  type="number" placeholder="Enter Number of Discs">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Media Format" formControlName="mediaFormat">
                  <mat-option *ngFor="let res of MediaFormatList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Genre" formControlName="genre">
                  <mat-option *ngFor="let res of GenreList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field class="example-full-width">
                <input (focus)="picker.open()" and (click)="picker.open()" formControlName="streetDate" matInput
                  [matDatepicker]="picker" placeholder="Select Street Date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="submitted && pdmForm.controls['streetDate'].hasError('required')">
                  *Required Field
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Planogram Display Type" formControlName="planogramDisplayType">
                  <mat-option *ngFor="let res of PlanogramDisplayTypeList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="privateLabelIndicator" placeholder="Enter Private Label Indicator">
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="disable_ripple" collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title>Item Pricing
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="unitCost" placeholder="Enter Unit Cost">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field class="example-full-width">
                <input (focus)="picker3.open()" and (click)="picker3.open()" formControlName="unitCostStartDate"
                  matInput [matDatepicker]="picker3" placeholder="Select Unit Cost Start Date">
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
                <mat-error *ngIf="submitted && pdmForm.controls['unitCostStartDate'].hasError('required')">
                  *Required Field
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="looseCost" placeholder="Enter Loose Cost">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="suggestedRetail" placeholder="Enter Suggested Retail">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="suggestedRetailCurrency" placeholder="Enter Suggested Retail Currency">
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="disable_ripple" collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title>Item Configuration
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="inBoxDimensionUOM" placeholder="Enter In Box Dimension UOM">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="inBoxHeight" placeholder="Enter In Box Height">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="inBoxLengthorDepth" placeholder="Enter In Box Length or Depth">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="inBoxWidth" placeholder="Enter In Box Width">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="inBoxWeight" type="number" placeholder="Enter In Box Weight">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="inBoxWeightUOM" placeholder="Enter In Box Weight UOM">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="outofBoxDimensionUOM" placeholder="Enter Out of Box Dimension UOM">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="outofBoxHeight" placeholder="Enter Out of Box Height">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="outofBoxLengthorDepth" placeholder="Enter Out of Box Length or Depth">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="outofBoxWidth" placeholder="Enter Out of Box Width">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="outofBoxWeight" type="number" placeholder="Enter Out of Box Weight">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="outofBoxWeightUOM" placeholder="Enter Out of Box Weight UOM">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Package Type" formControlName="packageType">
                  <mat-option *ngFor="let res of PackageTypeList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="packageQty" placeholder="Enter Package Qty">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field class="example-full-width">
                <input (focus)="picker1.open()" and (click)="picker1.open()" formControlName="dimensionPackageStartDate"
                  matInput [matDatepicker]="picker1" placeholder="Select Dimension Package Start Date">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error *ngIf="submitted && pdmForm.controls['dimensionPackageStartDate'].hasError('required')">
                  *Required Field
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="packageGTIN" placeholder="Enter Package GTIN">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput type="number" formControlName="caseDimensionUOM" placeholder="Enter Case Dimension UOM">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="caseHeight" placeholder="Enter Case Height">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="caseLengthorDepth" placeholder="Enter Case Length or Depth">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="caseWidth" placeholder="Enter Case Width">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="caseWeigth" placeholder="Enter Case Weight">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="caseWeightUOM" type="number" placeholder="Enter Case Weight UOM">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="innerDimensionUOM" type="number"
                  placeholder="Enter Inner Dimension UOM">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="innerHeight" placeholder="Enter Inner Height">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="innerLengthorDepth" placeholder="Enter Inner Length or Depth">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="innerWidth" placeholder="Enter Inner Width">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="innerWeight" placeholder="Enter Inner Weight">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="innerWeightUOM" type="number" placeholder="Enter Inner Weight UOM">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="palletDimensionUOM" type="number"
                  placeholder="Enter Pallet Dimension UOM">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="palletHeight" placeholder="Enter Pallet Height">
              </mat-form-field>
            </div>
            <div class=" col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="palletLengthorDepth" placeholder="Enter Pallet Length or Depth">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="palletWidth" placeholder="Enter Pallet Width">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="palletWeight" placeholder="Enter Pallet Weight">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="palletWeightUOM" type="number" placeholder="Enter Pallet Weight UOM">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="ti" placeholder="Enter Ti">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="high" placeholder="Enter High">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="isNested" placeholder="Enter isNested">
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="disable_ripple" collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title>Item Manufacturer Details
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="bbyDirectImportIndicator"
                  placeholder="Enter BBY Direct Import Indicator">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Fee Type" formControlName="feeType">
                  <mat-option *ngFor="let res of FeeTypeList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field class="example-full-width">
                <input (focus)="picker2.open()" and (click)="picker2.open()" formControlName="supplierBeginOrderDate"
                  matInput [matDatepicker]="picker2" placeholder="Select Supplier Begin Order Date">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-error *ngIf="submitted && pdmForm.controls['supplierBeginOrderDate'].hasError('required')">
                  *Required Field
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="manufacturerGLNName" placeholder="Enter Manufacturer GLN Name">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="manufacturerGLNNumber" placeholder="Enter Manufacturer GLN Number">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Country of Export" formControlName="countryofExport">
                  <mat-option *ngFor="let res of CountryList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Country of Origin" formControlName="countryofOrigin">
                  <mat-option *ngFor="let res of CountryList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Country of Assembly" formControlName="countryofAssembly">
                  <mat-option *ngFor="let res of CountryList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="disable_ripple" collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title>Item Regulations
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select CA Prop Warning Type" formControlName="caPropWarningType">
                  <mat-option *ngFor="let res of CAPropWarningTypeList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="customCAPropWarningMessage"
                  placeholder="Enter Custom CA Prop Warning Message">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="transportationOrStorageRegulations"
                  placeholder="Enter Transportation Or Storage Regulations">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select UN Number And Proper ShippingName"
                  formControlName="unNumberAndProperShippingName">
                  <mat-option *ngFor="let res of shippingList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="amountOfRegulatedMaterial"
                  placeholder="Enter Amount Of Regulated Material">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="cpscRegulations" placeholder="Enter CPSC Regulations">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="cpscDefinedToy" placeholder="Enter CPSC CPSC Defined Toy">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="cpscDefinedChildProduct"
                  placeholder="Enter CPSC Defined Child Product">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="cpscChildPackaging" placeholder="Enter CPSC Child Packaging">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Aspect Ratio Description" formControlName="aspectRatioDescription">
                  <mat-option *ngFor="let res of AspectRatioDescriptionList; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <mat-select placeholder="Select Number Of Batteries Per Unit"
                  formControlName="numberOfBatteriesPerUnit">
                  <mat-option *ngFor="let res of NumberOfBatteriesPerUnit; let i = index" [value]="res">
                    {{res}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="warrantyIndicator" placeholder="Enter Warranty Indicator">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="warrantyLabor" placeholder="Enter Warranty Labor">
              </mat-form-field>
            </div>
            <div class="col-md-3 form-group">
              <mat-form-field>
                <input matInput formControlName="warrantyParts" placeholder="Enter Warranty Parts">
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </form>
      <br>
    </mat-card-content>
  </div>
</div>
