<div class="row m-0 mt-2">
    <div class="col-md-8">
        <h5 style="background: #f4f4f4; padding: 10px;">SKU Summary</h5>
        <div class="row m-0">
            <div class="col-md-4 d-flex">
                <strong style="width: 50%;" class="mr-2">SKU</strong>
                <mat-form-field appearance="fill">
                    <input matInput disabled value="5566999" />
                </mat-form-field>
            </div>
            <div class="col-md-4 d-flex">
                <strong style="width: 50%;" class="mr-2">Active Spread</strong>
                <mat-form-field appearance="fill">
                    <input matInput disabled value="60" />
                </mat-form-field>
            </div>
            <div class="col-md-4 d-flex">
                <strong style="width: 50%;" class="mr-2">Total Stock</strong>
                <mat-form-field appearance="fill">
                    <input matInput disabled value="4455" />
                </mat-form-field>
            </div>
            <div class="col-md-4 d-flex">
                <strong style="width: 50%;" class="mr-2">Stock at WH</strong>
                <mat-form-field appearance="fill">
                    <input matInput disabled value="1336" />
                </mat-form-field>
            </div>
            <div class="col-md-4 d-flex">
                <strong style="width: 50%;" class="mr-2">Stock at Stores</strong>
                <mat-form-field appearance="fill">
                    <input matInput disabled value="3119" />
                </mat-form-field>
            </div>
            <div class="col-md-4 d-flex">
                <strong style="width: 50%;" class="mr-2">On Order</strong>
                <mat-form-field appearance="fill">
                    <input matInput disabled value="1200" />
                </mat-form-field>
            </div>
            <div class="col-md-4 d-flex">
                <strong style="width: 50%;" class="mr-2">Sales This Season</strong>
                <mat-form-field appearance="fill">
                    <input matInput disabled value="2685" />
                </mat-form-field>
            </div>
            <div class="col-md-4 d-flex">
                <strong style="width: 50%;" class="mr-2">Sales Last Season</strong>
                <mat-form-field appearance="fill">
                    <input matInput disabled value="4239" />
                </mat-form-field>
            </div>
            <div class="col-md-4 d-flex">
                <strong style="width: 50%;" class="mr-2">ROS</strong>
                <mat-form-field appearance="fill">
                    <input matInput disabled value="0.28" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="col-md-4 d-flex align-items-center justify-content-center">
        <fusioncharts [height]="height" [type]="type" [dataFormat]="dataFormat"
            [dataSource]="dataSource">
        </fusioncharts>
    </div>
</div>

<hr class="theme-hr" />

<div class="d-flex align-items-center justify-content-between" style="background: #f4f4f4; padding: 10px;">
    <div class="d-flex align-items-center">
        <h5 style="min-width: 100px;">SKU View
            <span matBadge="40" matBadgeOverlap="false" matBadgeColor="accent"></span>
        </h5>

        <mat-form-field appearance="outline" class="w-25 ml-3">
            <mat-label>SKU</mat-label>
            <mat-select value="6655999">
                <mat-option value="6655999">
                    6655999
                </mat-option>
                <mat-option value="8855449">
                    8855449
                </mat-option>
                <mat-option value="8277994">
                    8277994
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="d-flex align-items-center">
        <button matTooltip="Show/Hide Columns" class="grid-bar-icon ml-3" mat-icon-button color="primary">
            <i class="material-icons">
            ballot
            </i>
        </button>
        <button matTooltip="Export" class="grid-bar-icon ml-3" mat-icon-button color="primary">
            <i class="material-icons">
            insert_drive_file
            </i>
        </button>
        
        <button class="import-export-button ml-3" mat-flat-button color="accent"
            [matMenuTriggerFor]="actionMenu">Actions</button>
        <mat-menu #actionMenu="matMenu">
            <button mat-menu-item>Export to Excel File</button>
            <button mat-menu-item>Export to Text File</button>
            <button mat-menu-item>Export to CSV File</button>
        </mat-menu>
    </div>
</div>
<div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material" [gridOptions]="gridOptions" [rowModelType]="rowModelType"
        [pagination]="true" [paginationPageSize]="paginationPageSize" [columnDefs]="columnDefs" [modules]="modules"
        [suppressMenuHide]="true" suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
        [rowSelection]="rowSelection" [rowData]="gridData" #skuGrid>
    </ag-grid-angular>
</div>
