<div [hidden]="!gridMapped">
  <mat-accordion class="fb-forecast-accordion order-panel">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title> Adjust Order Quantity</mat-panel-title>
      </mat-expansion-panel-header>
      <!-- <div class="mar">
        <button type="submit" class="btn btn-primary d-block" (click)="applyUDP()">Apply</button>
      </div> -->
      <!-- <div class="row">
        <div class="col-md-7"></div> -->
      <div class="data-wrapper btn-wrappers">
        <button type="submit" class="btn btn-primary" (click)="applyUDP()">Apply</button>
      </div>
      <!-- </div> -->

      <div class="container-fluid item-wrapper fb-forecast-planner" [style.height]="isAnyOpened() ? '70vh' : ''">
        <div class="row method-planner-bottom w-100" *ngFor="let adjust of adjustOrderQuantity">
          <div class="col-md-2 fb-line">
            <mat-label><b>{{adjust.absoluteAdjustment?.label}}</b></mat-label>
            <form class="marg">
              <div *ngFor="let absoluteAdjustment of adjust.absoluteAdjustment?.absoluteAdjustmentValue">
                <div class="form-group input-black">
                  <label>{{absoluteAdjustment.label}}</label>
                  <input type="number" style=" background-color: #e9ecef !important;" class="form-control"
                    placeholder="" autocomplete="off">
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-2 fb-line ">
            <mat-label><b>{{adjust.absoluteMinMax?.label}}</b></mat-label>
            <form class="marg">
              <div *ngFor="let absoluteMinMax of adjust.absoluteMinMax?.absoluteMinMaxValue">
                <div class="form-group input-black">
                  <label>{{absoluteMinMax.label}}</label>
                  <input type="number" style=" background-color: #e9ecef !important;" class="form-control"
                    placeholder="" autocomplete="off">
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-2 fb-line ">
            <mat-label><b>{{adjust.minMax?.label}}</b></mat-label>
            <form class="marg">
              <div *ngFor="let minMax of adjust.minMax?.minMaxValue">
                <div class="form-group input-black">
                  <label>{{minMax.label}}</label>
                  <input type="number" style=" background-color: #e9ecef !important;" class="form-control"
                    placeholder="" autocomplete="off">
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-2 fb-line ">
            <mat-label><b>{{adjust.absoluteDos?.label}}</b></mat-label>
            <form class="marg">
              <div *ngFor="let absoluteDos of adjust.absoluteDos?.absoluteDosValue">
                <div class="form-group input-black">
                  <label>{{absoluteDos.label}}</label>
                  <input type="number" style=" background-color: #e9ecef !important;" class="form-control"
                    placeholder="" autocomplete="off">
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-2 fb-line ">
            <mat-label><b>{{adjust.forecastAdjustment?.label}}</b></mat-label>
            <form class="marg">
              <div *ngFor="let forecastAdjustment of adjust.forecastAdjustment?.forecastAdjustmentValue">
                <div class="form-group input-black">
                  <label>{{forecastAdjustment.label}}</label>
                  <input type="number" style=" background-color: #e9ecef !important;" class="form-control"
                    placeholder="" autocomplete="off">
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-2 ">
            <mat-label><b>{{adjust.atp?.label}}</b></mat-label>
            <form>
              <div *ngFor="let atp of adjust.atp?.atpValue" class="marg">
                <div class="m-t">
                  <span> {{atp.label}}</span>
                </div>
                <div class="m-t-1">
                  <mat-slide-toggle>Yes</mat-slide-toggle>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ng-template #saveForecast>
        <h1 mat-dialog-title>What would you like to name your planner?</h1>
        <div mat-dialog-content>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Enter Planner Name" [(ngModel)]="plannerName"
              [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
        <div class="action-panel text-right">
          <button mat-raised-button class="btn btn-outline m-left" color="primargy"
            (click)="ref.close()">Cancel</button>
          <button mat-raised-button class="btn btn-primargy m-left" color="warn" (click)="ref.close(true)">Save</button>
        </div>

      </ng-template>
      <ng-template #commitForecast>
        <h1 mat-dialog-title>Congratulations! you have successfully committed</h1>
        <div mat-dialog-content>
        </div>
        <div class="action-panel text-right">
          <button mat-raised-button class="btn btn-outline m-left" color="primargy" (click)="ref.close()">Go
            Back</button>
        </div>

      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
