import { dateRenderer } from "src/ag-grid/renderers/common-renderers/common-renderers";
import * as moment from 'moment';
import * as _ from 'lodash';
import { tooltipcellRenderer } from "src/common/util/util";

export const defaultColDef = {
  filter: true,
  sortable: true,
  minWidth: 50,
  resizable: true,
  filterParams: {
    excelMode: 'windows',
  },
  menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
};

export const getSeasonalityColDefs = (filterLookupModel: any = {}, componentSelf) => {
  const sharedDataService = componentSelf._dataService;
  const colDefs: any = [
    {
      headerName: '',
      field: 'select',
      width: 70,
      pinned: 'left',
      suppressSizeToFit: true,
      checkboxSelection: true,
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: 'Seasonality Key',
      field: 'seasonalityKey',
      width: 110,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Seasonality Name',
      field: 'seasonalityName',
      width: 155,
      cellRenderer: 'seasonalityEditRenderer',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Selected Weeks',
      field: 'selectedWeeks',
      width: 150,
      cellClassRules: {
        'trend-chart-cell': () => true,
      },
      cellRenderer: 'seasonalityLineChartCellRenderer',
      suppressColumnsToolPanel: true,
      filter: false,
      sortable: false
    },
    {
      headerName: 'Status',
      field: 'active',
      width: 85,
      cellRenderer: statusRenderer,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['True', 'False'],
        cellRenderer: activeInActiveRenderer
      }
    },
    {
      headerName: 'Creation Type',
      field: 'creationType',
      width: 130,
      cellRenderer: capitalizeRenderer,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['Aggregate', 'Standalone']
      }
    },
    {
      headerName: 'Seasonality Type',
      field: 'seasonalityType',
      width: 110,
      cellRenderer: capitalizeRenderer,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['Static', 'Rolling']
      }
    },
    {
      headerName: 'Seasonality Level',
      field: 'seasonalityLevelName',
      width: 150,
      cellRenderer: whiteLabelSet.bind(componentSelf),
      cellRendererParams: { componentSelf: componentSelf },
      filter: 'agSetColumnFilter',
      filterParams: {
        // values: ['Account', 'Brand', 'Product Group','SKU Description', 'Brand & Account', 'Product Group & Account', 'Account & Description', 'Account & SKU']

        values: [sharedDataService.getDataById(1), sharedDataService.getDataById(2), sharedDataService.getDataById(3), sharedDataService.getDataById(5), sharedDataService.getDataById(2) + ' & ' + sharedDataService.getDataById(1), sharedDataService.getDataById(3) + ' & ' + sharedDataService.getDataById(1), sharedDataService.getDataById(1) + ' & ' + sharedDataService.getDataById(5), sharedDataService.getDataById(1) + ' & ' + sharedDataService.getDataById(4)]
      }
    },
    {
      headerName: 'Start Week',
      field: 'startWeekDate',
      width: 115,
      type: 'date',
      filter: 'agDateColumnFilter',
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'End Week',
      field: 'endWeekDate',
      width: 115,
      type: 'date',
      filter: 'agDateColumnFilter',
      cellRenderer: dateRenderer,
    },
    {
      headerName: sharedDataService.getDataById(1),
      field: 'retailer',
      width: 110,
      cellRenderer: tooltipcellRenderer,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Item(s)',
      field: 'seasonalityItems',
      width: 100,
      cellRenderer: tooltipcellRenderer,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Usage',
      field: 'usageCount',
      width: 95,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    }

  ];

  return colDefs;
}


export const getSeasonalityWeeksColDefs = (weeksList: any = [], filterLookupModel: any = {}) => {
  const colDefs: any = [
    {
      headerName: 'Values',
      field: 'values',
      width: 100
    }
  ];
  weeksList.forEach(week => {
    colDefs.push({
      headerName: `W-${week.weekNumber.toString().slice(-2)}`,
      children: [{
        headerName: `${moment(week.weekStartDate).format('MM/DD/YYYY')}`,
        headerClass: 'week-label-header',
        field: `${week.field}`,
        width: 100,
        cellEditor: 'numericCellEditor',
        editable: (params) => {
          if (params.data.values === 'Adjusted') {
            return true;
          } else {
            return false;
          }
        },
      }]
    });
  });

  return colDefs;
}

export const getPendingSeasonalityColDefs = (filterLookupModel: any = {}, componentSelf) => {
  const sharedDataService = componentSelf._dataService;
  const colDefs: any = [
    {
      headerName: '',
      field: 'select',
      width: 70,
      pinned: 'left',
      suppressSizeToFit: true,
      checkboxSelection: true,
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: sharedDataService.getDataById(1),
      field: 'retailer',
      width: 140,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: sharedDataService.getDataById(2),
      field: 'brand',
      width: 135,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: sharedDataService.getDataById(3),
      field: 'productGroup',
      width: 170,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: sharedDataService.getDataById(5),
      field: 'productDescription',
      width: 195,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: sharedDataService.getDataById(4),
      field: 'sku',
      width: 170,
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    }

  ];

  return colDefs;
}


const capitalizeRenderer = (params) => {
  return `${_.capitalize(params.value)}`;
}
const whiteLabelSet = (params) => {
  const sharedDataService = params.componentSelf._dataService;
  if (params.value === 'Account') {
    return sharedDataService.getDataById(1);
  } else if (params.value === 'Brand') {
    return sharedDataService.getDataById(2);
  } else if (params.value === 'Product Group') {
    return sharedDataService.getDataById(3);
  } else if (params.value === 'SKU Number') {
    return sharedDataService.getDataById(4);
  } else if (params.value === 'SKU Description') {
    return sharedDataService.getDataById(5);
  } else if (params.value === 'Account & SKU') {
    return `${sharedDataService.getDataById(1)} & ${sharedDataService.getDataById(4)}`;
  } else if (params.value === 'Account & Brand') {
    return `${sharedDataService.getDataById(1)} & ${sharedDataService.getDataById(2)}`;
  } else if (params.value === 'Account & Product Group') {
    return `${sharedDataService.getDataById(1)} & ${sharedDataService.getDataById(3)}`;
  }
}

const statusRenderer = (params) => {
  return `${params.value ? 'Active' : params.data.isAnomalous ? '<span style="color:red">In-Active</span>' : 'In-Active'}`;
}

const activeInActiveRenderer = (params) => {
  return `${params.value === 'True' ? 'Active' : params.value === 'False' ? 'In-Active' : 'All'}`;
}
