<mat-accordion class="fb-forecast-accordion promotion-form" id="fb-forecast">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header class="promotion-header" collapsedHeight="35px" expandedHeight="35px">
      <mat-panel-title> Promotion Details
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container-fluid item-wrapper fb-forecast-planner promotion-wrapper">
      <form class="account-form">
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label for="promotionName"  class="required">Promo Name</label>
              <input (input)="checkIfDuplicated($event.target.value)" [(ngModel)]="promotionModel.name"
                [disabled]="!editPermission" name="promotionName" [ngModelOptions]="{standalone: true}" type="text"
                required />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="promotionStartDate">Promo Start Date</label>
                  <mat-form-field floatLabel='never'>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="promotionModel.startDate"
                      [disabled]="!editPermission" name="promotionStartDate" [ngModelOptions]="{standalone: true}" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="promotionEndDate">Promot End Date</label>
                  <mat-form-field floatLabel='never'>
                    <input matInput [matDatepicker]="picker1" [(ngModel)]="promotionModel.endDate"
                      [disabled]="!editPermission" name="promotionEndDate" />
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="promotionName" >Misc Event Details</label>
              <input [(ngModel)]="promotionModel.miscEventDetails" [disabled]="!editPermission" name="promotionName"
                [ngModelOptions]="{standalone: true}" type="text" />
            </div>
            <div class="form-group">
              <label for="promotionName" >Program Name</label>
              <input [(ngModel)]="promotionModel.programName" [disabled]="!editPermission" name="promotionName"
                [ngModelOptions]="{standalone: true}" type="text" />
            </div>
            <div class="form-group">
              <label for="promotionName" >Promo Comments</label>
              <input [(ngModel)]="promotionModel.promocomments" [disabled]="!editPermission" name="promotionName"
                [ngModelOptions]="{standalone: true}" type="text" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="promotionName" >Program Number</label>
              <input [(ngModel)]="promotionModel.promoNumber" [disabled]="!editPermission" name="promotionName"
                [ngModelOptions]="{standalone: true}" type="text" />
            </div>
            <div class="form-group">
              <label for="promotionName" >Promo Event Type</label>
              <input [(ngModel)]="promotionModel.promoEventType" [disabled]="!editPermission" name="promotionName"
                [ngModelOptions]="{standalone: true}" type="text" />
            </div>
            <div class="form-group">
              <label for="promotionName" >Offer Type</label>
              <input [(ngModel)]="promotionModel.offerType" [disabled]="!editPermission" name="promotionName"
                [ngModelOptions]="{standalone: true}" type="text" />
            </div>
            <div class="form-group">
              <label for="promotionName" >Status</label>
              <mat-select [(ngModel)]="promotionModel.active"
                [disabled]="!editPermission" name="continentId">
                <mat-option *ngFor="let item of status" [value]="item.id">{{ item.value }}
                </mat-option>
              </mat-select>

            </div>
          </div>
          <div class="btn-wrapper">
            <button class="btn btn-primary" [disabled]="!editPermission" (click)="save()">Save</button>
          </div>
        </div>
      </form>
    </div>
    <!-- <div class="container-fluid item-wrapper fb-forecast-planner promotion-wrapper"
      style="border-top: 1px solid #707070;     padding-top: 40px;
      margin-top: 50px;">
      <form class="account-form">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="region">Add Product</label>
              <mat-select [(ngModel)]="promotionModel.productId" (selectionChange)="onItemChange($event.value)"
                [disabled]="!editPermission" name="continentId">
                <mat-option *ngFor="let item of itemList" [value]="item.itemManagerId">{{ item.description }}
                </mat-option>
              </mat-select>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="promotionName" >Discount To Customer</label>
                  <input [(ngModel)]="promotionModel.discountCost" [disabled]="!editPermission" name="promotionName"
                    [ngModelOptions]="{standalone: true}" type="text" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="promotionName" >Currency</label>
                  <mat-select [(ngModel)]="promotionModel.currency"
                  [disabled]="!editPermission" name="continentId">
                  <mat-option *ngFor="let item of currencyList" [value]="item.currencyName">{{ item.currencyName }}
                  </mat-option>
                </mat-select>

                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="region">Add Accounts</label>
              <mat-select [(ngModel)]="promotionModel.accountIds" (selectionChange)="onAccountChange($event.value)"
                [disabled]="!editPermission" [multiple]="true">
                <mat-option *ngFor="let account of accountSettingList" [value]="account.itemAccountId">{{
                  account.accountName }}
                </mat-option>
              </mat-select>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="promotionName" >MSRP</label>
                  <input [(ngModel)]="promotionModel.msrp" [disabled]="!editPermission" name="promotionName"
                    [ngModelOptions]="{standalone: true}" type="text" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="promotionName" >Discount Percent</label>
                  <input [(ngModel)]="promotionModel.discountPercent" [disabled]="!editPermission" name="promotionName"
                    [ngModelOptions]="{standalone: true}" type="text" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 bottom-section">
            <div class="form-group">
              <mat-slide-toggle (click)="onEvent($event)" [(ngModel)]="promotionModel.stackable">Stackable
              </mat-slide-toggle>
            </div>
            <div class="form-group">
              <mat-slide-toggle (click)="onEvent($event)" [(ngModel)]="promotionModel.activeAccount">Active
              </mat-slide-toggle>
            </div>
            <div class="btn-wrapper">
              <button class="btn btn-primary" [disabled]="!editPermission" (click)="addAccount()">Add</button>
            </div>
          </div>
        </div>
      </form>
    </div> -->
  </mat-expansion-panel>
  <ng-template #saveConfirmation>
    <div mat-dialog-content>
      <p>
        Are you sure you want to save and override the information in this
        account?
      </p>
    </div>
    <hr />
    <div class="item-wrapper">
      <div class="action-panel text-right btn-wrapper">
        <button mat-raised-button class="btn btn-secondary m-left" (click)="dialogRef.close()">
          Cancel
        </button>
        <button mat-raised-button class="btn btn-primary m-left" (click)="onSubmit()">
          Save
        </button>
      </div>
    </div>
  </ng-template>
</mat-accordion>

<mat-accordion class="fb-forecast-accordion promotion-accordion">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px" style="border-top: 2px solid transparent;">
      <div class="item-search-wrapper">
        <form action="" class="form-fields show-search">
          <div class="form-group">
            <mat-form-field floatLabel="never">
              <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)" />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
        </form>
        <div class="item-search-btn">
          <img src="../../assets/images/excel.svg" alt="" (click)="exportExcel($event)" />
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="all-show-grid items-wrapper-grid">
      <ag-grid-angular class="ag-theme-material" [gridOptions]="gridOptions" [defaultColDef]="defaultColDef"
        [pagination]="true" [headerHeight]="headerHeight" [rowData]="promotionModel.promotionProductAccount"
        [paginationPageSize]="paginationPageSize" [columnDefs]="columnDefs" (gridReady)="onGridReady($event)"
        [modules]="modules" #grid>
      </ag-grid-angular>
    </div>
  </mat-expansion-panel>
</mat-accordion>
