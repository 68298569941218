import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SkuFormComponent } from '../sku-form/sku-form.component';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  selector: 'app-sku-creation-form',
  templateUrl: './sku-creation-form.component.html',
  styleUrls: ['./sku-creation-form.component.css']
})
export class SkuCreationFormComponent implements OnInit, AfterViewInit {

  public titleForm: FormGroup;
  public submitted = false;
  public customerList = ['Best Buy', 'Walmart', 'Target', 'Dollar General'];
  public genreList = ['Action', 'Family', 'Comedy', 'Drama', 'Sci-Fi'];
  public classList = [{ classCode: 1, classCodeDescription: 'TV' }, { classCode: 2, classCodeDescription: 'Theatrical' }];
  public subClassList = [
    { subClassCode: 1, subClassCodeDescription: 'DVD' },
    { subClassCode: 2, subClassCodeDescription: 'BD' },
    { subClassCode: 3, subClassCodeDescription: '3D' },
    { subClassCode: 4, subClassCodeDescription: 'UHD' }
  ];
  public isEdit = false;
  constructor(
    public fb: FormBuilder,
    public configurationService: ConfigurationService,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialogRef: MatDialogRef<SkuFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.initializeForm();
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    if (this.data.rowData && this.data.rowData.skuId) {
      this.isEdit = true;
      const model = JSON.parse(JSON.stringify(this.data.rowData));
      model.customer = (model.customer || '').split(',');
      this.initializeFormWithValues(model);
    }
  }
  /**
   * initializeFormWithValues
   */
  public initializeFormWithValues(data) {
    Object.keys(this.titleForm.controls).forEach(key => {
      if (this.data[key] !== undefined && this.data[key] !== null) {
        this.titleForm.controls[key].setValue(data[key]);
      }
    });
  }
  public initializeForm = () => {
    this.titleForm = this.fb.group({
      itemDesciption: [],
      skuId: [0],
      titleId: [0],
      titleCode: [],
      titleName: [],
      upc: [],
      itemNumber: [],
      customer: [],
      workingGroup: [],
      vendor: [],
      streetDate: [],
      arp: [],
      unitCost: [],
      grossBoxOffice: [],
      classCodeId: [],
      subClassCodeId: [],
      rating: [],
      numberOfDiscs: [],
      discType: [],
      cinemaNow: [],
      digitalCopy: [],
      exclusive: [],
      gwp: [],
      digitalCode: [],
      ultraViolet: [],
      expirationDate: [],
      documentPath: [],
      genre: [],
      comment: [],
      active: []
    });
  }
  compareFn(o1: any, o2: any) {
    return o1 === o2;
  }
  public onSubmit = () => {
    this.submitted = true;
    if (this.titleForm.valid) {
      const model = { ...this.titleForm.value };
      model.customer = `${model.customer}`;
      this.spinner.show();
      if (this.isEdit) {
        this.configurationService.SKUUpdate(model).subscribe(res => {
          this.toastr.success('Master Item', 'Item updated successfully.');
          this.spinner.hide();
          if (this.dialogRef) {
            this.dialogRef.close();
          }
          this.isEdit = false;
        }, error => {
          this.spinner.hide();
        });
      } else {
        this.configurationService.SKUAdd(model).subscribe(res => {
          this.toastr.success('Master Item', 'Item added successfully.');
          this.titleForm.controls['skuId'].setValue(res);
          this.isEdit = true;
          if (this.dialogRef) {
            this.dialogRef.close();
          }
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      }
    }
  }
  public Cancel_Click = () => {

  }
}
