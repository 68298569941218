import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import * as pbi from 'powerbi-client';
import { MICROSOFT_TOKEN_INFO } from 'src/common/keys';
import { BotService } from 'src/services/bot.service';
import { LocalstorageService } from 'src/services/localstorage.service';

@Component({
  selector: 'app-power-bi-embedded',
  templateUrl: './power-bi-embedded.component.html',
  styleUrls: ['./power-bi-embedded.component.css']
})
export class PowerBiEmbeddedComponent implements OnInit {
  txtEmbedGroupId = '08d061dd-7dea-495f-9767-bc137e72ccaf';
  txtEmbedReportId = 'b62b4b53-3718-407b-aaa2-563a14d6c930';
  txtEmbedDatasetId = '7ecdc624-4675-42c6-94d9-82962207daea';
  public screenHeight: number;
  public tokenInfo;
  @ViewChild('reportContainer', { static: true }) reportContainer: any;
  constructor(public msalService: MsalService,
    public broadcastService: BroadcastService,
    public botService: BotService, public storage: LocalstorageService) { }

  ngOnInit() {
    const params = {
      reportId: this.txtEmbedReportId,
      groupId: this.txtEmbedGroupId
    };
    this.botService.GetMicrosoftLoginToken(params).subscribe((res: any) => {
      this.tokenInfo = res.token;
      var txtAccessToken = "eyJ0eXAiOiJKV1QiLCJub25jZSI6IlVtUFdRZGhMcFZvb1ZNWURFaml1ZFZsc203bWRRc1NBZWZaMHUySUpEWk0iLCJhbGciOiJSUzI1NiIsIng1dCI6Imh1Tjk1SXZQZmVocTM0R3pCRFoxR1hHaXJuTSIsImtpZCI6Imh1Tjk1SXZQZmVocTM0R3pCRFoxR1hHaXJuTSJ9.eyJhdWQiOiIwMDAwMDAwMy0wMDAwLTAwMDAtYzAwMC0wMDAwMDAwMDAwMDAiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9kZDcwYTY0Ni05OTJmLTRmMGUtYTE1ZS1kOWM1NTRlMWY1NzYvIiwiaWF0IjoxNTk2MDMyODI0LCJuYmYiOjE1OTYwMzI4MjQsImV4cCI6MTU5NjAzNjcyNCwiYWNjdCI6MSwiYWNyIjoiMSIsImFpbyI6IkFVUUF1LzhRQUFBQWpCWGplUWFYMTZxMGkwOWRtZXhocGRLYzd5VTlHQ0grcU1HM21TbXgwOE5kLzhQb1lLVUZ1N1RpeTdaNm5tbzFKdVB0c1ZydU9iVm15OFZBL24vTFBRPT0iLCJhbHRzZWNpZCI6IjU6OjEwMDMyMDAwQTg3RjlCMUQiLCJhbXIiOlsicHdkIl0sImFwcF9kaXNwbGF5bmFtZSI6IkFsZ28gUUEiLCJhcHBpZCI6Ijk3MzZkNGY0LTc0NDAtNDk1My04YmU2LThhNGZhZmZhMzhjYiIsImFwcGlkYWNyIjoiMSIsImVtYWlsIjoienViYWlyLmFuanVtQGFsZ28uY29tIiwiaWRwIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvNTRjNzBmNjEtZmNiNC00MTZhLTgyMWQtMmMxZGVmZTQ4NjBmLyIsImlwYWRkciI6IjEyNS4yMDkuNzcuMjI3IiwibmFtZSI6Ilp1YmFpciBBbmp1bSIsIm9pZCI6IjM5MDM3YzRkLTU5YTYtNDY4ZC05OGFlLTYzMGEyMTU0NTQyOCIsInBsYXRmIjoiMTQiLCJwdWlkIjoiMTAwMzIwMDBDRkMzMzgyQiIsInNjcCI6IlVzZXIuUmVhZCBwcm9maWxlIG9wZW5pZCBlbWFpbCIsInN1YiI6IlE0cXh1bE0yZUpwZi1iYTZpMXhDTHB1MldXNTd2czR6U2pBWEpkUkl6UTAiLCJ0ZW5hbnRfcmVnaW9uX3Njb3BlIjoiTkEiLCJ0aWQiOiJkZDcwYTY0Ni05OTJmLTRmMGUtYTE1ZS1kOWM1NTRlMWY1NzYiLCJ1bmlxdWVfbmFtZSI6Inp1YmFpci5hbmp1bUBhbGdvLmNvbSIsInV0aSI6InctZERZOU1aWmtpM3RYdkVKaGROQUEiLCJ2ZXIiOiIxLjAiLCJ3aWRzIjpbIjliODk1ZDkyLTJjZDMtNDRjNy05ZDAyLWE2YWMyZDVlYTVjMyJdLCJ4bXNfc3QiOnsic3ViIjoiOXRwMWZWWFpKWlA1VnM0UVFWMWJnSEJmUC0zNE10ald2U01LbkVGX1U1TSJ9LCJ4bXNfdGNkdCI6MTU4NTE2OTIwN30.NskFEa8-1KXwXL7yVqwu5IXiQhstRfZl1n28Qu0SLXdA2-6PQBYXDUvdbsfNxwoS0BgN75z8v6lWRtvBnjDjRTsVOsgxpI_eqKf-9R3sSfdQ4chN5yhk3RYV3r1YSrhD6eVj7Xf1pc-hQ4S6iyeeSp1I5JXk7YUdSuqK0R_nMqLQuzoaNIUR_NZg2Mzj0Zn9kqYRKlooNFEGBzupbeVNPhqaWt3rRA9yqZBNNc3_E1qajMdfUPY0E_cGWL84hLIa8OexevxAjY-v9Z-fGaKOgWvtARPJAazyQE4SGYWewqR6fhrcCZ50Sqln23WrfqnOcBd6IVNsc8W_p0zA9U6qxg";

      // Read embed URL from textbox
      var txtEmbedUrl = `https://app.powerbi.com/qnaEmbed?groupId=${this.txtEmbedGroupId}`;

      // Read dataset Id from textbox
      // https://app.powerbi.com/qnaEmbed?groupId=be8908da-da25-452e-b220-163f52476cdd
      var txtDatasetId = this.txtEmbedDatasetId;

      // Read question from textbox
      var txtQuestion = '';

      // Read Q&A mode
      var qnaMode = 'Interactive';
      // ResultOnly
      // Get models. models contains enums that can be used.
      var models = pbi.models;

      // Embed configuration used to describe the what and how to embed.
      // This object is used when calling powerbi.embed.
      // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details.
      var config = {
        type: 'qna',
        tokenType: models.TokenType.Embed,
        accessToken: txtAccessToken,
        embedUrl: txtEmbedUrl,
        datasetIds: [txtDatasetId],
        viewMode: models.QnaMode[qnaMode],
        question: txtQuestion
      };

      // Get a reference to the embedded Q&A HTML element
      // Embed the Q&A and display it within the div container.
      let reportContainer = this.reportContainer.nativeElement;
      let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      let report = powerbi.embed(reportContainer, config);
      const qnA = powerbi.get(reportContainer);

      // qnA.setQuestion("This year sales")
      //   .then(function (result) {
      //   })
      //   .catch(function (errors) {
      //   });
      report.off("loaded");
      report.on("loaded", () => {
        console.log("Loaded");
      });
      report.on("error", () => {
        // this.getToken();
      });
    });


    this.tokenInfo = this.storage.get(MICROSOFT_TOKEN_INFO);
    const data = {
      grant_type: 'password',
      username: 'zubair.anjum@algo.com',
      password: '5]<?]mg,S38rDk7',
      client_id: '9736d4f4-7440-4953-8be6-8a4faffa38cb',
      scope: 'user.read openid profile offline_access',
      client_secret: 'WA5Pbm-Ks_--l5.DiaiJYWrK26y2ISql4M'
    };
  }
}
