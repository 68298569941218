import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AddDropComponent } from './dialogs/add-drop/add-drop.component';
import { calendarEvent, seasonInterface } from './interfaces';

@Component({
  selector: 'app-apparel-season',
  templateUrl: './apparel-season.component.html',
  styleUrls: ['./apparel-season.component.scss']
})
export class ApparelSeasonComponent implements OnInit {

  addSeasonForm: FormGroup;

  seasonsArray: seasonInterface[];
  events: calendarEvent[];

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.seasonsArray = [
      {
          "name": "Summer 2022",
          "startDate": new Date("2022-02-01"),
          "endDate": new Date("2022-09-30"),
          "drops": [
              {
                  "name": "Men shirts collection",
                  "date": new Date("2022-02-15")
              },
              {
                  "name": "Women warm colors",
                  "date": new Date("2022-06-15")
              }
          ]
      },
      {
          "name": "Winters 2022",
          "startDate": new Date("2022-10-01"),
          "endDate": new Date("2023-01-31"),
          "drops": [
              {
                  "name": "Boys' high-necks",
                  "date": new Date("2022-11-18")
              },
              {
                  "name": "Girls' jackets",
                  "date": new Date("2022-12-29")
              }
          ]
      }
    ];
    this.initializeSeasonForm();
    this.calculateEvents();
  }
  
  initializeSeasonForm() {
    this.addSeasonForm = this.formBuilder.group({
      name: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required]
    });
  }

  addSeason() {
    this.seasonsArray.push(this.addSeasonForm.value);
    this.initializeSeasonForm();
  }

  addDrop(index: number) {
    const confirmationDialogRef = this.dialog.open(AddDropComponent, {
      width: '600px'
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        !this.seasonsArray[index].drops && (this.seasonsArray[index].drops = []);
        this.seasonsArray[index].drops.push(result);
        const obj: calendarEvent = {
          CalendarEventDate: (new Date(result.date.setHours(0,0,0,0))).toString(),
          CalendarEventId: 1,
          CalendarEventName: result.name,
          ColorHex: '#'+Math.floor(Math.random()*16777215).toString(16)
        }
        this.events.push(obj);
      }
    });
  }

  calculateEvents() {
    this.events = [];
    this.seasonsArray.forEach(season => {
      season.drops.forEach(drop => {
        const obj: calendarEvent = {
          CalendarEventDate: (new Date(drop.date.setHours(0,0,0,0))).toString(),
          CalendarEventId: 1,
          CalendarEventName: drop.name,
          ColorHex: '#'+Math.floor(Math.random()*16777215).toString(16)
        }
        this.events.push(obj);
      });
    });
  }

}
