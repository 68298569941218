import { Global_Planning } from '../mock-forecast';

export class PhysicalYear {
  public title: string;
  public measures: Measures;
  public riDistiMeasure: Measures;
  public riSubTotalMeasures: Measures;
  public riTotalMeasures: Measures;
  public riAccountMeasures: Array<AccountMeasuresResponse>;
  public halfList: HalfYear[];
  public show?: boolean;
  [key: string]: any;
  constructor() {
    this.halfList = [];
    this.measures = new Measures();
    this.riDistiMeasure = new Measures();
    this.riSubTotalMeasures = new Measures();
    this.riTotalMeasures = new Measures();
    this.riAccountMeasures = new Array<AccountMeasuresResponse>();
  }
}
export class HalfYear {
  public title = '';
  public measures: Measures;
  public riDistiMeasure: Measures;
  public riSubTotalMeasures: Measures;
  public riTotalMeasures: Measures;
  public riAccountMeasures: Array<AccountMeasuresResponse>;
  public quarterList: Quarter[];
  public show?: boolean;
  [key: string]: any;
  constructor() {
    this.quarterList = [];
    this.measures = new Measures();
    this.riDistiMeasure = new Measures();
    this.riSubTotalMeasures = new Measures();
    this.riTotalMeasures = new Measures();
    this.riAccountMeasures = new Array<AccountMeasuresResponse>();
  }
}
export class Quarter {
  public title = '';
  public measures: Measures;
  public riDistiMeasure: Measures;
  public riSubTotalMeasures: Measures;
  public riTotalMeasures: Measures;
  public monthList: Month[];
  public riAccountMeasures: Array<AccountMeasuresResponse>;
  public show?: boolean;
  [key: string]: any;
  constructor() {
    this.monthList = [];
    this.measures = new Measures();
    this.riDistiMeasure = new Measures();
    this.riSubTotalMeasures = new Measures();
    this.riTotalMeasures = new Measures();
    this.riAccountMeasures = new Array<AccountMeasuresResponse>();
  }
}
export class Month {
  public title = '';
  public measures: Measures;
  public riDistiMeasure: Measures;
  public riSubTotalMeasures: Measures;
  public riTotalMeasures: Measures;
  public weekList: Week[];
  public riAccountMeasures: Array<AccountMeasuresResponse>;
  public show?: boolean;
  [key: string]: any;
  constructor() {
    this.weekList = [];
    this.measures = new Measures();
    this.riDistiMeasure = new Measures();
    this.riSubTotalMeasures = new Measures();
    this.riTotalMeasures = new Measures();
    this.riAccountMeasures = new Array<AccountMeasuresResponse>();
  }
}
export class Week {
  public title = '';
  public measures: Measures;
  public riDistiMeasure: Measures;
  public riSubTotalMeasures: Measures;
  public riTotalMeasures: Measures;
  public riAccountMeasures: Array<AccountMeasuresResponse>;
  [key: string]: any;
  constructor() {
    this.measures = new Measures();
    this.riDistiMeasure = new Measures();
    this.riSubTotalMeasures = new Measures();
    this.riTotalMeasures = new Measures();
    this.riAccountMeasures = new Array<AccountMeasuresResponse>();
  }
}
export class AccountMeasuresResponse {
  channelId: number;
  measures: Measures;
  constructor() {
    this.measures = new Measures();
    this.channelId = 0;
  }
}
export enum FB_Template {
  Global_Planning = 1,
  Regional_Planning = 2,
  Retail_Direct = 3,
  Retail_InDirect = 4,
}
export class Measures {
  AlgoBaselineFcst: number;
  AlgoPromoUnits: number;
  AlgoTotalForecast: number;
  AlgoTFvsGDPTotalForecastPct: number;
  AlgoTFvsUDPLikelyTotalFcstPct: number;
  AlgoTFvsRDPTotalForecastPct: number;
  GDPBaselineFcst: number;
  GDPPromoUnits: number;
  GDPManualFcstAdjustment: number;
  GDPManualFcstAdjustmentPct: number;
  GDPTotalForecast: number;
  GDPComments: string;
  RDPBaselineFcst: number;
  RDPPromoUnits: number;
  RDPManualFcstAdjustment: number;
  RDPManualFcstAdjustmentPct: number;
  RDPTotalForecast: number;
  RDPComments: string;
  UDPLikelyTotalFcst: number;
  CustomerSTFcst: number;
  SalesGoal: number;
  CPFRSellThruOverrideFcst: number;
  ConstrainedSTFcst: number;
  ConstrainedSTFcstvsUDPLikelyTotalFcst: number;
  GDPTotalFcstvsUDPLikelyTotalFcstPct: number;
  GDPTotalFcstvsCustomerSTFcstPct: number;
  GDPTotalFcstvsSalesGoalPct: number;
  GDPTotalFcstvsCOSellThruOverrideFcstPct: number;
  GDPTotalFcstvsActualSTPct: number;
  RDPTotalFcstvsUDPLikelyTotalFcstPct: number;
  RDPTotalFcstvsCustomerSTFcstPct: number;
  RDPTotalFcstvsSalesGoalPct: number;
  RDPTotalFcstvsCOSellThruOverrideFcstPct: number;
  RDPTotalFcstvsActualSTPct: number;
  UDPLikelyTotalFcstvsActualSTPct: number;
  UDPLikelyTotalFcstvsCustomerSTFcstPct: number;
  CPFRSTOverrideFcstvsUDPLikelyTotalFcstPct: number;
  ActualST: number;
  WeeklyTrend: number;
  PromoIndicatorComments: string;
  CPFRComments: string;
  CustomerInStockEstimatePct: number;
  WebstoreTraffic: number;
  WebstoreConversion: number;
  ActualSTvsUDPLikelyTotalFcst: number;
  ActualSTvsUDPLikelyTotalFcstPct: number;
  ActualSTvsConstrainedSTFcst: number;
  ActualSTvsConstrainedSTFcstPct: number;
  ActualSTvsCustomerSTFcstPct: number;
  ActualSTvsCPFRSTOverrideFcstPct: number;
  ActualSTWOWPct: number;
  GDPHighTotalForecast: number;
  GDPLowTotalForecast: number;
  RDPHighTotalForecast: number;
  RDPLowTotalForecast: number;
  UDPHighTotalFcst: number;
  UDPLowTotalFcst: number;
  UDPHighTotalFcstvsUDPLikelyTotalFcstPct: number;
  UDPLowTotalFcstvsUDPLikelyTotalFcstPct: number;
  GDPHighTotalFcstvsGDPTotalFcstPct: number;
  GDPLowTotalFcstvsGDPTotalFcstPct: number;
  GDPHighTotalFcstvsUDPHighTotalFcstPct: number;
  GDPLowTotalFcstvsUDPLowTotalFcstPct: number;
  RDPHighTotalFcstvsRDPTotalFcstPct: number;
  RDPLowTotalFcstvsRDPTotalFcstPct: number;
  RDPHighTotalFcstvsUDPHighTotalFcstPct: number;
  RDPLowTotalFcstvsUDPLowTotalFcstPct: number;
  GDPHighTotalFcstvsRDPHighTotalFcstPct: number;
  GDPTotalForecastvsRDPTotalForecastPct: number;
  GDPLowTotalForecastvsRDPLowTotalForecastPct: number;
  RDPHighTotalForecastvsGDPHighTotalForecastPct: number;
  RDPTotalForecastvsGDPTotalForecastPct: number;
  RDPLowTotalForecastvsGDPLowTotalForecastPct: number;
  LYActualST: number;
  LYPromoIndicatorComments: string;
  LYCPFRComments: string;
  LYCustomerInStockEstimatePct: number;
  TYActualSTvsLYActualSTPct: number;
  GDPTotalFcstvsLYActualSTPct: number;
  RDPTotalFcstvsLYActualSTPct: number;
  UDPLikelyTotalFcstvsLYActualSTPct: number;
  DistiUnconstrainedSellInFcst: number;
  DistiUnconstrainedLandedSupply: number;
  DistiUnconstrainedSellOut: number;
  AccountUnconstrainedSellIn: number;
  DistiFinalSellInvsDistiUnconstrainedSellInFcst: number;
  DistiUnconstrainedSellInvsDistiConstrainedSellIn: number;
  DistiConstrainedSellInFcst: number;
  CustomerSellInFcst: number;
  DCLandedSupplyPlan: number;
  DistiAvailableAllocation: number;
  DistiFinalSellIn: number;
  DistiConstrainedLandedSupply: number;
  DistiConstrainedSellOut: number;
  DistiFinalSellInvsDistiAvailableAllocation: number;
  AccountConstrainedSellIn: number;
  InTransit: number;
  OpenOrders: number;
  Shipments: number;
  ProjectedDeliveries: number;
  DeliveredQty: number;
  OpenOrdersvsDistiFinalSellIn: number;
  PalletQty: number;
  CasepackQty: number;
  DistiUnconstrainedEndingInventory: number;
  DistiUnconstrainedFWOS: number;
  DistiUnconstrainedTargetInventory: number;
  DistiTargetFWOS: number;
  DistiUnconstrainedFWOSvsDistiTargetFWOS: number;
  DistiUnconstrainedEndingInventoryvsDistiConstrainedEndingInventory: number;
  AccountUnconstrainedEndingInventory: number;
  AccountUnconstrainedFWOS: number;
  AccountTargetFWOS: number;
  AccountUnconstrainedTargetInventory: number;
  AccountUnconstrainedFWOSvsAccountTargetFWOS: number;
  DistiEndingonHandInventory: number;
  DistiConstrainedEndingInventory: number;
  DistiConstrainedFWOS: number;
  DistiConstrainedFWOSvsDistiTargetFWOS: number;
  AccountEndingonHandInventory: number;
  AccountConstrainedEndingInventory: number;
  AccountConstrainedFWOS: number;
  AccountConstrainedFWOSvsAccountTargetFWOS: number;
  CustomerProjectedEndingInventory: number;
  CustomerFWOS: number;
  V1UDPLowTotalFcst: number;
  V1UDPLikelyTotalFcst: number;
  V1UDPHighTotalFcst: number;
  V2UDPLowTotalFcst: number;
  V2UDPLikelyTotalFcst: number;
  V2UDPHighTotalFcst: number;
  V3UDPLowTotalFcst: number;
  V3UDPLikelyTotalFcst: number;
  V3UDPHighTotalFcst: number;
  V4UDPLowTotalFcst: number;
  V4UDPLikelyTotalFcst: number;
  V4UDPHighTotalFcst: number;
  V5UDPLowTotalFcst: number;
  V5UDPLikelyTotalFcst: number;
  V5UDPHighTotalFcst: number;
  V6UDPLowTotalFcst: number;
  V6UDPLikelyTotalFcst: number;
  V6UDPHighTotalFcst: number;
  V7UDPLowTotalFcst: number;
  V7UDPLikelyTotalFcst: number;
  V7UDPHighTotalFcst: number;
  V8UDPLowTotalFcst: number;
  V8UDPLikelyTotalFcst: number;
  V8UDPHighTotalFcst: number;
  V9UDPLowTotalFcst: number;
  V9UDPLikelyTotalFcst: number;
  V9UDPHighTotalFcst: number;
  V10UDPLowTotalFcst: number;
  V10UDPLikelyTotalFcst: number;
  V10UDPHighTotalFcst: number;
  V11UDPLowTotalFcst: number;
  V11UDPLikelyTotalFcst: number;
  V11UDPHighTotalFcst: number;
  V12UDPLowTotalFcst: number;
  V12UDPLikelyTotalFcst: number;
  V12UDPHighTotalFcst: number;
  V1UDPSellIn: number;
  V2UDPSellIn: number;
  V3UDPSellIn: number;
  V4UDPSellIn: number;
  V5UDPSellIn: number;
  V6UDPSellIn: number;
  V7UDPSellIn: number;
  V8UDPSellIn: number;
  V9UDPSellIn: number;
  V10UDPSellIn: number;
  V11UDPSellIn: number;
  V12UDPSellIn: number;
  V1ODPForecast: number;
  V2ODPForecast: number;
  V3ODPForecast: number;
  V4ODPForecast: number;
  V5ODPForecast: number;
  V6ODPForecast: number;
  V7ODPForecast: number;
  V8ODPForecast: number;
  V9ODPForecast: number;
  V10ODPForecast: number;
  V11ODPForecast: number;
  V12ODPForecast: number;
  LeadTime: number;
  DistiLeadTime: number;
  ActualSTCumulative: number;
  UDPLikelyTotalFcstCumulative: number;
  CustomerSTFcstCumulative: number;
  CPFRSellThruOverrideFcstCumulative: number;
  AccountAvailableAllocation: number;
  AccountFinalSellInShipped: number;
  AccountConstrainedSellInFcst: number;
  AccountConstrainedSellInFcstCumulative: number;
  AccountFinalSellInReceived: number;
  AccountLeadTime: number;
  AccountConstrainedSellInFcstDirect: number;
  AccountConstrainedSellInCumulative: number;
  DistiConstrainedSellInFcstCumulative: number;
  DistiFinalSellInReceived: number;
  DistiFinalSellInShipped: number;
  FBOpeningOnHand: number;
  FBProjectedEndingOnHand: number;
  FBProjectedFWOS: number;
  FBSellThruFcst: number;
  FBTargetEndingOnHand: number;
  DistiActualST: number;
  DistiActualSTCumulative: number;
  DistiUDPLikelyTotalFcst: number;
  DistiUDPLikelyTotalFcstCumulative: number;
  DistiCustomerSTFcst: number;
  DistiCustomerSTFcstCumulative: number;
  DistiCPFRSellThruOverrideFcst: number;
  DistiCPFRSellThruOverrideFcstCumulative: number;
  DistiCPFRComments: string;
  IndirectAccountConstrainedSellIn: number;
  IndirectAccountConstrainedSellInCumulative: number;
  DistiInTransit: number;
  DistiOpenOrders: number;
  DistiShipments: number;
  DistiProjectedDeliveries: number;
  DistiDeliveredQty: number;
  GDPPromoFcstAdjustmentPct: number;
  RDPPromoFcstAdjustmentPct: number;
  AlgoTotalFcstvsGDPTotalFcst: number;
  AlgoTotalFcstvsUDPLikelyTotalFcst: number;
  AlgoBaselinevsGDPBaselineFcst: number;
  AlgoBaselinevsUDPLikelyBaselineFcst: number;
  AlgoPromovsGDPPromoFcst: number;
  AlgoTotalFcstvsRDPTotalFcst: number;
  AlgoBaselinevsRDPBaselineFcst: number;
  AlgoPromovsRDPPromoFcst: number;
  AlgoAccountUnconstrainedSellIn: number;
  LYDistiActualST: number;
  TYDistiActualSTvsLYDistiActualSTPct: number;
  DistiSalesGoal: number;
  OpenOrdersActualvsDistiFinalSellIn: number;
  DistiConstrainedFWOSvsDistiFWOS: number;
  AccountDPUnconstrainedSellIn: number;
  AlgoAccountUnconstrainedEndingInventory: number;
  AccountDPUnconstrainedEndingInventory: number;
  constructor() {
    this.AlgoBaselineFcst = 0;
    this.AlgoPromoUnits = 0;
    this.AlgoTotalForecast = 0;
    this.AlgoTFvsGDPTotalForecastPct = 0;
    this.AlgoTFvsUDPLikelyTotalFcstPct = 0;
    this.AlgoTFvsRDPTotalForecastPct = 0;
    this.GDPBaselineFcst = 0;
    this.GDPPromoUnits = 0;
    this.GDPManualFcstAdjustment = 0;
    this.GDPManualFcstAdjustmentPct = 0;
    this.GDPTotalForecast = 0;
    this.GDPComments = '';
    this.RDPBaselineFcst = 0;
    this.RDPPromoUnits = 0;
    this.RDPManualFcstAdjustment = 0;
    this.RDPManualFcstAdjustmentPct = 0;
    this.RDPTotalForecast = 0;
    this.RDPComments = '';
    this.UDPLikelyTotalFcst = 0;
    this.CustomerSTFcst = 0;
    this.SalesGoal = 0;
    this.CPFRSellThruOverrideFcst = 0;
    this.ConstrainedSTFcst = 0;
    this.ConstrainedSTFcstvsUDPLikelyTotalFcst = 0;
    this.GDPTotalFcstvsUDPLikelyTotalFcstPct = 0;
    this.GDPTotalFcstvsCustomerSTFcstPct = 0;
    this.GDPTotalFcstvsSalesGoalPct = 0;
    this.GDPTotalFcstvsCOSellThruOverrideFcstPct = 0;
    this.GDPTotalFcstvsActualSTPct = 0;
    this.RDPTotalFcstvsUDPLikelyTotalFcstPct = 0;
    this.RDPTotalFcstvsCustomerSTFcstPct = 0;
    this.RDPTotalFcstvsSalesGoalPct = 0;
    this.RDPTotalFcstvsCOSellThruOverrideFcstPct = 0;
    this.RDPTotalFcstvsActualSTPct = 0;
    this.UDPLikelyTotalFcstvsActualSTPct = 0;
    this.UDPLikelyTotalFcstvsCustomerSTFcstPct = 0;
    this.CPFRSTOverrideFcstvsUDPLikelyTotalFcstPct = 0;
    this.ActualST = 0;
    this.WeeklyTrend = 0;
    this.PromoIndicatorComments = '';
    this.CPFRComments = '';
    this.CustomerInStockEstimatePct = 0;
    this.WebstoreTraffic = 0;
    this.WebstoreConversion = 0;
    this.ActualSTvsUDPLikelyTotalFcst = 0;
    this.ActualSTvsUDPLikelyTotalFcstPct = 0;
    this.ActualSTvsConstrainedSTFcst = 0;
    this.ActualSTvsConstrainedSTFcstPct = 0;
    this.ActualSTvsCustomerSTFcstPct = 0;
    this.ActualSTvsCPFRSTOverrideFcstPct = 0;
    this.ActualSTWOWPct = 0;
    this.GDPHighTotalForecast = 0;
    this.GDPLowTotalForecast = 0;
    this.RDPHighTotalForecast = 0;
    this.RDPLowTotalForecast = 0;
    this.UDPHighTotalFcst = 0;
    this.UDPLowTotalFcst = 0;
    this.UDPHighTotalFcstvsUDPLikelyTotalFcstPct = 0;
    this.UDPLowTotalFcstvsUDPLikelyTotalFcstPct = 0;
    this.GDPHighTotalFcstvsGDPTotalFcstPct = 0;
    this.GDPLowTotalFcstvsGDPTotalFcstPct = 0;
    this.GDPHighTotalFcstvsUDPHighTotalFcstPct = 0;
    this.GDPLowTotalFcstvsUDPLowTotalFcstPct = 0;
    this.RDPHighTotalFcstvsRDPTotalFcstPct = 0;
    this.RDPLowTotalFcstvsRDPTotalFcstPct = 0;
    this.RDPHighTotalFcstvsUDPHighTotalFcstPct = 0;
    this.RDPLowTotalFcstvsUDPLowTotalFcstPct = 0;
    this.GDPHighTotalFcstvsRDPHighTotalFcstPct = 0;
    this.GDPTotalForecastvsRDPTotalForecastPct = 0;
    this.GDPLowTotalForecastvsRDPLowTotalForecastPct = 0;
    this.RDPHighTotalForecastvsGDPHighTotalForecastPct = 0;
    this.RDPTotalForecastvsGDPTotalForecastPct = 0;
    this.RDPLowTotalForecastvsGDPLowTotalForecastPct = 0;
    this.LYActualST = 0;
    this.LYPromoIndicatorComments = '';
    this.LYCPFRComments = '';
    this.LYCustomerInStockEstimatePct = 0;
    this.TYActualSTvsLYActualSTPct = 0;
    this.GDPTotalFcstvsLYActualSTPct = 0;
    this.RDPTotalFcstvsLYActualSTPct = 0;
    this.UDPLikelyTotalFcstvsLYActualSTPct = 0;
    this.DistiUnconstrainedSellInFcst = 0;
    this.DistiUnconstrainedLandedSupply = 0;
    this.DistiUnconstrainedSellOut = 0;
    this.AccountUnconstrainedSellIn = 0;
    this.DistiFinalSellInvsDistiUnconstrainedSellInFcst = 0;
    this.DistiUnconstrainedSellInvsDistiConstrainedSellIn = 0;
    this.DistiConstrainedSellInFcst = 0;
    this.CustomerSellInFcst = 0;
    this.DCLandedSupplyPlan = 0;
    this.DistiAvailableAllocation = 0;
    this.DistiFinalSellIn = 0;
    this.DistiConstrainedLandedSupply = 0;
    this.DistiConstrainedSellOut = 0;
    this.DistiFinalSellInvsDistiAvailableAllocation = 0;
    this.AccountConstrainedSellIn = 0;
    this.InTransit = 0;
    this.OpenOrders = 0;
    this.Shipments = 0;
    this.ProjectedDeliveries = 0;
    this.DeliveredQty = 0;
    this.OpenOrdersvsDistiFinalSellIn = 0;
    this.PalletQty = 0;
    this.CasepackQty = 0;
    this.DistiUnconstrainedEndingInventory = 0;
    this.DistiUnconstrainedFWOS = 0;
    this.DistiUnconstrainedTargetInventory = 0;
    this.DistiTargetFWOS = 0;
    this.DistiUnconstrainedFWOSvsDistiTargetFWOS = 0;
    this.DistiUnconstrainedEndingInventoryvsDistiConstrainedEndingInventory = 0;
    this.AccountUnconstrainedEndingInventory = 0;
    this.AccountUnconstrainedFWOS = 0;
    this.AccountTargetFWOS = 0;
    this.AccountUnconstrainedTargetInventory = 0;
    this.AccountUnconstrainedFWOSvsAccountTargetFWOS = 0;
    this.DistiEndingonHandInventory = 0;
    this.DistiConstrainedEndingInventory = 0;
    this.DistiConstrainedFWOS = 0;
    this.DistiConstrainedFWOSvsDistiTargetFWOS = 0;
    this.AccountEndingonHandInventory = 0;
    this.AccountConstrainedEndingInventory = 0;
    this.AccountConstrainedFWOS = 0;
    this.AccountConstrainedFWOSvsAccountTargetFWOS = 0;
    this.CustomerProjectedEndingInventory = 0;
    this.CustomerFWOS = 0;
    this.V1UDPLowTotalFcst = 0;
    this.V1UDPLikelyTotalFcst = 0;
    this.V1UDPHighTotalFcst = 0;
    this.V2UDPLowTotalFcst = 0;
    this.V2UDPLikelyTotalFcst = 0;
    this.V2UDPHighTotalFcst = 0;
    this.V3UDPLowTotalFcst = 0;
    this.V3UDPLikelyTotalFcst = 0;
    this.V3UDPHighTotalFcst = 0;
    this.V4UDPLowTotalFcst = 0;
    this.V4UDPLikelyTotalFcst = 0;
    this.V4UDPHighTotalFcst = 0;
    this.V5UDPLowTotalFcst = 0;
    this.V5UDPLikelyTotalFcst = 0;
    this.V5UDPHighTotalFcst = 0;
    this.V6UDPLowTotalFcst = 0;
    this.V6UDPLikelyTotalFcst = 0;
    this.V6UDPHighTotalFcst = 0;
    this.V7UDPLowTotalFcst = 0;
    this.V7UDPLikelyTotalFcst = 0;
    this.V7UDPHighTotalFcst = 0;
    this.V8UDPLowTotalFcst = 0;
    this.V8UDPLikelyTotalFcst = 0;
    this.V8UDPHighTotalFcst = 0;
    this.V9UDPLowTotalFcst = 0;
    this.V9UDPLikelyTotalFcst = 0;
    this.V9UDPHighTotalFcst = 0;
    this.V10UDPLowTotalFcst = 0;
    this.V10UDPLikelyTotalFcst = 0;
    this.V10UDPHighTotalFcst = 0;
    this.V11UDPLowTotalFcst = 0;
    this.V11UDPLikelyTotalFcst = 0;
    this.V11UDPHighTotalFcst = 0;
    this.V12UDPLowTotalFcst = 0;
    this.V12UDPLikelyTotalFcst = 0;
    this.V12UDPHighTotalFcst = 0;
    this.V1UDPSellIn = 0;
    this.V2UDPSellIn = 0;
    this.V3UDPSellIn = 0;
    this.V4UDPSellIn = 0;
    this.V5UDPSellIn = 0;
    this.V6UDPSellIn = 0;
    this.V7UDPSellIn = 0;
    this.V8UDPSellIn = 0;
    this.V9UDPSellIn = 0;
    this.V10UDPSellIn = 0;
    this.V11UDPSellIn = 0;
    this.V12UDPSellIn = 0;
    this.V1ODPForecast = 0;
    this.V2ODPForecast = 0;
    this.V3ODPForecast = 0;
    this.V4ODPForecast = 0;
    this.V5ODPForecast = 0;
    this.V6ODPForecast = 0;
    this.V7ODPForecast = 0;
    this.V8ODPForecast = 0;
    this.V9ODPForecast = 0;
    this.V10ODPForecast = 0;
    this.V11ODPForecast = 0;
    this.V12ODPForecast = 0;
    this.LeadTime = 0;
    this.DistiLeadTime = 0;
    this.ActualSTCumulative = 0;
    this.UDPLikelyTotalFcstCumulative = 0;
    this.CustomerSTFcstCumulative = 0;
    this.CPFRSellThruOverrideFcstCumulative = 0;
    this.AccountAvailableAllocation = 0;
    this.AccountFinalSellInShipped = 0;
    this.AccountConstrainedSellInFcst = 0;
    this.AccountConstrainedSellInFcstCumulative = 0;
    this.AccountFinalSellInReceived = 0;
    this.AccountLeadTime = 0;
    this.AccountConstrainedSellInFcstDirect = 0;
    this.AccountConstrainedSellInCumulative = 0;
    this.DistiConstrainedSellInFcstCumulative = 0;
    this.DistiFinalSellInReceived = 0;
    this.DistiFinalSellInShipped = 0;
    this.FBOpeningOnHand = 0;
    this.FBProjectedEndingOnHand = 0;
    this.FBProjectedFWOS = 0;
    this.FBSellThruFcst = 0;
    this.FBTargetEndingOnHand = 0;
    this.DistiActualST = 0;
    this.DistiActualSTCumulative = 0;
    this.DistiUDPLikelyTotalFcst = 0;
    this.DistiUDPLikelyTotalFcstCumulative = 0;
    this.DistiCustomerSTFcst = 0;
    this.DistiCustomerSTFcstCumulative = 0;
    this.DistiCPFRSellThruOverrideFcst = 0;
    this.DistiCPFRSellThruOverrideFcstCumulative = 0;
    this.DistiCPFRComments = '';
    this.IndirectAccountConstrainedSellIn = 0;
    this.IndirectAccountConstrainedSellInCumulative = 0;
    this.DistiInTransit = 0;
    this.DistiOpenOrders = 0;
    this.DistiShipments = 0;
    this.DistiProjectedDeliveries = 0;
    this.DistiDeliveredQty = 0;
    this.GDPPromoFcstAdjustmentPct = 0;
    this.RDPPromoFcstAdjustmentPct = 0;
    this.AlgoTotalFcstvsGDPTotalFcst = 0;
    this.AlgoTotalFcstvsUDPLikelyTotalFcst = 0;
    this.AlgoBaselinevsGDPBaselineFcst = 0;
    this.AlgoBaselinevsUDPLikelyBaselineFcst = 0;
    this.AlgoPromovsGDPPromoFcst = 0;
    this.AlgoTotalFcstvsRDPTotalFcst = 0;
    this.AlgoBaselinevsRDPBaselineFcst = 0;
    this.AlgoPromovsRDPPromoFcst = 0;
    this.AlgoAccountUnconstrainedSellIn = 0;
    this.LYDistiActualST = 0;
    this.TYDistiActualSTvsLYDistiActualSTPct = 0;
    this.DistiSalesGoal = 0;
    this.OpenOrdersActualvsDistiFinalSellIn = 0;
    this.DistiConstrainedFWOSvsDistiFWOS = 0;
    this.AccountDPUnconstrainedSellIn = 0;
    this.AlgoAccountUnconstrainedEndingInventory = 0;
    this.AccountDPUnconstrainedEndingInventory = 0;
  }
}
