import { Component, OnInit } from '@angular/core';
import { businessMetaDataGridColDefs, cacheBlockSize, paginationPageSize } from './ag-grid/gridOptions';
import { AllCommunityModules, ColDef } from '@ag-grid-community/all-modules';
import { DropdownRendererComponent } from 'src/common/dropdown-renderer';
import { AgGridDatePickerRenderer } from './ag-grid/agGridDatePickerRenderer';

@Component({
  selector: 'app-business-metadata',
  templateUrl: './business-metadata.component.html',
  styleUrls: ['./business-metadata.component.scss']
})
export class BusinessMetadataComponent implements OnInit {

  // Grid Variables
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  public gridColumnDefs;
  public modules = AllCommunityModules;
  public gridOptions: any;
  frameworkComponents = {
    dropdownRendererComponent: DropdownRendererComponent,
    agGridDatePickerRenderer: AgGridDatePickerRenderer
  };

  descriptions = [
    'The best feature',
    'Creative Intelligence',
    'Businessn Intelligence',
    'Natural Language Processing',
    'Research',
    'Metadata'
  ];

  names = [
    'Alpha',
    'Beta',
    'Gamma',
    'Delta',
    'Epsilon',
    'Zeta',
    'Eta',
    'Theta',
    'Iota',
    'Kappa',
    'Lambda',
    'Mu',
    'Nu',
    'Xi',
    'Omicron'
  ];

  gridNames = [
    'Season',
    'Fabric',
    'Category',
    'Sub Category',
    'Class',
    'Sub Class',
    'Item Attributes',
    'Item',
    'Department',
    'Sub Department',
    'Vendor',
    'Country',
    'Region',
    'State',
    'County',
    'Retails'
  ];

  gridColumns = [
    businessMetaDataGridColDefs(['Season Code', 'Reporting Season Name', 'Description', 'Season Start Date', 'Season End Date']),
    businessMetaDataGridColDefs(['Fabric Code', 'Fabric Name', 'Reporting Name', 'Description', 'Item Code']),
    businessMetaDataGridColDefs(['Category Code', 'Category Name', 'Reporting Name', 'Description']),
    businessMetaDataGridColDefs(['Sub Category Code', 'Sub Category Name', 'Reporting Name', 'Description', 'Category Code']),
    businessMetaDataGridColDefs(['Class Code', 'Class Name', 'Reporting Name', 'Description']),
    businessMetaDataGridColDefs(['Sub Class Code', 'Sub Class Name', 'Reporting Name', 'Description', 'Class Code']),
    businessMetaDataGridColDefs(['Attr Code', 'Attr Name', 'Reporting Name', 'Description']),
    businessMetaDataGridColDefs(['Item Code', 'Item Name', 'Reporting Name', 'Description', 'Attr Code 1', 'Attr Code 2', 'Attr Code 3', 'Attr Code 4', 'Dept Code']),
    businessMetaDataGridColDefs(['Dept Code', 'Dept Name', 'Reporting Name', 'Description', 'Business Value', 'Business Start Date']),
    businessMetaDataGridColDefs(['Sub Dept Code', 'Sub Dept Name', 'Reporting Name', 'Description', 'Dept Code']),
    businessMetaDataGridColDefs(['Vendor Code', 'Vendor Name', 'Reporting Name', 'Description', 'Dept Code']),
    businessMetaDataGridColDefs(['Country Code', 'Country Name', 'Reporting Name', 'Description']),
    businessMetaDataGridColDefs(['Region', 'Region Name', 'Reporting Name', 'Description']),
    businessMetaDataGridColDefs(['State Code', 'State Name', 'Description', 'Region Code']),
    businessMetaDataGridColDefs(['County Code', 'County Name', 'Reporting Name', 'Description', 'State Code']),
    businessMetaDataGridColDefs(['Retailer Code', 'Retailer Name', 'Reporting Name', 'Description', 'Latitude', 'Longitude', 'Date Open', 'Area Sq Ft', 'Display Area Sq Ft', 'Stock Area Sq Ft', 'Stock Area Sq Ft'])
  ];

  dataSources = [];

  constructor() { }

  ngOnInit(): void {
    this.gridColumns.forEach((e, index) => {
      const dataSource = [];
      const keys = [];
      e.forEach(element => {
        keys.push(element.headerName);
      });
      for (let i = 0; i < 20; i++) {
        const obj = {};
        keys.forEach((colKey: string, keyIndex) => {
          if (keyIndex == 0) {
            obj[colKey] = i + 1;
          } else if (colKey.toLowerCase().includes('code')) {
            obj[colKey] = Math.floor(Math.random() * 6) + 1;
          } else if (colKey.toLowerCase().includes('date')) {
            obj[colKey] = new Date();
          } else if (colKey.toLowerCase().includes('description')) {
            obj[colKey] = this.descriptions[Math.floor(Math.random() * 6) + 1];
          } else if (colKey.toLowerCase().includes('name')) {
            obj[colKey] = this.names[Math.floor(Math.random() * 14) + 1]
          } else {
            obj[colKey] = 'Dummy Test Data'
          }
        });
        dataSource.push(obj);
      }
      this.dataSources.push(dataSource);
    });
  }

}
