import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AllModules, GridApi, ColumnApi, ServerSideStoreType } from '@ag-grid-enterprise/all-modules';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { DemoMasterTitleDialogComponent } from '../master-title-dialog/master-title-dialog.component';
import {
  masterGridColDefs,
  paginationPageSize,
  cacheBlockSize,
  rowSelection,
  defaultColDef
} from '../../product-manager/ag-grid/gridOptions';
import { MasterItemEditRenderer } from '../../product-manager/ag-grid/master-item-edit-renderer.component';
import { MasterItemActionCellRenderer } from '../../product-manager/ag-grid/master-item-action-cell-renderer.component';
import { MasterProductService } from 'src/services/Item-Management-Services';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { UsersService } from 'src/services/User-services/user-services';
import { INVALID_TEAM, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { getFilterModel, getSortModel } from '../../util/util';
import { tap } from 'rxjs/operators';

export interface DialogData {
  isDialoag: boolean;
}

@Component({
  selector: 'app-master-grid-dialog',
  templateUrl: './master-grid-dialog.component.html',
  styleUrls: ['./master-grid-dialog.component.css']
})
export class DemoMasterGridDialogComponent implements OnInit {

  @Input() isDialoag?: boolean = false;

  rowModelType = 'serverSide';
  serverSideStoreType = ServerSideStoreType.Partial;
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  rowSelection = rowSelection;
  public gridColumnDefs = masterGridColDefs(false);
  public defaultColDef = defaultColDef;
  public modules = AllModules;
  private gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: any;
  gridData: any = [];
  frameworkComponents = {
    masterItemEditRenderer: MasterItemEditRenderer,
    masterItemActionCellRenderer: MasterItemActionCellRenderer
  }
  public editSubscription: Subscription;

  selectedMasterRow: any;

  userTeam: any = '';
  isRetailerTeam: boolean = false;

  filterEvent: any = {};

  constructor(
    public dialogRef: MatDialogRef<DemoMasterGridDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private masterItemService: MasterProductService,
    public toastr: NgxToasterService,
    private usersService: UsersService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>) { }

  ngOnInit() {
    this.setRoleBaseAccess();
    this.editSubscription = this.masterItemService.getMasterGridActionCellSubject().subscribe(res => {
      if (res) {
        this.performGridAction(res);
      }
    });
  }

  setRoleBaseAccess() {
    this.userTeam = this.usersService.checkLoginUserTeam();
    switch (this.userTeam) {
      case VENDOR_TEAM_CODE: {
        this.isRetailerTeam = false;
        break;
      }
      case RETAILER_TEAM_CODE: {
        this.isRetailerTeam = true;
        break;
      }
      case INVALID_TEAM: {
        console.log('Valid Team Not assigned');
        break;
      }
    }

    this.gridColumnDefs = masterGridColDefs(this.isRetailerTeam);
    this.gridApi && this.gridApi.setColumnDefs(this.gridColumnDefs);
  }

  performGridAction(actionObject) {
    switch (actionObject.action) {
      case 'edit': {
        this.openProductDialog(true, actionObject.actionData);
        break;
      }
      case 'delete': {
        this.openDeleteDialog(actionObject.actionData);
        break;
      }
    }
  }

  createGridDataSource() {
    const datasource = {
      getRows: (params) => {
        const sortModel = params.request.sortModel;
        const filterModel = params.request.filterModel;
        const requestBody = {
          ...this.filterEvent,
          offSet: params.request.startRow ? params.request.startRow : 0,
          pageSize: paginationPageSize,
        };
        requestBody.filterModel = getFilterModel(filterModel);
        requestBody.sortModel = getSortModel(sortModel);

        this.masterItemService
          .GetAllMasterItems(requestBody)
          .pipe(
            tap(() => this.gridApi.hideOverlay())
          )
          .subscribe((response: any) => {
            params.successCallback(response.data, response.totalRecord);
            response.data.length == 0 && this.gridApi.showNoRowsOverlay();
          });
      },
    };
    this.gridApi && this.gridApi.setServerSideDatasource(datasource);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.createGridDataSource();
  }

  masterGridRowSelectionChanged(params) {
    this.selectedMasterRow = params.api.getSelectedRows()[0];
    this.masterItemService.selectedMasterItem = this.selectedMasterRow;
  }

  onDoneClick() {
    this.dialogRef.close({ selectedMasterRow: this.selectedMasterRow });
  }

  openProductDialog(isEdit = false, data = {}): void {
    const dialogRef = this.dialog.open(DemoMasterTitleDialogComponent, {
      data: {
        isEdit: isEdit,
        formData: {
          ...data
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.gridApi.refreshServerSideStore({ purge: true });
      }
    }, err => {
      console.log(err);
    });
  }

  openDeleteDialog(actionData) {
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: actionData.productTitleName }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteChildItem(actionData);
      }
    });
  }

  deleteChildItem(actionData) {
    this.masterItemService.DeleteMasterItem(actionData.masterItemId).subscribe((res: any) => {
      this.toastr.success('Success', 'Master Item Deleted Successfully.');
      this.gridApi.refreshServerSideStore({ purge: true });
    }, err => {
      this.toastr.error('Error', 'Master Item Deletion Failed.');
    });
  }

  applyFilters(event) {
    this.filterEvent = event;
    this.createGridDataSource();
  }

  ngOnDestroy() {
    this.editSubscription.unsubscribe();
  }
}
