import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChildItemService } from 'src/services/Item-Management-Services';

@Component({
  selector: 'child-item-action-cell-renderer',
  template: `<div class="grid-action-cell">
        <span class="material-icons" (click)="actionClickHandler('delete')">delete</span>
    </div>`,
})
export class ChildItemActionCellRenderer implements ICellRendererAngularComp, OnDestroy {
  params: any;

  constructor(private childItemService: ChildItemService) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  actionClickHandler(action) {
    this.childItemService.setChildGridActionSubject({ action, actionData: this.params.data });
  }

  refresh(params?: any): boolean {
    return true;
  }

  ngOnDestroy() {
    // no need to remove the button click handler 
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}