<!-- Main Content area -->
<div class="action-panel text-right">
  <button mat-raised-button class="btn btn-primary m-left" color="primary"
    (click)="openCategoryDialog({})">{{isEdit ? 'Update' : 'Add New Category'}} <i class="icon-add"></i></button>
</div>
<ejs-treeview id="tree" #tree [fields]="field" [allowEditing]="allowEditing" (nodeEditing)='editing($event)'
  (nodeEdited)='onNodeEdited($event)'>
  <ng-template #nodeTemplate="" let-data="">
    <div>
      <div style="pointer-events: all;" class="action-panel">
        {{data.categoryName}}
        <div class="internal-info">
          <button mat-raised-button class="btn btn-primary" (click)="openCategoryDialog(data)">
              <span>Sub Category</span>
              <i class="icon-add" (click)="openConfirmationDialog(data)"></i>
          </button>
          <button style="width: 40px; margin-left: 10px;" class="btn btn-secondary" color="primary" mat-icon-button>
            <mat-icon (click)="openConfirmationDialog(data)"><i style="cursor: pointer;" class="material-icons">clear</i>
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</ejs-treeview>
