import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { NgxToasterService } from '../../../../services/ngx-toaster.service';
import { LocalstorageService } from '../../../../services/localstorage.service';
import { ConfigurationService } from '../../../../services/configuration.service';

import {
  Calendar,
  FbChannel,
  FbForecast,
  ForecastCell,
  Measure,
  UserFbForecast,
  Version,
} from '../../../../common/facebook.models';
import { TreeviewItem } from 'ngx-treeview';
import { USER_ID, USER_INFO } from '../../../../common/keys';
import {
  FB_Template,
  HalfYear,
  Month,
  PhysicalYear,
  Quarter,
  Week,
} from '../global.models';
import { throwError } from 'rxjs';

import { InlineWorker } from './inline-worker';
import { environment } from '../../../../environments/environment';
import { ForecastConfirmationModalComponent } from '../fb-forecast/forecast-confirmation-modal/forecast-confirmation-modal.component';
import { ForecastSavedVersionsComponent } from '../fb-forecast/forecast-saved-versions/forecast-saved-versions.component';
import { ForecastDeletePlannerModalComponent } from '../fb-forecast/fb-forecast-delete-planner-modal/fb-forecast-delete-planner-modal.component';
import { Global_Planning } from '../../mock-forecast';
import { GlobalDemandPlannerMeasuresNewComponent } from './global-demand-planner-measure-new/global-demand-planner-measures-new.component';
import { addCommas, CreateAccountScrollingList, CreateAccountTree, CreateCalendarList, CreateCalendarListWithoutWeeks, CreateCalendarListWithWeeks, CreateItemTree, CreateProductScrollingList } from 'src/common/functions';

@Component({
  selector: 'app-global-demand-planner-new',
  templateUrl: './global-demand-planner-new.component.html',
  styleUrls: ['./global-demand-planner-new.component.scss'],
})
export class GlobalDemandPlannerNewComponent implements OnInit {
  @Input() data: any;
  maxPlannerCount = environment.maxPlannerSaveCount;
  public clientId = 1;
  public measureList: Measure[];
  public masterMeasureList = [];
  public DefaultMeasures = [
    'AlgoBaselineFcst',
    'AlgoPromoUnits',
    'AlgoTotalForecast',
    'GDPBaselineFcst',
    'GDPManualFcstAdjustment',
    'GDPPromoUnits',
    'GDPPromoFcstAdjustmentPct',
    'GDPManualFcstAdjustmentPct',
    'GDPTotalForecast',
    'GDPComments',
    'RDPTotalForecast',
    'UDPLikelyTotalFcst',
    'ActualST',
    'LYActualST'
  ]
  public channelList: FbChannel[];
  public timeframeList: Calendar[];
  public versionList: Version[];
  public fbForecastList: UserFbForecast[];
  public channles = [];
  public customers = [];
  public countries = [];
  public continentList = [];
  public channelDropdownList: TreeviewItem[];
  public calendarListDropdown: TreeviewItem[];
  public leftGroupList = [];
  public productFlatList = [];
  public productList = [];
  public itemIds = [];
  public channelIds = [];
  public calendarIds = [];
  public dropdownObjects;
  public updatedDropdownObject;
  public yearListGrid = [];
  public yearList = [];
  public forecastList = [];
  public restoredProductList = [];
  public restoredChannelList = [];
  public restoreCalendarList = [];
  public scenarioPlanningList: Measure[] = [];
  public timeFrameListWithWeeksUPD = [];
  public calendarIdsUDP = [];
  public calendarIdsScenario = [];
  public udpfcstList = [];
  public udpMeasuresList = [];
  public fcstScenarioPlanningList = [];
  public forecastMethodList;
  public seasonalitySourceLevelList;
  public averageWeekList;
  public fbForecast;
  showVersionList = false;
  className = '';
  public hideScreen = true;
  public versionTypeId = 2;
  public userFBForecastId = 0;
  public selectedScenario;
  plannerName = '';
  @ViewChild('saveForecast') saveForecastTemplate: TemplateRef<any>;
  @ViewChild('commitForecast') commitForecast: TemplateRef<any>;
  savedPlanner: {};
  selectedProducts = [];
  productTree = [];
  channelTree = [];
  isOpenSaved: boolean;
  isBuidlNew: boolean;
  isRestoreSession: boolean;
  gridMapped: boolean;
  channelValue: any = '';
  calanderValue: any = '';
  productValue: any = '';
  isShowTreeDropDowns: boolean;
  public itemsList = [];
  public accountList = [];
  public productSettingList = [];
  public channelSettingList = [];
  public tempForecastList = [];
  public timeFrameListWithWeeks = [];
  public TimeframeWithWeeksUDP = [];
  public TimeframeWithWeeksScenario = [];
  refreshMeasureIds;
  selectedChannelIds = [];
  product: string;
  account: string;
  viewPermission = true;
  editPermission = false;
  readOnlyMeasres: Measure[];
  editViaUploadMeasures: Measure[];
  public forecastMethod;
  public seasonalitySourceLevel;
  public movingAverageWeeks;
  public selectedForecastMeasure;
  public selectedUDPMeasure;
  public adjustment = 0;
  public selectedScenarioForecastMeasure;
  public forecastMethodValue = '';
  public seasonalitySourceLevelValue = '';
  public movingAverageWeeksValue = '';
  public date = new Date();
  public measureKeys = [];
  public isDeleted = true;
 get refreshMeasuresList() {
   return this.masterMeasureList.filter(row => row.isRefresh);
 }
  constructor(
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    public dialogRef: MatDialogRef<any>,
    public ref: MatDialogRef<any>,
    public configurationService: ConfigurationService,
    public dialog: MatDialog
  ) {}
  async ngOnInit() {
    if (this.data) {
      this.data = JSON.parse(this.data);
    }
    let userTeams = this.storage.get(USER_INFO).teams;
    userTeams.forEach((element) => {
      element.teamScreenGroup.forEach((group) => {
        if (group.screenGroupId == 5 && group.viewPermission) {
          this.viewPermission = true;
        }
        if (group.screenGroupId == 5 && group.editPermission) {
          this.editPermission = true;
        }
      });
    });
    this.getUserForecast();
  }
  public getUserForecast = async () => {
    const model = {
      userId: this.storage.get(USER_ID),
      userFBForecastId: 0,
      fbTemplateId: FB_Template.Global_Planning,
    };
    this.fbForecast = await this.configurationService
      .GetUserSession(model)
      .toPromise()
      .catch((error) => throwError(error));
    if (
      this.fbForecast.itemIds &&
      this.fbForecast.channelIds &&
      this.fbForecast.calendarIds
    ) {
      this.openActionDialog(true);
    } else {
      this.openActionDialog(false);
    }
  };
  public getDefaultDropdowns = async (forecast?: any) => {
    this.getMeasuresByCode();
    this.configurationService
      .measuresGetList({ FBTemplateId: FB_Template.Global_Planning })
      .subscribe((res: Measure[]) => {
        this.masterMeasureList = res;
        this.measureList = res.filter(row => this.DefaultMeasures.includes(row.code) || !row.parentId);
        this.readOnlyMeasres = this.measureList.filter(
          (x) => x.templateId == 1
        );
        this.editViaUploadMeasures = this.measureList.filter(
          (x) => x.templateId == 2
        );
        this.extractParentsFromMeasures(forecast);
      });
    const params = {
      offset: 0,
      pageSize: 100000,
      active: true,
    };

    let url = `offSet=${params.offset ? params.offset : 0}&pageSize=${
      params.pageSize ? params.pageSize : 10
    }&active=true`;
    this.configurationService.ItemAccountGetAll(url).subscribe((res) => {
      this.accountList = res.data || [];
      this.GetAccountSettingList(forecast);
    });
    this.configurationService.ItemManagerGetAll(url).subscribe((res) => {
      this.itemsList = res.data || [];
      this.GetProductSettingList(forecast);
    });
    this.configurationService
      .calendarGetList({})
      .subscribe((res: Calendar[]) => {
        this.timeframeList = res;
        this.createCalendarListDropdown(forecast);
      });
    const params1 = {
      userId: this.storage.get(USER_ID),
      fbTemplateId: FB_Template.Global_Planning,
    };
    this.configurationService
      .userFBForecastGetList(params1)
      .subscribe((res: UserFbForecast[]) => {
        this.fbForecastList = res;
      });
  };
  public GetProductSettingList = (forecast?: any) => {
    this.configurationService
      .ProductSettingMappingGetList({})
      .subscribe((res) => {
        this.productSettingList = res;
        this._createProductDropdownList(res, forecast);
      });
  };

  public _createProductDropdownList = (productSettingList, forecast) => {
    const brandIds = [];
    // productSettingList = productSettingList.filter((product) =>
    //   this.itemsList.find(
    //     (el) =>
    //       el.brandId === product.brandId &&
    //       el.labelId === product.labelId &&
    //       el.genderId === product.genderId &&
    //       el.sectorId === product.sectorId &&
    //       el.categoryId === product.categoryId &&
    //       el.subCategoryId === product.subCategoryId
    //   )
    // );
    productSettingList = this.itemsList;
    productSettingList.forEach((account) => {
      if (brandIds.includes(account.brandId)) {
      } else {
        brandIds.push(account.brandId);
      }
    });
    let treeList = [];
    brandIds.forEach((brand) => {
      let brandObject = {
        brandName: productSettingList.find((acc) => acc.brandId === brand)
          ? productSettingList.find((acc) => acc.brandId === brand).brandName
          : '',
        name: productSettingList.find((acc) => acc.brandId === brand)
          ? productSettingList.find((acc) => acc.brandId === brand).brandName
          : '',
        brandId: brand,
        children: [],
        isBrand: true,
      };
      const labelIds = productSettingList
        .filter((el) => el.brandId === brand)
        .map((el) => el.labelId)
        .filter((value, index, self) => self.indexOf(value) === index);
        labelIds.forEach((labelId) => {
        let labelObject = {
          labelName: productSettingList.find(
            (acc) => acc.labelId === labelId
          )
            ? productSettingList.find(
                (acc) => acc.labelId === labelId
              ).labelName
            : '',
          name: productSettingList.find(
            (acc) => acc.labelId === labelId
          )
            ? productSettingList.find(
                (acc) => acc.labelId === labelId
              ).labelName
            : '',
          labelId: labelId,
          brandId: brand,
          children: [],
          isLabel: true,
        };
        const genderIds = productSettingList
          .filter(
            (el) => el.labelId === labelId && el.brandId === brand
          )
          .map((el) => el.genderId)
          .filter((value, index, self) => self.indexOf(value) === index);
          genderIds.forEach((genderId) => {
          let genderObject = {
            genderName: productSettingList.find(
              (acc) => acc.genderId === genderId
            )
              ? productSettingList.find(
                  (acc) => acc.genderId === genderId
                ).genderName
              : '',
            name: productSettingList.find(
              (acc) => acc.genderId === genderId
            )
              ? productSettingList.find(
                  (acc) => acc.genderId === genderId
                ).genderName
              : '',
            genderId: genderId,
            labelId: labelId,
            brandId: brand,
            children: [],
            isGender: true,
          };
          const sectorIds = productSettingList
            .filter(
              (el) =>
                el.genderId === genderId &&
                el.labelId === labelId &&
                el.brandId === brand
            )
            .map((el) => el.sectorId)
            .filter((value, index, self) => self.indexOf(value) === index);
          sectorIds.forEach((sectorId) => {
            let sectorObject = {
              sectorName: productSettingList.find(
                (acc) => acc.sectorId === sectorId
              )
                ? productSettingList.find(
                    (acc) => acc.sectorId === sectorId
                  ).sectorName
                : '',
              name: productSettingList.find(
                (acc) => acc.sectorId === sectorId
              )
                ? productSettingList.find(
                    (acc) => acc.sectorId === sectorId
                  ).sectorName
                : '',
              sectorId: sectorId,
              labelId: labelId,
              brandId: brand,
              children: [],
              isSector: true,
            };
            const categoryIds = productSettingList
            .filter(
              (el) =>
                el.sectorId === sectorId &&
                el.genderId === genderId &&
                el.labelId === labelId &&
                el.brandId === brand
            )
            .map((el) => el.categoryId)
            .filter((value, index, self) => self.indexOf(value) === index);
            categoryIds.forEach(categoryId => {
              let categoryObject = {
                categoryName: productSettingList.find(
                  (acc) => acc.categoryId === categoryId
                )
                  ? productSettingList.find(
                      (acc) => acc.categoryId === categoryId
                    ).categoryName
                  : '',
                name: productSettingList.find(
                  (acc) => acc.categoryId === categoryId
                )
                  ? productSettingList.find(
                      (acc) => acc.categoryId === categoryId
                    ).categoryName
                  : '',
                categoryId: categoryId,
                sectorId: sectorId,
                labelId: labelId,
                brandId: brand,
                children: [],
                isCategory: true,
              };

          const subCategoryIds = productSettingList
            .filter(
              (el) =>
                el.categoryId === categoryId &&
                el.sectorId === sectorId &&
                el.genderId === genderId &&
                el.labelId === labelId &&
                el.brandId === brand
            )
            .map((el) => el.subCategoryId)
            .filter((value, index, self) => self.indexOf(value) === index);

              subCategoryIds.forEach(subCategoryId => {
                
                let products = this.itemsList.filter(
                  (row) =>
                    row.categoryId === categoryId &&
                    row.sectorId === sectorId &&
                    row.genderId === genderId &&
                    row.labelId === labelId &&
                    row.brandId === brand
                );

                products = products.map((row) => ({
                  name: row.description,
                  isProduct: true,
                  ...row,
                }));

                let subCategoryObject = {
                  subCategoryName: productSettingList.find(
                    (acc) => acc.subCategoryId === subCategoryId
                  )
                    ? productSettingList.find(
                        (acc) => acc.subCategoryId === subCategoryId
                      ).subCategoryName
                    : '',
                  name: productSettingList.find(
                    (acc) => acc.subCategoryId === subCategoryId
                  )
                    ? productSettingList.find(
                        (acc) => acc.subCategoryId === subCategoryId
                      ).subCategoryName
                    : '',
                  subCategoryId: subCategoryId,
                  categoryId: categoryId,
                  sectorId: sectorId,
                  labelId: labelId,
                  brandId: brand,
                  children: products,
                  isSubCategory: true,
                };

                if (subCategoryObject.subCategoryId) {
                  categoryObject.children.push(subCategoryObject);
                }
              });

              if(categoryObject.categoryId) {
                sectorObject.children.push(categoryObject);
              }
            });

            if (sectorObject.sectorId) {
              genderObject.children.push(sectorObject);
            }
          });
          if (genderObject.genderId) {
            labelObject.children.push(genderObject);
          }
        });
        if (labelObject.labelId) {
          brandObject.children.push(labelObject);
        }
      });
      treeList.push(brandObject);
    });
    const tree = [];
    treeList.forEach((brand) => {
      let brandObject = new TreeviewItem({
        text: brand.name,
        collapsed: true,
        value: brand.brandId + 'B',
        children: brand.children.map((label) => {
          return new TreeviewItem({
            text: label.name,
            collapsed: true,
            value: label.labelId + 'L',
            children: label.children.map((gender) => {
              return new TreeviewItem({
                text: gender.name,
                collapsed: true,
                value: gender.genderId + 'G',
                children: gender.children.map((sector) => {
                  return new TreeviewItem({
                    text: sector.name,
                    collapsed: true,
                    value: sector.sectorId + 'S',
                    children: sector.children.map((category) => {
                      return new TreeviewItem({
                        text: category.name,
                        collapsed: true,
                        value: category.categoryId + 'C',
                        children: category.children.map((subCategory) => {
                          return new TreeviewItem({
                            text: subCategory.name,
                            collapsed: true,
                            value: subCategory.subCategoryId + 'SC',
                            children: subCategory.children.map((product) => {
                              return new TreeviewItem({
                                text: product.name,
                                collapsed: true,
                                value: product.itemManagerId,
                                checked:
                                  forecast && forecast.itemIds
                                    ? forecast.itemIds.includes(product.itemManagerId.toString())
                                    : false,
                              });
                            }),
                          checked: forecast && forecast.itemIds
                            ? forecast.itemIds.includes(category.subCategoryId) : false,
                          })
                        }),
                        checked:
                          forecast && forecast.itemIds
                            ? forecast.itemIds.includes(
                              category.categoryId
                              )
                            : false,
                      });
                    }),
                    checked:
                      forecast && forecast.itemIds
                        ? forecast.itemIds.includes(
                          sector.sectorId.toString()
                          )
                        : false,
                  });
                }),
                checked:
                  forecast && forecast.itemIds
                    ? forecast.itemIds.includes(
                      gender.genderId.toString()
                      )
                    : false,
              });
            }),
            checked:
              forecast && forecast.itemIds
                ? forecast.itemIds.includes(
                  label.labelId.toString()
                  )
                : false,
          });
        }),
        checked:
          forecast && forecast.itemIds
            ? forecast.itemIds.includes(brand.brandId.toString())
            : false,
      });

      tree.push(brandObject);
    });
    this.productList = tree;
    if (forecast) {
      const itemIds = forecast.itemIds;
      this._mapProductScrollingList(itemIds);
    }
  };

  public _mapProductScrollingList = (itemIds) => {
    if (itemIds) {
      let ids = itemIds.split(',');
      const items = this.itemsList.filter((row) =>
        ids.includes(row.itemManagerId.toString())
      );
      // const productSettingList = this.productSettingList.filter((product) =>
      //   items.find(
      //     (el) =>
      //       el.brandId === product.brandId &&
      //       el.productGroupId === product.productGroupId &&
      //       el.productFamilyId === product.productFamilyId &&
      //       el.parentProductId === product.parentProductId
      //   )
      // );
      const productSettingList = items;
      const selectedBrandIds = [];
      productSettingList.forEach((account) => {
        if (selectedBrandIds.includes(account.brandId)) {
        } else {
          selectedBrandIds.push(account.brandId);
        }
      });
      let treeList = [];
      selectedBrandIds.forEach((brand) => {
        let brandObject = {
          brandName: productSettingList.find((acc) => acc.brandId === brand)
            ? productSettingList.find((acc) => acc.brandId === brand).brandName
            : '',
          name: productSettingList.find((acc) => acc.brandId === brand)
            ? productSettingList.find((acc) => acc.brandId === brand).brandName
            : '',
          brandId: brand,
          children: [],
          isBrand: true,
        };
        const labelIds = productSettingList
          .filter((el) => el.brandId === brand)
          .map((el) => el.labelId)
          .filter((value, index, self) => self.indexOf(value) === index);
          labelIds.forEach((labelId) => {
          let labelObject = {
            labelName: productSettingList.find(
              (acc) => acc.labelId === labelId
            )
              ? productSettingList.find(
                  (acc) => acc.labelId === labelId
                ).labelName
              : '',
            name: productSettingList.find(
              (acc) => acc.labelId === labelId
            )
              ? productSettingList.find(
                  (acc) => acc.labelId === labelId
                ).labelName
              : '',
            labelId: labelId,
            brandId: brand,
            children: [],
            isLabel: true,
          };
          const genderIds = productSettingList
            .filter(
              (el) => el.labelId === labelId && el.brandId === brand
            )
            .map((el) => el.genderId)
            .filter((value, index, self) => self.indexOf(value) === index);
            genderIds.forEach((genderId) => {
            let genderObject = {
              genderName: productSettingList.find(
                (acc) => acc.genderId === genderId
              )
                ? productSettingList.find(
                    (acc) => acc.genderId === genderId
                  ).genderName
                : '',
              name: productSettingList.find(
                (acc) => acc.genderId === genderId
              )
                ? productSettingList.find(
                    (acc) => acc.genderId === genderId
                  ).genderName
                : '',
              genderId: genderId,
              labelId: labelId,
              brandId: brand,
              children: [],
              isGender: true,
            };
            const sectorIds = productSettingList
              .filter(
                (el) =>
                  el.genderId === genderId &&
                  el.labelId === labelId &&
                  el.brandId === brand
              )
              .map((el) => el.sectorId)
              .filter((value, index, self) => self.indexOf(value) === index);
            sectorIds.forEach((sectorId) => {
              let sectorObject = {
                sectorName: productSettingList.find(
                  (acc) => acc.sectorId === sectorId
                )
                  ? productSettingList.find(
                      (acc) => acc.sectorId === sectorId
                    ).sectorName
                  : '',
                name: productSettingList.find(
                  (acc) => acc.sectorId === sectorId
                )
                  ? productSettingList.find(
                      (acc) => acc.sectorId === sectorId
                    ).sectorName
                  : '',
                sectorId: sectorId,
                labelId: labelId,
                brandId: brand,
                children: [],
                isSector: true,
              };
              const categoryIds = productSettingList
              .filter(
                (el) =>
                  el.sectorId === sectorId &&
                  el.genderId === genderId &&
                  el.labelId === labelId &&
                  el.brandId === brand
              )
              .map((el) => el.categoryId)
              .filter((value, index, self) => self.indexOf(value) === index);
              categoryIds.forEach(categoryId => {
                let categoryObject = {
                  categoryName: productSettingList.find(
                    (acc) => acc.categoryId === categoryId
                  )
                    ? productSettingList.find(
                        (acc) => acc.categoryId === categoryId
                      ).categoryName
                    : '',
                  name: productSettingList.find(
                    (acc) => acc.categoryId === categoryId
                  )
                    ? productSettingList.find(
                        (acc) => acc.categoryId === categoryId
                      ).categoryName
                    : '',
                  categoryId: categoryId,
                  sectorId: sectorId,
                  labelId: labelId,
                  brandId: brand,
                  children: [],
                  isCategory: true,
                };
  
            const subCategoryIds = productSettingList
              .filter(
                (el) =>
                  el.categoryId === categoryId &&
                  el.sectorId === sectorId &&
                  el.genderId === genderId &&
                  el.labelId === labelId &&
                  el.brandId === brand
              )
              .map((el) => el.subCategoryId)
              .filter((value, index, self) => self.indexOf(value) === index);
  
                subCategoryIds.forEach(subCategoryId => {
                  
                  let products = this.itemsList.filter(
                    (row) =>
                      row.categoryId === categoryId &&
                      row.sectorId === sectorId &&
                      row.genderId === genderId &&
                      row.labelId === labelId &&
                      row.brandId === brand
                  );
  
                  products = products.map((row) => ({
                    name: row.description,
                    isProduct: true,
                    ...row,
                  }));
  
                  let subCategoryObject = {
                    subCategoryName: productSettingList.find(
                      (acc) => acc.subCategoryId === subCategoryId
                    )
                      ? productSettingList.find(
                          (acc) => acc.subCategoryId === subCategoryId
                        ).subCategoryName
                      : '',
                    name: productSettingList.find(
                      (acc) => acc.subCategoryId === subCategoryId
                    )
                      ? productSettingList.find(
                          (acc) => acc.subCategoryId === subCategoryId
                        ).subCategoryName
                      : '',
                    subCategoryId: subCategoryId,
                    categoryId: categoryId,
                    sectorId: sectorId,
                    labelId: labelId,
                    brandId: brand,
                    children: products,
                    isSubCategory: true,
                  };
  
                  if (subCategoryObject.subCategoryId) {
                    categoryObject.children.push(subCategoryObject);
                  }
                });
  
                if(categoryObject.categoryId) {
                  sectorObject.children.push(categoryObject);
                }
              });
  
              if (sectorObject.sectorId) {
                genderObject.children.push(sectorObject);
              }
            });
            if (genderObject.genderId) {
              labelObject.children.push(genderObject);
            }
          });
          if (labelObject.labelId) {
            brandObject.children.push(labelObject);
          }
        });
        treeList.push(brandObject);
      });
      const tree = [];
      treeList.forEach((brand) => {
        let brandObject = new TreeviewItem({
          text: brand.name,
          collapsed: true,
          value: brand.brandId + 'B',
          children: brand.children.map((label) => {
            return new TreeviewItem({
              text: label.name,
              collapsed: true,
              value: label.labelId + 'L',
              children: label.children.map((gender) => {
                return new TreeviewItem({
                  text: gender.name,
                  collapsed: true,
                  value: gender.genderId + 'G',
                  children: gender.children.map((sector) => {
                    return new TreeviewItem({
                      text: sector.name,
                      collapsed: true,
                      value: sector.sectorId + 'S',
                      children: sector.children.map((category) => {
                        return new TreeviewItem({
                          text: category.name,
                          collapsed: true,
                          value: category.categoryId + 'C',
                          children: category.children.map((subCategory) => {
                            return new TreeviewItem({
                              text: subCategory.name,
                              collapsed: true,
                              value: subCategory.subCategoryId + 'SC',
                              children: subCategory.children.map((product) => {
                                return new TreeviewItem({
                                  text: product.name,
                                  collapsed: true,
                                  value: product.itemManagerId,
                                  checked: false
                                });
                              }),
                            checked: false
                            })
                          }),
                          checked: false
                        });
                      }),
                      checked: false
                    });
                  }),
                  checked: false
                });
              }),
              checked: false
            });
          }),
          checked: false
        });
  
        tree.push(brandObject);
      });
      console.log(tree);
      this.productTree = tree;
    }
  };
  
  public createProductDropdownList = (productSettingList, forecast) => {
    const brandIds = [];
    productSettingList = productSettingList.filter((product) =>
      this.itemsList.find(
        (el) =>
          el.brandId === product.brandId &&
          el.productGroupId === product.productGroupId &&
          el.productFamilyId === product.productFamilyId &&
          el.parentProductId === product.parentProductId
      )
    );
    productSettingList.forEach((account) => {
      if (brandIds.includes(account.brandId)) {
      } else {
        brandIds.push(account.brandId);
      }
    });
    let treeList = [];
    brandIds.forEach((brand) => {
      let brandObject = {
        brandName: productSettingList.find((acc) => acc.brandId === brand)
          ? productSettingList.find((acc) => acc.brandId === brand).brandName
          : '',
        name: productSettingList.find((acc) => acc.brandId === brand)
          ? productSettingList.find((acc) => acc.brandId === brand).brandName
          : '',
        brandId: brand,
        children: [],
        isBrand: true,
      };
      const productGroupIds = productSettingList
        .filter((el) => el.brandId === brand)
        .map((el) => el.productGroupId)
        .filter((value, index, self) => self.indexOf(value) === index);
      productGroupIds.forEach((productGroupId) => {
        let productGroupObject = {
          productGroupName: productSettingList.find(
            (acc) => acc.productGroupId === productGroupId
          )
            ? productSettingList.find(
                (acc) => acc.productGroupId === productGroupId
              ).productGroupName
            : '',
          name: productSettingList.find(
            (acc) => acc.productGroupId === productGroupId
          )
            ? productSettingList.find(
                (acc) => acc.productGroupId === productGroupId
              ).productGroupName
            : '',
          productGroupId: productGroupId,
          brandId: brand,
          children: [],
          isProductGroup: true,
        };
        const productFamilyIds = productSettingList
          .filter(
            (el) => el.productGroupId === productGroupId && el.brandId === brand
          )
          .map((el) => el.productFamilyId)
          .filter((value, index, self) => self.indexOf(value) === index);
        productFamilyIds.forEach((productFamilyId) => {
          let productFamilyObject = {
            productFamilyName: productSettingList.find(
              (acc) => acc.productFamilyId === productFamilyId
            )
              ? productSettingList.find(
                  (acc) => acc.productFamilyId === productFamilyId
                ).productFamilyName
              : '',
            name: productSettingList.find(
              (acc) => acc.productFamilyId === productFamilyId
            )
              ? productSettingList.find(
                  (acc) => acc.productFamilyId === productFamilyId
                ).productFamilyName
              : '',
            productFamilyId: productFamilyId,
            productGroupId: productGroupId,
            brandId: brand,
            children: [],
            isProductFamily: true,
          };
          const parentProductIds = productSettingList
            .filter(
              (el) =>
                el.productFamilyId === productFamilyId &&
                el.productGroupId === productGroupId &&
                el.brandId === brand
            )
            .map((el) => el.parentProductId)
            .filter((value, index, self) => self.indexOf(value) === index);
          parentProductIds.forEach((parentProductId) => {
            let products = this.itemsList.filter(
              (row) =>
                row.parentProductId === parentProductId &&
                row.productFamilyId === productFamilyId &&
                row.productGroupId === productGroupId &&
                row.brandId === brand
            );
            products = products.map((row) => ({
              name: row.description,
              isProduct: true,
              ...row,
            }));
            let parentProductObject = {
              parentProductName: productSettingList.find(
                (acc) => acc.parentProductId === parentProductId
              )
                ? productSettingList.find(
                    (acc) => acc.parentProductId === parentProductId
                  ).parentProductName
                : '',
              name: productSettingList.find(
                (acc) => acc.parentProductId === parentProductId
              )
                ? productSettingList.find(
                    (acc) => acc.parentProductId === parentProductId
                  ).parentProductName
                : '',
              parentProductId: parentProductId,
              productGroupId: productGroupId,
              brandId: brand,
              children: products,
              isParentProduct: true,
            };
            if (parentProductObject.parentProductId) {
              productFamilyObject.children.push(parentProductObject);
            }
          });
          if (productFamilyObject.productFamilyId) {
            productGroupObject.children.push(productFamilyObject);
          }
        });
        if (productGroupObject.productGroupId) {
          brandObject.children.push(productGroupObject);
        }
      });
      treeList.push(brandObject);
    });
    const tree = [];
    treeList.forEach((brand) => {
      let brandObject = new TreeviewItem({
        text: brand.name,
        collapsed: true,
        value: brand.brandId + 'B',
        children: brand.children.map((productGroup) => {
          return new TreeviewItem({
            text: productGroup.name,
            collapsed: true,
            value: productGroup.productGroupId + 'PG',
            children: productGroup.children.map((productFamily) => {
              return new TreeviewItem({
                text: productFamily.name,
                collapsed: true,
                value: productFamily.productFamilyId + 'PF',
                children: productFamily.children.map((parentProduct) => {
                  return new TreeviewItem({
                    text: parentProduct.name,
                    collapsed: true,
                    value: parentProduct.parentProductId + 'PP',
                    checked:
                      forecast && forecast.itemIds
                        ? forecast.itemIds.includes(
                            parentProduct.parentProductId.toString()
                          )
                        : false,
                    children: parentProduct.children.map((product) => {
                      return new TreeviewItem({
                        text: product.name,
                        collapsed: true,
                        value: product.itemManagerId,
                        checked:
                          forecast && forecast.itemIds
                            ? forecast.itemIds.includes(
                                product.itemManagerId.toString()
                              )
                            : false,
                      });
                    }),
                  });
                }),
                checked:
                  forecast && forecast.itemIds
                    ? forecast.itemIds.includes(
                        productFamily.productFamilyId.toString()
                      )
                    : false,
              });
            }),
            checked:
              forecast && forecast.itemIds
                ? forecast.itemIds.includes(
                    productGroup.productGroupId.toString()
                  )
                : false,
          });
        }),
        checked:
          forecast && forecast.itemIds
            ? forecast.itemIds.includes(brand.brandId.toString())
            : false,
      });

      tree.push(brandObject);
    });
    this.productList = tree;
    if (forecast) {
      const itemIds = forecast.itemIds;
      this.mapProductScrollingList(itemIds);
    }
  };
  public mapProductScrollingList = (itemIds) => {
    if (itemIds) {
      let ids = itemIds.split(',');
      const items = this.itemsList.filter((row) =>
        ids.includes(row.itemManagerId.toString())
      );
      const productSettingList = this.productSettingList.filter((product) =>
        items.find(
          (el) =>
            el.brandId === product.brandId &&
            el.productGroupId === product.productGroupId &&
            el.productFamilyId === product.productFamilyId &&
            el.parentProductId === product.parentProductId
        )
      );
      const selectedBrandIds = [];
      productSettingList.forEach((account) => {
        if (selectedBrandIds.includes(account.brandId)) {
        } else {
          selectedBrandIds.push(account.brandId);
        }
      });
      let treeList = [];
      selectedBrandIds.forEach((brand) => {
        let brandObject = {
          brandName: productSettingList.find((acc) => acc.brandId === brand)
            ? productSettingList.find((acc) => acc.brandId === brand).brandName
            : '',
          name: productSettingList.find((acc) => acc.brandId === brand)
            ? productSettingList.find((acc) => acc.brandId === brand).brandName
            : '',
          brandId: brand,
          children: [],
          isBrand: true,
        };
        const productGroupIds = productSettingList
          .filter((el) => el.brandId === brand)
          .map((el) => el.productGroupId)
          .filter((value, index, self) => self.indexOf(value) === index);
        productGroupIds.forEach((productGroupId) => {
          let productGroupObject = {
            productGroupName: productSettingList.find(
              (acc) => acc.productGroupId === productGroupId
            )
              ? productSettingList.find(
                  (acc) => acc.productGroupId === productGroupId
                ).productGroupName
              : '',
            name: productSettingList.find(
              (acc) => acc.productGroupId === productGroupId
            )
              ? productSettingList.find(
                  (acc) => acc.productGroupId === productGroupId
                ).productGroupName
              : '',
            productGroupId: productGroupId,
            brandId: brand,
            children: [],
            isProductGroup: true,
          };
          const productFamilyIds = productSettingList
            .filter(
              (el) =>
                el.productGroupId === productGroupId && el.brandId === brand
            )
            .map((el) => el.productFamilyId)
            .filter((value, index, self) => self.indexOf(value) === index);
          productFamilyIds.forEach((productFamilyId) => {
            let productFamilyObject = {
              productFamilyName: productSettingList.find(
                (acc) => acc.productFamilyId === productFamilyId
              )
                ? productSettingList.find(
                    (acc) => acc.productFamilyId === productFamilyId
                  ).productFamilyName
                : '',
              name: productSettingList.find(
                (acc) => acc.productFamilyId === productFamilyId
              )
                ? productSettingList.find(
                    (acc) => acc.productFamilyId === productFamilyId
                  ).productFamilyName
                : '',
              productFamilyId: productFamilyId,
              productGroupId: productGroupId,
              brandId: brand,
              children: [],
              isProductFamily: true,
            };
            const parentProductIds = productSettingList
              .filter(
                (el) =>
                  el.productFamilyId === productFamilyId &&
                  el.productGroupId === productGroupId &&
                  el.brandId === brand
              )
              .map((el) => el.parentProductId)
              .filter((value, index, self) => self.indexOf(value) === index);
            parentProductIds.forEach((parentProductId) => {
              let products = items.filter(
                (row) =>
                  row.parentProductId === parentProductId &&
                  row.productFamilyId === productFamilyId &&
                  row.productGroupId === productGroupId &&
                  row.brandId === brand
              );
              products = products.map((row) => ({
                name: row.description,
                isProduct: true,
                ...row,
              }));
              let parentProductObject = {
                parentProductName: productSettingList.find(
                  (acc) => acc.parentProductId === parentProductId
                )
                  ? productSettingList.find(
                      (acc) => acc.parentProductId === parentProductId
                    ).parentProductName
                  : '',
                name: productSettingList.find(
                  (acc) => acc.parentProductId === parentProductId
                )
                  ? productSettingList.find(
                      (acc) => acc.parentProductId === parentProductId
                    ).parentProductName
                  : '',
                parentProductId: parentProductId,
                productGroupId: productGroupId,
                brandId: brand,
                children: products,
                isParentProduct: true,
              };
              if (parentProductObject.parentProductId) {
                productFamilyObject.children.push(parentProductObject);
              }
            });
            if (productFamilyObject.productFamilyId) {
              productGroupObject.children.push(productFamilyObject);
            }
          });
          if (productGroupObject.productGroupId) {
            brandObject.children.push(productGroupObject);
          }
        });
        treeList.push(brandObject);
      });
      const tree = [];
      treeList.forEach((brand) => {
        let brandObject = new TreeviewItem({
          text: brand.name,
          value: brand.brandId + 'B',
          children: brand.children.map((productGroup) => {
            return new TreeviewItem({
              text: productGroup.name,
              value: productGroup.productGroupId + 'PG',
              children: productGroup.children.map((productFamily) => {
                return new TreeviewItem({
                  text: productFamily.name,
                  value: productFamily.productFamilyId + 'PF',
                  children: productFamily.children.map((parentProduct) => {
                    return new TreeviewItem({
                      text: parentProduct.name,
                      value: parentProduct.parentProductId + 'PP',
                      checked: false,
                      children: parentProduct.children.map((product) => {
                        return new TreeviewItem({
                          text: product.name,
                          value: product.itemManagerId,
                          checked: false,
                        });
                      }),
                    });
                  }),
                  checked: false,
                });
              }),
              checked: false,
            });
          }),
          checked: false,
        });
        tree.push(brandObject);
      });
      console.log(tree);
      this.productTree = tree;
    }
  };
  public GetAccountSettingList = (forecast?: any) => {
    this.configurationService
      .AccountSettingMappingGetList({})
      .subscribe((res) => {
        this.channelSettingList = res;
        this.createChannelDropdownList(res, forecast);
      });
  }
  // public createProductDropdownList = (productSettingList, forecast) => {
  //   this.productList = CreateItemTree(productSettingList, this.itemsList, forecast)
  //   if (forecast) {
  //     const itemIds = forecast.itemIds;
  //     this.mapProductScrollingList(itemIds);
  //   }
  // };
  // public mapProductScrollingList = (itemIds) => {
  //   this.productTree = CreateProductScrollingList(itemIds, this.itemsList, this.productSettingList)
  // };
  public createChannelDropdownList = (accoutSettingList, forecast) => {
    this.channelDropdownList = CreateAccountTree(accoutSettingList,this.accountList, forecast);
    if (forecast && this.channelDropdownList.length > 0) {
      let channelIds = forecast.channelIds;
      this.mapChannelScrollingList(channelIds);
    }
  };
  public mapChannelScrollingList = (channelIds) => {
    this.channelTree = CreateAccountScrollingList(channelIds, this.accountList, this.channelSettingList)
  };
  public addItemNames = () => {
    let leafNodes = this.getLeafNodes(this.productTree);
    leafNodes.forEach((element) => {
      if (leafNodes.length > 1) {
        this.productValue += element.text + ', ';
      } else {
        this.productValue = element.text;
      }
    });
  };
  public addChannelNames = () => {
    let leafNodes = this.getLeafNodes(this.channelTree);
    leafNodes.forEach((element) => {
      if (leafNodes.length > 1) {
        this.channelValue += element.text + ', ';
      } else {
        this.channelValue = element.text;
      }
    });
  };
  public addCalendarNames = () => {
    let leafNodes = this.getLeafNodes(this.restoreCalendarList);
    leafNodes.forEach((element) => {
      if (element.internalChecked) {
        if (leafNodes.length > 1) {
          this.calanderValue += element.text + ', ';
        } else {
          this.calanderValue = element.text;
        }
      }
    });
  };
  public createCalendarListDropdown = (forecast?: any) => {
    this.calendarListDropdown = CreateCalendarList(
      this.calendarListDropdown,
      this.timeframeList,
      forecast
    );
  };
  public createCalendarListDropdownWithWeeks = (forecast?: any) => {
    this.timeFrameListWithWeeks = CreateCalendarListWithWeeks(
      this.timeframeList,
      this.timeFrameListWithWeeks,
      forecast
    );
    this.timeFrameListWithWeeksUPD = this.timeFrameListWithWeeks;

    const _timeframeList = this.timeframeList.filter((item) =>
      (forecast.calendarIds as string).includes(item.calendarId.toString())
    );
    const calendarWithoutWeeks = CreateCalendarListWithoutWeeks(
      _timeframeList,
      this.timeFrameListWithWeeks,
      forecast
    );
    this.restoreCalendarList = calendarWithoutWeeks;
  };
  getLeafNodes(nodes, result = []) {
    for (var i = 0, length = nodes.length; i < length; i++) {
      if (!nodes[i].children || nodes[i].children.length === 0) {
        result.push(nodes[i]);
      } else {
        result = this.getLeafNodes(nodes[i].children, result);
      }
    }
    return result;
  }
  public extractParentsFromMeasures = (forecast?: any) => {
    const groupList = this.measureList.filter((measure) => !measure.parentId);
    this.leftGroupList = groupList.map((row) => ({
      ...row,
      rowspan: this.measureList.filter(
        (measure) => measure.parentId === row.measureId
      ).length,
    }));
    this.measureList = this.measureList
      .filter((row) => row.parentId)
      .map((row) => ({ ...row, checked: true }))
      .sort((a, b) => a.sort - b.sort);
  };
  openFullScreen = (id: string) => {
    const domGrid = document.getElementById(id) as any;
    if (domGrid) {
      domGrid.webkitRequestFullscreen();
    }
  };
  openActionDialog(restoreSession: boolean): void {
    // tslint:disable-next-line: no-use-before-declare
    const sessionLabel = restoreSession
      ? 'Your last session ended abruptly, would you like to return to where you left off?'
      : 'Would you like to open a saved planner or build new?';
    this.dialog
      .open(ForecastConfirmationModalComponent, {
        width: '848px',
        panelClass: ['fb-dialog-wrapper2', 'fb-dialog-wrapper3'],
        data: {
          isRestoreSession: restoreSession,
          label: sessionLabel,
          isOnLoad: restoreSession ? false : true,
          isSaveAndCommit: false,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((command) => {
        if (command && command.isCanceled) {
          this.closeScreen();
        } else if (command && command.openSaved) {
          this.isOpenSaved = true;
          this.openSavedVersionDialog();
        } else if (command && command.restoreSession) {
          this.restoreSession();
          this.isRestoreSession = true;
          this.hideScreen = false;
        } else {
          this.hideScreen = false;
          this.deleteFbForecast();
          this.getDefaultDropdowns();
          this.isBuidlNew = true;
          this.isShowTreeDropDowns = true;
        }
      });
  }
  public deleteFbForecast() {
    this.isDeleted = false;
    const model = {
      userId: this.storage.get(USER_ID),
      userFBForecastId: 0,
      fbTemplateId: FB_Template.Global_Planning,
    }
    this.configurationService.fbForecastDefaultDelete(model).subscribe(res => {
      this.isDeleted = true;
    }, error => {
      this.isDeleted = true;
    })
  }
  public openSavedPlanner = async (version) => {
    const model = {
      userId: this.storage.get(USER_ID),
      userFBForecastId: version.userFBForecastId,
      fbTemplateId: FB_Template.Global_Planning,
    };
    this.userFBForecastId = version.userFBForecastId;
    this.fbForecast = await this.configurationService
      .GetUserSession(model)
      .toPromise()
      .catch((error) => throwError(error));
    this.versionTypeId = 3;
    this.userFBForecastId = version.userFBForecastId;
    this.getDefaultDropdowns(this.fbForecast);
  };
  public restoreSession = async () => {
    this.getDefaultDropdowns(this.fbForecast);
  };
  public openSavedVersionDialog = () => {
    const dialogRef = this.dialog.open(ForecastSavedVersionsComponent, {
      width: '848px',
      panelClass: 'fb-dialog-wrapper2',
      data: { isGlobal: true },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.version) {
        this.openSavedPlanner(res.version);
        this.plannerName = res.version.name;
        this.userFBForecastId = res.version.userFBForecastId;
        const model = {
          ...this.data,
          version: res.version,
        };
        this.configurationService.savedPlannerOpened.next(model);
        this.hideScreen = false;
      }
    });
  };
  public openDeletePlannerDialog = () => {
    const dialogRef = this.dialog.open(ForecastDeletePlannerModalComponent, {
      width: '848px',
      panelClass: 'fb-dialog-wrapper2',
      data: {},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.version) {
        this.openSavedPlanner(res.version);
        this.configurationService.savedPlannerOpened.next(res.version);
        this.hideScreen = false;
      }
    });
  };
  public closeScreen = () => {
    this.configurationService.removeMenu.next(this.data);
  };
  setValue = (selectedDropdown) => {
    switch (selectedDropdown.type) {
      case 'Item': {
        this.itemIds = selectedDropdown.value;
        break;
      }
      case 'Channel': {
        this.channelIds = selectedDropdown.value;
        this.selectedChannelIds = selectedDropdown.value;
        break;
      }
      case 'Timeframe': {
        this.calendarIds = selectedDropdown.value;
        break;
      }
      case 'TimeframeWithWeeksUDP': {
        this.calendarIdsUDP = selectedDropdown.value;
        break;
      }
      case 'TimeframeWithWeeksScenario': {
        this.calendarIdsScenario = selectedDropdown.value;
        break;
      }
    }
    this.channelIds =
      this.channelIds.filter((el) => typeof el === 'number') || [];
    this.itemIds = this.itemIds.filter((el) => typeof el === 'number') || [];
    this.dropdownObjects = {
      itemIds: this.itemIds || [],
      channelIds: this.channelIds,
      calendarIds: this.calendarIds || [],
    };
    this.updatedDropdownObject = this.dropdownObjects;
    if (this.isOpenSaved || this.isRestoreSession) {
      this.mapGridList(true);
    }
  };
  setDataManagerValues(selectedDropdown) {
    switch (selectedDropdown.type) {
      case 'TimeframeWithWeeksUDP': {
        this.calendarIdsUDP = selectedDropdown.value;
        break;
      }
      case 'TimeframeWithWeeksScenario': {
        this.calendarIdsScenario = selectedDropdown.value;
        break;
      }
    }
  }
  setRestoredValue = (selectedDropdown) => {
    switch (selectedDropdown.type) {
      case 'Item': {
        this.itemIds = selectedDropdown.value;
        break;
      }
      case 'Channel': {
        this.channelIds = selectedDropdown.value;
        this.selectedChannelIds = selectedDropdown.value;
        break;
      }
      case 'Timeframe': {
        this.calendarIds = selectedDropdown.value;
        break;
      }
    }
    this.channelIds =
      this.channelIds.filter((el) => typeof el === 'number') || [];
    this.itemIds = this.itemIds.filter((el) => typeof el === 'number') || [];
    this.dropdownObjects = {
      itemIds: this.itemIds || [],
      channelIds: this.channelIds,
      calendarIds: this.calendarIds || [],
    };
  };
  public mapGridList = async (isOnload) => {
    this.gridMapped = true;
    if ((this.isOpenSaved || this.isRestoreSession) && !isOnload) {
      this.isOpenSaved = false;
      this.isRestoreSession = false;
    }
    this.spinner.show();
    if (this.isBuidlNew && this.isShowTreeDropDowns) {
      if (
        this.fbForecast &&
        this.fbForecast.itemIds &&
        this.fbForecast.itemIds.match(/^[a-z0-9]+$/i)
      ) {
        this.fbForecast.itemIds = this.itemIds;
      }
      this.isShowTreeDropDowns = false;
      this.mapChannelScrollingList(this.channelIds.toString());
      this.mapProductScrollingList(this.itemIds.toString());
    }
    this.mapChannelScrollingList(this.channelIds.toString());
    this._mapProductScrollingList(this.itemIds.toString());
    this.forecastList = [];
    this.createGridList();
  };
  public createGridList = async (event?) => {
    if (event && event.itemIds && event.channelIds) {
      this.forecastList = [];
      event.isScrolling = true;
      this.traverseSelectedOptions(event);
    } else {
      if (
        this.dropdownObjects.itemIds.length &&
        this.dropdownObjects.channelIds.length &&
        this.dropdownObjects.calendarIds.length
      ) {
        this.traverseSelectedOptions(this.dropdownObjects);
      }
    }
  };
  public traverseSelectedOptions = (event) => {
    const calendarIds = [];
    if (event.calendarIds) {
      event.calendarIds.flat().forEach((calendar) => {
        calendarIds.push(calendar);
      });
    }
    event.calendarIds = calendarIds;
    if (this.isRestoreSession || this.isOpenSaved) {
      const model = {
        userId: this.storage.get(USER_ID),
        userFBForecastId: this.userFBForecastId || 0,
        fbTemplateId: FB_Template.Global_Planning,
        RestoreSession: true,
        VersionTypeId: this.versionTypeId,
        itemIds: event.itemIds.toString(),
        channelIds: event.channelIds?.toString(),
        calendarIds: event.calendarIds.flat().toString(),
      };
      this.configurationService
        .fbForecastGetPerformance(model)
        .subscribe((res) => {
          this.fbForecast = res;
          this.createCalendarListDropdownWithWeeks(this.fbForecast);
          this.mapWeeksList();
          this.addItemNames();
          this.addChannelNames();
          this.addCalendarNames();
          this.spinner.hide();
        });
    } else {
      const model = {
        userId: this.storage.get(USER_ID),
        userFBForecastId: 0,
        itemIds: event.itemIds.toString(),
        channelIds: event.channelIds?.toString(),
        calendarIds: event.calendarIds.flat().toString(),
        fbTemplateId: FB_Template.Global_Planning,
      };
      this.configurationService
        .FbForecastDefaultAdd(model)
        .subscribe((res) => {});
      this.spinner.show();
      this.configurationService
        .fbForecastGetPerformance(model)
        .subscribe((res) => {
          this.fbForecast = res;
          this.createCalendarListDropdownWithWeeks(this.fbForecast);
          this.mapWeeksList();
          this.spinner.hide();
        });
    }
  };
  public mapWeeksList() {
    this.yearListGrid = new Array();
    this.yearListGrid = this.fbForecast.weeks.map((row) => {
      Object.keys(row['measures']).forEach((measure) => {
        if (measure.indexOf('Comment') < 0) {
          if (measure.endsWith('Pct')) {
            if (!row['measures'][measure].endsWith('%')) {
              row['measures'][measure] = Math.round(
                Number(row['measures'][measure])
              );
              row['measures'][measure] = row['measures'][measure] + '%';
            }
          } else {
            row['measures'][measure] = Math.round(
              Number(row['measures'][measure])
            );
          }
        }
        row['measures'][measure] = addCommas(row['measures'], measure);
      });
      this.measureKeys = Object.keys(row['measures']);
      return {
        ...row,
        title: new Date(row.weekStartDate).toLocaleDateString(),
      };
    });
    this.restoredProductList = [...this.productTree];
    // this.restoreCalendarList = [...this.calendarListDropdown];
    this.restoredChannelList = [...this.channelTree];
  }
  openMesuresDialog() {
    this.dialog
      .open(GlobalDemandPlannerMeasuresNewComponent, {
        width: '1200px',
        panelClass: 'fb-dialog-wrapper',
        data: { measureList: this.measureList },
      })
      .afterClosed()
      .subscribe((command) => {
        if (command) {
          console.log(command);
        }
      });
  }
  save() {
    this.dialog
      .open(ForecastConfirmationModalComponent, {
        width: '848px',
        panelClass: ['fb-dialog-wrapper2', 'fb-dialog-wrapper3'],
        data: {
          label: 'How would you like to proceed?',
          isOnLoad: false,
          isSaveAndCommit: true,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((command) => {
        if (command) {
          if (command.isSave) {
            if (!this.isBuidlNew && !this.isRestoreSession) {
              const model = {
                itemIds: this.itemIds.toString(),
                channelIds: this.channelIds.toString(),
                calendarIds: this.calendarIds.toString(),
                userId: this.storage.get(USER_ID),
                name: this.plannerName,
                fbTemplateId: FB_Template.Global_Planning,
                userFBForecastId: this.userFBForecastId,
                active: true,
              };
              this.spinner.show();
              this.configurationService
                .userFBForecastUpdate(model)
                .subscribe((resp) => {
                  this.spinner.hide();
                });
            } else {
              const params = {
                userId: this.storage.get(USER_ID),
                fbTemplateId: FB_Template.Global_Planning,
              };
              this.configurationService
                .userFBForecastGetList(params)
                .subscribe((res) => {
                  if (res.length >= this.maxPlannerCount) {
                    this.dialog
                      .open(ForecastDeletePlannerModalComponent, {
                        width: '848px',
                        panelClass: 'fb-dialog-wrapper2',
                        data: { versionList: res, isGlobal: true },
                        disableClose: true,
                      })
                      .afterClosed()
                      .subscribe((command) => {
                        if (command && command.version) {
                          let versions = command.version;
                          this.dialog
                            .open(ForecastConfirmationModalComponent, {
                              width: '848px',
                              panelClass: 'fb-dialog-wrapper2',
                              data: {
                                label:
                                  'Are you sure you want to delete this planner?',
                                isOnLoad: false,
                                isDeletePlanner: true,
                              },
                              disableClose: true,
                            })
                            .afterClosed()
                            .subscribe((command) => {
                              if (command.isDelete) {
                                let model = {
                                  versionTypeId: null,
                                  userFBForecastIds: '',
                                  fbTemplateId: FB_Template.Global_Planning,
                                };
                                model.versionTypeId = 3;
                                for (var i = 0; i < versions.length; i++) {
                                  model.userFBForecastIds =
                                    model.userFBForecastIds +
                                    versions[i].userFBForecastId +
                                    ',';
                                }
                                model.userFBForecastIds =
                                  model.userFBForecastIds.slice(0, -1);
                                this.configurationService
                                  .userFBForecastDeleteByVersionID(model)
                                  .subscribe((resp) => {
                                    this.saveForecast();
                                  });
                              }
                            });
                        }
                      });
                  } else {
                    this.saveForecast();
                  }
                });
            }
          } else if (command.isCommit) {
            this.commitForecastPlanner();
          } else if (command.isSaveCommit) {
            this.saveForecast(command.isSaveCommit);
          }

          //
        }
      });
  }
  refreshMeasure() {
    this.dialog
      .open(ForecastConfirmationModalComponent, {
        width: '848px',
        panelClass: 'fb-dialog-wrapper2',
        data: {
          label:
            'Are you sure you want to refresh these measures? Your current data will be lost.',
          isOnLoad: false,
          refreshMeasure: true,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((command) => {
        if (command) {
          this.refreshForecastMeasures();
        }
      });
  }
  public refreshForecastMeasures = () => {
    const model = {
      itemIds: this.dropdownObjects.itemIds.toString(),
      channelIds: this.dropdownObjects.channelIds.toString(),
      calendarIds: this.dropdownObjects.calendarIds.toString(),
      userId: this.storage.get(USER_ID),
      measureIds: this.refreshMeasureIds.toString(),
      userFBForecastId: this.userFBForecastId,
      fbTemplateId: FB_Template.Global_Planning,
      versionTypeId: this.versionTypeId,
      fbForecastSelectedId: this.fbForecast ? this.fbForecast.fbForecastSelectedId: '',
    };
    const measureCodes = this.measureList
      .filter((row) => this.refreshMeasureIds.includes(row.measureId))
      .map((el) => el.code);
      this.spinner.show();
    this.configurationService
      .DefaultAddRefresh(model)
      .subscribe((res) => {
        this.traverseSelectedOptions(this.updatedDropdownObject);

      }, error => {
        this.spinner.hide();
      });
  };
  public saveForecast = (saveCommit?) => {
    if (saveCommit && this.userFBForecastId) {
      const model = {
        itemIds: this.itemIds.toString(),
        channelIds: this.channelIds.toString(),
        calendarIds: this.calendarIds.toString(),
        userId: this.storage.get(USER_ID),
        name: this.plannerName,
        fbTemplateId: FB_Template.Global_Planning,
        userFBForecastId: this.userFBForecastId,
        active: true,
      };
      this.spinner.show();
      this.configurationService
        .userFBForecastUpdate(model)
        .subscribe((resp) => {
          this.spinner.hide();
          this.commitForecastPlanner();
        });
    } else {
      this.dialog
        .open(ForecastConfirmationModalComponent, {
          width: '848px',
          panelClass: 'fb-dialog-wrapper2',
          data: {
            label: 'What would you like to name your planner?',
            isOnLoad: false,
            changeNameConfirmation: true,
            isInputRequired: true,
            inputLabel: 'Enter name',
          },
          disableClose: true,
        })
        .afterClosed()
        .subscribe((command) => {
          if (command) {
            this.plannerName = command.name;
            this.savePlanner(saveCommit);
          }
        });
    }
  };
  public savePlanner = (saveCommit?) => {
    const params = {
      userId: this.storage.get(USER_ID),
      fbTemplateId: FB_Template.Global_Planning,
    };
    this.configurationService.userFBForecastGetList(params).subscribe((res) => {
      if (res.find((x) => x.name == this.plannerName)) {
        this.dialog.open(ForecastConfirmationModalComponent, {
          width: '848px',
          panelClass: 'fb-dialog-wrapper2',
          data: {
            label:
              'Planner with this name already exists. Please update name and try again',
            isOnLoad: false,
            confirmation: true,
          },
          disableClose: true,
        });
      } else {
        const model = {
          itemIds: this.itemIds.toString(),
          channelIds: this.channelIds.toString(),
          calendarIds: this.calendarIds.toString(),
          userId: this.storage.get(USER_ID),
          name: this.plannerName,
          forecastMethodId: 38,
          seasonalitySourceLevelId: 8,
          movingAverageWeeksId: 20,
          fbTemplateId: FB_Template.Global_Planning,
          active: true,
          versionTypeId: this.versionTypeId,
        };
        this.spinner.show();
        this.configurationService.userFBForecastAdd(model).subscribe((res) => {
          this.savedPlanner = model;
          this.spinner.hide();
          this.versionTypeId = 3;
          this.userFBForecastId = res;
          this.isOpenSaved = true;
          this.isRestoreSession = false;
          this.isBuidlNew = false;
          this.addItemNames();
          this.addChannelNames();
          this.addCalendarNames();
          const version = {
            ...this.data,
            version: model,
          };
          this.configurationService.savedPlannerOpened.next(version);
          if (saveCommit) {
            this.commitForecastPlanner();
          }
        });
      }
    });
  };
  public commitForecastPlanner = () => {
    this.ref = this.dialog.open(this.commitForecast, {
      width: '848px',
      panelClass: 'fb-dialog-wrapper2',
    });
  };
  public commitFacebookPlanner = () => {
    const model = {
      itemIds: this.itemIds.toString(),
      channelIds: this.channelIds.toString(),
      calendarIds: this.calendarIds.toString(),
      userId: this.storage.get(USER_ID),
      fbTemplateId: FB_Template.Global_Planning,
      forecastMethodId: 38,
      seasonalitySourceLevelId: 8,
      movingAverageWeeksId: 20,
      userFBForecastId: this.userFBForecastId || 0,
    };
    this.spinner.show();
    this.configurationService.FBForecastCommit(model).subscribe((res) => {
      this.spinner.hide();
      this.ref.close();
      this.dialog.open(ForecastConfirmationModalComponent, {
        width: '686px',
        panelClass: ['fb-dialog-wrapper2', 'fb-dialog-wrapper4'],
        data: {
          label: 'Congratulations! You have successfully committed!',
          isOnLoad: false,
          goBack: true,
        },
        disableClose: true,
      });
    });
  };
  checkIfMaxCountReached() {
    if (!this.isBuidlNew && !this.isRestoreSession) {
      this.save();
      return;
    }

    const params = {
      userId: this.storage.get(USER_ID),
      fbTemplateId: FB_Template.Global_Planning,
    };
    this.configurationService.userFBForecastGetList(params).subscribe((res) => {
      if (res.length >= this.maxPlannerCount) {
        this.dialog
          .open(ForecastDeletePlannerModalComponent, {
            width: '848px',

            data: { versionList: res, isGlobal: true },
            disableClose: true,
          })
          .afterClosed()
          .subscribe((command) => {
            if (command && command.version) {
              let model = {
                versionTypeId: null,
                userFBForecastIds: '',
                fbTemplateId: FB_Template.Global_Planning,
              };
              model.versionTypeId = 3;
              for (var i = 0; i < command.version.length; i++) {
                model.userFBForecastIds =
                  model.userFBForecastIds +
                  command.version[i].userFBForecastId +
                  ',';
              }
              model.userFBForecastIds = model.userFBForecastIds.slice(0, -1);
              this.configurationService
                .userFBForecastDeleteByVersionID(model)
                .subscribe((resp) => {});
              this.save();
            }
          });
      } else {
        this.save();
      }
    });
  }
  onSelectionChanged(event) {
    if (event.value.includes(-1) > 0) {
      this.refreshMeasureIds = '';
    } else {
      this.refreshMeasureIds = event.value;
    }
  }
  isAnyOpened = () => {
    return document.getElementsByClassName('dropdown show').length > 0;
  };
  public getMeasuresByCode = () => {
    this.configurationService
      .DataManagerMeasuresGetList({
        code: 'UDPFCST',
        fbTemplateId: FB_Template.Global_Planning,
      })
      .subscribe((res) => {
        this.udpMeasuresList = res;
      });
    this.configurationService
      .DataManagerMeasuresGetList({
        code: 'UDP',
        fbTemplateId: FB_Template.Global_Planning,
      })
      .subscribe((res) => {
        this.udpfcstList = res;
      });
  };
  applyUDP() {
    const forecastMeasure = this.udpfcstList.find(
      (row) => row.measureId === this.selectedForecastMeasure.measureId
    );
    const udpMeasure = this.udpMeasuresList.find(
      (row) => row.measureId === this.selectedUDPMeasure.measureId
    );
    const model = {
      calendarIds: this.calendarIdsUDP.toString(),
      itemIds: this.updatedDropdownObject.itemIds.toString(),
      channelIds: this.updatedDropdownObject.channelIds.toString(),
      versionTypeId: this.versionTypeId,
      fbTemplateId: FB_Template.Global_Planning,
      userId: this.storage.get(USER_ID),
      userFBForecastId: this.userFBForecastId || 0,
      fromMeasureId: forecastMeasure.measureId,
      toMeasureId: udpMeasure.measureId,
      adjustment: this.adjustment,
    };
    this.spinner.show();
    this.configurationService.FbForecastPlannerCopyMeasure(model).subscribe(
      (res) => {
        const updateModel = {
          ...res,
          measureCode: udpMeasure.code,
          isGlobal: true,
        };
        this.configurationService.updateWeeks.next(updateModel);
        this.selectedUDPMeasure = null;
        this.selectedForecastMeasure = null;
        this.adjustment = 0;
        this.createCalendarListDropdownWithWeeks(this.fbForecast);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  updateCriteria(event) {
    this.updatedDropdownObject = event;
    if (event.isSessionUpdate && !this.userFBForecastId) {
      this.isRestoreSession = true;
    } else if (event.isSessionUpdate && this.userFBForecastId) {
      this.isRestoreSession = false;
      this.isOpenSaved = true;
    }
    this.spinner.show();
    this.traverseSelectedOptions(event);
  }
}
