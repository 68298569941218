import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { AllModules, GridApi, ColumnApi, GridOptions, RowNode, ColumnState } from "@ag-grid-enterprise/all-modules";
import * as _ from 'lodash';
import {
  paginationPageSize,
  cacheBlockSize,
  promotionDetailGridColDefs,
  approvalForecastGridColDefs,
  defaultColDef
} from '../../ag-grid/gridOptions';
import { getUniqueId, gridDataExportParams, onCellKeyDown, overlayLoadingTemplate, registerHorizontalScroll } from '../../../util/util';
import { INVALID_TEAM } from 'src/common/keys';
import { ItemPromotionService } from 'src/services/Item-Management-Services';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { getNumericCellEditor } from 'src/common/ag-numeric-editor';
import { getDatePicker } from 'src/common/ag-date-picker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DemoService } from 'src/services/demo-services/demo-service';

@Component({
  selector: 'app-demo-item-approval-detail',
  templateUrl: './item-approval-detail.component.html',
  styleUrls: ['./item-approval-detail.component.css']
})
export class DemoItemApprovalDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  INVALID_TEAM = INVALID_TEAM;

  gridPanelOpenState: boolean = true;
  isItemDetailGridDataUpdated: boolean = false;
  isForecastGridDataUpdated: boolean = false;
  isForecastDataLoaded: boolean = false;
  isItemDataLoaded: boolean = false;
  forecastGridUpdatedRows: any = [];

  public defaultColDef = defaultColDef;
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  rowSelection = 'multiple';
  editType = 'fullRow';
  public modules = AllModules;

  components = {
    numericCellEditor: getNumericCellEditor(),
    datePicker: getDatePicker()
  }

  public itemDetailGridColumnDefs = promotionDetailGridColDefs();

  public itemDetailAutoGroupColumnDef = {
    headerName: 'Status',
    field: 'Status',
    cellClass: 'ag-group-box-cell',
    width: 245,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: { checkbox: true }
  };

  public forecastGridAutoGroupColumnDef = {
    headerName: 'Status',
    field: 'Status',
    cellClass: 'ag-group-box-cell',
    width: 245,
    pinned: 'left',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: { checkbox: true }
  };

  public itemDetailGridApi: GridApi;
  public itemDetailColApi: ColumnApi;
  public itemDetailGridOptions: GridOptions = {
    getRowStyle: (params) => {
      const isLastChild = _.get(params, 'node.lastChild', false);
      const isLeafNode = !(_.get(params, 'node.group', false));
      return (isLastChild && isLeafNode) ? { borderBottom: '3px solid darkgrey' } : ``;
    }
  };
  itemDetailGridData: any[] = [];
  itemDetailGridUpdatedRows: any = [];
  public gridColumnStateItemGrid: ColumnState[] = [];

  public forecastGridColumnDefs = approvalForecastGridColDefs(new Date());
  public forecastGridApi: GridApi;
  public forecastColApi: ColumnApi;
  public forecastGridOptions: GridOptions = {
    getRowStyle: (params) => {
      const isLastChild = _.get(params, 'node.lastChild', false);
      const isLeafNode = !(_.get(params, 'node.group', false));
      return (isLastChild && isLeafNode) ? { borderBottom: '3px solid darkgrey' } : ``;
    }
  };
  forecastGridData: any[] = [];
  forecastGridDataOrgState: any[] = [];
  public gridColumnStateForecastGrid: ColumnState[] = [];

  groupDefaultExpanded = 1

  selectedApprovalDetailItems: any = [];
  selectedTab: number = 0;
  filterEvent: any = {};

  userTeam: any = '';
  isRetailerTeam: boolean = false;

  itemDetailGridId = 'itemDetailGrid';
  approvalForecastGridId = 'approvalForecastGrid';

  itemDetailGridMutationRef: MutationObserver;
  approvalForecastGridMutationRef: MutationObserver;
  onCellKeyDown = onCellKeyDown;
  itemGridPreference: any;
  forecastGridPreference: any;
  overlayLoadingTemplate = overlayLoadingTemplate;

  itemApprovalsSubscription: Subscription;
  weeklyForecastSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    public itemPromotionService: ItemPromotionService,
    public demoService: DemoService,
    public toastr: NgxToasterService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
    const uniqueId = getUniqueId();
    this.itemDetailGridId = this.itemDetailGridId + uniqueId;
    this.approvalForecastGridId = this.approvalForecastGridId + uniqueId;
  }

  ngOnInit() {
    this.initiateValues();
  }

  ngAfterViewInit() {
  }

  initiateValues() {
    this.getNewItemPromotionDetail();
    this.getNewItemPromotionForecast();
  }

  getNewItemPromotionDetail(filterBody: any = {}) {
    this.demoService.GetNewItemPromotionDetail(filterBody).subscribe(response => {
      this.itemDetailGridData = _.orderBy(response, ['promotionItemDetailId'], ['desc']);
      this.isItemDataLoaded = true;
    });
  }

  getNewItemPromotionForecast(filterBody: any = {}) {
    this.demoService.GetNewItemPromotionForecast(filterBody).subscribe(response => {
      this.forecastGridData = _.orderBy(response, ['PromotionItemDetailId'], ['desc']);
      this.isForecastDataLoaded = true;
    });
  }

  setTabIndex(tabEvent) {
    if (!this.approvalForecastGridMutationRef) {
      setTimeout(() => {
        this.approvalForecastGridMutationRef = registerHorizontalScroll(this.approvalForecastGridId);
      }, 500);
    }
    if (!this.itemDetailGridMutationRef) {
      setTimeout(() => {
        this.itemDetailGridMutationRef = registerHorizontalScroll(this.itemDetailGridId);
      }, 500);
    }
  }

  expandItemDetailRowGroups() {
    this.itemDetailGridApi.forEachNode(node => {
      node.setExpanded(true);
    });
  }

  expandForecastRowGroups() {
    this.forecastGridApi.forEachNode(node => {
      node.setExpanded(true);
    });
  }

  applyFilters(event) {
    this.filterEvent = event;
    this.getNewItemPromotionDetail(event);
    this.getNewItemPromotionForecast(event);
  }


  itemGridRowSelectionChanged(params) {
    this.selectedApprovalDetailItems = params.api.getSelectedRows();
  }

  openActionsDialog(action: any): void {
    if (this.selectedApprovalDetailItems.length) {
      // tslint:disable-next-line: no-use-before-declare
      this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {
          headerName: `Change Status to '${action}'`,
          confirmationMessage: `Are you sure you want to change the status to ${action}?`
        }
      });

      this.confirmationDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.handleActions(action);
        }
      });
    } else {
      this.toastr.info('Info', 'Please select at least one row to update status.');
    }
  }

  handleActions(action) {
    this.itemDetailGridData = this.itemDetailGridData.map(item => {
      if (this.selectedApprovalDetailItems.find(a => a.Item_ID === item.Item_ID)) {
        item.Status = action;
      }
      return item;
    });
  }

  onItemDetailGridReady(params) {
    this.itemDetailGridApi = params.api;
    this.itemDetailColApi = params.columnApi;
    // FOR HORIZONTAL SCROLL
    setTimeout(() => {
      if (!this.itemDetailGridMutationRef)
        this.itemDetailGridMutationRef = registerHorizontalScroll(this.itemDetailGridId);
    }, 500);
  }

  openItemDetailGridColumnDialog() {
    this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.itemDetailColApi,
        skipHeaders: ['promotionItemName']
      },
      width: '850px'
    });
  }

  onExportItemDetailGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'NewItemPromotions',
      colDefs: this.itemDetailGridColumnDefs,
      allColumns: true
    });
    this.itemDetailGridApi.exportDataAsCsv(csvExportParams);
  }

  itemDetailGridDataUpdated(params) {
    if ((params.newValue || params.oldValue) && (params.newValue != params.oldValue)) {
      this.isItemDetailGridDataUpdated = true;
      const prvUpdatedRowStateIndex = _.findIndex(this.itemDetailGridUpdatedRows, a => a.promotionItemDetailId === params.data.promotionItemDetailId);
      if (prvUpdatedRowStateIndex < 0) {
        this.itemDetailGridUpdatedRows.push(params.data);
      } else {
        this.itemDetailGridUpdatedRows[prvUpdatedRowStateIndex] = params.data;
      }
    }
  }

  saveUpdatedItemDetailGridData(event) {
    event.stopPropagation();
    this.itemDetailGridApi.stopEditing();
  }

  onForecastGridReady(params) {
    this.forecastGridApi = params.api;
    this.forecastColApi = params.columnApi;
    // FOR HORIZONTAL SCROLL
    setTimeout(() => {
      if (!this.approvalForecastGridMutationRef)
        this.approvalForecastGridMutationRef = registerHorizontalScroll(this.approvalForecastGridId);
    }, 500);
  }

  getForecastWeekDatesList() {
    /*
    In Case Week Date Property Missing from Forecast Data 
    */
    const weekDates =
      _.orderBy(
        _.compact(
          _.map(
            _.keys(_.first(this.forecastGridData)),
            (a: any) => {
              if (a.includes('PromoPrice')) {
                return a.substring(10);
              }
            })));

    return weekDates;
  }

  forecastGridDataUpdated(params) {
    if ((params.newValue || params.oldValue) && (params.newValue != params.oldValue)) {
      this.isForecastGridDataUpdated = true;
      const prvUpdatedRowStateIndex = _.findIndex(this.forecastGridUpdatedRows, a => a.PromotionItemDetailId === params.data.PromotionItemDetailId);
      if (prvUpdatedRowStateIndex < 0) {
        this.forecastGridUpdatedRows.push(params.data);
      } else {
        this.forecastGridUpdatedRows[prvUpdatedRowStateIndex] = params.data;
      }
    }
  }

  saveUpdatedForecastGridData(event) {
    event.stopPropagation();
    this.forecastGridApi.stopEditing();
  }

  openForecastGridColumnDialog() {
    this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.forecastColApi
      },
      width: '850px'
    });
  }

  setForecastGridColumnDefinitions() {
    this.forecastGridColumnDefs = approvalForecastGridColDefs(new Date());
    if (this.forecastGridApi) {
      this.forecastGridApi.setColumnDefs([]);
      this.forecastGridApi.setColumnDefs(this.forecastGridColumnDefs);
    }
  }

  onExportForecastGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'NewItemsPromotionWeeklyForecast',
      colDefs: this.forecastGridColumnDefs,
      allColumns: true
    });
    this.forecastGridApi.exportDataAsCsv(csvExportParams);
  }

  exportDataTemplate() {
    const csvExportParams = gridDataExportParams({
      fileName: 'NewItemsPromotionWeeklyDataTemplate',
      colDefs: this.forecastGridColumnDefs,
      isExportTemplate: true,
      allColumns: true
    });
    this.forecastGridApi.exportDataAsCsv(csvExportParams);
  }

  handleExport(event) {
    event.stopPropagation();
  }

  exportNewItemWeeklyDataTemplate() {
    this.exportDataTemplate();
  }

  importNewItemWeeklyData() {
    (document.getElementById('upload-new-item-approval-promotion-data') as any).value = '';
    document.getElementById('upload-new-item-approval-promotion-data').click();
  }

  validateUploadedCSV(fileInput: any) {
    const reader = new FileReader();
    if (fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        const importDataFile = reader.result as any;
        /*
          Files Validations Will go here
        */
        this.uploadImportedCsvFile(importDataFile);
      }
    }
  }

  uploadImportedCsvFile(importDataFile) {
    // this.itemPromotionService.ImportNewItemPromotionDataTemplate({
    //   fileBase64: importDataFile
    // }).subscribe(
    //   (res: any) => {
    //     this.toastr.success('Success', 'Promotion Data Imported Successfully.');
    //   },
    //   (err: any) => {
    //     this.toastr.error('Error', 'Promotion Data Import Failed.');
    //   });
  }

  ngOnDestroy() {
    this.itemDetailGridMutationRef && this.itemDetailGridMutationRef.disconnect();
    this.approvalForecastGridMutationRef && this.approvalForecastGridMutationRef.disconnect();
  }

}

