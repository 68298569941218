import { Component, OnInit, Input } from '@angular/core';
import { BotService } from 'src/services/bot.service';

@Component({
  selector: 'app-qna-report',
  templateUrl: './qna-report.component.html',
  styleUrls: ['./qna-report.component.css']
})
export class QNAReportComponent implements OnInit {

  @Input() data: any;
  txtEmbedGroupId = 'a3e52170-d3e7-42ce-add2-390223dc488e';
  txtEmbedReportId = 'd2d1e9ed-691f-44c7-bee5-3b4dfd749a02';
  txtEmbedDatasetId = 'd1a09620-2b9f-49e2-991f-94857eeb9736';
  requestData;
  url;
  tokenURL;
  tokenInfo;
  token = "eyJ0eXAiOiJKV1QiLCJub25jZSI6ImxqdnZLU2phRFZMcDQ1QXA3TWxVd3VyejJmSFF0bnpDYlJ4MFFCQ05ROWsiLCJhbGciOiJSUzI1NiIsIng1dCI6Imh1Tjk1SXZQZmVocTM0R3pCRFoxR1hHaXJuTSIsImtpZCI6Imh1Tjk1SXZQZmVocTM0R3pCRFoxR1hHaXJuTSJ9.eyJhdWQiOiIwMDAwMDAwMy0wMDAwLTAwMDAtYzAwMC0wMDAwMDAwMDAwMDAiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9kZDcwYTY0Ni05OTJmLTRmMGUtYTE1ZS1kOWM1NTRlMWY1NzYvIiwiaWF0IjoxNTk1MzY2MzQyLCJuYmYiOjE1OTUzNjYzNDIsImV4cCI6MTU5NTM3MDI0MiwiYWNjdCI6MSwiYWNyIjoiMSIsImFpbyI6IkFVUUF1LzhRQUFBQU9UbmJUN1h4WExrYTM2TU1RTmpucWFsNTBUeDUrWVJhanhBemtNcTRCc2dYb1prRlR6T2hOM2VtOTBDOUs4WElPOFh4d1VPOHd2RlVPbGcreFo4TjdnPT0iLCJhbHRzZWNpZCI6IjU6OjEwMDMyMDAwQTg3RjlCMUQiLCJhbXIiOlsicHdkIl0sImFwcF9kaXNwbGF5bmFtZSI6IkFsZ28gUUEiLCJhcHBpZCI6Ijk3MzZkNGY0LTc0NDAtNDk1My04YmU2LThhNGZhZmZhMzhjYiIsImFwcGlkYWNyIjoiMSIsImVtYWlsIjoienViYWlyLmFuanVtQGFsZ28uY29tIiwiaWRwIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvNTRjNzBmNjEtZmNiNC00MTZhLTgyMWQtMmMxZGVmZTQ4NjBmLyIsImlwYWRkciI6IjcyLjI1NS40OS4zMiIsIm5hbWUiOiJadWJhaXIgQW5qdW0iLCJvaWQiOiIzOTAzN2M0ZC01OWE2LTQ2OGQtOThhZS02MzBhMjE1NDU0MjgiLCJwbGF0ZiI6IjE0IiwicHVpZCI6IjEwMDMyMDAwQ0ZDMzM4MkIiLCJzY3AiOiJVc2VyLlJlYWQgcHJvZmlsZSBvcGVuaWQgZW1haWwiLCJzdWIiOiJRNHF4dWxNMmVKcGYtYmE2aTF4Q0xwdTJXVzU3dnM0elNqQVhKZFJJelEwIiwidGVuYW50X3JlZ2lvbl9zY29wZSI6Ik5BIiwidGlkIjoiZGQ3MGE2NDYtOTkyZi00ZjBlLWExNWUtZDljNTU0ZTFmNTc2IiwidW5pcXVlX25hbWUiOiJ6dWJhaXIuYW5qdW1AYWxnby5jb20iLCJ1dGkiOiJPaFFPNFVOdzNFZVRuYUQ1WHF3YUFBIiwidmVyIjoiMS4wIiwid2lkcyI6WyI5Yjg5NWQ5Mi0yY2QzLTQ0YzctOWQwMi1hNmFjMmQ1ZWE1YzMiXSwieG1zX3N0Ijp7InN1YiI6Ijl0cDFmVlhaSlpQNVZzNFFRVjFiZ0hCZlAtMzRNdGpXdlNNS25FRl9VNU0ifSwieG1zX3RjZHQiOjE1ODUxNjkyMDd9.RYPoXacGIvKU_Jm2Vq5fikprDcTgNNyxpKpLHA6AMSHrdNeR0I7576-j5_V9Uu7Z5YPkBVLvCldt7BaA1Yzli6MjP4lgyuapBpYrXqONJOfw3vJ3UAVG9IMLtZDtosyZZ3ePU92DW4X3nchdTUU02R4dts-danDmwurgSrgCkg5NWRorzR2nm9wPJmvozKX24eh2Yr5cWTRYj75xEqNzWDTKEpdNUak5-EOtKdDEWZPptcTqAyCimdwpvAeRKhi2rJLoXE_oJpNuV5pGwng4MZrFiUfhFQxFyx9WPD1b2Dm7FEvWHnzGC1OsE89er_adCNx0nkpMznyrpe1Dx_b09Q";
  constructor(public botService: BotService) { }

  ngOnInit() {

    if (this.data) {
      const report = JSON.parse(this.data);
      this.txtEmbedReportId = report.reportId;
      this.txtEmbedDatasetId = report.datasetId;
    }
    const params = {
      reportId: this.txtEmbedReportId,
      groupId: this.txtEmbedGroupId
    };
    this.botService.GetMicrosoftLoginToken(params).subscribe((res: any) => {
      this.tokenInfo = res.token;
    });
    this.url = `https://app.powerbi.com/reportEmbed?reportId=${this.txtEmbedReportId}&groupId=${this.txtEmbedGroupId}`;
    this.tokenURL = `https://api.powerbi.com/v1.0/myorg/groups/${this.txtEmbedGroupId}/reports/${this.txtEmbedReportId}/GenerateToken`;
    this.requestData = {
      'accessLevel': 'View', // Edit

      'datasetId': [this.txtEmbedDatasetId
      ]
    };
  }
}

