<!-- <h2 style="font-weight: bold; text-align: center;">Summary By Year</h2> -->
<div class="container summary-wrapper">
  <div *ngIf="!channel" class="row">
    <div class="col-md-6 form-group">
      <mat-form-field>
        <mat-select placeholder="Select Channel" [(ngModel)]="channel" (ngModelChange)="populateList()">
          <mat-option *ngFor=" let res of channelList; let i=index" [value]="res">
            {{res.channelName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="all-show-grid">
        <label class="grid-head">Sales PKR(M)</label>
        <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false'
          paginationPageSize='20'  groupHeaders suppressRowClickSelection
          toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptions" [headerHeight]="headerHeight"
          [getRowHeight]="getRowHeight" [columnDefs]="columnDefs" [suppressRowTransform]="true" [rowData]="gridList"
          (gridReady)="onGridReady($event)" [modules]="modules" #grid>
        </ag-grid-angular>
      </div>
    </div>
    <div *ngIf="!isFromChanneWiseSource" class="col-md-12">
      <div class="all-show-grid">
        <label class="grid-head">Same Store</label>
        <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false'
          paginationPageSize='20'  groupHeaders suppressRowClickSelection
          toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptionsVariation"
          [headerHeight]="headerHeight" [getRowHeight]="getRowHeight" [columnDefs]="columnDefsVariation"
          [suppressRowTransform]="true" [rowData]="gridListVariation" (gridReady)="onGridReady($event)"
          [modules]="modules" #grid>
        </ag-grid-angular>
      </div>
    </div>
    <div *ngIf="!isFromChanneWiseSource" class="col-md-12">
      <app-month-wise-new-store *ngIf="channel" [channel]="channel"></app-month-wise-new-store>
    </div>
    <div *ngIf="!isFromChanneWiseSource" class="col-md-12">
      <div class="all-show-grid">
        <label class="grid-head">%Age Contribution</label>
        <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false'
          paginationPageSize='20'  groupHeaders suppressRowClickSelection
          toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptionsPercentage" domLayout='autoHeight'
          paginationAutoPageSize='false' paginationPageSize='20' [headerHeight]="headerHeight"
          [getRowHeight]="getRowHeight" [columnDefs]="columnDefsPercentage" [suppressRowTransform]="true"
          [rowData]="gridListPercentage" (gridReady)="onGridReady($event)" [modules]="modules" #grid>
        </ag-grid-angular>
      </div>
    </div>
    <div *ngIf="!isFromChanneWiseSource" class="col-md-12">
      <div class="all-show-grid">
        <label class="grid-head">%Age Contribution Current Year</label>
        <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false'
          paginationPageSize='20'  groupHeaders suppressRowClickSelection
          toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptionsPercentageVariation"
          domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20' [headerHeight]="headerHeight"
          [getRowHeight]="getRowHeight" [columnDefs]="columnDefsPercentageVariation" [suppressRowTransform]="true"
          [rowData]="gridListPercentageVariation" (gridReady)="onGridReady($event)" [modules]="modules" #grid>
        </ag-grid-angular>
      </div>
    </div>
  </div>




</div>
