import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { USER_INFO } from 'src/common/keys';
import { ClusteringForecastService } from 'src/services/Item-Management-Services/clustering-forecast.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { UpdateNameDialogComponent } from '../../common/update-name-dialog/update-name-dialog.component';

@Component({
  selector: 'app-clustering-forecast',
  templateUrl: './clustering-forecast.component.html',
  styleUrls: ['./clustering-forecast.component.scss']
})
export class ClusteringForecastComponent implements OnInit, OnDestroy {

  constructor(private changeDetectionRef: ChangeDetectorRef, private dialog: MatDialog, private storage: LocalstorageService, private clusteringService: ClusteringForecastService, private spinner: NgxSpinnerService) { }

  outputTabData = null;
  runTabs = [];
  selectedTab = 0;

  resizeGraphSubject: Subject<boolean> = new Subject();

  userObj = {} as any;
  versionNo = null;

  ngOnInit(): void {
    this.userObj = this.storage.get(USER_INFO);
  }

  runClusterHandler(event) {
    this.versionNo = null;
    this.runTabs = [];
    if (event && event.versionNo) this.versionNo = event.versionNo;
    this.outputTabData = event;
    this.createRunTabs(this.outputTabData);
    this.selectedTab = 0;
    setTimeout(() => {
      this.outputTabData && (this.selectedTab = 1);
      this.changeDetectionRef.detectChanges();
      this.onTabChanged();
    }, 200);
  }

  onTabChanged() {
    this.resizeGraphSubject.next(true);
  }

  createRunTabs(data) {
    if (data && data.run_labels && data.run_labels.length) {
      this.spinner.show();
      data.run_labels.forEach(run => {
        let runObject: any = {
          metrices: data.metrices,
          fileName: data.fileName,
          clusteringLabels: JSON.parse(JSON.stringify(run.cluster_labels)),
          perClusterCount: data.data.per_cluster_count,
          objectToCluster: data.data.objectToCluster,
          summaryStatsMetricVariables: [],
          clusterStatistics: data.data.cluster_statistics,
          spiderPlot: []
        };
        runObject.tabName = run.label_name;
        const summaryMetrices = run.metric_columns;
        const obj = {
          file_name: data.fileName,
          user_id: this.userObj.userId,
          run_tab_index: run.label_id,
          matrix_columns: summaryMetrices,
          version_no: this.versionNo
        };
        data.metrices.forEach(element => {
          run.metric_columns.forEach(metricItem => {
            if (element.id == metricItem)
              runObject.summaryStatsMetricVariables.push(element);
          });
        });
        this.clusteringService.fetchSpiderChartData(obj)
          .subscribe((res: any) => {
            // const spiderPlot = [];
            // const theta = [];
            // const clustersObj = {};
            // res.payload.data.spider_plot.forEach((element, index) => {
            //   const key = Object.keys(element)[0];
            //   theta.push(key);
            //   const values = Object.values(element)[0] as Array<any>;
            //   values.forEach((item, itemIndex) => {
            //     !clustersObj[item._id] && (clustersObj[item._id] = [])
            //     clustersObj[item._id].push(item.avg);
            //   });
            // });
            // const keys = Object.keys(clustersObj);
            // const values = Object.values(clustersObj);
            // keys.forEach((clusterElement, index) => {
            //   let label = '';
            //   runObject.clusteringLabels.forEach(clusterLabel => {
            //     if (clusterLabel.label_id == clusterElement)
            //       label = clusterLabel.label_name;
            //   });
            //   const obj = {
            //     r: values[index],
            //     theta: theta,
            //     type: 'scatterpolar',
            //     fill: 'toself',
            //     name: label
            //   };
            //   spiderPlot.push(obj);
            // });
            // runObject.spiderPlot = spiderPlot;
            runObject.spiderVariables = [];
            runObject.metrices.forEach((element, index) => {
              if (index < 12) {
                runObject.spiderVariables.push(element.id);
              }
            });
            runObject.spiderPlot = res.payload.data.spider_plot;

            runObject.runIndex = run.label_id;
            runObject = JSON.parse(JSON.stringify(runObject));
            this.runTabs.push(runObject);
            setTimeout(() => {
              this.selectedTab = this.runTabs.length + 1;
              this.changeDetectionRef.detectChanges();
              this.onTabChanged();
            }, 200);
          },
            err => {
              this.spinner.hide();
            });
      });
    }
  }
  outputTabRunBtnHandler(data) {
    this.spinner.show();
    let runTabIndex = 1;
    if (this.runTabs.length) {
      const index = this.runTabs[this.runTabs.length - 1].runIndex.split('_').pop();
      runTabIndex = Number(index) + 1;
    }
    data.runIndex = `RUN_${runTabIndex}`;
    data.tabName = `RUN_${runTabIndex}`;
    const summaryMetrices = [];
    data.summaryStatsMetricVariables.forEach(metricElement => {
      summaryMetrices.push(metricElement.id);
    });
    const saveObj = {
      file_name: data.fileName,
      user_id: this.userObj.userId,
      run_cluster_id: data.runIndex,
      cluster_by: data.objectToCluster,
      metric_columns: summaryMetrices,
      version_no: this.versionNo
      // row_ids: data.row_ids,
      // cluster_ids: data.cluster_ids
    };
    const formData = new FormData();
    formData.append('save_run_settings', JSON.stringify(saveObj));
    this.clusteringService.saveRun(formData)
      .subscribe((res: any) => {
        const obj = {
          file_name: data.fileName,
          user_id: this.userObj.userId,
          run_tab_index: data.runIndex,
          matrix_columns: summaryMetrices,
          version_no: this.versionNo
        };
        this.clusteringService.fetchSpiderChartData(obj)
          .subscribe((res: any) => {
            // const spiderPlot = [];
            // const theta = [];
            // const clustersObj = {};
            // res.payload.data.spider_plot.forEach((element, index) => {
            //   const key = Object.keys(element)[0];
            //   theta.push(key);
            //   const values = Object.values(element)[0] as Array<any>;
            //   values.forEach((item, itemIndex) => {
            //     !clustersObj[item._id] && (clustersObj[item._id] = [])
            //     clustersObj[item._id].push(item.avg);
            //   });
            // });
            // const keys = Object.keys(clustersObj);
            // const values = Object.values(clustersObj);
            // keys.forEach((clusterElement, index) => {
            //   let label = '';
            //   data.clusteringLabels.forEach(clusterLabel => {
            //     if (clusterLabel.label_id == clusterElement)
            //       label = clusterLabel.label_name;
            //   });
            //   const obj = {
            //     r: values[index],
            //     theta: theta,
            //     type: 'scatterpolar',
            //     fill: 'toself',
            //     name: label
            //   };
            //   spiderPlot.push(obj);
            // });
            data.spiderVariables = [];
            data.metrices.forEach((element, index) => {
              if (index < 12) {
                data.spiderVariables.push(element.id);
              }
            });
            data.spiderPlot = res.payload.data.spider_plot;
            data = JSON.parse(JSON.stringify(data));
            this.runTabs.push(data);
            setTimeout(() => {
              this.selectedTab = this.runTabs.length + 1;
              this.changeDetectionRef.detectChanges();
              this.onTabChanged();
            }, 200);
          },
            err => {
              this.spinner.hide();
            });
      },
        err => {
          this.spinner.hide();
        });
  }

  editTabName(tab, index: number, event) {
    event && event.preventDefault();
    event && event.stopPropagation();
    console.log(this.runTabs);
    const columnsDialogRef = this.dialog.open(UpdateNameDialogComponent, {
      width: '650px',
      data: {
        name: this.runTabs[index].tabName ? this.runTabs[index].tabName : `RUN ${index + 1}`
      }
    });

    columnsDialogRef.afterClosed().subscribe(result => {
      if (result) {
        const labelsList = this.runTabs.map((tab, i) => {
          return index === i ? { uu_id: tab.runIndex, name: result } : { uu_id: tab.runIndex, name: tab.tabName }
        });
        this.clusteringService.updateRunTabMetadata({
          file_name: this.outputTabData.fileName,
          user_id: this.userObj.userId,
          update_cluster_label: labelsList,
          version_no: this.versionNo
        }).subscribe(res => {
          this.runTabs[index].tabName = result;
          console.log(res)
        },
        err => {
          console.log();
        });
      }
    });
  }

  removeTab(i: number, event) {
    event && event.preventDefault();
    event && event.stopPropagation();
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { confirmationMessage: 'Are you sure you want to remove this Run?' }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.runTabs.splice(i, 1);
      }
    });
  }

  ngOnDestroy() {
  }

}
