import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PromotionFormV2Component } from '../promotion-form-v2/promotion-form-v2.component';
import { CellClickedEvent } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';

@Component({
  selector: 'app-promotion-listing-v2',
  templateUrl: './promotion-listing-v2.component.html',
  styleUrls: ['./promotion-listing-v2.component.css']
})
export class PromotionListingV2Component implements OnInit {
  public fundTypeList = [{ fundTypeId: 1, fundTypeName: 'Promo' }, { fundTypeId: 2, fundTypeName: 'Base' }];
  public columnDefs = [
    {
      headerName: 'Promotion Name',
      field: 'promotionName',
      cellRenderer: (params) => {
        return `<a href="javascript:void(0)" style="color: #232323">${params.value}</a>`;
      },
    },
    {
      headerName: 'Customers',
      field: 'customers'
    },

    {
      headerName: 'Country',
      field: 'country'
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
      cellRenderer: (params) => {
        return `${params.value ? (new Date(params.value)).toLocaleDateString() : ''}`;
      }
    },
    {
      headerName: 'End Date',
      field: 'endDate',
      cellRenderer: (params) => {
        return `${params.value ? (new Date(params.value)).toLocaleDateString() : ''}`;
      }
    },
    {
      headerName: 'Product Type',
      field: 'productType'
    },
    {
      headerName: 'Promotion Type',
      field: 'promotionType'
    },
    {
      headerName: 'CO Promo Total ST Forecast',
      field: 'coPromoTotalSTForecast'
    }

  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public promotionList = [

    {
      "promotionId":2,
      "promotionName":"Holiday Sale",
      "customers":"Best Buy",
	    "country":"US",
      "startDate":"2020-12-06T00:00:00",
      "endDate":"2020-12-19T00:00:00",
	    "productType":"Oculus, Oculus Accessories",
	    "promotionType":"Email",
	    "coPromoTotalSTForecast" : "3,000",
      "classes":"New Release",
      "subClasses":"BD",
      "fundTypeId":1,
      "updateRMF":10.0000,
      "returnRate":0.0000,
      "returnOffsetWeeks":"",
      "shipmentOffsetWeeks":"",
      "promotionDescription":"",
      "primaryPlacement":true,
      "secondaryPlacement":true,
      "promotionProductType":"",
      "consumerPromotion":"",
      "advertisementDescription":"",
      "categoryStrategy":"Conversation",

      "active":true

   },
   {
      "promotionId":3,
      "promotionName":"2-Day Sale",
      "customers":"All",
	  "country":"All",
      "startDate":"2020-01-02T00:00:00",
      "endDate":"2020-01-04T00:00:00",
	"productType":"Oculus Accessories",
	"promotionType":"IS $20",
	"coPromoTotalSTForecast" : "25,000",
      "classes":"New Release",
      "subClasses":"BD,UHD",
      "fundTypeId":1,
      "updateRMF":0.0000,
      "returnRate":0.0000,
      "returnOffsetWeeks":"",
      "shipmentOffsetWeeks":"",
      "promotionDescription":"",
      "primaryPlacement":true,
      "secondaryPlacement":false,
      "promotionProductType":"",
      "consumerPromotion":"",
      "advertisementDescription":"",
      "categoryStrategy":"Conversation",

      "active":true

   },
   {
      "promotionId":4,
      "promotionName":"Black Friday",
      "customers":"Walmart, Target",
	  "country":"US",
      "startDate":"2020-11-26T00:00:00",
      "endDate":"2020-11-27T00:00:00",
	"productType":"Portal",
	"promotionType":"Commercial",
	"coPromoTotalSTForecast" : "5,000",
      "classes":"New Release",
      "subClasses":"BD,DVD",
      "fundTypeId":1,
      "updateRMF":0.0000,
      "returnRate":0.0000,
      "returnOffsetWeeks":"",
      "shipmentOffsetWeeks":"",
      "promotionDescription":"",
      "primaryPlacement":true,
      "secondaryPlacement":true,
      "promotionProductType":"",
      "consumerPromotion":"",
      "advertisementDescription":"",
      "categoryStrategy":"Conversation",

      "active":true

   },
   {
      "promotionId":5,
      "promotionName":"10% off Sale",
      "customers":"Best Buy",
	  "country":"US",
      "startDate":"2020-03-20T00:00:00",
      "endDate":"2020-03-27T00:00:00",
	"productType":"Oculus",
	"promotionType":"Direct mail",
	"coPromoTotalSTForecast" : "500",
      "classes":"New Release",
      "subClasses":"UHD",
      "fundTypeId":1,
      "updateRMF":0.0000,
      "returnRate":0.0000,
      "returnOffsetWeeks":"",
      "shipmentOffsetWeeks":"",
      "promotionDescription":"",
      "primaryPlacement":true,
      "secondaryPlacement":true,
      "promotionProductType":"",
      "consumerPromotion":"",
      "advertisementDescription":"",
      "categoryStrategy":"Conversation",

      "active":true

   },
   {
      "promotionId":6,
      "promotionName":"EOL Sale",
      "customers":"Best Buy",
	  "country":"CA",
      "startDate":"2021-01-20T00:00:00",
      "endDate":"2021-01-25T00:00:00",
	"productType":"Oculus Accessories",
	"promotionType":"Social Media",
	"coPromoTotalSTForecast" : "200",
      "classes":"New Release",
      "subClasses":"DVD,BD,UHD",
      "fundTypeId":1,
      "updateRMF":0.0000,
      "returnRate":0.0000,
      "returnOffsetWeeks":"",
      "shipmentOffsetWeeks":"",
      "promotionDescription":"",
      "primaryPlacement":true,
      "secondaryPlacement":true,
      "promotionProductType":"",
      "consumerPromotion":"",
      "advertisementDescription":"",
      "categoryStrategy":"Conversation",

      "active":true

   }

 ];
  public modules = AllCommunityModules;
  public user: any;
  constructor(
    public router: Router,
    public userService: UsersService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PromotionFormV2Component>,
    public configurationService: ConfigurationService,
    public matDialogRef: MatDialogRef<any>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
      paginationPageSize: 5
    };
  }
  ngOnInit() {
    this.configurationService.promotionListSubject$.subscribe(res => {
      this.populateList();
    });
    this.populateList();
  }
  public populateList = () => {
    // this.configurationService.PromotionGetAll({ active: true }).subscribe(res => {
    //   this.promotionList = res;
    // });
  }
  public getRoleNames = (roleList: any) => {
    if (roleList && roleList.length > 0) {
      let names = '';
      roleList.forEach(element => {
        names = `${names}, ${element.roleName}`;
      });
      names = names.split(',').splice(1).join(',');
      return names || '';
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.promotionName }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeSKU(row.rowData);
      }
    });
  }
  public removeSKU(selectedItem: any) {
    if (selectedItem) {
      const model = {
        ...selectedItem,
        active: false
      };
      this.spinner.show();
      this.configurationService.PromotionActivate(model).subscribe(res => {
        if (res) {
          this.spinner.hide();
          this.toastr.success('Promotion', 'Promotion removed successfully');
          this.promotionList = this.promotionList.filter(row => row.promotionId !== model.promotionId);
          this.gridOptions.api.setRowData(this.promotionList);
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('Promotion', error.error.text);
      });
    }
  }
  onCellClicked($event: CellClickedEvent) {
    if ($event && $event.colDef.field === 'promotionName') {
      const selector = {
        menuId: '451',
        name: 'Create Promotion',
        selector: 'app-promotion-form-v2',
        displayName: 'Create Promotion',
        data: {}
      };
      selector.data = { isEdit: true, ...$event.data };
      this.configurationService.menuRendererSubject.next(selector);
    }
  }
  public openDialog = () => {
    const selector = {
      menuId: '451',
      name: 'Create Promotion',
      selector: 'app-promotion-form-v2',
      displayName: 'Create Promotion',
      data: {}
    };

    this.configurationService.menuRendererSubject.next(selector);
    // this.dialogRef = this.dialog.open(PromotionFormComponent, { width: `${window.innerWidth}px`, height: `${window.innerHeight > 50 ? window.innerHeight - 50 : window.innerHeight}px`, panelClass: 'my-dialog' });
  }
}
