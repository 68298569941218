import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AllModules, GridApi, ColumnApi, GridOptions, ServerSideStoreType } from '@ag-grid-enterprise/all-modules';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { defaultColDef, getPromoColDefs } from './ag-grid/grid-options';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { PromoSetupDetailComponent } from './promo-setup-detail/promo-setup-detail.component';
import { PromoService } from 'src/services/forecast-services/promo-service';
import { PromoItemsEditRenderer } from './ag-grid/promo-items-edit-renderer.component';
import { Observable, Subscription } from 'rxjs';
import { ConfigurationService } from 'src/services/configuration.service';
import { downloadFromLink, gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import { ForecastLookupService } from 'src/services/forecast-services/forecast-lookup-service';
import { getNumericCellEditor } from 'src/common/ag-numeric-editor';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';
import * as signalR from '@aspnet/signalr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { getFilterModel, getSortModel } from 'src/common/util/util';
import { tap } from 'rxjs/operators';
import { SharedDataService } from 'src/services/shared-data.service';
import { setSyncButtonClass } from '../forecast-helper-functions';

@Component({
  selector: 'app-promo-manager',
  templateUrl: './promo-manager.component.html',
  styleUrls: ['./promo-manager.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PromoManagerComponent implements OnInit {


  totalRows = 0;
  rowModelType = 'serverSide';
  serverSideStoreType = ServerSideStoreType.Partial;
  paginationPageSize = 20;
  cacheBlockSize = 20;
  public modules = AllModules;
  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  rowSelection = 'multiple';
  public defaultColDef = defaultColDef;
  components = {
    numericCellEditor: getNumericCellEditor()
  };
  public columnDefs = getPromoColDefs({}, false, this);
  frameworkComponents = {
    promoItemsEditRenderer: PromoItemsEditRenderer,
  };
  selectedRows: any = [];

  public actionSubscription: Subscription;
  public promoSetupSubscription: Subscription;

  promoName: string = '';
  promotionEventName: string = '';
  planningMonth = [];
  selectedPlanningMonths = [];
  promoInfo: any = [];
  status;
  accounts: any = [];

  planningMonthList: any = [];
  statusList = [
    { statusId: false, statusName: 'Inactive' },
    { statusId: true, statusName: 'Active' }
  ];
  accountList: any = [];
  promoInfoList: any = [
    { promoInfoId: 'retailer', promoInfoName: 'Retailer' },
    { promoInfoId: 'user', promoInfoName: 'User' }
  ];

  isDepromo: boolean = false;
  gridUpdatedRows: any = [];

  azpipeline: any;

  filteredMonthsList: Observable<any[]>;
  selectedAccounts = [];

  accountsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'accountName',
    textField: 'accountName',
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: 'No Data Available'
  };

  monthsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'planningMonth',
    textField: 'planningMonth',
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    noFilteredDataAvailablePlaceholderText: 'No Data Available'
  };

  filterEvent: any = {};

  gridData: any = [];
  hiddenGridApi: GridApi;

  setSyncButtonClassFunction = setSyncButtonClass;

  constructor(
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public configurationService: ConfigurationService,
    public promoService: PromoService,
    public _dataService: SharedDataService,
    public forecastLookupService: ForecastLookupService
  ) {
  }

  get isFilteredApplied(): boolean {
    return this.gridApi ? !_.isEmpty(this.gridApi.getFilterModel()) : false;
  }

  get isGridDataUpdated() {
    return this.gridUpdatedRows.length ? true : false;
  }

  ngOnInit() {
    this.listenSubscriptions();
    this.getLookupData();
    this.setupHubConnection();
    // this.createDataSource();
  }

  createDataSource() {
    const datasource = {
      getRows: (params) => {
        const sortModel = params.request.sortModel;
        const filterModel = params.request.filterModel;
        const requestBody: any = {
          // 0 base index of page number
          isPromo: !this.isDepromo,
          offSet: params.request.startRow,
          pageSize: this.paginationPageSize,
          ...this.filterEvent
        };

        requestBody.filterModel = getFilterModel(filterModel);
        requestBody.sortModel = getSortModel(sortModel);

        this.promoService.GetPromotionPlannerList(requestBody).pipe(
          tap(() => this.gridApi.hideOverlay())
        ).subscribe((response: any) => {
          const gridData = response.data;
          const totalRecord = _.get(response, 'totalRecord', 0);
          this.totalRows = totalRecord;
          params.successCallback(gridData, totalRecord);
          this.deselectNode();
          response.data.length == 0 && this.gridApi.showNoRowsOverlay();

          this.gridApi && this.gridApi.setRowData(gridData);
          this.spinner.hide();
        });
      },
    };
    this.gridApi.setServerSideDatasource(datasource);
  }

  deselectNode() {
    if (this.gridApi) {
      this.gridApi.deselectAll();
    }
  }

  setupHubConnection() {
    const configUrl = `${environment.configurationUrl}`;
    const hubUrl = configUrl.substring(0, configUrl.length - 3) + "notify";
    this.azurePipelineTriggerGetList();
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(hubUrl)
      .build();
    connection
      .start()
      .then(function () {
        console.log('SignalR Connected!');
      })
      .catch(function (err) {
        return console.error();
      });
    connection.on('BroadcastMessage', (con) => {
      this.azurePipelineTriggerGetList();
    });
  }

  listenSubscriptions() {
    this.actionSubscription = this.promoService.getPromoGridActionCellSubject().subscribe(data => {
      if (data) {
        const promoDetailData = {
          isEdit: true,
          isCopyOperation: false,
          promoData: data.promoData,
          promoItemList: []
        }
        this.openPromotionSetupDetail(promoDetailData);
      }
    });

    this.promoSetupSubscription = this.promoService.getPromoSetupActionSubject().subscribe(data => {
      if (data) {
        this.createDataSource();
      }
    });
  }

  getLookupData() {
    this.forecastLookupService.GetAccountslist().subscribe(accountList => {
      this.accountList = accountList;
    });

    this.forecastLookupService.GetPlanningMonthList().subscribe(planningMonthList => {
      this.planningMonthList = planningMonthList;
    })
  }

  clearValues() {
    this.promoName = '';
    this.promotionEventName = '';
    this.promoInfo = [];
    this.planningMonth = [];
    this.selectedPlanningMonths = [];
    this.status = null;
    this.accounts = [];
    this.selectedAccounts = [];
    this.applyFilterEvent();
  }

  applyFilterEvent() {
    this.filterEvent = {
      ...this.promoName && { promoName: this.promoName },
      ...this.promotionEventName && { promotionEventName: this.promotionEventName },
      ...this.promoInfo.length && { promoInfo: this.promoInfo },
      ...this.status !== null && { status: this.status },
      ...this.planningMonth.length && { planningMonth: this.planningMonth.join(',') },
      ...this.accounts.length && { accounts: this.accounts.join(',') },
      ... { isPromo: !this.isDepromo }
    };
    this.createDataSource();
  }

  selectMonthsChange() {
    const months = [];
    this.selectedPlanningMonths.forEach(a => {
      months.push(a.planningMonth);
    });
    this.planningMonth = months;
    this.applyFilterEvent();
  }

  accountsChange() {
    const accounts = [];
    this.selectedAccounts.forEach(a => {
      accounts.push(a.accountName);
    });
    this.accounts = accounts;
    this.applyFilterEvent();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.applyFilterEvent();
  }

  rowSelectionChanged(params) {
    this.selectedRows = params.api.getSelectedRows();
  }

  clearSelectedRows() {
    this.gridApi.deselectAll();
  }

  clearGridFilters() {
    this.gridApi.setFilterModel(null);
  }

  openColumnDialog() {
    const dialogRef = this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select'],
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onExportGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'Promotion-Planner',
      colDefs: this.columnDefs,
      allColumns: false,
      columnKeys: _.map(this.columnDefs, a => a.field)
    });
    this.gridApi.exportDataAsCsv(csvExportParams);
  }

  exportDataTemplate() {
    this.promoService.ExportPromotionPlannerTemplate().subscribe((res: any) => {
      downloadFromLink(res, 'PromotionPlannerTemplate');
    });
  }

  importDataByTemplate() {
    (document.getElementById('upload-promotion-planner-data') as any).value = '';
    document.getElementById('upload-promotion-planner-data').click();
  }

  validateUploadedCSV(fileInput: any) {
    const formDataReader = new FormData();

    if (fileInput.target.files && fileInput.target.files.length) {
      const [files] = fileInput.target.files;

      formDataReader.append('file', files);
      this.uploadImportedPromoCsvFile(formDataReader);
    }
  }

  uploadImportedPromoCsvFile(file) {
    this.promoService.ImportPromotionPlannerTemplate(file).subscribe(
      (res: any) => {
        if (res && res.length) {
          const successMessages = [];
          const errorMessages = [];
          const warningMessages = [];
          const infoMessages = [];
          res.forEach(element => {

            switch (element.messageType) {
              case 'Success':
                successMessages.push(element.messageText);
                break;
              case 'Warning':
                warningMessages.push(element.messageText);
                break;
              case 'Info':
                infoMessages.push(element.messageText);
                break;
              case 'Error':
                errorMessages.push(element.messageText);
                break;
              default:
                break;
            }
          });

          if (errorMessages.length)
            this.toastr.error('Error', `${errorMessages.map(a => a).join('<br />')}`);
          if (successMessages.length)
            this.toastr.success('Success', `${successMessages.map(a => a).join('<br />')}`);
          if (warningMessages.length)
            this.toastr.warning('Warning', `${warningMessages.map(a => a).join('<br />')}`);
          if (infoMessages.length)
            this.toastr.info('Info', `${infoMessages.map(a => a).join('<br />')}`);

          this.createDataSource();
        } else {
          this.toastr.warning('Warning', 'Promotion Data Import Failed.');
        }
      },
      (err: any) => {
        this.toastr.error('Error', 'Promotion Data Import Failed.');
      });
  }

  openDePromoView(event) {
    this.columnDefs = getPromoColDefs({}, this.isDepromo, this);
    if (this.gridApi) {
      this.gridApi.setColumnDefs(this.columnDefs);
    }
    this.applyFilterEvent();
  }

  openPromotionSetupDetail(data = {}) {
    const promotionDetailComponent = {
      menuId: '449',
      name: 'Promo Details',
      selector: 'app-promo-setup-detail',
      displayName: 'Promo Details',
      module: 'ForecastEnginModule',
      data: data,
      component: PromoSetupDetailComponent
    }
    this.configurationService.menuRendererSubject.next(promotionDetailComponent);
  }

  depromoGridDataUpdated(params) {
    if ((params.newValue || params.oldValue) && (params.newValue != params.oldValue)) {
      const prvUpdatedRowStateIndex = _.findIndex(this.gridUpdatedRows, a => a.promotionPlannerId === params.data.promotionPlannerId);
      if (prvUpdatedRowStateIndex < 0) {
        this.gridUpdatedRows.push(params.data);
      } else {
        this.gridUpdatedRows[prvUpdatedRowStateIndex] = params.data;
      }
    }
  }


  saveGridData() {
    this.gridApi.stopEditing();
    setTimeout(() => {
      if (this.gridUpdatedRows.length) {
        this.gridApi && this.gridApi.showLoadingOverlay();
        this.promoService.InsertUpdateDePromotionPlanner({ dePromotionPlannerDatatableList: this.gridUpdatedRows }).subscribe((res: any) => {
          this.toastr.success('Success', 'Promotion Details Updated Successfully.');
          this.gridUpdatedRows = [];
          this.gridApi && this.gridApi.hideOverlay();
        }, err => {
          this.gridApi && this.gridApi.hideOverlay();
          this.toastr.error('Error', 'Promotion Details Update Failed.');
        });
      }
    }, 100);
  }

  triggerPromoManagerPipeline() {
    if (this.azpipeline && this.azpipeline.status && ['InProgress', 'Queued'].indexOf(this.azpipeline.status) > -1) {
      this.toastr.info('Info', 'Pipeline already in-progress.')
    } else {
      const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {
          headerName: `Sync De-Promo`,
          confirmationMessage: `Are you sure you want to trigger promo manager pipeline?`
        }
      });

      confirmationDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.promoService.TriggerPromoManagerPipeline().subscribe(response => {
            this.azurePipelineTriggerGetList();
            this.toastr.success('Success', 'Promo manager pipeline is triggered.');
          }, error => {
            this.toastr.error('Error', 'Promo manager pipeline is failed to triggered. Please check if the pipeline is already in progress.');
          });
        }
      });
    }
  }
  azurePipelineTriggerGetList() {
    this.spinner.show();
    this.promoService.AzurePipelineTriggerGetList().subscribe(azpipeline => {
      this.azpipeline = azpipeline;
      this.spinner.hide();
    });
  }

  onHiddenGridReady(params) {
    this.hiddenGridApi = params.api;
  }

  exportPromoGridData() {
    const sortModel = this.gridApi.getSortModel();
    const filterModel = this.gridApi.getFilterModel();
    const requestBody: any = {
      // 0 base index of page number
      isPromo: !this.isDepromo,
      offSet: 0,
      pageSize: this.totalRows,
      ...this.filterEvent
    };

    requestBody.filterModel = getFilterModel(filterModel);
    requestBody.sortModel = getSortModel(sortModel);

    this.promoService.GetPromotionPlannerList(requestBody).subscribe((response: any) => {
      this.gridData = response.data;
      setTimeout(() => {
        this.exportCSVPromoGridData();
      }, 200);
    });
  }

  exportCSVPromoGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'Promotion-Planner',
      colDefs: this.columnDefs,
      allColumns: false,
      columnKeys: _.map(this.columnDefs, a => a.field)
    });
    this.hiddenGridApi.exportDataAsCsv(csvExportParams);
  }

  ngOnDestroy() {
    this.actionSubscription.unsubscribe();
    this.promoSetupSubscription.unsubscribe();
  }

}
