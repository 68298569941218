import { ColDef } from '@ag-grid-enterprise/all-modules';
import * as moment from 'moment';
import * as _ from 'lodash';
import { tooltipcellRenderer } from 'src/common/util/util';

export const paginationPageSize = 50;
export const cacheBlockSize = 50;
export const rowSelection = 'single';

const dateRenderer = (params) => {
  return `${params.value ? (new Date(params.value)).toLocaleDateString() : ''}`;
}

export const defaultColDef = {
  minWidth: 50,
  filter: true,
  sortable: true,
  resizable: true,
  menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
};

export const promotionGridColDefs: ColDef[] = [
  {
    headerName: 'New Item Promotion Name',
    field: 'promotionItemName',
    cellRenderer: 'promotionItemEditRenderer',
    minWidth: 250
  },
  {
    headerName: 'Start Date',
    field: 'startDate',
    type: 'date',
    cellRenderer: dateRenderer,
    minWidth: 150
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    type: 'date',
    cellRenderer: dateRenderer,
    minWidth: 150
  },
  {
    headerName: 'Product Title Release Date',
    field: 'releaseDate',
    type: 'date',
    cellRenderer: dateRenderer,
    minWidth: 250
  },
  {
    headerName: 'Account',
    field: 'accountName',
    minWidth: 150
  }
];


export const promotionDetailGridColDefs = () => {

  const promotionItemDetailColDefs: ColDef[] = [
    {
      headerName: 'Promotion Name',
      field: 'Promo_Name_Group',
      rowGroup: true,
      lockVisible: true,
      hide: true,
    },
    {
      headerName: 'Notes',
      field: 'Notes',
      width: 200,
      editable: (params) => {
        if (params.data.actionName === 'Deleted' || params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected') {
          return false;
        } else {
          return true;
        }
      },
    },
    {
      headerName: 'ePID',
      field: 'SKU',
      width: 140,
    },
    {
      headerName: 'Manufacture Part Number',
      field: 'ManufacturePartNumber',
      width: 235,
    },
    {
      headerName: 'UPC',
      field: 'UPC',
      width: 125,
    },
    {
      headerName: 'Title',
      field: 'Title',
      width: 245,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Year',
      field: 'Year',
      width: 100,
    },
    {
      headerName: 'Make',
      field: 'Make',
      width: 130,
    },
    {
      headerName: 'Model',
      field: 'Model',
      width: 135,
    },
    {
      headerName: 'Brand',
      field: 'Brand',
      width: 150,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Type',
      field: 'Class',
      width: 180,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Subtype',
      field: 'SubClass',
      width: 200,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Cost',
      field: 'Cost',
      cellRenderer: currencyFormatRenderer,
      width: 120,
    },
    {
      headerName: 'Retail',
      field: 'Retail',
      cellRenderer: currencyFormatRenderer,
      width: 120,
    },
    {
      headerName: 'Start Date',
      field: 'Start_Date',
      width: 135,
      type: 'date',
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'End Date',
      field: 'End_Date',
      width: 125,
      type: 'date',
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Forecast',
      field: 'Forecast',
      width: 125,
      cellRenderer: numberFormatRenderer,
      editable: (params) => {
        if (params.data.actionName === 'Deleted' || params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected') {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor',
    },
    {
      headerName: 'Initial Order Quantity',
      field: 'Initial_Order_Quantity',
      width: 215,
      cellRenderer: numberFormatRenderer,
      editable: (params) => {
        if (params.data.actionName === 'Deleted' || params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected') {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor',
    },
    {
      headerName: 'Gross Margin $',
      field: 'Gross_Margin',
      cellRenderer: currencyFormatRenderer,
      width: 160,
    },
    {
      headerName: 'Gross Margin %',
      field: 'Gross_Margin_Percentage',
      cellRenderer: percentageFormatRenderer,
      width: 165,
    },
    {
      headerName: 'Rev',
      field: 'Rev',
      cellRenderer: currencyFormatRenderer,
      width: 115,
    },
    {
      headerName: 'Submitted By',
      field: 'Submitted_By',
      width: 155,
    },
    {
      headerName: 'Submitted Date',
      field: 'Submitted_Date',
      width: 165,
      type: 'date',
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'EOL Date',
      field: 'Algo_Recommended_EOL',
      width: 180,
      type: 'date',
      editable: (params) => {
        if (params.data.actionName === 'Deleted'
          || params.data.actionName === 'Accepted'
          || params.data.actionName === 'Rejected') {
          return false;
        } else {
          return true;
        }
      },
      cellRenderer: dateRenderer,
      cellEditor: 'datePicker'
    },
    {
      headerName: 'Fulfillment Type',
      field: 'Fulfillment_Type',
      width: 180,
    }
  ];
  return promotionItemDetailColDefs;
}

export const approvalForecastExportGridColDefs = () => {
  const approvalGridColDefs: any = [
    {
      headerName: 'Status',
      field: 'Status'
    },
    {
      headerName: 'ePID',
      field: 'SKU'
    },
    {
      headerName: 'Manufacture Part Numbe',
      field: 'ManufacturePartNumber'
    },
    {
      headerName: 'Title',
      field: 'Title',
    },
    {
      headerName: 'Brand',
      field: 'Brand',
    },
    {
      headerName: 'UPC',
      field: 'UPC'
    }
  ];
  for (let weekIndex = 1; weekIndex <= 8; weekIndex++) {
    approvalGridColDefs.push({
      headerName: `Week${weekIndex}`,
      field: `week${weekIndex}`,
      children: [
        {
          headerName: 'Weekly Forecast',
          field: `weeklyForecast${weekIndex}`,
          cellRenderer: forecastRandomValueRenderer,
        }
      ]
    });
  }
  return approvalGridColDefs;
}

export const approvalForecastGridColDefs = (startDate) => {
  const approvalGridColDefs: any = [
    {
      headerName: 'Promotion Name',
      field: 'Promo_Name_Group',
      rowGroup: true,
      lockVisible: true,
      hide: true,
    },
    {
      headerName: 'ePID',
      field: 'SKU',
      pinned: 'left',
      width: 120
    },
    {
      headerName: 'Manufacture Part Number',
      headerTooltip: 'Manufacture Part Number',
      field: 'ManufacturePartNumber',
      pinned: 'left',
      width: 205
    },
    {
      headerName: 'Title',
      field: 'Title',
      pinned: 'left',
      width: 125,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Brand',
      headerTooltip: 'Brand',
      field: 'Brand',
      pinned: 'left',
      width: 95,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'UPC',
      field: 'UPC',
      pinned: 'left',
      width: 90
    }
  ];
  for (let weekIndex = 1; weekIndex <= 12; weekIndex++) {
    const weekDate = addWeeks(new Date(startDate), (weekIndex - 1));
    approvalGridColDefs.push({
      headerName: moment(weekDate).format('MM/DD/YYYY'),
      field: `week${weekIndex}`,
      marryChildren: true,
      headerClass: 'ag-column-group-class',
      cellStyle: { textAlign: 'center', justifyContent: 'center' },
      children: [
        {
          headerName: 'Weekly Forecast',
          field: `${moment(weekDate).format('YYYY-MM-DD')}`,
          width: 130,
          cellRenderer: forecastRandomValueRenderer,
          editable: (params) => {
            if (params.data.actionName === 'Deleted' || params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected') {
              return false;
            } else {
              return true;
            }
          },
          cellEditor: 'numericCellEditor',
          headerClass: 'ag-column-group-first-header',
          cellStyle: { borderLeft: '2px solid lightgray' },
          suppressMenu: true,
        }
      ]
    });
  }
  return approvalGridColDefs;
}

const yesNoMapping = {
  0: 'No',
  1: 'Yes'
};

const lookupValue = (mappings, key) => {
  return mappings[Number(key)];
}

const extractValues = (mappings) => {
  return (Object.keys(mappings)).map(Number);
}

const addWeeks = (dt, n) => {
  return new Date(dt.setDate(dt.getDate() + (n * 7)));
}

const forecastRandomValueRenderer = (params) => {
  return params.node.group ? '' : (Math.random() * 100).toFixed();
}


const percentageFormatRenderer = (params) => {
  return params.value ? `${params.value}%` : params.value;
}

const numberFormatRenderer = (params) => {
  const formatter = new Intl.NumberFormat();

  return params.value ? formatter.format(params.value) : params.value;
}

const currencyFormatRenderer = (params) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return params.value ? formatter.format(params.value) : params.value;
}
