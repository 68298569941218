  <!-- <h2 style="font-weight: bold; text-align: center;">Summary By Year</h2> -->
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <div class="all-show-grid">
          <label class="grid-head">New Store Sales PKR(M)</label>
          <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20'
               groupHeaders suppressRowClickSelection
            toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptions" [headerHeight]="headerHeight" [getRowHeight]="getRowHeight" [columnDefs]="columnDefs"
            [suppressRowTransform]="true" [rowData]="gridList" (gridReady)="onGridReady($event)" [modules]="modules" #grid>
          </ag-grid-angular>
        </div>
      </div>
      <div class="col-md-12 col-lg-6">
        <div class="all-show-grid">
          <label class="grid-head">Variations +/-</label>
          <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20'
               groupHeaders suppressRowClickSelection
            toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptionsVariation" [headerHeight]="headerHeight" [getRowHeight]="getRowHeight" [columnDefs]="columnDefsVariation"
            [suppressRowTransform]="true" [rowData]="gridListVariation" (gridReady)="onGridReady($event)" [modules]="modules" #grid>
          </ag-grid-angular>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <div class="all-show-grid">
          <label class="grid-head">New Store Sales (Percentage)</label>
          <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20'
               groupHeaders suppressRowClickSelection
            toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptionsPercentage" [headerHeight]="headerHeight" [getRowHeight]="getRowHeight" [columnDefs]="columnDefsPercentage"
            [suppressRowTransform]="true" [rowData]="gridListPercentage" (gridReady)="onGridReady($event)" [modules]="modules" #grid>
          </ag-grid-angular>
        </div>
      </div>
      <div class="col-md-12 col-lg-6">
        <div class="all-show-grid">
          <label class="grid-head">New Store Sales Variation (Percentage)</label>
          <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20'
               groupHeaders suppressRowClickSelection
            toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptionsPercentageVariation" [headerHeight]="headerHeight" [getRowHeight]="getRowHeight" [columnDefs]="columnDefsPercentageVariation"
            [suppressRowTransform]="true" [rowData]="gridListPercentageVariation" (gridReady)="onGridReady($event)" [modules]="modules" #grid>
          </ag-grid-angular>
        </div>
      </div>
    </div>
</div>
