<div id="tem-manager">
  <div class="item-search-wrapper">
    <form action="" class="form-fields show-search">
      <div class="form-group">
        <mat-form-field floatLabel="never">
          <input
            matInput
            id="filter-text-box"
            placeholder="Search"
            [(ngModel)] = "searchText"
            (ngModelChange)="onFilterTextBoxChanged($event)"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </form>
    <div class="item-search-btn">
      <img src="../../assets/images/add-icon.svg" alt="" />
      <button
        class="btn btn-primary"
        *ngIf="createPermission"
        (click)="addItem()"
      >
        Add Item
      </button>
    </div>
  </div>
  <!-- <div class="user-page">
    <div class="row" style="margin-top: 15px;">
      <div class="col-md-1 d-block d-sm-block d-md-none mobile-icon">
        <div class="btn-show">
          <mat-icon (click)="addItem()"> <i class="material-icons">
              add_circle
            </i></mat-icon>
        </div>
      </div>
      <div class="col-md-3">

      </div>
      <div class="col-md-9 d-none d-sm-none d-md-block tablet-icon">
        <div class="btn-show">
          <button mat-icon-button color="primary" (click)="addItem()">
            <i class="material-icons">
              add_circle
            </i>
          </button>

        </div>
      </div>
    </div>
  </div> -->

  <div class="all-show-grid items-wrapper-grid">
    <ag-grid-angular
      class="ag-theme-material"
      [gridOptions]="gridOptions"
      [defaultColDef]="defaultColDef"
      [rowModelType]="rowModelType"
      [pagination]="true"
      [headerHeight]="headerHeight"
      [paginationPageSize]="paginationPageSize"
      [cacheBlockSize]="cacheBlockSize"
      [serverSideStoreType]="serverSideStoreType"
      [columnDefs]="columnDefs"
      (gridReady)="onGridReady($event)"
      [modules]="modules"
      #grid
    >
    </ag-grid-angular>
  </div>
  <app-item-form
    (updateList)="updateList()"
    (closePanel)="closeItemPanel()"
    *ngIf="showForm"
  ></app-item-form>
</div>
