
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { USER_INFO } from 'src/common/keys';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { Promotion } from './models/promotion.model';

@Component({
  selector: 'app-promotion-list',
  templateUrl: './promotion-list.component.html',
  styleUrls: ['./promotion-list.component.scss']
})
export class PromotionListComponent implements OnInit {
  public modules = AllCommunityModules;
  public showPromoForm = false;
  public columnDefs = [
    {
      headerName: 'Promo Name',
      field: 'name',
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openPromoForm.bind(this),
      },
    },


    {
      headerName: 'Promo Start Date',
      field: 'startDate',
      cellRenderer: (params) => {
        return params.value
          ? formatDate(params.value, 'dd MMM, y', 'en')
          : '';
      }
    },
    {
      headerName: 'Promo End Date',
      field: 'endDate',
      cellRenderer: (params) => {
        return params.value
          ? formatDate(params.value, 'dd MMM, y', 'en')
          : '';
      }
    },
    {
      headerName: 'Promo Event Type',
      field: 'promoEventType'
    },
    {
      headerName: 'Offer Type',
      field: 'offerType'
    },

    {
      headerName: 'Status',
      field: 'active',
      cellRenderer: params => {
        return params.value ? 'Active' : 'InActive'
      }
    },


  ];
  public gridOptions: any;
  gridApi: any;
  defaultColDef: any;
  headerHeight = 45;
  rowModelType: string;
  serverSideStoreType: string;
  paginationPageSize: number;
  cacheBlockSize: number;
  promotionList = [];
  promotionModel = [];
  isEdit: boolean;
  viewPermission: any;
  editPermission: any;
  createPermission: any;
  constructor(readonly configurationService: ConfigurationService, public storage: LocalstorageService) {
    this.gridOptions = {
      frameworkComponents: {
        'nameRenderer': NameRendererComponent,
      },
      pagination: true,
      paginationPageSize: 15
    };

    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true
    }
  }

  ngOnInit() {
    let userTeams = this.storage.get(USER_INFO).teams;
    userTeams.forEach(element => {
      element.teamScreenGroup.forEach(group => {
        if (group.screenGroupId == 7 && group.viewPermission && !this.viewPermission) {
          this.viewPermission = true
        }
        if (group.screenGroupId == 7 && group.editPermission && !this.editPermission) {
          this.editPermission = true
        }
        if (group.screenGroupId == 7 && group.createPermission && !this.createPermission) {
          this.createPermission = true
        }
      });
    });
    this.paginationPageSize = 100;
    this.cacheBlockSize = 20;
    this.getItemAccountList();
  }
  public getItemAccountList = () => {
    const params = {
      offset: 0,
      pageSize: 15000,
      active: true
    }
    this.configurationService.PromotionPlannerGetAll(params).subscribe(res => {
      this.promotionList = (res.data || []);
    })
  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  openPromoForm(rowModel) {
    let data
    if (rowModel && rowModel.rowData) {
      this.isEdit = true
      data = rowModel.rowData
    } else {
      this.isEdit = false
      data = new Promotion()
    }

    this.configurationService.promotionModel.next({ isEdit: this.isEdit, data: data, editPermission: this.editPermission })
    this.showPromoForm = true;
  }
  public closePanel = () => {
    this.showPromoForm = false;
  }
  onGridReady(params: any) {
    this.gridApi = params.api
  }
  updateList() {
    this.showPromoForm = false
    this.getItemAccountList()
  }
  public exportExcel = () => {
    let params = {
      fileName: 'promotions.csv',
      columnSeparator: ','
    };
    this.gridApi.exportDataAsCsv(params);

  }
}
