import { Component, OnInit } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { throwError } from 'rxjs';
import { ConfigurationService } from 'src/services/configuration.service';
@Component({
  selector: 'app-same-store-summary',
  templateUrl: './same-store-summary.component.html',
  styleUrls: ['./same-store-summary.component.css']
})
export class SameStoreSummaryComponent implements OnInit {

  public columnDefs = [
    {
      headerName: 'Store',
      field: 'sameStoreName',
      pinned: 'left',
      width: 120
    },
    {
      headerName: 'Channel',
      field: 'channelName',
      pinned: 'left',
      width: 120
    },
  ];
  public columnDefsVariation = [];
  public columnDefsPercentage = [];
  public columnDefsPercentageVariation = [];
  private gridApi: any;
  public sameStoreList = [];
  public gridOptions: any;
  public getRowHeight;
  public  headerHeight;
  public gridOptionsVariation: any;
  public gridOptionsPercentage: any;
  public gridOptionsPercentageVariation: any;
  public sameStoreHeader = [];
  public gridData = [];
  public gridList = [];
  public gridListVariation = [];
  public gridListPercentage = [];
  public gridListPercentageVariation = [];
  public channelList = [];
  public modules = AllCommunityModules;
  constructor(public configurationService: ConfigurationService) {
    this.gridOptions = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.gridOptionsVariation = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.gridOptionsPercentage = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.gridOptionsPercentageVariation = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.headerHeight = 30;
    this.gridOptions.getRowHeight = function(params) {
      return 30;
    };
    this.gridOptionsPercentage.getRowHeight = function(params) {
      return 30;
    };
    this.gridOptionsVariation.getRowHeight = function(params) {
      return 30;
    };
    this.gridOptionsPercentageVariation.getRowHeight = function(params) {
      return 30;
    };
  }

  ngOnInit() {
    this.populateList();
  }
  public populateList = () => {
    this.configurationService.getAllChannels({ clientId: 1 }).subscribe(res => {
      this.channelList = res.data;
      // Get All Same Stores
      this.channelList.forEach(async (channel) => {
        // this.sameStoreList = await this.configurationService.getSameStoreRowList({ clientId: 1, channelId: channel.channelId }).toPromise().catch(error => { throwError(error) });
        // this.sameStoreList = this.sameStoreList ? (this.sameStoreList as any).pivotList.map(store => ({ ...store, ...store.NewFormRow })) || [] : [];
        const sameStoreObject = { channelName: channel.channelName };
        const sameStoreTempList = [];
        const sameStoreList = await this.configurationService.getSameStoreHeaderList({ clientId: 1, active: true, channelId: channel.channelId }).toPromise().catch(error => {
          return throwError(error);
        });
        // Tenure Type List
        if (sameStoreList && sameStoreList.length > 0) {
          this.sameStoreHeader = sameStoreList;
          this.sameStoreHeader.forEach(store => {
            const year = store && store.name ? store.name.split('-')[1] : '';
            if (year && !sameStoreTempList.includes(year)) {
              sameStoreTempList.push({ field: `SameStore${year}`, name: year });
            }
          });
          if (sameStoreTempList.length > 0) {
            sameStoreTempList.forEach(column => {
              if (!this.columnDefs.find(columnDef => columnDef && columnDef.field === column.field)) {
                (this.columnDefs as any).push({
                  headerName: column.name,
                  field: column.field,
                  width: 80
                });
              }
              sameStoreObject[column.field] = 0;
            });
            if (this.columnDefs.length > 1) {
              this.gridOptions.api.setColumnDefs(this.columnDefs);
            }
          }
        }
        // Goals
        const values = await this.configurationService.getSameStoreFormList({ clientId: 1, channelId: channel.channelId }).toPromise().catch(error => {
          if (this.gridList.length === this.channelList.length) {
            this.mapTotalObject();
          }
          return throwError(error);
        });
        if (values && values.pivotList && values.pivotList.length > 0) {
          const gridData = values.pivotList;
          this.gridData.push(values.pivotList);
          this.gridData = (this.gridData as any).flat();
          gridData.forEach(goal => {
            this.columnDefs.forEach(column => {
              Object.keys(goal).forEach(key => {
                if (key.substring(7, 11) === column.headerName) {
                  if (column.field in sameStoreObject) {
                    sameStoreObject[column.field] += goal[key] ? goal[key] : 0;
                    sameStoreObject['sameStoreName'] = goal['SameStoreRow']['name'] ? goal['SameStoreRow']['name'] : 0;
                  }
                }
              });
            });
          });
          this.gridList.push(sameStoreObject);
          if (this.gridList.length === this.channelList.length) {
            this.mapTotalObject();
          }
        } else {
          this.gridList.push({ channelName: channel.channelName });
          if (this.gridList.length === this.channelList.length) {
            this.mapTotalObject();
          }
        }
        this.gridOptions.api.setRowData(this.gridList);
        console.log(this.gridList);
      });
    });
  }
  public mapTotalObject = () => {
    const totalObject: any = {};
    this.gridList.forEach(row => {
      Object.keys(row).forEach(key => {
        if (key !== 'sameStoreName' && key !== 'channelName') {
          if (key in row) {
            totalObject[key] = totalObject[key] !== null && totalObject[key] !== undefined ? totalObject[key] + row[key] : row[key];
          }
        }
      });
      totalObject.sameStoreName = 'Total';
    });
    this.gridList.push(totalObject);
    this.gridOptions.api.setRowData(this.gridList);
    this.mapPercentageList(JSON.parse(JSON.stringify(this.gridList)));
    this.mapVariationList(JSON.parse(JSON.stringify(this.gridList)));
  }
  public mapPercentageList = (goalList: any) => {
    const keys = ['sameStoreName', 'channelName'];
    for (let i = 0; i < goalList.length; i++) {
      let keyList = Object.keys(goalList[i]);
      keyList = keyList.filter(key => !keys.includes(key));
      for (let j = 0; j < keyList.length; j++) {
        if (goalList[i][keyList[j]] && goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = goalList[i][keyList[j + 1]] > 0 ? (((goalList[i][keyList[j]] - goalList[i][keyList[j + 1]]) / goalList[i][keyList[j + 1]]) * 100) : goalList[i][keyList[j + 1]];
        } else if (goalList[i][keyList[j]] && !goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = keyList[j] !== 'channelName' && keyList[j] !== 'sameStoreName' ? 0 : goalList[i][keyList[j]] ? goalList[i][keyList[j]] : 0;
        }
      }
      console.log(goalList[i]);
      this.gridListPercentage.push(goalList[i]);
      this.gridOptionsPercentage.api.setRowData(this.gridListPercentage);
    }
    this.columnDefsPercentage = this.columnDefs.map(column => (column.field !== 'channelName' && column.field !== 'sameStoreName' ? {
      ...column,
      valueFormatter: this.currencyFormatter
    } : { ...column }));
  }
  public mapVariationList = (goalList: any) => {
    const keys = ['sameStoreName', 'channelName'];
    for (let i = 0; i < goalList.length; i++) {
      let keyList = Object.keys(goalList[i]);
      keyList = keyList.filter(key => !keys.includes(key));
      for (let j = 0; j < keyList.length; j++) {
        if (goalList[i][keyList[j]] && goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = goalList[i][keyList[j + 1]] > 0 ? ((goalList[i][keyList[j]] - goalList[i][keyList[j + 1]])) : goalList[i][keyList[j + 1]];
        } else if (goalList[i][keyList[j]] && !goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = keyList[j] !== 'channelName' ? goalList[i][keyList[j]] : goalList[i][keyList[j]] ? goalList[i][keyList[j]] : 0;
        }
      }
      this.gridListVariation.push(goalList[i]);
      this.gridOptionsVariation.api.setRowData(this.gridListVariation);
    }
    this.mapPercentageListVariation(JSON.parse(JSON.stringify((this.gridListVariation))));
    this.columnDefsVariation = this.columnDefs.map(column => (column.field !== 'channelName' && column.field !== 'sameStoreName' ? {
      ...column,
      valueFormatter: this.currencyFormatterVaraition
    } : { ...column }));
  }
  public mapPercentageListVariation = (goalList: any) => {
    const keys = ['sameStoreName', 'channelName'];
    for (let i = 0; i < goalList.length; i++) {
      let keyList = Object.keys(goalList[i]);
      keyList = keyList.filter(key => !keys.includes(key));
      for (let j = 0; j < keyList.length; j++) {
        if (goalList[i][keyList[j]] && goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = goalList[i][keyList[j + 1]] > 0 ? (((goalList[i][keyList[j]] - goalList[i][keyList[j + 1]]) / goalList[i][keyList[j + 1]]) * 100) : goalList[i][keyList[j + 1]];
        } else if (goalList[i][keyList[j]] && !goalList[i][keyList[j + 1]]) {
          goalList[i][keyList[j]] = keyList[j] !== 'channelName' ? 0 : goalList[i][keyList[j]] ? goalList[i][keyList[j]] : 0;
        }
      }
      this.gridListPercentageVariation.push(goalList[i]);
      this.gridOptionsPercentageVariation.api.setRowData(this.gridListPercentageVariation);
    }
    this.columnDefsPercentageVariation = this.columnDefs.map(column => (column.field !== 'channelName' && column.field !== 'sameStoreName' ? {
      ...column,
      valueFormatter: this.currencyFormatter
    } : { ...column }));
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  currencyFormatter(params) {
    // return `${formatNumber(params.value, 'en', '2.0-2')}`;
    return `${Math.floor(typeof params.value === 'number' ? params.value : 0)}%`;
  }
  currencyFormatterVaraition(params) {
    return `${Math.floor(typeof params.value === 'number' ? params.value : 0)}`;
  }
}
