import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { USER_ID } from '../../../../../common/keys';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { LocalstorageService } from '../../../../../services/localstorage.service';
import { Workbook, Column } from 'exceljs';
import * as fs from 'file-saver';
import { FB_Template } from '../../global.models';
import { addCommas } from 'src/common/functions';
@Component({
  selector: 'app-planner-export',
  templateUrl: './export-all-template.component.html',
  styleUrls: ['./export-all-template.component.scss'],
})
export class ExportAllTemplateComponent implements OnInit {
  measureList;
  forecastList;
  accountList;
  itemsList;
  calendarList;
  gridListUI;
  filteredMeasures;
  dropdownObjects;
  selectedMeasures;
  fbTemplateId;
  selectedDistibutor;
  weeks = [];
  invItemIds;
  exportTemplates = [
    { name: 'Channel/Brand/Fiscal Week', checked: false },
    { name: 'Channel/Product Group/Fiscal Week', checked: false },
    { name: 'Channel/Product Family/Fiscal Week', checked: false },
    { name: 'Channel/Parent Product/Fiscal Week', checked: false },
    { name: 'Channel/Item/Fiscal Week', checked: false },
    { name: 'Region/Brand/Fiscal Week', checked: false },
    { name: 'Region/Product Group/Fiscal Week', checked: false },
    { name: 'Region/Product Family/Fiscal Week', checked: false },
  ];
  constructor(
    public dialogRef: MatDialogRef<ExportAllTemplateComponent>,
    public spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public config: ConfigurationService,
    public storage: LocalstorageService
  ) {
    this.measureList = data.measureList;
    this.forecastList = data.forecastList;
    this.accountList = this.data.accountList;
    this.itemsList = data.itemsList;
    this.gridListUI = data.gridListUI;
    this.calendarList = data.calendarList;
    this.dropdownObjects = data.dropdownObjects;
    this.fbTemplateId = data.fbTemplateId;
    this.weeks = data.weeks;
    this.selectedDistibutor = data.selectedDistibutor;
    this.invItemIds = data.invItemIds;
  }

  ngOnInit() {}
  onChnage(event) {
    this.filteredMeasures = this.forecastList.filter(
      (x) => x.measureId == event.value.measureId
    );
  }
  checkWhichToExport() {
    if (this.data.fbTemplateId == FB_Template.Retail_Direct) {
      this.exportAll();
    } else if (this.data.fbTemplateId == FB_Template.Retail_InDirect) {
      this.exportAllIR();
    } else {
      this.exportFbForecast();
    }
  }
  exportAll = () => {
    const calendarIdsList = [];
    this.dropdownObjects.calendarIds.forEach((element) => {
      try {
        calendarIdsList.push(...element);
      } catch {
        calendarIdsList.push(element);
      }
    });
    const selectedTimeFrames = this.calendarList.filter((el) =>
      calendarIdsList.includes(el.calendarId)
    );
    let exportList = [];
    this.dropdownObjects.channelIds.forEach((channelId) => {
      this.dropdownObjects.itemIds.forEach((itemId) => {
        calendarIdsList.forEach((calendar) => {
          this.selectedMeasures.forEach((measure) => {
            const foundSKU = this.itemsList.find(
              (row) => row.itemManagerId == itemId
            );
            const foundAccount = this.accountList.find(
              (row) => row.itemAccountId == channelId
            );
            const foundMeasure = this.measureList.find(
              (row) => row.code === measure.code
            );
            const exportObject = {
              measureName: foundMeasure.measureName,
              measureCode: foundMeasure.code,
              measureId: foundMeasure.measureId,
              itemName: foundSKU.sku,
              description: foundSKU.description,
              account: foundAccount.salesforceId,
              accountName: foundAccount.accountName,
              value: 0,
              calendarId: calendar,
              itemId: itemId,
              channelId: channelId,
            };
            exportList.push(exportObject);
          });
        });
      });
    });
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('RETAIL_DIRECT');

    const columns = [
      {
        header: 'MeasureName',
        key: 'measureName',
        width: 50,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'SKU',
        key: 'itemName',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'Description',
        key: 'description',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'AccountName',
        key: 'accountName',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'Account',
        key: 'account',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
    ];
    exportList.forEach((el) => {
      this.weeks.forEach((week) => {
        if (el.calendarId === week.calendarId) {
          if (el.measureCode.indexOf('Comment') < 0) {
            week['measures'][el.measureCode] = +week['measures'][el.measureCode]
              .toString()
              .replaceAll(',', '');
            el[new Date(week.weekStartDate).toLocaleDateString()] =
              week['measures'][el.measureCode];
          } else if (el.measureCode.indexOf('Comment') > 0) {
            el[new Date(week.weekStartDate).toLocaleDateString()] =
            week['measures'][el.measureCode];
          }
        }
      });
    });
    let commulativeList = [];
    exportList.forEach((el) => {
      const cummulativeObject = {};
      exportList
        .filter(
          (row) =>
            row.itemId == el.itemId &&
            row.channelId === el.channelId &&
            row.measureId === el.measureId
        )
        .forEach((e) => {
          Object.keys(e).forEach((key) => {
            if (new Date(key).toLocaleDateString() !== 'Invalid Date') {
              cummulativeObject[key] = cummulativeObject[key]
                ? cummulativeObject[key]
                : 0;
              e[key] = e[key] ? e[key] : 0;
              if (e.measureCode.indexOf('Comment') > 0) {
                cummulativeObject[key] = e[key];
              } else {
                cummulativeObject[key] += e[key];
              }
            } else {
              cummulativeObject[key] = e[key];
            }
          });
        });
      commulativeList.push(cummulativeObject);
    });
    commulativeList = commulativeList.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.measureId === thing.measureId)
    );
    selectedTimeFrames.forEach((el) => {
      const calendar = {
        header: new Date(el.weekStartDate).toLocaleDateString(),
        key: new Date(el.weekStartDate).toLocaleDateString(),
        width: 20,
        style: {
          font: { bold: true },
          numFmt: '#,##',
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
        },
      };
      columns.push(calendar as any);
    });
    worksheet.columns = columns as any;
    commulativeList.forEach((e) => {
      worksheet.addRow(e, 'n');
    });
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, `Forecast${new Date().toISOString()}.xlsx`);
      this.dialogRef.close();
    });
  };
  exportAllIR = () => {
    const calendarIdsList = [];
    this.dropdownObjects.calendarIds.forEach((element) => {
      try {
        calendarIdsList.push(...element);
      } catch {
        calendarIdsList.push(element);
      }
    });
    const selectedTimeFrames = this.calendarList.filter((el) =>
      calendarIdsList.includes(el.calendarId)
    );
    let exportListDisti = [];
    let exportListAccount = [];
    let exportListSubTotal = [];
    let exportListDistiAccTotal = [];
    const currentItem = this.itemsList.find(
      (row) => row.itemManagerId == +this.invItemIds.toString()
    );
      calendarIdsList.forEach((calendar) => {
        this.selectedMeasures.forEach((measure) => {
          const foundMeasure = this.measureList.find(
            (row) => row.code === measure.code && measure.isDisti
          );
          if (foundMeasure && currentItem) {
            const exportObject = {
              measureName: foundMeasure.measureName,
              measureCode: foundMeasure.code,
              measureId: foundMeasure.measureId,
              itemName: currentItem.sku,
              description: currentItem.description,
              account: '',
              accountName: '',
              value: 0,
              calendarId: calendar,
              itemId: currentItem.itemManagerId,
              type: 'Distributor',
              distributorName: this.selectedDistibutor.distributorName,
              salesforceId: this.selectedDistibutor.distributoR_SFID
            };
            exportListDisti.push(exportObject);
          }
        });
      });
    this.dropdownObjects.channelIds.forEach((channelId) => {
        calendarIdsList.forEach((calendar) => {
          this.selectedMeasures.forEach((measure) => {
            const foundAccount = this.accountList.find(
              (row) => row.itemAccountId == channelId
            );
            const foundMeasure = this.measureList.find(
              (row) => row.code === measure.code && measure.isAccount
            );
            if (foundMeasure && currentItem) {
              const exportObject = {
                measureName: foundMeasure.measureName,
                measureCode: foundMeasure.code,
                measureId: foundMeasure.measureId,
                itemName: currentItem.sku,
                description: currentItem.description,
                account: foundAccount.salesforceId,
                accountName: foundAccount.accountName,
                value: 0,
                calendarId: calendar,
                itemId: currentItem.itemManagerId,
                channelId: channelId,
                type: 'Account',
                distributorName: this.selectedDistibutor.distributorName,
                salesforceId: foundAccount.salesforceId,
              };
              exportListAccount.push(exportObject);
            }
          });
        });
    });
      calendarIdsList.forEach((calendar) => {
        this.selectedMeasures.forEach((measure) => {
          const foundMeasure = this.measureList.find(
            (row) => row.code === measure.code && measure.isAccSubtotal
          );
          if (foundMeasure && currentItem) {
            const exportObject = {
              measureName: foundMeasure.measureName,
              measureCode: foundMeasure.code,
              measureId: foundMeasure.measureId,
              itemName: currentItem.sku,
              description: currentItem.description,
              account: '',
              accountName: '',
              value: 0,
              calendarId: calendar,
              itemId: currentItem.itemManagerId,
              type: 'Distributor',
              distributorName: this.selectedDistibutor.distributorName,
              salesforceId: this.selectedDistibutor.distributoR_SFID
            };
            exportListSubTotal.push(exportObject);
          }
        });
    });
      calendarIdsList.forEach((calendar) => {
        this.selectedMeasures.forEach((measure) => {
          const foundMeasure = this.measureList.find(
            (row) => row.code === measure.code && measure.isDistAccTotal
          );
          if (foundMeasure && currentItem) {
            const exportObject = {
              measureName: foundMeasure.measureName,
              measureCode: foundMeasure.code,
              measureId: foundMeasure.measureId,
              itemName: currentItem.sku,
              description: currentItem.description,
              account: '',
              accountName: '',
              value: 0,
              calendarId: calendar,
              itemId: currentItem.itemManagerId,
              type: 'Distributor',
              distributorName: this.selectedDistibutor.distributorName,
              salesforceId: this.selectedDistibutor.distributoR_SFID
            };
            exportListDistiAccTotal.push(exportObject);
          }
        });
      });
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('RETAIL_INDIRECT');

    const columns = [
      {
        header: 'MeasureName',
        key: 'measureName',
        width: 50,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'SKU',
        key: 'itemName',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'Description',
        key: 'description',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'Disti/Account',
        key: 'type',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'Distributor',
        key: 'distributorName',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'AccountName',
        key: 'accountName',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
      {
        header: 'SFID',
        key: 'salesforceId',
        width: 20,
        style: {
          font: { bold: true },
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
          fill: {
            type: 'pattern',
            gradient: 'lightGray',
            bgColor: { argb: '808080' },
          },
        },
      },
    ];
    exportListDisti.forEach((el) => {
      this.weeks.forEach((week) => {
        if (el.calendarId === week.calendarId) {
          if (el.measureCode.indexOf('Comment') < 0) {
            week['riDistiMeasure'][el.measureCode] = +week['riDistiMeasure'][
              el.measureCode
            ]
              .toString()
              .replaceAll(',', '');
            el[new Date(week.weekStartDate).toLocaleDateString()] =
              week['riDistiMeasure'][el.measureCode];
          }  else if (el.measureCode.indexOf('Comment') > 0) {
            el[new Date(week.weekStartDate).toLocaleDateString()] =
            week['riDistiMeasure'][el.measureCode];
          }
        }
      });
    });
    exportListSubTotal.forEach((el) => {
      this.weeks.forEach((week) => {
        if (el.calendarId === week.calendarId) {
          if (el.measureCode.indexOf('Comment') < 0) {
            week['riSubTotalMeasures'][el.measureCode] = +week[
              'riSubTotalMeasures'
            ][el.measureCode]
              .toString()
              .replaceAll(',', '');
            el[new Date(week.weekStartDate).toLocaleDateString()] =
              week['riSubTotalMeasures'][el.measureCode];
          }  else if (el.measureCode.indexOf('Comment') > 0) {
            el[new Date(week.weekStartDate).toLocaleDateString()] =
            week['riSubTotalMeasures'][el.measureCode];
          }
        }
      });
    });
    exportListDistiAccTotal.forEach((el) => {
      this.weeks.forEach((week) => {
        if (el.calendarId === week.calendarId) {
          if (el.measureCode.indexOf('Comment') < 0) {
            week['riTotalMeasures'][el.measureCode] = +week['riTotalMeasures'][
              el.measureCode
            ]
              .toString()
              .replaceAll(',', '');
            el[new Date(week.weekStartDate).toLocaleDateString()] =
              week['riTotalMeasures'][el.measureCode];
          }  else if (el.measureCode.indexOf('Comment') > 0) {
            el[new Date(week.weekStartDate).toLocaleDateString()] =
                week['riTotalMeasures'][el.measureCode];
          }
        }
      });
    });
    exportListAccount.forEach((el) => {
      this.weeks.forEach((week) => {
        if (el.calendarId === week.calendarId) {
          if (el.measureCode.indexOf('Comment') < 0) {
            const curr =  week.riAccountMeasures.find((acc) => acc.channelId === el.channelId);
              curr['measures'][el.measureCode] = +curr['measures'][el.measureCode]
                .toString()
                .replaceAll(',', '');
              el[new Date(week.weekStartDate).toLocaleDateString()] =
                curr['measures'][el.measureCode];
          } else if (el.measureCode.indexOf('Comment') > 0) {
            const curr =  week.riAccountMeasures.find((acc) => acc.channelId === el.channelId);
            el[new Date(week.weekStartDate).toLocaleDateString()] = curr['measures'][el.measureCode];
          }
        }
      });
    });
    let commulativeListDisti = [];
    let commulativeListSubTotal = [];
    let commulativeListAccTotal = [];
    let commulativeListAccount = [];
    exportListDisti.forEach((el) => {
      const cummulativeObject = {};
      exportListDisti
        .filter(
          (row) => row.itemId == el.itemId && row.measureId === el.measureId
        )
        .forEach((e) => {
          Object.keys(e).forEach((key) => {
            if (new Date(key).toLocaleDateString() !== 'Invalid Date') {
              cummulativeObject[key] = cummulativeObject[key]
                ? cummulativeObject[key]
                : 0;
              e[key] = e[key] ? e[key] : 0;
              if (e.measureCode.indexOf('Comment') > 0) {
                cummulativeObject[key] = e[key];
              } else {
                cummulativeObject[key] += e[key];
              }
            } else {
              cummulativeObject[key] = e[key];
            }
          });
        });
      commulativeListDisti.push(cummulativeObject);
    });
    exportListSubTotal.forEach((el) => {
      const cummulativeObject = {};
      exportListSubTotal
        .filter(
          (row) => row.itemId == el.itemId && row.measureId === el.measureId
        )
        .forEach((e) => {
          Object.keys(e).forEach((key) => {
            if (new Date(key).toLocaleDateString() !== 'Invalid Date') {
              cummulativeObject[key] = cummulativeObject[key]
                ? cummulativeObject[key]
                : 0;
              e[key] = e[key] ? e[key] : 0;
              if (e.measureCode.indexOf('Comment') > 0) {
                cummulativeObject[key] = e[key];
              } else {
                cummulativeObject[key] += e[key];
              }
            } else {
              cummulativeObject[key] = e[key];
            }
          });
        });
      commulativeListSubTotal.push(cummulativeObject);
    });
    exportListDistiAccTotal.forEach((el) => {
      const cummulativeObject = {};
      exportListDisti
        .filter(
          (row) => row.itemId == el.itemId && row.measureId === el.measureId
        )
        .forEach((e) => {
          Object.keys(e).forEach((key) => {
            if (new Date(key).toLocaleDateString() !== 'Invalid Date') {
              cummulativeObject[key] = cummulativeObject[key]
                ? cummulativeObject[key]
                : 0;
              e[key] = e[key] ? e[key] : 0;
              if (e.measureCode.indexOf('Comment') > 0) {
                cummulativeObject[key] = e[key];
              } else {
                cummulativeObject[key] += e[key];
              }
            } else {
              cummulativeObject[key] = e[key];
            }
          });
        });
      commulativeListAccTotal.push(cummulativeObject);
    });
    exportListAccount.forEach((el) => {
      const cummulativeObject = {};
      exportListAccount
        .filter(
          (row) =>
            row.itemId == el.itemId &&
            row.measureId === el.measureId &&
            row.channelId === el.channelId
        )
        .forEach((e) => {
          Object.keys(e).forEach((key) => {
            if (new Date(key).toLocaleDateString() !== 'Invalid Date') {
              cummulativeObject[key] = cummulativeObject[key]
                ? cummulativeObject[key]
                : 0;
              e[key] = e[key] ? e[key] : 0;
              if (e.measureCode.indexOf('Comment') > 0) {
                cummulativeObject[key] = e[key];
              } else {
                cummulativeObject[key] += e[key];
              }
            } else {
              cummulativeObject[key] = e[key];
            }
          });
        });
      commulativeListAccount.push(cummulativeObject);
    });
    commulativeListDisti = commulativeListDisti.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.measureId === thing.measureId)
    );
    commulativeListSubTotal = commulativeListSubTotal.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.measureId === thing.measureId)
    );
    commulativeListAccTotal = commulativeListAccTotal.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.measureId === thing.measureId)
    );
    commulativeListAccount = commulativeListAccount.filter(
      (thing, index, self) =>
        index ===
        self.findIndex(
          (t) => t.measureId === thing.measureId && t.account === thing.account
        )
    );
    selectedTimeFrames.forEach((el) => {
      const calendar = {
        header: new Date(el.weekStartDate).toLocaleDateString(),
        key: new Date(el.weekStartDate).toLocaleDateString(),
        width: 20,
        style: {
          font: { bold: true },
          numFmt: '#,##',
          border: {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          },
        },
      };
      columns.push(calendar as any);
    });
    let commulativeList = [
      ...commulativeListDisti,
      ...commulativeListAccount,
      ...commulativeListSubTotal,
      ...commulativeListAccTotal,
    ];
    worksheet.columns = columns as any;
    commulativeList.forEach((e) => {
      worksheet.addRow(e, 'n');
    });
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, `Forecast${new Date().toISOString()}.xlsx`);
      this.dialogRef.close();
    });
  };
  exportFbForecast() {
    const model = {
      userId: this.storage.get(USER_ID),
      userFBForecastId: this.data.userFBForecastId || 0,
      versionTypeId: this.data.versionTypeId,
      itemIds: this.dropdownObjects.itemIds.toString(),
      channelIds: this.dropdownObjects.channelIds?.toString(),
      calendarIds: this.dropdownObjects.calendarIds.flat().toString(),
      measureIds: this.selectedMeasures.map((row) => row.measureId).toString(),
      fbTemplateId: this.fbTemplateId,
    };
    this.spinner.show();
    this.config.FbForecastExport(model).subscribe(
      (res) => {
        let dataType = res.body.type;
        let binaryData = [];
        binaryData.push(res.body);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        if (`Forecast`) downloadLink.setAttribute('download', `Forecast.xlsx`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.dialogRef.close();
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
}
