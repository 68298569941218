import { Component, OnInit } from '@angular/core';
import { generateGridColumns, paginationPageSize, rowSelection } from '../../ag-grid/gridOptions';
import { itemsData } from '../../MockData';
import { AllModules } from '@ag-grid-enterprise/all-modules';

@Component({
  selector: 'app-replenishment-item-view',
  templateUrl: './item-view.component.html',
  styleUrls: ['./item-view.component.scss']
})
export class ItemViewComponent implements OnInit {

  public gridOptions: any;
  paginationPageSize: number;
  rowModelType: string;
  itemsGridData: any[];
  columnDefs: any[];
  rowSelection: string;

  modules = AllModules;

  constructor() { }

  ngOnInit(): void {
    this.rowModelType = 'clientSide';
    this.paginationPageSize = paginationPageSize;
    this.gridOptions = {
      frameworkComponents: {},
      pagination: true,
      paginationPageSize: this.paginationPageSize,
    };
    this.rowSelection = rowSelection;
    this.itemsGridData = itemsData;
    const keys = Object.keys(this.itemsGridData[0]);
    this.columnDefs = generateGridColumns(keys);
  }

}
