<div *ngIf="userTeam !== INVALID_TEAM" class="container-fluid item-page" style="margin-top: 15px;">

  <app-product-filters *ngIf="!isMasterPage" (filterEvent)="applyFilters($event)">
  </app-product-filters>
  <div class="row">
    <div class="col-md-1 d-block d-sm-block d-md-none mobile-icon">
      <div class="btn-show">
        <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="primary"
          (click)="openColumnDialog()">
          <i class="material-icons">
            ballot
          </i>
        </button>
        <button matTooltip="Export" class="grid-bar-icon" mat-icon-button color="primary" (click)="onExportGridData()">
          <i class="material-icons">
            insert_drive_file
          </i>
        </button>
        <button class="import-export-button" mat-flat-button color="accent"
          [matMenuTriggerFor]="importExportMenu">Export/Import</button>
        <mat-menu #importExportMenu="matMenu">
          <button mat-menu-item (click)="exportGridTemplate()">Export</button>
          <button mat-menu-item (click)="importData()">Import
            <input class="hidden" type="file" id="upload-child-data" name="upload-file" accept=".csv"
              (change)="validateUploadedCSV($event)">
          </button>
        </mat-menu>
        <button matTooltip="Add Item" mat-icon-button color="primary" (click)="openItemDialog()">
          <i class="material-icons">
            add_circle
          </i>
        </button>
      </div>
    </div>
    <div class="col-md-12 d-none d-sm-none d-md-block tablet-icon">
      <div class="btn-show">
        <div class="section-title">Item List</div>
        <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="primary"
          (click)="openColumnDialog()">
          <i class="material-icons">
            ballot
          </i>
        </button>
        <button matTooltip="Export" class="grid-bar-icon" mat-icon-button color="primary" (click)="onExportGridData()">
          <i class="material-icons">
            insert_drive_file
          </i>
        </button>
        <button class="import-export-button" mat-flat-button color="accent"
          [matMenuTriggerFor]="importExportMenu">Export/Import</button>
        <mat-menu #importExportMenu="matMenu">
          <button mat-menu-item (click)="exportGridTemplate()">Export</button>
          <button mat-menu-item (click)="importData()">Import
            <input class="hidden" type="file" id="upload-child-data" name="upload-child-item-file" accept=".csv"
              (change)="validateUploadedCSV($event)">
          </button>
        </mat-menu>
        <button matTooltip="Add Item" mat-icon-button color="primary" (click)="openItemDialog()">
          <i class="material-icons">
            add_circle
          </i>
        </button>
      </div>
    </div>
  </div>

  <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material" 
      [suppressMenuHide]="true" 
      groupHeaders 
      suppressRowClickSelection
      toolPanelSuppressGroups 
      toolPanelSuppressValues
      (sortChanged)="onGridStateChange($event)"
      (columnVisible)="onGridStateChange($event)"
      (columnMoved)="onGridStateChange($event)"
      (columnPinned)="onGridStateChange($event)" 
      [frameworkComponents]="frameworkComponents"
      [gridOptions]="gridOptions" 
      [columnDefs]="gridColumnDefs" 
      [defaultColDef]="defaultColDef"
      [rowModelType]="rowModelType"
      [serverSideStoreType]="serverSideStoreType" 
      [pagination]="true" 
      [paginationPageSize]="paginationPageSize" 
      [cacheBlockSize]="cacheBlockSize"
      (gridReady)="onGridReady($event)" 
      [modules]="modules" #childGrid>
    </ag-grid-angular>
  </div>
</div>

<app-privilege-error *ngIf="userTeam === INVALID_TEAM"></app-privilege-error>