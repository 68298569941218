import { Component, OnInit, Input } from '@angular/core';
import { Report } from 'powerbi-client';
import { NgxPowerBiService } from 'ngx-powerbi';
import * as models from 'powerbi-models';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BotService } from 'src/services/bot.service';
import { UsersService } from 'src/services/User-services/user-services';
import { INVALID_TEAM, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { ItemManagerService } from 'src/services/Item-Management-Services';

@Component({
  selector: 'app-dynamic-report',
  templateUrl: './dynamic-report.component.html',
  styleUrls: ['./dynamic-report.component.css']
})
export class DynamicReportComponent implements OnInit {

  @Input() data: any;
  txtEmbedGroupId = 'f38294a7-1406-40d5-a474-6323eace60f2';
  txtEmbedReportId = '1c95231a-e01e-46b5-ac0c-5fef0741c402';
  txtEmbedDatasetId = 'd1a09620-2b9f-49e2-991f-94857eeb9736';
  embedReportContainerId = '1c95231a-e01e-46b5-ac0c-5fef0741c402';
  tokenInfo;
  reportFilters;

  VENDOR_TABLE_NAME = 'DimItem';
  VENDOR_FIELD_NAME = 'VendorName';

  DATE_COLUMNS = [
    'DimItem/ReleaseDate',
    'V_Promotion_Mart/promoSku_streetdate__c',
    'V_Promotion_Mart/promoSku_base_cost_effective_date__c',
    'V_Promotion_Mart/base_cost_effective_date__c',
    'V_Promotion_Mart/promo_startdate__c',
    'V_Promotion_Mart/promo_enddate__c',
    'V_Promotion_Mart/to_date__c',
    'V_Promotion_Mart/from_date__c',
    'DimDate/Date'
  ];

  private report: Report;
  private powerBiService: NgxPowerBiService;
  private pbiContainerElement: HTMLElement;
  private filterPaneEnabled = false;
  private navContentPaneEnabled = true;
  isBotFiltersApplied = false;

  accountList = [];
  accountId: any;
  userTeam: any = '';
  isRetailerTeam: boolean = false;

  constructor(
    public botService: BotService,
    private usersService: UsersService,
    private itemManagerService: ItemManagerService,) {
    this.powerBiService = new NgxPowerBiService();
  }

  ngOnInit() {
    this.getFormContorlsData();
    this.initiateValues();
    this.setRoleBaseAccess();
    if (this.data) {
      const report = JSON.parse(this.data);
      this.reportFilters = report.reportFilters;
      this.txtEmbedReportId = report.reportId;
      this.embedReportContainerId = `${report.reportId}-${moment.now().toString()}`;
      this.txtEmbedDatasetId = report.datasetId;

    }
    const params = {
      reportId: this.txtEmbedReportId,
      groupId: this.txtEmbedGroupId
    };

    /*
    JS PowerBI SDK
    */
    this.loadReportViaSDK(params);
  }

  async getFormContorlsData() {
    await this.itemManagerService.GetAllAccounts().toPromise();
  }

  initiateValues() {
    this.itemManagerService.getAccountListSubject().subscribe((accountList: any) => {
      this.accountList = accountList;
    });
  }

  setRoleBaseAccess() {
    this.userTeam = this.usersService.checkLoginUserTeam();
    switch (this.userTeam) {
      case VENDOR_TEAM_CODE: {
        this.accountId = this.usersService.getTeamRoleAccount();
        this.isRetailerTeam = false;
        break;
      }
      case RETAILER_TEAM_CODE: {
        this.isRetailerTeam = true;
        break;
      }
      case INVALID_TEAM: {
        console.log('Valid Team Not assigned');
        break;
      }
    }
  }

  loadReportViaSDK(params) {
    this.report = null;
    this.botService.GetMicrosoftLoginToken(params).subscribe(async (res: any) => {
      this.tokenInfo = res.access_token;
      const filters = this.getAdvancedFiltersList(this.reportFilters);
      this.isBotFiltersApplied = filters.length ? true : false;
      const roleBaseFilter = this.getRoleBaseFilter();
      roleBaseFilter && filters.push(roleBaseFilter);
      const config: models.IEmbedConfiguration = {
        permissions: models.Permissions.Copy,
        accessToken: this.tokenInfo,
        embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${this.txtEmbedReportId}&groupId=${this.txtEmbedGroupId}`,
        filters: filters,
        id: this.txtEmbedReportId,
        settings: {
          filterPaneEnabled: this.filterPaneEnabled,
          navContentPaneEnabled: this.navContentPaneEnabled,
          persistentFiltersEnabled: false
        },
        viewMode: models.ViewMode.View,
        tokenType: models.TokenType.Aad,
        type: 'report',
      };
      this.pbiContainerElement = <HTMLElement>(document.getElementById(this.embedReportContainerId));
      this.report = <Report>this.powerBiService.embed(this.pbiContainerElement, config);
      this.report.on('loaded', (event: any) => {
      });
      this.report.on("saved", (event: any) => {
      });
    });
  }

  clearReportFilter() {
    const roleBaseFilter = this.getRoleBaseFilter();
    this.report.setFilters(roleBaseFilter ? [roleBaseFilter] : []);
    this.isBotFiltersApplied = false;
  }

  getAdvancedFiltersList(reportFilters) {
    const advancedFilterList: any = [];
    let filter = reportFilters ? reportFilters.split(';') : '';
    if (filter.length > 1) {
      filter.shift();
    }
    filter = _.compact(filter);
    filter = _.filter(filter, value => !value.includes('Measure'));
    const fieldsMapping: any = [];
    filter.forEach(field => {
      let matchRgx = field.match(/(\[(.)+\])/g);
      if (matchRgx) {
        matchRgx = matchRgx[0]
        field = field.replace(matchRgx, `/${matchRgx.replaceAll(/(\[)|(\])/g, '')}`);
      }
      const fieldValueSplit = field.split(':');
      const fieldMeta = _.find(fieldsMapping, a => fieldValueSplit[0].includes(a.field));
      if (fieldValueSplit[0].includes('From-')) {
        if (fieldMeta) {
          fieldMeta.from = fieldValueSplit[1];
        } else {
          fieldsMapping.push({ type: 'range', field: fieldValueSplit[0].replace('From-', ''), from: fieldValueSplit[1] });
        }
      } else if (fieldValueSplit[0].includes('To-')) {
        if (fieldMeta) {
          fieldMeta.to = fieldValueSplit[1];
        } else {
          fieldsMapping.push({ type: 'range', field: fieldValueSplit[0].replace('To-', ''), to: fieldValueSplit[1] });
        }
      } else if (this.DATE_COLUMNS.some((dateField) => { return fieldValueSplit[0] === dateField })) {
        fieldsMapping.push({ type: 'range', field: fieldValueSplit[0], from: fieldValueSplit[1], to: fieldValueSplit[1] });
      } else {

        const fieldValue = fieldValueSplit.slice(1).join(':');
        // const fieldValue = fieldValueSplit[1];
        if (fieldMeta) {
          fieldMeta.values.push(...fieldValue.split('--'));
        } else {
          fieldsMapping.push({ type: 'select', field: fieldValueSplit[0], values: [...fieldValue.split('--')] });
        }
      }
    });
    fieldsMapping.forEach(filter => {
      const fieldSplit = filter.field.split('/');
      switch (filter.type) {
        case 'range': {
          advancedFilterList.push({
            $schema: "http://powerbi.com/product/schema#advanced",
            filterType: models.FilterType.Advanced,
            target: {
              table: fieldSplit[0], // filter table
              column: fieldSplit[1] // filter column
            },
            logicalOperator: 'And',
            conditions: [
              { operator: 'GreaterThanOrEqual', value: filter.from + "T00:00:00" },
              { operator: 'LessThanOrEqual', value: filter.to + "T00:00:00" },
            ]
          });
          break;
        }
        case 'select': {
          const conditions: any = [];
          filter.values.forEach(value => {
            conditions.push({ operator: 'Is', value: value });
          });
          advancedFilterList.push({
            $schema: "http://powerbi.com/product/schema#advanced",
            filterType: models.FilterType.Advanced,
            target: {
              table: fieldSplit[0], // filter table
              column: fieldSplit[1] // filter column
            },
            logicalOperator: 'Or',
            conditions: conditions
          });
          break;
        }
      }
    });
    return advancedFilterList;
  }

  getRoleBaseFilter() {
    if (!this.isRetailerTeam) {
      const account = _.find(this.accountList, a => a.accountId == this.accountId);

      return account ? {
        $schema: "http://powerbi.com/product/schema#advanced",
        filterType: models.FilterType.Advanced,
        target: {
          table: this.VENDOR_TABLE_NAME, // filter table
          column: this.VENDOR_FIELD_NAME // filter column
        },
        logicalOperator: 'Or',
        conditions: [{ operator: 'Is', value: account.accountName }]
      } : null;
    }
    return null;
  }

}

