import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-replenishment-line-chart',
  templateUrl: './replenishment-line-chart.component.html',
  styleUrls: ['./replenishment-line-chart.component.scss']
})
export class ReplenishmentLineChartComponent implements OnInit {

  
  dataSource: any = {
    chart: {
      theme: "fusion",
      showXAxisLine: 0,
      showYAxisValues: 0,
      showValues: 0,
      chartLeftMargin: "0",
      chartTopMargin: "0",
      chartRightMargin: "0",
      chartBottomMargin: "0",
      canvasPadding: "0",
      drawAnchors: "0",
      divLineAlpha: "0"
    },
    categories: [
        {
          category: [
            {
              label: "Jan"
            },
            {
              label: "Feb"
            },
            {
              label: "Mar"
            },
            {
              label: "Apr"
            },
            {
              label: "May"
            },
            {
              label: "Jun"
            },
            {
              label: "Jul"
            },
            {
              label: "Aug"
            },
            {
              label: "Sep"
            },
            {
              label: "Oct"
            },
            {
              label: "Nov"
            },
            {
              label: "Dec"
            }
          ]
        }
      ],
    dataset: [
      {
        seriesname: "Sales",
        renderas: "line",
        data: []
      },
      {
        seriesname: "Stock",
        renderas: "line",
        data: []
      }
    ]
  }

  chartConfig: Object = {
    width: '100%',
    height: '500',
    type: "mscombi2d",
    dataFormat: 'json',
  };


  constructor(
    public dialogRef: MatDialogRef<ReplenishmentLineChartComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.dataSource.dataset=this.data;
  }

}
