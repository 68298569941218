<!-- Main Content area -->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 form-group">
      <mat-form-field>
        <mat-select placeholder="Select Customer" (ngModelChange)="mapList($event)" [(ngModel)]="customerSelectedList"
          multiple>
          <mat-option *ngFor="let res of customerList; let i = index" [value]="res">
            {{res.apCustomerName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-3 form-group">
      <mat-form-field>
        <mat-select placeholder="Select Class" (ngModelChange)="mapList($event)" [(ngModel)]="classSelectedList"
          multiple>
          <mat-option *ngFor="let res of classList; let i = index" [value]="res">
            {{res.apClassName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-3 form-group">
      <mat-form-field>
        <mat-select placeholder="Select Year" (ngModelChange)="mapList($event)" [(ngModel)]="seletedYear">
          <mat-option *ngFor="let res of yearList; let i = index" [value]="res">
            {{res}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-3 form-group">
      <mat-form-field>
        <mat-select placeholder="Select Version" (ngModelChange)="mapList($event)" [(ngModel)]="selectedMonth" multiple>
          <mat-option *ngFor="let res of monthList; let i = index" [value]="res">
            {{res.monthName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="seletedYear" class="row">
    <div class="col-md-4">
      <form action="" class="form-fields show-search">
        <div class="form-group">
          <mat-form-field>
            <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
      </form>
    </div>
    <!-- <div class="col-md-8 d-none d-sm-none d-md-block tablet-icon">
      <div class="btn-show">
        <span (click)="openDialog({})">
          <i style="font-size: 36px;" class="material-icons" aria-label="Column Show/Hide">
            list_alt
          </i>
        </span>
      </div>
    </div> -->
  </div>
  <div *ngIf="seletedYear" class="action-panel text-right">
    <span (click)="openDialog({})">
      <i style="font-size: 42px; cursor: pointer;" class="material-icons" aria-label="Column Show/Hide">
        list_alt
      </i>
    </span>
    <span (click)="openExcelImportDialog({})">
      <i style="font-size: 42px;cursor:pointer;" class="material-icons" aria-label="Column Show/Hide">
        import_export
      </i>
    </span>
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit()">Save</button>
  </div>
  <div *ngIf="seletedYear" class="all-show-grid">
    <ag-grid-angular class="ag-theme-material budget-grid"   groupHeaders
      suppressRowClickSelection toolPanelSuppressGroups  toolPanelSuppressValues
      [gridOptions]="gridOptions" [rowStyle]="getRowStyle" [headerHeight]="headerHeight" [getRowHeight]="getRowHeight"
      [columnDefs]="columnDefs" [rowData]="rowList" (cellValueChanged)="onCellValueChanged($event)"
      (gridReady)="onGridReady($event)" [modules]="modules" #grid>
    </ag-grid-angular>
  </div>
</div>
<ng-template #dialog let-data>
  <h1 mat-dialog-title>Show/Hide Columns</h1>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <mat-checkbox maxlength="20" [(ngModel)]="selectAll" (ngModelChange)="selectAllSelected($event)">Select/Remove All
    </mat-checkbox>
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSave(data)">Save &
      Close</button>
  </div>
  <div mat-dialog-content>
    <div style="display: flex;flex-direction: column;">
      <mat-checkbox maxlength="20" *ngFor="let item of columnDefs" [(ngModel)]="item.show">{{item.headerName}}
      </mat-checkbox>
    </div>
  </div>
</ng-template>
<ng-template #ExcelImport let-data>
  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <h1 mat-dialog-title>Import/Export</h1>
      <button mat-raised-button class="btn btn-primary" color="primary" (click)="exportToExcel()">Export to
        Excel</button>
    </div>
    <div>
      <ngx-dropzone (change)="onSelect($event)">
        <ngx-dropzone-label>Drop files here!</ngx-dropzone-label>
        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>
    <!-- <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview> -->
  </div>
</ng-template>
