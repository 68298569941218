export class Measure {
  measureId: number;
  parentId: number;
  measureName: string;
  code: string;
  createdById?: number;
  createdByName?: string;
  createdDate?: Date;
  modifiedById?: number;
  modifiedByName?: string;
  modifiedDate?: Date;
  active?: boolean;
  searchText?: string;
  sortId?: number;
  sort?: number;
  [key: string]: any;
}

export class FbChannel {
  channelId: number;
  channelName: string;
  countryId: number;
  countryName: string;
  customerId: number;
  customerName: string;
  continentid: number;
  continentName: string;
  createdById?: number;
  createdByName?: string;
  createdDate?: Date;
  modifiedById?: number;
  modifiedByName?: string;
  modifiedDate?: Date;
  active: boolean;
  searchText?: string;
  [key: string]: any;
}
export class Calendar {
  calendarId: number;
  fy: string;
  hy: string;
  quarter: string;
  month: string;
  weekStartDate: Date;
  [key: string]: any;
}
export class Version {
  versionTypeId: number;
  versionTypeName: string;
  versionTypeCode: string;
  [key: string]: any;
}
export class UserFbForecast {
  userFBForecastId: number;
  name: string;
  userId: number;
  createdById?: number;
  createdByName?: string;
  createdDate?: Date;
  modifiedById?: number;
  modifiedByName?: string;
  modifiedDate?: Date;
  active: boolean;
  searchText?: string;
  [key: string]: any;
}
export class ForecastCell {
  fbForecastId: number;
  measureId: number;
  itemId: number;
  channelId: number;
  calendarId: number;
  versionTypeId: number;
  userFBForecastId: number;
  value: number;
  isEdited: boolean;
  userId: number;
  fbTemplateId?:number;
  [key: string]: any;
}
export class FbForecast {
  fbForecastSelectedId: number;
  itemIds: string;
  channelIds: string;
  calendarIds: string;
  userId: number;
  userFBForecastId: number;
  versionTypeId: number;
  fbTemplateId?: number;
  [key: string]: any;
  fbForecasts: Array<ForecastCell>
  constructor(forecast: FbForecast) {
    if (forecast) {
      this.itemIds = forecast.itemIds;
      this.fbForecastSelectedId = forecast.fbForecastSelectedId || null;
      this.channelIds = forecast.channelIds;
      this.calendarIds = forecast.calendarIds;
      this.userId = forecast.userId;
      this.userFBForecastId = forecast.userFBForecastId;
      this.versionTypeId = forecast.versionTypeId;
      this.fbForecasts = forecast.fbForecasts
    }

  }
}
