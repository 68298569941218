import { Component, OnInit, Input } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Router } from '@angular/router';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';

@Component({
  selector: 'app-month-wise-new-store',
  templateUrl: './month-wise-new-store.component.html',
  styleUrls: ['./month-wise-new-store.component.css']
})
export class MonthWiseNewStoreComponent implements OnInit {
  public submitted = false;
  public isEdit = false;
  public columnDefs: any = [{
    headerName: 'Store',
    field: 'NewFormRow.Name',
    pinned: 'left',
    editable: true,
    width: 120
  }];
  public columnDefsPercentage = [];
  private gridApi: any;
  public storeHeaderTypeList = [];
  public storeHeaderTypeListTemp = [];
  public storeList = [];
  public storeListPercentage = [];
  public store: any;
  public getRowHeight;
  public headerHeight;
  public gridOptions: any;
  public gridOptionsPercentage: any;
  public modules = AllCommunityModules;
  public clientId = 1;
  public storeTypeList = [];
  public channelList = [];
  @Input() channel: any;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private router: Router,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService) {
    this.gridOptions = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.gridOptionsPercentage = {
      frameworkComponents: {},
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.headerHeight = 30;
    this.gridOptions.getRowHeight = function (params) {
      return 30;
    };
    this.gridOptionsPercentage.getRowHeight = function (params) {
      return 30;
    };
  }
  ngOnInit() {
    this.getStoreMixFormList();
  }
  public getStoreMixFormList = async () => {
    if (this.channel) {
      this.storeHeaderTypeList = await this.configurationService.getStoreMixHeaderList({ clientId: 1, active: true, channelId: this.channel.channelId }).toPromise();
      this.storeHeaderTypeListTemp = this.storeHeaderTypeList;
      this.storeHeaderTypeListTemp = this.storeHeaderTypeListTemp.map(StoreHeader => ({ id: StoreHeader.newFormHeaderId, headerName: StoreHeader.name.split('-')[0], field: StoreHeader.code, active: StoreHeader.active }));
      this.columnDefs = [{
        headerName: 'Store',
        field: 'NewFormRow.Name',
        pinned: 'left',
        editable: true,
        width: 120
      }];
      this.storeHeaderTypeListTemp.forEach(StoreHeader => {
        this.columnDefs.push({
          ...StoreHeader,
          editable: true,
          width: 80,
          valueSetter: function (params) {
            params.data[params.colDef.field] = Number(params.newValue);
            return true;
          }
        });
      });
      this.columnDefs.push({
        headerName: 'Total',
        field: 'total',
        pinned: 'right',
        width: 120
      });
      this.gridOptions.api.setColumnDefs(this.columnDefs);
      this.configurationService.getStoreMixFormList({ clientId: 1, channelId: this.channel.channelId }).subscribe((Stores: any) => {
        this.storeList = Stores.pivotList || [];
        this.storeList.forEach(element => {
          element.NewFormRow = {
            Name: element.NewFormRow.name,
            NewFormRowId: element.NewFormRow.newFormRowId,
            ClientId: element.NewFormRow.clientId,
            Active: element.active,
            ChannelId: element.NewFormRow.channelId
          };
        });
        this.mapTotal(this.storeList);
      }, error => {
      });
    }
  }
  public mapTotal = (gridList) => {
    for (let i = 0; i < gridList.length; i++) {
      const newStoreObject = JSON.parse(JSON.stringify(gridList[i]));
      delete newStoreObject.NewFormRow;
      gridList[i]['total'] = 0;
      gridList[i]['total'] = Object.values(newStoreObject).reduce((a, b) => +a + +b);
    }
    this.mapTotalObject();
  }
  public mapTotalObject = () => {
    const totalObject: any = {};
    this.storeList.forEach(row => {
      const newStoreObject = JSON.parse(JSON.stringify(row));
      delete newStoreObject.NewFormRow;
      Object.keys(newStoreObject).forEach(key => {
        if (key in newStoreObject) {
          totalObject[key] = totalObject[key] !== null && totalObject[key] !== undefined ? totalObject[key] + newStoreObject[key] : newStoreObject[key];
        }
      });
      totalObject['NewFormRow'] = {};
      totalObject['NewFormRow']['Name'] = 'Total';
    });
    this.storeList.push(totalObject);
    this.gridOptions.api.setRowData(this.storeList);
    const totalList = JSON.parse(JSON.stringify(this.storeList.filter(row => row['NewFormRow']['Name'] === 'Total')));
    this.mapPercentageList(totalList);
  }
  public mapPercentageList = (goalList: any) => {
    const keys = ['year', 'NewFormRow'];
    for (let i = 0; i < goalList.length; i++) {
      let keyList = Object.keys(goalList[i]);
      keyList = keyList.filter(key => !keys.includes(key));
      for (let j = 0; j < keyList.length; j++) {
        if (goalList[i][keyList[j]] && goalList[i]['total']) {
          goalList[i][keyList[j]] = goalList[i][keyList[j + 1]] > 0 ? (((goalList[i][keyList[j]]) / goalList[i]['total']) * 100) : goalList[i][keyList[j + 1]];
        }
        if (keyList[j] === 'total') {
          goalList[i]['total'] = 100;
        }
      }
      this.storeListPercentage.push(goalList[i]);
      this.gridOptionsPercentage.api.setRowData(this.storeListPercentage);
    }
    this.columnDefsPercentage = this.columnDefs.map(column => (column.field !== 'NewFormRow.Name' ? {
      ...column,
      valueFormatter: this.currencyFormatter
    } : { ...column }));
  }
  currencyFormatter(params) {
    // return `${formatNumber(params.value, 'en', '2.0-2')}`;
    return `${Math.floor(typeof params.value === 'number' ? params.value : 0)}%`;
  }
  Cancel_Click() {
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
}
