<div [hidden]="!gridMapped">
  <mat-accordion class="fb-forecast-accordion">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>Order List</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="data-wrapper btn-wrappers">
        <img src="assets/images/graph.svg" alt="" (click)="openMesuresDialog()">
        <mat-select placeholder="Export/Import" class="form-control">
          <mat-option>Export/Import</mat-option>
          <mat-option value="Export Current View">Export Current View</mat-option>
          <mat-option value="Export template">Export template</mat-option>
          <mat-option value="Import">Import</mat-option>
        </mat-select>
        <button class="btn btn-primary">Save or Commit</button>
      </div>
      <!-- <div class="row">
        <div class="col-md-6">
          <div class="item-search-wrappers display-set">
            <form action="" class="form-fields show-search">
              <div class="form-group">
                <mat-form-field floatLabel="never">
                  <input matInput id="filter-text-box" placeholder="Search" [(ngModel)]="searchText"
                    (ngModelChange)="onFilterTextBoxChanged($event)" />
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <div class="data-wrapper btn-wrapper">
            <img src="assets/images/graph.svg" alt="" (click)="openMesuresDialog()">
            <mat-select placeholder="Export/Import" class="form-control">
              <mat-option>Export/Import</mat-option>
              <mat-option value="Export Current View">Export Current View</mat-option>
              <mat-option value="Export template">Export template</mat-option>
              <mat-option value="Import">Import</mat-option>
            </mat-select>
            <button class="btn btn-primary">Save or Commit</button>
          </div>
        </div>
      </div> -->
      <div class="all-show-grid items-wrapper-grids-change">
        <ag-grid-angular class="ag-theme-material" [gridOptions]="gridOptions" [defaultColDef]="defaultColDef"
          [rowModelType]="rowModelType" [pagination]="true" [headerHeight]="headerHeight"
          [paginationPageSize]="paginationPageSize" [columnDefs]="columnDefs" (gridReady)="onGridReady($event)"
          [modules]="modules" [suppressRowClickSelection]="true" [rowSelection]="rowSelection" [rowData]="orderList"
          #grid>
        </ag-grid-angular>
      </div>
      <ng-template #saveForecast>
        <h1 mat-dialog-title>What would you like to name your planner?</h1>
        <div mat-dialog-content>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Enter Planner Name" [(ngModel)]="plannerName"
              [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
        <div class="action-panel text-right">
          <button mat-raised-button class="btn btn-outline m-left" color="primargy"
            (click)="ref.close()">Cancel</button>
          <button mat-raised-button class="btn btn-primargy m-left" color="warn" (click)="ref.close(true)">Save</button>
        </div>
      </ng-template>
      <ng-template #commitForecast>
        <h1 mat-dialog-title>Congratulations! you have successfully committed</h1>
        <div mat-dialog-content>
        </div>
        <div class="action-panel text-right">
          <button mat-raised-button class="btn btn-outline m-left" color="primargy" (click)="ref.close()">Go
            Back</button>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
