import { Component, ViewChild, ElementRef } from '@angular/core';
import { ConfigurationService } from 'src/services/configuration.service';

@Component({
  selector: 'app-loading-overlay',
  template: `
        <div>
            <div *ngIf="params.enableMenu" #menuButton class="customHeaderMenuButton" (click)="onMenuClicked($event)"><i class="fa {{params.menuIcon}}"></i></div>
            <div style="margin-right: 20px;" class="customHeaderLabel">{{params.displayName}}</div>
            <!-- <div *ngIf="params.enableSorting" (click)="onSortRequested('asc', $event)" [ngClass]="ascSort" class="customSortDownLabel"><i class="fa fa-long-arrow-alt-down"></i></div>
            <div *ngIf="params.enableSorting" (click)="onSortRequested('desc', $event)" [ngClass]="descSort" class="customSortUpLabel"><i class="fa fa-long-arrow-alt-up"></i></div>
            <div *ngIf="params.enableSorting" (click)="onSortRequested('', $event)" [ngClass]="noSort" class="customSortRemoveLabel"><i class="fa fa-times"></i></div> -->
            <div *ngIf="params.displayName && (!configList.includes(params.displayName))" class="d-flex">
            <div (click)="onCrossClicked('', $event)" [ngClass]="noSort" class="customSortRemoveLabel"><i class="fa fa-times"></i></div>
            <div  (click)="onEditClicked('', $event)" [ngClass]="noSort" class="customSortRemoveLabel"><i class="fa fa-pencil"></i></div>
            </div>
        </div>
    `,
  styles: [
    `
        .customHeaderMenuButton,
        .customHeaderLabel,
        .customSortDownLabel,
        .customSortUpLabel,
        .customSortRemoveLabel
        {
            float: left;
            margin: 0 0 0 3px;
            cursor: pointer;
        }

        .customSortUpLabel {
            margin: 0;
        }

        .customSortRemoveLabel {
            font-size: 14px;
            float: right;
            cursor: pointer;
            margin-right: 5px;
        }

        .active {
            color: rgba(0, 0, 0, 0.54);
        }
    `
  ]
})
export class CustomHeaderComponent {
  public params: any;
  public ascSort: string;
  public descSort: string;
  public noSort: string;
  public configList = ['Goal Type', 'Source', 'Store', 'Category', 'Channel', 'Year', 'Total'];
  constructor(public configurationService: ConfigurationService) {

  }
  @ViewChild('menuButton', { read: ElementRef }) public menuButton;

  agInit(params): void {
    this.params = params;

    params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    this.onSortChanged();
  }

  onMenuClicked() {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

  public onSortChanged() {
    this.ascSort = this.descSort = this.noSort = 'inactive';
    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
    } else {
      this.noSort = 'active';
    }
  }
  onEditClicked() {
    this.configurationService.setEditColumnSubject({ ...this.params.column.colDef, displayName: this.params.displayName });
  }
  onCrossClicked() {
    this.configurationService.setRemoveColumnSubject(this.params.column.colDef);
  }
  onSortRequested(order, event) {
    this.params.setSort(order, event.shiftKey);
  }
}
