import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TreeviewItem } from 'ngx-treeview';
import { throwError } from 'rxjs';
import { Calendar } from 'src/common/facebook.models';
import { USER_INFO } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';

@Component({
  selector: 'app-new-item-planner',
  templateUrl: './new-item-planner.component.html',
  styleUrls: ['./new-item-planner.component.scss'],
})
export class NewItemPlannerComponent implements OnInit {
  public itemsList = [];
  public accountsList = [];
  public dataModel: any = {};
  public filteredItems = [];
  public filteredAccounts = [];
  public newItemProductGroupList = [];
  public newItemProductFamilyList = [];
  public newItemParentProductList = [];
  public newItemAccountRegionList = [];
  public newItemAccountCountryList = [];
  public accountSettingList = [];
  public calendarListDropdown = [];
  public timeframeList = [];
  public seasonlaityIndexList = [];
  public newItemAccountDistributorList = [];
  public launchCurvesList = [
    { id: 1, name: 'Launch Curve 1' },
    { id: 2, name: 'Launch Curve 2' },
    { id: 3, name: 'Launch Curve 3' },
  ];
  public itemAccountSettingList = [];
  public viewPermission = false;
  public editPermission = false;
  constructor(readonly config: ConfigurationService, public storage: LocalstorageService) {}

  ngOnInit(): void {
    let userTeams = this.storage.get(USER_INFO).teams;
    userTeams.forEach((element) => {
      element.teamScreenGroup.forEach((group) => {
        if (group.screenGroupId == 6 && group.viewPermission) {
          this.viewPermission = true;
        }
        if (group.screenGroupId == 6 && group.editPermission) {
          this.editPermission = true;
        }
      });
    });
    this.getDefaultDropdowns();
    const params = {
      offset: 0,
      pageSize: 100000,
      active: true,
    };
    let url = `offSet=${params.offset ? params.offset : 0}&pageSize=${
      params.pageSize ? params.pageSize : 10
    }&active=true`;
    this.config.ItemManagerGetAll(url).subscribe((res) => {
      this.itemsList = res.data || [];
      this.filteredItems = JSON.parse(JSON.stringify(this.itemsList));
    });
    this.config.ItemAccountGetAll(url).subscribe((res) => {
      this.accountsList = res.data || [];
      this.filteredAccounts = JSON.parse(JSON.stringify(this.accountsList));
    });
  }
  public showFilteredItems = (value: string) => {
    this.filteredItems = this.itemsList.filter(
      (el) =>
        (el.description as string)
          .toLocaleLowerCase()
          .indexOf(value.toLocaleLowerCase()) > -1
    );
  };
  public showFilteredAccounts = (value: string) => {
    this.filteredAccounts = this.accountsList.filter(
      (el) =>
        (el.accountName as string)
          .toLocaleLowerCase()
          .indexOf(value.toLocaleLowerCase()) > -1
    );
  };
  public async getDefaultDropdowns() {
    this.config.calendarGetList({}).subscribe((res: Calendar[]) => {
      this.timeframeList = res;
      this.createCalendarListDropdown();
    });
    this.seasonlaityIndexList = await this.config
      .GetLookupList('SeasonalitySourceLevel')
      .toPromise()
      .catch((error) => throwError(error));
    this.config
      .ProductSettingMappingProductGroupGetList({ brandId: 0 })
      .subscribe((res) => {
        this.newItemProductGroupList = res;
      });
    this.config
      .ProductSettingMappingProductFamilyGetList({ productGroupId: 0 })
      .subscribe((res) => {
        this.newItemProductFamilyList = res;
      });
    this.config
      .ProductSettingMappingParentProductGetList({ productFamilyId: 0 })
      .subscribe((res) => {
        this.newItemParentProductList = res;
      });
    // Account Mapping Lists
    this.config
      .AccountSettingMappingContinentGetList({ channelId: 0 })
      .subscribe((res) => {
        this.newItemAccountRegionList = res;
      });
    this.config
      .AccountSettingMappingCountryGetList({ channelId: 0 })
      .subscribe((res) => {
        this.newItemAccountCountryList = res;
      });
    this.config
      .AccountSettingMappingDistributorGetList({ channelId: 0 })
      .subscribe((res) => {
        this.newItemAccountDistributorList = res;
      });
  }
  public createCalendarListDropdown = (forecast?: any) => {
    this.calendarListDropdown = [];
    const uniqueYears = [
      ...new Set(this.timeframeList.map((item: Calendar) => item.fy)),
    ]; // ['FY18']
    uniqueYears.forEach((year: string, index) => {
      const yearList = this.timeframeList.filter((row) => row.fy === year);
      const uniqueHalfYears = [
        ...new Set(yearList.map((item: Calendar) => item.hy)),
      ]; // ['H1']
      const uniqueQuarters = [];
      const uniquemonths = [];
      yearList.forEach((y) => {
        if (
          uniqueQuarters.findIndex(
            (x) => x.quarter === y.quarter && x.hy === y.hy
          ) < 0
        ) {
          uniqueQuarters.push({ quarter: y.quarter, hy: y.hy });
        }
        if (
          uniquemonths.findIndex(
            (x) => x.quarter === y.quarter && x.month === y.month
          ) < 0
        ) {
          uniquemonths.push({ quarter: y.quarter, month: y.month });
        }
        y.checked =
          forecast && forecast.calendarIds
            ? forecast.calendarIds
                .split(',')
                .find((val) => val === y.calendarId.toString())
            : false;
      });
      // const uniqueQuarters = yearList.filter(year => year.quarter)
      const obj = new TreeviewItem({
        text: year,
        value: year,
        checked: false,
        children: uniqueHalfYears.map(
          (h: string) =>
            new TreeviewItem({
              text: h,
              value: h,
              checked: false,
              children: uniqueQuarters
                .filter((e) => e.hy === h)
                .map(
                  (q) =>
                    new TreeviewItem({
                      text: q.quarter,
                      value: q.quarter,
                      checked: false,
                      children: uniquemonths
                        .filter((el) => el.quarter === q.quarter)
                        .map((m) => ({
                          text: m.month,
                          checked: yearList
                            .filter(
                              (el) =>
                                el.month === m.month &&
                                el.quarter === q.quarter &&
                                el.hy === h
                            )
                            .find((el) => !el.checked)
                            ? false
                            : true,
                          value: yearList
                            .filter(
                              (el) =>
                                el.month === m.month &&
                                el.quarter === q.quarter &&
                                el.hy === h
                            )
                            .map((cal) => cal.calendarId),
                        })),
                    })
                ),
            })
        ),
      });
      this.calendarListDropdown.push(obj);
    });
  };
  public submitItemSearch = (selectedItem) => {
    this.config
      .ItemAccountSettingGet({ itemManagerId: selectedItem.itemManagerId })
      .subscribe((res) => {
        this.itemAccountSettingList = res.map(el => {
          const launchCurve = this.launchCurvesList.find(row => row.id === el.launchCurveId);
          return {
            ...el,
            launchCurveName: launchCurve?.name
          }
        });
      });
  };
  public submitAccountSearch = (selectedAccount) => {
    this.config
      .GetItemSettings({ AccountId: selectedAccount.itemAccountId })
      .subscribe((res) => {
        this.accountSettingList = res.map(el => {
          const launchCurve = this.launchCurvesList.find(row => row.id === el.launchCurveId);
          return {
            ...el,
            launchCurveName: launchCurve?.name
          }
        });
      });
  };
  getItemOptionText(option) {
    return option?.description;
  }
  getAccountOptionText(option) {
    return option?.accountName;
  }
  resetTreeList() {

  }
}
