<!-- <h2 style="font-weight: bold; text-align: center;">Summary By Year</h2> -->
<div class="container summary-wrapper">
  <mat-expansion-panel>
    <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
      <mat-panel-title>
        Summary By Year
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <div class="all-show-grid">
          <label class="grid-head">Sales PKR(M)</label>
          <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20'  groupHeaders suppressRowClickSelection
            toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptions" [headerHeight]="headerHeight" [getRowHeight]="getRowHeight" [columnDefs]="columnDefs"
            [suppressRowTransform]="true" [rowData]="gridList" (gridReady)="onGridReady($event)" [modules]="modules"
            #grid>
          </ag-grid-angular>
        </div>
      </div>
      <div class="col-md-12 col-lg-6">
        <div class="all-show-grid">
          <label class="grid-head">Variation +/-</label>
          <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20'  groupHeaders suppressRowClickSelection
            toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptionsVariation" [headerHeight]="headerHeight" [getRowHeight]="getRowHeight"
            [columnDefs]="columnDefsVariation" [suppressRowTransform]="true" [rowData]="gridVariationList"
            (gridReady)="onGridReady($event)" [modules]="modules" #grid>
          </ag-grid-angular>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <div class="all-show-grid">
          <label class="grid-head">Sales Percentage</label>
          <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20'  groupHeaders suppressRowClickSelection
            toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptionsPercentage" [headerHeight]="headerHeight" [getRowHeight]="getRowHeight"
            [columnDefs]="columnDefsPercentage" [suppressRowTransform]="true" [rowData]="gridPercentageList"
            (gridReady)="onGridReady($event)" [modules]="modules" #grid>
          </ag-grid-angular>
        </div>
      </div>
      <div class="col-md-12 col-lg-6">
        <div class="all-show-grid">
          <label class="grid-head">Variation Percentage</label>
          <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20'  groupHeaders suppressRowClickSelection
            toolPanelSuppressGroups toolPanelSuppressValues [gridOptions]="gridOptionsPercentageVariation" [headerHeight]="headerHeight" [getRowHeight]="getRowHeight"
            [columnDefs]="columnDefsPercentageVariation" [suppressRowTransform]="true"
            [rowData]="gridPercentageListVariation" (gridReady)="onGridReady($event)" [modules]="modules" #grid>
          </ag-grid-angular>
        </div>
      </div>
    </div>



  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
      <mat-panel-title>
        Same Store
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-same-store-summary></app-same-store-summary>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
      <mat-panel-title>
        New Store
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-new-store-summary></app-new-store-summary>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
      <mat-panel-title>
        Category Wise Break Down
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-category-summary [summaryByYearList]="gridList"></app-category-summary>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
      <mat-panel-title>
        Source Wise Break Down
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-source-summary [summaryByYearList]="gridList"></app-source-summary>
  </mat-expansion-panel>
</div>
