import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CALENDER_ACTIVITY_COL_DEFS } from 'src/common/algo-column-definitions';
import { USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  selector: 'app-event-type-add',
  templateUrl: './event-type-add.component.html',
  styleUrls: ['./event-type-add.component.css']
})
export class EventTypeAddComponent implements OnInit {
  public calenderActivityForm: FormGroup;
  public submitted = false;
  public isEdit = false;
  public columnDefs = CALENDER_ACTIVITY_COL_DEFS;
  private gridApi: any;
  public channelList = [];
  public gridOptions: any;
  public modules = AllCommunityModules;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public dialogRefEventType: MatDialogRef<EventTypeAddComponent>,
  ) {
    this.gridOptions = {
      frameworkComponents: {
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }

  ngOnInit() {
    this.initializeForm();
  }
  initializeForm() {
    this.calenderActivityForm = this.formBuilder.group({
      description: ['', Validators.required],
      eventTypesId: [0, Validators.required],
      clientId: [1],
      active: [true],
      currentUserId: [this.storage.get(USER_ID)]
    });
  }
  populateForm(event): void {
    if (event && event.data) {
      this.isEdit = true;
      const dataToPopulate = event.data;
      Object.keys(this.calenderActivityForm.controls).forEach(key => {
        if (dataToPopulate[key]) {
          this.calenderActivityForm.controls[key].setValue(dataToPopulate[key]);
        }
      });
    }
  }
  public onSubmit = () => {
    this.submitted = true;
    if (this.calenderActivityForm.valid) {
      const model = { ...this.calenderActivityForm.value };
      this.spinner.show();
      if (this.isEdit) {
        this.configurationService.updateEventTypes(model).subscribe(res => {
          this.isEdit = false;
          this.submitted = false;
          this.dialogRefEventType.close(true);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      } else {
        this.configurationService.addEventTypes(model).subscribe(res => {
          this.submitted = false;
          this.dialogRefEventType.close(true);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      }
    }
  }
  Cancel_Click() {
    this.dialogRefEventType.close();
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

}
