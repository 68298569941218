import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ViewRendererComponent } from 'src/common/view-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { FirebaseService } from 'src/services/firebase.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit, AfterViewInit {
  public classList = [{ classCode: 1, classCodeDescription: 'TV' }, { classCode: 2, classCodeDescription: 'Theatrical' }];
  public subClassList = [
    { subClassCode: 1, subClassCodeDescription: 'DVD' },
    { subClassCode: 2, subClassCodeDescription: 'BD' },
    { subClassCode: 3, subClassCodeDescription: '3D' },
    { subClassCode: 4, subClassCodeDescription: 'UHD' }
  ];
  @Input() data;
  public columnDefs = [
    {
      headerName: 'Name',
      field: 'documentName',
      width: 400
    },
    {
      headerName: '',
      field: 'documentPath',
      width: 150,
      cellRenderer: (params) => {
        return `<a href=${params.value} target='_blank' class="material-icons">save_alt</a>`;
      }
    },
    {
      headerName: '',
      field: 'delete',
      resizable: true,
      filter: false,
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveGoalDialog.bind(this),
      },
      width: 80
    }
  ];
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public ItemMasterList = [];
  public modules = AllCommunityModules;
  public user: any;
  public files = [];
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  public documents = [];
  constructor(public router: Router,
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<any>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public firebaseService: FirebaseService,
    private storage: AngularFireStorage,
  ) {
    this.gridOptions = {
      frameworkComponents: {
        buttonRenderer: EditButtonRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent,
        nameRenderer: NameRendererComponent,
        viewRenderer: ViewRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    this.getItemMasterList();
    this.configurationService.itemMasterListSubject$.subscribe(res => {
      if (res) {
        this.getItemMasterList();
      }
    });
  }
  ngAfterViewInit() {
    this.data = this.firebaseService.category;
  }
  public getItemMasterList = () => {
    // this.configurationService.ItemMasterGetAll({}).subscribe(res => {
    //   this.ItemMasterList = res.map(row => ({ ...row, name: 'ABC' }));
    // });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }
  openRemoveGoalDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.documentName }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeItemMaster(row.rowData);
      }
    });
  }
  openViewRenderer(row: any): void {
    // const selector = {
    //   id: '451',
    //   name: 'Item Plan',
    //   selector: 'app-item-plan',
    //   displayName: 'Item Plan',
    //   data: {}
    // };
    // this.configurationService.menuRendererSubject.next(selector);
  }
  onSelect = (e) => {
    this.uploadFile(e);
  }
  onRemove = (e) => {
    this.files.splice(this.files.indexOf(e), 1);
  }
  public removeItemMaster(selectedItem: any) {
    if (selectedItem) {
      const model = {
        ...selectedItem,
        active: false
      };
      this.spinner.show();
      this.data.videos = this.data.videos.filter(res => res.documentPath === model.documentPath);
      this.firebaseService.UpdateSKU(this.data);
      this.spinner.hide();
      // this.configurationService.ItemMasterActivate(model).subscribe(res => {
      //   if (res) {
      //     this.spinner.hide();
      //     this.toastr.success('Master Items', 'Item removed successfully');
      //     this.ItemMasterList = this.ItemMasterList.filter(row => row.itemMasterId !== model.itemMasterId);
      //     this.gridOptions.api.setRowData(this.ItemMasterList);
      //   }
      // }, error => {
      //   this.spinner.hide();
      //   this.toastr.error('Master Items', error.error.text);
      // });
    }
  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  // onCellClicked($event: CellClickedEvent) {
  //   if ($event && $event.colDef.field === 'itemDesciption') {
  //     this.openModal($event.data);
  //   }
  // }
  public openModal(template?: any) {
    // const selector = {
    //   id: '461',
    //   name: 'PDM Creation',
    //   selector: 'app-pdm-form',
    //   displayName: 'PDM Creation',
    //   data: data
    // };
    // this.configurationService.menuRendererSubject.next(selector);
    this.matDialogRef = this.dialog.open(template, { width: '500px', data: {}, disableClose: true });
    this.matDialogRef.afterClosed().subscribe(res => {
    });
  }
  public saveDocuments = () => {
    if (this.data.videos && this.data.videos.length > 0) {
      const model = { ...this.data };
      this.firebaseService.UpdateSKU(model).then(response => {
        this.documents = [];
        this.files = [];
        this.gridApi.setRowData(this.data.videos);
        this.matDialogRef.close();
      });
    }
  }
  uploadFile(event) {
    this.spinner.show();
    this.files.push(...event.addedFiles);
    if (event && event.addedFiles.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < event.addedFiles.length; i++) {
        this.uploadFilesToFirebase(event.addedFiles[i]);
      }
    }
  }
  public uploadFilesToFirebase = (file: File) => {
    const filePath = `Uploads/${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(res => {
          if (!this.data.videos) {
            this.data.videos = [];
            this.data.videos.push({ documentPath: res, documentName: file.name });
          } else {
            this.data.videos.push({ documentPath: res, documentName: file.name });
          }
          this.spinner.hide();
        });
      })
    )
      .subscribe();
  }
}
