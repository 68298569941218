import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'planogram-item-action-cell-renderer',
    template: `<mat-form-field appearance="fill">
                <input type="date" [(ngModel)]="inputDate" matInput  />
                </mat-form-field>`,
    styles: [
        `
        .container {
            width: 350px;
        }
        `
    ]
})
export class AgGridDatePickerRenderer implements ICellRendererAngularComp, OnDestroy {
    params: any;
    inputDate;

    constructor() {
    }

    agInit(params: any): void {
        this.params = params;
        this.inputDate = new Date(this.params.value).toISOString().split('T')[0];
    }

    refresh(params?: any): boolean {
        return true;
    }

    ngOnDestroy() {
        // no need to remove the button click handler 
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}