export const paginationPageSize = 10;
export const rowSelection = 'multiple';
export const defaultColDef = {
    filter: false,
    sortable: false,
    width: 80,
    resizable: false
};

export const generateGridColumns = (dataArray: any[]) => {
    const gridColumnDefs = [
        {
            headerName: '',
            field: '',
            checkboxSelection: true,
            headerCheckboxSelection: false,
            resizable: false,
            width: 80,
            pinned: 'left'
        }
    ];
    dataArray.forEach((element: any) => {
        const headerObj: any = {
            headerName: element,
            field: element,
            width: 200,
            minWidth: 200,
            resizable: false,
            editable: false,
            cellStyle: params => {
                if (params.data.color) {
                        // COLORING THE CELLS
                        return { backgroundColor: params.data.color };
                }
            }
        };
        if (element == 'Sales Graph') {
            headerObj['cellRenderer'] = 'agGridLineChartCellRenderer';
        }
        gridColumnDefs.push(headerObj);
    });
    return gridColumnDefs;
};
