import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-adjust-order-quantity',
  templateUrl: './adjust-order-quantity.component.html',
  styleUrls: ['./adjust-order-quantity.component.scss']
})
export class AdjustOrderQuantityComponent implements OnInit {
  @Input() gridMapped: any;
  @Input() adjustOrderQuantity: any;

  constructor() { }

  ngOnInit(): void {
  }
  isAnyOpened = () => {
    return document.getElementsByClassName('dropdown show').length > 0;
  };
  applyUDP() {
  }
}
