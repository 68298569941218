import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError } from 'rxjs';
import { ConfigurationService } from 'src/services/configuration.service';
import { FirebaseService } from 'src/services/firebase.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-pdm-form',
  templateUrl: './pdm-form.component.html',
  styleUrls: ['./pdm-form.component.css']
})
export class PdmFormComponent implements OnInit {

  public pdmForm: FormGroup;
  public submitted = false;
  public customerList = ['Best Buy', 'Walmart', 'Target', 'Dollar General'];
  public classList = ['New Release', 'Recent Release', 'Catalog', 'TV'];
  public subClassList = ['DVD', 'BD', '3D', 'UHD'];
  public fundTypeList = [{ fundTypeId: 1, fundTypeName: 'Promo' }, { fundTypeId: 2, fundTypeName: 'Base' }];
  public ItemMasterProductType = ['Special Edition', 'Catalog Drafters', 'Single Disc'];
  public advertisementDescList = ['In-Store', 'Emali Blast', 'Direct Mail'];
  @Input() data: any;
  public skuItemMaster = null;
  isEdit = false;
  public ratingList = ['AO', 'E', 'E10+', 'EC', 'G', 'INHALANT', 'M', 'NC_17', 'NOT_RATED', 'PG', 'PG_13', 'R', 'RATING_PENDING',
    'RP', 'T', 'UN_RATED', 'X_RATED'];
  public LabelStudioSupplierList = [
    'Sony Pictures',
    'Home Entertainment',
    'Sactuary Visual Entertainment',
    'Private Screening Collection',
    'Rhino Transport Home Video',
    'Final Level Entertainment',
    'Panik House Entertainment',
    'Millennium Media Services',
    'Lions Gate Entertainment',
    'Anchor Bay Entertainment',
    'Riverbend Entertainment',
    'Claredon Entertainment',
    'War Archive Collection',
    'Bayview Entertainment',
    'Questar Entertainment',
    'Barrel Entertainment',
    'Blueline Productions',
    'Grindhouse Releasing',
    'Alpha Home Entertain',
    'National Geographic',
    'Image Entertainment',
    'Gotham Distributing',
    'First Look Studios',
    'Paramount Pictures',
    'Universal Studios',
    'Paramount Studios',
    'Hit Entertainment',
    'MGM Entertainment',
    'Weinstein Company',
    'Warner Home Video',
    'XEG Entertainment',
    'Rhino Music Video',
    'Subversive Cinema',
    'Paramount Picture',
    'Blue Underground',
    'DVD Internatinal',
    'Electric Thunder',
    'Impulse Pictures',
    'Parlour Pictures',
    'Visa Point(CAV)',
    'Warner Nashville',
    'New Video Group',
    'Build Worldwide',
    'Move Collective',
    'Penalty Records',
    'A & E Home Video',
    'Blue Worldwide',
    'Discotek Media',
    'Indie Pictures',
    'Insomniac Inc.',
    'Ryko Filmworks',
    'Media Blasters',
    'The Garr Group',
    'Future Farmer',
    'Heretic Films',
    'Mondo Macabro',
    'Severin Films',
    'Synpase Films',
    'Legend House',
    'Magic Circle',
    'Raunchy Tonk',
    'Food Network',
    'Buena Vista',
    'Rhino Video',
    'World Video',
    'Hal Leonard',
    'Acorn Media',
    'Funimation',
    'Big Vision',
    'Scholastic',
    'Arts Magic',
    'Casa Negra',
    'Cult Epics',
    'Gemini Sun',
    'Section 23',
    'LOL Comedy',
    'MILL CREEK',
    'ADV Films',
    'Rhino P & D, Sanctuary',
    'Hartsharp',
    'Discovery',
    'Platinum',
    'FU Payme',
    'Hightone',
    'No Shame',
    'Level 33',
    'Unicine',
    'Absurda',
    'Arcanum',
    'spinArt',
    'Epitaph',
    'Destroy',
    'Disney',
    'Genius',
    'Geneon',
    'Asylum',
    'Morada',
    'Bandai',
    'And 1',
    'Zuffa',
    'Gaiam',
    'UMGD',
    'MISC',
    'ESPN',
    'Fox',
    'WWE',
    'HBO',
    'BBC',
    'SPV',
    'VAS',
    'MPI',
    'VEI',
    ' NL'];
  public MediaFormatList = ['12_Inch Vinyl Single', '3_Inch CD Single', '4K Ultra HD', '5_Inch CD Single', '5_Inch CDV', '7_Inch Vinyl Single', '8 MM',
    'Audio Blank Tape', 'Beta', 'Blu_Ray', 'CD Plus Graphics', 'CD ROM_Interactive', 'CD_DVD Audio', 'CD_DVD Video', 'CD_Full Length', 'Cassette Single',
    'Cassette_Full Length', 'DAT_Full Length', 'DCC_Full Length', 'DC_SACD', 'DVD', 'DVD Audio', 'DVD_ALBUM', 'Dataplay', 'HD DVD', 'LP_Full Length', 'Laserdisc',
    'MAXI_CD', 'MVI', 'Maxi_Cassette Single', 'Mini Disc_Full Length', 'Ringle', 'SACD', 'UMD', 'USB', 'VHS', 'Video Blank Tape'];
  public GenreList = [
    'Action', 'Action_Adventure', 'Action_Comedy', 'Action_Drama', 'Action_Thriller', 'Adult', 'Adult Anime', 'Adult Contemporary',
    'Adventure', 'Animated Comedy', 'Animation', 'Anime', 'Biopic_Drama', 'Black Comedy', 'Blues', 'Children', 'Childrens', 'Christmas',
    'Classical', 'Classics', 'Comedy', 'Country', 'Crime', 'Crime Action', 'Dark Comedy', 'Development Tools', 'Documentary', 'Drama',
    'Drama Romance', 'Drama_Comedy', 'Educational', 'Emulators', 'Erotic Thriller', 'Ethnic', 'Family', 'Family Entertainment', 'Fantasy_Sci_Fi',
    'Fighting', 'Fine Art_Foreign', 'Folk', 'Gay_Lesbian Adult', 'Gay_Lesbian Cinema', 'Hard Rock', 'Health_Fitness', 'Hobbies_Cooking_Craft_Repair',
    'Home', 'Horror', 'Instructional', 'Instrumental', 'Jazz', 'Latin', 'Latin Pop', 'Martial Arts', 'Mature', 'Mexican', 'Miscellaneous', 'Music', 'Music Video',
    'Musical', 'Mystery', 'New Age', 'Original Cast Soundtracks', 'Other', 'Political Drama', 'Pop', 'Puzzle', 'Racing', 'Reference',
    'Reggae', 'Religious', 'Role - Playing', 'Romance', 'Romantic Comedy', 'Romantic Drama', 'Sci_Fi', 'Sci_Fi_Action', 'Sci_Fi_Adventure',
    'Sci_Fi_Comedy', 'Sci_Fi_Horror', 'Seasonal', 'Shooter', 'Simulation', 'Soft Rock', 'Special Interest', 'Spoken word', 'Sports', 'Sports_Games',
    'Spy Thriller_Drama', 'Strategy', 'Suspense', 'Suspense_Thriller', 'Tejano', 'Television', 'Thriller', 'Traditional', 'Travel', 'Tropical', 'Urban',
    'Urban Musical', 'Urban Western', 'Utility', 'Variety', 'War Drama', 'Western', 'Wrestling'];
  public PlanogramDisplayTypeList = ['Peg', 'Shelf', 'Both'];
  public PackageTypeList = ['Case', 'Inner pack', 'Pallet'];
  public FeeTypeList = ['CA Recycling', 'Beverage', 'NC White Good', 'NA'];
  public CountryList = ['AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT',
    'BO', 'BQ', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'CV', 'KH', 'CM', 'CA', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR', 'CU',
    'CW', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'SZ', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD',
    'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW',
    'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM',
    'NA', 'NR', 'NP', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MK', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO', 'RU', 'RW',
    'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SE', 'CH', 'SY',
    'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'ZM', 'ZW'];
  public CAPropWarningTypeList = ['None', 'Cancer', 'Reproductive Harm', 'Cancer and Reproductive Harm'];
  public shippingList = [
    ' Not Applicable, UN2794 - Batteries wet fillied with acid', 'UN3171 - Battery - powered vehicle', 'UN3171 - Battery - powered equipment', 'UN3091 - Lithium metal batteries contained in equipment',
    'UN3091 - Lithium metal batteries packed with equipment'
    , 'UN3090 - Lithium metal batteries'
    , 'UN3481 - Lithium ion batteries contained in Equipment'
    , 'UN3481 - Lithium ion batteries packed with equipment'
    , 'UN3480 - Lithium ion batteries'
    , 'UN2795 - Batteries wet fillied with alkali'
    , 'UN2800 - Batteries wet non - spillable'
    , 'UN1954 - Compressed gas flammable n.o.s.'
    , 'UN3358 - Refrigerating machines containing flammable non - toxic liquefied gas'
    , 'UN2857 - Refrigerating machines containing non - flammable non - toxic gases or ammonia solutions(UN2672)'
    , 'UN1950 - Aerosols non flammable 2.2; UN1950 Aerosols 2.2'
    , 'UN1719 - Caustic Alkali Liquid n.o.s.UN1013 Carbon Dioxide'];
  public AspectRatioDescriptionList = ['ANAMORPHIC', 'FULLSCREEN', 'FULLSCREEN_WIDESCREEN', 'ANAMORPHIC_FULLSCREEN', 'WIDESCREEN', 'ANAMORPHIC_WIDESCREEN'];
  public NumberOfBatteriesPerUnit = [
    0, 1, 10, 100, 1000, 11, 112, 1148, 12, 122, 123
    , 13, 132, 14, 15, 16, 6, 60, 6932, 7, 72, 8, 86, 87,
    18, 1800, 2, 20, 21, 212, 2121, 2123, 213, 23, 231,
    2311, 2313, 232, 234, 24, 240, 25, 295, 3, 30, 300,
    312, 3123, 32, 321, 3213, 323, 33, 34, 36, 4, 41, 42,
    43, 442, 454, 4564, 48, 5, 50, 51, 52, 53, 54, 58, 9
  ];
  public categoryList = [];
  public selectedCategory;
  constructor(public fb: FormBuilder,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public firebase: FirebaseService) {
    this.initializeForm();
  }

  ngOnInit() {
    if (this.data) {
      this.data = JSON.parse(this.data);
    }
    if (this.data && this.data.rowData) {
      this.isEdit = true;
      this.initializeFormWithValues(this.data.rowData);
    }
    this.firebase.getAllCategory().subscribe((res: any) => {
      this.categoryList = res.map(e => {
        return {
          ...e.payload.doc.data(),
          id: e.payload.doc.id
        };
      });
    });
  }
  public initializeFormWithValues = (data: any) => {
    this.selectedCategory = data.pdmCategory;
    Object.keys(this.pdmForm.controls).forEach(key => {
      if (data[this.smallFirstLetter(key)] !== undefined && data[this.smallFirstLetter(key)] !== null) {
        this.pdmForm.controls[key].setValue(data[this.smallFirstLetter(key)]);
      }
    });
  }
  smallFirstLetter(string) {
    if (string) {
      return string.charAt(0).toLowerCase() + string.slice(1);
    }
  }
  public initializeForm = () => {
    this.pdmForm = this.fb.group({
      id: [],
      itemMasterId: [0],
      sku: [],
      department: [],
      class: [],
      subclass: [],
      supplierGLN: [],
      supplierGLNName: [],
      productDescription: [],
      posDescription: [],
      signDescription: [],
      upc: [],
      upcType: [],
      preSKUInd: [],
      modelNumber: [],
      brandName: [],
      aupplierGLN: [],
      aupplierGLNName: [],
      category: [],
      rating: [],
      labelStudioSupplier: [],
      productLine: [],
      numberofDiscs: [],
      mediaFormat: [],
      genre: [],
      streetDate: [],
      planogramDisplayType: [],
      privateLabelIndicator: [],
      unitCost: [],
      unitCostStartDate: [],
      looseCost: [],
      suggestedRetail: [],
      suggestedRetailCurrency: [],
      inBoxDimensionUOM: [],
      inBoxHeight: [],
      inBoxLengthorDepth: [],
      inBoxWidth: [],
      inBoxWeight: [],
      inBoxWeightUOM: [],
      outofBoxDimensionUOM: [],
      outofBoxHeight: [],
      outofBoxLengthorDepth: [],
      outofBoxWidth: [],
      outofBoxWeight: [],
      outofBoxWeightUOM: [],
      packageType: [],
      packageQty: [],
      dimensionPackageStartDate: [],
      packageGTIN: [],
      caseDimensionUOM: [],
      caseHeight: [],
      caseLengthorDepth: [],
      caseWidth: [],
      caseWeigth: [],
      caseWeightUOM: [],
      innerDimensionUOM: [],
      innerHeight: [],
      innerLengthorDepth: [],
      innerWidth: [],
      innerWeight: [],
      innerWeightUOM: [],
      palletDimensionUOM: [],
      palletHeight: [],
      palletLengthorDepth: [],
      palletWidth: [],
      palletWeight: [],
      palletWeightUOM: [],
      ti: [],
      high: [],
      isNested: [],
      bbyDirectImportIndicator: [],
      feeType: [],
      supplierBeginOrderDate: [],
      manufacturerGLNName: [],
      manufacturerGLNNumber: [],
      countryofExport: [],
      countryofOrigin: [],
      countryofAssembly: [],
      caPropWarningType: [],
      customCAPropWarningMessage: [],
      transportationOrStorageRegulations: [],
      unNumberAndProperShippingName: [],
      amountOfRegulatedMaterial: [],
      cpscRegulations: [],
      cpscDefinedToy: [],
      cpscDefinedChildProduct: [],
      cpscChildPackaging: [],
      aspectRatioDescription: [],
      numberOfBatteriesPerUnit: [],
      warrantyIndicator: [],
      warrantyLabor: [],
      warrantyParts: [],
    });
  }
  public onSubmit = () => {
    this.submitted = true;
    if (true) {
      this.spinner.show();
      const model = { ...this.pdmForm.value, pdmCategory: this.selectedCategory };
      if (this.isEdit) {
        // this.configurationServ*ice.ItemMasterUpdate(model).subscribe(res => {
        //   this.spinner.hide();
        //   this.toastr.success('Item Master', 'Item Master updated successfully.');
        //   this.configurationService.itemMasterListSubject.next(res);
        //   this.isEdit = false;
        //   this.initializeForm();
        // }, error => {
        //   this.spinner.hide();
        // }
        // );
        this.firebase.UpdateSKU(model).then(res => {
          this.spinner.hide();
          this.toastr.success('Item Master', 'Item Master updated successfully.');
          // this.configurationService.itemMasterListSubject.next(res);
          this.isEdit = false;
          this.initializeForm();
        }, error => {
          this.spinner.hide();
        });
      } else {
        // this.configurationService.ItemMasterAdd(model).subscribe(res => {
        //   this.spinner.hide();
        //   this.toastr.success('Item Master', 'Item Master updated successfully.');
        //   this.configurationService.itemMasterListSubject.next(res);
        //   this.isEdit = true;
        // }, error => {
        //   this.spinner.hide();
        // });
        this.firebase.addNewSKU(model).then(res => {
          this.spinner.hide();
          this.toastr.success('Item Master', 'Item Master updated successfully.');
          // this.configurationService.itemMasterListSubject.next(res);
          // this.isEdit = false;
          this.initializeForm();
        }, error => {
          this.spinner.hide();
        });
      }
    }
  }
  public onNoClick = () => {
  }
}
