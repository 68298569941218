import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomHeaderComponent } from './custom-header.component';
import { ButtonRendererComponent } from './button-renderer.component';
import { Subscription } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
// tslint:disable-next-line:no-duplicate-imports
@Component({
  selector: 'app-goal-settings',
  templateUrl: './goal-settings.component.html',
  styleUrls: ['./goal-settings.component.scss']
})
export class GoalSettingsComponent implements OnInit, OnDestroy {

  public submitted = false;
  public isEdit = false;
  public columnDefs: any = [{
    headerName: 'Goal Type',
    field: 'GoalType.Name',
    pinned: 'left',
    // editable: true
  },
    // {
    //   headerName: '',
    //   field: 'cancel',
    //   pinned: 'left',
    //   cellRenderer: 'buttonRenderer',
    //   cellRendererParams: {
    //     onClick: this.openRemoveGoalDialog.bind(this),
    //   },
    //   width: 80
    // },
  ];
  private gridApi: any;
  public tenureTypeList = [];
  public tenureTypeListTemp = [];
  public goalList = [];
  public goalTypeList = [];
  public gridOptions: any;
  public getRowHeight;
  public  headerHeight;
  public modules = AllCommunityModules;
  public clientId = 1;
  public channelList = [{ channelId: 1, channelName: 'Retail' }];
  public channel = null;
  public editSubscription: Subscription;
  public removeSubscription: Subscription;
  public csvRecords: any;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public dialogAddRef: MatDialogRef<DialogAddGoalSettingsComponent>,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public dialogRef: MatDialogRef<DialogGoalSettingsComponent>) {
    this.gridOptions = {
      frameworkComponents: { agColumnHeader: CustomHeaderComponent, buttonRenderer: ButtonRendererComponent, },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.headerHeight = 40;
    this.gridOptions.getRowHeight = function(params) {
      return 40;
    };
  }
  delete(rowData: any) {
    const row = {
      GoalTypeId: rowData.GoalType.GoalTypeId,
      active: false,
    };
    this.spinner.show();
    if (row.GoalTypeId) {
      this.configurationService.activateGoalType(row).subscribe(res => {
        this.goalList = this.goalList.filter(goal => row.GoalTypeId !== goal.GoalType.GoalTypeId);
        this.gridOptions.api.setRowData(this.goalList);
        this.spinner.hide();
        this.toastr.success('Success', 'Goal removed successfully');
      }, error => {
        this.spinner.hide();
      });
    } else {
      this.goalList = this.goalList.filter(goal => rowData !== goal);
      this.gridOptions.api.setRowData(this.goalList);
      this.spinner.hide();
    }
  }
  ngOnInit() {
    this.populateList();
    this.editSubscription = this.configurationService.getEditColumnSubject().subscribe(res => {
      if (res) {
        this.openColumnDialog(true, res);
      }
    });
    this.removeSubscription = this.configurationService.getRemoveColumnSubject().subscribe(res => {
      if (res) {
        this.openRemoveColumnDialog(res);
      }
    });
  }
  ngOnDestroy() {
    this.editSubscription.unsubscribe();
    this.removeSubscription.unsubscribe();
  }
  public getGoalList = (event: any) => {
    const channelId = event.channelId;
    this.spinner.show();
    this.configurationService.getGoalTypeList({ channelId: 5, clientId: 1, active: true }).subscribe(res => {
      this.goalTypeList = res;
    });
    this.configurationService.getGoalTenureTypeList({ channelId: channelId, clientId: 1, active: true }).subscribe((res: any) => {
      this.tenureTypeList = res;
      this.tenureTypeListTemp = this.tenureTypeList;
      this.tenureTypeListTemp = this.tenureTypeListTemp.map(tenure => ({ channelId: channelId, id: tenure.goalTenureTypeId, headerName: tenure.name, field: tenure.code, active: tenure.active }));
      this.columnDefs = [{
        headerName: 'Goal Type',
        field: 'GoalType.Name',
        pinned: 'left',
        // editable: true
      },
      // {
      //   headerName: '',
      //   field: 'cancel',
      //   pinned: 'left',
      //   cellRenderer: 'buttonRenderer',
      //   cellRendererParams: {
      //     onClick: this.openRemoveGoalDialog.bind(this),
      //   },
      //   width: 80
      // },
      ];
      this.tenureTypeListTemp.forEach(tenure => {
        this.columnDefs.push({
          ...tenure, editable: true, valueSetter: function (params) {
            params.data[params.colDef.field] = Number(params.newValue);
            return true;
          }
        });
      });
      this.gridOptions.api.setColumnDefs(this.columnDefs);
      this.spinner.hide();
    }, error => {
      this.columnDefs = [{
        headerName: 'Goal Type',
        field: 'GoalType.Name',
        pinned: 'left',
        // editable: true
      },
      // {
      //   headerName: '',
      //   field: 'cancel',
      //   pinned: 'left',
      //   cellRenderer: 'buttonRenderer',
      //   cellRendererParams: {
      //     onClick: this.openRemoveGoalDialog.bind(this),
      //   },
      //   width: 80
      // },
      ];
      this.spinner.hide();
    });
    this.configurationService.getGoalList({ channelId: channelId, clientId: 1, }).subscribe((goals: any) => {
      if (goals.pivotList && goals.pivotList.length > 0) {
        this.goalList = goals.pivotList || [];
        this.goalList.forEach(element => {
          element.GoalType = {
            Name: element.GoalType.name,
            GoalTypeId: element.GoalType.goalTypeId,
            ChannelId: element.GoalType.channelId,
            ClientId: element.GoalType.clientId,
            Active: element.active,
          };
        });
      }
    }, error => {
      this.goalList = this.goalTypeList.map(goalType => ({
        GoalType: {
          Name: goalType.name,
          GoalTypeId: goalType.goalTypeId,
          ChannelId: 5,
          ClientId: 1,
          Active: goalType.active,
        }
      }));
    });

  }
  public populateList = () => {
    this.configurationService.getAllChannels({ clientId: 1 }).subscribe(res => {
      this.channelList = res.data;
    });
  }
  populateForm(event): void {
    if (event && event.data) {
      this.isEdit = true;
      const dataToPopulate = event.data;
    }
  }
  public onSubmit = () => {
    this.submitted = true;
    let model = this.gridOptions.rowData;
    const columnList = this.columnDefs.filter(col => col.id).map(res => ({ GoalTenuretypeId: res.id, ChannelId: res.channelId, Name: res.headerName, ClientId: this.clientId, Code: res.field, Active: res.active }));
    model = {
      listOfTenures: columnList,
      goalsList: model
    };
    this.spinner.show();
    this.configurationService.addGoal(model).subscribe(res => {
      this.spinner.hide();
      this.toastr.success('Success', 'Goal saved successfully');
    }, error => {
      this.spinner.hide();
    });
  }
  Cancel_Click() {
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  openGoalDialog() {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogAddRef = this.dialog.open(DialogAddGoalSettingsComponent, {
      width: '500px',
      data: { columnDefs: this.columnDefs, goalTypeList: this.goalTypeList, goalList: this.goalList }
    });

    this.dialogAddRef.afterClosed().subscribe(result => {
      if (result) {
        const list = ['GoalType.Name', 'cancel'];
        const defaultFields = {};
        this.columnDefs.forEach(column => {
          if (!list.includes(column.field)) {
            defaultFields[column.field] = 0;
          }
        });
        result = {
          GoalType: { ...result, ChannelId: this.channel.channelId, ClientId: this.clientId },
          ...defaultFields
        };
        this.goalList.push(result);
        this.gridOptions.api.setRowData(this.goalList);
      }
    });
  }
  openColumnDialog(isEdit?: boolean, column?: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogRef = this.dialog.open(DialogGoalSettingsComponent, {
      width: '500px',
      data: { ...column, isEdit: isEdit, columnDefs: this.columnDefs },
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.oldColumn) {
          this.updateColumnDef(result);
        } else {
          this.addColumnDef(result.headerName);
        }
        this.gridOptions.api.setColumnDefs(this.columnDefs);
      }
    });
  }
  public addColumnDef = (headerName: string) => {
    const model = {
      name: headerName,
      clientId: this.clientId,
      channelId: this.channel.channelId
    };
    if (this.columnDefs.find(column => column.headerName !== model.name)) {
      this.spinner.show();
      this.configurationService.addGoalTenureType(model).subscribe(res => {
        if (res) {
          const newModel = { id: res.goalTenureTypeId, channelId: this.channel.channelId, clientId: this.clientId, headerName: res.name, field: res.code, active: res.active };
          this.columnDefs.push({ ...newModel, editable: true });
          this.goalList.forEach(goal => {
            goal[res.code] = null;
          });
          this.gridOptions.api.setColumnDefs(this.columnDefs);
          this.spinner.hide();
        }
      }, error => {
        this.spinner.hide();
      });
    }
  }
  public updateColumnDef = (result: any) => {
    const model = {
      name: result.headerName,
      code: result.field,
      active: result.active,
      channelId: this.channel.channelId,
      goalTenureTypeId: result.id,
      clientId: this.clientId
    };
    this.spinner.show();
    this.configurationService.updateGoalTenureType(model).subscribe(res => {
      if (res) {
        this.columnDefs.forEach(col => {
          if (col.headerName === result.oldColumn && col.field === result.field) {
            col.headerName = result.headerName;
            col.field = result.field;
          }
        });
        this.gridOptions.api.setColumnDefs(this.columnDefs);
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }
  openRemoveColumnDialog(columnToRemove?: string): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: columnToRemove
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tenureTypeListTemp = this.columnDefs;
        this.removeColumn(result);
      }
    });
  }
  openRemoveGoalDialog(goalToRemove: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: goalToRemove.rowData.GoalType.name || goalToRemove.rowData.GoalType.Name }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.delete(goalToRemove.rowData);
      }
    });
  }
  public removeColumn = (column: any) => {
    if (column) {
      const model = {
        goalTenureTypeId: column.id,
        active: false
      };
      this.configurationService.activateGoalTenureType(model).subscribe(res => {
        if (res) {
          this.columnDefs = this.columnDefs.filter(col => col.id !== column.id);
          this.gridOptions.api.setColumnDefs(this.columnDefs);
        }
      });
    }
  }
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
// tslint:disable-next-line:no-duplicate-imports
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'header-name-dialog',
  templateUrl: 'header-name-dialog.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DialogGoalSettingsComponent {
  public headerName = '';
  date = new FormControl(_moment());
  public showErrorMessage = false;
  monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  constructor(
    public dialogRef: MatDialogRef<DialogGoalSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data && this.data.isEdit) {
      const headerName = this.data.headerName;
      const date = _moment(`${headerName.split('-')[1]}-${this.monthList.findIndex(res => res === headerName.split('-')[0]) + 1}-01`);
      this.date.setValue(date);
    }
  }
  chosenYearHandler(normalizedYear: any) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit() {
    if (this.date.value) {
      if (!this.data.isEdit) {
        this.data.field = this.headerName.replace(/[^A-Za-z0-9 ]/ig, '');
      }
      this.headerName = this.date.value ? this.getMonthYearValue(this.date.value) : '';
      const newColumn = {
        ...this.data,
        headerName: this.headerName,
        oldColumn: this.data.displayName,
      };
      const columnIfExists = this.data.columnDefs.find(column => column.headerName.toLowerCase() === this.headerName.toLowerCase());
      if (!columnIfExists) {
        this.showErrorMessage = false;
        this.dialogRef.close(newColumn);
      } else {
        this.showErrorMessage = true;
      }
    }
  }
  public getMonthYearValue = (date: any) => {
    return `${this.monthList[date.month()]}-${date.year()}`;
  }
}
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'goal-dialog',
  templateUrl: 'goal-name-dialog.html',
})
export class DialogAddGoalSettingsComponent {
  public goalType: any;
  public goalTypeList = [];
  constructor(
    public dialogRef: MatDialogRef<DialogAddGoalSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.goalTypeList = this.data.goalTypeList.filter(goalType => {
        const goalFound = this.data.goalList.find(goal => goal.GoalType.GoalTypeId === goalType.goalTypeId);
        if (goalFound) {
          return goalFound.GoalType.GoalTypeId !== goalType.goalTypeId;
        } else {
          return true;
        }
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit() {
    if (this.goalType) {
      this.dialogRef.close({ Name: this.goalType.name, GoalTypeId: this.goalType.goalTypeId });
    }
  }
}
