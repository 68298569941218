<div id="fb-forecast">
  <div class="item-wrapper insight-wrapper">
    <div class="user-page item-search-wrapper">
      <form action="" class="form-fields show-search">
        <div class="form-group">
          <mat-form-field floatLabel="never">
            <input matInput id="filter-text-box" placeholder="Insight Search"
              (keyup)="onFilterTextBoxChanged($event)" />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
      </form>
      <form action="" class="insight-form">
        <div class="form-group">
          <label>Retailer</label>
          <mat-select (selectionChange)="filterList()" [(ngModel)]="retailer" [ngModelOptions]="{ standalone: true }"
            placeholder="All">
            <mat-option [value]="undefined">All</mat-option>
            <mat-option *ngFor="let retailer of retailerList" [value]="retailer.channelId">{{ retailer.channelName }}
            </mat-option>
          </mat-select>
        </div>
        <div class="form-group">
          <label>Country</label>
          <mat-select (selectionChange)="filterList()" [(ngModel)]="country" [ngModelOptions]="{ standalone: true }"
            placeholder="All">
            <mat-option [value]="undefined">All</mat-option>
            <mat-option *ngFor="let country of countryList" [value]="country.countryId">{{ country.countryName }}
            </mat-option>
          </mat-select>
        </div>
        <div class="form-group">
          <label>Products</label>
          <mat-select (selectionChange)="filterList()" [(ngModel)]="product" [ngModelOptions]="{ standalone: true }"
            placeholder="All">
            <mat-option [value]="undefined">All</mat-option>
            <mat-option *ngFor="let product of productList" [value]="product.itemManagerId">{{ product.description }}
            </mat-option>
          </mat-select>
        </div>
        <div class="form-group">
          <label>Published</label>
          <mat-select (selectionChange)="filterList()" [(ngModel)]="status" [ngModelOptions]="{ standalone: true }"
            placeholder="All">
            <mat-option [value]="undefined">All</mat-option>
            <mat-option *ngFor="let status of statusList" [value]="status.value">{{ status.statusName }}</mat-option>
          </mat-select>
        </div>
        <div class="form-group">
          <label>Expired</label>
          <mat-select (selectionChange)="filterList()" [(ngModel)]="active" [ngModelOptions]="{ standalone: true }"
            placeholder="All">
            <mat-option [value]="undefined">All</mat-option>
            <mat-option *ngFor="let active of activeList" [value]="active.value">{{ active.statusName }}</mat-option>
          </mat-select>
        </div>
        <div class="form-group">
          <label>Start Date</label>
          <mat-form-field>
            <input (dateChange)="filterList()" (click)="picker1.open()" [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="selectedStartDate" matInput [matDatepicker]="picker1" />
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-group">
          <label>End Date</label>
          <mat-form-field>
            <input (dateChange)="filterList()" (click)="picker2.open()" [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="selectedEndDate" matInput [matDatepicker]="picker2" />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="item-search-btn">
          <button (click)="resetFilters()" class="btn btn-primary reset-btn">
            Reset
          </button>
        </div>
      </form>
    </div>
    <mat-accordion class="fb-forecast-accordion item-wrapper-panel">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header [style.pointer-events]="'none'" collapsedHeight="35px" expandedHeight="35px">
          <mat-panel-title> All Insights </mat-panel-title>
          <mat-panel-description>
            <a [style.pointer-events]="'all'" (click)="$event.stopPropagation(); listView = true">List View</a>
            <a [style.pointer-events]="'all'" (click)="$event.stopPropagation(); listView = false">Gallery View</a>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div id="item-manager" class="container-fluid">
          <div *ngIf="listView">
            <div class="all-show-grid items-wrapper-grid">
              <ag-grid-angular class="ag-theme-material" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
                [rowData]="insightList" [headerHeight]="headerHeight" [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)" [modules]="modules" #grid>
              </ag-grid-angular>
            </div>
          </div>
          <div *ngIf="!listView">
            <div class="insight-gallery-banner d-flex">
              <p>Arranged by:</p>
              <mat-select class="ml-1" style="width: 8%;" [(ngModel)]="selectedSorting" (selectionChange)="sortList()">
                <mat-option [value]="'value'">Value</mat-option>
                <mat-option [value]="'name'">Name</mat-option>
              </mat-select>
            </div>
            <div class="row">
              <div class="col-md-4 insight-gallery" *ngFor="let item of insightList">
                <div style="text-align: center">
                  <img [src]="item.documentPath || 'assets/images/thumbnails.png'" width="200px" height="200px" />
                  <div class="insight-gallery-inner">
                    <p>{{ item.retailerName }}</p>
                    <a (click)="openDialog({ rowData: item })">{{
                      item.productName
                    }}</a>
                    <div class="gallery-date-value">
                      <p>{{ item.insights }}</p>
                      <p>
                        Value: <span>{{ item.value | currency: 'USD' }}</span>
                      </p>
                    </div>
                  </div>
                  <!-- <span>{{item.retailerName}}&nbsp;&nbsp;{{item.productName}}&nbsp;&nbsp;{{item.insight}}</span><br>
                <span><b>Value:</b>{{item.value}}</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
