<div class="container-fluid">
  <div class="user-page">
    <div class="row">
      <div class="col-md-1 d-block d-sm-block d-md-none mobile-icon">
        <div class="btn-show">
          <button (click)="openDialog({})" mat-icon-button color="primary">
            <i class="material-icons">
              add_circle
            </i>
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <form action="" class="form-fields show-search">
          <div class="form-group">
            <mat-form-field>
              <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-md-8 d-none d-sm-none d-md-block tablet-icon">
        <div class="btn-show">
          <span (click)="openAddDialog({})">
            <i style="font-size: 36px;" class="material-icons" aria-label="Add">
              add_circle
            </i>
          </span>
          <span (click)="openExcelImportDialog({})">
            <i style="font-size: 36px;" class="material-icons" aria-label="Column Show/Hide">
              import_export
            </i>
          </span>
          <span (click)="openDialog({})">
            <i style="font-size: 36px;" class="material-icons" aria-label="Column Show/Hide">
              list_alt
            </i>
          </span>
          <span (click)="openFullScreenMode()">
            <i style="font-size: 36px;" class="material-icons" aria-label="Full Screen">
              launch
            </i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="all-show-grid">
    <ag-grid-angular id="myGrid" class="ag-theme-material"  [suppressMenuHide]="true"
      [paginationPageSize]="10"   groupHeaders
      suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
      (cellDoubleClicked)="onCellClicked($event)" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
      [rowData]="skuPromotion" (gridReady)="onGridReady($event)" [modules]="modules" #grid>
    </ag-grid-angular>
  </div>
</div>
<!-- <router-outlet name="promotion"></router-outlet> -->
<ng-template #dialog let-data>
  <h1 mat-dialog-title>Show/Hide Columns</h1>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <mat-checkbox maxlength="20" [(ngModel)]="selectAll" (ngModelChange)="selectAllSelected($event)">Select/Remove All
    </mat-checkbox>
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSave(data)">Save &
      Close</button>
  </div>
  <div mat-dialog-content>
    <div style="display: flex;flex-direction: column;">
      <mat-checkbox maxlength="20" *ngFor="let item of columnDefs" [(ngModel)]="item.show">{{item.headerName}}
      </mat-checkbox>
    </div>
  </div>
</ng-template>
<ng-template #Adddialog let-data>
  <h1 mat-dialog-title>SKU Promotion</h1>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <mat-checkbox maxlength="20" [(ngModel)]="selectAllSKU" (ngModelChange)="selectAllSelected($event)">Select/Remove
      All
    </mat-checkbox>
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSave(data)">Save &
      Close</button>
  </div>
  <div mat-dialog-content>
    <div style="display: flex;flex-direction: column;">
      <mat-checkbox maxlength="20" *ngFor="let item of skuList" [(ngModel)]="item.selected">{{item.itemDescription}}
      </mat-checkbox>
    </div>
  </div>
</ng-template>
<ng-template #ExcelImport let-data>
  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <h1 mat-dialog-title>Import/Export</h1>
      <button mat-raised-button class="btn btn-primary" color="primary" (click)="exportToExcel()">Export to
        Excel</button>
    </div>
    <div>
      <ngx-dropzone (change)="onSelect($event)">
        <ngx-dropzone-label>Drop files here!</ngx-dropzone-label>
        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>
    <!-- <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview> -->
  </div>
</ng-template>
