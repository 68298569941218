<!-- Main Content area -->
<div class="container">
  <div class="row">
    <div class="col-md-6 form-group">
    </div>
  </div>
  <div class="action-panel text-right">
    <button (click)="openDialog({}, dialogRef)" class="btn btn-primary" color="primary" mat-raised-button>
      Add New Announcement
      <i class="icon-add"></i>
    </button>
  </div>
  <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material"  groupHeaders suppressRowClickSelection
      toolPanelSuppressGroups toolPanelSuppressValues [pagination]="true" [gridOptions]="gridOptions"
      domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20'
      [columnDefs]="columnDefs" [rowData]="data" (gridReady)="onGridReady($event)"
      [modules]="modules" #grid>
    </ag-grid-angular>
  </div>
</div>
<ng-template #dialogRef let-data>
  <h1 mat-dialog-title>Create Announcement</h1>
  <div mat-dialog-content>
    <ckeditor class="text-editor-wrapper" [config]="ckeditorConfig" [editor]="Editor" [(ngModel)]="data.content"
    (ready)="onReady($event)" (change)="onChange($event)">
  </ckeditor>
    <mat-slide-toggle [(ngModel)]="data.isPublish">Publish
    </mat-slide-toggle>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit(data)">Save</button>
    <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="matDialogRef.close()">Cancel</button>
  </div>
</ng-template>
