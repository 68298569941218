<div class="container">
  <div class="row">
    <div class="col-md-6 form-group">
      <mat-form-field>
        <mat-select placeholder="Select Channel" [(ngModel)]="channel" (ngModelChange)="getCategoryMixFormList()">
          <mat-option *ngFor=" let res of channelList; let i=index" [value]="res">
            {{res.channelName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <div *ngIf="channel" class="all-show-grid">
        <label class="grid-head">Category Wise Breakdown</label>
        <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20'
           groupHeaders suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
          [gridOptions]="gridOptions" [headerHeight]="headerHeight" [getRowHeight]="getRowHeight" [columnDefs]="columnDefs" [suppressRowTransform]="true" [rowData]="gridList"
          (gridReady)="onGridReady($event)" [modules]="modules" #grid>
        </ag-grid-angular>
      </div>
    </div>
    <div class="col-md-12 col-lg-6">
      <div  *ngIf="channel" class="all-show-grid">
        <label class="grid-head">Varaitions +/-</label>
        <ag-grid-angular class="ag-theme-material" domLayout='autoHeight' paginationAutoPageSize='false' paginationPageSize='20'
           groupHeaders suppressRowClickSelection toolPanelSuppressGroups toolPanelSuppressValues
          [gridOptions]="gridOptionsVariation" [headerHeight]="headerHeight" [getRowHeight]="getRowHeight" [columnDefs]="columnDefsVariation" [suppressRowTransform]="true"
          [rowData]="gridListVariation" [modules]="modules" #grid>
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
